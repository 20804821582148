import './UsersListItem.scss';

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { REGISTRATION_URL } from '../../../constants/constants';
import { useModalContext } from '../../../context';
import {
  useAdminEditUserMutation,
  useGetAllUsersQuery,
  useSetUserAnalyticMutation,
} from '../../../graphql/generated/graphql';
import { ModalStatuses } from '../../../types/enums';
import { formatToDateAndTime, textSlice } from '../../../utils';
import { EditIcon } from '../../icons';
import { Switch, TagItem } from '../../index';
import { UserProfileModal } from '../../userProfileModal/UserProfileModal';
import { UsersListItemType } from '../UsersList';

export type usersListItemProps = {
  id?: number | null;
  login?: string | null;
  role?: string | null;
  createdAt?: string | null;
  registrationLink?: string | null;
  creator?: string | null;
  updatedAt?: string | null;
  lastEditerUser?: string | null;
  lastConnect?: string | null;
  user: UsersListItemType;
  isAnalytics: boolean;
  tags?: {
    count: number;
    rows: Array<{
      id: number;
      name: string;
      color?: string | null;
      textColor?: string | null;
    }>;
  };
  chronographReports?: any;
};

export const UsersListItem = ({
  id,
  login,
  role,
  createdAt,
  registrationLink,
  creator,
  lastConnect,
  user,
  isAnalytics,
  tags,
}: usersListItemProps) => {
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  const { refetch } = useGetAllUsersQuery({
    variables: {
      input: {
        sortType: 'desc',
        sortBy: 'id',
        search: '',
      },
    },
  });
  const [adminEditUserMutation] = useAdminEditUserMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const [copyLinkColor, setCopyLinkColor] = useState('#303030');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isAnalyticsStatus, setIsAnalyticsStatus] = useState(isAnalytics);
  useEffect(() => {
    setIsAnalyticsStatus(isAnalytics);
  }, [isAnalytics]);

  const [setUserAnalyticMutation] = useSetUserAnalyticMutation({
    onCompleted: (response) => {
      setIsAnalyticsStatus(response.setUserAnalytic);
    },
  });

  const onCloseHandler = async () => {
    setIsModalOpen(false);
    await refetch();
  };

  let currentStatusTitle: string;
  switch (role) {
    case 'Admin':
      currentStatusTitle = 'Администратор';
      break;
    case 'Moderator':
      currentStatusTitle = 'Модератор';
      break;
    case 'User':
      currentStatusTitle = 'Пользователь';
      break;
    case 'Guest':
      currentStatusTitle = 'Гость';
      break;
    default:
      currentStatusTitle = '';
  }

  const onLinkClickHandler = (e: { preventDefault: () => void }, link: string) => {
    e.preventDefault();
    navigator.clipboard.writeText(link);
    toast.success('Ссылка успешно скопирована!');
    setCopyLinkColor('#149939');
    setTimeout(() => setCopyLinkColor('#303030'), 2000);
  };

  useEffect(() => {
    return () => {
      setIsModalOpen(false);
    };
  }, []);

  const regLink = `${REGISTRATION_URL}?registration=${registrationLink}&login=${login}`;

  return (
    <div className='userItem'>
      <div className='userItem__col' style={{ width: '5%' }}>
        <p className='userItem__content'>{id}</p>
      </div>
      <div className='userItem__col'>
        <p className='userItem__content'>
          <span onClick={() => setIsModalOpen(true)}>{login}</span>
        </p>
        <UserProfileModal
          isOpen={isModalOpen}
          onClose={onCloseHandler}
          userData={user}
          registrationLink={registrationLink}
        />
      </div>
      <div className='userItem__col'>
        <p className='userItem__content userItem__content--status'>
          <span
            className={`userItem__statusIndicator userItem__statusIndicator--${role}`}
          />
          <span>{currentStatusTitle}</span>
        </p>
      </div>
      <div className='userItem__col'>
        <p className='userItem__content'>{formatToDateAndTime(createdAt)}</p>
        <p className='userItem__content'>
          Пользователем {creator ? creator : 'Неизвестно'}
        </p>
      </div>
      <div
        className='userItem__col'
        style={{ width: '25%', justifyContent: 'flex-start', display: 'flex' }}
      >
        <div className='userItem__tag-wrapper'>
          {tags?.rows?.map((e, index: number) => {
            if (index > 3) return;
            return (
              <div key={e?.id} className={'userItem__tag-item'}>
                <TagItem
                  title={textSlice({ text: e?.name, count: tags?.count >= 4 ? 12 : 20 })}
                  color={e?.color}
                  textColor={e?.textColor}
                />
              </div>
            );
          })}
          {tags?.count && tags?.count > 4 ? (
            <div className='userItem__tag-more  ml10'>ещё {tags?.count - 4}</div>
          ) : null}
          {tags?.count === 0 && <div className='userItem__tag-empty'>Ещё нет тегов</div>}
          <EditIcon
            width={15}
            height={15}
            color='#303030'
            className='edit-color ml10 mb5'
            onClick={() => {
              handleSetModalStatus(ModalStatuses.ADD_USER_TAG_MODAL);
              handleChangeModalParameters([
                {
                  key: 'initialValues',
                  value: {
                    tagsId: tags?.rows?.map((e) => {
                      return `${e.id}`;
                    }),
                    userID: user?.id,
                  },
                },
                {
                  key: 'onCallback',
                  value: (e: any) => {
                    adminEditUserMutation({
                      variables: {
                        data: { idUser: parseInt(user?.id as any) as any, tagsId: e },
                      },
                    });
                  },
                },
              ]);
            }}
          />
        </div>
      </div>

      <div className='userItem__col'>
        {formatToDateAndTime(lastConnect) === formatToDateAndTime(createdAt) &&
        registrationLink ? (
          <>
            <p className='userItem__content'>Регистрация не завершена</p>
            <p className='userItem__content'>
              <a
                className='addUser__link'
                style={{ color: copyLinkColor }}
                onClick={(e) => onLinkClickHandler(e, regLink)}
                href={regLink}
              >
                {regLink}
              </a>
            </p>
          </>
        ) : (
          <p className='userItem__content'>{formatToDateAndTime(lastConnect)}</p>
        )}
      </div>
      <div className='userItem__col'>
        <div className='userItem__switch-wrapper'>
          <Switch
            value={isAnalyticsStatus}
            handleChange={(value: boolean) => {
              setUserAnalyticMutation({
                variables: {
                  userId: id as number,
                  isAnalytics: value,
                },
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
