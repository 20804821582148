import React from 'react';

export const DubleCheckIcons = () => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.10053 3.10414C7.97709 2.96529 7.77741 2.96529 7.65397 3.10414L3.18923 8.14432L1.53859 6.3002C1.41546 6.16135 1.21547 6.16135 1.09235 6.3002C0.969218 6.43906 0.969218 6.66384 1.09235 6.80234L2.96705 8.89719C3.08893 9.03427 3.29173 9.03427 3.41361 8.89719L8.10051 3.60631C8.22397 3.46778 8.22363 3.243 8.10053 3.10414Z'
        fill='#0D0D0D'
      />
      <path
        d='M10.9074 3.10414C10.784 2.96529 10.5843 2.96529 10.4609 3.10414L5.99611 8.14432L5.59548 7.72067C5.47235 7.58181 5.27236 7.58181 5.14923 7.72067C5.0261 7.85952 5.0261 8.08431 5.14923 8.22281L5.77393 8.89719C5.89581 9.03427 6.09861 9.03427 6.22049 8.89719L10.9074 3.60631C11.0309 3.46778 11.0309 3.243 10.9074 3.10414Z'
        fill='#0D0D0D'
      />
    </svg>
  );
};
