import './TextSizeIcon.scss';

import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const TextSizeIcon: FC<TextSizeIconType> = ({
  width = '20px',
  height = '20px',
  color = '#FFFFFF',
  className = 'text-size-icon',
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M11.875 4.5H7.375V3.625H18.25V4.5H13.75H13.25V5V15.75H12.375V5V4.5H11.875Z'
        fill={color}
        stroke={color}
      />
      <path
        d='M5.125 8.75V15.75H4.875V8.75V8.25H4.375H1.75V8H8.25V8.25H5.625H5.125V8.75Z'
        fill={color}
        stroke={color}
      />
    </svg>
  );
};

type TextSizeIconType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
>;
