import './ProjectAccordion.scss';

import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { arrowUp } from '../../assets/icons';
import { Tag, TagList } from '../../graphql/generated/graphql';
import { Comment } from '..';
import { ProjectStatus } from '../project-status/ProjectStatus';
import { TagItem } from '../tag/TagItem';

type ProjectAccordionPropsType = {
  name?: string;
  number?: number | null;
  tasksList?: Array<{
    id: number;
    name: string;
    code?: string | null;
    parentId?: number | null;
    status?: { id?: number; color?: string } | null;
    tags: TagList;
    maintainerComment?: string | null;
  } | null> | null;
  setFindItem: (value: boolean) => void;
};

export const ProjectAccordion = ({
  name,
  number,
  tasksList,
  setFindItem,
}: ProjectAccordionPropsType) => {
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(true);

  const isExpensesPage = location.pathname.includes('/expenses');
  if (tasksList?.length === 0) return null;
  setFindItem(true);

  return (
    <>
      <div className='projectAccordion' style={{ marginBottom: !isOpen ? '1px' : '' }}>
        <div className='projectAccordion__header' onClick={() => setIsOpen(!isOpen)}>
          <div className={`projectAccordion__icon ${isOpen ? 'open' : ''}`}>
            <img src={arrowUp} width='12px' height='8px' />
          </div>
          <h3 className='projectAccordion__title'>
            #{number} {name}
          </h3>
        </div>
        {isOpen && (
          <div className='projectAccordion__tasksList'>
            {tasksList?.map((item) => {
              return (
                <div key={item?.id} className='projectAccordion__taskItem'>
                  <div className='projectAccordion__taskItemCell tags'>
                    {item?.tags?.rows?.map((tag: Tag) => {
                      return (
                        <div key={tag.id}>
                          <TagItem
                            id={tag.id}
                            title={tag.name}
                            color={tag.color}
                            textColor={tag.textColor as string}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div
                    className={`projectAccordion__taskItemCell ${
                      !isExpensesPage ? 'task' : 'price'
                    }`}
                  >
                    <ProjectStatus projectStatus={item?.status?.id} id={item?.id} />
                    <Link
                      to={
                        item?.parentId
                          ? `/task/${item?.parentId}/subtask/${item?.id}`
                          : `/task/${item?.id}`
                      }
                    >
                      {`#${item?.code} ${item?.name}`}
                    </Link>
                  </div>
                  <div
                    className={`projectAccordion__taskItemCell ${
                      !isExpensesPage ? 'comment' : 'taskExpenses'
                    }`}
                  >
                    <Comment
                      comment={item?.maintainerComment || ''}
                      taskId={item?.id}
                      isSubtask={!!item?.parentId}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};
