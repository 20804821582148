import './TredfileList.scss';

import React, { useEffect } from 'react';

import {
  CreateScaleMapTaskMutationFn,
  DeleteScaleMapTaskMutationFn,
  MoveScaleMapTaskMutationFn,
  MoveScaleMapTaskToRowMutationFn,
  ScaleMapColumnStatisticsType as ScaleMapColumnStatisticsTypeType,
  UpdateScaleMapTaskMutationFn,
} from '../../graphql/generated/graphql';
import { CellFirstCol } from './CellFirstCol/CellFirstCol';
import { CellThreeCol } from './CellThreeCol/CellThreeCol';
import { CellTwoCol } from './CellTwoCol/CellTwoCol';

type TredFileListType = {
  scaleMapTasks: any;
  createScaleMapTaskMutation: CreateScaleMapTaskMutationFn;
  updateScaleMapTaskMutation: UpdateScaleMapTaskMutationFn;
  deleteScaleMapTaskMutation: DeleteScaleMapTaskMutationFn;
  moveScaleMapTaskToRowMutation: MoveScaleMapTaskToRowMutationFn;
  selectFirstCol: number;
  setSelectFirstCol: (value: number) => void;
  selectTwoCol: number;
  setSelectTwoCol: (value: number) => void;
  selectThreeCol: number;
  setSelectThreeCol: (value: number) => void;
  scaleMapColumnStatistics: [ScaleMapColumnStatisticsTypeType] | undefined;
  filterStatus: number | null;
  setFilterStatus: (value: number) => void;
  hideSuccess: boolean;
  selectTask: any;
  setSelectTask: (value: any) => void;
  hideSuccessInner: boolean;
  moveScaleMapTaskMutation: MoveScaleMapTaskMutationFn;
};

export const TredFileList = ({
  scaleMapTasks,
  createScaleMapTaskMutation,
  updateScaleMapTaskMutation,
  deleteScaleMapTaskMutation,
  moveScaleMapTaskToRowMutation,
  selectTwoCol,
  setSelectTwoCol,
  selectThreeCol,
  setSelectThreeCol,
  selectFirstCol,
  setSelectFirstCol,
  scaleMapColumnStatistics,
  filterStatus,
  setFilterStatus,
  hideSuccess,
  selectTask,
  setSelectTask,
  hideSuccessInner,
  moveScaleMapTaskMutation,
}: TredFileListType) => {
  const newArr: any = calcNewArray({ arr: scaleMapTasks });

  useEffect(() => {
    setSelectThreeCol(0);
    setSelectTwoCol(0);
  }, [filterStatus]);
  useEffect(() => {
    setSelectThreeCol(0);
    setSelectTwoCol(0);
  }, [selectFirstCol]);
  useEffect(() => {
    setSelectThreeCol(0);
  }, [selectTwoCol]);

  const handleMoveScaleMapTaskToRowMutation = (index: any, length: any) => {
    moveScaleMapTaskToRowMutation({
      variables: {
        id: selectTask,
        row: index,
        order: length ? length : 0,
      },
    });
    setSelectTask(null);
  };

  return (
    <div className='TredFileList__table'>
      {newArr.map((e: any, index: number) => {
        const rowStatistic = scaleMapColumnStatistics?.filter((e) => e.row === index + 1);
        const isLineSuccesses =
          e?.col1?.every(
            (element: any) => element.isNestingAllTasksWithCompleted === true,
          ) && e?.col1?.length > 0;
        // easy hide empty slot fix
        if (e?.col1?.length === 0 && index + 1 < newArr?.length) return null;

        if (hideSuccess && isLineSuccesses) return null;
        return (
          <div className='TredFileList__row' key={index}>
            <div className='TredFileList__column' style={{ position: 'relative' }}>
              {selectTask && (
                <div
                  className='TredFileList__selector'
                  onClick={() => {
                    handleMoveScaleMapTaskToRowMutation(index + 1, e?.col1?.length);
                  }}
                ></div>
              )}

              <CellFirstCol
                content={e?.col1?.sort((a: any, b: any) => {
                  return a.order - b.order;
                })}
                createScaleMapTaskMutation={createScaleMapTaskMutation}
                selectFirstCol={selectFirstCol}
                setSelectFirstCol={setSelectFirstCol}
                updateScaleMapTaskMutation={updateScaleMapTaskMutation}
                deleteScaleMapTaskMutation={deleteScaleMapTaskMutation}
                index={index}
                setFilterStatus={setFilterStatus}
                isLineSuccesses={isLineSuccesses}
                setSelectTask={setSelectTask}
                selectTask={selectTask}
                hideSuccessInner={hideSuccessInner}
                trigerLength={e?.col2?.length}
              />
            </div>
            <div className='TredFileList__column '>
              <CellTwoCol
                content={e?.col2}
                fatherId={e?.col1[0]?.id}
                createScaleMapTaskMutation={createScaleMapTaskMutation}
                setSelectTwoCol={setSelectTwoCol}
                selectFirstCol={selectFirstCol}
                selectTwoCol={selectTwoCol}
                updateScaleMapTaskMutation={updateScaleMapTaskMutation}
                deleteScaleMapTaskMutation={deleteScaleMapTaskMutation}
                index={index}
                statictic={rowStatistic?.find((e) => e.column === 2)}
                fatherArray={e?.col1?.map((e: any) => e.id)}
                filterStatus={filterStatus}
                isLineSuccesses={isLineSuccesses}
                moveScaleMapTaskMutation={moveScaleMapTaskMutation}
              />
            </div>
            <div className='TredFileList__column'>
              <CellThreeCol
                content={e?.col3}
                selectTwoCol={selectTwoCol}
                createScaleMapTaskMutation={createScaleMapTaskMutation}
                selectThreeCol={selectThreeCol}
                setSelectThreeCol={setSelectThreeCol}
                updateScaleMapTaskMutation={updateScaleMapTaskMutation}
                deleteScaleMapTaskMutation={deleteScaleMapTaskMutation}
                index={index}
                statictic={rowStatistic?.find((e) => e.column === 3)}
                fatherArray={e?.col2?.map((e: any) => e.id)}
                fatherId={e?.col2[0]?.id}
                filterStatus={filterStatus}
                isLineSuccesses={isLineSuccesses}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const calcNewArray = ({ arr }: any) => {
  let maxRow = 0;
  arr?.map((e: any) => {
    if (maxRow < e?.row) {
      maxRow = e?.row;
    }
  });
  let newArr = new Array(maxRow + 1).fill({
    col1: [],
    col2: [],
    col3: [],
    col4: [],
  });
  newArr = newArr?.map((_, index) => {
    const col1: any = [];
    const col2: any = [];
    const col3: any = [];
    arr?.map((e: any) => {
      if (e?.row !== index + 1) return;
      if (e?.column === 1) {
        col1.push(e);
      }
      if (e?.column === 2) {
        col2.push(e);
      }
      if (e?.column === 3) {
        col3.push(e);
      }
    });
    return {
      col1,
      col2,
      col3,
    };
  });
  return newArr;
};
