import './FileFilter.scss';

import React from 'react';

import { StorageFilterItems } from '../../graphql/generated/graphql';
import {
  StorageAllWord,
  StorageExelWord,
  StorageFileWord,
  StorageImgWord,
  StoragePdfWord,
} from '../icons';

type FileFilterType = { setFilter: any; filter: any };

export const FileFilter = ({ setFilter, filter }: FileFilterType) => {
  const updateFilter = ({ arr, value }: any) => {
    const index = arr.indexOf(value);
    if (index !== -1) {
      arr.splice(index, 1);
    } else {
      arr.push(value);
    }
    const checkAll = arr.indexOf(StorageFilterItems.All);
    if (checkAll !== -1) {
      arr.splice(checkAll, 1);
    }
    if (arr.length === 0) {
      setFilter([StorageFilterItems.All]);
    } else {
      setFilter(JSON.parse(JSON.stringify(arr)));
    }
  };

  const find = (value: StorageFilterItems, arr: any) => {
    return arr.indexOf(value) !== -1;
  };

  return (
    <div className='file-filter__wrapper'>
      <div
        className={`file-filter__item ${
          find(StorageFilterItems.Doc, filter) ? 'file-filter__select' : ''
        }`}
        onClick={() => {
          updateFilter({ arr: filter, value: StorageFilterItems.Doc });
        }}
      >
        <StorageFileWord
          color={find(StorageFilterItems.Doc, filter) ? 'white' : 'black'}
        />
      </div>
      <div
        className={`file-filter__item ${
          find(StorageFilterItems.Xls, filter) ? 'file-filter__select' : ''
        }`}
        onClick={() => {
          updateFilter({ arr: filter, value: StorageFilterItems.Xls });
        }}
      >
        <StorageExelWord
          color={find(StorageFilterItems.Xls, filter) ? 'white' : 'black'}
        />
      </div>
      <div
        className={`file-filter__item ${
          find(StorageFilterItems.Pdf, filter) ? 'file-filter__select' : ''
        }`}
        onClick={() => {
          updateFilter({ arr: filter, value: StorageFilterItems.Pdf });
        }}
      >
        <StoragePdfWord
          color={find(StorageFilterItems.Pdf, filter) ? 'white' : 'black'}
        />
      </div>
      <div
        className={`file-filter__item ${
          find(StorageFilterItems.Images, filter) ? 'file-filter__select' : ''
        }`}
        onClick={() => {
          updateFilter({ arr: filter, value: StorageFilterItems.Images });
        }}
      >
        <StorageImgWord
          color={find(StorageFilterItems.Images, filter) ? 'white' : 'black'}
        />
      </div>
      <div
        className={`file-filter__item ${
          find(StorageFilterItems.All, filter) ? 'file-filter__select' : ''
        }`}
        onClick={() => {
          setFilter([StorageFilterItems.All]);
        }}
      >
        <StorageAllWord
          color={find(StorageFilterItems.All, filter) ? 'white' : 'black'}
        />
      </div>
    </div>
  );
};
