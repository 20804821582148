import { gql } from '@apollo/client';

export const GET_PROJECT_LIGHT = gql`
  query getProjectLight($id: Int!, $tasksData: GetTasksInput!) {
    getProject(id: $id) {
      id
      name
      author {
        id
        login
      }
      maintainer {
        id
        login
      }
      tasksSubtasks(data: $tasksData) {
        count
        rows {
          id
          name
          code
          poligonObject {
            id
          }
        }
      }
    }
  }
`;
