/* eslint-disable react/no-unescaped-entities */
import './Terms.scss';

import React, { useEffect } from 'react';

export const Terms = () => {
  useEffect(() => {
    document.title = 'Terms & Conditions';
  }, []);
  return (
    <div style={{ backgroundColor: 'white', padding: '20px' }} className='Terms'>
      <h2>Sublicense Agreement</h2>
      <p>
        The Sublicense Agreement is an offer from the Limited Liability Company Barduck
        (hereinafter referred to the "Holder") to enter into the agreement upon the terms
        and conditions stated below.
      </p>
      <p>
        <strong>
          Please read the terms and conditions of this Sublicense Agreement before using
          the Mobile application. Use of the Mobile application on other conditions is not
          allowed.
        </strong>
      </p>

      <p>
        <strong>1. Terms and Definitions</strong>
      </p>
      <p>
        1.1. Words and phrases used in this Sublicense Agreement have the following
        meanings unless otherwise is expressly specified hereinafter:
      </p>

      <ul>
        <li>
          а) <strong>Sublicense (Agreement)</strong> means the text of this document with
          all its annexes, amendments and addendums displayed while installation process
          of the Mobile application or during the start of its using.
        </li>
        <li>
          b) <strong>User</strong> means a person who entered into this Agreement with the
          Holder for its own benefit or for the benefit of others due to requirements of
          the actual legislation and this Agreement.
        </li>
        <li>
          c) <strong>Mobile application/Game</strong> means the software “One Life Story”
          of the Holder intended for installation and use on the Device.
        </li>
        <li>
          d) <strong>Basic Game version</strong> means the Mobile application with limited
          functionality.
        </li>
        <li>
          e) <strong>Extended Game version</strong> means the Mobile application which
          includes software application, software extension, data and commands extending
          its functionality after installation is completed and/or activation process in
          Device is done.
        </li>
        <li>
          f) <strong>Device</strong> means any mobile phone, communicator, smartphone,
          tablet, or other device which allows to use the Mobile applications according to
          its functional purpose.
        </li>
      </ul>

      <p>
        1.2. This Agreement could use other terms and definitions not stipulates in clause
        1.1. hereof. In this case such term should be interpreted according to the text
        hereof. If it is not possible to interpret such term or definition in a unique
        manner, such term of definition should be interpreted firstly according to
        mandatory documents stipulated therein, secondly – according to the applicable
        laws and after that – according to business custom and science doctrine.
      </p>

      <p>
        <strong>2. Sublicense</strong>
      </p>
      <p>
        2.1. Once this Agreement is concluded the Holder entitles the User to use the
        related version of the Mobile application on the terms of a non-exclusive
        Sublicense worldwide during the set term.
      </p>
      <p>
        2.2. To conclude this Agreement in regards to the Basic version the User is
        supposed to perform the complex of the actions stated below:
      </p>

      <ul>
        <li>
          Mobile application reproduction (record) or launch of the Basic Game version on
          the Device
        </li>
      </ul>

      <p>
        The fulfillment of above mentioned actions confirm that the User has reviewed and
        fully and absolutely agrees to the full extent the terms set forth herein, and
        creates the agreement under the given conditions between the User and the Holder
        due to the provisions of article 437 and article 438 of the Civil Code of the
        Russian Federation.
      </p>

      <p>
        2.3. The User has the right to use the Basic Game version due to the terms and
        conditions hereof in the following ways:
      </p>
      <p>2.3.1 Basic Game version reproduction (record) on the Device.</p>
      <p>2.3.2. Basic Game version launch and using due to its functional purpose.</p>

      <p>
        2.4. The User will be granted with the Extended Game version Sublicense since the
        payment date of the Sublicense fee according to the Holder rates; Such Sublicense
        will create a separate Sublicense agreement on terms hereof.
      </p>

      <p>2.5. Under Extended Game version Sublicense the Holder entitles the User:</p>
      <p>
        2.5.1. to fulfill launch, installation and running of program applications and
        software extension to the Basic Game version or activation of additional commands
        and codes to update the Basic Game version to the Extended Game version.
      </p>
      <p>
        2.5.2. to use additional functionality of the Extended Game version according to
        their description.
      </p>

      <p>
        2.6. Any rights and ways of use of the Mobile application are not expressly
        granted to the User by the Agreement shall be deemed non-granted/prohibited by the
        Holder.
      </p>

      <p>2.7. The term of the Basic Game version Sublicense validity is not limited</p>

      <p>
        2.8. The period of the Extended Game version Sublicense should be determined
        according to the Sublicense fee amount paid by the User upon the Holder’s rates
        set on the payment date.
      </p>

      <p>
        2.9. Once the Extended Game version Sublicense is over the Agreement could be
        renewed for the new period upon the terms regards to the actual version of the
        Agreement on the Sublicense fee re-payment date. The quantity of extensions of the
        Extended Game version Sublicense period under the Agreement is not limited.
      </p>

      <p>
        2.10. The Sublicense fee payments could be made using payment ways and payment
        services operator stipulated on the moment of Sublicense obtaining.
      </p>

      <p>
        <strong>3. Sublicense Restrictions</strong>
      </p>
      <p>
        3.1. The User is not allowed thereof independently or employing any third parties
        to do the following:
      </p>
      <p>
        3.1.1. In any manner copy (reproduce) application software and databases being a
        part of the Mobile applications, including any of their elements and information
        materials without prior written consent of their owner.
      </p>
      <p>
        3.1.2. Reveal the techniques, emulate, decompile, disassemble, decode and make any
        other similar actions in relation to the Mobile applications.
      </p>
      <p>
        3.1.3. Create software and/or services using the Mobile application without prior
        permission of the Holder.
      </p>
      <p>
        3.1.4. Remove or in any way alter trademarks and copyright notices or other
        proprietary notices included in the Mobile application.
      </p>

      <p>
        3.2. The Holder has the right to set technical restrictions of using the Mobile
        application; the Holder will inform the User about abovementioned limitations from
        time to time in the manner by the Holder’s choice.
      </p>

      <p>
        3.3. The functionality of the Mobile application including one that is available
        exclusively in the Extended version, is a subject the Holder’s sole discretion and
        may be changed from time to time.
      </p>

      <p>
        <strong>4. Liability under the Sublicense</strong>
      </p>
      <p>
        4.1. In view of granting the User with the Basic Game version Sublicense on a
        free-of-charge basis under the Agreement the provisions of the Consumer Protection
        Legislation shall not be applicable to the relations of the parties with respect
        to the Agreement.
      </p>
      <p>
        4.2. The Mobile application is provided "as is", and in this connection the User
        shall be granted no further warranties that: it will conform with the requirements
        of the User; it will function continuously, quickly, reliably and without errors;
        the results which can be obtained by its use will be accurate and reliable; all
        the errors will be corrected.
      </p>
      <p>
        4.3. Since functional capabilities of the Mobile application are constantly
        supplemented and updated, the form and the nature of the Mobile application could
        be changed from time to time without prior notice of the User. The Holder has the
        right at his own discretion to stop (temporarily or finally) providing or
        supporting the Mobile Application to Users, and also to alter or withdraw the
        Sublicense without prior notice.
      </p>
      <p>
        4.4. The User is responsible for any violation of the obligations provided by the
        Agreement and (or) an applicable legislation, and also for all the consequences of
        such violations (including any damages which the Holder and other third parties
        can suffer).
      </p>
      <p>
        4.5. In case the Holder will be brought to justice or will be penalized due to the
        User’s violations of the rights and/or the interests on third parties as well as
        violation of the restrictions or limitations set by the law such User has to
        reimburse all the damages.
      </p>
      <p>
        4.6. The Holder is not responsible for the violations made by the User as well as
        for the damage or losses under abovementioned circumstances.
      </p>
      <p>
        4.7. Cumulative liability of the Holder under the Agreement in any event is
        limited by the documentary confirmed damage suffered by the User in the amount
        which does not exceed RUB 1000, and the Holder shall be deemed responsible for it
        if there is his fault in causing such damage.
      </p>
      <p>
        4.8. The Holder reserves the right to prosecute at his own discretion violators of
        the exclusive rights in relation to the Mobile application due to the civil,
        administrative and criminal legislation.
      </p>

      <p>
        <strong>5. Final provisions</strong>
      </p>
      <p>
        5.1. The processing the information provided by the User about himself/herself
        within use of the Mobile application as well as the information which is received
        within such use in automated way is made by the Holder according to the Privacy
        Policy published or available at the address:{' '}
        <a href='https://barduck.online/privacy' target='_blank' rel='noreferrer'>
          https://barduck.online/privacy
        </a>
      </p>
      <p>
        5.2. This Agreement, procedure for its concluding and executing and also any
        issues not provided herein shall be governed by the applicable law of the Russian
        Federation.
      </p>
      <p>
        5.3. Any dispute arising from the Agreement or in connection with it shall be
        subject to settlement in court at the location of the Holder in accordance with
        the applicable procedural law of the Russian Federation.
      </p>
      <p>
        5.4. This Agreement could be altered or terminated by the Holder unilaterally and
        without prior notification of the User and without payment of any compensation in
        this regard.
      </p>
      <p>5.5. The Holder’s details:</p>
      <p>“Barduck” LLC</p>
      <p>Registration number: 1187154024584</p>
      <p>Address: 2 Ul’yanova, lit. A, 2nd floor, office 1, Tula, Russia.</p>
      <p>
        E-mail: <a href='mailto:support@sacralium.games'>support@sacralium.games</a>
      </p>
      <p>Dated «15» April 2019</p>
      <p>
        The Sublicense Agreement is drawn up by the law firm{' '}
        <a href='https://it-lex.ru' target='_blank' rel='noreferrer'>
          IT Lex
        </a>
      </p>
    </div>
  );
};
