import './TredOpenImageModal.scss';

import React from 'react';

export const TredOpenImageModal = ({ modalParameters }: any) => {
  const usl = modalParameters?.initialValues?.url;

  const text = modalParameters?.initialValues?.text;

  return (
    <div className='tredOpenImageModal'>
      <img src={usl} className='tredOpenImageModal__image' />
      {text ? <div className='tredOpenImageModal__text'>{text}</div> : null}
    </div>
  );
};
