import './Storage.scss';

import React from 'react';

export const Pagination = ({
  dataPerPage,
  totalData,
  paginate,
  currentPage,
}: {
  dataPerPage: number;
  totalData: number;
  paginate: any;
  currentPage: number;
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalData / dataPerPage); i++) {
    pageNumbers.push(i);
  }
  if (pageNumbers.length === 1)
    return (
      <div className='storage__pagination'>
        <div className='storage__pagination-button'></div>
      </div>
    );

  return (
    <div className='storage__pagination'>
      {pageNumbers.map((number) => (
        <div
          key={number}
          className={`storage__pagination-button ${
            currentPage === number ? 'storage__pagination-select' : ''
          }`}
          onClick={() => paginate(number)}
        >
          {number}
        </div>
      ))}
    </div>
  );
};
