import React from 'react';

export const SuccessIcons = () => {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.0003 0.666626C17.5365 0.666626 20.9279 2.07138 23.4284 4.57187C25.9289 7.07235 27.3337 10.4637 27.3337 14C27.3337 17.5362 25.9289 20.9276 23.4284 23.4281C20.9279 25.9285 17.5365 27.3333 14.0003 27.3333C10.4641 27.3333 7.07272 25.9285 4.57224 23.4281C2.07175 20.9276 0.666992 17.5362 0.666992 14C0.666992 10.4637 2.07175 7.07235 4.57224 4.57187C7.07272 2.07138 10.4641 0.666626 14.0003 0.666626ZM12.3394 16.6304L9.37747 13.6666C9.27129 13.5604 9.14523 13.4762 9.00649 13.4187C8.86776 13.3613 8.71906 13.3317 8.5689 13.3317C8.41873 13.3317 8.27004 13.3613 8.1313 13.4187C7.99257 13.4762 7.86651 13.5604 7.76033 13.6666C7.54588 13.8811 7.4254 14.1719 7.4254 14.4752C7.4254 14.7785 7.54588 15.0693 7.76033 15.2838L11.5318 19.0552C11.6376 19.1619 11.7636 19.2466 11.9024 19.3044C12.0412 19.3622 12.19 19.392 12.3403 19.392C12.4907 19.392 12.6395 19.3622 12.7783 19.3044C12.9171 19.2466 13.043 19.1619 13.1489 19.0552L20.9584 11.2438C21.066 11.138 21.1516 11.012 21.2103 10.873C21.269 10.734 21.2995 10.5848 21.3002 10.434C21.3009 10.2831 21.2717 10.1336 21.2144 9.99405C21.157 9.85452 21.0726 9.72773 20.966 9.62099C20.8594 9.51425 20.7327 9.42968 20.5932 9.37215C20.4537 9.31461 20.3043 9.28526 20.1534 9.28579C20.0025 9.28631 19.8533 9.3167 19.7142 9.3752C19.5752 9.4337 19.4491 9.51915 19.3432 9.62663L12.3394 16.6304Z'
        fill='#149939'
      />
    </svg>
  );
};
