import { gql } from '@apollo/client';

export const GET_MY_TASKS_SUBTASKS = gql`
  query getMyTasksSubtasks {
    getCurrentUser {
      id
      myTasksSubtasks(limit: 100) {
        id
        parentId
        name
        code
        status {
          id
        }
        project {
          id
          name
          number
        }
        createdAt
        updatedAt
        maintainer {
          id
          login
          image
        }
        tags {
          count
          rows {
            id
            name
            color
            textColor
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
