import './Task.scss';

import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  Chat,
  Executors,
  Files,
  MainContentHeaderItem,
  MainContentHeaderTitle,
  Notes,
  Palette,
  RequestHandler,
  Subtasks,
} from '../../components';
import {
  CoinsIcon,
  CrossIcon,
  EditIcon,
  PersonalIcons,
  PolygonIcon,
} from '../../components/icons';
import { StartButton } from '../../components/startButton';
import { PATHS } from '../../constants/constants';
import { ChatProvider, useAuthContext, useModalContext } from '../../context';
import { useReadProjectActivitiesMutation } from '../../hooks/useMutations';
import { useGetTaskAssigneesQuery, useGetTaskQuery } from '../../hooks/useQueries';
import { ModalStatuses } from '../../types/enums';

export const Task: React.FC = () => {
  const { taskId, subtaskId } = useParams();
  const { data: assignees } = useGetTaskAssigneesQuery();
  const { currentUserData } = useAuthContext();

  const location = useLocation();

  const navigate = useNavigate();

  const { data, loading, error, refetch } = useGetTaskQuery({});

  const [readProjectActivitiesMutation] = useReadProjectActivitiesMutation();

  const executorsRef = useRef<HTMLDivElement | null>();

  const [toggleExecutors, setToggleExecutors] = useState<boolean>(true);

  const [executorsHeight, setExecutorsHeight] = useState<number | undefined>(223);

  const handleShowExecutors = () => setToggleExecutors(!toggleExecutors);

  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  useEffect(() => {
    if (executorsRef && executorsRef.current?.offsetHeight) {
      setTimeout(() => {
        setExecutorsHeight(executorsRef?.current?.offsetHeight);
      }, 300);
    }
  });

  useEffect(() => {
    readProjectActivitiesMutation({
      variables: {
        taskId: Number(taskId),
      },
    });
    return () => {
      readProjectActivitiesMutation({
        variables: {
          taskId: Number(taskId),
        },
      });
    };
  }, [taskId]);

  const isArchivedPage = location.pathname.includes('archive');

  const handleGoBack = () => {
    navigate(isArchivedPage ? PATHS.archive : PATHS.projects);
  };

  const participant = data?.getTask?.maintainer?.id === currentUserData?.id;

  return (
    <div className='container'>
      <div className='task-page'>
        <RequestHandler loading={loading} error={error}>
          <ChatProvider>
            <div className='task-page__body'>
              <div className='task-page__header'>
                <div className='task-page__header-left'>
                  <div style={{ display: 'flex', width: '100%' }}>
                    {participant && <PersonalIcons className='mr10' />}
                    <MainContentHeaderTitle
                      title={`#${data?.getTask?.code} ${data?.getTask?.name}`}
                    />
                  </div>
                  <div className='task-page__header-left-actions'>
                    {data?.getTask.poligonObject?.id && (
                      <PolygonIcon
                        color='#303030'
                        onClick={() => {
                          navigate(
                            `/polygon/${data.getTask.project.id}/${data?.getTask.poligonObject?.id}`,
                          );
                        }}
                      />
                    )}
                    <>
                      <div
                        onClick={() => {
                          handleSetModalStatus(ModalStatuses.EDIT_TITLE);
                          handleChangeModalParameters([
                            {
                              key: 'initialValues',
                              value: {
                                initialTitle: data?.getTask.name,
                                initialStartDate: data?.getTask.startDate,
                                initialCloseDate: data?.getTask.closeDate,
                                initialEndDate: data?.getTask?.endDate,
                                taskId: taskId,
                                label: 'Название задачи:',
                                initialTagIds: data?.getTask?.tags?.rows?.map(({ id }) =>
                                  String(id),
                                ),
                                entityId: Number(taskId),
                                subtaskId: subtaskId,
                              },
                            },
                          ]);
                        }}
                      >
                        <EditIcon
                          width={13}
                          height={13}
                          color={'black'}
                          className='mr10'
                        />
                      </div>
                      <div
                        onClick={() => {
                          handleSetModalStatus(ModalStatuses.DELETE_TASK);
                          handleChangeModalParameters([
                            {
                              key: 'initialValues',
                              value: {
                                confirmTitle: data?.getTask.name,
                                taskId: taskId,
                                subtaskId: subtaskId,
                                projectId: data?.getTask?.project?.id,
                                entityId: Number(taskId),
                              },
                            },
                          ]);
                        }}
                      >
                        <CrossIcon
                          width={13}
                          height={13}
                          color={'gray'}
                          className='mr10'
                        />
                      </div>
                      {taskId && !subtaskId ? (
                        <div
                          onClick={() => {
                            handleSetModalStatus(ModalStatuses.PRICE_TASK);
                            handleChangeModalParameters([
                              {
                                key: 'initialValues',
                                value: {
                                  entityId: Number(taskId),
                                  realizationPrice: data?.getTask.realizationPrice,
                                },
                              },
                            ]);
                          }}
                        >
                          <CoinsIcon className='mr10' />
                        </div>
                      ) : null}
                    </>
                    <Palette statusId={data?.getTask?.status?.id} />
                    {taskId && (
                      <div className='ml10'>
                        <StartButton
                          entityId={Number(taskId)}
                          startDate={data?.getTask.startDate}
                          closeDate={data?.getTask.closeDate}
                          status={parseInt(data?.getTask?.status?.id as any)}
                          refetch={refetch}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className='task-page__header-right'>
                  <MainContentHeaderItem
                    fullWidth
                    title={'Вернуться назад →'}
                    onClick={handleGoBack}
                    isButton
                  />
                </div>
              </div>
              <div className='line' />
              <div className='task-page__content'>
                <div className='task-page__content-left'>
                  <Subtasks
                    subTasksList={data?.getTask?.subtasks}
                    taskMaintainerId={data?.getTask.maintainer?.id}
                    initialTagIds={data?.getTask?.tags?.rows?.map(({ id }) => String(id))}
                  />
                  <Chat />
                </div>
                <div className='task-page__content-right'>
                  <Executors
                    maintainer={data?.getTask.maintainer}
                    executorsRef={executorsRef}
                    toggleExecutors={toggleExecutors}
                    variant={'task'}
                    handleShowExecutors={handleShowExecutors}
                    assignees={assignees}
                  />
                  <Files backgroundColor={'#F5F5F5'} />
                  <Notes
                    backgroundColor={'#F5F5F5'}
                    executorsHeight={executorsHeight}
                    maintainer={data?.getTask.maintainer}
                  />
                </div>
              </div>
            </div>
          </ChatProvider>
        </RequestHandler>
      </div>
    </div>
  );
};
