import './TreadsItem.scss';
import 'quill/dist/quill.snow.css';

import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ROLES } from '../../../constants/constants';
import { useAuthContext } from '../../../context';
import { ForumThread } from '../../../graphql/generated/graphql';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { avatarUrlCreate, getUserAvatar, wrapContainer } from '../../../utils';
import { CloseTreadWhiteIcon, ForumIconsTree, RedPinIcon } from '../../icons';
import { ForumUserHover, HoverMenuTreads } from '../../index';

type TreadsItemType = {
  updateForumThreadMutation: any;
  tread: ForumThread;
  removeForumThreadMutation: any;
  isAccessEdit: boolean;
};

export const TreadsItem = ({
  updateForumThreadMutation,
  removeForumThreadMutation,
  tread,
  isAccessEdit,
}: TreadsItemType) => {
  const [hoverMenuAction, setHoverMenuAction] = useState(false);
  const ref = useRef(null);
  const refText = useRef(null);
  const { sectionId } = useParams();
  useClickOutside(ref, () => setHoverMenuAction(false));
  const { currentUserData } = useAuthContext();

  const edited = () => {
    if (currentUserData?.role === ROLES.ADMIN) return true;
    if (tread?.author?.id === currentUserData?.id) return true;
    return false;
  };

  const isEdited = edited();
  const navigate = useNavigate();

  const avatarUrl = avatarUrlCreate({ tread, userId: tread?.author?.id as number });

  return (
    <div
      onClick={() => {
        navigate(`/forum/section/${sectionId}/tread/${tread.id}/page/1`);
      }}
    >
      <div className='TreadsItem__header'>
        <div className='TreadsItem__header-title'>
          {tread?.isClosed && <CloseTreadWhiteIcon className={'mr5'} />}
          {tread?.isAttached && <RedPinIcon className={'mr5'} />}
          {!tread?.isViewed && <div className='TreadsItem__header-new mr5'>New</div>}

          {tread?.name}
        </div>

        {isEdited && isAccessEdit ? (
          <span className='HoverMenu__wrapper' ref={ref}>
            <ForumIconsTree
              onClick={(e: any) => {
                e.stopPropagation();
                setHoverMenuAction(!hoverMenuAction);
              }}
            />
            {hoverMenuAction && (
              <HoverMenuTreads
                updateForumThreadMutation={updateForumThreadMutation}
                removeForumThreadMutation={removeForumThreadMutation}
                tread={tread}
                setHoverMenuAction={setHoverMenuAction}
              />
            )}
          </span>
        ) : (
          <span></span>
        )}
      </div>
      <div className='TreadsItem__body'>
        <div className='TreadsItem__body-user'>
          <ForumUserHover user={tread?.author}>
            <img src={avatarUrl} alt='add' className='TreadsItem__body-avatar' />
          </ForumUserHover>
          <div className='TreadsItem__body-userWrapper'>
            <div className='TreadsItem__body-text' ref={refText}>
              <div
                className='ql-editor'
                dangerouslySetInnerHTML={{ __html: wrapContainer(tread.description) }}
              ></div>
            </div>
          </div>
          <div className='TreadsItem__Line'></div>
          <div className='TreadsItem__ansver'>
            <div className='TreadsItem__ansver-textWrapper'>
              <span className='TreadsItem__ansver-text'>Ответы:</span>{' '}
              <span className='TreadsItem__ansver-count'>
                {tread?.forumMessages?.count}
              </span>
            </div>
            <div className='TreadsItem__ansver-wrapper'>
              {tread?.forumMessages?.rows?.map((message) => {
                return (
                  <ForumUserHover key={message?.id} right={true} user={message?.user}>
                    <img
                      src={getUserAvatar(message?.user?.image)}
                      alt='add'
                      className='TreadsItem__ansver-avatar'
                    />
                  </ForumUserHover>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
