import { ru } from 'date-fns/locale';
import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';

import { ArrowDown } from '../../../icons';

export const DatePickerC = ({ id, selectId, setSelectId, date, setDate }: any) => {
  const inputDate = moment(date).format('DD-MM-YYYY');
  const select = selectId === id;
  return (
    <div className='datePickerC'>
      <label
        htmlFor={id}
        className='datePickerC__label'
        onClick={() => {
          setSelectId(id);
        }}
      >
        <div
          className='datePickerC__title'
          style={{
            borderBottom: select ? '1px solid black' : '1px solid white',
          }}
        >
          <span
            style={{
              opacity: !date ? '0' : '1',
            }}
          >
            {inputDate}
          </span>
          <ArrowDown />
        </div>
      </label>

      {select && (
        <div className={`datePickerC__container`}>
          <DatePicker
            selected={date}
            onChange={(date) => setDate(date as any)}
            id={id}
            locale={ru}
          />{' '}
        </div>
      )}
    </div>
  );
};
