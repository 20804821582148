import './Comment.scss';

import React, { useState } from 'react';

import { ChangeCommentModal, LinkifyText } from '..';
import { EditIcon } from '../icons';

type CommentPropsType = {
  taskId?: number;
  comment: string;
  isSubtask: boolean;
};
export const Comment = ({ taskId, comment, isSubtask }: CommentPropsType) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const openModal = () => {
    setIsOpenModal(true);
    if (comment) {
      setIsEditMode(true);
    }
  };

  return (
    <>
      <div className='comment'>
        <div className='comment__icon'>
          <EditIcon width={13} height={13} onClick={openModal} />
        </div>
        {comment ? (
          <p className='comment__text'>
            <LinkifyText>{comment}</LinkifyText>
          </p>
        ) : (
          <p className='comment__placeholder'>Добавьте комментарий к задаче</p>
        )}
      </div>
      {isOpenModal && (
        <ChangeCommentModal
          isOpen={isOpenModal}
          id={taskId}
          closeModal={() => setIsOpenModal(false)}
          isEditMode={isEditMode}
          editedComment={comment}
          isSubtask={isSubtask}
        />
      )}
    </>
  );
};
