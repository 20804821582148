import { gql } from '@apollo/client';

export const GET_ALL_USERS = gql`
  query getAllUsers($input: GetUsersInput!) {
    getAllUsers(input: $input) {
      rows {
        id
        createdAt
        updatedAt
        login
        registrationLink
        editDate
        fullName
        theNote
        image
        role
        lastConnect
        isAnalytics
        karmaStatistics {
          karma
        }
        accessibleProjectsStorage {
          id
          name
        }
        creator {
          login
        }
        tags {
          rows {
            id
            name
            color
            textColor
          }
          count
        }
        chronographReports {
          id
          date
          text
          userId
        }
      }
      count
    }
  }
`;
