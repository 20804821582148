import './ForumTread.scss';

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ButtonLink, ButtonLinksWrap, ForumTreadItem, Header } from '../../components';
import { QuoteIcons } from '../../components/icons';
import { PATHS } from '../../constants/constants';
import { useGetForumSectionsQuery } from '../../graphql/generated/graphql';
import { useForumAccess, useNewActivityRefetch } from '../../hooks';

export const ForumTread = () => {
  const { sectionId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useNewActivityRefetch();

  const { data: dataSection } = useGetForumSectionsQuery();

  const sectionRole = dataSection?.getForumSections?.rows?.find(
    (e: any) => e?.id === parseInt(sectionId as any),
  )?.role;

  const { isAccessEdit } = useForumAccess({ sectionRole });

  return (
    <div className='ForumTreads__wrapper'>
      <div className='ForumTreads'>
        <Header />
        <div className='content__black-block'>
          <div className='content__black-block__inner'>
            <div className='content__black-block__text'>
              <QuoteIcons />
              <div className='content__black-block__text-inner'>
                В этом есть настоящий секрет жизни: целиком отдаваться тому, что ты
                делаешь прямо здесь и сейчас, и вместо того, чтобы называть это работой,
                осознать, что это – игра <QuoteIcons className='iconRotate' />
              </div>
              <div className='content__black-block__author '>Алан Уилсон Уотс</div>
            </div>
          </div>
        </div>
        <div className='block'>
          <div className='sidebar'></div>
          <div className='content'>
            <ForumTreadItem isAccessEdit={isAccessEdit} sectionRole={sectionRole} />
          </div>
          <div className='sidebar'></div>
        </div>
        <div className='ForumTreads__footer'></div>
      </div>
      <ButtonLinksWrap>
        <ButtonLink
          mode='decideOnTheFly'
          title='Решай-на-лету'
          path={PATHS.decideOnTheFly}
        />
        <ButtonLink mode='circle' title='Круг общения' path={PATHS.circle} />
        <ButtonLink mode='chrono' title='Хронометр' path={PATHS.chronometer} />
        <ButtonLink mode='file' title='Проект "Я"' path='/projectI' />
        <ButtonLink mode='history' title='История задач' path='/history' />
      </ButtonLinksWrap>
    </div>
  );
};
