import './ChronometerTime.scss';

import React from 'react';

export const ChronometerTime = () => {
  return (
    <>
      <div className='chronometerTime__zero'>00:00</div>
      <div className='chronometerTime__six'>06:00</div>
      <div className='chronometerTime__twelve'>12:00</div>
      <div className='chronometerTime__eighteen'>18:00</div>
    </>
  );
};
