import './FileGraphics.scss';

import moment from 'moment';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { CalendarPicker, FileGraphics } from '../../components';
import { HeaderStorageIcons } from '../../components/icons';
import { useAuthContext } from '../../context';
import {
  GetScaleMapTaskHistoryStatisticsQuery,
  useGetScaleMapTaskHistoryStatisticsQuery,
} from '../../graphql/generated/graphql';

export const FileGraphicsPage = () => {
  const [newMonth, setNewMonth] = useState<number | null>(null);
  const [newYear, setNewYear] = useState<number | null>(null);
  const location = useLocation();
  const iPage = location.pathname.includes('/file/graphicsI');
  const { currentUserData } = useAuthContext();

  const { data: historyFile } = useGetScaleMapTaskHistoryStatisticsQuery({
    variables: {
      date: createHistoryQueryData({ newMonth, newYear }),
      userId: iPage ? currentUserData?.id : undefined,
    },
    nextFetchPolicy: 'network-only',
  });
  const showGraphics =
    historyFile?.getScaleMapTaskHistoryStatistics &&
    historyFile?.getScaleMapTaskHistoryStatistics?.length > 0;

  const cryateDay = dayCreate({ data: historyFile?.getScaleMapTaskHistoryStatistics });
  const green = calcGreen({ data: historyFile?.getScaleMapTaskHistoryStatistics });

  const files = createReportFile(historyFile);

  return (
    <div className='container'>
      <div className='FileGraphics'>
        <div className='FileGraphics__header'>
          <div className='FileGraphics__header-name'>
            График
            <HeaderStorageIcons
              color='black'
              className='ml10'
              onClick={() => {
                createTxtFile(files, `${createHistoryQueryData({ newMonth, newYear })}`);
              }}
            />
          </div>
          <CalendarPicker
            changeMonthHandler={(e) => {
              setNewMonth(new Date(e).getMonth());
              setNewYear(new Date(e).getFullYear());
            }}
          />
        </div>
        <div className='FileGraphics__line'></div>

        {showGraphics ? (
          <FileGraphics
            historyData={historyFile?.getScaleMapTaskHistoryStatistics as any}
            newMonth={newMonth ? newMonth + 1 : undefined}
            newYear={newYear}
          />
        ) : (
          <div className='FileGraphics__noData'>Данных за этот месяц нет</div>
        )}
        <div className='FileGraphics__day-data'>
          <div className='FileGraphics__creative'>
            <div className='FileGraphics__creative-title'>Самый креативный день</div>
            <div>
              <div className='FileGraphics__creative-date'>
                {cryateDay?.dayName}, {cryateDay?.date}
              </div>
              <div>добавлено задач +{cryateDay?.length}</div>
            </div>
          </div>
          <div className='FileGraphics__creative'>
            <div className='FileGraphics__creative-title2'>День характера</div>
            <div>
              <div className='FileGraphics__creative-date'>
                {green?.dayName}, {green?.date}
              </div>
              <div>выполнено задач +{green?.length}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const createHistoryQueryData = ({ newMonth, newYear }: any) => {
  const date = new Date();
  const month = date.getMonth();
  const year = date.getFullYear();
  const createMonth = (newMonth: any) => {
    let updateMonth = 1;
    if (newMonth) {
      updateMonth = updateMonth + newMonth;
    } else {
      if (newMonth === 0) return '01';
      updateMonth = month + 1;
    }

    return `${updateMonth?.toString()?.length === 1 ? '0' : ''}${updateMonth}`;
  };

  return `${createMonth(newMonth)}-${newYear ? newYear : year}`;
};

const dayCreate = ({ data }: any) => {
  let maxData = { length: 0, date: '', dayName: '' };
  if (!data) return maxData;
  data?.map((day: any) => {
    day?.historyTypes?.map((e: any) => {
      if (e?.type === 'TaskCreated') {
        let count = 0;
        e?.items?.map((item: any) => {
          count = count + item.scaleMapTasks.length;
        });
        if (maxData.length < count) {
          maxData = { length: count, date: day?.date, dayName: '' };
        }
      }
    });
  });
  maxData.dayName = weekNames[parseDate(maxData.date).getDay() - 1];
  return maxData;
};

const calcGreen = ({ data }: any) => {
  let maxData = { length: 0, date: '', dayName: '' };

  if (!data) return maxData;
  data?.map((day: any) => {
    let count = 0;
    day?.historyTypes?.map((e: any) => {
      e?.items?.map((item: any) => {
        if (item?.status?.name === 'Completed') {
          count = count + item.scaleMapTasks.length;
        }
      });
      if (maxData.length < count) {
        maxData = { length: count, date: day?.date, dayName: '' };
      }
    });
  });
  maxData.dayName = weekNames[parseDate(maxData.date).getDay() - 1];
  return maxData;
};

const weekNames = [
  'понедельник',
  'вторник',
  'среда',
  'четверг',
  'пятница',
  'суббота',
  'воскресенье',
];

function parseDate(input: string): Date {
  const dateComponents = input.split('-');
  const day = dateComponents.shift();
  const month = dateComponents.shift();
  const year = dateComponents.shift();
  const isoDateString = `${year}-${month}-${day}`;
  return new Date(isoDateString);
}

const getColor = (id: number | undefined | null) => {
  switch (id) {
    case 1:
      return 'Зеленый';
    case 2:
      return 'Желтый';
    case 3:
      return 'Серый';
    case 4:
      return 'Красный';
    default:
      return 'unknown';
  }
};

const createReportFile = (
  historyFile: GetScaleMapTaskHistoryStatisticsQuery | undefined,
) => {
  const files: any = [];

  historyFile?.getScaleMapTaskHistoryStatistics?.forEach((historyTypes) => {
    historyTypes?.historyTypes?.forEach((items) => {
      items?.items?.map((scaleMapTasks) => {
        const type = items?.type;
        scaleMapTasks?.scaleMapTasks?.forEach((data) => {
          const projectName = data?.scaleMapFile?.project?.name
            ? data?.scaleMapFile?.project?.name
            : 'Проект "Я"';
          const fileName = data?.scaleMapFile?.name;
          const color = getColor(scaleMapTasks?.status?.id);
          const colorOld = getColor(data?.creationScaleMapStatusId);
          const name = data?.name;
          const dateCreate = moment(parseInt(data?.createdAt)).format('YYYY-MM-DD');
          if (type === 'TaskCreated') {
            files?.push(
              `[${dateCreate}],<${projectName}>,(${fileName}),'${name}',{${color}},/активна;`,
            );
          } else {
            const dateUpdate = moment(parseInt(data?.updatedAt)).format('YYYY-MM-DD');
            const startDate = moment(dateCreate);
            const endDate = moment(dateUpdate);
            const daysDifference = endDate?.diff(startDate, 'days');
            files?.push(
              `[${dateCreate}],<${projectName}>,(${fileName}),'${name}',{${colorOld}},/"${dateUpdate}",{${color}},|${daysDifference}|;`,
            );
          }
        });
      });
    });
  });
  return files;
};

function createTxtFile(files: any, fileName: string) {
  let formattedText = '';

  files?.forEach((e: string) => {
    formattedText += `${e}\n`;
  });

  const element = document.createElement('a');
  const file = new Blob([formattedText], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
