import './ProjectBlockHeader.scss';

import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { EntityActions, EntityActionsType } from '../entityActions/EntityActions';
import { ProjectBlockTitle } from '../projectBlock/projectBlockTitle/ProjectBlockTitle';

export const ProjectBlockHeader: FC<{ title?: string } & EntityActionsType> = ({
  title,
  projectNumber,
  initialTitle,
  label,
  color,
  entityId,
  isProject,
  projectId,
  handleDelete,
  refetch,
}) => {
  const location = useLocation();

  const isArchivePage = location.pathname.includes('archive');
  return (
    <div
      className={isArchivePage ? 'projectBlock__headerArchive' : 'projectBlock__header'}
    >
      <ProjectBlockTitle title={title} />
      <EntityActions
        entityId={entityId}
        projectNumber={projectNumber}
        initialTitle={initialTitle}
        label={label}
        color={color}
        isProject={isProject}
        projectId={projectId}
        handleDelete={handleDelete}
        refetch={refetch}
      />
    </div>
  );
};
