import './Chronometer.scss';

import React from 'react';

import { toolsChronoType } from '../../types/types';
import { ChronometerCanvas, ChronometerHtml2 } from '../index';
import { ChronometerTime } from './ChronometerTime/ChronometerTime';

type ChronometerType = {
  handleSetSelectHours: (value: number) => void;
  toolsChrono: toolsChronoType;
  selectDay: any;
  handleEnergyFixed: (value: any) => void;
  handleTimeFixed: (value: any) => void;
};

export const Chronometer = ({
  handleSetSelectHours,
  toolsChrono,
  selectDay,
  handleEnergyFixed,
  handleTimeFixed,
}: ChronometerType) => {
  return (
    <div className='chronometer__wrapper'>
      <ChronometerCanvas toolsChrono={toolsChrono} selectDay={selectDay} />
      <div className='chronometer__html'>
        <ChronometerHtml2
          selectDay={selectDay}
          handleSetSelectHours={handleSetSelectHours}
          toolsChrono={toolsChrono}
          handleEnergyFixed={handleEnergyFixed}
          handleTimeFixed={handleTimeFixed}
        />
      </div>
      <ChronometerTime />
    </div>
  );
};
