import './CoinsIcon.scss';

import React, { FC } from 'react';

export const CoinsIcon: FC<CoinsIconType> = ({
  color = '#AFAFAF',
  width = '16px',
  height = '12px',
  onClick,
  className,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      className={className}
    >
      <path
        className='coinsColor'
        d='M11.5 3.6V3.25C11.5 1.68125 9.1375 0.5 6 0.5C2.8625 0.5 0.5 1.68125 0.5 3.25V5.75C0.5 7.05625 2.1375 8.09375 4.5 8.40625V8.75C4.5 10.3188 6.8625 11.5 10 11.5C13.1375 11.5 15.5 10.3188 15.5 8.75V6.25C15.5 4.95625 13.9125 3.91875 11.5 3.6ZM14.5 6.25C14.5 7.075 12.575 8 10 8C9.76875 8 9.5375 7.99375 9.30625 7.975C10.6562 7.4875 11.5 6.6875 11.5 5.75V4.60625C13.3687 4.8875 14.5 5.64375 14.5 6.25ZM4.5 7.3875V5.90625C4.99742 5.97017 5.49849 6.00149 6 6C6.50151 6.00149 7.00258 5.97017 7.5 5.90625V7.3875C7.00379 7.46482 6.5022 7.50244 6 7.5C5.4978 7.50244 4.99621 7.46482 4.5 7.3875ZM10.5 4.86875V5.75C10.5 6.275 9.725 6.8375 8.5 7.18125V5.71875C9.30625 5.525 9.9875 5.23125 10.5 4.86875ZM6 1.5C8.575 1.5 10.5 2.425 10.5 3.25C10.5 4.075 8.575 5 6 5C3.425 5 1.5 4.075 1.5 3.25C1.5 2.425 3.425 1.5 6 1.5ZM1.5 5.75V4.86875C2.0125 5.23125 2.69375 5.525 3.5 5.71875V7.18125C2.275 6.8375 1.5 6.275 1.5 5.75ZM5.5 8.75V8.4875L6 8.5C6.24583 8.5 6.48333 8.49375 6.7125 8.48125C6.9625 8.56875 7.225 8.64375 7.5 8.7125V10.1812C6.275 9.8375 5.5 9.275 5.5 8.75ZM8.5 10.3875V8.9C8.99736 8.96525 9.49838 8.99865 10 9C10.5015 9.00149 11.0026 8.97017 11.5 8.90625V10.3875C10.5056 10.5375 9.49438 10.5375 8.5 10.3875ZM12.5 10.1812V8.71875C13.3062 8.525 13.9875 8.23125 14.5 7.86875V8.75C14.5 9.275 13.725 9.8375 12.5 10.1812Z'
        fill={color}
      />
    </svg>
  );
};

type CoinsIconType = {
  color?: string;
  onClick?: () => void;
  width?: string | number;
  height?: string | number;
  className?: string;
};
