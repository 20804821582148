import './BoldTextIcon.scss';

import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const BoldTextIcon: FC<BoldTextIconType> = ({
  width = '20px',
  height = '20px',
  className = 'bold-text-icon',
  color = '#FFFFFF',
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M9.34375 7.38281H8.25781V12H5.96094V7.38281H4.83594L3.04688 12H0.1875L3.05469 5.76562L0.3125 0.625H3.17188L5.02344 5.19531H5.96094V0.625H8.25781V5.19531H9.16406L10.9531 0.625H13.8047L11.125 5.74219L13.9297 12H11.0703L9.34375 7.38281Z'
        fill={color}
      />
    </svg>
  );
};

type BoldTextIconType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
>;
