import PropTypes from 'prop-types';
import React, { createContext, FC, useContext, useState } from 'react';

const MenuContext = createContext<any>('');
const useMenuContext = () => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error('useMenuContext must be used within a MenuProvider');
  }
  return context;
};

const MenuProvider: FC = ({ children }) => {
  let isToggleMenu = true;

  if (localStorage.getItem('menu')) {
    isToggleMenu = JSON.parse(localStorage.getItem('menu') || '');
  }

  const [isOpenMenu, setIsOpenMenu] = useState(isToggleMenu);

  const [descriptionState, setDescriptionState] = useState(false);

  const handleToggleMenu = () => {
    localStorage.setItem('menu', String(!isOpenMenu));
    setIsOpenMenu(!isOpenMenu);
  };

  return (
    <MenuContext.Provider
      value={{
        isOpenMenu,
        descriptionState,
        handleToggleMenu,
        setDescriptionState,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

MenuProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { MenuProvider, useMenuContext };
