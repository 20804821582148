import React, { useState } from 'react';

import { useThreadBackgroundsQuery } from '../../../../graphql/generated/graphql';
import { getUserAvatar } from '../../../../utils';
import { Button } from '../../../button/Button';
import { LoadImage } from '../../../treadItem/TreadItemHeader/LoadImage';
import { ItemColor } from '../tredFonsModal/TredFonsModal';

export const TreadSelectFonModal = ({ onClose, modalParameters }: any) => {
  const { data } = useThreadBackgroundsQuery({
    fetchPolicy: 'network-only',
  });
  const colors = data?.threadBackgrounds;

  const [selectColor, setSelectColor] = useState<any>(
    modalParameters?.initialValues?.threadBackground?.id,
  );
  const [selectImage, setSelectImage] = useState<any>(
    modalParameters?.initialValues?.cover,
  );

  const handleSelectColor = (value: any) => {
    setSelectImage(null);
    setSelectColor(value);
  };

  const handleSelectImage = (value: any) => {
    setSelectImage(value);
    setSelectColor(null);
  };

  return (
    <div className='TredFonsModal'>
      <div className='TredFonsModal__tite1'>Настройка фона</div>
      <div className='TredFonsModal__tite2'>
        <div>Цвет фона</div>
      </div>
      <div className='TredFonsModal__list'>
        {colors?.map((e) => {
          return (
            <ItemColor
              color={e.backgroundColor}
              key={e.id}
              select={selectColor === e.id}
              setSelectColor={handleSelectColor}
              id={e.id}
            />
          );
        })}
      </div>{' '}
      <LoadImage handleSelectImage={handleSelectImage} threadId={1 as any} />
      {selectImage && (
        <div
          className='treadsCard__header'
          style={{
            backgroundImage: selectImage ? `url(${getUserAvatar(selectImage)})` : '',
            marginBottom: '20px',
          }}
        />
      )}
      <Button
        type='submit'
        text='Сохранить'
        width='100%'
        height='25px'
        backgroundColor='#149939'
        color='white'
        onClick={() => {
          modalParameters.onCallback({
            cover: selectImage,
            threadBackgroundId: selectColor,
          });
          onClose();
        }}
      />
    </div>
  );
};
