import './Users.scss';

import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

import {
  AddUser,
  RequestHandler,
  UserSearch,
  UsersList,
  UserSort,
  usersSortValue,
} from '../../components';
import { PATHS, ROLES } from '../../constants/constants';
import { useAuthContext } from '../../context';
import { useGetAllUsersQuery } from '../../hooks/useQueries';

const usersSortOptions = [
  {
    value: { by: 'id', type: 'asc' },
    label: (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p>Идентификатору</p>↑
      </div>
    ),
  },
  {
    value: { by: 'id', type: 'desc' },
    label: (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p>Идентификатору</p>↓
      </div>
    ),
  },
  {
    value: { by: 'login', type: 'asc' },
    label: (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p>Имени пользователя</p>↑
      </div>
    ),
  },
  {
    value: { by: 'login', type: 'desc' },
    label: (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p>Имени пользователя</p>↓
      </div>
    ),
  },
  {
    value: { by: 'lastConnect', type: 'asc' },
    label: (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p>Последнему входу</p>↑
      </div>
    ),
  },
  {
    value: { by: 'lastConnect', type: 'desc' },
    label: (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p>Последнему входу</p>↓
      </div>
    ),
  },
  {
    value: { by: 'editDate', type: 'asc' },
    label: (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p>Последнему изменнию</p>↑
      </div>
    ),
  },
  {
    value: { by: 'editDate', type: 'desc' },
    label: (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p>Последнему изменнию</p>↓
      </div>
    ),
  },
];

export const Users = () => {
  const [searchValue, setSearchValue] = useState<string>('');

  const [sortValue, setSortValue] = useState<usersSortValue>({} as usersSortValue);

  const { currentUserData } = useAuthContext();

  const { data, loading, error } = useGetAllUsersQuery({
    variables: {
      input: {
        sortType: sortValue.type || 'desc',
        sortBy: sortValue.by || 'id',
        search: searchValue,
      },
    },
  });

  const searchUsers = (value: string) => {
    setSearchValue(value);
  };

  const selectChange = (value: usersSortValue) => {
    setSortValue(value);
  };

  if (currentUserData?.role === ROLES.GUEST || currentUserData?.role === ROLES.USER)
    return <Navigate replace to={PATHS.calendar} />;

  return (
    <div className='container'>
      <div className='users'>
        <div className='users__body'>
          <div className='users__header'>
            <AddUser />
            <UserSearch handleSearch={searchUsers} />
            <p className='users__info-text'>
              Всего пользователей <span>найдено: {data?.getAllUsers?.count}</span>
            </p>
            <UserSort onChangeSelect={selectChange} options={usersSortOptions} />
          </div>
          <RequestHandler loading={loading}>
            {error ? (
              <p>Что то пошло не так...</p>
            ) : (
              <UsersList users={data?.getAllUsers?.rows} loading={loading} />
            )}
          </RequestHandler>
        </div>
      </div>
    </div>
  );
};
