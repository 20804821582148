import './Textarea.scss';

import React, { ClipboardEvent, FC, KeyboardEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';

import { clip } from '../../assets/icons';
import { useLoadFileMutation } from '../../hooks/useMutations';
import { RequestHandler } from '../requestHandler/RequestHandler';

type TextareaPropsType = {
  value?: string;
  chatId?: number;
  onChange?: (value: string) => void;
  sendMessage: (value: string) => void;
};

export const Textarea: FC<TextareaPropsType> = ({ sendMessage, chatId }) => {
  const { taskId, subtaskId, privateChatId, treadId } = useParams();

  const [loadFile, { loading }] = useLoadFileMutation();

  const [value, setValue] = useState<string>('');

  const onChange = (message: string) => {
    setValue(message);
  };

  const loadFileVariables = (file: File) => {
    if (subtaskId) {
      return {
        data: { file: file, subtaskId: Number(subtaskId) },
      };
    }
    if (privateChatId) {
      return {
        data: { file: file, chatId: Number(chatId) },
      };
    }
    if (treadId) {
      return {
        data: { file: file, threadId: Number(treadId) },
      };
    }
    return {
      data: { file: file, taskId: Number(taskId) },
    };
  };

  const handlePasteFile = (e: ClipboardEvent<HTMLTextAreaElement>) => {
    if (e && e.clipboardData.files.length) {
      loadFile({
        variables: loadFileVariables(e.clipboardData.files[0]),
      });
    }
  };

  useEffect(() => {
    return () => {
      setValue('');
    };
  }, []);

  const handleUploadFile = (file: File) => {
    loadFile({
      variables: loadFileVariables(file),
    });
  };

  const onSend = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.code === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (value && chatId) {
        sendMessage(value);
        onChange && onChange('');
      }
    }
  };
  return (
    <div className='textarea'>
      <TextareaAutosize
        onKeyPress={onSend}
        placeholder='Написать сообщение'
        value={value}
        onChange={(e) => onChange(e.currentTarget.value)}
        onPaste={handlePasteFile}
      />
      <RequestHandler backgroundLoading={loading}>
        <label htmlFor='file-input'>
          <img src={clip} alt='clip' />
        </label>
        <input
          id='file-input'
          type='file'
          onChange={(e) => {
            if (e.currentTarget.files && e.currentTarget.files[0].size < 104857600) {
              handleUploadFile(e?.currentTarget?.files[0]);
            } else {
              toast.error('Вес файла не должен привышать 100мб!');
            }
          }}
        />
      </RequestHandler>
    </div>
  );
};
