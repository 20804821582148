type GenerateCircleType = {
  circleRadiusOut: number;
};

export const GenerateCircle = ({ circleRadiusOut }: GenerateCircleType) => {
  const numElements = 12; // Amount of elements
  const circleRadius = circleRadiusOut; // Circle radius (half the width and height of the circle)
  const angleIncrement = 360 / numElements; // Angle between elements

  const elements = [];

  const fillArr = [
    '#E8D4ED',
    '#FAF2A7',
    '#B3E4C1',
    '#E8D4ED',
    '#FAF2A7',
    '#D5EDD8',
    '#EDD4D8',
    '#FAF2A7',
    '#B3E4C1',
    '#EDD4D8',
    '#FAF2A7',
    '#B3E4C1',
  ];

  for (let i = 0; i < numElements; i++) {
    const angle = i * angleIncrement;
    elements.push({ angle: angle, circleRadius: circleRadius, fillArr: fillArr[i] });
  }

  return elements;
};
