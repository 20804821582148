import './ChangeDay.scss';

import React from 'react';

import { datesOfWeekType } from '../../../../types/types';
import { createArrayStartingFromMonday } from '../../../../utils';

type ChangeDayType = {
  weeks: datesOfWeekType;
  selectDayDate: string;
  setSelectDayDate: (value: string) => void;
};

export const ChangeDay = ({ weeks, selectDayDate, setSelectDayDate }: ChangeDayType) => {
  const arr = createArrayStartingFromMonday(weeks.from);
  return (
    <div className='changeDay'>
      {arr?.map((e, index: any) => {
        return (
          <div
            className={`changeDay__day ${
              e.date2 === selectDayDate && 'changeDay__select'
            }`}
            key={index}
            onClick={() => {
              setSelectDayDate(e.date2);
            }}
          >
            <div className='changeDay__day-text'>{e.name}</div>
            <div className='changeDay__day-date'>{e.date}</div>
          </div>
        );
      })}
    </div>
  );
};
