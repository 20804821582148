import './ChangeKarmaModal.scss';

import React from 'react';

import { getUserAvatar } from '../../../../utils';
import { Button } from '../../../button/Button';

export const ChangeKarmaModal = ({ onClose, modalParameters }: any) => {
  return (
    <div className='changeKarmaModal'>
      <div className='changeKarmaModal__title'>Изменение кармы</div>
      <div className='changeKarmaModal__user'>
        <div className='changeKarmaModal__avatar-wrapper'>
          <img
            className='changeKarmaModal__avatar'
            src={getUserAvatar(modalParameters?.initialValues?.avatar)}
          />
          <div className='changeKarmaModal__name'>
            {modalParameters?.initialValues?.login}
          </div>
        </div>

        <div
          className={`changeKarmaModal__count ${
            modalParameters?.initialValues?.status
              ? 'changeKarmaModal__count-positive'
              : 'changeKarmaModal__count-negative'
          }`}
        >
          {modalParameters?.initialValues ? '+' : '-'}1
        </div>
      </div>
      <div className='changeKarmaModal__buttons'>
        <Button
          width={'130px'}
          height={'25px'}
          margin={'0px 20px 0px 0px'}
          text='Отмена'
          onClick={() => {
            onClose();
          }}
        />
        <Button
          width={'130px'}
          height={'25px'}
          text='Применить'
          backgroundColor='green'
          color='white'
          onClick={() => {
            modalParameters?.onCallback();
            onClose();
          }}
        />
      </div>
    </div>
  );
};
