import './Executors.scss';

import React, { MutableRefObject } from 'react';

import { arrowDawn } from '../../assets/icons';
import {
  MainContentHeaderItem,
  UserItem,
  WrapperUserStatisticBlock,
} from '../../components';
import { GetTaskAssigneesQuery } from '../../graphql/generated/graphql';
import { addKarma } from '../../utils';
import { ExecutorsManaging } from '../executorsManaging/ExecutorsManaging';

export type MaintainerType = {
  id?: number | null | undefined;
  login?: string | null | undefined;
  image?: string | null | undefined;
  isAnalytics?: boolean;
};

type ExecutorsPropsType = {
  maintainer?: MaintainerType | null;
  taskMaintainer?: MaintainerType | null;
  executorsRef: MutableRefObject<HTMLDivElement | null | undefined>;
  variant: string | undefined;
  handleShowExecutors: () => void;
  toggleExecutors: boolean;
  assignees: GetTaskAssigneesQuery | undefined;
};

export const Executors: React.FC<ExecutorsPropsType> = ({
  maintainer,
  taskMaintainer,
  executorsRef,
  handleShowExecutors,
  toggleExecutors,
  variant,
  assignees,
}) => {
  return (
    <div
      className='executors'
      ref={(el) => {
        if (el) {
          executorsRef.current = el;
        }
      }}
    >
      <div className='executors__header'>
        <MainContentHeaderItem
          fullWidth
          title='Исполнители'
          style={{ fontWeight: 'normal' }}
        />
      </div>
      <div
        className='executors__content'
        style={variant === 'subtask' ? { backgroundColor: '#E4EFFF' } : {}}
      >
        <div className='executors__title' role='button' onClick={handleShowExecutors}>
          <span>В работе</span>
          <img
            src={arrowDawn}
            alt='arrow'
            style={toggleExecutors ? { transform: 'rotate(180deg)' } : {}}
          />
        </div>
        {toggleExecutors && (
          <div className='executors__users'>
            <div className='concealer'></div>
            {maintainer && (
              <div className='executors__maintainer'>
                <span className='show__modal'>
                  <UserItem
                    name={addKarma(maintainer as any)}
                    avatar={maintainer?.image}
                    id={maintainer?.id as number}
                  />
                  <WrapperUserStatisticBlock
                    id={maintainer?.id}
                    isAnalytics={maintainer?.isAnalytics as boolean}
                  />
                </span>
              </div>
            )}
            {taskMaintainer && taskMaintainer.id !== maintainer?.id ? (
              <div className='list__item'>
                <span className='show__modal'>
                  <UserItem
                    avatar={taskMaintainer?.image}
                    name={addKarma(taskMaintainer as any)}
                    style={{
                      borderLeft: 'none',
                      paddingLeft: '0',
                    }}
                    id={taskMaintainer?.id as number}
                  />

                  <WrapperUserStatisticBlock
                    id={taskMaintainer?.id}
                    isAnalytics={taskMaintainer?.isAnalytics as boolean}
                  />
                </span>
              </div>
            ) : null}
            {assignees?.getTask?.assignees
              ?.filter((assigner) => assigner?.id !== maintainer?.id)
              .filter((user) => user?.id !== taskMaintainer?.id)
              .map((user) => {
                if (user) {
                  return (
                    <span className='show__modal' key={user.id}>
                      <UserItem
                        name={addKarma(user as any)}
                        avatar={user.image}
                        id={user?.id as number}
                      />

                      <WrapperUserStatisticBlock
                        id={user?.id}
                        isAnalytics={user?.isAnalytics}
                      />
                    </span>
                  );
                }
              })}
            {!assignees?.getTask?.assignees?.length && !maintainer && (
              <p>Исполнители отсутствуют</p>
            )}
          </div>
        )}
        <div className='executors__managing'>
          <ExecutorsManaging
            title='Управление исполнителями'
            assignees={assignees?.getTask?.assignees}
            maintainer={maintainer}
            taskMaintainer={taskMaintainer}
          />
        </div>
      </div>
    </div>
  );
};
