import React from 'react';

export const HeaderForumIcons = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.66669 14.1666V2.49996C1.66669 2.26385 1.74669 2.06579 1.90669 1.90579C2.06613 1.74635 2.26391 1.66663 2.50002 1.66663H13.3334C13.5695 1.66663 13.7672 1.74635 13.9267 1.90579C14.0867 2.06579 14.1667 2.26385 14.1667 2.49996V9.99996C14.1667 10.2361 14.0867 10.4338 13.9267 10.5933C13.7672 10.7533 13.5695 10.8333 13.3334 10.8333H5.00002L1.66669 14.1666ZM5.83335 15C5.59724 15 5.39919 14.92 5.23919 14.76C5.07974 14.6005 5.00002 14.4027 5.00002 14.1666V12.5H15.8334V4.99996H17.5C17.7361 4.99996 17.9339 5.07968 18.0934 5.23913C18.2534 5.39913 18.3334 5.59718 18.3334 5.83329V18.3333L15 15H5.83335Z'
        fill='white'
      />
    </svg>
  );
};
