import './SearchInputHeader.scss';

import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Clock from 'react-live-clock';
import { Link, useLocation } from 'react-router-dom';

import { useSearchQuery } from '../../graphql/generated/graphql';
import { useClickOutside } from '../../hooks';
import { useDebounce } from '../../hooks/';
import { textSlice } from '../../utils';
import { FileSearchIcon, ForumSearchIcons, SearchHeaderIcon } from '../icons';

export const SearchInputHeader = () => {
  const [value, setValue] = useState('');
  const [showInput, setShowInput] = useState(true);
  const { debouncedValue } = useDebounce(value);

  const ref = useRef<any>();
  const location = useLocation();

  useEffect(() => {
    setShowInput(true);
  }, [location.pathname]);

  useClickOutside(ref, () => {
    setShowInput(true);
    setValue('');
  });

  const { data } = useSearchQuery({
    variables: {
      search: debouncedValue,
    },
    skip: value.length === 0,
  });

  const search = data?.search;

  const handleChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const notFound =
    search?.storageEntities?.length === 0 &&
    search?.forum?.length === 0 &&
    search?.threads?.length === 0;

  return (
    <div
      className='searchInputHeader__wrapper'
      style={{ width: showInput ? '50%' : '70%' }}
      ref={ref}
    >
      {showInput ? (
        <>
          <SearchHeaderIcon
            onClick={() => {
              setShowInput(!showInput);
            }}
            color='white'
          />
          <Clock format='HH:mm' ticking timezone='Europe/Kiev' />
        </>
      ) : (
        <div className='searchInputHeader'>
          <label>
            <SearchHeaderIcon
              onClick={() => {
                setShowInput(!showInput);
              }}
              className='searchInputHeader__icon'
            />
            <input
              className='searchInputHeader__input'
              type='text'
              value={value}
              onChange={handleChangeSearchValue}
              placeholder={''}
              autoFocus
            />
          </label>
        </div>
      )}
      {!showInput && value && (
        <div className='searchInputHeader__list'>
          {notFound && (
            <div
              className='searchInputHeader__title'
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              По данному запросу ничего не найдено
            </div>
          )}
          {search && search?.threads?.length > 0 && (
            <div>
              <div className='searchInputHeader__title'>Треды</div>
              <div>
                {search?.threads?.map((e) => {
                  return (
                    <Link
                      key={e?.id + 'tread' + e?.scaleMapTask?.name}
                      className='searchInputHeader__item'
                      to={`/tread/${e?.id}`}
                    >
                      <ForumSearchIcons className='searchInputHeader__svg' />
                      <Text text={e?.scaleMapTask?.name} searchValue={value} />
                    </Link>
                  );
                })}
              </div>
            </div>
          )}{' '}
          {search && search?.storageEntities?.length > 0 && (
            <div>
              <div className='searchInputHeader__title'>Файлы</div>
              <div>
                {search?.storageEntities?.map((e) => {
                  if (e?.__typename === 'File') {
                    const createLink = () => {
                      if (e?.threadId) {
                        return `/storage?fileName=${e?.fileName}`;
                      } else {
                        return `/storageProject?fileName=${e?.fileName}`;
                      }
                    };

                    return (
                      <Link
                        key={e?.id + 'File'}
                        className='searchInputHeader__item'
                        to={createLink()}
                      >
                        <FileSearchIcon className='searchInputHeader__svg' />
                        <Text text={e?.fileName} searchValue={value} />
                      </Link>
                    );
                  }

                  if (e?.__typename === 'StorageItem') {
                    const createLink = () => {
                      if (e?.taskId || e?.subtaskId) {
                        return `/storageProject?fileName=${e?.name}`;
                      } else {
                        return `/storage?fileName=${e?.name}`;
                      }
                    };
                    return (
                      <Link
                        key={e?.id + 'StorageItem'}
                        className='searchInputHeader__item'
                        to={createLink()}
                      >
                        <FileSearchIcon className='searchInputHeader__svg' />
                        <Text text={e?.name} searchValue={value} />
                      </Link>
                    );
                  }
                  return <></>;
                })}
              </div>
            </div>
          )}
          {search && search?.forum?.length > 0 && (
            <div>
              <div className='searchInputHeader__title'>Форум</div>
              <div>
                {search?.forum?.map((e) => {
                  const createLink = () => {
                    if (e?.__typename === 'ForumThread') {
                      return `/forum/section/${e?.forumSectionId}/tread/${e.id}/page/1`;
                    }
                    return `/forum/${e?.id}`;
                  };

                  return (
                    <Link
                      key={e?.id + 'forum' + e?.name}
                      className='searchInputHeader__item'
                      to={createLink()}
                    >
                      <ForumSearchIcons className='searchInputHeader__svg' />
                      <Text text={e?.name} searchValue={value} />
                    </Link>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const Text = ({ searchValue, text }: any) => {
  const texsSlice = textSlice({ text: text, count: 90 });

  const regex = new RegExp(searchValue, 'gi');
  const highlightedText = texsSlice.replace(
    regex,
    (match: any) => `<span class='searchInputHeader__selectedText'>${match}</span>`,
  );

  return <div dangerouslySetInnerHTML={{ __html: highlightedText }}></div>;
};
