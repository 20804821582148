import './AvatarLoad.scss';

import React, { useRef } from 'react';

import empty from '../../assets/empty.jpg';
import { getUserAvatar } from '../../utils';
import { TreadsDeleteICons, TreadsEditICons } from '../icons';

type AvatarLoadType = {
  setFieldValue: any;
  avatar: any;
  errors: any;
};

export const AvatarLoad = ({ setFieldValue, avatar, errors }: AvatarLoadType) => {
  let objectURL = '';

  const ref = useRef(null);

  if (typeof avatar !== 'string') {
    objectURL = avatar && URL.createObjectURL(avatar);
  } else {
    objectURL = getUserAvatar(avatar);
  }
  return (
    <div>
      <div style={{ position: 'relative' }}>
        <label ref={ref}>
          <p className='AvatarLoad__avatar-label'>Изменить аватарку темы</p>
          <div className='AvatarLoad__avatar-wrapperOuter'>
            <div className='AvatarLoad__avatar-wrapper'>
              <img
                className={`AvatarLoad__avatar `}
                src={objectURL ? objectURL : empty}
              />
            </div>
            <div className='AvatarLoad__errors'>
              {errors?.avatar && 'Используйте jpeg,png,svg'}
            </div>
          </div>
          <input
            name='file'
            type='file'
            onChange={(e) => {
              setFieldValue('avatar', e?.currentTarget?.files?.[0]);
            }}
            className='add-polygon-block-modal__file'
          />
        </label>
        <div
          className='AvatarLoad__avatar-buttons'
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <TreadsEditICons
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              ref?.current?.click();
            }}
          />
          <TreadsDeleteICons
            color={'red'}
            className={'ml20'}
            onClick={() => {
              setFieldValue('avatar', false);
            }}
          />
        </div>
      </div>
    </div>
  );
};
