import './FileGraphics.scss';

import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { ScaleMapTaskHistoryDate } from '../../graphql/generated/graphql';
import { CustomTooltip } from './CustomTooltip/CustomTooltip';
import { AllTaskType } from './fileTypes';

type FileGraphicsType = {
  historyData: Array<ScaleMapTaskHistoryDate | undefined> | undefined | any;
  newYear?: number | null | undefined;
  newMonth?: number | null | undefined;
};

export const FileGraphics = ({ historyData, newYear, newMonth }: FileGraphicsType) => {
  const [hoverValue, setHoverValue] = useState('');

  const location = useLocation();
  const isGraphicsI = location.pathname.includes('graphicsI');

  const fixNullableMonth = () => {
    if (newMonth) return newMonth;
    if (newYear) return 1;
    return newMonth;
  };

  const modifiedHistoryData = useMemo(() => {
    return transformArray(historyData, isGraphicsI);
  }, [historyData, isGraphicsI]);

  const dataInMonth = useMemo(() => {
    return createDataGraphics({
      modifiedHistoryData,
      newYear,
      newMonth: fixNullableMonth(),
    });
  }, [modifiedHistoryData]);

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <BarChart
        width={window.innerWidth - 360}
        height={600}
        data={dataInMonth}
        stackOffset='sign'
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        style={{
          backgroundColor: '#303030',
        }}
      >
        <CartesianGrid strokeDasharray='3 3' stroke='white' />

        <XAxis dataKey='name' stroke='white' />
        <YAxis stroke='white' colorInterpolation={'red'} />
        <Tooltip
          content={
            <CustomTooltip
              hoverValue={hoverValue}
              taskList={taskListCreate({ historyData, isGraphicsI })}
              isGraphicsI={isGraphicsI}
            />
          }
        />

        <Legend
          payload={
            !isGraphicsI
              ? [
                  { value: 'Completed', color: '#B3E4C1' },
                  { value: 'In Progress', color: '#dad40e' },
                  { value: 'Not In Progress', color: '#cdc3c3' },
                  { value: 'Problem', color: '#E80025' },
                  { value: 'Project i Create', color: '#8B00FF' },
                  { value: 'Project i Update', color: '#8B00FF' },
                ]
              : [
                  { value: 'Completed', color: '#B3E4C1' },
                  { value: 'In Progress', color: '#dad40e' },
                  { value: 'Not In Progress', color: '#cdc3c3' },
                  { value: 'Problem', color: '#E80025' },
                ]
          }
        />
        <ReferenceLine y={0} stroke='#fff' strokeWidth='3' />
        <Bar
          dataKey={HoverType.Completed_Create}
          stackId='stack'
          fill='#B3E4C1'
          onMouseEnter={() => {
            setHoverValue(HoverType.Completed_Create);
          }}
        />
        <Bar
          dataKey={HoverType.Completed_Update}
          stackId='stack'
          fill='#B3E4C1'
          onMouseEnter={() => {
            setHoverValue(HoverType.Completed_Update);
          }}
        />

        <Bar
          dataKey={HoverType.In_Progress_Create}
          stackId='stack'
          fill='#dad40e'
          onMouseEnter={() => {
            setHoverValue(HoverType.In_Progress_Create);
          }}
        />
        <Bar
          dataKey={HoverType.In_Progress_Update}
          stackId='stack'
          fill='#dad40e'
          onMouseEnter={() => {
            setHoverValue(HoverType.In_Progress_Update);
          }}
        />

        <Bar
          dataKey={HoverType.Not_In_Progress_Create}
          stackId='stack'
          fill='#cdc3c3'
          onMouseEnter={() => {
            setHoverValue(HoverType.Not_In_Progress_Create);
          }}
        />
        <Bar
          dataKey={HoverType.Not_In_Progress_Update}
          stackId='stack'
          onMouseEnter={() => {
            setHoverValue(HoverType.Not_In_Progress_Update);
          }}
          fill='#cdc3c3'
        />

        <Bar
          dataKey={HoverType.Problem_Create}
          stackId='stack'
          fill='#E80025'
          onMouseEnter={() => {
            setHoverValue(HoverType.Problem_Create);
          }}
        />
        <Bar
          dataKey={HoverType.Problem_Update}
          stackId='stack'
          fill='#E80025'
          onMouseEnter={() => {
            setHoverValue(HoverType.Problem_Update);
          }}
        />
        <Bar
          dataKey={HoverType.Project_I_Update}
          stackId='stack'
          fill='#8B00FF'
          onMouseEnter={() => {
            setHoverValue(HoverType.Project_I_Update);
          }}
        />
        <Bar
          dataKey={HoverType.Project_I_Create}
          stackId='stack'
          fill='#8B00FF'
          onMouseEnter={() => {
            setHoverValue(HoverType.Project_I_Create);
          }}
        />
      </BarChart>
    </div>
  );
};

const transformArray = (historyData: any, isGraphicsI: any) => {
  return historyData?.map((scaleMapTaskHistoryDate: any) => {
    return {
      ...scaleMapTaskHistoryDate,
      historyTypes: scaleMapTaskHistoryDate?.historyTypes?.map((taskCreated: any) => {
        if (isGraphicsI) return taskCreated;
        return {
          ...taskCreated,
          items: taskCreated?.items?.map((item: any) => {
            const scaleMapTasks: any = [];
            const scaleMapTasksI: any = [];

            item?.scaleMapTasks?.map((file: any) => {
              if (file?.scaleMapFile?.user?.id) {
                scaleMapTasksI.push(file);
              } else {
                scaleMapTasks.push(file);
              }
            });
            return {
              ...item,
              scaleMapTasks: scaleMapTasks,
              count: scaleMapTasks?.length,
              scaleMapTasksI: scaleMapTasksI,
              countI: scaleMapTasksI?.length,
            };
          }),
        };
      }),
    };
  });
};

const taskListCreate = ({
  historyData,
  isGraphicsI,
}: {
  historyData: (ScaleMapTaskHistoryDate | undefined)[] | undefined;
  isGraphicsI: boolean;
}) => {
  const allTask: AllTaskType = [];
  historyData?.map((e) => {
    const date = e?.date;
    e?.historyTypes?.map((e) => {
      const typeFul = e?.type === 'TaskCreated' ? 'Create' : 'Update';
      const type = e?.type;
      e?.items?.map((item) => {
        const statusFull = item?.status?.name;
        const status = item?.status;
        item?.scaleMapTasks?.map((e) => {
          allTask.push({
            ...e,
            scaleMapFileId: e?.scaleMapFile?.id,
            scaleMapFileName: e?.scaleMapFile?.name,
            projectId: e?.scaleMapFile?.project?.id,
            projectName: e?.scaleMapFile?.project?.name,
            statusFull:
              e?.scaleMapFile?.user?.id && !isGraphicsI
                ? `Project i ${typeFul}`
                : `${statusFull} ${typeFul}`,
            status: status,
            date: date,
            type: type,
          });
        });
      });
    });
  });
  return allTask;
};

enum HoverType {
  Problem_Update = 'Problem Update',
  Problem_Create = 'Problem Create',
  Not_In_Progress_Update = 'Not In Progress Update',
  Not_In_Progress_Create = 'Not In Progress Create',
  In_Progress_Update = 'In Progress Update',
  In_Progress_Create = 'In Progress Create',
  Completed_Update = 'Completed Update',
  Completed_Create = 'Completed Create',
  Project_I_Update = 'Project i Update',
  Project_I_Create = 'Project i Create',
  NONE = '',
}

const createDataGraphics = ({ modifiedHistoryData, newYear, newMonth }: any) => {
  const now = new Date();
  const month = newMonth ? newMonth : now.getMonth() + 1;
  const year = newYear ? newYear : now.getFullYear();
  function daysInThisMonth() {
    return new Date(year, month, 0).getDate();
  }

  let dataInMonth = new Array(daysInThisMonth()).fill('');

  dataInMonth = dataInMonth?.map((e, index) => {
    return {
      name:
        e +
        `${index + 1 <= 9 ? '0' : ''}${index + 1}-${
          month <= 9 ? '0' : ''
        }${month}-${year}`,
    };
  });

  const data1 = dataInMonth?.map((day) => {
    const data: {
      name: string;
      [key: string]: string | undefined;
    } = {
      name: day.name,
    };

    modifiedHistoryData?.map((taskHistoryDate: any) => {
      if (taskHistoryDate?.date === day.name) {
        data.name = day?.name;
        taskHistoryDate?.historyTypes?.map((taskHistoryType: any) => {
          if (taskHistoryType.type === 'TaskCreated') {
            taskHistoryType?.items?.map((historyStatItem: any) => {
              if (historyStatItem?.count > 0) {
                data[
                  `${historyStatItem?.status?.name} Create`
                ] = `-${historyStatItem?.count}`;
              }
              if (historyStatItem?.countI > 0) {
                data[
                  `Project i Create ${historyStatItem?.status?.name} `
                ] = `-${historyStatItem?.countI}`;
              }
            });
          }
          if (taskHistoryType.type === 'TaskUpdated') {
            taskHistoryType?.items?.map((historyStatItem: any) => {
              if (historyStatItem?.count > 0) {
                data[
                  `${historyStatItem?.status?.name} Update`
                ] = `${historyStatItem?.count}`;
              }
              if (historyStatItem?.countI > 0) {
                data[
                  `Project i Update ${historyStatItem?.status?.name} `
                ] = `${historyStatItem?.countI}`;
              }
            });
          }
        });
      }
    });
    return data;
  });
  const data2 = data1?.map((e) => {
    const transformedData = Object.entries(e).reduce((acc: any, [key, value]) => {
      if (key.includes('Project i Create')) {
        acc['Project i Create'] = (acc['Project i Create'] || 0) + parseInt(value as any);
      } else if (key.includes('Project i Update')) {
        acc['Project i Update'] = (acc['Project i Update'] || 0) + parseInt(value as any);
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});
    return transformedData;
  });

  return data2;
};
