import './PolygonDescription.scss';

import React, { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useMenuContext } from '../../context';
import { PoligonObject as PolygonObjectType } from '../../graphql/generated/graphql';
import { useGetTasksQuery } from '../../hooks/useQueries';
import { HoverRectIdType } from '../../types/enums';
import { RectType } from '../../types/types';
import { addKarma, truncate } from '../../utils';
import { ArrowLeftIcon, DownloadIcon, GoToMyTasksIcon, InfoIcon } from '../icons';
import { ProjectBlockItem } from '../projectBlock/project-block-item/ProjectBlockItem';

type PolygonDescriptionType = {
  rectangles: RectType[];
  selectedBlockId?: number | string | null;
  descriptionMenuOpenHandler?: (value: boolean) => void;
  projectName?: string;
  polygonObject?: PolygonObjectType;
  hoverRectId: { id: string | number; type: HoverRectIdType };
  setHoverRectId: (value: { id: string | number; type: HoverRectIdType }) => void;
};

export const PolygonDescription: FC<PolygonDescriptionType> = ({
  rectangles,
  selectedBlockId,
  descriptionMenuOpenHandler,
  projectName,
  polygonObject,
  setHoverRectId,
  hoverRectId,
}) => {
  const { descriptionState, setDescriptionState } = useMenuContext();

  const [isActiveTab, setIsActiveTab] = useState<'description' | 'tasks'>('description');

  const { pathname } = useLocation();

  const { projectId } = useParams();

  const { data } = useGetTasksQuery(Number(projectId));

  const displayedDescriptions = rectangles.map((item) => ({
    id: item?.id,
    title: item?.name,
    text: item?.text,
    descLink: `link-${item?.id}`,
  }));

  const selectedDesc = displayedDescriptions?.filter((el) => el.id === selectedBlockId);

  const polygonStatisticPage = pathname.toLowerCase().includes('/polygonstatistic');

  if (!descriptionState)
    return (
      <div className='description__wrapper description__wrapper_close'>
        <div
          className='description__button'
          onClick={() => {
            setDescriptionState(!descriptionState);
            descriptionMenuOpenHandler && descriptionMenuOpenHandler(!descriptionState);
          }}
        >
          <ArrowLeftIcon />
        </div>
        <InfoIcon
          color='#303030'
          className='description__icons'
          onClick={() => {
            setDescriptionState(true);
            setIsActiveTab('description');
          }}
        />
        {!polygonStatisticPage ? (
          <GoToMyTasksIcon
            width='22px'
            height='12px'
            color='#303030'
            className='description__icons'
            onClick={() => {
              setDescriptionState(true);
              setIsActiveTab('tasks');
            }}
          />
        ) : null}
      </div>
    );

  const showDescriptions = () => {
    if (selectedDesc?.length) {
      return selectedDesc;
    } else {
      if (!selectedBlockId) {
        return displayedDescriptions;
      } else {
        return [];
      }
    }
  };
  const showDownload =
    showDescriptions()?.filter((e: any) => {
      if (e.text?.length > 0) {
        return e.text;
      }
    })?.length > 0;

  return (
    <div className='description__wrapper description__wrapper_open'>
      <div className='description__header'>
        <div
          className='description__header-arrow'
          onClick={() => {
            setDescriptionState(!descriptionState);
            descriptionMenuOpenHandler && descriptionMenuOpenHandler(!descriptionState);
          }}
        >
          <ArrowLeftIcon className='description__header-arrow_right' color='#FFFFFF' />
        </div>
        <div className='description__header-tabs'>
          <div
            className={`description__header-tab${
              isActiveTab === 'description' ? '_active' : ''
            }`}
            style={
              polygonStatisticPage && isActiveTab === 'description'
                ? { width: '100%', borderBottom: 'none', justifyContent: 'center' }
                : {}
            }
            onClick={() => setIsActiveTab('description')}
          >
            <div
              style={
                polygonStatisticPage && isActiveTab === 'description'
                  ? {
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }
                  : { display: 'flex', alignItems: 'center' }
              }
            >
              <InfoIcon color='#AFAFAF' className='description__instruments-icons' />
              <span className='description__header-tab-text'>Описание</span>
            </div>
            <div
              className='description__header-element'
              style={
                polygonStatisticPage && isActiveTab === 'description'
                  ? { marginRight: '26px' }
                  : {}
              }
              onClick={() => {
                const newArr = [''];
                showDescriptions()?.map((e) => {
                  newArr.push(`\n ${e.title} \n`);
                  newArr.push(e.text);
                });
                downloadTxtFile({
                  text: newArr,
                  name: projectName,
                });
              }}
            >
              {showDownload && <DownloadIcon />}
            </div>
          </div>

          {!polygonStatisticPage ? (
            <div
              className={`description__header-tab${
                isActiveTab === 'tasks' ? '_active' : ''
              }`}
              onClick={() => setIsActiveTab('tasks')}
            >
              <GoToMyTasksIcon />
              <span className='description__header-tab-text'>Задачи</span>
            </div>
          ) : null}
        </div>
      </div>
      {!showDownload && isActiveTab === 'description' && (
        <div>Описание пока отсутствует</div>
      )}
      {isActiveTab === 'description' &&
        showDescriptions()?.map((e) => {
          if (e.text.length === 0) return;
          return (
            <DescriptionElements
              key={e.id}
              name={e.title}
              link={'test'}
              content={e.text}
              setHoverRectId={setHoverRectId}
              hoverRectId={hoverRectId}
              id={e.id}
            />
          );
        })}
      {isActiveTab === 'tasks' && polygonObject?.tasksSubtasks?.rows?.length ? (
        <div className='description__wrapper-tasks'>
          {polygonObject?.tasksSubtasks?.rows.map((taskSubtask) => {
            if (taskSubtask) {
              const rectIdList = rectangles
                .map((e: RectType) => {
                  return { taskId: e?.task?.id, rectId: e.id };
                })
                .filter((e) => !!e);
              const selectRect = rectIdList?.find((e) => e.taskId === taskSubtask.id);
              if (!taskSubtask.parentId) {
                return (
                  <ProjectBlockItem
                    key={taskSubtask.id}
                    taskId={taskSubtask.id}
                    title={`#${taskSubtask?.project?.number}.${taskSubtask?.number} ${taskSubtask?.name}`}
                    userAvatar={taskSubtask?.maintainer?.image}
                    name={addKarma(taskSubtask?.maintainer)}
                    projectStatus={taskSubtask?.status?.id}
                    endDate={taskSubtask?.endDate}
                    tags={taskSubtask?.tags.rows}
                    projectId={taskSubtask?.project?.id}
                    setHoverRectId={setHoverRectId}
                    hoverRectId={hoverRectId}
                    connectRectId={selectRect?.rectId}
                    isSubtask={false}
                  />
                );
              } else {
                const foundedTask = data?.getTasks.rows.find(
                  (task) => task?.id === taskSubtask.parentId,
                );

                const foundedSubtask = data?.getTasks?.rows
                  ?.find((task) => task?.id === taskSubtask?.parentId)
                  ?.subtasks?.find((subtask) => subtask?.id === taskSubtask?.id);
                return (
                  <div
                    key={taskSubtask.id}
                    style={{
                      border:
                        hoverRectId &&
                        hoverRectId.id === selectRect?.rectId &&
                        hoverRectId.type === HoverRectIdType.RECT
                          ? '1px solid #9747FF'
                          : '1px solid white',
                    }}
                    onMouseEnter={() => {
                      selectRect?.rectId &&
                        setHoverRectId &&
                        setHoverRectId({
                          id: selectRect?.rectId as any,
                          type: HoverRectIdType.DESCRIPTION,
                        });
                    }}
                    onMouseLeave={() => {
                      selectRect?.rectId &&
                        setHoverRectId &&
                        setHoverRectId({ id: 0, type: HoverRectIdType.NONE });
                    }}
                    id={
                      selectRect?.rectId
                        ? `description-${selectRect?.rectId} `
                        : `${foundedTask?.id}`
                    }
                  >
                    <ProjectBlockItem
                      taskId={foundedTask?.id}
                      title={`#${taskSubtask?.project?.number}.${foundedTask?.number}.${
                        foundedSubtask?.number || taskSubtask?.number
                      } ${taskSubtask?.name}`}
                      userAvatar={taskSubtask?.maintainer?.image}
                      name={addKarma(taskSubtask?.maintainer)}
                      projectStatus={taskSubtask?.status?.id}
                      endDate={taskSubtask?.endDate}
                      tags={taskSubtask?.tags.rows}
                      projectId={taskSubtask?.project?.id}
                      setHoverRectId={setHoverRectId}
                      hoverRectId={hoverRectId}
                      connectRectId={selectRect?.rectId}
                      isSubtask={true}
                      subtaskId={taskSubtask.id}
                    />
                  </div>
                );
              }
            }
          })}
        </div>
      ) : null}
      {isActiveTab === 'tasks' && !polygonObject?.tasksSubtasks?.rows?.length && (
        <div>Задачи пока отсутствуют</div>
      )}
    </div>
  );
};

type DescriptionElementsType = {
  id?: string | number | null;
  name: string;
  link: string;
  content: string;
  setHoverRectId: any;
  hoverRectId: any;
};

const DescriptionElements = ({
  name,
  content,
  setHoverRectId,
  hoverRectId,
  id,
}: DescriptionElementsType) => {
  useEffect(() => {
    if (hoverRectId.id === id && hoverRectId.type === HoverRectIdType.RECT) {
      document.getElementById(`description-${id}`)?.scrollIntoView();
    }
  }, [hoverRectId.id]);

  return (
    <div
      className='elements__wrapper'
      onMouseEnter={() => {
        setHoverRectId({ id: id, type: HoverRectIdType.DESCRIPTION });
      }}
      onMouseLeave={() => {
        setHoverRectId({ id: 0, type: HoverRectIdType.NONE });
      }}
      style={
        hoverRectId.id === id && hoverRectId.type === HoverRectIdType.RECT
          ? {
              border: '1px solid #9747ff',
              backgroundColor: '#F5F5F5',
            }
          : {
              border: '1px solid white',
              backgroundColor: 'white',
            }
      }
      id={`description-${id}`}
    >
      <div className='desc__container'>
        <div className='elements__header'>
          <div className='elements__name'>{truncate({ str: name?.trim(), n: 40 })}</div>
          <div className='elements__button-wrapper'>
            <div
              className='elements__button'
              onClick={() => downloadTxtFile({ text: [content], name })}
            >
              <DownloadIcon color='#FFFFFF' />
            </div>
          </div>
        </div>
        <div
          className='elements__content'
          dangerouslySetInnerHTML={{ __html: content.replace('\n', '<br/>') }}
        ></div>
      </div>
    </div>
  );
};

type downloadTxtFileType = {
  text: Array<string>;
  name?: string | number | null;
};

const downloadTxtFile = ({ text, name }: downloadTxtFileType) => {
  const element = document.createElement('a');
  const file = new Blob(text, {
    type: 'text/plain',
  });
  element.href = URL.createObjectURL(file);
  element.download = `${name}.txt`;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};
