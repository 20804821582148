import './TredItemPerformers.scss';

import React from 'react';

import { useModalContext } from '../../../context';
import { ModalStatuses } from '../../../types/enums';
import { addKarma, getUserAvatar } from '../../../utils';
import { Button } from '../../button/Button';
import { StarNoSelectIcons, StarSelectIcons } from '../../icons';

type TredItemPerformersType = {
  performers?:
    | {
        id: number | null | undefined;
        login: string | null | undefined;
        image: string | null | undefined;
        status: string | null | undefined;
        threads: {
          __typename?: 'GetThreadsAndCount' | undefined;
          rows: {
            __typename?: 'Thread' | undefined;
            id: number;
            performerStatus?: string | null | undefined;
          }[];
        };
      }[]
    | undefined;
  id: number;
  updateThreadMutation: any;
  mainPerformerId?: number | null | undefined;
};

export const TredItemPerformers = ({
  performers,
  updateThreadMutation,
  id,
  mainPerformerId,
}: TredItemPerformersType) => {
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  return (
    <div className='TredItemPerformers'>
      <div className='TredItemPerformers__header'>
        <svg
          width='1'
          height='15'
          viewBox='0 0 1 15'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className='pr10'
        >
          <path fillRule='evenodd' clipRule='evenodd' d='M0 0H1V15H0V0Z' fill='#AFAFAF' />
        </svg>
        Исполнители
      </div>
      <div className='TredItemPerformers__body'>
        <div className='TredItemPerformers__body-items'>
          <div className='TredItemPerformers__body-items__inner'>
            {performers?.length === 0 && (
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                Нет исполнителей
              </div>
            )}
            {performers
              ?.sort((a, b) => {
                if (a.status === '1' && b.status === '0') {
                  return -1;
                } else if (a.status === '0' && b.status === '1') {
                  return 1;
                } else {
                  return 0;
                }
              })
              ?.map((e) => {
                const showWhite = e.status === '1';
                return (
                  <div
                    key={e?.id}
                    className='TredItemPerformers__body-item'
                    style={{
                      backgroundColor: showWhite ? '#ffffff' : '',
                      color: showWhite ? '#303030' : '#AFAFAF',
                    }}
                  >
                    <img
                      className='TredItemPerformers__body-image'
                      src={getUserAvatar(e?.image)}
                    />
                    {addKarma(e as any)}
                    {e?.id === mainPerformerId ? (
                      <StarSelectIcons
                        onClick={() => {
                          updateThreadMutation({
                            variables: {
                              data: {
                                id: id,
                                mainPerformerId: null,
                              },
                            },
                          });
                        }}
                        className={'ml5'}
                      />
                    ) : (
                      <StarNoSelectIcons
                        className={'ml5'}
                        color={showWhite ? '#303030' : '#AFAFAF'}
                        onClick={() => {
                          updateThreadMutation({
                            variables: {
                              data: {
                                id: id,
                                mainPerformerId: e?.id,
                              },
                            },
                          });
                        }}
                      />
                    )}
                  </div>
                );
              })}
          </div>
        </div>
        <div className='TredItemPerformers__body-control'>
          <div className='TredItemPerformers__body-line'></div>
          <Button
            width={'100%'}
            onClick={() => {
              handleSetModalStatus(ModalStatuses.PERFORMERS_MODAL);
              handleChangeModalParameters([
                {
                  key: 'showAddGroup',
                  value: true,
                },
                {
                  key: 'closeModalCallback',
                  value: (e: any) => {
                    updateThreadMutation({
                      variables: {
                        data: {
                          id: id,
                          performers: e,
                        },
                      },
                    });
                  },
                },
                {
                  key: 'initialValues',
                  value: {
                    performers: performers,
                  },
                },
              ]);
            }}
          >
            Управление исполнителями
          </Button>
        </div>
      </div>
    </div>
  );
};
