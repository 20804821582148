import './DecideOnTheFlyCircle.scss';

import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

import { useModalContext } from '../../context/ModalContext';
import {
  GetRandomDecideOnFlyActionMutationFn,
  SetStatusUserDecideOnFlyActionMutationFn,
} from '../../graphql/generated/graphql';
import { ModalStatuses, TypeDecideOnTheFlyButtons } from '../../types/enums';
import { GenerateCircle } from '../../utils/generateCircle';
import { Button, Preloader } from '../';
import { Arrow } from './Arrow/Arrow';
import { ItemCircle } from './ItemCircle.tsx/ItemCircle';
import { useSelectElementHooks } from './useSelectElementHooks';

type DecideOnTheFlyCircleType = {
  typeButton: TypeDecideOnTheFlyButtons;
  getRandomDecideOnFlyActionMutation: GetRandomDecideOnFlyActionMutationFn;
  setStatusUserDecideOnFlyActionMutation: SetStatusUserDecideOnFlyActionMutationFn;
  setAnimationInAction: (value: boolean) => void;
  endDate?: string;
  refetch?: any;
  text?: string;
  animationInAction: boolean;
  createdAt: string | undefined;
};

export const DecideOnTheFlyCircle = ({
  typeButton,
  getRandomDecideOnFlyActionMutation,
  setStatusUserDecideOnFlyActionMutation,
  setAnimationInAction,
  refetch,
  endDate,
  text,
  animationInAction,
  createdAt,
}: DecideOnTheFlyCircleType) => {
  const [animation, setAnimation] = useState(0);

  const { refArrow, refs, selectNumber, setSelectNumber, findSelect, circleData } =
    useSelectElementHooks();

  const selectColor = '#149939';
  const date = new Date();

  const handleAnimation = () => {
    setAnimation(animation + (360 + Math.ceil(Math.random() * 441)));
    setSelectNumber(-1);
    setAnimationInAction(true);
    setTimeout(() => {
      findSelect();
      setAnimationInAction(false);
      getRandomDecideOnFlyActionMutation({
        variables: {
          timezoneOffset: date.getTimezoneOffset(),
        },
      });
    }, 3100);
  };

  const elements = GenerateCircle({ circleRadiusOut: circleData?.radius });

  return (
    <div>
      <div className='ContainerСircle'>
        <div
          style={{
            transform: `rotate(${animation}deg)`,
            transition: '3s',
          }}
        >
          <div
            className={`DecideOnTheFlyCircle`}
            style={{
              width: circleData?.widthHeight,
              height: circleData?.widthHeight,
            }}
          >
            {elements.map((item, index) => {
              return (
                <ItemCircle
                  key={index}
                  selectNumber={selectNumber}
                  selectColor={selectColor}
                  refIcon={refs[index]}
                  color={item.fillArr}
                  myNumber={index + 1}
                  text={text}
                  circleRadius={item.circleRadius}
                  angle={item.angle}
                  scale={circleData?.scale}
                />
              );
            })}
          </div>
        </div>
        <Arrow refArrow={refArrow} />
        <div
          className='DecideOnTheFlyCircle__shadow'
          style={{
            width: circleData?.widthHeight,
            height: circleData?.widthHeight,
          }}
        ></div>
      </div>
      <div className='ContainerСircle__buttons'>
        <DecideOnTheFlyButtons
          type={typeButton}
          handleAnimation={handleAnimation}
          setStatusUserDecideOnFlyActionMutation={setStatusUserDecideOnFlyActionMutation}
          refetch={refetch}
          endDate={endDate}
          animationInAction={animationInAction}
          createdAt={createdAt}
        />
      </div>
    </div>
  );
};

type DecideOnTheFlyButtonsType = {
  handleAnimation: any;
  type: TypeDecideOnTheFlyButtons;
  setStatusUserDecideOnFlyActionMutation: SetStatusUserDecideOnFlyActionMutationFn;
  endDate?: string;
  refetch: any;
  animationInAction: boolean;
  createdAt?: string;
};

const DecideOnTheFlyButtons = ({
  type,
  handleAnimation,
  setStatusUserDecideOnFlyActionMutation,
  endDate,
  refetch,
  animationInAction,
  createdAt,
}: DecideOnTheFlyButtonsType) => {
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();
  if (type === TypeDecideOnTheFlyButtons.noData) {
    return (
      <div
        className='ContainerСircle__count'
        style={{
          color: 'black',
        }}
      >
        <Preloader />
      </div>
    );
  }

  if (type === TypeDecideOnTheFlyButtons.sleep) {
    return (
      <div
        className='ContainerСircle__count'
        style={{
          color: 'black',
        }}
      >
        Иди спать
      </div>
    );
  }

  if (type === TypeDecideOnTheFlyButtons.start) {
    return (
      <Button
        width={240}
        height={30}
        color='#FFF'
        backgroundColor='#149939'
        onClick={() => {
          handleAnimation();
        }}
        disabled={animationInAction}
      >
        Старт
      </Button>
    );
  }
  if (type === TypeDecideOnTheFlyButtons.select) {
    return (
      <TimerComponentChoise
        createdAt={createdAt}
        refetch={refetch}
        setStatusUserDecideOnFlyActionMutation={setStatusUserDecideOnFlyActionMutation}
      />
    );
  }

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <TimerComponentWait endDateString={endDate} refetch={refetch} />
      <Button
        width={160}
        height={30}
        color='#FFF'
        backgroundColor='#E80025'
        margin={'0px 0px 0px 10px'}
        onClick={() => {
          handleSetModalStatus(ModalStatuses.DECIDE_ON_THE_FLY_REPORT);
          handleChangeModalParameters([
            {
              key: 'onCallback',
              value: (value: any) => {
                setStatusUserDecideOnFlyActionMutation({
                  variables: {
                    reasonForNotDone: value,
                    status: 'not_done',
                  },
                });
              },
            },
          ]);
        }}
      >
        Не могу
      </Button>
    </div>
  );
};

type TimerComponentType = {
  endDateString?: string;
  refetch: any;
};

const TimerComponentWait = ({ endDateString, refetch }: TimerComponentType) => {
  const timerIntervalRef = useRef<any>(null);
  const [timerCount, setTimerCount] = useState({ minutes: 0, seconds: 0 });
  useEffect(() => {
    const updateCountdown = () => {
      const endDate = moment(endDateString);
      const now = moment();
      const duration = moment.duration(endDate.diff(now));
      let minutes = Math.floor(duration.asMinutes());
      let seconds = Math.floor(duration.asSeconds()) % 60;

      // Ensure minutes and seconds don't go above 59
      minutes = Math.min(minutes, 59);
      seconds = Math.min(seconds, 59);

      if (minutes > 0 || seconds > 0) {
        setTimerCount({ minutes, seconds });
      } else {
        setTimeout(() => {
          refetch();
        }, 5000);
        clearInterval(timerIntervalRef.current);
      }
    };

    updateCountdown();
    timerIntervalRef.current = setInterval(updateCountdown, 1000); // Change interval to 1000 for 1 second
    return () => {
      clearInterval(timerIntervalRef.current);
    };
  }, [endDateString, setTimerCount, refetch]);

  return (
    <div className='ContainerСircle__count'>
      Крути еще:
      <span className='ContainerСircle__count-item'>
        {timerCount.minutes > 0
          ? `${timerCount.minutes} мин`
          : `${timerCount.seconds} сек`}
      </span>
    </div>
  );
};

type TimerComponentChoiseType = {
  createdAt?: string;
  refetch: any;
  setStatusUserDecideOnFlyActionMutation: any;
};

const TimerComponentChoise = ({
  createdAt,
  refetch,
  setStatusUserDecideOnFlyActionMutation,
}: TimerComponentChoiseType) => {
  const timerIntervalRef = useRef<any>(null);
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();
  useEffect(() => {
    const updateCountdown = () => {
      const endDate = moment
        .utc(parseInt(createdAt as any))
        .add({ minutes: 60, seconds: 10 });

      const now = moment();
      const duration = moment.duration(endDate.diff(now));
      let minutes = Math.floor(duration.asMinutes());
      let seconds = Math.floor(duration.asSeconds()) % 60;

      // Ensure minutes and seconds don't go above 59
      minutes = Math.min(minutes, 59);
      seconds = Math.min(seconds, 59);
      if (minutes === 0 && seconds === 0) {
        refetch();
        clearInterval(timerIntervalRef.current);
      }
    };

    updateCountdown();
    timerIntervalRef.current = setInterval(updateCountdown, 1000); // Change interval to 1000 for 1 second
    return () => {
      clearInterval(timerIntervalRef.current);
    };
  }, [createdAt, refetch]);

  return (
    <>
      <Button
        width={160}
        height={30}
        color='#FFF'
        backgroundColor='#149939'
        margin={'0px 10px 0px 0px'}
        onClick={() => {
          setStatusUserDecideOnFlyActionMutation({
            variables: {
              status: 'will_be_performed',
              reasonForNotDone: '',
            },
          });
        }}
      >
        Выполню
      </Button>
      <Button
        width={160}
        height={30}
        color='#FFF'
        backgroundColor='#E80025'
        margin={'0px'}
        onClick={() => {
          handleSetModalStatus(ModalStatuses.DECIDE_ON_THE_FLY_REPORT);
          handleChangeModalParameters([
            {
              key: 'onCallback',
              value: (value: any) => {
                setStatusUserDecideOnFlyActionMutation({
                  variables: {
                    reasonForNotDone: value,
                    status: 'not_done',
                  },
                });
              },
            },
          ]);
        }}
      >
        Не могу
      </Button>
    </>
  );
};
