import { ApolloLink, FetchResult, Observable, Operation } from '@apollo/client/core';
import { print } from 'graphql';
import { Client, ClientOptions, createClient } from 'graphql-ws';
import { toast } from 'react-toastify';

export class WebSocketLink extends ApolloLink {
  private client: Client;
  private options: ClientOptions;
  private connectionAttempts: number = 0;
  private maxConnectionAttempts: number = 10;
  private retryInterval: number = 5000;
  private retryWait: number = 2000; // Delay before reconnecting (in milliseconds)

  private isConnecting: boolean = false; // Flag indicating whether a reconnect process is in progress

  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
    this.options = options;
  }

  public request(operation: Operation): Observable<FetchResult> {
    this.client.on('connected', () => {
      init();
    });
    return new Observable((sink) => {
      const onNext = sink.next.bind(sink);
      const onError = (err: Error) => {
        if (!this.isConnecting) {
          this.reconnect(sink, operation); // Calling the reconnect function
        }

        sink.error(err);
      };
      const onComplete = sink.complete.bind(sink);

      const observer = {
        next: onNext,
        error: onError,
        complete: onComplete,
      };

      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: observer.next,
          error: observer.error,
          complete: observer.complete,
        },
      );
    });
  }

  private reconnect(sink: any, operation: Operation) {
    if (this.connectionAttempts <= this.maxConnectionAttempts) {
      if (!this.isConnecting) {
        this.isConnecting = true;
        const reconnect = localStorage.getItem('reconnect');
        if (reconnect === '') toast.error('Восстанавливаем соединение');

        setTimeout(() => {
          // Increasing the counter of connection attempts
          this.connectionAttempts++;

          // Reconnecting to the WebSocket Server
          this.client.dispose(); // We release the resources of the current client

          this.client = createClient({ ...this.options }); // Creating a new client

          // Call the request() function again
          this.request(operation).subscribe(sink);

          this.isConnecting = false;
        }, this.retryWait);
        localStorage.setItem('reconnect', 'reconnect');
      }
    }
    this.client.on('connected', () => {
      init();
    });
  }
}

const init = () => {
  const reconnect = localStorage.getItem('reconnect');
  if (reconnect === 'reconnect') {
    localStorage.setItem('reconnect', '');
    toast.error('Восстанавливаем соединение');
    // reload to reinitialize querySubscribe
    window.location.reload();
  }
};
