import './UserTred.scss';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { textSlice } from '../../../utils';
import { TredLinkIcon } from '../../icons';

export const UserTred = ({ threads }: any) => {
  const navigate = useNavigate();
  return (
    <div className='UserTred'>
      {threads?.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            width: '100%',
            marginTop: '10px',
          }}
        >
          Нет истории тредов
        </div>
      )}
      {threads?.map((e: any) => {
        return (
          <div key={e?.id} className='UserTred__item'>
            <div
              className='UserTred__status'
              style={{
                width: '10px',
                height: '10px',
                backgroundColor: e?.scaleMapTask?.status?.color,
              }}
            ></div>
            {textSlice({ text: e?.scaleMapTask?.name, count: 30 })}
            <TredLinkIcon
              className='UserTred__link'
              onClick={() => {
                navigate(`/tread/${e?.id}`);
              }}
            />
          </div>
        );
      })}
    </div>
  );
};
