import './AddUser.scss';

import { Form, Formik } from 'formik';
import React, { FC, useState } from 'react';

import { AppInput, Button, Modal, UserStatusSelect } from '../../components';
import { REGISTRATION_URL } from '../../constants/constants';
import { CreateUserInput, UserRole } from '../../graphql/generated/graphql';
import { GET_ALL_USERS, GET_PRIVATE_CHATS } from '../../graphql/queries';
import { useCreateUserMutation } from '../../hooks/useMutations';
import { getErrorText } from '../../utils';
import { createUserSchema } from '../../validation/schemas';

export const AddUser: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [link, setLink] = useState<string>('');
  const [copySuccess, setCopySuccess] = useState<boolean>(false);
  const [errorText, setErrorText] = useState('');

  const [createUser] = useCreateUserMutation();

  const handleSubmit = async (values: CreateUserInput) => {
    setErrorText('');
    setLink('');
    await createUser({
      variables: {
        data: {
          login: values.login,
          role: values.role,
        },
      },
      refetchQueries: [GET_PRIVATE_CHATS, GET_ALL_USERS],
      onCompleted: (response) => {
        setLink(
          `${REGISTRATION_URL}?registration=${response.createUser}&login=${values.login}`,
        );
      },
      onError: (error) => {
        setErrorText(getErrorText(error.message));
      },
    });
  };

  const initialValues: CreateUserInput = {
    login: '',
    role: '' as UserRole,
  };

  const onLinkClickHandler = (e: { preventDefault: () => void }, link: string) => {
    e.preventDefault();
    navigator.clipboard.writeText(link);
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 500);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
    setErrorText('');
    setLink('');
  };

  return (
    <div className='addUser'>
      <button className='addUser__btn' onClick={() => setIsModalOpen(true)}>
        <span className='addUser__btn-text'>Добавить пользователя</span>
        <svg
          className='addUser__btn-icon'
          width='12'
          height='12'
          viewBox='0 0 12 12'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M11.4665 5.46361H6.54226V0.539326C6.54226 0.243283 6.30191 0 6.00293 0C5.70396 0 5.46361 0.243283 5.46361 0.539326V5.46067H0.539326C0.395701 5.46067 0.257938 5.51637 0.15828 5.61895C0.0556913 5.72154 0 5.85638 0 6C0 6.29897 0.243283 6.53933 0.539326 6.53933H5.46067V11.4607C5.46067 11.7596 5.70396 12 6 12C6.29897 12 6.53933 11.7567 6.53933 11.4607V6.54226H11.4607C11.7596 6.54226 12 6.29897 12 6.00293C12.0059 5.70396 11.7626 5.46361 11.4665 5.46361Z' />
        </svg>
      </button>
      <Modal isOpen={isModalOpen} onClose={onCloseModal}>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={createUserSchema}
          initialValues={initialValues}
        >
          <Form className='addUser__modal'>
            <div className='addUser-form'>
              <h3 className='addUser-form__title'>Добавить пользователя</h3>
              <div className='addUser-from__fields-wrapper'>
                <UserStatusSelect />
                <AppInput label={'Логин:'} name={'login'} type={'text'} />
                <Button type={'submit'} width={'240px'} text={'Получить ссылку'} />
                {copySuccess && (
                  <div className={'addUser__notification'}>Ссылка скопирована!</div>
                )}
                <div className='addUser__response-wrap'>
                  {link && (
                    <a
                      className='addUser__link'
                      onClick={(e) => onLinkClickHandler(e, link)}
                      href={link}
                    >
                      {link}
                    </a>
                  )}
                  {errorText && <p className='addUser__error-message'>{errorText}</p>}
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </Modal>
    </div>
  );
};
