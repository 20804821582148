import './UserItem.scss';

import React, { CSSProperties } from 'react';

import { offline, online } from '../../assets/icons';
import { getUserAvatar } from '../../utils';

type UserItemPropsType = {
  avatar: string | null | undefined;
  name: string | null | undefined;
  displayOnline?: boolean;
  onlineStatus?: boolean | undefined;
  style?: CSSProperties;
  id: number;
};

export const UserItem = ({
  avatar,
  name,
  displayOnline,
  onlineStatus,
  style,
}: UserItemPropsType) => {
  return (
    <div className='user__item' style={style}>
      <div className='user__item--image'>
        <img src={getUserAvatar(avatar)} alt='avatar' className='user__item--avatar' />
        {displayOnline && (
          <img
            src={onlineStatus ? online : offline}
            alt='convert'
            className='user__item--status'
          />
        )}
      </div>
      <span>{name}</span>
    </div>
  );
};
