import React from 'react';

export const FileTskIcon = ({ className }: any) => {
  return (
    <svg
      width='10'
      height='12'
      viewBox='0 0 10 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M1.49967 0.166687C0.858008 0.166687 0.338841 0.691687 0.338841 1.33335L0.333008 10.6667C0.333008 11.3084 0.852174 11.8334 1.49384 11.8334H8.49967C9.14134 11.8334 9.66634 11.3084 9.66634 10.6667V3.66669L6.16634 0.166687H1.49967ZM5.58301 4.25002V1.04169L8.79134 4.25002H5.58301Z'
        fill='#303030'
      />
    </svg>
  );
};
