import './ScalePopup2.scss';

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useModalContext } from '../../../context';
import {
  ScaleMapTask,
  useUpdateScaleMapTaskMutation,
} from '../../../graphql/generated/graphql';
import { ModalStatuses } from '../../../types/enums';
import { textSlice } from '../../../utils';
import {
  AddPlusScaleIcon,
  EditScaleIcon,
  ScaleStarFavoriteIcons,
  ScaleStarIcons,
  TredLinkIcon,
} from '../../icons';

type ScalePopup2Type = {
  scaleMapTasks: ScaleMapTask[] | undefined;
  fileId: number;
};

export const ScalePopup2 = ({ scaleMapTasks, fileId }: ScalePopup2Type) => {
  const [scaleMapTasksValue, setScaleMapTasks] = useState(scaleMapTasks);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setScaleMapTasks(scaleMapTasks);
  }, [scaleMapTasks]);

  const [updateScaleMapTaskMutation] = useUpdateScaleMapTaskMutation({
    onCompleted: (data) => {
      const newScale = scaleMapTasksValue?.map((e) => {
        if (e.id === data?.updateScaleMapTask?.id) {
          return data?.updateScaleMapTask;
        }
        return e;
      });
      setScaleMapTasks(newScale as any);
    },
  });
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  if (!scaleMapTasksValue) return <div></div>;
  const updateTask = scaleMapTasksValue
    ?.map((data) => {
      const date = moment(
        parseInt(data.statusUpdatedDate ? data.statusUpdatedDate : data.updatedAt),
      );
      const currentDate = moment();
      const daysDiff = currentDate.diff(date, 'days');
      const threadId = data?.parentTree
        ?.map((e) => {
          return e?.thread?.id;
        })
        ?.filter((e) => e)[0];
      return {
        ...data,
        daysDiff: `${daysDiff}`,
        threadId: data?.thread?.id ? data?.thread?.id : threadId,
        thread: data?.thread,
        parentTree: data.parentTree?.map((e) => {
          const date = moment(
            parseInt(e.statusUpdatedDate ? e.statusUpdatedDate : e.updatedAt),
          );
          const currentDate = moment();
          const daysDiff = currentDate.diff(date, 'days');
          return {
            ...e,
            daysDiff: `${daysDiff}`,
            treadId: e?.thread?.id,
            thread: data?.thread,
          };
        }),
      };
    })
    ?.sort((a, b) => parseFloat(b.daysDiff) - parseFloat(a.daysDiff));

  const listData = countUniqueValues(
    updateTask?.map((e) => {
      return e.daysDiff;
    }),
  ).list;
  const convertArr = convert(updateTask);

  const handleUpdateStats = ({ id, statusId }: any) => {
    handleSetModalStatus(ModalStatuses.COLOR_STATUS_ITEM_MODAL);
    handleChangeModalParameters([
      {
        key: 'onCallback',
        value: (value: any) => {
          updateScaleMapTaskMutation({
            variables: {
              input: {
                id: id,
                scaleMapTaskMarkStatusId: value,
              },
            },
          });
        },
      },
      {
        key: 'initialValues',
        value: { statusId: statusId, show: true },
      },
    ]);
  };

  const isProjectI = location.pathname.includes('projectI');
  const empty = calcBoldLine({ array: convertArr });
  return (
    <div className='scalePopup2'>
      {convertArr?.map((scaleMapTask: any) => {
        const newParent = [...(scaleMapTask?.parentTree as any)]?.sort(
          (a, b) => parseFloat(a.column) - parseFloat(b.column),
        );
        const indexFind = listData.indexOf(parseInt(scaleMapTask?.daysDiff));

        const statusColor = scaleMapTask?.scaleMapTaskMarkStatus?.color;

        return (
          <div
            key={scaleMapTask.id}
            className='scalePopup2__line'
            style={{
              borderLeft: empty[0] ? '2px solid #303030' : '1px solid #afafaf',
            }}
          >
            {[...Array(indexFind)].map((_, index) => {
              const wide = empty[index + 1];
              return (
                <div
                  className='scalePopup2__emptyItem'
                  style={{
                    borderLeft: wide ? '2px solid #303030' : `1px solid #afafaf`,
                  }}
                  key={index}
                ></div>
              );
            })}
            <div
              className={`scalePopup2__line-inner`}
              style={{
                backgroundColor: statusColor,
              }}
            >
              {newParent?.map((e) => {
                return (
                  <div
                    key={e.id}
                    style={{
                      borderRight: `1px solid ${statusColor}`,
                    }}
                    className={`scalePopup2__line-vrticalLine ${
                      e.column === 1 ? 'scalePopup2__line-vrticalHover' : ''
                    }`}
                    onClick={() => {
                      if (e.column === 1) {
                        if (!isProjectI) {
                          navigate(`/file/${fileId}?firstCol=${e?.id}`);
                        } else {
                          navigate(`/file/self/${fileId}?firstCol=${e?.id}`);
                        }
                      }
                    }}
                  >
                    <div
                      key={e.id}
                      className={`scalePopup2__parent ${
                        e.column === 1 && 'scalePopup2__parent-first'
                      }`}
                    >
                      {e?.status?.color && (
                        <div
                          style={{ backgroundColor: e?.status?.color }}
                          className='scalePopup2__status'
                        ></div>
                      )}

                      {textSlice({ text: e.name, count: 30 })}
                    </div>
                  </div>
                );
              })}
              <div className='scalePopup2__name'>
                {scaleMapTask?.status?.color && (
                  <div
                    style={{ backgroundColor: scaleMapTask?.status?.color }}
                    className='scalePopup2__status'
                  ></div>
                )}
                {textSlice({ text: scaleMapTask.name, count: 30 })}
              </div>
              {scaleMapTask?.threadId && (
                <TredLinkIcon
                  onClick={() => {
                    navigate('/tread/' + scaleMapTask?.threadId);
                  }}
                  className='scalePopup2__link'
                  color={
                    scaleMapTask?.scaleMapTaskMarkStatus?.id === 5 ? 'white' : '#303030'
                  }
                />
              )}
              {scaleMapTask?.scaleMapTaskMarkStatus?.id === 5 && (
                <div className='scalePopup2__A'>A</div>
              )}

              <div className='scalePopup2__star'>
                {statusColor ? (
                  <ScaleStarFavoriteIcons
                    onClick={() => {
                      handleUpdateStats({
                        id: scaleMapTask?.id,
                        statusId: scaleMapTask?.scaleMapTaskMarkStatus?.id,
                      });
                    }}
                    style={{ stroke: 'black' }}
                  />
                ) : (
                  <ScaleStarIcons
                    onClick={() => {
                      handleUpdateStats({
                        id: scaleMapTask?.id,
                        statusId: scaleMapTask?.scaleMapTaskMarkStatus?.id,
                      });
                    }}
                  />
                )}
              </div>
            </div>
            {!scaleMapTask?.counterComment && (
              <div className='scalePopup2__plus-outer'>
                <div
                  className='scalePopup2__plus-inner'
                  onClick={() => {
                    handleSetModalStatus(ModalStatuses.COMMENT_COUNTER_MODAL);
                    handleChangeModalParameters([
                      {
                        key: 'onCallback',
                        value: (value: any) => {
                          updateScaleMapTaskMutation({
                            variables: {
                              input: {
                                id: scaleMapTask?.id,
                                counterComment: value.comment,
                              },
                            },
                          });
                        },
                      },
                      {
                        key: 'initialValues',
                        value: { comment: scaleMapTask.counterComment },
                      },
                    ]);
                  }}
                >
                  <AddPlusScaleIcon />
                </div>
              </div>
            )}
            {scaleMapTask?.counterComment && (
              <div className='scalePopup2__comment'>
                {textSlice({ text: scaleMapTask?.counterComment, count: 100 })}
                <EditScaleIcon
                  onClick={() => {
                    handleSetModalStatus(ModalStatuses.COMMENT_COUNTER_MODAL);
                    handleChangeModalParameters([
                      {
                        key: 'onCallback',
                        value: (value: any) => {
                          updateScaleMapTaskMutation({
                            variables: {
                              input: {
                                id: scaleMapTask?.id,
                                counterComment: value.comment,
                              },
                            },
                          });
                        },
                      },
                      {
                        key: 'initialValues',
                        value: { comment: scaleMapTask.counterComment },
                      },
                    ]);
                  }}
                />
              </div>
            )}
          </div>
        );
      })}
      <div className='scalePopup2__dayList'>
        {listData?.map((e, index) => {
          const wide = empty[index];
          const shiftBorder = empty
            ?.map((e, localindex) => {
              if (localindex >= index) return 0;
              if (e) {
                // border 2px
                return 2;
              } else {
                // border 1px
                return 1;
              }
            })
            ?.reduce((acc: number, val: number) => acc + val, 0);

          return (
            <div
              key={e}
              style={{
                marginLeft: 40 * index + shiftBorder,
                fontWeight: e >= 30 ? 'bold' : 'normal',
                color: e >= 60 ? 'red' : 'normal',
                borderLeft: wide ? '2px solid #303030' : '',
              }}
              className='scalePopup2__dayNumber'
            >
              {e}
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface UniqueValues {
  count: number;
  list: number[];
}

function countUniqueValues(arr: string[]): UniqueValues {
  const numArr: number[] = arr.map(Number);
  const uniqueObj: { [key: number]: number } = {};

  for (let i = 0; i < numArr.length; i++) {
    if (uniqueObj[numArr[i]]) {
      uniqueObj[numArr[i]]++;
    } else {
      uniqueObj[numArr[i]] = 1;
    }
  }

  const uniqueArr: number[] = Object.keys(uniqueObj).map(Number);

  return {
    count: uniqueArr.length,
    list: uniqueArr?.sort((a, b) => b - a),
  };
}

const convert = (items: any) => {
  return items?.filter((item: any, index: any) => {
    // Если длина parentTree равна 1, проверяем наличие других элементов с таким же daysDiff
    if (item?.parentTree?.length === 1) {
      const daysDiff = item?.daysDiff;
      const otherItems = items?.filter(
        (otherItem: any, otherIndex: any) =>
          otherIndex !== index && otherItem?.daysDiff === daysDiff,
      );
      const foundItem = otherItems?.find((otherItem: any) =>
        otherItem?.parentTree.some(
          (parent: any) => parent?.id === item?.id && parent?.daysDiff === daysDiff,
        ),
      );
      if (foundItem) {
        return false; // Удаляем элемент
      }
    }
    return true; // Оставляем элемент
  });
};

type calcBoldLineType = {
  array: Array<{ daysDiff: number; threadId?: number } & any>;
};

const calcBoldLine = ({ array }: calcBoldLineType) => {
  const uniqueDaysDiff = [...(new Set(array.map((item) => item.daysDiff)) as any)];

  return uniqueDaysDiff.map((daysDiff) => {
    const hasThreadId = array.some(
      (item) => item.daysDiff === daysDiff && item.threadId !== undefined,
    );
    return hasThreadId;
  });
};
