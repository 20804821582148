import './AddTaskInPlanModal.scss';

import { Form, Formik } from 'formik';
import React from 'react';

import {
  useGetChronographSampleQuery,
  useGetCurrentUserScaleQuery,
} from '../../../../graphql/generated/graphql';
import { weeklyPlan } from '../../../../validation/schemas';
import { Button, TemplateNewLine } from '../../../index';
import { Line } from './Line';

export const AddTaskInPlanModal2 = ({ onClose, modalParameters }: any) => {
  const { data } = useGetCurrentUserScaleQuery({
    variables: {},
  });

  const { data: sample, loading } = useGetChronographSampleQuery({
    fetchPolicy: 'network-only',
  });

  const optionsFile = data?.getCurrentUser?.scaleMapFiles?.rows?.map((e: any) => {
    return { value: e.id, label: e.name, color: e?.color };
  });

  const initialValues = sample?.getChronographSample?.sampleItems?.map((e) => {
    return {
      color: e?.color,
      scaleMapFileId: parseInt(e?.scaleMapFileId as any),
      name: e?.scaleMapFile?.name,
    };
  });

  if (loading) return <div></div>;

  return (
    <Formik
      validationSchema={weeklyPlan}
      enableReinitialize={true}
      initialValues={{
        sampleItems: initialValues,
      }}
      onSubmit={(value) => {
        const parse = value?.sampleItems?.map((e: any) => {
          return { ...e, name: undefined };
        });
        modalParameters.onCallback(
          parse,
          parseInt(sample?.getChronographSample?.id as any),
        );
        onClose();
      }}
    >
      {({ setFieldValue, values, errors, setFieldTouched, touched }) => {
        const optionNoDublicate = optionsFile?.filter((obj1) => {
          return !values?.sampleItems?.some(
            (obj2: any) => obj2?.scaleMapFileId === obj1?.value,
          );
        });

        return (
          <Form className='addTaskInPlanModal2'>
            <div className='addTaskInPlanModal2__title'>Публичный шаблон</div>
            <div className='addTaskInPlanModal2__table'>
              <div className='addTaskInPlanModal2__table-title'>
                <div className='addTaskInPlanModal2__table-col1'>Задача</div>
              </div>
              <div>
                {values?.sampleItems?.map((e: any, index: number) => {
                  return (
                    <Line
                      key={e.scaleMapFileId}
                      e={e}
                      setFieldValue={setFieldValue}
                      index={index}
                      values={values}
                    />
                  );
                })}
              </div>
            </div>
            <TemplateNewLine
              setFieldValue={setFieldValue}
              disableEdit={false}
              values={values}
              optionsFile={optionNoDublicate}
              errors={errors}
              setFieldTouched={setFieldTouched}
              touched={touched}
            />

            <div className='addTaskInPlanModal2__button-wrapper'>
              <Button
                onClick={() => {
                  onClose();
                }}
                text={'Отменить'}
                width='150px'
                height='25px'
              />
              <Button
                type='submit'
                text={'Сохранить'}
                width='150px'
                height='25px'
                backgroundColor='#149939'
                color='#FFFFFF'
                margin={'0px 0px 0px 10px'}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
