import React from 'react';

export const SearchHeaderIcon = ({ onClick, className }: any) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      className={className}
    >
      <path
        d='M17.8014 16.8473L13.8347 12.7218C14.8546 11.5093 15.4134 9.98387 15.4134 8.39579C15.4134 4.68539 12.3946 1.66663 8.68424 1.66663C4.97384 1.66663 1.95508 4.68539 1.95508 8.39579C1.95508 12.1062 4.97384 15.1249 8.68424 15.1249C10.0772 15.1249 11.4046 14.7048 12.5395 13.9073L16.5363 18.0641C16.7033 18.2376 16.928 18.3333 17.1688 18.3333C17.3967 18.3333 17.613 18.2464 17.7771 18.0884C18.1258 17.7528 18.137 17.1964 17.8014 16.8473ZM8.68424 3.42206C11.4268 3.42206 13.658 5.65321 13.658 8.39579C13.658 11.1384 11.4268 13.3695 8.68424 13.3695C5.94167 13.3695 3.71051 11.1384 3.71051 8.39579C3.71051 5.65321 5.94167 3.42206 8.68424 3.42206Z'
        fill='#AFAFAF'
      />
    </svg>
  );
};
