import './Input.scss';

import { Field, useField } from 'formik';
import React, { useState } from 'react';

import { EyeIcons } from '../icons';

type InputPropsType = {
  name: string;
  placeholder?: string;
  label?: string;
  type?: string;
  disabled?: boolean;
  width?: string | number;
  showEye?: boolean;
};

export const Input: React.FC<InputPropsType> = ({
  name,
  type = 'text',
  label,
  placeholder = 'Введите значение',
  disabled = false,
  width,
  showEye,
}) => {
  const [, meta] = useField(name);
  const hasError = Boolean(meta.error && meta.touched);
  const [changeType, setChangeType] = useState(false);

  return (
    <div
      style={{
        width: width,
      }}
      className={type === 'checkbox' ? 'input__wrapper-checkbox' : 'input__wrapper'}
    >
      {label && (
        <label htmlFor={name} className='input__label'>
          {label}
        </label>
      )}
      <Field
        type={changeType ? 'text' : type}
        className={'input__field' + ' ' + (hasError && 'input__field-error')}
        placeholder={placeholder}
        name={name}
        disabled={disabled}
      />
      {showEye && (
        <EyeIcons
          className='eyeIcons'
          onClick={() => {
            setChangeType(!changeType);
          }}
        />
      )}

      {hasError && <p className='input__error'>{meta.error}</p>}
    </div>
  );
};
