import { ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';

import * as GENERATED from '../../graphql/generated/graphql';
import { GetTaskDocument, GetTaskQuery } from '../../graphql/generated/graphql';
import { getErrorText } from '../../utils';

export const useCloseTaskMutation = (handleClosePanel: () => void) => {
  return GENERATED.useCloseTaskMutation({
    update(cache, { data }) {
      if (!data?.closeTask) return null;

      const task: GetTaskQuery | null = cache.readQuery({
        query: GetTaskDocument,
        variables: {
          id: data.closeTask.id,
        },
      });

      if (task) {
        cache.writeQuery({
          query: GetTaskDocument,
          variables: {
            id: data.closeTask.id,
          },
          data: {
            ...task,
            getTask: {
              ...task.getTask,
              closeDate: data.closeTask.closeDate,
            },
          },
        });
      }
    },
    onCompleted: (res) => {
      if (res.closeTask) {
        handleClosePanel();
        toast.success('Задача завершена!');
      }
    },
    onError(error: ApolloError) {
      if (error?.message) {
        handleClosePanel();
        toast.error(getErrorText(error.message));
      }
    },
  });
};
