import './MenuBlockActions.scss';

import { FormikValues } from 'formik';
import React, { FC, useEffect, useState } from 'react';

import { OptionsType } from '../../../constants/constants';
import { useModalContext } from '../../../context';
import {
  File,
  Poligon as PoligonType,
  PoligonObject as PoligonObjectType,
} from '../../../graphql/generated/graphql';
import { ModalStatuses, OptionsStatuses } from '../../../types/enums';
import {
  ColorType,
  polygonToolsChangeParameterType,
  polygonToolsDeleteType,
  RectType,
} from '../../../types/types';
import { calcLeftMenu, calcTopMenu } from '../../../utils/';
import {
  BlockIcon,
  InfoIcon,
  LinkAddIcon,
  PolygonIcon,
  RemoveIcon,
  SendPositionIcon,
  TransformTextIcon,
  UnblockIcon,
} from '../../icons';
import { FillIcon } from '../../icons/fillIcon/FillIcon';
import { OptionsMenu } from '../../optionsMenu/OptionsMenu';
import { PolygonActionsWrapper } from '../../polygonActionsWrapper/PolygonActionsWrapper';
import { PolygonArrowColorItem } from '../../polygonMainArrowMenu';
import { Popover } from '../../popover/Popover';

export const MenuBlockActions: FC<MenuBlockActionsType> = ({
  blockId,
  removeConfirmTitle,
  onRemove,
  changeParameter,
  initialColorId,
  onSetColor,
  colors,
  sendOptions,
  selectRect,
  setIsEditState,
  selectedIdRect,
  initLinkValue,
  initialInfoValues,
  onSaveParentLinks,
  poligon,
  polygonObject,
  file,
  changeImage,
  blockItem,
  refScroll,
}) => {
  const {
    optionsStatus,
    handleSetModalStatus,
    handleSetOptionsStatus,
    handleCloseModal,
    handleChangeModalParameters,
  } = useModalContext();

  useEffect(() => {
    setIsNotBlocked(selectRect?.draggable);
  }, [selectRect]);

  useEffect(() => setIsSendPositionMenuOpen(false), [selectedIdRect]);

  const [isNotBlocked, setIsNotBlocked] = useState(selectRect?.draggable);

  const [isSendPositionMenuOpen, setIsSendPositionMenuOpen] = useState(false);

  const onAddInfoHandler = (values: FormikValues) => {
    blockId && changeParameter({ id: blockId, key: 'text', value: values.info });
    handleCloseModal();
  };

  const onRemoveHandler = () => {
    onRemove && blockId && onRemove({ id: blockId });
    handleCloseModal();
  };

  const onSaveLinksParentHandler = (values: FormikValues) => {
    onSaveParentLinks(values);
    handleCloseModal();
  };

  const handleBlockMenu = () => {
    blockId && blockItem({ id: blockId, value: !isNotBlocked });
    setIsNotBlocked(!isNotBlocked);
  };

  const onSetColorHandler = (color: string) => {
    onSetColor && onSetColor(color);
    handleSetOptionsStatus(OptionsStatuses.WITHOUT_STATUS);
  };

  const top = selectRect?.y - 75 - refScroll?.current?.scrollTop || 0;
  const left = selectRect?.x + 15 - refScroll?.current?.scrollLeft || 0;

  return (
    <PolygonActionsWrapper
      isVisible={!!selectedIdRect}
      style={{
        top: calcTopMenu({ top, height: selectRect?.height }),
        left: calcLeftMenu({ shift: 500, left }),
      }}
    >
      <InfoIcon
        onClick={() => {
          handleChangeModalParameters([
            {
              key: 'onCallback',
              value: (values: any) => onAddInfoHandler(values),
            },
            {
              key: 'initialInfoValues',
              value: initialInfoValues,
            },
          ]);
          handleSetModalStatus(ModalStatuses.INFO_MODAL);
        }}
      />

      <div className='polygon-block-menu__colors'>
        <FillIcon onClick={() => handleSetOptionsStatus(OptionsStatuses.PALETTE_MENU)} />
        {optionsStatus === OptionsStatuses.PALETTE_MENU ? (
          <Popover>
            <PolygonArrowColorItem
              initialColorId={initialColorId}
              colors={colors}
              onClick={onSetColorHandler}
            />
          </Popover>
        ) : null}
      </div>

      <TransformTextIcon
        onClick={() => {
          handleSetOptionsStatus(OptionsStatuses.EDIT_TEXT_MENU);
          setIsEditState(true);
        }}
      />
      <PolygonIcon
        onClick={() => {
          handleChangeModalParameters([
            {
              key: 'onCallback',
              value: changeImage,
            },
            {
              key: 'file',
              value: file,
            },
          ]);
          handleSetModalStatus(ModalStatuses.CHANGE_IMAGE_MODAL);
        }}
      />

      <div className='polygon-block-menu__position'>
        <SendPositionIcon
          onClick={() => setIsSendPositionMenuOpen(!isSendPositionMenuOpen)}
        />
        <OptionsMenu isOpen={isSendPositionMenuOpen} options={sendOptions} />
      </div>
      <LinkAddIcon
        onClick={() => {
          handleChangeModalParameters([
            {
              key: 'onCallback',
              value: (values: any) => {
                onSaveLinksParentHandler(values);
              },
            },
            {
              key: 'parentLink',
              value: { parentLink: initLinkValue },
            },
            {
              key: 'poligon',
              value: poligon,
            },
            {
              key: 'polygonObject',
              value: polygonObject,
            },
          ]);
          handleSetModalStatus(ModalStatuses.NEW_LINK_MODAL);
        }}
      />

      {isNotBlocked ? (
        <UnblockIcon onClick={handleBlockMenu} />
      ) : (
        <BlockIcon onClick={handleBlockMenu} />
      )}
      <div className='polygon-actions-wrapper-line' />

      <RemoveIcon
        onClick={() => {
          handleChangeModalParameters([
            {
              key: 'onCallback',
              value: () => onRemoveHandler(),
            },
            {
              key: 'removeConfirmTitle',
              value: removeConfirmTitle,
            },
          ]);
          handleSetModalStatus(ModalStatuses.REMOVE_MODAL);
        }}
      />
    </PolygonActionsWrapper>
  );
};

type MenuBlockActionsType = {
  blockId: number | string | null;
  selectRect: RectType;
  initialColorId?: number;
  removeConfirmTitle?: string | null;
  colors?: ColorType[];
  initialInfoValues?: { info?: string };
  sendOptions?: OptionsType;
  onRemove?: ({ id }: polygonToolsDeleteType) => void;
  changeParameter: ({ id, key, value }: polygonToolsChangeParameterType) => void;
  onSetColor?: (color: string) => void;
  setIsEditState: React.Dispatch<React.SetStateAction<boolean>>;
  selectedIdRect: string | number | null;
  initLinkValue?: string;
  onSaveParentLinks: (values: FormikValues) => void;
  linkModalHandle: any;
  poligon: PoligonType;
  polygonObject: PoligonObjectType | undefined;
  file?: File;
  changeImage: (value: File) => void;
  blockItem: ({ id, value }: { id: number | string; value: boolean }) => void;
  refScroll: any;
};
