import './UnblockIcon.scss';

import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const UnblockIcon: FC<UnblockIconType> = ({
  width = '20px',
  height = '20px',
  className = 'unblock-icon',
  color = '#FFFFFF',
  onClick,
  isBordered = false,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      style={isBordered ? { borderLeft: '1px solid #AFAFAF' } : {}}
    >
      <path
        d='M14.1666 6.66663V5.83329C14.1666 3.53579 12.2974 1.66663 9.99992 1.66663C7.70242 1.66663 5.83325 3.53579 5.83325 5.83329V8.33329H4.99992C4.08075 8.33329 3.33325 9.08079 3.33325 9.99996V16.6666C3.33325 17.5858 4.08075 18.3333 4.99992 18.3333H14.9999C15.9191 18.3333 16.6666 17.5858 16.6666 16.6666V9.99996C16.6666 9.08079 15.9191 8.33329 14.9999 8.33329H7.49992V5.83329C7.49992 4.45496 8.62158 3.33329 9.99992 3.33329C11.3783 3.33329 12.4999 4.45496 12.4999 5.83329V6.66663H14.1666ZM14.9999 9.99996L15.0016 16.6666H4.99992V9.99996H14.9999Z'
        fill={color}
      />
    </svg>
  );
};

type UnblockIconType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
> & { isBordered?: boolean };
