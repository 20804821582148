import './DevPage.scss';

import React from 'react';

import {
  AddPageIcon,
  AddPlusScaleIcon,
  AddTaskIcon,
  AlertCircleIcons,
  AlertIcons,
  AllTaskIcons,
  ArrowDawnIcon,
  ArrowDown,
  ArrowLeftIcon,
  ArrowNext,
  ArrowPrev,
  ArrowRightIcon,
  ArrowSmallIcon,
  BellIconWithNumber,
  BigPlusIcons,
  BlockIcon,
  BoldTextIcon,
  CalendarIcons,
  CalendarTimeIcons,
  CancelIcon,
  CenterAlignTextIcon,
  ChatIcon,
  ChatIconWithNumber,
  CheckAllIcon,
  CheckIcons,
  ChronoIcons,
  ClipIcon,
  ClockIcons,
  ClockIcons2,
  CloseTreadIcons,
  CloseTreadWhiteIcon,
  CogwheelIcon,
  CoinsIcon,
  CrossedTextIcon,
  CrossIcon,
  CursiveTextIcon,
  DateIcons,
  DecideOnTheFly,
  DeleteIcon,
  DoneIcon,
  DownloadIcon,
  DubleCheckIcons,
  EditClicked,
  EditIcon,
  EditIcon2,
  EditIcon3,
  EditScaleIcon,
  ExecutorsIcon,
  ExitIcons,
  EyeIcons,
  FigmaIcons,
  FileIcon,
  FileMoveIcons,
  FileSearchIcon,
  FileTskIcon,
  FillIcon,
  FinishIcons,
  FlagCalendarIcon,
  FlagChronoICons,
  FlagIcons,
  FlagSearchIcon,
  ForumAnswerIcons,
  ForumDownArrIcons,
  ForumIconsTree,
  ForumImgIcons,
  ForumLink,
  ForumNewMessageIcons,
  ForumQuoteIcons,
  ForumSearchIcons,
  GearsIcons,
  GearwheelIcon,
  GoToMyTasksIcon,
  HeaderCalendarIcons,
  HeaderForumIcons,
  HeaderGraphicsIcons,
  HeaderMapIcons,
  HeaderPolygonIcons,
  HeaderStatisticIcons,
  HeaderStorageIcons,
  HeaderTaskIcons,
  ImageCenterTemplateIcons,
  ImageLeftTemplateIcons,
  ImageRightTemplateIcons,
  InfoIcon,
  LeftAlignTextIcon,
  LinkAddIcon,
  LinkIcon,
  LoadBackground,
  LongArrowLeft,
  LongArrowTopIcon,
  MinusIcon,
  NewMessageIcons,
  NotificationSubscribeIcon,
  NotificationUnSubscribeIcon,
  OpenTreadIcons,
  OpenTreadWhiteIcons,
  OptionsIcon,
  PaletteIcon,
  PauseIcons,
  PersonalIcons,
  PlayIcon,
  Plus2Icons,
  PlusChatIcon,
  PlusIcon2,
  PlusIcons,
  PolygonIcon,
  PolygonIconReverse,
  PolygonLinkIcon,
  ProjectI,
  QuoteIcons,
  RedPinIcon,
  RefreshIcon,
  RemoveIcon,
  RemoveIconBlack,
  SaveIcon,
  SaveIconWhite,
  ScaleStarFavoriteIcons,
  ScaleStarIcons,
  SearchHeaderIcon,
  SearchHeaderIcons,
  SearchIcons,
  SendPositionIcon,
  SendToTopIcon,
  SmallEditIcons,
  StarNoSelectIcons,
  StarSelectIcons,
  StorageAllWord,
  StorageExelWord,
  StorageFileWord,
  StorageImgWord,
  StoragePdfWord,
  SuccessIcons,
  TemplateIcon,
  TextSizeIcon,
  ThreeDotsIcon,
  TransformTextIcon,
  TreadDeleteIcons,
  TreadSaveIcons,
  TreadsDeleteICons,
  TreadsEditICons,
  TreadsPinIcons,
  TreadTemplateOpenIcon,
  TredLinkIcon,
  TredStatus,
  TredStatusComplete,
  TreeIcon,
  UnblockIcon,
  UnderlineTextIcon,
  UnPined,
  UpdateIcons,
  UserOutlineIcons,
  UserStatisticIcons,
  UserStatisticIconsReverse,
  WarningCalendarIcon,
} from '../../components/icons';

export const DevPage = () => {
  return (
    <div className='devPage'>
      <div className='devPage__icon'>
        <GearwheelIcon color='white' />
        GearwheelIcon
      </div>
      <div className='devPage__icon'>
        <FigmaIcons white='20px' height='20px' />
        FigmaIcons
      </div>
      <div className='devPage__icon'>
        <AddPageIcon />
        AddPageIcon
      </div>
      <div className='devPage__icon'>
        <AddPlusScaleIcon />
        AddPlusScaleIcon
      </div>
      <div className='devPage__icon'>
        <AddTaskIcon color='black' />
        AddTaskIcon
      </div>
      <div className='devPage__icon'>
        <AlertCircleIcons />
        AlertCircleIcons
      </div>
      <div className='devPage__icon'>
        <AlertIcons />
        AlertIcons
      </div>
      <div className='devPage__icon' style={{ backgroundColor: 'white' }}>
        <AllTaskIcons />
        AllTaskIcons
      </div>
      <div className='devPage__icon'>
        <ArrowDawnIcon width='20px' height='20px' />
        ArrowDawnIcon
      </div>
      <div className='devPage__icon'>
        <ArrowLeftIcon />
        ArrowLeftIcon
      </div>
      <div className='devPage__icon'>
        <ArrowRightIcon />
        ArrowRightIcon
      </div>
      <div className='devPage__icon'>
        <ArrowSmallIcon />
        ArrowSmallIcon
      </div>{' '}
      <div className='devPage__icon' style={{ backgroundColor: 'white' }}>
        <BellIconWithNumber count={1} />
        BellIconWithNumber
      </div>{' '}
      <div className='devPage__icon'>
        <BlockIcon /> BlockIcon
      </div>{' '}
      <div className='devPage__icon'>
        <ArrowRightIcon />
        ArrowRightIcon
      </div>{' '}
      <div className='devPage__icon'>
        <BoldTextIcon />
        BoldTextIcon
      </div>{' '}
      <div className='devPage__icon' style={{ backgroundColor: 'white' }}>
        <CalendarIcons />
        CalendarIcons
      </div>{' '}
      <div className='devPage__icon'>
        <CalendarTimeIcons />
        CalendarTimeIcons
      </div>{' '}
      <div className='devPage__icon'>
        <CancelIcon />
        CancelIcon
      </div>{' '}
      <div className='devPage__icon'>
        <CenterAlignTextIcon />
        CenterAlignTextIcon
      </div>{' '}
      <div className='devPage__icon'>
        <ChatIcon />
        ChatIcon
      </div>{' '}
      <div className='devPage__icon' style={{ backgroundColor: 'white' }}>
        <ChatIconWithNumber count={1} />
        ChatIconWithNumber
      </div>{' '}
      <div className='devPage__icon'>
        <CheckAllIcon color='red' />
        CheckAllIcon
      </div>{' '}
      <div className='devPage__icon'>
        <CheckIcons />
        CheckIcons
      </div>{' '}
      <div className='devPage__icon'>
        <ChronoIcons />
        ChronoIcons
      </div>{' '}
      <div className='devPage__icon'>
        <ClipIcon color='red' />
        ClipIcon
      </div>{' '}
      <div className='devPage__icon' style={{ backgroundColor: 'white' }}>
        <ClockIcons color='red' />
        ClockIcons
      </div>{' '}
      <div className='devPage__icon'>
        <CloseTreadIcons />
        CloseTreadIcons
      </div>{' '}
      <div className='devPage__icon'>
        <CloseTreadWhiteIcon />
        CloseTreadWhiteIcon
      </div>{' '}
      <div className='devPage__icon'>
        <CogwheelIcon />
        CogwheelIcon
      </div>{' '}
      <div className='devPage__icon'>
        <ArrowRightIcon />
        ArrowRightIcon
      </div>{' '}
      <div className='devPage__icon'>
        <CoinsIcon color='red' />
        CoinsIcon
      </div>{' '}
      <div className='devPage__icon'>
        <CrossIcon />
        CrossIcon
      </div>
      <div className='devPage__icon'>
        <CursiveTextIcon />
        CursiveTextIcon
      </div>{' '}
      <div className='devPage__icon' style={{ backgroundColor: 'white' }}>
        <DateIcons color='red' />
        DateIcons
      </div>{' '}
      <div className='devPage__icon'>
        <DoneIcon color='red' />
        DoneIcon
      </div>{' '}
      <div className='devPage__icon'>
        <DownloadIcon />
        DownloadIcon
      </div>{' '}
      <div className='devPage__icon'>
        <DubleCheckIcons />
        DubleCheckIcons
      </div>{' '}
      <div className='devPage__icon'>
        <EditClicked />
        EditClicked
      </div>{' '}
      <div className='devPage__icon'>
        <EditIcon color='red' />
        EditIcon
      </div>{' '}
      <div className='devPage__icon'>
        <EditScaleIcon />
        EditScaleIcon
      </div>{' '}
      <div className='devPage__icon'>
        <ExecutorsIcon />
        ExecutorsIcon
      </div>{' '}
      <div className='devPage__icon'>
        <EyeIcons />
        EyeIcons
      </div>{' '}
      <div className='devPage__icon'>
        <CrossedTextIcon />
        CrossedTextIcon
      </div>
      <div className='devPage__icon'>
        <FileMoveIcons />
        FileMoveIcons
      </div>{' '}
      <div className='devPage__icon'>
        <FileIcon />
        FileIcon
      </div>{' '}
      <div className='devPage__icon' style={{ backgroundColor: 'white' }}>
        <FileSearchIcon className='' />
        FileSearchIcon
      </div>{' '}
      <div className='devPage__icon'>
        <FillIcon />
        FillIcon
      </div>{' '}
      <div className='devPage__icon'>
        <FileTskIcon />
        FileTskIcon
      </div>{' '}
      <div className='devPage__icon' style={{ backgroundColor: 'white' }}>
        <FinishIcons />
        FinishIcons
      </div>{' '}
      <div className='devPage__icon'>
        <FlagCalendarIcon />
        FlagCalendarIcon
      </div>{' '}
      <div className='devPage__icon'>
        <FlagChronoICons color='red' />
        FlagChronoICons
      </div>{' '}
      <div className='devPage__icon' style={{ backgroundColor: 'white' }}>
        <FlagIcons />
        FlagIcons
      </div>{' '}
      <div className='devPage__icon' style={{ backgroundColor: 'white' }}>
        <FlagSearchIcon className='' />
        FlagSearchIcon
      </div>{' '}
      <div className='devPage__icon'>
        <ForumAnswerIcons />
        ForumAnswerIcons
      </div>{' '}
      <div className='devPage__icon'>
        <ForumDownArrIcons />
        ForumDownArrIcons
      </div>{' '}
      <div className='devPage__icon'>
        <ForumIconsTree />
        ForumIconsTree
      </div>{' '}
      <div className='devPage__icon'>
        <ForumImgIcons />
        ForumImgIcons
      </div>{' '}
      <div className='devPage__icon'>
        <ForumLink />
        ForumLink
      </div>{' '}
      <div className='devPage__icon'>
        <ForumNewMessageIcons />
        ForumNewMessageIcons
      </div>{' '}
      <div className='devPage__icon'>
        <ForumQuoteIcons />
        ForumQuoteIcons
      </div>{' '}
      <div className='devPage__icon' style={{ backgroundColor: 'white' }}>
        <ForumSearchIcons className='' />
        ForumSearchIcons
      </div>{' '}
      <div className='devPage__icon' style={{ backgroundColor: 'white' }}>
        <GearsIcons />
        GearsIcons
      </div>{' '}
      <div className='devPage__icon' style={{ backgroundColor: 'white' }}>
        <GoToMyTasksIcon />
        GoToMyTasksIcon
      </div>
      <div className='devPage__icon'>
        <HeaderCalendarIcons />
        HeaderCalendarIcons
      </div>{' '}
      <div className='devPage__icon'>
        <HeaderForumIcons />
        HeaderForumIcons
      </div>{' '}
      <div className='devPage__icon'>
        <HeaderGraphicsIcons />
        HeaderGraphicsIcons
      </div>{' '}
      <div className='devPage__icon'>
        <HeaderMapIcons />
        HeaderMapIcons
      </div>{' '}
      <div className='devPage__icon'>
        <HeaderPolygonIcons />
        HeaderPolygonIcons
      </div>{' '}
      <div className='devPage__icon'>
        <HeaderStatisticIcons />
        HeaderStatisticIcons
      </div>{' '}
      <div className='devPage__icon'>
        <HeaderStorageIcons />
        HeaderStorageIcons
      </div>{' '}
      <div className='devPage__icon'>
        <HeaderTaskIcons />
        HeaderTaskIcons
      </div>{' '}
      <div className='devPage__icon'>
        <ImageCenterTemplateIcons />
        ImageCenterTemplateIcons
      </div>{' '}
      <div className='devPage__icon'>
        <ImageLeftTemplateIcons />
        ImageLeftTemplateIcons
      </div>{' '}
      <div className='devPage__icon'>
        <ImageRightTemplateIcons />
        ImageRightTemplateIcons
      </div>{' '}
      <div className='devPage__icon'>
        <InfoIcon />
        InfoIcon
      </div>{' '}
      <div className='devPage__icon'>
        <LeftAlignTextIcon />
        LeftAlignTextIcon
      </div>{' '}
      <div className='devPage__icon'>
        <LinkAddIcon />
        LinkAddIcon
      </div>{' '}
      <div className='devPage__icon'>
        <LinkIcon />
        LinkIcon
      </div>{' '}
      <div className='devPage__icon'>
        <LongArrowTopIcon />
        LongArrowTopIcon
      </div>{' '}
      <div className='devPage__icon'>
        <MinusIcon />
        MinusIcon
      </div>{' '}
      <div className='devPage__icon'>
        <NewMessageIcons />
        NewMessageIcons
      </div>{' '}
      <div className='devPage__icon'>
        <OpenTreadIcons />
        OpenTreadIcons
      </div>{' '}
      <div className='devPage__icon'>
        <OptionsIcon />
        OptionsIcon
      </div>
      <div className='devPage__icon'>
        <PaletteIcon />
        PaletteIcon
      </div>{' '}
      <div className='devPage__icon'>
        <PauseIcons color='red' />
        PauseIcons
      </div>{' '}
      <div className='devPage__icon'>
        <PersonalIcons />
        PersonalIcons
      </div>{' '}
      <div className='devPage__icon'>
        <PlayIcon />
        PlayIcon
      </div>{' '}
      <div className='devPage__icon'>
        <Plus2Icons />
        Plus2Icons
      </div>{' '}
      <div className='devPage__icon'>
        <PlusChatIcon />
        PlusChatIcon
      </div>{' '}
      <div className='devPage__icon'>
        <PlusIcons />
      </div>{' '}
      <div className='devPage__icon'>
        <PolygonIcon />
        PolygonIcon
      </div>{' '}
      <div className='devPage__icon'>
        <PolygonIconReverse />
        PolygonIconReverse
      </div>{' '}
      <div className='devPage__icon'>
        <PolygonLinkIcon />
        PolygonLinkIcon
      </div>{' '}
      <div className='devPage__icon' style={{ overflowY: 'auto' }}>
        <ProjectI />
        ProjectI
      </div>{' '}
      <div className='devPage__icon' style={{ backgroundColor: 'white' }}>
        <QuoteIcons color='red' />
        QuoteIcons
      </div>{' '}
      <div className='devPage__icon'>
        <RedPinIcon />
        RedPinIcon
      </div>{' '}
      <div className='devPage__icon'>
        <RefreshIcon color='red' />
        RefreshIcon
      </div>{' '}
      <div className='devPage__icon'>
        <RemoveIcon />
        RemoveIcon
      </div>{' '}
      <div className='devPage__icon'>
        <RemoveIconBlack />
        RemoveIconBlack
      </div>{' '}
      <div className='devPage__icon'>
        <SaveIcon />
        SaveIcon
      </div>{' '}
      <div className='devPage__icon'>
        <OpenTreadWhiteIcons />
        OpenTreadWhiteIcons
      </div>
      <div className='devPage__icon'>
        <ScaleStarFavoriteIcons />
        ScaleStarFavoriteIcons
      </div>{' '}
      <div className='devPage__icon'>
        <ScaleStarIcons />
        ScaleStarIcons
      </div>{' '}
      <div className='devPage__icon' style={{ backgroundColor: 'white' }}>
        <SearchHeaderIcon color='red' />
        SearchHeaderIcon
      </div>{' '}
      <div className='devPage__icon'>
        <SearchHeaderIcons />
        SearchHeaderIcons
      </div>{' '}
      <div className='devPage__icon' style={{ position: 'relative' }}>
        <SearchIcons color='red' />
        <span style={{ paddingLeft: '40px' }}>SearchIcons</span>
      </div>{' '}
      <div className='devPage__icon'>
        <SendPositionIcon />
        SendPositionIcon
      </div>{' '}
      <div className='devPage__icon'>
        <SendToTopIcon />
        SendToTopIcon
      </div>
      <div className='devPage__icon'>
        <SaveIconWhite />
        SaveIconWhite
      </div>{' '}
      <div className='devPage__icon'>
        <StorageAllWord />
        StorageAllWord
      </div>{' '}
      <div className='devPage__icon'>
        <StorageExelWord />
        StorageExelWord
      </div>{' '}
      <div className='devPage__icon'>
        <StorageFileWord />
        StorageFileWord
      </div>{' '}
      <div className='devPage__icon'>
        <StorageImgWord />
        StorageImgWord
      </div>{' '}
      <div className='devPage__icon'>
        <StoragePdfWord />
        StoragePdfWord
      </div>{' '}
      <div className='devPage__icon'>
        <SuccessIcons />
        SuccessIcons
      </div>{' '}
      <div className='devPage__icon'>
        <TemplateIcon />
        TemplateIcon
      </div>{' '}
      <div className='devPage__icon'>
        <TextSizeIcon />
        TextSizeIcon
      </div>
      <div className='devPage__icon'>
        <TransformTextIcon />
        TransformTextIcon
      </div>{' '}
      <div className='devPage__icon'>
        <TreadDeleteIcons />
        TreadDeleteIcons
      </div>{' '}
      <div className='devPage__icon'>
        <TreadSaveIcons />
        TreadSaveIcons
      </div>{' '}
      <div className='devPage__icon'>
        <TreadsDeleteICons />
        TreadsDeleteICons
      </div>{' '}
      <div className='devPage__icon'>
        <TreadsEditICons />
        TreadsEditICons
      </div>{' '}
      <div className='devPage__icon'>
        <TreadsPinIcons />
        TreadsPinIcons
      </div>{' '}
      <div className='devPage__icon'>
        <TreadTemplateOpenIcon />
        TreadTemplateOpenIcon
      </div>{' '}
      <div className='devPage__icon'>
        <TreeIcon />
        TreeIcon
      </div>
      <div className='devPage__icon'>
        <UnblockIcon />
        UnblockIcon
      </div>{' '}
      <div className='devPage__icon'>
        <UnderlineTextIcon />
        UnderlineTextIcon
      </div>{' '}
      <div className='devPage__icon'>
        <UnPined />
        UnPined
      </div>{' '}
      <div className='devPage__icon'>
        <UpdateIcons />
        UpdateIcons
      </div>{' '}
      <div className='devPage__icon'>
        <UserStatisticIcons />
        UserStatisticIcons
      </div>{' '}
      <div className='devPage__icon'>
        <UserStatisticIconsReverse />
        UserStatisticIconsReverse
      </div>{' '}
      <div className='devPage__icon'>
        <WarningCalendarIcon />
        WarningCalendarIcon
      </div>
      <div className='devPage__icon'>
        <ArrowNext />
        ArrowNext
      </div>
      <div className='devPage__icon'>
        <ArrowPrev />
        ArrowPrev
      </div>
      <div className='devPage__icon'>
        <ArrowDown />
        ArrowDown
      </div>
      <div className='devPage__icon'>
        <TredStatus />
        TredStatus
      </div>
      <div className='devPage__icon'>
        <TredStatusComplete />
        TredStatusComplete
      </div>
      <div className='devPage__icon'>
        <LoadBackground />
        LoadBackground
      </div>
      <div className='devPage__icon'>
        <BigPlusIcons />
        BigPlusIcons
      </div>
      <div className='devPage__icon'>
        <SmallEditIcons />
        SmallEditIcons
      </div>
      <div className='devPage__icon'>
        <ExitIcons />
        ExitIcons
      </div>
      <div className='devPage__icon'>
        <StarSelectIcons />
        StarSelectIcons
      </div>
      <div className='devPage__icon'>
        <StarNoSelectIcons />
        StarNoSelectIcons
      </div>
      <div className='devPage__icon'>
        <NotificationSubscribeIcon />
        NotificationSubscribeIcon
      </div>
      <div className='devPage__icon'>
        <NotificationUnSubscribeIcon />
        NotificationUnSubscribeIcon
      </div>
      <div className='devPage__icon'>
        <UserOutlineIcons />
        UserOutlineIcons
      </div>
      <div className='devPage__icon'>
        <UserOutlineIcons />
        UserOutlineIcons
      </div>
      <div className='devPage__icon'>
        <TredLinkIcon />
        TredLinkIcon
      </div>
      <div className='devPage__icon' style={{ backgroundColor: 'white' }}>
        <EditIcon2 />
        EditIcon2
      </div>
      <div className='devPage__icon' style={{ backgroundColor: 'white' }}>
        <ThreeDotsIcon />
        ThreeDotsIcon
      </div>
      <div className='devPage__icon' style={{ backgroundColor: 'white' }}>
        <ClockIcons2 />
        ClockIcons2
      </div>
      <div className='devPage__icon' style={{ backgroundColor: 'white' }}>
        <PlusIcon2 />
        PlusIcon2
      </div>
      <div className='devPage__icon'>
        <DecideOnTheFly />
        DecideOnTheFly
      </div>
      <div className='devPage__icon'>
        <LongArrowLeft />
        LongArrowLeft
      </div>
      <div className='devPage__icon'>
        <EditIcon3 />
        EditIcon3
      </div>
      <div className='devPage__icon'>
        <DeleteIcon />
        DeleteIcon
      </div>
    </div>
  );
};
