import './UserStatisticBlockItem.scss';

import React from 'react';

import { useModalContext } from '../../../context';
import {
  useDeleteUserCharacteristicMutation,
  useUpdateUserCharacteristicMutation,
} from '../../../graphql/generated/graphql';
import { ModalStatuses } from '../../../types/enums';
import { CrossIcon, EditIcon, OptionsIcon, Status } from '../../icons';

type UserStatisticBlockItemType = {
  characteristic: any;
  userId: number;
  handleRefetch: () => void;
};

export const UserStatisticBlockItem: React.FC<UserStatisticBlockItemType> = ({
  characteristic,
  handleRefetch,
}) => {
  const { handleSetModalStatus, handleChangeModalParameters, handleCloseModal } =
    useModalContext();

  const [updateUserCharacteristicMutation] = useUpdateUserCharacteristicMutation({
    onCompleted: () => {
      handleRefetch();
    },
  });
  const [deleteUserCharacteristicMutation] = useDeleteUserCharacteristicMutation({
    onCompleted: () => {
      handleRefetch();
    },
  });

  return (
    <div className='userStatisticBlockItem'>
      <div>
        <Status color={characteristic?.color} />
      </div>
      <div className='userStatisticBlockItem__left'>
        <div className='userStatisticBlockItem__name'>{characteristic?.text}</div>
        <div className='popover-wrapper'>
          <OptionsIcon width='15px' height='15px' color={'#303030'} className='test' />
          <div className='popover-inner'>
            <div
              className='popover-item mb10'
              onClick={() => {
                handleSetModalStatus(ModalStatuses.ADD_TASKS);
                handleChangeModalParameters([
                  {
                    key: 'onCallback',
                    value: (values: any) => {
                      updateUserCharacteristicMutation({
                        variables: {
                          data: { ...values, id: characteristic.id },
                        },
                      });
                      handleCloseModal();
                    },
                  },
                  {
                    key: 'initialEditTitleValues',
                    value: characteristic,
                  },
                ]);
              }}
            >
              <EditIcon
                width={12}
                height={12}
                color={'#AFAFAF'}
                className='popover-icons'
              />
              <span>Редактировать</span>
            </div>
            <div
              className='popover-item'
              onClick={() => {
                deleteUserCharacteristicMutation({
                  variables: { id: characteristic.id },
                });
              }}
            >
              <CrossIcon
                width={12}
                height={12}
                color={'#AFAFAF'}
                className='popover-icons'
              />
              <span>Удалить</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
