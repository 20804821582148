import './DeleteAccount.scss';

import React from 'react';

import { useAuthContext } from '../../../../context';
import { useRemoveMyAccountMutation } from '../../../../graphql/generated/graphql';
import { Button } from '../../../index';

export const DeleteAccount = ({ onClose }: any) => {
  const [removeMyAccountMutation] = useRemoveMyAccountMutation({
    variables: {},
  });
  const { onLogout } = useAuthContext();

  return (
    <div className='DeleteAccount'>
      <div className='DeleteAccount__title'>Вы хотите удалить аккаунт?</div>

      <Button
        onClick={() => {
          removeMyAccountMutation().then(() => {
            onLogout();
            onClose();
          });
        }}
      >
        Да
      </Button>
      <Button margin={'10px 0px 0px 0px'} onClick={onClose}>
        Нет
      </Button>
    </div>
  );
};
