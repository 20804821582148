import React from 'react';

type StorageAllWordType = {
  color?: string;
};

export const StorageAllWord = ({ color = 'black' }: StorageAllWordType) => {
  return (
    <svg
      width='16'
      height='20'
      viewBox='0 0 16 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2 0C0.9 0 0.0100002 0.9 0.0100002 2L0 18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6L10 0H2ZM9 7V1.5L14.5 7H9Z'
        fill={color}
      />
    </svg>
  );
};
