import React from 'react';

export const PlusChatIcon = ({ onClick }: any) => {
  return (
    <svg
      width='10'
      height='10'
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        d='M9.0999 4.5977H5.40669V0.904494C5.40669 0.682462 5.22643 0.5 5.0022 0.5C4.77797 0.5 4.5977 0.682462 4.5977 0.904494V4.59551H0.904494C0.796776 4.59551 0.693454 4.63727 0.61871 4.71422C0.541768 4.79116 0.5 4.89228 0.5 5C0.5 5.22423 0.682462 5.40449 0.904494 5.40449H4.59551V9.09551C4.59551 9.31974 4.77797 9.5 5 9.5C5.22423 9.5 5.40449 9.31754 5.40449 9.09551V5.40669H9.09551C9.31974 5.40669 9.5 5.22423 9.5 5.0022C9.5044 4.77797 9.32193 4.5977 9.0999 4.5977Z'
        fill='#149939'
      />
    </svg>
  );
};
