import React from 'react';

type TreadSaveIconsType = {
  className?: string;
};

export const TreadSaveIcons = ({ className }: TreadSaveIconsType) => {
  return (
    <svg
      width='15'
      height='14'
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M4.875 1.75V3.9375C4.875 4.2856 5.01328 4.61944 5.25942 4.86558C5.50556 5.11172 5.8394 5.25 6.1875 5.25H7.9375C8.2856 5.25 8.61944 5.11172 8.86558 4.86558C9.11172 4.61944 9.25 4.2856 9.25 3.9375V1.75H9.58162C10.0457 1.7501 10.4908 1.93454 10.8189 2.26275L12.2372 3.68112C12.5655 4.00924 12.7499 4.45428 12.75 4.91837V10.5C12.75 10.9641 12.5656 11.4092 12.2374 11.7374C11.9092 12.0656 11.4641 12.25 11 12.25V8.3125C11 7.9644 10.8617 7.63056 10.6156 7.38442C10.3694 7.13828 10.0356 7 9.6875 7H5.3125C4.58887 7 4 7.58538 4 8.31075V12.25C3.53587 12.25 3.09075 12.0656 2.76256 11.7374C2.43437 11.4092 2.25 10.9641 2.25 10.5V3.5C2.25 3.03587 2.43437 2.59075 2.76256 2.26256C3.09075 1.93437 3.53587 1.75 4 1.75H4.875ZM5.75 1.75V3.9375C5.75 4.05353 5.79609 4.16481 5.87814 4.24686C5.96019 4.32891 6.07147 4.375 6.1875 4.375H7.9375C8.05353 4.375 8.16481 4.32891 8.24686 4.24686C8.32891 4.16481 8.375 4.05353 8.375 3.9375V1.75H5.75ZM4.875 12.25H10.125V8.3125C10.125 8.19647 10.0789 8.08519 9.99686 8.00314C9.91481 7.92109 9.80353 7.875 9.6875 7.875H5.3125C5.07012 7.875 4.875 8.07013 4.875 8.31075V12.25Z'
        fill='white'
      />
    </svg>
  );
};
