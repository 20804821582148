import React from 'react';

export const ForumLink = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.66667 5.99967V3.33301L2 7.99967L6.66667 12.6663V9.93301C10 9.93301 12.3333 10.9997 14 13.333C13.3333 9.99967 11.3333 6.66634 6.66667 5.99967Z'
        fill='white'
      />
    </svg>
  );
};
