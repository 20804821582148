import './MenuTitle.scss';

import React from 'react';

type MenuTitlePropsType = {
  title: string;
  count?: number | string;
  icon?: JSX.Element;
};

export const MenuTitle = ({ title, count, icon }: MenuTitlePropsType) => {
  return (
    <div className='menu__title' style={{ justifyContent: 'flex-start' }}>
      {icon}
      <span className='menu__title-name'>{title}</span>
      {Math.sign(Number(count)) ? (
        <span className='menu__title-count'>{Number(count)}</span>
      ) : null}
    </div>
  );
};
