import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { taskPaginationLimit } from '../../constants/constants';
import * as GENERATED from '../../graphql/generated/graphql';
import {
  GetTasksDocument,
  GetTasksQuery,
  SortingField,
  SortingOrders,
} from '../../graphql/generated/graphql';
import { GET_ARCHIVED_PROJECTS, GET_PROJECTS } from '../../graphql/queries';
import { GET_PROJECT } from '../../graphql/queries/getProject.gql';

export const useDeleteTaskMutation = (projectId?: number | null) => {
  const { taskId } = useParams();

  const getProjectVariables = {
    data: {
      limit: 100,
      sort: { field: SortingField.Number, type: SortingOrders.Asc },
    },
    tasksData: {
      sort: { type: SortingOrders.Desc, field: SortingField.Activity },
      limit: 100,
    },
  };
  return GENERATED.useDeleteTaskMutation({
    update(cache, { data }) {
      if (!data?.deleteTask) {
        return null;
      }

      const tasks: GetTasksQuery | null = cache.readQuery({
        query: GetTasksDocument,
        variables: {
          data: {
            limit: 100,
          },
        },
      });

      const newTasks = tasks?.getTasks.rows.filter((task) => task?.id !== taskId);

      if (tasks) {
        cache.writeQuery({
          query: GetTasksDocument,
          variables: {
            data: {
              limit: 100,
            },
          },
          data: {
            ...tasks,
            getTasks: {
              ...tasks?.getTasks,
              rows: [...(newTasks || [])],
            },
          },
        });
      }
    },
    refetchQueries: [
      {
        query: GET_ARCHIVED_PROJECTS,
        variables: {
          data: {
            limit: 100,
          },
        },
      },
      { query: GET_PROJECTS, variables: getProjectVariables },
      {
        query: GET_PROJECT,
        variables: {
          id: projectId,
          tasksData: {
            limit: taskPaginationLimit,
            isTaskStorage: false,
          },
        },
      },
    ],
    onCompleted: (response) => {
      if (response?.deleteTask) {
        toast.success('Задача успешно удалена!');
      }
    },
  });
};
