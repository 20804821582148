import './ArrowDawnIcon.scss';

import React from 'react';

type ArrowDawnPropsType = {
  color?: string;
  width?: string | number;
  height?: string | number;
  className?: string;
  onClick?: () => void;
};

export const ArrowDawnIcon: React.FC<ArrowDawnPropsType> = ({
  color = '#303030',
  width,
  height,
  className = 'arrow-dawn-icon',
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 12 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M11.853 1.36258L6.38036 7.35381C6.27583 7.45885 6.13677 7.50336 6.00024 7.49636C5.8632 7.50336 5.72463 7.45885 5.6201 7.35381L0.147044 1.36258C-0.0490147 1.16603 -0.0490147 0.846932 0.147044 0.650364C0.343103 0.453795 0.661221 0.453815 0.857279 0.650364L6.00028 6.28103L11.1427 0.650364C11.3388 0.453815 11.6569 0.453815 11.853 0.650364C12.049 0.846893 12.049 1.16601 11.853 1.36258Z'
        fill={color}
      />
    </svg>
  );
};
