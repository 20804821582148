import './AppTextarea.scss';

import { useField } from 'formik';
import React from 'react';

type AppTextareaPropsType = {
  name: string;
  placeholder?: string;
  label?: string;
  width?: string | number;
  style?: any;
};

export const AppTextarea: React.FC<AppTextareaPropsType> = ({
  name,
  label,
  placeholder,
  style,
}) => {
  const [field, meta] = useField(name);
  const hasError = Boolean(meta.error && meta.touched);
  return (
    <div className='appTextarea__wrap'>
      {label && (
        <label className='appTextarea__label' htmlFor={name}>
          {label}{' '}
        </label>
      )}
      <textarea
        className='appTextarea__textarea'
        id={name}
        value={field.value}
        onChange={field.onChange}
        placeholder={placeholder}
        name={name}
        style={style}
      />
      {hasError && <p className='appTextarea__error'>{meta.error}</p>}
    </div>
  );
};
