import { useEffect, useRef, useState } from 'react';

export const useSelectElementHooks = () => {
  const refArrow = useRef<any>(null);
  const ref1 = useRef<any>(null);
  const ref2 = useRef<any>(null);
  const ref3 = useRef<any>(null);
  const ref4 = useRef<any>(null);
  const ref5 = useRef<any>(null);
  const ref6 = useRef<any>(null);
  const ref7 = useRef<any>(null);
  const ref8 = useRef<any>(null);
  const ref9 = useRef<any>(null);
  const ref10 = useRef<any>(null);
  const ref11 = useRef<any>(null);
  const ref12 = useRef<any>(null);
  const [selectNumber, setSelectNumber] = useState(0);

  useEffect(() => {
    findSelect();
  }, []);

  const findSelect = () => {
    const distances = [
      calculateDistance({ arrow: refArrow, item: ref1 }),
      calculateDistance({ arrow: refArrow, item: ref2 }),
      calculateDistance({ arrow: refArrow, item: ref3 }),
      calculateDistance({ arrow: refArrow, item: ref4 }),
      calculateDistance({ arrow: refArrow, item: ref5 }),
      calculateDistance({ arrow: refArrow, item: ref6 }),
      calculateDistance({ arrow: refArrow, item: ref7 }),
      calculateDistance({ arrow: refArrow, item: ref8 }),
      calculateDistance({ arrow: refArrow, item: ref9 }),
      calculateDistance({ arrow: refArrow, item: ref10 }),
      calculateDistance({ arrow: refArrow, item: ref11 }),
      calculateDistance({ arrow: refArrow, item: ref12 }),
    ];

    // Finding the minimum distance and its index
    let minDistance = null;
    let minDistanceIndex = -1;

    for (let i = 0; i < distances.length; i++) {
      const distance = distances[i];
      if (distance !== null && (minDistance === null || distance < minDistance)) {
        minDistance = distance;
        minDistanceIndex = i;
      }
    }

    if (minDistance !== null) {
      setSelectNumber(minDistanceIndex + 1);
    }
  };

  const [endpoint, setEndpoint] = useState(0);

  const getRealSize = () => {
    const screenHeight = window.innerHeight;
    if (screenHeight > 900) {
      setEndpoint(0);
    }
    if (screenHeight < 900) {
      setEndpoint(1);
    }
    if (screenHeight < 790) {
      setEndpoint(2);
    }
  };

  useEffect(() => {
    getRealSize();
    window.addEventListener('resize', getRealSize);
    return () => {
      window.removeEventListener('resize', getRealSize);
    };
  }, []);

  const circlesData = [
    { radius: 150, scale: 1, widthHeight: 570 },
    { radius: 120, scale: 0.8, widthHeight: 470 },
    { radius: 100, scale: 0.65, widthHeight: 390 },
  ];

  const refs = [
    ref1,
    ref2,
    ref3,
    ref4,
    ref5,
    ref6,
    ref7,
    ref8,
    ref9,
    ref10,
    ref11,
    ref12,
  ];

  return {
    refArrow,
    refs,
    selectNumber,
    setSelectNumber,
    findSelect,
    circleData: circlesData[endpoint],
  };
};

const calculateDistance = ({ arrow, item }: any) => {
  // Get DOM elements from ref
  const arrowElement = arrow.current;
  const itemElement = item.current;

  // Check that both elements exist
  if (arrowElement && itemElement) {
    const arrowCoordinates = arrowElement.getBoundingClientRect();
    const itemCoordinates = itemElement.getBoundingClientRect();

    // Calculate center of element arrow
    const arrowCenterX = arrowCoordinates.left + arrowCoordinates.width / 2;
    const arrowCenterY = arrowCoordinates.top + arrowCoordinates.height / 2;

    // Calculate the center of the item element
    const itemCenterX = itemCoordinates.left + itemCoordinates.width / 2;
    const itemCenterY = itemCoordinates.top + itemCoordinates.height / 2;

    // Calculate the distance between the centers of elements
    const deltaX = arrowCenterX - itemCenterX;
    const deltaY = arrowCenterY - itemCenterY;
    const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

    return distance;
  }

  return null;
};
