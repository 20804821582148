import { useState } from 'react';

export const useControlSelectCol = ({
  setFilterStatus,
}: {
  setFilterStatus: (value: number | null) => void;
}) => {
  const [selectFirstCol, setSelectFirstCol] = useState(0);
  const [selectTwoCol, setSelectTwoCol] = useState(0);
  const [selectThreeCol, setSelectThreeCol] = useState(0);

  const reload = () => {
    setSelectTwoCol(0);
    setSelectThreeCol(0);
    setSelectFirstCol(0);
    setFilterStatus(null);
  };

  return {
    selectFirstCol,
    setSelectFirstCol,
    reload,
    selectTwoCol,
    setSelectTwoCol,
    selectThreeCol,
    setSelectThreeCol,
  };
};
