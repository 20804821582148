import './ForumCreateMessage.scss';

import { Form, Formik } from 'formik';
import React, { useState } from 'react';

import { ROLES } from '../../../constants/constants';
import { useAuthContext } from '../../../context';
import { TemplateState } from '../../../types/enums';
import { addMessage } from '../../../validation/schemas';
import {
  CloseTreadWhiteIcon,
  ImageCenterTemplateIcons,
  ImageLeftTemplateIcons,
  ImageRightTemplateIcons,
  OpenTreadWhiteIcons,
  PlusIcons,
  RemoveIcon,
  SaveIconWhite,
  TreadTemplateOpenIcon,
} from '../../icons';
import { Button, Editor, ImageLoad, Quote, TreadLink } from '../../index';

type ForumCreateMessageType = {
  initialValues?: any;
  onCallback: (value: any) => void;
  onClose?: () => void;
  title?: string;
  buttonText?: string;
};

export const ForumCreateMessage = ({
  initialValues,
  onCallback,
  onClose,
  title = 'Создать тему',
  buttonText = 'Cоздать',
}: ForumCreateMessageType) => {
  const { currentUserData } = useAuthContext();
  const [focused, setFocused] = useState(false);

  return (
    <Formik
      initialValues={initialValues || { name: '', viewType: TemplateState.center }}
      onSubmit={(e) => {
        onCallback(e);
        onClose && onClose();
      }}
      validationSchema={addMessage}
    >
      {({ setFieldValue, values, errors }) => {
        return (
          <Form style={{ width: '100%' }}>
            <div className='ForumCreateMessage'>
              <div className='ForumCreateMessage__head'>
                <div className='ForumCreateMessage__head-title'>{title}</div>
                <DropdownTemplate
                  templateState={values?.viewType}
                  setTemplateState={(e) => {
                    setFieldValue('viewType', e);
                  }}
                />
                {title === 'Редактировать тему' && currentUserData?.role === ROLES.ADMIN && (
                  <Button
                    onClick={() => {
                      setFieldValue('isClosed', !values?.isClosed);
                    }}
                    backgroundColor={values?.isClosed ? '#303030' : '#EE3944'}
                    color={'white'}
                  >
                    {values?.isClosed ? (
                      <OpenTreadWhiteIcons className='mr10' />
                    ) : (
                      <CloseTreadWhiteIcon className='mr10' />
                    )}
                    {values?.isClosed ? 'Открыть' : 'Закрыть'}
                  </Button>
                )}
              </div>
              <div className='ForumCreateMessage__quote'>
                {values?.message && <Quote message={values?.message as any} />}
                {values?.link && <TreadLink link={values?.link as any} />}
                {values?.message || values?.link ? (
                  <RemoveIcon
                    color={'black'}
                    className='ForumCreateMessage__remove'
                    onClick={() => {
                      setFieldValue('link', undefined);
                      setFieldValue('message', undefined);
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>

              <div className='ForumCreateMessage__template'>
                {TemplateState.left === values?.viewType && (
                  <ImageLoad
                    margin={'mr20'}
                    setFieldValue={setFieldValue}
                    image={values?.image}
                    focused={focused}
                    errors={errors}
                  />
                )}
                <div className='ForumCreateMessage__template-inner'>
                  <div style={{ margin: '40px' }}></div>

                  <div className={errors?.description && `ForumCreateMessage__errors`}>
                    <Editor
                      setValue={(e) => {
                        setFieldValue('description', e);
                      }}
                      initialValues={initialValues?.description}
                      setFocused={setFocused}
                    />
                  </div>
                </div>
                {TemplateState.right === values?.viewType && (
                  <ImageLoad
                    margin={'ml20'}
                    setFieldValue={setFieldValue}
                    image={values?.image}
                    focused={focused}
                    errors={errors}
                  />
                )}
              </div>
              <div className='ForumCreateMessage__buttons'>
                <Button
                  onClick={() => {
                    onClose && onClose();
                  }}
                >
                  Отмена
                </Button>
                <Button type='submit'>
                  {buttonText === 'Сохранить' ? (
                    <SaveIconWhite className='mr10' />
                  ) : (
                    <PlusIcons width='10px' height='10px' className='mr10' />
                  )}
                  {buttonText}
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

type DropdownTemplateType = {
  templateState: TemplateState;
  setTemplateState: (value: TemplateState) => void;
};

const DropdownTemplate = ({ templateState, setTemplateState }: DropdownTemplateType) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='DropdownTemplate'>
      <TreadTemplateOpenIcon
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        color={isOpen ? '#303030' : 'white'}
      />

      {isOpen && (
        <div className='DropdownTemplate__open'>
          <div
            className={`DropdownTemplate__item ${
              templateState === TemplateState.left && 'DropdownTemplate__select'
            }`}
            onClick={() => {
              setTemplateState(TemplateState.left);
            }}
          >
            <ImageLeftTemplateIcons
              color={templateState === TemplateState.left ? 'white' : '#303030'}
            />
          </div>
          <div
            className={`DropdownTemplate__item ${
              templateState === TemplateState.center && 'DropdownTemplate__select'
            }`}
            onClick={() => {
              setTemplateState(TemplateState.center);
            }}
          >
            <ImageCenterTemplateIcons
              color={templateState === TemplateState.center ? 'white' : '#303030'}
            />
          </div>
          <div
            className={`DropdownTemplate__item ${
              templateState === TemplateState.right && 'DropdownTemplate__select'
            }`}
            onClick={() => {
              setTemplateState(TemplateState.right);
            }}
          >
            <ImageRightTemplateIcons
              color={templateState === TemplateState.right ? 'white' : '#303030'}
            />
          </div>
        </div>
      )}
    </div>
  );
};
