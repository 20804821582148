import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { ModalLayout } from './components';
import {
  AuthProvider,
  ChatProvider,
  MenuProvider,
  ModalProvider,
  PaginationProvider,
  SearchProvider,
  TredsProvider,
} from './context';
import { client } from './graphql/client';
import { Navigation } from './navigation/Navigation';

export const App = () => {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <AuthProvider>
          <ChatProvider>
            <SearchProvider>
              <MenuProvider>
                <PaginationProvider>
                  <ModalProvider>
                    <ModalLayout>
                      <TredsProvider>
                        <Navigation />
                      </TredsProvider>
                    </ModalLayout>
                  </ModalProvider>
                </PaginationProvider>
              </MenuProvider>
            </SearchProvider>
            <ToastContainer position='bottom-right' />
          </ChatProvider>
        </AuthProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
};
