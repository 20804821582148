import { gql } from '@apollo/client';

export const GET_CALENDAR = gql`
  query getCalendar(
    $dateRange: OnlyDateRangeInput!
    $limit: Int
    $offset: Int
    $taskSort: Sort
    $projectSort: Sort
    $subtasksData: GetSubtasksInput!
    $taskSubtaskStatusId: Int
  ) {
    getCalendar(
      dateRange: $dateRange
      limit: $limit
      offset: $offset
      taskSort: $taskSort
      projectSort: $projectSort
      taskSubtaskStatusId: $taskSubtaskStatusId
    ) {
      calendarProjects {
        count
        rows {
          id
          name
          number
          tasks {
            id
            number
            name
            code
            parentId
            startDate
            closeDate
            endDate
            maintainer {
              id
              tags {
                rows {
                  id
                  name
                  color
                  textColor
                }
              }
            }
            realizationPrice
            realizationPerMonthAmount
            assignees {
              id
              tags {
                rows {
                  id
                  name
                  color
                  textColor
                }
              }
            }
            status {
              id
              color
            }
            project {
              id
              number
            }
            subtasks(data: $subtasksData) {
              id
              number
              name
              code
              startDate
              closeDate
              endDate
              status {
                id
                color
              }
              task {
                id
                name
              }
              tags {
                count
                rows {
                  id
                  name
                  color
                  textColor
                  createdAt
                  updatedAt
                }
              }
            }
            tags {
              count
              rows {
                id
                name
                color
                textColor
                createdAt
                updatedAt
              }
            }
          }
        }
      }
    }
  }
`;
