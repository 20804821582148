export const wrapContainer = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  const iframes = doc.querySelectorAll('iframe');

  iframes.forEach((iframe) => {
    const container = doc.createElement('div');
    container.classList.add('video-container');
    iframe?.parentNode?.insertBefore(container, iframe);
    container.appendChild(iframe);
  });

  return doc.body.innerHTML;
};
