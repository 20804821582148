import './ConfirmModal.scss';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useDeleteSubtaskMutation,
  useDeleteTaskMutation,
} from '../../../../hooks/useMutations';
import { Button } from '../../..';

type DeleteTaskModalType = {
  isOpen: boolean;
  onClose: () => void;
  modalParameters?: any;
};

export const DeleteTaskModal = ({ onClose, modalParameters }: DeleteTaskModalType) => {
  const { projectId, taskId, subtaskId, entityId } = modalParameters.initialValues;

  const [deleteTask, { loading: loadingTask }] = useDeleteTaskMutation(projectId);
  const [deleteSubtask, { loading: loadingSubtask }] = useDeleteSubtaskMutation();

  const navigate = useNavigate();

  const isArchivePage = location.pathname.includes('archive');

  const handleDelete = async () => {
    const deleteVariables = { id: Number(entityId) };
    if (loadingTask || loadingSubtask) return;
    if (subtaskId && taskId) {
      await deleteSubtask({ variables: deleteVariables });
      onClose();
      navigate(isArchivePage ? `/archivedTask/${taskId}` : `/task/${taskId}`);
    } else if (!subtaskId && taskId) {
      await deleteTask({ variables: deleteVariables });
      onClose();
      navigate(isArchivePage ? '/archive' : '/');
    }
  };

  const { confirmTitle } = modalParameters.initialValues;

  return (
    <div className='confirmModal' style={{ width: 'auto', maxWidth: 'none' }}>
      {confirmTitle && (
        <p>
          Вы действительно хотите удалить <b>{confirmTitle}&nbsp;</b>?
        </p>
      )}

      <div className='confirmModal--buttons'>
        <Button
          text='Удалить'
          onClick={handleDelete}
          width='40%'
          isCancelButton
          height={25}
        />
      </div>
    </div>
  );
};
