import './MenuTextActions.scss';

import { FormikValues } from 'formik';
import React, { FC, useEffect, useState } from 'react';

import { polygonBlockTextFontSizes } from '../../../constants/constants';
import { useModalContext } from '../../../context';
import { OptionsStatuses } from '../../../types/enums';
import {
  MenuTextActionsInitialValuesType,
  PolygonTextStylesOptionsType,
  PolygonTextStylesType,
  polygonToolsChangeParameterType,
  RectType,
} from '../../../types/types';
import { calcLeftMenu, calcTopMenu } from '../../../utils';
import {
  ArrowLeftIcon,
  ArrowSmallIcon,
  BoldTextIcon,
  CenterAlignTextIcon,
  CrossedTextIcon,
  CursiveTextIcon,
  LeftAlignTextIcon,
  TextSizeIcon,
  UnderlineTextIcon,
} from '../../icons';
import { OptionsMenu } from '../../optionsMenu/OptionsMenu';
import { PolygonActionsWrapper } from '../../polygonActionsWrapper/PolygonActionsWrapper';

type MenuTextActionsType = {
  onMenuTextActionsSubmit: (values: FormikValues) => void;
  blockId: number | string | null;
  initialValues: MenuTextActionsInitialValuesType;
  setIsEditState: React.Dispatch<React.SetStateAction<boolean>>;
  changeParameter: ({ id, key, value }: polygonToolsChangeParameterType) => void;
  selectRect: RectType;
  refScroll: any;
};

const transformInitialValues = (obj: MenuTextActionsInitialValuesType) => ({
  fontSize: obj?.fontSize,
  textAlign: obj?.textAlign,
  isBold: obj?.fontWeight === 'bold',
  isItalic: obj?.fontStyle === 'italic',
  isUnderline: obj?.textDecoration.split(' ').includes('underline'),
  isCrossed: obj?.textDecoration.split(' ').includes('line-through'),
});

const transformForSend = (state: PolygonTextStylesType) => {
  let decorationStr = '';

  if (state.isCrossed) {
    decorationStr = 'line-through';
  }

  if (state.isUnderline) {
    decorationStr = 'underline';
  }

  if (state.isCrossed && state.isUnderline) {
    decorationStr = 'underline line-through';
  }

  return {
    fontSize: state.fontSize,
    textAlign: state.textAlign,
    fontWeight: state.isBold ? 'bold' : 'normal',
    fontStyle: state.isItalic ? 'italic' : 'normal',
    textDecoration: decorationStr,
  };
};

export const MenuTextActions: FC<MenuTextActionsType> = ({
  onMenuTextActionsSubmit,
  blockId,
  initialValues,
  setIsEditState,
  changeParameter,
  selectRect,
  refScroll,
}) => {
  const { handleSetOptionsStatus, optionsStatus } = useModalContext();

  const [isTextSizeMenuOpen, setIsTextSizeMenuOpen] = useState(false);

  const [styleState, setStyleState] = useState<PolygonTextStylesType>(
    transformInitialValues(initialValues),
  );

  const handleSubmit = (values: FormikValues) => {
    onMenuTextActionsSubmit(values);
    setIsEditState(false);
    handleSetOptionsStatus(OptionsStatuses.WITHOUT_STATUS);
  };

  const changeParam = (
    key: PolygonTextStylesOptionsType,
    value: string | number | boolean,
  ) => {
    setStyleState({
      ...styleState,
      [key]: value,
    });
  };

  useEffect(() => {
    blockId &&
      changeParameter({
        id: blockId,
        key: 'textObj',
        value: transformForSend(styleState),
      });
  }, [styleState]);

  const top = selectRect?.y - 75 - refScroll?.current?.scrollTop || 0;
  const left = selectRect?.x - refScroll?.current?.scrollLeft || 0;

  return (
    <PolygonActionsWrapper
      isVisible={optionsStatus === OptionsStatuses.EDIT_TEXT_MENU}
      style={{
        top: calcTopMenu({ top, height: selectRect?.height }),
        left: calcLeftMenu({ shift: 600, left }),
      }}
    >
      <ArrowLeftIcon
        className='arrow-left_hovered'
        color='#FFFFFF'
        onClick={() => handleSubmit(styleState)}
      />
      <div className='polygon-actions-wrapper-line' />
      <div
        className='menu-text-actions__font-size-select'
        onClick={() => setIsTextSizeMenuOpen(!isTextSizeMenuOpen)}
      >
        <TextSizeIcon className='size-icon' />
        <span>{styleState.fontSize}</span>
        <ArrowSmallIcon
          className={`arrow-small-icon ${
            isTextSizeMenuOpen ? 'arrow-small-icon_open' : ''
          }`}
        />
        <OptionsMenu
          onClickCallback={(value) => changeParam('fontSize', parseInt(value, 10))}
          isOpen={isTextSizeMenuOpen}
          options={polygonBlockTextFontSizes}
        />
      </div>
      <div className='menu-text-actions__font-styles'>
        <BoldTextIcon
          className={styleState.isBold ? 'bold-text-icon_active' : 'bold-text-icon'}
          onClick={() => changeParam('isBold', !styleState.isBold)}
        />
        <CursiveTextIcon
          height='20px'
          className={
            styleState.isItalic ? 'cursive-text-icon_active' : 'cursive-text-icon'
          }
          onClick={() => changeParam('isItalic', !styleState.isItalic)}
        />
        <UnderlineTextIcon
          width='30px'
          height='34px'
          className={
            styleState.isUnderline ? 'underline-text-icon_active' : 'underline-text-icon'
          }
          onClick={() => changeParam('isUnderline', !styleState.isUnderline)}
        />
        <CrossedTextIcon
          className={styleState.isCrossed ? 'bold-text-icon_active' : 'bold-text-icon'}
          onClick={() => changeParam('isCrossed', !styleState.isCrossed)}
        />
      </div>
      <div className='menu-text-actions__align'>
        <LeftAlignTextIcon
          className={
            styleState.textAlign === 'left' ? 'bold-text-icon_active' : 'bold-text-icon'
          }
          onClick={() => changeParam('textAlign', 'left')}
        />
        <CenterAlignTextIcon
          className={
            styleState.textAlign === 'center' ? 'bold-text-icon_active' : 'bold-text-icon'
          }
          onClick={() => changeParam('textAlign', 'center')}
        />
        <LeftAlignTextIcon
          onClick={() => changeParam('textAlign', 'right')}
          className={
            styleState.textAlign === 'right'
              ? 'right-align-text-icon_active'
              : 'right-align-text-icon'
          }
        />
      </div>
    </PolygonActionsWrapper>
  );
};
