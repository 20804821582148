import * as yup from 'yup';

import * as MESSAGES from '../messages';

export const editName = yup.object().shape({
  fullName: yup
    .string()
    .matches(/^[A-Za-z0-9А-я_@./#&+\-\s]*$/, MESSAGES.INCORRECT_NAME)
    .max(20, MESSAGES.INCORRECT_MAX_LENGTH_20),
});
