import './UpdateFileScaleModal.scss';

import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import Select from 'react-select';

import { selectorStyle } from '../../../../constants/constants';
import { useOptionScrollLoad } from '../../../../hooks';
import { addFileTaskSchema } from '../../../../validation/schemas';
import { FileMoveIcons, RemoveIconBlack } from '../../../icons/index';
import { AppInput, Button, ColorPicker, Switch } from '../../../index';

type UpdateFileScaleModalType = {
  isOpen: boolean;
  onClose: () => void;
  modalParameters?: any;
};

export const UpdateFileScaleModal = ({
  onClose,
  modalParameters,
}: UpdateFileScaleModalType) => {
  const [fileChange, setFileChange] = useState(false);
  const { setPageNumberState, options } = useOptionScrollLoad();
  return (
    <div className='UpdateFileScaleModal'>
      <Formik
        initialValues={modalParameters?.initialValues || { name: '' }}
        onSubmit={(e) => {
          modalParameters?.onCallback(e);
          onClose();
        }}
        validationSchema={addFileTaskSchema}
      >
        {({ setFieldValue, errors, values }) => {
          return (
            <>
              {!fileChange ? (
                <Form style={{ width: 'auto' }}>
                  <div style={{ marginTop: '10px' }}>
                    <AppInput
                      name={'name'}
                      label='Название:'
                      placeholder='Введите название'
                    />
                  </div>
                  <Button
                    text={'Изменить файл'}
                    type='submit'
                    width='100%'
                    height='25px'
                    margin='5px 0 0 0'
                  />
                  <Button
                    text={' Удалить файл'}
                    width='100%'
                    height='25px'
                    margin='15px 0 0 0'
                    onClick={() => {
                      modalParameters.onCallbackDelete();
                      onClose();
                    }}
                  >
                    <RemoveIconBlack className='icons' />
                  </Button>
                  {!modalParameters?.selfLocation && (
                    <Button
                      text={'Перенести файл'}
                      width='100%'
                      height='25px'
                      margin='15px 0 0 0'
                      onClick={() => {
                        setFileChange(true);
                      }}
                    >
                      <FileMoveIcons />
                    </Button>
                  )}
                  <Button
                    text={
                      modalParameters?.initialValues?.isArchived
                        ? 'Убрать из архива'
                        : 'Добавить в архив'
                    }
                    width='100%'
                    height='25px'
                    margin='15px 0 0 0'
                    onClick={() => {
                      modalParameters.onCallbackisArchived();
                      onClose();
                    }}
                  />
                  {modalParameters?.selfLocation && (
                    <Button
                      text={
                        modalParameters?.initialValues?.isBridgeOfSleep
                          ? 'Убрать мост как “Сон”'
                          : 'Сделать мостом как “Сон”'
                      }
                      width='100%'
                      height='25px'
                      margin='15px 0 0 0'
                      onClick={() => {
                        modalParameters?.onCallbackSleepFile();
                        onClose();
                      }}
                    ></Button>
                  )}
                  {!modalParameters?.selfLocation && (
                    <Button
                      text={
                        modalParameters?.initialValues?.isPersonal
                          ? 'Сделать файл публичным'
                          : 'Сделать файл персональным'
                      }
                      width='100%'
                      height='25px'
                      margin='15px 0 0 0'
                      onClick={() => {
                        modalParameters?.onCallbackPesonalFile();
                        onClose();
                      }}
                    ></Button>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <ColorPicker
                      colorForm={values?.calendarTaskTextColor}
                      setColorForm={(e: string) => {
                        setFieldValue('calendarTaskTextColor ', e);
                      }}
                      submit={(e: string) => {
                        modalParameters?.onCallback({
                          ...values,
                          calendarTaskTextColor: e,
                        });
                        onClose();
                      }}
                    />
                    {values?.calendarTaskTextColor && (
                      <div
                        style={{
                          display: 'flex',
                          marginTop: '8px',
                          cursor: 'pointer',
                        }}
                      >
                        <div className='mr5'>{values?.calendarTaskTextColor}</div>
                        <div
                          onClick={() => {
                            modalParameters?.onCallback({
                              ...values,
                              calendarTaskTextColor: '',
                            });
                            onClose();
                          }}
                        >
                          <RemoveIconBlack />
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '5px',
                    }}
                  >
                    <div style={{ marginRight: '5px' }}>
                      Показывать выполнено по умолчанию
                    </div>
                    <Switch
                      value={values?.isStatus1ExcludedInFilter}
                      handleChange={() => {
                        modalParameters?.onCallbackisStatus1ExcludedInFilter();
                        onClose();
                      }}
                    />
                  </div>
                </Form>
              ) : (
                <Form style={{ width: 'auto' }}>
                  <div
                    style={{
                      border: errors?.projectId ? '1px solid red' : '',
                    }}
                  >
                    <Select
                      styles={selectorStyle}
                      options={options}
                      defaultValue={
                        modalParameters?.initialValues && {
                          value: modalParameters?.initialValues?.projectId,
                          label: modalParameters?.initialValues?.projectName,
                        }
                      }
                      placeholder='Выберете проект'
                      onChange={(option) => setFieldValue('projectId', option?.value)}
                      isClearable
                      onMenuScrollToBottom={() => {
                        setPageNumberState((prev) => prev + 1);
                      }}
                    />
                  </div>

                  <Button
                    text={'Изменить привязку к проекту'}
                    onClick={() => {
                      modalParameters.onCallbackChangeFile({
                        projectId: values?.projectId,
                      });
                      onClose();
                    }}
                    width='100%'
                    height='25px'
                    margin='15px 0 0 0'
                  />
                </Form>
              )}
            </>
          );
        }}
      </Formik>
    </div>
  );
};
