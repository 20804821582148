import './ResponsibleUser.scss';

import { useFormikContext } from 'formik';
import React, { ChangeEvent, FC, useState } from 'react';

import { FindUsersQuery, useFindUsersQuery } from '../../graphql/generated/graphql';
import { useDebounce } from '../../hooks';
import { getUserAvatar } from '../../utils';
import { CrossIcon } from '../icons';

type Props = {
  name: string;
  setIsSelect: () => void;
  usersData?: FindUsersQuery;
};

export const ResponsibleUser: FC<Props> = ({ name, setIsSelect }) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const { setFieldValue } = useFormikContext();

  const { debouncedValue } = useDebounce(searchValue);

  const { data } = useFindUsersQuery({
    variables: {
      input: {
        search: debouncedValue,
        sortBy: 'updatedAt',
        sortType: 'ASC',
      },
    },
  });

  const changeSearchValueHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value);
  };

  const onSetFieldValue = (
    field: string,
    id: number | null | undefined,
    login: string | null | undefined,
    image: string | null | undefined,
  ) => {
    setFieldValue(field, {
      id: id,
      name: login,
      image: image,
    });
    setIsSelect();
  };

  return (
    <div className='chooseUser__main'>
      <div className='chooseUser__title'>
        <input
          type='text'
          placeholder='Имя пользователя'
          value={searchValue}
          onChange={changeSearchValueHandler}
        />
      </div>
      <div className='find__users-list'>
        {data?.getAllUsers.rows[0] ? (
          data?.getAllUsers.rows.map((user) => {
            if (user) {
              return (
                <div key={user.id} className='chooseUser__result'>
                  <div className='chooseUser__info'>
                    <img src={getUserAvatar(user.image)} alt='avatar' />
                    <p>{user.login}</p>
                  </div>
                  <div
                    className='list__item-add'
                    onClick={() => onSetFieldValue(name, user.id, user.login, user.image)}
                  >
                    <CrossIcon color='#AFAFAF' />
                  </div>
                </div>
              );
            }
          })
        ) : (
          <div className='empty__users-list'>Нет пользователя с таким логином</div>
        )}
      </div>
    </div>
  );
};
