import './Files.scss';

import { Formik, FormikValues } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { clip, clipSelected } from '../../assets/icons';
import {
  CrossIcon,
  EditIcon,
  MainContentHeaderItem,
  RequestHandler,
} from '../../components';
import { BASE_URL, ROLES } from '../../constants/constants';
import { useAuthContext, useChatContext } from '../../context';
import {
  LoadFileInput,
  SortingField,
  SortingOrders,
  StorageFilterItems,
  TrackActivityTypeEnum,
} from '../../graphql/generated/graphql';
import { GET_STORAGE_PROJECTS } from '../../graphql/queries';
import {
  useDeleteFileMutation,
  useLoadFileMutation,
  useUpdateFileMutation,
} from '../../hooks/useMutations';
import { useGetAllFilesQuery } from '../../hooks/useQueries';
import { fileTitleSchema } from '../../validation/schemas';
import { SimpleFormModal } from '../simpleFormModal/SimpleFormModal';

type FilesPropsType = {
  backgroundColor: string;
};

export const Files: React.FC<FilesPropsType> = ({ backgroundColor }) => {
  const [hoveredClip, setHoveredClip] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [fileData, setFileData] = useState({ id: '', fileName: '' });

  const { taskId, subtaskId, privateChatId } = useParams();

  const { sendMessageHandler, chatId, dataSubscription } = useChatContext();

  const [loadFile] = useLoadFileMutation();

  const [deleteFile] = useDeleteFileMutation();

  const [updateFile, { loading: loadingFile }] = useUpdateFileMutation();

  const { currentUserData } = useAuthContext();

  const controllAcess =
    currentUserData?.role === ROLES.ADMIN || currentUserData?.role === ROLES.MODERATOR;

  const refetchStorageQueryParams = [
    {
      query: GET_STORAGE_PROJECTS,
      variables: {
        projectsData: {
          limit: 100,
          sort: {
            type: SortingOrders.Asc,
            field: SortingField.Number,
          },
          storageFilter: [StorageFilterItems.All],
        },
        search: '',
        storageFilter: [StorageFilterItems.All],
        tasksSubtasksData: {
          limit: 100,
          isTaskStorage: true,
          sort: {
            type: SortingOrders.Asc,
            field: SortingField.Number,
          },
        },
      },
    },
  ];

  const { data, refetch } = useGetAllFilesQuery({
    skip: privateChatId && !chatId,
    variables: {
      data: subtaskId
        ? { subtaskId: Number(subtaskId) }
        : privateChatId
        ? { chatId: Number(chatId) }
        : { taskId: Number(taskId) },
    },
  });

  useEffect(() => {
    if (
      dataSubscription?.privateChatActivity?.type === TrackActivityTypeEnum.FileAdded ||
      dataSubscription?.privateChatActivity?.type === TrackActivityTypeEnum.FileDeleted
    ) {
      refetch();
    }
  }, [dataSubscription]);
  const handleLoadFileSubmit = async (values: LoadFileInput) => {
    setIsLoading(true);
    await loadFile({
      variables: {
        data: subtaskId
          ? {
              file: values.file,
              subtaskId: Number(subtaskId),
            }
          : privateChatId
          ? {
              file: values.file,
              chatId: Number(chatId),
            }
          : {
              file: values.file,
              taskId: Number(taskId),
            },
      },
      refetchQueries: refetchStorageQueryParams,
    });
    setIsLoading(false);
  };

  const handleDeleteFile = async (id: number, fileName: string) => {
    await deleteFile({
      variables: {
        id: id,
      },
      refetchQueries: refetchStorageQueryParams,
      onCompleted: () => {
        refetch();
      },
    });
  };

  const handleSubmitFileTitle = async (values: FormikValues) => {
    setIsModalOpen(false);
    await updateFile({
      variables: {
        id: fileData.id,
        data: { fileName: values.fileTitle },
      },
      refetchQueries: refetchStorageQueryParams,
      onCompleted: (res) => {
        if (res.updateFile.id) {
          setIsModalOpen(false);
          toast.success('Файл успешно изменен!');
        }
      },
    });
  };

  const handleClipHover = (value: boolean) => {
    setHoveredClip(value);
  };

  const intFrameHeight = window.innerHeight;

  return (
    <div className='files'>
      <MainContentHeaderItem fullWidth title='Файлы' style={{ fontWeight: 'normal' }} />
      <div className='main' style={{ backgroundColor }}>
        {controllAcess && (
          <>
            <Formik initialValues={{ file: '' }} onSubmit={handleLoadFileSubmit}>
              {({ handleSubmit, setFieldValue }) => (
                <div className='files__upload'>
                  <label
                    onMouseEnter={() => handleClipHover(true)}
                    onMouseLeave={() => handleClipHover(false)}
                  >
                    <input
                      type='file'
                      name='file'
                      multiple={false}
                      disabled={isLoading}
                      onChange={(e) => {
                        if (
                          e.currentTarget.files &&
                          e.currentTarget.files[0].size < 104857600
                        ) {
                          setFieldValue('file', e?.currentTarget?.files[0]);
                          handleSubmit();
                        } else {
                          toast.error('Вес файла не должен привышать 100мб!');
                        }
                      }}
                    />
                    <img src={!hoveredClip ? clip : clipSelected} alt='clip' />
                    <span>Загрузить файл</span>
                  </label>
                </div>
              )}
            </Formik>
            <div className='files__line' />
          </>
        )}

        <div
          className='files__list'
          style={intFrameHeight < 649 ? { height: '65px' } : {}}
        >
          <RequestHandler loading={isLoading || loadingFile}>
            {data?.getAllFiles?.rows
              .map((file) => {
                return (
                  <div key={file.id} className='list__item'>
                    <div className='list__item--left'>
                      <a
                        href={`${BASE_URL}${file.path}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        {file.fileName}
                      </a>
                      <div className='item__line' />
                      <span>{file.size.toFixed(1)} KB</span>
                    </div>
                    {controllAcess && (
                      <div className='list__item--buttons'>
                        <EditIcon
                          onClick={() => {
                            setFileData({
                              id: String(file.id),
                              fileName: file.fileName,
                            });
                            setIsModalOpen(true);
                          }}
                        />
                        <CrossIcon
                          onClick={() => handleDeleteFile(file.id, file.fileName)}
                          color='#AFAFAF'
                        />
                      </div>
                    )}
                  </div>
                );
              })
              .reverse()}
          </RequestHandler>
        </div>
      </div>
      <SimpleFormModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        validationSchema={fileTitleSchema}
        onSubmit={handleSubmitFileTitle}
        initialValues={{ fileTitle: fileData.fileName }}
        buttonText='Сохранить'
        buttonType='submit'
        inputName='fileTitle'
        labelText='Введите название:'
      />
    </div>
  );
};
