import './AccesStorageModal.scss';

import React, { useState } from 'react';

import { useGetProjectsStorageAccesQuery } from '../../../../graphql/generated/graphql';
import { Button } from '../../../button/Button';
import Checkbox from '../../../checkbox/Checkbox';

export const AccesStorageModal = ({ onClose, modalParameters }: any) => {
  const { data } = useGetProjectsStorageAccesQuery({
    variables: {
      data: {},
    },
  });

  const allProjects = data?.getProjects?.rows;

  const [selectedProjects, setSelectedProjects] = useState<number[]>(
    modalParameters?.initialValues as any,
  );

  const handleCheckboxChange = (projectId: number) => {
    setSelectedProjects((prevSelected) =>
      prevSelected.includes(projectId)
        ? prevSelected.filter((id) => id !== projectId)
        : [...prevSelected, projectId],
    );
  };

  const handleSaveAllData = () => {
    modalParameters?.onCallback(selectedProjects);
    onClose();
  };

  return (
    <div className='accesStorageModal'>
      <div className='accesStorageModal__title'>Доступ к хранилищу проектов</div>
      <div className='accesStorageModal__items'>
        {allProjects?.map((e: any) => {
          return (
            <Item
              key={e?.id}
              e={e}
              isChecked={selectedProjects.includes(e?.id)}
              onCheckboxChange={() => handleCheckboxChange(e?.id)}
            />
          );
        })}
      </div>
      <Button onClick={handleSaveAllData} margin={'10px 0px 0px 0px'}>
        Сохранить
      </Button>
    </div>
  );
};

const Item = ({ e, isChecked, onCheckboxChange }: any) => {
  return (
    <div key={e?.id} className='accesStorageModal__item'>
      {e?.name}
      <Checkbox isChecked={isChecked} setIsChecked={onCheckboxChange} className='ml10' />
    </div>
  );
};
