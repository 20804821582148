import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const UserStatisticIcons: FC<UserStatisticIconsType> = ({
  width = '20px',
  height = '20px',
  color = '#FFFFFF',
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        d='M21 6H22.5V12H21V6ZM17.25 3.75H18.75V12H17.25V3.75ZM13.5 7.5H15V12H13.5V7.5ZM6.75 6.75C7.19501 6.75 7.63002 6.88196 8.00003 7.12919C8.37004 7.37643 8.65843 7.72783 8.82873 8.13896C8.99903 8.5501 9.04358 9.0025 8.95677 9.43895C8.86995 9.87541 8.65566 10.2763 8.34099 10.591C8.02632 10.9057 7.62541 11.12 7.18895 11.2068C6.7525 11.2936 6.3001 11.249 5.88896 11.0787C5.47783 10.9084 5.12643 10.62 4.87919 10.25C4.63196 9.88002 4.5 9.44501 4.5 9C4.5 8.40326 4.73705 7.83097 5.15901 7.40901C5.58097 6.98705 6.15326 6.75 6.75 6.75ZM6.75 5.25C6.00832 5.25 5.2833 5.46993 4.66661 5.88199C4.04993 6.29404 3.56928 6.87971 3.28545 7.56494C3.00162 8.25016 2.92736 9.00416 3.07206 9.73159C3.21675 10.459 3.5739 11.1272 4.09835 11.6517C4.6228 12.1761 5.29098 12.5333 6.01841 12.6779C6.74584 12.8226 7.49984 12.7484 8.18506 12.4645C8.87029 12.1807 9.45596 11.7001 9.86801 11.0834C10.2801 10.4667 10.5 9.74168 10.5 9C10.5 8.50754 10.403 8.01991 10.2145 7.56494C10.0261 7.10997 9.74987 6.69657 9.40165 6.34835C9.05343 6.00013 8.64003 5.72391 8.18506 5.53545C7.73009 5.347 7.24246 5.25 6.75 5.25Z'
        fill='white'
      />
      <path
        d='M12 22.5H10.5H3H1.5V18C1.50119 17.0058 1.89666 16.0527 2.59966 15.3497C3.30267 14.6467 4.2558 14.2512 5.25 14.25H8.25C9.2442 14.2512 10.1973 14.6467 10.9003 15.3497C11.6033 16.0527 11.9988 17.0058 12 18V22.5Z'
        fill='white'
      />
      <path
        d='M6.75 6.75C7.19501 6.75 7.63002 6.88196 8.00003 7.12919C8.37004 7.37643 8.65843 7.72783 8.82873 8.13896C8.99903 8.5501 9.04358 9.0025 8.95677 9.43895C8.86995 9.87541 8.65566 10.2763 8.34099 10.591C8.02632 10.9057 7.62541 11.12 7.18895 11.2068C6.7525 11.2936 6.3001 11.249 5.88896 11.0787C5.47783 10.9084 5.12643 10.62 4.87919 10.25C4.63196 9.88002 4.5 9.44501 4.5 9C4.5 8.40326 4.73705 7.83097 5.15901 7.40901C5.58097 6.98705 6.15326 6.75 6.75 6.75Z'
        fill='white'
      />
    </svg>
  );
};

type UserStatisticIconsType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
>;
