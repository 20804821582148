import './ColorStatusItemModal.scss';

import React, { useState } from 'react';

import { Button } from '../../../index';

export const ColorStatusItemModal = ({ onClose, modalParameters }: any) => {
  const [status, setStatus] = useState(modalParameters?.initialValues?.statusId);

  const statusData = [
    { id: 1, backgroundColor: '#FF0000', text: 'Важно и срочно' },
    { id: 2, backgroundColor: '#EC8D71', text: 'Важно, но не срочно' },
    { id: 3, backgroundColor: '#F1BD6C', text: 'Не важно, но срочно' },
    { id: 4, backgroundColor: '#6D6E6F', text: 'Не важно и не срочно' },
    {
      id: 5,
      backgroundColor: '#000000',
      text: 'Курирует ассистент',
    },
    {
      id: 6,
      backgroundColor: '#4ECBC4',
      text: 'Стратегические задачи',
    },
    { id: null, backgroundColor: 'white', text: 'Убрать статус' },
  ];

  return (
    <div className='ColorStatusItemModal'>
      <div className='ColorStatusItemModal__inner'>
        {statusData?.map((e) => {
          if (!modalParameters?.initialValues?.show && !e?.id) {
            return;
          }
          return (
            <div
              key={e?.id}
              className='ColorStatusItemModal__item'
              style={{
                border: status === e?.id ? '1px solid black' : '',
              }}
              onClick={() => {
                setStatus(e?.id);
              }}
            >
              <div
                className='ColorStatusItemModal__status'
                style={{
                  backgroundColor: e?.backgroundColor,
                }}
              ></div>
              <div>{e?.text}</div>
            </div>
          );
        })}
      </div>
      {modalParameters?.initialValues?.show && (
        <Button
          onClick={() => {
            if (status === undefined) {
              onClose();
              return;
            }
            modalParameters?.onCallback(status);
            onClose();
          }}
        >
          Сохранить
        </Button>
      )}
    </div>
  );
};

// const statusDataOld = [
//   { id: 1, backgroundColor: '#FFA500', text: 'Важно и срочно' },
//   { id: 2, backgroundColor: '#008080', text: 'Важно, но не срочно' },
//   { id: 3, backgroundColor: '#4E89E1', text: 'Не важно, но срочно' },
//   { id: 4, backgroundColor: '#FAF2A7', text: 'Не важно и не срочно' },
//   {
//     id: 5,
//     backgroundColor: '#303030',
//     text: 'Курирует ассистент',
//   },
//   {
//     id: 6,
//     backgroundColor: '#DC143C',
//     text: 'Стратегические задачи',
//   },
//   { id: null, backgroundColor: 'white', text: 'Убрать статус' },
// ];
