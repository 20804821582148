import './Subtask.scss';

import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  Chat,
  Executors,
  Files,
  MainContentHeaderItem,
  MainContentHeaderTitle,
  Notes,
  Palette,
  RequestHandler,
} from '../../components';
import { CrossIcon, EditIcon, PersonalIcons, PolygonIcon } from '../../components/icons';
import { StartButton } from '../../components/startButton';
import { ChatProvider, useAuthContext, useModalContext } from '../../context';
import { useReadProjectActivitiesMutation } from '../../hooks/useMutations';
import { useGetSubtaskQuery, useGetTaskAssigneesQuery } from '../../hooks/useQueries';
import { ModalStatuses } from '../../types/enums';

export const Subtask: React.FC = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { data: assignees } = useGetTaskAssigneesQuery();
  const { currentUserData } = useAuthContext();

  const { taskId, subtaskId } = useParams();

  const [readProjectActivitiesMutation] = useReadProjectActivitiesMutation();

  const { data, loading, error, refetch } = useGetSubtaskQuery();

  const executorsRef = useRef<HTMLDivElement | null>();

  const [toggleExecutors, setToggleExecutors] = useState<boolean>(true);

  const [executorsHeight, setExecutorsHeight] = useState<number>(223);
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  const handleShowExecutors = () => setToggleExecutors(!toggleExecutors);

  useEffect(() => {
    if (executorsRef && executorsRef.current?.offsetHeight) {
      setExecutorsHeight(executorsRef.current?.offsetHeight);
    }
  });

  useEffect(() => {
    readProjectActivitiesMutation({
      variables: {
        subtaskId: Number(subtaskId),
      },
    });
    return () => {
      readProjectActivitiesMutation({
        variables: {
          subtaskId: Number(subtaskId),
        },
      });
    };
  }, [subtaskId]);

  const isArchivedPage = location.pathname.includes('archive');

  const handleGoBack = () => {
    navigate(isArchivedPage ? `/archivedTask/${taskId}` : `/task/${taskId}`);
  };

  const participant = data?.getSubtask?.maintainer?.id === currentUserData?.id;

  return (
    <div className='container'>
      <div className='subtask'>
        <RequestHandler loading={loading} error={error}>
          <ChatProvider>
            <div className='subtask__body'>
              <div className='subtask__header'>
                <div className='subtask__header-left'>
                  <div style={{ display: 'flex', width: '100%' }}>
                    {participant && <PersonalIcons className='mr10' />}

                    <MainContentHeaderTitle
                      title={`#${data?.getSubtask?.code} ${data?.getSubtask?.name}`}
                    />
                  </div>
                  <div className='subtask__header-left-actions'>
                    {data?.getSubtask.poligonObject?.id && (
                      <PolygonIcon
                        color='#303030'
                        onClick={() => {
                          navigate(
                            `/polygon/${data.getSubtask.task.project.id}/${data?.getSubtask.poligonObject?.id}`,
                          );
                        }}
                      />
                    )}
                    <>
                      <div
                        onClick={() => {
                          handleSetModalStatus(ModalStatuses.EDIT_TITLE);
                          handleChangeModalParameters([
                            {
                              key: 'initialValues',
                              value: {
                                initialTitle: data?.getSubtask.name,
                                initialStartDate: data?.getSubtask.startDate,
                                initialCloseDate: data?.getSubtask.closeDate,
                                initialEndDate: data?.getSubtask?.endDate,
                                taskId: taskId,
                                label: 'Название задачи:',
                                initialTagIds: data?.getSubtask?.tags?.rows?.map(
                                  ({ id }) => String(id),
                                ),
                                entityId: Number(subtaskId),
                                subtaskId: subtaskId,
                              },
                            },
                          ]);
                        }}
                      >
                        <EditIcon
                          width={13}
                          height={13}
                          color={'black'}
                          className='mr10'
                        />
                      </div>
                      <div
                        onClick={() => {
                          handleSetModalStatus(ModalStatuses.DELETE_TASK);
                          handleChangeModalParameters([
                            {
                              key: 'initialValues',
                              value: {
                                confirmTitle: data?.getSubtask.name,
                                taskId: taskId,
                                subtaskId: subtaskId,
                                projectId: data?.getSubtask.task.project.id,
                                entityId: Number(subtaskId),
                              },
                            },
                          ]);
                        }}
                      >
                        <CrossIcon
                          width={13}
                          height={13}
                          color={'gray'}
                          className='mr10'
                        />
                      </div>
                    </>
                    <Palette statusId={data?.getSubtask?.status?.id} />
                    {taskId && (
                      <div className='ml10'>
                        <StartButton
                          entityId={Number(subtaskId)}
                          startDate={data?.getSubtask.startDate}
                          closeDate={data?.getSubtask.closeDate}
                          status={parseInt(data?.getSubtask?.status?.id as any)}
                          refetch={refetch}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className='subtask__header-right'>
                  <MainContentHeaderItem
                    fullWidth
                    title={'Перейти к основной задаче →'}
                    onClick={handleGoBack}
                    isButton
                  />
                </div>
              </div>
              <div className='line' />
              <div className='subtask__content'>
                <div className='subtask__content-left'>
                  <Chat />
                </div>
                <div className='subtask__content-right'>
                  <Executors
                    maintainer={data?.getSubtask.maintainer}
                    taskMaintainer={data?.getSubtask.task.maintainer}
                    executorsRef={executorsRef}
                    toggleExecutors={toggleExecutors}
                    variant={'subtask'}
                    handleShowExecutors={handleShowExecutors}
                    assignees={assignees}
                  />
                  <Files backgroundColor={'#E4EFFF'} />
                  <Notes
                    backgroundColor={'#E4EFFF'}
                    executorsHeight={executorsHeight}
                    maintainer={data?.getSubtask.maintainer}
                  />
                </div>
              </div>
            </div>
          </ChatProvider>
        </RequestHandler>
      </div>
    </div>
  );
};
