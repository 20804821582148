import React from 'react';

import { Button } from '../../../index';

export const ChatDeleteModal = ({ onClose, modalParameters }: any) => {
  return (
    <div
      style={{
        padding: '20px',
      }}
    >
      <Button
        margin={'0px 0px 10px 0px'}
        onClick={() => {
          modalParameters.onCallback();
          onClose();
        }}
      >
        Удалить переписку
      </Button>
      <Button onClick={onClose}>Отмена</Button>
    </div>
  );
};
