import * as yup from 'yup';

import * as MESSAGES from '../messages';

export const addFileTaskStatusSchema = yup.object().shape({
  status: yup.number().required(MESSAGES.REQUIRE_MESSAGE),
  name: yup
    .string()
    .min(1, MESSAGES.INCORRECT_MIN_LENGTH_1)
    .required(MESSAGES.REQUIRE_MESSAGE),
});

export const addFileTaskStatusSchemaCol1 = yup.object().shape({
  status: yup.number().required(MESSAGES.REQUIRE_MESSAGE),
  name: yup
    .string()
    .min(1, MESSAGES.INCORRECT_MIN_LENGTH_1)
    .max(30, MESSAGES.INCORRECT_MAX_LENGTH_30)
    .required(MESSAGES.REQUIRE_MESSAGE),
});
