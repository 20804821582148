import 'react-tooltip/dist/react-tooltip.css';

import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { TOOL_TIP_STATUS } from '../../constants/constants';

type TooltipStatusType = {
  anchorId: string;
  id: number | string;
};

export const TooltipStatus = ({ anchorId, id }: TooltipStatusType) => {
  return (
    <ReactTooltip
      anchorId={anchorId}
      place='bottom'
      content={`${TOOL_TIP_STATUS[id].title}`}
    />
  );
};
