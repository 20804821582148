import { useEffect } from 'react';

type useLoadFileCtrlVType = {
  callback: (value: any) => void;
  focused: boolean;
};

export const useLoadFileCtrlV = ({ callback, focused }: useLoadFileCtrlVType) => {
  useEffect(() => {
    if (focused) return;
    const paste = (event: any) => {
      const clipboardItems = event.clipboardData.items;
      const items = [].slice.call(clipboardItems).filter((item: any) => {
        return item.type.indexOf('image') !== -1;
      });
      if (items.length === 0) {
        return;
      }
      const item: any = items[0];
      const blob = item.getAsFile();

      const reader = new FileReader();
      reader.readAsDataURL(blob as any);
      reader.onload = function (e) {
        const image: any = new Image();
        image.src = e?.target?.result;
        image.onload = () => {
          callback(blob);
        };
      };
    };

    window.addEventListener('paste', paste);

    return () => {
      window.removeEventListener('paste', paste);
    };
  }, [callback]);
};
