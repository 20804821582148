import React from 'react';

export const ClockIcons2 = ({ className, style }: any) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      style={style}
    >
      <path
        d='M8.00065 1.33331C11.6827 1.33331 14.6673 4.31798 14.6673 7.99998C14.6673 11.682 11.6827 14.6666 8.00065 14.6666C4.31865 14.6666 1.33398 11.682 1.33398 7.99998C1.33398 4.31798 4.31865 1.33331 8.00065 1.33331ZM8.00065 2.26331C6.47919 2.26331 5.02005 2.86771 3.94422 3.94354C2.86838 5.01938 2.26398 6.47852 2.26398 7.99998C2.26398 9.52144 2.86838 10.9806 3.94422 12.0564C5.02005 13.1322 6.47919 13.7366 8.00065 13.7366C9.52211 13.7366 10.9813 13.1322 12.0571 12.0564C13.1329 10.9806 13.7373 9.52144 13.7373 7.99998C13.7373 6.47852 13.1329 5.01938 12.0571 3.94354C10.9813 2.86771 9.52211 2.26331 8.00065 2.26331ZM7.38065 5.05398C7.63732 5.05398 7.84532 5.26265 7.84532 5.51931V8.66665L10.9617 7.98756C11.082 7.99211 11.1958 8.0431 11.2793 8.1298C11.3628 8.21651 11.4094 8.33219 11.4094 8.45256C11.4094 8.57292 11.3628 8.68861 11.2793 8.77531C11.1958 8.86202 11.082 8.913 10.9617 8.91756L7.38065 9.70531C7.25724 9.70531 7.13888 9.65629 7.05161 9.56902C6.96434 9.48175 6.91532 9.36339 6.91532 9.23998V5.51998C6.91532 5.26265 7.12332 5.05398 7.38065 5.05398Z'
        fill='#AFAFAF'
      />
    </svg>
  );
};
