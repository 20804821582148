import './TredsPagination.scss';

import React from 'react';

import { ArrowNext, ArrowPrev } from '../../icons';

export const TredsPagination = ({
  totalItems,
  limit,
  setCurrentPage,
  currentPage,
}: any) => {
  const totalPages = Math.ceil(totalItems / limit);

  const handlePageClick = (page: any) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    if (totalPages <= 3) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 2) {
        for (let i = 1; i <= 3; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      } else if (currentPage >= totalPages - 1) {
        pageNumbers.push(1);
        pageNumbers.push('...');
        for (let i = totalPages - 2; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        pageNumbers.push(1);
        if (currentPage > 3) {
          pageNumbers.push('...');
        }
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pageNumbers.push(i);
        }
        if (currentPage < totalPages - 2) {
          pageNumbers.push('...');
        }
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers.map((number, index) => {
      if (number === '...') {
        return (
          <div key={index} className='TredsPagination__button'>
            ...
          </div>
        );
      }

      return (
        <div
          key={index}
          className={`TredsPagination__button ${
            currentPage === number ? 'TredsPagination__active' : ''
          }`}
          onClick={() => handlePageClick(number)}
        >
          {number}
        </div>
      );
    });
  };

  return (
    <div className='TredsPagination'>
      <div
        onClick={() => {
          if (currentPage !== 1) {
            handlePageClick(currentPage - 1);
          }
        }}
        className='TredsPagination__button'
      >
        <ArrowPrev />
      </div>
      {renderPageNumbers()}
      <div
        onClick={() => {
          if (currentPage !== totalPages) {
            handlePageClick(currentPage + 1);
          }
        }}
        className='TredsPagination__button'
      >
        <ArrowNext />
      </div>
    </div>
  );
};
