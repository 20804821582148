import './PolygonArrowColorItem.scss';

import React, { FC, useRef, useState } from 'react';

import { useClickOutside } from '../../../hooks/useClickOutside';
import { ColorType } from '../../../types/types';

export const PolygonArrowColorItem: FC<PolygonArrowColorItem> = ({
  initialColorId,
  colors,
  onClick,
  onClose,
}) => {
  const ref = useRef(null);

  useClickOutside(ref, () => {
    onClose && onClose();
  });

  const [activeId, setActiveId] = useState(initialColorId);

  const onClickHandler = (color?: any) => {
    if (color) {
      setActiveId(color.id);
      onClick && onClick(color.hex);
    }
  };

  const transformedColors = colors.map((item: ColorType) => ({
    id: item.id,
    hex: item.color,
  }));

  return (
    <div className='polygon-arrow-color-item' ref={ref}>
      {transformedColors?.map((color: any, index: any) => {
        if (color.hex)
          return (
            <div
              key={color.hex + String(index)}
              className='polygon-arrow-color-item__item'
              style={
                activeId === color.id
                  ? {
                      border: '2px solid #303030',
                      backgroundColor: `${color.hex || '#AFAFAF'}`,
                    }
                  : {
                      marginTop: '7px',
                      backgroundColor: `${color.hex || '#AFAFAF'}`,
                    }
              }
              title={color.colorName}
              onClick={() => onClickHandler(color)}
            />
          );
      })}
    </div>
  );
};

type PolygonArrowColorItem = {
  colors?: any;
  onClick?: (color: string) => void;
  initialColorId?: number;
  onClose?: () => void;
};
