import 'rc-slider/assets/index.css';
import './DoubleSliderFormik.scss';

import Slider from 'rc-slider';
import React from 'react';

type DoubleSliderFormikType = {
  setFieldValue: (name: string, value: any) => void;
  values: any;
  min: number;
  max: number;
};

export const DoubleSliderFormik = ({
  setFieldValue,
  values,
  min,
  max,
}: DoubleSliderFormikType) => {
  return (
    <div className='DoubleSliderFormik'>
      <div className='DoubleSliderFormik__title'>Время</div>
      <div className='DoubleSliderFormik__inner'>
        <div
          className='DoubleSliderFormik__count'
          style={{
            marginRight: '8px',
          }}
        >
          {values?.hourFrom}
        </div>
        <Slider
          range
          min={min}
          max={max}
          defaultValue={[values?.hourFrom, values?.hourTo]}
          onChange={(values: any) => {
            setFieldValue('hourFrom', values[0]);
            setFieldValue('hourTo', values[1]);
          }}
        />
        <div
          className='DoubleSliderFormik__count'
          style={{
            marginLeft: '8px',
          }}
        >
          {values?.hourTo}
        </div>
      </div>
    </div>
  );
};
