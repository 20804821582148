import React, { FC } from 'react';

export const ArrowRightIcon: FC<ArrowRightIconType> = ({
  onClick,
  width = '8px',
  height = '12px',
  color = '#303030',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 8 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M1.36258 11.853L7.35381 6.38036C7.45885 6.27583 7.50336 6.13677 7.49636 6.00024C7.50336 5.8632 7.45885 5.72463 7.35381 5.6201L1.36258 0.147044C1.16603 -0.049015 0.846932 -0.049015 0.650364 0.147044C0.453795 0.343103 0.453815 0.66122 0.650364 0.857279L6.28103 6.00028L0.650363 11.1427C0.453814 11.3388 0.453814 11.6569 0.650363 11.853C0.846893 12.049 1.16601 12.049 1.36258 11.853Z'
        fill={color}
      />
    </svg>
  );
};

export type ArrowRightIconType = {
  onClick?: () => void;
  width?: string;
  height?: string;
  color?: string;
  className?: string;
};
