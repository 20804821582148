import './ChronometerReport.scss';

import moment from 'moment';
import React, { useState } from 'react';

import { useGetChronographDaysRecordsQuery } from '../../../../graphql/generated/graphql';
import { Button } from '../../../index';
import { DatePickerC } from '../reportChronographModal/DatePickerC';

export const ChronometerReport = () => {
  const [selectId, setSelectId] = useState('DatePicker');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const { data } = useGetChronographDaysRecordsQuery({
    variables: {
      data: {
        from: moment(startDate).format('YYYY-MM-DD'),
        to: moment(endDate).format('YYYY-MM-DD'),
      },
    },
  });

  return (
    <div className='ChronometerReport'>
      <div className='ChronometerReport__pickers'>
        <DatePickerC
          id={'DatePicker'}
          selectId={selectId}
          setSelectId={setSelectId}
          date={startDate}
          setDate={setStartDate}
        />
        <DatePickerC
          id={'DatePicker2'}
          selectId={selectId}
          setSelectId={setSelectId}
          date={endDate}
          setDate={setEndDate}
        />
      </div>
      <div className='ChronometerReport__buttonWrapper'>
        <Button
          onClick={() => {
            createTxtFile(
              data?.getChronographDaysRecords,
              `from ${moment(startDate).format('YYYY-MM-DD')} to ${moment(endDate).format(
                'YYYY-MM-DD',
              )}`,
            );
          }}
        >
          Скачать файл
        </Button>
      </div>
    </div>
  );
};

function createTxtFile(dataLogs: any, fileName: string) {
  let formattedText = '';

  dataLogs?.map((e: any) => {
    const date = e?.date;
    const cell1 = e?.cell1;
    if (cell1) {
      formattedText = formattedText + `${date} - Заметка 1 - ${cell1}\n`;
    }
    const cell2 = e?.cell2;
    if (cell2) {
      formattedText = formattedText + `${date} - Заметка 2 - ${cell2}\n`;
    }
    const cell3 = e?.cell3;
    if (cell3) {
      formattedText = formattedText + `${date} - Заметка 3 - ${cell3}\n`;
    }
  });
  const element = document.createElement('a');
  const file = new Blob([formattedText], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
