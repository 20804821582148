import './Expenses.scss';

import React from 'react';

import { RequestHandler } from '../../components';
import { ExpensesAccordion, ExpensesHeader } from '../../components/expenses';
import { useExpenses } from '../../hooks';

export const Expenses = () => {
  const { data, loading, changeMonth } = useExpenses();

  return (
    <div className='container'>
      <div className='expenses'>
        <div className='expenses__body'>
          <ExpensesHeader
            title='Расходы'
            changeMonth={changeMonth}
            amountPerMonth={data?.getExpenses.amountPerMonth}
            amountPerYear={data?.getExpenses.amountPerYear}
          />
          <RequestHandler loading={loading}>
            <ExpensesAccordion projects={data?.getExpenses.projects} />
          </RequestHandler>
        </div>
      </div>
    </div>
  );
};
