import './EditText.scss';

import React from 'react';
import { Text } from 'react-konva';
import { Html } from 'react-konva-utils';

import { polygonToolsChangeParameterType, RectType } from '../../../../types/types';

type EditTextType = {
  isEditMode: boolean;
  onChangeHandler: ({ id, key, value }: polygonToolsChangeParameterType) => void;
  rect: RectType;
};

export function EditText({ isEditMode, onChangeHandler, rect }: EditTextType) {
  const onChangeText = (value: string) => {
    onChangeHandler({ id: rect.id, key: 'name', value });
  };

  if (isEditMode) {
    return (
      <Html>
        <textarea
          className='editText__textarea'
          style={{
            width: rect.width,
            height: rect.height - 40,
            fontSize: rect.textObj.fontSize,
            fontWeight: rect.textObj.fontWeight,
            fontStyle: rect.textObj.fontStyle,
            textDecoration: rect.textObj.textDecoration,
            textAlign: rect.textObj.textAlign as any,
            backgroundColor: rect.fill,
          }}
          value={rect.name}
          onChange={(e) => onChangeText(e.target.value)}
        />
      </Html>
    );
  }
  return (
    <>
      <Text
        x={10}
        text={rect.name}
        fontFamily='Roboto Condensed, sans-serif'
        fontSize={rect.textObj.fontSize}
        fontWeight={rect.textObj.fontWeight}
        fontStyle={rect.textObj.fontStyle}
        align={rect.textObj.textAlign}
        textDecoration={rect.textObj.textDecoration}
        width={rect.width - 30}
      />
    </>
  );
}
