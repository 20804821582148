export function removeDuplicates(originalArray: Array<any>, prop: string) {
  const newArray = [];
  const lookupObject = {};

  for (const i in originalArray) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (const i in lookupObject) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    newArray.push(lookupObject[i]);
  }
  return newArray;
}
