import './ConfirmDeletionModal.scss';

import React from 'react';

import { Button } from '../../../button/Button';

export const ConfirmDeletionModal = ({ onClose, modalParameters }: any) => {
  return (
    <div className='confirmDeletionModal'>
      <div className='confirmDeletionModal__title'>
        {modalParameters?.initialValues?.title}
      </div>
      <Button onClick={onClose}>Отменить</Button>
      <Button
        onClick={() => {
          modalParameters?.onCallback();
          onClose();
        }}
        margin={'10px 0px 0px'}
      >
        Удалить
      </Button>
    </div>
  );
};
