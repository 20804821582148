import './AddPolygonObjects.scss';

import { Form, Formik } from 'formik';
import React from 'react';

import { AppInput } from '../../../appInput/AppInput';
import { Button } from '../../../index';

export const AddPolygonObjects = ({ modalParameters }: any) => {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ name: '' }}
      onSubmit={modalParameters.onCallback}
    >
      {() => {
        return (
          <Form className='AddPolygonObjects'>
            <AppInput name='name' label='Название:' />
            <Button
              text={'Создать'}
              type='submit'
              width='100%'
              height='25px'
              margin='20px 0 0 0'
            />
          </Form>
        );
      }}
    </Formik>
  );
};
