import './Quote.scss';

import React from 'react';

import { ForumMessage } from '../../../graphql/generated/graphql';
import { addKarma, dateParse, getUserAvatar, wrapContainer } from '../../../utils';
import { ForumQuoteIcons } from '../../icons';

type QuoteType = {
  message: ForumMessage;
};

export const Quote = ({ message }: QuoteType) => {
  return (
    <div className='TreadItem__quote'>
      <div className='TreadItem__quote-line'></div>
      <div className='TreadItem__quote-content'>
        <div className='TreadItem__quote-header'>
          <ForumQuoteIcons />
          <img
            src={getUserAvatar(message?.user?.image)}
            alt='add'
            className='TreadItem__quote-image'
          />
          <div className='TreadItem__quote-userData'>
            <span className='TreadItem__quote-user'>{addKarma(message?.user)}</span>
            <span className='TreadItem__quote-time'>
              {dateParse(message?.createdAt)?.date2},{' '}
              {dateParse(message?.createdAt)?.time}
            </span>
          </div>
        </div>
        <div
          className='TreadItem__quote-body'
          dangerouslySetInnerHTML={{ __html: wrapContainer(message?.text) }}
        ></div>
      </div>
    </div>
  );
};
