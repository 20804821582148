import './EditTagsTredModal.scss';

import { Form, Formik } from 'formik';
import React from 'react';

import { EditTagTred } from '../../../../validation/schemas';
import { Button, TagsSelect } from '../../../index';

export const EditTagsTredModal = ({ onClose, modalParameters }: any) => {
  return (
    <div>
      <Formik
        initialValues={modalParameters?.initialValues || { tagsIds: undefined }}
        onSubmit={(e) => {
          modalParameters.onCallback(e?.tagsIds?.map((e: any) => parseInt(e)));
          onClose();
        }}
        validationSchema={EditTagTred}
      >
        {({ values }) => {
          return (
            <Form className='EditTagsTredModal'>
              <div className=''>
                <TagsSelect
                  name='tagsIds'
                  maxTags={99}
                  isEditMode={true}
                  editTagIds={values?.tagsIds?.map((e: any) => String(e))}
                  initialValues={modalParameters?.initialValues?.tags}
                />
              </div>
              <Button
                text={'Сохранить'}
                type='submit'
                width='100%'
                height='30px'
                margin='30px 0 0 0'
                backgroundColor='green'
                color='white'
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
