import { useState } from 'react';

import {
  GetScaleMapFileDocument,
  useCreateScaleMapTaskMutation,
  useCreateThreadMutation,
  useDeleteScaleMapFileMutation,
  useDeleteScaleMapTaskWithResponseMutation,
  useGetScaleMapFileQuery,
  useMoveScaleMapTaskMutation,
  useMoveScaleMapTaskToRowMutation,
  useUpdateScaleMapFileMutation,
  useUpdateScaleMapTaskMutation,
} from '../../../graphql/generated/graphql';

export const useFileModeration = ({
  fileId,
  navigate,
  selfLocation,
  hideSuccessInner,
}: any) => {
  const [file, setFile] = useState<any>();
  const [filterStatus, setFilterStatus] = useState<number | null>(null);
  const { refetch } = useGetScaleMapFileQuery({
    variables: {
      getScaleMapFileId: parseInt(fileId as string),
      statusId: filterStatus,
      excludeStatusId: hideSuccessInner ? 1 : undefined,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setFile(data?.getScaleMapFile);
    },
    onError: () => {
      if (selfLocation) {
        navigate('/projectI');
      } else {
        navigate(-1);
      }
    },
  });
  const [createScaleMapTaskMutation] = useCreateScaleMapTaskMutation({
    onCompleted: () => {
      refetch();
    },
    update(cache, { data: dataQuery }) {
      const existingData: any = cache.readQuery({
        query: GetScaleMapFileDocument,
        variables: {
          getScaleMapFileId: parseInt(fileId as string),
          statusId: filterStatus,
          excludeStatusId: hideSuccessInner ? 1 : undefined,
        },
      });
      const clone = JSON.parse(JSON.stringify(existingData));
      const scaleMapTasks = [
        ...(clone?.getScaleMapFile?.scaleMapTasks || []),
        dataQuery?.createScaleMapTask,
      ];
      clone.getScaleMapFile.scaleMapTasks = scaleMapTasks;
      cache.writeQuery({
        query: GetScaleMapFileDocument,
        variables: {
          getScaleMapFileId: parseInt(fileId as string),
          statusId: filterStatus,
          excludeStatusId: hideSuccessInner ? 1 : undefined,
        },
        data: clone,
      });
    },
  });

  const [updateScaleMapTaskMutation] = useUpdateScaleMapTaskMutation({
    onCompleted: () => {
      refetch();
    },
    update(cache, { data: dataQuery }) {
      cache.updateQuery(
        {
          query: GetScaleMapFileDocument,
          variables: {
            getScaleMapFileId: parseInt(fileId as string),
            statusId: filterStatus,
            excludeStatusId: hideSuccessInner ? 1 : undefined,
          },
        },
        (cachedData) => {
          const clone = JSON.parse(JSON.stringify(cachedData));
          const updatedTask: any = dataQuery?.updateScaleMapTask;
          const existingTasks = clone.getScaleMapFile.scaleMapTasks || [];
          const index = existingTasks.findIndex(
            (task: any) => task.id === updatedTask.id,
          );
          if (index !== -1) {
            clone.getScaleMapFile.scaleMapTasks[index] = updatedTask;
          }
          return clone;
        },
      );
    },
  });

  const [deleteScaleMapTaskWithResponseMutation] =
    useDeleteScaleMapTaskWithResponseMutation({
      onCompleted: () => {
        refetch();
      },
      update(cache, { data: dataQuery }) {
        cache.updateQuery(
          {
            query: GetScaleMapFileDocument,
            variables: {
              getScaleMapFileId: parseInt(fileId as string),
              statusId: filterStatus,
              excludeStatusId: hideSuccessInner ? 1 : undefined,
            },
          },
          (cachedData) => {
            const clone = JSON.parse(JSON.stringify(cachedData));
            const updatedTask: any = dataQuery?.deleteScaleMapTaskWithResponse;

            const existingTasks = clone.getScaleMapFile.scaleMapTasks || [];
            const existingTasksFilter = existingTasks.filter(
              (task: any) => task.id !== updatedTask.id,
            );
            clone.getScaleMapFile.scaleMapTasks = existingTasksFilter;
            return clone;
          },
        );
      },
    });

  const [updateScaleMapFileMutation] = useUpdateScaleMapFileMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const [deleteScaleMapFileMutation] = useDeleteScaleMapFileMutation({
    onCompleted: () => {
      if (selfLocation) {
        navigate(`/projectI`);
      } else {
        navigate(`/scale`);
      }
    },
  });

  const [moveScaleMapTaskToRowMutation] = useMoveScaleMapTaskToRowMutation({
    onCompleted: () => {
      refetch();
    },
  });
  const [createThreadMutation] = useCreateThreadMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const [moveScaleMapTaskMutation] = useMoveScaleMapTaskMutation({
    onCompleted: () => {
      refetch();
    },
  });

  return {
    createScaleMapTaskMutation,
    deleteScaleMapFileMutation,
    updateScaleMapFileMutation,
    deleteScaleMapTaskMutation: deleteScaleMapTaskWithResponseMutation,
    updateScaleMapTaskMutation,
    moveScaleMapTaskToRowMutation,
    file,
    filterStatus,
    setFilterStatus,
    createThreadMutation,
    moveScaleMapTaskMutation,
  };
};
