import React from 'react';
import { Arrow, Layer, Text } from 'react-konva';

import { polygonWidth } from '../../constants/constants';
import { AddArrowType, HoverRectIdType } from '../../types/enums';
import {
  arrowArrayType,
  arrowToolsType,
  polygonToolsType,
  RectType,
} from '../../types/types';
import { Rectangle } from './rectangle/Rectangle';

type PolygonLayerType = {
  rectangles: Array<RectType>;
  arrowArray: arrowArrayType;
  arrowArrayDrag: arrowArrayType;
  addArrow: AddArrowType;
  navigate: (e: string) => void;
  polygonTools: polygonToolsType;
  arrowTools: arrowToolsType;
  projectId: string;
  isAccess: boolean;
  loadingRectangle: boolean;
  polygonStatisticID?: string;
  hoverRectId: { id: string | number; type: HoverRectIdType };
  setHoverRectId: (value: { id: string | number; type: HoverRectIdType }) => void;
};

export const PolygonGeneralLayer = ({
  rectangles,
  arrowArray,
  arrowArrayDrag,
  addArrow,
  navigate,
  polygonTools,
  arrowTools,
  projectId,
  isAccess,
  loadingRectangle,
  polygonStatisticID,
  setHoverRectId,
  hoverRectId,
}: PolygonLayerType) => {
  const arrowArrayConcat = arrowArray?.concat(arrowArrayDrag);

  const width = polygonWidth;

  return (
    <Layer>
      {arrowArray?.length <= 1 && rectangles?.length === 0 && !loadingRectangle && (
        <>
          <Text
            fontSize={36}
            text='Eще не создан ни один полигон для этого проекта,'
            x={width / 8}
            y={300}
          />
          <Text
            fontSize={36}
            text='нажми “добавить раздел” что-бы начать'
            x={width / 8}
            y={400}
          />
        </>
      )}
      {rectangles?.map((rect: RectType, index: number) => {
        if (rect.isStatus === 'Delete') return null;
        return (
          <Rectangle
            key={rect.id}
            shapeProps={rect}
            onChange={(rect: RectType) => {
              polygonTools.change({ rect, index });
            }}
            onSelect={() => {
              if (!isAccess) return;
              polygonTools.select({ id: rect.id });
            }}
            rect={rect}
            addArrow={addArrow}
            navigate={navigate}
            projectId={projectId}
            polygonTools={polygonTools}
            arrowTools={arrowTools}
            isAccess={isAccess}
            polygonStatisticID={polygonStatisticID}
            setHoverRectId={setHoverRectId}
            hoverRectId={hoverRectId}
          />
        );
      })}
      {arrowArrayConcat?.map((arrow, id: number) => {
        if (arrow.isStatus === 'Delete') return null;
        return (
          <Arrow
            points={[arrow.startX, arrow.startY, arrow.endX, arrow.endY]}
            fill={arrow.fill}
            stroke={arrow.fill}
            key={'arrow' + id}
            onClick={() => {
              if (!isAccess) return;
              arrowTools.select({ id: arrow.id });
            }}
            onTap={() => {
              if (!isAccess) return;
              arrowTools.select({ id: arrow.id });
            }}
          />
        );
      })}
    </Layer>
  );
};
