import './EditIcon.scss';

import React, { FC } from 'react';

type Props = {
  color?: string;
  stroke?: string;
  onClick?: () => void;
  width?: string | number;
  height?: string | number;
  className?: 'edit-color' | 'edit-color_with-background' | string;
};

export const EditIcon: FC<Props> = ({
  color = '#AFAFAF',
  stroke = '#AFAFAF',
  onClick,
  width = 10,
  height = 10,
  className = 'edit-color',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      className={className}
    >
      <g clipPath='url(#clip0_52_20)'>
        <path
          d='M7.1875 9.99913H1.14586C0.513763 9.99913 0 9.48537 0 8.85327V2.81163C0 2.17953 0.513763 1.66577 1.14586 1.66577H4.6875C4.86 1.66577 5 1.80577 5 1.97827C5 2.15077 4.86 2.29077 4.6875 2.29077H1.14586C0.858765 2.29077 0.625 2.52454 0.625 2.81163V8.85327C0.625 9.14037 0.858765 9.37413 1.14586 9.37413H7.1875C7.47459 9.37413 7.70836 9.14037 7.70836 8.85327V5.31163C7.70836 5.13913 7.84836 4.99913 8.02086 4.99913C8.19336 4.99913 8.33336 5.13875 8.33336 5.31163V8.85327C8.33336 9.48537 7.8196 9.99913 7.1875 9.99913Z'
          fill={color}
          stroke={stroke}
          strokeWidth='0.3'
        />
        <path
          d='M3.65511 6.6566C3.57302 6.6566 3.49298 6.6241 3.43424 6.56498C3.36008 6.4912 3.32842 6.38492 3.34887 6.28284L3.64344 4.80953C3.65549 4.74872 3.68547 4.69326 3.72881 4.64992L8.04429 0.334965C8.49092 -0.111736 9.21762 -0.111736 9.6647 0.334965C9.88092 0.551182 10.0001 0.838734 10.0001 1.14498C10.0001 1.45122 9.88092 1.7387 9.66424 1.95499L5.34929 6.2704C5.30596 6.31412 5.25011 6.34372 5.18968 6.35578L3.71675 6.65035C3.69631 6.65454 3.67548 6.6566 3.65511 6.6566ZM5.12842 6.04953H5.13262H5.12842ZM4.23761 5.02498L4.05382 5.94577L4.97423 5.7616L9.22258 1.51332C9.32092 1.41452 9.37509 1.28414 9.37509 1.14498C9.37509 1.00582 9.32092 0.875355 9.22258 0.77663C9.02009 0.573688 8.68967 0.573688 8.48596 0.77663L4.23761 5.02498Z'
          fill={color}
          stroke={stroke}
          strokeWidth='0.2'
        />
        <path
          d='M8.85423 2.63626C8.77428 2.63626 8.69424 2.60582 8.63344 2.54456L7.45508 1.36581C7.33301 1.24374 7.33301 1.04584 7.45508 0.923767C7.57715 0.801697 7.77505 0.801697 7.8972 0.923767L9.07548 2.10251C9.19755 2.22458 9.19755 2.42249 9.07548 2.54456C9.01422 2.60544 8.93426 2.63626 8.85423 2.63626Z'
          fill={color}
          stroke={stroke}
          strokeWidth='0.2'
        />
      </g>
      <defs>
        <clipPath id='clip0_52_20'>
          <rect width='10' height='10' fill='red' />
        </clipPath>
      </defs>
    </svg>
  );
};
