import React from 'react';

export const AllTaskIcons = () => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.5 0V12H1.5V8H11.5V0H0.5ZM1.5 1H3V2.5H4.5V1H6V2.5H7.5V1H9V2.5H10.5V4H9V5.5H10.5V7H9V5.5H7.5V7H6V5.5H4.5V7H3V5.5H1.5V4H3V2.5H1.5V1ZM3 4V5.5H4.5V4H3ZM4.5 4H6V2.5H4.5V4ZM6 4V5.5H7.5V4H6ZM7.5 4H9V2.5H7.5V4Z'
        fill='#AFAFAF'
      />
    </svg>
  );
};
