import * as yup from 'yup';

import * as MESSAGES from '../messages';

export const createUserSchema = yup.object().shape({
  login: yup
    .string()
    .matches(/^[A-Za-z0-9А-я_@./#&+-]*$/, MESSAGES.INCORRECT_LOGIN)
    .min(3, MESSAGES.INCORRECT_MIN_LENGTH_3)
    .max(20, MESSAGES.INCORRECT_MAX_LENGTH_20)
    .required(MESSAGES.REQUIRE_MESSAGE),
  role: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  link: yup.string(),
});
