import * as yup from 'yup';

import * as MESSAGES from '../messages';

export const addTagSchema = yup.object().shape({
  name: yup
    .string()
    .max(30, MESSAGES.INCORRECT_MAX_LENGTH_30)
    .required(MESSAGES.REQUIRE_MESSAGE),
});
