import './TreadItemChat.scss';

import { ApolloError, ApolloQueryResult } from '@apollo/client';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ScrollableFeed from 'react-scrollable-feed';
import { toast } from 'react-toastify';

import {
  Button,
  ChatMessage,
  MainContentHeaderItem,
  RequestHandler,
  SelectTypeChat,
  Textarea,
} from '../../../components';
import { useAuthContext } from '../../../context';
import {
  GetTredChatQuery as GetTredChatQueryType,
  TrackActivityTypeEnum,
  useDeleteMessageMutation,
  useGetTredChatLazyQuery,
  useMessagesReadBeforeSubscription,
  useNewMessageSubscription,
  usePrivateChatActivitySubscription,
  useReadMessagesBeforeMutation,
  useSendMessageMutation,
} from '../../../graphql/generated/graphql';
import { chatType } from '../../../types/enums';

type TreadItemChatType = {
  refetch: () => void;
  item2Style: any;
};

export const TreadItemChat = ({ refetch, item2Style }: TreadItemChatType) => {
  const {
    messages,
    chatLoading,
    chatId,
    fetchMoreMessages,
    messagesCount,
    chatScrollerRef,
    scrollChatToBottom,
    sendMessageHandler,
    chatRequestError,
    deleteMessageHandlerTreds,
    taskChatRefetch,
    chatTypeData,
    setChatTypeData,
  } = useChatHooks({ refetchTreds: refetch });

  const [isAtBottom, setIsAtBottom] = useState(true);

  useEffect(() => {
    setIsAtBottom(true);
  }, [chatId]);

  return (
    <div className='TreadItemChat'>
      <RequestHandler loading={chatLoading} error={chatRequestError}>
        <div className='TreadItemChat__header'>
          <MainContentHeaderItem
            title={`Чат #${chatId ? chatId : ''}`}
            style={{ fontWeight: 'normal' }}
          />
        </div>
        <div className='TreadItemChat__content' style={item2Style ? item2Style : {}}>
          <SelectTypeChat
            setChatTypeData={setChatTypeData}
            chatTypeData={chatTypeData}
            chatId={chatId}
          />
          <ScrollableFeed
            ref={chatScrollerRef}
            onScroll={(isAtBottom) => setIsAtBottom(isAtBottom)}
            className='TreadItemChat__messages'
          >
            {messages?.[0] && (
              <div className='TreadItemChat__fetch-button'>
                {Number(messagesCount) > messages?.length ? (
                  <Button
                    text={'Загрузить ещё...'}
                    withBorder
                    backgroundColor={'white'}
                    onClick={fetchMoreMessages}
                  />
                ) : (
                  <></>
                )}
              </div>
            )}
            {messages?.[0] ? (
              messages
                ?.map((message: any, index: number) => {
                  return (
                    <ChatMessage
                      key={message?.id + index + message?.createdAt}
                      messageId={message?.id}
                      avatar={message?.user?.image}
                      messageText={message?.text}
                      login={message?.user?.login}
                      createdAt={message?.createdAt}
                      isRead={message?.isRead}
                      karmaStatistics={message?.user?.karmaStatistics}
                      isPossibleSetKarma={message?.isPossibleSetKarma}
                      deleteMessageHandlerTreds={deleteMessageHandlerTreds}
                      refetchTred={taskChatRefetch}
                      isActivity={message?.isActivity}
                    />
                  );
                })
                .reverse()
            ) : (
              <p className='TreadItemChat__absence'>Пока нет сообщений...</p>
            )}
            {!isAtBottom && (
              <Button
                text={'вниз ⇓'}
                onClick={scrollChatToBottom}
                withBorder
                width='150px'
                backgroundColor={'white'}
              />
            )}
          </ScrollableFeed>
          <Textarea sendMessage={sendMessageHandler} chatId={chatId} />
        </div>
      </RequestHandler>
    </div>
  );
};

const useChatHooks = ({ refetchTreds }: any) => {
  const { currentUserData } = useAuthContext();

  const [chatTypeData, setChatTypeData] = useState<chatType>(chatType.messagesList);

  const { treadId } = useParams();

  const chatScrollerRef = createRef<ScrollableFeed>();

  const scrollChatToBottom = useCallback(() => {
    if (chatScrollerRef.current) {
      chatScrollerRef.current.scrollToBottom();
    }
  }, [chatScrollerRef]);

  const [chatRequestError, setChatRequestError] = useState<ApolloError | undefined>();

  const [readMessagesBeforeMutation] = useReadMessagesBeforeMutation();

  const [
    getTaskChatLazyQuery,
    {
      data: taskChatData,
      loading: taskChatDataLoading,
      refetch: taskChatRefetch,
      fetchMore: fetchMoreTaskChat,
      error: taskError,
    },
  ] = useGetTredChatLazyQuery({
    variables: {
      id: Number(treadId),
    },
    onCompleted: (data) => {
      const lastMessage = objectTransformer({ chatTypeData })?.lastMessage({
        data,
      });

      if (lastMessage && lastMessage?.isRead === null) {
        readMessagesBeforeMutation({
          variables: {
            messageId: [
              Number(objectTransformer({ chatTypeData })?.lastMessageID({ data })),
            ],
          },
        });
      }
      setMessagesCount(objectTransformer({ chatTypeData })?.lastMessageCount({ data }));
    },
  });
  const initMessagesCount = objectTransformer({ chatTypeData })?.initMessagesCount({
    taskChatData,
  });
  const [messagesCount, setMessagesCount] = useState(initMessagesCount);

  useEffect(() => {
    setMessagesCount(initMessagesCount);
  }, [initMessagesCount]);

  const [sendMessageMutation] = useSendMessageMutation({
    onCompleted: (data) => {
      const isAddFile =
        data?.sendMessage?.text?.includes('Добавлен файл') ||
        data?.sendMessage?.isExistLink;
      if (isAddFile) {
        setTimeout(refetchTreds, 1000);
      }
      messagesCount && setMessagesCount(messagesCount + 1);
    },
  });

  const [deleteMessageMutation] = useDeleteMessageMutation();

  const messagesList = objectTransformer({ chatTypeData })?.messagesList({
    taskChatData,
  });

  const chatId = taskChatData?.getThread?.chat?.id;

  useEffect(() => {
    setChatRequestError(taskError);
    return () => {
      setChatRequestError(undefined);
    };
  }, [taskError]);

  const [messages, setMessages] = useState<any>(messagesList);
  const [chatLoading, setChatLoading] = useState<boolean>(true);

  const { data: newMessageData } = useNewMessageSubscription({
    skip: !chatId,
    variables: { chatId: Number(chatId) },
  });
  const { data: MessagesReadBeforeSubscriptionData } = useMessagesReadBeforeSubscription({
    skip: !chatId,
    variables: { chatId: Number(chatId) },
  });

  //task refetch when useMessagesReadBeforeSubscription is triggered
  useEffect(() => {
    if (
      MessagesReadBeforeSubscriptionData?.messagesReadBefore?.messageIds &&
      MessagesReadBeforeSubscriptionData?.messagesReadBefore?.messageIds?.length > 0 &&
      MessagesReadBeforeSubscriptionData?.messagesReadBefore?.user?.id !==
        currentUserData?.id
    ) {
      taskChatRefetch();
    }
  }, [MessagesReadBeforeSubscriptionData?.messagesReadBefore?.messageIds]);

  useEffect(() => {
    refetchTreds();
    taskChatRefetch();
  }, [chatTypeData]);

  const { data: dataSubscription } = usePrivateChatActivitySubscription({
    variables: {
      chatId: Number(chatId),
    },
    skip: !chatId,
  });
  useEffect(() => {
    if (
      dataSubscription?.privateChatActivity?.type === TrackActivityTypeEnum.DeletedMessage
    ) {
      setMessages(
        messages?.filter((e: any) => {
          return e?.id !== dataSubscription?.privateChatActivity?.messageId;
        }),
      );
      if (messagesCount && messagesCount >= 1) {
        setMessagesCount(messagesCount - 1);
      }
    }
  }, [dataSubscription]);

  //just fetchMore and state setting after that
  const fetchMoreMessages = () => {
    fetchMoreTaskChat({
      variables: {
        offset: Number(messages.length) || 20,
      },
    }).then((response) => {
      setMessages((prevState: any) => [
        ...prevState,
        ...objectTransformer({ chatTypeData }).moreMessages({
          response,
        }),
      ]);
    });
  };

  //Getting chat depending on the page(task or subtask or privateChatId)
  useEffect(() => {
    getTaskChatLazyQuery().then(() => setChatLoading(false));
    return () => {
      setChatLoading(false);
    };
  }, [treadId]);

  //newMessage subscription useEffect to set messages state and readMessagesBefore mutation
  useEffect(() => {
    const activityConvert = !!(chatTypeData === chatType.logs);
    if (!!newMessageData?.newMessage?.isActivity !== activityConvert) {
      if (
        newMessageData?.newMessage?.text?.includes('Добавлен файл') ||
        newMessageData?.newMessage?.text?.includes('Добавлено изображение')
      ) {
        setTimeout(refetchTreds, 1000);
      }

      return;
    }

    if (newMessageData?.newMessage?.id) {
      setMessages((prevState: any) => [newMessageData?.newMessage, ...prevState]);
      if (newMessageData?.newMessage?.user?.login !== currentUserData?.login) {
        readMessagesBeforeMutation({
          variables: { messageId: [newMessageData?.newMessage?.id] },
        });
      }
    }
    if (
      newMessageData?.newMessage?.text?.includes('Добавлен файл') ||
      newMessageData?.newMessage?.text?.includes('Добавлено изображение')
    ) {
      refetchTreds();
    }
  }, [newMessageData]);

  //set state when queries end loading
  useEffect(() => {
    if (!taskChatDataLoading) {
      setMessages(messagesList);
    }
    return () => {
      setMessages([]);
    };
  }, [messagesList, taskChatDataLoading]);

  const sendMessage = useCallback(
    (message: string) => {
      sendMessageMutation({
        variables: {
          data: {
            chatId: Number(chatId),
            text: message,
          },
        },
      });
    },
    [chatId],
  );

  const deleteMessage = useCallback(
    (messageId: string) => {
      deleteMessageMutation({
        variables: {
          messageId: messageId,
        },
        onCompleted: (res) => {
          if (res.deleteMessage) {
            setMessages((prevState: any) => [
              ...prevState.filter((state: any) => state?.id !== messageId),
            ]);
            toast.success('Сообщение удалено');
          }
        },
      });
    },
    [chatId],
  );

  const sendMessageHandler = useCallback(
    (message: string) => {
      sendMessage(message);
      scrollChatToBottom();
    },
    [scrollChatToBottom, sendMessage],
  );

  const deleteMessageHandlerTreds = useCallback(
    (messageId?: string | null) => {
      if (messageId) {
        deleteMessage(messageId);
        if (messagesCount && messagesCount >= 1) {
          setMessagesCount(messagesCount - 1);
        }
      }
    },
    [scrollChatToBottom, deleteMessage],
  );

  return {
    messages,
    chatLoading,
    chatId,
    fetchMoreMessages,
    messagesCount,
    chatScrollerRef,
    scrollChatToBottom,
    sendMessageHandler,
    chatRequestError,
    deleteMessageHandlerTreds,
    taskChatRefetch,
    chatTypeData,
    setChatTypeData,
  };
};

type objectTransformerType = {
  chatTypeData: chatType;
};

const objectTransformer = ({ chatTypeData }: objectTransformerType) => {
  return {
    lastMessage: ({ data }: { data: GetTredChatQueryType }) => {
      if (chatTypeData === chatType.logs) {
        return data?.getThread?.chat?.logs?.rows[0];
      }
      return data?.getThread?.chat?.messagesList?.rows[0];
    },
    lastMessageID: ({ data }: { data: GetTredChatQueryType }) => {
      if (chatTypeData === chatType.logs) {
        return data?.getThread?.chat?.logs?.rows?.[0]?.id;
      }
      return data?.getThread?.chat?.messagesList?.rows?.[0]?.id;
    },
    lastMessageCount: ({ data }: { data: GetTredChatQueryType }) => {
      if (chatTypeData === chatType.logs) {
        return data?.getThread?.chat?.logs?.count;
      }
      return data?.getThread?.chat?.messagesList?.count;
    },
    initMessagesCount: ({
      taskChatData,
    }: {
      taskChatData: GetTredChatQueryType | undefined;
    }) => {
      if (chatTypeData === chatType.logs) {
        return taskChatData?.getThread?.chat?.logs?.count;
      }
      return taskChatData?.getThread?.chat?.messagesList?.count;
    },
    messagesList: ({
      taskChatData,
    }: {
      taskChatData: GetTredChatQueryType | undefined;
    }) => {
      if (chatTypeData === chatType.logs) {
        return taskChatData?.getThread?.chat?.logs?.rows;
      }
      return taskChatData?.getThread?.chat?.messagesList?.rows;
    },
    moreMessages: ({
      response,
    }: {
      response: ApolloQueryResult<GetTredChatQueryType>;
    }) => {
      if (chatTypeData === chatType.logs) {
        return response?.data?.getThread?.chat?.logs?.rows || null;
      }
      return response?.data?.getThread?.chat?.messagesList?.rows || null;
    },
  };
};
