import React, { useEffect, useState } from 'react';

import { ScaleList } from '../../components';
import {
  Project as ProjectType,
  SortingField,
  SortingOrders,
  useGetProjectsScaleOptimizeQuery,
  useGetProjectsScaleQuery,
} from '../../graphql/generated/graphql';

export const Scale = () => {
  const [isExcludeNullStatusTasks, setIsExcludeNullStatusTasks] = useState<number | null>(
    null,
  );

  const [isArchived, setIsArchived] = useState(false);
  useEffect(() => {
    setIsExcludeNullStatusTasks(null);
  }, [isArchived]);
  const setIsExcludeNullStatusTasksHandle = (value: number | null) => {
    if (isArchived) return;
    setIsExcludeNullStatusTasks(value);
  };

  const { data, loading, refetch } = useSelectQuery({
    isArchived,
    isExcludeNullStatusTasks,
  });

  return (
    <div className='container'>
      <ScaleList
        projectsList={data?.getProjects?.rows as Array<ProjectType>}
        refetchProjects={refetch}
        isExcludeNullStatusTasks={isExcludeNullStatusTasks}
        setIsExcludeNullStatusTasks={setIsExcludeNullStatusTasksHandle}
        isArchived={isArchived}
        setIsArchived={setIsArchived}
        loading={loading}
      />
    </div>
  );
};

// use for optimize first long request on page
const useSelectQuery = ({ isArchived, isExcludeNullStatusTasks }: any) => {
  const variables = {
    data: {
      limit: 1000,
      sort: { field: SortingField.Number, type: SortingOrders.Asc },
      isExistScaleMapFiles: true,
      isArchivedScaleMapFiles: isArchived,
    },
    dataFiles: {
      isArchived: isArchived,
    },
    statusId: isExcludeNullStatusTasks ? isExcludeNullStatusTasks : undefined,
    isExcludeNullStatusTasks: !!isExcludeNullStatusTasks,
  };

  // get All data
  const {
    data: dataAll,
    loading: loadingAll,
    refetch: refetchAll,
  } = useGetProjectsScaleQuery({
    variables: variables,
    fetchPolicy: 'no-cache',
    skip: !isExcludeNullStatusTasks,
  });

  // get Data excluding statistics
  const {
    data: dataOptimize,
    loading: loadingOptimize,
    refetch: refetchOptimize,
  } = useGetProjectsScaleOptimizeQuery({
    variables: variables,
    fetchPolicy: 'no-cache',
    skip: isExcludeNullStatusTasks,
  });

  if (isExcludeNullStatusTasks) {
    return { data: dataAll, loading: loadingAll, refetch: refetchAll };
  } else {
    return { data: dataOptimize, loading: loadingOptimize, refetch: refetchOptimize };
  }
};
