import { gql } from '@apollo/client';

export const GET_PROJECT = gql`
  query getProject($id: Int!, $tasksData: GetTasksInput!) {
    getProject(id: $id) {
      id
      name
      number
      createdAt
      updatedAt
      poligon {
        id
      }
      author {
        id
        image
        login
      }
      tasks(data: $tasksData) {
        count
        rows {
          id
          number
          name
          code
          startDate
          closeDate
          project {
            id
            name
          }
          status {
            id
            color
          }
          project {
            id
            number
          }
          author {
            id
            image
            login
            role
            karmaStatistics {
              karma
            }
          }
          assignees {
            id
            image
            login
            role
            karmaStatistics {
              karma
            }
          }
          maintainer {
            id
            login
            image
            karmaStatistics {
              karma
            }
          }
          subtasks(data: { sort: { type: DESC, field: activity } }) {
            id
            createdAt
            name
            number
            status {
              id
              color
            }
            task {
              id
              number
              project {
                id
                name
              }
              poligonObject {
                id
              }
            }
            tags {
              count
              rows {
                id
                createdAt
                updatedAt
                name
                color
                textColor
              }
            }
          }
          maintainer {
            id
            login
            image
            fullName
            karmaStatistics {
              karma
            }
          }
          endDate
          tags {
            count
            rows {
              id
              createdAt
              updatedAt
              name
              color
            }
          }
          realizationPerMonthAmount
          realizationPrice
        }
      }
    }
  }
`;
