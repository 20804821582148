import { gql } from '@apollo/client';

export const GET_THREAD = gql`
  query getThread($id: Int!) {
    getThread(id: $id) {
      id
      author {
        id
        login
        image
      }
      isSubscriber
      notes {
        id
        threadId
        content
        owner {
          id
          image
        }
      }
      threadBackground {
        id
        backgroundColor
      }
      cover
      statistic {
        performerAcceptedPercent
      }
      scaleMapTaskForCalendar {
        id
      }
      scaleMapTask {
        id
        name
        column
        row
        status {
          id
          name
          color
        }
        children {
          id
          name
          isUrgently
          status {
            id
            name
            color
          }
        }
      }
      chat {
        id
      }
      notesAndCount {
        rows {
          id
        }
      }
      storageFiles {
        rows {
          ... on File {
            id
            fileName
            previewUrl
            mimeType
            path
            size
            task {
              id
              number
              name
              code
            }
            subtask {
              id
              number
              name
              code
              task {
                id
              }
            }
            createdAt
            updatedAt
            mimeType
            userClickedAt
          }
          ... on StorageItem {
            id
            name
            href
            thumbnailPath
            isAccess
            createdAt
            updatedAt
            taskId
            task {
              id
              number
              name
              code
            }
            subtask {
              id
              number
              name
              code
              task {
                id
              }
            }
            subtaskId
            mimeType
            userClickedAt
          }
        }
      }
      storageImages {
        rows {
          ... on File {
            id
            fileName
            previewUrl
            mimeType
            path
            size
            task {
              id
              number
              name
              code
            }
            subtask {
              id
              number
              name
              code
              task {
                id
              }
            }
            threadFileWrap {
              id
              orderNumber
              comment
            }
            createdAt
            updatedAt
            mimeType
            userClickedAt
          }
          ... on StorageItem {
            id
            name
            href
            thumbnailPath
            isAccess
            createdAt
            updatedAt
            taskId
            task {
              id
              number
              name
              code
            }
            subtask {
              id
              number
              name
              code
              task {
                id
              }
            }
            subtaskId
            mimeType
            userClickedAt
          }
        }
      }
      performers {
        threadId
        userId
        status
        user {
          id
          login
          image
          role
          karmaStatistics {
            karma
          }
          threads {
            rows {
              id
              performerStatus
            }
          }
        }
      }
      mainPerformer {
        id
        login
        image
        IsExistUnViewedForum
        role
        fullName
      }
      mainPerformerId
      endDate
      startDate
      closeDate
      isCritical
      tags {
        id
        name
        color
        textColor
      }
      meta {
        id
        name
        column
        row
        status {
          color
          name
        }
        isMain
        isCurrent
        scaleMapFile {
          id
          isPersonal
          author {
            id
          }
          user {
            id
          }
        }
      }
    }
  }
`;
