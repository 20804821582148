import { useEffect, useState } from 'react';

import { PoligonObject } from '../graphql/generated/graphql';
import { cancelUpdateType } from '../types/enums';
import { RectType } from '../types/types';

type useStateManagementType = {
  rectangles: Array<RectType>;
  setRectangles: (value: Array<RectType>) => void;
  polygonObject: PoligonObject | undefined;
};

export const useStateManagement = ({
  rectangles,
  setRectangles,
  polygonObject,
}: useStateManagementType) => {
  const [historyState, setHistoryState] = useState([rectangles]);
  const [historyIndex, setHistoryIndex] = useState<number>(0);
  const [cancelUpdate, setCancelUpdate] = useState<cancelUpdateType>(
    cancelUpdateType.UPDATE,
  );

  useEffect(() => {
    setHistoryState([]);
    setHistoryIndex(0);
  }, [polygonObject?.id]);

  useEffect(() => {
    if (cancelUpdate === cancelUpdateType.FIX_DUPLICATES)
      return setCancelUpdate(cancelUpdateType.UPDATE);

    if (cancelUpdate === cancelUpdateType.UPDATE) {
      stateTools.updateState();
    }
  }, [rectangles]);

  const state = JSON.parse(JSON.stringify(historyState));

  const stateTools = {
    updateState: () => {
      if (historyIndex + 1 !== state.length && state.length !== 1) {
        const newBranch = state.slice(0, historyIndex);
        newBranch.push(rectangles);
        setHistoryState(newBranch);
        setHistoryIndex(newBranch.length - 1);
      } else {
        state.push(rectangles);
        setHistoryState(state);
        setHistoryIndex(state.length - 1);
      }
    },
    cancel: () => {
      if (historyIndex) {
        const index = historyIndex - 1 > 0 ? historyIndex - 1 : 0;
        setHistoryIndex(index);
        setRectangles(state[index]);
        setCancelUpdate(cancelUpdateType.FIX_DUPLICATES);
      }
    },
    forward: () => {
      if (historyIndex <= state.length) {
        const index = historyIndex < state.length ? historyIndex + 1 : state.length;
        setHistoryIndex(index);
        setRectangles(state[index <= state.length - 1 ? index : state.length - 1]);
        setCancelUpdate(cancelUpdateType.FIX_DUPLICATES);
      }
    },
  };

  return { stateTools, setCancelUpdate, historyIndex, historyState };
};
