import React, { useEffect, useRef, useState } from 'react';
import { ChromePicker } from 'react-color';

import { useClickOutside } from '../../hooks';
import { Button } from '../button/Button';

export const ColorPicker = ({ colorForm, setColorForm, submit }: any) => {
  const [openColor, setOpenColor] = useState(false);

  const [color, setColor] = useState(colorForm || '');

  const ref = useRef(null);

  useClickOutside(ref, () => setOpenColor(false));

  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{ marginTop: '5px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginRight: '5px' }}>Цвет текста в календаре</div>
        <div
          className='addTaskInPlanModal2__table-color'
          style={{
            backgroundColor: color,
          }}
          onClick={() => {
            setOpenColor(true);
          }}
        ></div>
      </div>
      {openColor && (
        <div
          className='addTaskInPlanModal2__colorPanel'
          ref={ref}
          style={height < 840 ? { top: '50%', left: '60%' } : {}}
        >
          <ChromePicker
            disableAlpha
            color={color}
            onChangeComplete={(paletteColor) => {
              setColor(paletteColor.hex);
            }}
            onChange={(paletteColor) => {
              setColor(paletteColor.hex);
            }}
          />
          <Button
            text='Сохранить цвет'
            margin='10px 0 0 0'
            onClick={() => {
              setColorForm(color);

              if (submit) {
                submit(color);
              } else {
                setOpenColor(false);
              }
            }}
          />
        </div>
      )}
    </div>
  );
};
