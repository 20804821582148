import { useEffect, useRef, useState } from 'react';

export const useChatHeight = (data: any, storageFiles: any, storageImages: any) => {
  const item1Ref = useRef<any>(null);
  const [heightItem1, setHeightItem1] = useState(0);

  useEffect(() => {
    if (item1Ref.current) {
      if (
        (!storageFiles || storageFiles?.length === 0) &&
        (!storageImages || storageImages?.length === 0)
      ) {
        setHeightItem1(40);
      } else {
        setHeightItem1((item1Ref?.current?.offsetHeight + 40) as any);
      }
    }
  }, [item1Ref?.current?.offsetHeight, data, storageFiles, storageImages]);

  const item2Style = {
    height: `calc(100% - ${heightItem1}px)`,
  };

  return { item1Ref, item2Style };
};
