import React from 'react';

export const ForumDownArrIcons = () => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.877 5.81178L7.4978 9.13678C7.35998 9.26803 7.18133 9.33366 7.00012 9.33366C6.82006 9.33366 6.63974 9.26798 6.5032 9.13656L3.12404 5.81157C2.681 5.3913 2.99451 4.66699 3.62249 4.66699H10.3783C11.0046 4.66699 11.3185 5.3913 10.877 5.81178V5.81178Z'
        fill='#303030'
      />
    </svg>
  );
};
