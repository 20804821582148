import './NewLine.scss';

import React from 'react';

import { NewLine } from './NewLine';

type TemplateNewLineType = {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  setFieldTouched: (value: any) => void;
  disableEdit: boolean;
  touched: any;
  values: any;
  optionsFile: any;
  errors: any;
};

export const TemplateNewLine = ({
  setFieldValue,
  disableEdit,
  values,
  optionsFile,
  errors,
  setFieldTouched,
  touched,
}: TemplateNewLineType) => {
  return (
    <>
      {!disableEdit && (
        <NewLine
          setFieldValueOuter={setFieldValue}
          values={values}
          options={optionsFile}
          error={!!errors.sampleItems}
          setFieldTouched={setFieldTouched}
          touchedSampleItems={!!touched.sampleItems}
        />
      )}
      {typeof errors.sampleItems === 'string' && touched.sampleItems && (
        <div className='NewLine__errors'>{errors.sampleItems}</div>
      )}
    </>
  );
};
