import './ChronometerPage.scss';

import React, { useState } from 'react';

import {
  Button,
  Chronometer,
  ChronometerDone,
  ChronometerTaskMonth,
  ChronometerWeeks,
} from '../../components';
import { useModalContext } from '../../context';
import {
  SetChronographDayRecordsMutationFn,
  useGetChronographDaysQuery,
  useSetChronographDayRecordsMutation,
  useTrackChronographTimeMutation,
} from '../../graphql/generated/graphql';
import { useChronometerTools } from '../../hooks';
import { ModalStatuses } from '../../types/enums';
import { datesOfWeekType } from '../../types/types';

export const ChronometerPage = () => {
  const [weeks, setWeeks] = useState<datesOfWeekType>({
    from: '',
    fromUTC: '',
    to: '',
    toUTC: '',
  });

  const { data: dataDays, refetch } = useGetChronographDaysQuery({
    variables: {
      data: {
        from: convertDate(weeks.from),
        to: convertDate(weeks.to),
      },
    },
    skip: weeks.fromUTC === '',
    fetchPolicy: 'network-only',
  });

  const [setChronographDayRecordsMutation] = useSetChronographDayRecordsMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const [trackChronographTimeMutation] = useTrackChronographTimeMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const handleEnergyFixed = (value: any) => {
    trackChronographTimeMutation({
      variables: {
        data: {
          date: selectDayDate,
          hours: selectHours as number[],
          energy: value.energy,
        },
      },
    });
    setSelectHours([]);
  };

  const {
    handleSetSelectHours,
    toolsChrono,
    selectDay,
    selectHours,
    selectDayDate,
    setSelectDayDate,
    setSelectHours,
  } = useChronometerTools({ dataDays, from: weeks.from });

  const handleTimeFixed = (value: any) => {
    trackChronographTimeMutation({
      variables: {
        data: {
          date: selectDayDate,
          scaleMapFileId: value?.fileId,
          scaleMapTaskId: value?.taskId,
          hours: selectHours as number[],
        },
      },
    });
    setSelectHours([]);
  };

  return (
    <div className='chronometerPage'>
      <div className='chronometerPage__content'>
        <div className='chronometerPage__section-wrapper'>
          <div className='chronometerPage__section'>
            <div className='chronometerPage__subsection'>
              <ChronometerWeeks
                setWeeks={setWeeks}
                weeks={weeks}
                selectDayDate={selectDayDate}
                setSelectDayDate={setSelectDayDate}
              />
              <Chronometer
                handleSetSelectHours={handleSetSelectHours}
                toolsChrono={toolsChrono}
                selectDay={selectDay}
                handleTimeFixed={handleTimeFixed}
                handleEnergyFixed={handleEnergyFixed}
              />
              <ChronometerReport
                setChronographDayRecordsMutation={setChronographDayRecordsMutation}
                selectDayDate={selectDayDate}
                dayRecords={selectDay?.dayRecords}
              />
            </div>
            <div className='chronometerPage__subsection'>
              <ChronometerDone
                handleTimeFixed={handleTimeFixed}
                handleEnergyFixed={handleEnergyFixed}
                selectHours={selectHours}
                selectDay={selectDay}
                selectDayDate={selectDayDate}
                plan={dataDays?.getChronographDays?.statistic?.plan as any}
                from={convertDate(weeks.from)}
              />
            </div>
          </div>
          <div className='chronometerPage__section'>
            <ChronometerTaskMonth
              statistic={dataDays?.getChronographDays?.statistic as any}
              weeks={weeks}
              setWeeks={setWeeks}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const convertDate = (inputDate: string) => {
  const parts = inputDate.split('.');
  return parts[2] + '-' + parts[1] + '-' + parts[0];
};

type ChronometerReportType = {
  setChronographDayRecordsMutation: SetChronographDayRecordsMutationFn;
  selectDayDate: string;
  dayRecords: {
    date: string;
    cell1: string;
    cell2: string;
    cell3: string;
  };
};

const ChronometerReport = ({
  setChronographDayRecordsMutation,
  selectDayDate,
  dayRecords,
}: ChronometerReportType) => {
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  return (
    <div className='chronometerPage__buttonWrapper'>
      <Button
        width={200}
        onClick={() => {
          handleSetModalStatus(ModalStatuses.CHRONOMETER_NOTE);
          handleChangeModalParameters([
            {
              key: 'onCallback',
              value: (value: any) => {
                setChronographDayRecordsMutation({
                  variables: {
                    data: {
                      date: selectDayDate,
                      cell1: value?.text1,
                      cell2: value?.text2,
                      cell3: value?.text3,
                    },
                  },
                });
              },
            },
            {
              key: 'initialValues',
              value: dayRecords,
            },
          ]);
        }}
      >
        Заметки
      </Button>
      <Button
        width={200}
        onClick={() => {
          handleSetModalStatus(ModalStatuses.CHRONOMETER_REPORT);
          handleChangeModalParameters([
            {
              key: 'onCallback',
              value: (value: any) => {
                console.info(value);
              },
            },
            {
              key: 'initialValues',
              value: undefined,
            },
          ]);
        }}
      >
        Отчет
      </Button>
    </div>
  );
};
