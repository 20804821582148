import './NewLinkModal.scss';

import { Form, Formik, FormikValues } from 'formik';
import React, { FC } from 'react';
import { toast } from 'react-toastify';

import {
  Poligon as PoligonType,
  PoligonObject as PoligonObjectType,
} from '../../../../graphql/generated/graphql';
import { ModalType } from '../../../../types/types';
import { addPolygonLink } from '../../../../validation/schemas';
import { AppInput } from '../../../appInput/AppInput';
import { Button } from '../../../button/Button';

export const NewLinkModal: FC<
  Pick<
    ModalType,
    | 'isOpen'
    | 'onSubmit'
    | 'initialValues'
    | 'onClose'
    | 'labelText'
    | 'buttonText'
    | 'inputName'
    | 'placeholder'
  > & { onAddPolygon: () => void; poligon: PoligonType; polygonObject: PoligonObjectType }
> = ({ onSubmit, initialValues, labelText, placeholder, poligon, polygonObject }) => {
  const handleSubmit = (values: FormikValues) => {
    onSubmit({
      parentLink: values?.parentName ? null : values?.parentLink,
      parentName: values?.parentName ? values?.parentName : undefined,
    });
    if (values?.newLink === 'new') {
      toast.success(`Сохранитесь для создания вложенного полигона`);
    }
    if (values?.parentLink) {
      toast.success(`Сохранитесь для изменения ссылки`);
    }
  };

  const poligonIDArray = poligon?.objects?.map((e: any) => e.id);

  const createFatherElements = () => {
    const fatherId: any = [];
    if (!polygonObject) return [];
    let newPolygon = JSON.parse(JSON.stringify(poligon?.objects));
    newPolygon = newPolygon
      .map((element: PoligonObjectType) => {
        if (element?.parent?.length === 0) {
          fatherId.push(element.id);
          return null;
        } else {
          return element;
        }
      })
      ?.filter((e: PoligonObjectType) => e);

    const findFather = ({ arrayList, fatherIdArray, callback }: any) => {
      const newArrayList = JSON.parse(JSON.stringify(arrayList));
      const newFatherId: any = [];

      newArrayList.map((e: PoligonObjectType) => {
        fatherIdArray.map((parentID: number) => {
          if (e?.id === parentID) {
            newFatherId.push(e.id);
          }
        });
      });

      if (newFatherId?.length === 0) {
        return fatherIdArray;
      }
      return callback({
        arrayList: newArrayList?.filter((e: PoligonObjectType) => {
          if (newFatherId.includes(e.id)) {
            return false;
          }
          return true;
        }),
        fatherIdArray: fatherIdArray.concat(newFatherId),
        callback,
      });
    };

    const treeFatherId = findFather({
      arrayList: newPolygon,
      fatherIdArray: polygonObject?.parent?.map((e) => {
        return e.id;
      }),
      callback: findFather,
    });
    treeFatherId.push(polygonObject.id);
    return fatherId?.concat(treeFatherId);
  };

  return (
    <Formik
      initialValues={{
        parentLink:
          initialValues?.parentLink === 'new' ? undefined : initialValues?.parentLink,
        newLink: '',
        parentName: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={addPolygonLink({
        poligonIDArray,
        fatherElements: createFatherElements(),
      })}
    >
      {({ setFieldValue, values, handleSubmit }) => {
        const showElse = !values?.parentLink && !values?.newLink;
        return (
          <Form>
            <div className='new-link-modal'>
              <div className='new-link-modal__title'>
                {!values?.newLink && (
                  <AppInput
                    name={'parentLink'}
                    label={labelText}
                    placeholder={placeholder}
                    type='number'
                  />
                )}
                {showElse && <span className='new-link-modal__title-or'>или</span>}

                {!values?.parentLink &&
                  (values?.newLink ? (
                    <AppInput
                      name={'parentName'}
                      label={labelText}
                      placeholder='Введите имя нового полигона'
                    />
                  ) : (
                    <div className='link-add-modal__links_add'>
                      <Button
                        onClick={() => {
                          setFieldValue('newLink', 'new');
                        }}
                        backgroundColor='#149939'
                        width='100%'
                        height='25px'
                        margin='10px 0 0 0'
                        text='+ Создать новый полигон'
                      />
                    </div>
                  ))}
              </div>
              {values?.parentLink && (
                <Button
                  type='submit'
                  text={'Добавить ссылку на существующий полигон'}
                  width='100%'
                  height='25px'
                />
              )}
              {values?.newLink && values?.parentName && (
                <Button
                  type='submit'
                  text={'Добавить новый полигон'}
                  width='100%'
                  height='25px'
                />
              )}
              {(initialValues?.parentLink && !values?.newLink && !values?.parentLink) ||
                (initialValues?.parentLink === values?.parentLink && (
                  <Button
                    type='submit'
                    text={'Удалить ссылку'}
                    width='100%'
                    height='25px'
                    onClick={() => {
                      setFieldValue('parentLink', null);
                      handleSubmit();
                    }}
                    margin='10px 0 0 0'
                  />
                ))}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
