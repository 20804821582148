import React from 'react';

import {
  AddTaskIcon,
  EditIcon,
  ExecutorsIcon,
  InfoIcon,
  LinkAddIcon,
  LongArrowTopIcon,
  RemoveIcon,
  SendToTopIcon,
} from '../components/icons';

export const API_HOST: string = process.env.REACT_APP_API_HOST as string;
export const SOCKET_HOST: string = process.env.REACT_APP_SOCKET_HOST as string;
export const BASE_URL: string = process.env.REACT_APP_BASE_URL as string;
export const REGISTRATION_URL = `${process.env.REACT_APP_BASE_URL}registr`;
export const RECOVERY_URL = `${process.env.REACT_APP_BASE_URL}password-recovery`;

export const PATHS = {
  decideOnTheFlyCreate: '/decide-on-the-fly-create',
  decideOnTheFlyPackage: '/decide-on-the-fly-package',
  spontaneousTasks: '/spontaneous-tasks',
  userSettings: '/user-settings',
  userStorage: '/user-storage',
  decideOnTheFlyControl: '/decide-on-the-fly-control',
  decideOnTheFly: '/decide-on-the-fly',
  circle: '/circle',
  storage: '/storage',
  treadsFile: '/treadsFile',
  treadItem: '/tread/:treadId',
  treads: '/',
  devPage: '/devPage',
  terms: '/terms',
  privacy: '/privacy',
  calendar: '/calendar',
  projects: '/projects',
  registration: '/registr',
  passwordRecovery: '/password-recovery',
  login: '/login',
  history: '/history',
  tasks: '/tasks',
  myTasks: '/myTasks',
  myTasksMaintainer: '/my-tasks-maintainer',
  task: '/task/:taskId',
  subtask: '/task/:taskId/subtask/:subtaskId',
  archivedTask: '/archivedTask/:taskId',
  archivedSubtask: '/archivedTask/:taskId/archivedSubtaskId/:subtaskId',
  chat: '/private-chat/:privateChatId',
  users: '/users',
  archive: '/archive',
  expenses: '/expenses',
  storageProject: '/storageProject',
  polygonStatistic: '/polygonStatistic/:projectId',
  polygon: 'polygon/:projectId/:polygonId',
  allPolygon: '/all-polygon',
  userStatistic: '/users-statistic',
  singlePolygon: '/polygon-single/:polygonStatisticID/:polygonId',
  singlePolygonStatistic: '/polygon-single/:polygonStatisticID/',
  userTasks: '/user-tasks/:userId',
  scale: '/scale',
  file: '/file/:fileId',
  fileGraphics: '/file/graphics',
  fileGraphicsI: '/file/graphicsI',
  forum: '/forum/:sectionId',
  tred: '/forum/section/:sectionId/tread/:treadID/page/:pageNumber',
  projectI: '/projectI',
  projectIUser: '/projectI/:userId',
  fileSelf: '/file/self/:fileId',
  chronometer: '/chronometer',
};

type ErrorMessagesType = {
  [key: string]: string;
};

type ActionTypesType = {
  [key: string]: { name: string; color: string };
};

type StatusColorType = Array<{ id: number; name: string; rusName: string }>;

export type ProjectStatusesType = {
  id: number;
  status: number;
  isNew: boolean;
};

export type SelectLinksType = {
  id: number;
  options: Array<{ name: string }>;
};

export type ExpensesAccordionHeaderType = Array<{ name: string; class: string }>;

export type OptionsType = Array<{
  id?: number;
  name?: string;
  title: string;
  Icon?: React.FC;
  onClick?: (value: any) => void;
  className?: string;
  isHaveChildrenPopover?: boolean;
}>;

export const ERROR_MESSAGES: ErrorMessagesType = {
  INCORRECT_LOGIN: 'Неверный логин',
  INCORRECT_PASSWORD: 'Неверный пароль',
  USER_ALREADY_EXISTS: 'Пользователь с таким именем уже существует',
  USER_NOT_FOUND: 'Неверный логин',
  TASK_NOT_FOUND: 'Задача не найдена или не хватает прав',
  SUBTASK_NOT_FOUND: 'Подзадача не найдена или не хватает прав',
  PROJECT_ALREADY_EXISTS: 'Проект с таким именем или номером уже существует',
  SUBTASK_ACCESS_DENIED: 'Нет прав доступа!',
  TASK_ACCESS_DENIED: 'Нет прав доступа!',
};

export const ACTION_TYPES: ActionTypesType = {
  'New Message': { name: 'Новое сообщение', color: '#149939' },
  'Note created': { name: 'Добавлена заметка', color: '#149939' },
  'File deleted': { name: 'Удален файл', color: '#dc3545' },
  'File uploaded': { name: 'Добавлен файл', color: '#149939' },
  'Status changed': { name: 'Изменен статус', color: '#149939' },
  'Maintainer changed': { name: 'Изменен главный исполнитель', color: '#149939' },
  'Assignee added': { name: 'Добавлен исполнитель', color: '#149939' },
  'Deassign user': { name: 'Удален исполнитель', color: '#dc3545' },
  'Subtask created': { name: 'Новая подзадача', color: '#149939' },
};

export const PreloaderSizes = {
  s: '25px',
  m: '60px',
  l: '100px',
};

export const PAGE_LIST_COLUMNS_TITLES: string[] = [
  'Дата',
  'Название задачи, подзадачи',
  'Исполнитель',
  'Название проекта',
];

export const EXPENSES_ACCORDION_HEADER_TITLES: ExpensesAccordionHeaderType = [
  { name: 'Теги', class: 'tags' },
  { name: 'Стоимость, $', class: 'price' },
  { name: 'Задача', class: 'task' },
];

export const statusOptions = [
  { label: 'Активный', value: 1, color: '#149939' },
  { label: 'Модератор', value: 2, color: '#E5580A' },
  { label: 'Только просмотр', value: 3, color: '#650B78' },
  { label: 'Приостановлен', value: 4, color: '#303030' },
];

export const breakpointColumnsObj = {
  default: 4,
  4000: 6,
  2560: 5,
  1920: 4,
  1500: 3,
  1440: 3,
  1024: 2,
};

export const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export const ROLES = {
  USER: 'User',
  ADMIN: 'Admin',
  MODERATOR: 'Moderator',
  GUEST: 'Guest',
};

export const FORUM_ROLES = {
  ADMIN_MODERATOR: 'ADMIN_MODERATOR',
  ADMIN: 'ADMIN',
};

export const STORAGE_ATTACHMENTS_TYPES = {
  FILE: 'File',
  NOTE: 'Note',
};

export const STATUSES_COLORS: StatusColorType = [
  { id: 1, name: 'red', rusName: 'Красный' },
  { id: 2, name: 'green', rusName: 'Зеленый' },
  { id: 3, name: 'yellow', rusName: 'Желтый' },
  { id: 4, name: 'orange', rusName: 'Оранжевый' },
  { id: 5, name: 'blue', rusName: 'Синий' },
  { id: 6, name: 'purple', rusName: 'Фиолетовый' },
  { id: 7, name: 'grey', rusName: 'Серый' },
  { id: 8, name: 'black', rusName: 'Черный' },
];

export const messageActions: string[] = ['Добавлен файл', 'Удален файл'];

export const taskPaginationLimit = 5;

export const tasksSiblingPaginationCount = 1;

export const DOTS = '...';

export const startStatusOptions = ['Старт', 'Завершить'];
export const closeStatusOptions = ['Завершить'];

export const colors = [
  { id: 1, color: '#1E1C2A', colorName: 'Основной цвет' },
  { id: 2, color: '#5A32FB', colorName: 'Акцент' },
  { id: 3, color: '#917AED', colorName: 'Доп.цвет 1' },
];

export const selectLinks: SelectLinksType = {
  id: 1,
  options: [{ name: 'Test1' }, { name: 'Test2' }, { name: 'Test3' }],
};

export const selects = [selectLinks];

export const polygonBlockTextFontSizes = [
  { title: '6px' },
  { title: '8px' },
  { title: '10px' },
  { title: '12px' },
  { title: '14px' },
  { title: '16px' },
  { title: '18px' },
  { title: '20px' },
  { title: '22px' },
];

export const sendOptions: OptionsType = [
  {
    id: 1,
    title: 'Отправить наверх',
    Icon: SendToTopIcon,
    onClick: () => {},
    isHaveChildrenPopover: false,
    className: 'send-to-top-icon',
  },
  {
    id: 2,
    title: 'Вверх',
    Icon: LongArrowTopIcon,
    onClick: () => {},
    isHaveChildrenPopover: false,
    className: 'long-arrow-top-icon',
  },
  {
    id: 3,
    title: 'Вниз',
    Icon: LongArrowTopIcon,
    onClick: () => {},
    isHaveChildrenPopover: false,
    className: 'long-arrow-bottom-icon',
  },
  {
    id: 4,
    title: 'Отправить вниз',
    Icon: SendToTopIcon,
    onClick: () => {},
    isHaveChildrenPopover: false,
    className: 'send-to-bottom-icon',
  },
];

export const mainPolygonOptions: OptionsType = [
  {
    id: 1,
    title: 'Описание',
    Icon: InfoIcon,
    onClick: () => {},
    isHaveChildrenPopover: false,
  },
  {
    id: 2,
    title: 'Редактировать',
    Icon: EditIcon,
    onClick: () => {},
    isHaveChildrenPopover: false,
  },
  {
    id: 3,
    title: 'Исполнители',
    Icon: ExecutorsIcon,
    onClick: () => {},
    isHaveChildrenPopover: false,
  },
  {
    id: 4,
    title: 'Добавить задачу',
    Icon: AddTaskIcon,
    onClick: () => {},
    isHaveChildrenPopover: false,
  },
  {
    id: 5,
    title: 'Добавить линк',
    Icon: LinkAddIcon,
    onClick: () => {},
    isHaveChildrenPopover: false,
  },
  {
    id: 6,
    title: 'Удалить',
    Icon: RemoveIcon,
    onClick: () => {},
    isHaveChildrenPopover: false,
  },
];

export const selectorStyle = {
  container: (provided: any) => ({
    ...provided,
    minWidth: '100%',
  }),
  control: (provided: any) => ({
    ...provided,
    background: '#f5f5f5',
    border: 'none',
    borderRadius: 0,
    borderBottomColor: '#9e9e9e',
    minHeight: '30px',
    height: '30px',
    width: '100%',
    boxShadow: null,
    textAlign: 'left',
  }),
  input: (provided: any) => ({
    ...provided,
    margin: '20',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    paddingLeft: '0px',
    fontFamily: 'Roboto Condensed, sans-serif',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: '300',
    color: '#303030',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    margin: 0,
    paddingLeft: '0px',
    fontFamily: 'Roboto Condensed, sans-serif',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 'normal',
    color: '#303030',
    backgroundColor: '#f5f5f5',
    textAlign: 'left',
  }),
  menu: (provided: any) => ({
    ...provided,
    textAlign: 'left',
    width: '100%',
    padding: 0,
    borderRadius: 0,
    position: 'relative',
    zIndex: 9999,
  }),
  menuList: (provided: any) => ({
    ...provided,
    backgroundColor: '#f5f5f5',
  }),
  option: (provided: any) => ({
    ...provided,
    position: 'relative',
    padding: '7px 5px 7px 8px',
    fontFamily: 'Roboto Condensed, sans-serif',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: '300',
    backgroundColor: '#f5f5f5',
    textAlign: 'left',
    color: '#303030',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ':hover': {
      cursor: 'pointer',
      textDecorationLine: 'underline',
    },
    ':active': {
      color: '#149939',
      textDecorationLine: 'none',
    },
  }),
};

export const polygonWidth = 1880;
export const polygonHeight = 3000;
export const polygonHeight2 = polygonHeight - 65;

export const tasksQueryLimit = 100;

export const polygonStatisticHeight = 130;
export const polygonStatisticWidth = 360;

export const TOOL_TIP_STATUS: any = {
  '1': { title: 'проблема', color: 'red' },
  '2': { title: 'выполнена', color: 'green' },
  '3': { title: 'выполняется', color: 'yellow' },
  '4': { title: 'срочно', color: 'orange' },
  '5': { title: 'остановлена', color: 'blue' },
  '6': { title: 'другое', color: 'purple' },
  '7': { title: 'создана', color: 'grey' },
  '8': { title: 'архив', color: 'black' },
};
