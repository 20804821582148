import React from 'react';

export const UnPined = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.91667 8.6241L8.38789 9.09532L6.31311 11.1701L6.16667 11.3166V11.5237V11.667V12.167H6.66667H11.4596L12.1262 12.8337H10.8333H10.3333V13.3337V16.5859L10 17.5859L9.66667 16.5859V13.3337V12.8337H9.16667H5.5V11.0408L7.85355 8.68721L7.91667 8.6241ZM8.66667 3.33366V5.84322L8 5.17655V4.16699V3.85798L7.72361 3.71978L6.33333 3.02464V2.16699H13.6667V3.02464L12.2764 3.71978L12 3.85798V4.16699V8.33366V8.54077L12.1464 8.68721L14.5 11.0408V11.6772L11.3333 8.50991V3.33366V2.83366H10.8333H9.16667H8.66667V3.33366Z'
        fill='#303030'
        stroke='#303030'
      />
      <path
        d='M1.89171 1.8916L0.833374 2.94993L17.05 19.1666L18.1084 18.1083L9.16671 9.1666L1.89171 1.8916Z'
        fill='black'
      />
    </svg>
  );
};
