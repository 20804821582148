import './MyPersonalPanel.scss';

import { Field, Formik } from 'formik';
import React, { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { uploadAvatar } from '../../assets/icons';
import { RequestHandler } from '../../components';
import { PATHS, ROLES } from '../../constants/constants';
import { useAuthContext, useModalContext } from '../../context';
import { EditUserInput } from '../../graphql/generated/graphql';
import { useEditUserMutation } from '../../hooks/useMutations';
import { ModalStatuses } from '../../types/enums';
import { addKarma, getUserAvatar } from '../../utils';
import { Button } from '../button/Button';

type MyPersonalPanelPropsType = {
  onRequestClose: () => void;
};

export const MyPersonalPanel: FC<MyPersonalPanelPropsType> = ({ onRequestClose }) => {
  const { onLogout, currentUserData } = useAuthContext();

  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  const [editUser] = useEditUserMutation();

  const [load, setLoad] = useState(false);

  const navigate = useNavigate();

  const initialValues: EditUserInput = {
    fullName: currentUserData?.fullName || '',
    image: '',
    theNote: currentUserData?.theNote || '',
  };

  const handleAvatarSubmit = async (values: EditUserInput) => {
    if (
      !values?.image ||
      (values?.image instanceof File && !values?.image.type.startsWith('image/'))
    ) {
      toast.error('Выберите изображение');
      return;
    }

    setLoad(true);
    await editUser({
      variables: {
        data: {
          image: values?.image,
        },
      },
    });
    setLoad(false);
  };

  return (
    <div className='dropdown__content'>
      <div className='content__flex'>
        <Formik initialValues={initialValues} onSubmit={handleAvatarSubmit}>
          {({ handleSubmit, setFieldValue }) => (
            <>
              <div className='user__head'>
                <div className='avatar__image-upload'>
                  <label htmlFor='avatar-input'>
                    <div>
                      <RequestHandler loading={load}>
                        <div className='user__head-avatar'>
                          <img src={getUserAvatar(currentUserData?.image)} alt='avatar' />
                          <img
                            src={uploadAvatar}
                            alt='avatar'
                            className='avatar__upload'
                          />
                        </div>
                      </RequestHandler>
                    </div>
                  </label>
                  <input
                    id='avatar-input'
                    name='image'
                    type='file'
                    onChange={(e) => {
                      setFieldValue('image', e?.currentTarget?.files?.[0]);
                      handleSubmit();
                    }}
                  />
                </div>
                <div className={'user__info'}>
                  <Field
                    autoComplete='off'
                    name='fullName'
                    placeholder={'Нет имени'}
                    className={'user__info-name'}
                    disabled={true}
                  />
                  <span>{addKarma(currentUserData as any)}</span>
                </div>
              </div>
              <div className='user__note'>
                <div className='user__note-inner'>
                  {currentUserData?.theNote
                    ? currentUserData?.theNote
                    : 'Твоя жизнь, твой художник'}
                </div>
              </div>
            </>
          )}
        </Formik>
        <div className='user__actions'>
          {currentUserData?.role !== ROLES.USER && currentUserData?.role !== ROLES.GUEST && (
            <span onClick={onRequestClose}>
              <Link to='/users' className='header__link'>
                Все пользователи{' '}
              </Link>
            </span>
          )}
          {currentUserData?.role === ROLES.ADMIN && (
            <span
              onClick={() => {
                handleSetModalStatus(ModalStatuses.CHANGE_TAGS_MODAL);
                handleChangeModalParameters([
                  {
                    key: 'onCallback',
                    value: () => {},
                  },
                ]);
              }}
            >
              Настройки тегов
            </span>
          )}
          {(currentUserData?.role === ROLES.MODERATOR ||
            currentUserData?.role === ROLES.ADMIN) && (
            <span
              onClick={() => {
                handleSetModalStatus(ModalStatuses.CHANGE_TAGS_USER_MODAL);
                handleChangeModalParameters([
                  {
                    key: 'onCallback',
                    value: () => {},
                  },
                ]);
              }}
            >
              Настройки тегов пользователей
            </span>
          )}
          {(currentUserData?.role === ROLES.ADMIN ||
            currentUserData?.role === ROLES.MODERATOR) && (
            <span
              onClick={() => {
                handleSetModalStatus(ModalStatuses.USER_GROUP);
              }}
            >
              Группы пользователей
            </span>
          )}{' '}
          {currentUserData?.role === ROLES.ADMIN && (
            <Link
              to={PATHS.decideOnTheFlyPackage}
              style={{ padding: '5px 0px' }}
              onClick={onRequestClose}
            >
              Решай на лету
            </Link>
          )}
          {currentUserData?.role === ROLES.ADMIN && (
            <span
              onClick={() => {
                handleSetModalStatus(ModalStatuses.TREADS_FONS_MODAL);
              }}
            >
              Настройка фонов для треддов
            </span>
          )}
          {currentUserData?.role === ROLES.ADMIN && (
            <span onClick={onRequestClose}>
              <Link to={PATHS.projects}>Проекты</Link>
            </span>
          )}
          {(currentUserData?.role === ROLES.ADMIN ||
            currentUserData?.role === ROLES.MODERATOR) && (
            <span onClick={onRequestClose}>
              <Link to={PATHS.userStorage}>Доступ в хранилище</Link>
            </span>
          )}
          <span onClick={onRequestClose}>
            <Link to={PATHS.storage}>Хранилище</Link>
          </span>
          {currentUserData?.role === ROLES.ADMIN && (
            <span onClick={onRequestClose}>
              <Link to={PATHS.storageProject}>Хранилище проектов</Link>
            </span>
          )}
          {currentUserData?.role === ROLES.ADMIN && (
            <span onClick={onRequestClose}>
              <Link to={PATHS.allPolygon}>Полигоны</Link>
            </span>
          )}
          {currentUserData?.role === ROLES.ADMIN && (
            <span onClick={onRequestClose}>
              <Link to='/archive'>Архив</Link>
            </span>
          )}
        </div>
        <div className='user__buttons'>
          <Button
            onClick={() => {
              onRequestClose();
              navigate(PATHS.userSettings);
            }}
            height='25px'
            backgroundColor='#f5f5f5'
            width='181px'
            text='Настройки пользователя'
          />
          <Button
            onClick={() => onLogout()}
            height='25px'
            backgroundColor='#f5f5f5'
            width='181px'
            text='Выйти из системы'
          />{' '}
          <Button
            onClick={() => {
              handleSetModalStatus(ModalStatuses.DELETE_ACCOUNT);
            }}
            height='25px'
            backgroundColor='#f5f5f5'
            width='181px'
            text='Удалить аккаунт'
            margin='15px 0px 0px 0px'
          />
        </div>
      </div>
    </div>
  );
};
