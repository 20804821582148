import './CreateTaskOrSubtaskModal.scss';

import formatISO from 'date-fns/formatISO';
import { Form, Formik } from 'formik';
import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';

import { useAuthContext } from '../../../../context';
import { Maybe } from '../../../../graphql/generated/graphql';
import {
  useCreateSubtaskMutation,
  useCreateTaskMutation,
} from '../../../../hooks/useMutations';
import { useGetProjectLightQuery, useGetTasksQuery } from '../../../../hooks/useQueries';
import { getErrorText } from '../../../../utils';
import { addTaskSchema } from '../../../../validation/schemas';
import { AppInput } from '../../../appInput/AppInput';
import { AppSelect } from '../../../appSelect/AppSelect';
import { Button } from '../../../button/Button';
import { CustomDatePicker } from '../../../customDatePicker/CustomDatePicker';
import { CrossIcon } from '../../../icons';
import { ResponsibleUser } from '../../../responsibleUser/ResponsibleUser';
import { ResponsibleUserItem } from '../../../responsibleUserItem/ResponsibleUserItem';
import { TagsSelect } from '../../../tagsSelect/TagsSelect';
import { Modal } from '../../Modal';

type CreateTaskOrSubtaskModalType = {
  isOpen: boolean;
  onClose: () => void;
  projectId?: string;
  addTasks: (value: number) => void;
};

type Users = {
  title?: string | undefined;
  taskId?: Maybe<number> | undefined;
  selectedUser: {
    id: Maybe<number> | undefined;
    name: Maybe<string> | undefined;
    image: Maybe<string> | undefined;
  };
  tagsId: string[];
  endDate: Date | null;
};

export const CreateTaskOrSubtaskModal: FC<CreateTaskOrSubtaskModalType> = ({
  onClose,
  projectId,
  addTasks,
}) => {
  const { currentUserData } = useAuthContext();

  const [createTask] = useCreateTaskMutation();

  const [createSubtask] = useCreateSubtaskMutation();

  const { refetch: refetchProjectTasks } = useGetProjectLightQuery({}, projectId);

  const {
    data: tasksData,
    loading: tasksDataLoading,
    refetch: tasksDataRefetch,
  } = useGetTasksQuery(Number(projectId));

  const [isChooseUserModalOpen, setIsChooseUserModalOpen] = useState(false);

  const handleCloseAddTaskModal = () => {
    onClose();
    setIsChooseUserModalOpen(false);
  };

  const handleCreateTask = async (values: Users) => {
    const selectedTask = tasksData?.getTasks.rows.find(
      (task) => task?.id === values.taskId,
    );
    if (values.title && projectId && values.taskId) {
      await createSubtask({
        variables: {
          data: {
            name: values.title,
            parentId: +values.taskId,
            statusId: 7,
            maintainerId: selectedTask?.maintainer && selectedTask?.maintainer.id,
            tagsId: values.tagsId,
            endDate: values.endDate ? formatISO(values.endDate) : null,
          },
        },
        onCompleted: (response) => {
          if (response?.createSubtask.id) {
            onClose();
            toast.success('Подзадача успешно создана!');
            addTasks(response?.createSubtask.id);
          }
        },
        onError: (error) => {
          if (error?.message) {
            toast.error(getErrorText(error.message));
          }
        },
      });
    }
    if (values.title && projectId && !values.taskId) {
      await createTask({
        variables: {
          data: {
            name: values.title,
            projectId: +projectId,
            statusId: 7,
            maintainerId: values.selectedUser.id || currentUserData?.id,
            tagsId: values.tagsId,
            endDate: values.endDate ? formatISO(values.endDate) : null,
          },
        },
        onCompleted: (response) => {
          handleCloseAddTaskModal();
          addTasks(response?.createTask?.id);

          toast.success('Задача успешно создана!');
        },
        onError: (error) => {
          getErrorText(error.message);
        },
      });
    }
    await tasksDataRefetch();
    await refetchProjectTasks();
  };

  const handleChooseUserModalOpen = async () => {
    setIsChooseUserModalOpen(true);
  };

  const initialValues = {
    title: '',
    taskId: undefined,
    selectedUser: {
      id: null,
      name: '',
      image: '',
    },
    tagsId: [],
    endDate: null,
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={addTaskSchema}
      onSubmit={handleCreateTask}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div className='createTasksOrSubtask__main'>
            <div
              className='createTasksOrSubtask__data'
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              {!values.taskId ? (
                <div className='createTasksOrSubtask__data-helper-text'>
                  Для создания подзадачи Выберите родительскую задачу
                </div>
              ) : null}
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                className='createTasksOrSubtask__data-parent-select'
              >
                <AppSelect
                  name='taskId'
                  options={tasksData?.getTasks.rows?.map((taskSubtask) => ({
                    value: taskSubtask?.id,
                    label: taskSubtask?.name || 'Нет имени',
                  }))}
                  isLoading={tasksDataLoading}
                  placeholder='Родительская задача'
                  isSearchable={true}
                />
                {values.taskId ? (
                  <CrossIcon
                    width='10px'
                    height='10px'
                    onClick={() => setFieldValue('taskId', undefined)}
                  />
                ) : null}
              </div>
            </div>
            <div
              className={`createTasksOrSubtask__title${values.taskId ? '-subtask' : ''}`}
            >
              <AppInput
                label={values.taskId ? 'Название подзадачи:' : 'Название задачи:'}
                name='title'
              />
            </div>
            {!values.taskId ? (
              <ResponsibleUserItem
                handleChooseUserModalOpen={handleChooseUserModalOpen}
                values={values}
                onSetFieldValue={setFieldValue}
              />
            ) : null}
            <CustomDatePicker name='endDate' label='Дата сдачи:' endDateSet />
            <TagsSelect name='tagsId' />
            <Button
              text={`Добавить ${values.taskId ? 'подзадачу' : 'задачу'}`}
              type='submit'
              width='100%'
              height={25}
              onClick={() => handleCreateTask(values)}
            />
          </div>
          <Modal
            isOpen={isChooseUserModalOpen}
            onClose={() => setIsChooseUserModalOpen(false)}
          >
            <ResponsibleUser
              name='selectedUser'
              setIsSelect={() => setIsChooseUserModalOpen(false)}
            />
            <Button
              width='93%'
              margin={10}
              text='Назад к исполнителям'
              onClick={() => setIsChooseUserModalOpen(false)}
              height={30}
            />
          </Modal>
        </Form>
      )}
    </Formik>
  );
};
