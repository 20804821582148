import './UsersList.scss';

import React, { Fragment } from 'react';

import { UsersListItem } from '../../components';
import { addKarma } from '../../utils';

export type UsersListItemType = {
  createdAt?: string | null;
  creator?: {
    login?: string | null;
  } | null;
  creatorId?: number | null;
  fullName?: string | null;
  id?: number | null;
  image?: string | null;
  lastConnect?: string | null;
  lastEditerUser?: {
    login?: string | null;
  } | null;
  login?: string | null;
  registrationLink?: string | null;
  role?: string | null;
  theNote?: string | null;
  updatedAt?: string | null;
  isAnalytics: boolean;
  editDate?: string | null;
  accessibleProjectsStorage: Array<{
    __typename?: 'Project';
    id: number;
    name: string;
  }>;
  tags: {
    count: number;
    rows: Array<{
      id: number;
      name: string;
      color?: string | null;
      textColor?: string | null;
    }>;
  };
  chronographReports?: any;
};

type UsersListProps = {
  users?: UsersListItemType[];
  loading: boolean;
};

export const UsersList = ({ users, loading }: UsersListProps) => {
  return (
    <div className='usersList'>
      <div className='usersList__header'>
        <h3 className='usersList__title' style={{ width: '5%' }}>
          ID
        </h3>
        <h3 className='usersList__title'>Имя пользователя</h3>
        <h3 className='usersList__title'>Роль</h3>
        <h3 className='usersList__title'>Создан</h3>
        <h3 className='usersList__title' style={{ width: '25%' }}>
          Теги
        </h3>

        <h3 className='usersList__title'>Последний вход</h3>
        <h3 className='usersList__title'>Аналитика</h3>
      </div>
      <div>
        {users?.length
          ? users.map((user) => (
              <Fragment key={user.id}>
                <UsersListItem
                  user={user}
                  id={user.id}
                  login={addKarma(user as any)}
                  role={user.role}
                  createdAt={user.createdAt}
                  creator={user.creator?.login}
                  registrationLink={user?.registrationLink}
                  updatedAt={user?.updatedAt}
                  lastEditerUser={user.lastEditerUser?.login}
                  lastConnect={user.lastConnect}
                  isAnalytics={user.isAnalytics}
                  tags={user?.tags}
                  chronographReports={user?.chronographReports}
                />
              </Fragment>
            ))
          : !loading && <p className='usersList__text'>Пользователи не найдены...</p>}
      </div>
    </div>
  );
};
