import './ExpensesTaskCell.scss';

import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { ProjectStatus } from '../../project-status/ProjectStatus';
import { ExpensesTaskType } from '../expensesRow/ExpensesRow';

export const ExpensesTaskCell: FC<
  Pick<ExpensesTaskType, 'status' | 'parentId' | 'id' | 'name' | 'code'>
> = ({ id, name, status, parentId, code }) => {
  return (
    <div className='expensesTaskCell'>
      <ProjectStatus projectStatus={status?.id} id={id} />
      <Link to={parentId ? `/task/${parentId}/subtask/${id}` : `/task/${id}`}>
        {`#${code} ${name}`}
      </Link>
    </div>
  );
};
