import './BlockIcon.scss';

import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const BlockIcon: FC<BlockIconType> = ({
  width = '20px',
  height = '20px',
  className = 'block-icon',
  color = '#FFFFFF',
  onClick,
  isBordered = false,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      style={isBordered ? { borderLeft: '1px solid #AFAFAF' } : {}}
    >
      <path
        d='M10.0002 1.66675C7.70266 1.66675 5.8335 3.53591 5.8335 5.83342V8.33342H5.00016C4.081 8.33342 3.3335 9.08091 3.3335 10.0001V16.6667C3.3335 17.5859 4.081 18.3334 5.00016 18.3334H15.0002C15.9193 18.3334 16.6668 17.5859 16.6668 16.6667V10.0001C16.6668 9.08091 15.9193 8.33342 15.0002 8.33342H14.1668V5.83342C14.1668 3.53591 12.2977 1.66675 10.0002 1.66675ZM15.0002 10.0001L15.0018 16.6667H5.00016V10.0001H15.0002ZM7.50016 8.33342V5.83342C7.50016 4.45508 8.62183 3.33341 10.0002 3.33341C11.3785 3.33341 12.5002 4.45508 12.5002 5.83342V8.33342H7.50016Z'
        fill={color}
      />
    </svg>
  );
};

type BlockIconType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
> & { isBordered?: boolean };
