import PropTypes from 'prop-types';
import React, { createContext, FC, useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { useDebounce } from '../hooks';

const SearchContext = createContext<any>('');
const useSearchContext = () => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error('useSearchContext must be used within a SearchProvider');
  }
  return context;
};

const SearchProvider: FC = ({ children }) => {
  const [searchValue, setSearchValue] = React.useState('');

  const { debouncedValue, isDebouncing } = useDebounce(searchValue);

  const { pathname } = useLocation();

  const initPathRef = useRef<string>();

  React.useEffect(() => {
    if (!initPathRef.current) {
      initPathRef.current = pathname;
    } else if (initPathRef.current !== pathname) {
      setSearchValue('');
    }
  }, [pathname]);

  return (
    <SearchContext.Provider
      value={{
        searchValue,
        debouncedValue,
        isDebouncing,
        changeSearchValue: setSearchValue,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

SearchProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { SearchProvider, useSearchContext };
