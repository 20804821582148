import './InfoIcon.scss';

import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const InfoIcon: FC<InfoIconType> = ({
  width = '20px',
  height = '20px',
  className = 'info-icon',
  color = '#FFFFFF',
  onClick,
  isBordered = false,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      style={isBordered ? { borderLeft: '1px solid #AFAFAF' } : {}}
    >
      <path
        d='M1.75 10C1.75 5.44411 5.44411 1.75 10 1.75C14.5559 1.75 18.25 5.44411 18.25 10C18.25 14.5559 14.5559 18.25 10 18.25C5.44411 18.25 1.75 14.5559 1.75 10ZM2.23438 10C2.23438 14.2879 5.71214 17.7656 10 17.7656C14.2879 17.7656 17.7656 14.2879 17.7656 10C17.7656 5.71214 14.2879 2.23438 10 2.23438C5.71214 2.23438 2.23438 5.71214 2.23438 10Z'
        fill={color}
        stroke={color}
      />
      <path
        d='M9.69064 6.87186C9.60859 6.78981 9.5625 6.67853 9.5625 6.5625C9.5625 6.44647 9.60859 6.33519 9.69064 6.25314C9.77269 6.17109 9.88397 6.125 10 6.125C10.116 6.125 10.2273 6.17109 10.3094 6.25314C10.3914 6.33519 10.4375 6.44647 10.4375 6.5625C10.4375 6.67853 10.3914 6.78981 10.3094 6.87186C10.2273 6.95391 10.116 7 10 7C9.88397 7 9.77269 6.95391 9.69064 6.87186ZM9.875 13.875V9.25H10.125V13.875H9.875Z'
        fill={color}
        stroke={color}
      />
    </svg>
  );
};

type InfoIconType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
> & { isBordered?: boolean };
