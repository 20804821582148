import './TagsSelect.scss';

import React, { useEffect, useState } from 'react';

import { Tag, TagType, useGetTagsQuery } from '../../graphql/generated/graphql';
import { TagItem } from '..';
import { CrossIcon } from '../icons';

type TagsPropsType = {
  editTagIds?: string[];
  isEditMode?: boolean;
  setValue: any;
};

export const TagsSelectNew = ({
  editTagIds,
  isEditMode = false,
  setValue,
}: TagsPropsType) => {
  const { data: dataTags } = useGetTagsQuery({
    variables: { data: { type: TagType.User } },
    fetchPolicy: 'network-only',
  });

  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [tagsOptions, setTagsOptions] = useState<Tag[]>([]);

  const openTagsSelect = () => {
    setIsOpenSelect(true);
  };

  const closeTagsSelect = () => {
    setIsOpenSelect(false);
  };

  const selectTag = (id: number) => {
    const tagsOptionsNew = tagsOptions.filter((item) => item.id !== id);
    const tagToSelected = tagsOptions.filter((item) => item.id === id);
    setValue([...selectedTags, ...tagToSelected].map((item) => String(item.id)));
    setSelectedTags([...selectedTags, ...tagToSelected]);
    setTagsOptions(tagsOptionsNew);
  };

  const delTag = (id: number) => {
    const tagToDel = selectedTags.filter((item) => item.id === id);
    const selectedTagsNew = selectedTags.filter((item) => item.id !== id);
    setValue(selectedTagsNew.map((item) => String(item.id)));
    setSelectedTags([...selectedTagsNew]);
    setTagsOptions([...tagsOptions, ...tagToDel]);
  };

  useEffect(() => {
    if (dataTags?.getTags.rows) {
      setTagsOptions(dataTags?.getTags.rows);
    }

    if (dataTags?.getTags.rows && isEditMode && editTagIds) {
      const selectedTagsEdit = dataTags?.getTags.rows.filter((item) =>
        editTagIds.includes(String(item.id)),
      );

      const newOptionsEdit = dataTags?.getTags.rows.filter(
        (item) => !editTagIds.includes(String(item.id)),
      );
      setSelectedTags([...selectedTagsEdit]);
      setTagsOptions([...newOptionsEdit]);
      setValue(editTagIds);
    }
  }, [dataTags?.getTags.rows, isEditMode]);

  return (
    <div className='tagsSelect'>
      <div className='tagsSelect__header'>
        <p>Теги:</p>
        <div className='tagsSelect__icon'>
          <CrossIcon color='#AFAFAF' onClick={openTagsSelect} width='9px' height='9px' />
        </div>
      </div>

      {selectedTags.length >= 1 && (
        <div className='tagsSelect__selectedList'>
          {selectedTags.map((tag) => (
            <div className='tagsSelect__selectedList-item' key={tag.id}>
              <TagItem
                title={tag.name}
                color={tag.color}
                textColor={tag.textColor as string}
                isClose
                closeFunc={() => delTag(tag.id)}
              />
            </div>
          ))}
        </div>
      )}

      {isOpenSelect ? (
        <div className='tagsSelect__optionsList'>
          <div className='tagsSelect__optionsList-header'>
            <p>Тег</p>
            <div className='tagsSelect__icon'>
              <CrossIcon
                color='#AFAFAF'
                onClick={closeTagsSelect}
                width='10px'
                height='10px'
              />
            </div>
          </div>
          {tagsOptions.length ? (
            <div className='tagsSelect__optionsList-inner'>
              {tagsOptions.map((tag) => (
                <div
                  key={tag.id}
                  className='tagsSelect__optionsList-items'
                  onClick={() => selectTag(tag.id)}
                >
                  <TagItem
                    id={tag.id}
                    title={tag.name}
                    color={tag.color}
                    textColor={tag.textColor as string}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className='tagsSelect__optionsList-item'>Все теги выбраны</div>
          )}
        </div>
      ) : null}
    </div>
  );
};
