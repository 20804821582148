import React from 'react';

export const FileMoveIcons = () => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.83333 5.25002V2.91669L1.75 7.00002L5.83333 11.0834V8.69169C8.75 8.69169 10.7917 9.62502 12.25 11.6667C11.6667 8.75002 9.91667 5.83335 5.83333 5.25002Z'
        fill='#303030'
      />
    </svg>
  );
};
