import '../PolygonStatistic/PolygonStatistic.scss';

import React, { useEffect, useRef, useState } from 'react';
import { Stage } from 'react-konva';
import { useNavigate, useParams } from 'react-router-dom';

import {
  ButtonTree,
  CrossIcon,
  EditIcon,
  OptionsMenu,
  PolygonBlockMenu,
  PolygonDescription,
  PolygonTreeLayer,
  RequestHandler,
} from '../../components';
import {
  ArrowDawnIcon,
  CancelIcon,
  LongArrowTopIcon,
  PaletteIcon,
  RemoveIcon,
  SaveIcon,
  SendToTopIcon,
  TemplateIcon,
} from '../../components/icons';
import { LinkIcon } from '../../components/icons/linkIcon/LinkIcon';
import { OptionsType, polygonHeight2, polygonWidth } from '../../constants/constants';
import { useMenuContext, useModalContext } from '../../context';
import {
  Poligon as PoligonType,
  useLoadFileMutation,
} from '../../graphql/generated/graphql';
import {
  useApiPolygonTemplates,
  useCheckAccessEditPolygon,
  useClickOutside,
  useLine,
  useMultiselectPolygon,
  usePolygon,
  useStateManagement,
} from '../../hooks';
import { useApiPolygonObject } from '../../hooks/useApiPolygonObject';
import {
  HoverRectIdType,
  ModalStatuses,
  OptionsStatuses,
  SendLevels,
} from '../../types/enums';
import { RectType } from '../../types/types';
import { fileSettings, idGenerate } from '../../utils';

export const Polygon = () => {
  const { descriptionState } = useMenuContext();
  const [isEditText, setIsEditText] = useState(false);
  const navigate = useNavigate();

  const [hoverRectId, setHoverRectId] = useState<{
    id: number | string;
    type: HoverRectIdType;
  }>({
    id: 0,
    type: HoverRectIdType.NONE,
  });

  const refWrapper = useRef(null);
  const refScroll = useRef<any>(null);

  const {
    optionsStatus,
    handleSetModalStatus,
    handleChangeModalParameters,
    handleSetOptionsStatus,
    handleCloseModal,
    childrenOptionsStatus,
  } = useModalContext();

  const { projectId, polygonId, polygonStatisticID } = useParams();

  const {
    rectangles,
    setRectangles,
    polygonTools,
    selectedIdRect,
    setSelectIdRect,
    descriptionMenuOpenHandler,
    descriptionMenuOpen,
    getSelectedRect,
    colorsData,
    setColorsData,
    getRectDescriptionById,
  } = usePolygon();

  const {
    onSaveColorsPallete,
    onSaveButton,
    polygonObject,
    handleSetPolygonObject,
    poligon,
    handleDeletePoligonObject,
    poligonObjectLoading,
    tasks,
  } = useApiPolygonObject({
    setColorsData,
    rectangles,
    setRectangles,
    polygonTools,
    selectedIdRect,
  });

  // if (location.pathname.toLowerCase().includes('single'))
  const { stateTools, setCancelUpdate, historyIndex, historyState } = useStateManagement({
    rectangles,
    setRectangles,
    polygonObject,
  });

  const { multiselectTools, selectionRectRef, trRef, layerRef } = useMultiselectPolygon({
    rectangles,
    setRectangles,
    selectedIdRect,
    setCancelUpdate,
  });

  const { templateOptions, templateChildrenOptions, createPoligonTemplateMutation } =
    useApiPolygonTemplates({ setRectangles, poligon });
  const [loadFileMutation] = useLoadFileMutation();

  const { checkAccess } = useCheckAccessEditPolygon({ poligon });
  const isAccess = checkAccess({ skipMessage: true });

  const { h_lines, v_lines, stage, lineTools } = useLine();

  const sendOptions = getSendOptions(polygonTools.sendToLayer);

  const createInitTaskList = () => {
    const convertTask = polygonObject?.task?.map((e: any) => {
      return {
        id: e?.id || e?.taskId,
      };
    });
    const convertSubTask = polygonObject?.subtask?.map((e: any) => {
      return {
        id: e?.id,
      };
    });

    const taskList: any = convertTask?.concat(
      convertSubTask?.filter(({ id }) => !convertTask.find((e) => e.id == id)) as any,
    );
    return taskList?.map((e: any) => {
      const task = tasks?.find((task) => task?.id === (e.taskId || e.id));
      return {
        value: e.taskId || e.id,
        label: task?.code + ' ' + task?.name,
      };
    });
  };

  const linkModalHandle = () => {
    const newTask: any = [];
    tasks?.map((e: any) => {
      if (e?.poligonObject?.id) {
        if (e?.poligonObject?.id === polygonObject?.id) newTask.push(e);
      } else {
        newTask.push(e);
      }
    });

    handleChangeModalParameters([
      {
        key: 'onCallback',
        value: (values: [{ value: number; label: string }]) => {
          handleSetPolygonObject({
            key: 'task',
            value: values?.map((e) => {
              return {
                taskId: e.value,
              };
            }),
            key2: 'subtask',
            value2: values?.map((e) => {
              return {
                id: e.value,
              };
            }),
          });

          handleCloseModal();
        },
      },
      {
        key: 'rect',
        value: rectangles,
      },
      {
        key: 'rectChange',
        value: (value: any) => {
          polygonTools.setRectTask(value);
        },
      },
      {
        key: 'selects',
        value: newTask,
      },
      {
        key: 'initialValues',
        value: createInitTaskList(),
      },
    ]);
  };

  useClickOutside(refWrapper, () => {
    setSelectIdRect(null);
  });

  const handleCheckAndSetColor = (values: {
    polygonBlockName: string;
    blockColorsName: string;
  }) => {
    const checkedColors = colorsData.find(
      (color) => color.color === values.blockColorsName,
    );
    if (!checkedColors && values.blockColorsName !== '') {
      onSaveColorsPallete([
        {
          id: Date.now().toString(),
          color: values.blockColorsName,
          name: values.polygonBlockName,
        },
        ...colorsData,
      ]);
    }
  };

  useEffect(() => {
    const paste = (event: any) => {
      const clipboardItems = event.clipboardData.items;
      const items = [].slice.call(clipboardItems).filter((item: any) => {
        return item.type.indexOf('image') !== -1;
      });
      if (items.length === 0) {
        return;
      }
      const item: any = items[0];
      const blob = item.getAsFile();
      if (!selectedIdRect) {
        fileSettings({ blob, loadFileMutation, polygonTools });
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(blob as any);
        reader.onload = function (e) {
          const image: any = new Image();
          image.src = e?.target?.result;
          image.onload = () => {
            loadFileMutation({
              variables: { data: { file: blob } },
            }).then((response: any) => {
              polygonTools.changeParameter({
                id: selectedIdRect,
                key: 'file',
                value: response.data.loadFile,
              });
            });
          };
        };
      }
    };

    window.addEventListener('paste', paste);

    return () => {
      window.removeEventListener('paste', paste);
    };
  });

  return (
    <div className='container'>
      <div style={{ display: 'flex' }}>
        <div className='statistic-page'>
          <div className='statistic-page__name'>
            #{projectId}
            {poligon?.project?.name ? poligon?.project?.name : poligon?.name}
          </div>

          <div className='statistic-page__instruments statistic-page__instruments_father mb20'>
            <span
              onClick={() => {
                navigate(`/polygonStatistic/${projectId}`);
              }}
              className='d-flex'
            >
              <div className='statistic-page__instruments-arrow'>
                <ArrowDawnIcon color='#303030' width='12px' height='12px' />
              </div>
              <div className='statistic-page__instruments-button'>
                {polygonObject?.name}
              </div>
            </span>

            <div
              className='statistic-page__instruments-button ml25'
              style={{ opacity: isAccess ? '1' : '0', cursor: 'default' }}
            >
              <EditIcon
                color='#303030'
                width='16px'
                height='16px'
                onClick={() => {
                  if (!isAccess) return;
                  handleSetModalStatus(ModalStatuses.EDIT_TITLE_MODAL);
                  handleChangeModalParameters([
                    {
                      key: 'onCallback',
                      value: (values: any) => {
                        handleSetPolygonObject({
                          key: 'name',
                          value: values.projectName,
                        });
                        handleCloseModal();
                      },
                    },
                    {
                      key: 'initialEditTitleValues',
                      value: {
                        projectName:
                          polygonObject?.name === 'Без имени' ? '' : polygonObject?.name,
                      },
                    },
                  ]);
                }}
              />
            </div>
          </div>
          <div
            className='statistic-page__inner'
            style={{
              justifyContent: isAccess ? 'center' : 'flex-end',
            }}
          >
            <div
              className='statistic-page__instruments statistic-page__instruments-inner-polygon'
              style={{ display: isAccess ? 'flex' : 'none' }}
            >
              <div
                className='statistic-page__instruments-button statistic-page__instruments_add'
                onClick={() => {
                  if (!isAccess) return;
                  handleSetModalStatus(ModalStatuses.ADD_POLYGON_BLOCK_MODAL);
                  handleChangeModalParameters([
                    {
                      key: 'onCallback',
                      value: (values: any) => {
                        polygonTools.crateNew({
                          name: values.polygonBlockName,
                          color: values.blockColorsName,
                          file: values.file,
                          height: values.height,
                          width: values.width,
                        });
                        handleCheckAndSetColor(values);
                      },
                    },
                    {
                      key: 'colors',
                      value: colorsData,
                    },
                    {
                      key: 'projectName',
                      value: poligon?.project?.name,
                    },
                  ]);
                }}
              >
                <CrossIcon
                  className='statistic-page__instruments-icons'
                  color='white'
                  width='8px'
                  height='8px'
                />
                Добавить блок
              </div>
              <div className='statistic-page__instruments-group'>
                <CancelIcon
                  width='20px'
                  height='20px'
                  className='statistic-page__instruments-cancel'
                  color={historyIndex === 0 ? '#AFAFAF' : '#303030'}
                  onClick={() => {
                    if (!isAccess) return;
                    stateTools.cancel();
                  }}
                />
                <CancelIcon
                  width='20px'
                  height='20px'
                  className='statistic-page__instruments-forward'
                  color={historyIndex + 1 >= historyState.length ? '#AFAFAF' : '#303030'}
                  onClick={() => {
                    if (!isAccess) return;
                    stateTools.forward();
                  }}
                />
              </div>
              <div className='vertical-line' />

              <div className='statistic-page__instruments-button_save'>
                <SaveIcon
                  onClick={() => {
                    onSaveButton();
                  }}
                />
              </div>

              <div className='statistic-page__instruments-button_m0'>
                <TemplateIcon
                  onClick={() => {
                    if (!isAccess) return;
                    handleSetOptionsStatus(OptionsStatuses.TEMPLATE_MENU);
                    handleChangeModalParameters([
                      {
                        key: 'onCallback',
                        value: (values: any) => {
                          handleSetModalStatus(ModalStatuses.WITHOUT_STATUS);
                          if (!isAccess) return;
                          createPoligonTemplateMutation({
                            variables: {
                              data: {
                                name: values.template,
                                canvasObject: rectangles.map((e: RectType) => {
                                  return {
                                    ...e,
                                    id: idGenerate(),
                                    parentLink: undefined,
                                  };
                                }),
                              },
                            },
                          });
                        },
                      },
                    ]);
                  }}
                />
                <OptionsMenu
                  isOpen={optionsStatus === OptionsStatuses.TEMPLATE_MENU}
                  isChildrenPopoverOpen={
                    childrenOptionsStatus === OptionsStatuses.TEMPLATE_CHILDREN_MENU
                  }
                  options={templateOptions}
                  childrenOptions={templateChildrenOptions()}
                />
              </div>
              <div className='vertical-line' />
              <>
                <div
                  className='statistic-page__instruments-button_m0'
                  onClick={() => {
                    if (!isAccess) return;

                    handleSetModalStatus(ModalStatuses.POLYGON_PALETTE_MODAL);
                    handleChangeModalParameters([
                      {
                        key: 'onCallback',
                        value: (values: any) => onSaveColorsPallete(values),
                      },
                      {
                        key: 'initColors',
                        value: colorsData,
                      },
                    ]);
                  }}
                >
                  <PaletteIcon />
                </div>
                <div className='vertical-line' />
              </>

              {projectId && (
                <>
                  <div className='statistic-page__instruments-button_m0'>
                    <LinkIcon
                      onClick={() => {
                        if (!isAccess) return;
                        linkModalHandle();
                        handleSetModalStatus(ModalStatuses.LINK_ADD_MODAL);
                        handleChangeModalParameters([
                          {
                            key: 'projectId',
                            value: projectId,
                          },
                          {
                            key: 'addTasks',
                            value: (value: any) => {
                              const newTasks = JSON.parse(
                                JSON.stringify(polygonObject?.task),
                              );
                              newTasks?.push({
                                id: value,
                              });
                              handleSetPolygonObject({
                                key: 'task',
                                value: newTasks,
                              });
                            },
                          },
                        ]);
                      }}
                    />
                  </div>
                  <div className='vertical-line' />
                </>
              )}

              <div className='statistic-page__instruments-button_mr35'>
                <RemoveIcon
                  color='#303030'
                  onClick={() => {
                    if (!isAccess) return;
                    handleSetModalStatus(ModalStatuses.REMOVE_MODAL);
                    handleChangeModalParameters([
                      {
                        key: 'onCallback',
                        value: () => {
                          handleCloseModal();
                          handleDeletePoligonObject(Number(polygonId));
                        },
                      },
                      {
                        key: 'removeConfirmTitle',
                        value: polygonObject?.name,
                      },
                      {
                        key: 'additionalConfirmTitle',
                        value: 'полигон',
                      },
                    ]);
                  }}
                />
              </div>
            </div>
            <ButtonTree
              descriptionMenuOpen={descriptionMenuOpen}
              handleSetModalStatus={handleSetModalStatus}
              handleChangeModalParameters={handleChangeModalParameters}
              poligon={poligon}
              projectId={projectId}
              polygonId={polygonObject?.id ? polygonObject?.id : 0}
              polygonStatisticID={polygonStatisticID}
            />
          </div>
          <RequestHandler loading={poligonObjectLoading}>
            <div className='statistic-page__polygon' ref={refWrapper}>
              <div
                className='statistic-page__container'
                style={{
                  height: `calc(100vh - 197px - ${!descriptionState ? '41px' : '51px'})`,
                }}
                ref={refScroll}
              >
                <Stage
                  width={polygonWidth}
                  ref={stage as any}
                  height={polygonHeight2}
                  onMouseDown={(event) => {
                    polygonTools.checkDeselect({ event });
                    multiselectTools.onMouseDown({ event });
                    setIsEditText(false);
                    handleSetOptionsStatus(OptionsStatuses.WITHOUT_STATUS);
                  }}
                  onMouseUp={multiselectTools.onMouseUp}
                  onTouchStart={(event) => {
                    polygonTools.checkDeselect({ event });
                  }}
                  onMouseMove={(event) => {
                    multiselectTools.onMouseMove({ event });
                  }}
                >
                  <PolygonTreeLayer
                    rectangles={rectangles}
                    selectedIdRect={selectedIdRect}
                    setRectangles={setRectangles}
                    polygonTools={polygonTools}
                    selectionRectRef={selectionRectRef}
                    trRef={trRef}
                    layerRef={layerRef}
                    multiselectTools={multiselectTools}
                    h_lines={h_lines}
                    v_lines={v_lines}
                    lineTools={lineTools}
                    isEditText={isEditText}
                    navigate={navigate}
                    projectId={projectId as string}
                    isAccess={isAccess}
                    polygonStatisticID={polygonStatisticID}
                    hoverRectId={hoverRectId}
                    setHoverRectId={setHoverRectId}
                  />
                </Stage>
              </div>
              <PolygonBlockMenu
                refScroll={refScroll}
                rectanglesArray={rectangles}
                blockId={selectedIdRect}
                colors={colorsData}
                initialColorId={
                  colorsData.find((item) => item.color === getSelectedRect()?.fill)?.id
                }
                sendOptions={sendOptions}
                blockItem={({ id, value }: { id: number | string; value: boolean }) => {
                  polygonTools.blockItem({ id, value });
                }}
                onRemove={polygonTools.delete}
                changeParameter={polygonTools.changeParameter}
                onSaveParentLinks={(values) => {
                  polygonTools.changeParentLinkAndParentName({
                    id: selectedIdRect as any,
                    parentLink: values.parentLink,
                    parentName: values.parentName,
                  });
                }}
                onSetColor={(color) =>
                  polygonTools.changeParameter({
                    id: selectedIdRect as any,
                    key: 'fill',
                    value: color,
                  })
                }
                onMenuTextActionsSubmit={(value) => console.info('Text styles:', value)}
                removeConfirmTitle='раздел'
                setIsEditState={setIsEditText}
                selectedIdRect={selectedIdRect}
                initialInfoValues={{
                  info: getRectDescriptionById(selectedIdRect)?.text,
                }}
                linkModalHandle={linkModalHandle}
                poligon={poligon as PoligonType}
                polygonObject={polygonObject}
                file={getSelectedRect()?.file}
                changeImage={(file) => {
                  polygonTools.changeParameter({
                    id: selectedIdRect as any,
                    key: 'file',
                    value: file,
                  });
                }}
              />
            </div>
          </RequestHandler>
        </div>

        <PolygonDescription
          rectangles={rectangles}
          descriptionMenuOpenHandler={descriptionMenuOpenHandler}
          selectedBlockId={selectedIdRect}
          projectName={poligon?.project?.name}
          polygonObject={polygonObject}
          hoverRectId={hoverRectId}
          setHoverRectId={setHoverRectId}
        />
      </div>
    </div>
  );
};

const getSendOptions = (func: {
  (type: SendLevels): void;
  (arg0: SendLevels): void;
}): OptionsType => {
  return [
    {
      id: 1,
      title: 'Отправить наверх',
      Icon: SendToTopIcon,
      onClick: () => func(SendLevels.UP),
      isHaveChildrenPopover: false,
      className: 'send-to-top-icon',
    },
    {
      id: 2,
      title: 'Вверх',
      Icon: LongArrowTopIcon,
      onClick: () => func(SendLevels.TOP),
      isHaveChildrenPopover: false,
      className: 'long-arrow-top-icon',
    },
    {
      id: 3,
      title: 'Вниз',
      Icon: LongArrowTopIcon,
      onClick: () => func(SendLevels.BOTTOM),
      isHaveChildrenPopover: false,
      className: 'long-arrow-bottom-icon',
    },
    {
      id: 4,
      title: 'Отправить вниз',
      Icon: SendToTopIcon,
      onClick: () => func(SendLevels.DOWN),
      isHaveChildrenPopover: false,
      className: 'send-to-bottom-icon',
    },
  ];
};
