import './Storage.scss';

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  AccordionItemStorage,
  FileFilter,
  RequestHandler,
  SerchInput,
} from '../../components';
import { StorageItemTred } from '../../components/storage/storageItemTred/StorageItemTred';
import {
  Project as ProjectType,
  SortingField,
  SortingOrders,
  StorageFilterItems,
  useGetStorageProjectLazyQuery,
  useGetStorageProjectsQuery,
} from '../../graphql/generated/graphql';
import { useDebounce } from '../../hooks';
import { Pagination } from './Pagination';
import { ProjectAllTreeadItem } from './ProjectAllTreeadItem';

const limit = 12;

export const StorageTreds = () => {
  const [filter, setFilter] = useState([StorageFilterItems.All]);
  const [search, setSearch] = useState('');

  const [noTredCount, setNoTredCount] = useState<number | undefined>(undefined);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchFileName = queryParams.get('fileName');

  useEffect(() => {
    searchFileName && setSearch(searchFileName);
  }, [searchFileName]);

  const { debouncedValue } = useDebounce(search);
  const [data, setData] = useState<any>([]);

  const { loading, refetch } = useGetStorageProjectsQuery({
    variables: {
      projectsData: {
        limit: 99999999,
        threadStorageSearch: debouncedValue,
        sort: {
          type: SortingOrders.Asc,
          field: SortingField.Number,
        },
        threadStorageFilter: filter,
      },
      search: debouncedValue,
      storageFilter: filter,
      limit: limit,
      offset: 0,
      isOnlyFromThreads: true,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data) return;
      setData(data);
    },
    onError: () => {
      setData([]);
    },
  });

  enum ContentEnum {
    content = 'content',
    no_filter = 'no-filter',
    no_search_result = 'no-search-result',
  }

  const content = () => {
    if (filter?.length === 0) return ContentEnum.no_filter;
    if (
      (data?.getProjects?.rows[0]?.storageEntities?.rows?.length === 0 ||
        data?.getProjects?.rows?.length === 0) &&
      noTredCount === 0 &&
      noTredCount !== undefined
    )
      return ContentEnum.no_search_result;
    return ContentEnum.content;
  };

  return (
    <div className='container'>
      <div className='storage'>
        <div className='storage__body'>
          <div className='storage__title-wrapper'>
            <div className='storage__title'>Хранилище</div>
            <div style={{ display: 'flex' }}>
              <SerchInput
                handleSearch={setSearch}
                value={search}
                placeholder='Поиск файлов'
              />
              <FileFilter
                filter={filter}
                setFilter={(e: any) => {
                  setFilter(e);
                  refetch();
                }}
              />
            </div>
          </div>
          <div className='storage__line-horizontal'></div>
          {content() === ContentEnum.content ? (
            <>
              <div className='storage__table'>
                <div className='storage__row'>
                  <div className='storage__column storage__project'>Проект</div>
                </div>
                <RequestHandler loading={loading}>
                  {data?.getProjects?.rows?.map((project: any, index: number) => {
                    return (
                      <ProjectItem
                        project={project as ProjectType}
                        key={index}
                        filter={filter}
                        debouncedValue={debouncedValue}
                      />
                    );
                  })}
                </RequestHandler>
              </div>
            </>
          ) : (
            <div>
              {content() === ContentEnum.no_filter
                ? 'Выбери один из подходящих фильтров'
                : 'Файлы не найдены'}
            </div>
          )}
          <div className='storage__table'>
            <ProjectAllTreeadItem
              filter={filter}
              debouncedValue={debouncedValue}
              setNoTredCount={setNoTredCount}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ProjectItem = ({
  project,
  filter,
  debouncedValue,
}: {
  project: ProjectType | undefined;
  filter: any;
  debouncedValue: string;
}) => {
  useEffect(() => {
    setFileList((project?.storageEntities?.rows as any) || []);
  }, [project?.storageEntities?.rows]);
  const [fileList, setFileList] = useState(project?.storageEntities?.rows || []);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(limit);

  const [getStorage] = useGetStorageProjectLazyQuery({
    onCompleted: (data) => {
      setFileList(data?.getProject?.storageEntities?.rows as any);
    },
    variables: {
      id: project?.id as number,
      search: debouncedValue,
      storageFilter: filter,
      limit: dataPerPage,
      offset: (currentPage - 1) * dataPerPage,
      isOnlyFromThreads: true,
    },
  });
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (firstRender) {
      return setFirstRender(false);
    }
    getStorage();
  }, [currentPage]);

  if (fileList?.length === 0) return <div></div>;

  return (
    <AccordionItemStorage
      key={project?.id}
      title={`#${project?.number} ${project?.name}`}
      pagination={
        <Pagination
          dataPerPage={dataPerPage}
          totalData={project?.storageEntities?.count as number}
          paginate={paginate}
          currentPage={currentPage}
        />
      }
    >
      <div className='storage__itemWrapper-outer'>
        <div className='storage__itemWrapper-inner'>
          {fileList?.map((storageEntities, index) => {
            if (storageEntities) {
              return (
                <StorageItemTred
                  key={storageEntities?.id ? storageEntities?.id : index}
                  file={storageEntities as any}
                />
              );
            }
          })}
        </div>
      </div>
    </AccordionItemStorage>
  );
};
