import './UsersListStorage.scss';

import React, { Fragment } from 'react';

import { addKarma } from '../../utils';
import { UsersListItemStorage } from './UsersListItemStorage/UsersListItemStorage';

export type UsersListStorageItemType = {
  createdAt?: string | null;
  creator?: {
    login?: string | null;
  } | null;
  creatorId?: number | null;
  fullName?: string | null;
  id?: number | null;
  image?: string | null;
  lastConnect?: string | null;
  lastEditerUser?: {
    login?: string | null;
  } | null;
  login?: string | null;
  registrationLink?: string | null;
  role?: string | null;
  theNote?: string | null;
  updatedAt?: string | null;
  isAnalytics: boolean;
  editDate?: string | null;
  accessibleProjectsStorage: Array<{
    __typename?: 'Project';
    id: number;
    name: string;
  }>;
  tags: {
    count: number;
    rows: Array<{
      id: number;
      name: string;
      color?: string | null;
      textColor?: string | null;
    }>;
  };
  chronographReports?: any;
};

type UsersListProps = {
  users?: UsersListStorageItemType[];
  loading: boolean;
};

export const UsersListStorage = ({ users, loading }: UsersListProps) => {
  return (
    <div className='usersList'>
      <div className='usersList__header'>
        <h3 className='usersList__title' style={{ width: '5%' }}>
          ID
        </h3>
        <h3 className='usersList__title'>Имя пользователя</h3>
        <h3 className='usersList__title'>Роль</h3>
        <h3 className='usersList__title' style={{ width: '39%' }}>
          Доступ к хранилищу проектов
        </h3>
      </div>
      <div>
        {users?.length
          ? users.map((user) => (
              <Fragment key={user.id}>
                <UsersListItemStorage
                  user={user}
                  id={user.id}
                  login={addKarma(user as any)}
                  role={user.role}
                  updatedAt={user?.updatedAt}
                  lastEditerUser={user.lastEditerUser?.login}
                  chronographReports={user?.chronographReports}
                />
              </Fragment>
            ))
          : !loading && <p className='usersList__text'>Пользователи не найдены...</p>}
      </div>
    </div>
  );
};
