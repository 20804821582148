import './ButtonLink.scss';

import React from 'react';
import { Link } from 'react-router-dom';

import { chrono, goToMyTasks, goToTask, ProjectI } from '../../assets/icons';
import { DecideOnTheFly, PersonalIcons } from '../icons';

type ButtonLinkTypeProps = {
  mode?: string;
  title?: string;
  path: string;
};

export const ButtonLink = ({ mode, title, path }: ButtonLinkTypeProps) => {
  const icons = () => {
    if (mode === 'decideOnTheFly') {
      return;
    }
    if (mode === 'history') {
      return goToTask;
    }
    if (mode === 'file') {
      return ProjectI;
    }
    if (mode === 'chrono') {
      return chrono;
    }

    if (mode === 'circle') {
      return;
    }

    return goToMyTasks;
  };

  if (mode === 'decideOnTheFly') {
    return (
      <Link className='buttonLink' title={title} to={path}>
        <DecideOnTheFly />
      </Link>
    );
  }

  if (mode === 'circle') {
    return (
      <Link className='buttonLink' title={title} to={path}>
        <PersonalIcons fill='white' />
      </Link>
    );
  }

  return (
    <Link
      className='buttonLink'
      title={title}
      to={path}
      style={mode === 'file' ? { padding: '10px 0px' } : {}}
    >
      <img src={icons()} />
    </Link>
  );
};
