import './ChangeAvatar.scss';

import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { uploadAvatar } from '../../assets/icons';
import { useAuthContext } from '../../context';
import {
  InputMaybe,
  Scalars,
  useEditUserMutation,
} from '../../graphql/generated/graphql';
import { getUserAvatar } from '../../utils';
import { RequestHandler } from '..';

export const ChangeAvatar = () => {
  const [editUser] = useEditUserMutation({
    onCompleted: () => {
      toast.success('Аватар изменен');
      refetch();
    },
    onError: () => {
      setLoad(false);
    },
  });

  const [load, setLoad] = useState(false);
  const { currentUserData, refetch } = useAuthContext();
  const handleAvatarSubmits = async (image: InputMaybe<Scalars['Upload']>) => {
    if (!image || (image instanceof File && !image.type.startsWith('image/'))) {
      toast.error('Выберите изображение');
      return;
    }
    setLoad(true);
    await editUser({
      variables: {
        data: {
          image: image,
        },
      },
    });
    setLoad(false);
  };

  return (
    <div className='changeAvatar'>
      <div className='avatar__image-upload'>
        <label htmlFor='admin-edit-image'>
          <div>
            <RequestHandler loading={load}>
              <div className='user__head-avatar'>
                <img src={getUserAvatar(currentUserData?.image)} alt='avatar' />
                <img src={uploadAvatar} alt='avatar' className='avatar__upload' />
              </div>
            </RequestHandler>
          </div>
        </label>
        <input
          id='admin-edit-image'
          name='image'
          type='file'
          onChange={(e) => {
            handleAvatarSubmits(e?.currentTarget?.files?.[0]);
          }}
        />
      </div>
    </div>
  );
};
