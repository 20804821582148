import './ChatMessage.scss';

import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { checkRead, checkUnread } from '../../assets/icons';
import { messageActions, ROLES, STATUSES_COLORS } from '../../constants/constants';
import { useAuthContext, useChatContext, useModalContext } from '../../context';
import {
  UserKarmaStatistics,
  useSetKarmaMutation,
} from '../../graphql/generated/graphql';
import { ModalStatuses } from '../../types/enums';
import { formatToDateAndTime, getUserAvatar } from '../../utils';
import { CrossIcon, MinusIcon, PlusChatIcon } from '../icons';
import { LinkifyText } from '../linkifyText/LinkifyText';

type ChatMessagePropsType = {
  messageId?: string | null;
  messageText?: string;
  login?: string | null;
  avatar?: string | null;
  createdAt?: string;
  isRead?: boolean | null;
  karmaStatistics: UserKarmaStatistics;
  isPossibleSetKarma: boolean;
  deleteMessageHandlerTreds?: any;
  refetchTred?: any;
  isActivity?: boolean;
};

export const ChatMessage = ({
  messageId,
  messageText,
  login,
  avatar,
  createdAt,
  isRead,
  karmaStatistics,
  isPossibleSetKarma,
  deleteMessageHandlerTreds,
  refetchTred,
  isActivity,
}: ChatMessagePropsType) => {
  const { taskId, subtaskId, privateChatId } = useParams();

  const { currentUserData } = useAuthContext();

  const { deleteMessageHandler, refetch } = useChatContext();
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  const isBoldMessageText = useMemo(() => {
    return messageActions.some((item) => messageText?.includes(item));
  }, [messageText]);

  const [setKarmaMutation] = useSetKarmaMutation({
    onCompleted: () => {
      refetch();
      refetchTred && refetchTred();
    },
  });

  const identifyMessageColor = () => {
    if (messageText) {
      const foundColor = STATUSES_COLORS.find(({ rusName }) =>
        messageText?.includes(rusName),
      );

      if (foundColor)
        return { wordColor: foundColor.name, wordColorText: foundColor.rusName };
    }
  };

  const checkRightsForDeleteMessage = () => {
    if (currentUserData?.role === ROLES.USER && currentUserData?.login === login)
      return true;
    if (
      currentUserData?.role === ROLES.MODERATOR ||
      currentUserData?.role === ROLES.ADMIN
    )
      return true;
    if (currentUserData?.role === ROLES.USER && currentUserData?.login !== login)
      return false;

    return false;
  };

  const messageColor = () => {
    if (taskId && !subtaskId) return { backgroundColor: '#EFEFEF' };
    if (taskId && subtaskId) return { backgroundColor: '#E4EFFF' };
    if (privateChatId) return { backgroundColor: '#D5EDD8' };
    return;
  };

  const isPositiveKarma = karmaStatistics?.karma >= 0;
  return (
    <div
      className='chat__message'
      style={
        currentUserData?.login !== login ? messageColor() : { backgroundColor: '#FFFFFF' }
      }
    >
      <div className='message__info'>
        <div className='message__user'>
          <img src={getUserAvatar(avatar)} alt='avatar' />
          <span>{login}</span>
        </div>
        <div className={`message__karma-wrapper ${!isPossibleSetKarma ? 'pl5 pr5' : ''}`}>
          {isPossibleSetKarma && (
            <div className='message__karma-minus'>
              <MinusIcon
                onClick={() => {
                  handleSetModalStatus(ModalStatuses.CHANGE_KARMA_MODAL);

                  handleChangeModalParameters([
                    {
                      key: 'onCallback',
                      value: () => {
                        setKarmaMutation({
                          variables: {
                            isPlus: false,
                            messageId: messageId as string,
                          },
                        });
                      },
                    },
                    {
                      key: 'initialValues',
                      value: {
                        avatar: avatar,
                        login: login,
                        status: false,
                      },
                    },
                  ]);
                }}
              />
            </div>
          )}

          <div
            className='message__karma-count'
            onClick={() => {
              handleSetModalStatus(ModalStatuses.SHOW_KARMA_MODAL);
              handleChangeModalParameters([
                {
                  key: 'initialValues',
                  value: { karmaStatistics, avatar, login },
                },
              ]);
            }}
          >
            [{isPositiveKarma ? `${karmaStatistics?.karma === 0 ? '' : '+'}` : ''}
            {karmaStatistics?.karma}]
          </div>
          {isPossibleSetKarma && (
            <div className='message__karma-plus'>
              <PlusChatIcon
                onClick={() => {
                  handleSetModalStatus(ModalStatuses.CHANGE_KARMA_MODAL);
                  handleChangeModalParameters([
                    {
                      key: 'onCallback',
                      value: () => {
                        setKarmaMutation({
                          variables: {
                            isPlus: true,
                            messageId: messageId as string,
                          },
                        });
                      },
                    },
                    {
                      key: 'initialValues',
                      value: {
                        avatar: avatar,
                        login: login,
                        status: true,
                      },
                    },
                  ]);
                }}
              />
            </div>
          )}
        </div>
        <div className='message__date'>
          {createdAt && <span>{formatToDateAndTime(createdAt)}</span>}
          {currentUserData?.login === login &&
            ((!isRead && <img src={checkUnread} alt='read' />) ||
              (isRead === true && <img src={checkRead} alt='read' />))}
        </div>
        {checkRightsForDeleteMessage() && (
          <DeleteMessageItem
            deleteMessageHandlerTreds={deleteMessageHandlerTreds}
            deleteMessageHandler={deleteMessageHandler}
            messageId={messageId}
          />
        )}
      </div>
      <div className='message__text'>
        {!identifyMessageColor() ? (
          <LinkifyText>
            <p className={isBoldMessageText || isActivity ? 'bold' : ''}>{messageText}</p>
          </LinkifyText>
        ) : (
          <p className='bold'>
            Изменен статус на
            <span style={{ color: `${identifyMessageColor()?.wordColor}` }}>
              {` ${identifyMessageColor()?.wordColorText} `}
            </span>
            цвет!
          </p>
        )}
      </div>
    </div>
  );
};

type DeleteMessageItemType = {
  deleteMessageHandlerTreds?: (value: string) => void;
  messageId: string | null | undefined;
  deleteMessageHandler: (value: string) => void;
};

const DeleteMessageItem = ({
  deleteMessageHandlerTreds,
  deleteMessageHandler,
  messageId,
}: DeleteMessageItemType) => {
  const [disabled, setDisabled] = useState(false);
  return (
    <div className='message__delete' title='Удалить сообщение'>
      <CrossIcon
        color='red'
        onClick={() => {
          if (disabled) {
            return;
          }
          if (deleteMessageHandlerTreds) {
            deleteMessageHandlerTreds(messageId as any);
          } else {
            deleteMessageHandler(messageId as any);
          }
          setDisabled(true);
        }}
      />
    </div>
  );
};
