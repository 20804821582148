import React, { FC } from 'react';
import Linkify from 'react-linkify';

export const LinkifyText: FC<LinkifyTextType> = ({
  children,
  textDecoration = 'none',
  color,
  target = 'blank',
}) => {
  return (
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a
          target={target}
          href={decoratedHref}
          key={key}
          style={{ textDecoration, color }}
        >
          {decoratedText}
        </a>
      )}
    >
      {children}
    </Linkify>
  );
};

type LinkifyTextType = {
  textDecoration?: string;
  color?: string;
  target?: string;
};
