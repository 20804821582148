import React, { FC } from 'react';

export const PlayIcon: FC<PlayIconType> = ({
  width = '9px',
  height = '10px',
  color = '#AFAFAF',
  onClick,
  className = 'play-icon',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 11 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      className={className}
    >
      <path
        d='M1.25 0.75V11.25M10.25 6L1.25 11.25M10.25 6L1.25 0.75'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

type PlayIconType = {
  color?: string;
  width?: string;
  height?: string;
  className?: string;
  onClick?: () => void;
};
