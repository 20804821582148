import './ChangeWeeks.scss';

import moment from 'moment';
import React from 'react';

import { datesOfWeekType } from '../../../../types/types';
import { ArrowRightIcon } from '../../../icons';

type ChangeWeeksType = {
  weeks: datesOfWeekType;
  setWeeks: any;
};

export const ChangeWeeks = ({ weeks, setWeeks }: ChangeWeeksType) => {
  return (
    <div className='changeWeeks'>
      <ArrowRightIcon
        className='changeWeeks__transform'
        onClick={() => {
          setWeeks(changeWeek(weeks, 'prev'));
        }}
      />
      <div className='changeWeeks__text'>{`${weeks.from} - ${weeks.to}`}</div>
      <ArrowRightIcon
        onClick={() => {
          setWeeks(changeWeek(weeks, 'next'));
        }}
      />
    </div>
  );
};

const changeWeek = (week: any, nextOrPrev: string) => {
  const { fromUTC } = week;
  const date = moment(fromUTC).utc();

  if (nextOrPrev === 'next') {
    date.add(1, 'week');
  } else if (nextOrPrev === 'prev') {
    date.subtract(1, 'week');
  }

  const from = date.clone().format('DD.MM.YYYY'); // Monday of the current week

  const to = date.clone().add(6, 'days').format('DD.MM.YYYY'); // Saturday of the current week
  const fromUTCNext = date.clone().toISOString();
  const toUTCNext = date.clone().add(6, 'days').toISOString();

  return { from, to, fromUTC: fromUTCNext, toUTC: toUTCNext };
};
