import './EditSingleTagModal.scss';

import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import { toast } from 'react-toastify';

import {
  TagType,
  useGetTagsQuery,
  useUpdateTagMutation,
} from '../../../../graphql/generated/graphql';
import { addTagSchema } from '../../../../validation/schemas';
import { AppInput, Button } from '../../../index';
import { Modal } from '../../Modal';

type AddTagType = {
  onClose: any;
  modalParameters: any;
};

export const EditSingleTagModal = ({ onClose, modalParameters }: AddTagType) => {
  const { data } = useGetTagsQuery({
    variables: {
      data: {
        type: TagType.User,
      },
    },
    fetchPolicy: 'network-only',
  });
  const tags = data?.getTags?.rows;

  const editTag = modalParameters?.initialValues?.tag;

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [pickerColor, setPickerColor] = useState('');
  const [selectColorName, setSelectColorName] = useState('');

  const { handleEnter } = useEnterColor({ setPickerColor, setIsPickerOpen });
  const [updateTagMutation] = useUpdateTagMutation({
    onCompleted() {
      toast.success('Тег обновлен');
      modalParameters?.onCallback();
      onClose();
    },
  });

  const handleSubmit = async (value: {
    name: string;
    color: string;
    textColor: string;
  }) => {
    if (editTag) {
      if (
        !tags?.filter(
          (tag) =>
            tag.id !== editTag.id && tag.name.toLowerCase() === value.name.toLowerCase(),
        ).length
      ) {
        await updateTagMutation({
          variables: {
            id: editTag?.id,
            data: { ...value, type: TagType.User },
          },
        });
      } else {
        toast.error('Тег с таким названием уже существует!');
      }
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          name: editTag ? editTag?.name : '',
          color: editTag ? (editTag?.color as string) : '#E3025A',
          textColor: editTag ? (editTag?.textColor as string) : 'white',
        }}
        onSubmit={handleSubmit}
        validationSchema={addTagSchema}
      >
        {({ values, setFieldValue }) => {
          return (
            <div className='EditSingleTagModal'>
              <Form className='change__taq'>
                <AppInput
                  label={`${editTag ? 'Редактировать' : 'Создать'} тег:`}
                  name='name'
                  placeholder='название тега'
                />
                <div className='tag-color__wrapper-outer'>
                  <div className='tag-color__wrapper-inner'>
                    <div className='tag-color__text'> Цвет фона:</div>
                    <div
                      style={{
                        backgroundColor: values?.color,
                      }}
                      className='tag-color__element'
                      onClick={() => {
                        setSelectColorName('color');
                        setIsPickerOpen(true);
                      }}
                    ></div>
                  </div>
                  <div className='tag-color__wrapper-inner ml20'>
                    <div className='tag-color__text'>Цвет текста (не рекомендуется):</div>
                    <div
                      style={{
                        backgroundColor: values?.textColor,
                      }}
                      className='tag-color__element'
                      onClick={() => {
                        setSelectColorName('textColor');
                        setIsPickerOpen(true);
                      }}
                    ></div>
                  </div>
                </div>
                <Modal isOpen={isPickerOpen} onClose={() => setIsPickerOpen(false)}>
                  <div className='add-polygon-block-modal__colors-picker'>
                    <ChromePicker
                      disableAlpha
                      color={pickerColor}
                      onChangeComplete={(color) => {
                        setPickerColor(color.hex);
                        setFieldValue(selectColorName, color.hex);
                      }}
                      onChange={(paletteColor) => {
                        handleEnter({ hex: paletteColor.hex, name: paletteColor.hex });
                      }}
                    />
                    <Button
                      text='Сохранить цвет'
                      margin='10px 0 0 0'
                      onClick={() => setIsPickerOpen(false)}
                    />
                  </div>
                </Modal>
                <Button type='submit' width='100%' margin='20px 0px 0px'>
                  {`${editTag ? 'Сохранить' : 'Создать'} тег`}
                </Button>
              </Form>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

const useEnterColor = ({ setPickerColor, setIsPickerOpen }: any) => {
  const [colorEnter, setColorEnter] = useState('');

  useEffect(() => {
    const handleUserKeyPress = (event: any) => {
      const { keyCode } = event;
      if (keyCode === 13) {
        setPickerColor(colorEnter);
        setIsPickerOpen(false);
      }
    };

    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  });

  const handleEnter = ({ hex }: any) => {
    setColorEnter(hex);
  };

  return { handleEnter };
};
