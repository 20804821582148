import './DecideOnTheFlyTask.scss';

import { Form, Formik } from 'formik';
import React from 'react';

import { useDecideOnFlyPackagesQuery } from '../../../../graphql/generated/graphql';
import { decideOnTheFlyTask } from '../../../../validation/schemas';
import { AppInput, AppSelect, Button, DoubleSliderFormik } from '../../../index';

export const DecideOnTheFlyTask = ({ onClose, modalParameters }: any) => {
  const typeUpdate = modalParameters?.typeModal === 'update';
  const date = new Date();

  const { data } = useDecideOnFlyPackagesQuery({
    variables: {
      timezoneOffset: date.getTimezoneOffset(),
    },
    fetchPolicy: 'network-only',
  });

  const options: any = data?.decideOnFlyPackages?.map((e) => {
    return { value: e.id, label: e.title };
  });
  options?.push({ value: null, label: 'Убрать из пакета' });
  const decideOnFlyPeriodId = modalParameters?.initialValues?.decideOnFlyPeriodId;
  const hourFrom = modalParameters?.initialValues?.hourFrom;
  const hourTo = modalParameters?.initialValues?.hourTo;

  const minMax = minMaxCalc({ decideOnFlyPeriodId });
  const hourFromHourTo = caclHourFromHourTo({ decideOnFlyPeriodId, hourFrom, hourTo });

  return (
    <div>
      <div className='DecideOnTheFlyTask__title2'>
        {typeUpdate ? 'Редактировать' : 'Добавить'} {weekdayCalc(decideOnFlyPeriodId)}
      </div>
      <div className='DecideOnTheFlyTask'>
        <Formik
          enableReinitialize={true}
          initialValues={{
            ...modalParameters?.initialValues,
            hourFrom: hourFromHourTo.hourFrom,
            hourTo: hourFromHourTo.hourTo,
          }}
          onSubmit={(e) => {
            modalParameters.onCallback({
              ...e,
              hourFrom: convertValue(e?.hourFrom),
              hourTo: convertValue(e?.hourTo),
            });
            onClose();
          }}
          validationSchema={decideOnTheFlyTask}
        >
          {({ setFieldValue, values }) => {
            return (
              <Form style={{ width: 'auto' }}>
                <div className='DecideOnTheFlyTask__title'>
                  {typeUpdate ? 'Редактировать раздел' : 'Создать раздел'}
                </div>
                <div className=''>
                  <AppInput name={'name'} label={'Название:'} />
                </div>
                <div className=''>
                  <AppInput name={'percent'} label={'Процент:'} type='number' />
                </div>
                <div className='DecideOnTheFlyTask__selectLine mb15'>
                  <div className='DecideOnTheFlyTask__selectLine-title'>Пакет:</div>
                  <AppSelect
                    name='decideOnFlyPackageId'
                    options={[...(options ? options : [])]}
                    placeholder='Пакет'
                    background='white'
                    hideError={true}
                    maxHeightList={170}
                    onChange={(e: any) => {
                      setFieldValue('decideOnFlyPackageId', e.value);
                    }}
                  />
                </div>
                <DoubleSliderFormik
                  setFieldValue={setFieldValue}
                  values={values}
                  max={minMax.max}
                  min={minMax.min}
                />
                <Button
                  type='submit'
                  width='100%'
                  height={30}
                  color='#fff'
                  backgroundColor='#149939'
                  margin='20px 0px 0px 0px'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='14'
                    height='15'
                    viewBox='0 0 14 15'
                    fill='none'
                    className='mr10'
                  >
                    <path
                      d='M4.375 2.25V4.4375C4.375 4.7856 4.51328 5.11944 4.75942 5.36558C5.00556 5.61172 5.3394 5.75 5.6875 5.75H7.4375C7.7856 5.75 8.11944 5.61172 8.36558 5.36558C8.61172 5.11944 8.75 4.7856 8.75 4.4375V2.25H9.08162C9.54572 2.2501 9.99076 2.43454 10.3189 2.76275L11.7372 4.18112C12.0655 4.50924 12.2499 4.95428 12.25 5.41837V11C12.25 11.4641 12.0656 11.9092 11.7374 12.2374C11.4092 12.5656 10.9641 12.75 10.5 12.75V8.8125C10.5 8.4644 10.3617 8.13056 10.1156 7.88442C9.86944 7.63828 9.5356 7.5 9.1875 7.5H4.8125C4.08888 7.5 3.5 8.08538 3.5 8.81075V12.75C3.03587 12.75 2.59075 12.5656 2.26256 12.2374C1.93437 11.9092 1.75 11.4641 1.75 11V4C1.75 3.53587 1.93437 3.09075 2.26256 2.76256C2.59075 2.43437 3.03587 2.25 3.5 2.25H4.375ZM5.25 2.25V4.4375C5.25 4.55353 5.29609 4.66481 5.37814 4.74686C5.46019 4.82891 5.57147 4.875 5.6875 4.875H7.4375C7.55353 4.875 7.66481 4.82891 7.74686 4.74686C7.82891 4.66481 7.875 4.55353 7.875 4.4375V2.25H5.25ZM4.375 12.75H9.625V8.8125C9.625 8.69647 9.57891 8.58519 9.49686 8.50314C9.41481 8.42109 9.30353 8.375 9.1875 8.375H4.8125C4.57013 8.375 4.375 8.57013 4.375 8.81075V12.75Z'
                      fill='white'
                    />
                  </svg>
                  {typeUpdate ? 'Редактировать' : 'Создать'}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

type minMaxCalcType = {
  decideOnFlyPeriodId: number;
};

const minMaxCalc = ({ decideOnFlyPeriodId }: minMaxCalcType) => {
  if (decideOnFlyPeriodId === 1 || decideOnFlyPeriodId === 3) {
    return {
      min: 0,
      max: 18,
    };
  }
  if (decideOnFlyPeriodId === 2 || decideOnFlyPeriodId === 4) {
    return {
      min: 18,
      max: 24,
    };
  }
  return {
    min: 0,
    max: 18,
  };
};

type caclHourFromHourToType = {
  decideOnFlyPeriodId: number;
  hourTo: string | undefined;
  hourFrom: string | undefined;
};

const caclHourFromHourTo = ({
  hourTo,
  hourFrom,
  decideOnFlyPeriodId,
}: caclHourFromHourToType) => {
  if (hourTo && hourFrom) {
    return {
      hourTo: convertToHours(hourTo),
      hourFrom: convertToHours(hourFrom),
    };
  }
  if (decideOnFlyPeriodId === 1 || decideOnFlyPeriodId === 3) {
    return {
      hourFrom: 0,
      hourTo: 18,
    };
  }
  if (decideOnFlyPeriodId === 2 || decideOnFlyPeriodId === 4) {
    return {
      hourFrom: 18,
      hourTo: 24,
    };
  }
  return {
    hourFrom: 0,
    hourTo: 18,
  };
};

function convertValue(data: number) {
  const formatedTime = data.toString().padStart(2, '0');
  return `${formatedTime}:00:00`;
}

function convertToHours(time: string) {
  const splitTime = time.split(':');

  if (splitTime.length === 3) {
    const hours = parseInt(splitTime[0], 10);

    if (!isNaN(hours) && hours >= 0 && hours <= 24) {
      return hours;
    }
  }

  return 0;
}

const weekdayCalc = (decideOnFlyPeriodId: number) => {
  if (decideOnFlyPeriodId === 1) {
    return 'будний день';
  }
  if (decideOnFlyPeriodId === 2) {
    return 'будний вечер';
  }
  if (decideOnFlyPeriodId === 3) {
    return 'выходной день';
  }
  if (decideOnFlyPeriodId === 4) {
    return 'выходной вечер';
  }
};
