import './MyTasksMaintainer.scss';

import React, { useEffect, useState } from 'react';

import { ProjectAccordion, RequestHandler, TagsFilter } from '../../components';
import { useGetMyProjectsRoleMaintainerQuery } from '../../hooks/useQueries';

export const MyTasksMaintainer = () => {
  const [filterIds, setFilterIds] = useState<string[]>([]);

  const { data, loading } = useGetMyProjectsRoleMaintainerQuery({
    tagsId: filterIds,
  });
  const [findItem, setFindItem] = useState(false);
  useEffect(() => {
    setFindItem(false);
  }, [filterIds]);

  return (
    <div className='container'>
      <div className='my-tasks-maintainer'>
        <div className='my-tasks-maintainer__body'>
          <div className='my-tasks-maintainer__title'>
            <h2>Мои задачи</h2>
            <TagsFilter applyFilter={setFilterIds} filterState={filterIds} />
          </div>
          <div className='my-tasks-maintainer__table'>
            {findItem && (
              <div className='my-tasks-maintainer__row'>
                <div className='my-tasks-maintainer__column my-tasks-maintainer__tags'>
                  Теги
                </div>
                <div className='my-tasks-maintainer__column my-tasks-maintainer__task'>
                  Задача
                </div>
                <div className='my-tasks-maintainer__column my-tasks-maintainer__comment'>
                  Комментарий
                </div>
              </div>
            )}

            <RequestHandler loading={loading}>
              {data?.getMyProjectsRoleMaintainer?.rows.map((item) => (
                <ProjectAccordion
                  key={item?.id}
                  name={item?.name}
                  number={item?.number}
                  tasksList={item?.myTaskSubTaskList?.rows}
                  setFindItem={setFindItem}
                />
              ))}
              {!findItem && <div>Задачи не найдены</div>}
            </RequestHandler>
          </div>
        </div>
      </div>
    </div>
  );
};
