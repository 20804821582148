import './CellThreeCol.scss';

import React from 'react';
import { useParams } from 'react-router-dom';

import { useModalContext } from '../../../context/ModalContext';
import { ScaleMapColumnStatisticsType } from '../../../graphql/generated/graphql';
import { useShowStatus2Filter } from '../../../hooks';
import { ModalStatuses } from '../../../types/enums';
import { EditIcon, PlusIcons } from '../../icons';

type CellThreeColType = {
  content?: any;
  selectTwoCol?: number;
  createScaleMapTaskMutation: (value: any) => void;
  setSelectThreeCol: (value: number) => void;
  selectThreeCol: number;
  updateScaleMapTaskMutation: (value: any) => void;
  deleteScaleMapTaskMutation: (value: any) => void;
  index: number;
  statictic: ScaleMapColumnStatisticsType | undefined;
  fatherArray: [number | null];
  fatherId: number;
  filterStatus: null | number;
  isLineSuccesses: boolean;
};

export const CellThreeCol = ({
  content,
  selectTwoCol,
  createScaleMapTaskMutation,
  setSelectThreeCol,
  selectThreeCol,
  updateScaleMapTaskMutation,
  deleteScaleMapTaskMutation,
  index,
  fatherArray,
  fatherId,
  filterStatus,
  isLineSuccesses,
}: CellThreeColType) => {
  const { fileId } = useParams();
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  const { showLocalStatus2Filter, handleSetSowLocalStatus2Filter } = useShowStatus2Filter(
    { filterStatus },
  );

  const currentFather = content?.map((e: any) => e?.scaleMapTaskId);

  const selectIsFather = fatherArray.indexOf(selectTwoCol as any) !== -1;

  const showState2 =
    content?.length >= 1 && currentFather?.indexOf(selectTwoCol as any) !== -1;

  const showState4 =
    (!fatherId && !filterStatus) ||
    (fatherArray.indexOf(selectTwoCol as any) === -1 && content?.length === 0);

  const addTaskHeader = ({ scaleMapTaskId }: any) => {
    handleSetModalStatus(ModalStatuses.ADD_FILE_TASK_STATUS_MODAL);
    handleChangeModalParameters([
      {
        key: 'onCallback',
        value: (value: { name: string; status: number }) => {
          createScaleMapTaskMutation({
            variables: {
              input: {
                name: value?.name,
                scaleMapFileId: parseInt(fileId as string),
                scaleMapTaskId: scaleMapTaskId,
                statusId: value?.status,
                column: 3,
                row: index + 1,
              },
            },
          });
        },
      },
      {
        key: 'initialValues',
        value: undefined,
      },
      {
        key: 'col',
        value: 'col3',
      },
    ]);
  };

  const editTaskHeader = (task: {
    name: string;
    status: number;
    id: number;
    scaleMapTaskId: number;
  }) => {
    handleSetModalStatus(ModalStatuses.ADD_FILE_TASK_STATUS_MODAL);
    handleChangeModalParameters([
      {
        key: 'onCallback',
        value: (value: { name: string; status: number }) => {
          updateScaleMapTaskMutation({
            variables: {
              input: {
                id: task?.id,
                name: value?.name,
                scaleMapFileId: parseInt(fileId as string),
                scaleMapTaskId: task?.scaleMapTaskId,
                statusId: value?.status,
              },
            },
          });
        },
      },
      {
        key: 'onCallbackDelete',
        value: () => {
          deleteScaleMapTaskMutation({
            variables: {
              id: task?.id,
            },
          });
        },
      },
      {
        key: 'initialValues',
        value: { name: task?.name, status: task?.status },
      },
      {
        key: 'col',
        value: 'col3',
      },
    ]);
  };

  if (showLocalStatus2Filter) {
    return (
      <div
        className={`CellFirstColFile__state2 ${
          isLineSuccesses && 'CellFirstColFile__state2-black'
        }`}
        onClick={(e) => {
          e.stopPropagation();
          handleSetSowLocalStatus2Filter();
        }}
      >
        <>
          {content?.map((e: any, index: number) => {
            return (
              <div
                className={`${
                  selectThreeCol === e.id ? 'CellFirstColFile__state2-select' : ''
                } CellFirstColFile__state2-element `}
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <span className='CellFirstColFile__status-wrapper'>
                  <div
                    className='CellFirstColFile__status'
                    style={{
                      backgroundColor: e?.status?.color,
                    }}
                  ></div>
                  {e?.name}
                </span>

                <div className='CellFirstColFile__state2-icons'>
                  <EditIcon
                    color='black'
                    width={14}
                    height={14}
                    onClick={() => {
                      editTaskHeader({
                        status: e?.status?.id,
                        name: e?.name,
                        scaleMapTaskId: e?.scaleMapTaskId,
                        id: e?.id,
                      });
                    }}
                  />
                </div>
              </div>
            );
          })}
        </>
        <div className='CellFirstColFile__state2-empty'></div>
      </div>
    );
  }

  if (showState4) {
    if (isLineSuccesses)
      return (
        <div className='CellTwoCol__state3' style={{ backgroundColor: '#303030' }}></div>
      );

    return null;
  }

  if (showState2) {
    return (
      <div
        className={`CellFirstColFile__state2 ${
          isLineSuccesses && 'CellFirstColFile__state2-black'
        }`}
      >
        <>
          {content?.map((e: any, index: number) => {
            if (e?.scaleMapTaskId !== selectTwoCol) return null;
            return (
              <div
                className={`${
                  selectThreeCol === e.id ? 'CellFirstColFile__state2-select' : ''
                } CellFirstColFile__state2-element `}
                key={index}
                onClick={() => {
                  setSelectThreeCol && setSelectThreeCol(e?.id as number);
                }}
              >
                <span className='CellFirstColFile__status-wrapper'>
                  <div
                    className='CellFirstColFile__status'
                    style={{
                      backgroundColor: e?.status?.color,
                    }}
                  ></div>
                  {e?.name}
                </span>

                <div className='CellFirstColFile__state2-icons'>
                  <EditIcon
                    color='black'
                    width={14}
                    height={14}
                    onClick={() => {
                      editTaskHeader({
                        status: e?.status?.id,
                        name: e?.name,
                        scaleMapTaskId: e?.scaleMapTaskId,
                        id: e?.id,
                      });
                    }}
                  />
                </div>
              </div>
            );
          })}
        </>
        <div
          onClick={() => {
            addTaskHeader({ scaleMapTaskId: selectTwoCol });
          }}
          className='CellFirstColFile__state2-button'
          style={{
            justifyContent: 'center',
          }}
        >
          <div className='CellFirstColFile__child'>
            <PlusIcons color='#149939' width='24' height='24' />
          </div>
        </div>
      </div>
    );
  }
  if (!selectTwoCol) return <div></div>;

  return (
    <div
      className='CellFirstColFile__state1'
      onClick={() => {
        addTaskHeader({ scaleMapTaskId: selectIsFather ? selectTwoCol : fatherId });
      }}
    >
      <div className='CellFirstColFile__child'>
        <PlusIcons color='#149939' width='24' height='24' />
      </div>
    </div>
  );
};
