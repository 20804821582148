import { ApolloError } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as GENERATED from '../../graphql/generated/graphql';
import { GetSubtaskDocument, GetSubtaskQuery } from '../../graphql/generated/graphql';
import { GET_SUBTASK, GET_TASK } from '../../graphql/queries';
import { getErrorText } from '../../utils';

export const useUpdateSubtaskMutation = () => {
  const { taskId, subtaskId } = useParams();
  return GENERATED.useUpdateSubtaskMutation({
    update(cache, { data }) {
      if (!data?.updateSubtask) {
        return null;
      }

      const subtask: GetSubtaskQuery | null = cache.readQuery({
        query: GetSubtaskDocument,
        variables: { id: Number(subtaskId) },
      });

      cache.writeQuery({
        query: GetSubtaskDocument,
        variables: { id: Number(subtaskId) },
        data: {
          ...subtask,
          getSubtask: data?.updateSubtask,
        },
      });
    },
    refetchQueries:
      subtaskId || taskId
        ? [
            {
              query: subtaskId ? GET_SUBTASK : GET_TASK,
              variables: { id: Number(subtaskId ? subtaskId : taskId) },
            },
          ]
        : [],
    onError(error: ApolloError) {
      if (error?.message) {
        toast.error(getErrorText(error.message));
      }
    },
  });
};
