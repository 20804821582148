import * as GENERATED from '../../graphql/generated/graphql';
import {
  GetProjectsDocument,
  GetProjectsQuery,
  SortingField,
  SortingOrders,
} from '../../graphql/generated/graphql';

export const useCreateProjectMutation = ({ refetch }: { refetch: () => void }) => {
  const getProjectVariables = {
    data: {
      limit: 100,
      sort: { field: SortingField.Number, type: SortingOrders.Asc },
    },
    tasksData: { limit: 100 },
  };

  return GENERATED.useCreateProjectMutation({
    update(cache, { data }) {
      if (!data?.createProject) {
        return null;
      }
      const projects: GetProjectsQuery | null = cache.readQuery({
        query: GetProjectsDocument,
        variables: getProjectVariables,
      });

      if (projects) {
        cache.writeQuery({
          query: GetProjectsDocument,
          variables: getProjectVariables,
          data: {
            ...projects,
            getProjects: {
              ...projects?.getProjects,
              count: projects.getProjects.count + 1,
              rows: [...(projects?.getProjects.rows || []), data.createProject],
            },
          },
        });
      }
    },
    onCompleted: () => {
      refetch();
    },
  });
};
