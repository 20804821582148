import * as GENERATED from '../../graphql/generated/graphql';
import {
  GetAllUsersDocument,
  GetAllUsersQuery,
  GetPrivateChatsDocument,
  GetPrivateChatsQuery,
} from '../../graphql/generated/graphql';

export const useDeleteUserMutation = (userId: number | null | undefined) => {
  return GENERATED.useDeleteUserMutation({
    update(cache, { data }) {
      if (!data?.deleteUser) return null;

      const users: GetAllUsersQuery | null = cache.readQuery({
        query: GetAllUsersDocument,
        variables: {
          input: {
            sortType: 'desc',
            sortBy: 'id',
            search: '',
          },
        },
      });

      const chats: GetPrivateChatsQuery | null = cache.readQuery({
        query: GetPrivateChatsDocument,
        variables: {
          data: {
            limit: 20,
          },
        },
      });

      const newChats = chats?.getPrivateChats.rows.filter((chat) => chat.id !== userId);

      const newUsers = users?.getAllUsers.rows.filter((user) => user?.id !== userId);

      if (newUsers) {
        cache.writeQuery({
          query: GetAllUsersDocument,
          variables: {
            input: {
              sortType: 'desc',
              sortBy: 'id',
              search: '',
            },
          },
          data: {
            ...users,
            getAllUsers: {
              ...users?.getAllUsers,
              count: (users && users?.getAllUsers?.count - 1) || users?.getAllUsers.count,
              rows: newUsers,
            },
          },
        });
      }

      if (newChats) {
        cache.writeQuery({
          query: GetPrivateChatsDocument,
          variables: {
            data: {
              limit: 20,
            },
          },
          data: {
            ...chats,
            getPrivateChats: {
              ...chats?.getPrivateChats,
              count:
                (chats && chats?.getPrivateChats?.count - 1) ||
                chats?.getPrivateChats.count,
              rows: newChats,
            },
          },
        });
      }
    },
  });
};
