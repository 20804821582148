import React, { FC } from 'react';

export const DoneIcon: FC<DoneIconType> = ({
  color = '#AFAFAF',
  width = '14px',
  height = '16px',
  onClick,
  className = 'done-icon',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      className={className}
    >
      <path
        d='M1 6L5.5 10.5L13 1.5'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

type DoneIconType = {
  color?: string;
  width?: string;
  height?: string;
  className?: string;
  onClick?: () => void;
};
