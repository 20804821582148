import React from 'react';

import noImage from '../../../assets/noImage.svg';
import { useModalContext } from '../../../context';
import { UpdateThreadHeaderMutationFn } from '../../../graphql/generated/graphql';
import { ModalStatuses } from '../../../types/enums';

type LoadImageType = {
  updateThreadMutation: UpdateThreadHeaderMutationFn;
  threadId: number;
  threadBackground: any;
  cover: any;
};
export const AddFon = ({
  updateThreadMutation,
  threadId,
  threadBackground,
  cover,
}: LoadImageType) => {
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  return (
    <div
      style={{
        cursor: 'pointer',
      }}
      onClick={() => {
        handleSetModalStatus(ModalStatuses.TREAD_SELECT_FON_MODAL);
        handleChangeModalParameters([
          {
            key: 'onCallback',
            value: (value: any) => {
              updateThreadMutation({
                variables: {
                  data: {
                    id: threadId,
                    cover: value.cover,
                    threadBackgroundId: value.threadBackgroundId,
                  },
                },
              });
            },
          },
          {
            key: 'initialValues',
            value: {
              threadId: threadId,
              threadBackground: threadBackground,
              cover: cover,
            },
          },
        ]);
      }}
    >
      <img
        src={noImage}
        style={{
          width: '100%',
          height: 'auto',
        }}
      />
    </div>
  );
};
