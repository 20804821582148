import React from 'react';

export const HeaderStatisticIcons = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.5 5H18.75V10H17.5V5ZM14.375 3.125H15.625V10H14.375V3.125ZM11.25 6.25H12.5V10H11.25V6.25ZM5.625 5.625C5.99584 5.625 6.35835 5.73497 6.66669 5.94099C6.97504 6.14702 7.21536 6.43986 7.35727 6.78247C7.49919 7.12508 7.53632 7.50208 7.46397 7.86579C7.39163 8.22951 7.21305 8.5636 6.95083 8.82583C6.6886 9.08805 6.35451 9.26663 5.99079 9.33897C5.62708 9.41132 5.25008 9.37419 4.90747 9.23227C4.56486 9.09036 4.27202 8.85004 4.06599 8.54169C3.85997 8.23335 3.75 7.87084 3.75 7.5C3.75 7.00272 3.94754 6.52581 4.29917 6.17417C4.65081 5.82254 5.12772 5.625 5.625 5.625ZM5.625 4.375C5.00693 4.375 4.40275 4.55828 3.88884 4.90166C3.37494 5.24504 2.9744 5.7331 2.73788 6.30411C2.50135 6.87513 2.43947 7.50347 2.56005 8.10966C2.68062 8.71585 2.97825 9.27267 3.41529 9.70971C3.85233 10.1467 4.40915 10.4444 5.01534 10.565C5.62153 10.6855 6.24987 10.6236 6.82089 10.3871C7.3919 10.1506 7.87996 9.75006 8.22334 9.23616C8.56672 8.72225 8.75 8.11807 8.75 7.5C8.75 7.08962 8.66917 6.68326 8.51212 6.30411C8.35508 5.92497 8.12489 5.58047 7.83471 5.29029C7.54453 5.00011 7.20003 4.76992 6.82089 4.61288C6.44174 4.45583 6.03538 4.375 5.625 4.375Z'
        fill='white'
      />
      <path
        d='M10 18.75H8.75H2.5H1.25V15C1.25099 14.1715 1.58055 13.3772 2.16639 12.7914C2.75222 12.2056 3.5465 11.876 4.375 11.875H6.875C7.7035 11.876 8.49778 12.2056 9.08361 12.7914C9.66945 13.3772 9.99901 14.1715 10 15V18.75Z'
        fill='white'
      />
      <path
        d='M5.625 5.625C5.99584 5.625 6.35835 5.73497 6.66669 5.94099C6.97504 6.14702 7.21536 6.43986 7.35727 6.78247C7.49919 7.12508 7.53632 7.50208 7.46397 7.86579C7.39163 8.22951 7.21305 8.5636 6.95083 8.82583C6.6886 9.08805 6.35451 9.26663 5.99079 9.33897C5.62708 9.41132 5.25008 9.37419 4.90747 9.23227C4.56486 9.09036 4.27202 8.85004 4.06599 8.54169C3.85997 8.23335 3.75 7.87084 3.75 7.5C3.75 7.00272 3.94754 6.52581 4.29917 6.17417C4.65081 5.82254 5.12772 5.625 5.625 5.625Z'
        fill='white'
      />
    </svg>
  );
};
