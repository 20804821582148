import './Popover.scss';

import React, { ReactNode } from 'react';

type PopoverPropType = {
  children?: ReactNode;
};

export const Popover = ({ children }: PopoverPropType) => {
  return <div className='popover'>{children}</div>;
};
