import './Treads.scss';

import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { TreadsCard, TredsModal, TredsPagination } from '../../components';
import { useMenuContext, useTredsContext } from '../../context';
import {
  Thread,
  useCreateScaleMapTaskMutation,
  useGetThreadsAndCountByUserQuery,
} from '../../graphql/generated/graphql';
import { useCanvasElectroLayout, useDebounce } from '../../hooks';

export const Treads = () => {
  const ref = useRef<any>();
  const scrollRef = useRef<any>();

  const { isOpenMenu } = useMenuContext();
  const { searchTred, currentPage, setCurrentPage, selectUser, isAll } =
    useTredsContext();

  const { debouncedValue } = useDebounce(searchTred);

  const tredLimit = 12;

  useEffect(() => {
    setCurrentPage(1);
  }, [debouncedValue, isAll]);

  const { data, loading, refetch } = useGetThreadsAndCountByUserQuery({
    fetchPolicy: 'network-only',
    variables: {
      data: {
        search: debouncedValue,
        offset: (currentPage - 1) * tredLimit,
        limit: tredLimit,
        userId: selectUser?.id,
        isHideCompletedThreadsAfter24Hours:
          debouncedValue || selectUser?.id || isAll ? false : true,
        all: isAll,
      },
    },
  });

  const [openModal, setOpenModal] = useState({ status: false, x: 0, y: 0 });

  const [createScaleMapTaskMutation] = useCreateScaleMapTaskMutation({
    onCompleted: () => {
      refetch();
    },
    onError: () => {
      toast.error('Ой');
    },
  });

  const treads = data?.getThreadsAndCountByUser?.rows;
  useCanvasElectroLayout({ ref, scrollRef, loading });

  return (
    <div className='Treads' ref={scrollRef}>
      <div
        className={`container ${isOpenMenu ? 'container__open' : ''}`}
        ref={ref}
        onClick={(event) => {
          modalCalc({ event, ref, isOpenMenu, setOpenModal, openModal });
        }}
      >
        <canvas id='canvas' className='Treads__canvas'></canvas>
        <div className='Treads__cover'>
          <div className={`Treads__inner ${isOpenMenu ? 'Treads__inner__open' : ''}`}>
            <div className='item__empty'>aaa</div>
            {treads?.map((e) => {
              return (
                <div key={e.id} className='item'>
                  <TreadsCard
                    tread={e as Thread}
                    selectOtherUser={!!selectUser?.id}
                    isAll={isAll}
                  />
                </div>
              );
            })}
          </div>
          <div
            className='Treads__pagination'
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {data?.getThreadsAndCountByUser &&
              data?.getThreadsAndCountByUser?.count > 11 && (
                <TredsPagination
                  totalItems={data?.getThreadsAndCountByUser?.count as number}
                  limit={tredLimit}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              )}
          </div>
        </div>

        {openModal.status && (
          <TredsModal
            x={openModal.x}
            y={openModal.y + scrollRef?.current?.scrollTop}
            createScaleMapTaskMutation={createScaleMapTaskMutation}
            closeModal={() => {
              setOpenModal({
                ...openModal,
                status: false,
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

const modalCalc = ({ event, ref, isOpenMenu, setOpenModal, openModal }: any) => {
  // fix outside window
  let y = event.clientY;
  const modalHeight = 535;

  const minusY = window.innerHeight - (event.clientY + modalHeight);
  if (minusY < 0) {
    y = window.innerHeight - modalHeight;
  }

  // left menu close
  let x = event.clientX;
  const modalWidth = 330;
  const plusX = x + modalWidth;
  const minusX = plusX - ref?.current?.offsetWidth;
  if (minusX > 0 && !isOpenMenu) {
    x = x - minusX;
  }

  // left menu open
  const minusX2 = plusX - (ref?.current?.offsetWidth + 186);
  if (minusX2 > 0 && isOpenMenu) {
    x = x - minusX2;
  }

  setOpenModal({
    status: !openModal?.status,
    x: x,
    y: y,
  });
};
