import './TreadItemHeader.scss';

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROLES } from '../../../constants/constants';
import { useAuthContext, useMenuContext, useModalContext } from '../../../context';
import {
  ExitThreadPerformersMutationFn,
  UpdateThreadHeaderMutationFn,
} from '../../../graphql/generated/graphql';
import { ModalStatuses } from '../../../types/enums';
import { getUserAvatar, textSlice } from '../../../utils';
import {
  ExitIcons,
  HeaderCalendarIcons,
  NotificationSubscribeIcon,
  NotificationUnSubscribeIcon,
  SmallEditIcons,
  TredStatus,
  TredStatusComplete,
} from '../../icons';
import { TagItem } from '../../index';
import { AddFon } from './AddFon';
import { TredItem } from './TredItem';

type TreadItemHeaderType = {
  updateThreadMutation: UpdateThreadHeaderMutationFn;
  handleSubscribe: () => void;
  isPerformer: boolean | undefined;
  tags: Tag | undefined;
  meta: Meta | undefined;
  threadId: number;
  isSubscriber: boolean;
  scaleMapTaskName: string | undefined;
  mainPerformer: mainPerformersType | undefined;
  author: AuthorType;
  performers: performersType;
  statusId: number | undefined;
  calendarSubscriptionDate: string;
  handleCalendarSubscribe: () => void;
  exitThreadPerformersMutation: any;
  endDate?: string;
  startDate?: string;
  closeDate?: string;
  threadBackground: any;
  cover: string | undefined | null;
  isCritical?: boolean | undefined | null;
};

export const TreadItemHeader = ({
  updateThreadMutation,
  handleSubscribe,
  tags,
  isPerformer,
  meta,
  threadId,
  isSubscriber,
  scaleMapTaskName,
  mainPerformer,
  author,
  performers,
  statusId,
  calendarSubscriptionDate,
  handleCalendarSubscribe,
  exitThreadPerformersMutation,
  endDate,
  startDate,
  closeDate,
  threadBackground,
  cover,
  isCritical,
}: TreadItemHeaderType) => {
  const navigation = useNavigate();
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();
  const { hideExcess, buttonResize, refAll, refName, refTagsOuter, refTags } =
    useTagsResize({
      tags,
      meta,
    });

  const handleSetStatus = ({ statusId }: { statusId: number }) => {
    updateThreadMutation({
      variables: {
        data: {
          id: threadId,
          scaleMapTask: {
            statusId: statusId,
            name: scaleMapTaskName as string,
          },
        },
      },
    });
  };
  const { currentUserData } = useAuthContext();
  return (
    <div className='TreadItemHeader' ref={refAll}>
      <div className='TreadItemHeader__wrapper' ref={refName}>
        <img
          className='TreadItemHeader__avatar'
          src={getUserAvatar(mainPerformer ? mainPerformer?.image : author?.image)}
        />
        <div className='TreadItemHeader__items'>
          {meta?.map((e: any, index: number) => {
            return (
              <TredItem
                key={e?.id}
                length={meta?.length}
                index={index}
                status={e?.status}
                tredId={threadId}
                isCurrent={e?.isCurrent}
                name={e?.name}
                updateThreadMutation={updateThreadMutation}
                scaleMapFileId={e?.scaleMapFile?.id}
                isI={!!e?.scaleMapFile?.user?.id}
                meta={meta}
                scaleMapFile={e?.scaleMapFile}
              />
            );
          })}
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }} ref={refTagsOuter}>
        <div
          className={
            hideExcess && tags?.length !== 0
              ? 'TreadItemHeader__excess'
              : 'TreadItemHeader__excess-hidden'
          }
          ref={refTags}
        >
          {!hideExcess && <div className='TreadItemHeader__line'></div>}

          <SmallEditIcons
            onClick={() => {
              handleSetModalStatus(ModalStatuses.EDIT_TITLE_MODAL_LOCAL);
              handleChangeModalParameters([
                {
                  key: 'onCallback',
                  value: (value: any) => {
                    updateThreadMutation({
                      variables: {
                        data: {
                          id: threadId,
                          tagsIds: value?.tagsId?.map((e: string) => Number(e)),
                          startDate: value?.startDate,
                          closeDate: value?.closeDate,
                          endDate: value?.endDate,
                          scaleMapTask: value?.closeDate
                            ? {
                                statusId: value?.statusId,
                                name: value?.scaleMapTaskName as any,
                              }
                            : { name: value?.scaleMapTaskName as string },
                          isCritical: value?.isCritical,
                        },
                      },
                    });
                  },
                },
                {
                  key: 'initialValues',
                  value: {
                    tagsIds: tags?.map((e: any) => e.id),
                    endDate,
                    startDate,
                    closeDate,
                    isCritical,
                    scaleMapTaskName: scaleMapTaskName || '',
                  },
                },
              ]);
            }}
          />
          {tags?.map((e: any) => {
            return (
              <div key={e?.id} className='ml5'>
                <TagItem
                  title={textSlice({
                    text: e?.name,
                    count: 20,
                    hide: true,
                  })}
                  color={e?.color}
                  textColor={e?.textColor}
                />
              </div>
            );
          })}
        </div>
        {(currentUserData?.role === ROLES.ADMIN ||
          currentUserData?.role === ROLES.MODERATOR) && (
          <>
            <div className='TreadItemHeader__line'></div>
            <AddFon
              updateThreadMutation={updateThreadMutation}
              threadId={threadId}
              threadBackground={threadBackground}
              cover={cover}
            />
          </>
        )}

        <div className='TreadItemHeader__line'></div>
        <StatusChange handleSetStatus={handleSetStatus} statusId={statusId} />
        <HeaderCalendarIcons
          color={calendarSubscriptionDate ? 'black' : 'gray'}
          className='mr5'
          onClick={() => {
            handleCalendarSubscribe();
          }}
        />
        {!isPerformer && (
          <div
            className='TreadItemHeader__button'
            onClick={() => {
              handleSubscribe();
            }}
          >
            {isSubscriber ? (
              <NotificationSubscribeIcon />
            ) : (
              <NotificationUnSubscribeIcon />
            )}
          </div>
        )}
        <ExitTred
          exitThreadPerformersMutation={exitThreadPerformersMutation}
          performers={performers}
          hideExcess={hideExcess}
          buttonResize={buttonResize}
          threadId={threadId}
        />
        <div
          onClick={() => {
            navigation(-1);
          }}
          className='TreadItemHeader__getBack'
          style={
            buttonResize ? { width: 'auto', padding: '5px', boxSizing: 'border-box' } : {}
          }
        >
          {buttonResize ? 'Назад' : 'Вернуться назад'}

          <svg
            width='14'
            height='8'
            viewBox='0 0 14 8'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={!hideExcess ? { marginLeft: '10px' } : { padding: '5px' }}
          >
            <path
              d='M13.8396 3.61289C13.8394 3.61273 13.8393 3.61254 13.8391 3.61237L10.9816 0.768623C10.7675 0.555588 10.4213 0.556381 10.2082 0.770482C9.9951 0.984556 9.99592 1.33081 10.21 1.54387L12.1285 3.45312H0.546875C0.244836 3.45312 0 3.69796 0 4C0 4.30204 0.244836 4.54687 0.546875 4.54687H12.1285L10.21 6.45612C9.99594 6.66918 9.99512 7.01544 10.2082 7.22951C10.4213 7.44364 10.7676 7.44438 10.9816 7.23137L13.8391 4.38762C13.8393 4.38746 13.8394 4.38727 13.8396 4.3871C14.0538 4.17333 14.0531 3.82595 13.8396 3.61289Z'
              fill='white'
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

type StatusChangeType = {
  handleSetStatus: any;
  statusId: number | undefined;
};

const StatusChange = ({ handleSetStatus, statusId }: StatusChangeType) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
      <div
        className='mr10'
        onClick={() => {
          handleSetStatus({ statusId: 1 });
        }}
      >
        {statusId === 1 ? (
          <TredStatusComplete color={'#149939'} />
        ) : (
          <TredStatus color={'#149939'} />
        )}
      </div>
      <div
        className='mr10'
        onClick={() => {
          handleSetStatus({ statusId: 2 });
        }}
      >
        {statusId === 2 ? (
          <TredStatusComplete color={'#faf2a7'} />
        ) : (
          <TredStatus color={'#faf2a7'} />
        )}
      </div>
      <div
        className='mr10'
        onClick={() => {
          handleSetStatus({ statusId: 3 });
        }}
      >
        {statusId === 3 ? (
          <TredStatusComplete color={'#efefef'} />
        ) : (
          <TredStatus color={'#efefef'} />
        )}
      </div>
      <div
        className='mr10'
        onClick={() => {
          handleSetStatus({ statusId: 4 });
        }}
      >
        {statusId === 4 ? (
          <TredStatusComplete color={'#e80025'} />
        ) : (
          <TredStatus color={'#e80025'} />
        )}
      </div>
    </div>
  );
};

type ExitTredType = {
  exitThreadPerformersMutation: ExitThreadPerformersMutationFn;
  hideExcess: boolean;
  buttonResize: boolean;
  performers: performersType;
  threadId: number;
};

export const ExitTred = ({
  exitThreadPerformersMutation,
  hideExcess,
  buttonResize,
  performers,
  threadId,
}: ExitTredType) => {
  const { currentUserData } = useAuthContext();

  const performersInclude = () => {
    const performersId = performers?.map((e: any) => e?.id);
    const userId = currentUserData?.id;
    return performersId?.includes(userId);
  };

  if (!performersInclude()) return <></>;

  return (
    <div
      className='TreadItemHeader__exit'
      onClick={() => {
        exitThreadPerformersMutation({
          variables: {
            threadId: threadId,
          },
        });
      }}
      style={
        buttonResize ? { width: 'auto', padding: '5px', boxSizing: 'border-box' } : {}
      }
    >
      {buttonResize ? 'Выйти ' : 'Выйти из тредда'}
      <ExitIcons className={!hideExcess && 'ml5'} />
    </div>
  );
};

const useTagsResize = ({ tags, meta }: any) => {
  const { isOpenMenu } = useMenuContext();

  const [hideExcess, setHideExcess] = useState(false);
  const [buttonResize, setButtonResize] = useState(false);

  const refAll = useRef<any>(null);
  const refName = useRef<any>(null);
  const refTagsOuter = useRef<any>(null);
  const refTags = useRef<any>(null);

  const [tagOutherWirth, setTagOutherWirth] = useState(0);

  useEffect(() => {
    setTagOutherWirth(refTagsOuter?.current?.offsetWidth);
  }, [tags, meta]);

  useEffect(() => {
    const handleResize = () => {
      const fullWidth =
        refAll?.current?.offsetWidth >= 921 ? refAll?.current?.offsetWidth : 921;

      const widthName = refName?.current?.offsetWidth;
      const widthTagOuther = tagOutherWirth;

      const contentWidth = widthName + widthTagOuther + 150;

      if (contentWidth >= fullWidth) {
        setHideExcess(true);
      } else {
        setHideExcess(false);
      }
      if (window?.innerWidth <= 1440) {
        setButtonResize(true);
      } else {
        setButtonResize(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isOpenMenu, tags, meta]);

  return { hideExcess, buttonResize, refAll, refName, refTagsOuter, refTags };
};

type Tag = ({
  __typename?: 'Tag' | undefined;
  id: number;
  name: string;
  color?: string | null | undefined;
  textColor?: string | null | undefined;
  createdAt?: string | null | undefined;
  updatedAt?: string | null | undefined;
} | null)[];

type Meta = Array<{
  __typename?: 'ScaleMapTask';
  id: number;
  name: string;
  column?: number | null;
  row?: number | null;
  isMain?: boolean | null;
  isCurrent?: boolean | null;
  status?: { __typename?: 'ScaleMapStatus'; color: string; name: string } | null;
} | null>;

type performersType = {
  id: any;
  login: any;
  image: any;
  threads: any;
  status: any;
}[];

type mainPerformersType = {
  __typename?: 'User';
  id?: number | null;
  login?: string | null;
  image?: string | null;
  IsExistUnViewedForum: boolean;
  role?: string | null;
  fullName?: string | null;
} | null;

type AuthorType =
  | {
      __typename?: 'User' | undefined;
      id?: number | null | undefined;
      login?: string | null | undefined;
      image?: string | null | undefined;
    }
  | undefined;
