import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const ChatIcon: FC<AddPageIconType> = ({
  width = '20px',
  height = '20px',
  className = 'chat-icon',
  color = '#303030',
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M5.87508 8.00004C5.87508 8.27631 5.76533 8.54126 5.56998 8.73661C5.37463 8.93196 5.10968 9.04171 4.83341 9.04171C4.55715 9.04171 4.2922 8.93196 4.09685 8.73661C3.90149 8.54126 3.79175 8.27631 3.79175 8.00004C3.79175 7.72377 3.90149 7.45882 4.09685 7.26347C4.2922 7.06812 4.55715 6.95837 4.83341 6.95837C5.10968 6.95837 5.37463 7.06812 5.56998 7.26347C5.76533 7.45882 5.87508 7.72377 5.87508 8.00004ZM10.0417 8.00004C10.0417 8.27631 9.932 8.54126 9.73665 8.73661C9.5413 8.93196 9.27635 9.04171 9.00008 9.04171C8.72381 9.04171 8.45886 8.93196 8.26351 8.73661C8.06816 8.54126 7.95841 8.27631 7.95841 8.00004C7.95841 7.72377 8.06816 7.45882 8.26351 7.26347C8.45886 7.06812 8.72381 6.95837 9.00008 6.95837C9.27635 6.95837 9.5413 7.06812 9.73665 7.26347C9.932 7.45882 10.0417 7.72377 10.0417 8.00004ZM13.1667 9.04171C13.443 9.04171 13.708 8.93196 13.9033 8.73661C14.0987 8.54126 14.2084 8.27631 14.2084 8.00004C14.2084 7.72377 14.0987 7.45882 13.9033 7.26347C13.708 7.06812 13.443 6.95837 13.1667 6.95837C12.8905 6.95837 12.6255 7.06812 12.4302 7.26347C12.2348 7.45882 12.1251 7.72377 12.1251 8.00004C12.1251 8.27631 12.2348 8.54126 12.4302 8.73661C12.6255 8.93196 12.8905 9.04171 13.1667 9.04171Z'
        fill={color}
      />
      <path
        d='M2.92196 16.1282L2.94279 16.124C4.84904 15.7459 6.01362 15.2469 6.55529 14.973C7.35278 15.1857 8.17472 15.2928 9.00008 15.2917C13.6022 15.2917 17.3334 12.0271 17.3334 8.00004C17.3334 3.97296 13.6022 0.708374 9.00008 0.708374C4.398 0.708374 0.666748 3.97296 0.666748 8.00004C0.666748 9.83337 1.44071 11.5105 2.71883 12.7917C2.62941 13.6147 2.44628 14.4248 2.173 15.2063L2.16987 15.2177C2.09221 15.4416 2.00744 15.6629 1.91571 15.8813C1.83341 16.075 1.99279 16.2917 2.20008 16.2584C2.4414 16.2189 2.68205 16.1755 2.92196 16.1282ZM3.75529 12.8907C3.76988 12.7382 3.75065 12.5843 3.69898 12.4401C3.6473 12.2959 3.56445 12.1648 3.45633 12.0563C2.35216 10.948 1.70841 9.52921 1.70841 8.00004C1.70841 4.67504 4.83758 1.75004 9.00008 1.75004C13.1626 1.75004 16.2917 4.67504 16.2917 8.00004C16.2917 11.3261 13.1626 14.25 9.00008 14.25C8.26581 14.2511 7.53457 14.1559 6.82508 13.9667C6.57714 13.9003 6.3134 13.9277 6.08446 14.0438C5.68133 14.248 4.79279 14.6375 3.34071 14.974C3.54329 14.2939 3.68208 13.5965 3.75529 12.8907Z'
        fill={color}
      />
    </svg>
  );
};

type AddPageIconType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
>;
