import './ExpensesAccordion.scss';

import React, { FC } from 'react';

import { EXPENSES_ACCORDION_HEADER_TITLES } from '../../../constants/constants';
import { AccordionItem } from '../../accordionItem/AccordionItem';
import { ExpensesAccordionHeader } from '../expensesAccordionHeader/ExpensesAccordionHeader';
import { ExpensesRow, ExpensesTaskType } from '../expensesRow/ExpensesRow';

export const ExpensesAccordion: FC<ExpensesAccordion> = ({ projects }) => {
  return (
    <div className='expensesAccordion'>
      <ExpensesAccordionHeader items={EXPENSES_ACCORDION_HEADER_TITLES} />
      {projects?.map((project) => {
        if (project) {
          return (
            <AccordionItem
              key={`${project.id}${project?.name}`}
              title={`#${project?.number} ${project?.name}`}
            >
              {project?.tasks?.rows?.map((task) => {
                if (task)
                  return <ExpensesRow task={task} key={`${task?.id}${task?.name}`} />;
              })}
            </AccordionItem>
          );
        }
      })}
    </div>
  );
};

type ExpensesAccordion = {
  projects?: Array<{
    id: number;
    name?: string;
    number?: number | null;
    tasks?: { rows: Array<ExpensesTaskType | null> };
  }> | null;
};
