import './AddFileTaskModal.scss';

import { Form, Formik } from 'formik';
import React from 'react';

import { addFileTaskSchema } from '../../../../validation/schemas';
import { AlertIcons, RemoveIconBlack, SaveIconWhite } from '../../../icons/index';
import { AppInput, Button, ColorPicker, Switch, TagsSelect } from '../../../index';

type AddFileTaskModalType = {
  isOpen: boolean;
  onClose: () => void;
  modalParameters?: any;
};

export const AddFileTaskModal = ({ onClose, modalParameters }: AddFileTaskModalType) => {
  return (
    <div className='addFileTaskModal'>
      <Formik
        initialValues={
          modalParameters?.initialValues || {
            projectId: '',
            name: '',
            isMain: false,
            isExclamationPoint: false,
            tagsId: [],
            calendarTaskTextColor: '',
          }
        }
        onSubmit={(e) => {
          modalParameters?.onCallback(e);
          onClose();
        }}
        validationSchema={addFileTaskSchema}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form style={{ width: 'auto' }}>
              <div style={{ marginTop: '10px' }}>
                <AppInput
                  name={'name'}
                  label='Название:'
                  placeholder='Введите название'
                />
              </div>
              <AlertIcons
                color={values?.isExclamationPoint ? 'black' : 'gray'}
                className='mt5'
                onClick={() => {
                  setFieldValue('isExclamationPoint', !values?.isExclamationPoint);
                }}
              />
              <div className='addFileTaskModal__switch-wrapper'>
                Главная задача{' '}
                <Switch
                  value={values.isMain}
                  handleChange={(e: boolean) => {
                    setFieldValue('isMain', e);
                  }}
                />
              </div>
              <div style={{ position: 'relative' }}>
                {values.isMain && (
                  <TagsSelect
                    name='tagsId'
                    editTagIds={values?.tagsId?.map((e: any) => String(e))}
                    isEditMode={true}
                    maxTags={1}
                  />
                )}
              </div>
              {values.isMain && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                >
                  <ColorPicker
                    colorForm={values?.calendarTaskTextColor}
                    setColorForm={(e: string) => {
                      setFieldValue('calendarTaskTextColor', e);
                    }}
                    submit={null}
                  />
                  {values?.calendarTaskTextColor && (
                    <div
                      style={{
                        display: 'flex',
                        marginTop: '8px',
                        cursor: 'pointer',
                      }}
                    >
                      <div className='mr5'>{values?.calendarTaskTextColor}</div>
                      <div
                        onClick={() => {
                          modalParameters?.onCallback({
                            ...values,
                            calendarTaskTextColor: '',
                          });
                          onClose();
                        }}
                      >
                        <RemoveIconBlack />
                      </div>
                    </div>
                  )}
                </div>
              )}

              {modalParameters?.initialValues ? (
                <>
                  <Button
                    text={'Сохранить'}
                    type='submit'
                    width='100%'
                    height='25px'
                    margin='5px 0 0 0'
                  >
                    <SaveIconWhite className='icons' />
                  </Button>
                  <Button
                    text={'Удалить'}
                    width='100%'
                    height='25px'
                    margin='5px 0 0 0'
                    onClick={() => {
                      modalParameters.onCallbackDelete();
                      onClose();
                    }}
                  >
                    <RemoveIconBlack className='icons' />
                  </Button>
                </>
              ) : (
                <Button
                  text={'Добавить'}
                  type='submit'
                  width='100%'
                  height='25px'
                  margin='5px 0 0 0'
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
