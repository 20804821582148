import './SelectPackageModal.scss';

import { Form, Formik } from 'formik';
import React from 'react';

import { CrossIcon2 } from '../../../icons';
import { Button, Switch } from '../../../index';

export const SelectPackageModal = ({ onClose, modalParameters }: any) => {
  const initValue: any = {};
  modalParameters?.initialValues?.map((e: any) => {
    initValue[e.id] = e?.isActiveInCurrentUser;
  });
  return (
    <Formik
      onSubmit={(value) => {
        const convert: any = [];
        Object.keys(value)?.map((e) => {
          if (value[e]) {
            convert.push(parseInt(e));
          }
        });
        modalParameters?.onCallback(convert);
        onClose();
      }}
      initialValues={initValue}
      enableReinitialize={true}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form className='selectPackageModal'>
            <div className='selectPackageModal__title'>
              Пакеты спонтанных заданий
              <CrossIcon2 onClick={onClose} />
            </div>
            {modalParameters?.initialValues?.length === 0 ? (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '20px',
                }}
              >
                Hет пакетов для выбора
              </div>
            ) : (
              <div className='selectPackageModal__body'>
                {modalParameters?.initialValues?.map((e: any) => {
                  return (
                    <div key={e.id} className='selectPackageModal__switchWrapper-outer'>
                      <div className='selectPackageModal__switchWrapper'>
                        <div className='selectPackageModal__switchWrapper-inner'>
                          <div
                            className='selectPackageModal__color'
                            style={{
                              backgroundColor: e?.color,
                            }}
                          ></div>
                          <div className='selectPackageModal__switchWrapper-text'>
                            {e?.title}
                          </div>
                        </div>
                        <Switch
                          value={values[e?.id]}
                          handleChange={(data: any) => {
                            setFieldValue(`${e?.id}`, data);
                          }}
                        />
                      </div>
                      <div className='selectPackageModal__description'>
                        {e?.description}
                      </div>
                    </div>
                  );
                })}
                <Button type='submit' margin={'10px auto'}>
                  Сохранить
                </Button>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
