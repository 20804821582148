import './UserStatusSelect.scss';

import React from 'react';

import { useAuthContext } from '../../context';
import { AppSelect } from '../appSelect/AppSelect';

export const UserStatusSelect = () => {
  const { currentUserData } = useAuthContext();

  const options = [
    {
      value: 'Guest',
      label: (
        <div className='userStatusSelect__item'>
          <div
            className='userStatusSelect__mark'
            style={{ backgroundColor: '#d9cd22b0' }}
          />
          <p>Гость</p>
        </div>
      ),
    },
    {
      value: 'User',
      label: (
        <div className='userStatusSelect__item'>
          <div
            className='userStatusSelect__mark'
            style={{ backgroundColor: '#149939' }}
          />
          <p>Пользователь</p>
        </div>
      ),
    },
    {
      value: 'Moderator',
      label: (
        <div className='userStatusSelect__item'>
          <div
            className='userStatusSelect__mark'
            style={{ backgroundColor: '#650b78' }}
          />
          <p>Модератор</p>
        </div>
      ),
    },
    ...(currentUserData?.role === 'Admin'
      ? [
          {
            value: 'Admin',
            label: (
              <div className='userStatusSelect__item'>
                <div
                  className='userStatusSelect__mark'
                  style={{ backgroundColor: '#e80025' }}
                />
                <p>Администратор</p>
              </div>
            ),
          },
        ]
      : []),
  ];

  return <AppSelect placeholder='Роль пользователя:' name='role' options={options} />;
};
