import './TreadsCard.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import threadHIstoryBgImg from '../../../assets/thread-history-bg.png';
import { useAuthContext } from '../../../context';
import { Thread } from '../../../graphql/generated/graphql';
import { getUserAvatar, textSlice } from '../../../utils';
import { TagItem } from '../../index';

type TreadsCardType = {
  tread: Thread;
  isAll: boolean;
  selectOtherUser: boolean;
};

export const TreadsCard = ({ tread, isAll, selectOtherUser }: TreadsCardType) => {
  const navigate = useNavigate();

  const { currentUserData } = useAuthContext();

  const userTag = tread?.userLastEdited?.tags?.rows[0];
  const percent = tread?.statistic?.performerAcceptedPercent;

  const calcColor = () => {
    if (percent <= 33) {
      return 'red';
    }
    if (percent <= 66) {
      return '#dadb00';
    }
    if (percent <= 99) {
      return 'blue';
    }
    return 'black';
  };

  const { count } = useResize();

  const backgroundImage = tread?.cover ? `url(${getUserAvatar(tread?.cover)})` : '';
  const backgroundImageForThreadHistory = `url(${threadHIstoryBgImg})`;

  const getBackgroundColor = (defaultValue = '#303030'): string => {
    return tread?.threadBackground?.backgroundColor
      ? tread?.threadBackground?.backgroundColor
      : defaultValue;
  };

  const isHistoryThread =
    tread.performerStatus === '-1' ||
    tread.scaleMapTask?.status?.id === 1 ||
    !tread.performers.find((performer) => performer.userId === currentUserData?.id);

  return (
    <div
      className='treadsCard'
      onClick={(e) => {
        e.stopPropagation();

        navigate(`/tread/${tread?.id}`);
      }}
      style={
        isAll && isHistoryThread
          ? {
              backgroundImage: backgroundImageForThreadHistory,
              backgroundSize: 'cover',
            }
          : {}
      }
    >
      <div
        className='treadsCard__header'
        style={
          isAll && isHistoryThread
            ? {
                backgroundColor: 'transparent',
              }
            : {
                backgroundImage: backgroundImage,
                backgroundColor: getBackgroundColor(''),
              }
        }
      >
        {isAll && isHistoryThread ? null : (
          <img
            src={getUserAvatar(
              tread?.mainPerformer?.image
                ? tread?.mainPerformer?.image
                : tread?.author?.image,
            )}
            className='treadsCard__avatar'
          />
        )}

        {isAll && isHistoryThread ? null : (
          <div className='treadsCard__header-status'>
            <div
              className='treadsCard__header-percent'
              style={{
                width: `${percent}%`,
                backgroundColor: calcColor(),
              }}
            ></div>
          </div>
        )}

        {isAll && isHistoryThread ? null : (
          <div className='treadsCard__header-tags'>
            {tread?.tags?.map((e) => {
              const slice = tread?.tags?.length === 3;
              return (
                <div key={e?.id} className='ml5 mb5'>
                  <TagItem
                    title={
                      slice
                        ? textSlice({
                            text: e?.name,
                            count: count ? 7 : 20,
                            hide: true,
                          })
                        : (e?.name as string)
                    }
                    color={e?.color}
                    textColor={e?.textColor}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div
        className='treadsCard__body'
        style={
          isAll && isHistoryThread
            ? {
                backgroundColor: 'transparent',
              }
            : {}
        }
      >
        <div className='treadsCard__body-two'>
          {tread?.meta?.map((e, index) => {
            const first =
              (tread?.meta?.length === 3 && index === 0) || (!e?.status && index === 0);

            if (!first && !e?.isCurrent) return <div key={e?.id}></div>;

            return (
              <div
                className='treadsCard__body-two__item'
                key={e?.id}
                style={{ backgroundColor: first ? '#e2e2e2' : '#b3e4c1' }}
              >
                {e?.status?.color && (
                  <div
                    className='treadsCard__body-two__item-color'
                    style={{
                      backgroundColor: e?.status?.color,
                    }}
                  ></div>
                )}

                {textSlice({
                  text: e?.name,
                  count: count ? 28 : 35,
                })}
              </div>
            );
          })}
        </div>
        {isAll && isHistoryThread ? null : (
          <div className='treadsCard__body-tree'>
            <div style={{ marginLeft: 'auto' }}>
              {tread?.userLastEdited?.tags &&
              currentUserData?.id !== tread?.userLastEdited?.id &&
              tread?.userLastEdited?.tags?.rows?.length > 0 ? (
                <TagItem
                  title={userTag?.name as string}
                  color={userTag?.color}
                  textColor={userTag?.textColor}
                />
              ) : (
                <div></div>
              )}
            </div>
          </div>
        )}
        {tread?.performerStatus === '0' && !selectOtherUser && (
          <div className='treadsCard__new'>NEW</div>
        )}
      </div>
    </div>
  );
};

const useResize = () => {
  const [count, setCount] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setCount(width <= 1520);
  }, [width]);

  return { count };
};
