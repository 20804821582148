import './CursiveTextIcon.scss';

import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const CursiveTextIcon: FC<CursiveTextIconType> = ({
  width = '18px',
  height = '18px',
  className = 'cursive-text-icon',
  color = '#FFFFFF',
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 10 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M3.42188 6.89062H2.33594L1.44531 12H0.046875L2.02344 0.625H3.42188L2.54688 5.64062H3.34375L7.3125 0.625H9.15625L4.63281 6.20312L7.34375 12H5.73438L3.42188 6.89062Z'
        fill={color}
      />
    </svg>
  );
};

type CursiveTextIconType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
>;
