import '../../../treads/TredsModal/TredsModal.scss';

import React, { useState } from 'react';

import { useAuthContext } from '../../../../context/AuthContext';
import { CreateStatus } from '../../../treads/TredsModal/CreateStatus';

export const ScaleToTreadModal = ({ onClose, modalParameters }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const { currentUserData } = useAuthContext();
  return (
    <div style={{ width: '360px', padding: '20px' }}>
      <div
        style={{
          fontFamily: 'Roboto Condensed',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '14px',
          marginBottom: '10px',
        }}
      >
        Создать тред
      </div>
      <CreateStatus
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        closeModal={onClose}
        updateThreadMutation={(e: any) => {
          modalParameters?.onCallback(e);
        }}
        initialValues={{
          name: modalParameters?.initialValues?.name,
          tagsIds: [],
          cover: '',
          threadBackgroundId: null,
          performers: [currentUserData],
          selectFile: modalParameters?.initialValues?.selectFile,
        }}
        id={modalParameters?.initialValues?.id}
        inTable={true}
      />
    </div>
  );
};
