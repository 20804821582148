import './Deadline.scss';

import React from 'react';

import { flag } from '../../assets/icons';
import { formatDate } from '../../utils';

type DeadlinePropTypes = {
  date: string;
  status?: number | null;
};

export const Deadline = ({ date }: DeadlinePropTypes) => {
  return (
    <div className='deadline'>
      <img className='deadline__icon' src={flag} alt='deadline' />
      <p className='deadline__text'>{formatDate(new Date(date), 'dd-MM-yyyy')}</p>
    </div>
  );
};
