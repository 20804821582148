import './PolygonStatistic.scss';

import React, { useRef, useState } from 'react';
import { Stage } from 'react-konva';
import { useNavigate, useParams } from 'react-router-dom';

import {
  ButtonTree,
  CrossIcon,
  PolygonDescription,
  PolygonGeneralLayer,
  PolygonMainArrowMenu,
  PolygonMainBlockMenu,
  RequestHandler,
} from '../../components';
import { LongArrowTopIcon, PaletteIcon, SaveIcon } from '../../components/icons';
import {
  polygonHeight,
  polygonStatisticHeight,
  polygonStatisticWidth,
  polygonWidth,
} from '../../constants/constants';
import { useMenuContext, useModalContext } from '../../context';
import {
  useApiPolygonStatistic,
  useArrow,
  useCheckAccessEditPolygon,
  useClickOutside,
  usePolygon,
} from '../../hooks';
import { AddArrowType, HoverRectIdType, ModalStatuses } from '../../types/enums';
import { arrowArrayType } from '../../types/types';

export const PolygonStatistic = () => {
  const { descriptionState } = useMenuContext();
  const { projectId, polygonStatisticID } = useParams();
  const navigate = useNavigate();
  const ref = useRef(null);
  const refScroll = useRef<any>(null);

  const [hoverRectId, setHoverRectId] = useState<{
    id: number | string;
    type: HoverRectIdType;
  }>({
    id: 0,
    type: HoverRectIdType.NONE,
  });
  const {
    rectangles,
    setRectangles,
    polygonTools,
    selectedIdRect,
    setSelectIdRect,
    getSelectedRect,
    getRectDescriptionById,
    colorsData,
    setColorsData,
    descriptionMenuOpen,
    descriptionMenuOpenHandler,
  } = usePolygon();
  const {
    arrowArrayDraggable,
    arrowArray,
    addArrow,
    setAddArrow,
    arrowTools,
    selectedIdArrow,
    setArrowArray,
  } = useArrow();

  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  const { onSaveButton, onSaveColorsPallete, poligon, project, loadingRectangle } =
    useApiPolygonStatistic({
      rectangles,
      arrowArray,
      setRectangles,
      setArrowArray,
      setColorsData,
    });

  const { checkAccess, getProjectDataLoading } = useCheckAccessEditPolygon({ poligon });

  const isAccess = checkAccess({ skipMessage: true });

  const handleCheckAndSetColor = (values: {
    polygonBlockName: string;
    blockColorsName: string;
  }) => {
    const checkedColors = colorsData.find(
      (color) => color.color === values.blockColorsName,
    );
    if (!checkedColors && values.blockColorsName !== '') {
      onSaveColorsPallete([
        {
          id: Date.now().toString(),
          color: values.blockColorsName,
          name: values.polygonBlockName,
        },
        ...colorsData,
      ]);
    }
  };

  useClickOutside(ref, () => {
    setSelectIdRect(null);
  });

  return (
    <div className='container'>
      <RequestHandler loading={getProjectDataLoading}>
        <div className='statistic-page'>
          <div className='statistic-page__name'>
            #{project?.getProject.number} {project?.getProject?.name || poligon?.name}
          </div>
          <div
            className='statistic-page__instruments'
            style={{ display: isAccess ? 'flex' : 'none' }}
          >
            <div className='statistic-page__instruments-container'>
              <div
                className='statistic-page__instruments-button statistic-page__instruments_add mr50'
                onClick={() => {
                  if (!isAccess) return;

                  handleSetModalStatus(ModalStatuses.ADD_POLYGON_BLOCK_MODAL);
                  handleChangeModalParameters([
                    {
                      key: 'onCallback',
                      value: (values: {
                        polygonBlockName: string;
                        blockColorsName: string;
                      }) => {
                        polygonTools.crateNew({
                          name: values.polygonBlockName,
                          color: values.blockColorsName,
                          width: polygonStatisticWidth,
                          height: polygonStatisticHeight,
                        });
                        handleCheckAndSetColor(values);
                      },
                    },
                    {
                      key: 'colors',
                      value: colorsData,
                    },
                    {
                      key: 'projectName',
                      value: poligon?.project?.name,
                    },
                  ]);
                }}
              >
                <CrossIcon
                  className='statistic-page__instruments-icons'
                  color='white'
                  width='8px'
                  height='8px'
                />
                Добавить раздел
              </div>
              <div
                className='statistic-page__instruments-button mr50'
                onClick={() => {
                  if (!isAccess) return;

                  setAddArrow(AddArrowType.Add);
                }}
              >
                <LongArrowTopIcon
                  color='#303030'
                  className='long-arrow-top-right-icon mr10'
                />
                Стрелка
              </div>
              {poligon?.id && (
                <div
                  className='statistic-page__instruments-button-arrow'
                  onClick={() => {
                    if (!isAccess) return;

                    handleSetModalStatus(ModalStatuses.POLYGON_PALETTE_MODAL);
                    handleChangeModalParameters([
                      {
                        key: 'onCallback',
                        value: (values: any) => onSaveColorsPallete(values),
                      },
                      {
                        key: 'initColors',
                        value: colorsData,
                      },
                    ]);
                  }}
                >
                  <PaletteIcon className='statistic-page__instruments' />
                  <span>Цветовая схема проекта</span>
                </div>
              )}
              {(arrowArray?.length > 1 || rectangles?.length > 0) && (
                <div
                  className='statistic-page__instruments-button-arrow ml50'
                  onClick={onSaveButton}
                >
                  <SaveIcon />
                  <span>Сохранить</span>
                </div>
              )}
            </div>
            {poligon?.id ? (
              <ButtonTree
                descriptionMenuOpen={descriptionMenuOpen}
                handleSetModalStatus={handleSetModalStatus}
                handleChangeModalParameters={handleChangeModalParameters}
                poligon={poligon}
                projectId={projectId}
                polygonId={0}
                polygonStatisticID={polygonStatisticID}
              />
            ) : null}
          </div>
          <div
            className='statistic-page__instruments'
            style={{ display: isAccess ? 'none' : 'flex' }}
          >
            {poligon?.id ? (
              <ButtonTree
                descriptionMenuOpen={descriptionMenuOpen}
                handleSetModalStatus={handleSetModalStatus}
                handleChangeModalParameters={handleChangeModalParameters}
                poligon={poligon}
                projectId={projectId}
                statisticAccess={true}
                polygonId={0}
                polygonStatisticID={polygonStatisticID}
              />
            ) : null}
          </div>
          <RequestHandler loading={loadingRectangle}>
            <div className='statistic-page__polygon' ref={ref as any}>
              <div
                className='statistic-page__container'
                style={{
                  height: `calc(100vh - 187px - ${!descriptionState ? '12px' : '50px'})`,
                }}
                ref={refScroll}
              >
                <Stage
                  width={polygonWidth}
                  height={polygonHeight}
                  onMouseMove={(event) => {
                    arrowTools.mouseMove({ event });
                  }}
                  onMouseDown={(event) => {
                    arrowTools.mouseDown({ event });
                    polygonTools.checkDeselect({ event });
                    arrowTools.checkDeselect({ event });
                  }}
                  onMouseUp={() => {
                    arrowTools.mouseUp();
                  }}
                  onTouchStart={(event) => {
                    polygonTools.checkDeselect({ event });
                    arrowTools.checkDeselect({ event });
                  }}
                >
                  <PolygonGeneralLayer
                    rectangles={rectangles}
                    arrowArray={arrowArray}
                    arrowArrayDrag={arrowArrayDraggable as arrowArrayType}
                    addArrow={addArrow}
                    navigate={navigate}
                    polygonTools={polygonTools}
                    arrowTools={arrowTools}
                    projectId={projectId as string}
                    isAccess={isAccess}
                    loadingRectangle={loadingRectangle}
                    polygonStatisticID={polygonStatisticID}
                    setHoverRectId={setHoverRectId}
                    hoverRectId={hoverRectId}
                  />
                </Stage>
              </div>
              <PolygonMainArrowMenu
                arrowId={selectedIdArrow}
                colors={colorsData}
                onSetColor={(arrowId, fill) =>
                  arrowTools.changeColor({ id: arrowId, fill })
                }
                onRemove={(arrowId) => arrowTools.delete({ id: arrowId })}
                removeConfirmTitle='связь'
                arrowArray={arrowArray}
                selectedIdArrow={selectedIdArrow}
                refScroll={refScroll}
              />
              <PolygonMainBlockMenu
                blockId={selectedIdRect}
                removeConfirmTitle={String(getSelectedRect()?.name)}
                onRemove={(blockId) => polygonTools.delete({ id: blockId as any })}
                changeParameter={polygonTools.changeParameter}
                onSaveEditData={(polygonName, color) => {
                  polygonTools.changeNameAndColor({
                    id: selectedIdRect as any,
                    name: polygonName as string,
                    color: color as any,
                  });
                }}
                onSaveLinksData={(values) => {
                  polygonTools.changeParentLinkAndParentName({
                    id: selectedIdRect as any,
                    parentLink: values.parentLink,
                    parentName: values.parentName,
                  });
                }}
                initLinkValue={getSelectedRect()?.parentLink}
                initialEditInfoValues={{
                  polygonName: getSelectedRect()?.name,
                  color: getSelectedRect()?.fill,
                }}
                initialColorId={
                  colorsData.find((item) => item.color === getSelectedRect()?.fill)?.id
                }
                initialInfoValues={{
                  info: getRectDescriptionById(selectedIdRect)?.text,
                }}
                rectangles={rectangles}
                selectedIdRect={selectedIdRect}
                editColors={colorsData}
                poligon={poligon}
                refScroll={refScroll}
              />
            </div>
          </RequestHandler>
          <PolygonDescription
            rectangles={rectangles}
            descriptionMenuOpenHandler={descriptionMenuOpenHandler}
            selectedBlockId={selectedIdRect}
            projectName={poligon?.project?.name}
            hoverRectId={hoverRectId}
            setHoverRectId={setHoverRectId}
          />
        </div>
      </RequestHandler>
    </div>
  );
};
