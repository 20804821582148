import React from 'react';

export const WarningCalendarIcon = () => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.99984 0.666626C4.39756 0.666626 0.666504 4.39769 0.666504 8.99996C0.666504 13.6022 4.39756 17.3333 8.99984 17.3333C13.6021 17.3333 17.3332 13.6022 17.3332 8.99996C17.3332 4.39769 13.6021 0.666626 8.99984 0.666626ZM9.75741 5.21208C9.75741 5.01116 9.6776 4.81847 9.53552 4.67639C9.39345 4.53432 9.20076 4.4545 8.99984 4.4545C8.79892 4.4545 8.60622 4.53432 8.46415 4.67639C8.32208 4.81847 8.24226 5.01116 8.24226 5.21208V9.75754C8.24226 9.95846 8.32208 10.1511 8.46415 10.2932C8.60622 10.4353 8.79892 10.5151 8.99984 10.5151C9.20076 10.5151 9.39345 10.4353 9.53552 10.2932C9.6776 10.1511 9.75741 9.95846 9.75741 9.75754V5.21208ZM9.75741 12.4091C9.75741 12.2081 9.6776 12.0154 9.53552 11.8734C9.39345 11.7313 9.20076 11.6515 8.99984 11.6515C8.79892 11.6515 8.60622 11.7313 8.46415 11.8734C8.32208 12.0154 8.24226 12.2081 8.24226 12.4091V12.7878C8.24226 12.9888 8.32208 13.1815 8.46415 13.3235C8.60622 13.4656 8.79892 13.5454 8.99984 13.5454C9.20076 13.5454 9.39345 13.4656 9.53552 13.3235C9.6776 13.1815 9.75741 12.9888 9.75741 12.7878V12.4091Z'
        fill='white'
      />
    </svg>
  );
};
