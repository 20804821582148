import './CopyPasswordModal.scss';

import React from 'react';

export const CopyPasswordModal = ({ modalParameters }: any) => {
  return (
    <div className='CopyPasswordModal'>
      <p> Пароль успешно сброшен!</p>
      <a href={modalParameters.resetPasswordLink} target='_blank' rel='noreferrer'>
        Используйте ссылку для установки нового пароля!
      </a>
    </div>
  );
};
