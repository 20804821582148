import './Chat.scss';

import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ScrollableFeed from 'react-scrollable-feed';

import {
  Button,
  ChatMessage,
  MainContentHeaderItem,
  RequestHandler,
  Textarea,
} from '../../components';
import { useChatContext } from '../../context';

export const Chat: FC = () => {
  const { subtaskId, privateChatId } = useParams();

  const {
    messages,
    chatId,
    fetchMoreMessages,
    messagesCount,
    chatScrollerRef,
    scrollChatToBottom,
    sendMessageHandler,
    refetch,
  } = useChatContext();

  const [isAtBottom, setIsAtBottom] = useState(true);

  useEffect(() => {
    setIsAtBottom(true);
  }, [chatId]);

  const showDown = !!(!isAtBottom && messagesCount && messagesCount > 0);

  return (
    <div className='chat'>
      <RequestHandler>
        <div className='chat__header'>
          <MainContentHeaderItem
            title={`Чат #${chatId ? chatId : ''}`}
            style={{ fontWeight: 'normal' }}
            refetch={refetch}
            chatId={chatId}
          />
        </div>

        <div
          className='chat__content'
          style={{
            backgroundColor: subtaskId
              ? '#e4efff'
              : privateChatId
              ? '#D5EDD8'
              : '#f5f5f5',
          }}
        >
          <ScrollableFeed
            ref={chatScrollerRef}
            onScroll={(isAtBottom) => setIsAtBottom(isAtBottom)}
            className='chat__messages'
          >
            {messages?.[0] && (
              <div className='chat__fetch-button'>
                {Number(messagesCount) > messages?.length ? (
                  <Button
                    text={'Загрузить ещё...'}
                    withBorder
                    backgroundColor={'white'}
                    onClick={fetchMoreMessages}
                  />
                ) : (
                  'Это все сообщения'
                )}
              </div>
            )}
            {messages?.[0] ? (
              messages
                ?.map((message: any, index) => {
                  return (
                    <ChatMessage
                      key={message?.id + index + message?.createdAt}
                      messageId={message?.id}
                      avatar={message?.user?.image}
                      messageText={message?.text}
                      login={message?.user?.login}
                      createdAt={message?.createdAt}
                      isRead={message?.isRead}
                      karmaStatistics={message?.user?.karmaStatistics}
                      isPossibleSetKarma={message?.isPossibleSetKarma}
                    />
                  );
                })
                .reverse()
            ) : (
              <p className='chat__absence'>Пока нет сообщений...</p>
            )}
            {showDown && (
              <Button
                text={'вниз ⇓'}
                onClick={scrollChatToBottom}
                withBorder
                width='150px'
                backgroundColor={'white'}
              />
            )}
          </ScrollableFeed>
          <Textarea sendMessage={sendMessageHandler} chatId={chatId} />
        </div>
      </RequestHandler>
    </div>
  );
};
