import * as GENERATED from '../../graphql/generated/graphql';
import { useNewActivityRefetch } from '../useNewActivityRefetch';

export const useReadProjectActivitiesMutation = () => {
  const { GetProjectActivitiesRefetch } = useNewActivityRefetch();

  return GENERATED.useReadProjectActivitiesMutation({
    onCompleted: () => {
      GetProjectActivitiesRefetch();
    },
  });
};
