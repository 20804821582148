import './CreateChapterModal.scss';

import { Form, Formik } from 'formik';
import React from 'react';

import {
  ForumSectionRoles,
  useCreateForumSectionMutation,
} from '../../../../graphql/generated/graphql';
import { addFileTaskSchema } from '../../../../validation/schemas';
import { Plus2Icons } from '../../../icons/index';
import { AppInput, AppSelect, Button } from '../../../index';

type UpdateFileScaleModalType = {
  isOpen: boolean;
  onClose: () => void;
  modalParameters?: any;
};

export const CreateChapterModal = ({
  onClose,
  modalParameters,
}: UpdateFileScaleModalType) => {
  const [createForumSectionMutation] = useCreateForumSectionMutation({
    variables: {
      data: { name: '' },
    },
    onCompleted: () => {
      modalParameters.refetch();
    },
  });
  return (
    <div className='CreateChapterModal'>
      <Formik
        initialValues={{ name: '', role: 'all' }}
        onSubmit={(e) => {
          createForumSectionMutation({
            variables: {
              data: {
                name: e.name,
                role: e?.role === 'all' ? undefined : (e?.role as any),
              },
            },
          });
          onClose();
        }}
        validationSchema={addFileTaskSchema}
      >
        {() => {
          return (
            <Form style={{ width: 'auto' }}>
              <div className='CreateChapterModal__title'>Создать раздел</div>
              <div className='CreateChapterModal__inputWrapper'>
                <AppInput name={'name'} label={'Название:'} />
              </div>
              <div className='CreateChapterModal__selectorWrapper'>
                <div>Доступ:</div>
                <AppSelect
                  name='role'
                  options={[
                    {
                      value: 'all',
                      label: 'Все пользователи',
                    },
                    {
                      value: ForumSectionRoles.Admin,
                      label: 'Админ',
                    },
                    {
                      value: ForumSectionRoles.AdminModerator,
                      label: 'Админ и модератор',
                    },
                  ]}
                  placeholder=''
                  isSearchable={true}
                />
              </div>
              <div className='mb20'>
                <Button
                  text={`Создать`}
                  type='submit'
                  width='100%'
                  height={25}
                  onClick={() => {}}
                  backgroundColor={'green'}
                  color={'white'}
                >
                  <Plus2Icons className='CreateChapterModal__icons' color='white' />
                </Button>
              </div>
              <Button
                text={`Отмена`}
                type='submit'
                width='100%'
                height={25}
                onClick={() => {
                  onClose();
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
