import './Notes.scss';

import { Field, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, RequestHandler } from '../../components';
import { useAuthContext } from '../../context';
import {
  useGetSubtaskNotesLazyQuery,
  useGetTaskNotesLazyQuery,
} from '../../graphql/generated/graphql';
import { useCreateNoteMutation } from '../../hooks/useMutations';
import { useGetTaskAssigneesQuery } from '../../hooks/useQueries';
import { NotesItem } from '../notesItem/NotesItem';

export const Notes: React.FC<NotesPropsType> = ({
  maintainer,
  backgroundColor,
  executorsHeight = 223,
}) => {
  const [createNote, { loading: loadingNotes }] = useCreateNoteMutation();

  const { taskId, subtaskId } = useParams();

  const { currentUserData } = useAuthContext();

  const { data } = useGetTaskAssigneesQuery();

  const [
    getSubtaskNotes,
    { data: subtaskNotes, refetch: subtaskNotesRefetch, loading: subtaskNotesLoading },
  ] = useGetSubtaskNotesLazyQuery();

  const [
    getTaskNotes,
    { data: taskNotes, refetch: taskNotesRefetch, loading: taskNotesLoading },
  ] = useGetTaskNotesLazyQuery();

  useEffect(() => {
    if (subtaskId) {
      getSubtaskNotes({
        variables: {
          id: Number(subtaskId),
        },
      });
    } else if (!subtaskId && taskId) {
      getTaskNotes({
        variables: {
          id: Number(taskId),
        },
      });
    }
  }, []);

  const prevNotes = subtaskId
    ? subtaskNotes?.getSubtask?.notes
    : taskNotes?.getTask.notes;

  const rightsChecker = () => {
    const check = data?.getTask.assignees.map((assignUser) => assignUser?.login);
    return (
      maintainer?.login === currentUserData?.login ||
      check?.includes(currentUserData?.login) ||
      currentUserData?.role === 'Admin'
    );
  };

  const handleSubmit = async (values: initialStateType, { setFieldValue }: any) => {
    if (values.notes !== null && values.notes !== undefined && values.notes !== '') {
      await createNote({
        variables: {
          data: subtaskId
            ? {
                content: values.notes.toString().trim(),
                subtaskId: Number(subtaskId),
              }
            : {
                content: values.notes.toString().trim(),
                taskId: Number(taskId),
              },
        },

        onCompleted: (data?) => {
          if (data?.createNote?.id) {
            toast.success('Заметка успешно сохранена!');
            setFieldValue('notes', '');
          }
        },
      });
    } else {
      toast.error('Введите текст заметки!');
    }
  };

  const initialState = {
    notes: '',
  };

  const intFrameHeight = window.innerHeight;

  return (
    <Formik initialValues={initialState} onSubmit={handleSubmit}>
      {({ handleSubmit }) => (
        <div className='notes'>
          <div className='notes__header'>
            <p>Заметки</p>
            {rightsChecker() ? (
              <Button
                type='submit'
                onClick={handleSubmit}
                text='Сохранить'
                disabled={loadingNotes}
              />
            ) : null}
          </div>
          <div
            className='notes__text'
            style={
              intFrameHeight < 649
                ? {
                    backgroundColor,
                    height: `calc(100vh - 405px - ${executorsHeight}px + 50px)`,
                  }
                : {
                    backgroundColor,
                    height: `calc(100vh - 405px - ${executorsHeight}px)`,
                  }
            }
          >
            {rightsChecker() ? (
              <Field as='textarea' placeholder='Введите текст заметки' name='notes' />
            ) : null}

            <RequestHandler loading={subtaskId ? subtaskNotesLoading : taskNotesLoading}>
              {prevNotes?.map((prevNote) => {
                if (prevNote) {
                  return (
                    <NotesItem
                      key={prevNote.id}
                      note={prevNote}
                      assignees={data?.getTask.assignees}
                      refetch={subtaskId ? subtaskNotesRefetch : taskNotesRefetch}
                    />
                  );
                }
              })}
            </RequestHandler>
          </div>
        </div>
      )}
    </Formik>
  );
};

export type NotesType = {
  id?: number | null;
  createdAt?: string | null;
  chatId?: number | null;
  taskId?: number | null;
  content?: string | null;
  ownerId?: number | null;
  owner?: {
    id?: number | null;
    login?: string | null;
    fullName?: string | null;
    image?: string | null;
    createdAt?: string | null;
    role?: string | null;
  } | null;
};

type NotesPropsType = {
  backgroundColor: string;
  executorsHeight?: number;
  maintainer?: {
    id?: number | null | undefined;
    login?: string | null | undefined;
    image?: string | null | undefined;
  } | null;
};

type initialStateType = {
  notes?: string | null | undefined;
};
