import './Navigation.scss';

import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { ButtonLink, ButtonLinksWrap, Header, MenuLeft } from '../components';
import { PATHS, ROLES } from '../constants/constants';
import { routes } from '../constants/routes';
import { useAuthContext } from '../context';

type Props = {
  children: JSX.Element;
  authRequired: boolean;
  withLayout: boolean;
  allAccess?: boolean;
};

const RouteWrapper = ({ children, authRequired, withLayout, allAccess }: Props) => {
  const { isAuth, currentUserData } = useAuthContext();

  const location = useLocation();

  const isRegistration = location.pathname.includes('registr');
  const isRecovery = location.pathname.includes('password-recovery');

  //for public pages
  if (allAccess) return children;

  // for guest role
  if (isAuth && currentUserData?.role === ROLES.GUEST) {
    if (
      !location.pathname.startsWith(PATHS.projectI) &&
      location.pathname !== PATHS.chronometer &&
      !location.pathname.startsWith(PATHS.fileSelf.replace(':fileId', ''))
    ) {
      return <Navigate replace to={PATHS.chronometer} />;
    }
    return (
      <div className='wrapper'>
        <Header />
        <div
          className='block'
          style={location.pathname === PATHS.chronometer ? { overflowY: 'hidden' } : {}}
        >
          {children}
          <ButtonLinksWrap>
            <ButtonLink mode='chrono' title='Хронометр' path={PATHS.chronometer} />
            <ButtonLink mode='file' title='Проект "Я"' path='/projectI' />
          </ButtonLinksWrap>
        </div>
      </div>
    );
  }
  if (!isRegistration && authRequired && !isAuth && !isRecovery)
    return <Navigate replace to={PATHS.login} />;

  if (!isRegistration && !authRequired && isAuth)
    return <Navigate replace to={PATHS.treads} />;

  if (!withLayout) return children;

  let style: any = {};

  if (location.pathname === PATHS.chronometer) {
    style = { overflowY: 'hidden' };
  }

  if (location.pathname?.includes('/tread/')) {
    style = { height: '100%', overflowY: 'hidden' };
  }

  return (
    <div className='wrapper'>
      <Header />
      <div className='block' style={style}>
        <MenuLeft />
        {children}
        <ButtonLinksWrap>
          <ButtonLink
            mode='decideOnTheFly'
            title='Решай-на-лету'
            path={PATHS.decideOnTheFly}
          />
          <ButtonLink mode='circle' title='Круг общения' path={PATHS.circle} />
          <ButtonLink mode='chrono' title='Хронометр' path={PATHS.chronometer} />
          <ButtonLink mode='file' title='Проект "Я"' path='/projectI' />
          <ButtonLink mode='history' title='История задач' path='/history' />
        </ButtonLinksWrap>
      </div>
    </div>
  );
};

export const Navigation = () => {
  return (
    <Routes>
      {routes.map((route) => {
        const { path, withLayout, component: Component, auth, allAccess } = route;
        return (
          <Route
            key={path}
            path={path}
            element={
              <RouteWrapper
                withLayout={withLayout}
                authRequired={auth}
                allAccess={allAccess}
              >
                <Component />
              </RouteWrapper>
            }
          />
        );
      })}
      <Route path={'files/usersImage/:id'} />
      <Route path={'*'} element={<Navigate replace to={PATHS.calendar} />} />
    </Routes>
  );
};
