import { toast } from 'react-toastify';

import * as GENERATED from '../../graphql/generated/graphql';

export const useDeleteFileMutation = () => {
  return GENERATED.useDeleteFileMutation({
    onCompleted: (response) => {
      if (response.deleteFile?.status) {
        toast.success('Файл успешно удален!');
      }
    },
  });
};
