import React, { CSSProperties, FC } from 'react';

type StatusType = {
  style?: CSSProperties;
  color?: string | undefined;
};

export const Status: FC<StatusType> = ({ color, style }) => {
  return (
    <svg
      width='11'
      height='11'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
    >
      <path
        opacity={color === '#CCCCCC' ? '0.3' : '0.1'}
        d='M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z'
        fill={color}
      />
      <path
        opacity={color === '#CCCCCC' ? '0.3' : '0.2'}
        d='M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z'
        fill={color}
      />
      <path
        opacity={color === '#CCCCCC' ? '0.7' : '0.2'}
        d='M6 10C8.20914 10 10 8.20914 10 6C10 3.79086 8.20914 2 6 2C3.79086 2 2 3.79086 2 6C2 8.20914 3.79086 10 6 10Z'
        fill={color}
      />
      <path
        d='M6 9C7.65685 9 9 7.65685 9 6C9 4.34315 7.65685 3 6 3C4.34315 3 3 4.34315 3 6C3 7.65685 4.34315 9 6 9Z'
        fill={color}
      />
    </svg>
  );
};
