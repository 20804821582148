import './DecideOnTheFly.scss';

import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { Button, DecideOnTheFlyCircle } from '../../components';
import { useModalContext } from '../../context';
import {
  useGetCurrentUserDecideOnFlyQuery,
  useGetDecideOnFlyPackagesForUserQuery,
  useGetRandomDecideOnFlyActionMutation,
  useGetUserDecideOnFlyActionsHistoryQuery,
  useSetDecideOnFlyPackagesToUserMutation,
  useSetStatusUserDecideOnFlyActionMutation,
} from '../../graphql/generated/graphql';
import { ModalStatuses, TypeDecideOnTheFlyButtons } from '../../types/enums';

export const DecideOnTheFly = () => {
  const { data, refetch } = useGetCurrentUserDecideOnFlyQuery({
    variables: {},
    fetchPolicy: 'network-only',
  });

  const { data: history, refetch: refecthHistory } =
    useGetUserDecideOnFlyActionsHistoryQuery({ fetchPolicy: 'network-only' });

  const [animationInAction, setAnimationInAction] = useState(false);

  const isAccessToGet =
    data?.getCurrentUser?.currentUserDecideOnFlyActionData?.isAccessToGet;

  const currentUserAction =
    data?.getCurrentUser?.currentUserDecideOnFlyActionData?.currentUserAction;

  const typeCalc = () => {
    const currentTime = moment();
    const bedtimeStart = moment('23:00', 'HH:mm');
    const morningStart = moment('08:00', 'HH:mm');

    if (!currentTime.isBetween(morningStart, bedtimeStart)) {
      return TypeDecideOnTheFlyButtons.sleep;
    }

    if (isAccessToGet) {
      return TypeDecideOnTheFlyButtons.start;
    }
    if (currentUserAction?.status === 'created') {
      return TypeDecideOnTheFlyButtons.select;
    }
    if (!currentUserAction?.status) {
      return TypeDecideOnTheFlyButtons?.noData;
    }

    return TypeDecideOnTheFlyButtons.tyme;
  };
  const [typeButton, setTypeButton] = useState(TypeDecideOnTheFlyButtons?.noData);

  useEffect(() => {
    setTypeButton(typeCalc());
  }, [isAccessToGet, currentUserAction]);

  const [getRandomDecideOnFlyActionMutation] = useGetRandomDecideOnFlyActionMutation({
    onCompleted: () => {
      refetch();
      refecthHistory();
    },
  });

  const [setStatusUserDecideOnFlyActionMutation] =
    useSetStatusUserDecideOnFlyActionMutation({
      onCompleted: () => {
        refetch();
        refecthHistory();
      },
    });

  return (
    <div className='container'>
      <div className='DecideOnTheFly'>
        <DecideOnTheFlyTop
          count={history?.getUserDecideOnFlyActionsHistory?.count}
          doneCount={history?.getUserDecideOnFlyActionsHistory?.doneCount}
          notDoneCount={history?.getUserDecideOnFlyActionsHistory?.notDoneCount}
        />
        <div className='DecideOnTheFly__center'>
          <div className='DecideOnTheFly__center-heder'>
            {currentUserAction?.decideOnFlyAction?.name
              ? 'Супер! Ваше задание'
              : 'Крути колесо'}
          </div>
          <div
            className='DecideOnTheFly__center-select'
            style={{
              opacity: !animationInAction ? 1 : 0,
            }}
          >
            {currentUserAction?.decideOnFlyAction?.name ? (
              currentUserAction?.decideOnFlyAction?.name
            ) : (
              <span
                style={{
                  opacity: 0,
                }}
              >
                0
              </span>
            )}
          </div>
          <DecideOnTheFlyCircle
            typeButton={typeButton}
            getRandomDecideOnFlyActionMutation={getRandomDecideOnFlyActionMutation}
            setStatusUserDecideOnFlyActionMutation={
              setStatusUserDecideOnFlyActionMutation
            }
            setAnimationInAction={setAnimationInAction}
            endDate={currentUserAction?.endDate}
            refetch={refetch}
            text={currentUserAction?.decideOnFlyAction?.name}
            animationInAction={animationInAction}
            createdAt={currentUserAction?.createdAt}
          />
        </div>
      </div>
    </div>
  );
};

const DecideOnTheFlyTop = ({ count, doneCount, notDoneCount }: any) => {
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  const { refetch } = useGetDecideOnFlyPackagesForUserQuery({
    fetchPolicy: 'network-only',
  });

  const [setDecideOnFlyPackagesToUserMutation] = useSetDecideOnFlyPackagesToUserMutation({
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <div className='DecideOnTheFly__top'>
      <div className='DecideOnTheFly__top-left'>
        <Button
          width={240}
          height={30}
          color='#FFF'
          backgroundColor='#303030'
          onClick={() => {
            refetch().then((e) => {
              handleSetModalStatus(ModalStatuses.SELECT_PACKAGE_MODAL);
              handleChangeModalParameters([
                {
                  key: 'onCallback',
                  value: (value: any) => {
                    setDecideOnFlyPackagesToUserMutation({
                      variables: {
                        packagesId: value,
                      },
                    });
                  },
                },
                {
                  key: 'initialValues',
                  value: e?.data?.getDecideOnFlyPackagesForUser,
                },
              ]);
            });
          }}
        >
          Выбрать пакет
        </Button>
      </div>
      <div className='DecideOnTheFly__list'>
        <div className='DecideOnTheFly__list-item'>
          Пинов выпало: <span className='DecideOnTheFly__list-number'>{count}</span>
        </div>
        <div className='DecideOnTheFly__list-item'>
          Пинов выполнено:
          <span className='DecideOnTheFly__list-number'>{doneCount}</span>
        </div>
        <div className='DecideOnTheFly__list-item'>
          Пинов невыполнено:
          <span className='DecideOnTheFly__list-number'>{notDoneCount}</span>
        </div>
      </div>{' '}
      <Button
        width={240}
        height={30}
        color='#FFF'
        backgroundColor='#303030'
        margin={'0px 10px'}
        onClick={() => {
          handleSetModalStatus(ModalStatuses.DECIDE_ON_THE_FLY_LOGS);
          handleChangeModalParameters([
            {
              key: 'onCallback',
              value: (value: any) => {
                console.info(value);
              },
            },
          ]);
        }}
      >
        Мои логи
      </Button>
    </div>
  );
};

export const SelectItem = ({ e }: any) => {
  return (
    <div className='DecideOnTheFly__select'>
      <div
        className='DecideOnTheFly__select-color'
        style={{
          backgroundColor: e?.color,
        }}
      ></div>
      <div className='DecideOnTheFly__select-text'>{e?.title}</div>
    </div>
  );
};
