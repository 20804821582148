import './Chats.scss';

import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { search } from '../../assets/icons';
import { useChatContext } from '../../context';
import { addKarma } from '../../utils';
import { ChatIcon } from '../icons';
import { ChatItem } from '../menu-left/menuLeftChatItem/ChatItem';
import { MenuTitle } from '../menu-left/menuTitle/MenuTitle';

export const Chats: FC = () => {
  const { privateChatId } = useParams();

  const { chatsData, counts, setSearchUsers, searchUsers } = useChatContext();

  const activeColor = (id: number) => {
    if (id === Number(privateChatId)) {
      return { backgroundColor: '#D5EDD8' };
    } else return {};
  };

  return (
    <div className='menu__chats'>
      <MenuTitle title='Чаты' count={counts || undefined} icon={<ChatIcon />} />
      <div className='chat__items'>
        {chatsData?.getPrivateChats.rows.map((el: any) => {
          if (el) {
            return (
              <ChatItem
                key={el.id}
                id={el.id}
                image={el.image}
                login={addKarma(el)}
                onlineStatus={el.onlineStatus}
                style={activeColor(Number(el.id))}
                chats={el.chats}
              />
            );
          }
        })}
      </div>
      <div className='chat__search'>
        <img src={search} alt='search' />
        <input
          autoComplete='off'
          placeholder='Поиск чатов'
          onChange={(e) => setSearchUsers(e.currentTarget.value)}
          value={searchUsers}
        />
      </div>
    </div>
  );
};
