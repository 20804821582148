import './UserStatisticBlock.scss';

import React, { FC, useState } from 'react';

import {
  taskPaginationLimit,
  tasksSiblingPaginationCount,
} from '../../constants/constants';
import { useGetUserCharacteristicsQuery } from '../../graphql/generated/graphql';
import { UserStatisticBlockHeader } from './userStatisticBlockHeader/UserStatisticBlockHeader';
import { UserStatisticList } from './userStatisticList/UserStatisticList';
import { UserTred } from './userTred/UserTred';

type UserBlockType = {
  userData: any;
};

export const UserStatisticBlock: FC<UserBlockType> = ({ userData }) => {
  const [selectButton, setSelectButton] = useState(false);

  const {
    handleRefetch,
    listChar,
    setCurrentPage,
    setDisablePagination,
    currentPage,
    countChar,
  } = useChar({
    userData,
  });
  return (
    <div className='userStatisticBlock'>
      <UserStatisticBlockHeader userData={userData} handleRefetch={handleRefetch} />
      <ChangeButton selectButton={selectButton} setSelectButton={setSelectButton} />
      <div>
        {!selectButton ? (
          <UserTred threads={userData?.analyticThreads?.threadsLastEdited} />
        ) : (
          <UserStatisticList
            listChar={listChar}
            userData={userData}
            handleRefetch={handleRefetch}
            setCurrentPage={setCurrentPage}
            setDisablePagination={setDisablePagination}
            currentPage={currentPage}
            countChar={countChar}
            taskPaginationLimit={taskPaginationLimit}
            tasksSiblingPaginationCount={tasksSiblingPaginationCount}
          />
        )}
      </div>
    </div>
  );
};

const useChar = ({ userData }: any) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [disablePagination, setDisablePagination] = useState(true);
  const [listChar, setListChar] = useState(userData?.userCharacteristics?.rows);
  const [countChar, setCountChar] = useState(userData?.userCharacteristics?.count);

  const { refetch } = useGetUserCharacteristicsQuery({
    variables: {
      userId: userData.id,
      data: {
        limit: taskPaginationLimit,
        offset: (currentPage - 1) * taskPaginationLimit,
      },
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (e) => {
      setListChar(e?.getUserCharacteristics?.rows);
      setCountChar(e?.getUserCharacteristics?.count);
    },
    skip: disablePagination,
  });

  const handleRefetch = () => {
    setDisablePagination(false);
    refetch();
  };

  return {
    handleRefetch,
    setCurrentPage,
    listChar,
    countChar,
    setDisablePagination,
    currentPage,
    refetch,
  };
};

const ChangeButton = ({ selectButton, setSelectButton }: any) => {
  return (
    <div className='userStatisticBlock__button-wrapper'>
      <div className='userStatisticBlock__button'>
        <div
          className={`userStatisticBlock__button-item ${
            selectButton && 'userStatisticBlock__button-select'
          }`}
          onClick={() => {
            setSelectButton(!selectButton);
          }}
        >
          Заметки
        </div>
      </div>
      <div className='userStatisticBlock__button'>
        <div
          className={`userStatisticBlock__button-item ${
            !selectButton && 'userStatisticBlock__button-select'
          }`}
          onClick={() => {
            setSelectButton(!selectButton);
          }}
        >
          Треды
        </div>
      </div>
    </div>
  );
};
