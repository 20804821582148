import './Switch.scss';

import React from 'react';

export const Switch = ({ value, handleChange }: any) => {
  return (
    <label className='switch2'>
      <input
        type='checkbox'
        value={value}
        checked={value}
        onChange={() => {
          handleChange(!value);
        }}
      />
      <span className='slider round'></span>
    </label>
  );
};
