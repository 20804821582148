import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const CancelIcon: FC<CancelIconType> = ({
  color = '#303030',
  onClick,
  width = 8,
  height = 8,
  className = 'cancel-icon',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M12 4.5H2.86125L5.5515 1.8105L4.5 0.75L0 5.25L4.5 9.75L5.5515 8.68875L2.8635 6H12C13.1935 6 14.3381 6.47411 15.182 7.31802C16.0259 8.16193 16.5 9.30653 16.5 10.5C16.5 11.6935 16.0259 12.8381 15.182 13.682C14.3381 14.5259 13.1935 15 12 15H6V16.5H12C13.5913 16.5 15.1174 15.8679 16.2426 14.7426C17.3679 13.6174 18 12.0913 18 10.5C18 8.9087 17.3679 7.38258 16.2426 6.25736C15.1174 5.13214 13.5913 4.5 12 4.5Z'
        fill={color}
      />
    </svg>
  );
};

type CancelIconType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
>;
