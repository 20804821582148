import React from 'react';

export const HeaderCalendarIcons = ({ color = 'white', className, onClick }: any) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M17.5 16.6666V4.99996C17.5 4.08079 16.7525 3.33329 15.8333 3.33329H14.1667V1.66663H12.5V3.33329H7.5V1.66663H5.83333V3.33329H4.16667C3.2475 3.33329 2.5 4.08079 2.5 4.99996V16.6666C2.5 17.5858 3.2475 18.3333 4.16667 18.3333H15.8333C16.7525 18.3333 17.5 17.5858 17.5 16.6666ZM7.5 15H5.83333V13.3333H7.5V15ZM7.5 11.6666H5.83333V9.99996H7.5V11.6666ZM10.8333 15H9.16667V13.3333H10.8333V15ZM10.8333 11.6666H9.16667V9.99996H10.8333V11.6666ZM14.1667 15H12.5V13.3333H14.1667V15ZM14.1667 11.6666H12.5V9.99996H14.1667V11.6666ZM15.8333 7.49996H4.16667V5.83329H15.8333V7.49996Z'
        fill={color}
      />
    </svg>
  );
};
