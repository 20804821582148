import { gql } from '@apollo/client';

export const GET_SUBTASK = gql`
  query getSubtask($id: Int!) {
    getSubtask(id: $id) {
      id
      createdAt
      name
      number
      code
      startDate
      closeDate
      endDate
      task {
        id
        code
        startDate
        closeDate
        endDate
        project {
          id
        }
        maintainer {
          id
          login
          image
          isAnalytics
          karmaStatistics {
            karma
          }
        }
      }
      status {
        id
        color
      }
      author {
        id
        login
        karmaStatistics {
          karma
        }
      }
      maintainer {
        id
        login
        image
        isAnalytics
        karmaStatistics {
          karma
        }
      }
      tags {
        count
        rows {
          id
          name
          color
          textColor
          createdAt
          updatedAt
        }
      }
      poligonObject {
        id
      }
    }
  }
`;
