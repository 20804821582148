import './CenterAlignTextIcon.scss';

import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const CenterAlignTextIcon: FC<CenterAlignTextIconType> = ({
  width = '20px',
  height = '20px',
  className = 'center-align-text-icon',
  color = '#FFFFFF',
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path d='M3.16667 11H14.8333H3.16667Z' fill={color} />
      <path
        d='M1.5 1H16.5M4.83333 6H13.1667M3.16667 11H14.8333'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='square'
      />
    </svg>
  );
};

type CenterAlignTextIconType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
>;
