import React from 'react';

type UpdateIconsType = {
  className?: string;
};

export const UpdateIcons = ({ className }: UpdateIconsType) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M17.3289 15.0671C16.8435 14.625 16.4185 14.1181 16.0654 13.5602C15.68 12.79 15.4489 11.9489 15.3859 11.0863V8.54559C15.3892 7.19068 14.9082 5.88117 14.0333 4.86308C13.1583 3.84499 11.9496 3.18841 10.6342 3.0167V2.35324C10.6342 2.17114 10.5634 1.99649 10.4374 1.86773C10.3114 1.73897 10.1405 1.66663 9.96225 1.66663C9.78403 1.66663 9.61311 1.73897 9.48709 1.86773C9.36107 1.99649 9.29027 2.17114 9.29027 2.35324V3.02699C7.98671 3.21108 6.7926 3.87163 5.92913 4.88629C5.06565 5.90096 4.59131 7.20099 4.59396 8.54559V11.0863C4.53092 11.9489 4.29989 12.79 3.91443 13.5602C3.56756 14.1168 3.14943 14.6236 2.67114 15.0671C2.61745 15.1153 2.57442 15.1746 2.54491 15.2411C2.5154 15.3076 2.50009 15.3798 2.5 15.4528V16.1523C2.5 16.2887 2.55303 16.4195 2.64743 16.516C2.74183 16.6124 2.86986 16.6666 3.00336 16.6666H16.9966C17.1301 16.6666 17.2582 16.6124 17.3526 16.516C17.447 16.4195 17.5 16.2887 17.5 16.1523V15.4528C17.4999 15.3798 17.4846 15.3076 17.4551 15.2411C17.4256 15.1746 17.3826 15.1153 17.3289 15.0671ZM3.54698 15.638C4.01531 15.1757 4.42765 14.6577 4.77517 14.095C5.26071 13.1649 5.54401 12.1386 5.60571 11.0863V8.54559C5.58574 7.94283 5.68469 7.34213 5.89667 6.77926C6.10864 6.21639 6.4293 5.70286 6.83955 5.26925C7.24981 4.83564 7.74126 4.49082 8.28466 4.25531C8.82805 4.01981 9.41226 3.89845 10.0025 3.89845C10.5928 3.89845 11.177 4.01981 11.7204 4.25531C12.2638 4.49082 12.7552 4.83564 13.1655 5.26925C13.5757 5.70286 13.8964 6.21639 14.1084 6.77926C14.3203 7.34213 14.4193 7.94283 14.3993 8.54559V11.0863C14.461 12.1386 14.7443 13.1649 15.2299 14.095C15.5774 14.6577 15.9897 15.1757 16.4581 15.638H3.54698Z'
        fill='#303030'
      />
      <path
        d='M10.0316 18.3332C10.4308 18.3279 10.8139 18.2414 11.1131 18.0889C11.4123 17.9364 11.6084 17.7278 11.6666 17.5H8.33325C8.39312 17.734 8.59835 17.9475 8.91076 18.1008C9.22316 18.254 9.62148 18.3367 10.0316 18.3332Z'
        fill='#303030'
      />
    </svg>
  );
};
