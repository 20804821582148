import './HoverMenuTreads.scss';

import React from 'react';

import { ROLES } from '../../../constants/constants';
import { useAuthContext, useModalContext } from '../../../context';
import { ForumThread } from '../../../graphql/generated/graphql';
import { ModalStatuses } from '../../../types/enums';
import {
  CloseTreadIcons,
  OpenTreadIcons,
  TreadsDeleteICons,
  TreadsEditICons,
  TreadsPinIcons,
  UnPined,
} from '../../icons';

type HoverMenuTreadsType = {
  updateForumThreadMutation: any;
  removeForumThreadMutation: any;
  tread: ForumThread;
  setHoverMenuAction: (value: boolean) => void;
};

export const HoverMenuTreads = ({
  updateForumThreadMutation,
  tread,
  removeForumThreadMutation,
  setHoverMenuAction,
}: HoverMenuTreadsType) => {
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();
  const { currentUserData } = useAuthContext();

  return (
    <div
      className='HoverMenuTreads'
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className='HoverMenuTreads__item'
        onClick={(e) => {
          e.stopPropagation();
          setHoverMenuAction(false);
          handleSetModalStatus(ModalStatuses.FORUM_TREAD);
          handleChangeModalParameters([
            {
              key: 'title',
              value: 'Редактировать тему',
              showTemplate: false,
            },
            {
              key: 'buttonText',
              value: 'Сохранить',
            },
            {
              key: 'initialValues',
              value: {
                description: tread?.description,
                name: tread?.name,
                image: tread?.imagePath,
                isClosed: tread?.isClosed,
                avatar: tread?.authorAvatarPath,
              },
            },
            {
              key: 'onCallback',
              value: (value: any) => {
                const authorAvatar = () => {
                  if (!value?.avatar) return undefined;
                  if (value?.avatar === false) return null;
                  if (typeof value?.avatar !== 'string') return value?.avatar;
                  return undefined;
                };
                const authorAvatarPath = () => {
                  if (typeof value?.avatar === 'string') return value?.avatar;
                  if (value?.avatar === false) return null;
                  return undefined;
                };

                updateForumThreadMutation({
                  variables: {
                    data: {
                      description: value?.description,
                      name: value?.name,
                      image: value?.image,
                      viewType: value?.viewType,
                      id: tread.id,
                      isClosed: value?.isClosed,
                      authorAvatar: authorAvatar(),
                      authorAvatarPath: authorAvatarPath(),
                    },
                  },
                });
              },
            },
          ]);
        }}
      >
        <TreadsEditICons /> Редактировать
      </div>
      {currentUserData?.role === ROLES.ADMIN && (
        <div
          className='HoverMenuTreads__item'
          onClick={(e) => {
            e.stopPropagation();
            setHoverMenuAction(false);
            handleSetModalStatus(ModalStatuses.ACTION_TREAD);
            handleChangeModalParameters([
              {
                key: 'onCallback',
                value: () => {
                  updateForumThreadMutation({
                    variables: {
                      data: {
                        id: tread.id,
                        isAttached: !tread.isAttached,
                      },
                    },
                  });
                },
              },
              {
                key: 'textData',
                value: {
                  title: `${tread.isAttached ? 'Открепить' : 'Закрепить'} раздел`,
                  iconsDelete: false,
                  buttonText: tread.isAttached ? 'Открепить' : 'Закрепить',
                },
              },
            ]);
          }}
        >
          {tread?.isAttached ? <UnPined /> : <TreadsPinIcons />}
          {tread?.isAttached ? 'Открепить' : 'Закрепить'}
        </div>
      )}
      {currentUserData?.role === ROLES.ADMIN && (
        <div
          className='HoverMenuTreads__item'
          onClick={(e) => {
            e.stopPropagation();
            setHoverMenuAction(false);
            handleSetModalStatus(ModalStatuses.ACTION_TREAD);
            handleChangeModalParameters([
              {
                key: 'onCallback',
                value: () => {
                  updateForumThreadMutation({
                    variables: {
                      data: {
                        id: tread.id,
                        isClosed: !tread.isClosed,
                      },
                    },
                  });
                },
              },
              {
                key: 'textData',
                value: {
                  title: `${tread.isClosed ? 'Открыть' : 'Закрыть'} раздел`,
                  iconsDelete: false,
                  buttonText: tread.isClosed ? 'Открыть' : 'Закрыть',
                },
              },
            ]);
          }}
        >
          {tread?.isClosed ? <OpenTreadIcons /> : <CloseTreadIcons />}
          {tread?.isClosed ? 'Открыть' : 'Закрыть'}
        </div>
      )}

      <div className='HoverMenuTreads__line'></div>
      <div
        className='HoverMenuTreads__item'
        onClick={(e) => {
          e.stopPropagation();
          setHoverMenuAction(false);
          handleSetModalStatus(ModalStatuses.ACTION_TREAD);
          handleChangeModalParameters([
            {
              key: 'onCallback',
              value: () => {
                removeForumThreadMutation({
                  variables: {
                    id: tread.id,
                  },
                });
              },
            },
            {
              key: 'textData',
              value: {
                title: 'Удалить раздел',
                iconsDelete: true,
                buttonText: 'Удалить',
              },
            },
          ]);
        }}
      >
        <TreadsDeleteICons /> Удалить
      </div>
    </div>
  );
};
