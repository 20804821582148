import './DecideOnTheFlyReport.scss';

import React, { useState } from 'react';

import { Button } from '../../../button/Button';

export const DecideOnTheFlyReport = ({ onClose, modalParameters }: any) => {
  const [value, setValue] = useState('');

  return (
    <div className='DecideOnTheFlyReport'>
      <div className='DecideOnTheFlyReport__head'>Укажите причину?</div>

      <textarea
        className='DecideOnTheFlyReport__textarea'
        value={value}
        onChange={(e) => {
          setValue(e?.target?.value);
        }}
        placeholder={'Сообщение'}
      />
      <Button
        color='#FFF'
        backgroundColor='#149939'
        width={'100%'}
        onClick={() => {
          modalParameters?.onCallback(value);
          onClose();
        }}
      >
        Указать
      </Button>
    </div>
  );
};
