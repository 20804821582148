import './ButtonLinksWrap.scss';

import React, { ReactNode } from 'react';

type ButtonLinksWrapPropType = {
  children?: ReactNode;
};

export const ButtonLinksWrap = ({ children }: ButtonLinksWrapPropType) => {
  return <div className='buttonLinks__wrap'>{children}</div>;
};
