import './ScaleList.scss';

import React from 'react';

import { useModalContext } from '../../context/';
import {
  Project as ProjectType,
  useCreateScaleMapFileMutation,
} from '../../graphql/generated/graphql';
import { ModalStatuses } from '../../types/enums';
import { Button, IsExcludeNullStatusTasks } from '../index';
import { RequestHandler } from '../requestHandler/RequestHandler';
import { ScaleAccordion } from './scaleAccordion/ScaleAccordion';

export const PAGE_LIST_COLUMNS_TITLES: string[] = [
  'Проект',
  'Всего задач',
  'Название файла',
];

type ScaleListType = {
  projectsList?: Array<ProjectType>;
  refetchProjects: () => void;
  isExcludeNullStatusTasks?: number | null;
  setIsExcludeNullStatusTasks?: (value: number | null) => void;
  setIsArchived: (value: boolean) => void;
  isArchived: boolean;
  loading: boolean;
};

export const ScaleList = ({
  projectsList,
  refetchProjects,
  isExcludeNullStatusTasks,
  setIsExcludeNullStatusTasks,
  isArchived,
  setIsArchived,
  loading,
}: ScaleListType) => {
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  const [createScaleMapFileMutation] = useCreateScaleMapFileMutation({
    onCompleted: () => {
      refetchProjects();
    },
  });

  return (
    <div className='scale-list'>
      <div className='scale-list__body'>
        <div className='scale-list__title'>
          <div className='scale-list__h2-wrapper'>
            <h2>Scale map</h2>
          </div>

          <div className='selectOuter'>
            <IsExcludeNullStatusTasks
              setIsExcludeNullStatusTasks={setIsExcludeNullStatusTasks}
              isExcludeNullStatusTasks={isExcludeNullStatusTasks}
            />

            <div className='button-add__wrapper'>
              <Button
                onClick={() => {
                  handleSetModalStatus(ModalStatuses.ADD_FILE_MODAL);
                  handleChangeModalParameters([
                    {
                      key: 'onCallback',
                      value: (value: { name: string; projectId: string }) => {
                        createScaleMapFileMutation({
                          variables: {
                            input: value,
                          },
                        });
                      },
                    },
                    {
                      key: 'initialValues',
                      value: undefined,
                    },
                  ]);
                }}
                backgroundColor='#149939'
                width='200px'
                height='25px'
                text='Создать файл'
                color='white'
              />
            </div>
          </div>
        </div>
        <div className='horizontal__line'></div>
        <div className='scale-list__table'>
          <div className='scale-list__row'>
            <div className='scale-list__column scale-list__projects'>Проект</div>
            <div className='scale-list__column scale-list__task'>Всего задач</div>
            <div className='scale-list__column scale-list__name'>Название файла</div>
            <button
              onClick={() => {
                setIsArchived(!isArchived);
              }}
              className='buttonArchive'
            >
              {!isArchived ? 'Показать архив' : 'Убрать архив'}
            </button>
          </div>
          <RequestHandler loading={loading}>
            {projectsList?.map((item) => {
              return (
                <ScaleAccordion
                  key={item?.id}
                  project={item}
                  createScaleMapFileMutation={createScaleMapFileMutation}
                  isExcludeNullStatusTasks={!!isExcludeNullStatusTasks}
                />
              );
            })}
          </RequestHandler>
          {!isArchived && !loading && (
            <ScaleAccordion
              project={
                {
                  name: 'Треды',
                  scaleMapFiles: [
                    {
                      name: 'Треды',
                      statistics: { completedTasksPercent: 0 },
                    },
                  ],
                } as any
              }
              createScaleMapFileMutation={createScaleMapFileMutation}
              isExcludeNullStatusTasks={false}
              isTreads={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};
