import './infoModal.scss';

import { Form, Formik, FormikValues } from 'formik';
import React, { FC } from 'react';

import { ModalType } from '../../../../types/types';
import { AppTextarea } from '../../../appTextarea/AppTextarea';
import { Button } from '../../../button/Button';

export const InfoModal: FC<
  Pick<
    ModalType,
    | 'isOpen'
    | 'onSubmit'
    | 'initialValues'
    | 'onClose'
    | 'labelText'
    | 'buttonText'
    | 'inputName'
  >
> = ({ onSubmit, initialValues, labelText, inputName, buttonText }) => {
  const handleSubmit = (values: FormikValues) => {
    onSubmit(values);
  };

  return (
    <Formik initialValues={initialValues || { info: '' }} onSubmit={handleSubmit}>
      <Form>
        <div className='info-modal'>
          <div className='info-modal__title'>
            <AppTextarea
              name={inputName || 'info'}
              label={labelText}
              placeholder='Введите описание блока'
              style={{
                maxHeight: '300px',
              }}
            />
          </div>
          <div className='info-modal__buttons'>
            <Button type='submit' text={buttonText} width='100%' height='25px' />
          </div>
        </div>
      </Form>
    </Formik>
  );
};
