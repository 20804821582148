export const filterArrayDiapason = (selectHours: (number | null | undefined)[]) => {
  interface HourRange {
    hour: number;
    range: string;
  }

  const inputArray: [number] = JSON.parse(JSON.stringify(selectHours));

  // Sort input array
  inputArray.sort((a, b) => a - b);

  // Let's transform the input array
  const outputArray: number[][] = [];
  let tempArray: number[] = [];
  for (let i = 0; i < inputArray.length; i++) {
    if (tempArray.length === 0 || inputArray[i] === tempArray[tempArray.length - 1] + 1) {
      tempArray.push(inputArray[i]);
    } else {
      outputArray.push(tempArray);
      tempArray = [inputArray[i]];
    }
  }
  if (tempArray.length > 0) {
    outputArray.push(tempArray);
  }

  // Get an array of objects from the middle of each subarray
  const middleNumbersArray: HourRange[] = outputArray.map((subArray) => ({
    hour: subArray[Math.floor(subArray.length / 2)],
    range: getRangeString(subArray),
  }));

  function getRangeString(subArray: number[]): string {
    if (subArray.length === 1) {
      return formatTime(subArray[0] + 1);
    } else {
      return `${formatTime(subArray[0] + 1)} - ${formatTime(
        subArray[subArray.length - 1] + 1,
      )}`;
    }
  }

  function formatTime(hour: number): string {
    return `${hour.toString().padStart(2, '0')}:00`;
  }

  return middleNumbersArray;
};
