import './ArrowLeftIcon.scss';

import React, { FC } from 'react';

export const ArrowLeftIcon: FC<ArrowLeftIconType> = ({
  onClick,
  width = '24px',
  height = '24px',
  color = '#ffffff',
  className = 'arrow-left',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18 12C18 12.1989 17.921 12.3896 17.7803 12.5303C17.6397 12.671 17.4489 12.75 17.25 12.75H8.5605L11.781 15.969C11.8507 16.0387 11.906 16.1215 11.9438 16.2126C11.9815 16.3037 12.0009 16.4014 12.0009 16.5C12.0009 16.5986 11.9815 16.6962 11.9438 16.7873C11.906 16.8785 11.8507 16.9612 11.781 17.031C11.7113 17.1007 11.6285 17.156 11.5374 17.1938C11.4463 17.2315 11.3486 17.2509 11.25 17.2509C11.1514 17.2509 11.0537 17.2315 10.9626 17.1938C10.8715 17.156 10.7887 17.1007 10.719 17.031L6.219 12.531C6.14915 12.4613 6.09374 12.3785 6.05593 12.2874C6.01812 12.1963 5.99866 12.0986 5.99866 12C5.99866 11.9013 6.01812 11.8036 6.05593 11.7125C6.09374 11.6214 6.14915 11.5386 6.219 11.469L10.719 6.96897C10.8598 6.82814 11.0508 6.74902 11.25 6.74902C11.4492 6.74902 11.6402 6.82814 11.781 6.96897C11.9218 7.1098 12.0009 7.30081 12.0009 7.49997C12.0009 7.69913 11.9218 7.89014 11.781 8.03097L8.5605 11.25H17.25C17.4489 11.25 17.6397 11.329 17.7803 11.4696C17.921 11.6103 18 11.8011 18 12Z'
        fill={color}
      />
    </svg>
  );
};

export type ArrowLeftIconType = {
  onClick?: (e: any) => void;
  width?: string;
  height?: string;
  color?: string;
  className?: string;
};
