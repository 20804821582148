import './StorageItem.scss';

import React from 'react';
import { Link } from 'react-router-dom';

import locImg from '../../../assets/locImg.png';
import noImg from '../../../assets/noImg.jpg';
import synodoc from '../../../assets/synodoc.png';
import synoDrive_16 from '../../../assets/synoDrive_16.png';
import synofolder from '../../../assets/synofolder.png';
import synosheet from '../../../assets/synosheet.png';
import synoslide from '../../../assets/synoslide.png';
import synovideo from '../../../assets/synovideo.png';
import { BASE_URL } from '../../../constants/constants';
import { useTrackStorageEventClickedMutation } from '../../../graphql/generated/graphql';
import { FileTypeStorage } from '../../../types/enums';
import { textSlice } from '../../../utils';
import {
  FigmaIcons,
  StorageExelWord,
  StorageFileWord,
  StorageImgWord,
  StoragePdfWord,
} from '../../icons';

export const StorageItemTred = ({ file }: { file: any }) => {
  const [trackStorageEventClickedMutation] = useTrackStorageEventClickedMutation({});
  const allImg =
    file.mimeType === FileTypeStorage.jpeg ||
    file.mimeType === FileTypeStorage.gif ||
    file.mimeType === FileTypeStorage.png ||
    file.mimeType === FileTypeStorage.svg ||
    file.mimeType === FileTypeStorage.webp ||
    file.mimeType === FileTypeStorage.oimage ||
    file.mimeType === FileTypeStorage.sharingFileImage;

  const createImgUrl = () => {
    if (file?.isAccess === false) {
      return locImg;
    }
    if (file.previewUrl) {
      return BASE_URL + file.previewUrl;
    }
    if (file?.thumbnailPath) {
      return BASE_URL + file.thumbnailPath;
    }

    if (allImg && file.path) {
      return BASE_URL + file.path;
    }
    return noImg;
  };
  const createUrlFile = () => {
    if (file.href) return file.href;
    return BASE_URL + file.path;
  };

  const crateLinkTask = () => {
    return `/tread/${file?.thread?.id}`;
  };
  return (
    <div
      className='storage-item'
      onClick={() => {
        trackStorageEventClickedMutation({
          variables: {
            fileId: file?.__typename === 'File' ? file?.id : undefined,
            storageItemId: file?.__typename === 'StorageItem' ? file?.id : undefined,
            threadId: file?.thread?.id,
          },
        });
      }}
    >
      <a
        href={createUrlFile() as string}
        target='_blank'
        rel='noreferrer'
        className='storage-item-imageLink'
      >
        {file.mimeType === 'figma' ? (
          <FigmaIcons width='248' height='212' />
        ) : (
          <img src={createImgUrl()} alt='avatar' className='storage-item__image' />
        )}
        <div className='storage-item__text-wrapper'>
          <div className='storage-item__file'>
            <ShowImage mimeType={file.mimeType} />
            {(file.mimeType === FileTypeStorage.doc ||
              file.mimeType === FileTypeStorage.docx) && (
              <StorageFileWord color='#4E89E1' />
            )}
            {(file.mimeType === FileTypeStorage.xls ||
              file.mimeType === FileTypeStorage.xlsx) && (
              <StorageExelWord color='#149939' />
            )}
            {file.mimeType === FileTypeStorage.pdf && <StoragePdfWord color='#E80025' />}
            {allImg && <StorageImgWord color='#E5580A' />}
            <span>
              {textSlice({ text: file?.name ? file?.name : file?.fileName, count: 25 })}
            </span>
          </div>
        </div>
      </a>
      <Link to={crateLinkTask()}>
        <div className='storage-item__text-wrapper'>
          <div className='storage-item__task-name'>
            {textSlice({
              text: `#${file?.thread?.scaleMapTask?.name} `,
              count: 55,
            })}
          </div>
        </div>
      </Link>
    </div>
  );
};

export const ShowImage = ({ mimeType }: any) => {
  return (
    <>
      {mimeType === FileTypeStorage.odoc && (
        <img src={synodoc} alt='avatar' className='storage-item__imageIcons' />
      )}
      {(mimeType === FileTypeStorage.ovideo ||
        mimeType === FileTypeStorage.sharingFileVideo) && (
        <img
          src={synovideo}
          style={{
            width: '24px',
          }}
          alt='avatar'
          className='storage-item__imageIcons'
        />
      )}
      {mimeType === FileTypeStorage.ofolder && (
        <img
          src={synofolder}
          style={{
            width: '24px',
          }}
          alt='avatar'
          className='storage-item__imageIcons'
        />
      )}{' '}
      {mimeType === FileTypeStorage.sharingFile && (
        <img src={synoDrive_16} alt='avatar' className='storage-item__imageIcons' />
      )}
      {mimeType === FileTypeStorage.sharingFileImage ||
        (mimeType === FileTypeStorage.osheet && (
          <img src={synosheet} alt='avatar' className='storage-item__imageIcons' />
        ))}
      {mimeType === FileTypeStorage.slides && (
        <img src={synoslide} alt='avatar' className='storage-item__imageIcons' />
      )}
    </>
  );
};
