import React from 'react';

import { ModalController } from './ModalController';

export const ModalLayout = ({ children }: any) => {
  return (
    <div>
      {children}
      <ModalController />
    </div>
  );
};
