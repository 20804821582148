import './LinkIcon.scss';

import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const LinkIcon: FC<LinkIconType> = ({
  width = '16px',
  height = '16px',
  color = '#303030',
  className = 'link-icon',
  onClick,
  isBordered = false,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      style={isBordered ? { borderLeft: '1px solid #AFAFAF' } : {}}
    >
      <path
        d='M9.21094 10.9961C9.18157 10.967 9.14191 10.9507 9.10059 10.9507C9.05926 10.9507 9.0196 10.967 8.99024 10.9961L6.7207 13.2656C5.66992 14.3164 3.89648 14.4277 2.73633 13.2656C1.57422 12.1035 1.68555 10.332 2.73633 9.28125L5.00586 7.01172C5.06641 6.95117 5.06641 6.85156 5.00586 6.79102L4.22852 6.01367C4.19915 5.98459 4.15949 5.96828 4.11816 5.96828C4.07684 5.96828 4.03718 5.98459 4.00781 6.01367L1.73828 8.2832C0.0859375 9.93555 0.0859375 12.6094 1.73828 14.2598C3.39062 15.9102 6.06445 15.9121 7.71484 14.2598L9.98438 11.9902C10.0449 11.9297 10.0449 11.8301 9.98438 11.7695L9.21094 10.9961ZM14.2617 1.73828C12.6094 0.0859374 9.93555 0.0859374 8.28516 1.73828L6.01367 4.00781C5.98459 4.03718 5.96828 4.07684 5.96828 4.11816C5.96828 4.15949 5.98459 4.19915 6.01367 4.22852L6.78906 5.00391C6.84961 5.06445 6.94922 5.06445 7.00977 5.00391L9.2793 2.73438C10.3301 1.68359 12.1035 1.57227 13.2637 2.73438C14.4258 3.89648 14.3145 5.66797 13.2637 6.71875L10.9941 8.98828C10.9651 9.01765 10.9487 9.05731 10.9487 9.09863C10.9487 9.13996 10.9651 9.17962 10.9941 9.20899L11.7715 9.98633C11.832 10.0469 11.9316 10.0469 11.9922 9.98633L14.2617 7.7168C15.9121 6.06445 15.9121 3.39063 14.2617 1.73828ZM9.91602 5.27148C9.88665 5.24241 9.84699 5.22609 9.80567 5.22609C9.76434 5.22609 9.72468 5.24241 9.69531 5.27148L5.27148 9.69336C5.24241 9.72273 5.22609 9.76238 5.22609 9.80371C5.22609 9.84504 5.24241 9.8847 5.27148 9.91406L6.04492 10.6875C6.10547 10.748 6.20508 10.748 6.26563 10.6875L10.6875 6.26563C10.748 6.20508 10.748 6.10547 10.6875 6.04492L9.91602 5.27148Z'
        fill={color}
      />
    </svg>
  );
};

type LinkIconType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
> & { isBordered?: boolean };
