export const REQUIRE_MESSAGE = 'Обязательное поле';
export const INCORRECT_LOGIN = 'Некорректный логин';
export const INCORRECT_NAME = 'Некорректное имя';
export const INCORRECT_PROJECT_NAME = 'Некорректное название проекта';
export const INCORRECT_TASK_NAME = 'Некорректное название задачи';
export const INCORRECT_TASK_PRICE = 'Некорректная цена реализации';
export const INCORRECT_PASSWORD = 'Некорректный пароль';
export const INCORRECT_MIN_LENGTH_1 = 'Минимальное количество символов 1';
export const INCORRECT_MIN_LENGTH_3 = 'Минимальное количество символов 3';
export const INCORRECT_MAX_LENGTH_16 = 'Максимальное количество символов 16';
export const INCORRECT_MAX_LENGTH_20 = 'Максимальная длина 20 символов';
export const INCORRECT_MAX_LENGTH_30 = 'Максимальная длина 30 символов';
export const INCORRECT_MAX_LENGTH_40 = 'Максимальная длина 40 символов';
export const INCORRECT_MAX_LENGTH_50 = 'Максимальная длина 50 символов';
export const INCORRECT_MAX_LENGTH_100 = 'Максимальная длина 100 символов';
export const INCORRECT_MAX_TAG_3 = 'Максимальное количество тегов 3';
export const TITLE_TOO_LONG = 'Длина заголовка не должна превышать 255 символов';
export const POLYGON_NOT_FOUND = 'Полигон не существует';
export const POLYGON_TREE_ERROR = 'Нельзя указывать ссылку на родителя, и начало ветки';
export const SELECT_COLOR = 'Выберите цвет';
