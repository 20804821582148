import './CustomDatePicker.scss';

import { ru } from 'date-fns/locale';
import { useField } from 'formik';
import React from 'react';
import DatePicker from 'react-datepicker';

import { calendar } from '../../assets/icons';
import { CrossIcon } from '../icons';

type CustomDatePickerPropsType = {
  name: string;
  label?: string;
  endDateSet?: boolean;
  startDateSet?: boolean;
};

export const CustomDatePicker = ({
  name,
  label,
  endDateSet,
  startDateSet,
}: CustomDatePickerPropsType) => {
  const [field, , helpers] = useField(name);
  const { setValue } = helpers;

  function lastDayOfMonth(date: any) {
    const newDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    const lastDay = new Date(newDate.getTime() - 86400000);
    const day = lastDay.getDate().toString().padStart(2, '0');
    const month = (lastDay.getMonth() + 1).toString().padStart(2, '0');
    const year = lastDay.getFullYear().toString().slice(0);
    return { lastDay: `${day}.${month}.${year}`, lastDayValue: lastDay };
  }

  const { lastDay, lastDayValue } = lastDayOfMonth(new Date());

  function firstDayOfMonth(date: any) {
    const newDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const day = newDate.getDate().toString().padStart(2, '0');
    const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
    const year = newDate.getFullYear().toString().slice(0);
    return { firstDay: `${day}.${month}.${year}`, firstDayValue: newDate };
  }
  const { firstDay, firstDayValue } = firstDayOfMonth(new Date());

  return (
    <div className='customDatePicker'>
      <div className='customDatePicker__wrap'>
        <p className='customDatePicker__labelText'>{label}</p>
        {endDateSet && (
          <div
            className='customDatePicker__setMonth'
            onClick={() => {
              setValue(lastDayValue);
            }}
          >
            Установить {lastDay}
          </div>
        )}
        {startDateSet && (
          <div
            className='customDatePicker__setMonth'
            onClick={() => {
              setValue(firstDayValue);
            }}
          >
            Установить {firstDay}
          </div>
        )}
        <div className='customDatePicker__inputWrap'>
          <div className='customDatePicker__input'>
            <DatePicker
              id={name}
              selected={field.value}
              onChange={(date) => {
                setValue(date);
              }}
              locale={ru}
              dateFormat='dd.MM.yyyy'
              autoComplete='off'
              withPortal
            />
            <label htmlFor={name} className='customDatePicker__labelIcon'>
              <img src={calendar} alt='calendar' />
            </label>
          </div>
          {field?.value && (
            <div className='customDatePicker__closeIcon' onClick={() => setValue(null)}>
              <CrossIcon color='#AFAFAF' width='11px' height='10px' />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
