import { gql } from '@apollo/client';

export const GET_CHATS = gql`
  query getChats($data: GetChatsInput!) {
    getChats(data: $data) {
      rows {
        id
        task {
          id
          name
        }
        subtask {
          id
          name
          task {
            id
            name
          }
        }
        name
        unreadMessagesCount
      }
      count
    }
  }
`;
