import './ImageLoad.scss';

import React from 'react';

import empty from '../../assets/empty.jpg';
import { useLoadFileCtrlV } from '../../hooks';
import { getUserAvatar } from '../../utils';

type ImageLoadType = {
  margin: string;
  setFieldValue: any;
  image: any;
  focused: boolean;
  errors: any;
};

export const ImageLoad = ({
  margin,
  setFieldValue,
  image,
  focused,
  errors,
}: ImageLoadType) => {
  let objectURL = '';

  useLoadFileCtrlV({
    callback: (e: any) => {
      setFieldValue('image', e);
    },
    focused: focused,
  });
  if (typeof image !== 'string') {
    objectURL = image && URL?.createObjectURL(image);
  } else {
    objectURL = getUserAvatar(image);
  }
  return (
    <div>
      <label>
        <img
          className={`ImageLoad__image ${margin}`}
          src={objectURL ? objectURL : empty}
        />
        <input
          name='file'
          type='file'
          onChange={(e) => {
            setFieldValue('image', e?.currentTarget?.files?.[0]);
          }}
          className='add-polygon-block-modal__file'
        />
        <div className='ImageLoad__errors'>
          {errors?.image && 'Используйте jpeg,png,svg'}
        </div>
      </label>
    </div>
  );
};
