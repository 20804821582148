import { ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';

import * as GENERATED from '../../graphql/generated/graphql';
import { GetSubtaskDocument, GetSubtaskQuery } from '../../graphql/generated/graphql';
import { getErrorText } from '../../utils';

export const useStartSubtaskMutation = (handleClosePanel: () => void) => {
  return GENERATED.useStartSubtaskMutation({
    update(cache, { data }) {
      if (!data?.startSubtask) return null;

      const subtask: GetSubtaskQuery | null = cache.readQuery({
        query: GetSubtaskDocument,
        variables: {
          id: data.startSubtask.id,
        },
      });

      if (subtask) {
        cache.writeQuery({
          query: GetSubtaskDocument,
          variables: {
            id: data.startSubtask.id,
          },
          data: {
            ...subtask,
            getSubtask: {
              ...subtask.getSubtask,
              startDate: data.startSubtask.startDate,
            },
          },
        });
      }
    },
    onCompleted: (res) => {
      if (res.startSubtask) {
        handleClosePanel();
        toast.success('Задача начата!');
      }
    },
    onError(error: ApolloError) {
      if (error?.message) {
        handleClosePanel();
        toast.error(getErrorText(error.message));
      }
    },
  });
};
