import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { taskPaginationLimit } from '../../constants/constants';
import * as GENERATED from '../../graphql/generated/graphql';
import {
  GetArchivedProjectsDocument,
  GetArchivedProjectsQuery,
  GetProjectsDocument,
  GetProjectsQuery,
  SortingField,
  SortingOrders,
} from '../../graphql/generated/graphql';

export const useDeleteProjectMutation = ({
  projectId,
  refech,
}: {
  projectId?: number | null;
  refech: () => void;
}) => {
  const location = useLocation();

  const getProjectsVariables = {
    data: {
      limit: 100,
      sort: { field: SortingField.Number, type: SortingOrders.Asc },
    },
    tasksData: {
      limit: taskPaginationLimit,
      isTaskStorage: false,
    },
  };

  const isArchivedPage = location.pathname.includes('archive');

  return GENERATED.useDeleteProjectMutation({
    update(cache, { data }) {
      if (!data?.deleteProject) return null;

      if (!isArchivedPage) {
        const projects: GetProjectsQuery | null = cache.readQuery({
          query: GetProjectsDocument,
          variables: getProjectsVariables,
        });

        const newData = projects?.getProjects.rows.filter(
          (project) => project?.id !== Number(projectId),
        );

        if (projects) {
          cache.writeQuery({
            query: GetProjectsDocument,
            variables: getProjectsVariables,
            data: {
              ...projects,
              getProjects: {
                ...projects.getProjects,
                count: projects.getProjects.count - 1,
                rows: newData,
              },
            },
          });
        }
      } else {
        const archiveProjects: GetArchivedProjectsQuery | null = cache.readQuery({
          query: GetArchivedProjectsDocument,
          variables: { data: { limit: 100 } },
        });

        const newArchiveProjectsData = archiveProjects?.getArchivedProjects.rows.filter(
          (archiveProject) => archiveProject?.id !== String(projectId),
        );

        if (archiveProjects) {
          cache.writeQuery({
            query: GetArchivedProjectsDocument,
            variables: { data: { limit: 100 } },
            data: {
              ...archiveProjects,
              getArchivedProjects: {
                ...archiveProjects.getArchivedProjects,
                count: archiveProjects.getArchivedProjects.count - 1,
                rows: newArchiveProjectsData,
              },
            },
          });
        }
      }
    },
    onCompleted: (response) => {
      if (response?.deleteProject) {
        toast.success('Проект успешно удален!');
        refech();
      }
    },
  });
};
