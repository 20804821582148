export enum AddArrowType {
  NO = 'No',
  Add = 'Add',
  Down = 'DOWN',
}

export enum ModalStatuses {
  DELETE_ACCOUNT = 'delete-account',
  FILE_FON_AND_TAGS_MODAL = 'file-fon-and-tags-modal',
  CHANGE_COL_NAME_MODAL = 'change-col-name-modal',
  CONFIRM_DELETION_MODAL = 'confirm_deletion_modal',
  SELECT_PACKAGE_MODAL = 'select_package_modal',
  MOVE_SCALE_TASK_MODAL = 'move-scale-task-modal',
  ALL_TRED_IMAGE_MODAL = 'all-tred-image-modal',
  COLOR_STATUS_ITEM_MODAL = 'color-status-item-modal',
  PULSOMETER_MODAL = 'pulsometer_modal',
  PLAN_CHRONO_MODAL = 'plan_chrono_modal',
  TRED_OPEN_IMAGE_MODAL = 'tred-open-image-modal',
  EDIT_SINGLE_TAG_MODAL = 'edit-single-tag-modal',
  NEW_RESET_PASSWORD_MODAL = 'new-reset-password-modal',
  CHAT_DELETE_MODAL = 'chat-delete-modal',
  ACCES_STORAGE_MODAL = 'acces-storage-modal',
  DECIDE_ON_THE_FLY_DELETE_TASK = 'decide-on-the-fly-delete-task',
  DECIDE_ON_THE_FLY_TASK = 'decide-on-the-fly-task',
  DECIDE_ON_THE_FLY_LOGS = 'decide-on-the-fly-logs',
  DECIDE_ON_THE_FLY_REPORT = 'decide-on-the-fly-report',
  TREAD_SELECT_FON_MODAL = 'tread-select-fon-modal',
  TREADS_FONS_MODAL = 'treads-fons-modal',
  CIRCLE_USER_MODAL = 'circle-user-modal',
  CIRCLE_ADD_MEET_MODAL = 'circle-add-meet-modal',
  CIRCLE_CREATE_MODAL = 'circle-create-modal',
  CHRONOMETER_REPORT = 'chronometer-report',
  CHRONOMETER_NOTE = 'chronometer-note',
  EDIT_TITLE_MODAL_LOCAL = 'edit-title-modal-local',
  SCALE_TO_TREAD_MODAL = 'scale-to-tread-modal',
  REPORT_CHRONOGRAPH_MODAL = 'report-chronograph-modal',
  RENAME_TRED_FILE_MODAL = 'rename-tred-file-modal',
  EDIT_TRED_TAGS_MODAL = 'edit-tred-tags-modal',
  RENAME_TRED_MODAL = 'rename-tred-modal',
  DELETE_TREDS_FILE = 'delete-treds-file',
  USER_GROUP = 'user-group',
  MOVE_TRED_MODAL = 'move-tred-modal',
  PERFORMERS_MODAL = 'performers-modal',
  CREATE_TEMPLATE = 'create-template',
  COMMENT_COUNTER_MODAL = 'comment-counter-modal',
  SHOW_KARMA_MODAL = 'show-karma-modal',
  CHANGE_KARMA_MODAL = 'change-karma-modal',
  CHRONO_REPORT_MODAL = 'chrono-report-modal',
  CHRONO_WEEK_REPORT = 'chrono-week-report',
  SET_TEMPLATE_MODAL = 'set-template-modal',
  ADD_TASK_IN_PLAN_MODAL = 'add-task-in-plan-modal',
  ADD_USER_TAG_MODAL = 'add-user-tag-modal',
  WITHOUT_STATUS = 'without-status',
  POLYGON_PALETTE_MODAL = 'polygon-palette-modal',
  INFO_MODAL = 'info-modal',
  EDIT_MODAL = 'edit-modal',
  EDIT_TITLE_MODAL = 'edit-title-modal',
  LINK_ADD_MODAL = 'link-add-modal',
  NEW_LINK_MODAL = 'new-link-modal',
  REMOVE_MODAL = 'remove-modal',
  ADD_POLYGON_BLOCK_MODAL = 'add-polygon-block-modal',
  TEMPLATE_MODAL = 'template-modal',
  TEMPLATE_MODAL_ALL = 'template-modal-all',
  POLYGON_TREE_MODAL = 'polygon-tree-modal',
  ADD_TASKS = 'add-tasks',
  CHANGE_IMAGE_MODAL = 'change-image-modal',
  ADD_NEW_POLYGON_OBJECT = 'add-new-polygon-object',
  CHANGE_TAGS_MODAL = 'change-tags-modal',
  CREATE_TASK_OR_SUBTASK_MODAL = 'create-task-or-subtask-modal',
  TASK_COMMENTS_MODAL = 'task-comments-modal',
  ADD_FILE_MODAL = 'add-file-modal',
  ADD_FILE_TASK_MODAL = 'add-file-task-modal',
  ADD_FILE_TASK_STATUS_MODAL = 'add-file-task-status-modal',
  UPDATE_FILE_SCALE_MODAL = 'update-file-scale-modal',
  FORUM_TREAD = 'forum-tread',
  FORUM_TREAD_MESSAGE = 'forum-tread-message',
  COPY_PASSWORD = 'copy-password',
  ACTION_TREAD = 'action-tread',
  CREATE_CHAPTER = 'create-chapter',
  UPDATE_CHAPTER = 'update-chapter',
  SHOW_LINK = 'show-link',
  ADD_FILE_USER_MODAL = 'add-file-user-modal',
  EDIT_TITLE = 'edit-title',
  DELETE_TASK = 'delete-task',
  PRICE_TASK = 'price-task',
  CHANGE_TAGS_USER_MODAL = 'change-tags-user-modal',
  CHRONO__TIME = 'chrono-time',
  CHRONO__ENERGY = 'chrono-energy',
}

export enum OptionsStatuses {
  WITHOUT_STATUS = '',
  PALETTE_MENU = 'palette-menu',
  EDIT_TEXT_MENU = 'edit-text-menu',
  TEMPLATE_MENU = 'template-menu',
  TEMPLATE_CHILDREN_MENU = 'template-children-menu',
}

export enum cancelUpdateType {
  FIX_DUPLICATES = 'fix-duplicates',
  MULTISELECT_CANCEL = 'multiselect-cancel',
  UPDATE = 'UPDATE',
}

export enum SendLevels {
  TOP = 'top',
  BOTTOM = 'bottom',
  DOWN = 'down',
  UP = 'up',
}

export enum HoverRectIdType {
  NONE = '',
  RECT = 'rect',
  DESCRIPTION = 'description',
}

export enum AddModalEnum {
  DEFAULT_STATUS = '',
  ADD_STATUS = 'add',
  EDIT_STATUS = 'edit',
}

export enum FileTypeStorage {
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls = 'application/vnd.ms-excel',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  pdf = 'application/pdf',
  gif = 'image/gif',
  jpeg = 'image/jpeg',
  png = 'image/png',
  svg = 'image/svg+xml',
  webp = 'image/webp',
  odoc = 'odoc',
  ofolder = 'ofolder',
  sharingFileImage = 'sharingFileImage',
  sharingFile = 'sharingFile',
  osheet = 'osheet',
  ovideo = 'ovideo',
  sharingFileVideo = 'sharingFileVideo',
  slides = 'slides',
  oimage = 'oimage',
}

export enum TemplateState {
  left = 2,
  right = 3,
  center = 1,
}

export enum chronoEnergy {
  negative = '#303030',
  positive = '#DEBB3F',
  empty = '',
}

export enum TypeDecideOnTheFlyButtons {
  start = 'start',
  select = 'select',
  tyme = '',
  sleep = 'sleep',
  noData = 'no-data',
}
export enum chatType {
  logs = 'logs',
  messagesList = 'messagesList',
}
