import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const CheckAllIcon: FC<CheckAllIconType> = ({
  color = '#AFAFAF',
  width = '20px',
  height = '20px',
  onClick,
  className = 'check-all-icon',
}) => {
  return (
    <svg
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 50 50'
      width={width}
      height={height}
      onClick={onClick}
      className={className}
    >
      <path
        d='M 7 2 C 4.2 2 2 4.2 2 7 L 2 34 C 2 36.8 4.2 39 7 39 L 34 39 C 36.8 39 39 36.8 39 34 L 39 9.8007812 L 37 12 L 37 34 C 37 35.7 35.7 37 34 37 L 7 37 C 5.3 37 4 35.7 4 34 L 4 7 C 4 5.3 5.3 4 7 4 L 33.400391 4 L 35.099609 2.0996094 C 34.699609 2.0996094 34.4 2 34 2 L 7 2 z M 36.255859 5.3300781 L 18.921875 24.591797 L 10.664062 17.251953 L 9.3359375 18.748047 L 19.078125 27.408203 L 37.744141 6.6699219 L 36.255859 5.3300781 z M 41 11 L 41 13 L 43 13 C 44.7 13 46 14.3 46 16 L 46 43 C 46 44.7 44.7 46 43 46 L 16 46 C 14.3 46 13 44.7 13 43 L 13 41 L 11 41 L 11 43 C 11 45.8 13.2 48 16 48 L 43 48 C 45.8 48 48 45.8 48 43 L 48 16 C 48 13.2 45.8 11 43 11 L 41 11 z'
        fill={color}
      />
    </svg>
  );
};

type CheckAllIconType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
>;
