import '../notes/Notes.scss';

import { Field, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button } from '../../components';
import { useChatContext } from '../../context';
import {
  TrackActivityTypeEnum,
  useGetPrivateChatNotesQuery,
} from '../../graphql/generated/graphql';
import { useCreateNoteMutation } from '../../hooks/useMutations';
import { NotesItem } from '../notesItem/NotesItem';

export const PrivateChatNotes: React.FC = () => {
  const [createNote] = useCreateNoteMutation();

  const { privateChatId } = useParams();

  const { chatId, dataSubscription } = useChatContext();

  const { data: privateChatNotesData, refetch } = useGetPrivateChatNotesQuery({
    variables: { userId: Number(privateChatId) },
  });

  useEffect(() => {
    if (
      dataSubscription?.privateChatActivity?.type === TrackActivityTypeEnum.NoteAdded ||
      dataSubscription?.privateChatActivity?.type === TrackActivityTypeEnum.NoteDeleted
    ) {
      refetch();
    }
  }, [dataSubscription]);

  const handleSubmit = async (values: initialStateType, { setFieldValue }: any) => {
    if (values.notes !== null && values.notes !== undefined && values.notes !== '') {
      await createNote({
        variables: {
          data: {
            content: values.notes.toString().trim(),
            chatId: Number(chatId),
          },
        },
        onCompleted: (data?) => {
          if (data?.createNote?.id) {
            toast.success('Заметка успешно сохранена!');
            setFieldValue('notes', '');
            refetch();
          }
        },
      });
    } else {
      toast.error('Введите текст заметки!');
    }
  };

  const initialState = {
    notes: '',
  };

  return (
    <Formik initialValues={initialState} onSubmit={handleSubmit}>
      {({ handleSubmit }) => (
        <div className='notes'>
          <div className='notes__header'>
            <p>Заметки</p>
            <Button type='submit' onClick={handleSubmit} text='Сохранить' />
          </div>
          <div
            className='notes__text'
            style={{
              backgroundColor: '#D5EDD8',
            }}
          >
            <Field as='textarea' placeholder='Введите текст заметки' name='notes' />

            {privateChatNotesData?.getPrivateChat?.notes?.map((note) => {
              if (note) {
                return <NotesItem key={note.id} note={note} refetch={refetch} />;
              }
            })}
          </div>
        </div>
      )}
    </Formik>
  );
};

type initialStateType = {
  notes?: string | null | undefined;
};
