import './TreadHistory.scss';

import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { TagType } from '../../graphql/generated/graphql';
import { addKarma, dateParse } from '../../utils';
import { Status } from '../icons/Status';
import { TagItem } from '../index';
import { TableCell } from '../pageListBodyRow/TableCell';
import { RequestHandler } from '../requestHandler/RequestHandler';
import { Table } from '../table/Table';
import { TableBody } from '../tableBody/TableBody';
import { TableRow } from '../tableRow/TableRow';
import { TagsFilter } from '../tagsFilter/TagsFilter';
import { UserItem } from '../user-item/UserItem';

export const TreadHistory: FC<PageListType> = ({
  mappedData,
  isLoading,
  setFilterIds,
  filterIds,
}) => {
  const location = useLocation();

  const isHistoryPage = location.pathname.includes('/history');

  const navigate = useNavigate();

  return (
    <div className='TreadHistory'>
      <div className='TreadHistory__body'>
        <div className='TreadHistory__header'>
          <h2 className='ml10'>История треддов</h2>
          {isHistoryPage && (
            <TagsFilter
              applyFilter={setFilterIds}
              filterState={filterIds}
              typeTag={TagType.Task}
            />
          )}
        </div>
        <div className='TreadHistory__line'></div>
        <RequestHandler loading={isLoading}>
          <Table>
            {mappedData?.length !== 0 && (
              <thead>
                <tr>
                  {[
                    'Дата просмотра',
                    'Название треда',
                    'Теги треда',
                    'Главный исполнитель',
                  ].map((title, index) => {
                    return (
                      <th key={index} style={{ borderBottom: 'none' }}>
                        <p>{title}</p>
                      </th>
                    );
                  })}
                </tr>
              </thead>
            )}

            <TableBody>
              {mappedData?.length === 0 && <div>Нет просмотренных тредов</div>}
              {mappedData?.map((item, index) => {
                return (
                  <TableRow
                    key={`${item?.id}${item?.name}${index}`}
                    onClick={() => {
                      navigate(`/tread/${item.id}`);
                    }}
                  >
                    <TableCell
                      width='20%'
                      background={item?.isPerformer ? '#AFAFAF' : ''}
                    >
                      <span>
                        {dateParse(item?.createdAt).date}
                        {' в'}
                        {dateParse(item?.createdAt).time}
                      </span>
                    </TableCell>
                    <TableCell
                      width='20%'
                      background={item?.isPerformer ? '#AFAFAF' : ''}
                    >
                      <>
                        <Status
                          color={item?.status as string}
                          style={{
                            marginRight: '10px',
                          }}
                        />
                        <span>{item?.name}</span>
                      </>
                    </TableCell>
                    <TableCell
                      width='40%'
                      background={item?.isPerformer ? '#AFAFAF' : ''}
                    >
                      {item?.tags?.map((e) => {
                        return (
                          <TagItem
                            color={e?.color}
                            title={e?.name}
                            key={e?.id}
                            textColor={e?.textColor}
                            margin='0px 10px 0px 0px'
                          />
                        );
                      })}
                    </TableCell>
                    <TableCell
                      width='20%'
                      background={item?.isPerformer ? '#AFAFAF' : ''}
                    >
                      <UserItem
                        avatar={item?.maintainer?.image}
                        name={addKarma(item?.maintainer as any)}
                        id={item?.maintainer?.id as number}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </RequestHandler>
      </div>
    </div>
  );
};

type TasksListItemType = {
  id?: number | null | undefined;
  createdAt?: string | null;
  status?: string | null | undefined;
  name?: string | null;
  project?: string | null;
  parentId?: number | null | undefined;
  isPerformer?: boolean;
  tags: [
    {
      id: number;
      name: string;
      color: string;
      textColor: string;
    },
  ];
  maintainer?: {
    id?: number | null;
    login: string | null | undefined;
    image: string | null | undefined;
  } | null;
};

type PageListType = {
  isLoading: boolean;
  mappedData: TasksListItemType[];
  pageTitle: string;
  filterIds?: string[];
  setFilterIds?: (ids: string[]) => void;
  count?: number;
  setCurrentPage?: (value: number) => void;
  currentPage?: number;
};
