import { gql } from '@apollo/client';

export const GET_PRIVATE_CHATS = gql`
  query getPrivateChats($data: GetChatsInput!) {
    getPrivateChats(data: $data) {
      rows {
        id
        login
        image
        role
        onlineStatus
        karmaStatistics {
          karma
          items {
            id
            isPlus
            userId
            messageId
          }
        }
        chats {
          id
          unreadMessagesCount
          name
          messagesList(limit: 50) {
            rows {
              id
              text
              user {
                id
                login
              }
              isRead
            }
            count
          }
        }
      }
      count
    }
  }
`;
