import * as yup from 'yup';

import * as MESSAGES from '../messages';

export const createTreds = yup.object().shape({
  name: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  tagsIds: yup.array().of(yup.string().required()).max(3, MESSAGES.INCORRECT_MAX_TAG_3),
  performers: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(MESSAGES.REQUIRE_MESSAGE),
      }),
    )
    .min(1, MESSAGES.REQUIRE_MESSAGE)
    .required(MESSAGES.REQUIRE_MESSAGE),
});
