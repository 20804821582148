import './CellTwoCol.scss';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useModalContext } from '../../../context/ModalContext';
import {
  MoveScaleMapTaskMutationFn,
  ScaleMapColumnStatisticsType,
} from '../../../graphql/generated/graphql';
import { ModalStatuses } from '../../../types/enums';
import { EditIcon, TredLinkIcon } from '../../icons';

type CellTwoColType = {
  content?: any;
  fatherId: number;
  createScaleMapTaskMutation: (value: any) => void;
  setSelectTwoCol?: (value: number) => void;
  selectTwoCol?: number;
  selectFirstCol?: number;
  updateScaleMapTaskMutation: (value: any) => void;
  deleteScaleMapTaskMutation: (value: any) => void;
  index: number;
  statictic: ScaleMapColumnStatisticsType | undefined;
  fatherArray: [number | null];
  filterStatus: null | number;
  isLineSuccesses: boolean;
  moveScaleMapTaskMutation: MoveScaleMapTaskMutationFn;
};

export const CellTwoCol = ({
  content,
  selectTwoCol,
  setSelectTwoCol,
  selectFirstCol,
  updateScaleMapTaskMutation,
  deleteScaleMapTaskMutation,
  isLineSuccesses,
  moveScaleMapTaskMutation,
}: CellTwoColType) => {
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();
  const navigate = useNavigate();

  const moveTask = ({ scaleMapTaskId }: { scaleMapTaskId: number }) => {
    handleSetModalStatus(ModalStatuses.MOVE_TRED_MODAL);
    handleChangeModalParameters([
      {
        key: 'onCallback',
        value: ({ parentScaleMapTaskId }: { parentScaleMapTaskId: number }) => {
          moveScaleMapTaskMutation({
            variables: {
              scaleMapTaskId: scaleMapTaskId,
              parentScaleMapTaskId: parentScaleMapTaskId,
            },
          });
        },
      },
    ]);
  };

  const editTaskHeader = ({
    task,
  }: {
    task: { name: string; status: number; id: number };
  }) => {
    handleSetModalStatus(ModalStatuses.ADD_FILE_TASK_STATUS_MODAL);
    handleChangeModalParameters([
      {
        key: 'onCallback',
        value: (value: { name: string; status: number }) => {
          updateScaleMapTaskMutation({
            variables: {
              input: {
                id: task?.id,
                name: value?.name,
                scaleMapTaskId: undefined,
                statusId: value?.status,
              },
            },
          });
        },
      },
      {
        key: 'onCallbackDelete',
        value: () => {
          deleteScaleMapTaskMutation({
            variables: {
              id: task?.id,
            },
          });
        },
      },
      {
        key: 'initialValues',
        value: { name: task?.name, status: task?.status },
      },
      {
        key: 'col',
        value: 'col2',
      },
    ]);
  };

  return (
    <div
      className={`CellTwoColFile__state2 ${
        isLineSuccesses && 'CellTwoColFile__state2-black'
      }`}
    >
      {content?.map((e: any, index: number) => {
        if (e?.scaleMapTaskId !== selectFirstCol) return null;

        return (
          <div
            className={`${
              selectTwoCol === e.id ? 'CellTwoColFile__state2-select' : ''
            } CellTwoColFile__state2-element `}
            key={index}
            onClick={() => {
              setSelectTwoCol && setSelectTwoCol(e?.id as number);
            }}
          >
            <span className='CellTwoColFile__status-wrapper'>
              <div
                className='CellTwoColFile__status'
                style={{
                  backgroundColor: e?.status?.color,
                }}
              ></div>
              {e?.name}
            </span>

            <div className='CellTwoColFile__state2-icons'>
              <div
                className='CellTwoColFile__state2-move'
                onClick={() => {
                  moveTask({ scaleMapTaskId: e.id });
                }}
              >
                Перенести
              </div>
              {e?.threadId && (
                <TredLinkIcon
                  className='CellTwoColFile__editIcons'
                  onClick={() => {
                    navigate('/tread/' + e?.threadId);
                  }}
                />
              )}

              <EditIcon
                color='black'
                width={14}
                height={14}
                onClick={() =>
                  editTaskHeader({
                    task: {
                      status: e?.status?.id,
                      name: e?.name,
                      id: e?.id,
                    },
                  })
                }
              />
            </div>
          </div>
        );
      })}
      <div
        className='CellTwoColFile__state2'
        style={{
          justifyContent: 'center',
        }}
      ></div>
    </div>
  );
};
