import './AddTaskInPlanModal.scss';

import React, { useState } from 'react';
import { ChromePicker } from 'react-color';

import { CreateChronographSampleItemInput as sampleItemsType } from '../../../../graphql/generated/graphql';
import { TreadsDeleteICons } from '../../../icons';
import { Button } from '../../../index';

export const Line = ({
  e,
  index,
  setFieldValue,
  values,
}: {
  e: sampleItemsType & { name: string };
  index: number;
  setFieldValue: any;
  values: any;
}) => {
  const [color, setColor] = useState(e.color);
  const [openColor, setOpenColor] = useState(false);

  return (
    <div className='addTaskInPlanModal2__table-rows'>
      <div className='addTaskInPlanModal2__table-col1' style={{ position: 'relative' }}>
        <div
          className='addTaskInPlanModal2__table-color'
          style={{
            backgroundColor: color,
          }}
          onClick={() => {
            setOpenColor(true);
          }}
        ></div>
        {openColor && (
          <div className='addTaskInPlanModal2__colorPanel'>
            <ChromePicker
              disableAlpha
              color={color}
              onChangeComplete={(paletteColor) => {
                setColor(paletteColor.hex);
              }}
              onChange={(paletteColor) => {
                setColor(paletteColor.hex);
              }}
            />
            <Button
              text='Сохранить цвет'
              margin='10px 0 0 0'
              onClick={() => {
                setOpenColor(false);
                setFieldValue(`sampleItems.${index}.color`, color);
              }}
            />
          </div>
        )}
        {e?.name}
      </div>
      <div className='addTaskInPlanModal2__table-col3'>
        <TreadsDeleteICons
          onClick={() => {
            const updatedSampleItems = [...values.sampleItems];
            updatedSampleItems.splice(index, 1);
            setFieldValue('sampleItems', updatedSampleItems);
          }}
        />
      </div>
    </div>
  );
};
