import React from 'react';

export const ScaleStarFavoriteIcons = ({ onClick, style }: any) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      style={style}
    >
      <path
        d='M17.0152 7.78193L12.5185 7.09758L10.5083 2.83013C10.4534 2.71329 10.3631 2.6187 10.2515 2.56121C9.9717 2.41655 9.63166 2.5371 9.49174 2.83013L7.4816 7.09758L2.98489 7.78193C2.86091 7.80047 2.74757 7.86167 2.66078 7.95441C2.55587 8.06733 2.49806 8.21924 2.50005 8.37678C2.50204 8.53431 2.56368 8.68457 2.67141 8.79454L5.92484 12.1161L5.1562 16.8064C5.13818 16.9156 5.14971 17.0278 5.18948 17.1304C5.22926 17.233 5.29569 17.3218 5.38125 17.3869C5.4668 17.452 5.56805 17.4906 5.67353 17.4985C5.779 17.5064 5.88446 17.4831 5.97797 17.4314L10 15.217L14.0221 17.4314C14.1319 17.4926 14.2594 17.513 14.3816 17.4908C14.6898 17.4352 14.897 17.1291 14.8439 16.8064L14.0752 12.1161L17.3287 8.79454C17.4172 8.70367 17.4757 8.58497 17.4934 8.45515C17.5412 8.13059 17.3251 7.83015 17.0152 7.78193Z'
        fill='#DEBB3F'
      />
    </svg>
  );
};
