import './ChangeCommentModal.scss';

import { Form, Formik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';

import { GET_MY_PROJECTS_ROLE_MAINTAINER } from '../../graphql/queries';
import {
  useUpdateSubtaskMutation,
  useUpdateTaskMutation,
} from '../../hooks/useMutations';
import { createComment, editComment } from '../../validation/schemas';
import { AppInput, Button, Modal } from '..';

type Values = {
  maintainerComment: string;
};

type ChangeCommentModalPropTypes = {
  isOpen: boolean;
  id?: number;
  closeModal: () => void;
  isEditMode: boolean;
  editedComment: string;
  isSubtask: boolean;
};

export const ChangeCommentModal = ({
  isOpen,
  id,
  closeModal,
  isEditMode,
  editedComment,
  isSubtask,
}: ChangeCommentModalPropTypes) => {
  const initialValues = {
    maintainerComment: !isEditMode ? '' : editedComment,
  };

  const [updateTask] = useUpdateTaskMutation();
  const [updateSubtask] = useUpdateSubtaskMutation();

  const handleCreateComment = async (values: Values) => {
    const parameters = {
      id: Number(id),
      data: { maintainerComment: values.maintainerComment },
    };

    if (isSubtask) {
      await updateSubtask({
        variables: parameters,
        refetchQueries: [GET_MY_PROJECTS_ROLE_MAINTAINER],
        onCompleted: (response) => {
          if (response?.updateSubtask.id) {
            closeModal();
            toast.success(
              `Комменатрий для подзадачи ${response.updateSubtask.name} успешно изменен!`,
            );
          }
        },
      });
    } else {
      await updateTask({
        variables: parameters,
        refetchQueries: [GET_MY_PROJECTS_ROLE_MAINTAINER],
        onCompleted: (response) => {
          if (response?.updateTask.id) {
            closeModal();
            toast.success(
              `Комментарий для задачи ${response.updateTask.name} успешно изменен!`,
            );
          }
        },
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Formik
        initialValues={initialValues}
        validationSchema={!isEditMode ? createComment : editComment}
        onSubmit={handleCreateComment}
      >
        <Form>
          <div className='changeCommentModal'>
            <div className='changeCommentModal__inputWrap'>
              <AppInput
                name='maintainerComment'
                placeholder='Введите текст комментария'
              />
            </div>
            <Button
              text={!isEditMode ? 'Добавить комментарий' : 'Сохранить комментарий'}
              type='submit'
              width='100%'
              height={25}
            />
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};
