import React, { ReactNode } from 'react';

import { selects } from '../../constants/constants';
import { useModalContext } from '../../context';
import { ModalStatuses } from '../../types/enums';
import { ConfirmModal } from '../confirmModal/ConfirmModal';
import {
  AccesStorageModal,
  ActionTreadsModal,
  AddFileModal,
  AddFileModalUser,
  AddFileTaskModal,
  AddFileTaskStatusModal,
  AddPolygonBlockModal,
  AddPolygonObjects,
  AddTaskInPlanModal2,
  AddTaskModal,
  AddUserTagsModal,
  AllTredImageModal,
  ChangeColNameModal,
  ChangeImageModal,
  ChangeKarmaModal,
  ChangeTagsModal,
  ChangeTagsUserModal,
  ChatDeleteModal,
  ChronoEnergyModal,
  ChronometerNote,
  ChronometerReport,
  ChronoReportModal,
  ChronoTimeModal,
  ChronoWeekReport,
  CircleAddMeetModal,
  CircleCreateModal,
  CircleUserModal,
  ColorStatusItemModal,
  CommentCounterModal,
  ConfirmDeletionModal,
  CopyPasswordModal,
  CreateChapterModal,
  CreateTaskOrSubtaskModal,
  CreateTemplate,
  CreateTreadMessageModal,
  CreateTreadModal,
  DecideOnTheFlyDeleteTask,
  DecideOnTheFlyLogs,
  DecideOnTheFlyReport,
  DecideOnTheFlyTask,
  DeleteAccount,
  DeleteTaskModal,
  DeleteTredsFile,
  EditModal,
  EditSingleTagModal,
  EditTagsTredModal,
  EditTitleModal,
  EditTitleModalLocal,
  FileFonAndTagsModal,
  InfoModal,
  LinkAddModal,
  MoveScaleTaskModal,
  MoveTredModal,
  NewLinkModal,
  NewResetPasswordModal,
  PerformersModal,
  PlanChronoModal,
  PolygonPaletteModal,
  PolygonTreeModal,
  PriceTaskModal,
  PulsometerModals,
  RenameTredFileModal,
  RenameTredModal,
  ReportChronographModal,
  ScaleToTreadModal,
  SelectPackageModal,
  ShowKarmaModal,
  ShowLinkModal,
  TaskCommentsModal,
  TemplateAll,
  TemplateModal,
  TreadSelectFonModal,
  TredFonsModal,
  TredOpenImageModal,
  UpdateChapterModal,
  UpdateFileScaleModal,
  UserGroupModal,
} from '../modal';
import { Modal } from '../modal/Modal';

const ModalWrapper = ({ children }: { children: ReactNode }) => {
  const { handleCloseModal } = useModalContext();

  return (
    <Modal isOpen={true} onClose={handleCloseModal}>
      {children}
    </Modal>
  );
};
export const ModalController = () => {
  const { modalStatus, handleCloseModal, modalParameters } = useModalContext();

  switch (modalStatus) {
    case ModalStatuses.DELETE_ACCOUNT:
      return (
        <ModalWrapper>
          <DeleteAccount onClose={handleCloseModal} modalParameters={modalParameters} />
        </ModalWrapper>
      );
    case ModalStatuses.FILE_FON_AND_TAGS_MODAL:
      return (
        <ModalWrapper>
          <FileFonAndTagsModal
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.CHANGE_COL_NAME_MODAL:
      return (
        <ModalWrapper>
          <ChangeColNameModal
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.CONFIRM_DELETION_MODAL:
      return (
        <ModalWrapper>
          <ConfirmDeletionModal
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.MOVE_SCALE_TASK_MODAL:
      return (
        <ModalWrapper>
          <MoveScaleTaskModal
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.SELECT_PACKAGE_MODAL:
      return (
        <ModalWrapper>
          <SelectPackageModal
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.ALL_TRED_IMAGE_MODAL:
      return (
        <ModalWrapper>
          <AllTredImageModal
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.COLOR_STATUS_ITEM_MODAL:
      return (
        <ModalWrapper>
          <ColorStatusItemModal
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.PULSOMETER_MODAL:
      return (
        <ModalWrapper>
          <PulsometerModals
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.PLAN_CHRONO_MODAL:
      return (
        <ModalWrapper>
          <PlanChronoModal onClose={handleCloseModal} modalParameters={modalParameters} />
        </ModalWrapper>
      );
    case ModalStatuses.TRED_OPEN_IMAGE_MODAL:
      return (
        <ModalWrapper>
          <TredOpenImageModal
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.EDIT_SINGLE_TAG_MODAL:
      return (
        <ModalWrapper>
          <EditSingleTagModal
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.NEW_RESET_PASSWORD_MODAL:
      return (
        <ModalWrapper>
          <NewResetPasswordModal
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );

    case ModalStatuses.CHAT_DELETE_MODAL:
      return (
        <ModalWrapper>
          <ChatDeleteModal onClose={handleCloseModal} modalParameters={modalParameters} />
        </ModalWrapper>
      );
    case ModalStatuses.ACCES_STORAGE_MODAL:
      return (
        <ModalWrapper>
          <AccesStorageModal
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.DECIDE_ON_THE_FLY_DELETE_TASK:
      return (
        <ModalWrapper>
          <DecideOnTheFlyDeleteTask
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.DECIDE_ON_THE_FLY_TASK:
      return (
        <ModalWrapper>
          <DecideOnTheFlyTask
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.DECIDE_ON_THE_FLY_LOGS:
      return (
        <ModalWrapper>
          <DecideOnTheFlyLogs
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.DECIDE_ON_THE_FLY_REPORT:
      return (
        <ModalWrapper>
          <DecideOnTheFlyReport
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.TREAD_SELECT_FON_MODAL:
      return (
        <ModalWrapper>
          <TreadSelectFonModal
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.TREADS_FONS_MODAL:
      return (
        <ModalWrapper>
          <TredFonsModal onClose={handleCloseModal} modalParameters={modalParameters} />
        </ModalWrapper>
      );
    case ModalStatuses.CIRCLE_USER_MODAL:
      return (
        <ModalWrapper>
          <CircleUserModal onClose={handleCloseModal} modalParameters={modalParameters} />
        </ModalWrapper>
      );
    case ModalStatuses.CIRCLE_ADD_MEET_MODAL:
      return (
        <ModalWrapper>
          <CircleAddMeetModal
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.CIRCLE_CREATE_MODAL:
      return (
        <ModalWrapper>
          <CircleCreateModal
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.CHRONOMETER_REPORT:
      return (
        <ModalWrapper>
          <ChronometerReport />
        </ModalWrapper>
      );
    case ModalStatuses.CHRONOMETER_NOTE:
      return (
        <ModalWrapper>
          <ChronometerNote onClose={handleCloseModal} modalParameters={modalParameters} />
        </ModalWrapper>
      );
    case ModalStatuses.EDIT_TITLE_MODAL_LOCAL:
      return (
        <ModalWrapper>
          <EditTitleModalLocal
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.SCALE_TO_TREAD_MODAL:
      return (
        <ModalWrapper>
          <ScaleToTreadModal
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.REPORT_CHRONOGRAPH_MODAL:
      return (
        <ModalWrapper>
          <ReportChronographModal
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.RENAME_TRED_FILE_MODAL:
      return (
        <ModalWrapper>
          <RenameTredFileModal
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.EDIT_TRED_TAGS_MODAL:
      return (
        <ModalWrapper>
          <EditTagsTredModal
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.RENAME_TRED_MODAL:
      return (
        <ModalWrapper>
          <RenameTredModal onClose={handleCloseModal} modalParameters={modalParameters} />
        </ModalWrapper>
      );
    case ModalStatuses.DELETE_TREDS_FILE:
      return (
        <ModalWrapper>
          <DeleteTredsFile onClose={handleCloseModal} modalParameters={modalParameters} />
        </ModalWrapper>
      );
    case ModalStatuses.USER_GROUP:
      return (
        <ModalWrapper>
          <UserGroupModal />
        </ModalWrapper>
      );
    case ModalStatuses.MOVE_TRED_MODAL:
      return (
        <ModalWrapper>
          <MoveTredModal onClose={handleCloseModal} modalParameters={modalParameters} />
        </ModalWrapper>
      );
    case ModalStatuses.PERFORMERS_MODAL:
      return (
        <ModalWrapper>
          <PerformersModal onClose={handleCloseModal} modalParameters={modalParameters} />
        </ModalWrapper>
      );

    case ModalStatuses.CREATE_TEMPLATE:
      return (
        <ModalWrapper>
          <CreateTemplate onClose={handleCloseModal} modalParameters={modalParameters} />
        </ModalWrapper>
      );
    case ModalStatuses.COMMENT_COUNTER_MODAL:
      return (
        <ModalWrapper>
          <CommentCounterModal
            isOpen={true}
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.SHOW_KARMA_MODAL:
      return (
        <ModalWrapper>
          <ShowKarmaModal onClose={handleCloseModal} modalParameters={modalParameters} />
        </ModalWrapper>
      );
    case ModalStatuses.CHANGE_KARMA_MODAL:
      return (
        <ModalWrapper>
          <ChangeKarmaModal
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.CHRONO_REPORT_MODAL:
      return (
        <ModalWrapper>
          <ChronoReportModal
            isOpen={true}
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.CHRONO_WEEK_REPORT:
      return (
        <ModalWrapper>
          <ChronoWeekReport
            isOpen={true}
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );

    case ModalStatuses.ADD_TASK_IN_PLAN_MODAL:
      return (
        <ModalWrapper>
          <AddTaskInPlanModal2
            isOpen={true}
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.ADD_USER_TAG_MODAL:
      return (
        <ModalWrapper>
          <AddUserTagsModal
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.PRICE_TASK:
      return (
        <ModalWrapper>
          <PriceTaskModal
            isOpen={true}
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.DELETE_TASK:
      return (
        <ModalWrapper>
          <DeleteTaskModal
            isOpen={true}
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.EDIT_TITLE:
      return (
        <ModalWrapper>
          <EditTitleModal
            isOpen={true}
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.SHOW_LINK:
      return (
        <ModalWrapper>
          <ShowLinkModal
            isOpen={true}
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.UPDATE_CHAPTER:
      return (
        <ModalWrapper>
          <UpdateChapterModal
            isOpen={true}
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.CREATE_CHAPTER:
      return (
        <ModalWrapper>
          <CreateChapterModal
            isOpen={true}
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.ACTION_TREAD:
      return (
        <ModalWrapper>
          <ActionTreadsModal
            isOpen={true}
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.FORUM_TREAD:
      return (
        <ModalWrapper>
          <CreateTreadModal
            isOpen={true}
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.FORUM_TREAD_MESSAGE:
      return (
        <ModalWrapper>
          <CreateTreadMessageModal
            isOpen={true}
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.COPY_PASSWORD:
      return (
        <ModalWrapper>
          <CopyPasswordModal
            isOpen={true}
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.UPDATE_FILE_SCALE_MODAL:
      return (
        <ModalWrapper>
          <UpdateFileScaleModal
            isOpen={true}
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.ADD_FILE_TASK_STATUS_MODAL:
      return (
        <ModalWrapper>
          <AddFileTaskStatusModal
            isOpen={true}
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.ADD_FILE_TASK_MODAL:
      return (
        <ModalWrapper>
          <AddFileTaskModal
            isOpen={true}
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.ADD_FILE_USER_MODAL:
      return (
        <ModalWrapper>
          <AddFileModalUser
            isOpen={true}
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.ADD_FILE_MODAL:
      return (
        <ModalWrapper>
          <AddFileModal
            isOpen={true}
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.TASK_COMMENTS_MODAL:
      return (
        <ModalWrapper>
          <TaskCommentsModal
            isOpen={true}
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.CHANGE_TAGS_MODAL:
      return (
        <ModalWrapper>
          <ChangeTagsModal isOpen={true} onClose={handleCloseModal} />
        </ModalWrapper>
      );
    case ModalStatuses.CHANGE_TAGS_USER_MODAL:
      return (
        <ModalWrapper>
          <ChangeTagsUserModal isOpen={true} onClose={handleCloseModal} />
        </ModalWrapper>
      );
    case ModalStatuses.ADD_NEW_POLYGON_OBJECT:
      return (
        <ModalWrapper>
          <AddPolygonObjects
            isOpen={true}
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.CHANGE_IMAGE_MODAL:
      return (
        <ModalWrapper>
          <ChangeImageModal
            isOpen={true}
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.ADD_TASKS:
      return (
        <ModalWrapper>
          <AddTaskModal
            isOpen={true}
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.ADD_POLYGON_BLOCK_MODAL:
      return (
        <ModalWrapper>
          <AddPolygonBlockModal
            isOpen={true}
            onClose={handleCloseModal}
            onSubmit={modalParameters.onCallback}
            labelText='Название блока: '
            buttonText='Добавить блок'
            colors={modalParameters.colors}
            onSubmitPaletteColors={modalParameters.onSubmitColorCallback}
            polygonId={modalParameters.polygonId}
          />
        </ModalWrapper>
      );
    case ModalStatuses.INFO_MODAL:
      return (
        <ModalWrapper>
          <InfoModal
            isOpen={true}
            onSubmit={modalParameters.onCallback}
            onClose={handleCloseModal}
            buttonText='Сохранить'
            labelText='Добавить описание'
            initialValues={modalParameters.initialInfoValues}
            inputName='info'
          />
        </ModalWrapper>
      );
    case ModalStatuses.TEMPLATE_MODAL:
      return (
        <ModalWrapper>
          <TemplateModal
            isOpen={true}
            onSubmit={modalParameters.onCallback}
            onClose={handleCloseModal}
            buttonText='Сохранить'
            inputName='template'
          />
        </ModalWrapper>
      );
    case ModalStatuses.EDIT_MODAL:
      return (
        <ModalWrapper>
          <EditModal
            isOpen={true}
            onClose={handleCloseModal}
            onSubmit={modalParameters.onCallback}
            inputName='polygonName'
            labelText='Название полигона:'
            buttonText='Сохранить'
            colorsName='color'
            colors={modalParameters.colors}
            initialColorId={modalParameters.initialColorId}
            initialValues={modalParameters.initialEditInfoValues}
          />
        </ModalWrapper>
      );
    case ModalStatuses.EDIT_TITLE_MODAL:
      return (
        <ModalWrapper>
          <EditModal
            isOpen={true}
            onClose={handleCloseModal}
            onSubmit={modalParameters.onCallback}
            inputName='projectName'
            labelText='Введите название:'
            buttonText='Сохранить'
            initialValues={modalParameters.initialEditTitleValues}
          />
        </ModalWrapper>
      );
    case ModalStatuses.LINK_ADD_MODAL:
      return (
        <ModalWrapper>
          <LinkAddModal
            onSubmit={modalParameters.onCallback}
            buttonText='Сохранить'
            initialValues={modalParameters.initialValues}
            links={modalParameters.selects || selects}
            projectId={modalParameters.projectId}
            rect={modalParameters.rect}
            rectChange={modalParameters.rectChange}
          />
        </ModalWrapper>
      );

    case ModalStatuses.CREATE_TASK_OR_SUBTASK_MODAL:
      return (
        <ModalWrapper>
          <CreateTaskOrSubtaskModal
            isOpen={true}
            onClose={handleCloseModal}
            projectId={modalParameters.projectId}
            addTasks={modalParameters.addTasks}
          />
        </ModalWrapper>
      );

    case ModalStatuses.NEW_LINK_MODAL:
      return (
        <ModalWrapper>
          <NewLinkModal
            isOpen={true}
            onClose={handleCloseModal}
            onSubmit={modalParameters.onCallback}
            initialValues={modalParameters.parentLink}
            inputName='parentLink'
            placeholder='Введите id полигона'
            buttonText='Добавить'
            onAddPolygon={modalParameters.onCallback}
            poligon={modalParameters.poligon}
            polygonObject={modalParameters.polygonObject}
          />
        </ModalWrapper>
      );
    case ModalStatuses.REMOVE_MODAL:
      return (
        <ModalWrapper>
          <ConfirmModal
            confirmTitle={modalParameters.removeConfirmTitle}
            additionalConfirmTitle={modalParameters.additionalConfirmTitle}
            isOpen={true}
            callback={modalParameters.onCallback}
            onClose={handleCloseModal}
          />
        </ModalWrapper>
      );
    case ModalStatuses.POLYGON_PALETTE_MODAL:
      return (
        <ModalWrapper>
          <PolygonPaletteModal
            isOpen={true}
            onClose={handleCloseModal}
            onSubmit={modalParameters.onCallback}
            initialValues={modalParameters.initialPaletteValues}
            initColors={modalParameters.initColors}
          />
        </ModalWrapper>
      );
    case ModalStatuses.TEMPLATE_MODAL_ALL:
      return (
        <ModalWrapper>
          <TemplateAll
            isOpen={true}
            onClose={handleCloseModal}
            deleteTemplate={modalParameters.onDelete}
            templates={modalParameters.templates}
          />
        </ModalWrapper>
      );
    case ModalStatuses.POLYGON_TREE_MODAL:
      return (
        <ModalWrapper>
          <PolygonTreeModal
            isOpen={true}
            onClose={handleCloseModal}
            onSubmit={modalParameters.onCallback}
            polygonTree={modalParameters.polygonTree}
            linksParams={modalParameters.linksParams}
            poligonName={modalParameters.poligonName}
          />
        </ModalWrapper>
      );
    case ModalStatuses.CHRONO__TIME:
      return (
        <ModalWrapper>
          <ChronoTimeModal
            isOpen={true}
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );
    case ModalStatuses.CHRONO__ENERGY:
      return (
        <ModalWrapper>
          <ChronoEnergyModal
            isOpen={true}
            onClose={handleCloseModal}
            modalParameters={modalParameters}
          />
        </ModalWrapper>
      );

    default:
      return <div></div>;
  }
};
