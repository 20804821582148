import './UserSettings.scss';

import { Form, Formik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';

import {
  AppInput,
  Button,
  ChangeAvatar,
  RequestHandler,
  TagItem,
} from '../../components';
import { EditIcon } from '../../components/icons';
import { useAuthContext, useModalContext } from '../../context';
import {
  ResetPasswordUser,
  useEditUserMutation,
  useGetCurrentUserTagsQuery,
  useResetPasswordMutation,
} from '../../graphql/generated/graphql';
import { ModalStatuses } from '../../types/enums';
import { getErrorText } from '../../utils';
import { editName, editNotes, resetPasswordSchema } from '../../validation/schemas';

export const UserSettings = () => {
  const { currentUserData, refetch } = useAuthContext();

  return (
    <div className='container'>
      <div className='userSettings'>
        <div className='userSettings__wrapper'>
          <div className='userSettings__title'>Изменить аватар</div>
          <ChangeAvatar />
        </div>
        <ChangeNotes currentUserData={currentUserData} refetch={refetch} />
        <ChangeName currentUserData={currentUserData} refetch={refetch} />
        <div className='userSettings__wrapper'>
          <div className='userSettings__title'>Управлять моей тегой</div>
          <EditsTags />
        </div>
        <div
          className='userSettings__wrapper'
          style={{
            width: '255px',
          }}
        >
          <div className='userSettings__title'>Изменить пароль</div>
          <ChangePassword />
        </div>
      </div>
    </div>
  );
};

const EditsTags = () => {
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  const { data, refetch: tagRefetch } = useGetCurrentUserTagsQuery({
    variables: {},
  });

  const tags = data?.getCurrentUser?.tags;

  return (
    <div className='userSettings__tag-wrapper'>
      {tags?.rows?.length === 0 && (
        <div
          className='userSettings__title'
          style={{
            marginTop: '16px',
          }}
        >
          {'Тег не назначен'}
        </div>
      )}
      {tags?.rows?.map((e, index: number) => {
        if (index > 3) return;
        return (
          <div key={e?.id} className={'userSettings__tag-item'}>
            <TagItem title={e?.name} color={e?.color} textColor={e?.textColor} />{' '}
            <EditIcon
              width={15}
              height={15}
              color='#303030'
              className='edit-color ml10'
              onClick={() => {
                handleSetModalStatus(ModalStatuses.EDIT_SINGLE_TAG_MODAL);
                handleChangeModalParameters([
                  {
                    key: 'initialValues',
                    value: {
                      tag: e,
                    },
                  },
                  {
                    key: 'onCallback',
                    value: () => {
                      tagRefetch();
                    },
                  },
                ]);
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

const ChangePassword = () => {
  const [resetPassword, { loading, error }] = useResetPasswordMutation({
    onCompleted: () => {
      toast.success('Пароль изменен');
    },
    onError: () => {
      toast.error('Неверный пароль');
    },
  });
  const initialValues: ResetPasswordUser = {
    oldPassword: '',
    newPassword: '',
  };

  const handleSubmit = async (values: ResetPasswordUser, { resetForm }: any) => {
    await resetPassword({
      variables: {
        data: {
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        },
      },
    });
    resetForm();
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={resetPasswordSchema}
      initialValues={initialValues}
    >
      <Form className='userSettings__password'>
        <div className=''>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <AppInput type='password' label='Старый пароль:' name='oldPassword' />
            <AppInput type='password' label='Новый пароль:' name='newPassword' />
            <p className={'server__form-error'}>{error && getErrorText(error.message)}</p>
            <RequestHandler size='s' loading={loading}>
              <Button type='submit' width='240px' text='Изменить' />
            </RequestHandler>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

const ChangeNotes = ({ currentUserData, refetch }: any) => {
  const [editUser] = useEditUserMutation();

  const handleTheNoteSubmit = async (values: any) => {
    await editUser({
      variables: {
        data: {
          theNote: values?.notes,
        },
      },
    }).then(() => {
      refetch();
      toast.success('Заметка изменена');
    });
  };

  return (
    <Formik
      initialValues={{
        notes: currentUserData?.theNote ? currentUserData?.theNote : '',
      }}
      validationSchema={editNotes}
      onSubmit={handleTheNoteSubmit}
    >
      <Form>
        <div className='userSettings__wrapper'>
          <div className='userSettings__title'>
            <div>Изменить заметку</div>
          </div>
          <AppInput
            name='notes'
            placeholder='Твоя жизнь, твой художник'
            style={{ marginBottom: '0px' }}
          />
          <Button type='submit' margin={'15px 0px 0px 0px'}>
            Сохранить заметку
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

const ChangeName = ({ currentUserData, refetch }: any) => {
  const [editUser] = useEditUserMutation({
    onCompleted: () => {
      refetch();
      toast.success('Имя изменено');
    },
    onError: () => {
      refetch();
    },
  });

  const handleTheNoteSubmit = async (e: any) => {
    editUser({
      variables: {
        data: {
          fullName: e?.fullName,
        },
      },
    });
  };

  return (
    <Formik
      initialValues={{
        fullName: currentUserData?.fullName ? currentUserData?.fullName : '',
      }}
      validationSchema={editName}
      onSubmit={handleTheNoteSubmit}
    >
      <Form>
        <div className='userSettings__wrapper'>
          <div className='userSettings__title'>
            <div>Изменить имя</div>
          </div>
          <AppInput name='fullName' placeholder='Имя' style={{ marginBottom: '0px' }} />
          <Button type='submit' margin={'15px 0px 0px 0px'}>
            Сохранить имя
          </Button>
        </div>
      </Form>
    </Formik>
  );
};
