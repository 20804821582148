import React from 'react';

type FileSearchIconType = {
  className: string;
};

export const FileSearchIcon = ({ className }: FileSearchIconType) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M16.6914 5.63867C16.8086 5.75586 16.875 5.91406 16.875 6.08008V18.125C16.875 18.4707 16.5957 18.75 16.25 18.75H3.75C3.4043 18.75 3.125 18.4707 3.125 18.125V1.875C3.125 1.5293 3.4043 1.25 3.75 1.25H12.0449C12.2109 1.25 12.3711 1.31641 12.4883 1.43359L16.6914 5.63867V5.63867ZM15.4336 6.36719L11.7578 2.69141V6.36719H15.4336Z'
        fill='#AFAFAF'
      />
    </svg>
  );
};
