import rgba from 'color-rgba';
import moment from 'moment';

import { TagList } from '../graphql/generated/graphql';

export const afterBefore = ({
  day,
  currentDay,
  checkedDate,
  color = 'white',
  item,
}: {
  day: any;
  currentDay: any;
  checkedDate: any;
  color?: string | undefined | null;
  item: ItemType | undefined | null;
}) => {
  const rowMonth = new Date(checkedDate).getMonth();
  const rowYear = new Date(checkedDate).getFullYear();

  /// for safari fix
  const createRowMonth = `${rowMonth + 1 <= 9 ? '0' : ''}${rowMonth + 1}`;
  const createRowDay = `${currentDay <= 9 ? '0' : ''}${currentDay}`;

  const rowDate = new Date(`${rowYear}-${createRowMonth}-${createRowDay}`);
  const currentData = new Date(
    `${day?.currentYear}-${day?.currentMonth + 1 <= 9 ? '0' : ''}${
      day?.currentMonth + 1
    }-${day?.currentDay <= 9 ? '0' : ''}${day?.currentDay}`,
  );
  const currentDataMYD = moment(currentData).format('YYYY-MM-DD');

  const rowDateYMD = moment(rowDate).format('YYYY-MM-DD');

  const newColor: any = rgba(color as string);
  const { isEndDay, isStartDay } = startEndCalc({ item: item, rowDate });

  const background =
    rowDateYMD <= currentDataMYD
      ? `rgba(${newColor[0]}, ${newColor[1]}, ${newColor[2]}, 1)`
      : `rgba(${newColor[0]}, ${newColor[1]}, ${newColor[2]}, 0.5)`;
  const newBlackColor = `rgba(${newColor[0] - 30}, ${newColor[1] - 30}, ${
    newColor[2] - 30
  }, 1)`;

  return {
    color: newBlackColor,
    background: background,
    isEndDay,
    isStartDay,
    rowDateYMD,
    today: rowDateYMD === currentDataMYD,
  };
};

const startEndCalc = ({
  item,
  rowDate,
}: {
  item: ItemType | undefined | null;
  rowDate: any;
}) => {
  const endDateDefauld = new Date(item?.closeDate);
  const startDateDefauld = new Date(item?.startDate);
  const newRowDateDefauld = new Date(rowDate);

  const newRowDateMDY = moment(newRowDateDefauld).format('MMM Do YY');
  const endDateMDY = moment(endDateDefauld).format('MMM Do YY');
  const startDateMDY = moment(startDateDefauld).format('MMM Do YY');

  return {
    endDate: endDateDefauld,
    isEndDay: calcEndDateDay({ newRowDateMDY, endDateMDY, item }),
    isStartDay: newRowDateMDY === startDateMDY,
  };
};

const calcEndDateDay = ({ newRowDateMDY, endDateMDY, item }: any) => {
  const closeDataMDY = moment(new Date(item?.endDate)).format('MMM Do YY');
  const endDate = moment(new Date(item?.closeDate)).format();
  const closeData = moment(new Date(item?.endDate)).format();

  if (closeData <= endDate && newRowDateMDY === endDateMDY) {
    return true;
  }

  if (
    item?.endDate &&
    !!item?.closeDate &&
    closeData > endDate &&
    closeDataMDY === newRowDateMDY
  ) {
    return true;
  }
  return false;
};

type ItemType = {
  id: number;
  status?: {
    id: number;
    color: string;
  } | null;
  endDate?: string | null;
  startDate?: any | null;
  closeDate?: any | null;
  parentId?: number | null;
  name: string;
  code?: string | null;
  tags: TagList;
  task?: {
    id: number;
    name: string;
  } | null;
};
