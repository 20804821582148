import './CircleUserModal.scss';

import { Form, Formik } from 'formik';
import moment from 'moment';
import React from 'react';

import { circleUser } from '../../../../validation/schemas';
import { AppInput, Button } from '../../../index';

export const CircleUserModal = ({ modalParameters, onClose }: any) => {
  const currentDate = moment();
  const startDate = moment(modalParameters?.initialValues?.lastMeetingDate);

  const daysPassed = currentDate.diff(startDate, 'days');

  return (
    <div>
      <Formik
        onSubmit={(e) => {
          modalParameters?.onCallback(e);
          onClose();
        }}
        initialValues={{
          name: modalParameters?.initialValues?.name,
          whoIs: modalParameters?.initialValues?.whoIs,
          colorToDisplay: modalParameters?.initialValues?.colorToDisplay,
          usefulnessDescription: modalParameters?.initialValues?.usefulnessDescription,
          strongPoints: modalParameters?.initialValues?.strongPoints,
          weakPoints: modalParameters?.initialValues?.weakPoints,
          letter1: modalParameters?.initialValues?.letter1,
          letter2: modalParameters?.initialValues?.letter2,
          letter3: modalParameters?.initialValues?.letter3,
          letter4: modalParameters?.initialValues?.letter4,
        }}
        validationSchema={circleUser}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form className='CircleUserModal__outer'>
              <div className='CircleUserModal'>
                <div className='CircleUserModal__title'>
                  Редактировать контакт
                  <Button
                    height='20px'
                    width='55px'
                    onClick={() => {
                      modalParameters?.onCallBackDelete();
                      onClose();
                    }}
                  >
                    Удалить
                  </Button>
                </div>
                <AppInput name='name' label={'Имя:'} />
                <AppInput name='whoIs' label={'Кем является:'} />

                <AppInput name='usefulnessDescription' label='Чем может быть полезен:' />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '20px',
                  }}
                >
                  <label
                    style={{ width: '45%', display: 'flex', flexDirection: 'column' }}
                  >
                    Cильные стороны:
                    <textarea
                      className='CircleUserModal__textarea'
                      placeholder=''
                      onChange={(e) => {
                        setFieldValue('strongPoints', e.target.value);
                      }}
                      value={values.strongPoints}
                    ></textarea>
                  </label>
                  <label
                    style={{ width: '45%', display: 'flex', flexDirection: 'column' }}
                  >
                    Cлабые стороны:{' '}
                    <textarea
                      className='CircleUserModal__textarea'
                      placeholder=''
                      onChange={(e) => {
                        setFieldValue('weakPoints', e.target.value);
                      }}
                      value={values.weakPoints}
                    ></textarea>
                  </label>
                </div>
                <div className='CircleUserModal__counts'>
                  <div className='CircleUserModal__counts-inner'>
                    Кол-во встреч:{' '}
                    <span className='CircleUserModal__counts-item'>
                      {modalParameters?.initialValues?.meetingCount}
                    </span>
                  </div>
                  {modalParameters?.initialValues?.lastMeetingDate && (
                    <div className='CircleUserModal__counts-inner'>
                      Кол-во дней с последней встречи:{' '}
                      <span className='CircleUserModal__counts-item'>{daysPassed}</span>
                    </div>
                  )}

                  {modalParameters?.initialValues?.lastMeetingDate && (
                    <div className='CircleUserModal__counts-inner'>
                      Дата последней встречи:{' '}
                      <span className='CircleUserModal__counts-item'>
                        {moment(modalParameters?.initialValues?.lastMeetingDate).format(
                          'DD.MM.YY/HH:mm',
                        )}
                      </span>
                    </div>
                  )}
                </div>

                <div className='CircleUserModal__buttons'>
                  <Button onClick={onClose}>Отменить</Button>
                  <Button type='submit'>Сохранить</Button>
                </div>
              </div>
              <SetSimbphol values={values} setFieldValue={setFieldValue} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export const SetSimbphol = ({ setFieldValue, values }: any) => {
  return (
    <div className='CircleUserModal__wrappers'>
      <div
        className={`CircleUserModal__click ${
          values.letter1 ? 'CircleUserModal__select ' : ''
        } mr5`}
        onClick={() => {
          setFieldValue('letter1', !values.letter1);
          setFieldValue('letter2', false);
          setFieldValue('letter3', false);
          setFieldValue('letter4', false);
        }}
      >
        Р
      </div>
      <div
        className={`CircleUserModal__click ${
          values.letter2 ? 'CircleUserModal__select ' : ''
        }`}
        onClick={() => {
          setFieldValue('letter2', !values.letter2);
          setFieldValue('letter1', false);
          setFieldValue('letter3', false);
          setFieldValue('letter4', false);
        }}
      >
        Б
      </div>
      <div
        className={`CircleUserModal__click ${
          values.letter3 ? 'CircleUserModal__select ' : ''
        } mr5`}
        onClick={() => {
          setFieldValue('letter3', !values.letter3);
          setFieldValue('letter2', false);
          setFieldValue('letter1', false);
          setFieldValue('letter4', false);
        }}
      >
        С
      </div>
      <div
        className={`CircleUserModal__click ${
          values.letter4 ? 'CircleUserModal__select ' : ''
        } `}
        onClick={() => {
          setFieldValue('letter4', !values.letter4);
          setFieldValue('letter1', false);
          setFieldValue('letter2', false);
          setFieldValue('letter3', false);
        }}
      >
        И
      </div>
    </div>
  );
};
