import React from 'react';

export const OpenTreadWhiteIcons = ({ className }: any) => {
  return (
    <svg
      width='15'
      height='14'
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M10.4166 4.66699V4.08366C10.4166 2.47541 9.10817 1.16699 7.49992 1.16699C5.89167 1.16699 4.58325 2.47541 4.58325 4.08366V5.83366H3.99992C3.3565 5.83366 2.83325 6.35691 2.83325 7.00033V11.667C2.83325 12.3104 3.3565 12.8337 3.99992 12.8337H10.9999C11.6433 12.8337 12.1666 12.3104 12.1666 11.667V7.00033C12.1666 6.35691 11.6433 5.83366 10.9999 5.83366H5.74992V4.08366C5.74992 3.11883 6.53509 2.33366 7.49992 2.33366C8.46475 2.33366 9.24992 3.11883 9.24992 4.08366V4.66699H10.4166Z'
        fill='white'
      />
    </svg>
  );
};
