import { ApolloError } from '@apollo/client';
import PropTypes from 'prop-types';
import React, {
  createContext,
  createRef,
  FC,
  LegacyRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import ScrollableFeed from 'react-scrollable-feed';
import { toast } from 'react-toastify';

import * as GENERATED from '../graphql/generated/graphql';
import {
  PrivateChatActivitySubscription,
  TrackActivityTypeEnum,
  useCreatePrivateChatMutation,
  useDeleteMessageMutation,
  useGetPrivateChatLazyQuery,
  useMessagesReadBeforeSubscription,
  useNewMessageSubscription,
  usePrivateChatActivitySubscription,
  useReadMessagesBeforeMutation,
  useSendMessageMutation,
} from '../graphql/generated/graphql';
import { useDebounce, useNewActivityRefetch } from '../hooks';
import { useGetSubtaskChatLazyQuery, useGetTaskChatLazyQuery } from '../hooks/useQueries';
import { useAuthContext } from './AuthContext';

type ContextProps = {
  messages: Array<{
    __typename?: 'Message';
    isRead?: boolean | null;
    id: number;
    text: string;
    createdAt: any;
    user: { __typename?: 'User'; login?: string | null; image?: string | null };
  } | null>;
  messagesCount?: number;
  chatLoading: boolean;
  chatId?: number;
  sendMessageLoading: boolean;
  setMessages: (
    prevState: Array<{
      __typename?: 'Message';
      isRead?: boolean | null;
      id: number;
      text: string;
      createdAt: any;
      user: { __typename?: 'User'; login?: string | null; image?: string | null };
    } | null>,
  ) => void;
  fetchMoreMessages: () => void;
  chatScrollerRef?: LegacyRef<ScrollableFeed>;
  scrollChatToBottom: () => void;
  sendMessageHandler: (message: string) => void;
  deleteMessageHandler: (messageId?: string | null) => void;
  chatRequestError?: ApolloError;
  taskChatRefetch: () => void;
  refetch: () => void;
  dataSubscription: PrivateChatActivitySubscription | undefined;
  setSearchUsers: (value: string) => void;
  chatsData: any;
  counts: any;
  searchUsers: string | undefined;
};

const ChatContext = createContext<ContextProps>({
  messages: [],
  messagesCount: undefined,
  chatLoading: false,
  chatId: undefined,
  sendMessageLoading: false,
  setMessages: () => {},
  fetchMoreMessages: () => {},
  chatScrollerRef: undefined,
  scrollChatToBottom: () => {},
  sendMessageHandler: () => {},
  deleteMessageHandler: () => {},
  chatRequestError: undefined,
  taskChatRefetch: () => {},
  refetch: () => {},
  dataSubscription: undefined,
  setSearchUsers: () => {},
  counts: undefined,
  chatsData: undefined,
  searchUsers: '',
});
const useChatContext = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChatContext must be used within a ChatProvider');
  }
  return context;
};

const ChatProvider: FC = ({ children }) => {
  const { currentUserData } = useAuthContext();

  const { taskId, subtaskId, privateChatId } = useParams();

  const chatScrollerRef = createRef<ScrollableFeed>();

  const [searchUsers, setSearchUsers] = useState<string>();

  const { debouncedValue } = useDebounce(searchUsers);

  const { chatsData, counts, refetchChatsData } = useNewActivityRefetch(debouncedValue);

  const scrollChatToBottom = useCallback(() => {
    if (chatScrollerRef.current) {
      chatScrollerRef.current.scrollToBottom();
    }
  }, [chatScrollerRef]);

  const [chatRequestError, setChatRequestError] = useState<ApolloError | undefined>();

  const [
    getTaskChatLazyQuery,
    {
      data: taskChatData,
      loading: taskChatDataLoading,
      refetch: taskChatRefetch,
      fetchMore: fetchMoreTaskChat,
      error: taskError,
    },
  ] = useGetTaskChatLazyQuery();

  const [
    getSubtaskChatLazyQuery,
    {
      data: subtaskChatData,
      loading: subtaskChatDataLoading,
      refetch: subtaskChatRefetch,
      fetchMore: fetchMoreSubtaskChat,
      error: subtaskError,
    },
  ] = useGetSubtaskChatLazyQuery();

  const [createPrivateChatMutation] = useCreatePrivateChatMutation({
    variables: {
      userId: Number(privateChatId),
    },
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      getPrivateChatLazyQuery({ variables: { userId: Number(privateChatId) } });
    },
  });

  const [
    getPrivateChatLazyQuery,
    { data: privateChatData, loading: privateChatDataLoading, error: privateChatError },
  ] = useGetPrivateChatLazyQuery({
    fetchPolicy: 'no-cache',
    onError: () => {
      createPrivateChatMutation();
    },
  });

  // independent query to update correctly
  const [getPrivateChatLazyQueryMore] = GENERATED.useGetPrivateChatLazyQuery({
    onError: () => {
      createPrivateChatMutation();
    },
  });

  const [sendMessageMutation, { loading: sendMessageLoading }] = useSendMessageMutation();

  const [deleteMessageMutation] = useDeleteMessageMutation();

  const messagesList = subtaskId
    ? subtaskChatData?.getSubtask?.chat?.messagesList?.rows
    : privateChatId
    ? privateChatData?.getPrivateChat?.messagesList?.rows
    : taskChatData?.getTask?.chat?.messagesList?.rows;

  const chatId = subtaskId
    ? subtaskChatData?.getSubtask?.chat?.id
    : privateChatId
    ? privateChatData?.getPrivateChat?.id
    : taskChatData?.getTask?.chat?.id;

  const initMessagesCount = subtaskId
    ? subtaskChatData?.getSubtask?.chat?.messagesList?.count
    : privateChatId
    ? privateChatData?.getPrivateChat?.messagesList?.count
    : taskChatData?.getTask?.chat?.messagesList?.count;
  const [messagesCount, setMessagesCount] = useState(initMessagesCount);

  useEffect(() => {
    setMessagesCount(initMessagesCount);
  }, [initMessagesCount]);

  useEffect(() => {
    setChatRequestError(
      subtaskId ? subtaskError : privateChatId ? privateChatError : taskError,
    );
    return () => {
      setChatRequestError(undefined);
    };
  }, [subtaskError, privateChatError, taskError]);

  const [messages, setMessages] = useState<any>(messagesList);
  const [chatLoading, setChatLoading] = useState<boolean>(true);

  const { data: newMessageData } = useNewMessageSubscription({
    skip: !chatId,
    variables: { chatId: Number(chatId) },
  });

  const { data: dataSubscription } = usePrivateChatActivitySubscription({
    variables: {
      chatId: Number(chatId),
    },
    skip: !chatId,
  });

  useEffect(() => {
    if (
      dataSubscription?.privateChatActivity?.type === TrackActivityTypeEnum.DeletedMessage
    ) {
      setMessages(
        messages?.filter(
          (e: any) => e?.id !== dataSubscription?.privateChatActivity?.messageId,
        ),
      );
      if (messagesCount && messagesCount >= 1) {
        setMessagesCount(messagesCount - 1);
      }
    }
  }, [dataSubscription]);

  useEffect(() => {
    if (
      dataSubscription?.privateChatActivity?.type === TrackActivityTypeEnum.ChatCleared
    ) {
      refetch();
    }
  }, [dataSubscription]);

  const { data: MessagesReadBeforeSubscriptionData } = useMessagesReadBeforeSubscription({
    skip: !chatId,
    variables: { chatId: Number(chatId) },
  });

  //task refetch when useMessagesReadBeforeSubscription is triggered
  useEffect(() => {
    if (
      MessagesReadBeforeSubscriptionData?.messagesReadBefore?.messageIds &&
      MessagesReadBeforeSubscriptionData?.messagesReadBefore?.messageIds?.length > 0 &&
      MessagesReadBeforeSubscriptionData?.messagesReadBefore?.user?.id !==
        currentUserData?.id
    ) {
      if (subtaskId) {
        subtaskChatRefetch();
      } else if (taskId && !subtaskId) {
        taskChatRefetch();
      } else if (privateChatId) {
        getPrivateChatLazyQuery({ variables: { userId: Number(privateChatId) } }).then(
          () => {
            setMessages(
              messages?.map((e: any) => {
                return { ...e, isRead: true };
              }),
            );
            refetch(); // messagesCount update
          },
        );
      }
    }
  }, [MessagesReadBeforeSubscriptionData?.messagesReadBefore?.messageIds]);

  const [readMessagesBeforeMutation] = useReadMessagesBeforeMutation({
    onCompleted: () => {
      refetchChatsData();
    },
  });

  //just fetchMore and state setting after that
  const fetchMoreMessages = () => {
    if (subtaskId) {
      fetchMoreSubtaskChat({
        variables: {
          offset: Number(messages.length) || 20,
        },
      }).then((response) => {
        setMessages((prevState: any) => [
          ...prevState,
          ...(response?.data?.getSubtask?.chat?.messagesList?.rows || null),
        ]);
      });
    } else if (taskId && !subtaskId) {
      fetchMoreTaskChat({
        variables: {
          offset: Number(messages.length) || 20,
        },
      }).then((response) => {
        setMessages((prevState: any) => [
          ...prevState,
          ...(response?.data?.getTask?.chat?.messagesList?.rows || null),
        ]);
      });
    } else if (!taskId && !subtaskId && privateChatId) {
      getPrivateChatLazyQueryMore({
        variables: {
          userId: Number(privateChatId),
          offset: Number(messages.length) || 20,
        },
      }).then((response: any) => {
        setMessages((prevState: any) => [
          ...prevState,
          ...(response?.data?.getPrivateChat?.messagesList?.rows || null),
        ]);
      });
    }
  };

  //Getting chat depending on the page(task or subtask or privateChatId)
  useEffect(() => {
    setChatLoading(true);
    if (subtaskId) {
      getSubtaskChatLazyQuery().then(() => setChatLoading(false));
    } else if (taskId && !subtaskId) {
      getTaskChatLazyQuery().then(() => setChatLoading(false));
    } else if (!taskId && !subtaskId && privateChatId) {
      getPrivateChatLazyQuery({ variables: { userId: Number(privateChatId) } }).then(() =>
        setChatLoading(false),
      );
    }
    return () => {
      setChatLoading(false);
    };
  }, [subtaskId, taskId, privateChatId]);

  //newMessage subscription useEffect to set messages state and readMessagesBefore mutation
  useEffect(() => {
    if (newMessageData?.newMessage?.id) {
      setMessages((prevState: any) => [newMessageData?.newMessage, ...prevState]);
      setMessagesCount(messagesCount ? messagesCount + 1 : 1);
      if (newMessageData?.newMessage?.user?.login !== currentUserData?.login) {
        readMessagesBeforeMutation({
          variables: { messageId: newMessageData?.newMessage?.id },
        });
      }
    }
  }, [newMessageData]);

  const [initMessageIsTrue, setInitMessageIsTrue] = useState(false);

  //set state when queries end loading
  useEffect(() => {
    if (!taskChatDataLoading && !subtaskChatDataLoading && !privateChatDataLoading) {
      setMessages(messagesList);
      setInitMessageIsTrue(true);
    }
    return () => {
      setMessages([]);
    };
  }, [messagesList, subtaskChatDataLoading, taskChatDataLoading, privateChatDataLoading]);

  useReadInitMessages({
    messages,
    currentUserData,
    readMessagesBeforeMutation,
    setMessages,
    initMessageIsTrue,
    setInitMessageIsTrue,
  });
  const sendMessage = useCallback(
    (message: string) => {
      sendMessageMutation({
        variables: {
          data: {
            chatId: Number(chatId),
            text: message,
          },
        },
      });
    },
    [chatId],
  );

  const deleteMessage = useCallback(
    (messageId: string) => {
      deleteMessageMutation({
        variables: {
          messageId: messageId,
        },
        onCompleted: (res) => {
          if (res.deleteMessage) {
            // fix tread chat
            if (messages) {
              setMessages((prevState: any) => [
                ...prevState.filter((state: any) => state?.id !== messageId),
              ]);
            } else {
              return;
            }

            if (messagesCount && messagesCount >= 1) {
              setMessagesCount(messagesCount - 1);
            }
            toast.success('Сообщение удалено');
          }
        },
      });
    },
    [chatId],
  );

  const sendMessageHandler = useCallback(
    (message: string) => {
      sendMessage(message);
      scrollChatToBottom();
    },
    [scrollChatToBottom, sendMessage],
  );

  const deleteMessageHandler = useCallback(
    (messageId?: string | null) => {
      if (messageId) {
        deleteMessage(messageId);
      }
    },
    [scrollChatToBottom, deleteMessage],
  );

  const refetch = () => {
    if (subtaskId) {
      subtaskChatRefetch();
    } else if (taskId && !subtaskId) {
      taskChatRefetch();
    } else if (privateChatId) {
      getPrivateChatLazyQuery({ variables: { userId: Number(privateChatId) } });
    }
  };

  return (
    <ChatContext.Provider
      value={{
        messages,
        messagesCount,
        chatLoading,
        chatId,
        sendMessageLoading,
        setMessages,
        fetchMoreMessages,
        chatScrollerRef,
        scrollChatToBottom,
        sendMessageHandler,
        deleteMessageHandler,
        chatRequestError,
        taskChatRefetch,
        refetch,
        dataSubscription,
        chatsData,
        counts,
        setSearchUsers,
        searchUsers,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

ChatProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ChatProvider, useChatContext };

const useReadInitMessages = ({
  messages,
  currentUserData,
  readMessagesBeforeMutation,
  setMessages,
  setInitMessageIsTrue,
  initMessageIsTrue,
}: any) => {
  const [disableReadMessage, setDisableReadMessage] = useState(false);

  useEffect(() => {
    setDisableReadMessage(false);
  }, [initMessageIsTrue]);

  useEffect(() => {
    if (!initMessageIsTrue) return;

    setInitMessageIsTrue(false);
    if (disableReadMessage) return;

    if (messages?.length > 0) {
      setDisableReadMessage(true);
    }
    const idReadList: any = [];

    const updateMessage = messages?.map((message: any) => {
      if (message?.user?.id !== currentUserData?.id && !message?.isDidYouRead) {
        idReadList.push(message?.id);
        return { ...message, isDidYouRead: false };
      }
      return message;
    });
    // array messeges
    if (idReadList?.length > 0) {
      readMessagesBeforeMutation({
        variables: { messageId: idReadList },
      });
      setMessages(updateMessage);
    }
  }, [messages, disableReadMessage]);
};
