import React from 'react';

export const AlertCircleIcons = ({
  height = '14',
  width = '14',
  color = '#AFAFAF',
  className,
}: any) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.99992 0.333374C3.3181 0.333374 0.333252 3.31822 0.333252 7.00004C0.333252 10.6819 3.3181 13.6667 6.99992 13.6667C10.6817 13.6667 13.6666 10.6819 13.6666 7.00004C13.6666 3.31822 10.6817 0.333374 6.99992 0.333374ZM7.60598 3.96974C7.60598 3.809 7.54213 3.65485 7.42847 3.54119C7.31481 3.42753 7.16066 3.36368 6.99992 3.36368C6.83918 3.36368 6.68503 3.42753 6.57137 3.54119C6.45771 3.65485 6.39386 3.809 6.39386 3.96974V7.6061C6.39386 7.76684 6.45771 7.92099 6.57137 8.03465C6.68503 8.14831 6.83918 8.21216 6.99992 8.21216C7.16066 8.21216 7.31481 8.14831 7.42847 8.03465C7.54213 7.92099 7.60598 7.76684 7.60598 7.6061V3.96974ZM7.60598 9.72731C7.60598 9.56658 7.54213 9.41242 7.42847 9.29876C7.31481 9.18511 7.16066 9.12125 6.99992 9.12125C6.83918 9.12125 6.68503 9.18511 6.57137 9.29876C6.45771 9.41242 6.39386 9.56658 6.39386 9.72731V10.0303C6.39386 10.1911 6.45771 10.3452 6.57137 10.4589C6.68503 10.5726 6.83918 10.6364 6.99992 10.6364C7.16066 10.6364 7.31481 10.5726 7.42847 10.4589C7.54213 10.3452 7.60598 10.1911 7.60598 10.0303V9.72731Z'
        fill={color}
      />
    </svg>
  );
};
