import './File.scss';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Switch } from '../../components';
import {
  AlertCircleIcons,
  EditIcon2,
  FinishIcons,
  FlagIcons,
  GearsIcons,
  HeaderStorageIcons,
} from '../../components/icons';
import { ROLES } from '../../constants/constants';
import { useAuthContext } from '../../context';
import { textSlice } from '../../utils';

type FileTitleType = {
  updateFileName: () => void;
  file: any;
  setSelectTask: (value: number | null) => void;
  setHideSuccessInner: (value: boolean) => void;
  setHideSuccess: (value: boolean) => void;
  reload: () => void;
  hideSuccess: boolean;
  hideSuccessInner: boolean;
  filterStatus: number | null;
  setFilterStatus: (value: number | null) => void;
  newArr: any;
  handleFonAndTags: () => void;
};

export const FileTitle = ({
  updateFileName,
  file,
  setSelectTask,
  setHideSuccessInner,
  setHideSuccess,
  reload,
  hideSuccess,
  hideSuccessInner,
  filterStatus,
  setFilterStatus,
  newArr,
  handleFonAndTags,
}: FileTitleType) => {
  const selfLocation = location?.pathname?.includes('/file/self/');
  const navigate = useNavigate();

  const { currentUserData } = useAuthContext();

  return (
    <div className='file-list__title'>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
            className='file-list__title-filename'
          >
            <div
              onClick={() => {
                updateFileName();
              }}
            >
              {textSlice({ text: file?.name, count: 60 })}
            </div>
            <HeaderStorageIcons
              color='black'
              className='ml10'
              onClick={() => {
                createTxtFile(newArr, file);
              }}
            />
          </div>
          <div
            className='file-list__title-name'
            onClick={() => {
              if (selfLocation) {
                navigate(`/projectI`);
              } else {
                navigate(`/scale`);
              }
            }}
          >
            #{file?.project?.number} {selfLocation && 'Проект Я'}
            {textSlice({ text: file?.project?.name, count: 85 })}
          </div>
        </div>
        {(currentUserData?.role === ROLES.ADMIN ||
          currentUserData?.role === ROLES.MODERATOR) && (
          <div
            style={{
              marginTop: 'auto',
              marginBottom: '7px',
              marginLeft: '5px',
            }}
          >
            <EditIcon2
              onClick={() => {
                handleFonAndTags();
              }}
            />
          </div>
        )}
      </div>
      <div className='file-list__title-wrapper'>
        <Button
          type={'submit'}
          width={'240px'}
          text={'Обновить'}
          height={'34px'}
          onClick={() => {
            setSelectTask(null);
            setHideSuccessInner(true);
            setHideSuccess(true);
            reload();
          }}
        />
      </div>
      <div className='file-list__title-Checkbox'>
        <Switch value={hideSuccess} handleChange={setHideSuccess} />
        <span className='ml10'>
          {hideSuccess && (
            <Switch value={hideSuccessInner} handleChange={setHideSuccessInner} />
          )}
        </span>
      </div>
      <TaskInfoAndStatus
        file={file}
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
      />
    </div>
  );
};

type TaskInfoAndStatusType = {
  file: any;
  filterStatus: number | null;
  setFilterStatus: (value: number | null) => void;
};

export const TaskInfoAndStatus = ({
  file,
  filterStatus,
  setFilterStatus,
}: TaskInfoAndStatusType) => {
  return (
    <div className='task'>
      <div className='task__count'>
        <div className='task__count-element task__count-all'>
          <div>Всего задач</div>
          <div className='task__count-number'>
            <FlagIcons /> {file?.statistics?.tasksCount}
          </div>
        </div>
        <div className='task__count-element task__count-work'>
          <div>Готово, %</div>
          <div className='task__count-number'>
            <FinishIcons /> {file?.statistics?.completedTasksPercent}
          </div>
        </div>
        <div className='task__count-element task__count-work'>
          <div>В работе, %</div>
          <div className='task__count-number'>
            <GearsIcons /> {file?.statistics?.inProgressTasksPercent}
          </div>
        </div>
        <div className='task__count-element task__count-problem'>
          <div>Проблемы</div>
          <div className='task__count-number'>
            <AlertCircleIcons /> {file?.statistics?.problemTasksCount}
          </div>
        </div>
      </div>
      <div className='line__wrapper'>
        <div
          className='line__success'
          style={{
            width: `${file?.statistics?.completedTasksPercent}%`,
            border: `1px solid ${filterStatus === 1 ? 'black' : 'white'}`,
          }}
          onClick={() => {
            setFilterStatus(1);
          }}
        ></div>
        <div
          className='line__process'
          style={{
            width: `${file?.statistics?.inProgressTasksPercent}%`,
            border: `1px solid ${filterStatus === 2 ? 'black' : 'white'}`,
          }}
          onClick={() => {
            setFilterStatus(2);
          }}
        ></div>
        <div
          className='line__empty'
          style={{
            width: `${file?.statistics?.notInProgressTasksPercent}%`,
            border: `1px solid ${filterStatus === 3 ? 'black' : 'white'}`,
          }}
          onClick={() => {
            setFilterStatus(3);
          }}
        ></div>
        <div
          className='line__problem'
          style={{
            width: `${file?.statistics?.problemTasksPercent}%`,
            border: `1px solid ${filterStatus === 4 ? 'black' : 'white'}`,
          }}
          onClick={() => {
            setFilterStatus(4);
          }}
        ></div>
      </div>
    </div>
  );
};

function createTxtFile(dataLogs: any, fileData: any) {
  let formattedText = '';

  if (dataLogs && Array.isArray(dataLogs)) {
    for (const dataLog of dataLogs) {
      const { col1, col2, col3 } = dataLog;

      col1?.map((col1Item: any) => {
        const col1Id = col1Item?.id;
        const general = col1?.find((e: any) => e.isMain);
        const generalCount = col1?.filter((e: any) => e.isMain)?.length;

        const calc1Item = () => {
          if (generalCount > 1) {
            return 'Есть проблема, главных задач больше 1 в разделе ';
          }
          if (generalCount === 0 || !general) {
            return 'Главная задача отсутствует в разделе ';
          }
          if (general?.id === col1Item?.id) {
            return;
          }
          return `Название блока <${general?.name}> ${fileData?.columnName1} “${col1Item?.name}”`;
        };

        const calc2Item = () => {
          col2?.map((col2Item: any) => {
            const col2Id = col2Item?.id;
            if (col1Id !== col2Item?.scaleMapTaskId) return;
            const item3: any = [];
            col3?.map((col3Item: any) => {
              if (col2Id !== col3Item?.scaleMapTaskId) return;
              item3?.push(col3Item?.name);
            });

            const simbphol = !!item3[0];
            item2?.push(
              ` ${fileData?.columnName2} [${col2Item?.name}${
                simbphol
                  ? `; ${fileData?.columnName3} -`
                  : ' - списка дополнительных действий нету'
              } ${item3.join(', ')}]`,
            );
          });
          if (general?.id === col1Item?.id) {
            return;
          }
          if (item2?.length === 0) {
            combinedString =
              combinedString + item1 + ' - требуемых действий не установлено';
          } else {
            combinedString = combinedString + item1 + item2.join('');
          }
        };

        const item1: any = calc1Item();
        const item2: any = [];
        let combinedString = '';
        calc2Item();
        formattedText += combinedString?.length === 0 ? '' : `${combinedString} \n`;
      });
    }
  }

  const element = document.createElement('a');
  const file = new Blob([formattedText], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = fileData?.name?.replace(/[^a-zA-Zа-яА-Я0-9\s\(\)\[\]{}]+/g, '');
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
