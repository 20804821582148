import './CircleCreateModal.scss';

import { Form, Formik } from 'formik';
import React from 'react';

import { circleUser } from '../../../../validation/schemas';
import { AppInput, Button, SetSimbphol } from '../../../index';

export const CircleCreateModal = ({ modalParameters, onClose }: any) => {
  return (
    <div>
      <Formik
        onSubmit={(e) => {
          modalParameters?.onCallback(e);
          onClose();
        }}
        initialValues={{
          name: '',
          whoIs: '',
          colorToDisplay: 'white',
          usefulnessDescription: '',
          strongPoints: '',
          weakPoints: '',
          letter1: false,
          letter2: false,
          letter3: false,
          letter4: false,
        }}
        validationSchema={circleUser}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form className='CircleCreateModal__outer '>
              <div className='CircleCreateModal'>
                <div style={{ marginBlock: '5px', fontSize: '20px', fontWeight: '600' }}>
                  Создать контакт
                </div>
                <AppInput name='name' label={'Имя:'} />
                <AppInput name='whoIs' label={'Кем является:'} />

                <AppInput name='usefulnessDescription' label='Чем может быть полезен:' />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '20px',
                  }}
                >
                  <label
                    style={{ width: '45%', display: 'flex', flexDirection: 'column' }}
                  >
                    Cильные стороны:
                    <textarea
                      className='CircleCreateModal__textarea'
                      placeholder=''
                      onChange={(e) => {
                        setFieldValue('strongPoints', e.target.value);
                      }}
                      value={values.strongPoints}
                    ></textarea>
                  </label>
                  <label
                    style={{ width: '45%', display: 'flex', flexDirection: 'column' }}
                  >
                    Cлабые стороны:{' '}
                    <textarea
                      className='CircleCreateModal__textarea'
                      placeholder=''
                      onChange={(e) => {
                        setFieldValue('weakPoints', e.target.value);
                      }}
                      value={values.weakPoints}
                    ></textarea>
                  </label>
                </div>
                <div className='CircleCreateModal__buttons'>
                  <Button onClick={onClose}>Отменить</Button>{' '}
                  <Button type='submit'>Сохранить</Button>
                </div>
              </div>
              <SetSimbphol values={values} setFieldValue={setFieldValue} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
