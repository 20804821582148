import { toast } from 'react-toastify';

import * as GENERATED from '../../graphql/generated/graphql';

export const useResetPasswordMutation = (closePasswordModal: () => void) => {
  return GENERATED.useResetPasswordMutation({
    onCompleted: (response) => {
      if (response.resetPassword.status) {
        toast.success('Пароль успешно изменён!');
        closePasswordModal();
      }
    },
  });
};
