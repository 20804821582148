import React from 'react';

export const HeaderMapIcons = ({ className = '', onClick }: any) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M12.5 15.8333L7.5 14.075V4.16667L12.5 5.925M17.0833 2.5H16.95L12.5 4.25L7.5 2.5L2.8 4.08333C2.625 4.14167 2.5 4.29167 2.5 4.48333V17.0833C2.5 17.1938 2.5439 17.2998 2.62204 17.378C2.70018 17.4561 2.80616 17.5 2.91667 17.5C2.95833 17.5 3.00833 17.5 3.05 17.475L7.5 15.75L12.5 17.5L17.2 15.9167C17.375 15.8333 17.5 15.7083 17.5 15.5167V2.91667C17.5 2.80616 17.4561 2.70018 17.378 2.62204C17.2998 2.5439 17.1938 2.5 17.0833 2.5Z'
        fill='white'
      />
    </svg>
  );
};
