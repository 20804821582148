import React from 'react';

type StorageImgWordType = {
  color?: string;
};

export const StorageImgWord = ({ color = 'black' }: StorageImgWordType) => {
  return (
    <svg
      width='16'
      height='20'
      viewBox='0 0 16 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9 7H14.5L9 1.5V7ZM2 0H10L16 6V18C16 18.5304 15.7893 19.0391 15.4142 19.4142C15.0391 19.7893 14.5304 20 14 20H2C1.46957 20 0.960859 19.7893 0.585786 19.4142C0.210714 19.0391 0 18.5304 0 18V2C0 0.89 0.89 0 2 0ZM2 18H14V10L10 14L8 12L2 18ZM4 7C3.46957 7 2.96086 7.21071 2.58579 7.58579C2.21071 7.96086 2 8.46957 2 9C2 9.53043 2.21071 10.0391 2.58579 10.4142C2.96086 10.7893 3.46957 11 4 11C4.53043 11 5.03914 10.7893 5.41421 10.4142C5.78929 10.0391 6 9.53043 6 9C6 8.46957 5.78929 7.96086 5.41421 7.58579C5.03914 7.21071 4.53043 7 4 7Z'
        fill={color}
      />
    </svg>
  );
};
