import './Pagination.scss';

import React, { FC, useState } from 'react';

import { DOTS, taskPaginationLimit } from '../../constants/constants';
import { usePaginationContext } from '../../context';
import { usePagination } from '../../hooks';
import { ArrowLeftIcon } from '../icons';

export const Pagination: FC<PaginationType> = ({
  paginate,
  currentPage,
  totalCount,
  siblingCount,
  pageSize,
  hideArrow = false,
}) => {
  const [activePage, setActivePage] = useState(currentPage ? currentPage : 1);

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const { setPaginationNumber } = usePaginationContext();

  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null;
  }

  const lastPage = paginationRange && paginationRange[paginationRange.length - 1];

  const onNext = () => {
    if (currentPage !== lastPage) {
      paginate(currentPage + 1);
      setActivePage(currentPage + 1);
    }
  };

  const onPrevious = () => {
    if (currentPage !== 1) {
      paginate(currentPage - 1);
      setActivePage(currentPage - 1);
    }
  };

  const handleClickPage = (e: any, pageNumber: string | number) => {
    setPaginationNumber(Number(pageNumber));
    setActivePage(Number(pageNumber));
    paginate(Number(pageNumber));
  };

  const isDisabledNext = activePage === Math.ceil(totalCount / taskPaginationLimit);
  const isDisabledPrev = currentPage === 1;

  return (
    <nav>
      <div className='pagination'>
        {!hideArrow && (
          <ArrowLeftIcon
            color='#303030'
            width='20px'
            height='20px'
            className={`pagination__prev ${
              isDisabledPrev ? 'pagination__prev_disabled' : ''
            }`}
            onClick={!isDisabledPrev ? onPrevious : () => {}}
          />
        )}

        {paginationRange?.map((pageNumber, index) => {
          if (pageNumber === null) {
            return (
              <span
                key={`${pageNumber}${index}`}
                className='pagination__item pagination__item_hide'
              >
                {DOTS}
              </span>
            );
          }

          return (
            <span
              key={pageNumber}
              className={`pagination__item ${
                activePage === Number(pageNumber) ? 'pagination__item_active' : ''
              }`}
              onClick={(e) => handleClickPage(e, pageNumber)}
            >
              {pageNumber}
            </span>
          );
        })}
        {!hideArrow && (
          <ArrowLeftIcon
            color='#303030'
            width='20px'
            height='20px'
            className={`pagination__next ${
              isDisabledNext ? 'pagination__next_disabled' : ''
            }`}
            onClick={!isDisabledNext ? onNext : () => {}}
          />
        )}
      </div>
    </nav>
  );
};

type PaginationType = {
  paginate: (pageNumber: number) => void;
  currentPage: number;
  totalCount: number;
  pageSize: number;
  siblingCount: number;
  hideArrow?: boolean;
};
