import './UsersListItemStorage.scss';

import React from 'react';
import { toast } from 'react-toastify';

import { ROLES } from '../../../constants/constants';
import { useModalContext } from '../../../context';
import {
  useAdminEditUserMutation,
  useGetAllUsersQuery,
} from '../../../graphql/generated/graphql';
import { ModalStatuses } from '../../../types/enums';
import { SmallEditIcons } from '../../icons';
import { UsersListStorageItemType } from '../UsersListStorage';

export type usersListItemProps = {
  id?: number | null;
  login?: string | null;
  role?: string | null;
  updatedAt?: string | null;
  lastEditerUser?: string | null;
  user: UsersListStorageItemType;

  chronographReports?: any;
};

export const UsersListItemStorage = ({ id, login, role, user }: usersListItemProps) => {
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  const { refetch } = useGetAllUsersQuery({
    variables: {
      input: {
        sortType: 'desc',
        sortBy: 'id',
        search: '',
      },
    },
  });
  const [adminEditUserMutation] = useAdminEditUserMutation({
    onCompleted: () => {
      refetch();
    },
  });

  let currentStatusTitle: string;
  switch (role) {
    case 'Admin':
      currentStatusTitle = 'Администратор';
      break;
    case 'Moderator':
      currentStatusTitle = 'Модератор';
      break;
    case 'User':
      currentStatusTitle = 'Пользователь';
      break;
    case 'Guest':
      currentStatusTitle = 'Гость';
      break;
    default:
      currentStatusTitle = '';
  }

  return (
    <div className='userItem'>
      <div className='userItem__col' style={{ width: '5%' }}>
        <p className='userItem__content'>{id}</p>
      </div>
      <div className='userItem__col'>
        <p className='userItem__content'>
          <span>{login}</span>
        </p>
      </div>
      <div className='userItem__col'>
        <p className='userItem__content userItem__content--status'>
          <span
            className={`userItem__statusIndicator userItem__statusIndicator--${role}`}
          />
          <span>{currentStatusTitle}</span>
        </p>
      </div>
      <div
        className='userItem__col'
        style={{
          width: '72%',
        }}
      >
        {role === ROLES.ADMIN || role === ROLES.MODERATOR ? (
          <span className='userItem__textProject'>Все</span>
        ) : (
          <div className='userItem__textProject'>
            {user?.accessibleProjectsStorage?.map((e: any, index: number) => {
              return (
                <div key={e.id} className='userItem__textProject-inner'>
                  <div>
                    {e.name}
                    {user?.accessibleProjectsStorage?.length !== index + 1 && ','}
                  </div>
                </div>
              );
            })}

            <SmallEditIcons
              className='userItem__icons'
              onClick={() => {
                handleSetModalStatus(ModalStatuses.ACCES_STORAGE_MODAL);
                handleChangeModalParameters([
                  {
                    key: 'onCallback',
                    value: (e: any) => {
                      adminEditUserMutation({
                        variables: {
                          data: {
                            idUser: parseInt(user?.id as any) as any,
                            accessibleProjectsStorageIds: e,
                          },
                        },
                      })?.then(() => {
                        toast.success('Доступ к проектам успешно изменен');
                      });
                    },
                  },
                  {
                    key: 'initialValues',
                    value: user?.accessibleProjectsStorage?.map((e) => e?.id),
                  },
                ]);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
