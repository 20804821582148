import './PackageControll.scss';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { DeleteIcon } from '../../../components/icons';
import { AppInput, Button, PickerWrapper } from '../../../components/index';
import { PATHS } from '../../../constants/constants';
import { useModalContext } from '../../../context';
import { useRemoveDecideOnFlyPackageMutation } from '../../../graphql/generated/graphql';
import { ModalStatuses } from '../../../types/enums';

export const PackageControll = ({ values, setFieldValue, idPage }: any) => {
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();
  const navigate = useNavigate();

  const [removeDecideOnFlyPackageMutation] = useRemoveDecideOnFlyPackageMutation({
    onCompleted: () => {
      navigate(PATHS.decideOnTheFlyPackage);
    },
  });

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <div className='packageControll'>
        <div className='packageControll__input'>
          <p className='packageControll__label'>Название пакета</p>
          <AppInput name={'title'} type={'text'} placeholder='Введите название' />
        </div>
        <div className='packageControll__input'>
          <p className='packageControll__label'>Описание пакета</p>
          <AppInput name={'description'} type={'text'} placeholder='Введите описание' />
        </div>
        <PickerWrapper
          color={values?.color}
          setFieldValue={setFieldValue}
          title={'Цвет пакета'}
        />
        {idPage && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '15px',
            }}
          >
            <DeleteIcon
              onClick={() => {
                handleSetModalStatus(ModalStatuses.CONFIRM_DELETION_MODAL);
                handleChangeModalParameters([
                  {
                    key: 'onCallback',
                    value: () => {
                      removeDecideOnFlyPackageMutation({
                        variables: {
                          id: parseInt(idPage),
                        },
                      });
                    },
                  },
                  {
                    key: 'initialValues',
                    value: {
                      title: 'Удалить пакет',
                    },
                  },
                ]);
              }}
            />
          </div>
        )}
        <div
          style={{
            marginLeft: 'auto',
          }}
        >
          {values?.title && (
            <Button backgroundColor='#149939' color='white' type='submit'>
              {idPage ? 'Сохранить' : 'Создать'}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
