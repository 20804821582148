import './TredItemVerbs.scss';
import 'react-tooltip/dist/react-tooltip.css';

import React, { useMemo } from 'react';
import { Tooltip } from 'react-tooltip';

import { useModalContext } from '../../../context';
import { ModalStatuses } from '../../../types/enums';
import { textSlice } from '../../../utils';
import { LongArrowTopIcon, SmallEditIcons } from '../../icons';

export const TredItemVerbs = ({
  scaleMapTask,
  createScaleMapTaskMutation,
  child,
  deleteScaleMapTaskMutation,
  updateScaleMapTaskMutation,
  updateThread,
  id,
  scaleMapTaskForCalendarId,
}: any) => {
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  const addTaskHeader = () => {
    handleSetModalStatus(ModalStatuses.ADD_FILE_TASK_STATUS_MODAL);
    handleChangeModalParameters([
      {
        key: 'onCallback',
        value: (value: { name: string; status: number }) => {
          createScaleMapTaskMutation({
            variables: {
              input: {
                name: value?.name,
                scaleMapTaskId: scaleMapTask?.id,
                statusId: value?.status,
                column: 3,
                row: scaleMapTask?.row ? scaleMapTask?.row : undefined,
              },
            },
          });
        },
      },
      {
        key: 'initialValues',
        value: undefined,
      },
      {
        key: 'col',
        value: 'col3',
      },
    ]);
  };

  const childSort = useMemo(() => {
    return (
      child &&
      JSON?.parse(JSON?.stringify(child))?.sort((a: any, b: any) => {
        return a?.id - b?.id;
      })
    );
  }, [child]);

  return (
    <div className='TredItemVerbs'>
      <div className='TredItemVerbs__header'>
        <span>
          <svg
            width='1'
            height='15'
            viewBox='0 0 1 15'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='pr10'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M0 0H1V15H0V0Z'
              fill='#AFAFAF'
            />
          </svg>
          Глаголы
        </span>

        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          onClick={() => {
            addTaskHeader();
          }}
        >
          <path
            d='M8.17782 7.32951V7.82951H8.67782H14.8332C14.9308 7.82951 15.0018 7.90758 15.0001 7.99386L15 7.99386V8.00366C15 8.09935 14.9217 8.17782 14.8258 8.17782H8.67416H8.17416V8.67782V14.8258C8.17416 14.9215 8.09581 15 8 15C7.90432 15 7.82584 14.9216 7.82584 14.8258V8.67416V8.17416H7.32584H1.17416C1.07848 8.17416 1 8.09581 1 8C1 7.95342 1.01669 7.91196 1.0514 7.87725L1.05144 7.87728L1.05649 7.87209C1.08254 7.84527 1.12333 7.82584 1.17416 7.82584H7.32951H7.82951V7.32584V1.17416C7.82951 1.07848 7.90786 1 8.00367 1C8.09947 1 8.17782 1.07847 8.17782 1.17416V7.32951Z'
            fill='#303030'
            stroke='#303030'
          />
        </svg>
      </div>
      <div className='TredItemVerbs__body'>
        {childSort?.length === 0 && <div>Ещё нет глаголов</div>}
        {childSort?.map((e: any) => {
          const isAttach = scaleMapTaskForCalendarId === e?.id;
          return (
            <label
              key={e.id}
              className='TredItemVerbs__body-item'
              id={`TredItemVerbs${e.id}`}
              htmlFor={`${e.id}`}
            >
              <div
                className='TredItemVerbs__body-item__status'
                style={{
                  backgroundColor: e?.status?.color,
                }}
              ></div>
              {textSlice({ text: e.name, count: 20 })}
              <Tooltip
                id={e.id}
                anchorId={`TredItemVerbs${e.id}`}
                place='bottom'
                content={e.name}
                className='TredItemVerbs__body-tooltop'
              />
              <div className={'TredItemVerbs__body-item__edit'}>
                {scaleMapTaskForCalendarId === e?.id && (
                  <LongArrowTopIcon
                    className='mr5'
                    style={{
                      cursor: 'auto',
                      transform: 'rotate(90deg)',
                      width: '12px',
                    }}
                  />
                )}

                <SmallEditIcons
                  className={'TredItemVerbs__body-item__edit'}
                  onClick={() => {
                    handleSetModalStatus(ModalStatuses.ADD_FILE_TASK_STATUS_MODAL);
                    handleChangeModalParameters([
                      {
                        key: 'onCallback',
                        value: (value: {
                          name: string;
                          status: number;
                          attach: boolean;
                          isUrgently: boolean;
                        }) => {
                          updateScaleMapTaskMutation({
                            variables: {
                              input: {
                                id: e?.id,
                                name: value?.name,
                                scaleMapTaskId: e?.scaleMapTaskId,
                                statusId: value?.status,
                                isUrgently: value?.isUrgently,
                              },
                            },
                          });
                          if (isAttach !== value?.attach) {
                            updateThread({
                              variables: {
                                data: {
                                  id: id,
                                  scaleMapTaskForCalendarId: value?.attach ? e?.id : null,
                                },
                              },
                            });
                          }
                        },
                      },
                      {
                        key: 'onCallbackDelete',
                        value: () => {
                          deleteScaleMapTaskMutation({
                            variables: {
                              id: e?.id,
                            },
                          });
                        },
                      },
                      {
                        key: 'initialValues',
                        value: {
                          name: e?.name,
                          status: e?.status?.id,
                          attach: isAttach,
                          isUrgently: e?.isUrgently,
                          isAttach: true,
                        },
                      },
                      {
                        key: 'col',
                        value: 'col3',
                      },
                    ]);
                  }}
                />
              </div>
            </label>
          );
        })}
      </div>
    </div>
  );
};
