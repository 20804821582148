import './ChronoReportModal.scss';

import React from 'react';

export const ChronoReportModal = ({ modalParameters }: any) => {
  return (
    <div className='chronoReportModal'>
      <div>Дата отчета: {modalParameters?.initialValues?.date}</div>
      <div className='chronoReportModal__text'>
        {modalParameters?.initialValues?.text}
      </div>
    </div>
  );
};
