import { useParams } from 'react-router-dom';

import * as GENERATED from '../../graphql/generated/graphql';

export const useGetSubtaskQuery = () => {
  const { subtaskId } = useParams();
  return GENERATED.useGetSubtaskQuery({
    variables: {
      id: Number(subtaskId),
    },
  });
};
