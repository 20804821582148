import 'quill/dist/quill.snow.css';

import QuillImageDropAndPaste from 'quill-image-drop-and-paste';
import React from 'react';
import { useQuill } from 'react-quilljs';

import { BASE_URL } from '../../constants/constants';
import { useLoadFileMutation } from '../../graphql/generated/graphql';

type EditorType = {
  setValue: (value: any) => void;
  initialValues: string;
  setFocused: (value: boolean) => void;
};

export const Editor = ({ setValue, initialValues, setFocused }: EditorType) => {
  function handleFocus() {
    setFocused(true);
  }

  function handleBlur() {
    setFocused(false);
  }

  const saveToServer = async (file: any, type: any, imageData: any) => {
    const newFile = imageData.toFile();
    const body = new FormData();
    body.append('file', newFile);
    loadFileMutation({
      variables: { data: { file: newFile } },
    });
  };
  const saveToServerEditor = async (file: any) => {
    const body = new FormData();
    body.append('file', file);
    loadFileMutation({
      variables: { data: { file: file } },
    });
  };
  const modules = {
    imageDropAndPaste: {
      // add an custom image handler
      handler: saveToServer,
    },
  };

  const { quill, quillRef, Quill } = useQuill({ modules });
  if (Quill && !quill) {
    // For execute this line only once.
    Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);
  }

  // Insert Image(selected by user) to quill
  const insertToEditor = (url: any) => {
    const range = quill.getSelection();
    quill.insertEmbed(range.index, 'image', url);
  };

  // Upload Image to Image Server such as AWS S3, Cloudinary, Cloud Storage, etc..

  // Open Dialog to select Image File
  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const file = input?.files[0];
      saveToServerEditor(file);
    };
  };

  // set init value
  React.useEffect(() => {
    if (quill && initialValues) {
      quill.clipboard.dangerouslyPasteHTML(initialValues);
    }
  }, [initialValues, quill]);

  React.useEffect(() => {
    if (quill) {
      // Add custom handler for Image Upload
      quill.getModule('toolbar').addHandler('image', selectLocalImage);
      quill.on('text-change', () => {
        setValue(quill.root.innerHTML);
      });
    }
  }, [quill]);
  const [loadFileMutation] = useLoadFileMutation({
    onCompleted: (response: any) => {
      insertToEditor(`${BASE_URL}${response?.loadFile?.path}`);
    },
  });

  return (
    <div onFocus={handleFocus} onBlur={handleBlur}>
      <div ref={quillRef} />
    </div>
  );
};
