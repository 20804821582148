import './DeleteTredsFile.scss';

import React from 'react';

import { Button } from '../../..';

export const DeleteTredsFile = ({ onClose, modalParameters }: any) => {
  return (
    <div className='DeleteTredsFile'>
      <div className='DeleteTredsFile__header'>
        Вы уверены что хотите удалить файл внутри треда?
      </div>
      <div className='DeleteTredsFile__message'>
        Удаленный файл будет невозможно восстановить
      </div>
      <Button
        text='Удалить'
        onClick={() => {
          modalParameters?.onCallback();
          onClose();
        }}
        width='100%'
        height={25}
        color='#FFFFFF'
        backgroundColor='#E80025'
        isCancelButton
      />
      <Button
        text='Отмена'
        onClick={onClose}
        width='100%'
        height={25}
        margin={'20px 0px 0px 0px'}
      />
    </div>
  );
};
