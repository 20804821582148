import './Calendar.scss';

import React, { useState } from 'react';

import {
  AccordionItem,
  CalendarHeaderRow,
  CalendarPicker,
  CalendarRow,
  CalendarRowThred,
  RequestHandler,
  Switch,
} from '../../components';
import { useNewActivityCalendar } from '../../hooks/useNewActivityCalendar';

export const Calendar = () => {
  const [filter, setFilter] = useState(true);

  const {
    data,
    loading,
    changeMonth,
    checkedDate,
    daysCount,
    updateTask,
    updateSubtask,
    dataTred,
    loadingTred,
    updateThreadMutation,
  } = useNewActivityCalendar({ filter });
  return (
    <div className='container container_relative'>
      <div className='calendar__picker'>
        <CalendarPicker changeMonthHandler={changeMonth} />
      </div>
      <div className='calendar'>
        <div className='calendar__list'>
          <h2 className='calendarTable__title'>
            Календарь
            <div className='checkbox-wrapper'>
              <div>
                Только в работе
                <Switch value={filter} handleChange={setFilter} />
              </div>
            </div>
          </h2>
          <div className='calendarTable'>
            <CalendarHeaderRow count={daysCount} checkedDate={checkedDate} />
            <RequestHandler loading={loading || loadingTred}>
              {!data?.getCalendar.calendarProjects.rows.length &&
              !dataTred?.getCalendarScaleMapTasksWithThreads?.projects?.rows.length ? (
                <h1>Запланированых задач нет</h1>
              ) : (
                <>
                  {data?.getCalendar.calendarProjects.rows.map((project) => {
                    if (!project?.tasks?.length) return null;

                    return (
                      <AccordionItem
                        title={`#${project?.number} ${project?.name}`}
                        key={project?.id}
                      >
                        {project?.tasks.map((task) => {
                          return (
                            <CalendarRow
                              key={task?.id}
                              count={daysCount}
                              item={task as any}
                              checkedDate={checkedDate}
                              updateTask={task?.parentId ? updateSubtask : updateTask}
                            />
                          );
                        })}
                      </AccordionItem>
                    );
                  })}
                  {dataTred?.getCalendarScaleMapTasksWithThreads?.projects?.rows?.map(
                    (project, index) => {
                      return (
                        <AccordionItem title={project?.name as string} key={index}>
                          {project?.calendarScaleMapTasks?.map((item, index) => {
                            const calcName = () => {
                              if (
                                item?.thread?.meta?.length &&
                                item?.thread?.meta?.length > 1
                              )
                                return item?.thread?.meta[0]?.name + ' / ';
                              return '';
                            };
                            const calcIsLastCritical = () => {
                              const isCritical = item?.thread?.isCritical;
                              const nextIsCritical =
                                project?.calendarScaleMapTasks?.[index + 1]?.thread
                                  ?.isCritical;
                              return isCritical && !nextIsCritical;
                            };

                            const showRedLine = calcIsLastCritical();

                            const statusCalendarComplete =
                              item?.thread?.scaleMapTaskForCalendar?.status?.id === 1;
                            const tags =
                              item?.parentTags?.length > 0
                                ? item?.parentTags
                                : item?.thread?.tags;

                            const itemConvert = {
                              id: item?.thread?.id,
                              status: item?.status,
                              startDate: item?.thread?.startDate,
                              endDate: item?.thread?.endDate,
                              closeDate: item?.thread?.closeDate,
                              name: calcName(),
                              name2: item?.name,
                              name3: statusCalendarComplete
                                ? undefined
                                : item?.thread?.scaleMapTaskForCalendar?.name,
                              tags: {
                                rows: tags,
                              },
                              task: null,
                              maintainer: item?.thread?.mainPerformer,
                              tread: true,
                              isUrgently:
                                item?.thread?.scaleMapTaskForCalendar?.isUrgently,
                              textFileColor: item?.parentCalendarTaskTextColor
                                ? item?.parentCalendarTaskTextColor
                                : item?.scaleMapFile?.calendarTaskTextColor,
                            };

                            return (
                              <>
                                <CalendarRowThred
                                  key={itemConvert?.id}
                                  count={daysCount}
                                  item={itemConvert as any}
                                  checkedDate={checkedDate}
                                  updateTask={updateThreadMutation}
                                  showRedLine={showRedLine}
                                />
                                {showRedLine && (
                                  <div
                                    style={{
                                      borderBottom: '2px solid red',
                                      margin: '2px 0px',
                                    }}
                                  ></div>
                                )}
                              </>
                            );
                          })}
                        </AccordionItem>
                      );
                    },
                  )}
                </>
              )}
            </RequestHandler>
          </div>
        </div>
      </div>
    </div>
  );
};
