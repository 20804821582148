import { useRef, useState } from 'react';

import { cancelUpdateType } from '../types/enums';
import {
  multiselectToolsDragMoveType,
  multiselectToolsDragStartType,
  multiselectToolsMouseDownType,
  multiselectToolsMouseMoveType,
  RectType,
} from '../types/types';

type useMultiselectPolygonType = {
  rectangles: Array<RectType>;
  setRectangles: (value: Array<RectType>) => void;
  selectedIdRect: string | number | null;
  setCancelUpdate: (value: cancelUpdateType) => void;
};

export const useMultiselectPolygon = ({
  rectangles,
  setRectangles,
  selectedIdRect,
  setCancelUpdate,
}: useMultiselectPolygonType) => {
  const selectionRectRef = useRef<any>();
  const trRef = useRef<any>();
  const oldPos = useRef<any>();
  const layerRef = useRef<any>();

  const [multiselectId, setMultiselectId] = useState<Array<string>>([]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const Konva = window.Konva as any;

  const selection = useRef({
    visible: false,
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  });

  /// watch move START ///
  const [oldX, setOldX] = useState(0);
  const [oldY, setOldY] = useState(0);

  const updateSelectionRect = () => {
    const node = selectionRectRef.current as any;
    node.setAttrs({
      visible: selection.current.visible,
      x: Math.min(selection.current.x1, selection.current.x2),
      y: Math.min(selection.current.y1, selection.current.y2),
      width: Math.abs(selection.current.x1 - selection.current.x2),
      height: Math.abs(selection.current.y1 - selection.current.y2),
      fill: 'rgba(0, 161, 255, 0.3)',
    });
    node.getLayer().batchDraw();
  };

  const multiselectTools = {
    onMouseDown: ({ event }: multiselectToolsMouseDownType) => {
      if (!event.evt.shiftKey) {
        if (event.target?.attrs?.container) {
          trRef.current.nodes([]);
          setMultiselectId([]);
          setCancelUpdate(cancelUpdateType.UPDATE);
        }
        return;
      }

      const pos = event?.target?.getStage()?.getPointerPosition() as any;
      selection.current.visible = true;
      selection.current.x1 = pos.x;
      selection.current.y1 = pos.y;
      selection.current.x2 = pos.x;
      selection.current.y2 = pos.y;
      updateSelectionRect();
    },
    onMouseMove: ({ event }: multiselectToolsMouseMoveType) => {
      if (!selection.current.visible) {
        return;
      }
      const pos = event?.target?.getStage()?.getPointerPosition() as any;
      selection.current.x2 = pos.x;
      selection.current.y2 = pos.y;
      updateSelectionRect();
    },
    onMouseUp: () => {
      oldPos.current = null;
      if (!selection.current.visible) {
        return;
      }
      const selBox = selectionRectRef.current.getClientRect();
      const elements: any = [];
      layerRef.current.find('.rectangle').forEach((elementNode: any) => {
        const elBox = elementNode.getClientRect();
        if (Konva.Util.haveIntersection(selBox, elBox)) {
          elements.push(elementNode);
        }
      });
      setMultiselectId(
        elements?.map((e: any) => {
          return e.attrs.id.split('/')[0];
        }),
      );

      trRef.current.nodes(elements);
      selection.current.visible = false;
      // disable click event
      Konva.listenClickTap = false;
      updateSelectionRect();
    },
    dragMove: ({ event }: multiselectToolsDragMoveType) => {
      if (multiselectId.length <= 1) return;
      setOldX(event.target.x());
      setOldY(event.target.y());
      const xCurrent = event.target.x();
      const yCurrent = event.target.y();

      const diffX = xCurrent - oldX;
      const diffY = yCurrent - oldY;

      const moveRect = JSON.parse(JSON.stringify(rectangles));

      if (multiselectId.indexOf(`${event.target.attrs.id}`) === -1) return;

      rectangles.map((rect: any, index: number) => {
        if (multiselectId.indexOf(`${rect.id}`) !== -1) {
          if (selectedIdRect !== rect.id) {
            moveRect[index].x = moveRect[index].x + diffX;
            moveRect[index].y = moveRect[index].y + diffY;
          }
        }
      });
      setRectangles(moveRect);
    },
    dragStart: ({ event }: multiselectToolsDragStartType) => {
      if (multiselectId.length <= 1) return;
      const xCurrent = event.target.x();
      const yCurrent = event.target.y();
      setOldX(xCurrent);
      setOldY(yCurrent);
      setCancelUpdate(cancelUpdateType.MULTISELECT_CANCEL);
    },
    dragEnd: () => {},
  };

  return { selectionRectRef, trRef, selection, multiselectTools, layerRef };
};
