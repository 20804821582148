import './NoFormikInput.scss';

import React from 'react';

type AppInputPropsType = {
  placeholder?: string;
  label?: string;
  type?: string;
  disabled?: boolean;
  width?: string | number;
  error?: string;
  value: any;
  setValue: any;
};

export const NoFormikInput: React.FC<AppInputPropsType> = ({
  type = 'text',
  label,
  placeholder,
  disabled = false,
  value,
  setValue,
}) => {
  return (
    <div className={'appInput__wrapper '}>
      {label && <p className='appInput__label'>{label}</p>}
      <input
        value={value}
        type={type}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        className='appInput__field'
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  );
};
