import * as yup from 'yup';

import * as MESSAGES from '../messages';

export const addFileUserSchema = yup.object().shape({
  fileName: yup.string().when('id', {
    is: (val: any) => !val,
    then: yup
      .string()
      .min(3, MESSAGES.INCORRECT_MIN_LENGTH_3)
      .max(30, MESSAGES.INCORRECT_MAX_LENGTH_30)
      .required(MESSAGES.REQUIRE_MESSAGE),
    otherwise: yup.string(),
  }),
  id: yup.number(),
});
