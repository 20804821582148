import { gql } from '@apollo/client';

export const GET_THREADS_AND_COUNT_BY_USER = gql`
  query getThreadsAndCountByUser($data: GetThreadAndCountInput) {
    getThreadsAndCountByUser(data: $data) {
      rows {
        id
        createdAt
        updatedAt
        authorId
        cover
        author {
          id
          login
          image
        }
        notes {
          id
        }
        threadBackground {
          id
          backgroundColor
        }
        statistic {
          performerAcceptedPercent
        }
        scaleMapTask {
          id
          name
          status {
            id
            name
            color
          }
          children {
            id
            name
            status {
              color
            }
          }
        }
        performers {
          threadId
          userId
          status
          user {
            id
            login
            image
            IsExistUnViewedForum
            role
            fullName
          }
        }
        mainPerformer {
          id
          login
          image
          IsExistUnViewedForum
          role
          fullName
        }
        mainPerformerId
        tags {
          id
          name
          color
          textColor
        }
        performerStatus
        meta {
          id
          name
          isMain
          isCurrent
          status {
            color
          }
        }
        userLastEdited {
          id
          login
          tags {
            rows {
              id
              name
              color
              textColor
            }
          }
        }
      }
      count
    }
  }
`;
