import * as yup from 'yup';

import * as MESSAGES from '../messages';

export const weeklyPlan = yup.object().shape({
  sampleItems: yup
    .array()
    .min(1, 'At least one sample item is required')
    .of(
      yup.object().shape({
        color: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
        scaleMapFileId: yup.number().required(MESSAGES.REQUIRE_MESSAGE),
        scheduledTimePerDay: yup.number(),
      }),
    )
    .required('Required'),
});
