import React from 'react';

export const Arrow = ({ refArrow }: any) => {
  return (
    <div>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-105%, -50%)',
          width: '300px',
          height: '3px',
          zIndex: '-1',
        }}
        ref={refArrow}
      ></div>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='83'
        height='46'
        viewBox='0 0 83 46'
        fill='none'
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-105%, -50%)',
        }}
      >
        <path
          d='M5.84427 18.6755C1.72888 19.6409 1.72893 25.4988 5.84427 26.4641L76.0865 42.9407C78.5966 43.5295 81 41.6247 81 39.0464L81 6.09325C81 3.51497 78.5966 1.61016 76.0865 2.19895L5.84427 18.6755Z'
          fill='#149939'
          stroke='white'
          strokeWidth='4'
          strokeLinejoin='round'
        />
      </svg>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='43'
        height='43'
        viewBox='0 0 43 43'
        fill='none'
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <circle
          cx='21.0703'
          cy='21.5698'
          r='19'
          fill='#0D0D0D'
          stroke='white'
          strokeWidth='4'
        />
      </svg>
    </div>
  );
};
