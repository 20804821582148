import moment from 'moment-timezone';

export const datesOfWeek = (weeksToAdd = 0, timeZone = 'UTC') => {
  const today = moment().tz(timeZone);
  const monday = today.clone().startOf('isoWeek').add(weeksToAdd, 'weeks');
  const sunday = today.clone().endOf('isoWeek').add(weeksToAdd, 'weeks');

  const fromFormatted = monday.format('DD.MM.YYYY');
  const toFormatted = sunday.format('DD.MM.YYYY');

  return {
    from: fromFormatted,
    to: toFormatted,
    fromUTC: monday.toISOString(),
    toUTC: sunday.toISOString(),
  };
};
