import React from 'react';
import { toast } from 'react-toastify';

import { SortingField, SortingOrders } from '../../../../graphql/generated/graphql';
import { GET_EXPENSES } from '../../../../graphql/queries/getExpenses.gql';
import { useExpenses } from '../../../../hooks';
import {
  useResetRealizationPriceMutation,
  useUpdateTaskMutation,
} from '../../../../hooks/useMutations';
import { addPriceSchema } from '../../../../validation/schemas';
import { SimpleFormModal } from '../../../simpleFormModal/SimpleFormModal';

type PriceTaskModalType = {
  isOpen: boolean;
  onClose: () => void;
  modalParameters?: any;
};

export const PriceTaskModal = ({
  isOpen,
  onClose,
  modalParameters,
}: PriceTaskModalType) => {
  const { entityId, realizationPrice } = modalParameters.initialValues;
  const [resetRealizationPrice] = useResetRealizationPriceMutation(onClose);
  const { date } = useExpenses();
  const [updateTask] = useUpdateTaskMutation();

  const refetchExpensesQueryParams = {
    query: GET_EXPENSES,
    variables: {
      expensesDate: date?.toISOString().substr(0, 7),
      data: {
        expensesDate: date?.toISOString().substr(0, 7),
        limit: 100,
        statusId: null,
        sort: { field: SortingField.Number, type: SortingOrders.Asc },
      },
    },
  };
  const handleSubmit = async (values: any) => {
    if (entityId && values.taskPrice) {
      await updateTask({
        variables: {
          id: Number(entityId),
          data: {
            realizationPrice: values.taskPrice,
          },
        },
        refetchQueries: [refetchExpensesQueryParams],
        onCompleted: (res) => {
          if (res.updateTask.name) {
            onClose();
            toast.success(
              `Добавлена стоимость реализации для задачи ${res.updateTask.name}`,
            );
          }
        },
      });
    }
  };

  const handleResetPrice = async () => {
    if (entityId) {
      await resetRealizationPrice({
        variables: {
          taskId: entityId,
        },
        refetchQueries: [refetchExpensesQueryParams],
      });
    }
  };
  return (
    <SimpleFormModal
      isOpen={isOpen}
      onClose={onClose}
      initialValues={{ taskPrice: realizationPrice || '' }}
      validationSchema={addPriceSchema}
      onSubmit={handleSubmit}
      onReset={handleResetPrice}
      inputName='taskPrice'
      inputType='number'
      labelText='Стоимость реализации в $'
      buttonText='Добавить стоимость'
      resetButtonText='Обнулить стоимость'
      buttonType='submit'
    />
  );
};
