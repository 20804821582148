import './SendPositionIcon.scss';

import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const SendPositionIcon: FC<SendPositionIconType> = ({
  width = '34px',
  height = '20px',
  className = 'send-position-icon',
  color = '#FFFFFF',
  arrowColor = '#FFFFFF',
  onClick,
  isBordered = false,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 34 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      style={isBordered ? { borderLeft: '1px solid #AFAFAF' } : {}}
    >
      <path
        d='M14.1667 15.8333L10 11.6666L11.1667 10.5L13.3333 12.6666V4.16663H15V12.6666L17.1667 10.5L18.3333 11.6666L14.1667 15.8333Z'
        fill={color}
      />
      <path
        d='M5.83333 4.16671L10 8.33337L8.83333 9.50004L6.66667 7.33337L6.66667 15.8334L5 15.8334L5 7.33337L2.83333 9.50004L1.66667 8.33337L5.83333 4.16671Z'
        fill={color}
      />
      <path
        d='M30.9783 8.61917L30.9783 8.61912L30.9725 8.62485L28.0801 11.4708C28.0604 11.4888 28.0335 11.5 28.0001 11.5C27.9663 11.5 27.9393 11.4879 27.922 11.4719L25.0285 8.62466L25.0285 8.62461L25.0219 8.61831C25.0032 8.60053 25.0012 8.58956 25.0006 8.58634L25.0006 8.58633C24.9995 8.58023 24.9995 8.56911 25.0057 8.55469C25.012 8.54026 25.0227 8.527 25.0367 8.51759C25.0489 8.50944 25.0694 8.5 25.105 8.5H30.8957C30.9307 8.5 30.9509 8.50927 30.9629 8.51738C30.9769 8.52678 30.9877 8.54012 30.9941 8.55483C31.0005 8.56953 31.0006 8.58096 30.9994 8.58727C30.9988 8.59068 30.9968 8.60157 30.9783 8.61917Z'
        fill={arrowColor}
        stroke={arrowColor}
      />
    </svg>
  );
};

type SendPositionIconType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
> & { isBordered?: boolean; arrowColor?: string };
