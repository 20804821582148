import * as yup from 'yup';

import * as MESSAGES from '../messages';

export const addMessage = yup.object().shape({
  description: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  image: yup.mixed().test('fileType', 'Unsupported File Type', (value) => {
    if (typeof value === 'string') return true;
    if (!value) return true;
    const allowedTypes = ['image/jpeg', 'image/png', 'image/svg+xml'];
    return allowedTypes.includes(value.type);
  }),
});
