import React from 'react';

export const NotificationSubscribeIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21 18.2602L20.7946 18.0751C20.212 17.5448 19.702 16.9368 19.2785 16.2674C18.816 15.3435 18.5387 14.3345 18.4631 13.2997V10.2519C18.4671 8.6266 17.8899 7.05572 16.8399 5.83443C15.7899 4.61314 14.3395 3.82552 12.7611 3.61954V2.82365C12.7611 2.6052 12.6761 2.39571 12.5249 2.24124C12.3737 2.08678 12.1686 2 11.9547 2C11.7408 2 11.5357 2.08678 11.3845 2.24124C11.2333 2.39571 11.1483 2.6052 11.1483 2.82365V3.63188C9.58405 3.85271 8.15112 4.64509 7.11495 5.86228C6.07878 7.07946 5.50957 8.63896 5.51275 10.2519V13.2997C5.4371 14.3345 5.15987 15.3435 4.69732 16.2674C4.28107 16.9351 3.77931 17.5431 3.20537 18.0751L3 18.2602V20H21V18.2602Z'
        fill='#303030'
      />
      <path
        d='M10 21C10.0668 21.2772 10.3061 21.5311 10.6737 21.7149C11.0414 21.8987 11.5124 22 12 22C12.4876 22 12.9586 21.8987 13.3263 21.7149C13.6939 21.5311 13.9332 21.2772 14 21H10Z'
        fill='#303030'
      />
    </svg>
  );
};
