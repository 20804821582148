import './AddProject.scss';

import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { useCreateProjectMutation } from '../../hooks/useMutations';
import { getErrorText } from '../../utils';
import { addProjectSchema } from '../../validation/schemas';
import { BigPlusIcons } from '../icons';
import { SimpleFormModal } from '../simpleFormModal/SimpleFormModal';

type AddProjectInputValues = {
  title?: string | undefined;
};

const initialValues = {
  title: '',
};

export const AddProject = ({ refetch }: { refetch: () => void }) => {
  const [createProject] = useCreateProjectMutation({ refetch });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCreateProject = async (values: AddProjectInputValues) => {
    if (values.title) {
      createProject({
        variables: {
          data: {
            name: values.title,
          },
        },
        onError: (error) => {
          toast.error(getErrorText(error.message));
        },
        onCompleted: () => {
          setIsModalOpen(false);
          toast.success('Проект успешно создан!');
        },
      });
    }
  };
  return (
    <div>
      <div className='AddProject' onClick={() => setIsModalOpen(true)}>
        <BigPlusIcons />
      </div>
      <SimpleFormModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleCreateProject}
        validationSchema={addProjectSchema}
        initialValues={initialValues}
        buttonText='Добавить проект'
        buttonType='submit'
        inputName='title'
        labelText='Название проекта:'
      />
    </div>
  );
};
