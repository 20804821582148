import { useLocation, useParams } from 'react-router-dom';

import * as GENERATED from '../../graphql/generated/graphql';

export const useGetTaskQuery = ({ ...options }) => {
  const { taskId } = useParams();

  const location = useLocation();

  const archivedTask = location.pathname.includes('archived');
  return GENERATED.useGetTaskQuery({
    ...options,
    variables: !archivedTask
      ? { id: Number(taskId) }
      : { id: Number(taskId), subtaskStatusId: 8 },
  });
};
