import React, { FC } from 'react';

export const TableCell: FC<TableCellType> = ({ children, width, background }) => {
  return (
    <td style={{ width, background }}>
      <div>{children}</div>
    </td>
  );
};

type TableCellType = {
  width?: string | number | undefined;
  background?: string;
};
