import './CalendarRow.scss';

import React from 'react';
import { Link } from 'react-router-dom';

import { ROLES } from '../../../constants/constants';
import { useAuthContext } from '../../../context';
import { TagList } from '../../../graphql/generated/graphql';
import { afterBefore, getCalendarDaysArray, getWorkDay2 } from '../../../utils';
import {
  CalendarTimeIcons,
  FlagCalendarIcon,
  PersonalIcons,
  WarningCalendarIcon,
} from '../../icons';

export const CalendarRow = ({
  count,
  item,
  checkedDate,
  updateTask,
}: CalendarRowPropTypes) => {
  const days = getCalendarDaysArray({
    endDate: item?.endDate,
    count: count,
    checkedDate,
  });
  const { currentUserData } = useAuthContext();
  const isBlue = item?.status?.id === 5;
  // with this tag '[Need] - Reactions' paint red
  const retFlagTag = !!item?.tags.rows?.find((e) => {
    return e?.name === '[Need] - Reactions';
  });

  const participant = item?.maintainer?.id === currentUserData?.id;

  return (
    <div
      key={item?.parentId}
      className='calendarTable__row'
      style={retFlagTag ? { backgroundColor: 'rgba(255, 0, 0, 0.5)' } : {}}
    >
      <div
        className={`calendarTable__rowTitle ${
          item?.parentId ? 'calendarTable__rowTitle_isSub' : ''
        }`}
      >
        <div className='calendarTable__rowTitle--tags tagsList'></div>
        <div className='linkWrap'>
          <Link
            to={
              item?.parentId
                ? `/task/${item?.parentId}/subtask/${item?.id}`
                : `/task/${item?.id}`
            }
          >
            {participant && <PersonalIcons />}
            <div>{`#${item?.code} ${item?.name}`}</div>
          </Link>
        </div>
      </div>
      <div className='calendarTable__days'>
        {days.map((day, index) => {
          const afterBeforeColor = item?.tags?.rows[0]?.color
            ? item?.tags?.rows[0]?.color
            : item?.status?.color;

          const { background, color, isEndDay, isStartDay, rowDateYMD, today } =
            afterBefore({
              day,
              currentDay: index + 1,
              checkedDate,
              color: afterBeforeColor,
              item,
            });
          const workDat2 = getWorkDay2({
            checkedDate,
            day,
            item,
            currentDay: index + 1,
            isEndDay,
            isStartDay,
          });

          const borderRight = borderRightCalc({ background, today, workDat2 });

          return (
            <div
              key={day.id}
              className='calendarTable__row__day'
              style={
                workDat2
                  ? {
                      background: background,
                      borderRadius: `${isStartDay ? '6px' : '0px'} 
                      ${isEndDay ? '6px' : '0px'} 
                      ${isEndDay ? '6px' : '0px'} 
                      ${isStartDay ? '6px' : '0px'}`,
                      borderRight: `${borderRight}`,
                    }
                  : { borderRight: `${borderRight}` }
              }
              onClick={() => {
                if (currentUserData?.role === ROLES.ADMIN) {
                  updateTask({
                    variables: {
                      id: item?.id,
                      data: {
                        endDate: rowDateYMD,
                      },
                    },
                  });
                }
              }}
            >
              {day?.deadlineDay && (
                <div
                  style={{
                    backgroundColor: color,
                  }}
                  className='calendarTable__closeItem'
                >
                  <>
                    {isBlue && item?.closeDate && <CalendarTimeIcons />}
                    {item?.status?.id === 2 ? (
                      <FlagCalendarIcon />
                    ) : (
                      !isBlue && <WarningCalendarIcon />
                    )}
                  </>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

type ItemType = {
  id: number;
  status?: {
    id: number;
    color: string;
  } | null;
  endDate?: string | null;
  startDate?: any | null;
  closeDate?: any | null;
  parentId?: number | null;
  name: string;
  code?: string | null;
  tags: TagList;
  task?: {
    id: number;
    name: string;
  } | null;
  assignees: any;
  maintainer: any;
};

type CalendarRowPropTypes = {
  count: number;
  item: ItemType | null;
  checkedDate: Date;
  updateTask: any;
};

const borderRightCalc = ({ today }: any) => {
  if (today) return '2px solid #303030';
  return '1px solid #afafaf';
};
