import './SerchInput.scss';

import React, { ChangeEvent } from 'react';

import { SearchIcons } from '../icons';

type searchProps = {
  handleSearch: (value: string) => void;
  placeholder: string;
  value: string;
};

export const SerchInput = ({ handleSearch, value, placeholder = '' }: searchProps) => {
  const handleChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    handleSearch(e.target.value);
  };

  return (
    <div className='serch-input'>
      <label>
        <SearchIcons />
        <input
          className='serch-input__input'
          type='text'
          value={value}
          onChange={handleChangeSearchValue}
          placeholder={placeholder}
        />
      </label>
    </div>
  );
};
