import './Updates.scss';

import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { PATHS, ROLES } from '../../constants/constants';
import { useAuthContext } from '../../context';
import { useNewActivityRefetch } from '../../hooks';
import { useReadProjectActivitiesMutation } from '../../hooks/useMutations';
import { addKarma } from '../../utils';
import {
  ArrowRightIcon,
  CheckAllIcon,
  UpdateIcons,
  UserStatisticIconsReverse,
} from '../icons';
import { MenuLeftItem } from '../menu-left/menuLeftItem/MenuLeftItem';

export const Updates: FC = () => {
  const { tasksSubtasksActivities } = useNewActivityRefetch();
  const [readProjectActivities] = useReadProjectActivitiesMutation();
  const { currentUserData } = useAuthContext();
  return (
    <div className='menu__updates'>
      <div className='icons-row__wrapper-outer'>
        {(currentUserData?.role === ROLES.ADMIN ||
          currentUserData?.role === ROLES.MODERATOR) && (
          <Link to={PATHS.userStatistic}>
            <div
              className='icons-row__wrapper-inner'
              style={{
                backgroundColor: location.pathname.includes('/users-statistic')
                  ? '#efefef'
                  : 'white',
              }}
            >
              <div className='icons-row__text'>
                <UserStatisticIconsReverse />
                <div className='ml10'> Аналитика юзеров</div>
              </div>
              <ArrowRightIcon />
            </div>
          </Link>
        )}
      </div>
      <div className='left-title'>
        <div className='menu__title' style={{ justifyContent: 'flex-start' }}>
          <UpdateIcons />
          <span className='menu__title-name'>Обновления</span>
          {Math.sign(Number(tasksSubtasksActivities?.length)) ? (
            <div className='menu__title-count-container'>
              <span className='menu__title-count'>
                {Number(tasksSubtasksActivities?.length)}
              </span>
              <CheckAllIcon onClick={readProjectActivities} color='#303030' />
            </div>
          ) : null}
        </div>
      </div>

      <div className='menu__content'>
        {tasksSubtasksActivities?.[0] ? (
          tasksSubtasksActivities?.map((el, i) => {
            if (!el) return;
            const calcMeta = () => {
              if (el?.meta?.length > 1) {
                return el?.meta[0]?.name;
              }
              return null;
            };
            return (
              <MenuLeftItem
                key={i}
                projectName={el?.project?.name}
                avatar={el?.actioner?.image}
                userName={addKarma(el?.actioner)}
                code={el?.code}
                itemName={el?.name}
                pathname={el?.__typename === 'Thread' ? `/tread/${el?.id}` : el?.pathname}
                metaName={calcMeta()}
              />
            );
          })
        ) : (
          <p className='empty__updates-list'>Новых обновлений нет...</p>
        )}
      </div>
    </div>
  );
};
