import './ChronoEnergyModal.scss';

import { Form, Formik } from 'formik';
import moment from 'moment';
import React from 'react';

import { Button } from '../../../index';

export const ChronoEnergyModal = ({ onClose, modalParameters }: any) => {
  const filteredTaskDuplicate = modalParameters.selectHourData.filter(
    (obj: any, index: any, arr: any) => {
      return (
        !index ||
        !arr
          .slice(0, index)
          .some((prevObj: any) => prevObj.scaleMapFileId === obj.scaleMapFileId)
      );
    },
  );

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ energy: '1' }}
      onSubmit={(value) => {
        modalParameters.onCallback(value);
        onClose();
      }}
    >
      {({ setFieldValue, values }) => {
        return (
          <Form className='chronoEnergyModal'>
            <div className='chronoEnergyModal__title'>Фиксирование времени</div>
            <div className='chronoEnergyModal__date'>
              <span className='chronoEnergyModal__time'>
                {modalParameters?.diapason?.map((e: any) => {
                  return `${e.range}, `;
                })}
              </span>{' '}
              {moment(modalParameters.selectDay).format('DD.MM.YYYY')}
            </div>
            <div className='mt30'>
              <div className='chronoEnergyModal__energy'>Энергия:</div>
              <div className='chronoEnergyModal__button-wrapper'>
                <div
                  onClick={() => {
                    setFieldValue('energy', '1');
                  }}
                  className={`chronoEnergyModal__button-positive ${
                    values.energy === '1' && 'chronoEnergyModal__button-positive__select'
                  }`}
                >
                  Получена
                </div>
                <div
                  onClick={() => {
                    setFieldValue('energy', '-1');
                  }}
                  className={`chronoEnergyModal__button-negative  ${
                    values.energy === '-1' && 'chronoEnergyModal__button-negative__select'
                  }`}
                >
                  Потрачена
                </div>
              </div>
            </div>
            <div>
              <div className='chronoEnergyModal__tasks'>Задача(и):</div>
              <div>
                {filteredTaskDuplicate?.map((e: any) => {
                  return (
                    <div
                      key={e?.scaleMapFileId}
                      className='chronoEnergyModal__tasks-element'
                    >
                      {e?.scaleMapFile?.name
                        ? e?.scaleMapFile?.name
                        : 'не отмеченное время'}
                    </div>
                  );
                })}
              </div>
            </div>
            <Button
              text={'Сохранить'}
              type='submit'
              width='100%'
              height='25px'
              margin='20px 0 0 0'
              backgroundColor='green'
              color='white'
            />
            <Button
              onClick={() => {
                modalParameters.onCallback({ energy: null });
                onClose();
              }}
              text={'Очистить'}
              width='100%'
              height='25px'
              margin='20px 0 0 0'
            />
          </Form>
        );
      }}
    </Formik>
  );
};
