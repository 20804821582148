import React from 'react';

type PersonalIconsType = {
  className?: string;
  fill?: string;
};

export const PersonalIcons = ({ className, fill = '#730E00' }: PersonalIconsType) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M9.99984 0.833374C12.3019 0.833374 14.1665 2.65652 14.1665 4.90745C14.1665 7.15837 12.3019 8.98152 9.99984 8.98152C7.69775 8.98152 5.83317 7.15837 5.83317 4.90745C5.83317 2.65652 7.69775 0.833374 9.99984 0.833374ZM14.1665 11.5686C14.1665 12.6482 13.8748 15.1639 11.8853 17.975L11.0415 13.0556L12.0207 11.1408C11.3748 11.0695 10.6978 11.0186 9.99984 11.0186C9.30192 11.0186 8.62484 11.0695 7.979 11.1408L8.95817 13.0556L8.11442 17.975C6.12484 15.1639 5.83317 12.6482 5.83317 11.5686C3.34359 12.2815 1.6665 13.5649 1.6665 15.0926V19.1667H18.3332V15.0926C18.3332 13.5649 16.6665 12.2815 14.1665 11.5686Z'
        fill={fill}
      />
    </svg>
  );
};
