import './ForumTreadsNavigation.scss';

import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ROLES } from '../../constants/constants';
import { useAuthContext, useModalContext } from '../../context/';
import { GetForumSectionsQuery } from '../../graphql/generated/graphql';
import { ModalStatuses } from '../../types/enums';
import { AlertCircleIcons, CogwheelIcon, Plus2Icons } from '../icons';

type ForumTreadsNavigationType = {
  dataSection: GetForumSectionsQuery;
  refetchSection: () => void;
};

export const ForumTreadsNavigation = ({
  dataSection,
  refetchSection,
}: ForumTreadsNavigationType) => {
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();
  const { currentUserData } = useAuthContext();
  const location = useLocation();

  const { sectionId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (sectionId === ':sectionId' && dataSection?.getForumSections?.rows[0]?.id) {
      navigate('/forum/' + dataSection?.getForumSections?.rows[0]?.id);
    }
  }, [dataSection, sectionId]);

  // for notification forum
  useEffect(() => {
    refetchSection();
  }, [location]);

  const selected = parseInt(sectionId as any);

  return (
    <div className='ForumTreadsNavigation'>
      {dataSection?.getForumSections?.rows.map((e) => {
        return (
          <div
            key={e?.id}
            className='ForumTreadsNavigation__item-wrapper'
            onClick={() => {
              navigate('/forum/' + e?.id);
            }}
          >
            <div
              className={`ForumTreadsNavigation__item ${
                selected === e?.id ? 'ForumTreadsNavigation__white' : ''
              }`}
            >
              <div>
                {e?.name} [{e?.threadCount}]
                {e?.isExistUnViewedForumThreads && (
                  <AlertCircleIcons className='ml5' color='red' />
                )}
              </div>
              {currentUserData?.role === ROLES.ADMIN ? (
                <CogwheelIcon
                  color={selected === e?.id ? 'black' : 'white'}
                  className='ForumTreadsNavigation__cogwheel'
                  onClick={() => {
                    handleSetModalStatus(ModalStatuses.UPDATE_CHAPTER);
                    handleChangeModalParameters([
                      {
                        key: 'refetch',
                        value: refetchSection,
                      },
                      {
                        key: 'initialValues',
                        value: {
                          name: e?.name,
                          role: e?.role ? e?.role : 'all',
                          id: e?.id,
                        },
                      },
                    ]);
                  }}
                />
              ) : (
                <div></div>
              )}
            </div>
          </div>
        );
      })}
      {currentUserData?.role === ROLES.ADMIN && (
        <div
          className='ForumTreadsNavigation__button'
          onClick={() => {
            handleSetModalStatus(ModalStatuses.CREATE_CHAPTER);
            handleChangeModalParameters([
              {
                key: 'refetch',
                value: refetchSection,
              },
            ]);
          }}
        >
          <Plus2Icons /> Добавить раздел
        </div>
      )}
    </div>
  );
};
