import './ExpensesPriceCell.scss';

import React, { FC } from 'react';

export const ExpensesPriceCell: FC<{ realizationPerMonthAmount?: number | null }> = ({
  realizationPerMonthAmount,
}) => {
  return (
    <div className='expensesPriceCell'>
      <span>{realizationPerMonthAmount}</span>
    </div>
  );
};
