import '../../components/masonryWrapper/MasonreyWrapper.scss';
import './UserStatistic.scss';

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { MasonryWrapper, RequestHandler, UserStatisticBlock } from '../../components';
import { ROLES } from '../../constants/constants';
import { useAuthContext } from '../../context';
import { useGetUsersThreadsAnalyticQuery } from '../../graphql/generated/graphql';

export const UserStatistic = () => {
  const { data, loading } = useGetUsersThreadsAnalyticQuery({
    variables: {},
  });

  const { currentUserData } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUserData?.role === ROLES.GUEST || currentUserData?.role === ROLES.USER) {
      navigate('/');
    }
  }, []);

  return (
    <div className='container'>
      <div className='userStatistic__header'>Аналитика пользователей</div>
      <RequestHandler loading={loading}>
        <MasonryWrapper>
          {data?.getUsersThreadsAnalytic?.rows?.map((user, index) => {
            return <UserStatisticBlock key={index} userData={user} />;
          })}
        </MasonryWrapper>
      </RequestHandler>
    </div>
  );
};
