import React, { useEffect, useState } from 'react';

import { ScaleListI } from '../../components';
import {
  useGetCurrentUserScaleQuery,
  useUpdateOrderScaleMapFilesMutation,
} from '../../graphql/generated/graphql';

export const ProjectI = () => {
  const [isExcludeNullStatusTasks, setIsExcludeNullStatusTasks] = useState<number | null>(
    null,
  );

  const [isArchived, setIsArchived] = useState(false);

  const { data, refetch, loading } = useGetCurrentUserScaleQuery({
    variables: {
      statusId: isExcludeNullStatusTasks ? isExcludeNullStatusTasks : undefined,
      isExcludeNullStatusTasks: !!isExcludeNullStatusTasks,
      isArchived: isArchived,
    },
    fetchPolicy: 'network-only',
  });

  const [updateOrderScaleMapFilesMutation] = useUpdateOrderScaleMapFilesMutation({
    variables: {
      data: { items: [] },
    },
    onCompleted: () => {
      refetch();
    },
  });
  const scaleMapFiles = data?.getCurrentUser?.scaleMapFiles?.rows;
  useEffect(() => {
    const updateData: any = [];
    let maxOrder = -1;

    scaleMapFiles?.forEach((e) => {
      if (e?.order !== undefined && e?.order !== null) {
        maxOrder = Math.max(maxOrder, e.order);
      }
    });
    scaleMapFiles?.forEach((e, index) => {
      if (e?.order === undefined || e?.order === null) {
        maxOrder++;
        updateData.push({ scaleMapFileId: e?.id, order: maxOrder });
      } else if (e.order !== index) {
        updateData.push({ scaleMapFileId: e?.id, order: index });
      }
    });

    if (updateData?.length > 0) {
      updateOrderScaleMapFilesMutation({
        variables: {
          data: {
            items: updateData,
          },
        },
      });
    }
  }, [scaleMapFiles]);

  type upFileype = {
    currentOrder: number;
    currentID: number;
  };

  const upFile = ({ currentOrder, currentID }: upFileype) => {
    if (currentOrder === 0) {
      return;
    }
    const replacement = scaleMapFiles?.find((e: any) => e.order === currentOrder - 1);
    const data = [
      { scaleMapFileId: currentID, order: currentOrder - 1 },
      { scaleMapFileId: replacement?.id, order: currentOrder },
    ];
    updateOrderScaleMapFilesMutation({
      variables: {
        data: {
          items: data as any,
        },
      },
    });
  };
  const downFile = ({ currentOrder, currentID }: upFileype) => {
    const replacement = scaleMapFiles?.find((e: any) => e.order === currentOrder + 1);
    if (!replacement || scaleMapFiles?.length === currentOrder) return;
    const data = [
      { scaleMapFileId: currentID, order: currentOrder + 1 },
      { scaleMapFileId: replacement?.id, order: currentOrder },
    ];
    updateOrderScaleMapFilesMutation({
      variables: {
        data: {
          items: data as any,
        },
      },
    });
  };

  return (
    <div className='container'>
      <ScaleListI
        isArchived={isArchived}
        setIsArchived={setIsArchived}
        upFile={upFile}
        downFile={downFile}
        loading={loading}
        projectsList={
          [
            {
              id: 'pri',
              scaleMapFiles: scaleMapFiles?.sort((a: any, b: any) => {
                return a?.order - b?.order;
              }),
              name: 'Проект "Я"',
            },
          ] as any
        }
        refetchProjects={refetch}
        isExcludeNullStatusTasks={isExcludeNullStatusTasks}
        setIsExcludeNullStatusTasks={setIsExcludeNullStatusTasks}
      />
    </div>
  );
};
