import './Circle.scss';

import moment from 'moment';
import React, { useState } from 'react';

import { CirclesStats } from '../../components';
import { useModalContext } from '../../context';
import {
  SocialCircleObject,
  useCreateSocialCircleObjectMutation,
  useGetAndCountSocialCircleObjectsQuery,
  useGetSocialCircleStatisticsQuery,
  useMadeSocialCircleMeetingMutation,
  useRemoveSocialCircleObjectMutation,
  useUpdateSocialCircleObjectMutation,
} from '../../graphql/generated/graphql';
import { ModalStatuses } from '../../types/enums';
import { textSliceSpace } from '../../utils';

export const Circle = () => {
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  const { data, refetch } = useGetAndCountSocialCircleObjectsQuery({
    variables: {},
  });
  const [searchValue, setSearchValue] = useState('');

  const { data: statistic, refetch: refetchStatistic } =
    useGetSocialCircleStatisticsQuery({
      variables: {},
    });

  const [createSocialCircleObjectMutation] = useCreateSocialCircleObjectMutation({
    onCompleted: () => {
      refetch();
      refetchStatistic();
    },
  });
  const [madeSocialCircleMeetingMutation] = useMadeSocialCircleMeetingMutation({
    onCompleted: () => {
      refetch();
      refetchStatistic();
    },
  });
  const [updateSocialCircleObjectMutation] = useUpdateSocialCircleObjectMutation({
    onCompleted: () => {
      refetch();
      refetchStatistic();
    },
  });

  const [removeSocialCircleObjectMutation] = useRemoveSocialCircleObjectMutation({
    onCompleted: () => {
      refetch();
      refetchStatistic();
    },
  });

  const rows = data?.getAndCountSocialCircleObjects?.rows;

  const circleCreate = () => {
    handleSetModalStatus(ModalStatuses.CIRCLE_CREATE_MODAL);
    handleChangeModalParameters([
      {
        key: 'onCallback',
        value: (value: any) => {
          createSocialCircleObjectMutation({
            variables: {
              createSocialCircleObjectInput: {
                colorToDisplay: value?.colorToDisplay,
                name: value?.name,
                whoIs: value?.whoIs,
                strongPoints: value?.strongPoints,
                weakPoints: value?.weakPoints,
                usefulnessDescription: value?.usefulnessDescription,
                letter1: `${value?.letter1}`,
                letter2: `${value?.letter2}`,
                letter3: `${value?.letter3}`,
                letter4: `${value?.letter4}`,
              },
            },
          });
        },
      },
    ]);
  };

  const topClick = ({ id }: { id: number }) => {
    handleSetModalStatus(ModalStatuses.CIRCLE_ADD_MEET_MODAL);
    handleChangeModalParameters([
      {
        key: 'onCallback',
        value: () => {
          madeSocialCircleMeetingMutation({
            variables: {
              socialCircleObjectId: id,
            },
          });
        },
      },
    ]);
  };

  const botClick = ({ circle }: { circle: SocialCircleObject | any }) => {
    handleSetModalStatus(ModalStatuses.CIRCLE_USER_MODAL);
    handleChangeModalParameters([
      {
        key: 'onCallback',
        value: (value: any) => {
          updateSocialCircleObjectMutation({
            variables: {
              updateSocialCircleObjectInput: {
                id: circle?.id,
                colorToDisplay: value?.colorToDisplay,
                name: value?.name,
                whoIs: value?.whoIs,
                strongPoints: value?.strongPoints,
                weakPoints: value?.weakPoints,
                usefulnessDescription: value?.usefulnessDescription,
                letter1: `${value?.letter1}`,
                letter2: `${value?.letter2}`,
                letter3: `${value?.letter3}`,
                letter4: `${value?.letter4}`,
              },
            },
          });
        },
      },
      {
        key: 'onCallBackDelete',
        value: () => {
          removeSocialCircleObjectMutation({
            variables: {
              id: circle?.id,
            },
          });
        },
      },
      {
        key: 'initialValues',
        value: {
          colorToDisplay: circle?.colorToDisplay,
          name: circle?.name,
          whoIs: circle?.whoIs,
          strongPoints: circle?.strongPoints,
          weakPoints: circle?.weakPoints,
          usefulnessDescription: circle?.usefulnessDescription,
          lastMeetingDate: circle?.lastMeetingDate,
          meetingCount: circle?.meetingCount,
          letter1: stringToBoolean(circle?.letter1),
          letter2: stringToBoolean(circle?.letter2),
          letter3: stringToBoolean(circle?.letter3),
          letter4: stringToBoolean(circle?.letter4),
        },
      },
    ]);
  };

  const calc = calculateRowsCols(rows?.length ? rows?.length + 1 : 0);
  const matrix = calcMatrix({ rows: calc, cols: calc });
  const newRows: any = rows ? [{ siI: true }, ...rows] : [{ siI: true }];

  return (
    <div className='container'>
      <div className='circles'>
        <CirclesStats
          statistic={statistic}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <div className='circles__panel'>
          <div
            className='circles__panel-inner'
            style={{
              width: `${110 * calc}px`,
              height: `calc(${110 * calc}px + 3px)`,
            }}
          >
            {newRows?.map((e: any, index: any) => {
              const indexes = findIndexes(matrix, index + 1);

              const { positionDifferentNext, positionDifferentPrev, positionNext } =
                calcMatrixCoordinate({ index, matrix });

              const styleElement = calculateBorder({
                positionDifferentNext,
                positionDifferentPrev,
                noNext: positionNext?.col === 0 && positionNext?.row === 0,
              });

              const style = {
                top: `calc(110px*${indexes?.row} + 55px)`,
                left: `calc( 110px*${indexes ? indexes?.col : 0 + 1} + 55px) `,
              };

              if (e?.siI) {
                return (
                  <div className='circles__block' key={index} style={style}>
                    <div
                      className='circles__inner'
                      style={{
                        cursor: 'pointer',
                        backgroundColor: 'black',
                      }}
                      onClick={circleCreate}
                    >
                      <div
                        className='circles__title-item'
                        style={{
                          color: 'white',
                          zIndex: 1,
                        }}
                      >
                        Я
                      </div>
                    </div>
                  </div>
                );
              }
              return (
                <div
                  key={index}
                  className='circles__block'
                  style={{
                    ...style,
                    backgroundColor: calcBackground({
                      lastMeetingDate: e.lastMeetingDate,
                    }),
                  }}
                >
                  <div className='circles__inner'>
                    {stringToBoolean(e?.letter1) && (
                      <div className='circles__letter1'>P</div>
                    )}
                    {stringToBoolean(e?.letter2) && (
                      <div className='circles__letter2'>Б</div>
                    )}
                    {stringToBoolean(e?.letter3) && (
                      <div className='circles__letter3'>С</div>
                    )}
                    {stringToBoolean(e?.letter4) && (
                      <div className='circles__letter4'>И</div>
                    )}

                    <div
                      className='circles__half'
                      onClick={() => {
                        topClick({ id: e?.id });
                      }}
                    ></div>
                    <div
                      className='circles__half'
                      onClick={() => {
                        botClick({ circle: e });
                      }}
                    ></div>
                    <div
                      className='circles__title-item'
                      style={{
                        backgroundColor:
                          e?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()) &&
                          searchValue?.length > 0
                            ? 'gold'
                            : '',
                        padding: '3px',
                      }}
                    >
                      {textSliceSpace({
                        count: 8,
                        text: `${e?.name}`,
                        space: true,
                      })}
                    </div>
                    <div className='circles__element' style={styleElement}></div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

function findIndexes(matrix: any, num: any) {
  if (!matrix) return;
  for (let i = 0; i < matrix.length; i++) {
    for (let j = 0; j < matrix[i].length; j++) {
      if (matrix[i][j] === num) {
        return { row: i, col: j };
      }
    }
  }
  return null;
}

function calculateRowsCols(numElements: any) {
  const sideLength = Math.ceil(Math.sqrt(numElements));
  const rows = Math.ceil(numElements / sideLength);
  const cols = sideLength;
  if (rows >= cols) {
    return rows;
  }
  if (cols === 0) {
    return 1;
  }

  return cols;
}

const calcMatrix = ({ rows, cols }: any) => {
  function createReverseLeftSpiralMatrix(rows: any, cols: any) {
    const matrix = Array.from({ length: rows }, () => Array(cols).fill(0));
    let startRow = 0,
      endRow = rows - 1,
      startCol = 0,
      endCol = cols - 1;
    let counter = rows * cols;

    while (startRow <= endRow && startCol <= endCol) {
      for (let i = endCol; i >= startCol; i--) {
        matrix[startRow][i] = counter--;
      }
      startRow++;

      for (let i = startRow; i <= endRow; i++) {
        matrix[i][startCol] = counter--;
      }
      startCol++;

      if (startRow <= endRow) {
        for (let i = startCol; i <= endCol; i++) {
          matrix[endRow][i] = counter--;
        }
        endRow--;
      }

      if (startCol <= endCol) {
        for (let i = endRow; i >= startRow; i--) {
          matrix[i][endCol] = counter--;
        }
        endCol--;
      }
    }

    return matrix;
  }

  const reverseLeftSpiralMatrix = createReverseLeftSpiralMatrix(rows, cols);

  if (rows >= 2 && rows % 2 === 0) {
    return rotateMatrix(reverseLeftSpiralMatrix, 180);
  }

  return reverseLeftSpiralMatrix;
};

const rotateMatrix = (matrix: any, degrees: number) => {
  const rows = matrix.length;
  const cols = matrix[0].length;
  let rotatedMatrix;

  switch (degrees % 360) {
    case 90:
      rotatedMatrix = Array.from({ length: cols }, () => Array(rows).fill(0));
      for (let i = 0; i < rows; i++) {
        for (let j = 0; j < cols; j++) {
          rotatedMatrix[j][rows - 1 - i] = matrix[i][j];
        }
      }
      break;
    case 180:
      rotatedMatrix = Array.from({ length: rows }, () => Array(cols).fill(0));
      for (let i = 0; i < rows; i++) {
        for (let j = 0; j < cols; j++) {
          rotatedMatrix[rows - 1 - i][cols - 1 - j] = matrix[i][j];
        }
      }
      break;
    case 270:
      rotatedMatrix = Array.from({ length: cols }, () => Array(rows).fill(0));
      for (let i = 0; i < rows; i++) {
        for (let j = 0; j < cols; j++) {
          rotatedMatrix[cols - 1 - j][i] = matrix[i][j];
        }
      }
      break;
    default:
      rotatedMatrix = matrix;
  }

  return rotatedMatrix;
};
const stringToBoolean = (string: string) => (string === 'false' ? false : !!string);

type Direction = { col: number; row: number };
type Border = {
  borderTop: string;
  borderBottom: string;
  borderRight: string;
  borderLeft: string;
};

const calculateBorder = ({
  positionDifferentNext,
  positionDifferentPrev,
  noNext,
}: {
  positionDifferentNext: Direction;
  positionDifferentPrev: Direction;
  noNext: boolean;
}): Border => {
  const border: Border = {
    borderTop: '',
    borderBottom: '',
    borderRight: '',
    borderLeft: '',
  };
  const defaultBorder = '3px solid #654d4d';
  const isNextEqual = (dir: Direction) =>
    positionDifferentNext?.col === dir.col && positionDifferentNext?.row === dir.row;
  const isPrevEqual = (dir: Direction) =>
    positionDifferentPrev?.col === dir.col && positionDifferentPrev?.row === dir.row;

  if (isNextEqual({ col: 0, row: 1 }) && !isPrevEqual({ col: 0, row: 1 })) {
    border.borderTop = defaultBorder;
    border.borderRight = defaultBorder;
  }
  if (
    isNextEqual({ col: -1, row: 0 }) &&
    positionDifferentNext.col !== positionDifferentPrev.col &&
    !noNext
  ) {
    border.borderBottom = defaultBorder;
    border.borderRight = defaultBorder;
  }
  if (
    isNextEqual({ col: -1, row: 0 }) &&
    positionDifferentNext.col !== positionDifferentPrev.col &&
    noNext
  ) {
    border.borderTop = defaultBorder;
  }

  if (
    isNextEqual({ col: -1, row: 0 }) &&
    positionDifferentNext.col === positionDifferentPrev.col
  ) {
    border.borderBottom = defaultBorder;
  }

  if (
    isNextEqual({ col: 0, row: -1 }) &&
    positionDifferentNext.col !== positionDifferentPrev.col &&
    !noNext
  ) {
    border.borderLeft = defaultBorder;
    border.borderBottom = defaultBorder;
  }
  if (isNextEqual({ col: 0, row: -1 }) && !noNext) {
    border.borderLeft = defaultBorder;
  }
  if (isNextEqual({ col: 0, row: -1 }) && noNext && !isPrevEqual({ col: 0, row: -1 })) {
    border.borderBottom = defaultBorder;
  }
  if (isNextEqual({ col: 0, row: -1 }) && noNext && isPrevEqual({ col: 0, row: -1 })) {
    border.borderLeft = defaultBorder;
  }

  if (
    isNextEqual({ col: 1, row: 0 }) &&
    positionDifferentNext.col !== positionDifferentPrev.col
  ) {
    border.borderLeft = defaultBorder;
    border.borderTop = defaultBorder;
  }
  if (isNextEqual({ col: 1, row: 0 })) {
    border.borderTop = defaultBorder;
  }
  if (isNextEqual({ col: 0, row: 1 })) {
    border.borderRight = defaultBorder;
  }

  return border;
};

function findPosition(matrix: any, target: any) {
  for (let row = 0; row < matrix.length; row++) {
    for (let col = 0; col < matrix[row].length; col++) {
      if (matrix[row][col] === target) {
        return { row, col };
      }
    }
  }

  return { col: 0, row: 0 };
}
const calcMatrixCoordinate = ({ matrix, index }: any) => {
  const positionPrev = findPosition(matrix, index);
  const position = findPosition(matrix, index + 1);
  const positionNext = findPosition(matrix, index + 2);

  const positionDifferentNext = {
    col: Math.max(-1, Math.min(1, Math.round(positionNext?.col - position?.col))),
    row: Math.max(-1, Math.min(1, Math.round(positionNext?.row - position?.row))),
  };

  const positionDifferentPrev = {
    col: Math.max(-1, Math.min(1, Math.round(position?.col - positionPrev?.col))),
    row: Math.max(-1, Math.min(1, Math.round(position?.row - positionPrev?.row))),
  };
  return { positionDifferentNext, positionDifferentPrev, positionNext };
};

const calcBackground = ({ lastMeetingDate }: { lastMeetingDate: string | null }) => {
  // Current date and time
  const currentDate = moment();

  // Calculate the difference in days
  const daysDifference = currentDate.diff(moment(lastMeetingDate), 'days');
  function getColorLevel(daysSinceLastMeeting: number) {
    if (daysSinceLastMeeting <= 1) {
      return 9;
    } else if (daysSinceLastMeeting === 2) {
      return 8;
    } else if (daysSinceLastMeeting === 3) {
      return 7;
    } else if (daysSinceLastMeeting === 4) {
      return 6;
    } else if (daysSinceLastMeeting === 5) {
      return 5;
    } else if (daysSinceLastMeeting >= 6 && daysSinceLastMeeting <= 7) {
      return 4;
    } else if (daysSinceLastMeeting >= 8 && daysSinceLastMeeting <= 14) {
      return 3;
    } else if (daysSinceLastMeeting >= 15 && daysSinceLastMeeting <= 21) {
      return 2;
    } else if (daysSinceLastMeeting >= 22 && daysSinceLastMeeting <= 30) {
      return 1;
    } else {
      return 0;
    }
  }
  const index = getColorLevel(daysDifference);

  return colorCircle.warning[index];
};

const colorCircle = {
  gray: [
    '#FCFCFD',
    '#F9FAFB',
    '#F2F4F7',
    '#EAECF0',
    '#D0D5DD',
    '#98A2B3',
    '#667085',
    '#475467',
    '#344054',
    '#1D2939',
    '#101828',
  ],
  brand: [
    '#FCFAFF',
    '#F9F5FF',
    '#F4EBFF',
    '#E9D7FE',
    '#D6BBFB',
    '#B692F6',
    '#9E77ED',
    '#7F56D9',
    '#6941C6',
    '#53389E',
    '#42307D',
  ],
  error: [
    '#FFFBFA',
    '#FEF3F2',
    '#FEE4E2',
    '#FECDCA',
    '#FDA29B',
    '#F97066',
    '#F04438',
    '#D92D20',
    '#B42318',
    '#912018',
    '#7A271A',
  ],
  warning: [
    '#FFFCF5',
    '#FFFAEB',
    '#FEF0C7',
    '#FEDF89',
    '#FEC84B',
    '#FDB022',
    '#F79009',
    '#DC6803',
    '#B54708',
    '#93370D',
  ],
  success: [
    '#F6FEF9',
    '#ECFDF3',
    '#D1FADF',
    '#A6F4C5',
    '#6CE9A6',
    '#32D583',
    '#12B76A',
    '#039855',
    '#027A48',
    '#05603A',
    '#054F31',
  ],
  blueGray: [
    '#FCFCFD',
    '#F8F9FC',
    '#EAECF5',
    '#D5D9EB',
    '#AFB5D9',
    '#717BBC',
    '#4E5BA6',
    '#3E4784',
    '#363F72',
    '#293056',
    '#101323',
  ],
};
