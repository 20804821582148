import { gql } from '@apollo/client';

export const GET_TASK = gql`
  query getTask($id: Int!, $subtaskStatusId: Int) {
    getTask(id: $id) {
      id
      createdAt
      updatedAt
      name
      number
      description
      code
      startDate
      closeDate
      endDate
      project {
        id
        number
        name
      }
      status {
        id
      }
      author {
        id
        login
        image
        fullName
      }
      subtasks(
        data: {
          limit: 100
          sort: { type: ASC, field: status }
          statusId: $subtaskStatusId
        }
      ) {
        id
        createdAt
        updatedAt
        name
        number
        code
        startDate
        closeDate
        task {
          id
          number
          code
          project {
            id
            number
          }
        }
        status {
          id
        }
        author {
          id
          login
          image
          karmaStatistics {
            karma
          }
        }
        maintainer {
          id
          login
          image
          isAnalytics
          karmaStatistics {
            karma
          }
        }
        endDate
        tags {
          count
          rows {
            id
            name
            color
            textColor
            createdAt
            updatedAt
          }
        }
        poligonObject {
          id
          name
        }
      }
      maintainer {
        id
        login
        image
        isAnalytics
        karmaStatistics {
          karma
        }
      }
      tags {
        count
        rows {
          id
          name
          color
          createdAt
          updatedAt
        }
      }
      realizationPerMonthAmount
      realizationPrice
      poligonObject {
        id
      }
    }
  }
`;
