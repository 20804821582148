import './ChangeImageModal.scss';

import { Form, Formik } from 'formik';
import React from 'react';

import { BASE_URL } from '../../../../constants/constants';
import { useLoadFileMutation } from '../../../../graphql/generated/graphql';
import { CrossIcon } from '../../../icons';
import { Button } from '../../../index';

export const ChangeImageModal = ({ modalParameters, onClose }: any) => {
  const [loadFileMutation] = useLoadFileMutation();

  const handleSubmit = (value: any) => {
    modalParameters.onCallback(value?.file);
    onClose();
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: '',
        color: '',
        file: modalParameters?.file ? modalParameters?.file : null,
      }}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values }) => {
        return (
          <Form className='changeImageModal'>
            {!values?.file?.path && (
              <label>
                <span className='add-polygon-block-modal__file-button'>
                  Использовать изображение
                </span>
                <input
                  name='file'
                  type='file'
                  onChange={(e) => {
                    loadFileMutation({
                      variables: { data: { file: e?.currentTarget?.files?.[0] } },
                    }).then((response) => {
                      setFieldValue('file', response.data?.loadFile);
                    });
                  }}
                  className='add-polygon-block-modal__file'
                />
              </label>
            )}
            {values?.file?.path && (
              <div style={{ position: 'relative' }}>
                <img
                  className='showImage'
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  src={`${BASE_URL}${values?.file?.path as string}`}
                />
                <CrossIcon
                  width={20}
                  height={20}
                  color={'red'}
                  onClick={() => {
                    setFieldValue('file', null);
                  }}
                  className={'deleteImageIcons'}
                />
              </div>
            )}
            <Button
              text={'Сохранить'}
              type='submit'
              width='100%'
              height='25px'
              margin='20px 0 0 0'
            />
          </Form>
        );
      }}
    </Formik>
  );
};
