import './Login.scss';

import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import bigLogo from '../../assets/bigLogo.svg';
import { Button, Input, RequestHandler } from '../../components';
import { PATHS } from '../../constants/constants';
import { useResetPasswordByTokenMutation } from '../../graphql/generated/graphql';
import { useCanvasElectro } from '../../hooks';
import { useSignInLazyQuery } from '../../hooks/useQueries';
import { loginSchema } from '../../validation/schemas';
import { registrSchema } from '../../validation/schemas/registr.schema';

type FormikValues = {
  login: string;
  password: string;
};

export const Login: React.FC = (): JSX.Element => {
  const { pathname } = useLocation();

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const linkParams = searchParams.get('registration');

  const loginParams = searchParams.get('login');

  const resetParams = searchParams.get('recovery');

  const isRegistration = linkParams && loginParams;

  const isRecovery = resetParams && loginParams;

  const [login, { loading, error }] = useSignInLazyQuery({});

  const [resetPasswordMutation] = useResetPasswordByTokenMutation();
  useCanvasElectro({ login });

  const memoCanvas = useMemo(() => {
    return <canvas id='canvas' className='login-page__canvas'></canvas>;
  }, [login]);

  const handleFormSubmit = async (values: FormikValues) => {
    if (!isRecovery) {
      await login({
        variables: {
          data: {
            login: isRegistration ? loginParams : values.login,
            password: values.password,
            link: linkParams,
          },
        },
      });
    } else {
      await resetPasswordMutation({
        variables: {
          token: resetParams,
          newPassword: values.password,
        },
        onCompleted: () => {
          navigate(PATHS.login);
          values.login = loginParams;
          values.password = '';
        },
      });
    }
  };

  if ((!linkParams || !loginParams) && pathname === '/registr')
    return <Navigate replace to={PATHS.login} />;

  if ((!resetParams || !loginParams) && pathname === '/password-recovery')
    return <Navigate replace to={PATHS.login} />;

  return (
    <div className='login-page'>
      {memoCanvas}

      <div className='login-page__outer-form'>
        <RequestHandler backgroundLoading={loading}>
          <div className='login__wrapper'>
            <img className='login__logo' src={bigLogo} alt='logo' />
            <Formik
              onSubmit={handleFormSubmit}
              validationSchema={
                isRegistration || isRecovery ? registrSchema : loginSchema
              }
              initialValues={{
                login: '',
                password: '',
              }}
            >
              <div
                className='form__wrapper'
                onMouseMove={(e) => {
                  e.defaultPrevented;
                }}
              >
                <Form>
                  {(isRegistration || isRecovery) && (
                    <h1 className='login__title'>
                      {isRegistration
                        ? 'Регистрация'
                        : !isRecovery
                        ? 'Вход'
                        : 'Восстановление пароля'}
                    </h1>
                  )}
                  <div className='login__fields-wrapper'>
                    <Input
                      label={'Логин'}
                      name={'login'}
                      placeholder={
                        isRegistration || isRecovery ? loginParams : 'Введите значение'
                      }
                      disabled={!!isRegistration || !!isRecovery}
                    />
                    <Input
                      type={'password'}
                      label={
                        isRegistration
                          ? 'Придумайте пароль'
                          : !isRecovery
                          ? 'Пароль'
                          : 'Введите новый пароль'
                      }
                      name={'password'}
                      showEye={true}
                    />
                    <p className={'login__error'}>
                      {error && 'Неправильный логин или пароль'}
                    </p>
                    <div className='login__button'>
                      <Button
                        type='submit'
                        height={'30.8px'}
                        width={'200.4px'}
                        text={
                          isRegistration
                            ? 'Зарегистрироватся'
                            : !isRecovery
                            ? 'Войти'
                            : 'Сохранить'
                        }
                      />
                    </div>
                  </div>
                </Form>
              </div>
            </Formik>
          </div>
        </RequestHandler>
      </div>
    </div>
  );
};
