import './DecideOnTheFlyPackages.scss';

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../../components';
import { LongArrowLeft } from '../../../components/icons';
import { DecideOnTheFlyPackageItem } from '../../../components/index';
import { PATHS, ROLES } from '../../../constants/constants';
import { useAuthContext } from '../../../context';
import {
  DecideOnFlyPackage,
  useDecideOnFlyPackagesQuery,
  useUpdateDecideOnFlyPackageMutation,
} from '../../../graphql/generated/graphql';

type DecideOnTheFlyPackagesType = {
  setStatePage: (value: number) => void;
};

export const DecideOnTheFlyPackages = ({ setStatePage }: DecideOnTheFlyPackagesType) => {
  const date = new Date();
  const { data, refetch } = useDecideOnFlyPackagesQuery({
    variables: {
      timezoneOffset: date.getTimezoneOffset(),
    },
    fetchPolicy: 'network-only',
  });

  const [updateDecideOnFlyPackageMutation] = useUpdateDecideOnFlyPackageMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const { currentUserData } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUserData?.role !== ROLES.ADMIN) {
      navigate('/');
    }
  }, []);

  const getDecideOnFlyPeriods = data?.decideOnFlyPackages;

  const navigation = useNavigate();

  return (
    <div className='DecideOnTheFlyPackage'>
      <div
        onClick={() => {
          navigation(-1);
        }}
        className='DecideOnTheFlyPackage__getBack'
      >
        <LongArrowLeft
          style={{
            marginRight: '10px',
          }}
        />
        Вернуться назад
      </div>
      <div className='DecideOnTheFlyPackage__controll'>
        <div
          className={`DecideOnTheFlyPackage__controll-item DecideOnTheFlyPackage__controll-select`}
        >
          Пакеты
        </div>
        <div
          className={`DecideOnTheFlyPackage__controll-item`}
          onClick={() => {
            setStatePage(2);
          }}
        >
          Спонтанные Задания
        </div>
      </div>

      <div className='DecideOnTheFlyPackage__title'>
        Пакеты{' '}
        <div
          style={{
            marginLeft: 'auto',
          }}
        >
          <Button
            backgroundColor='#149939'
            color='white'
            onClick={() => {
              navigation(PATHS.decideOnTheFlyCreate);
            }}
          >
            Создать
          </Button>
        </div>
      </div>
      {getDecideOnFlyPeriods?.length === 0 ? (
        <div
          style={{
            fontSize: '20px',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Нет созданных пакетов
        </div>
      ) : (
        <div className='DecideOnTheFlyPackage__content'>
          {getDecideOnFlyPeriods?.map((e, index) => {
            return (
              <DecideOnTheFlyPackageItem
                key={index}
                decideOnFlyPackage={e as DecideOnFlyPackage}
                updateDecideOnFlyPackageMutation={updateDecideOnFlyPackageMutation}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
