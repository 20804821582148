import './NewResetPasswordModal.scss';

import { Form, Formik } from 'formik';
import React from 'react';

import { ResetPasswordUser } from '../../../../graphql/generated/graphql';
import { useResetPasswordMutation } from '../../../../hooks/useMutations/useResetPasswordMutation';
import { getErrorText } from '../../../../utils';
import { resetPasswordSchema } from '../../../../validation/schemas';
import { AppInput, Button, RequestHandler } from '../../../index';

export const NewResetPasswordModal = ({ onClose }: any) => {
  const [resetPassword, { loading, error }] = useResetPasswordMutation(onClose);
  const initialValues: ResetPasswordUser = {
    oldPassword: '',
    newPassword: '',
  };

  const handleSubmit = async (values: ResetPasswordUser) => {
    await resetPassword({
      variables: {
        data: {
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        },
      },
    });
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={resetPasswordSchema}
      initialValues={initialValues}
    >
      <Form className='reset__password-wrapper'>
        <div className='reset__password-form'>
          <p className='reset__password-title'>Изменить пароль</p>
          <div className='reset__password-fields'>
            <AppInput type='password' label='Старый пароль:' name='oldPassword' />
            <AppInput type='password' label='Новый пароль:' name='newPassword' />
            <p className={'server__form-error'}>{error && getErrorText(error.message)}</p>
            <RequestHandler size='s' loading={loading}>
              <Button type='submit' width='240px' text='Изменить' />
            </RequestHandler>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
