import { toast } from 'react-toastify';

import { useUpdateThreadHeaderMutation } from '../../../graphql/generated/graphql';

export const useTagsOptimize = ({ data, refetch }: any) => {
  const [updateThreadTagsMetaHeaderMutation] = useUpdateThreadHeaderMutation({
    onCompleted: () => {
      refetch();
    },
    onError: () => {
      toast.error('Ой');
    },
  });

  const tags = data?.getThread?.tags;
  const meta = data?.getThread?.meta;
  const isSubscriber = data?.getThread?.isSubscriber;
  const scaleMapTaskName = data?.getThread?.scaleMapTask?.name;
  const statusId = data?.getThread?.scaleMapTask?.status?.id;
  const author = data?.getThread?.author;

  return {
    updateThreadTagsMetaHeaderMutation,
    tags,
    meta,
    isSubscriber,
    scaleMapTaskName,
    statusId,
    author,
  };
};
