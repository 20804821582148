import './TredItem.scss';

import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  TreadItemChat,
  TreadItemFile,
  TreadItemHeader,
  TreadItemImage,
  TreadItemNotes,
  TredItemPerformers,
  TredItemSubscribeButton,
  TredItemVerbs,
} from '../../components';
import { PolygonLinkIcon } from '../../components/icons';
import { useAuthContext, useModalContext } from '../../context';
import {
  useCheckIsUnsubscribedAndSubscribeToThreadMutation,
  useCreateScaleMapTaskMutation,
  useDeleteScaleMapTaskMutation,
  useExitThreadPerformersMutation,
  useGetThreadQuery,
  useSetPerformerStatusMutation,
  useSubscribeToThreadMutation,
  useUnsubscribeFromThreadMutation,
  useUpdateScaleMapTaskMutation,
  useUpdateThreadMutation,
} from '../../graphql/generated/graphql';
import { GET_THREAD } from '../../graphql/queries/getThread.gql';
import { GET_THREADS_AND_COUNT_BY_USER } from '../../graphql/queries/getThreadsAndCountByUser';
import { useReadProjectActivitiesMutation } from '../../hooks/useMutations';
import { ModalStatuses } from '../../types/enums';
import {
  useChatHeight,
  useFileOptimize,
  usePerformerOptimize,
  useTagsOptimize,
} from './itemHook';

export const TredItem = () => {
  const { treadId } = useParams();
  const { currentUserData } = useAuthContext();
  const [setPerformerStatusMutation] = useSetPerformerStatusMutation({
    refetchQueries: [GET_THREADS_AND_COUNT_BY_USER, GET_THREAD],
  });
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  const [readProjectActivitiesMutation] = useReadProjectActivitiesMutation();

  const { data, refetch } = useGetThreadQuery({
    variables: {
      id: parseInt(treadId as any),
    },
    fetchPolicy: 'cache-and-network',
  });
  const [checkIsUnsubscribedAndSubscribeToThreadMutation] =
    useCheckIsUnsubscribedAndSubscribeToThreadMutation({
      variables: {
        threadId: parseInt(treadId as any),
      },
    });

  useEffect(() => {
    checkIsUnsubscribedAndSubscribeToThreadMutation();
  }, []);

  const handleEnterThread = useCallback(() => {
    setPerformerStatusMutation({
      variables: {
        data: { threadId: Number(data?.getThread.id), status: '1' },
      },
    });
    readProjectActivitiesMutation({
      variables: {
        threadId: data?.getThread.id,
      },
    });
  }, [setPerformerStatusMutation, readProjectActivitiesMutation, data?.getThread.id]);

  useEffect(() => {
    const currentUserThreads = data?.getThread?.performers.find(
      (item) => item.userId === currentUserData?.id,
    )?.user?.threads?.rows;

    const performerStatus = treadId
      ? currentUserThreads?.find((i) => i.id === data?.getThread.id)?.performerStatus
      : null;

    if (performerStatus === '0') {
      handleEnterThread();
    }
  }, [data?.getThread.id, currentUserData?.id]);

  const {
    storageFiles,
    storageImages,
    getThreadFile,
    deleteFileMutation,
    deleteStorageItemMutation,
    updateFileMutation,
    updateStorageItemMutation,
    trackStorageEventClickedMutation,
    updateThreadFileCommentMutation,
    updateThread,
  } = useFileOptimize({ data, treadId, refetch });
  const { performers, isPerformer, updatePerformers, mainPerformerId, mainPerformer } =
    usePerformerOptimize({
      data,
      treadId,
    });
  const {
    updateThreadTagsMetaHeaderMutation,
    tags,
    meta,
    isSubscriber,
    scaleMapTaskName,
    statusId,
    author,
  } = useTagsOptimize({ refetch, data });
  const { item1Ref, item2Style } = useChatHeight(data, storageFiles, storageImages);

  useEffect(() => {
    readProjectActivitiesMutation({
      variables: {
        threadId: Number(treadId),
      },
    });
    return () => {
      readProjectActivitiesMutation({
        variables: {
          threadId: Number(treadId),
        },
      });
    };
  }, [treadId]);

  const [createScaleMapTaskMutation] = useCreateScaleMapTaskMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const [updateScaleMapTaskMutation] = useUpdateScaleMapTaskMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const [deleteScaleMapTaskMutation] = useDeleteScaleMapTaskMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const [subscribeToThreadMutation] = useSubscribeToThreadMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const [unsubscribeFromThreadMutation] = useUnsubscribeFromThreadMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const [updateThreadMutation] = useUpdateThreadMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const [exitThreadPerformersMutation] = useExitThreadPerformersMutation({
    onCompleted: () => {
      toast.success('Вы вышли из треда');
      subscribeToThreadMutation({
        variables: {
          threadId: data?.getThread?.id as number,
        },
      });
    },
  });

  const handleSubscribe = () => {
    if (data?.getThread?.isSubscriber) {
      unsubscribeFromThreadMutation({
        variables: {
          threadId: data?.getThread?.id,
        },
      });
    } else {
      subscribeToThreadMutation({
        variables: {
          threadId: data?.getThread?.id as number,
        },
      });
    }
  };

  const current = data?.getThread?.meta?.filter((obj: any) => obj?.isCurrent === true)[0];
  const calendarSubscriptionDate = data?.getThread?.startDate;

  const handleCalendarSubscribe = () => {
    if (!data?.getThread?.scaleMapTask?.id) return;
    if (!calendarSubscriptionDate) {
      updateThreadMutation({
        variables: {
          data: {
            id: data?.getThread?.id,
            startDate: moment().format('YYYY-MM-DD'),
          },
        },
      });
    }
  };
  return (
    <div className='TredItem'>
      <TredItemSubscribeButton
        name={current?.name}
        subscribeToThreadMutation={subscribeToThreadMutation}
        id={data?.getThread?.id}
        isSubscriber={data?.getThread?.isSubscriber}
        isPerformer={isPerformer}
      />
      <div className='TredItem__content'>
        <TreadItemHeader
          updateThreadMutation={updateThreadTagsMetaHeaderMutation}
          handleSubscribe={handleSubscribe}
          isPerformer={isPerformer}
          tags={tags}
          meta={meta}
          threadId={data?.getThread?.id as number}
          isSubscriber={isSubscriber as boolean}
          scaleMapTaskName={scaleMapTaskName}
          mainPerformer={mainPerformer}
          author={author}
          performers={performers}
          statusId={statusId}
          calendarSubscriptionDate={calendarSubscriptionDate}
          handleCalendarSubscribe={handleCalendarSubscribe}
          exitThreadPerformersMutation={exitThreadPerformersMutation}
          endDate={data?.getThread?.endDate}
          startDate={data?.getThread?.startDate}
          closeDate={data?.getThread?.closeDate}
          cover={data?.getThread?.cover}
          threadBackground={data?.getThread?.threadBackground}
          isCritical={data?.getThread?.isCritical}
        />
        <div className='TredItem__content-body'>
          <div className='TredItem__content-left'>
            <div ref={item1Ref} style={{ position: 'relative' }}>
              {storageImages && storageImages?.length > 0 && (
                <div
                  className='TredItem__all'
                  onClick={() => {
                    handleSetModalStatus(ModalStatuses.ALL_TRED_IMAGE_MODAL);
                    handleChangeModalParameters([
                      {
                        key: 'initialValues',
                        value: {
                          storageFiles: storageImages,
                        },
                      },
                    ]);
                  }}
                >
                  <PolygonLinkIcon color='black' />
                </div>
              )}
              {storageImages && storageImages?.length > 0 && (
                <TreadItemImage
                  storageFiles={storageImages}
                  trackStorageEventClickedMutation={trackStorageEventClickedMutation}
                  deleteFileMutation={deleteFileMutation}
                  deleteStorageItemMutation={deleteStorageItemMutation}
                  updateFileMutation={updateFileMutation}
                  updateStorageItemMutation={updateStorageItemMutation}
                  updateThreadFileCommentMutation={updateThreadFileCommentMutation}
                />
              )}
              {storageFiles && storageFiles?.length > 0 && (
                <TreadItemFile
                  storageFiles={storageFiles}
                  trackStorageEventClickedMutation={trackStorageEventClickedMutation}
                  deleteFileMutation={deleteFileMutation}
                  deleteStorageItemMutation={deleteStorageItemMutation}
                  updateFileMutation={updateFileMutation}
                  updateStorageItemMutation={updateStorageItemMutation}
                />
              )}
            </div>
            <TreadItemChat refetch={getThreadFile} item2Style={item2Style} />
          </div>
          <div className='TredItem__content-right'>
            <TredItemVerbs
              scaleMapTask={data?.getThread?.scaleMapTask}
              createScaleMapTaskMutation={createScaleMapTaskMutation}
              child={data?.getThread?.scaleMapTask?.children}
              updateScaleMapTaskMutation={updateScaleMapTaskMutation}
              deleteScaleMapTaskMutation={deleteScaleMapTaskMutation}
              updateThread={updateThread}
              id={Number(treadId)}
              scaleMapTaskForCalendarId={data?.getThread?.scaleMapTaskForCalendar?.id}
            />
            <TredItemPerformers
              performers={performers}
              id={Number(treadId)}
              updateThreadMutation={updatePerformers}
              mainPerformerId={mainPerformerId}
            />
            <TreadItemNotes
              backgroundColor={'#F5F5F5'}
              maintainer={data?.getThread?.author}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
