import './ForumTreadsList.scss';
import 'quill/dist/quill.snow.css';

import React from 'react';

import { ForumThread, GetForumThreadsQuery } from '../../../graphql/generated/graphql';
import { Pagination, TreadsItem } from '../../index';

const tasksQueryLimit = 10;

type ForumTreadsListType = {
  setCurrentPage: (value: any) => void;
  currentPage: number;
  noShowPagination: any;
  data: GetForumThreadsQuery | undefined;
  isAccessEdit: boolean;
  removeForumThreadMutation: any;
  updateForumThreadMutation: any;
};

export const ForumTreadsList = ({
  setCurrentPage,
  currentPage,
  noShowPagination,
  data,
  isAccessEdit,
  removeForumThreadMutation,
  updateForumThreadMutation,
}: ForumTreadsListType) => {
  return (
    <div className='ForumTreadsList'>
      {noShowPagination && <div></div>}
      <div className='ForumTreadsList__navigations'>
        <Pagination
          paginate={setCurrentPage}
          currentPage={currentPage}
          totalCount={data?.getForumThreads?.count as number}
          pageSize={tasksQueryLimit}
          siblingCount={1}
          hideArrow
        />
      </div>
      <div className='ForumTreadsList__list'>
        {data?.getForumThreads?.rows?.map((tread) => {
          if (!tread) return;
          return (
            <TreadsItem
              key={tread.id}
              updateForumThreadMutation={updateForumThreadMutation}
              removeForumThreadMutation={removeForumThreadMutation}
              tread={tread as ForumThread}
              isAccessEdit={isAccessEdit}
            />
          );
        })}
        {data?.getForumThreads?.count === 0 && (
          <div> В этом разделе еще не создана ни одна тема</div>
        )}
      </div>
    </div>
  );
};
