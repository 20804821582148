import './DecideOnTheFlyCreate.scss';

import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { DecideOnTheFlyControlItem } from '../../components/decideOnTheFlyControlItem/DecideOnTheFlyControlItem';
import { LongArrowLeft } from '../../components/icons';
import { PATHS, ROLES } from '../../constants/constants';
import { useAuthContext } from '../../context';
import {
  useCreateDecideOnFlyActionMutation,
  useCreateDecideOnFlyPackageMutation,
  useDecideOnFlyPackageQuery,
  useRemoveDecideOnFlyActionMutation,
  useUpdateDecideOnFlyActionMutation,
  useUpdateDecideOnFlyPackageMutation,
} from '../../graphql/generated/graphql';
import { createPackageShema } from '../../validation/schemas';
import { PackageControll } from './PackageControll/PackageControll';

export const DecideOnTheFlyCreate = () => {
  const [sechParams] = useSearchParams();

  const idPage = sechParams?.get('id');

  const { data, refetch } = useDecideOnFlyPackageQuery({
    variables: {
      id: parseInt(idPage as any),
    },
    skip: !idPage,
    fetchPolicy: 'cache-and-network',
    onError: () => {
      navigate(-1);
    },
  });

  enum createStatus {
    empty = '',
    create = 'create',
  }

  const [statusIsCareate, setStatusIsCareate] = useState(createStatus.empty);

  const { currentUserData } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUserData?.role !== ROLES.ADMIN) {
      navigate('/');
    }
  }, []);

  const [createDecideOnFlyPackageMutation] = useCreateDecideOnFlyPackageMutation({
    onCompleted: (data) => {
      toast.success('Создано');
      navigation({
        pathname: PATHS.decideOnTheFlyCreate,
        search: `&id=${data?.createDecideOnFlyPackage?.id}`,
      });
    },
  });

  const [createDecideOnFlyActionMutation] = useCreateDecideOnFlyActionMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const [updateDecideOnFlyActionMutation] = useUpdateDecideOnFlyActionMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const [removeDecideOnFlyActionMutation] = useRemoveDecideOnFlyActionMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const [updateDecideOnFlyPackageMutation] = useUpdateDecideOnFlyPackageMutation({
    onCompleted: () => {
      toast.success('Обновлено');
      refetch();
    },
  });

  const getDecideOnFlyPeriods = data?.decideOnFlyPackage?.decideOnFlyActions;

  const navigation = useNavigate();

  return (
    <Formik
      onSubmit={(values) => {
        if (!idPage) {
          createDecideOnFlyPackageMutation({
            variables: {
              createDecideOnFlyPackageInput: {
                title: values?.title,
                color: values?.color,
                description: values?.description,
              },
            },
          });
          setStatusIsCareate(createStatus.create);
        } else {
          updateDecideOnFlyPackageMutation({
            variables: {
              updateDecideOnFlyPackageInput: {
                title: values?.title,
                color: values?.color,
                description: values?.description,
                id: parseInt(idPage as any),
              },
            },
          });
        }
      }}
      enableReinitialize={true}
      validationSchema={createPackageShema}
      initialValues={{
        color: data?.decideOnFlyPackage?.color ? data?.decideOnFlyPackage?.color : 'red',
        title: data?.decideOnFlyPackage?.title ? data?.decideOnFlyPackage?.title : '',
        description: data?.decideOnFlyPackage?.description
          ? data?.decideOnFlyPackage?.description
          : '',
      }}
    >
      {({ setFieldValue, values }) => {
        return (
          <Form className='DecideOnTheFlyCreate'>
            <div
              onClick={() => {
                if (statusIsCareate === createStatus.create) {
                  return navigation(-2);
                }
                navigation(-1);
              }}
              className='DecideOnTheFlyCreate__getBack'
            >
              <LongArrowLeft
                style={{
                  marginRight: '10px',
                }}
              />
              Вернуться назад
            </div>
            <div className='DecideOnTheFlyCreate__controll'>
              <PackageControll
                setFieldValue={setFieldValue}
                values={values}
                idPage={idPage}
              />
            </div>

            <div className='DecideOnTheFlyCreate__half-outer'>
              <div className='DecideOnTheFlyCreate__half'>
                <div className='DecideOnTheFlyCreate__head'>Будний день</div>
                <div className='DecideOnTheFlyCreate__element'>
                  <div className='DecideOnTheFlyCreate__item'>
                    <DecideOnTheFlyControlItem
                      createDecideOnFlyActionMutation={createDecideOnFlyActionMutation}
                      updateDecideOnFlyActionMutation={updateDecideOnFlyActionMutation}
                      removeDecideOnFlyActionMutation={removeDecideOnFlyActionMutation}
                      decideOnFlyActions={getDecideOnFlyPeriods?.filter(
                        (e) => e.decideOnFlyPeriodId === 1,
                      )}
                      decideOnFlyPeriod={1}
                      time='День'
                      disableButton={!idPage}
                      idPage={parseInt(idPage as any)}
                      backgroundColor={data?.decideOnFlyPackage?.color}
                    />
                  </div>
                  <div className='DecideOnTheFlyCreate__item'>
                    <DecideOnTheFlyControlItem
                      createDecideOnFlyActionMutation={createDecideOnFlyActionMutation}
                      updateDecideOnFlyActionMutation={updateDecideOnFlyActionMutation}
                      removeDecideOnFlyActionMutation={removeDecideOnFlyActionMutation}
                      decideOnFlyActions={getDecideOnFlyPeriods?.filter(
                        (e) => e.decideOnFlyPeriodId === 2,
                      )}
                      decideOnFlyPeriod={2}
                      time='Вечер'
                      disableButton={!idPage}
                      idPage={parseInt(idPage as any)}
                      backgroundColor={data?.decideOnFlyPackage?.color}
                    />
                  </div>
                </div>
              </div>
              <div className='DecideOnTheFlyCreate__half'>
                <div className='DecideOnTheFlyCreate__head'>Выходной день</div>
                <div className='DecideOnTheFlyCreate__element'>
                  <div className='DecideOnTheFlyCreate__item'>
                    <DecideOnTheFlyControlItem
                      createDecideOnFlyActionMutation={createDecideOnFlyActionMutation}
                      updateDecideOnFlyActionMutation={updateDecideOnFlyActionMutation}
                      removeDecideOnFlyActionMutation={removeDecideOnFlyActionMutation}
                      decideOnFlyActions={getDecideOnFlyPeriods?.filter(
                        (e) => e.decideOnFlyPeriodId === 3,
                      )}
                      decideOnFlyPeriod={3}
                      time='День'
                      disableButton={!idPage}
                      idPage={parseInt(idPage as any)}
                      backgroundColor={data?.decideOnFlyPackage?.color}
                    />
                  </div>
                  <div className='DecideOnTheFlyCreate__item'>
                    <DecideOnTheFlyControlItem
                      createDecideOnFlyActionMutation={createDecideOnFlyActionMutation}
                      updateDecideOnFlyActionMutation={updateDecideOnFlyActionMutation}
                      removeDecideOnFlyActionMutation={removeDecideOnFlyActionMutation}
                      decideOnFlyActions={getDecideOnFlyPeriods?.filter(
                        (e) => e.decideOnFlyPeriodId === 4,
                      )}
                      decideOnFlyPeriod={4}
                      time='Вечер'
                      disableButton={!idPage}
                      idPage={parseInt(idPage as any)}
                      backgroundColor={data?.decideOnFlyPackage?.color}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
