import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as GENERATED from '../../graphql/generated/graphql';
import {
  GetArchivedProjectsDocument,
  GetArchivedProjectsQuery,
  GetProjectsDocument,
  GetProjectsQuery,
} from '../../graphql/generated/graphql';
import { getErrorText } from '../../utils';

export const useUpdateProjectMutation = () => {
  const location = useLocation();

  const isArchivedPage = location.pathname.includes('archive');
  return GENERATED.useUpdateProjectMutation({
    update(cache, { data }) {
      if (!data?.updateProject) return null;

      if (!isArchivedPage) {
        const projects: GetProjectsQuery | null = cache.readQuery({
          query: GetProjectsDocument,
        });

        const newData = projects?.getProjects.rows.map((project) =>
          project?.id === data?.updateProject.id
            ? {
                ...project,
                name: data.updateProject.name,
                number: data.updateProject.number,
              }
            : project,
        );

        if (projects) {
          cache.writeQuery({
            query: GetProjectsDocument,
            data: {
              ...projects,
              getProjects: {
                ...projects?.getProjects,
                rows: newData,
              },
            },
          });
        }
      } else {
        const archivedProjects: GetArchivedProjectsQuery | null = cache.readQuery({
          query: GetArchivedProjectsDocument,
          variables: { data: { limit: 100 } },
        });

        const newArchivedProjectsData = archivedProjects?.getArchivedProjects.rows.map(
          (project) =>
            Number(project?.id) === data?.updateProject.id
              ? {
                  ...project,
                  name: data.updateProject.name,
                  number: data.updateProject.number,
                }
              : project,
        );

        if (archivedProjects) {
          cache.writeQuery({
            query: GetArchivedProjectsDocument,
            variables: { data: { limit: 100 } },
            data: {
              ...archivedProjects,
              getArchivedProjects: {
                ...archivedProjects?.getArchivedProjects,
                rows: newArchivedProjectsData,
              },
            },
          });
        }
      }
    },
    onError: (error) => {
      if (error?.message) {
        toast.error(getErrorText(error.message));
      }
    },
  });
};
