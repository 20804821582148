import './DecideOnTheFlyControl.scss';

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { DecideOnTheFlyControlItem } from '../../../components/decideOnTheFlyControlItem/DecideOnTheFlyControlItem';
import { LongArrowLeft } from '../../../components/icons';
import { ROLES } from '../../../constants/constants';
import { useAuthContext } from '../../../context';
import {
  useCreateDecideOnFlyActionMutation,
  useGetDecideOnFlyPeriodsQuery,
  useRemoveDecideOnFlyActionMutation,
  useUpdateDecideOnFlyActionMutation,
} from '../../../graphql/generated/graphql';

type DecideOnTheFlyControlType = {
  setStatePage: (value: number) => void;
};

export const DecideOnTheFlyControl = ({ setStatePage }: DecideOnTheFlyControlType) => {
  const { data, refetch } = useGetDecideOnFlyPeriodsQuery({
    variables: {},
    fetchPolicy: 'network-only',
  });

  const { currentUserData } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUserData?.role !== ROLES.ADMIN) {
      navigate('/');
    }
  }, []);

  const [createDecideOnFlyActionMutation] = useCreateDecideOnFlyActionMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const [updateDecideOnFlyActionMutation] = useUpdateDecideOnFlyActionMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const [removeDecideOnFlyActionMutation] = useRemoveDecideOnFlyActionMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const getDecideOnFlyPeriods = data?.getDecideOnFlyPeriods;

  const navigation = useNavigate();

  return (
    <div className='DecideOnTheFlyControl'>
      <div
        onClick={() => {
          navigation(-1);
        }}
        className='DecideOnTheFlyControl__getBack'
      >
        <LongArrowLeft
          style={{
            marginRight: '10px',
          }}
        />
        Вернуться назад
      </div>
      <div className='DecideOnTheFlyControl__controll'>
        <div
          className={`DecideOnTheFlyControl__controll-item`}
          onClick={() => {
            setStatePage(1);
          }}
        >
          Пакеты
        </div>
        <div
          className={`DecideOnTheFlyControl__controll-item DecideOnTheFlyControl__controll-select`}
        >
          Спонтанные Задания
        </div>
      </div>

      <div className='DecideOnTheFlyControl__title'>Спонтанные Задания </div>
      <div className='DecideOnTheFlyControl__half-outer'>
        <div className='DecideOnTheFlyControl__half'>
          <div className='DecideOnTheFlyControl__head'>Будний день</div>
          <div className='DecideOnTheFlyControl__element'>
            <div className='DecideOnTheFlyControl__item'>
              <DecideOnTheFlyControlItem
                createDecideOnFlyActionMutation={createDecideOnFlyActionMutation}
                updateDecideOnFlyActionMutation={updateDecideOnFlyActionMutation}
                removeDecideOnFlyActionMutation={removeDecideOnFlyActionMutation}
                decideOnFlyActions={getDecideOnFlyPeriods?.[0]?.decideOnFlyActions}
                decideOnFlyPeriod={1}
                time='День'
                backgroundColor={null}
              />
            </div>
            <div className='DecideOnTheFlyControl__item'>
              <DecideOnTheFlyControlItem
                createDecideOnFlyActionMutation={createDecideOnFlyActionMutation}
                updateDecideOnFlyActionMutation={updateDecideOnFlyActionMutation}
                removeDecideOnFlyActionMutation={removeDecideOnFlyActionMutation}
                decideOnFlyActions={getDecideOnFlyPeriods?.[1]?.decideOnFlyActions}
                decideOnFlyPeriod={2}
                time='Вечер'
                backgroundColor={null}
              />
            </div>
          </div>
        </div>
        <div className='DecideOnTheFlyControl__half'>
          <div className='DecideOnTheFlyControl__head'>Выходной день</div>
          <div className='DecideOnTheFlyControl__element'>
            <div className='DecideOnTheFlyControl__item'>
              <DecideOnTheFlyControlItem
                createDecideOnFlyActionMutation={createDecideOnFlyActionMutation}
                updateDecideOnFlyActionMutation={updateDecideOnFlyActionMutation}
                removeDecideOnFlyActionMutation={removeDecideOnFlyActionMutation}
                decideOnFlyActions={getDecideOnFlyPeriods?.[2]?.decideOnFlyActions}
                decideOnFlyPeriod={3}
                time='День'
                backgroundColor={null}
              />
            </div>
            <div className='DecideOnTheFlyControl__item'>
              <DecideOnTheFlyControlItem
                createDecideOnFlyActionMutation={createDecideOnFlyActionMutation}
                updateDecideOnFlyActionMutation={updateDecideOnFlyActionMutation}
                removeDecideOnFlyActionMutation={removeDecideOnFlyActionMutation}
                decideOnFlyActions={getDecideOnFlyPeriods?.[3]?.decideOnFlyActions}
                decideOnFlyPeriod={4}
                time='Вечер'
                backgroundColor={null}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
