import React from 'react';

export const OpenTreadIcons = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.83337 8.83366H6.33337V8.33366V5.83366C6.33337 3.8123 7.97868 2.16699 10 2.16699C12.0214 2.16699 13.6667 3.8123 13.6667 5.83366V6.16699H13V5.83366C13 4.17918 11.6545 2.83366 10 2.83366C8.34556 2.83366 7.00004 4.17918 7.00004 5.83366V8.33366V8.83366H7.50004H15C15.6431 8.83366 16.1667 9.3573 16.1667 10.0003V16.667C16.1667 17.31 15.6431 17.8337 15 17.8337H5.00004C4.35702 17.8337 3.83337 17.31 3.83337 16.667V10.0003C3.83337 9.3573 4.35702 8.83366 5.00004 8.83366H5.83337ZM15.5 10.0002L15.4999 9.50033H15H5.00004H4.50004V10.0003V16.667V17.167H5.00004H15.0017H15.5018L15.5017 16.6669L15.5 10.0002Z'
        fill='#303030'
        stroke='#303030'
      />
    </svg>
  );
};
