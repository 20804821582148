import React from 'react';

export const DecideOnTheFly = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
    >
      <path
        d='M25.2856 13.848C25.2501 13.6977 25.1781 13.5586 25.0758 13.4429C24.9736 13.3271 24.8444 13.2385 24.6997 13.1847L17.9485 10.6523L19.6665 2.05893C19.7054 1.85926 19.6783 1.65237 19.5893 1.46945C19.5003 1.28654 19.3542 1.13754 19.1731 1.04493C18.992 0.952321 18.7856 0.921133 18.5852 0.956072C18.3849 0.99101 18.2013 1.09018 18.0622 1.23861L4.93717 15.3011C4.83057 15.4134 4.75346 15.5504 4.71272 15.6998C4.67197 15.8492 4.66887 16.0064 4.7037 16.1573C4.73852 16.3082 4.81017 16.4481 4.91226 16.5645C5.01435 16.681 5.14369 16.7703 5.28874 16.8246L12.0423 19.357L10.329 27.941C10.2901 28.1406 10.3172 28.3475 10.4062 28.5304C10.4952 28.7133 10.6412 28.8623 10.8224 28.955C11.0035 29.0476 11.2098 29.0788 11.4102 29.0438C11.6106 29.0089 11.7942 28.9097 11.9333 28.7613L25.0583 14.6988C25.1629 14.5864 25.2384 14.4501 25.2781 14.3017C25.3177 14.1534 25.3203 13.9976 25.2856 13.848ZM12.8145 25.0781L14.0415 18.9398C14.0854 18.7221 14.0506 18.4959 13.9433 18.3014C13.836 18.107 13.6631 17.957 13.4555 17.8781L7.26334 15.5519L17.1797 4.92768L15.954 11.066C15.9101 11.2837 15.9449 11.5099 16.0522 11.7043C16.1595 11.8988 16.3323 12.0488 16.5399 12.1277L22.7274 14.448L12.8145 25.0781Z'
        fill='white'
      />
    </svg>
  );
};
