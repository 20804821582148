import { gql } from '@apollo/client';

export const GET_FILE_BY_ID = gql`
  query getFileById($id: Float!) {
    getFileById(id: $id) {
      id
      fileName
    }
  }
`;
