import * as yup from 'yup';

import * as MESSAGES from '../messages';

export const resetPasswordSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .matches(/^[A-Za-z0-9А-я_@./#&+-]*$/, MESSAGES.INCORRECT_PASSWORD)
    .min(3, MESSAGES.INCORRECT_MIN_LENGTH_3)
    .required(MESSAGES.REQUIRE_MESSAGE),
  newPassword: yup
    .string()
    .matches(/^[A-Za-z0-9А-я_@./#&+-]*$/, MESSAGES.INCORRECT_PASSWORD)
    .min(3, MESSAGES.INCORRECT_MIN_LENGTH_3)
    .max(16, MESSAGES.INCORRECT_MAX_LENGTH_16)
    .required(MESSAGES.REQUIRE_MESSAGE),
});
