import './ForumChapters.scss';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  ButtonLink,
  ButtonLinksWrap,
  ForumTreadsList,
  ForumTreadsNavigation,
  Header,
} from '../../components';
import { PlusIcons, QuoteIcons } from '../../components/icons';
import { PATHS } from '../../constants/constants';
import { useModalContext } from '../../context/';
import {
  GetForumSectionsQuery,
  useCreateForumThreadMutation,
  useGetForumSectionsQuery,
  useGetForumThreadsQuery,
  useNewActivitySubscription,
  useRemoveForumThreadMutation,
  useUpdateForumThreadMutation,
} from '../../graphql/generated/graphql';
import { useForumAccess } from '../../hooks';
import { ModalStatuses } from '../../types/enums';

const tasksQueryLimit = 10;

export const ForumChapters = () => {
  useNewActivitySubscription({
    variables: {},
  });

  const {
    data: dataSection,
    loading: loadSection,
    refetch: refetchSection,
  } = useGetForumSectionsQuery({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const noSectionData = !loadSection && dataSection?.getForumSections?.count === 0;

  const [currentPage, setCurrentPage] = useState(1);

  const { sectionId } = useParams();

  const sectionRole = dataSection?.getForumSections?.rows?.find(
    (e: any) => e?.id === parseInt(sectionId as any),
  )?.role;

  const { isAccessEdit } = useForumAccess({ sectionRole });

  const { data, refetch } = useGetForumThreadsQuery({
    variables: {
      data: {
        offset: (currentPage - 1) * tasksQueryLimit,
        limit: tasksQueryLimit,
        forumSectionId: parseInt(sectionId as string),
      },
    },
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    setCurrentPage(1);
  }, [sectionId]);

  const noShowPagination = (data?.getForumThreads?.count as number) < tasksQueryLimit + 1;

  const [createForumThreadMutation] = useCreateForumThreadMutation({
    onCompleted: () => {
      refetch();
    },
    onError: (error) => {
      if (error.message === 'FILE_NOT_ALLOWED') {
        toast.error('Файл не изображение');
      }
    },
  });
  const [updateForumThreadMutation] = useUpdateForumThreadMutation({
    onCompleted: () => {
      refetch();
    },
    onError: (error) => {
      if (error.message === 'FILE_NOT_ALLOWED') {
        toast.error('Файл не изображение');
      }
    },
  });

  const [removeForumThreadMutation] = useRemoveForumThreadMutation({
    onCompleted: () => {
      refetch();
    },
  });
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  return (
    <div className='forumPage__wrapper'>
      <div className='forumPage'>
        <Header />
        <div>
          <div className='content__black-block'>
            <div className='content__black-block__inner'>
              <div className='content__black-block__text'>
                <QuoteIcons />
                <div className='content__black-block__text-inner'>
                  В этом есть настоящий секрет жизни: целиком отдаваться тому, что ты
                  делаешь прямо здесь и сейчас, и вместо того, чтобы называть это работой,
                  осознать, что это – игра <QuoteIcons className='iconRotate' />
                </div>
                <div className='content__black-block__author '>Алан Уилсон Уотс</div>
              </div>
              <div
                className='forumPage__addButton'
                onClick={() => {
                  handleSetModalStatus(ModalStatuses.FORUM_TREAD);
                  handleChangeModalParameters([
                    {
                      key: 'title',
                      value: 'Создать тему',
                    },
                    {
                      key: 'buttonText',
                      value: 'Создать',
                    },
                    {
                      key: 'initialValues',
                      value: {
                        // avatar: '',
                      },
                    },
                    {
                      key: 'onCallback',
                      value: (value: any) => {
                        createForumThreadMutation({
                          variables: {
                            data: {
                              description: value?.description,
                              name: value?.name,
                              forumSectionId: `${sectionId}`,
                              image: value?.image,
                              viewType: value?.viewType,
                              isAttached: false,
                              authorAvatar: value?.avatar ? value?.avatar : undefined,
                            },
                          },
                        });
                      },
                    },
                  ]);
                }}
              >
                <PlusIcons color='black' /> Создать тред
              </div>
            </div>
          </div>
        </div>
        <div className='block'>
          <div className='navigation-treads'>
            <div className='navigation-treads__inner'>
              <ForumTreadsNavigation
                dataSection={dataSection as GetForumSectionsQuery}
                refetchSection={refetchSection}
              />
            </div>
          </div>
          <div className='sidebar'></div>
          <div className='content'>
            {noSectionData && (
              <div className='mt10'> В этом разделе еще не создана ни одна тема</div>
            )}

            <div className='content__treads'>
              {!noSectionData && (
                <ForumTreadsList
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  noShowPagination={noShowPagination}
                  data={data}
                  isAccessEdit={isAccessEdit}
                  updateForumThreadMutation={updateForumThreadMutation}
                  removeForumThreadMutation={removeForumThreadMutation}
                />
              )}
            </div>
          </div>
          <div className='sidebar'></div>
        </div>
        <div className='forumPage__footer'></div>
      </div>
      <ButtonLinksWrap>
        <ButtonLink
          mode='decideOnTheFly'
          title='Решай-на-лету'
          path={PATHS.decideOnTheFly}
        />
        <ButtonLink mode='circle' title='Круг общения' path={PATHS.circle} />
        <ButtonLink mode='chrono' title='Хронометр' path={PATHS.chronometer} />
        <ButtonLink mode='file' title='Проект "Я"' path='/projectI' />
        <ButtonLink
          mode='myTask'
          title='Ответственные задачи по проектам'
          path='/my-tasks-maintainer'
        />
        <ButtonLink mode='history' title='История задач' path='/history' />
      </ButtonLinksWrap>
    </div>
  );
};
