import './CircleAddMeetModal.scss';

import React from 'react';

import { Button } from '../../../index';

export const CircleAddMeetModal = ({ onClose, modalParameters }: any) => {
  return (
    <div className='CircleAddMeetModal'>
      <div className='CircleAddMeetModal__title'> Назначить встречу</div>
      <div className='CircleAddMeetModal__buttons'>
        <Button
          type='submit'
          onClick={() => {
            modalParameters?.onCallback();
            onClose();
          }}
        >
          Встреча
        </Button>
        <Button onClick={onClose}>Отменить</Button>
      </div>
    </div>
  );
};
