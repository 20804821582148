import React from 'react';

export const MinusIcon = ({ onClick }: any) => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        d='M6.40669 5.5979H10.0999C10.3219 5.5979 10.5044 5.77817 10.5 6.0024C10.5 6.22443 10.3197 6.40689 10.0955 6.40689H6.40449L5.59551 6.40469H1.90449C1.68246 6.40469 1.5 6.22443 1.5 6.0002C1.5 5.89248 1.54177 5.79136 1.61871 5.71441C1.69345 5.63747 1.79678 5.5957 1.90449 5.5957H5.5977L6.40669 5.5979Z'
        fill='#EE3944'
      />
    </svg>
  );
};
