import './Chronometer.scss';

import React, { CSSProperties } from 'react';

import { ROLES } from '../../../constants/constants';
import { useAuthContext, useMenuContext } from '../../../context';
import { toolsChronoType } from '../../../types/types';
import { CrossIcon } from '../../icons';

type ChronometerHtml2Type = {
  handleSetSelectHours: (value: any) => void;
  toolsChrono: toolsChronoType;
  selectDay: any;
  handleEnergyFixed: (value: any) => void;
  handleTimeFixed: (value: any) => void;
};

export const ChronometerHtml2 = ({
  handleSetSelectHours,
  selectDay,
  toolsChrono,
  handleEnergyFixed,
  handleTimeFixed,
}: ChronometerHtml2Type) => {
  const showNumber = toolsChrono.showNumber();
  const { isOpenMenu } = useMenuContext();
  const { currentUserData } = useAuthContext();

  return (
    <div className='clock'>
      {selectDay?.hours?.map((hour: any, index: number) => {
        const select = toolsChrono.findIndex(index) !== -1;
        const showSelect = showNumber?.map((e: any) => e.hour).indexOf(index);
        return (
          <div
            key={index}
            className='hour-wrapper'
            style={{ '--hour': index } as CSSProperties}
            onClick={() => {
              handleSetSelectHours(index);
            }}
          >
            <div className='hour'>
              <Element index={index} select={select} hour={hour} />
              {showSelect !== -1 && (
                <div
                  className='hour-number'
                  style={{
                    top:
                      isOpenMenu && currentUserData?.role !== ROLES.GUEST
                        ? '-40px'
                        : '-60px',
                  }}
                >
                  <div
                    style={{
                      marginLeft: 'auto',
                    }}
                  >
                    {showNumber[showSelect]?.range}
                  </div>
                  <div
                    style={{
                      marginLeft: 'auto',
                      paddingRight: '2px',
                    }}
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTimeFixed({ fileId: null });
                      }}
                    >
                      <CrossIcon />
                    </div>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEnergyFixed({ energy: null });
                      }}
                    >
                      <CrossIcon />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
type ElementType = {
  index: number | string;
  select: boolean;
  hour: any;
};

const Element = ({ select, index, hour }: ElementType) => {
  let color = hour?.color ? hour?.color : 'white';
  if (parseInt(index as any) % 2 !== 0 && !hour?.color) {
    color = '#F5F5F5';
  }
  return <Rect fill={color} stroke={select ? '#303030' : '#AFAFAF'} select={select} />;
};

const Rect = ({ onClick, fill = '#F5F5F5', stroke = '#AFAFAF', select }: any) => {
  return (
    <svg
      width='34'
      height='58'
      viewBox='0 0 34 58'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        d='M1.57321 10.9334C0.959075 6.24877 4.25439 2.03498 8.82922 1.75822C14.0785 1.44066 19.3414 1.41598 24.5935 1.6843C29.1707 1.91814 32.5054 6.10084 31.9352 10.791L27.4459 47.7191C26.884 52.3416 22.6322 55.7224 17.76 55.7009C17.2296 55.6985 16.6992 55.701 16.1689 55.7083C11.2972 55.7756 7.01389 52.4348 6.40862 47.8178L1.57321 10.9334Z'
        fill={fill}
        stroke={stroke}
        strokeWidth={select ? '3' : '1'}
      />
    </svg>
  );
};
