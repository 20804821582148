import React from 'react';

export const EditIcon3 = ({ onClick }: any) => {
  return (
    <div
      style={{
        width: '14px',
        height: '14px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={onClick}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
        style={{
          width: '14px',
          height: '14px',
        }}
      >
        <path
          d='M9.54948 12.8326H2.5009C1.76345 12.8326 1.16406 12.2332 1.16406 11.4958V4.44719C1.16406 3.70974 1.76345 3.11035 2.5009 3.11035H6.63281C6.83406 3.11035 6.9974 3.27368 6.9974 3.47493C6.9974 3.67619 6.83406 3.83952 6.63281 3.83952H2.5009C2.16595 3.83952 1.89323 4.11224 1.89323 4.44719V11.4958C1.89323 11.8307 2.16595 12.1034 2.5009 12.1034H9.54948C9.88442 12.1034 10.1571 11.8307 10.1571 11.4958V7.36385C10.1571 7.1626 10.3205 6.99927 10.5217 6.99927C10.723 6.99927 10.8863 7.16216 10.8863 7.36385V11.4958C10.8863 12.2332 10.2869 12.8326 9.54948 12.8326Z'
          fill='#303030'
          stroke='#303030'
          strokeWidth='0.3'
        />
        <path
          d='M5.4347 8.9331C5.33893 8.9331 5.24556 8.89518 5.17702 8.8262C5.0905 8.74013 5.05357 8.61614 5.07742 8.49704L5.42109 6.77818C5.43515 6.70724 5.47013 6.64253 5.52069 6.59197L10.5554 1.55786C11.0765 1.0367 11.9243 1.0367 12.4459 1.55786C12.6981 1.81011 12.8372 2.14559 12.8372 2.50287C12.8372 2.86015 12.6981 3.19554 12.4454 3.44789L7.41125 8.48253C7.36069 8.53354 7.29554 8.56807 7.22504 8.58214L5.50662 8.9258C5.48277 8.9307 5.45847 8.9331 5.4347 8.9331ZM7.15357 8.22485H7.15846H7.15357ZM6.11429 7.02954L5.89987 8.1038L6.97368 7.88893L11.9301 2.93261C12.0448 2.81734 12.108 2.66522 12.108 2.50287C12.108 2.34052 12.0448 2.18831 11.9301 2.07313C11.6939 1.83637 11.3084 1.83637 11.0707 2.07313L6.11429 7.02954Z'
          fill='#303030'
          stroke='#303030'
          strokeWidth='0.2'
        />
        <path
          d='M11.497 4.24242C11.4037 4.24242 11.3103 4.2069 11.2394 4.13543L9.86462 2.76023C9.72221 2.61781 9.72221 2.38692 9.86462 2.24451C10.007 2.10209 10.2379 2.10209 10.3804 2.24451L11.7551 3.61971C11.8975 3.76212 11.8975 3.99301 11.7551 4.13543C11.6836 4.20646 11.5903 4.24242 11.497 4.24242Z'
          fill='#303030'
          stroke='#303030'
          strokeWidth='0.2'
        />
      </svg>
    </div>
  );
};
