import './PolygonPaletteModal.scss';

import { FormikValues } from 'formik';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';

import { removeDuplicates } from '../../../../utils';
import { Button } from '../../../button/Button';
import { RemoveIcon } from '../../../icons';
import { Modal } from '../../Modal';

export const PolygonPaletteModal: FC<PolygonPaletteModalType> = ({
  onSubmit,
  onClose,
  initColors,
}) => {
  const [pickerColors, setPickerColors] = useState('');

  const [selectedFieldId, setSelectedFieldId] = useState(1);

  const [colorsState, setColorsState] =
    useState<Array<{ id: number; name: string; color: string }>>(initColors);

  const handleChangeColorItemName = (
    e: ChangeEvent<HTMLInputElement>,
    fieldId: number,
  ) => {
    setColorsState(
      colorsState.map((item) =>
        item.id === fieldId ? { ...item, name: e.currentTarget.value } : item,
      ),
    );
  };

  const handleChangeColorItemColor = (paletteColor: string, fieldId: number) => {
    setColorsState(
      colorsState.map((item) =>
        item.id === fieldId ? { ...item, color: paletteColor } : item,
      ),
    );
  };

  const handleRemoveColorItem = (itemId: number) => {
    setColorsState(colorsState.filter((item) => item.id !== itemId));
  };

  const handleSubmit = () => {
    onSubmit(removeDuplicates(colorsState, 'color'));
    onClose();
  };

  const { handleEnter } = useEnterSetColor({
    setPickerColors,
    handleChangeColorItemColor,
    setSelectedFieldId,
    handleSubmit,
    selectedFieldId,
  });

  return (
    <div className='polygon-palette-modal'>
      <span className='polygon-palette-modal__title'>Задать цветовую схему</span>
      {colorsState.map((color) => {
        return (
          <div key={color.id} className='polygon-palette-modal__colors-items'>
            <div
              style={{
                backgroundColor: color.color || '#AFAFAF',
              }}
              className='polygon-palette-modal__colors-items-color'
              onClick={() => {
                setSelectedFieldId(color.id);
                setPickerColors(color.color);
              }}
            />
            <Modal
              isOpen={selectedFieldId === color.id}
              onClose={() => setSelectedFieldId(0)}
            >
              <div className='polygon-palette-modal__picker'>
                <ChromePicker
                  disableAlpha
                  color={pickerColors}
                  onChangeComplete={(paletteColor) => {
                    setPickerColors(paletteColor.hex);
                    handleChangeColorItemColor(paletteColor.hex, color.id);
                  }}
                  onChange={(paletteColor) => {
                    handleEnter({ hex: paletteColor.hex, id: color.id });
                  }}
                />
                <Button
                  onClick={() => {
                    setSelectedFieldId(0);
                  }}
                  text='Сохранить'
                />
              </div>
            </Modal>
            <input
              className='polygon-palette-modal__colors-items-colors-hex-input'
              type='text'
              disabled
              value={color.color}
              onChange={() => {}}
              placeholder='#000000'
            />
            <input
              className='polygon-palette-modal__colors-items-colors-description-input'
              type='text'
              value={color.name}
              onChange={(e) => handleChangeColorItemName(e, color.id)}
              placeholder='Введите описание'
            />
            <RemoveIcon
              onClick={() => handleRemoveColorItem(color.id)}
              color='#303030'
              className='remove-icon-inner'
            />
          </div>
        );
      })}
      <Button
        onClick={() =>
          setColorsState((prevState) => [
            ...prevState,
            { id: Date.now(), name: '', color: '' },
          ])
        }
        text='Добавить цвет'
        width='150px'
        height='20px'
      />
      <Button
        type='submit'
        text='Сохранить цветовую схему'
        onClick={handleSubmit}
        width='100%'
        height='25px'
      />
    </div>
  );
};

type PolygonPaletteModalType = {
  onSubmit: (values: FormikValues) => void;
  initialValues?: {
    [key: string]: any;
  };
  isOpen: boolean;
  onClose: () => void;
  initColors: [{ id: number; name: string; color: string }];
};

const useEnterSetColor = ({
  setPickerColors,
  handleChangeColorItemColor,
  setSelectedFieldId,
  handleSubmit,
  selectedFieldId,
}: any) => {
  const [colorEnter, setColorEnter] = useState('');
  const [colorID, setColorID] = useState(0);

  useEffect(() => {
    const handleUserKeyPress = (event: any) => {
      const { keyCode } = event;

      if (keyCode === 13 && (selectedFieldId === 1 || selectedFieldId === 0)) {
        handleSubmit();
      }

      if (keyCode === 13 && (selectedFieldId !== 1 || selectedFieldId !== 0)) {
        setPickerColors(colorEnter);
        handleChangeColorItemColor(colorEnter, colorID);
        setSelectedFieldId(0);
      }
    };

    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  });

  const handleEnter = ({ hex, id }: any) => {
    setColorEnter(hex);
    setColorID(id);
  };
  return { handleEnter };
};
