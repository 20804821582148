import { FORUM_ROLES, ROLES } from '../constants/constants';
import { useAuthContext } from '../context';

export const useForumAccess = ({ sectionRole }: any) => {
  const { currentUserData } = useAuthContext();
  const inAccessForum = () => {
    if (FORUM_ROLES.ADMIN_MODERATOR === sectionRole) {
      if (currentUserData?.role === ROLES.ADMIN) return true;
      if (currentUserData?.role === ROLES.MODERATOR) return true;
      return false;
    }
    if (FORUM_ROLES.ADMIN === sectionRole) {
      if (currentUserData?.role === ROLES.ADMIN) return true;
      return false;
    }

    return true;
  };
  return { isAccessEdit: inAccessForum() };
};
