import { useParams } from 'react-router-dom';

import * as GENERATED from '../../graphql/generated/graphql';
import { SortingField, SortingOrders } from '../../graphql/generated/graphql';

export const useGetProjectLightQuery = (options?: any, id?: string | number) => {
  const { projectId, polygonId } = useParams();
  return GENERATED.useGetProjectLightQuery({
    variables: {
      id: Number(projectId) || Number(id),
      tasksData: {
        limit: 100,
        isTaskStorage: false,
        sort:
          projectId && polygonId
            ? { type: SortingOrders.Desc, field: SortingField.Code }
            : undefined,
      },
    },
    ...options,
  });
};
