import './LinkAddIcon.scss';

import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const LinkAddIcon: FC<LinkAddIconType> = ({
  width = '20px',
  height = '20px',
  className = 'link-add-icon',
  color = '#ffffff',
  onClick,
  isBordered = false,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={`${className} ${isBordered ? '_active' : ''}`}
      onClick={onClick}
      style={isBordered ? { borderLeft: '1px solid #AFAFAF' } : {}}
    >
      <path
        d='M11.2109 12.9961C11.1816 12.967 11.1419 12.9507 11.1006 12.9507C11.0593 12.9507 11.0196 12.967 10.9902 12.9961L8.7207 15.2656C7.66992 16.3164 5.89648 16.4277 4.73633 15.2656C3.57422 14.1035 3.68555 12.332 4.73633 11.2812L7.00586 9.01172C7.06641 8.95117 7.06641 8.85156 7.00586 8.79102L6.22852 8.01367C6.19915 7.98459 6.15949 7.96828 6.11816 7.96828C6.07684 7.96828 6.03718 7.98459 6.00781 8.01367L3.73828 10.2832C2.08594 11.9355 2.08594 14.6094 3.73828 16.2598C5.39062 17.9102 8.06445 17.9121 9.71484 16.2598L11.9844 13.9902C12.0449 13.9297 12.0449 13.8301 11.9844 13.7695L11.2109 12.9961ZM16.2617 3.73828C14.6094 2.08594 11.9355 2.08594 10.2852 3.73828L8.01367 6.00781C7.98459 6.03718 7.96828 6.07684 7.96828 6.11816C7.96828 6.15949 7.98459 6.19915 8.01367 6.22852L8.78906 7.00391C8.84961 7.06445 8.94922 7.06445 9.00977 7.00391L11.2793 4.73438C12.3301 3.68359 14.1035 3.57227 15.2637 4.73438C16.4258 5.89648 16.3145 7.66797 15.2637 8.71875L14.1289 9.85352L12.9941 10.9883C12.9651 11.0176 12.9487 11.0573 12.9487 11.0986C12.9487 11.14 12.9651 11.1796 12.9941 11.209L13.7715 11.9863C13.832 12.0469 13.9316 12.0469 13.9922 11.9863L16.2617 9.7168C17.9121 8.06445 17.9121 5.39062 16.2617 3.73828ZM11.916 7.27148C11.8866 7.24241 11.847 7.22609 11.8057 7.22609C11.7643 7.22609 11.7247 7.24241 11.6953 7.27148L7.27148 11.6934C7.24241 11.7227 7.22609 11.7624 7.22609 11.8037C7.22609 11.845 7.24241 11.8847 7.27148 11.9141L8.04492 12.6875C8.10547 12.748 8.20508 12.748 8.26563 12.6875L12.6875 8.26563C12.748 8.20508 12.748 8.10547 12.6875 8.04492L11.916 7.27148Z'
        fill={color}
      />
      <path
        d='M16.6667 13.3333H15V15H13.3334V16.6667H15V18.3333H16.6667V16.6667H18.3334V15H16.6667V13.3333Z'
        fill={color}
      />
    </svg>
  );
};

type LinkAddIconType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
> & { isBordered?: boolean };
