import './CalendarHeaderRow.scss';

import React from 'react';

import { getCalendarDaysArray } from '../../../utils';

type CalendarHeaderRowPropsTypes = {
  count: number;
  checkedDate: Date;
};

export const CalendarHeaderRow = ({
  count,
  checkedDate,
}: CalendarHeaderRowPropsTypes) => {
  return (
    <div className='calendarTable__header'>
      <div className='calendarTable__header--firstColumn'>Проект</div>
      <div className='calendarTable__header--days calendarTable__days'>
        {getCalendarDaysArray({
          count,
          isHeaderTable: true,
          checkedDate: checkedDate,
        }).map((item) => (
          <div
            key={item.id}
            className={`calendarTable__day ${item.today ? 'today' : ''}`}
          >
            {item.number}
          </div>
        ))}
      </div>
    </div>
  );
};
