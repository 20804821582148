export const getCalendarDaysArray = ({
  endDate,
  count,
  checkedDate,
  isHeaderTable = false,
}: {
  endDate?: string | null;
  count: number;
  checkedDate?: Date;
  isHeaderTable?: boolean;
}) => {
  const deadlineDay = endDate ? new Date(endDate).getDate() : undefined;
  const deadlineMonth = endDate ? new Date(endDate).getMonth() : undefined;
  const deadlineYear = endDate ? new Date(endDate).getFullYear() : undefined;

  const days: Array<DaysType> = [];
  const date = new Date();

  const currentDay = date.getDate();
  const currentMonth = date.getMonth();
  const currentYear = date.getFullYear();

  const checkedMonth = checkedDate?.getMonth();
  const checkedYear = checkedDate?.getFullYear();

  for (let i = 1; i <= count; i++) {
    days.push({
      id: i,
      number: isHeaderTable ? i : '',
      today: isHeaderTable
        ? i === currentDay && currentMonth === checkedMonth && currentYear === checkedYear
        : false,
      deadlineDay:
        i === deadlineDay &&
        checkedMonth === deadlineMonth &&
        checkedYear === deadlineYear,
      currentDay,
      checkedMonth,
      currentMonth,
      currentYear,
    });
  }

  return days;
};

export type DaysType = {
  id: number;
  number?: string | number;
  today: boolean;
  deadlineDay: boolean;
  currentDay: number;
  checkedMonth?: number;
  currentMonth: number;
  currentYear: number;
};
