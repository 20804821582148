import '../../components/masonryWrapper/MasonreyWrapper.scss';

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ArchiveProjectBlock, MasonryWrapper } from '../../components';
import { ROLES } from '../../constants/constants';
import { useAuthContext } from '../../context';
import { ArchivedProject } from '../../graphql/generated/graphql';
import { useGetArchivedProjectsQuery } from '../../hooks/useQueries';

export const Archive = () => {
  const { data } = useGetArchivedProjectsQuery();
  const { currentUserData } = useAuthContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (currentUserData?.role !== ROLES.ADMIN) {
      navigate('/');
    }
  }, []);

  return (
    <div className='container'>
      <MasonryWrapper>
        {data?.getArchivedProjects?.rows?.map((project, index) => {
          return (
            <ArchiveProjectBlock key={index} projectData={project as ArchivedProject} />
          );
        })}
      </MasonryWrapper>
    </div>
  );
};
