import React from 'react';

export const CalendarTimeIcons = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5 1.66663V6.66663L8.33333 9.99996L5 13.3333V18.3333H15V13.3333L11.6667 9.99996L15 6.66663V1.66663H5ZM13.3333 13.75V16.6666H6.66667V13.75L10 10.4166L13.3333 13.75ZM10 9.58329L6.66667 6.24996V3.33329H13.3333V6.24996L10 9.58329Z'
        fill='white'
      />
    </svg>
  );
};
