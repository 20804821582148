import './TreadItemImage.scss';

import React from 'react';

import { useModalContext } from '../../../context';
import {
  UpdateFileMutationFn,
  UpdateStorageItemMutationFn,
} from '../../../graphql/generated/graphql';
import { FileTypeStorage, ModalStatuses } from '../../../types/enums';
import { getUserAvatar } from '../../../utils';
import { SmallEditIcons, TreadsDeleteICons } from '../../icons';

type TreadItemFileType = {
  storageFiles: any;
  deleteFileMutation: any;
  deleteStorageItemMutation: any;
  updateFileMutation: UpdateFileMutationFn;
  updateStorageItemMutation: UpdateStorageItemMutationFn;
  trackStorageEventClickedMutation: any;
  updateThreadFileCommentMutation: any;
};
export const TreadItemImage = ({
  storageFiles,
  deleteFileMutation,
  deleteStorageItemMutation,
  updateFileMutation,
  updateStorageItemMutation,
  trackStorageEventClickedMutation,
  updateThreadFileCommentMutation,
}: TreadItemFileType) => {
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  return (
    <div className='TreadItemImage'>
      {storageFiles?.map((file: any) => {
        const allImg =
          file?.mimeType === FileTypeStorage.jpeg ||
          file?.mimeType === FileTypeStorage.gif ||
          file?.mimeType === FileTypeStorage.png ||
          file?.mimeType === FileTypeStorage.svg ||
          file?.mimeType === FileTypeStorage.webp;
        if (!allImg) return <></>;
        if (!file) return <></>;
        return (
          <div
            key={file?.id}
            style={{
              textDecoration: 'none',
            }}
            onClick={() => {
              trackStorageEventClickedMutation({
                variables: {
                  fileId: file.__typename !== 'StorageItem' ? file?.id : undefined,
                  storageItemId: file.__typename === 'StorageItem' ? file?.id : undefined,
                },
              });
            }}
          >
            <div
              className='TreadItemImage__item'
              style={{
                border: file?.userClickedAt && '1px solid #DEBB3F',
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${getUserAvatar(file?.path)})`,
                }}
                className='TreadItemImage__imge'
                onClick={() => {
                  handleSetModalStatus(ModalStatuses.TRED_OPEN_IMAGE_MODAL);
                  handleChangeModalParameters([
                    {
                      key: 'initialValues',
                      value: {
                        url: getUserAvatar(file?.path),
                        text: file?.threadFileWrap?.comment,
                      },
                    },
                  ]);
                }}
              ></div>
              <div
                style={{
                  padding: '5px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  width: '100%',
                  height: '32px',
                }}
              >
                <div
                  style={{
                    padding: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxSizing: 'border-box',
                  }}
                >
                  {file?.threadFileWrap?.orderNumber && (
                    <div
                      style={{
                        marginRight: '15px',
                        paddingTop: '3px',
                        boxSizing: 'border-box',
                      }}
                    >
                      №{file?.threadFileWrap?.orderNumber}
                    </div>
                  )}

                  <SmallEditIcons
                    className='mr10 TreadItemImage__item-edit'
                    onClick={(e: any) => {
                      e.preventDefault();
                      handleSetModalStatus(ModalStatuses.RENAME_TRED_FILE_MODAL);
                      handleChangeModalParameters([
                        {
                          key: 'onCallback',
                          value: (value: any) => {
                            if (file.__typename !== 'StorageItem') {
                              updateFileMutation({
                                variables: {
                                  id: file?.id,
                                  data: {
                                    fileName: value?.name,
                                  },
                                },
                              });
                            } else {
                              updateStorageItemMutation({
                                variables: {
                                  data: {
                                    id: file?.id,
                                    name: value?.name,
                                  },
                                },
                              });
                            }
                            if (value?.comment !== file?.threadFileWrap?.comment) {
                              updateThreadFileCommentMutation({
                                variables: {
                                  data: {
                                    id: file?.threadFileWrap?.id,
                                    comment: value?.comment,
                                  },
                                },
                              });
                            }
                          },
                        },
                        {
                          key: 'initialValues',
                          value: {
                            name: file?.name ? file?.name : file?.fileName,
                            comment: file?.threadFileWrap?.comment
                              ? file?.threadFileWrap?.comment
                              : '',
                            type: file?.threadFileWrap?.id && 'image',
                          },
                        },
                      ]);
                    }}
                  />
                </div>

                <TreadsDeleteICons
                  color='#E80025'
                  onClick={(e) => {
                    e?.preventDefault();
                    handleSetModalStatus(ModalStatuses.DELETE_TREDS_FILE);
                    handleChangeModalParameters([
                      {
                        key: 'onCallback',
                        value: () => {
                          if (file.__typename === 'StorageItem') {
                            deleteStorageItemMutation({
                              variables: {
                                storageItemId: file?.id,
                              },
                            });
                          } else {
                            deleteFileMutation({
                              variables: {
                                id: file?.id,
                              },
                            });
                          }
                        },
                      },
                    ]);
                  }}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
