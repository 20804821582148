import './ChronoTimeModal.scss';

import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect } from 'react';

import { chronoTime } from '../../../../validation/schemas';
import { AppSelect, Button } from '../../../index';

export const ChronoTimeModal = ({ onClose, modalParameters }: any) => {
  const options = modalParameters?.plan
    ?.sort((a: any, b: any) => {
      return a?.scaleMapFile?.order - b?.scaleMapFile?.order;
    })
    ?.map((e: any) => {
      if (e?.scaleMapFile?.isArchived) return;
      return { value: e?.scaleMapFileId, label: e?.scaleMapFile?.name };
    })
    ?.filter((e: any) => e);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ fileId: undefined }}
      onSubmit={(value) => {
        modalParameters.onCallback(value);
        onClose();
      }}
      validationSchema={chronoTime}
    >
      {({ errors, values, setFieldValue }) => {
        return (
          <Form className='chronoTimeModal'>
            <div className='chronoTimeModal__title'>Фиксирование времени</div>
            <div className='chronoTimeModal__date'>
              {modalParameters?.diapason?.map((e: any, index: number) => {
                return (
                  <span className='chronoTimeModal__time' key={index}>
                    {e?.range},{' '}
                  </span>
                );
              })}
              {moment(modalParameters.selectDay).format('DD.MM.YYYY')}
            </div>
            <div
              className='chronoTimeModal__selector'
              style={{
                borderBottom: errors?.fileId && '1px solid red',
              }}
            >
              Задача:
              {options && (
                <AppSelect
                  name='fileId'
                  options={options as any}
                  placeholder='Выберите файл'
                  isSearchable={true}
                  background='white'
                  hideError={true}
                />
              )}
            </div>
            <TaskSelector
              fileId={values?.fileId}
              setFieldValue={setFieldValue}
              plan={modalParameters?.plan}
            />

            <Button
              text={'Сохранить'}
              type='submit'
              width='100%'
              height='25px'
              margin='20px 0 0 0'
              backgroundColor='green'
              color='white'
            />
            <Button
              text={'Очистить'}
              width='100%'
              height='25px'
              margin='20px 0 0 0'
              onClick={() => {
                modalParameters.onCallback({ fileId: null });
                onClose();
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

const TaskSelector = ({ fileId, setFieldValue, plan }: any) => {
  useEffect(() => {
    setFieldValue('taskId', null);
  }, [fileId]);

  const mainOption = plan
    ?.filter((e: any) => e.scaleMapFileId === parseInt(fileId))[0]
    ?.scaleMapFile?.isMainScaleMapTasks?.filter((e: any) => e.isMain)
    ?.map((e: any) => {
      return { label: e.name, value: e.id };
    });

  if (!mainOption || mainOption?.length === 0) return <></>;

  return (
    <div className='chronoTimeModal__selector'>
      Записка:
      <AppSelect
        name='taskId'
        options={mainOption as any}
        placeholder='Выберите записку'
        isSearchable={true}
        background='white'
        hideError={true}
      />
    </div>
  );
};
