import { gql } from '@apollo/client';

export const GET_STORAGE_PROJECTS = gql`
  query getStorageProjects(
    $projectsData: GetProjectsInput!
    $storageFilter: [storageFilterItems!]!
    $limit: Int
    $offset: Int
    $search: String!
    $isOnlyFromThreads: Boolean
  ) {
    getProjects(data: $projectsData) {
      count
      rows {
        id
        name
        number
        storageEntities(
          storageFilter: $storageFilter
          limit: $limit
          offset: $offset
          search: $search
          isOnlyFromThreads: $isOnlyFromThreads
        ) {
          count
          rows {
            ... on File {
              id
              fileName
              previewUrl
              mimeType
              path
              size
              task {
                id
                number
                name
                code
              }
              subtask {
                id
                number
                name
                code
                task {
                  id
                }
              }
              thread {
                id
                scaleMapTask {
                  name
                }
              }
              createdAt
              updatedAt
              mimeType
            }
            ... on StorageItem {
              id
              name
              href
              thumbnailPath
              isAccess
              createdAt
              updatedAt
              taskId
              task {
                id
                number
                name
                code
              }
              subtask {
                id
                number
                name
                code
                task {
                  id
                }
              }
              thread {
                id
                scaleMapTask {
                  name
                }
              }
              subtaskId
              mimeType
            }
          }
        }
      }
    }
  }
`;
