import './ChronometerWeeks.scss';

import React, { useEffect } from 'react';

import { datesOfWeekType } from '../../../types/types';
import { datesOfWeek } from '../../../utils/';
import { ChangeDay } from './ChangeDay/ChangeDay';
import { ChangeWeeks } from './ChangeWeeks/ChangeWeeks';

type ChronometerWeeksType = {
  setWeeks: (value: datesOfWeekType) => void;
  weeks: datesOfWeekType;
  selectDayDate: string;
  setSelectDayDate: (value: string) => void;
};

export const ChronometerWeeks = ({
  setWeeks,
  weeks,
  selectDayDate,
  setSelectDayDate,
}: ChronometerWeeksType) => {
  useEffect(() => {
    setWeeks(datesOfWeek(0));
  }, []);
  return (
    <div className='chronometerWeeks'>
      <ChangeWeeks weeks={weeks} setWeeks={setWeeks} />
      <ChangeDay
        weeks={weeks}
        selectDayDate={selectDayDate}
        setSelectDayDate={setSelectDayDate}
      />
    </div>
  );
};
