import React from 'react';

export const SearchHeaderIcons = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.801 16.8467L13.8343 12.7211C14.8542 11.5087 15.413 9.98326 15.413 8.39518C15.413 4.68478 12.3943 1.66602 8.68387 1.66602C4.97347 1.66602 1.95471 4.68478 1.95471 8.39518C1.95471 12.1056 4.97347 15.1243 8.68387 15.1243C10.0768 15.1243 11.4042 14.7042 12.5391 13.9067L16.5359 18.0635C16.703 18.237 16.9277 18.3327 17.1685 18.3327C17.3964 18.3327 17.6126 18.2458 17.7767 18.0878C18.1255 17.7522 18.1366 17.1957 17.801 16.8467ZM8.68387 3.42145C11.4264 3.42145 13.6576 5.6526 13.6576 8.39518C13.6576 11.1377 11.4264 13.3689 8.68387 13.3689C5.9413 13.3689 3.71015 11.1377 3.71015 8.39518C3.71015 5.6526 5.9413 3.42145 8.68387 3.42145Z'
        fill='white'
      />
    </svg>
  );
};
