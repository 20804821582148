import './TaskPrice.scss';

import { FormikValues } from 'formik';
import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';

import { SortingField, SortingOrders } from '../../../graphql/generated/graphql';
import { GET_EXPENSES } from '../../../graphql/queries/getExpenses.gql';
import { useExpenses } from '../../../hooks';
import {
  useResetRealizationPriceMutation,
  useUpdateTaskMutation,
} from '../../../hooks/useMutations';
import { addPriceSchema } from '../../../validation/schemas';
import { CoinsIcon } from '../../icons/coinsIcon/CoinsIcon';
import { SimpleFormModal } from '../../simpleFormModal/SimpleFormModal';
import { EntityActionsType } from '../EntityActions';

export const TaskPrice: FC<Pick<EntityActionsType, 'entityId' | 'realizationPrice'>> = ({
  entityId,
  realizationPrice,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => setIsModalOpen(false);

  const [updateTask] = useUpdateTaskMutation();

  const [resetRealizationPrice] = useResetRealizationPriceMutation(handleCloseModal);

  const { date } = useExpenses();

  const refetchExpensesQueryParams = {
    query: GET_EXPENSES,
    variables: {
      expensesDate: date?.toISOString().substr(0, 7),
      data: {
        expensesDate: date?.toISOString().substr(0, 7),
        limit: 100,
        statusId: null,
        sort: { field: SortingField.Number, type: SortingOrders.Asc },
      },
    },
  };

  const handleSubmit = async (values: FormikValues) => {
    if (entityId && values.taskPrice) {
      await updateTask({
        variables: {
          id: Number(entityId),
          data: {
            realizationPrice: values.taskPrice,
          },
        },
        refetchQueries: [refetchExpensesQueryParams],
        onCompleted: (res) => {
          if (res.updateTask.name) {
            handleCloseModal();
            toast.success(
              `Добавлена стоимость реализации для задачи ${res.updateTask.name}`,
            );
          }
        },
      });
    }
  };

  const handleResetPrice = async () => {
    if (entityId) {
      await resetRealizationPrice({
        variables: {
          taskId: entityId,
        },
        refetchQueries: [refetchExpensesQueryParams],
      });
    }
  };
  return (
    <>
      <div className='taskPrice' onClick={handleOpenModal}>
        <CoinsIcon />
        <span>Стоимость</span>
      </div>
      <SimpleFormModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        initialValues={{ taskPrice: realizationPrice || '' }}
        validationSchema={addPriceSchema}
        onSubmit={handleSubmit}
        onReset={handleResetPrice}
        inputName='taskPrice'
        inputType='number'
        labelText='Стоимость реализации в $'
        buttonText='Добавить стоимость'
        resetButtonText='Обнулить стоимость'
        buttonType='submit'
      />
    </>
  );
};
