import './ShowLinkModal.scss';

import React from 'react';

import { TemplateState } from '../../../../types/enums';
import {
  addKarma,
  avatarUrlCreate,
  getUserAvatar,
  wrapContainer,
} from '../../../../utils';

type ShowLinkModalType = {
  isOpen: boolean;
  modalParameters: any;
  onClose: () => void;
};

export const ShowLinkModal = ({ modalParameters }: ShowLinkModalType) => {
  const message = modalParameters?.message;
  const avatarUrl = avatarUrlCreate({
    tread: modalParameters?.tread,
    userId: modalParameters?.tread?.author?.id as number,
    message: modalParameters?.message,
  });
  return (
    <div className='ShowLinkModal'>
      <div className='TreadItem__wrapper'>
        <div className='TreadItem__wrapper-inner'>
          {message?.viewType === TemplateState.left && message?.imagePath && (
            <img
              src={getUserAvatar(message?.imagePath)}
              alt='add'
              className='TreadItem__comment-image pr30'
            />
          )}
          <div className='TreadItem'>
            <div>
              <div className='TreadItem__header'>
                <div className='TreadItem__avatar'>
                  <img src={avatarUrl} alt='add' className='TreadItem__avatar-image' />
                  <div className='TreadItem__avatar-wrapper'>
                    <p className='TreadItem__avatar-name'> {addKarma(message?.user)}</p>
                    <p className='TreadItem__avatar-count'>
                      Сообщений: {message?.user?.forumMessageCount}
                    </p>
                  </div>
                </div>
                <div className='TreadItem__data'></div>
              </div>

              <div className='TreadItem__comment'>
                <div
                  className='ql-editor'
                  dangerouslySetInnerHTML={{ __html: wrapContainer(message?.text) }}
                  style={{
                    width:
                      message?.viewType === TemplateState.center ? '100%' : undefined,
                  }}
                ></div>
              </div>
            </div>

            <div className='TreadItem__ButtonWrapper-outer'>
              <div className='TreadItem__ButtonWrapper-inner'></div>
              <div className='TreadItem__ButtonWrapper-inner'></div>
            </div>
          </div>
          {message?.viewType === TemplateState.right && message?.imagePath && (
            <img
              src={getUserAvatar(message?.imagePath)}
              alt='add'
              className='TreadItem__comment-image pl30'
            />
          )}
        </div>
      </div>
    </div>
  );
};
