import './SendToTopIcon.scss';

import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const SendToTopIcon: FC<SendToTopIconType> = ({
  width = '20px',
  height = '20px',
  color = '#303030',
  className = 'send-to-top-icon',
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M5.81303 6.64645L3.99992 8.45956L3.54036 8L6.99992 4.54044L10.4595 8L9.99992 8.45956L8.18681 6.64645L7.33325 5.79289V7V15H6.66658V7V5.79289L5.81303 6.64645ZM0.833252 1.66667V1H13.1666V1.66667H0.833252Z'
        fill={color}
        stroke='white'
      />
    </svg>
  );
};

type SendToTopIconType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
>;
