import React from 'react';
import { Layer, Line, Rect, Transformer } from 'react-konva';

import { HoverRectIdType } from '../../types/enums';
import { multiselectToolsType, polygonToolsType, RectType } from '../../types/types';
import { Rectangle } from './rectangle/Rectangle';

type PolygonLayerType = {
  rectangles: Array<RectType>;
  setRectangles: (e: Array<RectType>) => void;
  selectedIdRect: string | number | null;
  x?: number;
  y?: number;
  polygonTools: polygonToolsType;
  selectionRectRef: any;
  trRef: any;
  layerRef: any;
  multiselectTools: multiselectToolsType;
  isEditText: boolean;
  h_lines: any;
  v_lines: any;
  lineTools: any;
  navigate: (e: string) => void;
  projectId: string | number;
  isAccess: boolean;
  polygonStatisticID?: string;
  hoverRectId: { id: string | number; type: HoverRectIdType };
  setHoverRectId: (value: { id: string | number; type: HoverRectIdType }) => void;
};

export const PolygonTreeLayer = ({
  rectangles,
  selectedIdRect,
  setRectangles,
  polygonTools,
  selectionRectRef,
  trRef,
  layerRef,
  multiselectTools,
  h_lines,
  v_lines,
  lineTools,
  isEditText,
  navigate,
  projectId,
  isAccess,
  polygonStatisticID,
  hoverRectId,
  setHoverRectId,
}: PolygonLayerType) => {
  return (
    <Layer
      ref={layerRef}
      onDragMove={(e) => lineTools.onDragMoveLine(e)}
      onDragEnd={(e) => lineTools.onDragEndLine(e)}
    >
      {rectangles?.map((rect: RectType, index: number) => {
        if (rect.isStatus === 'Delete') return null;
        return (
          <Rectangle
            key={rect.id}
            shapeProps={rect}
            isSelected={rect.id === selectedIdRect}
            onSelect={() => {
              if (!isAccess) return;
              polygonTools.select({ id: rect.id });
            }}
            onChange={(rect: RectType) => {
              if (!isAccess) return;
              polygonTools.change({ rect, index });
            }}
            rectangles={rectangles}
            setRectangles={setRectangles}
            rect={rect}
            polygonTools={polygonTools}
            multiselectTools={multiselectTools}
            isEditText={isEditText}
            navigate={navigate}
            projectId={projectId}
            isAccess={isAccess}
            polygonStatisticID={polygonStatisticID}
            hoverRectId={hoverRectId}
            setHoverRectId={setHoverRectId}
          />
        );
      })}
      <Transformer ref={trRef as any} resizeEnabled={false} rotateEnabled={false} />
      <Rect fill='rgba(0,0,255,0.5)' ref={selectionRectRef as any} />
      {h_lines.map((item: any, i: number) => {
        return <Line key={i} {...item} />;
      })}
      {v_lines.map((item: any, i: any) => {
        return <Line key={i} {...item} />;
      })}
    </Layer>
  );
};
