import { gql } from '@apollo/client';

export const GET_CURRENT_USER_HISTORY = gql`
  query getCurrentUserHistory($limit: Int, $tagsId: [ID!]) {
    getCurrentUser {
      id
      role
      IsExistUnViewedForum
      viewHistory(limit: $limit, tagsId: $tagsId) {
        id
        task {
          id
          number
          name
          code
          project {
            id
            name
            number
          }
          status {
            id
          }
          createdAt
          maintainer {
            id
            login
            image
          }
        }
        subtask {
          id
          number
          name
          code
          status {
            id
          }
          task {
            id
            project {
              id
              name
              number
            }
            number
          }
          createdAt
          maintainer {
            id
            login
            image
          }
        }
        viewedAt
      }
    }
  }
`;
