import './CellFirstCol.scss';

import React from 'react';

type CellFirstColType = {
  content?: any;
  success?: boolean;
  createScaleMapTaskMutation: (value: any) => void;
  selectFirstCol: number;
  setSelectFirstCol: (value: number) => void;
  updateScaleMapTaskMutation: (value: any) => void;
  deleteScaleMapTaskMutation: (value: any) => void;
  index: number;
  setFilterStatus: (value: number) => void;
  isLineSuccesses: boolean;
  setSelectTask: (value: any) => void;
  selectTask: number | null;
  hideSuccessInner: boolean;
  trigerLength: number;
};

export const CellFirstCol = ({
  content,
  selectFirstCol,
  setSelectFirstCol,
  trigerLength,
  setFilterStatus,
  isLineSuccesses,
}: CellFirstColType) => {
  const showState2 = content.length > 0;

  if (showState2) {
    return (
      <div
        className={`CellFirstColFile__state2 ${
          isLineSuccesses && 'CellFirstColFile__state2-black'
        }`}
        key={content?.id}
      >
        {content?.map((task: any, index: number) => {
          const general = content?.length > 1 && index === 0;

          const showTrigger = task?.name === 'Тригер';

          return (
            <div
              className={`${
                selectFirstCol === task?.id && 'CellFirstColFile__state2-select'
              } 
              CellFirstColFile__state2-element `}
              onClick={() => {
                setSelectFirstCol(task?.id);
                setFilterStatus(0);
              }}
              key={task?.id}
            >
              <div
                className={
                  general
                    ? 'CellFirstColFile__state2__gray'
                    : 'CellFirstColFile__state2__name'
                }
              >
                {task?.name}
              </div>
              {showTrigger && <div style={{ marginLeft: 'auto' }}>{trigerLength}</div>}

              <div className='CellFirstColFile__state2-icons'></div>
            </div>
          );
        })}
        <div
          onClick={() => {}}
          className='CellFirstColFile__state2'
          style={{
            justifyContent: 'center',
          }}
        ></div>
      </div>
    );
  }

  return <div className='CellFirstColFile__state1' onClick={() => {}}></div>;
};
