import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const PaletteIcon: FC<PaletteIconType> = ({
  color = '#303030',
  onClick,
  width = '20px',
  height = '20px',
  className = 'palette-icon',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M16.8012 13.5898C16.5434 13.3516 16.1645 13.293 15.8402 13.2383C15.391 13.1641 15.2191 13.082 14.9574 12.8477C14.3988 12.3516 14.3988 11.6328 14.9574 11.1367L16.141 10.0859C17.9535 8.48438 17.9535 5.85938 16.141 4.25781C14.8051 3.08203 13.0121 2.5 11.1488 2.5C8.97305 2.5 6.69962 3.29297 4.94571 4.84766C1.68399 7.73047 1.68399 12.4531 4.94571 15.3359C6.5668 16.7695 8.7543 17.4844 10.9184 17.5H10.9848C13.1488 17.5 15.2816 16.8008 16.7973 15.4531C17.3598 14.957 17.2656 14.0234 16.8012 13.5898V13.5898Z'
        stroke='#303030'
        strokeWidth='1.5'
        strokeMiterlimit='10'
      />
      <path
        d='M5.625 9.375C6.31536 9.375 6.875 8.81536 6.875 8.125C6.875 7.43464 6.31536 6.875 5.625 6.875C4.93464 6.875 4.375 7.43464 4.375 8.125C4.375 8.81536 4.93464 9.375 5.625 9.375Z'
        fill={color}
      />
      <path
        d='M5.9375 13.3984C6.62786 13.3984 7.1875 12.8388 7.1875 12.1484C7.1875 11.4581 6.62786 10.8984 5.9375 10.8984C5.24714 10.8984 4.6875 11.4581 4.6875 12.1484C4.6875 12.8388 5.24714 13.3984 5.9375 13.3984Z'
        fill={color}
      />
      <path
        d='M8.75 6.875C9.44036 6.875 10 6.31536 10 5.625C10 4.93464 9.44036 4.375 8.75 4.375C8.05964 4.375 7.5 4.93464 7.5 5.625C7.5 6.31536 8.05964 6.875 8.75 6.875Z'
        fill={color}
      />
      <path
        d='M10 16.2109C11.0355 16.2109 11.875 15.3715 11.875 14.3359C11.875 13.3004 11.0355 12.4609 10 12.4609C8.96447 12.4609 8.125 13.3004 8.125 14.3359C8.125 15.3715 8.96447 16.2109 10 16.2109Z'
        fill={color}
      />
      <path
        d='M12.8125 6.875C13.5029 6.875 14.0625 6.31536 14.0625 5.625C14.0625 4.93464 13.5029 4.375 12.8125 4.375C12.1221 4.375 11.5625 4.93464 11.5625 5.625C11.5625 6.31536 12.1221 6.875 12.8125 6.875Z'
        fill={color}
      />
    </svg>
  );
};

type PaletteIconType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
>;
