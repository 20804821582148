import './TemplateModal.scss';

import { Form, Formik, FormikValues } from 'formik';
import React, { FC } from 'react';

import { ModalType } from '../../../../types/types';
import { AppInput } from '../../../appInput/AppInput';
import { Button } from '../../../button/Button';

export const TemplateModal: FC<
  Pick<
    ModalType,
    | 'isOpen'
    | 'onSubmit'
    | 'initialValues'
    | 'onClose'
    | 'labelText'
    | 'buttonText'
    | 'inputName'
  >
> = ({ onSubmit, initialValues, labelText, inputName, buttonText }) => {
  const handleSubmit = (values: FormikValues) => {
    onSubmit(values);
  };

  return (
    <Formik initialValues={initialValues || { template: '' }} onSubmit={handleSubmit}>
      <Form>
        <div className='template-modal'>
          <div className='template-modal__title'>
            <h4>Сохранить шаблон страницы</h4>
            <AppInput
              name={inputName || 'template'}
              label={labelText}
              placeholder='Введите название шаблона'
            />
          </div>
          <div className='template-modal__buttons'>
            <Button type='submit' text={buttonText} width='100%' height='25px' />
          </div>
        </div>
      </Form>
    </Formik>
  );
};
