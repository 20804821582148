import { gql } from '@apollo/client';

export const GET_PROJECTS = gql`
  query getProjects($data: GetProjectsInput!, $tasksData: GetTasksInput!) {
    getProjects(data: $data) {
      rows {
        id
        name
        number
        createdAt
        updatedAt
        poligon {
          id
        }
        author {
          id
          image
          login
          karmaStatistics {
            karma
          }
        }
        tasks(data: $tasksData) {
          count
          rows {
            id
            number
            name
            code
            startDate
            closeDate
            endDate
            status {
              id
              color
            }
            project {
              id
              number
            }
            author {
              id
              image
              login
              role
            }
            assignees {
              id
              image
              login
              role
            }
            maintainer {
              id
              login
              image
              karmaStatistics {
                karma
              }
            }
            tags {
              count
              rows {
                id
                name
                color
                textColor
                createdAt
                updatedAt
              }
            }
            realizationPerMonthAmount
            realizationPrice
            poligonObject {
              id
            }
          }
        }
      }
      count
    }
  }
`;
