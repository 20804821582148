import React from 'react';

import { CalendarIcons, ClockIcons2 } from '../../../icons';

type dataLogType =
  | {
      convertedDateFrom: string;
      convertedDateTo: string;
      formattedTimeFrom: string;
      formattedTimeTo: string;
      fileName: string;
      taskName: string;
    }[]
  | undefined;

type TableReportType = {
  dataLogs: dataLogType;
};

export const TableReport = ({ dataLogs }: TableReportType) => {
  return (
    <div className='table__modal'>
      {dataLogs?.map((e, index) => {
        return (
          <div key={index} className='table__col'>
            <div className='table__row'>
              {e?.fileName}
              {e?.taskName ? ` / ${e?.taskName}` : ''}
            </div>
            <div className='table__row'>
              <CalendarIcons className={'ml5 mr5'} />
              {e?.convertedDateFrom}/{e?.formattedTimeFrom}
              <ClockIcons2 className={'ml5 mr5'} />
            </div>
            <div className='table__row'>
              <CalendarIcons className={'ml5 mr5'} /> {e?.convertedDateTo} /
              <ClockIcons2 className={'ml5 mr5'} /> {e?.formattedTimeTo}
            </div>
          </div>
        );
      })}
    </div>
  );
};
