import './ForumTreadItem.scss';
import 'quill/dist/quill.snow.css';

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuthContext } from '../../../context';
import {
  ForumMessage,
  ForumThread,
  useCreateForumMessageMutation,
  useGetForumMessagesQuery,
  useGetForumThreadQuery,
  useRemoveForumMessageMutation,
  useUpdateForumMessageMutation,
} from '../../../graphql/generated/graphql';
import { ArrowRightIcon } from '../../icons';
import { ForumCreateMessage, Pagination, RequestHandler, TreadItem } from '../../index';

const tasksQueryLimit = 10;

type ForumTreadItemType = {
  isAccessEdit: boolean;
  sectionRole?: any;
};

export const ForumTreadItem = ({ isAccessEdit, sectionRole }: ForumTreadItemType) => {
  const { treadID, pageNumber, sectionId } = useParams();
  const [currentPage, setCurrentPage] = useState(
    pageNumber ? parseInt(pageNumber as string) : 1,
  );
  const { currentUserData } = useAuthContext();

  const navigate = useNavigate();

  const { data: tread } = useGetForumThreadQuery({
    variables: {
      id: treadID as any,
    },
  });
  const [fixFirstRender, setFixFirstRender] = useState(true);

  useEffect(() => {
    if (currentPage === 1 && fixFirstRender) {
      setFixFirstRender(false);
      return;
    }
    navigate(`/forum/section/${sectionId}/tread/${treadID}/page/${currentPage}`);
  }, [currentPage]);

  const { data, refetch } = useGetForumMessagesQuery({
    variables: {
      data: {
        forumThreadId: parseInt(treadID as string),
        offset: (parseInt(pageNumber as string) - 1) * tasksQueryLimit,
        limit: tasksQueryLimit,
      },
    },
    fetchPolicy: 'network-only',
  });
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (data?.getForumMessages?.count) {
      setTotalCount(data?.getForumMessages?.count as number);
    }
  }, [data]);

  const [updateForumMessageMutation] = useUpdateForumMessageMutation({
    onCompleted: () => {
      refetch();
    },
    onError: (error) => {
      if (error.message === 'FILE_NOT_ALLOWED') {
        toast.error('Файл не изображение');
      }
    },
  });

  const [createForumMessageMutation, { loading: loadingCreate }] =
    useCreateForumMessageMutation({
      onCompleted: () => {
        refetch();
      },
      onError: (error) => {
        if (error.message === 'FILE_NOT_ALLOWED') {
          toast.error('Файл не изображение');
        }
      },
    });

  const [removeForumMessageMutation] = useRemoveForumMessageMutation({
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <div className='ForumTreadItem'>
      <div
        className='ForumTreadItem__arrow-wrapper'
        onClick={() => {
          navigate(`/forum/${sectionId}`);
        }}
      >
        <ArrowRightIcon className='ForumTreadItem__arrow' />
        {tread?.getForumThread.name}
      </div>
      <Pagination
        paginate={setCurrentPage}
        currentPage={currentPage}
        totalCount={totalCount}
        pageSize={tasksQueryLimit}
        siblingCount={1}
        hideArrow
      />

      <div className='ForumTreadItem__list'>
        {data?.getForumMessages?.rows?.map((message, index) => (
          <TreadItem
            key={message.id}
            message={message as ForumMessage}
            updateForumMessageMutation={updateForumMessageMutation}
            removeForumMessageMutation={removeForumMessageMutation}
            createForumMessageMutation={createForumMessageMutation}
            isAccessEdit={isAccessEdit}
            sectionRole={sectionRole}
            currentUserData={currentUserData}
            treadClose={tread?.getForumThread?.isClosed}
            nextTreadAuthorId={
              index !== 0 && data?.getForumMessages?.rows[index - 1]?.user?.id
            }
            tread={tread?.getForumThread as ForumThread}
          />
        ))}
        {data?.getForumMessages?.count === 0 && (
          <div className='mb10'>В этой теме еще не создано ни одно сообщение</div>
        )}
        {!tread?.getForumThread?.isClosed && (
          <RequestHandler loading={loadingCreate}>
            <span className='listEditor__wrapper'>
              <ForumCreateMessage
                onCallback={(value: any) => {
                  createForumMessageMutation({
                    variables: {
                      data: {
                        forumThreadId: parseInt(treadID as string),
                        text: value?.description,
                        image: value?.image,
                        viewType: value?.viewType,
                      },
                    },
                  });
                }}
                title={'Создать сообщение'}
                buttonText={'Создать'}
              />
            </span>
          </RequestHandler>
        )}
        {tread?.getForumThread?.isClosed && <div>Тема закрыта</div>}
      </div>
    </div>
  );
};
