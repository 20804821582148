import './AllPolygon.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PolygonList } from '../../components';
import { ROLES, tasksQueryLimit } from '../../constants/constants';
import { useAuthContext } from '../../context';
import {
  Poligon as PoligonType,
  useGetPoligonsQuery,
} from '../../graphql/generated/graphql';

export const AllPolygon = () => {
  const [searchValue, setSearchValue] = useState<string>('');

  const [currentPage, setCurrentPage] = useState(1);

  const { data, loading, error } = useGetPoligonsQuery({
    variables: {
      data: {
        search: searchValue,
        offset: (currentPage - 1) * tasksQueryLimit,
        limit: tasksQueryLimit,
      },
    },
    fetchPolicy: 'network-only',
  });
  const { currentUserData } = useAuthContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (currentUserData?.role !== ROLES.ADMIN) {
      navigate('/');
    }
  }, []);

  const handlePagination = (value: number) => {
    setCurrentPage && setCurrentPage(value);
  };

  const searchPolygon = (value: string) => {
    setSearchValue(value);
  };

  return (
    <div className='container'>
      <div className='AllPolygon'>
        <div className='AllPolygon__body'>
          {error ? (
            <p>Что то пошло не так...</p>
          ) : (
            <PolygonList
              poligons={data?.getPoligons.rows as Array<PoligonType>}
              loading={loading}
              searchPolygon={searchPolygon}
              currentPage={currentPage}
              handlePagination={handlePagination}
              count={data?.getPoligons?.count as number}
            />
          )}
        </div>
      </div>
    </div>
  );
};
