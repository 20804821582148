import React from 'react';

export const ChronoIcons = () => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15 2.5C21.9037 2.5 27.5 8.09625 27.5 15C27.5 21.9037 21.9037 27.5 15 27.5C8.09625 27.5 2.5 21.9037 2.5 15C2.5 8.09625 8.09625 2.5 15 2.5ZM15 4.24375C12.1473 4.24375 9.41137 5.37699 7.39418 7.39418C5.37699 9.41137 4.24375 12.1473 4.24375 15C4.24375 17.8527 5.37699 20.5886 7.39418 22.6058C9.41137 24.623 12.1473 25.7562 15 25.7562C17.8527 25.7562 20.5886 24.623 22.6058 22.6058C24.623 20.5886 25.7562 17.8527 25.7562 15C25.7562 12.1473 24.623 9.41137 22.6058 7.39418C20.5886 5.37699 17.8527 4.24375 15 4.24375ZM13.8375 9.47625C14.3187 9.47625 14.7087 9.8675 14.7087 10.3487V16.25L20.552 14.9767C20.7775 14.9853 20.9909 15.0808 21.1475 15.2434C21.304 15.406 21.3915 15.6229 21.3915 15.8486C21.3915 16.0743 21.304 16.2912 21.1475 16.4538C20.9909 16.6163 20.7775 16.7119 20.552 16.7205L13.8375 18.1975C13.6061 18.1975 13.3842 18.1056 13.2205 17.942C13.0569 17.7783 12.965 17.5564 12.965 17.325V10.35C12.965 9.8675 13.355 9.47625 13.8375 9.47625Z'
        fill='white'
      />
    </svg>
  );
};
