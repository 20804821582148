/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/no-unescaped-entities */
import './Privacy.scss';

import React, { useEffect } from 'react';

export const Privacy = () => {
  useEffect(() => {
    document.title = 'Privacy Policy';
  }, []);
  return (
    <div className='privacy'>
      <h2>Privacy Policy</h2>

      <p>
        This document "Privacy Policy" (hereinafter referred to as "Policy") is the terms
        and conditions for collection and use of the Personal information of the User
        (hereinafter referred to also as "You") by Barduck Limited Liability Company
        (hereinafter referred to as "Barduck") during download, installation and use of
        the mobile application "One Life Story" (hereinafter referred to as "Mobile
        application")
      </p>

      <p>
        <strong>USER'S CONSENT:</strong>
      </p>
      <p>
        By checking the box "I agree" accompanied with the link to this Policy you
        (hereinafter also referred to as "User") with your own free will and for your
        benefit give your written consent to the processing of User's Personal Information
        for the purposes and under the terms and conditions stipulated by this Policy.
      </p>

      <h3>1. General Provisions</h3>
      <p>
        1.1. This Policy is an integral part of the End User Sublicense Agreement for the
        Mobile application (hereinafter referred to as the "EULA"). Therefore, when
        executing the above said EULA by methods defined therein including by downloading,
        installation and using the Mobile application you fully accept the terms of this
        Policy.
      </p>
      <p>
        1.2. Methods of processing of Personal Information include any action (operation)
        or a combination of actions (operations) with Personal Information including
        collection, recording, systematization, storage, updating (refreshment,
        modification), extraction, use, transfer (distribution, provision, access),
        depersonalization, blocking, deletion, destruction in the purposes established by
        this Policy with/without the use of automated facilities.
      </p>
      <p>
        1.3. The terms and definitions provided for by the EULA are used herein, as well
        as other agreements entered into with the User, unless otherwise provided for by
        this Policy or follows from the merits thereof. In other cases, the terms used in
        the Policy shall be interpreted in accordance with the applicable laws, the
        business practice, or scientific doctrine.
      </p>

      <h3>2. Personal Information</h3>
      <p>
        2.1. When using Mobile application the User does not actively provide any
        information about him/her.
      </p>
      <p>2.2. Personal information in this Policy means as follows:</p>
      <p>
        2.2.1. Data transmitted in a depersonalized form in automatic mode during use of
        Mobile application, depending on the settings of the User's software including
        operating system of User's device.
      </p>
      <p>
        2.3. Barduck collects and stores the following impersonal data about the User:
        location of User's devise (country and city) and unique identifier of User's
        device (UID). Barduck does not collect and store the data that enables
        identification of User (personal data).
      </p>
      <p>
        2.4. The User understands, accepts and agrees with use of third party software in
        the Mobile application by Barduck; as a result, such third parties can
        automatically receive and transmit the impersonal data about the User.
      </p>
      <p>The aforementioned third party software includes:</p>
      <p>a) analytic systems:</p>

      <ul>
        <li>
          Unity Analytics that collects and processes data according to privacy policy
          available at:{' '}
          <a href='https://unity3d.com/legal/privacy-policy'>
            https://unity3d.com/legal/privacy-policy
          </a>
          ;
        </li>
        <li>
          PlayFab that is software product of Microsoft Corporation and that collects and
          processes data according to privacy statement available at:{' '}
          <a href='https://privacy.microsoft.com/privacystatement' target='_blank'>
            https://privacy.microsoft.com/privacystatement
          </a>
        </li>
        <li>
          Facebook Analytics, that is software product of Facebook and that collects and
          processes data according to data use policy available at:{' '}
          <a
            href='https://web.facebook.com/full_data_use_policy?_rdc=1&amp;_rdr'
            target='_blank'
          >
            https://web.facebook.com/full_data_use_policy?_rdc=1&amp;_rdr
          </a>
        </li>
      </ul>

      <p>Such data collected with third party software may include:</p>

      <ul>
        <li>
          User's device data, including unique identifiers of User's device, its location
          (country and city), operating system data (type, version, screen resolution) and
          so on
        </li>
        <li>query data (time, source, IP address)</li>
        <li>other information about User's activity in the Mobile application</li>
      </ul>

      <p>
        The scope and conditions for collection and use of impersonal data by such
        software's titleholders are determined directly by its titleholders and governed
        by documents available at their websites.
      </p>
      <p>
        Accepting this Policy as well applying certain setting in his/her software
        including device's operating system, the User agrees with conditions for
        collection and use of data by the titleholders of above listed software.
      </p>

      <h3>3. Legal basis of processing of Personal information</h3>
      <p>
        The legal basis on which Barduck relies for the use of Personal information
        includes:
      </p>
      <p>
        3.1. Processing of Personal information where User has given consent for one or
        more specific purposes.
      </p>
      <p>
        3.2. Processing of Personal information that is necessary for performing a
        contract to which User are party.
      </p>

      <h3>4. Policy with regard to children</h3>
      <p>
        4.1. Barduck does not intentionally collect personal data from children younger
        than 16, it does not request it and does not allow them to use the Mobile
        application. If Barduck gets to know that the information was collected from a
        child under 16, Barduck shall immediately delete such information. If you think we
        could have obtained any information from a child or about a child under 16,
        please, contact us through e-mail:{' '}
        <a href='mailto:support@sacralium.games'>support@sacralium.games</a>.
      </p>

      <h3>5. Purposes for Personal Information Processing</h3>
      <p>
        5.1. Barduck is entitled to use the Personal Information for the following
        purposes:
      </p>
      <p>
        5.1.1. Anonymized identification of the User within the fulfillment of the EULA.
        The User is identified anonymously by unique identifier of a device.
      </p>
      <p>
        5.1.2. Fulfillment of obligations under EULA, including providing the User with an
        opportunity to use the Mobile application.
      </p>
      <p>
        5.1.3. Marketing, statistical and other research based on depersonalized data from
        the analytics systems stipulated in the paragraph 2.4 hereof.
      </p>
      <p>
        5.1.4. Targeting of advertising and/or informational materials based on
        depersonalized data from the analytics systems stipulated in the paragraph 2.4
        hereof.
      </p>

      <h3>6. User's Rights to User's Personal Information</h3>
      <p>
        6.1. Subject to specific provisions of applicable law, the User has the right:
      </p>
      <p>6.1.1. to be informed about the collection and use of his/her personal data.</p>
      <p>
        6.1.2. to access his/her personal data and have it corrected if it is wrong or
        incomplete.
      </p>
      <p>
        6.1.3. to restrict the processing of his/her personal data where the accuracy of
        the personal data is contested, where the processing is unlawful, and where
        Barduck no longer needs the personal data for the purposes for which Barduck has
        processed it.
      </p>
      <p>
        6.1.4. to object to the processing of his/her personal data and also restrict its
        processing in those instances where Barduck undertook processing of it in carrying
        out a task in the public interest or where necessary for our legitimate interest
        where there is no compelling reason for its continued processing.
      </p>
      <p>
        6.1.5. to withdraw consent that User had given to the collection and processing of
        his/her personal data at any time. If User does withdraw his/her consent to the
        collection and processing of personal data, that withdrawal shall not affect the
        lawfulness of any processing by Barduck that is based on the consent User had
        given before he/she withdrew it.
      </p>
      <p>
        6.1.6. to obtain the deletion of his/her personal data when it is no longer
        relevant to the purposes for which it was collected or processed, when User has
        withdrawn consent and there is no basis for us to continue processing, when User
        has objected to further processing and there is no compelling interest for
        continued processing, and when the data was unlawfully processed.
      </p>
      <p>
        6.1.7. not to be a subject to a decision based solely on automated processing,
        including profiling, which produces legal affects concerning User or similarly
        affecting User, except where there is a lawful basis for continued processing.
      </p>
      <p>
        6.1.8. to receive personal data in a structured, commonly used, and machine
        readable form for the purpose of data portability in those instances where Barduck
        processes by automated means personal data that User has provided.
      </p>

      <p>
        6.2. These rights may be exercised by contacting Barduck with specific request
        through the methods described below. Barduck will address any such requests in a
        timely manner and according to applicable law. In certain cases, Barduck may ask
        User to verify identity before acting on User's request. If User is unsatisfied
        with Barduck's response, User may refer his/her complaint to the relevant
        supervisory authority in User's jurisdiction.
      </p>

      <h3>7. Personal Information Storage Retention</h3>
      <p>
        7.1. All Personal Information that is collected will be stored only as long as is
        necessary to accomplish the purpose for which it is collected, or as long as is
        permitted or required by applicable law. Periodically, Barduck will review its
        data processing systems to determine whether or not the purposes for the
        collection and processing of User's personal data remain valid. That determination
        will be based on factors that include, but are not limited to, whether or not you
        are still in contact with Barduck, whether or not any requests made by User from
        Barduck have been fulfilled (including any follow-up tasks), whether or not there
        is a contractual relationship between User and Barduck, and whether or not there
        is a contractual or legal basis for continuing to retain User's personal data.
        Based on that review and any notifications received from data subjects, Barduck
        will update its systems.
      </p>

      <h3>8. Requirements for the Personal Information Protection</h3>
      <p>
        8.1. Barduck stores the Personal Information and provides its protection against
        unauthorized access and distribution in accordance with internal terms and
        regulations.
      </p>
      <p>
        8.2. With regard to the User's Personal Information, its confidentiality is
        protected.
      </p>

      <h3>9. Transfer of the Personal Information</h3>
      <p>
        9.1. Barduck is entitled to transfer Personal Information to third parties in the
        following cases:
      </p>
      <p>
        9.1.1. The User expressed his/her consent to such actions, including when the User
        applies the settings of the used software that do not limit the provision of
        certain information.
      </p>
      <p>
        9.1.2. The transfer is necessary in the context of the User's use of the Mobile
        application's functionality.
      </p>
      <p>
        9.1.3. In connection with use in Mobile application of third party software for
        collection and processing of User's data. Specifically, Barduck may use the third
        party software for collection and processing of User's data according to clause
        2.4. of this Policy.
      </p>
      <p>
        9.1.4. Due to whole or partial transfer of Barduck's assets to the third party,
        including assignment of rights under agreements concluded with the User in benefit
        of such third party.
      </p>
      <p>
        9.1.5. Upon request of a court or other state authority within the procedure
        established by law.
      </p>
      <p>
        9.1.6. To protect the rights and legitimate interests of Barduck in connection
        with failure to comply with the agreements entered into with the User.
      </p>

      <h3>10. Amendments to and deletion of the Personal Information</h3>
      <p>
        10.1. Any questions related to this Policy, use, amendment, deletion of the User's
        and/or exercising of any User's rights with respect to the Personal Information
        can be mailed to Barduck at the address:{' '}
        <a href='mailto:support@sacralium.games'>support@sacralium.games</a>
      </p>

      <h3>11. Amendments to the Privacy Policy</h3>
      <p>
        11.1. This Policy may be amended or terminated unilaterally by Sacralium without
        prior notice to the User. The User is recommended to read this Policy in the
        current version regularly.
      </p>
      <p>
        11.2. The new version of the Policy becomes effective upon its placement, unless
        otherwise provided for by the new version of the Policy.
      </p>
      <p>
        11.3. The current version of the Policy is available at:{' '}
        <a href='https://barduck.online/privacy' target='_blank' rel='noopener'>
          https://barduck.online/privacy
        </a>
        .
      </p>

      <p>"Sacralium" LLC</p>
      <p>Registration number: 1187154024584</p>
      <p>Address: 2 Ul'yanova, lit. A, 2nd floor, office 1, Tula, Russia.</p>
      <p>
        E-mail: <a href='mailto:support@sacralium.games'>support@sacralium.games</a>
      </p>
      <p>The current version of the Policy of 15.04.2019.</p>
      <p>
        The Russian Federation is outside the European Economic Area. An adequacy decision
        by the Commission with respect to the Russian Federation is absent.
      </p>
      <p>
        This Privacy Policy is designed by the law firm{' '}
        <a href='https://it-lex.ru' target='_blank' rel='noopener'>
          IT Lex
        </a>
      </p>
    </div>
  );
};
