import { useState } from 'react';

import { useGetExpensesQuery } from './useQueries';

export const useExpenses = () => {
  const [date, setDate] = useState(new Date());

  const { data, loading } = useGetExpensesQuery(date);

  const changeMonth = (date: Date) => {
    setDate(date);
  };

  return { data, loading, changeMonth, date };
};
