import './ChronoWeekReport.scss';

import React, { useState } from 'react';

import { Button } from '../../../button/Button';

export const ChronoWeekReport = ({ onClose, modalParameters }: any) => {
  const [text, setText] = useState('');

  return (
    <div className='ChronoWeekReport'>
      <div className='ChronoWeekReport__title'>Отчет за неделю</div>
      <textarea
        className='ChronoWeekReport__textarea'
        placeholder='Сообщение'
        onChange={(e) => {
          setText(e.target.value);
        }}
        value={text}
      ></textarea>
      <Button
        width={'100%'}
        text='Разблокировать'
        backgroundColor='#149939'
        color='#FFFFFF'
        margin={'40px 0px 0px'}
        disabled={text?.length <= 0}
        onClick={() => {
          modalParameters.onCallback({ text: text });
          onClose();
        }}
      />
      <Button
        width={'100%'}
        text='Отмена'
        margin={'20px 0px 0px'}
        onClick={() => {
          onClose();
        }}
      />
    </div>
  );
};
