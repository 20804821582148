import './ActionTreadsModal.scss';

import React from 'react';

import { TreadDeleteIcons } from '../../../icons/index';
import { Button } from '../../../index';

type UpdateFileScaleModalType = {
  isOpen: boolean;
  onClose: () => void;
  modalParameters?: any;
};

export const ActionTreadsModal = ({
  onClose,
  modalParameters,
}: UpdateFileScaleModalType) => {
  return (
    <div className='ActionTreadsModal'>
      <div style={{ width: 'auto' }}>
        <div className='ActionTreadsModal__title'>{modalParameters?.textData?.title}</div>
        <Button
          text={modalParameters?.textData?.buttonText}
          type='submit'
          width='100%'
          height={25}
          onClick={() => {
            modalParameters.onCallback();
            onClose();
          }}
        >
          {modalParameters?.textData?.iconsDelete && (
            <TreadDeleteIcons className='ActionTreadsModal__icons' />
          )}
        </Button>
      </div>
    </div>
  );
};
