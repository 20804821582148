import './AccordionItem.scss';

import React, { ReactNode, useState } from 'react';

import { arrowUp } from '../../assets/icons';

type AccordionItemPropsType = {
  children?: ReactNode;
  title: string;
};

export const AccordionItem = ({ children, title }: AccordionItemPropsType) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className='accordionItem' style={{ marginBottom: !isOpen ? '1px' : '' }}>
      <div className='accordionItem__header' onClick={() => setIsOpen(!isOpen)}>
        <div className={`accordionItem__icon ${isOpen ? 'open' : ''}`}>
          <img src={arrowUp} width='12px' height='8px' />
        </div>
        <h3 className='accordionItem__title'>{title}</h3>
      </div>
      {isOpen && children}
    </div>
  );
};

export const AccordionItemStorage = ({
  children,
  title,
  pagination,
}: AccordionItemPropsType & { pagination?: any }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className='accordionItem' style={{ marginBottom: !isOpen ? '1px' : '' }}>
      <div
        className='accordionItem__header'
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        style={{ justifyContent: 'space-between' }}
      >
        <div className='accordionItem__header-inner'>
          <div className={`accordionItem__icon ${isOpen ? 'open' : ''}`}>
            <img src={arrowUp} width='12px' height='8px' />
          </div>
          <h3 className='accordionItem__title'>{title}</h3>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {pagination && pagination}
        </div>
      </div>
      {isOpen && children}
    </div>
  );
};
