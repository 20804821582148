import './AddPolygonButton.scss';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useModalContext } from '../../context';
import { useUpsertPoligonMutation } from '../../graphql/generated/graphql';
import { ModalStatuses } from '../../types/enums';

export const AddPolygonButton = () => {
  const navigate = useNavigate();
  const { handleSetModalStatus, handleChangeModalParameters, handleCloseModal } =
    useModalContext();
  const [upsertPoligonMutation] = useUpsertPoligonMutation({});

  return (
    <button
      className='addPolygon__btn'
      onClick={() => {
        handleSetModalStatus(ModalStatuses.ADD_NEW_POLYGON_OBJECT);
        handleChangeModalParameters([
          {
            key: 'onCallback',
            value: (values: { name: string }) => {
              handleCloseModal();
              upsertPoligonMutation({
                variables: {
                  data: {
                    name: values.name ? values.name : 'Без имени',
                    canvasData: {
                      arrows: [],
                      rectangles: [],
                    },
                  },
                },
              }).then((response) => {
                const id = response?.data?.upsertPoligon.id;
                navigate(`/polygon-single/${id}`);
              });
            },
          },
        ]);
      }}
    >
      <span className='addPolygon__btn-text'>Создать полигон</span>
      <svg
        className='addPolygon__btn-icon'
        width='12'
        height='12'
        viewBox='0 0 12 12'
        xmlns='http://www.w3.org/2000/svg'
        fill='#ffffff'
      >
        <path d='M11.4665 5.46361H6.54226V0.539326C6.54226 0.243283 6.30191 0 6.00293 0C5.70396 0 5.46361 0.243283 5.46361 0.539326V5.46067H0.539326C0.395701 5.46067 0.257938 5.51637 0.15828 5.61895C0.0556913 5.72154 0 5.85638 0 6C0 6.29897 0.243283 6.53933 0.539326 6.53933H5.46067V11.4607C5.46067 11.7596 5.70396 12 6 12C6.29897 12 6.53933 11.7567 6.53933 11.4607V6.54226H11.4607C11.7596 6.54226 12 6.29897 12 6.00293C12.0059 5.70396 11.7626 5.46361 11.4665 5.46361Z' />
      </svg>
    </button>
  );
};
