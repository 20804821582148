import './TransformTextIcon.scss';

import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const TransformTextIcon: FC<TransformTextIconType> = ({
  width = '20px',
  height = '20px',
  color = '#303030',
  className = 'transform-text-icon',
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M4.25625 8.125H3.91852L3.79241 8.43831L2.91227 10.625H2.74661L6.70985 1H7.29015L11.2534 10.625H11.0846L10.181 8.43433L10.0534 8.125H9.71875H4.25625ZM4.30629 7.18493L4.02267 7.875H4.76875H9.2125H9.95611L9.67555 7.18635L7.5443 1.9551L7.41745 1.64375H7.08125H6.91875H6.58366L6.45628 1.95368L4.30629 7.18493ZM1.25 13.5H12.75V15H1.25V13.5Z'
        fill={color}
        stroke='white'
      />
    </svg>
  );
};

type TransformTextIconType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
> & { isBordered?: boolean };
