import './ForumCreateTread.scss';

import { Form, Formik } from 'formik';
import React, { useState } from 'react';

import { ROLES } from '../../../constants/constants';
import { useAuthContext } from '../../../context';
import { addTreadSchema } from '../../../validation/schemas';
import {
  CloseTreadWhiteIcon,
  ForumImgIcons,
  OpenTreadWhiteIcons,
  PlusIcons,
  RemoveIcon,
  SaveIconWhite,
} from '../../icons';
import { AppInput, AvatarLoad, Button, Editor } from '../../index';

type ForumCreateTreadType = {
  initialValues?: any;
  onCallback: (value: any) => void;
  onClose?: () => void;
  title?: string;
  buttonText?: string;
};

export const ForumCreateTread = ({
  initialValues,
  onCallback,
  onClose,
  title = 'Создать тему',
  buttonText = 'Cоздать',
}: ForumCreateTreadType) => {
  const { currentUserData } = useAuthContext();

  const [showAvatar, setShowAvatar] = useState(!!initialValues?.avatar);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(e) => {
        onCallback(e);
        onClose && onClose();
      }}
      validationSchema={addTreadSchema}
    >
      {({ setFieldValue, values, errors }) => {
        return (
          <Form style={{ width: '100%' }}>
            <div className='ForumCreateTread'>
              <div className='ForumCreateTread__head'>
                <div className='ForumCreateTread__head-title'>{title}</div>
                <div className='ForumCreateTread__imgWrapper'>
                  <ForumImgIcons
                    className={'mr10'}
                    onClick={() => {
                      setShowAvatar(!showAvatar);
                    }}
                  />
                  {title === 'Редактировать тему' &&
                    currentUserData?.role === ROLES.ADMIN && (
                      <Button
                        onClick={() => {
                          setFieldValue('isClosed', !values?.isClosed);
                        }}
                        backgroundColor={values?.isClosed ? '#303030' : '#EE3944'}
                        color={'white'}
                      >
                        {values?.isClosed ? (
                          <OpenTreadWhiteIcons className='mr10' />
                        ) : (
                          <CloseTreadWhiteIcon className='mr10' />
                        )}
                        {values?.isClosed ? 'Открыть' : 'Закрыть'}
                      </Button>
                    )}
                </div>
              </div>
              <div className='ForumCreateTread__quote'>
                {values?.message || values?.link ? (
                  <RemoveIcon
                    color={'black'}
                    className='ForumCreateTread__remove'
                    onClick={() => {
                      setFieldValue('link', undefined);
                      setFieldValue('message', undefined);
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>

              <div className='ForumCreateTread__template'>
                {showAvatar && (
                  <AvatarLoad
                    setFieldValue={setFieldValue}
                    avatar={values?.avatar}
                    errors={errors}
                  />
                )}
                <div className='ForumCreateTread__template-inner'>
                  <AppInput
                    name={'name'}
                    label={'Название:'}
                    error={errors?.name as string}
                  />
                  <div className={errors?.description && `ForumCreateTread__errors`}>
                    <Editor
                      setValue={(e) => {
                        setFieldValue('description', e);
                      }}
                      initialValues={initialValues?.description}
                      setFocused={() => {}}
                    />
                  </div>
                </div>
              </div>
              <div className='ForumCreateTread__buttons'>
                <Button
                  onClick={() => {
                    onClose && onClose();
                  }}
                >
                  Отмена
                </Button>
                <Button type='submit'>
                  {buttonText === 'Сохранить' ? (
                    <SaveIconWhite className='mr10' />
                  ) : (
                    <PlusIcons width='10px' height='10px' className='mr10' />
                  )}
                  {buttonText}
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
