import './PolygonListItem.scss';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Poligon as PoligonType } from '../../../graphql/generated/graphql';
import { dateParse } from '../../../utils';

export type usersListItemProps = {
  poligon: PoligonType;
};

export const PolygonListItem = ({ poligon }: usersListItemProps) => {
  const navigate = useNavigate();

  return (
    <div
      className='polygonItem'
      onClick={() => {
        navigate(
          poligon?.project?.id
            ? `/polygonStatistic/${poligon?.project?.id}`
            : `/polygon-single/${poligon?.id}`,
        );
      }}
    >
      <div className='polygonItem__col'>
        <p className='polygonItem__content'>{poligon.name}</p>
      </div>
      <div className='polygonItem__col'>
        <p className='polygonItem__content'>{dateParse(poligon.createdAt).date}</p>
      </div>
      <div className='polygonItem__col'>
        <p className='polygonItem__content'>
          {poligon?.project?.name ? poligon?.project?.name : 'Нет проекта'}
        </p>
      </div>
    </div>
  );
};
