import './UserGroupModal.scss';

import React, { useState } from 'react';

import {
  useCreateUserGroupMutation,
  useRemoveUserGroupMutation,
  useUpdateUserGroupMutation,
  useUserGroupsQuery,
} from '../../../../graphql/generated/graphql';
import { addKarma, getUserAvatar } from '../../../../utils';
import { Button } from '../../../button/Button';
import { CrossIcon, EditScaleIcon } from '../../../icons';
import { NoFormikInput } from '../../../noFormikInput/NoFormikInput';
import { Performers } from '../../../treads/TredsModal/Performers';

type OpenState = {
  state: boolean;
  name: string;
  id: number | undefined;
  value: any[] | undefined;
};

export const UserGroupModal = () => {
  const { data: userGroup, refetch } = useUserGroupsQuery();

  const [createUserGroupMutation] = useCreateUserGroupMutation({
    onCompleted: () => {
      refetch();
    },
  });
  const [updateUserGroupMutation] = useUpdateUserGroupMutation({
    onCompleted: () => {
      refetch();
    },
  });
  const [removeUserGroupMutation] = useRemoveUserGroupMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const [open, setOpen] = useState<OpenState>({
    state: false,
    name: '',
    id: undefined,
    value: [],
  });
  return (
    <div className='UserGroupModal'>
      {open?.state ? (
        <ChangeGroup
          createUserGroupMutation={createUserGroupMutation}
          updateUserGroupMutation={updateUserGroupMutation}
          setOpen={setOpen}
          open={open}
        />
      ) : (
        <div>
          <div className='UserGroupModal__groups'>
            {userGroup?.userGroups?.map((groups) => {
              return (
                <div key={groups.id} className='UserGroupModal__group'>
                  <div className='UserGroupModal__group-name'>
                    <div>{groups.name}</div>
                    <div className='UserGroupModal__group-icons'>
                      <CrossIcon
                        onClick={() => {
                          removeUserGroupMutation({
                            variables: {
                              id: groups.id,
                            },
                          });
                        }}
                      />
                      <EditScaleIcon
                        onClick={() => {
                          setOpen({
                            state: true,
                            name: groups.name as string,
                            value: groups?.users as any,
                            id: groups.id,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className='UserGroupModal__group-users'>
                    {groups?.users?.map((user) => {
                      return (
                        <div key={user.id} className='UserGroupModal__user'>
                          <img
                            src={getUserAvatar(user.image)}
                            className='UserGroupModal__user-avatar'
                          />
                          <div>{addKarma(user as any)}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <Button
            onClick={() => {
              setOpen({
                state: true,
                name: '',
                value: [],
                id: undefined,
              });
            }}
            width={'100%'}
          >
            Создать группу
          </Button>
        </div>
      )}
    </div>
  );
};

const ChangeGroup = ({
  setOpen,
  createUserGroupMutation,
  updateUserGroupMutation,
  open,
}: {
  setOpen: (value: OpenState) => void;
  createUserGroupMutation: any;
  updateUserGroupMutation: any;
  open: OpenState;
}) => {
  const [value, setValue] = useState<any>(open?.value ? open?.value : []);
  const [name, setName] = useState(open?.name ? open?.name : '');

  return (
    <div>
      <NoFormikInput value={name} setValue={setName} placeholder='Название группы' />
      <Performers
        assignees1={value}
        setIsOpen={() => {
          if (value.length > 0) {
            if (!open?.id) {
              createUserGroupMutation({
                variables: {
                  createUserGroupInput: {
                    name: name,
                    userIds: value?.map((e: any) => e.id),
                  },
                },
              });
            } else {
              updateUserGroupMutation({
                variables: {
                  updateUserGroupInput: {
                    name: name,
                    userIds: value?.map((e: any) => e.id),
                    id: open?.id,
                  },
                },
              });
            }
          }
          setOpen({ state: false, name: '', value: [], id: undefined });
        }}
        setValues={setValue}
      />
    </div>
  );
};
