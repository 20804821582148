import './ForumUserHover.scss';

import React from 'react';

import { User } from '../../graphql/generated/graphql';
import { addKarma, dateParse, getUserAvatar } from '../../utils';

type ForumUserHoverType = {
  children: any;
  user: User;
  right?: boolean;
};

export const ForumUserHover = ({ children, user, right = false }: ForumUserHoverType) => {
  return (
    <div className='ForumUserHover'>
      {children}
      <div
        className={`ForumUserHover__item ${
          right ? 'ForumUserHover__right' : 'ForumUserHover__left'
        }`}
      >
        <div className='user__avatar'>
          <span className='user__status'>
            <img
              src={getUserAvatar(user?.image)}
              alt='avatar'
              className='user__avatar-image'
            />
            <div
              className='user__status-indicator'
              style={{ backgroundColor: user?.onlineStatus ? 'green' : 'red' }}
            ></div>
          </span>
          <div className='user__avatar-text'>{addKarma(user)}</div>
        </div>
        <div className='ForumUserHover__item-line'></div>
        <div className='ForumUserHover__data'>
          <div className='ForumUserHover__data-left'>
            <div className='mr40'>
              <div className='ForumUserHover__data-name'>Темы</div>
              <div>{user?.forumThreadAuthorCount}</div>
            </div>
            <div>
              <div className='ForumUserHover__data-name'>Сообщения</div>
              <div>{user?.forumMessageCount}</div>
            </div>
          </div>
          <div>
            <div className='ForumUserHover__data-name'>
              {user?.onlineStatus ? 'В сети' : 'Не в сети'}
            </div>
            <div>
              {user?.onlineStatus ? 'Сейчас' : dateParse(user?.lastConnect)?.date2}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
