import './MainContentHeaderTitle.scss';

import React, { CSSProperties } from 'react';

type Props = {
  style?: CSSProperties;
  onClick?: () => void;
  title?: string;
  fullWidth?: boolean;
};

export const MainContentHeaderTitle = ({ title, style, fullWidth }: Props) => {
  const full = fullWidth ? { width: '100%' } : {};
  return (
    <div className='mainContentHeaderTitle' style={full}>
      <span className='mainContentHeaderTitle__title' style={style}>
        {title}
      </span>
    </div>
  );
};
