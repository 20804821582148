import React from 'react';

export const ExitIcons = ({ className }: any) => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M20 22.5H11C10.6022 22.5 10.2206 22.342 9.93934 22.0607C9.65804 21.7794 9.5 21.3978 9.5 21V18.75H11V21H20V3H11V5.25H9.5V3C9.5 2.60218 9.65804 2.22064 9.93934 1.93934C10.2206 1.65804 10.6022 1.5 11 1.5H20C20.3978 1.5 20.7794 1.65804 21.0607 1.93934C21.342 2.22064 21.5 2.60218 21.5 3V21C21.5 21.3978 21.342 21.7794 21.0607 22.0607C20.7794 22.342 20.3978 22.5 20 22.5Z'
        fill='#303030'
      />
      <path
        d='M11.4425 15.4425L14.1275 12.75H3.5V11.25H14.1275L11.4425 8.5575L12.5 7.5L17 12L12.5 16.5L11.4425 15.4425Z'
        fill='#303030'
      />
    </svg>
  );
};
