import './DecideOnTheFlyLogs.scss';

import React, { useState } from 'react';

import { useGetUserDecideOnFlyActionsHistoryQuery } from '../../../../graphql/generated/graphql';
import { DecideOnTheFlyLogsHed } from './DecideOnTheFlyLogsHed/DecideOnTheFlyLogsHed';
import { DecideOnTheFlyLogsTable } from './DecideOnTheFlyLogsTable/DecideOnTheFlyLogsTable';

export const DecideOnTheFlyLogs = ({ onClose }: any) => {
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const setStartDateAtMidnight = (value: any) =>
    setStartDate(
      new Date(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0, 0),
    );
  const setEndDateAtEndOfDay = (value: any) =>
    setEndDate(
      new Date(value.getFullYear(), value.getMonth(), value.getDate(), 23, 59, 59, 999),
    );

  const { data: history } = useGetUserDecideOnFlyActionsHistoryQuery({
    variables: {
      from: startDate,
      to: endDate,
    },
    fetchPolicy: 'network-only',
  });

  return (
    <div className='DecideOnTheFlyLogs'>
      <DecideOnTheFlyLogsHed
        onClose={onClose}
        history={history}
        endDate={endDate}
        setEndDate={setEndDateAtEndOfDay}
        startDate={startDate}
        setStartDate={setStartDateAtMidnight}
        historyRows={history?.getUserDecideOnFlyActionsHistory?.rows}
      />
      {history?.getUserDecideOnFlyActionsHistory?.count === 0 ? (
        <div className='DecideOnTheFlyLogs__warning'>Нет логов за выбранный период</div>
      ) : (
        <DecideOnTheFlyLogsTable history={history} />
      )}
    </div>
  );
};
