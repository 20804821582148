import * as yup from 'yup';

import * as MESSAGES from '../messages';

export const weeklyFile = yup.object().shape({
  color: yup
    .string()
    .matches(/^#([0-9A-Fa-f]{3}){1,2}$/, 'Нужен код цвета hex')
    .required(MESSAGES.REQUIRE_MESSAGE),
  name: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
});
