import PropTypes from 'prop-types';
import React, { createContext, FC, useContext } from 'react';

const PaginationContext = createContext<any>('');
const usePaginationContext = () => {
  const context = useContext(PaginationContext);
  if (!context) {
    throw new Error('usePaginationContext must be used within a PaginationProvider');
  }
  return context;
};

const PaginationProvider: FC = ({ children }) => {
  const [paginationNumber, setPaginationNumber] = React.useState(1);

  return (
    <PaginationContext.Provider
      value={{
        setPaginationNumber,
        paginationNumber,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

PaginationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { PaginationProvider, usePaginationContext };
