import './TreadLink.scss';

import React from 'react';

import { useModalContext } from '../../../context';
import { ForumMessage, ForumThread } from '../../../graphql/generated/graphql';
import { ModalStatuses } from '../../../types/enums';
import { addKarma } from '../../../utils';
import { ForumLink } from '../../icons';

type TreadLinkType = {
  link: ForumMessage;
  tread?: ForumThread;
};

export const TreadLink = ({ link, tread }: TreadLinkType) => {
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  return (
    <div className='TreadItem__link'>
      <div
        className='TreadItem__link-wrapper'
        onClick={() => {
          handleSetModalStatus(ModalStatuses.SHOW_LINK);
          handleChangeModalParameters([
            {
              key: 'message',
              value: link,
            },
            {
              key: 'tread',
              value: tread,
            },
          ]);
        }}
      >
        <div className='TreadItem__link-item'>
          <ForumLink /> {addKarma(link?.user)}
        </div>
        <div className='TreadItem__link'>Сообщение #{link?.id}</div>
      </div>
    </div>
  );
};
