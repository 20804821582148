import React from 'react';

export const EllipseCircleIcons = ({ style, fill = '#E8D4ED', refIcon }: any) => {
  return (
    <svg
      width='133'
      height='247'
      viewBox='0 0 133 247'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
      ref={refIcon}
    >
      <mask id='path-1-inside-1_14174_84887' fill='white'>
        <path d='M0.779134 19.4678C-0.650279 14.1332 2.5125 8.63084 7.89582 7.39735C46.2251 -1.38538 86.0002 -1.80192 124.505 6.17614C129.913 7.29669 133.19 12.7315 131.873 18.095L77.1655 240.824C76.2597 244.512 72.5102 246.673 68.713 246.713C64.9158 246.753 61.1219 244.67 60.1391 241.002L0.779134 19.4678Z' />
      </mask>
      <path
        d='M0.779134 19.4678C-0.650279 14.1332 2.5125 8.63084 7.89582 7.39735C46.2251 -1.38538 86.0002 -1.80192 124.505 6.17614C129.913 7.29669 133.19 12.7315 131.873 18.095L77.1655 240.824C76.2597 244.512 72.5102 246.673 68.713 246.713C64.9158 246.753 61.1219 244.67 60.1391 241.002L0.779134 19.4678Z'
        fill={fill}
        stroke='black'
        strokeOpacity='0.2'
        strokeWidth='2'
        mask='url(#path-1-inside-1_14174_84887)'
      />
    </svg>
  );
};
