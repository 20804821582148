import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const FileIcon: FC<FileIconType> = ({
  width = '14px',
  height = '18px',
  color = '#303030',
  className = 'file-icon',
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M13.6914 4.63672L9.48828 0.433594C9.37109 0.316406 9.21289 0.25 9.04688 0.25H0.75C0.404297 0.25 0.125 0.529297 0.125 0.875V17.125C0.125 17.4707 0.404297 17.75 0.75 17.75H13.25C13.5957 17.75 13.875 17.4707 13.875 17.125V5.08008C13.875 4.91406 13.8086 4.75391 13.6914 4.63672ZM12.4336 5.36719H8.75781V1.69141L12.4336 5.36719ZM12.4688 16.3438H1.53125V1.65625H7.42969V5.875C7.42969 6.09256 7.51611 6.30121 7.66995 6.45505C7.82379 6.60889 8.03244 6.69531 8.25 6.69531H12.4688V16.3438Z'
        fill={color}
      />
    </svg>
  );
};

type FileIconType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
>;
