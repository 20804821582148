import moment from 'moment';
import { useEffect, useState } from 'react';

import { GetChronographDaysQuery } from '../graphql/generated/graphql';
import { toolsChronoType } from '../types/types';
import { createArrayStartingFromMonday, filterArrayDiapason } from '../utils';

type useChronometerToolsType = {
  dataDays: GetChronographDaysQuery | undefined;
  from: string;
};

export const useChronometerTools = ({ dataDays, from }: useChronometerToolsType) => {
  const [selectHours, setSelectHours] = useState<Array<number | null | undefined>>([]);
  const [selectDay, setHourArray] = useState<any>();
  const dayArray = from ? createArrayStartingFromMonday(from) : [{ date2: '' }];

  const [selectDayDate, setSelectDayDate] = useState('');

  const converterDayNumber: { [key: number]: number } = {
    1: 0,
    2: 1,
    3: 2,
    4: 3,
    5: 4,
    6: 5,
    0: 6,
  };

  // set the day of the week when changing the week
  useEffect(() => {
    const startDate = moment(dayArray[0].date2);
    const endDate = startDate.clone().add(7, 'days');
    const today = moment();
    const returnDay = converterDayNumber[today.day() as keyof typeof converterDayNumber];
    if (today.isBetween(startDate, endDate, null, '[]') && returnDay) {
      setSelectDayDate(dayArray[returnDay]?.date2);
    } else {
      setSelectDayDate(dayArray[0]?.date2);
    }
  }, [from]);

  const [weeksDayArray, setWeeksDayArray] = useState<any>();

  const days = dataDays?.getChronographDays?.days;

  // filling empty slots
  useEffect(() => {
    if (!days) return;
    const dayMap = days?.map((e) => e.date);
    const IFillTheDays = dayArray?.map((e) => {
      const index = dayMap?.indexOf(e.date2);
      if (index == -1) {
        return { ...e, hours: [], statistic: null, date: e.date2 };
      } else {
        return { ...e, ...days[index] };
      }
    });
    const IFillTheHours = IFillTheDays?.map((e) => {
      return e;
    });

    // Create an array with zero values   for all hours in a day
    const allHours = Array.from({ length: 24 }, (_, hour) => ({
      hour,
      energy: '',
      scaleMapFileId: null,
    }));

    // Iterate through each day and fill in the gaps in the hours array
    const daysWithAllHours = IFillTheHours.map((d) => ({
      ...d,
      hours: allHours.map((h) => d.hours.find((dh) => dh.hour === h.hour) || h),
    }));

    // find and add the color of the plan to the task
    const plan = dataDays?.getChronographDays?.statistic?.plan;
    const planFileId = plan?.map((e) => e.scaleMapFileId);

    const daysWithAllHoursColor = daysWithAllHours?.map((e) => {
      const hours = e?.hours?.map((e) => {
        const index = planFileId?.indexOf(e?.scaleMapFileId as number);
        let color: string | null | undefined = '';
        if (index !== -1) {
          color = plan[index].color;
        }

        return { ...e, color };
      });
      const newStatistic = {
        ...e?.statistic,
        scaleMapFilesTracked: e?.statistic?.scaleMapFilesTracked?.map((e) => {
          const index = planFileId?.indexOf(e?.scaleMapFileId as any);
          let color: string | null | undefined = '';
          if (index !== -1) {
            color = plan[index].color;
          }
          return { ...e, color };
        }),
      };
      return { ...e, hours, statistic: newStatistic };
    });

    setWeeksDayArray(daysWithAllHoursColor);
  }, [days]);

  // choose the day of the week
  useEffect(() => {
    if (weeksDayArray?.length === 0 || !weeksDayArray) {
      setHourArray(null);
    } else {
      const index = weeksDayArray.map((e: any) => e.date)?.indexOf(selectDayDate);
      if (index !== -1) {
        weeksDayArray && setHourArray(weeksDayArray[index]);
      }
    }
  }, [weeksDayArray, selectDayDate]);

  const handleSetSelectHours = (value: number) => {
    const index = toolsChrono?.findIndex(value);

    if (index === -1) {
      setSelectHours([...selectHours, value]);
    } else {
      const clone: [number] = JSON.parse(JSON.stringify(selectHours));
      setSelectHours(clone.filter((item) => item !== value));
    }
  };

  const toolsChrono: toolsChronoType = {
    findIndex: (value: number) => {
      return selectHours.indexOf(value);
    },

    showNumber: () => {
      return filterArrayDiapason(selectHours);
    },
  };
  return {
    selectHours,
    handleSetSelectHours,
    toolsChrono,
    selectDay,
    selectDayDate,
    setSelectDayDate,
    setSelectHours,
  };
};
