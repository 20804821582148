import './ChangeTagsModal.scss';

import { Form, Formik } from 'formik';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import { toast } from 'react-toastify';

import {
  Tag as TagTypeValue,
  TagType,
  useCreateTagMutation,
  useDeleteTagMutation,
  useGetTagsQuery,
  useUpdateTagMutation,
} from '../../../../graphql/generated/graphql';
import { addTagSchema } from '../../../../validation/schemas';
import { EditIcon, RemoveIcon } from '../../../icons';
import { AppInput, Button, TagItem } from '../../../index';
import { Modal } from '../../Modal';

type ChangeTagsModalType = {
  isOpen: boolean;
  onClose: () => void;
};

enum Status {
  ADD = 'add',
  EDIT = 'edit',
  NONE = 'none',
}

export const ChangeTagsModal = ({ onClose }: ChangeTagsModalType) => {
  const [modalState, setModalState] = useState(Status.NONE);
  const [searchValue, setSearchValue] = useState<string>('');
  const [editTag, setEditTag] = useState<TagTypeValue | null>(null);

  useEffect(() => {
    setSearchValue('');
  }, [modalState]);

  const { data, refetch } = useGetTagsQuery({
    variables: {
      data: {
        search: searchValue,
        type: TagType.Task,
      },
    },
    fetchPolicy: 'network-only',
  });

  return (
    <div className='ChangeTagsModal'>
      {modalState === Status.NONE && (
        <div className='button__wrapper'>
          <Button
            onClick={() => {
              setModalState(Status.ADD);
            }}
          >
            Добавить тег
          </Button>

          <Button
            margin='10px 0px 0px'
            onClick={() => {
              setModalState(Status.EDIT);
            }}
          >
            Редактировать тег
          </Button>
        </div>
      )}
      {modalState === Status.EDIT && (
        <EditTags
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          tags={data?.getTags?.rows}
          refetch={refetch}
          setModalState={setModalState}
          setEditTag={setEditTag}
        />
      )}
      {modalState === Status.ADD && (
        <AddTag
          onClose={onClose}
          editTag={editTag}
          setEditTag={setEditTag}
          setModalState={setModalState}
          refetch={refetch}
          tags={data?.getTags?.rows}
        />
      )}
    </div>
  );
};

type EditTagsType = {
  searchValue: string;
  setSearchValue: (value: string) => void;
  tags?: Array<TagTypeValue>;
  refetch: () => void;
  setModalState: (value: Status) => void;
  setEditTag: (value: TagTypeValue | null) => void;
};

const EditTags = ({
  searchValue,
  setSearchValue,
  tags,
  refetch,
  setModalState,
  setEditTag,
}: EditTagsType) => {
  const [deleteTagMutation] = useDeleteTagMutation({
    onCompleted() {
      toast.success('Тег удален');
      refetch();
    },
  });

  return (
    <div>
      <div className='tags-search'>
        <label>
          <svg
            className='tags-search__icon'
            width='12'
            height='12'
            viewBox='0 0 11 11'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M10.6487 10.0193L8.03072 7.29639C8.70386 6.49619 9.07268 5.48938 9.07268 4.44125C9.07268 1.99238 7.0803 0 4.63143 0C2.18257 0 0.190186 1.99238 0.190186 4.44125C0.190186 6.89011 2.18257 8.88249 4.63143 8.88249C5.55077 8.88249 6.42685 8.6052 7.17588 8.07882L9.81378 10.8224C9.92404 10.9369 10.0723 11 10.2313 11C10.3817 11 10.5244 10.9426 10.6327 10.8384C10.8629 10.6169 10.8702 10.2496 10.6487 10.0193ZM4.63143 1.15859C6.44153 1.15859 7.91409 2.63115 7.91409 4.44125C7.91409 6.25134 6.44153 7.72391 4.63143 7.72391C2.82133 7.72391 1.34877 6.25134 1.34877 4.44125C1.34877 2.63115 2.82133 1.15859 4.63143 1.15859Z' />
          </svg>

          <input
            className='tags-search__input'
            type='text'
            value={searchValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSearchValue(e.target.value);
            }}
            placeholder='Поиск'
          />
        </label>
      </div>
      <div className='tag__wrapper-outer'>
        {tags?.map((tag) => {
          return (
            <div key={tag.id + 'tags'} className='tag__wrapper'>
              <TagItem
                key={tag.id}
                id={tag.id}
                title={tag.name}
                color={tag.color}
                textColor={tag.textColor as string}
              />
              <div className='icons__wrapper'>
                <EditIcon
                  width={15}
                  height={15}
                  color='#303030'
                  className='edit-color'
                  onClick={() => {
                    setEditTag(tag);
                    setModalState(Status.ADD);
                  }}
                />
                <RemoveIcon
                  color='#303030'
                  onClick={() => {
                    deleteTagMutation({
                      variables: {
                        id: tag.id,
                      },
                    });
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

type AddTagType = {
  onClose: () => void;
  editTag: TagTypeValue | null;
  setEditTag: (value: TagTypeValue | null) => void;
  setModalState: (value: Status) => void;
  refetch: () => void;
  tags?: Array<TagTypeValue>;
};

const AddTag = ({ editTag, setModalState, setEditTag, refetch, tags }: AddTagType) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [pickerColor, setPickerColor] = useState('');
  const [selectColorName, setSelectColorName] = useState('');

  const { handleEnter } = useEnterColor({ setPickerColor, setIsPickerOpen });
  const [updateTagMutation] = useUpdateTagMutation({
    onCompleted() {
      refetch();
      toast.success('Тег обновлен');
      setEditTag(null);
      setModalState(Status.EDIT);
    },
  });

  const [createTagMutation] = useCreateTagMutation({
    onCompleted() {
      refetch();
      toast.success('Тег создан');
      setModalState(Status.EDIT);
    },
  });

  const handleSubmit = async (value: {
    name: string;
    color: string;
    textColor: string;
  }) => {
    const isEqualTagName = tags?.filter(
      (tag) => tag.name.toLowerCase() === value.name.toLowerCase(),
    );

    if (editTag) {
      if (
        !tags?.filter(
          (tag) =>
            tag.id !== editTag.id && tag.name.toLowerCase() === value.name.toLowerCase(),
        ).length
      ) {
        await updateTagMutation({
          variables: {
            id: editTag?.id,
            data: value,
          },
        });
      } else {
        toast.error('Тег с таким названием уже существует!');
      }
    } else {
      if (!isEqualTagName?.length) {
        await createTagMutation({
          variables: {
            data: value,
          },
        });
      } else {
        toast.error('Тег с таким названием уже существует!');
      }
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          name: editTag ? editTag?.name : '',
          color: editTag ? (editTag?.color as string) : '#E3025A',
          textColor: editTag ? (editTag?.textColor as string) : 'white',
        }}
        onSubmit={handleSubmit}
        validationSchema={addTagSchema}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form className='change__taq'>
              <AppInput
                label={`${editTag ? 'Редактировать' : 'Создать'} тег:`}
                name='name'
                placeholder='название тега'
              />
              <div className='tag-color__wrapper-outer'>
                <div className='tag-color__wrapper-inner'>
                  <div className='tag-color__text'> Цвет фона:</div>
                  <div
                    style={{
                      backgroundColor: values?.color,
                    }}
                    className='tag-color__element'
                    onClick={() => {
                      setSelectColorName('color');
                      setIsPickerOpen(true);
                    }}
                  ></div>
                </div>
                <div className='tag-color__wrapper-inner ml20'>
                  <div className='tag-color__text'>Цвет текста (не рекомендуется):</div>
                  <div
                    style={{
                      backgroundColor: values?.textColor,
                    }}
                    className='tag-color__element'
                    onClick={() => {
                      setSelectColorName('textColor');
                      setIsPickerOpen(true);
                    }}
                  ></div>
                </div>
              </div>
              <Modal isOpen={isPickerOpen} onClose={() => setIsPickerOpen(false)}>
                <div className='add-polygon-block-modal__colors-picker'>
                  <ChromePicker
                    disableAlpha
                    color={pickerColor}
                    onChangeComplete={(color) => {
                      setPickerColor(color.hex);
                      setFieldValue(selectColorName, color.hex);
                    }}
                    onChange={(paletteColor) => {
                      handleEnter({ hex: paletteColor.hex, name: paletteColor.hex });
                    }}
                  />
                  <Button
                    text='Сохранить цвет'
                    margin='10px 0 0 0'
                    onClick={() => setIsPickerOpen(false)}
                  />
                </div>
              </Modal>
              <Button type='submit' width='100%' margin='20px 0px 0px'>
                {`${editTag ? 'Сохранить' : 'Создать'} тег`}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const useEnterColor = ({ setPickerColor, setIsPickerOpen }: any) => {
  const [colorEnter, setColorEnter] = useState('');

  useEffect(() => {
    const handleUserKeyPress = (event: any) => {
      const { keyCode } = event;
      if (keyCode === 13) {
        setPickerColor(colorEnter);
        setIsPickerOpen(false);
      }
    };

    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  });

  const handleEnter = ({ hex }: any) => {
    setColorEnter(hex);
  };

  return { handleEnter };
};
