import {
  AllPolygon,
  Archive,
  Calendar,
  ChronometerPage,
  Circle,
  DecideOnTheFly,
  DecideOnTheFlyCreate,
  DecideOnTheFlyPackage,
  DevPage,
  Expenses,
  File,
  FileGraphicsPage,
  ForumChapters,
  ForumTread,
  History,
  Login,
  MyTasks,
  MyTasksMaintainer,
  Polygon,
  PolygonStatistic,
  Privacy,
  ProjectI,
  Projects,
  Scale,
  Storage,
  StorageTreds,
  Subtask,
  Task,
  Tasks,
  Terms,
  Treads,
  TreadsFile,
  TredItem,
  UserChat,
  Users,
  UserSettings,
  UsersStorage,
  UserStatistic,
  UserTasks,
} from '../screens';
import { PATHS } from './constants';

export const routes = [
  {
    path: PATHS.decideOnTheFlyCreate,
    component: DecideOnTheFlyCreate,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.decideOnTheFlyPackage,
    component: DecideOnTheFlyPackage,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.userSettings,
    component: UserSettings,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.userStorage,
    component: UsersStorage,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.decideOnTheFly,
    component: DecideOnTheFly,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.circle,
    component: Circle,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.storage,
    component: StorageTreds,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.treadsFile,
    component: TreadsFile,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.treadsFile,
    component: TreadsFile,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.treadItem,
    component: TredItem,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.treads,
    component: Treads,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.devPage,
    component: DevPage,
    auth: true,
    withLayout: false,
  },
  {
    path: PATHS.terms,
    component: Terms,
    auth: false,
    withLayout: false,
    allAccess: true,
  },
  {
    path: PATHS.privacy,
    component: Privacy,
    auth: false,
    withLayout: false,
    allAccess: true,
  },
  {
    path: PATHS.chronometer,
    component: ChronometerPage,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.fileGraphicsI,
    component: FileGraphicsPage,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.fileGraphics,
    component: FileGraphicsPage,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.tred,
    component: ForumTread,
    auth: true,
    withLayout: false,
  },
  {
    path: PATHS.singlePolygonStatistic,
    component: PolygonStatistic,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.singlePolygon,
    component: Polygon,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.userStatistic,
    component: UserStatistic,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.allPolygon,
    component: AllPolygon,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.projects,
    component: Projects,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.registration,
    component: Login,
    auth: false,
    withLayout: false,
  },
  {
    path: PATHS.passwordRecovery,
    component: Login,
    auth: false,
    withLayout: false,
  },
  {
    path: PATHS.login,
    component: Login,
    auth: false,
    withLayout: false,
  },
  {
    path: PATHS.tasks,
    component: Tasks,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.myTasks,
    component: MyTasks,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.myTasksMaintainer,
    component: MyTasksMaintainer,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.history,
    component: History,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.task,
    component: Task,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.archivedTask,
    component: Task,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.subtask,
    component: Subtask,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.archivedSubtask,
    component: Subtask,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.chat,
    component: UserChat,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.users,
    component: Users,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.archive,
    component: Archive,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.expenses,
    component: Expenses,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.calendar,
    component: Calendar,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.storageProject,
    component: Storage,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.polygonStatistic,
    component: PolygonStatistic,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.polygon,
    component: Polygon,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.userTasks,
    component: UserTasks,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.scale,
    component: Scale,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.file,
    component: File,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.forum,
    component: ForumChapters,
    auth: true,
    withLayout: false,
  },
  {
    path: PATHS.projectI,
    component: ProjectI,
    auth: true,
    withLayout: true,
  },
  {
    path: PATHS.fileSelf,
    component: File,
    auth: true,
    withLayout: true,
  },
];
