import * as yup from 'yup';

import * as MESSAGES from '../messages';

export const addPriceSchema = yup.object().shape({
  taskPrice: yup
    .number()
    .min(1, MESSAGES.INCORRECT_MIN_LENGTH_3)
    .positive(MESSAGES.INCORRECT_TASK_PRICE)
    .nullable(true)
    .required(MESSAGES.REQUIRE_MESSAGE),
});
