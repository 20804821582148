import React from 'react';

import { Performers } from '../../../treads/TredsModal/Performers';
import { Modal } from '../../Modal';

type PerformersModalType = {
  onClose: () => void;
  modalParameters?: any;
};

export const PerformersModal = ({ onClose, modalParameters }: PerformersModalType) => {
  return (
    <Modal
      isOpen={true}
      onClose={() => {
        onClose();
      }}
    >
      <div style={{ width: '350px' }}>
        <Performers
          setIsOpen={() => {}}
          showAddGroup={modalParameters?.showAddGroup}
          closeModalCallback={(e: any) => {
            modalParameters.closeModalCallback(e);
            onClose();
          }}
          isModal={true}
          assignees1={modalParameters?.initialValues?.performers}
          onClose={onClose}
        />
      </div>
    </Modal>
  );
};
