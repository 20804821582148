import * as GENERATED from '../../graphql/generated/graphql';

export const useGetTasksQuery = (projectId: number) => {
  return GENERATED.useGetTasksQuery({
    variables: {
      data: {
        limit: 100,
        isTaskStorage: false,
        projectId,
      },
    },
  });
};
