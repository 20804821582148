import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const TreeIcon: FC<TreeIconType> = ({
  width = '14px',
  height = '14px',
  color = '#303030',
  className = 'tree-icon',
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M5.83317 2.91667C5.83317 3.561 6.3555 4.08333 6.99984 4.08333C7.64417 4.08333 8.1665 3.561 8.1665 2.91667C8.1665 2.27233 7.64417 1.75 6.99984 1.75C6.3555 1.75 5.83317 2.27233 5.83317 2.91667Z'
        fill={color}
        stroke='white'
      />
      <path
        d='M9.91667 11.0833C9.91667 11.7276 10.439 12.25 11.0833 12.25C11.7277 12.25 12.25 11.7276 12.25 11.0833C12.25 10.439 11.7277 9.91663 11.0833 9.91663C10.439 9.91663 9.91667 10.439 9.91667 11.0833Z'
        stroke='white'
      />
      <path
        d='M5.83317 11.0833C5.83317 11.7276 6.35551 12.25 6.99984 12.25C7.64417 12.25 8.1665 11.7276 8.1665 11.0833C8.1665 10.439 7.64417 9.91663 6.99984 9.91663C6.35551 9.91663 5.83317 10.439 5.83317 11.0833Z'
        stroke='white'
      />
      <path
        d='M1.75016 11.0833C1.75016 11.7276 2.2725 12.25 2.91683 12.25C3.56116 12.25 4.0835 11.7276 4.0835 11.0833C4.0835 10.439 3.56116 9.91663 2.91683 9.91663C2.2725 9.91663 1.75016 10.439 1.75016 11.0833Z'
        stroke='white'
      />
      <path
        d='M7.00016 4.08337L7.00016 9.91671M2.91683 9.91671L2.91683 6.41671L11.0835 6.41671L11.0835 9.91671L2.91683 9.91671Z'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

type TreeIconType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
>;
