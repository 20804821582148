import { ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';

import * as GENERATED from '../../graphql/generated/graphql';
import { GetTaskDocument, GetTaskQuery } from '../../graphql/generated/graphql';
import { getErrorText } from '../../utils';

export const useStartTaskMutation = (handleClosePanel: () => void) => {
  return GENERATED.useStartTaskMutation({
    update(cache, { data }) {
      if (!data?.startTask) return null;

      const task: GetTaskQuery | null = cache.readQuery({
        query: GetTaskDocument,
        variables: {
          id: data.startTask.id,
        },
      });

      if (task) {
        cache.writeQuery({
          query: GetTaskDocument,
          variables: {
            id: data.startTask.id,
          },
          data: {
            ...task,
            getTask: {
              ...task.getTask,
              startDate: data.startTask.startDate,
            },
          },
        });
      }
    },
    onCompleted: (res) => {
      if (res.startTask) {
        handleClosePanel();
        toast.success('Задача начата!');
      }
    },
    onError(error: ApolloError) {
      if (error?.message) {
        handleClosePanel();
        toast.error(getErrorText(error.message));
      }
    },
  });
};
