import './FileFonAndTagsModal.scss';

import { Form, Formik } from 'formik';
import React from 'react';

import {
  useGetScaleMapFileTagsAndFonsQuery,
  useThreadBackgroundsQuery,
} from '../../../../graphql/generated/graphql';
import { Button, Preloader, TagsSelect } from '../../../index';
import { ItemColor } from '../tredFonsModal/TredFonsModal';

export const FileFonAndTagsModal = ({ onClose, modalParameters }: any) => {
  const { data, loading } = useGetScaleMapFileTagsAndFonsQuery({
    variables: {
      getScaleMapFileId: modalParameters?.fileId,
    },
  });
  const { data: colorData, loading: loadingColor } = useThreadBackgroundsQuery({
    fetchPolicy: 'network-only',
  });

  const colors = colorData?.threadBackgrounds;

  if (loading || loadingColor) return <Preloader />;
  return (
    <Formik
      initialValues={{
        attachedTagIds: data?.getScaleMapFile?.attachedTags?.map((e) => e.id),
        threadBackgroundId: data?.getScaleMapFile?.threadBackground?.id,
      }}
      onSubmit={(values) => {
        modalParameters?.onCallback(values);
        onClose();
      }}
      enableReinitialize={true}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form className='FileFonAndTagsModal'>
            <div className='FileFonAndTagsModal__title1'>
              Настройки для тредов по умолчанию
            </div>
            <TagsSelect
              name='attachedTagIds'
              isEditMode={true}
              editTagIds={values?.attachedTagIds?.map((e: any) => String(e))}
            />{' '}
            <div className='FileFonAndTagsModal__title2'>Цвет фона</div>
            <div className='FileFonAndTagsModal__list'>
              {colors?.map((e) => {
                return (
                  <ItemColor
                    color={e.backgroundColor}
                    key={e.id}
                    select={values?.threadBackgroundId === e.id}
                    setSelectColor={(value: any) => {
                      if (values?.threadBackgroundId === value) {
                        setFieldValue('threadBackgroundId', undefined);
                      } else {
                        setFieldValue('threadBackgroundId', value);
                      }
                    }}
                    id={e.id}
                  />
                );
              })}
            </div>
            <div className='FileFonAndTagsModal__buttonsWraper'>
              <Button>Отменить</Button>
              <Button type='submit'>Сохранить</Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
