import { useEffect, useState } from 'react';

import { taskPaginationLimit } from '../constants/constants';
import {
  SortingField,
  SortingOrders,
  useGetProjectsQuery,
} from '../graphql/generated/graphql';

export const useOptionScrollLoad = () => {
  const [pageNumberState, setPageNumberState] = useState<number>(0);

  const { data, loading } = useGetProjectsQuery({
    variables: {
      data: {
        limit: 99999,
        offset: pageNumberState * 99999,
        sort: { field: SortingField.Number, type: SortingOrders.Asc },
      },
      tasksData: {
        limit: taskPaginationLimit,
        isTaskStorage: false,
      },
    },
  });

  const newOptions = data?.getProjects?.rows?.map((e) => {
    return { value: e?.id, label: e?.name };
  });

  const [options, setOptions] = useState([{ value: '', label: '' }]);
  useEffect(() => {
    if (!newOptions || newOptions?.length === 0) return;
    const ids = new Set(options?.map((d: any) => d.value) as any);
    const merged = [...options, ...(newOptions?.filter((d) => !ids.has(d.value)) as any)];
    setOptions(merged as any);
  }, [loading]);

  return { setPageNumberState, options };
};
