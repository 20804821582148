import React from 'react';

export const TreadsPinIcons = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.1666 3.33366V2.83366H11.6666H8.33329H7.83329V3.33366V7.50033C7.83329 8.30896 7.57666 9.06961 7.10108 9.69884L6.4953 10.5003H7.49996H12.5H13.5L12.9 9.70033C12.4377 9.08393 12.1666 8.32186 12.1666 7.50033V3.33366ZM7.16663 3.33366V2.83366H6.66663H5.83329C5.6511 2.83366 5.49996 2.68252 5.49996 2.50033C5.49996 2.31813 5.6511 2.16699 5.83329 2.16699H14.1666C14.3488 2.16699 14.5 2.31813 14.5 2.50033C14.5 2.68252 14.3488 2.83366 14.1666 2.83366H13.3333H12.8333V3.33366V7.50033C12.8333 8.9894 13.9127 10.2217 15.3333 10.459V11.167H10.8083H10.3083V11.667V17.2932L9.97496 17.6266L9.64163 17.2932V11.667V11.167H9.14163H4.66663V10.459C6.08726 10.2217 7.16663 8.9894 7.16663 7.50033V3.33366Z'
        fill='#303030'
        stroke='#303030'
      />
    </svg>
  );
};
