import './EditIcon.scss';

import React, { FC } from 'react';

type Props = {
  color?: string;
  stroke?: string;
  onClick?: () => void;
  width?: string | number;
  height?: string | number;
  className?: 'edit-color' | 'edit-color_with-background' | string;
};

export const EditIcon2: FC<Props> = ({ onClick, className = 'edit-color' }) => {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      className={className}
    >
      <path
        d='M13.9787 18.3323H3.90928C2.85578 18.3323 1.99951 17.476 1.99951 16.4225V6.35312C1.99951 5.29963 2.85578 4.44336 3.90928 4.44336H9.81201C10.0995 4.44336 10.3328 4.67669 10.3328 4.96419C10.3328 5.25169 10.0995 5.48503 9.81201 5.48503H3.90928C3.43079 5.48503 3.04118 5.87463 3.04118 6.35312V16.4225C3.04118 16.901 3.43079 17.2906 3.90928 17.2906H13.9787C14.4572 17.2906 14.8468 16.901 14.8468 16.4225V10.5198C14.8468 10.2323 15.0801 9.99896 15.3676 9.99896C15.6551 9.99896 15.8884 10.2317 15.8884 10.5198V16.4225C15.8884 17.476 15.0322 18.3323 13.9787 18.3323Z'
        fill='#AFAFAF'
        stroke='#AFAFAF'
        strokeWidth='0.3'
      />
      <path
        d='M8.09136 12.7605C7.95454 12.7605 7.82115 12.7063 7.72324 12.6077C7.59965 12.4848 7.54688 12.3077 7.58095 12.1375L8.07191 9.682C8.092 9.58065 8.14197 9.48821 8.21419 9.41599L15.4067 2.22439C16.151 1.47989 17.3622 1.47989 18.1073 2.22439C18.4677 2.58475 18.6663 3.06401 18.6663 3.57441C18.6663 4.08482 18.4677 4.56395 18.1066 4.92443L10.915 12.1168C10.8428 12.1897 10.7497 12.239 10.649 12.2591L8.1941 12.75C8.16002 12.757 8.12531 12.7605 8.09136 12.7605ZM10.5469 11.7487H10.5539H10.5469ZM9.0622 10.0411L8.75588 11.5757L10.2899 11.2688L17.3705 4.18833C17.5344 4.02366 17.6247 3.80635 17.6247 3.57441C17.6247 3.34248 17.5344 3.12504 17.3705 2.9605C17.033 2.62226 16.4823 2.62226 16.1428 2.9605L9.0622 10.0411Z'
        fill='#AFAFAF'
        stroke='#AFAFAF'
        strokeWidth='0.2'
      />
      <path
        d='M16.7565 6.06143C16.6232 6.06143 16.4898 6.0107 16.3885 5.90859L14.4246 3.94402C14.2211 3.74057 14.2211 3.41073 14.4246 3.20728C14.628 3.00382 14.9579 3.00382 15.1614 3.20728L17.1252 5.17185C17.3287 5.3753 17.3287 5.70514 17.1252 5.90859C17.0231 6.01006 16.8899 6.06143 16.7565 6.06143Z'
        fill='#AFAFAF'
        stroke='#AFAFAF'
        strokeWidth='0.2'
      />
    </svg>
  );
};
