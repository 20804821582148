import PropTypes from 'prop-types';
import React, { createContext, FC, useContext, useState } from 'react';

import { ModalStatuses, OptionsStatuses } from '../types/enums';

const ModalContext = createContext<any>('');
const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useMenuContext must be used within a MenuProvider');
  }
  return context;
};

const ModalProvider: FC = ({ children }) => {
  const [modalStatus, setModalStatus] = useState('');

  const [optionsStatus, setOptionsStatus] = useState('');

  const [childrenOptionsStatus, setChildrenOptionsStatus] = useState('');

  const handleSetOptionsStatus = (optionsStatus: OptionsStatuses) =>
    setOptionsStatus(optionsStatus);

  const handleSetModalStatus = (modalStatus: string) => setModalStatus(modalStatus);

  const handleSetChildrenOptionsStatus = (childrenOptionsStatus: string) =>
    setChildrenOptionsStatus(childrenOptionsStatus);

  const handleCloseModal = () => {
    setModalStatus(ModalStatuses.WITHOUT_STATUS);
    setOptionsStatus(OptionsStatuses.WITHOUT_STATUS);
    setChildrenOptionsStatus(OptionsStatuses.WITHOUT_STATUS);
  };

  const initModalValue = {
    onCallback: () => {},
    onSubmitColorCallback: () => {},
    value: '',
    initialInfoValues: { info: '' },
    initialPaletteValues: {
      hex1: '',
      colorName1: '',
      hex2: '',
      colorName2: '',
      hex3: '',
      colorName3: '',
      hex4: '',
      colorName4: '',
    },
    initialEditTitleValues: { projectName: '' },
    initialEditInfoValues: { polygonName: '', color: '' },
    initialColorId: 0,
    removeConfirmTitle: '',
    colors: [],
  };

  const [modalParameters, setModalParameters] = useState(initModalValue);

  const handleChangeModalParameters = (params: any[]) => {
    params.map(({ key, value }) => {
      return setModalParameters((prevModalParameters) => ({
        ...prevModalParameters,
        [key]: value,
      }));
    });
  };

  return (
    <ModalContext.Provider
      value={{
        modalStatus,
        optionsStatus,
        childrenOptionsStatus,
        handleSetModalStatus,
        handleSetOptionsStatus,
        handleCloseModal,
        handleChangeModalParameters,
        handleSetChildrenOptionsStatus,
        modalParameters,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ModalProvider, useModalContext };
