import './HoverMenuTreadItem.scss';

import React from 'react';

import { useModalContext } from '../../../context';
import { ForumMessage } from '../../../graphql/generated/graphql';
import { ModalStatuses } from '../../../types/enums';
import { TreadsDeleteICons, TreadsEditICons } from '../../icons';

type HoverMenuTreadItemType = {
  updateForumMessageMutation: (value: any) => void;
  removeForumMessageMutation: (value: any) => void;
  message: ForumMessage;
};

export const HoverMenuTreadItem = ({
  updateForumMessageMutation,
  message,
  removeForumMessageMutation,
}: HoverMenuTreadItemType) => {
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  return (
    <div className='HoverMenuTreadItem'>
      <div
        className='HoverMenuTreadItem__item'
        onClick={() => {
          handleSetModalStatus(ModalStatuses.FORUM_TREAD_MESSAGE);
          handleChangeModalParameters([
            {
              key: 'title',
              value: 'Редактировать сообщение',
            },
            {
              key: 'buttonText',
              value: 'Сохранить',
            },
            {
              key: 'initialValues',
              value: {
                description: message?.text,
                viewType: message?.viewType,
                image: message?.imagePath,
                message: message?.quote ? message?.quote : undefined,
                link: message?.reply ? message?.reply : undefined,
              },
            },
            {
              key: 'onCallback',
              value: (value: any) => {
                updateForumMessageMutation({
                  variables: {
                    data: {
                      id: message?.id,
                      text: value?.description,
                      viewType: value?.viewType,
                      image: typeof value?.image !== 'string' ? value?.image : undefined,
                      imagePath:
                        typeof value?.image === 'string' ? value?.image : undefined,
                      quoteId: value?.message?.id ? value?.message?.id : null,
                      replyId: value?.link?.id ? value?.link?.id : null,
                    },
                  },
                });
              },
            },
          ]);
        }}
      >
        <TreadsEditICons /> Редактировать
      </div>
      <div className='HoverMenuTreadItem__line'></div>
      <div
        className='HoverMenuTreadItem__item'
        onClick={() => {
          handleSetModalStatus(ModalStatuses.ACTION_TREAD);
          handleChangeModalParameters([
            {
              key: 'onCallback',
              value: () => {
                removeForumMessageMutation({
                  variables: {
                    id: message?.id,
                  },
                });
              },
            },
            {
              key: 'textData',
              value: {
                title: 'Удалить сообщение',
                iconsDelete: true,
                buttonText: 'Удалить',
              },
            },
          ]);
        }}
      >
        <TreadsDeleteICons /> Удалить
      </div>
    </div>
  );
};
