import React from 'react';

type LoadBackgroundType = {
  onClick?: () => void;
};

export const LoadBackground = ({ onClick }: LoadBackgroundType) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <rect x='0.25' y='0.25' width='21.5' height='21.5' rx='1.75' fill='#EFEFEF' />
      <rect
        x='0.184384'
        y='0.388744'
        width='10.6732'
        height='8.86449'
        transform='matrix(0.937041 0.349218 -0.322428 0.946594 8.57347 5.06184)'
        stroke='#AFAFAF'
        strokeWidth='0.6'
      />
      <rect
        x='4.92651'
        y='5.5'
        width='9.83871'
        height='8.71774'
        fill='#D9D9D9'
        stroke='#EFEFEF'
      />
      <rect
        x='3.3'
        y='5.3'
        width='10.7548'
        height='8.57786'
        fill='#EFEFEF'
        stroke='#AFAFAF'
        strokeWidth='0.6'
      />
      <path
        d='M12.2906 7.43091C12.2906 7.8782 11.9439 8.24072 11.5163 8.24072C11.0888 8.24072 10.7422 7.8782 10.7422 7.43091C10.7422 6.98372 11.0888 6.62109 11.5163 6.62109C11.9439 6.62109 12.2906 6.98372 12.2906 7.43091Z'
        fill='#AFAFAF'
      />
      <path
        d='M12.8063 12.5542H4.84315C4.74484 12.5542 4.54822 12.4246 4.54822 11.9063C4.54822 11.2585 5.72794 7.69531 6.61273 7.69531C7.49753 7.69531 8.08739 10.9346 8.97218 10.9346C9.85698 10.9346 9.56204 9.63886 10.4468 9.63886C11.1547 9.63886 12.3147 11.5824 12.8063 12.5542Z'
        fill='#AFAFAF'
        stroke='#AFAFAF'
      />
      <rect
        x='0.25'
        y='0.25'
        width='21.5'
        height='21.5'
        rx='1.75'
        stroke='#AFAFAF'
        strokeWidth='0.5'
      />
    </svg>
  );
};
