import './LinkAddModal.scss';

import React, { useEffect, useState } from 'react';

import { AddModalEnum } from '../../../../types/enums';
import { RectType } from '../../../../types/types';
import { ArrowDawnIcon } from '../../../icons';
import { Button, SelectStateController } from '../../../index';
import { AddLinkStart } from './AddLinkStatus/AddLinkStart';

type LinkAddModalType = {
  onSubmit: (value: any) => void;
  initialValues: {
    [key: string]: any;
  };
  buttonText: string;
  links: Array<{
    name: string;
    id: number;
  }>;
  projectId: string;
  rect: [RectType];
  rectChange: (value: any) => void;
};

export const LinkAddModal = ({
  onSubmit,
  initialValues,

  buttonText,
  links,
  projectId,
  rect,
  rectChange,
}: LinkAddModalType) => {
  const [modalStatus, setModalStatus] = useState(AddModalEnum.DEFAULT_STATUS);

  const {
    selectValue,
    setSelectValue,
    rectOptionInit,
    selectRect,
    setSelectRect,
    rectOptions,
    filterLinks,
  } = useFilterLink({
    links,
    initialValues,
    rect,
  });

  const [editValue, setEditValue] = useState<any>(null);

  const handleSubmit = () => {
    onSubmit(selectValue);
    rectChange(selectRect);
  };

  return (
    <div>
      {AddModalEnum.DEFAULT_STATUS === modalStatus && (
        <AddLinkStart
          buttonText={buttonText}
          projectId={projectId}
          handleSubmit={handleSubmit}
          selectValue={selectValue}
          selectRect={selectRect}
          rectOptionInit={rectOptionInit}
          setModalStatus={setModalStatus}
          setEditValue={setEditValue}
        />
      )}
      {AddModalEnum.ADD_STATUS === modalStatus && (
        <div className='link-add-modal' style={{ width: 'auto', maxWidth: 'none' }}>
          <div
            className='link-add-modal__links-title2'
            onClick={() => {
              setModalStatus(AddModalEnum.DEFAULT_STATUS);
            }}
          >
            <ArrowDawnIcon
              color='#303030'
              width='12px'
              height='12px'
              className='link-add-modal__links-arrow'
            />
            Задача
          </div>

          <SelectStateController
            filterLinks={filterLinks}
            initValue={null}
            setSelectValue={setSelectValue}
            rectOptions={rectOptions}
            setSelectRect={setSelectRect}
            selectRect={selectRect}
            initRect={null}
            selectValue={selectValue}
          />
          <div className='mt30'>
            <Button
              text={buttonText}
              width='100%'
              height='25px'
              onClick={() => {
                handleSubmit();
              }}
            />
          </div>
        </div>
      )}
      {AddModalEnum.EDIT_STATUS === modalStatus && (
        <div className='link-add-modal' style={{ width: 'auto', maxWidth: 'none' }}>
          <div
            className='link-add-modal__links-title2'
            onClick={() => {
              setModalStatus(AddModalEnum.DEFAULT_STATUS);
            }}
          >
            <ArrowDawnIcon
              color='#303030'
              width='12px'
              height='12px'
              className='link-add-modal__links-arrow'
            />
            Задача [{editValue.label}]
          </div>

          <SelectStateController
            filterLinks={filterLinks}
            initValue={editValue}
            setSelectValue={setSelectValue}
            selectValue={selectValue}
            rectOptions={rectOptions}
            setSelectRect={setSelectRect}
            selectRect={selectRect}
            initRect={
              selectRect
                .map((e: any) => {
                  if (e?.taskId === editValue?.value) {
                    return rectOptionInit?.find((option: any) => {
                      if (option?.value === e?.rectID) return option;
                    });
                  }
                })
                .filter((e: any) => !!e)[0]
            }
          />
          <div className='mt30'>
            <Button
              text={buttonText}
              width='100%'
              height='25px'
              onClick={() => {
                handleSubmit();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const customFindIndex = ({ array, key = 'value', value }: any) => {
  return array?.map((x: any) => x[key])?.indexOf(value);
};

type selectValueType = {
  value: number;
  label: string;
};
type selectRectType = {
  taskId: number;
  rectID: number;
};

type useFilterLinkType = {
  links: any;
  initialValues: any;
  rect: any;
};

const useFilterLink = ({ links, initialValues, rect }: useFilterLinkType) => {
  const [linksData] = useState<any[]>([
    ...(links?.map((link: any) => {
      return {
        value: link?.id,
        label: link?.code + ' ' + link?.name || 'Нет имени',
      };
    }) as any),
  ]);
  const [selectValue, setSelectValue] = useState<[selectValueType] | any>(
    initialValues ? initialValues : [],
  );

  const rectOptionInit = rect?.map((e: RectType) => {
    return {
      value: e?.id,
      label: e?.name ? e?.nameArray + ' ' + e?.name : e?.nameArray,
    };
  });

  const selectRectInit = rect
    .map((e: RectType) => {
      if (e?.task?.id)
        return {
          taskId: e?.task?.id,
          rectID: e.id,
        };
    })
    .filter((e: any) => !!e);

  const [selectRect, setSelectRect] = useState<[selectRectType] | any>(
    // remove disconnect links
    selectRectInit
      .map((e: any) => {
        if (
          selectValue
            .map((e: any) => {
              return e.value;
            })
            .indexOf(e.taskId) === -1
        ) {
          return;
        }

        return e;
      })
      .filter((e: any) => !!e),
  );

  const [rectOptions, setRectOptions] = useState(rectOptionInit);

  const filterLinks = linksData
    .map((link) => {
      if (customFindIndex({ array: selectValue, value: link.value }) === -1) {
        return link;
      }
    })
    .filter(function (e) {
      return e;
    });

  useEffect(() => {
    const newRect = selectRect.map((e: selectRectType) => {
      return e.rectID;
    });
    const newOptions = rectOptionInit
      .map((options: any) => {
        if (newRect.indexOf(options?.value) === -1) return options;
      })
      .filter((e: any) => !!e);
    setRectOptions(newOptions);
  }, [selectRect]);

  return {
    linksData,
    selectValue,
    setSelectValue,
    rectOptionInit,
    selectRectInit,
    selectRect,
    setSelectRect,
    rectOptions,
    setRectOptions,
    filterLinks,
  };
};
