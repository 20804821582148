import React, { useRef } from 'react';
import { Group, Image, Rect, Text } from 'react-konva';
import useImage from 'use-image';

import {
  statisticAllTask,
  statisticDone,
  statisticProgress,
  statisticTree,
} from '../../../assets/icons';
import LinkIcon from '../../../assets/icons/link.svg';
import OptionsIcon from '../../../assets/icons/threeDots.svg';
import {
  polygonStatisticHeight,
  polygonStatisticWidth,
} from '../../../constants/constants';
import { AddArrowType, HoverRectIdType } from '../../../types/enums';
import { arrowToolsType, polygonToolsType, RectType } from '../../../types/types';

type RectangleType = {
  shapeProps: any;
  onSelect: () => void;
  onChange: (e: any) => void;
  rect: RectType;
  addArrow: AddArrowType;
  navigate: (e: string) => void;
  polygonTools: polygonToolsType;
  arrowTools: arrowToolsType;
  projectId: string;
  isAccess: boolean;
  polygonStatisticID?: string;
  hoverRectId: { id: string | number; type: HoverRectIdType };
  setHoverRectId: (value: { id: string | number; type: HoverRectIdType }) => void;
};

export const Rectangle = ({
  shapeProps,
  onSelect,
  onChange,
  rect,
  addArrow,
  navigate,
  polygonTools,
  arrowTools,
  projectId,
  isAccess,
  polygonStatisticID,
  setHoverRectId,
  hoverRectId,
}: RectangleType) => {
  const shapeRef = useRef<any>();

  const percentX = rect.width / 100;
  const percentY = rect.height / 100;

  const width = percentX * 50;
  const height = percentY * 50;

  const [optionsImage] = useImage(OptionsIcon);
  const [linkIcon] = useImage(LinkIcon);

  const draggable = () => {
    if (!isAccess) return;
    return addArrow === AddArrowType.NO ? true : false;
  };
  const showLink = !!rect?.parentLink;

  return (
    <Group
      style={{ position: 'relative' }}
      id={rect.id}
      onClick={onSelect}
      onTap={onSelect}
      ref={shapeRef}
      {...shapeProps}
      draggable={draggable()}
      onDragStart={(event) => {
        arrowTools.dragStart({ event, rect });
        polygonTools.dragStart();
      }}
      onDragEnd={(event) => {
        arrowTools.dragEnd();
        polygonTools.dragEnd({ event, onChange, shapeProps });
      }}
      onDragMove={(event: any) => {
        arrowTools.dragMove({ event });
      }}
      onDblClick={() => {
        if (rect?.parentLink !== 'new' && rect?.parentLink)
          polygonStatisticID
            ? navigate(`/polygon-single/${polygonStatisticID}/${rect?.parentLink}`)
            : navigate(`/polygon/${projectId}/${rect?.parentLink}`);
      }}
      onMouseEnter={() => {
        setHoverRectId({ id: rect.id, type: HoverRectIdType.RECT });
      }}
      onMouseLeave={() => {
        setHoverRectId({ id: 0, type: HoverRectIdType.NONE });
      }}
    >
      <React.Fragment>
        <Rect
          width={polygonStatisticWidth}
          height={polygonStatisticHeight}
          fill='white'
          onClick={onSelect}
          onTap={onSelect}
          stroke={
            hoverRectId.id === rect.id && hoverRectId.type === HoverRectIdType.DESCRIPTION
              ? '#9747FF'
              : '#d8d8d8'
          }
          cornerRadius={3}
          strokeWidth={2}
        />
        <Text
          x={30}
          y={30}
          width={width + 90}
          height={height}
          text={rect.name}
          fill='#303030'
          fontFamily='Roboto Condensed, sans-serif'
          fontSize={18}
          lineHeight={1.2}
          perfectDrawEnabled={false}
        />
        <Image x={polygonStatisticWidth - 25} y={30} image={optionsImage} />
        {rect?.statistics && showLink && <StatisticLine rect={rect} />}
        {rect?.statistics && showLink && <RectStatisticGroup rect={rect} />}

        {showLink && <Image image={linkIcon} x={rect?.width - 18} y={5} />}

        <Rect
          x={1}
          y={1}
          width={8}
          height={polygonStatisticHeight - 2}
          fill={rect?.fill}
          cornerRadius={[3, 0, 0, 3]}
        />
      </React.Fragment>
    </Group>
  );
};

type RectStatisticGroupType = {
  rect: RectType;
};

const RectStatisticGroup = ({ rect }: RectStatisticGroupType) => {
  const [statisticAllTaskImage] = useImage(statisticAllTask);
  const [statisticDoneImage] = useImage(statisticDone);
  const [statisticProgressImage] = useImage(statisticProgress);
  const [statisticTreeImage] = useImage(statisticTree);

  const statisticCoordinate = ({ init, count }: { init: number; count: number }) => {
    const steep = 80;
    return init + steep * count;
  };

  return (
    <>
      <Group
        x={statisticCoordinate({ init: 30, count: 0 })}
        y={polygonStatisticHeight - 50}
      >
        <Rect width={80} height={40} fill='#dcdcdc' cornerRadius={[3, 3, 3, 3]} />
        <Text text={'Всего задач'} fill='#4E4E4E' fontSize={12} x={6} y={4} />

        <Image image={statisticAllTaskImage} y={23} x={6} />
        <Text
          text={`${rect?.statistics?.tasksCount}`}
          y={24}
          x={30}
          fontSize={16}
          width={500}
        />
      </Group>
      <Group
        x={statisticCoordinate({ init: 30, count: 1 })}
        y={polygonStatisticHeight - 50}
      >
        <Rect width={80} height={40} fill='#F5F5F5' cornerRadius={[3, 3, 3, 3]} />
        <Text text={'Готово, %'} fill='#4E4E4E' fontSize={12} x={6} y={4} />

        <Image image={statisticDoneImage} y={23} x={6} />
        <Text
          text={`${rect?.statistics?.tasksDonePercentage}`}
          y={24}
          x={30}
          fontSize={16}
          width={500}
        />
      </Group>
      <Group
        x={statisticCoordinate({ init: 30, count: 2 })}
        y={polygonStatisticHeight - 50}
      >
        <Rect width={80} height={40} fill='#F5F5F5' cornerRadius={[3, 3, 3, 3]} />
        <Text text={'В работе, %'} fill='#4E4E4E' fontSize={12} x={6} y={4} />

        <Image image={statisticProgressImage} y={23} x={6} />
        <Text
          text={`${rect?.statistics?.tasksInProgressPercentage}`}
          y={24}
          x={30}
          fontSize={16}
          width={500}
        />
      </Group>
      <Group x={polygonStatisticWidth - 90} y={polygonStatisticHeight - 50}>
        <Rect width={80} height={40} />
        <Text text={'Древо'} fill='#4E4E4E' fontSize={12} x={40} y={4} />

        <Image image={statisticTreeImage} y={22} x={45} />
        <Text
          text={`${rect?.statistics?.treeCount}`}
          y={24}
          x={65}
          fontSize={16}
          width={500}
        />
      </Group>
    </>
  );
};

type StatisticLineType = {
  rect: RectType;
};

const StatisticLine = ({ rect }: StatisticLineType) => {
  const fullWidth = polygonStatisticWidth - 50;

  const greenWidth = rect?.statistics?.tasksDonePercentage
    ? (fullWidth / 100) * rect?.statistics?.tasksDonePercentage
    : 0;

  const yellowWidth = rect?.statistics?.tasksInProgressPercentage
    ? (fullWidth / 100) * rect?.statistics?.tasksInProgressPercentage
    : 0;

  return (
    <Group>
      <Rect
        x={30}
        y={polygonStatisticHeight / 2}
        width={greenWidth}
        height={6}
        fill={'#149939'}
      />
      <Rect
        x={30 + greenWidth}
        y={polygonStatisticHeight / 2}
        width={yellowWidth}
        height={6}
        fill={'#FBE000'}
      />
      <Rect
        x={30 + greenWidth + yellowWidth}
        y={polygonStatisticHeight / 2}
        width={fullWidth - (greenWidth + yellowWidth)}
        height={6}
        fill={'#a2a2a2'}
      />
    </Group>
  );
};
