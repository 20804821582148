import './StartButton.scss';

import React, { FC, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { closeStatusOptions, startStatusOptions } from '../../constants/constants';
import { useClickOutside } from '../../hooks/useClickOutside';
import {
  useCloseSubtaskMutation,
  useCloseTaskMutation,
  useStartSubtaskMutation,
  useStartTaskMutation,
} from '../../hooks/useMutations';
import { EntityActionsType } from '../entityActions/EntityActions';
import { DoneIcon, PlayIcon, RefreshIcon } from '../icons';
import { StartButtonOption } from './startButtonOption/StartButtonOption';

export const StartButton: FC<
  Pick<EntityActionsType, 'startDate' | 'closeDate' | 'entityId'> & {
    status: number;
    refetch: any;
  }
> = ({ entityId, startDate, closeDate, status, refetch }) => {
  const ref = useRef(null);
  const { subtaskId } = useParams();

  const [isStatusesListOpen, setIsStatusesListOpen] = useState(false);

  const handleCloseList = () => {
    setIsStatusesListOpen(false);
    refetch();
  };

  const handleOpenList = () => {
    if (!closeDate) {
      setIsStatusesListOpen(true);
    }
  };

  const [startTask] = useStartTaskMutation(handleCloseList);

  const [startSubtask] = useStartSubtaskMutation(handleCloseList);

  const [closeTask] = useCloseTaskMutation(handleCloseList);

  const [closeSubtask] = useCloseSubtaskMutation(handleCloseList);

  const onGetIconByStatus = () => {
    if (!startDate && !closeDate) return <PlayIcon width='9px' height='10px' />;
    if (startDate && !closeDate) return <RefreshIcon />;
    if (status === 2 || (!startDate && closeDate)) return <DoneIcon />;
  };

  const onGetStartButtonPlaceholder = () => {
    if (!startDate && !closeDate) return 'Старт';
    if (startDate && !closeDate) return 'Выполняется';
    if (status === 2 || (!startDate && closeDate)) return 'Завершена';
    return 'Выполняется';
  };

  const handleGetIconWithOption = (option: string) => {
    if (option === 'Старт') return <PlayIcon />;
    if (option === 'Выполняется') return <RefreshIcon />;
    if (option === 'Завершить') return <DoneIcon />;
  };

  const handleStart = async (status: string) => {
    if (entityId) {
      const startCloseVariables = { id: entityId };

      if (!subtaskId && status === 'Старт') {
        await startTask({
          variables: startCloseVariables,
        });
      }

      if (subtaskId && status === 'Старт') {
        await startSubtask({
          variables: startCloseVariables,
        });
      }

      if (!subtaskId && status === 'Завершить') {
        await closeTask({
          variables: startCloseVariables,
        });
      }

      if (subtaskId && status === 'Завершить') {
        await closeSubtask({
          variables: startCloseVariables,
        });
      }
    }
  };

  useClickOutside(ref, () => setIsStatusesListOpen(false));

  const getStatusesArray = () => {
    if (!startDate && !closeDate) return startStatusOptions;
    if (startDate && !closeDate) return closeStatusOptions;
  };

  return (
    <div className='start-button' onClick={handleOpenList}>
      <div className='start-button__dropdown' ref={ref}>
        <div className='start-button__placeholder'>
          {onGetIconByStatus()}
          <span>{onGetStartButtonPlaceholder()}</span>
        </div>
        <div
          className='start-button__options'
          style={!isStatusesListOpen ? { display: 'none' } : {}}
        >
          {getStatusesArray()?.map((option, index) => {
            return (
              <StartButtonOption
                key={index + option}
                option={option}
                handleGetIconWithOption={handleGetIconWithOption}
                handleStart={handleStart}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
