import { FormikValues } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  PoligonObject as PolygonObjectType,
  useCreatePoligonParentLinkMutation,
  useDeletePoligonObjectMutation,
  useDeletePoligonParentLinkMutation,
  useGetPoligonObjectQuery,
  useGetPoligonQuery,
  useGetProjectQuery,
  useUpsertPoligonColorMutation,
  useUpsertPoligonObjectMutation,
} from '../graphql/generated/graphql';
import { useGetProjectLightQuery } from '../hooks/useQueries';
import { polygonToolsType, RectType } from '../types/types';
import { transformColorsPalleteBeforeSend } from './useApiPolygonStatistic';
import { useCheckAccessEditPolygon } from './useCheckAccessEditPolygon';

type useApiPolygonObjectType = {
  setColorsData: (value: any) => void;
  rectangles: Array<RectType>;
  setRectangles: (value: Array<RectType>) => void;
  polygonTools: polygonToolsType;
  selectedIdRect: string | number | null;
};

export const useApiPolygonObject = ({
  setColorsData,
  rectangles,
  setRectangles,
}: useApiPolygonObjectType) => {
  const { projectId, polygonStatisticID, polygonId } = useParams();
  const navigate = useNavigate();

  type newPolygonIdType = {
    poligonId: number;
    prodjectId: number;
  };

  const [newPolygonId, setNewPolygonId] = useState<newPolygonIdType | null>(null);

  useEffect(() => {
    if (newPolygonId?.poligonId) {
      onSaveButton();
    }
  }, [newPolygonId]);

  const [polygonObject, setPolygonObject] = useState<PolygonObjectType>();
  const [updateLink, setUpdateLink] = useState<any>([]);
  const [deleteLink, setDeleteLink] = useState<any>([]);
  const [flagChange, setFlagChange] = useState(false);

  useEffect(() => {
    if (polygonObject?.name && flagChange) {
      onSaveButton();
      setFlagChange(false);
    }
  }, [polygonObject?.name]);

  useEffect(() => {
    if (flagChange) {
      onSaveButton();
      setFlagChange(false);
    }
  }, [polygonObject?.task?.length]);

  const { data, refetch: refetchTask } = useGetProjectLightQuery({ skip: !projectId });

  const tasks = data?.getProject?.tasksSubtasks?.rows;

  const [deletePoligonObject] = useDeletePoligonObjectMutation({
    onCompleted: () => {
      navigate(-1);
      refetchPoligon();
    },
  });

  const { data: project } = useGetProjectQuery({
    variables: {
      id: parseInt(projectId as string),
      tasksData: {
        isTaskStorage: false,
        limit: 5,
      },
    },
    skip: !projectId,
  });

  const handleSetPolygonObject = ({
    key,
    value,
    key2 = '',
    value2,
  }: {
    key: string;
    value: any;
    key2?: string;
    value2?: any;
  }) => {
    // the current if location is needed to properly update the polygon
    if (key === 'task') {
      setFlagChange(true);
    }
    setPolygonObject(
      (prevState) =>
        ({
          ...prevState,
          [key]: value,
          [key2]: value2,
        } as any),
    );
    if (key === 'name') {
      setFlagChange(true);
    }
  };

  const handleDeletePoligonObject = (id: number) => {
    if (!checkAccess({})) return;
    deletePoligonObject({
      variables: {
        id: id,
      },
    });
  };

  const { data: poligonData, refetch: refetchPoligon } = useGetPoligonQuery({
    variables: {
      id: polygonStatisticID
        ? parseInt(polygonStatisticID)
        : (project?.getProject?.poligon?.id as number),
    },
    skip: !project?.getProject?.poligon?.id && !polygonStatisticID,
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      if (response?.getPoligon?.colors?.length) {
        setColorsData(
          response?.getPoligon?.colors?.map(({ id, name, color }) => ({
            id,
            name,
            color,
          })),
        );
      }
    },
  });

  const { refetch: refetchPoligonObject, loading: poligonObjectLoading } =
    useGetPoligonObjectQuery({
      variables: {
        subtasksData: {},
        id: parseInt(polygonId as string),
      },
      fetchPolicy: 'network-only',
      onCompleted: (response) => {
        const data = response?.getPoligonObject;
        if (!data) return;
        setPolygonObject(data as PolygonObjectType);
        if (data?.rectangles) {
          setRectangles(data?.rectangles as any);
        }
      },
      onError: () => {
        toast.error('Полигон не существует');
        navigate(-1);
      },
    });

  const [upsertPoligonColorMutation] = useUpsertPoligonColorMutation();

  const poligon = poligonData?.getPoligon;

  const { checkAccess } = useCheckAccessEditPolygon({ poligon });

  const onSaveColorsPallete = (values: FormikValues) => {
    const data = transformColorsPalleteBeforeSend(values, poligon?.id);
    if (!checkAccess({})) return;
    upsertPoligonColorMutation({
      variables: {
        data: data,
      },
      onCompleted: () => {
        setColorsData(values);
      },
    });
  };

  const [upsertPoligonObjectMutation] = useUpsertPoligonObjectMutation({
    onError: () => {
      toast.error('Полигон не сохранен');
    },
  });

  const [createPoligonParentLinkMutation] = useCreatePoligonParentLinkMutation();
  const [deletePoligonParentLinkMutation] = useDeletePoligonParentLinkMutation();

  const onSaveButton = () => {
    if (!checkAccess({})) return;

    rectangles.map((rect: RectType) => {
      if (rect?.parentLinkUpdate) {
        // id of new and updated may not match
        if (rect?.parentLink || rect?.parentName) {
          setUpdateLink(updateLink?.push(`${rect?.id}`));
        }
        if (rect?.deletedLink) {
          setDeleteLink(
            deleteLink?.push({ rectID: `${rect?.id}`, deleteLink: rect?.deletedLink }),
          );
        }
      }
    });

    upsertPoligonObjectMutation({
      variables: {
        data: {
          id: polygonObject?.id as number,
          name: polygonObject?.name ? polygonObject?.name : 'Без имени',
          description: polygonObject?.description,
          poligonId: newPolygonId ? newPolygonId?.poligonId : poligon?.id,
          canvasData: {
            rectangles: rectangles.map((e: RectType) => {
              return {
                ...e,
                id: typeof e.id === 'string' ? undefined : e.id,
                isStatus: e.isStatus === 'Delete' ? 'Delete' : 'Upsert',
                taskLink: e?.taskLink?.map((link: any) => {
                  return {
                    taskId: link.taskId,
                    subTaskId: link.subTaskId,
                  };
                }),
                x: Math.round(e.x),
                y: Math.round(e.y),
                width: Math.round(e.width),
                height: Math.round(e.height),
                fileId: e?.file?.id,
                frontendData: `${e.id}`,
                __typename: undefined,
                parentLinkUpdate: undefined,
                deletedLink: undefined,
                file: undefined,
                task: undefined,
                taskId: e?.task?.id ? e?.task?.id : null,
                statistics: undefined,
              };
            }) as any,
          },
          // we impoverish if no manipulations have occurred
          // and subtasks are not gone
          tasksId: polygonObject?.task
            ?.map((e: any) => {
              return {
                taskId: e?.taskId || e?.id,
              };
            })
            ?.concat(
              polygonObject?.subtask?.map((e: any) => {
                return {
                  taskId: e?.taskId || e?.id,
                };
              }) as any,
            ),
        },
      },
    }).then((response) => {
      const rectangles = response?.data?.upsertPoligonObject?.rectangles;
      const promiseUpdate = rectangles
        ?.map((rect) => {
          if (updateLink?.includes(rect?.frontendData)) {
            return createPoligonParentLinkMutation({
              variables: {
                parentId: parseInt(rect?.parentLink as any),
                poligonObjectId: polygonObject?.id as number,
              },
            })
              .then((response) => {
                return response;
              })
              .catch((error) => error);
          }
        })
        ?.filter((e) => e);

      const promiseDelete = deleteLink?.map((e: any) => {
        return deletePoligonParentLinkMutation({
          variables: {
            parentId: e?.deleteLink,
            poligonObjectId: polygonObject?.id as number,
          },
        }).then((response) => {
          return response;
        });
      });

      Promise.all(promiseUpdate?.concat(promiseDelete) as any)
        .then(() => {
          refetchPoligonObject();
          if (projectId) {
            refetchTask();
            setUpdateLink([]);
            setDeleteLink([]);
          }
          refetchPoligon();
          toast.success('Полигон сохранен');
          if (newPolygonId?.poligonId) {
            navigate(`/polygon/${newPolygonId?.prodjectId}/${polygonObject?.id}`);
          }
        })
        .catch(() => {
          toast.error('Что то пошло не так');
          if (projectId) {
            setUpdateLink([]);
            setDeleteLink([]);
          }
        });
    });
  };

  const handleSetNewPolygonId = (value: newPolygonIdType | null) => {
    setNewPolygonId(value);
    onSaveButton();
  };

  return {
    onSaveColorsPallete,
    polygonObject,
    onSaveButton,
    handleSetPolygonObject,
    poligon,
    handleDeletePoligonObject,
    poligonObjectLoading,
    tasks,
    handleSetNewPolygonId,
  };
};
