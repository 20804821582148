import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type ActivityInChatType = {
  __typename?: 'ActivityInChatType';
  chat: Chat;
  status: Scalars['Boolean'];
};

export type AddNewItemToThreadStorageType = {
  __typename?: 'AddNewItemToThreadStorageType';
  status: Scalars['Boolean'];
  threadId: Scalars['Int'];
};

export type AddPerformersToThread = {
  threadId: Scalars['Int'];
  userId: Scalars['Int'];
};

export type ArchivedProject = ProjectInterface & {
  __typename?: 'ArchivedProject';
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  number?: Maybe<Scalars['Int']>;
  tasks: TasksList;
};

export type ArchivedProjectTasksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
};

export type ArchivedProjectListType = {
  __typename?: 'ArchivedProjectListType';
  count: Scalars['Int'];
  rows: Array<Maybe<ArchivedProject>>;
};

export type CalendarEntityListType = {
  __typename?: 'CalendarEntityListType';
  count: Scalars['Int'];
  rows: Array<Maybe<ProjectEntityType>>;
};

export type CalendarType = {
  __typename?: 'CalendarType';
  calendarProjects: CalendarEntityListType;
};

export type Chat = {
  __typename?: 'Chat';
  id: Scalars['Int'];
  isReadActivities: Scalars['Boolean'];
  isReadMessages: Scalars['Boolean'];
  logs: MessagesList;
  messagesList: MessagesList;
  name?: Maybe<Scalars['String']>;
  notes: Array<Maybe<Note>>;
  subtask?: Maybe<Subtask>;
  task?: Maybe<Task>;
  unreadMessagesCount: Scalars['Float'];
  usersList: GetAllUsers;
};

export type ChatLogsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ChatMessagesListArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ChatNotesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortOrder?: InputMaybe<SortingOrders>;
};

export type ChatUsersListArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ChatsList = {
  __typename?: 'ChatsList';
  count: Scalars['Int'];
  rows: Array<Maybe<Chat>>;
};

export type ChronographDay = {
  __typename?: 'ChronographDay';
  date: Scalars['String'];
  dayRecords?: Maybe<ChronographDayRecord>;
  hours: Array<ChronographHour>;
  statistic: ChronographDayStatistic;
};

export type ChronographDayRecord = {
  __typename?: 'ChronographDayRecord';
  cell1?: Maybe<Scalars['String']>;
  cell2?: Maybe<Scalars['String']>;
  cell3?: Maybe<Scalars['String']>;
  date: Scalars['String'];
};

export type ChronographDayStatScaleMapFile = {
  __typename?: 'ChronographDayStatScaleMapFile';
  color?: Maybe<Scalars['String']>;
  planOnToday?: Maybe<Scalars['Int']>;
  scaleMapFile: ScaleMapFile;
  scaleMapFileId: Scalars['Int'];
  scaleMapTask?: Maybe<ScaleMapTask>;
  scaleMapTaskId?: Maybe<Scalars['Int']>;
  timeTracked: Scalars['Int'];
};

export type ChronographDayStatistic = {
  __typename?: 'ChronographDayStatistic';
  negativeEnergyPercent: Scalars['Int'];
  positiveEnergyPercent: Scalars['Int'];
  scaleMapFilesTracked: Array<ChronographDayStatScaleMapFile>;
};

export type ChronographHour = {
  __typename?: 'ChronographHour';
  energy?: Maybe<Scalars['String']>;
  hour: Scalars['Int'];
  scaleMapFile?: Maybe<ScaleMapFile>;
  scaleMapFileId?: Maybe<Scalars['Int']>;
  scaleMapTask?: Maybe<ScaleMapTask>;
  scaleMapTaskId?: Maybe<Scalars['Int']>;
};

export type ChronographItemType = {
  __typename?: 'ChronographItemType';
  date: Scalars['DateTime'];
  scaleMapFile: ScaleMapFile;
  scaleMapFileId: Scalars['Int'];
  scaleMapTask?: Maybe<ScaleMapTask>;
  scaleMapTaskId?: Maybe<Scalars['Int']>;
};

export type ChronographReport = {
  __typename?: 'ChronographReport';
  date: Scalars['String'];
  id: Scalars['Int'];
  text: Scalars['String'];
  userId: Scalars['Int'];
};

export type ChronographSample = {
  __typename?: 'ChronographSample';
  author: User;
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  isPublic: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  sampleItems: Array<ChronographSampleItem>;
  updatedAt: Scalars['String'];
  userId: Scalars['Int'];
};

export type ChronographSampleItem = {
  __typename?: 'ChronographSampleItem';
  chronographSampleId: Scalars['Int'];
  color?: Maybe<Scalars['String']>;
  isDaily: Scalars['Boolean'];
  scaleMapFile: ScaleMapFile;
  scaleMapFileId: Scalars['Int'];
  scheduledTime?: Maybe<Scalars['Int']>;
  scheduledTimePerDay?: Maybe<Scalars['Int']>;
};

export type ChronographStatPlan = {
  __typename?: 'ChronographStatPlan';
  color?: Maybe<Scalars['String']>;
  isDaily: Scalars['Boolean'];
  scaleMapFile: ScaleMapFile;
  scaleMapFileId: Scalars['Int'];
  scheduledTime: Scalars['Int'];
  scheduledTotalTime?: Maybe<Scalars['Int']>;
  trackedTime?: Maybe<Scalars['Int']>;
  trackedTimePercent?: Maybe<Scalars['Int']>;
};

export type ChronographStatistic = {
  __typename?: 'ChronographStatistic';
  energyStatisticPerMonth: EnergyStatisticType;
  energyStatisticPerWeek: EnergyStatisticType;
  plan: Array<ChronographStatPlan>;
  statisticPerMonth: Array<StatisticPerTypeItem>;
  statisticPerWeek: Array<StatisticPerTypeItem>;
  trackedPercentPerMonth: Scalars['Float'];
  trackedPercentPerWeek: Scalars['Float'];
};

export type ChronographType = {
  __typename?: 'ChronographType';
  userId: Scalars['Int'];
  weeklyScheduled: ChronographWeeklyScheduledResponseType;
  weeklyScheduledItems: Array<ChronographWeeklyScheduledItemType>;
};

export type ChronographTypeWeeklyScheduledArgs = {
  data: GetWeeklyScheduledItemsInput;
};

export type ChronographTypeWeeklyScheduledItemsArgs = {
  data: GetWeeklyScheduledItemsInput;
};

export type ChronographWeeklyScheduledItemType = {
  __typename?: 'ChronographWeeklyScheduledItemType';
  scaleMapFile: ScaleMapFile;
  scheduledTime?: Maybe<Scalars['Int']>;
  spentTime?: Maybe<Scalars['Int']>;
  timeSpentPercentage: Scalars['Float'];
};

export type ChronographWeeklyScheduledResponseType = {
  __typename?: 'ChronographWeeklyScheduledResponseType';
  plannedTimeCompletionPercentage: Scalars['Float'];
  totalPlannedHours: Scalars['Int'];
};

export type CopyChronographPlanInput = {
  copyFrom: Scalars['DateTime'];
  copyTo: Scalars['DateTime'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type CreateChronographReportInput = {
  date: Scalars['DateTime'];
  text: Scalars['String'];
};

export type CreateChronographSampleItemInput = {
  chronographSampleId?: InputMaybe<Scalars['Int']>;
  color: Scalars['String'];
  isDaily: Scalars['Boolean'];
  scaleMapFile?: InputMaybe<CreateScaleMapFileInput>;
  scaleMapFileId?: InputMaybe<Scalars['Int']>;
  scheduledTime: Scalars['Int'];
  scheduledTimePerDay?: InputMaybe<Scalars['Int']>;
};

export type CreateDecideOnFlyActionInput = {
  chancePercent?: InputMaybe<Scalars['Float']>;
  color?: InputMaybe<Scalars['String']>;
  decideOnFlyPackageId?: InputMaybe<Scalars['Int']>;
  decideOnFlyPeriodId: Scalars['Int'];
  /** Example: "08:00:00" */
  hourFrom?: InputMaybe<Scalars['String']>;
  /** Example: "18:00:00" */
  hourTo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateDecideOnFlyPackageInput = {
  color?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

export type CreateForumMessageInput = {
  forumThreadId: Scalars['Int'];
  image?: InputMaybe<Scalars['Upload']>;
  quoteId?: InputMaybe<Scalars['Int']>;
  replyId?: InputMaybe<Scalars['Int']>;
  text: Scalars['String'];
  viewType?: InputMaybe<Scalars['Int']>;
};

export type CreateForumSection = {
  name: Scalars['String'];
  role?: InputMaybe<ForumSectionRoles>;
};

export type CreateForumThreadInput = {
  authorAvatar?: InputMaybe<Scalars['Upload']>;
  description: Scalars['String'];
  forumSectionId?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<Scalars['Upload']>;
  isAttached: Scalars['Boolean'];
  isClosed?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  viewType?: InputMaybe<Scalars['Int']>;
};

/** Input for create message */
export type CreateMessageInput = {
  chatId: Scalars['Float'];
  text: Scalars['String'];
};

/** Input load note */
export type CreateNoteInput = {
  chatId?: InputMaybe<Scalars['Int']>;
  content: Scalars['String'];
  subtaskId?: InputMaybe<Scalars['Int']>;
  taskId?: InputMaybe<Scalars['Int']>;
  threadId?: InputMaybe<Scalars['Int']>;
};

export type CreatePoligonTemplateInput = {
  canvasObject: Scalars['JSON'];
  name: Scalars['String'];
};

export type CreateProjectInput = {
  name: Scalars['String'];
  number?: InputMaybe<Scalars['Int']>;
};

export type CreateScaleMapFileInput = {
  attachedTagIds?: InputMaybe<Array<Scalars['Int']>>;
  calendarTaskTextColor?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  columnName1?: InputMaybe<Scalars['String']>;
  columnName2?: InputMaybe<Scalars['String']>;
  columnName3?: InputMaybe<Scalars['String']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isDaily?: InputMaybe<Scalars['Boolean']>;
  isPersonal?: InputMaybe<Scalars['Boolean']>;
  isStatus1ExcludedInFilter?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  projectId?: InputMaybe<Scalars['ID']>;
  scheduledTime?: InputMaybe<Scalars['Int']>;
  threadBackgroundId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type CreateScaleMapTaskInput = {
  attachedToUserId?: InputMaybe<Scalars['Int']>;
  calendarTaskTextColor?: InputMaybe<Scalars['String']>;
  column?: InputMaybe<Scalars['Int']>;
  counterComment?: InputMaybe<Scalars['String']>;
  counterFavorites?: InputMaybe<Scalars['Boolean']>;
  isExclamationPoint?: InputMaybe<Scalars['Boolean']>;
  isMain?: InputMaybe<Scalars['Boolean']>;
  isUrgently?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  parentScaleMapTaskId?: InputMaybe<Scalars['Boolean']>;
  row?: InputMaybe<Scalars['Int']>;
  scaleMapFileId?: InputMaybe<Scalars['Int']>;
  scaleMapTaskId?: InputMaybe<Scalars['Int']>;
  scaleMapTaskMarkStatusId?: InputMaybe<Scalars['Int']>;
  statusId?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<Array<Scalars['Int']>>;
  thread?: InputMaybe<CreateThread>;
};

export type CreateSocialCircleObjectInput = {
  colorToDisplay?: InputMaybe<Scalars['String']>;
  letter1?: InputMaybe<Scalars['String']>;
  letter2?: InputMaybe<Scalars['String']>;
  letter3?: InputMaybe<Scalars['String']>;
  letter4?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  strongPoints?: InputMaybe<Scalars['String']>;
  usefulnessDescription?: InputMaybe<Scalars['String']>;
  weakPoints?: InputMaybe<Scalars['String']>;
  whoIs?: InputMaybe<Scalars['String']>;
};

/** Input for create status */
export type CreateStatusInput = {
  color: Scalars['String'];
};

export type CreateSubtaskInput = {
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  maintainerId?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  parentId: Scalars['Int'];
  statusId?: InputMaybe<Scalars['Int']>;
  tagsId?: InputMaybe<Array<Scalars['ID']>>;
};

export type CreateTagInput = {
  attachToProjects?: InputMaybe<Array<Scalars['Int']>>;
  attachToScaleMapFiles?: InputMaybe<Array<Scalars['Int']>>;
  color?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  textColor?: InputMaybe<Scalars['String']>;
  type?: TagType;
};

export type CreateTaskInput = {
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  maintainerId?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  projectId: Scalars['Int'];
  statusId?: InputMaybe<Scalars['Int']>;
  tagsId?: InputMaybe<Array<Scalars['ID']>>;
};

export type CreateThread = {
  avatar?: InputMaybe<Scalars['String']>;
  closeDate?: InputMaybe<Scalars['Date']>;
  cover?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  isCritical?: InputMaybe<Scalars['Boolean']>;
  mainPerformerId?: InputMaybe<Scalars['Int']>;
  parentScaleMapTaskId?: InputMaybe<Scalars['Int']>;
  performers?: InputMaybe<Array<Scalars['Int']>>;
  scaleMapTask?: InputMaybe<CreateScaleMapTaskInput>;
  scaleMapTaskForCalendarId?: InputMaybe<Scalars['Int']>;
  scaleMapTaskId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
  tagsIds?: InputMaybe<Array<Scalars['Int']>>;
  threadBackgroundId?: InputMaybe<Scalars['Int']>;
  userGroupId?: InputMaybe<Scalars['Int']>;
};

export type CreateThreadBackgroundInput = {
  attachToProjects?: InputMaybe<Array<Scalars['Int']>>;
  attachToScaleMapFiles?: InputMaybe<Array<Scalars['Int']>>;
  backgroundColor: Scalars['String'];
};

export type CreateUserCharacteristicInput = {
  color: Scalars['String'];
  text: Scalars['String'];
  userId: Scalars['Int'];
};

export type CreateUserGroupInput = {
  name: Scalars['String'];
  userIds: Array<Scalars['Int']>;
};

/** Input for create user */
export type CreateUserInput = {
  login: Scalars['String'];
  role: UserRole;
  tagsId?: InputMaybe<Array<Scalars['ID']>>;
};

export type DateRangeInput = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type DecideOnFlyAction = {
  __typename?: 'DecideOnFlyAction';
  authorId: Scalars['Int'];
  chancePercent: Scalars['Float'];
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  decideOnFlyPackage?: Maybe<DecideOnFlyPackage>;
  decideOnFlyPackageId?: Maybe<Scalars['Int']>;
  decideOnFlyPeriodId: Scalars['Int'];
  hourFrom?: Maybe<Scalars['String']>;
  hourTo?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isCustomPercent: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type DecideOnFlyPackage = {
  __typename?: 'DecideOnFlyPackage';
  color?: Maybe<Scalars['String']>;
  countDecideOnFlyActions: Scalars['Int'];
  createdAt: Scalars['String'];
  decideOnFlyActions: Array<DecideOnFlyAction>;
  decideOnFlyActionsSumPercent: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  isActiveInCurrentUser?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type DecideOnFlyPackageDecideOnFlyActionsSumPercentArgs = {
  timezoneOffset: Scalars['Int'];
};

export type DecideOnFlyPeriod = {
  __typename?: 'DecideOnFlyPeriod';
  decideOnFlyActions: Array<DecideOnFlyAction>;
  id: Scalars['Int'];
  isWeekend: Scalars['Boolean'];
  periodName: Scalars['String'];
};

export type DeletedMessageResponseType = {
  __typename?: 'DeletedMessageResponseType';
  chatId: Scalars['Int'];
  messageId: Scalars['Int'];
};

/** Input for edit user */
export type EditStatusUserInput = {
  accessibleProjectsStorageIds?: InputMaybe<Array<Scalars['Int']>>;
  fullName?: InputMaybe<Scalars['String']>;
  idUser: Scalars['ID'];
  image?: InputMaybe<Scalars['Upload']>;
  role?: InputMaybe<UserRole>;
  tagsId?: InputMaybe<Array<Scalars['ID']>>;
  theNote?: InputMaybe<Scalars['String']>;
};

/** Input for edit user */
export type EditUserInput = {
  fullName?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['Upload']>;
  login?: InputMaybe<Scalars['String']>;
  tagsId?: InputMaybe<Array<Scalars['ID']>>;
  theNote?: InputMaybe<Scalars['String']>;
};

export type EnergyStatisticType = {
  __typename?: 'EnergyStatisticType';
  negativeEnergyPercent: Scalars['Float'];
  negativeEnergyTime: Scalars['Int'];
  positiveEnergyPercent: Scalars['Float'];
  positiveEnergyTime: Scalars['Int'];
};

export type Expenses = {
  __typename?: 'Expenses';
  amountPerMonth: Scalars['Float'];
  amountPerYear: Scalars['Float'];
  projects?: Maybe<Array<Project>>;
};

export type ExpensesProjectsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
};

export type File = {
  __typename?: 'File';
  createdAt: Scalars['String'];
  fileName: Scalars['String'];
  hashName: Scalars['String'];
  id: Scalars['Int'];
  mimeType: Scalars['String'];
  path: Scalars['String'];
  previewUrl?: Maybe<Scalars['String']>;
  size: Scalars['Float'];
  subtask?: Maybe<Subtask>;
  task?: Maybe<Task>;
  thread?: Maybe<Thread>;
  threadFileWrap?: Maybe<ThreadFileWrap>;
  threadId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['String'];
  user?: Maybe<User>;
  userClickedAt?: Maybe<Scalars['String']>;
};

export type ForumMessage = {
  __typename?: 'ForumMessage';
  createdAt: Scalars['String'];
  forumThreadId: Scalars['Int'];
  id: Scalars['Int'];
  imagePath?: Maybe<Scalars['String']>;
  quote?: Maybe<ForumMessage>;
  quoteId?: Maybe<Scalars['Int']>;
  reply?: Maybe<ForumMessage>;
  replyId?: Maybe<Scalars['Int']>;
  text: Scalars['String'];
  updatedAt: Scalars['String'];
  user: User;
  userId: Scalars['Int'];
  viewType: Scalars['Int'];
};

export type ForumMessageList = {
  __typename?: 'ForumMessageList';
  count: Scalars['Int'];
  rows: Array<ForumMessage>;
};

export type ForumSection = {
  __typename?: 'ForumSection';
  createdAt: Scalars['String'];
  hashName: Scalars['String'];
  id: Scalars['Int'];
  isExistUnViewedForumThreads: Scalars['Boolean'];
  name: Scalars['String'];
  role?: Maybe<ForumSectionRoles>;
  threadCount: Scalars['Int'];
  updatedAt: Scalars['String'];
};

export type ForumSectionList = {
  __typename?: 'ForumSectionList';
  count: Scalars['Int'];
  rows: Array<ForumSection>;
};

export type ForumStatistics = {
  __typename?: 'ForumStatistics';
  messagesCount: Scalars['Int'];
  threadsCount: Scalars['Int'];
};

export type ForumThread = {
  __typename?: 'ForumThread';
  author: User;
  authorAvatarPath?: Maybe<Scalars['String']>;
  authorId: Scalars['Int'];
  createdAt: Scalars['String'];
  description: Scalars['String'];
  forumMessages: ForumMessageList;
  forumSectionId: Scalars['Int'];
  hashName: Scalars['String'];
  id: Scalars['Int'];
  imagePath?: Maybe<Scalars['String']>;
  isAttached: Scalars['Boolean'];
  isClosed: Scalars['Boolean'];
  isViewed?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt: Scalars['String'];
  viewType: Scalars['Int'];
};

export type ForumThreadForumMessagesArgs = {
  data?: InputMaybe<GetForumMessagesInput>;
};

export type ForumThreadList = {
  __typename?: 'ForumThreadList';
  count: Scalars['Int'];
  rows: Array<ForumThread>;
};

export type ForumUnion = ForumSection | ForumThread;

export type GetAllFiles = {
  __typename?: 'GetAllFiles';
  count: Scalars['Int'];
  rows: Array<File>;
};

export type GetAllUserCharacteristics = {
  __typename?: 'GetAllUserCharacteristics';
  count: Scalars['Int'];
  rows: Array<UserCharacteristic>;
};

export type GetAllUsers = {
  __typename?: 'GetAllUsers';
  count: Scalars['Int'];
  rows: Array<User>;
};

export type GetAnalytic = {
  __typename?: 'GetAnalytic';
  completed?: Maybe<Scalars['Int']>;
  deadlineDate?: Maybe<Scalars['DateTime']>;
  deadlineThrough?: Maybe<Scalars['Int']>;
  progress?: Maybe<Scalars['Int']>;
};

export type GetAnalyticThreadsType = {
  __typename?: 'GetAnalyticThreadsType';
  countThreadsInCalendar: Scalars['Int'];
  daysInactiveInThreadPanel?: Maybe<Scalars['Int']>;
  deadlineDate?: Maybe<Scalars['DateTime']>;
  deadlineThrough?: Maybe<Scalars['Int']>;
  threadsLastEdited: Array<Thread>;
  totalCreatedThreads: Scalars['Int'];
  userThreadCount: Scalars['Int'];
};

export type GetAndCountSocialCircleObjects = {
  __typename?: 'GetAndCountSocialCircleObjects';
  count: Scalars['Int'];
  rows: Array<SocialCircleObject>;
};

export type GetChatsInput = {
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type GetChronographDays = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type GetChronographDaysRecords = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type GetChronographDaysResponse = {
  __typename?: 'GetChronographDaysResponse';
  days: Array<ChronographDay>;
  isBlocked: Scalars['Boolean'];
  statistic: ChronographStatistic;
  uncompletedWeek?: Maybe<Scalars['String']>;
};

export type GetChronographItemsInput = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

/** Options get all files */
export type GetFileInput = {
  chatId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortType?: InputMaybe<Scalars['String']>;
  subtaskId?: InputMaybe<Scalars['Int']>;
  taskId?: InputMaybe<Scalars['Int']>;
};

export type GetForumMessagesInput = {
  forumThreadId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
};

export type GetForumSections = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type GetForumThreadSortInput = {
  field: SortingFieldForumThread;
  type: SortingOrders;
};

export type GetForumThreadsInput = {
  forumSectionId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<GetForumThreadSortInput>;
};

export type GetMyProjectsInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
  tagsId?: InputMaybe<Array<Scalars['ID']>>;
};

/** Input for get note by params */
export type GetNoteByParametersInput = {
  chatId?: InputMaybe<Scalars['Int']>;
  taskId?: InputMaybe<Scalars['Int']>;
};

export type GetNotesAndCount = {
  __typename?: 'GetNotesAndCount';
  count: Scalars['Int'];
  rows: Array<Note>;
};

export type GetPoligonObjectsInput = {
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
};

export type GetPoligonTemplateInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type GetPoligonsInput = {
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
};

export type GetProjectsInput = {
  archive?: InputMaybe<Scalars['Boolean']>;
  authorId?: InputMaybe<Scalars['Int']>;
  isArchivedScaleMapFiles?: InputMaybe<Scalars['Boolean']>;
  isExistScaleMapFiles?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
  sortOrder?: InputMaybe<SortingOrders>;
  storageFilter?: InputMaybe<Array<StorageFilterItems>>;
  storageSearch?: InputMaybe<Scalars['String']>;
  taskSubtaskPerformerId?: InputMaybe<Scalars['Int']>;
  tasksEndDateRange?: InputMaybe<DateRangeInput>;
  threadStorageFilter?: InputMaybe<Array<StorageFilterItems>>;
  threadStorageSearch?: InputMaybe<Scalars['String']>;
};

export type GetPulseMonitorResponseEntity = {
  __typename?: 'GetPulseMonitorResponseEntity';
  rows: Array<PulseMonitorItemEntity>;
};

export type GetRandomDecideOnFlyActionResponse = {
  __typename?: 'GetRandomDecideOnFlyActionResponse';
  currentUserAction?: Maybe<UserDecideOnFlyAction>;
  isAccessToGet: Scalars['Boolean'];
};

export type GetScaleMapFiles = {
  calendarDateRange?: InputMaybe<OnlyDateRangeInput>;
  projectId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type GetStorageResponseType = {
  __typename?: 'GetStorageResponseType';
  projects: ThreadProjectList;
};

export type GetSubtasksInput = {
  authorId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  parentId?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
  sortOrder?: InputMaybe<SortingOrders>;
  statusId?: InputMaybe<Scalars['Int']>;
};

export type GetTagsInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<SortTagsInput>;
  type: TagType;
};

export type GetTasksInput = {
  assigneeId?: InputMaybe<Scalars['Int']>;
  authorId?: InputMaybe<Scalars['Int']>;
  calendarDateRange?: InputMaybe<DateRangeInput>;
  endDateRange?: InputMaybe<DateRangeInput>;
  expensesDate?: InputMaybe<Scalars['String']>;
  isTaskStorage?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  performerId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
  sortOrder?: InputMaybe<SortingOrders>;
  statusId?: InputMaybe<Scalars['Int']>;
  tagsId?: InputMaybe<Array<Scalars['ID']>>;
};

export type GetTasksSubtasksInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
};

export type GetThreadAndCountInput = {
  all?: InputMaybe<Scalars['Boolean']>;
  isHideCompletedThreadsAfter24Hours?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  storageFilter?: InputMaybe<Array<StorageFilterItems>>;
  storageSearch?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type GetThreadStorageInput = {
  filter?: InputMaybe<Array<StorageFilterItems>>;
  projectLimit?: InputMaybe<Scalars['Int']>;
  projectOffset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Sort>;
  storageEntitiesLimit?: InputMaybe<Scalars['Int']>;
  storageEntitiesOffset?: InputMaybe<Scalars['Int']>;
};

export type GetThreadsAndCount = {
  __typename?: 'GetThreadsAndCount';
  count: Scalars['Int'];
  rows: Array<Thread>;
};

export type GetUserCharacteristicsInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type GetUserDecideOnFlyActionsHistoryEntity = {
  __typename?: 'GetUserDecideOnFlyActionsHistoryEntity';
  count: Scalars['Int'];
  doneCount: Scalars['Int'];
  notDoneCount: Scalars['Int'];
  rows: Array<UserDecideOnFlyAction>;
};

/** Get all users */
export type GetUsersInput = {
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortType?: InputMaybe<Scalars['String']>;
};

export type GetWeeklyScheduledItemsInput = {
  isAll?: InputMaybe<Scalars['Boolean']>;
  startOfPeriodDate: Scalars['DateTime'];
};

export type GlobalStatusResponseType = {
  __typename?: 'GlobalStatusResponseType';
  status: Scalars['Boolean'];
};

export type InputCanvasObjects = {
  arrows: Array<PoligonArrowType>;
  rectangles: Array<PoligonRectType>;
};

export type Karma = {
  __typename?: 'Karma';
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  isPlus: Scalars['Boolean'];
  message: Message;
  messageId: Scalars['Int'];
  updatedAt: Scalars['String'];
  user: User;
  userId: Scalars['Int'];
};

export type LinkType = {
  taskId?: InputMaybe<Scalars['Int']>;
};

export type LinkTypeModel = {
  __typename?: 'LinkTypeModel';
  taskId?: Maybe<Scalars['Int']>;
};

/** Input for create user */
export type LoadFileInput = {
  chatId?: InputMaybe<Scalars['Int']>;
  file: Scalars['Upload'];
  subtaskId?: InputMaybe<Scalars['Int']>;
  taskId?: InputMaybe<Scalars['Int']>;
  threadId?: InputMaybe<Scalars['Int']>;
};

/** Input for login user */
export type LoginUserInput = {
  link?: InputMaybe<Scalars['String']>;
  login: Scalars['String'];
  password: Scalars['String'];
  pushTokenInfo?: InputMaybe<PushTokenInput>;
};

/** Input for logout user */
export type LogoutUserInput = {
  pushToken: PushTokenInput;
};

export type Message = {
  __typename?: 'Message';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  isActivity?: Maybe<Scalars['Boolean']>;
  isDidYouRead?: Maybe<Scalars['Boolean']>;
  isExistLink?: Maybe<Scalars['Boolean']>;
  isPossibleSetKarma: Scalars['Boolean'];
  isRead?: Maybe<Scalars['Boolean']>;
  text: Scalars['String'];
  user: User;
};

export type MessagesList = {
  __typename?: 'MessagesList';
  count: Scalars['Int'];
  rows: Array<Maybe<Message>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addChronographSampleItem: Scalars['Boolean'];
  addPerformersToThread: Scalars['Boolean'];
  addPerformersToThreadFromUserGroup: Scalars['Boolean'];
  adminEditUser: StatusAndUser;
  assignUserToTask: Task;
  checkIsUnsubscribedAndSubscribeToThread: Scalars['Boolean'];
  clearMessagesFromChat: Scalars['Boolean'];
  closeSubtask: Subtask;
  closeTask: Task;
  copyChronographPlan: Scalars['Boolean'];
  createChronographReport: Scalars['Boolean'];
  createDecideOnFlyAction: DecideOnFlyAction;
  createDecideOnFlyPackage: DecideOnFlyPackage;
  createForumMessage: ForumMessage;
  createForumSection: ForumSection;
  createForumThread: ForumThread;
  createNote: Note;
  createPoligonParentLink: Scalars['Boolean'];
  createPoligonTemplate: PoligonTemplate;
  createPrivateChat?: Maybe<Chat>;
  createProject: Project;
  createScaleMapFile: ScaleMapFile;
  createScaleMapTask: ScaleMapTask;
  createSocialCircleObject: SocialCircleObject;
  createStatus: Status;
  createSubtask: Subtask;
  createTag: Tag;
  createTask: Task;
  createThread: Thread;
  createThreadBackground: Array<ThreadBackground>;
  createUser: Scalars['String'];
  createUserCharacteristic: UserCharacteristic;
  createUserGroup: UserGroup;
  deassignUserFromTask: Task;
  deleteChronographSampleItem: Scalars['Boolean'];
  deleteFile: StatusResponse;
  deleteMessage: Scalars['Boolean'];
  deletePoligon: Scalars['Boolean'];
  deletePoligonObject: Scalars['Boolean'];
  deletePoligonParentLink: Scalars['Boolean'];
  deletePoligonTemplate: Scalars['Boolean'];
  deleteProject: Scalars['Boolean'];
  deleteScaleMapFile: Scalars['Boolean'];
  deleteScaleMapTask: Scalars['Boolean'];
  deleteScaleMapTaskWithResponse: ScaleMapTask;
  deleteStorageItem: Scalars['Boolean'];
  deleteSubtask: Scalars['Boolean'];
  deleteTag: Scalars['Boolean'];
  deleteTask: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  deleteUserCharacteristic: Scalars['Boolean'];
  editUser: User;
  exitThreadPerformers: Scalars['Boolean'];
  generatePasswordResetTokenUser: Scalars['String'];
  getOrCreatePrivateChat?: Maybe<Chat>;
  getRandomDecideOnFlyAction: GetRandomDecideOnFlyActionResponse;
  loadFile: File;
  logout: Scalars['Boolean'];
  madeSocialCircleMeeting: SocialCircleObject;
  moveScaleMapTask: Scalars['Boolean'];
  moveScaleMapTaskToRow: Scalars['Boolean'];
  readMessages: GlobalStatusResponseType;
  readMessagesBefore: Scalars['Boolean'];
  readMessagesByChatId: GlobalStatusResponseType;
  readProjectActivities: Scalars['Boolean'];
  refreshPushToken: StatusResponse;
  removeDecideOnFlyAction: Scalars['Boolean'];
  removeDecideOnFlyPackage: Scalars['Boolean'];
  removeForumMessage: Scalars['Boolean'];
  removeForumSection: Scalars['Boolean'];
  removeForumThread: Scalars['Boolean'];
  removeMyAccount: Scalars['Boolean'];
  removeNote: Scalars['Boolean'];
  removePerformerFromThread: Scalars['Boolean'];
  removeSocialCircleObject: Scalars['Boolean'];
  removeThreadBackground: Scalars['Boolean'];
  removeUserGroup: Scalars['Boolean'];
  resetPassword: StatusResponse;
  resetPasswordByToken: StatusResponse;
  resetRealizationPrice: Scalars['Boolean'];
  sendMessage: Message;
  setChronographDayRecords: Scalars['Boolean'];
  setDecideOnFlyPackagesToUser: Array<DecideOnFlyPackage>;
  setKarma: Scalars['Boolean'];
  setPerformerStatus: Scalars['Boolean'];
  setStatusUserDecideOnFlyAction: Scalars['Boolean'];
  setUserAnalytic: Scalars['Boolean'];
  signIn: UserSignIn;
  startSubtask: Subtask;
  startTask: Task;
  subscribeToThread: Scalars['Boolean'];
  trackChronographTime: Scalars['Boolean'];
  trackStorageEventClicked: Scalars['Boolean'];
  unsubscribeFromThread: Scalars['Boolean'];
  updateChronographSample: ChronographSample;
  updateChronographSampleItem: Scalars['Boolean'];
  updateDecideOnFlyAction: DecideOnFlyAction;
  updateDecideOnFlyPackage: DecideOnFlyPackage;
  updateFile: File;
  updateForumMessage: ForumMessage;
  updateForumSection: ForumSection;
  updateForumThread: ForumThread;
  updateNote: Note;
  updateOrderScaleMapFiles: Scalars['Boolean'];
  updatePoligonTemplate: PoligonTemplate;
  updateProject: Project;
  updateScaleMapFile: ScaleMapFile;
  updateScaleMapTask: ScaleMapTask;
  updateSocialCircleObject: SocialCircleObject;
  updateStorageItem: StorageItem;
  updateSubtask: Subtask;
  updateTag: Tag;
  updateTask: Task;
  updateThread: Thread;
  updateThreadBackground: ThreadBackground;
  updateThreadFileComment: ThreadFileWrap;
  updateUserCharacteristic: UserCharacteristic;
  updateUserGroup: UserGroup;
  updateWeeklyScheduledItems: Scalars['Boolean'];
  upsertPoligon: Poligon;
  upsertPoligonColor: Scalars['Boolean'];
  upsertPoligonObject: PoligonObject;
};

export type MutationAddChronographSampleItemArgs = {
  data: CreateChronographSampleItemInput;
};

export type MutationAddPerformersToThreadArgs = {
  data: AddPerformersToThread;
};

export type MutationAddPerformersToThreadFromUserGroupArgs = {
  threadId: Scalars['Int'];
  userGroupId: Scalars['Int'];
};

export type MutationAdminEditUserArgs = {
  data: EditStatusUserInput;
};

export type MutationAssignUserToTaskArgs = {
  taskId: Scalars['Int'];
  userId: Scalars['Int'];
};

export type MutationCheckIsUnsubscribedAndSubscribeToThreadArgs = {
  threadId: Scalars['Int'];
};

export type MutationClearMessagesFromChatArgs = {
  chatId: Scalars['Int'];
};

export type MutationCloseSubtaskArgs = {
  id: Scalars['Int'];
};

export type MutationCloseTaskArgs = {
  id: Scalars['Int'];
};

export type MutationCopyChronographPlanArgs = {
  data: CopyChronographPlanInput;
};

export type MutationCreateChronographReportArgs = {
  data: CreateChronographReportInput;
};

export type MutationCreateDecideOnFlyActionArgs = {
  createDecideOnFlyActionInput: CreateDecideOnFlyActionInput;
};

export type MutationCreateDecideOnFlyPackageArgs = {
  createDecideOnFlyPackageInput: CreateDecideOnFlyPackageInput;
};

export type MutationCreateForumMessageArgs = {
  data: CreateForumMessageInput;
};

export type MutationCreateForumSectionArgs = {
  data: CreateForumSection;
};

export type MutationCreateForumThreadArgs = {
  data: CreateForumThreadInput;
};

export type MutationCreateNoteArgs = {
  data: CreateNoteInput;
};

export type MutationCreatePoligonParentLinkArgs = {
  parentId: Scalars['Int'];
  poligonObjectId?: InputMaybe<Scalars['Int']>;
};

export type MutationCreatePoligonTemplateArgs = {
  data: CreatePoligonTemplateInput;
};

export type MutationCreatePrivateChatArgs = {
  userId: Scalars['Float'];
};

export type MutationCreateProjectArgs = {
  data: CreateProjectInput;
};

export type MutationCreateScaleMapFileArgs = {
  input: CreateScaleMapFileInput;
};

export type MutationCreateScaleMapTaskArgs = {
  input: CreateScaleMapTaskInput;
};

export type MutationCreateSocialCircleObjectArgs = {
  createSocialCircleObjectInput: CreateSocialCircleObjectInput;
};

export type MutationCreateStatusArgs = {
  data: CreateStatusInput;
};

export type MutationCreateSubtaskArgs = {
  data: CreateSubtaskInput;
};

export type MutationCreateTagArgs = {
  data: CreateTagInput;
};

export type MutationCreateTaskArgs = {
  data: CreateTaskInput;
};

export type MutationCreateThreadArgs = {
  data: CreateThread;
};

export type MutationCreateThreadBackgroundArgs = {
  createThreadBackgroundInputs: Array<CreateThreadBackgroundInput>;
};

export type MutationCreateUserArgs = {
  data: CreateUserInput;
};

export type MutationCreateUserCharacteristicArgs = {
  data: CreateUserCharacteristicInput;
};

export type MutationCreateUserGroupArgs = {
  createUserGroupInput: CreateUserGroupInput;
};

export type MutationDeassignUserFromTaskArgs = {
  taskId: Scalars['Int'];
  userId: Scalars['Int'];
};

export type MutationDeleteChronographSampleItemArgs = {
  chronographSampleId: Scalars['Int'];
  scaleMapFileId: Scalars['Int'];
};

export type MutationDeleteFileArgs = {
  id: Scalars['Float'];
};

export type MutationDeleteMessageArgs = {
  messageId: Scalars['ID'];
};

export type MutationDeletePoligonArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
};

export type MutationDeletePoligonObjectArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
};

export type MutationDeletePoligonParentLinkArgs = {
  parentId: Scalars['Int'];
  poligonObjectId: Scalars['Int'];
};

export type MutationDeletePoligonTemplateArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
};

export type MutationDeleteProjectArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
};

export type MutationDeleteScaleMapFileArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteScaleMapTaskArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteScaleMapTaskWithResponseArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteStorageItemArgs = {
  storageItemId: Scalars['Int'];
};

export type MutationDeleteSubtaskArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteTagArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteTaskArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteUserCharacteristicArgs = {
  id: Scalars['Int'];
};

export type MutationEditUserArgs = {
  data: EditUserInput;
};

export type MutationExitThreadPerformersArgs = {
  threadId: Scalars['Int'];
};

export type MutationGeneratePasswordResetTokenUserArgs = {
  userId: Scalars['ID'];
};

export type MutationGetOrCreatePrivateChatArgs = {
  userId: Scalars['Float'];
};

export type MutationGetRandomDecideOnFlyActionArgs = {
  timezoneOffset: Scalars['Int'];
};

export type MutationLoadFileArgs = {
  data: LoadFileInput;
};

export type MutationLogoutArgs = {
  data: LogoutUserInput;
};

export type MutationMadeSocialCircleMeetingArgs = {
  socialCircleObjectId: Scalars['Int'];
};

export type MutationMoveScaleMapTaskArgs = {
  parentScaleMapTaskId: Scalars['Int'];
  scaleMapTaskId: Scalars['Int'];
};

export type MutationMoveScaleMapTaskToRowArgs = {
  id: Scalars['Int'];
  order: Scalars['Int'];
  row: Scalars['Int'];
};

export type MutationReadMessagesArgs = {
  messageIds: Array<Scalars['Int']>;
};

export type MutationReadMessagesBeforeArgs = {
  messageId: Array<Scalars['Int']>;
};

export type MutationReadMessagesByChatIdArgs = {
  chatId: Scalars['Int'];
  isActivity: Scalars['Boolean'];
};

export type MutationReadProjectActivitiesArgs = {
  subtaskId?: InputMaybe<Scalars['Int']>;
  taskId?: InputMaybe<Scalars['Int']>;
  threadId?: InputMaybe<Scalars['Int']>;
};

export type MutationRefreshPushTokenArgs = {
  data: PushTokenInput;
};

export type MutationRemoveDecideOnFlyActionArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveDecideOnFlyPackageArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveForumMessageArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveForumSectionArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveForumThreadArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveNoteArgs = {
  id: Scalars['Int'];
};

export type MutationRemovePerformerFromThreadArgs = {
  data: AddPerformersToThread;
};

export type MutationRemoveSocialCircleObjectArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveThreadBackgroundArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveUserGroupArgs = {
  id: Scalars['Int'];
};

export type MutationResetPasswordArgs = {
  data: ResetPasswordUser;
};

export type MutationResetPasswordByTokenArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};

export type MutationResetRealizationPriceArgs = {
  taskId: Scalars['Int'];
};

export type MutationSendMessageArgs = {
  data: CreateMessageInput;
};

export type MutationSetChronographDayRecordsArgs = {
  data: SetChronographDayRecords;
};

export type MutationSetDecideOnFlyPackagesToUserArgs = {
  packagesId: Array<Scalars['Int']>;
};

export type MutationSetKarmaArgs = {
  isPlus: Scalars['Boolean'];
  messageId: Scalars['ID'];
};

export type MutationSetPerformerStatusArgs = {
  data: PerformerStatus;
};

export type MutationSetStatusUserDecideOnFlyActionArgs = {
  reasonForNotDone?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
};

export type MutationSetUserAnalyticArgs = {
  isAnalytics: Scalars['Boolean'];
  userId: Scalars['Int'];
};

export type MutationSignInArgs = {
  data: LoginUserInput;
};

export type MutationStartSubtaskArgs = {
  id: Scalars['Int'];
};

export type MutationStartTaskArgs = {
  id: Scalars['Int'];
};

export type MutationSubscribeToThreadArgs = {
  threadId: Scalars['Int'];
};

export type MutationTrackChronographTimeArgs = {
  data: TrackChronographTimeInput;
};

export type MutationTrackStorageEventClickedArgs = {
  fileId?: InputMaybe<Scalars['Int']>;
  storageItemId?: InputMaybe<Scalars['Int']>;
  threadId?: InputMaybe<Scalars['Int']>;
};

export type MutationUnsubscribeFromThreadArgs = {
  threadId: Scalars['Int'];
};

export type MutationUpdateChronographSampleArgs = {
  input: UpdateChronographSampleInput;
};

export type MutationUpdateChronographSampleItemArgs = {
  data: UpdateChronographSampleItemInput;
};

export type MutationUpdateDecideOnFlyActionArgs = {
  updateDecideOnFlyActionInput: UpdateDecideOnFlyActionInput;
};

export type MutationUpdateDecideOnFlyPackageArgs = {
  updateDecideOnFlyPackageInput: UpdateDecideOnFlyPackageInput;
};

export type MutationUpdateFileArgs = {
  data: UpdateFileInput;
  id: Scalars['ID'];
};

export type MutationUpdateForumMessageArgs = {
  data: UpdateForumMessageInput;
};

export type MutationUpdateForumSectionArgs = {
  data: UpdateForumSection;
};

export type MutationUpdateForumThreadArgs = {
  data: UpdateForumThreadInput;
};

export type MutationUpdateNoteArgs = {
  data: UpdateNoteInput;
  id: Scalars['Int'];
};

export type MutationUpdateOrderScaleMapFilesArgs = {
  data: UpdateOrderScaleMapFiles;
};

export type MutationUpdatePoligonTemplateArgs = {
  data: UpdatePoligonTemplateInput;
  id: Scalars['Int'];
};

export type MutationUpdateProjectArgs = {
  data: UpdateProjectInput;
  id: Scalars['Int'];
};

export type MutationUpdateScaleMapFileArgs = {
  input: UpdateScaleMapFileInput;
};

export type MutationUpdateScaleMapTaskArgs = {
  input: UpdateScaleMapTaskInput;
};

export type MutationUpdateSocialCircleObjectArgs = {
  updateSocialCircleObjectInput: UpdateSocialCircleObjectInput;
};

export type MutationUpdateStorageItemArgs = {
  data: UpdateStorageItem;
};

export type MutationUpdateSubtaskArgs = {
  data: UpdateSubtaskInput;
  id: Scalars['Int'];
};

export type MutationUpdateTagArgs = {
  data: CreateTagInput;
  id: Scalars['Int'];
};

export type MutationUpdateTaskArgs = {
  data: UpdateTaskInput;
  id: Scalars['Int'];
};

export type MutationUpdateThreadArgs = {
  data: UpdateThread;
};

export type MutationUpdateThreadBackgroundArgs = {
  updateThreadBackgroundInput: UpdateThreadBackgroundInput;
};

export type MutationUpdateThreadFileCommentArgs = {
  data: UpdateThreadFileComment;
};

export type MutationUpdateUserCharacteristicArgs = {
  data: UpdateUserCharacteristicInput;
};

export type MutationUpdateUserGroupArgs = {
  updateUserGroupInput: UpdateUserGroupInput;
};

export type MutationUpdateWeeklyScheduledItemsArgs = {
  data: UpdateWeeklyScheduledItemsDataInput;
};

export type MutationUpsertPoligonArgs = {
  data: UpsertPoligonInput;
};

export type MutationUpsertPoligonColorArgs = {
  data: UpsertPoligonColorInput;
};

export type MutationUpsertPoligonObjectArgs = {
  data: UpsertPoligonObjectInput;
};

export type MyProject = {
  __typename?: 'MyProject';
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  myTaskSubTaskList: TasksList;
  name: Scalars['String'];
  number?: Maybe<Scalars['Int']>;
};

export type MyProjectMyTaskSubTaskListArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
  tagsId?: InputMaybe<Array<Scalars['ID']>>;
};

export type MyProjectsList = {
  __typename?: 'MyProjectsList';
  count: Scalars['Int'];
  rows: Array<Maybe<MyProject>>;
};

export type Note = {
  __typename?: 'Note';
  chat?: Maybe<Chat>;
  chatId?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  owner?: Maybe<User>;
  ownerId: Scalars['Int'];
  subtask?: Maybe<Subtask>;
  task?: Maybe<Task>;
  taskId?: Maybe<Scalars['Int']>;
  threadId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['String'];
};

export type OnlyDateRangeInput = {
  from?: InputMaybe<Scalars['Date']>;
  to?: InputMaybe<Scalars['Date']>;
};

export type PerformerStatus = {
  status: Scalars['String'];
  threadId: Scalars['Int'];
};

export type Poligon = {
  __typename?: 'Poligon';
  arrows?: Maybe<Array<PoligonArrow>>;
  colors?: Maybe<Array<PoligonColor>>;
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  objects?: Maybe<Array<PoligonObject>>;
  owner: User;
  project?: Maybe<Project>;
  rectangles?: Maybe<Array<PoligonRect>>;
  updatedAt: Scalars['String'];
};

export type PoligonArrow = {
  __typename?: 'PoligonArrow';
  createdAt: Scalars['String'];
  endX: Scalars['Int'];
  endY: Scalars['Int'];
  fill: Scalars['String'];
  id: Scalars['Int'];
  isStatus: Scalars['String'];
  poligon?: Maybe<Poligon>;
  startX: Scalars['Int'];
  startY: Scalars['Int'];
  updatedAt: Scalars['String'];
};

export type PoligonArrowType = {
  endX: Scalars['Int'];
  endY: Scalars['Int'];
  fill: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  isStatus: IsStatus;
  startX: Scalars['Int'];
  startY: Scalars['Int'];
};

export type PoligonColor = {
  __typename?: 'PoligonColor';
  color: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  poligon: Poligon;
  updatedAt: Scalars['String'];
};

export type PoligonList = {
  __typename?: 'PoligonList';
  count: Scalars['Int'];
  rows: Array<Poligon>;
};

export type PoligonObject = {
  __typename?: 'PoligonObject';
  createdAt: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  parent?: Maybe<Array<PoligonObject>>;
  poligon?: Maybe<Poligon>;
  rectangles?: Maybe<Array<PoligonRect>>;
  statistics: PoligonObjectStatistics;
  subtask?: Maybe<Array<Subtask>>;
  task?: Maybe<Array<Task>>;
  tasksSubtasks?: Maybe<TasksList>;
  updatedAt: Scalars['String'];
};

export type PoligonObjectTasksSubtasksArgs = {
  data?: InputMaybe<GetTasksSubtasksInput>;
};

export type PoligonObjectInputCanvasObjects = {
  rectangles: Array<PoligonRectType>;
};

export type PoligonObjectStatistics = {
  __typename?: 'PoligonObjectStatistics';
  tasksCount: Scalars['Int'];
  tasksDonePercentage: Scalars['Int'];
  tasksGreyPercentage: Scalars['Int'];
  tasksInProgressPercentage: Scalars['Int'];
  treeCount: Scalars['Int'];
};

export type PoligonObjectsList = {
  __typename?: 'PoligonObjectsList';
  count: Scalars['Int'];
  rows: Array<PoligonObject>;
};

export type PoligonReactStatistics = {
  __typename?: 'PoligonReactStatistics';
  tasksCount: Scalars['Int'];
  tasksDonePercentage: Scalars['Int'];
  tasksGreyPercentage: Scalars['Int'];
  tasksInProgressPercentage: Scalars['Int'];
  treeCount: Scalars['Int'];
};

export type PoligonRect = {
  __typename?: 'PoligonRect';
  alignName?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  draggable: Scalars['Boolean'];
  file?: Maybe<File>;
  fill: Scalars['String'];
  frontendData?: Maybe<Scalars['String']>;
  height: Scalars['Int'];
  id: Scalars['Int'];
  isStatus: Scalars['String'];
  name: Scalars['String'];
  nameArray: Array<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  parentLink?: Maybe<Scalars['Int']>;
  parentName?: Maybe<Scalars['String']>;
  poligon?: Maybe<Poligon>;
  poligonObject?: Maybe<PoligonObject>;
  statistics: PoligonReactStatistics;
  task?: Maybe<Task>;
  taskLink?: Maybe<LinkTypeModel>;
  text: Scalars['String'];
  textObj: Scalars['JSON'];
  updatedAt: Scalars['String'];
  width: Scalars['Int'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type PoligonRectType = {
  alignName?: InputMaybe<Scalars['String']>;
  draggable: Scalars['Boolean'];
  fileId?: InputMaybe<Scalars['Int']>;
  fill: Scalars['String'];
  frontendData?: InputMaybe<Scalars['String']>;
  height: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  isStatus: IsStatus;
  name: Scalars['String'];
  nameArray: Array<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  parentLink?: InputMaybe<Scalars['Int']>;
  parentName?: InputMaybe<Scalars['String']>;
  taskId?: InputMaybe<Scalars['Int']>;
  taskLink?: InputMaybe<Array<LinkType>>;
  text: Scalars['String'];
  textObj: TextObjType;
  width: Scalars['Int'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type PoligonTemplate = {
  __typename?: 'PoligonTemplate';
  canvasObject: Scalars['JSON'];
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type PoligonTemplateList = {
  __typename?: 'PoligonTemplateList';
  count: Scalars['Int'];
  rows: Array<PoligonTemplate>;
};

export type PrivateChatActivityType = {
  __typename?: 'PrivateChatActivityType';
  chatId?: Maybe<Scalars['Int']>;
  fileId?: Maybe<Scalars['Int']>;
  message?: Maybe<Message>;
  messageId?: Maybe<Scalars['Int']>;
  noteId?: Maybe<Scalars['Int']>;
  type: TrackActivityTypeEnum;
};

export type Project = ProjectInterface & {
  __typename?: 'Project';
  attachedTags: Array<Tag>;
  author?: Maybe<User>;
  calendarScaleMapTasks?: Maybe<Array<ScaleMapTask>>;
  createdAt: Scalars['String'];
  editor?: Maybe<User>;
  id: Scalars['Int'];
  isHaveAccessToProjectInStorage: Scalars['Boolean'];
  maintainer?: Maybe<User>;
  name: Scalars['String'];
  number?: Maybe<Scalars['Int']>;
  poligon?: Maybe<Poligon>;
  projectActivities: Array<Maybe<ProjectActivity>>;
  scaleMapFiles: Array<ScaleMapFile>;
  storageEntities: StorageResponse;
  tasks: TasksList;
  tasksSubtasks: TasksList;
  updatedAt: Scalars['String'];
};

export type ProjectProjectActivitiesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortOrder?: InputMaybe<SortingOrders>;
};

export type ProjectScaleMapFilesArgs = {
  data?: InputMaybe<ScaleMapFilesInput>;
};

export type ProjectStorageEntitiesArgs = {
  isOnlyFromThreads?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  storageFilter?: InputMaybe<Array<StorageFilterItems>>;
};

export type ProjectTasksArgs = {
  data: GetTasksInput;
};

export type ProjectTasksSubtasksArgs = {
  data: GetTasksInput;
  userId?: InputMaybe<Scalars['Int']>;
};

export type ProjectActivity = {
  __typename?: 'ProjectActivity';
  action: Scalars['String'];
  actioner: User;
  assignee?: Maybe<User>;
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  note?: Maybe<Note>;
  project?: Maybe<Project>;
  status?: Maybe<Status>;
  subtask?: Maybe<Subtask>;
  task?: Maybe<Task>;
  thread?: Maybe<Thread>;
};

export type ProjectEntityType = {
  __typename?: 'ProjectEntityType';
  id: Scalars['Int'];
  name: Scalars['String'];
  number?: Maybe<Scalars['Int']>;
  tasks?: Maybe<Array<Task>>;
};

export type ProjectInterface = {
  name: Scalars['String'];
  number?: Maybe<Scalars['Int']>;
};

export type ProjectsList = {
  __typename?: 'ProjectsList';
  count: Scalars['Int'];
  rows: Array<Maybe<Project>>;
};

export type PulseMonitorItemEntity = {
  __typename?: 'PulseMonitorItemEntity';
  scaleMapFile: ScaleMapFile;
  statisticByMonth: Array<PulseMonitorStatisticByMonthEntity>;
};

export type PulseMonitorStatisticByMonthEntity = {
  __typename?: 'PulseMonitorStatisticByMonthEntity';
  monthNumber: Scalars['Int'];
  percent: Scalars['Float'];
  year: Scalars['Int'];
};

export type PushTokenInput = {
  appPlatform: Scalars['String'];
  deviceId: Scalars['String'];
  token: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  chronograph: ChronographType;
  decideOnFlyAction: DecideOnFlyAction;
  decideOnFlyActions: Array<DecideOnFlyAction>;
  decideOnFlyPackage: DecideOnFlyPackage;
  decideOnFlyPackages: Array<DecideOnFlyPackage>;
  getAllFiles: GetAllFiles;
  getAllUsers: GetAllUsers;
  getAndCountSocialCircleObjects: GetAndCountSocialCircleObjects;
  getArchivedProjects: ArchivedProjectListType;
  getBadge: Scalars['Int'];
  getCalendar: CalendarType;
  getCalendarScaleMapTasksWithThreads: GetStorageResponseType;
  getChats: ChatsList;
  getChronographDays: GetChronographDaysResponse;
  getChronographDaysRecords: Array<ChronographDayRecord>;
  getChronographItems: Array<ChronographItemType>;
  getChronographSample: ChronographSample;
  getCurrentUser?: Maybe<User>;
  getDecideOnFlyPackagesForUser: Array<DecideOnFlyPackage>;
  getDecideOnFlyPeriods: Array<DecideOnFlyPeriod>;
  getExpenses: Expenses;
  getFileById: File;
  getForumMessage: ForumMessage;
  getForumMessages: ForumMessageList;
  getForumSections: ForumSectionList;
  getForumStatistics: ForumStatistics;
  getForumThread: ForumThread;
  getForumThreads: ForumThreadList;
  getMessages: MessagesList;
  getMyProjectsRoleMaintainer: MyProjectsList;
  getNoteById: Note;
  getNoteByParameters: Note;
  getPoligon: Poligon;
  getPoligonObject: PoligonObject;
  getPoligonObjects: PoligonObjectsList;
  getPoligonRect: PoligonRect;
  getPoligonTemplate: PoligonTemplate;
  getPoligonTemplates: PoligonTemplateList;
  getPoligons: PoligonList;
  getPrivateChat: Chat;
  getPrivateChats: GetAllUsers;
  getProject: Project;
  getProjectActivities: Array<ProjectActivity>;
  getProjects: ProjectsList;
  getScaleMapFile: ScaleMapFile;
  getScaleMapFiles: ScaleMapFileList;
  getScaleMapStatuses: Array<ScaleMapStatus>;
  getScaleMapTask: ScaleMapTask;
  getScaleMapTaskHistoryStatistics: Array<ScaleMapTaskHistoryDate>;
  getScaleMapTaskMarkStatuses: Array<ScaleMapTaskMarkStatus>;
  getServerTime?: Maybe<ServerTimeType>;
  getSocialCircleStatistics: SocialCircleStatisticsEntity;
  getStatuses: Array<Status>;
  getStorageEntities: StorageResponse;
  getSubtask: Subtask;
  getSubtasks: SubtasksList;
  getTags: TagList;
  getTagsByUser: Array<Tag>;
  getTask: Task;
  getTasks: TasksList;
  getTasksSubtasks: TasksList;
  getThread: Thread;
  getThreadStorage: GetStorageResponseType;
  getThreadStorageProject: ThreadProject;
  getThreadViewHistory: Array<Maybe<ViewHistory>>;
  getThreadsAndCount: GetThreadsAndCount;
  getThreadsAndCountByUser: GetThreadsAndCount;
  getUserAnalytic: User;
  getUserCharacteristics: GetAllUserCharacteristics;
  getUserDecideOnFlyActionsHistory: GetUserDecideOnFlyActionsHistoryEntity;
  getUserPrivateChats: ChatsList;
  getUserScaleMapThread: ScaleMapFile;
  getUsersAnalytic: GetAllUsers;
  getUsersThreadsAnalytic: GetAllUsers;
  loginUser: UserSignIn;
  pulseMonitor: GetPulseMonitorResponseEntity;
  search: SearchType;
  searchThreads: GetThreadsAndCount;
  threadBackground: ThreadBackground;
  threadBackgrounds: Array<ThreadBackground>;
  userById: User;
  userGroup: UserGroup;
  userGroups: Array<UserGroup>;
};

export type QueryDecideOnFlyActionArgs = {
  id: Scalars['Int'];
};

export type QueryDecideOnFlyPackageArgs = {
  id: Scalars['Int'];
};

export type QueryGetAllFilesArgs = {
  data: GetFileInput;
};

export type QueryGetAllUsersArgs = {
  input: GetUsersInput;
};

export type QueryGetArchivedProjectsArgs = {
  data: GetProjectsInput;
};

export type QueryGetCalendarArgs = {
  dateRange: OnlyDateRangeInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  projectSort?: InputMaybe<Sort>;
  taskLimit?: InputMaybe<Scalars['Int']>;
  taskOffset?: InputMaybe<Scalars['Int']>;
  taskSort?: InputMaybe<Sort>;
  taskSubtaskStatusId?: InputMaybe<Scalars['Int']>;
};

export type QueryGetCalendarScaleMapTasksWithThreadsArgs = {
  dateRange: OnlyDateRangeInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  projectSort?: InputMaybe<Sort>;
  scaleMapTaskStatusId?: InputMaybe<Array<Scalars['Int']>>;
};

export type QueryGetChatsArgs = {
  data: GetChatsInput;
};

export type QueryGetChronographDaysArgs = {
  data: GetChronographDays;
};

export type QueryGetChronographDaysRecordsArgs = {
  data: GetChronographDaysRecords;
};

export type QueryGetChronographItemsArgs = {
  data: GetChronographItemsInput;
};

export type QueryGetExpensesArgs = {
  expensesDate?: InputMaybe<Scalars['String']>;
};

export type QueryGetFileByIdArgs = {
  id: Scalars['Float'];
};

export type QueryGetForumMessageArgs = {
  id: Scalars['ID'];
};

export type QueryGetForumMessagesArgs = {
  data?: InputMaybe<GetForumMessagesInput>;
};

export type QueryGetForumSectionsArgs = {
  data?: InputMaybe<GetForumSections>;
};

export type QueryGetForumThreadArgs = {
  id: Scalars['ID'];
};

export type QueryGetForumThreadsArgs = {
  data?: InputMaybe<GetForumThreadsInput>;
};

export type QueryGetMessagesArgs = {
  chatId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryGetMyProjectsRoleMaintainerArgs = {
  data: GetMyProjectsInput;
};

export type QueryGetNoteByIdArgs = {
  id: Scalars['Float'];
};

export type QueryGetNoteByParametersArgs = {
  data: GetNoteByParametersInput;
};

export type QueryGetPoligonArgs = {
  id: Scalars['Int'];
};

export type QueryGetPoligonObjectArgs = {
  id: Scalars['Int'];
};

export type QueryGetPoligonObjectsArgs = {
  data?: InputMaybe<GetPoligonObjectsInput>;
};

export type QueryGetPoligonRectArgs = {
  id: Scalars['Int'];
};

export type QueryGetPoligonTemplateArgs = {
  id: Scalars['Int'];
};

export type QueryGetPoligonTemplatesArgs = {
  data: GetPoligonTemplateInput;
};

export type QueryGetPoligonsArgs = {
  data?: InputMaybe<GetPoligonsInput>;
};

export type QueryGetPrivateChatArgs = {
  userId: Scalars['Int'];
};

export type QueryGetPrivateChatsArgs = {
  data: GetChatsInput;
};

export type QueryGetProjectArgs = {
  id: Scalars['Int'];
};

export type QueryGetProjectActivitiesArgs = {
  sortOrder?: InputMaybe<SortingOrders>;
};

export type QueryGetProjectsArgs = {
  data: GetProjectsInput;
  userId?: InputMaybe<Scalars['Int']>;
};

export type QueryGetScaleMapFileArgs = {
  id: Scalars['Int'];
};

export type QueryGetScaleMapFilesArgs = {
  data: GetScaleMapFiles;
};

export type QueryGetScaleMapTaskArgs = {
  id: Scalars['Int'];
};

export type QueryGetScaleMapTaskHistoryStatisticsArgs = {
  date: Scalars['String'];
  userId?: InputMaybe<Scalars['Int']>;
};

export type QueryGetStorageEntitiesArgs = {
  isAttachedFromUsersThread?: InputMaybe<Scalars['Boolean']>;
  isFromProjectIdNullThreads?: InputMaybe<Scalars['Boolean']>;
  isOnlyFromThreads?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  projectId: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
  storageFilter?: InputMaybe<Array<StorageFilterItems>>;
};

export type QueryGetSubtaskArgs = {
  id: Scalars['Int'];
  type?: InputMaybe<Scalars['String']>;
};

export type QueryGetSubtasksArgs = {
  data: GetSubtasksInput;
};

export type QueryGetTagsArgs = {
  data?: InputMaybe<GetTagsInput>;
};

export type QueryGetTagsByUserArgs = {
  userId: Scalars['Int'];
};

export type QueryGetTaskArgs = {
  id: Scalars['Int'];
  type?: InputMaybe<Scalars['String']>;
};

export type QueryGetTasksArgs = {
  data: GetTasksInput;
};

export type QueryGetTasksSubtasksArgs = {
  data: GetTasksInput;
};

export type QueryGetThreadArgs = {
  id: Scalars['Int'];
};

export type QueryGetThreadStorageArgs = {
  data: GetThreadStorageInput;
};

export type QueryGetThreadStorageProjectArgs = {
  data: GetThreadStorageInput;
};

export type QueryGetThreadViewHistoryArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortOrder?: InputMaybe<SortingOrders>;
  tagsId?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryGetThreadsAndCountArgs = {
  data: GetThreadAndCountInput;
};

export type QueryGetThreadsAndCountByUserArgs = {
  data?: InputMaybe<GetThreadAndCountInput>;
};

export type QueryGetUserAnalyticArgs = {
  userId: Scalars['Int'];
};

export type QueryGetUserCharacteristicsArgs = {
  data?: InputMaybe<GetUserCharacteristicsInput>;
  userId: Scalars['Int'];
};

export type QueryGetUserDecideOnFlyActionsHistoryArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type QueryGetUserPrivateChatsArgs = {
  data?: InputMaybe<GetChatsInput>;
};

export type QueryLoginUserArgs = {
  data: LoginUserInput;
};

export type QueryPulseMonitorArgs = {
  year: Scalars['Int'];
};

export type QuerySearchArgs = {
  search: Scalars['String'];
};

export type QuerySearchThreadsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search: Scalars['String'];
};

export type QueryThreadBackgroundArgs = {
  id: Scalars['Int'];
};

export type QueryUserByIdArgs = {
  id: Scalars['Int'];
};

export type QueryUserGroupArgs = {
  id: Scalars['Int'];
};

export type ReadMessageResponse = {
  __typename?: 'ReadMessageResponse';
  chatId: Scalars['Int'];
  messageIds: Array<Scalars['Int']>;
  user: User;
};

/** Input for reset password user */
export type ResetPasswordUser = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type ScaleMapColumnStatisticsType = {
  __typename?: 'ScaleMapColumnStatisticsType';
  column: Scalars['Int'];
  row: Scalars['Int'];
  statusTasks?: Maybe<ScaleMapStatus>;
  statusTasksPercent?: Maybe<Scalars['Int']>;
};

export type ScaleMapFile = {
  __typename?: 'ScaleMapFile';
  attachedTags: Array<Tag>;
  author?: Maybe<User>;
  authorId?: Maybe<Scalars['Int']>;
  calendarTaskTextColor?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  columnName1: Scalars['String'];
  columnName2: Scalars['String'];
  columnName3: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  isBridgeOfSleep: Scalars['Boolean'];
  isDaily?: Maybe<Scalars['Boolean']>;
  isMainScaleMapTasks: Array<Maybe<ScaleMapTask>>;
  isPersonal: Scalars['Boolean'];
  isPublic: Scalars['Boolean'];
  isStatus1ExcludedInFilter?: Maybe<Scalars['Boolean']>;
  mergedColumnStatistics: Array<ScaleMapMergedColumnStatisticsType>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  project?: Maybe<Project>;
  scaleMapColumnStatistics: Array<ScaleMapColumnStatisticsType>;
  scaleMapTasks: Array<ScaleMapTask>;
  scheduledTime?: Maybe<Scalars['Int']>;
  statistics: ScaleMapFileStatistics;
  threadBackground?: Maybe<ThreadBackground>;
  updatedAt: Scalars['String'];
  user?: Maybe<User>;
};

export type ScaleMapFileMergedColumnStatisticsArgs = {
  statusId?: InputMaybe<Scalars['Int']>;
};

export type ScaleMapFileScaleMapColumnStatisticsArgs = {
  statusId?: InputMaybe<Scalars['Int']>;
};

export type ScaleMapFileScaleMapTasksArgs = {
  excludeStatusId?: InputMaybe<Scalars['Int']>;
  isExcludeNullStatusTasks?: InputMaybe<Scalars['Boolean']>;
  statusId?: InputMaybe<Scalars['Int']>;
};

export type ScaleMapFileList = {
  __typename?: 'ScaleMapFileList';
  count: Scalars['Int'];
  rows: Array<Maybe<ScaleMapFile>>;
};

export type ScaleMapFileStatistics = {
  __typename?: 'ScaleMapFileStatistics';
  completedTasksPercent: Scalars['Int'];
  inProgressTasksPercent: Scalars['Int'];
  isTasksCreatedToday: Scalars['Boolean'];
  isTasksUpdatedToday: Scalars['Boolean'];
  notInProgressTasksPercent: Scalars['Int'];
  problemTasksCount: Scalars['Int'];
  problemTasksPercent: Scalars['Int'];
  tasksCount: Scalars['Int'];
};

export type ScaleMapFilesInput = {
  isArchived: Scalars['Boolean'];
};

export type ScaleMapMergedColumnStatisticsType = {
  __typename?: 'ScaleMapMergedColumnStatisticsType';
  column?: Maybe<Scalars['Int']>;
  row: Scalars['Int'];
  statusTasks?: Maybe<ScaleMapStatus>;
  statusTasksPercent?: Maybe<Scalars['Int']>;
};

export type ScaleMapStatus = {
  __typename?: 'ScaleMapStatus';
  color: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type ScaleMapTask = {
  __typename?: 'ScaleMapTask';
  calendarTaskTextColor?: Maybe<Scalars['String']>;
  children?: Maybe<Array<ScaleMapTask>>;
  column?: Maybe<Scalars['Int']>;
  counterComment?: Maybe<Scalars['String']>;
  counterFavorites?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['String'];
  creationScaleMapStatusId?: Maybe<Scalars['Int']>;
  hashName: Scalars['String'];
  id: Scalars['Int'];
  isCurrent?: Maybe<Scalars['Boolean']>;
  isExclamationPoint?: Maybe<Scalars['Boolean']>;
  isMain?: Maybe<Scalars['Boolean']>;
  isNestingAllTasksWithCompleted?: Maybe<Scalars['Boolean']>;
  isUrgently?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  nestingTaskCount?: Maybe<Scalars['Int']>;
  order: Scalars['Int'];
  parentCalendarTaskTextColor?: Maybe<Scalars['String']>;
  parentTags: Array<Tag>;
  parentTree?: Maybe<Array<ScaleMapTask>>;
  row?: Maybe<Scalars['Int']>;
  scaleMapFile?: Maybe<ScaleMapFile>;
  scaleMapTaskId?: Maybe<Scalars['Int']>;
  scaleMapTaskMarkStatus?: Maybe<ScaleMapTaskMarkStatus>;
  scaleMapTaskMarkStatusId?: Maybe<Scalars['Int']>;
  statistics?: Maybe<ScaleMapTaskStatistics>;
  status?: Maybe<ScaleMapStatus>;
  statusUpdatedDate?: Maybe<Scalars['String']>;
  tags: Array<Tag>;
  thread?: Maybe<Thread>;
  updatedAt: Scalars['String'];
};

export type ScaleMapTaskHistoryDate = {
  __typename?: 'ScaleMapTaskHistoryDate';
  date: Scalars['String'];
  historyTypes: Array<ScaleMapTaskHistoryType>;
};

export type ScaleMapTaskHistoryStatItem = {
  __typename?: 'ScaleMapTaskHistoryStatItem';
  count: Scalars['Int'];
  scaleMapTasks: Array<ScaleMapTask>;
  status: ScaleMapStatus;
  statusId: Scalars['Int'];
};

export type ScaleMapTaskHistoryType = {
  __typename?: 'ScaleMapTaskHistoryType';
  items: Array<ScaleMapTaskHistoryStatItem>;
  type: Scalars['String'];
};

export type ScaleMapTaskMarkStatus = {
  __typename?: 'ScaleMapTaskMarkStatus';
  color: Scalars['String'];
  id: Scalars['Int'];
  title: Scalars['String'];
};

export type ScaleMapTaskStatistics = {
  __typename?: 'ScaleMapTaskStatistics';
  maxStatusInNestingTasks?: Maybe<ScaleMapStatus>;
  maxStatusInNestingTasksPercent?: Maybe<Scalars['Int']>;
};

export type SearchType = {
  __typename?: 'SearchType';
  forum: Array<Maybe<ForumUnion>>;
  storageEntities: Array<Maybe<StorageUnion>>;
  tasksSubtasks: Array<Maybe<Task>>;
  threads: Array<Maybe<Thread>>;
};

export type ServerTimeType = {
  __typename?: 'ServerTimeType';
  time: Scalars['String'];
  timeZone: Scalars['String'];
  timeZoneGMT: Scalars['String'];
};

export enum SessionTypeEnum {
  InPrivateChat = 'IN_PRIVATE_CHAT',
  InThread = 'IN_THREAD',
}

export type SetChronographDayRecords = {
  cell1?: InputMaybe<Scalars['String']>;
  cell2?: InputMaybe<Scalars['String']>;
  cell3?: InputMaybe<Scalars['String']>;
  date: Scalars['DateTime'];
};

export type SocialCircleObject = {
  __typename?: 'SocialCircleObject';
  authorId: Scalars['ID'];
  colorToDisplay?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  firstMeetingDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  lastMeetingDate?: Maybe<Scalars['DateTime']>;
  letter1?: Maybe<Scalars['String']>;
  letter2?: Maybe<Scalars['String']>;
  letter3?: Maybe<Scalars['String']>;
  letter4?: Maybe<Scalars['String']>;
  meetingCount: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  score: Scalars['Int'];
  strongPoints?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  usefulnessDescription?: Maybe<Scalars['String']>;
  weakPoints?: Maybe<Scalars['String']>;
  whoIs?: Maybe<Scalars['String']>;
};

export type SocialCircleStatisticsEntity = {
  __typename?: 'SocialCircleStatisticsEntity';
  daysSinceFirstMeetingCreation?: Maybe<Scalars['Int']>;
  totalAppointedMeetings: Scalars['Int'];
  totalObjectsInCircle: Scalars['Int'];
};

export type Sort = {
  field: SortingField;
  type: SortingOrders;
};

export enum SortTagsField {
  CreatedAt = 'createdAt',
  Name = 'name',
}

export type SortTagsInput = {
  field?: InputMaybe<SortTagsField>;
  type?: InputMaybe<SortingOrders>;
};

export enum SortingField {
  Activity = 'activity',
  ClickedAt = 'clickedAt',
  Code = 'code',
  CreatedAt = 'createdAt',
  Number = 'number',
  Order = 'order',
  Status = 'status',
  StatusId = 'statusId',
}

export enum SortingFieldForumThread {
  Attached = 'attached',
}

export enum SortingOrders {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type StatisticPerTask = {
  __typename?: 'StatisticPerTask';
  percent: Scalars['Float'];
  scaleMapFile: ScaleMapFile;
  scaleMapFileId: Scalars['Int'];
  scaleMapTask?: Maybe<ScaleMapTask>;
  scaleMapTaskId?: Maybe<Scalars['Int']>;
  timeTracked: Scalars['Int'];
};

export type StatisticPerTypeItem = {
  __typename?: 'StatisticPerTypeItem';
  groupByScaleMapTask: Array<Maybe<StatisticPerTask>>;
  percent: Scalars['Float'];
  scaleMapFile: ScaleMapFile;
  scaleMapFileId: Scalars['Int'];
  timeTracked: Scalars['Int'];
};

export type Status = {
  __typename?: 'Status';
  color: Scalars['String'];
  id: Scalars['Float'];
};

export type StatusAndUser = {
  __typename?: 'StatusAndUser';
  status: Scalars['String'];
  user: User;
};

export type StatusResponse = {
  __typename?: 'StatusResponse';
  status: Scalars['String'];
};

export type StorageItem = {
  __typename?: 'StorageItem';
  author: User;
  createdAt: Scalars['String'];
  hashName: Scalars['String'];
  href?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isAccess?: Maybe<Scalars['Boolean']>;
  mimeType: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  subtask?: Maybe<Subtask>;
  subtaskId?: Maybe<Scalars['Int']>;
  task?: Maybe<Task>;
  taskId?: Maybe<Scalars['Int']>;
  thread?: Maybe<Thread>;
  threadId?: Maybe<Scalars['Int']>;
  thumbnailPath?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  userClickedAt?: Maybe<Scalars['String']>;
};

export type StorageResponse = {
  __typename?: 'StorageResponse';
  count: Scalars['Int'];
  rows: Array<Maybe<StorageUnion>>;
};

export type StorageUnion = File | StorageItem;

export type Subscription = {
  __typename?: 'Subscription';
  activityInChat: ActivityInChatType;
  addedNewItemToThreadStorage: AddNewItemToThreadStorageType;
  deletedMessage: DeletedMessageResponseType;
  messagesReadBefore: ReadMessageResponse;
  newActivity: Scalars['String'];
  newMessage: Message;
  privateChatActivity: PrivateChatActivityType;
  setUserSession: Scalars['Boolean'];
  taskSubtaskActivity: TaskSubtaskActivityType;
};

export type SubscriptionActivityInChatArgs = {
  chatId: Scalars['Int'];
};

export type SubscriptionAddedNewItemToThreadStorageArgs = {
  threadId: Scalars['Int'];
};

export type SubscriptionDeletedMessageArgs = {
  chatId: Scalars['Int'];
};

export type SubscriptionMessagesReadBeforeArgs = {
  chatId: Scalars['Int'];
};

export type SubscriptionNewMessageArgs = {
  chatId: Scalars['Int'];
};

export type SubscriptionPrivateChatActivityArgs = {
  chatId: Scalars['Int'];
};

export type SubscriptionSetUserSessionArgs = {
  chatId?: InputMaybe<Scalars['Int']>;
  sessionType: SessionTypeEnum;
};

export type Subtask = {
  __typename?: 'Subtask';
  author?: Maybe<User>;
  chat: Chat;
  clickedAt?: Maybe<Scalars['String']>;
  closeDate?: Maybe<Scalars['Date']>;
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  daysAfterStartCount: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  editor?: Maybe<User>;
  endDate?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  maintainer?: Maybe<User>;
  maintainerComment?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notes: Array<Maybe<Note>>;
  number: Scalars['Int'];
  poligonObject?: Maybe<PoligonObject>;
  startDate?: Maybe<Scalars['Date']>;
  status?: Maybe<Status>;
  tags: TagList;
  task: Task;
  updatedAt: Scalars['String'];
};

export type SubtaskNotesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortOrder?: InputMaybe<SortingOrders>;
};

export type SubtaskTagsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type SubtasksList = {
  __typename?: 'SubtasksList';
  count: Scalars['Int'];
  rows: Array<Maybe<Subtask>>;
};

export type Tag = {
  __typename?: 'Tag';
  attachedToProjects?: Maybe<Array<Project>>;
  attachedToScaleMapFiles?: Maybe<Array<ScaleMapFile>>;
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  textColor?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
};

export type TagList = {
  __typename?: 'TagList';
  count: Scalars['Int'];
  rows: Array<Tag>;
};

export enum TagType {
  Attached = 'Attached',
  Task = 'Task',
  User = 'User',
}

export type Task = {
  __typename?: 'Task';
  assignees: Array<Maybe<User>>;
  author?: Maybe<User>;
  chat: Chat;
  clickedAt?: Maybe<Scalars['String']>;
  closeDate?: Maybe<Scalars['Date']>;
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  daysAfterStartCount: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  editor?: Maybe<User>;
  endDate?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  maintainer?: Maybe<User>;
  maintainerComment?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notes: Array<Maybe<Note>>;
  number: Scalars['Int'];
  parentId?: Maybe<Scalars['Float']>;
  poligonObject?: Maybe<PoligonObject>;
  project: Project;
  /** This value appears only when there is an argument 'expensesDate' */
  realizationPerMonthAmount?: Maybe<Scalars['Float']>;
  realizationPrice?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['Date']>;
  status?: Maybe<Status>;
  subtasks: Array<Maybe<Subtask>>;
  tags: TagList;
  updatedAt: Scalars['String'];
};

export type TaskAssigneesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type TaskNotesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortOrder?: InputMaybe<SortingOrders>;
};

export type TaskSubtasksArgs = {
  data: GetSubtasksInput;
};

export type TaskTagsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type TaskSubtaskActivityType = {
  __typename?: 'TaskSubtaskActivityType';
  subtask?: Maybe<Scalars['Int']>;
  task?: Maybe<Scalars['Int']>;
  type: TaskSubtaskEventTypes;
};

export enum TaskSubtaskEventTypes {
  AddCloseDate = 'ADD_CLOSE_DATE',
  AddStartDate = 'ADD_START_DATE',
  Deleted = 'DELETED',
  StatusChanged = 'STATUS_CHANGED',
  TaskClosed = 'TASK_CLOSED',
  TaskDeleted = 'TASK_DELETED',
  TaskStarted = 'TASK_STARTED',
  TaskUpdated = 'TASK_UPDATED',
}

export type TasksList = {
  __typename?: 'TasksList';
  count: Scalars['Int'];
  rows: Array<Maybe<Task>>;
};

export type TextObjType = {
  fontSize: Scalars['Int'];
  fontStyle: Scalars['String'];
  fontWeight: Scalars['String'];
  textAlign: Scalars['String'];
  textDecoration: Scalars['String'];
};

export type Thread = {
  __typename?: 'Thread';
  author: User;
  authorId: Scalars['Int'];
  avatar?: Maybe<Scalars['String']>;
  chat: Chat;
  closeDate?: Maybe<Scalars['Date']>;
  cover?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  endDate?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  isCritical: Scalars['Boolean'];
  isReadLogs: Scalars['Boolean'];
  isSubscriber: Scalars['Boolean'];
  mainPerformer?: Maybe<User>;
  mainPerformerId?: Maybe<Scalars['Int']>;
  meta: Array<Maybe<ScaleMapTask>>;
  notes: Array<Note>;
  notesAndCount: GetNotesAndCount;
  performerStatus?: Maybe<Scalars['String']>;
  performers: Array<ThreadPerformer>;
  scaleMapTask?: Maybe<ScaleMapTask>;
  scaleMapTaskForCalendar?: Maybe<ScaleMapTask>;
  scaleMapTaskForCalendarDate?: Maybe<Scalars['Date']>;
  scaleMapTaskForCalendarId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  statistic: ThreadStatistic;
  storageEntities: StorageResponse;
  storageFiles: StorageResponse;
  storageImages: StorageResponse;
  tags: Array<Maybe<Tag>>;
  threadBackground?: Maybe<ThreadBackground>;
  threadBackgroundId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['String'];
  userLastEdited?: Maybe<User>;
};

export type ThreadStorageEntitiesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  storageFilter: Array<StorageFilterItems>;
};

export type ThreadBackground = {
  __typename?: 'ThreadBackground';
  attachedToProjects?: Maybe<Array<Project>>;
  backgroundColor: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  updatedAt: Scalars['String'];
};

export type ThreadFileWrap = {
  __typename?: 'ThreadFileWrap';
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  orderNumber: Scalars['Int'];
  updatedAt: Scalars['String'];
};

export type ThreadPerformer = {
  __typename?: 'ThreadPerformer';
  status: Scalars['String'];
  threadId: Scalars['Int'];
  user: User;
  userId: Scalars['Int'];
};

export type ThreadProject = {
  __typename?: 'ThreadProject';
  calendarScaleMapTasks?: Maybe<Array<ScaleMapTask>>;
  name: Scalars['String'];
  storageEntities?: Maybe<StorageResponse>;
};

export type ThreadProjectList = {
  __typename?: 'ThreadProjectList';
  count: Scalars['Int'];
  rows: Array<Maybe<ThreadProjectUnion>>;
};

export type ThreadProjectUnion = Project | ThreadProject;

export type ThreadStatistic = {
  __typename?: 'ThreadStatistic';
  performerAcceptedPercent: Scalars['Int'];
};

export enum TrackActivityTypeEnum {
  ChatCleared = 'CHAT_CLEARED',
  DeletedMessage = 'DELETED_MESSAGE',
  FileAdded = 'FILE_ADDED',
  FileDeleted = 'FILE_DELETED',
  NewMessage = 'NEW_MESSAGE',
  NoteAdded = 'NOTE_ADDED',
  NoteDeleted = 'NOTE_DELETED',
}

export type TrackChronographTimeInput = {
  date: Scalars['Date'];
  energy?: InputMaybe<Scalars['String']>;
  hours: Array<Scalars['Int']>;
  scaleMapFileId?: InputMaybe<Scalars['Int']>;
  scaleMapTaskId?: InputMaybe<Scalars['Int']>;
};

export type UpdateChronographSampleInput = {
  id: Scalars['Int'];
  isPublic?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  sampleItems?: InputMaybe<Array<UpdateChronographSampleItemInput>>;
};

export type UpdateChronographSampleItemInput = {
  chronographSampleId?: InputMaybe<Scalars['Int']>;
  color?: InputMaybe<Scalars['String']>;
  isDaily?: InputMaybe<Scalars['Boolean']>;
  scaleMapFile?: InputMaybe<CreateScaleMapFileInput>;
  scaleMapFileId: Scalars['Int'];
  scheduledTime?: InputMaybe<Scalars['Int']>;
  scheduledTimePerDay?: InputMaybe<Scalars['Int']>;
};

export type UpdateDecideOnFlyActionInput = {
  chancePercent?: InputMaybe<Scalars['Float']>;
  color?: InputMaybe<Scalars['String']>;
  decideOnFlyPackageId?: InputMaybe<Scalars['Int']>;
  decideOnFlyPeriodId?: InputMaybe<Scalars['Int']>;
  /** Example: "08:00:00" */
  hourFrom?: InputMaybe<Scalars['String']>;
  /** Example: "18:00:00" */
  hourTo?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateDecideOnFlyPackageInput = {
  color?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateFileInput = {
  fileName?: InputMaybe<Scalars['String']>;
};

export type UpdateForumMessageInput = {
  forumThreadId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  image?: InputMaybe<Scalars['Upload']>;
  imagePath?: InputMaybe<Scalars['String']>;
  quoteId?: InputMaybe<Scalars['Int']>;
  replyId?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  viewType?: InputMaybe<Scalars['Int']>;
};

export type UpdateForumSection = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<ForumSectionRoles>;
};

export type UpdateForumThreadInput = {
  authorAvatar?: InputMaybe<Scalars['Upload']>;
  authorAvatarPath?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  forumSectionId?: InputMaybe<Scalars['ID']>;
  id: Scalars['Int'];
  image?: InputMaybe<Scalars['Upload']>;
  isAttached?: InputMaybe<Scalars['Boolean']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  viewType?: InputMaybe<Scalars['Int']>;
};

export type UpdateNoteInput = {
  content: Scalars['String'];
};

export type UpdateOrderScaleMapFiles = {
  items: Array<UpdateOrderScaleMapFilesItem>;
};

export type UpdateOrderScaleMapFilesItem = {
  order: Scalars['Int'];
  scaleMapFileId: Scalars['Int'];
};

export type UpdatePoligonTemplateInput = {
  canvasObject?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateProjectInput = {
  name?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['Int']>;
};

export type UpdateScaleMapFileInput = {
  attachedTagIds?: InputMaybe<Array<Scalars['Int']>>;
  calendarTaskTextColor?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  columnName1?: InputMaybe<Scalars['String']>;
  columnName2?: InputMaybe<Scalars['String']>;
  columnName3?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isBridgeOfSleep?: InputMaybe<Scalars['Boolean']>;
  isDaily?: InputMaybe<Scalars['Boolean']>;
  isPersonal?: InputMaybe<Scalars['Boolean']>;
  isStatus1ExcludedInFilter?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['ID']>;
  scheduledTime?: InputMaybe<Scalars['Int']>;
  threadBackgroundId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type UpdateScaleMapTaskInput = {
  calendarTaskTextColor?: InputMaybe<Scalars['String']>;
  counterComment?: InputMaybe<Scalars['String']>;
  counterFavorites?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  isExclamationPoint?: InputMaybe<Scalars['Boolean']>;
  isMain?: InputMaybe<Scalars['Boolean']>;
  isUrgently?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  scaleMapFileId?: InputMaybe<Scalars['Int']>;
  scaleMapTaskId?: InputMaybe<Scalars['Int']>;
  scaleMapTaskMarkStatusId?: InputMaybe<Scalars['Int']>;
  statusId?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<Array<Scalars['Int']>>;
};

export type UpdateSocialCircleObjectInput = {
  colorToDisplay?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  letter1?: InputMaybe<Scalars['String']>;
  letter2?: InputMaybe<Scalars['String']>;
  letter3?: InputMaybe<Scalars['String']>;
  letter4?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  strongPoints?: InputMaybe<Scalars['String']>;
  usefulnessDescription?: InputMaybe<Scalars['String']>;
  weakPoints?: InputMaybe<Scalars['String']>;
  whoIs?: InputMaybe<Scalars['String']>;
};

export type UpdateStorageItem = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type UpdateSubtaskInput = {
  closeDate?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  maintainerComment?: InputMaybe<Scalars['String']>;
  maintainerId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  statusId?: InputMaybe<Scalars['Int']>;
  tagsId?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateTaskInput = {
  closeDate?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  maintainerComment?: InputMaybe<Scalars['String']>;
  maintainerId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  realizationPrice?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['Date']>;
  statusId?: InputMaybe<Scalars['Int']>;
  tagsId?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateThread = {
  avatar?: InputMaybe<Scalars['String']>;
  closeDate?: InputMaybe<Scalars['Date']>;
  cover?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['Int'];
  isCritical?: InputMaybe<Scalars['Boolean']>;
  mainPerformerId?: InputMaybe<Scalars['Int']>;
  parentScaleMapTaskId?: InputMaybe<Scalars['Int']>;
  performers?: InputMaybe<Array<Scalars['Int']>>;
  scaleMapTask?: InputMaybe<CreateScaleMapTaskInput>;
  scaleMapTaskForCalendarId?: InputMaybe<Scalars['Int']>;
  scaleMapTaskId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
  tagsIds?: InputMaybe<Array<Scalars['Int']>>;
  threadBackgroundId?: InputMaybe<Scalars['Int']>;
  userGroupId?: InputMaybe<Scalars['Int']>;
};

export type UpdateThreadBackgroundInput = {
  attachToProjects?: InputMaybe<Array<Scalars['Int']>>;
  attachToScaleMapFiles?: InputMaybe<Array<Scalars['Int']>>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

export type UpdateThreadFileComment = {
  comment: Scalars['String'];
  id: Scalars['Int'];
};

export type UpdateUserCharacteristicInput = {
  color?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  text?: InputMaybe<Scalars['String']>;
};

export type UpdateUserGroupInput = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  userIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type UpdateWeeklyScheduledItemInput = {
  scaleMapFileId: Scalars['Int'];
  scheduledTime: Scalars['Int'];
};

export type UpdateWeeklyScheduledItemsDataInput = {
  items: Array<UpdateWeeklyScheduledItemInput>;
  startOfPeriodDate: Scalars['DateTime'];
};

export type UpsertPoligonColorInput = {
  color?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Array<Scalars['String']>>;
  poligonId: Scalars['Float'];
};

export type UpsertPoligonInput = {
  canvasData: InputCanvasObjects;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  projectId?: InputMaybe<Scalars['Int']>;
};

export type UpsertPoligonObjectInput = {
  canvasData?: InputMaybe<PoligonObjectInputCanvasObjects>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  poligonId?: InputMaybe<Scalars['Int']>;
  tasksId?: InputMaybe<Array<LinkType>>;
};

export type User = {
  __typename?: 'User';
  IsExistUnViewedForum: Scalars['Boolean'];
  accessibleProjectsStorage: Array<Project>;
  analytic?: Maybe<GetAnalytic>;
  analyticThreads?: Maybe<GetAnalyticThreadsType>;
  assignedTasks: Array<Maybe<Task>>;
  /** Use only to display private chats in a query getPrivateChats. Use the first element of the array.  */
  chats?: Maybe<Array<Chat>>;
  chatsList: ChatsList;
  chronographReports: Array<ChronographReport>;
  createdAt?: Maybe<Scalars['String']>;
  creator?: Maybe<User>;
  creatorId?: Maybe<Scalars['Int']>;
  currentUserDecideOnFlyActionData: GetRandomDecideOnFlyActionResponse;
  editDate?: Maybe<Scalars['String']>;
  forumMessageCount: Scalars['Int'];
  forumThreadAuthorCount: Scalars['Int'];
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  isAccessToStorageProjects: Scalars['Boolean'];
  isAnalytics: Scalars['Boolean'];
  karmaStatistics?: Maybe<UserKarmaStatistics>;
  lastConnect?: Maybe<Scalars['String']>;
  lastEditerUser?: Maybe<User>;
  login?: Maybe<Scalars['String']>;
  maintainedSubtasks: Array<Maybe<Subtask>>;
  maintainedTasks: Array<Maybe<Task>>;
  myTasksSubtasks: Array<Maybe<Task>>;
  notes?: Maybe<Array<Note>>;
  onlineStatus: Scalars['Boolean'];
  projects: Array<Maybe<Project>>;
  projectsWithCheckAccessToStorage: ProjectsList;
  registrationLink?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  scaleMapFiles: ScaleMapFileList;
  scaleMapTasksWithThreads: Array<ScaleMapTask>;
  selectedDecideOnFlyPackages: Array<DecideOnFlyPackage>;
  tags: TagList;
  taskCount?: Maybe<Scalars['Int']>;
  tasks: Array<Maybe<Task>>;
  theNote?: Maybe<Scalars['String']>;
  threads: GetThreadsAndCount;
  updatedAt?: Maybe<Scalars['String']>;
  userCharacteristics?: Maybe<GetAllUserCharacteristics>;
  viewHistory: Array<Maybe<ViewHistory>>;
};

export type UserAssignedTasksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type UserChatsListArgs = {
  data: GetChatsInput;
};

export type UserMaintainedSubtasksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type UserMaintainedTasksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type UserMyTasksSubtasksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  tagsId?: InputMaybe<Array<Scalars['ID']>>;
};

export type UserNotesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type UserScaleMapFilesArgs = {
  isArchived?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<Sort>;
};

export type UserTagsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type UserTaskCountArgs = {
  tagsId?: InputMaybe<Array<Scalars['ID']>>;
};

export type UserThreadsArgs = {
  data?: InputMaybe<GetThreadAndCountInput>;
};

export type UserUserCharacteristicsArgs = {
  data?: InputMaybe<GetUserCharacteristicsInput>;
};

export type UserViewHistoryArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortOrder?: InputMaybe<SortingOrders>;
  tagsId?: InputMaybe<Array<Scalars['ID']>>;
};

export type UserCharacteristic = {
  __typename?: 'UserCharacteristic';
  color: Scalars['String'];
  id: Scalars['Int'];
  text: Scalars['String'];
  user?: Maybe<User>;
};

export type UserDecideOnFlyAction = {
  __typename?: 'UserDecideOnFlyAction';
  createdAt: Scalars['String'];
  decideOnFlyAction?: Maybe<DecideOnFlyAction>;
  decideOnFlyActionId: Scalars['Int'];
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  reasonForNotDone?: Maybe<Scalars['String']>;
  selectionEndDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  updatedAt: Scalars['String'];
  userId: Scalars['Int'];
};

export type UserGroup = {
  __typename?: 'UserGroup';
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['String'];
  users: Array<User>;
};

export type UserKarmaStatistics = {
  __typename?: 'UserKarmaStatistics';
  items: Array<Karma>;
  karma: Scalars['Int'];
};

export enum UserRole {
  Admin = 'Admin',
  Guest = 'Guest',
  Moderator = 'Moderator',
  User = 'User',
}

export type UserSignIn = {
  __typename?: 'UserSignIn';
  token: Scalars['String'];
  user: User;
};

export type ViewHistory = {
  __typename?: 'ViewHistory';
  id: Scalars['Int'];
  subtask?: Maybe<Subtask>;
  task?: Maybe<Task>;
  thread?: Maybe<Thread>;
  user: User;
  viewedAt: Scalars['String'];
};

export enum ForumSectionRoles {
  Admin = 'ADMIN',
  AdminModerator = 'ADMIN_MODERATOR',
}

export enum IsStatus {
  Delete = 'Delete',
  Upsert = 'Upsert',
}

export enum StorageFilterItems {
  All = 'ALL',
  Doc = 'doc',
  Images = 'images',
  Pdf = 'pdf',
  Xls = 'xls',
}

export type ChatFragmentFragment = {
  __typename?: 'Chat';
  id: number;
  messagesList: {
    __typename?: 'MessagesList';
    count: number;
    rows: Array<{
      __typename?: 'Message';
      isRead?: boolean | null;
      isPossibleSetKarma: boolean;
      isActivity?: boolean | null;
      isDidYouRead?: boolean | null;
      id: number;
      text: string;
      createdAt: any;
      user: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
        createdAt?: string | null;
        karmaStatistics?: {
          __typename?: 'UserKarmaStatistics';
          karma: number;
          items: Array<{
            __typename?: 'Karma';
            id: number;
            createdAt: string;
            isPlus: boolean;
            messageId: number;
            user: { __typename?: 'User'; login?: string | null; image?: string | null };
          }>;
        } | null;
      };
    } | null>;
  };
  logs: {
    __typename?: 'MessagesList';
    count: number;
    rows: Array<{
      __typename?: 'Message';
      isRead?: boolean | null;
      isPossibleSetKarma: boolean;
      isActivity?: boolean | null;
      isDidYouRead?: boolean | null;
      id: number;
      text: string;
      createdAt: any;
      user: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
        createdAt?: string | null;
        karmaStatistics?: {
          __typename?: 'UserKarmaStatistics';
          karma: number;
          items: Array<{
            __typename?: 'Karma';
            id: number;
            createdAt: string;
            isPlus: boolean;
            messageId: number;
            user: { __typename?: 'User'; login?: string | null; image?: string | null };
          }>;
        } | null;
      };
    } | null>;
  };
};

export type MessageFragmentFragment = {
  __typename?: 'Message';
  id: number;
  text: string;
  createdAt: any;
  isRead?: boolean | null;
  isPossibleSetKarma: boolean;
  isActivity?: boolean | null;
  user: {
    __typename?: 'User';
    id?: number | null;
    login?: string | null;
    image?: string | null;
    createdAt?: string | null;
    karmaStatistics?: {
      __typename?: 'UserKarmaStatistics';
      karma: number;
      items: Array<{
        __typename?: 'Karma';
        id: number;
        createdAt: string;
        isPlus: boolean;
        messageId: number;
        user: { __typename?: 'User'; login?: string | null; image?: string | null };
      }>;
    } | null;
  };
};

export type PoligonRectFragmentFragment = {
  __typename?: 'PoligonRect';
  id: number;
  x: number;
  y: number;
  width: number;
  height: number;
  fill: string;
  text: string;
  textObj: any;
  name: string;
  draggable: boolean;
  nameArray: Array<string>;
  parentLink?: number | null;
  alignName?: string | null;
  frontendData?: string | null;
  taskLink?: { __typename?: 'LinkTypeModel'; taskId?: number | null } | null;
  file?: { __typename?: 'File'; id: number; path: string } | null;
  statistics: {
    __typename?: 'PoligonReactStatistics';
    tasksCount: number;
    tasksDonePercentage: number;
    tasksInProgressPercentage: number;
    tasksGreyPercentage: number;
    treeCount: number;
  };
};

export type AddChronographSampleItemMutationVariables = Exact<{
  data: CreateChronographSampleItemInput;
}>;

export type AddChronographSampleItemMutation = {
  __typename?: 'Mutation';
  addChronographSampleItem: boolean;
};

export type AddPerformersToThreadMutationVariables = Exact<{
  data: AddPerformersToThread;
}>;

export type AddPerformersToThreadMutation = {
  __typename?: 'Mutation';
  addPerformersToThread: boolean;
};

export type AdminEditUserMutationVariables = Exact<{
  data: EditStatusUserInput;
}>;

export type AdminEditUserMutation = {
  __typename?: 'Mutation';
  adminEditUser: { __typename?: 'StatusAndUser'; status: string };
};

export type AssignUserToTaskMutationVariables = Exact<{
  taskId: Scalars['Int'];
  userId: Scalars['Int'];
}>;

export type AssignUserToTaskMutation = {
  __typename?: 'Mutation';
  assignUserToTask: {
    __typename?: 'Task';
    id: number;
    createdAt: string;
    updatedAt: string;
    name: string;
    description?: string | null;
    project: { __typename?: 'Project'; id: number; name: string };
    assignees: Array<{
      __typename?: 'User';
      id?: number | null;
      fullName?: string | null;
      image?: string | null;
      login?: string | null;
    } | null>;
    status?: { __typename?: 'Status'; id: number; color: string } | null;
  };
};

export type CheckIsUnsubscribedAndSubscribeToThreadMutationVariables = Exact<{
  threadId: Scalars['Int'];
}>;

export type CheckIsUnsubscribedAndSubscribeToThreadMutation = {
  __typename?: 'Mutation';
  checkIsUnsubscribedAndSubscribeToThread: boolean;
};

export type ClearMessagesFromChatMutationVariables = Exact<{
  chatId: Scalars['Int'];
}>;

export type ClearMessagesFromChatMutation = {
  __typename?: 'Mutation';
  clearMessagesFromChat: boolean;
};

export type CloseSubtaskMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type CloseSubtaskMutation = {
  __typename?: 'Mutation';
  closeSubtask: {
    __typename?: 'Subtask';
    id: number;
    name: string;
    daysAfterStartCount: number;
    startDate?: any | null;
    endDate?: any | null;
    closeDate?: any | null;
  };
};

export type CloseTaskMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type CloseTaskMutation = {
  __typename?: 'Mutation';
  closeTask: {
    __typename?: 'Task';
    id: number;
    name: string;
    daysAfterStartCount: number;
    startDate?: any | null;
    endDate?: any | null;
    closeDate?: any | null;
    realizationPerMonthAmount?: number | null;
    realizationPrice?: number | null;
  };
};

export type CreateChronographReportMutationVariables = Exact<{
  data: CreateChronographReportInput;
}>;

export type CreateChronographReportMutation = {
  __typename?: 'Mutation';
  createChronographReport: boolean;
};

export type CreateDecideOnFlyActionMutationVariables = Exact<{
  createDecideOnFlyActionInput: CreateDecideOnFlyActionInput;
}>;

export type CreateDecideOnFlyActionMutation = {
  __typename?: 'Mutation';
  createDecideOnFlyAction: { __typename?: 'DecideOnFlyAction'; id: number };
};

export type CreateDecideOnFlyPackageMutationVariables = Exact<{
  createDecideOnFlyPackageInput: CreateDecideOnFlyPackageInput;
}>;

export type CreateDecideOnFlyPackageMutation = {
  __typename?: 'Mutation';
  createDecideOnFlyPackage: { __typename?: 'DecideOnFlyPackage'; id: number };
};

export type CreateForumMessageMutationVariables = Exact<{
  data: CreateForumMessageInput;
}>;

export type CreateForumMessageMutation = {
  __typename?: 'Mutation';
  createForumMessage: {
    __typename?: 'ForumMessage';
    id: number;
    createdAt: string;
    updatedAt: string;
    text: string;
    forumThreadId: number;
  };
};

export type CreateForumSectionMutationVariables = Exact<{
  data: CreateForumSection;
}>;

export type CreateForumSectionMutation = {
  __typename?: 'Mutation';
  createForumSection: {
    __typename?: 'ForumSection';
    id: number;
    createdAt: string;
    updatedAt: string;
    name: string;
    role?: ForumSectionRoles | null;
  };
};

export type CreateForumThreadMutationVariables = Exact<{
  data: CreateForumThreadInput;
}>;

export type CreateForumThreadMutation = {
  __typename?: 'Mutation';
  createForumThread: {
    __typename?: 'ForumThread';
    id: number;
    createdAt: string;
    updatedAt: string;
    name: string;
    description: string;
    imagePath?: string | null;
    forumSectionId: number;
    authorId: number;
    viewType: number;
  };
};

export type CreateNoteMutationVariables = Exact<{
  data: CreateNoteInput;
}>;

export type CreateNoteMutation = {
  __typename?: 'Mutation';
  createNote: {
    __typename?: 'Note';
    id: number;
    createdAt: string;
    chatId?: number | null;
    taskId?: number | null;
    content?: string | null;
    ownerId: number;
    owner?: {
      __typename?: 'User';
      id?: number | null;
      login?: string | null;
      fullName?: string | null;
      image?: string | null;
      createdAt?: string | null;
      role?: string | null;
    } | null;
  };
};

export type CreatePoligonParentLinkMutationVariables = Exact<{
  parentId: Scalars['Int'];
  poligonObjectId?: InputMaybe<Scalars['Int']>;
}>;

export type CreatePoligonParentLinkMutation = {
  __typename?: 'Mutation';
  createPoligonParentLink: boolean;
};

export type CreatePoligonTemplateMutationVariables = Exact<{
  data: CreatePoligonTemplateInput;
}>;

export type CreatePoligonTemplateMutation = {
  __typename?: 'Mutation';
  createPoligonTemplate: {
    __typename?: 'PoligonTemplate';
    id: number;
    createdAt: string;
    updatedAt: string;
    name: string;
    canvasObject: any;
  };
};

export type CreatePrivateChatMutationVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type CreatePrivateChatMutation = {
  __typename?: 'Mutation';
  createPrivateChat?: { __typename?: 'Chat'; id: number } | null;
};

export type CreateProjectMutationVariables = Exact<{
  data: CreateProjectInput;
}>;

export type CreateProjectMutation = {
  __typename?: 'Mutation';
  createProject: {
    __typename?: 'Project';
    id: number;
    name: string;
    number?: number | null;
    createdAt: string;
    updatedAt: string;
    author?: {
      __typename?: 'User';
      id?: number | null;
      image?: string | null;
      login?: string | null;
    } | null;
    tasks: {
      __typename?: 'TasksList';
      count: number;
      rows: Array<{
        __typename?: 'Task';
        id: number;
        number: number;
        name: string;
        code?: string | null;
        startDate?: any | null;
        closeDate?: any | null;
        endDate?: any | null;
        status?: { __typename?: 'Status'; id: number; color: string } | null;
        project: { __typename?: 'Project'; id: number; number?: number | null };
        author?: {
          __typename?: 'User';
          id?: number | null;
          image?: string | null;
          login?: string | null;
          role?: string | null;
        } | null;
        assignees: Array<{
          __typename?: 'User';
          id?: number | null;
          image?: string | null;
          login?: string | null;
          role?: string | null;
        } | null>;
        maintainer?: {
          __typename?: 'User';
          id?: number | null;
          login?: string | null;
          image?: string | null;
        } | null;
      } | null>;
    };
  };
};

export type CreateScaleMapFileMutationVariables = Exact<{
  input: CreateScaleMapFileInput;
}>;

export type CreateScaleMapFileMutation = {
  __typename?: 'Mutation';
  createScaleMapFile: {
    __typename?: 'ScaleMapFile';
    id: number;
    name: string;
    createdAt: string;
    updatedAt: string;
    project?: { __typename?: 'Project'; id: number } | null;
    scaleMapTasks: Array<{ __typename?: 'ScaleMapTask'; id: number }>;
    statistics: { __typename?: 'ScaleMapFileStatistics'; tasksCount: number };
  };
};

export type CreateScaleMapTaskMutationVariables = Exact<{
  input: CreateScaleMapTaskInput;
}>;

export type CreateScaleMapTaskMutation = {
  __typename?: 'Mutation';
  createScaleMapTask: {
    __typename?: 'ScaleMapTask';
    id: number;
    name: string;
    column?: number | null;
    row?: number | null;
    order: number;
    scaleMapTaskId?: number | null;
    isExclamationPoint?: boolean | null;
    isNestingAllTasksWithCompleted?: boolean | null;
    isMain?: boolean | null;
    nestingTaskCount?: number | null;
    thread?: { __typename?: 'Thread'; id: number } | null;
    status?: {
      __typename?: 'ScaleMapStatus';
      id: number;
      name: string;
      color: string;
    } | null;
    statistics?: {
      __typename?: 'ScaleMapTaskStatistics';
      maxStatusInNestingTasksPercent?: number | null;
      maxStatusInNestingTasks?: {
        __typename?: 'ScaleMapStatus';
        id: number;
        name: string;
        color: string;
      } | null;
    } | null;
  };
};

export type CreateSocialCircleObjectMutationVariables = Exact<{
  createSocialCircleObjectInput: CreateSocialCircleObjectInput;
}>;

export type CreateSocialCircleObjectMutation = {
  __typename?: 'Mutation';
  createSocialCircleObject: { __typename?: 'SocialCircleObject'; id: number };
};

export type CreateSubtaskMutationVariables = Exact<{
  data: CreateSubtaskInput;
}>;

export type CreateSubtaskMutation = {
  __typename?: 'Mutation';
  createSubtask: {
    __typename?: 'Subtask';
    id: number;
    createdAt: string;
    updatedAt: string;
    number: number;
    name: string;
    description?: string | null;
    startDate?: any | null;
    closeDate?: any | null;
    code?: string | null;
    endDate?: any | null;
    task: {
      __typename?: 'Task';
      id: number;
      name: string;
      number: number;
      code?: string | null;
      project: {
        __typename?: 'Project';
        id: number;
        number?: number | null;
        name: string;
      };
    };
    status?: { __typename?: 'Status'; id: number; color: string } | null;
    author?: {
      __typename?: 'User';
      id?: number | null;
      login?: string | null;
      image?: string | null;
    } | null;
    maintainer?: {
      __typename?: 'User';
      id?: number | null;
      image?: string | null;
      login?: string | null;
    } | null;
    tags: {
      __typename?: 'TagList';
      count: number;
      rows: Array<{
        __typename?: 'Tag';
        id: number;
        createdAt: string;
        updatedAt: string;
        name: string;
        color?: string | null;
      }>;
    };
  };
};

export type CreateTagMutationVariables = Exact<{
  data: CreateTagInput;
}>;

export type CreateTagMutation = {
  __typename?: 'Mutation';
  createTag: { __typename?: 'Tag'; id: number };
};

export type CreateTaskMutationVariables = Exact<{
  data: CreateTaskInput;
}>;

export type CreateTaskMutation = {
  __typename?: 'Mutation';
  createTask: {
    __typename?: 'Task';
    id: number;
    number: number;
    name: string;
    code?: string | null;
    startDate?: any | null;
    closeDate?: any | null;
    endDate?: any | null;
    realizationPerMonthAmount?: number | null;
    realizationPrice?: number | null;
    project: { __typename?: 'Project'; id: number; name: string; number?: number | null };
    status?: { __typename?: 'Status'; id: number; color: string } | null;
    author?: {
      __typename?: 'User';
      id?: number | null;
      image?: string | null;
      login?: string | null;
      role?: string | null;
    } | null;
    assignees: Array<{
      __typename?: 'User';
      id?: number | null;
      image?: string | null;
      login?: string | null;
      role?: string | null;
    } | null>;
    maintainer?: {
      __typename?: 'User';
      id?: number | null;
      login?: string | null;
      image?: string | null;
      fullName?: string | null;
    } | null;
    subtasks: Array<{
      __typename?: 'Subtask';
      id: number;
      createdAt: string;
      name: string;
      number: number;
      startDate?: any | null;
      closeDate?: any | null;
      endDate?: any | null;
      status?: { __typename?: 'Status'; id: number; color: string } | null;
      task: {
        __typename?: 'Task';
        id: number;
        number: number;
        project: { __typename?: 'Project'; id: number; name: string };
      };
      tags: {
        __typename?: 'TagList';
        count: number;
        rows: Array<{
          __typename?: 'Tag';
          id: number;
          createdAt: string;
          updatedAt: string;
          name: string;
          color?: string | null;
        }>;
      };
    } | null>;
    tags: {
      __typename?: 'TagList';
      count: number;
      rows: Array<{
        __typename?: 'Tag';
        id: number;
        createdAt: string;
        updatedAt: string;
        name: string;
        color?: string | null;
      }>;
    };
  };
};

export type CreateThreadMutationVariables = Exact<{
  data: CreateThread;
}>;

export type CreateThreadMutation = {
  __typename?: 'Mutation';
  createThread: { __typename?: 'Thread'; id: number };
};

export type CreateThreadBackgroundMutationVariables = Exact<{
  createThreadBackgroundInputs:
    | Array<CreateThreadBackgroundInput>
    | CreateThreadBackgroundInput;
}>;

export type CreateThreadBackgroundMutation = {
  __typename?: 'Mutation';
  createThreadBackground: Array<{ __typename?: 'ThreadBackground'; id: number }>;
};

export type CreateUserMutationVariables = Exact<{
  data: CreateUserInput;
}>;

export type CreateUserMutation = { __typename?: 'Mutation'; createUser: string };

export type CreateUserCharacteristicMutationVariables = Exact<{
  data: CreateUserCharacteristicInput;
}>;

export type CreateUserCharacteristicMutation = {
  __typename?: 'Mutation';
  createUserCharacteristic: {
    __typename?: 'UserCharacteristic';
    id: number;
    text: string;
    color: string;
  };
};

export type CreateUserGroupMutationVariables = Exact<{
  createUserGroupInput: CreateUserGroupInput;
}>;

export type CreateUserGroupMutation = {
  __typename?: 'Mutation';
  createUserGroup: { __typename?: 'UserGroup'; id: number };
};

export type DeassignUserFromTaskMutationVariables = Exact<{
  taskId: Scalars['Int'];
  userId: Scalars['Int'];
}>;

export type DeassignUserFromTaskMutation = {
  __typename?: 'Mutation';
  deassignUserFromTask: {
    __typename?: 'Task';
    id: number;
    assignees: Array<{
      __typename?: 'User';
      id?: number | null;
      login?: string | null;
      image?: string | null;
    } | null>;
  };
};

export type DeleteChronographSampleItemMutationVariables = Exact<{
  scaleMapFileId: Scalars['Int'];
  chronographSampleId: Scalars['Int'];
}>;

export type DeleteChronographSampleItemMutation = {
  __typename?: 'Mutation';
  deleteChronographSampleItem: boolean;
};

export type DeleteFileMutationVariables = Exact<{
  id: Scalars['Float'];
}>;

export type DeleteFileMutation = {
  __typename?: 'Mutation';
  deleteFile: { __typename?: 'StatusResponse'; status: string };
};

export type DeleteMessageMutationVariables = Exact<{
  messageId: Scalars['ID'];
}>;

export type DeleteMessageMutation = { __typename?: 'Mutation'; deleteMessage: boolean };

export type DeletePoligonMutationVariables = Exact<{
  id: Scalars['Int'];
  force?: InputMaybe<Scalars['Boolean']>;
}>;

export type DeletePoligonMutation = { __typename?: 'Mutation'; deletePoligon: boolean };

export type DeletePoligonObjectMutationVariables = Exact<{
  id: Scalars['Int'];
  force?: InputMaybe<Scalars['Boolean']>;
}>;

export type DeletePoligonObjectMutation = {
  __typename?: 'Mutation';
  deletePoligonObject: boolean;
};

export type DeletePoligonParentLinkMutationVariables = Exact<{
  parentId: Scalars['Int'];
  poligonObjectId: Scalars['Int'];
}>;

export type DeletePoligonParentLinkMutation = {
  __typename?: 'Mutation';
  deletePoligonParentLink: boolean;
};

export type DeletePoligonTemplateMutationVariables = Exact<{
  id: Scalars['Int'];
  force?: InputMaybe<Scalars['Boolean']>;
}>;

export type DeletePoligonTemplateMutation = {
  __typename?: 'Mutation';
  deletePoligonTemplate: boolean;
};

export type DeleteProjectMutationVariables = Exact<{
  id: Scalars['Int'];
  force?: InputMaybe<Scalars['Boolean']>;
}>;

export type DeleteProjectMutation = { __typename?: 'Mutation'; deleteProject: boolean };

export type DeleteScaleMapFileMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteScaleMapFileMutation = {
  __typename?: 'Mutation';
  deleteScaleMapFile: boolean;
};

export type DeleteScaleMapTaskMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteScaleMapTaskMutation = {
  __typename?: 'Mutation';
  deleteScaleMapTask: boolean;
};

export type DeleteScaleMapTaskWithResponseMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteScaleMapTaskWithResponseMutation = {
  __typename?: 'Mutation';
  deleteScaleMapTaskWithResponse: { __typename?: 'ScaleMapTask'; id: number };
};

export type DeleteStorageItemMutationVariables = Exact<{
  storageItemId: Scalars['Int'];
}>;

export type DeleteStorageItemMutation = {
  __typename?: 'Mutation';
  deleteStorageItem: boolean;
};

export type DeleteSubtaskMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteSubtaskMutation = { __typename?: 'Mutation'; deleteSubtask: boolean };

export type DeleteTagMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteTagMutation = { __typename?: 'Mutation'; deleteTag: boolean };

export type DeleteTaskMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteTaskMutation = { __typename?: 'Mutation'; deleteTask: boolean };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteUserMutation = { __typename?: 'Mutation'; deleteUser: boolean };

export type DeleteUserCharacteristicMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteUserCharacteristicMutation = {
  __typename?: 'Mutation';
  deleteUserCharacteristic: boolean;
};

export type EditUserMutationVariables = Exact<{
  data: EditUserInput;
}>;

export type EditUserMutation = {
  __typename?: 'Mutation';
  editUser: {
    __typename?: 'User';
    id?: number | null;
    login?: string | null;
    fullName?: string | null;
    image?: string | null;
    theNote?: string | null;
  };
};

export type ExitThreadPerformersMutationVariables = Exact<{
  threadId: Scalars['Int'];
}>;

export type ExitThreadPerformersMutation = {
  __typename?: 'Mutation';
  exitThreadPerformers: boolean;
};

export type GeneratePasswordResetTokenUserMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type GeneratePasswordResetTokenUserMutation = {
  __typename?: 'Mutation';
  generatePasswordResetTokenUser: string;
};

export type GetOrCreatePrivateChatMutationVariables = Exact<{
  userId: Scalars['Float'];
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type GetOrCreatePrivateChatMutation = {
  __typename?: 'Mutation';
  getOrCreatePrivateChat?: {
    __typename?: 'Chat';
    id: number;
    messagesList: {
      __typename?: 'MessagesList';
      count: number;
      rows: Array<{
        __typename?: 'Message';
        isRead?: boolean | null;
        isPossibleSetKarma: boolean;
        isActivity?: boolean | null;
        isDidYouRead?: boolean | null;
        id: number;
        text: string;
        createdAt: any;
        user: {
          __typename?: 'User';
          id?: number | null;
          login?: string | null;
          image?: string | null;
          createdAt?: string | null;
          karmaStatistics?: {
            __typename?: 'UserKarmaStatistics';
            karma: number;
            items: Array<{
              __typename?: 'Karma';
              id: number;
              createdAt: string;
              isPlus: boolean;
              messageId: number;
              user: { __typename?: 'User'; login?: string | null; image?: string | null };
            }>;
          } | null;
        };
      } | null>;
    };
    logs: {
      __typename?: 'MessagesList';
      count: number;
      rows: Array<{
        __typename?: 'Message';
        isRead?: boolean | null;
        isPossibleSetKarma: boolean;
        isActivity?: boolean | null;
        isDidYouRead?: boolean | null;
        id: number;
        text: string;
        createdAt: any;
        user: {
          __typename?: 'User';
          id?: number | null;
          login?: string | null;
          image?: string | null;
          createdAt?: string | null;
          karmaStatistics?: {
            __typename?: 'UserKarmaStatistics';
            karma: number;
            items: Array<{
              __typename?: 'Karma';
              id: number;
              createdAt: string;
              isPlus: boolean;
              messageId: number;
              user: { __typename?: 'User'; login?: string | null; image?: string | null };
            }>;
          } | null;
        };
      } | null>;
    };
  } | null;
};

export type GetRandomDecideOnFlyActionMutationVariables = Exact<{
  timezoneOffset: Scalars['Int'];
}>;

export type GetRandomDecideOnFlyActionMutation = {
  __typename?: 'Mutation';
  getRandomDecideOnFlyAction: {
    __typename?: 'GetRandomDecideOnFlyActionResponse';
    isAccessToGet: boolean;
    currentUserAction?: {
      __typename?: 'UserDecideOnFlyAction';
      id: number;
      userId: number;
      decideOnFlyActionId: number;
      startDate?: any | null;
      endDate?: any | null;
      status: string;
      reasonForNotDone?: string | null;
    } | null;
  };
};

export type LoadFileMutationVariables = Exact<{
  data: LoadFileInput;
}>;

export type LoadFileMutation = {
  __typename?: 'Mutation';
  loadFile: {
    __typename?: 'File';
    id: number;
    fileName: string;
    path: string;
    size: number;
  };
};

export type MadeSocialCircleMeetingMutationVariables = Exact<{
  socialCircleObjectId: Scalars['Int'];
}>;

export type MadeSocialCircleMeetingMutation = {
  __typename?: 'Mutation';
  madeSocialCircleMeeting: { __typename?: 'SocialCircleObject'; id: number };
};

export type MoveScaleMapTaskMutationVariables = Exact<{
  scaleMapTaskId: Scalars['Int'];
  parentScaleMapTaskId: Scalars['Int'];
}>;

export type MoveScaleMapTaskMutation = {
  __typename?: 'Mutation';
  moveScaleMapTask: boolean;
};

export type MoveScaleMapTaskToRowMutationVariables = Exact<{
  id: Scalars['Int'];
  row: Scalars['Int'];
  order: Scalars['Int'];
}>;

export type MoveScaleMapTaskToRowMutation = {
  __typename?: 'Mutation';
  moveScaleMapTaskToRow: boolean;
};

export type ReadMessagesBeforeMutationVariables = Exact<{
  messageId: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type ReadMessagesBeforeMutation = {
  __typename?: 'Mutation';
  readMessagesBefore: boolean;
};

export type ReadMessagesByChatIdMutationVariables = Exact<{
  chatId: Scalars['Int'];
  isActivity: Scalars['Boolean'];
}>;

export type ReadMessagesByChatIdMutation = {
  __typename?: 'Mutation';
  readMessagesByChatId: { __typename?: 'GlobalStatusResponseType'; status: boolean };
};

export type ReadProjectActivitiesMutationVariables = Exact<{
  taskId?: InputMaybe<Scalars['Int']>;
  subtaskId?: InputMaybe<Scalars['Int']>;
  threadId?: InputMaybe<Scalars['Int']>;
}>;

export type ReadProjectActivitiesMutation = {
  __typename?: 'Mutation';
  readProjectActivities: boolean;
};

export type RemoveDecideOnFlyActionMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type RemoveDecideOnFlyActionMutation = {
  __typename?: 'Mutation';
  removeDecideOnFlyAction: boolean;
};

export type RemoveDecideOnFlyPackageMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type RemoveDecideOnFlyPackageMutation = {
  __typename?: 'Mutation';
  removeDecideOnFlyPackage: boolean;
};

export type RemoveForumMessageMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type RemoveForumMessageMutation = {
  __typename?: 'Mutation';
  removeForumMessage: boolean;
};

export type RemoveForumSectionMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type RemoveForumSectionMutation = {
  __typename?: 'Mutation';
  removeForumSection: boolean;
};

export type RemoveForumThreadMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type RemoveForumThreadMutation = {
  __typename?: 'Mutation';
  removeForumThread: boolean;
};

export type RemoveMyAccountMutationVariables = Exact<{ [key: string]: never }>;

export type RemoveMyAccountMutation = {
  __typename?: 'Mutation';
  removeMyAccount: boolean;
};

export type RemoveNoteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type RemoveNoteMutation = { __typename?: 'Mutation'; removeNote: boolean };

export type RemovePerformerFromThreadMutationVariables = Exact<{
  data: AddPerformersToThread;
}>;

export type RemovePerformerFromThreadMutation = {
  __typename?: 'Mutation';
  removePerformerFromThread: boolean;
};

export type RemoveSocialCircleObjectMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type RemoveSocialCircleObjectMutation = {
  __typename?: 'Mutation';
  removeSocialCircleObject: boolean;
};

export type RemoveThreadBackgroundMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type RemoveThreadBackgroundMutation = {
  __typename?: 'Mutation';
  removeThreadBackground: boolean;
};

export type RemoveUserGroupMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type RemoveUserGroupMutation = {
  __typename?: 'Mutation';
  removeUserGroup: boolean;
};

export type ResetPasswordMutationVariables = Exact<{
  data: ResetPasswordUser;
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword: { __typename?: 'StatusResponse'; status: string };
};

export type ResetPasswordByTokenMutationVariables = Exact<{
  token: Scalars['String'];
  newPassword: Scalars['String'];
}>;

export type ResetPasswordByTokenMutation = {
  __typename?: 'Mutation';
  resetPasswordByToken: { __typename?: 'StatusResponse'; status: string };
};

export type ResetRealizationPriceMutationVariables = Exact<{
  taskId: Scalars['Int'];
}>;

export type ResetRealizationPriceMutation = {
  __typename?: 'Mutation';
  resetRealizationPrice: boolean;
};

export type SendMessageMutationVariables = Exact<{
  data: CreateMessageInput;
}>;

export type SendMessageMutation = {
  __typename?: 'Mutation';
  sendMessage: {
    __typename?: 'Message';
    id: number;
    text: string;
    isRead?: boolean | null;
    createdAt: any;
    isExistLink?: boolean | null;
    user: { __typename?: 'User'; login?: string | null; image?: string | null };
  };
};

export type SetChronographDayRecordsMutationVariables = Exact<{
  data: SetChronographDayRecords;
}>;

export type SetChronographDayRecordsMutation = {
  __typename?: 'Mutation';
  setChronographDayRecords: boolean;
};

export type SetDecideOnFlyPackagesToUserMutationVariables = Exact<{
  packagesId: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type SetDecideOnFlyPackagesToUserMutation = {
  __typename?: 'Mutation';
  setDecideOnFlyPackagesToUser: Array<{ __typename?: 'DecideOnFlyPackage'; id: number }>;
};

export type SetKarmaMutationVariables = Exact<{
  messageId: Scalars['ID'];
  isPlus: Scalars['Boolean'];
}>;

export type SetKarmaMutation = { __typename?: 'Mutation'; setKarma: boolean };

export type SetPerformerStatusMutationVariables = Exact<{
  data: PerformerStatus;
}>;

export type SetPerformerStatusMutation = {
  __typename?: 'Mutation';
  setPerformerStatus: boolean;
};

export type SetStatusUserDecideOnFlyActionMutationVariables = Exact<{
  status: Scalars['String'];
  reasonForNotDone: Scalars['String'];
}>;

export type SetStatusUserDecideOnFlyActionMutation = {
  __typename?: 'Mutation';
  setStatusUserDecideOnFlyAction: boolean;
};

export type SetUserAnalyticMutationVariables = Exact<{
  userId: Scalars['Int'];
  isAnalytics: Scalars['Boolean'];
}>;

export type SetUserAnalyticMutation = {
  __typename?: 'Mutation';
  setUserAnalytic: boolean;
};

export type StartSubtaskMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type StartSubtaskMutation = {
  __typename?: 'Mutation';
  startSubtask: {
    __typename?: 'Subtask';
    id: number;
    name: string;
    daysAfterStartCount: number;
    startDate?: any | null;
    endDate?: any | null;
    closeDate?: any | null;
  };
};

export type StartTaskMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type StartTaskMutation = {
  __typename?: 'Mutation';
  startTask: {
    __typename?: 'Task';
    id: number;
    name: string;
    daysAfterStartCount: number;
    startDate?: any | null;
    endDate?: any | null;
    closeDate?: any | null;
    realizationPerMonthAmount?: number | null;
    realizationPrice?: number | null;
  };
};

export type SubscribeToThreadMutationVariables = Exact<{
  threadId: Scalars['Int'];
}>;

export type SubscribeToThreadMutation = {
  __typename?: 'Mutation';
  subscribeToThread: boolean;
};

export type TrackChronographTimeMutationVariables = Exact<{
  data: TrackChronographTimeInput;
}>;

export type TrackChronographTimeMutation = {
  __typename?: 'Mutation';
  trackChronographTime: boolean;
};

export type TrackStorageEventClickedMutationVariables = Exact<{
  storageItemId?: InputMaybe<Scalars['Int']>;
  fileId?: InputMaybe<Scalars['Int']>;
  threadId?: InputMaybe<Scalars['Int']>;
}>;

export type TrackStorageEventClickedMutation = {
  __typename?: 'Mutation';
  trackStorageEventClicked: boolean;
};

export type UnsubscribeFromThreadMutationVariables = Exact<{
  threadId: Scalars['Int'];
}>;

export type UnsubscribeFromThreadMutation = {
  __typename?: 'Mutation';
  unsubscribeFromThread: boolean;
};

export type UpdateChronographSampleMutationVariables = Exact<{
  input: UpdateChronographSampleInput;
}>;

export type UpdateChronographSampleMutation = {
  __typename?: 'Mutation';
  updateChronographSample: {
    __typename?: 'ChronographSample';
    id: number;
    createdAt: string;
    updatedAt: string;
    name?: string | null;
    isPublic: boolean;
    userId: number;
    sampleItems: Array<{
      __typename?: 'ChronographSampleItem';
      scaleMapFileId: number;
      chronographSampleId: number;
      scheduledTime?: number | null;
      scheduledTimePerDay?: number | null;
      isDaily: boolean;
      color?: string | null;
      scaleMapFile: { __typename?: 'ScaleMapFile'; id: number };
    }>;
  };
};

export type UpdateChronographSampleItemMutationVariables = Exact<{
  data: UpdateChronographSampleItemInput;
}>;

export type UpdateChronographSampleItemMutation = {
  __typename?: 'Mutation';
  updateChronographSampleItem: boolean;
};

export type UpdateDecideOnFlyActionMutationVariables = Exact<{
  updateDecideOnFlyActionInput: UpdateDecideOnFlyActionInput;
}>;

export type UpdateDecideOnFlyActionMutation = {
  __typename?: 'Mutation';
  updateDecideOnFlyAction: { __typename?: 'DecideOnFlyAction'; id: number };
};

export type UpdateDecideOnFlyPackageMutationVariables = Exact<{
  updateDecideOnFlyPackageInput: UpdateDecideOnFlyPackageInput;
}>;

export type UpdateDecideOnFlyPackageMutation = {
  __typename?: 'Mutation';
  updateDecideOnFlyPackage: { __typename?: 'DecideOnFlyPackage'; id: number };
};

export type UpdateFileMutationVariables = Exact<{
  id: Scalars['ID'];
  data: UpdateFileInput;
}>;

export type UpdateFileMutation = {
  __typename?: 'Mutation';
  updateFile: { __typename?: 'File'; id: number; fileName: string };
};

export type UpdateForumMessageMutationVariables = Exact<{
  data: UpdateForumMessageInput;
}>;

export type UpdateForumMessageMutation = {
  __typename?: 'Mutation';
  updateForumMessage: { __typename?: 'ForumMessage'; id: number };
};

export type UpdateForumSectionMutationVariables = Exact<{
  data: UpdateForumSection;
}>;

export type UpdateForumSectionMutation = {
  __typename?: 'Mutation';
  updateForumSection: {
    __typename?: 'ForumSection';
    id: number;
    createdAt: string;
    updatedAt: string;
    name: string;
    role?: ForumSectionRoles | null;
  };
};

export type UpdateForumThreadMutationVariables = Exact<{
  data: UpdateForumThreadInput;
}>;

export type UpdateForumThreadMutation = {
  __typename?: 'Mutation';
  updateForumThread: {
    __typename?: 'ForumThread';
    id: number;
    createdAt: string;
    updatedAt: string;
    name: string;
    description: string;
    imagePath?: string | null;
    forumSectionId: number;
    authorId: number;
    viewType: number;
  };
};

export type UpdateOrderScaleMapFilesMutationVariables = Exact<{
  data: UpdateOrderScaleMapFiles;
}>;

export type UpdateOrderScaleMapFilesMutation = {
  __typename?: 'Mutation';
  updateOrderScaleMapFiles: boolean;
};

export type UpdatePoligonTemplateMutationVariables = Exact<{
  data: UpdatePoligonTemplateInput;
  id: Scalars['Int'];
}>;

export type UpdatePoligonTemplateMutation = {
  __typename?: 'Mutation';
  updatePoligonTemplate: {
    __typename?: 'PoligonTemplate';
    id: number;
    createdAt: string;
    updatedAt: string;
    name: string;
    canvasObject: any;
  };
};

export type UpdateProjectMutationVariables = Exact<{
  id: Scalars['Int'];
  data: UpdateProjectInput;
}>;

export type UpdateProjectMutation = {
  __typename?: 'Mutation';
  updateProject: {
    __typename?: 'Project';
    id: number;
    createdAt: string;
    updatedAt: string;
    name: string;
    number?: number | null;
  };
};

export type UpdateScaleMapFileMutationVariables = Exact<{
  input: UpdateScaleMapFileInput;
}>;

export type UpdateScaleMapFileMutation = {
  __typename?: 'Mutation';
  updateScaleMapFile: { __typename?: 'ScaleMapFile'; id: number };
};

export type UpdateScaleMapTaskMutationVariables = Exact<{
  input: UpdateScaleMapTaskInput;
}>;

export type UpdateScaleMapTaskMutation = {
  __typename?: 'Mutation';
  updateScaleMapTask: {
    __typename?: 'ScaleMapTask';
    id: number;
    updatedAt: string;
    name: string;
    column?: number | null;
    row?: number | null;
    order: number;
    scaleMapTaskId?: number | null;
    isExclamationPoint?: boolean | null;
    isNestingAllTasksWithCompleted?: boolean | null;
    isMain?: boolean | null;
    nestingTaskCount?: number | null;
    statusUpdatedDate?: string | null;
    counterComment?: string | null;
    counterFavorites?: boolean | null;
    thread?: { __typename?: 'Thread'; id: number } | null;
    status?: {
      __typename?: 'ScaleMapStatus';
      id: number;
      name: string;
      color: string;
    } | null;
    scaleMapFile?: { __typename?: 'ScaleMapFile'; id: number } | null;
    scaleMapTaskMarkStatus?: {
      __typename?: 'ScaleMapTaskMarkStatus';
      id: number;
      title: string;
      color: string;
    } | null;
    statistics?: {
      __typename?: 'ScaleMapTaskStatistics';
      maxStatusInNestingTasksPercent?: number | null;
      maxStatusInNestingTasks?: {
        __typename?: 'ScaleMapStatus';
        id: number;
        name: string;
        color: string;
      } | null;
    } | null;
    parentTree?: Array<{
      __typename?: 'ScaleMapTask';
      id: number;
      name: string;
      column?: number | null;
      updatedAt: string;
      statusUpdatedDate?: string | null;
      thread?: { __typename?: 'Thread'; id: number } | null;
      status?: {
        __typename?: 'ScaleMapStatus';
        id: number;
        name: string;
        color: string;
      } | null;
    }> | null;
  };
};

export type UpdateSocialCircleObjectMutationVariables = Exact<{
  updateSocialCircleObjectInput: UpdateSocialCircleObjectInput;
}>;

export type UpdateSocialCircleObjectMutation = {
  __typename?: 'Mutation';
  updateSocialCircleObject: { __typename?: 'SocialCircleObject'; id: number };
};

export type UpdateStorageItemMutationVariables = Exact<{
  data: UpdateStorageItem;
}>;

export type UpdateStorageItemMutation = {
  __typename?: 'Mutation';
  updateStorageItem: { __typename?: 'StorageItem'; id: number };
};

export type UpdateSubtaskMutationVariables = Exact<{
  data: UpdateSubtaskInput;
  id: Scalars['Int'];
}>;

export type UpdateSubtaskMutation = {
  __typename?: 'Mutation';
  updateSubtask: {
    __typename?: 'Subtask';
    id: number;
    createdAt: string;
    name: string;
    number: number;
    description?: string | null;
    code?: string | null;
    startDate?: any | null;
    closeDate?: any | null;
    endDate?: any | null;
    task: {
      __typename?: 'Task';
      id: number;
      number: number;
      code?: string | null;
      startDate?: any | null;
      closeDate?: any | null;
      endDate?: any | null;
      project: { __typename?: 'Project'; id: number };
      assignees: Array<{
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
        fullName?: string | null;
      } | null>;
      maintainer?: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
        fullName?: string | null;
      } | null;
    };
    status?: { __typename?: 'Status'; id: number; color: string } | null;
    author?: { __typename?: 'User'; id?: number | null; login?: string | null } | null;
    maintainer?: {
      __typename?: 'User';
      id?: number | null;
      login?: string | null;
      image?: string | null;
      fullName?: string | null;
    } | null;
    notes: Array<{
      __typename?: 'Note';
      id: number;
      createdAt: string;
      chatId?: number | null;
      taskId?: number | null;
      content?: string | null;
      ownerId: number;
      owner?: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        fullName?: string | null;
        image?: string | null;
        createdAt?: string | null;
        role?: string | null;
      } | null;
    } | null>;
    tags: {
      __typename?: 'TagList';
      count: number;
      rows: Array<{
        __typename?: 'Tag';
        id: number;
        createdAt: string;
        updatedAt: string;
        name: string;
        color?: string | null;
      }>;
    };
  };
};

export type UpdateTagMutationVariables = Exact<{
  id: Scalars['Int'];
  data: CreateTagInput;
}>;

export type UpdateTagMutation = {
  __typename?: 'Mutation';
  updateTag: {
    __typename?: 'Tag';
    name: string;
    color?: string | null;
    textColor?: string | null;
  };
};

export type UpdateTaskMutationVariables = Exact<{
  data: UpdateTaskInput;
  id: Scalars['Int'];
}>;

export type UpdateTaskMutation = {
  __typename?: 'Mutation';
  updateTask: {
    __typename?: 'Task';
    id: number;
    createdAt: string;
    updatedAt: string;
    name: string;
    number: number;
    description?: string | null;
    code?: string | null;
    startDate?: any | null;
    closeDate?: any | null;
    endDate?: any | null;
    realizationPerMonthAmount?: number | null;
    realizationPrice?: number | null;
    project: { __typename?: 'Project'; id: number; number?: number | null; name: string };
    assignees: Array<{
      __typename?: 'User';
      id?: number | null;
      login?: string | null;
      image?: string | null;
      fullName?: string | null;
    } | null>;
    status?: { __typename?: 'Status'; id: number; color: string } | null;
    author?: {
      __typename?: 'User';
      id?: number | null;
      login?: string | null;
      image?: string | null;
      fullName?: string | null;
    } | null;
    subtasks: Array<{
      __typename?: 'Subtask';
      id: number;
      createdAt: string;
      updatedAt: string;
      name: string;
      description?: string | null;
      number: number;
      code?: string | null;
      startDate?: any | null;
      closeDate?: any | null;
      endDate?: any | null;
      task: {
        __typename?: 'Task';
        id: number;
        number: number;
        code?: string | null;
        project: { __typename?: 'Project'; id: number; number?: number | null };
      };
      status?: { __typename?: 'Status'; id: number; color: string } | null;
      author?: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
      } | null;
      maintainer?: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
        fullName?: string | null;
      } | null;
      notes: Array<{
        __typename?: 'Note';
        id: number;
        createdAt: string;
        chatId?: number | null;
        taskId?: number | null;
        content?: string | null;
        ownerId: number;
        owner?: {
          __typename?: 'User';
          id?: number | null;
          login?: string | null;
          fullName?: string | null;
          image?: string | null;
        } | null;
      } | null>;
      tags: {
        __typename?: 'TagList';
        count: number;
        rows: Array<{
          __typename?: 'Tag';
          id: number;
          createdAt: string;
          updatedAt: string;
          name: string;
          color?: string | null;
        }>;
      };
    } | null>;
    notes: Array<{
      __typename?: 'Note';
      id: number;
      createdAt: string;
      chatId?: number | null;
      taskId?: number | null;
      content?: string | null;
      ownerId: number;
      owner?: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        fullName?: string | null;
        image?: string | null;
        createdAt?: string | null;
        role?: string | null;
      } | null;
    } | null>;
    maintainer?: {
      __typename?: 'User';
      id?: number | null;
      login?: string | null;
      image?: string | null;
      fullName?: string | null;
    } | null;
    tags: {
      __typename?: 'TagList';
      count: number;
      rows: Array<{
        __typename?: 'Tag';
        id: number;
        createdAt: string;
        updatedAt: string;
        name: string;
        color?: string | null;
      }>;
    };
  };
};

export type UpdateThreadMutationVariables = Exact<{
  data: UpdateThread;
}>;

export type UpdateThreadMutation = {
  __typename?: 'Mutation';
  updateThread: { __typename?: 'Thread'; id: number };
};

export type UpdateThreadFileCommentMutationVariables = Exact<{
  data: UpdateThreadFileComment;
}>;

export type UpdateThreadFileCommentMutation = {
  __typename?: 'Mutation';
  updateThreadFileComment: { __typename?: 'ThreadFileWrap'; id: number };
};

export type UpdateThreadHeaderMutationVariables = Exact<{
  data: UpdateThread;
}>;

export type UpdateThreadHeaderMutation = {
  __typename?: 'Mutation';
  updateThread: {
    __typename?: 'Thread';
    id: number;
    isSubscriber: boolean;
    tags: Array<{
      __typename?: 'Tag';
      id: number;
      name: string;
      color?: string | null;
      textColor?: string | null;
    } | null>;
    meta: Array<{
      __typename?: 'ScaleMapTask';
      id: number;
      name: string;
      column?: number | null;
      row?: number | null;
      isMain?: boolean | null;
      isCurrent?: boolean | null;
      status?: { __typename?: 'ScaleMapStatus'; color: string; name: string } | null;
    } | null>;
    scaleMapTask?: {
      __typename?: 'ScaleMapTask';
      id: number;
      name: string;
      column?: number | null;
      row?: number | null;
      status?: {
        __typename?: 'ScaleMapStatus';
        id: number;
        name: string;
        color: string;
      } | null;
      children?: Array<{
        __typename?: 'ScaleMapTask';
        id: number;
        name: string;
        status?: {
          __typename?: 'ScaleMapStatus';
          id: number;
          name: string;
          color: string;
        } | null;
      }> | null;
    } | null;
  };
};

export type UpdateThreadPerformersMutationVariables = Exact<{
  data: UpdateThread;
}>;

export type UpdateThreadPerformersMutation = {
  __typename?: 'Mutation';
  updateThread: {
    __typename?: 'Thread';
    id: number;
    mainPerformerId?: number | null;
    performers: Array<{
      __typename?: 'ThreadPerformer';
      threadId: number;
      userId: number;
      status: string;
      user: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
        role?: string | null;
        threads: {
          __typename?: 'GetThreadsAndCount';
          rows: Array<{
            __typename?: 'Thread';
            id: number;
            performerStatus?: string | null;
          }>;
        };
      };
    }>;
    mainPerformer?: {
      __typename?: 'User';
      id?: number | null;
      login?: string | null;
      image?: string | null;
      IsExistUnViewedForum: boolean;
      role?: string | null;
      fullName?: string | null;
    } | null;
  };
};

export type UpdateUserCharacteristicMutationVariables = Exact<{
  data: UpdateUserCharacteristicInput;
}>;

export type UpdateUserCharacteristicMutation = {
  __typename?: 'Mutation';
  updateUserCharacteristic: {
    __typename?: 'UserCharacteristic';
    id: number;
    text: string;
    color: string;
  };
};

export type UpdateUserGroupMutationVariables = Exact<{
  updateUserGroupInput: UpdateUserGroupInput;
}>;

export type UpdateUserGroupMutation = {
  __typename?: 'Mutation';
  updateUserGroup: { __typename?: 'UserGroup'; id: number };
};

export type UpdateWeeklyScheduledItemsMutationVariables = Exact<{
  data: UpdateWeeklyScheduledItemsDataInput;
}>;

export type UpdateWeeklyScheduledItemsMutation = {
  __typename?: 'Mutation';
  updateWeeklyScheduledItems: boolean;
};

export type UpsertPoligonMutationVariables = Exact<{
  data: UpsertPoligonInput;
}>;

export type UpsertPoligonMutation = {
  __typename?: 'Mutation';
  upsertPoligon: {
    __typename?: 'Poligon';
    id: number;
    name: string;
    owner: { __typename?: 'User'; id?: number | null };
    project?: { __typename?: 'Project'; id: number } | null;
    colors?: Array<{
      __typename?: 'PoligonColor';
      id: number;
      createdAt: string;
      updatedAt: string;
      name: string;
      color: string;
    }> | null;
    rectangles?: Array<{
      __typename?: 'PoligonRect';
      id: number;
      x: number;
      y: number;
      width: number;
      height: number;
      fill: string;
      text: string;
      textObj: any;
      name: string;
      draggable: boolean;
      nameArray: Array<string>;
      parentLink?: number | null;
      alignName?: string | null;
      frontendData?: string | null;
      taskLink?: { __typename?: 'LinkTypeModel'; taskId?: number | null } | null;
      file?: { __typename?: 'File'; id: number; path: string } | null;
      statistics: {
        __typename?: 'PoligonReactStatistics';
        tasksCount: number;
        tasksDonePercentage: number;
        tasksInProgressPercentage: number;
        tasksGreyPercentage: number;
        treeCount: number;
      };
    }> | null;
    arrows?: Array<{
      __typename?: 'PoligonArrow';
      id: number;
      startX: number;
      startY: number;
      endX: number;
      endY: number;
      fill: string;
    }> | null;
  };
};

export type UpsertPoligonColorMutationVariables = Exact<{
  data: UpsertPoligonColorInput;
}>;

export type UpsertPoligonColorMutation = {
  __typename?: 'Mutation';
  upsertPoligonColor: boolean;
};

export type UpsertPoligonObjectMutationVariables = Exact<{
  data: UpsertPoligonObjectInput;
}>;

export type UpsertPoligonObjectMutation = {
  __typename?: 'Mutation';
  upsertPoligonObject: {
    __typename?: 'PoligonObject';
    id: number;
    name: string;
    description?: string | null;
    poligon?: { __typename?: 'Poligon'; id: number } | null;
    parent?: Array<{ __typename?: 'PoligonObject'; id: number }> | null;
    rectangles?: Array<{
      __typename?: 'PoligonRect';
      id: number;
      x: number;
      y: number;
      width: number;
      height: number;
      fill: string;
      text: string;
      textObj: any;
      name: string;
      draggable: boolean;
      nameArray: Array<string>;
      parentLink?: number | null;
      alignName?: string | null;
      frontendData?: string | null;
      taskLink?: { __typename?: 'LinkTypeModel'; taskId?: number | null } | null;
      file?: { __typename?: 'File'; id: number; path: string } | null;
      statistics: {
        __typename?: 'PoligonReactStatistics';
        tasksCount: number;
        tasksDonePercentage: number;
        tasksInProgressPercentage: number;
        tasksGreyPercentage: number;
        treeCount: number;
      };
    }> | null;
  };
};

export type GetChronographQueryVariables = Exact<{
  GetWeeklyScheduledItemsInput: GetWeeklyScheduledItemsInput;
}>;

export type GetChronographQuery = {
  __typename?: 'Query';
  chronograph: {
    __typename?: 'ChronographType';
    weeklyScheduledItems: Array<{
      __typename?: 'ChronographWeeklyScheduledItemType';
      spentTime?: number | null;
      scheduledTime?: number | null;
      timeSpentPercentage: number;
      scaleMapFile: { __typename?: 'ScaleMapFile'; id: number; name: string };
    }>;
    weeklyScheduled: {
      __typename?: 'ChronographWeeklyScheduledResponseType';
      totalPlannedHours: number;
      plannedTimeCompletionPercentage: number;
    };
  };
};

export type DecideOnFlyActionsQueryVariables = Exact<{ [key: string]: never }>;

export type DecideOnFlyActionsQuery = {
  __typename?: 'Query';
  decideOnFlyActions: Array<{
    __typename?: 'DecideOnFlyAction';
    id: number;
    name: string;
    chancePercent: number;
    decideOnFlyPeriodId: number;
    authorId: number;
    decideOnFlyPackage?: {
      __typename?: 'DecideOnFlyPackage';
      color?: string | null;
    } | null;
  }>;
};

export type DecideOnFlyPackageQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DecideOnFlyPackageQuery = {
  __typename?: 'Query';
  decideOnFlyPackage: {
    __typename?: 'DecideOnFlyPackage';
    id: number;
    title: string;
    description?: string | null;
    isActive: boolean;
    color?: string | null;
    decideOnFlyActions: Array<{
      __typename?: 'DecideOnFlyAction';
      id: number;
      name: string;
      chancePercent: number;
      decideOnFlyPeriodId: number;
      authorId: number;
      hourFrom?: string | null;
      hourTo?: string | null;
    }>;
  };
};

export type DecideOnFlyPackagesQueryVariables = Exact<{
  timezoneOffset: Scalars['Int'];
}>;

export type DecideOnFlyPackagesQuery = {
  __typename?: 'Query';
  decideOnFlyPackages: Array<{
    __typename?: 'DecideOnFlyPackage';
    id: number;
    title: string;
    description?: string | null;
    isActive: boolean;
    color?: string | null;
    countDecideOnFlyActions: number;
    decideOnFlyActionsSumPercent: number;
  }>;
};

export type FindProjectsQueryVariables = Exact<{
  data: GetProjectsInput;
}>;

export type FindProjectsQuery = {
  __typename?: 'Query';
  getProjects: {
    __typename?: 'ProjectsList';
    rows: Array<{
      __typename?: 'Project';
      id: number;
      name: string;
      createdAt: string;
    } | null>;
  };
};

export type FindUsersQueryVariables = Exact<{
  input: GetUsersInput;
}>;

export type FindUsersQuery = {
  __typename?: 'Query';
  getAllUsers: {
    __typename?: 'GetAllUsers';
    rows: Array<{
      __typename?: 'User';
      id?: number | null;
      login?: string | null;
      image?: string | null;
    }>;
  };
};

export type GetAllFilesQueryVariables = Exact<{
  data: GetFileInput;
}>;

export type GetAllFilesQuery = {
  __typename?: 'Query';
  getAllFiles: {
    __typename?: 'GetAllFiles';
    rows: Array<{
      __typename?: 'File';
      id: number;
      fileName: string;
      path: string;
      size: number;
    }>;
  };
};

export type GetAllUsersQueryVariables = Exact<{
  input: GetUsersInput;
}>;

export type GetAllUsersQuery = {
  __typename?: 'Query';
  getAllUsers: {
    __typename?: 'GetAllUsers';
    count: number;
    rows: Array<{
      __typename?: 'User';
      id?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      login?: string | null;
      registrationLink?: string | null;
      editDate?: string | null;
      fullName?: string | null;
      theNote?: string | null;
      image?: string | null;
      role?: string | null;
      lastConnect?: string | null;
      isAnalytics: boolean;
      karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
      accessibleProjectsStorage: Array<{
        __typename?: 'Project';
        id: number;
        name: string;
      }>;
      creator?: { __typename?: 'User'; login?: string | null } | null;
      tags: {
        __typename?: 'TagList';
        count: number;
        rows: Array<{
          __typename?: 'Tag';
          id: number;
          name: string;
          color?: string | null;
          textColor?: string | null;
        }>;
      };
      chronographReports: Array<{
        __typename?: 'ChronographReport';
        id: number;
        date: string;
        text: string;
        userId: number;
      }>;
    }>;
  };
};

export type GetAllUsersTredsQueryVariables = Exact<{
  input: GetUsersInput;
}>;

export type GetAllUsersTredsQuery = {
  __typename?: 'Query';
  getAllUsers: {
    __typename?: 'GetAllUsers';
    count: number;
    rows: Array<{
      __typename?: 'User';
      id?: number | null;
      login?: string | null;
      image?: string | null;
      role?: string | null;
      scaleMapTasksWithThreads: Array<{
        __typename?: 'ScaleMapTask';
        id: number;
        name: string;
        column?: number | null;
        row?: number | null;
        scaleMapFile?: { __typename?: 'ScaleMapFile'; id: number } | null;
        status?: {
          __typename?: 'ScaleMapStatus';
          id: number;
          name: string;
          color: string;
        } | null;
        thread?: { __typename?: 'Thread'; id: number } | null;
        children?: Array<{
          __typename?: 'ScaleMapTask';
          id: number;
          name: string;
          column?: number | null;
          row?: number | null;
          status?: {
            __typename?: 'ScaleMapStatus';
            id: number;
            name: string;
            color: string;
          } | null;
        }> | null;
      }>;
    }>;
  };
};

export type GetAndCountSocialCircleObjectsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAndCountSocialCircleObjectsQuery = {
  __typename?: 'Query';
  getAndCountSocialCircleObjects: {
    __typename?: 'GetAndCountSocialCircleObjects';
    count: number;
    rows: Array<{
      __typename?: 'SocialCircleObject';
      id: number;
      name?: string | null;
      whoIs?: string | null;
      colorToDisplay?: string | null;
      usefulnessDescription?: string | null;
      strongPoints?: string | null;
      weakPoints?: string | null;
      meetingCount: number;
      lastMeetingDate?: any | null;
      score: number;
      authorId: string;
      letter1?: string | null;
      letter2?: string | null;
      letter3?: string | null;
      letter4?: string | null;
    }>;
  };
};

export type GetArchivedProjectsQueryVariables = Exact<{
  data: GetProjectsInput;
}>;

export type GetArchivedProjectsQuery = {
  __typename?: 'Query';
  getArchivedProjects: {
    __typename?: 'ArchivedProjectListType';
    count: number;
    rows: Array<{
      __typename?: 'ArchivedProject';
      id: string;
      name: string;
      number?: number | null;
      tasks: {
        __typename?: 'TasksList';
        count: number;
        rows: Array<{
          __typename?: 'Task';
          id: number;
          name: string;
          number: number;
          description?: string | null;
          code?: string | null;
          startDate?: any | null;
          closeDate?: any | null;
          endDate?: any | null;
          project: {
            __typename?: 'Project';
            id: number;
            number?: number | null;
            name: string;
          };
          status?: { __typename?: 'Status'; id: number } | null;
          author?: {
            __typename?: 'User';
            id?: number | null;
            login?: string | null;
            image?: string | null;
            fullName?: string | null;
          } | null;
          maintainer?: {
            __typename?: 'User';
            id?: number | null;
            login?: string | null;
            image?: string | null;
          } | null;
          tags: {
            __typename?: 'TagList';
            count: number;
            rows: Array<{
              __typename?: 'Tag';
              id: number;
              name: string;
              color?: string | null;
              textColor?: string | null;
              createdAt: string;
              updatedAt: string;
            }>;
          };
        } | null>;
      };
    } | null>;
  };
};

export type GetCalendarQueryVariables = Exact<{
  dateRange: OnlyDateRangeInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  taskSort?: InputMaybe<Sort>;
  projectSort?: InputMaybe<Sort>;
  subtasksData: GetSubtasksInput;
  taskSubtaskStatusId?: InputMaybe<Scalars['Int']>;
}>;

export type GetCalendarQuery = {
  __typename?: 'Query';
  getCalendar: {
    __typename?: 'CalendarType';
    calendarProjects: {
      __typename?: 'CalendarEntityListType';
      count: number;
      rows: Array<{
        __typename?: 'ProjectEntityType';
        id: number;
        name: string;
        number?: number | null;
        tasks?: Array<{
          __typename?: 'Task';
          id: number;
          number: number;
          name: string;
          code?: string | null;
          parentId?: number | null;
          startDate?: any | null;
          closeDate?: any | null;
          endDate?: any | null;
          realizationPrice?: number | null;
          realizationPerMonthAmount?: number | null;
          maintainer?: {
            __typename?: 'User';
            id?: number | null;
            tags: {
              __typename?: 'TagList';
              rows: Array<{
                __typename?: 'Tag';
                id: number;
                name: string;
                color?: string | null;
                textColor?: string | null;
              }>;
            };
          } | null;
          assignees: Array<{
            __typename?: 'User';
            id?: number | null;
            tags: {
              __typename?: 'TagList';
              rows: Array<{
                __typename?: 'Tag';
                id: number;
                name: string;
                color?: string | null;
                textColor?: string | null;
              }>;
            };
          } | null>;
          status?: { __typename?: 'Status'; id: number; color: string } | null;
          project: { __typename?: 'Project'; id: number; number?: number | null };
          subtasks: Array<{
            __typename?: 'Subtask';
            id: number;
            number: number;
            name: string;
            code?: string | null;
            startDate?: any | null;
            closeDate?: any | null;
            endDate?: any | null;
            status?: { __typename?: 'Status'; id: number; color: string } | null;
            task: { __typename?: 'Task'; id: number; name: string };
            tags: {
              __typename?: 'TagList';
              count: number;
              rows: Array<{
                __typename?: 'Tag';
                id: number;
                name: string;
                color?: string | null;
                textColor?: string | null;
                createdAt: string;
                updatedAt: string;
              }>;
            };
          } | null>;
          tags: {
            __typename?: 'TagList';
            count: number;
            rows: Array<{
              __typename?: 'Tag';
              id: number;
              name: string;
              color?: string | null;
              textColor?: string | null;
              createdAt: string;
              updatedAt: string;
            }>;
          };
        }> | null;
      } | null>;
    };
  };
};

export type GetCalendarScaleMapTasksWithThreadsQueryVariables = Exact<{
  dateRange: OnlyDateRangeInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  projectSort?: InputMaybe<Sort>;
  scaleMapTaskStatusId?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type GetCalendarScaleMapTasksWithThreadsQuery = {
  __typename?: 'Query';
  getCalendarScaleMapTasksWithThreads: {
    __typename?: 'GetStorageResponseType';
    projects: {
      __typename?: 'ThreadProjectList';
      count: number;
      rows: Array<
        | {
            __typename?: 'Project';
            id: number;
            name: string;
            calendarScaleMapTasks?: Array<{
              __typename?: 'ScaleMapTask';
              id: number;
              name: string;
              parentCalendarTaskTextColor?: string | null;
              parentTags: Array<{
                __typename?: 'Tag';
                id: number;
                name: string;
                color?: string | null;
                textColor?: string | null;
              }>;
              status?: {
                __typename?: 'ScaleMapStatus';
                id: number;
                name: string;
                color: string;
              } | null;
              scaleMapFile?: {
                __typename?: 'ScaleMapFile';
                name: string;
                calendarTaskTextColor?: string | null;
              } | null;
              thread?: {
                __typename?: 'Thread';
                id: number;
                isCritical: boolean;
                endDate?: any | null;
                startDate?: any | null;
                closeDate?: any | null;
                tags: Array<{
                  __typename?: 'Tag';
                  id: number;
                  name: string;
                  color?: string | null;
                  textColor?: string | null;
                } | null>;
                meta: Array<{
                  __typename?: 'ScaleMapTask';
                  id: number;
                  name: string;
                } | null>;
                scaleMapTaskForCalendar?: {
                  __typename?: 'ScaleMapTask';
                  id: number;
                  name: string;
                  isUrgently?: boolean | null;
                  status?: {
                    __typename?: 'ScaleMapStatus';
                    id: number;
                    name: string;
                    color: string;
                  } | null;
                } | null;
                mainPerformer?: {
                  __typename?: 'User';
                  id?: number | null;
                  login?: string | null;
                  tags: {
                    __typename?: 'TagList';
                    rows: Array<{
                      __typename?: 'Tag';
                      id: number;
                      name: string;
                      color?: string | null;
                      textColor?: string | null;
                    }>;
                  };
                } | null;
              } | null;
            }> | null;
          }
        | {
            __typename?: 'ThreadProject';
            name: string;
            calendarScaleMapTasks?: Array<{
              __typename?: 'ScaleMapTask';
              id: number;
              name: string;
              parentCalendarTaskTextColor?: string | null;
              parentTags: Array<{
                __typename?: 'Tag';
                id: number;
                name: string;
                color?: string | null;
                textColor?: string | null;
              }>;
              status?: {
                __typename?: 'ScaleMapStatus';
                id: number;
                name: string;
                color: string;
              } | null;
              scaleMapFile?: {
                __typename?: 'ScaleMapFile';
                name: string;
                calendarTaskTextColor?: string | null;
              } | null;
              thread?: {
                __typename?: 'Thread';
                id: number;
                isCritical: boolean;
                endDate?: any | null;
                startDate?: any | null;
                closeDate?: any | null;
                tags: Array<{
                  __typename?: 'Tag';
                  id: number;
                  name: string;
                  color?: string | null;
                  textColor?: string | null;
                } | null>;
                meta: Array<{
                  __typename?: 'ScaleMapTask';
                  id: number;
                  name: string;
                } | null>;
                scaleMapTaskForCalendar?: {
                  __typename?: 'ScaleMapTask';
                  id: number;
                  name: string;
                  isUrgently?: boolean | null;
                  status?: {
                    __typename?: 'ScaleMapStatus';
                    id: number;
                    name: string;
                    color: string;
                  } | null;
                } | null;
                mainPerformer?: {
                  __typename?: 'User';
                  id?: number | null;
                  login?: string | null;
                  tags: {
                    __typename?: 'TagList';
                    rows: Array<{
                      __typename?: 'Tag';
                      id: number;
                      name: string;
                      color?: string | null;
                      textColor?: string | null;
                    }>;
                  };
                } | null;
              } | null;
            }> | null;
          }
        | null
      >;
    };
  };
};

export type GetChatsQueryVariables = Exact<{
  data: GetChatsInput;
}>;

export type GetChatsQuery = {
  __typename?: 'Query';
  getChats: {
    __typename?: 'ChatsList';
    count: number;
    rows: Array<{
      __typename?: 'Chat';
      id: number;
      name?: string | null;
      unreadMessagesCount: number;
      task?: { __typename?: 'Task'; id: number; name: string } | null;
      subtask?: {
        __typename?: 'Subtask';
        id: number;
        name: string;
        task: { __typename?: 'Task'; id: number; name: string };
      } | null;
    } | null>;
  };
};

export type GetChronographDaysQueryVariables = Exact<{
  data: GetChronographDays;
}>;

export type GetChronographDaysQuery = {
  __typename?: 'Query';
  getChronographDays: {
    __typename?: 'GetChronographDaysResponse';
    uncompletedWeek?: string | null;
    isBlocked: boolean;
    statistic: {
      __typename?: 'ChronographStatistic';
      trackedPercentPerWeek: number;
      trackedPercentPerMonth: number;
      plan: Array<{
        __typename?: 'ChronographStatPlan';
        scaleMapFileId: number;
        scheduledTime: number;
        isDaily: boolean;
        color?: string | null;
        trackedTimePercent?: number | null;
        trackedTime?: number | null;
        scheduledTotalTime?: number | null;
        scaleMapFile: {
          __typename?: 'ScaleMapFile';
          id: number;
          color?: string | null;
          name: string;
          isArchived: boolean;
          order?: number | null;
          isMainScaleMapTasks: Array<{
            __typename?: 'ScaleMapTask';
            id: number;
            name: string;
            isMain?: boolean | null;
          } | null>;
        };
      }>;
      statisticPerWeek: Array<{
        __typename?: 'StatisticPerTypeItem';
        scaleMapFileId: number;
        timeTracked: number;
        percent: number;
        groupByScaleMapTask: Array<{
          __typename?: 'StatisticPerTask';
          percent: number;
          timeTracked: number;
          scaleMapFileId: number;
          scaleMapTaskId?: number | null;
          scaleMapFile: {
            __typename?: 'ScaleMapFile';
            id: number;
            color?: string | null;
            name: string;
            isMainScaleMapTasks: Array<{
              __typename?: 'ScaleMapTask';
              id: number;
              name: string;
              isMain?: boolean | null;
            } | null>;
          };
        } | null>;
        scaleMapFile: {
          __typename?: 'ScaleMapFile';
          id: number;
          color?: string | null;
          name: string;
          isMainScaleMapTasks: Array<{
            __typename?: 'ScaleMapTask';
            id: number;
            name: string;
            isMain?: boolean | null;
          } | null>;
        };
      }>;
      statisticPerMonth: Array<{
        __typename?: 'StatisticPerTypeItem';
        scaleMapFileId: number;
        timeTracked: number;
        percent: number;
        groupByScaleMapTask: Array<{
          __typename?: 'StatisticPerTask';
          percent: number;
          timeTracked: number;
          scaleMapFileId: number;
          scaleMapTaskId?: number | null;
          scaleMapFile: {
            __typename?: 'ScaleMapFile';
            id: number;
            color?: string | null;
            name: string;
            isMainScaleMapTasks: Array<{
              __typename?: 'ScaleMapTask';
              id: number;
              name: string;
              isMain?: boolean | null;
            } | null>;
          };
        } | null>;
        scaleMapFile: {
          __typename?: 'ScaleMapFile';
          id: number;
          color?: string | null;
          name: string;
          isMainScaleMapTasks: Array<{
            __typename?: 'ScaleMapTask';
            id: number;
            name: string;
            isMain?: boolean | null;
          } | null>;
        };
      }>;
      energyStatisticPerWeek: {
        __typename?: 'EnergyStatisticType';
        positiveEnergyPercent: number;
        negativeEnergyPercent: number;
        positiveEnergyTime: number;
        negativeEnergyTime: number;
      };
      energyStatisticPerMonth: {
        __typename?: 'EnergyStatisticType';
        positiveEnergyPercent: number;
        negativeEnergyPercent: number;
        positiveEnergyTime: number;
        negativeEnergyTime: number;
      };
    };
    days: Array<{
      __typename?: 'ChronographDay';
      date: string;
      hours: Array<{
        __typename?: 'ChronographHour';
        hour: number;
        energy?: string | null;
        scaleMapFileId?: number | null;
        scaleMapFile?: {
          __typename?: 'ScaleMapFile';
          id: number;
          color?: string | null;
          name: string;
        } | null;
      }>;
      dayRecords?: {
        __typename?: 'ChronographDayRecord';
        date: string;
        cell1?: string | null;
        cell2?: string | null;
        cell3?: string | null;
      } | null;
      statistic: {
        __typename?: 'ChronographDayStatistic';
        positiveEnergyPercent: number;
        negativeEnergyPercent: number;
        scaleMapFilesTracked: Array<{
          __typename?: 'ChronographDayStatScaleMapFile';
          scaleMapFileId: number;
          timeTracked: number;
          scaleMapFile: {
            __typename?: 'ScaleMapFile';
            id: number;
            color?: string | null;
            name: string;
          };
          scaleMapTask?: { __typename?: 'ScaleMapTask'; id: number; name: string } | null;
        }>;
      };
    }>;
  };
};

export type GetChronographDaysRecordsQueryVariables = Exact<{
  data: GetChronographDaysRecords;
}>;

export type GetChronographDaysRecordsQuery = {
  __typename?: 'Query';
  getChronographDaysRecords: Array<{
    __typename?: 'ChronographDayRecord';
    date: string;
    cell1?: string | null;
    cell2?: string | null;
    cell3?: string | null;
  }>;
};

export type GetChronographItemsQueryVariables = Exact<{
  data: GetChronographItemsInput;
}>;

export type GetChronographItemsQuery = {
  __typename?: 'Query';
  getChronographItems: Array<{
    __typename?: 'ChronographItemType';
    date: any;
    scaleMapFileId: number;
    scaleMapTaskId?: number | null;
    scaleMapFile: { __typename?: 'ScaleMapFile'; id: number; name: string };
    scaleMapTask?: { __typename?: 'ScaleMapTask'; id: number; name: string } | null;
  }>;
};

export type GetChronographSampleQueryVariables = Exact<{ [key: string]: never }>;

export type GetChronographSampleQuery = {
  __typename?: 'Query';
  getChronographSample: {
    __typename?: 'ChronographSample';
    id: number;
    createdAt: string;
    updatedAt: string;
    name?: string | null;
    isPublic: boolean;
    userId: number;
    author: { __typename?: 'User'; id?: number | null };
    sampleItems: Array<{
      __typename?: 'ChronographSampleItem';
      scaleMapFileId: number;
      chronographSampleId: number;
      scheduledTimePerDay?: number | null;
      color?: string | null;
      scaleMapFile: {
        __typename?: 'ScaleMapFile';
        id: number;
        color?: string | null;
        name: string;
      };
    }>;
  };
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = {
  __typename?: 'Query';
  getCurrentUser?: {
    __typename?: 'User';
    id?: number | null;
    login?: string | null;
    fullName?: string | null;
    theNote?: string | null;
    image?: string | null;
    role?: string | null;
    IsExistUnViewedForum: boolean;
    isAccessToStorageProjects: boolean;
    karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
  } | null;
};

export type GetCurrentUserCustomDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserCustomDataQuery = {
  __typename?: 'Query';
  getCurrentUser?: {
    __typename?: 'User';
    id?: number | null;
    login?: string | null;
    role?: string | null;
    IsExistUnViewedForum: boolean;
    assignedTasks: Array<{
      __typename?: 'Task';
      id: number;
      number: number;
      name: string;
      createdAt: string;
      project: { __typename?: 'Project'; id: number; name: string };
      status?: { __typename?: 'Status'; id: number } | null;
    } | null>;
    maintainedTasks: Array<{
      __typename?: 'Task';
      id: number;
      createdAt: string;
      number: number;
      name: string;
      project: { __typename?: 'Project'; id: number; name: string };
      status?: { __typename?: 'Status'; id: number } | null;
    } | null>;
  } | null;
};

export type GetCurrentUserDecideOnFlyQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserDecideOnFlyQuery = {
  __typename?: 'Query';
  getCurrentUser?: {
    __typename?: 'User';
    id?: number | null;
    currentUserDecideOnFlyActionData: {
      __typename?: 'GetRandomDecideOnFlyActionResponse';
      isAccessToGet: boolean;
      currentUserAction?: {
        __typename?: 'UserDecideOnFlyAction';
        id: number;
        userId: number;
        decideOnFlyActionId: number;
        createdAt: string;
        startDate?: any | null;
        endDate?: any | null;
        status: string;
        reasonForNotDone?: string | null;
        decideOnFlyAction?: {
          __typename?: 'DecideOnFlyAction';
          id: number;
          name: string;
        } | null;
      } | null;
    };
  } | null;
};

export type GetCurrentUserHistoryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  tagsId?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type GetCurrentUserHistoryQuery = {
  __typename?: 'Query';
  getCurrentUser?: {
    __typename?: 'User';
    id?: number | null;
    role?: string | null;
    IsExistUnViewedForum: boolean;
    viewHistory: Array<{
      __typename?: 'ViewHistory';
      id: number;
      viewedAt: string;
      task?: {
        __typename?: 'Task';
        id: number;
        number: number;
        name: string;
        code?: string | null;
        createdAt: string;
        project: {
          __typename?: 'Project';
          id: number;
          name: string;
          number?: number | null;
        };
        status?: { __typename?: 'Status'; id: number } | null;
        maintainer?: {
          __typename?: 'User';
          id?: number | null;
          login?: string | null;
          image?: string | null;
        } | null;
      } | null;
      subtask?: {
        __typename?: 'Subtask';
        id: number;
        number: number;
        name: string;
        code?: string | null;
        createdAt: string;
        status?: { __typename?: 'Status'; id: number } | null;
        task: {
          __typename?: 'Task';
          id: number;
          number: number;
          project: {
            __typename?: 'Project';
            id: number;
            name: string;
            number?: number | null;
          };
        };
        maintainer?: {
          __typename?: 'User';
          id?: number | null;
          login?: string | null;
          image?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetCurrentUserScaleQueryVariables = Exact<{
  statusId?: InputMaybe<Scalars['Int']>;
  isExcludeNullStatusTasks?: InputMaybe<Scalars['Boolean']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetCurrentUserScaleQuery = {
  __typename?: 'Query';
  getCurrentUser?: {
    __typename?: 'User';
    id?: number | null;
    login?: string | null;
    fullName?: string | null;
    IsExistUnViewedForum: boolean;
    theNote?: string | null;
    image?: string | null;
    role?: string | null;
    scaleMapFiles: {
      __typename?: 'ScaleMapFileList';
      count: number;
      rows: Array<{
        __typename?: 'ScaleMapFile';
        id: number;
        createdAt: string;
        updatedAt: string;
        name: string;
        color?: string | null;
        isDaily?: boolean | null;
        scheduledTime?: number | null;
        order?: number | null;
        project?: { __typename?: 'Project'; id: number } | null;
        scaleMapTasks: Array<{
          __typename?: 'ScaleMapTask';
          id: number;
          name: string;
          order: number;
          updatedAt: string;
          statusUpdatedDate?: string | null;
          column?: number | null;
          row?: number | null;
          counterComment?: string | null;
          counterFavorites?: boolean | null;
          status?: {
            __typename?: 'ScaleMapStatus';
            id: number;
            name: string;
            color: string;
          } | null;
          thread?: { __typename?: 'Thread'; id: number } | null;
          scaleMapTaskMarkStatus?: {
            __typename?: 'ScaleMapTaskMarkStatus';
            id: number;
            title: string;
            color: string;
          } | null;
          parentTree?: Array<{
            __typename?: 'ScaleMapTask';
            id: number;
            name: string;
            column?: number | null;
            updatedAt: string;
            statusUpdatedDate?: string | null;
            status?: {
              __typename?: 'ScaleMapStatus';
              id: number;
              name: string;
              color: string;
            } | null;
          }> | null;
        }>;
        statistics: {
          __typename?: 'ScaleMapFileStatistics';
          tasksCount: number;
          completedTasksPercent: number;
          inProgressTasksPercent: number;
          problemTasksCount: number;
          notInProgressTasksPercent: number;
          problemTasksPercent: number;
          isTasksCreatedToday: boolean;
          isTasksUpdatedToday: boolean;
        };
      } | null>;
    };
  } | null;
};

export type GetCurrentUserTagsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserTagsQuery = {
  __typename?: 'Query';
  getCurrentUser?: {
    __typename?: 'User';
    id?: number | null;
    tags: {
      __typename?: 'TagList';
      rows: Array<{
        __typename?: 'Tag';
        id: number;
        name: string;
        color?: string | null;
        textColor?: string | null;
      }>;
    };
  } | null;
};

export type GetDecideOnFlyPackagesForUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetDecideOnFlyPackagesForUserQuery = {
  __typename?: 'Query';
  getDecideOnFlyPackagesForUser: Array<{
    __typename?: 'DecideOnFlyPackage';
    id: number;
    title: string;
    color?: string | null;
    isActiveInCurrentUser?: boolean | null;
    description?: string | null;
  }>;
};

export type GetDecideOnFlyPeriodsQueryVariables = Exact<{ [key: string]: never }>;

export type GetDecideOnFlyPeriodsQuery = {
  __typename?: 'Query';
  getDecideOnFlyPeriods: Array<{
    __typename?: 'DecideOnFlyPeriod';
    id: number;
    periodName: string;
    isWeekend: boolean;
    decideOnFlyActions: Array<{
      __typename?: 'DecideOnFlyAction';
      id: number;
      name: string;
      chancePercent: number;
      decideOnFlyPeriodId: number;
      authorId: number;
      hourFrom?: string | null;
      hourTo?: string | null;
      decideOnFlyPackage?: {
        __typename?: 'DecideOnFlyPackage';
        color?: string | null;
      } | null;
    }>;
  }>;
};

export type GetExpensesQueryVariables = Exact<{
  expensesDate?: InputMaybe<Scalars['String']>;
  data: GetTasksInput;
}>;

export type GetExpensesQuery = {
  __typename?: 'Query';
  getExpenses: {
    __typename?: 'Expenses';
    amountPerMonth: number;
    amountPerYear: number;
    projects?: Array<{
      __typename?: 'Project';
      id: number;
      name: string;
      number?: number | null;
      tasks: {
        __typename?: 'TasksList';
        count: number;
        rows: Array<{
          __typename?: 'Task';
          id: number;
          number: number;
          name: string;
          code?: string | null;
          parentId?: number | null;
          startDate?: any | null;
          closeDate?: any | null;
          endDate?: any | null;
          realizationPrice?: number | null;
          realizationPerMonthAmount?: number | null;
          status?: { __typename?: 'Status'; id: number; color: string } | null;
          project: { __typename?: 'Project'; id: number; number?: number | null };
          tags: {
            __typename?: 'TagList';
            count: number;
            rows: Array<{
              __typename?: 'Tag';
              id: number;
              name: string;
              color?: string | null;
              textColor?: string | null;
              createdAt: string;
              updatedAt: string;
            }>;
          };
        } | null>;
      };
    }> | null;
  };
};

export type GetFileByIdQueryVariables = Exact<{
  id: Scalars['Float'];
}>;

export type GetFileByIdQuery = {
  __typename?: 'Query';
  getFileById: { __typename?: 'File'; id: number; fileName: string };
};

export type GetForumMessageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetForumMessageQuery = {
  __typename?: 'Query';
  getForumMessage: {
    __typename?: 'ForumMessage';
    id: number;
    createdAt: string;
    updatedAt: string;
    text: string;
    forumThreadId: number;
    userId: number;
  };
};

export type GetForumMessagesQueryVariables = Exact<{
  data?: InputMaybe<GetForumMessagesInput>;
}>;

export type GetForumMessagesQuery = {
  __typename?: 'Query';
  getForumMessages: {
    __typename?: 'ForumMessageList';
    count: number;
    rows: Array<{
      __typename?: 'ForumMessage';
      id: number;
      createdAt: string;
      updatedAt: string;
      text: string;
      forumThreadId: number;
      viewType: number;
      imagePath?: string | null;
      user: {
        __typename?: 'User';
        id?: number | null;
        onlineStatus: boolean;
        forumMessageCount: number;
        forumThreadAuthorCount: number;
        login?: string | null;
        image?: string | null;
        lastConnect?: string | null;
        karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
      };
      quote?: {
        __typename?: 'ForumMessage';
        id: number;
        text: string;
        createdAt: string;
        user: { __typename?: 'User'; login?: string | null; image?: string | null };
      } | null;
      reply?: {
        __typename?: 'ForumMessage';
        id: number;
        text: string;
        createdAt: string;
        imagePath?: string | null;
        viewType: number;
        quoteId?: number | null;
        user: {
          __typename?: 'User';
          login?: string | null;
          image?: string | null;
          forumMessageCount: number;
          karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
        };
      } | null;
    }>;
  };
};

export type GetForumSectionsQueryVariables = Exact<{
  data?: InputMaybe<GetForumSections>;
}>;

export type GetForumSectionsQuery = {
  __typename?: 'Query';
  getForumSections: {
    __typename?: 'ForumSectionList';
    count: number;
    rows: Array<{
      __typename?: 'ForumSection';
      id: number;
      createdAt: string;
      updatedAt: string;
      name: string;
      role?: ForumSectionRoles | null;
      isExistUnViewedForumThreads: boolean;
      threadCount: number;
    }>;
  };
};

export type GetForumStatisticsQueryVariables = Exact<{ [key: string]: never }>;

export type GetForumStatisticsQuery = {
  __typename?: 'Query';
  getForumStatistics: {
    __typename?: 'ForumStatistics';
    threadsCount: number;
    messagesCount: number;
  };
};

export type GetForumThreadQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetForumThreadQuery = {
  __typename?: 'Query';
  getForumThread: {
    __typename?: 'ForumThread';
    id: number;
    createdAt: string;
    updatedAt: string;
    name: string;
    description: string;
    imagePath?: string | null;
    authorAvatarPath?: string | null;
    forumSectionId: number;
    isClosed: boolean;
    viewType: number;
    author: {
      __typename?: 'User';
      id?: number | null;
      onlineStatus: boolean;
      forumMessageCount: number;
      forumThreadAuthorCount: number;
      login?: string | null;
      image?: string | null;
      lastConnect?: string | null;
      karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
    };
    forumMessages: {
      __typename?: 'ForumMessageList';
      rows: Array<{
        __typename?: 'ForumMessage';
        id: number;
        createdAt: string;
        updatedAt: string;
        text: string;
        forumThreadId: number;
      }>;
    };
  };
};

export type GetForumThreadsQueryVariables = Exact<{
  data: GetForumThreadsInput;
}>;

export type GetForumThreadsQuery = {
  __typename?: 'Query';
  getForumThreads: {
    __typename?: 'ForumThreadList';
    count: number;
    rows: Array<{
      __typename?: 'ForumThread';
      id: number;
      createdAt: string;
      updatedAt: string;
      name: string;
      description: string;
      imagePath?: string | null;
      authorAvatarPath?: string | null;
      forumSectionId: number;
      authorId: number;
      viewType: number;
      isAttached: boolean;
      isClosed: boolean;
      isViewed?: boolean | null;
      author: {
        __typename?: 'User';
        id?: number | null;
        onlineStatus: boolean;
        forumMessageCount: number;
        forumThreadAuthorCount: number;
        login?: string | null;
        image?: string | null;
        lastConnect?: string | null;
        karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
      };
      forumMessages: {
        __typename?: 'ForumMessageList';
        count: number;
        rows: Array<{
          __typename?: 'ForumMessage';
          id: number;
          user: {
            __typename?: 'User';
            id?: number | null;
            onlineStatus: boolean;
            forumMessageCount: number;
            forumThreadAuthorCount: number;
            login?: string | null;
            image?: string | null;
            lastConnect?: string | null;
            karmaStatistics?: {
              __typename?: 'UserKarmaStatistics';
              karma: number;
            } | null;
          };
        }>;
      };
    }>;
  };
};

export type GetModeratorProjectsIdsQueryVariables = Exact<{
  data: GetProjectsInput;
}>;

export type GetModeratorProjectsIdsQuery = {
  __typename?: 'Query';
  getProjects: {
    __typename?: 'ProjectsList';
    rows: Array<{ __typename?: 'Project'; id: number } | null>;
  };
};

export type GetMyProjectsRoleMaintainerQueryVariables = Exact<{
  data: GetMyProjectsInput;
  tagsId?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type GetMyProjectsRoleMaintainerQuery = {
  __typename?: 'Query';
  getMyProjectsRoleMaintainer: {
    __typename?: 'MyProjectsList';
    count: number;
    rows: Array<{
      __typename?: 'MyProject';
      id: string;
      name: string;
      number?: number | null;
      myTaskSubTaskList: {
        __typename?: 'TasksList';
        count: number;
        rows: Array<{
          __typename?: 'Task';
          id: number;
          name: string;
          code?: string | null;
          parentId?: number | null;
          maintainerComment?: string | null;
          realizationPrice?: number | null;
          realizationPerMonthAmount?: number | null;
          status?: { __typename?: 'Status'; id: number } | null;
          tags: {
            __typename?: 'TagList';
            count: number;
            rows: Array<{
              __typename?: 'Tag';
              id: number;
              name: string;
              color?: string | null;
              textColor?: string | null;
              createdAt: string;
              updatedAt: string;
            }>;
          };
        } | null>;
      };
    } | null>;
  };
};

export type GetMyTasksSubtasksQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyTasksSubtasksQuery = {
  __typename?: 'Query';
  getCurrentUser?: {
    __typename?: 'User';
    id?: number | null;
    myTasksSubtasks: Array<{
      __typename?: 'Task';
      id: number;
      parentId?: number | null;
      name: string;
      code?: string | null;
      createdAt: string;
      updatedAt: string;
      status?: { __typename?: 'Status'; id: number } | null;
      project: {
        __typename?: 'Project';
        id: number;
        name: string;
        number?: number | null;
      };
      maintainer?: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
      } | null;
      tags: {
        __typename?: 'TagList';
        count: number;
        rows: Array<{
          __typename?: 'Tag';
          id: number;
          name: string;
          color?: string | null;
          textColor?: string | null;
          createdAt: string;
          updatedAt: string;
        }>;
      };
    } | null>;
  } | null;
};

export type GetPoligonQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetPoligonQuery = {
  __typename?: 'Query';
  getPoligon: {
    __typename?: 'Poligon';
    id: number;
    name: string;
    owner: {
      __typename?: 'User';
      id?: number | null;
      login?: string | null;
      role?: string | null;
    };
    project?: { __typename?: 'Project'; id: number; name: string } | null;
    colors?: Array<{
      __typename?: 'PoligonColor';
      id: number;
      createdAt: string;
      updatedAt: string;
      name: string;
      color: string;
    }> | null;
    rectangles?: Array<{
      __typename?: 'PoligonRect';
      id: number;
      x: number;
      y: number;
      width: number;
      height: number;
      fill: string;
      text: string;
      textObj: any;
      name: string;
      draggable: boolean;
      nameArray: Array<string>;
      parentLink?: number | null;
      alignName?: string | null;
      frontendData?: string | null;
      taskLink?: { __typename?: 'LinkTypeModel'; taskId?: number | null } | null;
      file?: { __typename?: 'File'; id: number; path: string } | null;
      statistics: {
        __typename?: 'PoligonReactStatistics';
        tasksCount: number;
        tasksDonePercentage: number;
        tasksInProgressPercentage: number;
        tasksGreyPercentage: number;
        treeCount: number;
      };
    }> | null;
    arrows?: Array<{
      __typename?: 'PoligonArrow';
      id: number;
      startX: number;
      startY: number;
      endX: number;
      endY: number;
      fill: string;
    }> | null;
    objects?: Array<{
      __typename?: 'PoligonObject';
      id: number;
      name: string;
      description?: string | null;
      parent?: Array<{ __typename?: 'PoligonObject'; id: number }> | null;
      tasksSubtasks?: { __typename?: 'TasksList'; count: number } | null;
    }> | null;
  };
};

export type GetPoligonObjectQueryVariables = Exact<{
  id: Scalars['Int'];
  subtasksData: GetSubtasksInput;
}>;

export type GetPoligonObjectQuery = {
  __typename?: 'Query';
  getPoligonObject: {
    __typename?: 'PoligonObject';
    id: number;
    name: string;
    description?: string | null;
    poligon?: { __typename?: 'Poligon'; id: number } | null;
    parent?: Array<{ __typename?: 'PoligonObject'; id: number; name: string }> | null;
    task?: Array<{
      __typename?: 'Task';
      id: number;
      code?: string | null;
      number: number;
      endDate?: any | null;
      name: string;
      project: {
        __typename?: 'Project';
        id: number;
        name: string;
        number?: number | null;
      };
      poligonObject?: { __typename?: 'PoligonObject'; id: number; name: string } | null;
      maintainer?: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
        fullName?: string | null;
      } | null;
      status?: { __typename?: 'Status'; id: number; color: string } | null;
      tags: {
        __typename?: 'TagList';
        count: number;
        rows: Array<{
          __typename?: 'Tag';
          id: number;
          createdAt: string;
          updatedAt: string;
          name: string;
          color?: string | null;
          textColor?: string | null;
        }>;
      };
    }> | null;
    subtask?: Array<{
      __typename?: 'Subtask';
      id: number;
      code?: string | null;
      number: number;
      endDate?: any | null;
      name: string;
      task: {
        __typename?: 'Task';
        id: number;
        name: string;
        project: {
          __typename?: 'Project';
          id: number;
          name: string;
          number?: number | null;
        };
      };
      poligonObject?: { __typename?: 'PoligonObject'; id: number; name: string } | null;
      maintainer?: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
        fullName?: string | null;
      } | null;
      status?: { __typename?: 'Status'; id: number; color: string } | null;
      tags: {
        __typename?: 'TagList';
        count: number;
        rows: Array<{
          __typename?: 'Tag';
          id: number;
          createdAt: string;
          updatedAt: string;
          name: string;
          color?: string | null;
          textColor?: string | null;
        }>;
      };
    }> | null;
    rectangles?: Array<{
      __typename?: 'PoligonRect';
      id: number;
      x: number;
      y: number;
      width: number;
      height: number;
      fill: string;
      text: string;
      textObj: any;
      name: string;
      draggable: boolean;
      nameArray: Array<string>;
      parentLink?: number | null;
      alignName?: string | null;
      frontendData?: string | null;
      taskLink?: { __typename?: 'LinkTypeModel'; taskId?: number | null } | null;
      task?: { __typename?: 'Task'; id: number } | null;
      file?: { __typename?: 'File'; id: number; path: string } | null;
    }> | null;
    tasksSubtasks?: {
      __typename?: 'TasksList';
      count: number;
      rows: Array<{
        __typename?: 'Task';
        id: number;
        name: string;
        number: number;
        parentId?: number | null;
        code?: string | null;
        endDate?: any | null;
        project: {
          __typename?: 'Project';
          id: number;
          number?: number | null;
          name: string;
        };
        maintainer?: {
          __typename?: 'User';
          id?: number | null;
          login?: string | null;
          image?: string | null;
        } | null;
        status?: { __typename?: 'Status'; id: number } | null;
        tags: {
          __typename?: 'TagList';
          count: number;
          rows: Array<{
            __typename?: 'Tag';
            id: number;
            createdAt: string;
            updatedAt: string;
            name: string;
            color?: string | null;
            textColor?: string | null;
          }>;
        };
        poligonObject?: { __typename?: 'PoligonObject'; id: number } | null;
        subtasks: Array<{
          __typename?: 'Subtask';
          id: number;
          name: string;
          number: number;
          code?: string | null;
        } | null>;
      } | null>;
    } | null;
  };
};

export type GetPoligonObjectsQueryVariables = Exact<{
  data?: InputMaybe<GetPoligonObjectsInput>;
}>;

export type GetPoligonObjectsQuery = {
  __typename?: 'Query';
  getPoligonObjects: {
    __typename?: 'PoligonObjectsList';
    count: number;
    rows: Array<{
      __typename?: 'PoligonObject';
      id: number;
      name: string;
      description?: string | null;
      createdAt: string;
      poligon?: {
        __typename?: 'Poligon';
        id: number;
        name: string;
        project?: { __typename?: 'Project'; id: number } | null;
      } | null;
    }>;
  };
};

export type GetPoligonTemplateQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetPoligonTemplateQuery = {
  __typename?: 'Query';
  getPoligonTemplate: {
    __typename?: 'PoligonTemplate';
    id: number;
    createdAt: string;
    updatedAt: string;
    name: string;
    canvasObject: any;
  };
};

export type GetPoligonTemplatesQueryVariables = Exact<{
  data: GetPoligonTemplateInput;
}>;

export type GetPoligonTemplatesQuery = {
  __typename?: 'Query';
  getPoligonTemplates: {
    __typename?: 'PoligonTemplateList';
    count: number;
    rows: Array<{
      __typename?: 'PoligonTemplate';
      id: number;
      createdAt: string;
      updatedAt: string;
      name: string;
      canvasObject: any;
    }>;
  };
};

export type GetPoligonsQueryVariables = Exact<{
  data?: InputMaybe<GetPoligonsInput>;
}>;

export type GetPoligonsQuery = {
  __typename?: 'Query';
  getPoligons: {
    __typename?: 'PoligonList';
    count: number;
    rows: Array<{
      __typename?: 'Poligon';
      id: number;
      name: string;
      createdAt: string;
      owner: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        role?: string | null;
      };
      project?: { __typename?: 'Project'; id: number; name: string } | null;
    }>;
  };
};

export type GetPrivateChatQueryVariables = Exact<{
  userId: Scalars['Int'];
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type GetPrivateChatQuery = {
  __typename?: 'Query';
  getPrivateChat: {
    __typename?: 'Chat';
    id: number;
    messagesList: {
      __typename?: 'MessagesList';
      count: number;
      rows: Array<{
        __typename?: 'Message';
        isRead?: boolean | null;
        isPossibleSetKarma: boolean;
        isActivity?: boolean | null;
        isDidYouRead?: boolean | null;
        id: number;
        text: string;
        createdAt: any;
        user: {
          __typename?: 'User';
          id?: number | null;
          login?: string | null;
          image?: string | null;
          createdAt?: string | null;
          karmaStatistics?: {
            __typename?: 'UserKarmaStatistics';
            karma: number;
            items: Array<{
              __typename?: 'Karma';
              id: number;
              createdAt: string;
              isPlus: boolean;
              messageId: number;
              user: { __typename?: 'User'; login?: string | null; image?: string | null };
            }>;
          } | null;
        };
      } | null>;
    };
    logs: {
      __typename?: 'MessagesList';
      count: number;
      rows: Array<{
        __typename?: 'Message';
        isRead?: boolean | null;
        isPossibleSetKarma: boolean;
        isActivity?: boolean | null;
        isDidYouRead?: boolean | null;
        id: number;
        text: string;
        createdAt: any;
        user: {
          __typename?: 'User';
          id?: number | null;
          login?: string | null;
          image?: string | null;
          createdAt?: string | null;
          karmaStatistics?: {
            __typename?: 'UserKarmaStatistics';
            karma: number;
            items: Array<{
              __typename?: 'Karma';
              id: number;
              createdAt: string;
              isPlus: boolean;
              messageId: number;
              user: { __typename?: 'User'; login?: string | null; image?: string | null };
            }>;
          } | null;
        };
      } | null>;
    };
  };
};

export type GetPrivateChatNotesQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;

export type GetPrivateChatNotesQuery = {
  __typename?: 'Query';
  getPrivateChat: {
    __typename?: 'Chat';
    notes: Array<{
      __typename?: 'Note';
      id: number;
      createdAt: string;
      chatId?: number | null;
      taskId?: number | null;
      content?: string | null;
      owner?: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
        role?: string | null;
      } | null;
    } | null>;
  };
};

export type GetPrivateChatsQueryVariables = Exact<{
  data: GetChatsInput;
}>;

export type GetPrivateChatsQuery = {
  __typename?: 'Query';
  getPrivateChats: {
    __typename?: 'GetAllUsers';
    count: number;
    rows: Array<{
      __typename?: 'User';
      id?: number | null;
      login?: string | null;
      image?: string | null;
      role?: string | null;
      onlineStatus: boolean;
      karmaStatistics?: {
        __typename?: 'UserKarmaStatistics';
        karma: number;
        items: Array<{
          __typename?: 'Karma';
          id: number;
          isPlus: boolean;
          userId: number;
          messageId: number;
        }>;
      } | null;
      chats?: Array<{
        __typename?: 'Chat';
        id: number;
        unreadMessagesCount: number;
        name?: string | null;
        messagesList: {
          __typename?: 'MessagesList';
          count: number;
          rows: Array<{
            __typename?: 'Message';
            id: number;
            text: string;
            isRead?: boolean | null;
            user: { __typename?: 'User'; id?: number | null; login?: string | null };
          } | null>;
        };
      }> | null;
    }>;
  };
};

export type GetProjectQueryVariables = Exact<{
  id: Scalars['Int'];
  tasksData: GetTasksInput;
}>;

export type GetProjectQuery = {
  __typename?: 'Query';
  getProject: {
    __typename?: 'Project';
    id: number;
    name: string;
    number?: number | null;
    createdAt: string;
    updatedAt: string;
    poligon?: { __typename?: 'Poligon'; id: number } | null;
    author?: {
      __typename?: 'User';
      id?: number | null;
      image?: string | null;
      login?: string | null;
    } | null;
    tasks: {
      __typename?: 'TasksList';
      count: number;
      rows: Array<{
        __typename?: 'Task';
        id: number;
        number: number;
        name: string;
        code?: string | null;
        startDate?: any | null;
        closeDate?: any | null;
        endDate?: any | null;
        realizationPerMonthAmount?: number | null;
        realizationPrice?: number | null;
        project: {
          __typename?: 'Project';
          id: number;
          name: string;
          number?: number | null;
        };
        status?: { __typename?: 'Status'; id: number; color: string } | null;
        author?: {
          __typename?: 'User';
          id?: number | null;
          image?: string | null;
          login?: string | null;
          role?: string | null;
          karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
        } | null;
        assignees: Array<{
          __typename?: 'User';
          id?: number | null;
          image?: string | null;
          login?: string | null;
          role?: string | null;
          karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
        } | null>;
        maintainer?: {
          __typename?: 'User';
          id?: number | null;
          login?: string | null;
          image?: string | null;
          fullName?: string | null;
          karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
        } | null;
        subtasks: Array<{
          __typename?: 'Subtask';
          id: number;
          createdAt: string;
          name: string;
          number: number;
          status?: { __typename?: 'Status'; id: number; color: string } | null;
          task: {
            __typename?: 'Task';
            id: number;
            number: number;
            project: { __typename?: 'Project'; id: number; name: string };
            poligonObject?: { __typename?: 'PoligonObject'; id: number } | null;
          };
          tags: {
            __typename?: 'TagList';
            count: number;
            rows: Array<{
              __typename?: 'Tag';
              id: number;
              createdAt: string;
              updatedAt: string;
              name: string;
              color?: string | null;
              textColor?: string | null;
            }>;
          };
        } | null>;
        tags: {
          __typename?: 'TagList';
          count: number;
          rows: Array<{
            __typename?: 'Tag';
            id: number;
            createdAt: string;
            updatedAt: string;
            name: string;
            color?: string | null;
          }>;
        };
      } | null>;
    };
  };
};

export type GetProjectActivitiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetProjectActivitiesQuery = {
  __typename?: 'Query';
  getProjectActivities: Array<{
    __typename?: 'ProjectActivity';
    id: number;
    action: string;
    createdAt: string;
    actioner: {
      __typename?: 'User';
      id?: number | null;
      login?: string | null;
      image?: string | null;
      karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
    };
    project?: { __typename?: 'Project'; id: number; name: string } | null;
    task?: {
      __typename?: 'Task';
      id: number;
      name: string;
      code?: string | null;
      maintainer?: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
        karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
      } | null;
    } | null;
    subtask?: {
      __typename?: 'Subtask';
      id: number;
      name: string;
      code?: string | null;
      maintainer?: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
        karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
      } | null;
    } | null;
    thread?: {
      __typename?: 'Thread';
      id: number;
      meta: Array<{
        __typename?: 'ScaleMapTask';
        id: number;
        name: string;
        status?: { __typename?: 'ScaleMapStatus'; color: string } | null;
      } | null>;
      scaleMapTask?: { __typename?: 'ScaleMapTask'; name: string } | null;
    } | null;
  }>;
};

export type GetProjectLightQueryVariables = Exact<{
  id: Scalars['Int'];
  tasksData: GetTasksInput;
}>;

export type GetProjectLightQuery = {
  __typename?: 'Query';
  getProject: {
    __typename?: 'Project';
    id: number;
    name: string;
    author?: { __typename?: 'User'; id?: number | null; login?: string | null } | null;
    maintainer?: {
      __typename?: 'User';
      id?: number | null;
      login?: string | null;
    } | null;
    tasksSubtasks: {
      __typename?: 'TasksList';
      count: number;
      rows: Array<{
        __typename?: 'Task';
        id: number;
        name: string;
        code?: string | null;
        poligonObject?: { __typename?: 'PoligonObject'; id: number } | null;
      } | null>;
    };
  };
};

export type GetProjectsQueryVariables = Exact<{
  data: GetProjectsInput;
  tasksData: GetTasksInput;
}>;

export type GetProjectsQuery = {
  __typename?: 'Query';
  getProjects: {
    __typename?: 'ProjectsList';
    count: number;
    rows: Array<{
      __typename?: 'Project';
      id: number;
      name: string;
      number?: number | null;
      createdAt: string;
      updatedAt: string;
      poligon?: { __typename?: 'Poligon'; id: number } | null;
      author?: {
        __typename?: 'User';
        id?: number | null;
        image?: string | null;
        login?: string | null;
        karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
      } | null;
      tasks: {
        __typename?: 'TasksList';
        count: number;
        rows: Array<{
          __typename?: 'Task';
          id: number;
          number: number;
          name: string;
          code?: string | null;
          startDate?: any | null;
          closeDate?: any | null;
          endDate?: any | null;
          realizationPerMonthAmount?: number | null;
          realizationPrice?: number | null;
          status?: { __typename?: 'Status'; id: number; color: string } | null;
          project: { __typename?: 'Project'; id: number; number?: number | null };
          author?: {
            __typename?: 'User';
            id?: number | null;
            image?: string | null;
            login?: string | null;
            role?: string | null;
          } | null;
          assignees: Array<{
            __typename?: 'User';
            id?: number | null;
            image?: string | null;
            login?: string | null;
            role?: string | null;
          } | null>;
          maintainer?: {
            __typename?: 'User';
            id?: number | null;
            login?: string | null;
            image?: string | null;
            karmaStatistics?: {
              __typename?: 'UserKarmaStatistics';
              karma: number;
            } | null;
          } | null;
          tags: {
            __typename?: 'TagList';
            count: number;
            rows: Array<{
              __typename?: 'Tag';
              id: number;
              name: string;
              color?: string | null;
              textColor?: string | null;
              createdAt: string;
              updatedAt: string;
            }>;
          };
          poligonObject?: { __typename?: 'PoligonObject'; id: number } | null;
        } | null>;
      };
    } | null>;
  };
};

export type GetProjectsCalendarQueryVariables = Exact<{
  data: GetProjectsInput;
  tasksSubtasksData: GetTasksInput;
}>;

export type GetProjectsCalendarQuery = {
  __typename?: 'Query';
  getProjects: {
    __typename?: 'ProjectsList';
    count: number;
    rows: Array<{
      __typename?: 'Project';
      id: number;
      name: string;
      number?: number | null;
      createdAt: string;
      updatedAt: string;
      tasksSubtasks: {
        __typename?: 'TasksList';
        count: number;
        rows: Array<{
          __typename?: 'Task';
          id: number;
          number: number;
          name: string;
          code?: string | null;
          parentId?: number | null;
          startDate?: any | null;
          closeDate?: any | null;
          endDate?: any | null;
          status?: { __typename?: 'Status'; id: number; color: string } | null;
          project: { __typename?: 'Project'; id: number; number?: number | null };
          tags: {
            __typename?: 'TagList';
            count: number;
            rows: Array<{
              __typename?: 'Tag';
              id: number;
              name: string;
              color?: string | null;
              textColor?: string | null;
              createdAt: string;
              updatedAt: string;
            }>;
          };
        } | null>;
      };
    } | null>;
  };
};

export type GetProjectsScaleQueryVariables = Exact<{
  data: GetProjectsInput;
  statusId?: InputMaybe<Scalars['Int']>;
  isExcludeNullStatusTasks?: InputMaybe<Scalars['Boolean']>;
  dataFiles?: InputMaybe<ScaleMapFilesInput>;
}>;

export type GetProjectsScaleQuery = {
  __typename?: 'Query';
  getProjects: {
    __typename?: 'ProjectsList';
    count: number;
    rows: Array<{
      __typename?: 'Project';
      id: number;
      name: string;
      number?: number | null;
      createdAt: string;
      updatedAt: string;
      poligon?: { __typename?: 'Poligon'; id: number } | null;
      author?: {
        __typename?: 'User';
        id?: number | null;
        image?: string | null;
        login?: string | null;
      } | null;
      scaleMapFiles: Array<{
        __typename?: 'ScaleMapFile';
        id: number;
        createdAt: string;
        updatedAt: string;
        name: string;
        isPersonal: boolean;
        project?: { __typename?: 'Project'; id: number } | null;
        scaleMapTasks: Array<{
          __typename?: 'ScaleMapTask';
          id: number;
          name: string;
          order: number;
          updatedAt: string;
          statusUpdatedDate?: string | null;
          column?: number | null;
          row?: number | null;
          counterComment?: string | null;
          counterFavorites?: boolean | null;
          status?: {
            __typename?: 'ScaleMapStatus';
            id: number;
            name: string;
            color: string;
          } | null;
          thread?: { __typename?: 'Thread'; id: number } | null;
          scaleMapTaskMarkStatus?: {
            __typename?: 'ScaleMapTaskMarkStatus';
            id: number;
            title: string;
            color: string;
          } | null;
          parentTree?: Array<{
            __typename?: 'ScaleMapTask';
            id: number;
            name: string;
            column?: number | null;
            updatedAt: string;
            statusUpdatedDate?: string | null;
            scaleMapTaskMarkStatus?: {
              __typename?: 'ScaleMapTaskMarkStatus';
              id: number;
              title: string;
              color: string;
            } | null;
            thread?: { __typename?: 'Thread'; id: number } | null;
            status?: {
              __typename?: 'ScaleMapStatus';
              id: number;
              name: string;
              color: string;
            } | null;
          }> | null;
        }>;
        statistics: {
          __typename?: 'ScaleMapFileStatistics';
          tasksCount: number;
          completedTasksPercent: number;
          inProgressTasksPercent: number;
          problemTasksCount: number;
          notInProgressTasksPercent: number;
          problemTasksPercent: number;
          isTasksCreatedToday: boolean;
          isTasksUpdatedToday: boolean;
        };
      }>;
    } | null>;
  };
};

export type GetProjectsScaleOptimizeQueryVariables = Exact<{
  data: GetProjectsInput;
  dataFiles?: InputMaybe<ScaleMapFilesInput>;
}>;

export type GetProjectsScaleOptimizeQuery = {
  __typename?: 'Query';
  getProjects: {
    __typename?: 'ProjectsList';
    count: number;
    rows: Array<{
      __typename?: 'Project';
      id: number;
      name: string;
      number?: number | null;
      scaleMapFiles: Array<{
        __typename?: 'ScaleMapFile';
        id: number;
        name: string;
        isPersonal: boolean;
        statistics: {
          __typename?: 'ScaleMapFileStatistics';
          tasksCount: number;
          completedTasksPercent: number;
          inProgressTasksPercent: number;
          problemTasksCount: number;
          notInProgressTasksPercent: number;
          problemTasksPercent: number;
          isTasksCreatedToday: boolean;
          isTasksUpdatedToday: boolean;
        };
      }>;
    } | null>;
  };
};

export type GetProjectsStorageAccesQueryVariables = Exact<{
  data: GetProjectsInput;
}>;

export type GetProjectsStorageAccesQuery = {
  __typename?: 'Query';
  getProjects: {
    __typename?: 'ProjectsList';
    count: number;
    rows: Array<{ __typename?: 'Project'; id: number; name: string } | null>;
  };
};

export type GetProjectsUserQueryVariables = Exact<{
  data: GetProjectsInput;
  tasksData: GetTasksInput;
  userId?: InputMaybe<Scalars['Int']>;
}>;

export type GetProjectsUserQuery = {
  __typename?: 'Query';
  getProjects: {
    __typename?: 'ProjectsList';
    count: number;
    rows: Array<{
      __typename?: 'Project';
      id: number;
      name: string;
      number?: number | null;
      createdAt: string;
      updatedAt: string;
      poligon?: { __typename?: 'Poligon'; id: number } | null;
      author?: {
        __typename?: 'User';
        id?: number | null;
        image?: string | null;
        login?: string | null;
      } | null;
      tasksSubtasks: {
        __typename?: 'TasksList';
        count: number;
        rows: Array<{
          __typename?: 'Task';
          id: number;
          parentId?: number | null;
          number: number;
          description?: string | null;
          name: string;
          code?: string | null;
          startDate?: any | null;
          closeDate?: any | null;
          endDate?: any | null;
          realizationPerMonthAmount?: number | null;
          realizationPrice?: number | null;
          status?: { __typename?: 'Status'; id: number; color: string } | null;
          project: { __typename?: 'Project'; id: number; number?: number | null };
          author?: {
            __typename?: 'User';
            id?: number | null;
            image?: string | null;
            login?: string | null;
            role?: string | null;
          } | null;
          assignees: Array<{
            __typename?: 'User';
            id?: number | null;
            image?: string | null;
            login?: string | null;
            role?: string | null;
          } | null>;
          maintainer?: {
            __typename?: 'User';
            id?: number | null;
            login?: string | null;
            image?: string | null;
          } | null;
          tags: {
            __typename?: 'TagList';
            count: number;
            rows: Array<{
              __typename?: 'Tag';
              id: number;
              name: string;
              color?: string | null;
              textColor?: string | null;
              createdAt: string;
              updatedAt: string;
            }>;
          };
          poligonObject?: { __typename?: 'PoligonObject'; id: number } | null;
        } | null>;
      };
    } | null>;
  };
};

export type GetScaleMapFileQueryVariables = Exact<{
  getScaleMapFileId: Scalars['Int'];
  statusId?: InputMaybe<Scalars['Int']>;
  excludeStatusId?: InputMaybe<Scalars['Int']>;
}>;

export type GetScaleMapFileQuery = {
  __typename?: 'Query';
  getScaleMapFile: {
    __typename?: 'ScaleMapFile';
    id: number;
    name: string;
    isPersonal: boolean;
    columnName1: string;
    columnName2: string;
    columnName3: string;
    calendarTaskTextColor?: string | null;
    isStatus1ExcludedInFilter?: boolean | null;
    isBridgeOfSleep: boolean;
    isPublic: boolean;
    isArchived: boolean;
    project?: {
      __typename?: 'Project';
      id: number;
      number?: number | null;
      name: string;
    } | null;
    user?: { __typename?: 'User'; id?: number | null; login?: string | null } | null;
    scaleMapTasks: Array<{
      __typename?: 'ScaleMapTask';
      id: number;
      name: string;
      column?: number | null;
      row?: number | null;
      calendarTaskTextColor?: string | null;
      order: number;
      scaleMapTaskId?: number | null;
      isExclamationPoint?: boolean | null;
      isNestingAllTasksWithCompleted?: boolean | null;
      isMain?: boolean | null;
      nestingTaskCount?: number | null;
      tags: Array<{
        __typename?: 'Tag';
        id: number;
        name: string;
        color?: string | null;
        textColor?: string | null;
      }>;
      thread?: { __typename?: 'Thread'; id: number } | null;
      status?: {
        __typename?: 'ScaleMapStatus';
        id: number;
        name: string;
        color: string;
      } | null;
      statistics?: {
        __typename?: 'ScaleMapTaskStatistics';
        maxStatusInNestingTasksPercent?: number | null;
        maxStatusInNestingTasks?: {
          __typename?: 'ScaleMapStatus';
          id: number;
          name: string;
          color: string;
        } | null;
      } | null;
    }>;
    statistics: {
      __typename?: 'ScaleMapFileStatistics';
      tasksCount: number;
      completedTasksPercent: number;
      inProgressTasksPercent: number;
      problemTasksCount: number;
      notInProgressTasksPercent: number;
      problemTasksPercent: number;
    };
    scaleMapColumnStatistics: Array<{
      __typename?: 'ScaleMapColumnStatisticsType';
      column: number;
      row: number;
      statusTasksPercent?: number | null;
      statusTasks?: {
        __typename?: 'ScaleMapStatus';
        id: number;
        name: string;
        color: string;
      } | null;
    }>;
    mergedColumnStatistics: Array<{
      __typename?: 'ScaleMapMergedColumnStatisticsType';
      column?: number | null;
      row: number;
      statusTasksPercent?: number | null;
      statusTasks?: {
        __typename?: 'ScaleMapStatus';
        id: number;
        name: string;
        color: string;
      } | null;
    }>;
  };
};

export type GetScaleMapFileTagsAndFonsQueryVariables = Exact<{
  getScaleMapFileId: Scalars['Int'];
}>;

export type GetScaleMapFileTagsAndFonsQuery = {
  __typename?: 'Query';
  getScaleMapFile: {
    __typename?: 'ScaleMapFile';
    attachedTags: Array<{ __typename?: 'Tag'; id: number }>;
    threadBackground?: { __typename?: 'ThreadBackground'; id: number } | null;
  };
};

export type GetScaleMapStatusesQueryVariables = Exact<{ [key: string]: never }>;

export type GetScaleMapStatusesQuery = {
  __typename?: 'Query';
  getScaleMapStatuses: Array<{
    __typename?: 'ScaleMapStatus';
    id: number;
    name: string;
    color: string;
  }>;
};

export type GetScaleMapTaskHistoryStatisticsQueryVariables = Exact<{
  date: Scalars['String'];
  userId?: InputMaybe<Scalars['Int']>;
}>;

export type GetScaleMapTaskHistoryStatisticsQuery = {
  __typename?: 'Query';
  getScaleMapTaskHistoryStatistics: Array<{
    __typename?: 'ScaleMapTaskHistoryDate';
    date: string;
    historyTypes: Array<{
      __typename?: 'ScaleMapTaskHistoryType';
      type: string;
      items: Array<{
        __typename?: 'ScaleMapTaskHistoryStatItem';
        statusId: number;
        count: number;
        status: {
          __typename?: 'ScaleMapStatus';
          id: number;
          name: string;
          color: string;
        };
        scaleMapTasks: Array<{
          __typename?: 'ScaleMapTask';
          id: number;
          name: string;
          order: number;
          createdAt: string;
          updatedAt: string;
          statusUpdatedDate?: string | null;
          creationScaleMapStatusId?: number | null;
          scaleMapFile?: {
            __typename?: 'ScaleMapFile';
            id: number;
            name: string;
            project?: { __typename?: 'Project'; id: number; name: string } | null;
            user?: { __typename?: 'User'; id?: number | null } | null;
          } | null;
        }>;
      }>;
    }>;
  }>;
};

export type SearchThreadsQueryVariables = Exact<{
  search: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type SearchThreadsQuery = {
  __typename?: 'Query';
  searchThreads: {
    __typename?: 'GetThreadsAndCount';
    count: number;
    rows: Array<{
      __typename?: 'Thread';
      id: number;
      createdAt: string;
      updatedAt: string;
      authorId: number;
      cover?: string | null;
      mainPerformerId?: number | null;
      performerStatus?: string | null;
      author: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
      };
      notes: Array<{ __typename?: 'Note'; id: number }>;
      statistic: { __typename?: 'ThreadStatistic'; performerAcceptedPercent: number };
      scaleMapTask?: {
        __typename?: 'ScaleMapTask';
        id: number;
        name: string;
        children?: Array<{
          __typename?: 'ScaleMapTask';
          id: number;
          name: string;
          status?: { __typename?: 'ScaleMapStatus'; color: string } | null;
        }> | null;
      } | null;
      performers: Array<{
        __typename?: 'ThreadPerformer';
        threadId: number;
        userId: number;
        status: string;
        user: {
          __typename?: 'User';
          id?: number | null;
          login?: string | null;
          image?: string | null;
          IsExistUnViewedForum: boolean;
          role?: string | null;
          fullName?: string | null;
        };
      }>;
      mainPerformer?: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
        IsExistUnViewedForum: boolean;
        role?: string | null;
        fullName?: string | null;
      } | null;
      tags: Array<{
        __typename?: 'Tag';
        id: number;
        name: string;
        color?: string | null;
        textColor?: string | null;
      } | null>;
      meta: Array<{
        __typename?: 'ScaleMapTask';
        id: number;
        name: string;
        isMain?: boolean | null;
        isCurrent?: boolean | null;
        status?: { __typename?: 'ScaleMapStatus'; color: string } | null;
      } | null>;
      userLastEdited?: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        tags: {
          __typename?: 'TagList';
          rows: Array<{
            __typename?: 'Tag';
            id: number;
            name: string;
            color?: string | null;
            textColor?: string | null;
          }>;
        };
      } | null;
    }>;
  };
};

export type GetServerTimeQueryVariables = Exact<{ [key: string]: never }>;

export type GetServerTimeQuery = {
  __typename?: 'Query';
  getServerTime?: { __typename?: 'ServerTimeType'; timeZone: string } | null;
};

export type GetSocialCircleStatisticsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSocialCircleStatisticsQuery = {
  __typename?: 'Query';
  getSocialCircleStatistics: {
    __typename?: 'SocialCircleStatisticsEntity';
    totalObjectsInCircle: number;
    totalAppointedMeetings: number;
    daysSinceFirstMeetingCreation?: number | null;
  };
};

export type GetStatusesQueryVariables = Exact<{ [key: string]: never }>;

export type GetStatusesQuery = {
  __typename?: 'Query';
  getStatuses: Array<{ __typename?: 'Status'; id: number; color: string }>;
};

export type GetStorageProjectQueryVariables = Exact<{
  id: Scalars['Int'];
  storageFilter: Array<StorageFilterItems> | StorageFilterItems;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search: Scalars['String'];
  isOnlyFromThreads?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetStorageProjectQuery = {
  __typename?: 'Query';
  getProject: {
    __typename?: 'Project';
    id: number;
    name: string;
    number?: number | null;
    storageEntities: {
      __typename?: 'StorageResponse';
      count: number;
      rows: Array<
        | {
            __typename?: 'File';
            id: number;
            fileName: string;
            previewUrl?: string | null;
            mimeType: string;
            path: string;
            size: number;
            createdAt: string;
            updatedAt: string;
            task?: {
              __typename?: 'Task';
              id: number;
              number: number;
              name: string;
              code?: string | null;
            } | null;
            subtask?: {
              __typename?: 'Subtask';
              id: number;
              number: number;
              name: string;
              code?: string | null;
              task: { __typename?: 'Task'; id: number };
            } | null;
            thread?: {
              __typename?: 'Thread';
              id: number;
              scaleMapTask?: { __typename?: 'ScaleMapTask'; name: string } | null;
            } | null;
          }
        | {
            __typename?: 'StorageItem';
            id: number;
            name?: string | null;
            href?: string | null;
            thumbnailPath?: string | null;
            isAccess?: boolean | null;
            createdAt: string;
            updatedAt: string;
            taskId?: number | null;
            subtaskId?: number | null;
            mimeType: string;
            task?: {
              __typename?: 'Task';
              id: number;
              number: number;
              name: string;
              code?: string | null;
            } | null;
            subtask?: {
              __typename?: 'Subtask';
              id: number;
              number: number;
              name: string;
              code?: string | null;
              task: { __typename?: 'Task'; id: number };
            } | null;
            thread?: {
              __typename?: 'Thread';
              id: number;
              scaleMapTask?: { __typename?: 'ScaleMapTask'; name: string } | null;
            } | null;
          }
        | null
      >;
    };
  };
};

export type GetStorageProjectsQueryVariables = Exact<{
  projectsData: GetProjectsInput;
  storageFilter: Array<StorageFilterItems> | StorageFilterItems;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search: Scalars['String'];
  isOnlyFromThreads?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetStorageProjectsQuery = {
  __typename?: 'Query';
  getProjects: {
    __typename?: 'ProjectsList';
    count: number;
    rows: Array<{
      __typename?: 'Project';
      id: number;
      name: string;
      number?: number | null;
      storageEntities: {
        __typename?: 'StorageResponse';
        count: number;
        rows: Array<
          | {
              __typename?: 'File';
              id: number;
              fileName: string;
              previewUrl?: string | null;
              mimeType: string;
              path: string;
              size: number;
              createdAt: string;
              updatedAt: string;
              task?: {
                __typename?: 'Task';
                id: number;
                number: number;
                name: string;
                code?: string | null;
              } | null;
              subtask?: {
                __typename?: 'Subtask';
                id: number;
                number: number;
                name: string;
                code?: string | null;
                task: { __typename?: 'Task'; id: number };
              } | null;
              thread?: {
                __typename?: 'Thread';
                id: number;
                scaleMapTask?: { __typename?: 'ScaleMapTask'; name: string } | null;
              } | null;
            }
          | {
              __typename?: 'StorageItem';
              id: number;
              name?: string | null;
              href?: string | null;
              thumbnailPath?: string | null;
              isAccess?: boolean | null;
              createdAt: string;
              updatedAt: string;
              taskId?: number | null;
              subtaskId?: number | null;
              mimeType: string;
              task?: {
                __typename?: 'Task';
                id: number;
                number: number;
                name: string;
                code?: string | null;
              } | null;
              subtask?: {
                __typename?: 'Subtask';
                id: number;
                number: number;
                name: string;
                code?: string | null;
                task: { __typename?: 'Task'; id: number };
              } | null;
              thread?: {
                __typename?: 'Thread';
                id: number;
                scaleMapTask?: { __typename?: 'ScaleMapTask'; name: string } | null;
              } | null;
            }
          | null
        >;
      };
    } | null>;
  };
};

export type GetSubtaskQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetSubtaskQuery = {
  __typename?: 'Query';
  getSubtask: {
    __typename?: 'Subtask';
    id: number;
    createdAt: string;
    name: string;
    number: number;
    code?: string | null;
    startDate?: any | null;
    closeDate?: any | null;
    endDate?: any | null;
    task: {
      __typename?: 'Task';
      id: number;
      code?: string | null;
      startDate?: any | null;
      closeDate?: any | null;
      endDate?: any | null;
      project: { __typename?: 'Project'; id: number };
      maintainer?: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
        isAnalytics: boolean;
        karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
      } | null;
    };
    status?: { __typename?: 'Status'; id: number; color: string } | null;
    author?: {
      __typename?: 'User';
      id?: number | null;
      login?: string | null;
      karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
    } | null;
    maintainer?: {
      __typename?: 'User';
      id?: number | null;
      login?: string | null;
      image?: string | null;
      isAnalytics: boolean;
      karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
    } | null;
    tags: {
      __typename?: 'TagList';
      count: number;
      rows: Array<{
        __typename?: 'Tag';
        id: number;
        name: string;
        color?: string | null;
        textColor?: string | null;
        createdAt: string;
        updatedAt: string;
      }>;
    };
    poligonObject?: { __typename?: 'PoligonObject'; id: number } | null;
  };
};

export type GetSubtaskChatQueryVariables = Exact<{
  id: Scalars['Int'];
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type GetSubtaskChatQuery = {
  __typename?: 'Query';
  getSubtask: {
    __typename?: 'Subtask';
    id: number;
    chat: {
      __typename?: 'Chat';
      id: number;
      messagesList: {
        __typename?: 'MessagesList';
        count: number;
        rows: Array<{
          __typename?: 'Message';
          isRead?: boolean | null;
          isPossibleSetKarma: boolean;
          isActivity?: boolean | null;
          isDidYouRead?: boolean | null;
          id: number;
          text: string;
          createdAt: any;
          user: {
            __typename?: 'User';
            id?: number | null;
            login?: string | null;
            image?: string | null;
            createdAt?: string | null;
            karmaStatistics?: {
              __typename?: 'UserKarmaStatistics';
              karma: number;
              items: Array<{
                __typename?: 'Karma';
                id: number;
                createdAt: string;
                isPlus: boolean;
                messageId: number;
                user: {
                  __typename?: 'User';
                  login?: string | null;
                  image?: string | null;
                };
              }>;
            } | null;
          };
        } | null>;
      };
      logs: {
        __typename?: 'MessagesList';
        count: number;
        rows: Array<{
          __typename?: 'Message';
          isRead?: boolean | null;
          isPossibleSetKarma: boolean;
          isActivity?: boolean | null;
          isDidYouRead?: boolean | null;
          id: number;
          text: string;
          createdAt: any;
          user: {
            __typename?: 'User';
            id?: number | null;
            login?: string | null;
            image?: string | null;
            createdAt?: string | null;
            karmaStatistics?: {
              __typename?: 'UserKarmaStatistics';
              karma: number;
              items: Array<{
                __typename?: 'Karma';
                id: number;
                createdAt: string;
                isPlus: boolean;
                messageId: number;
                user: {
                  __typename?: 'User';
                  login?: string | null;
                  image?: string | null;
                };
              }>;
            } | null;
          };
        } | null>;
      };
    };
  };
};

export type GetSubtaskNotesQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetSubtaskNotesQuery = {
  __typename?: 'Query';
  getSubtask: {
    __typename?: 'Subtask';
    id: number;
    notes: Array<{
      __typename?: 'Note';
      id: number;
      createdAt: string;
      chatId?: number | null;
      taskId?: number | null;
      content?: string | null;
      owner?: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
        role?: string | null;
        karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
      } | null;
    } | null>;
  };
};

export type GetTagsQueryVariables = Exact<{
  data?: InputMaybe<GetTagsInput>;
}>;

export type GetTagsQuery = {
  __typename?: 'Query';
  getTags: {
    __typename?: 'TagList';
    rows: Array<{
      __typename?: 'Tag';
      id: number;
      name: string;
      color?: string | null;
      createdAt: string;
      updatedAt: string;
      textColor?: string | null;
    }>;
  };
};

export type GetTagsByUserQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;

export type GetTagsByUserQuery = {
  __typename?: 'Query';
  getTagsByUser: Array<{
    __typename?: 'Tag';
    id: number;
    name: string;
    color?: string | null;
    createdAt: string;
    updatedAt: string;
    textColor?: string | null;
  }>;
};

export type GetTaskQueryVariables = Exact<{
  id: Scalars['Int'];
  subtaskStatusId?: InputMaybe<Scalars['Int']>;
}>;

export type GetTaskQuery = {
  __typename?: 'Query';
  getTask: {
    __typename?: 'Task';
    id: number;
    createdAt: string;
    updatedAt: string;
    name: string;
    number: number;
    description?: string | null;
    code?: string | null;
    startDate?: any | null;
    closeDate?: any | null;
    endDate?: any | null;
    realizationPerMonthAmount?: number | null;
    realizationPrice?: number | null;
    project: { __typename?: 'Project'; id: number; number?: number | null; name: string };
    status?: { __typename?: 'Status'; id: number } | null;
    author?: {
      __typename?: 'User';
      id?: number | null;
      login?: string | null;
      image?: string | null;
      fullName?: string | null;
    } | null;
    subtasks: Array<{
      __typename?: 'Subtask';
      id: number;
      createdAt: string;
      updatedAt: string;
      name: string;
      number: number;
      code?: string | null;
      startDate?: any | null;
      closeDate?: any | null;
      endDate?: any | null;
      task: {
        __typename?: 'Task';
        id: number;
        number: number;
        code?: string | null;
        project: { __typename?: 'Project'; id: number; number?: number | null };
      };
      status?: { __typename?: 'Status'; id: number } | null;
      author?: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
        karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
      } | null;
      maintainer?: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
        isAnalytics: boolean;
        karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
      } | null;
      tags: {
        __typename?: 'TagList';
        count: number;
        rows: Array<{
          __typename?: 'Tag';
          id: number;
          name: string;
          color?: string | null;
          textColor?: string | null;
          createdAt: string;
          updatedAt: string;
        }>;
      };
      poligonObject?: { __typename?: 'PoligonObject'; id: number; name: string } | null;
    } | null>;
    maintainer?: {
      __typename?: 'User';
      id?: number | null;
      login?: string | null;
      image?: string | null;
      isAnalytics: boolean;
      karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
    } | null;
    tags: {
      __typename?: 'TagList';
      count: number;
      rows: Array<{
        __typename?: 'Tag';
        id: number;
        name: string;
        color?: string | null;
        createdAt: string;
        updatedAt: string;
      }>;
    };
    poligonObject?: { __typename?: 'PoligonObject'; id: number } | null;
  };
};

export type GetTaskAssigneesQueryVariables = Exact<{
  id: Scalars['Int'];
  assigneesLimit: Scalars['Int'];
}>;

export type GetTaskAssigneesQuery = {
  __typename?: 'Query';
  getTask: {
    __typename?: 'Task';
    id: number;
    assignees: Array<{
      __typename?: 'User';
      id?: number | null;
      login?: string | null;
      image?: string | null;
      isAnalytics: boolean;
      karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
    } | null>;
  };
};

export type GetTaskChatQueryVariables = Exact<{
  id: Scalars['Int'];
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type GetTaskChatQuery = {
  __typename?: 'Query';
  getTask: {
    __typename?: 'Task';
    id: number;
    chat: {
      __typename?: 'Chat';
      id: number;
      messagesList: {
        __typename?: 'MessagesList';
        count: number;
        rows: Array<{
          __typename?: 'Message';
          isRead?: boolean | null;
          isPossibleSetKarma: boolean;
          isActivity?: boolean | null;
          isDidYouRead?: boolean | null;
          id: number;
          text: string;
          createdAt: any;
          user: {
            __typename?: 'User';
            id?: number | null;
            login?: string | null;
            image?: string | null;
            createdAt?: string | null;
            karmaStatistics?: {
              __typename?: 'UserKarmaStatistics';
              karma: number;
              items: Array<{
                __typename?: 'Karma';
                id: number;
                createdAt: string;
                isPlus: boolean;
                messageId: number;
                user: {
                  __typename?: 'User';
                  login?: string | null;
                  image?: string | null;
                };
              }>;
            } | null;
          };
        } | null>;
      };
      logs: {
        __typename?: 'MessagesList';
        count: number;
        rows: Array<{
          __typename?: 'Message';
          isRead?: boolean | null;
          isPossibleSetKarma: boolean;
          isActivity?: boolean | null;
          isDidYouRead?: boolean | null;
          id: number;
          text: string;
          createdAt: any;
          user: {
            __typename?: 'User';
            id?: number | null;
            login?: string | null;
            image?: string | null;
            createdAt?: string | null;
            karmaStatistics?: {
              __typename?: 'UserKarmaStatistics';
              karma: number;
              items: Array<{
                __typename?: 'Karma';
                id: number;
                createdAt: string;
                isPlus: boolean;
                messageId: number;
                user: {
                  __typename?: 'User';
                  login?: string | null;
                  image?: string | null;
                };
              }>;
            } | null;
          };
        } | null>;
      };
    };
  };
};

export type GetTaskNotesQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetTaskNotesQuery = {
  __typename?: 'Query';
  getTask: {
    __typename?: 'Task';
    id: number;
    notes: Array<{
      __typename?: 'Note';
      id: number;
      createdAt: string;
      chatId?: number | null;
      taskId?: number | null;
      content?: string | null;
      owner?: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
        role?: string | null;
        karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
      } | null;
    } | null>;
  };
};

export type GetTasksQueryVariables = Exact<{
  data: GetTasksInput;
}>;

export type GetTasksQuery = {
  __typename?: 'Query';
  getTasks: {
    __typename?: 'TasksList';
    count: number;
    rows: Array<{
      __typename?: 'Task';
      id: number;
      createdAt: string;
      updatedAt: string;
      name: string;
      number: number;
      code?: string | null;
      startDate?: any | null;
      closeDate?: any | null;
      endDate?: any | null;
      realizationPerMonthAmount?: number | null;
      realizationPrice?: number | null;
      project: {
        __typename?: 'Project';
        id: number;
        name: string;
        number?: number | null;
      };
      status?: { __typename?: 'Status'; id: number; color: string } | null;
      subtasks: Array<{
        __typename?: 'Subtask';
        id: number;
        createdAt: string;
        updatedAt: string;
        name: string;
        number: number;
        code?: string | null;
        startDate?: any | null;
        closeDate?: any | null;
        endDate?: any | null;
        status?: { __typename?: 'Status'; id: number; color: string } | null;
        maintainer?: {
          __typename?: 'User';
          id?: number | null;
          login?: string | null;
          image?: string | null;
        } | null;
        task: {
          __typename?: 'Task';
          id: number;
          number: number;
          code?: string | null;
          project: {
            __typename?: 'Project';
            id: number;
            name: string;
            number?: number | null;
          };
        };
      } | null>;
      maintainer?: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
      } | null;
    } | null>;
  };
};

export type GetTasksSubtasksQueryVariables = Exact<{
  data: GetTasksInput;
}>;

export type GetTasksSubtasksQuery = {
  __typename?: 'Query';
  getTasksSubtasks: {
    __typename?: 'TasksList';
    count: number;
    rows: Array<{
      __typename?: 'Task';
      id: number;
      createdAt: string;
      updatedAt: string;
      name: string;
      number: number;
      code?: string | null;
      startDate?: any | null;
      closeDate?: any | null;
      endDate?: any | null;
      realizationPerMonthAmount?: number | null;
      realizationPrice?: number | null;
      project: {
        __typename?: 'Project';
        id: number;
        name: string;
        number?: number | null;
      };
      status?: { __typename?: 'Status'; id: number; color: string } | null;
      subtasks: Array<{
        __typename?: 'Subtask';
        id: number;
        createdAt: string;
        updatedAt: string;
        name: string;
        number: number;
        code?: string | null;
        startDate?: any | null;
        closeDate?: any | null;
        endDate?: any | null;
        status?: { __typename?: 'Status'; id: number; color: string } | null;
        maintainer?: {
          __typename?: 'User';
          id?: number | null;
          login?: string | null;
          image?: string | null;
        } | null;
        task: {
          __typename?: 'Task';
          id: number;
          number: number;
          code?: string | null;
          project: {
            __typename?: 'Project';
            id: number;
            name: string;
            number?: number | null;
          };
        };
      } | null>;
      maintainer?: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
      } | null;
    } | null>;
  };
};

export type GetThreadQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetThreadQuery = {
  __typename?: 'Query';
  getThread: {
    __typename?: 'Thread';
    id: number;
    isSubscriber: boolean;
    cover?: string | null;
    mainPerformerId?: number | null;
    endDate?: any | null;
    startDate?: any | null;
    closeDate?: any | null;
    isCritical: boolean;
    author: {
      __typename?: 'User';
      id?: number | null;
      login?: string | null;
      image?: string | null;
    };
    notes: Array<{
      __typename?: 'Note';
      id: number;
      threadId?: number | null;
      content?: string | null;
      owner?: { __typename?: 'User'; id?: number | null; image?: string | null } | null;
    }>;
    threadBackground?: {
      __typename?: 'ThreadBackground';
      id: number;
      backgroundColor: string;
    } | null;
    statistic: { __typename?: 'ThreadStatistic'; performerAcceptedPercent: number };
    scaleMapTaskForCalendar?: { __typename?: 'ScaleMapTask'; id: number } | null;
    scaleMapTask?: {
      __typename?: 'ScaleMapTask';
      id: number;
      name: string;
      column?: number | null;
      row?: number | null;
      status?: {
        __typename?: 'ScaleMapStatus';
        id: number;
        name: string;
        color: string;
      } | null;
      children?: Array<{
        __typename?: 'ScaleMapTask';
        id: number;
        name: string;
        isUrgently?: boolean | null;
        status?: {
          __typename?: 'ScaleMapStatus';
          id: number;
          name: string;
          color: string;
        } | null;
      }> | null;
    } | null;
    chat: { __typename?: 'Chat'; id: number };
    notesAndCount: {
      __typename?: 'GetNotesAndCount';
      rows: Array<{ __typename?: 'Note'; id: number }>;
    };
    storageFiles: {
      __typename?: 'StorageResponse';
      rows: Array<
        | {
            __typename?: 'File';
            id: number;
            fileName: string;
            previewUrl?: string | null;
            mimeType: string;
            path: string;
            size: number;
            createdAt: string;
            updatedAt: string;
            userClickedAt?: string | null;
            task?: {
              __typename?: 'Task';
              id: number;
              number: number;
              name: string;
              code?: string | null;
            } | null;
            subtask?: {
              __typename?: 'Subtask';
              id: number;
              number: number;
              name: string;
              code?: string | null;
              task: { __typename?: 'Task'; id: number };
            } | null;
          }
        | {
            __typename?: 'StorageItem';
            id: number;
            name?: string | null;
            href?: string | null;
            thumbnailPath?: string | null;
            isAccess?: boolean | null;
            createdAt: string;
            updatedAt: string;
            taskId?: number | null;
            subtaskId?: number | null;
            mimeType: string;
            userClickedAt?: string | null;
            task?: {
              __typename?: 'Task';
              id: number;
              number: number;
              name: string;
              code?: string | null;
            } | null;
            subtask?: {
              __typename?: 'Subtask';
              id: number;
              number: number;
              name: string;
              code?: string | null;
              task: { __typename?: 'Task'; id: number };
            } | null;
          }
        | null
      >;
    };
    storageImages: {
      __typename?: 'StorageResponse';
      rows: Array<
        | {
            __typename?: 'File';
            id: number;
            fileName: string;
            previewUrl?: string | null;
            mimeType: string;
            path: string;
            size: number;
            createdAt: string;
            updatedAt: string;
            userClickedAt?: string | null;
            task?: {
              __typename?: 'Task';
              id: number;
              number: number;
              name: string;
              code?: string | null;
            } | null;
            subtask?: {
              __typename?: 'Subtask';
              id: number;
              number: number;
              name: string;
              code?: string | null;
              task: { __typename?: 'Task'; id: number };
            } | null;
            threadFileWrap?: {
              __typename?: 'ThreadFileWrap';
              id: number;
              orderNumber: number;
              comment?: string | null;
            } | null;
          }
        | {
            __typename?: 'StorageItem';
            id: number;
            name?: string | null;
            href?: string | null;
            thumbnailPath?: string | null;
            isAccess?: boolean | null;
            createdAt: string;
            updatedAt: string;
            taskId?: number | null;
            subtaskId?: number | null;
            mimeType: string;
            userClickedAt?: string | null;
            task?: {
              __typename?: 'Task';
              id: number;
              number: number;
              name: string;
              code?: string | null;
            } | null;
            subtask?: {
              __typename?: 'Subtask';
              id: number;
              number: number;
              name: string;
              code?: string | null;
              task: { __typename?: 'Task'; id: number };
            } | null;
          }
        | null
      >;
    };
    performers: Array<{
      __typename?: 'ThreadPerformer';
      threadId: number;
      userId: number;
      status: string;
      user: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
        role?: string | null;
        karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
        threads: {
          __typename?: 'GetThreadsAndCount';
          rows: Array<{
            __typename?: 'Thread';
            id: number;
            performerStatus?: string | null;
          }>;
        };
      };
    }>;
    mainPerformer?: {
      __typename?: 'User';
      id?: number | null;
      login?: string | null;
      image?: string | null;
      IsExistUnViewedForum: boolean;
      role?: string | null;
      fullName?: string | null;
    } | null;
    tags: Array<{
      __typename?: 'Tag';
      id: number;
      name: string;
      color?: string | null;
      textColor?: string | null;
    } | null>;
    meta: Array<{
      __typename?: 'ScaleMapTask';
      id: number;
      name: string;
      column?: number | null;
      row?: number | null;
      isMain?: boolean | null;
      isCurrent?: boolean | null;
      status?: { __typename?: 'ScaleMapStatus'; color: string; name: string } | null;
      scaleMapFile?: {
        __typename?: 'ScaleMapFile';
        id: number;
        isPersonal: boolean;
        author?: { __typename?: 'User'; id?: number | null } | null;
        user?: { __typename?: 'User'; id?: number | null } | null;
      } | null;
    } | null>;
  };
};

export type GetThreadAndCountQueryVariables = Exact<{
  id: Scalars['Int'];
  storageFilter: Array<StorageFilterItems> | StorageFilterItems;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search: Scalars['String'];
}>;

export type GetThreadAndCountQuery = {
  __typename?: 'Query';
  getThread: {
    __typename?: 'Thread';
    id: number;
    scaleMapTask?: { __typename?: 'ScaleMapTask'; name: string } | null;
    storageEntities: {
      __typename?: 'StorageResponse';
      count: number;
      rows: Array<
        | {
            __typename?: 'File';
            id: number;
            fileName: string;
            previewUrl?: string | null;
            mimeType: string;
            path: string;
            size: number;
            createdAt: string;
            updatedAt: string;
          }
        | {
            __typename?: 'StorageItem';
            id: number;
            name?: string | null;
            href?: string | null;
            thumbnailPath?: string | null;
            isAccess?: boolean | null;
            createdAt: string;
            updatedAt: string;
            mimeType: string;
          }
        | null
      >;
    };
  };
};

export type GetThreadFileQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetThreadFileQuery = {
  __typename?: 'Query';
  getThread: {
    __typename?: 'Thread';
    storageFiles: {
      __typename?: 'StorageResponse';
      rows: Array<
        | {
            __typename?: 'File';
            id: number;
            fileName: string;
            previewUrl?: string | null;
            mimeType: string;
            path: string;
            size: number;
            createdAt: string;
            updatedAt: string;
            userClickedAt?: string | null;
            task?: {
              __typename?: 'Task';
              id: number;
              number: number;
              name: string;
              code?: string | null;
            } | null;
            subtask?: {
              __typename?: 'Subtask';
              id: number;
              number: number;
              name: string;
              code?: string | null;
              task: { __typename?: 'Task'; id: number };
            } | null;
          }
        | {
            __typename?: 'StorageItem';
            id: number;
            name?: string | null;
            href?: string | null;
            thumbnailPath?: string | null;
            isAccess?: boolean | null;
            createdAt: string;
            updatedAt: string;
            taskId?: number | null;
            subtaskId?: number | null;
            mimeType: string;
            userClickedAt?: string | null;
            task?: {
              __typename?: 'Task';
              id: number;
              number: number;
              name: string;
              code?: string | null;
            } | null;
            subtask?: {
              __typename?: 'Subtask';
              id: number;
              number: number;
              name: string;
              code?: string | null;
              task: { __typename?: 'Task'; id: number };
            } | null;
          }
        | null
      >;
    };
    storageImages: {
      __typename?: 'StorageResponse';
      rows: Array<
        | {
            __typename?: 'File';
            id: number;
            fileName: string;
            previewUrl?: string | null;
            mimeType: string;
            path: string;
            size: number;
            createdAt: string;
            updatedAt: string;
            userClickedAt?: string | null;
            task?: {
              __typename?: 'Task';
              id: number;
              number: number;
              name: string;
              code?: string | null;
            } | null;
            subtask?: {
              __typename?: 'Subtask';
              id: number;
              number: number;
              name: string;
              code?: string | null;
              task: { __typename?: 'Task'; id: number };
            } | null;
            threadFileWrap?: {
              __typename?: 'ThreadFileWrap';
              id: number;
              orderNumber: number;
              comment?: string | null;
            } | null;
          }
        | {
            __typename?: 'StorageItem';
            id: number;
            name?: string | null;
            href?: string | null;
            thumbnailPath?: string | null;
            isAccess?: boolean | null;
            createdAt: string;
            updatedAt: string;
            taskId?: number | null;
            subtaskId?: number | null;
            mimeType: string;
            userClickedAt?: string | null;
            task?: {
              __typename?: 'Task';
              id: number;
              number: number;
              name: string;
              code?: string | null;
            } | null;
            subtask?: {
              __typename?: 'Subtask';
              id: number;
              number: number;
              name: string;
              code?: string | null;
              task: { __typename?: 'Task'; id: number };
            } | null;
          }
        | null
      >;
    };
  };
};

export type GetThreadPerformersQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetThreadPerformersQuery = {
  __typename?: 'Query';
  getThread: {
    __typename?: 'Thread';
    mainPerformerId?: number | null;
    performers: Array<{
      __typename?: 'ThreadPerformer';
      threadId: number;
      userId: number;
      status: string;
      user: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
        role?: string | null;
        threads: {
          __typename?: 'GetThreadsAndCount';
          rows: Array<{
            __typename?: 'Thread';
            id: number;
            performerStatus?: string | null;
          }>;
        };
      };
    }>;
    mainPerformer?: {
      __typename?: 'User';
      id?: number | null;
      login?: string | null;
      image?: string | null;
      IsExistUnViewedForum: boolean;
      role?: string | null;
      fullName?: string | null;
    } | null;
  };
};

export type GetThreadStorageProjectQueryVariables = Exact<{
  data: GetThreadStorageInput;
}>;

export type GetThreadStorageProjectQuery = {
  __typename?: 'Query';
  getThreadStorageProject: {
    __typename?: 'ThreadProject';
    name: string;
    storageEntities?: {
      __typename?: 'StorageResponse';
      count: number;
      rows: Array<
        | {
            __typename?: 'File';
            id: number;
            fileName: string;
            previewUrl?: string | null;
            mimeType: string;
            path: string;
            size: number;
            createdAt: string;
            updatedAt: string;
            thread?: {
              __typename?: 'Thread';
              id: number;
              scaleMapTask?: { __typename?: 'ScaleMapTask'; name: string } | null;
            } | null;
          }
        | {
            __typename?: 'StorageItem';
            id: number;
            name?: string | null;
            href?: string | null;
            thumbnailPath?: string | null;
            isAccess?: boolean | null;
            createdAt: string;
            updatedAt: string;
            subtaskId?: number | null;
            mimeType: string;
            thread?: {
              __typename?: 'Thread';
              id: number;
              scaleMapTask?: { __typename?: 'ScaleMapTask'; name: string } | null;
            } | null;
          }
        | null
      >;
    } | null;
  };
};

export type GetThreadViewHistoryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortOrder?: InputMaybe<SortingOrders>;
  tagsId?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type GetThreadViewHistoryQuery = {
  __typename?: 'Query';
  getThreadViewHistory: Array<{
    __typename?: 'ViewHistory';
    id: number;
    viewedAt: string;
    thread?: {
      __typename?: 'Thread';
      id: number;
      createdAt: string;
      tags: Array<{
        __typename?: 'Tag';
        id: number;
        name: string;
        color?: string | null;
        textColor?: string | null;
      } | null>;
      meta: Array<{
        __typename?: 'ScaleMapTask';
        id: number;
        name: string;
        isCurrent?: boolean | null;
        status?: {
          __typename?: 'ScaleMapStatus';
          id: number;
          name: string;
          color: string;
        } | null;
      } | null>;
      mainPerformer?: {
        __typename?: 'User';
        image?: string | null;
        login?: string | null;
        id?: number | null;
        karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
      } | null;
      userLastEdited?: {
        __typename?: 'User';
        image?: string | null;
        login?: string | null;
        id?: number | null;
        karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
      } | null;
      performers: Array<{ __typename?: 'ThreadPerformer'; userId: number }>;
    } | null;
  } | null>;
};

export type GetThreadsAndCountQueryVariables = Exact<{
  data: GetThreadAndCountInput;
  storageFilter: Array<StorageFilterItems> | StorageFilterItems;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search: Scalars['String'];
}>;

export type GetThreadsAndCountQuery = {
  __typename?: 'Query';
  getThreadsAndCount: {
    __typename?: 'GetThreadsAndCount';
    count: number;
    rows: Array<{
      __typename?: 'Thread';
      id: number;
      scaleMapTask?: { __typename?: 'ScaleMapTask'; name: string } | null;
      storageEntities: {
        __typename?: 'StorageResponse';
        count: number;
        rows: Array<
          | {
              __typename?: 'File';
              id: number;
              fileName: string;
              previewUrl?: string | null;
              mimeType: string;
              path: string;
              size: number;
              createdAt: string;
              updatedAt: string;
            }
          | {
              __typename?: 'StorageItem';
              id: number;
              name?: string | null;
              href?: string | null;
              thumbnailPath?: string | null;
              isAccess?: boolean | null;
              createdAt: string;
              updatedAt: string;
              mimeType: string;
            }
          | null
        >;
      };
    }>;
  };
};

export type GetThreadsAndCountByUserQueryVariables = Exact<{
  data?: InputMaybe<GetThreadAndCountInput>;
}>;

export type GetThreadsAndCountByUserQuery = {
  __typename?: 'Query';
  getThreadsAndCountByUser: {
    __typename?: 'GetThreadsAndCount';
    count: number;
    rows: Array<{
      __typename?: 'Thread';
      id: number;
      createdAt: string;
      updatedAt: string;
      authorId: number;
      cover?: string | null;
      mainPerformerId?: number | null;
      performerStatus?: string | null;
      author: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
      };
      notes: Array<{ __typename?: 'Note'; id: number }>;
      threadBackground?: {
        __typename?: 'ThreadBackground';
        id: number;
        backgroundColor: string;
      } | null;
      statistic: { __typename?: 'ThreadStatistic'; performerAcceptedPercent: number };
      scaleMapTask?: {
        __typename?: 'ScaleMapTask';
        id: number;
        name: string;
        status?: {
          __typename?: 'ScaleMapStatus';
          id: number;
          name: string;
          color: string;
        } | null;
        children?: Array<{
          __typename?: 'ScaleMapTask';
          id: number;
          name: string;
          status?: { __typename?: 'ScaleMapStatus'; color: string } | null;
        }> | null;
      } | null;
      performers: Array<{
        __typename?: 'ThreadPerformer';
        threadId: number;
        userId: number;
        status: string;
        user: {
          __typename?: 'User';
          id?: number | null;
          login?: string | null;
          image?: string | null;
          IsExistUnViewedForum: boolean;
          role?: string | null;
          fullName?: string | null;
        };
      }>;
      mainPerformer?: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
        IsExistUnViewedForum: boolean;
        role?: string | null;
        fullName?: string | null;
      } | null;
      tags: Array<{
        __typename?: 'Tag';
        id: number;
        name: string;
        color?: string | null;
        textColor?: string | null;
      } | null>;
      meta: Array<{
        __typename?: 'ScaleMapTask';
        id: number;
        name: string;
        isMain?: boolean | null;
        isCurrent?: boolean | null;
        status?: { __typename?: 'ScaleMapStatus'; color: string } | null;
      } | null>;
      userLastEdited?: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        tags: {
          __typename?: 'TagList';
          rows: Array<{
            __typename?: 'Tag';
            id: number;
            name: string;
            color?: string | null;
            textColor?: string | null;
          }>;
        };
      } | null;
    }>;
  };
};

export type GetThreadsAndCountByUserEditQueryVariables = Exact<{
  data?: InputMaybe<GetThreadAndCountInput>;
}>;

export type GetThreadsAndCountByUserEditQuery = {
  __typename?: 'Query';
  getThreadsAndCountByUser: {
    __typename?: 'GetThreadsAndCount';
    count: number;
    rows: Array<{
      __typename?: 'Thread';
      id: number;
      cover?: string | null;
      scaleMapTask?: {
        __typename?: 'ScaleMapTask';
        id: number;
        name: string;
        children?: Array<{
          __typename?: 'ScaleMapTask';
          id: number;
          name: string;
          status?: { __typename?: 'ScaleMapStatus'; color: string } | null;
        }> | null;
      } | null;
      threadBackground?: {
        __typename?: 'ThreadBackground';
        id: number;
        backgroundColor: string;
      } | null;
      performers: Array<{
        __typename?: 'ThreadPerformer';
        threadId: number;
        userId: number;
        status: string;
        user: {
          __typename?: 'User';
          id?: number | null;
          login?: string | null;
          image?: string | null;
          IsExistUnViewedForum: boolean;
          role?: string | null;
          fullName?: string | null;
        };
      }>;
      tags: Array<{
        __typename?: 'Tag';
        id: number;
        name: string;
        color?: string | null;
        textColor?: string | null;
      } | null>;
    }>;
  };
};

export type GetTredChatQueryVariables = Exact<{
  id: Scalars['Int'];
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type GetTredChatQuery = {
  __typename?: 'Query';
  getThread: {
    __typename?: 'Thread';
    id: number;
    chat: {
      __typename?: 'Chat';
      id: number;
      messagesList: {
        __typename?: 'MessagesList';
        count: number;
        rows: Array<{
          __typename?: 'Message';
          isRead?: boolean | null;
          isPossibleSetKarma: boolean;
          isActivity?: boolean | null;
          isDidYouRead?: boolean | null;
          id: number;
          text: string;
          createdAt: any;
          user: {
            __typename?: 'User';
            id?: number | null;
            login?: string | null;
            image?: string | null;
            createdAt?: string | null;
            karmaStatistics?: {
              __typename?: 'UserKarmaStatistics';
              karma: number;
              items: Array<{
                __typename?: 'Karma';
                id: number;
                createdAt: string;
                isPlus: boolean;
                messageId: number;
                user: {
                  __typename?: 'User';
                  login?: string | null;
                  image?: string | null;
                };
              }>;
            } | null;
          };
        } | null>;
      };
      logs: {
        __typename?: 'MessagesList';
        count: number;
        rows: Array<{
          __typename?: 'Message';
          isRead?: boolean | null;
          isPossibleSetKarma: boolean;
          isActivity?: boolean | null;
          isDidYouRead?: boolean | null;
          id: number;
          text: string;
          createdAt: any;
          user: {
            __typename?: 'User';
            id?: number | null;
            login?: string | null;
            image?: string | null;
            createdAt?: string | null;
            karmaStatistics?: {
              __typename?: 'UserKarmaStatistics';
              karma: number;
              items: Array<{
                __typename?: 'Karma';
                id: number;
                createdAt: string;
                isPlus: boolean;
                messageId: number;
                user: {
                  __typename?: 'User';
                  login?: string | null;
                  image?: string | null;
                };
              }>;
            } | null;
          };
        } | null>;
      };
    };
  };
};

export type GetTredChatReadQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetTredChatReadQuery = {
  __typename?: 'Query';
  getThread: {
    __typename?: 'Thread';
    id: number;
    chat: { __typename?: 'Chat'; isReadActivities: boolean; isReadMessages: boolean };
  };
};

export type GetTredsNotesQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetTredsNotesQuery = {
  __typename?: 'Query';
  getThread: {
    __typename?: 'Thread';
    id: number;
    notes: Array<{
      __typename?: 'Note';
      id: number;
      createdAt: string;
      chatId?: number | null;
      taskId?: number | null;
      content?: string | null;
      owner?: {
        __typename?: 'User';
        id?: number | null;
        login?: string | null;
        image?: string | null;
        role?: string | null;
        karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
      } | null;
    }>;
  };
};

export type GetUserAnalyticQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;

export type GetUserAnalyticQuery = {
  __typename?: 'Query';
  getUserAnalytic: {
    __typename?: 'User';
    id?: number | null;
    fullName?: string | null;
    login?: string | null;
    onlineStatus: boolean;
    image?: string | null;
    isAnalytics: boolean;
    karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
    analyticThreads?: {
      __typename?: 'GetAnalyticThreadsType';
      deadlineDate?: any | null;
      deadlineThrough?: number | null;
      countThreadsInCalendar: number;
      daysInactiveInThreadPanel?: number | null;
      userThreadCount: number;
      totalCreatedThreads: number;
      threadsLastEdited: Array<{
        __typename?: 'Thread';
        id: number;
        scaleMapTask?: {
          __typename?: 'ScaleMapTask';
          name: string;
          status?: { __typename?: 'ScaleMapStatus'; color: string } | null;
        } | null;
      }>;
    } | null;
    analytic?: {
      __typename?: 'GetAnalytic';
      completed?: number | null;
      progress?: number | null;
      deadlineDate?: any | null;
      deadlineThrough?: number | null;
    } | null;
    projects: Array<{
      __typename?: 'Project';
      id: number;
      name: string;
      tasksSubtasks: {
        __typename?: 'TasksList';
        rows: Array<{ __typename?: 'Task'; id: number } | null>;
      };
    } | null>;
    userCharacteristics?: {
      __typename?: 'GetAllUserCharacteristics';
      count: number;
      rows: Array<{
        __typename?: 'UserCharacteristic';
        id: number;
        text: string;
        color: string;
      }>;
    } | null;
    tags: {
      __typename?: 'TagList';
      count: number;
      rows: Array<{
        __typename?: 'Tag';
        id: number;
        name: string;
        color?: string | null;
        textColor?: string | null;
      }>;
    };
  };
};

export type GetUserAnalyticScaleQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;

export type GetUserAnalyticScaleQuery = {
  __typename?: 'Query';
  getUserAnalytic: {
    __typename?: 'User';
    id?: number | null;
    fullName?: string | null;
    login?: string | null;
    onlineStatus: boolean;
    image?: string | null;
    isAnalytics: boolean;
    analytic?: {
      __typename?: 'GetAnalytic';
      completed?: number | null;
      progress?: number | null;
      deadlineDate?: any | null;
      deadlineThrough?: number | null;
    } | null;
    analyticThreads?: {
      __typename?: 'GetAnalyticThreadsType';
      deadlineDate?: any | null;
      deadlineThrough?: number | null;
      daysInactiveInThreadPanel?: number | null;
      countThreadsInCalendar: number;
      userThreadCount: number;
      totalCreatedThreads: number;
      threadsLastEdited: Array<{
        __typename?: 'Thread';
        id: number;
        scaleMapTask?: {
          __typename?: 'ScaleMapTask';
          name: string;
          status?: { __typename?: 'ScaleMapStatus'; color: string } | null;
        } | null;
      }>;
    } | null;
    scaleMapFiles: {
      __typename?: 'ScaleMapFileList';
      count: number;
      rows: Array<{
        __typename?: 'ScaleMapFile';
        id: number;
        createdAt: string;
        updatedAt: string;
        name: string;
        project?: { __typename?: 'Project'; id: number } | null;
        statistics: {
          __typename?: 'ScaleMapFileStatistics';
          tasksCount: number;
          completedTasksPercent: number;
          inProgressTasksPercent: number;
          problemTasksCount: number;
          notInProgressTasksPercent: number;
          problemTasksPercent: number;
          isTasksCreatedToday: boolean;
          isTasksUpdatedToday: boolean;
        };
      } | null>;
    };
    projects: Array<{
      __typename?: 'Project';
      id: number;
      name: string;
      tasksSubtasks: {
        __typename?: 'TasksList';
        rows: Array<{ __typename?: 'Task'; id: number } | null>;
      };
    } | null>;
    userCharacteristics?: {
      __typename?: 'GetAllUserCharacteristics';
      count: number;
      rows: Array<{
        __typename?: 'UserCharacteristic';
        id: number;
        text: string;
        color: string;
      }>;
    } | null;
  };
};

export type GetUserCharacteristicsQueryVariables = Exact<{
  userId: Scalars['Int'];
  data?: InputMaybe<GetUserCharacteristicsInput>;
}>;

export type GetUserCharacteristicsQuery = {
  __typename?: 'Query';
  getUserCharacteristics: {
    __typename?: 'GetAllUserCharacteristics';
    count: number;
    rows: Array<{
      __typename?: 'UserCharacteristic';
      id: number;
      text: string;
      color: string;
    }>;
  };
};

export type GetUserDecideOnFlyActionsHistoryQueryVariables = Exact<{
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
}>;

export type GetUserDecideOnFlyActionsHistoryQuery = {
  __typename?: 'Query';
  getUserDecideOnFlyActionsHistory: {
    __typename?: 'GetUserDecideOnFlyActionsHistoryEntity';
    count: number;
    doneCount: number;
    notDoneCount: number;
    rows: Array<{
      __typename?: 'UserDecideOnFlyAction';
      id: number;
      decideOnFlyActionId: number;
      createdAt: string;
      startDate?: any | null;
      endDate?: any | null;
      status: string;
      reasonForNotDone?: string | null;
      decideOnFlyAction?: {
        __typename?: 'DecideOnFlyAction';
        id: number;
        name: string;
        chancePercent: number;
        decideOnFlyPeriodId: number;
      } | null;
    }>;
  };
};

export type GetUsersAnalyticQueryVariables = Exact<{ [key: string]: never }>;

export type GetUsersAnalyticQuery = {
  __typename?: 'Query';
  getUsersAnalytic: {
    __typename?: 'GetAllUsers';
    count: number;
    rows: Array<{
      __typename?: 'User';
      id?: number | null;
      fullName?: string | null;
      login?: string | null;
      onlineStatus: boolean;
      image?: string | null;
      isAnalytics: boolean;
      karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
      analytic?: {
        __typename?: 'GetAnalytic';
        completed?: number | null;
        progress?: number | null;
        deadlineDate?: any | null;
        deadlineThrough?: number | null;
      } | null;
      analyticThreads?: {
        __typename?: 'GetAnalyticThreadsType';
        deadlineDate?: any | null;
        deadlineThrough?: number | null;
        daysInactiveInThreadPanel?: number | null;
        countThreadsInCalendar: number;
        userThreadCount: number;
        totalCreatedThreads: number;
        threadsLastEdited: Array<{
          __typename?: 'Thread';
          id: number;
          scaleMapTask?: {
            __typename?: 'ScaleMapTask';
            name: string;
            status?: { __typename?: 'ScaleMapStatus'; color: string } | null;
          } | null;
        }>;
      } | null;
      userCharacteristics?: {
        __typename?: 'GetAllUserCharacteristics';
        count: number;
        rows: Array<{
          __typename?: 'UserCharacteristic';
          id: number;
          text: string;
          color: string;
        }>;
      } | null;
      tags: {
        __typename?: 'TagList';
        count: number;
        rows: Array<{
          __typename?: 'Tag';
          id: number;
          name: string;
          color?: string | null;
          textColor?: string | null;
        }>;
      };
    }>;
  };
};

export type GetUsersThreadsAnalyticQueryVariables = Exact<{ [key: string]: never }>;

export type GetUsersThreadsAnalyticQuery = {
  __typename?: 'Query';
  getUsersThreadsAnalytic: {
    __typename?: 'GetAllUsers';
    count: number;
    rows: Array<{
      __typename?: 'User';
      id?: number | null;
      fullName?: string | null;
      login?: string | null;
      onlineStatus: boolean;
      image?: string | null;
      isAnalytics: boolean;
      karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
      analyticThreads?: {
        __typename?: 'GetAnalyticThreadsType';
        deadlineDate?: any | null;
        deadlineThrough?: number | null;
        daysInactiveInThreadPanel?: number | null;
        countThreadsInCalendar: number;
        userThreadCount: number;
        totalCreatedThreads: number;
        threadsLastEdited: Array<{
          __typename?: 'Thread';
          id: number;
          scaleMapTask?: {
            __typename?: 'ScaleMapTask';
            name: string;
            status?: { __typename?: 'ScaleMapStatus'; color: string } | null;
          } | null;
        }>;
      } | null;
      userCharacteristics?: {
        __typename?: 'GetAllUserCharacteristics';
        count: number;
        rows: Array<{
          __typename?: 'UserCharacteristic';
          id: number;
          text: string;
          color: string;
        }>;
      } | null;
      tags: {
        __typename?: 'TagList';
        count: number;
        rows: Array<{
          __typename?: 'Tag';
          id: number;
          name: string;
          color?: string | null;
          textColor?: string | null;
        }>;
      };
    }>;
  };
};

export type LoginUserQueryVariables = Exact<{
  data: LoginUserInput;
}>;

export type LoginUserQuery = {
  __typename?: 'Query';
  loginUser: {
    __typename?: 'UserSignIn';
    token: string;
    user: { __typename?: 'User'; login?: string | null };
  };
};

export type PulseMonitorQueryVariables = Exact<{
  year: Scalars['Int'];
}>;

export type PulseMonitorQuery = {
  __typename?: 'Query';
  pulseMonitor: {
    __typename?: 'GetPulseMonitorResponseEntity';
    rows: Array<{
      __typename?: 'PulseMonitorItemEntity';
      scaleMapFile: { __typename?: 'ScaleMapFile'; id: number; name: string };
      statisticByMonth: Array<{
        __typename?: 'PulseMonitorStatisticByMonthEntity';
        monthNumber: number;
        percent: number;
      }>;
    }>;
  };
};

export type SearchQueryVariables = Exact<{
  search: Scalars['String'];
}>;

export type SearchQuery = {
  __typename?: 'Query';
  search: {
    __typename?: 'SearchType';
    storageEntities: Array<
      | { __typename?: 'File'; id: number; fileName: string; threadId?: number | null }
      | {
          __typename?: 'StorageItem';
          id: number;
          name?: string | null;
          taskId?: number | null;
          subtaskId?: number | null;
        }
      | null
    >;
    forum: Array<
      | { __typename?: 'ForumSection'; id: number; name: string }
      | { __typename?: 'ForumThread'; id: number; name: string; forumSectionId: number }
      | null
    >;
    threads: Array<{
      __typename?: 'Thread';
      id: number;
      scaleMapTask?: { __typename?: 'ScaleMapTask'; name: string } | null;
    } | null>;
  };
};

export type ThreadBackgroundsQueryVariables = Exact<{ [key: string]: never }>;

export type ThreadBackgroundsQuery = {
  __typename?: 'Query';
  threadBackgrounds: Array<{
    __typename?: 'ThreadBackground';
    id: number;
    backgroundColor: string;
  }>;
};

export type UserGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type UserGroupsQuery = {
  __typename?: 'Query';
  userGroups: Array<{
    __typename?: 'UserGroup';
    id: number;
    name: string;
    users: Array<{
      __typename?: 'User';
      id?: number | null;
      login?: string | null;
      image?: string | null;
      IsExistUnViewedForum: boolean;
      role?: string | null;
      fullName?: string | null;
      karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
    }>;
  }>;
};

export type ActivityInChatSubscriptionVariables = Exact<{
  chatId: Scalars['Int'];
}>;

export type ActivityInChatSubscription = {
  __typename?: 'Subscription';
  activityInChat: {
    __typename?: 'ActivityInChatType';
    status: boolean;
    chat: { __typename?: 'Chat'; isReadActivities: boolean; isReadMessages: boolean };
  };
};

export type AddedNewItemToThreadStorageSubscriptionVariables = Exact<{
  threadId: Scalars['Int'];
}>;

export type AddedNewItemToThreadStorageSubscription = {
  __typename?: 'Subscription';
  addedNewItemToThreadStorage: {
    __typename?: 'AddNewItemToThreadStorageType';
    threadId: number;
    status: boolean;
  };
};

export type DeletedMessageSubscriptionVariables = Exact<{
  chatId: Scalars['Int'];
}>;

export type DeletedMessageSubscription = {
  __typename?: 'Subscription';
  deletedMessage: {
    __typename?: 'DeletedMessageResponseType';
    messageId: number;
    chatId: number;
  };
};

export type MessagesReadBeforeSubscriptionVariables = Exact<{
  chatId: Scalars['Int'];
}>;

export type MessagesReadBeforeSubscription = {
  __typename?: 'Subscription';
  messagesReadBefore: {
    __typename?: 'ReadMessageResponse';
    messageIds: Array<number>;
    user: { __typename?: 'User'; id?: number | null };
  };
};

export type NewActivitySubscriptionVariables = Exact<{ [key: string]: never }>;

export type NewActivitySubscription = {
  __typename?: 'Subscription';
  newActivity: string;
};

export type NewMessageSubscriptionVariables = Exact<{
  chatId: Scalars['Int'];
}>;

export type NewMessageSubscription = {
  __typename?: 'Subscription';
  newMessage: {
    __typename?: 'Message';
    id: number;
    text: string;
    createdAt: any;
    isRead?: boolean | null;
    isPossibleSetKarma: boolean;
    isActivity?: boolean | null;
    user: {
      __typename?: 'User';
      id?: number | null;
      login?: string | null;
      image?: string | null;
      createdAt?: string | null;
      karmaStatistics?: {
        __typename?: 'UserKarmaStatistics';
        karma: number;
        items: Array<{
          __typename?: 'Karma';
          id: number;
          createdAt: string;
          isPlus: boolean;
          messageId: number;
          user: { __typename?: 'User'; login?: string | null; image?: string | null };
        }>;
      } | null;
    };
  };
};

export type PrivateChatActivitySubscriptionVariables = Exact<{
  chatId: Scalars['Int'];
}>;

export type PrivateChatActivitySubscription = {
  __typename?: 'Subscription';
  privateChatActivity: {
    __typename?: 'PrivateChatActivityType';
    type: TrackActivityTypeEnum;
    chatId?: number | null;
    noteId?: number | null;
    fileId?: number | null;
    messageId?: number | null;
    message?: { __typename?: 'Message'; isActivity?: boolean | null } | null;
  };
};

export type TaskSubtaskActivitySubscriptionVariables = Exact<{ [key: string]: never }>;

export type TaskSubtaskActivitySubscription = {
  __typename?: 'Subscription';
  taskSubtaskActivity: {
    __typename?: 'TaskSubtaskActivityType';
    type: TaskSubtaskEventTypes;
    task?: number | null;
    subtask?: number | null;
  };
};

export const MessageFragmentFragmentDoc = gql`
  fragment messageFragment on Message {
    id
    text
    createdAt
    user {
      id
      login
      image
      createdAt
      karmaStatistics {
        karma
        items {
          id
          createdAt
          isPlus
          user {
            login
            image
          }
          messageId
        }
      }
    }
    isRead
    isPossibleSetKarma
    isActivity
  }
`;
export const ChatFragmentFragmentDoc = gql`
  fragment chatFragment on Chat {
    id
    messagesList(limit: 20, offset: $offset) {
      count
      rows {
        isRead
        isPossibleSetKarma
        isActivity
        isDidYouRead
        ...messageFragment
      }
    }
    logs(limit: 20, offset: $offset) {
      count
      rows {
        isRead
        isPossibleSetKarma
        isActivity
        isDidYouRead
        ...messageFragment
      }
    }
  }
  ${MessageFragmentFragmentDoc}
`;
export const PoligonRectFragmentFragmentDoc = gql`
  fragment PoligonRectFragment on PoligonRect {
    id
    x
    y
    width
    height
    fill
    text
    textObj
    name
    draggable
    nameArray
    parentLink
    alignName
    taskLink {
      taskId
    }
    frontendData
    file {
      id
      path
    }
    statistics {
      tasksCount
      tasksDonePercentage
      tasksInProgressPercentage
      tasksGreyPercentage
      treeCount
    }
  }
`;
export const AddChronographSampleItemDocument = gql`
  mutation addChronographSampleItem($data: CreateChronographSampleItemInput!) {
    addChronographSampleItem(data: $data)
  }
`;
export type AddChronographSampleItemMutationFn = Apollo.MutationFunction<
  AddChronographSampleItemMutation,
  AddChronographSampleItemMutationVariables
>;

/**
 * __useAddChronographSampleItemMutation__
 *
 * To run a mutation, you first call `useAddChronographSampleItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChronographSampleItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChronographSampleItemMutation, { data, loading, error }] = useAddChronographSampleItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddChronographSampleItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddChronographSampleItemMutation,
    AddChronographSampleItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddChronographSampleItemMutation,
    AddChronographSampleItemMutationVariables
  >(AddChronographSampleItemDocument, options);
}
export type AddChronographSampleItemMutationHookResult = ReturnType<
  typeof useAddChronographSampleItemMutation
>;
export type AddChronographSampleItemMutationResult =
  Apollo.MutationResult<AddChronographSampleItemMutation>;
export type AddChronographSampleItemMutationOptions = Apollo.BaseMutationOptions<
  AddChronographSampleItemMutation,
  AddChronographSampleItemMutationVariables
>;
export const AddPerformersToThreadDocument = gql`
  mutation addPerformersToThread($data: AddPerformersToThread!) {
    addPerformersToThread(data: $data)
  }
`;
export type AddPerformersToThreadMutationFn = Apollo.MutationFunction<
  AddPerformersToThreadMutation,
  AddPerformersToThreadMutationVariables
>;

/**
 * __useAddPerformersToThreadMutation__
 *
 * To run a mutation, you first call `useAddPerformersToThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPerformersToThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPerformersToThreadMutation, { data, loading, error }] = useAddPerformersToThreadMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddPerformersToThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPerformersToThreadMutation,
    AddPerformersToThreadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPerformersToThreadMutation,
    AddPerformersToThreadMutationVariables
  >(AddPerformersToThreadDocument, options);
}
export type AddPerformersToThreadMutationHookResult = ReturnType<
  typeof useAddPerformersToThreadMutation
>;
export type AddPerformersToThreadMutationResult =
  Apollo.MutationResult<AddPerformersToThreadMutation>;
export type AddPerformersToThreadMutationOptions = Apollo.BaseMutationOptions<
  AddPerformersToThreadMutation,
  AddPerformersToThreadMutationVariables
>;
export const AdminEditUserDocument = gql`
  mutation adminEditUser($data: EditStatusUserInput!) {
    adminEditUser(data: $data) {
      status
    }
  }
`;
export type AdminEditUserMutationFn = Apollo.MutationFunction<
  AdminEditUserMutation,
  AdminEditUserMutationVariables
>;

/**
 * __useAdminEditUserMutation__
 *
 * To run a mutation, you first call `useAdminEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditUserMutation, { data, loading, error }] = useAdminEditUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminEditUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminEditUserMutation,
    AdminEditUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminEditUserMutation, AdminEditUserMutationVariables>(
    AdminEditUserDocument,
    options,
  );
}
export type AdminEditUserMutationHookResult = ReturnType<typeof useAdminEditUserMutation>;
export type AdminEditUserMutationResult = Apollo.MutationResult<AdminEditUserMutation>;
export type AdminEditUserMutationOptions = Apollo.BaseMutationOptions<
  AdminEditUserMutation,
  AdminEditUserMutationVariables
>;
export const AssignUserToTaskDocument = gql`
  mutation assignUserToTask($taskId: Int!, $userId: Int!) {
    assignUserToTask(taskId: $taskId, userId: $userId) {
      id
      createdAt
      updatedAt
      name
      description
      project {
        id
        name
      }
      assignees {
        id
        fullName
        image
        login
      }
      status {
        id
        color
      }
    }
  }
`;
export type AssignUserToTaskMutationFn = Apollo.MutationFunction<
  AssignUserToTaskMutation,
  AssignUserToTaskMutationVariables
>;

/**
 * __useAssignUserToTaskMutation__
 *
 * To run a mutation, you first call `useAssignUserToTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignUserToTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignUserToTaskMutation, { data, loading, error }] = useAssignUserToTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAssignUserToTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignUserToTaskMutation,
    AssignUserToTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignUserToTaskMutation, AssignUserToTaskMutationVariables>(
    AssignUserToTaskDocument,
    options,
  );
}
export type AssignUserToTaskMutationHookResult = ReturnType<
  typeof useAssignUserToTaskMutation
>;
export type AssignUserToTaskMutationResult =
  Apollo.MutationResult<AssignUserToTaskMutation>;
export type AssignUserToTaskMutationOptions = Apollo.BaseMutationOptions<
  AssignUserToTaskMutation,
  AssignUserToTaskMutationVariables
>;
export const CheckIsUnsubscribedAndSubscribeToThreadDocument = gql`
  mutation checkIsUnsubscribedAndSubscribeToThread($threadId: Int!) {
    checkIsUnsubscribedAndSubscribeToThread(threadId: $threadId)
  }
`;
export type CheckIsUnsubscribedAndSubscribeToThreadMutationFn = Apollo.MutationFunction<
  CheckIsUnsubscribedAndSubscribeToThreadMutation,
  CheckIsUnsubscribedAndSubscribeToThreadMutationVariables
>;

/**
 * __useCheckIsUnsubscribedAndSubscribeToThreadMutation__
 *
 * To run a mutation, you first call `useCheckIsUnsubscribedAndSubscribeToThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckIsUnsubscribedAndSubscribeToThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkIsUnsubscribedAndSubscribeToThreadMutation, { data, loading, error }] = useCheckIsUnsubscribedAndSubscribeToThreadMutation({
 *   variables: {
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useCheckIsUnsubscribedAndSubscribeToThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckIsUnsubscribedAndSubscribeToThreadMutation,
    CheckIsUnsubscribedAndSubscribeToThreadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CheckIsUnsubscribedAndSubscribeToThreadMutation,
    CheckIsUnsubscribedAndSubscribeToThreadMutationVariables
  >(CheckIsUnsubscribedAndSubscribeToThreadDocument, options);
}
export type CheckIsUnsubscribedAndSubscribeToThreadMutationHookResult = ReturnType<
  typeof useCheckIsUnsubscribedAndSubscribeToThreadMutation
>;
export type CheckIsUnsubscribedAndSubscribeToThreadMutationResult =
  Apollo.MutationResult<CheckIsUnsubscribedAndSubscribeToThreadMutation>;
export type CheckIsUnsubscribedAndSubscribeToThreadMutationOptions =
  Apollo.BaseMutationOptions<
    CheckIsUnsubscribedAndSubscribeToThreadMutation,
    CheckIsUnsubscribedAndSubscribeToThreadMutationVariables
  >;
export const ClearMessagesFromChatDocument = gql`
  mutation clearMessagesFromChat($chatId: Int!) {
    clearMessagesFromChat(chatId: $chatId)
  }
`;
export type ClearMessagesFromChatMutationFn = Apollo.MutationFunction<
  ClearMessagesFromChatMutation,
  ClearMessagesFromChatMutationVariables
>;

/**
 * __useClearMessagesFromChatMutation__
 *
 * To run a mutation, you first call `useClearMessagesFromChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearMessagesFromChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearMessagesFromChatMutation, { data, loading, error }] = useClearMessagesFromChatMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useClearMessagesFromChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearMessagesFromChatMutation,
    ClearMessagesFromChatMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClearMessagesFromChatMutation,
    ClearMessagesFromChatMutationVariables
  >(ClearMessagesFromChatDocument, options);
}
export type ClearMessagesFromChatMutationHookResult = ReturnType<
  typeof useClearMessagesFromChatMutation
>;
export type ClearMessagesFromChatMutationResult =
  Apollo.MutationResult<ClearMessagesFromChatMutation>;
export type ClearMessagesFromChatMutationOptions = Apollo.BaseMutationOptions<
  ClearMessagesFromChatMutation,
  ClearMessagesFromChatMutationVariables
>;
export const CloseSubtaskDocument = gql`
  mutation closeSubtask($id: Int!) {
    closeSubtask(id: $id) {
      id
      name
      daysAfterStartCount
      startDate
      endDate
      closeDate
    }
  }
`;
export type CloseSubtaskMutationFn = Apollo.MutationFunction<
  CloseSubtaskMutation,
  CloseSubtaskMutationVariables
>;

/**
 * __useCloseSubtaskMutation__
 *
 * To run a mutation, you first call `useCloseSubtaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseSubtaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeSubtaskMutation, { data, loading, error }] = useCloseSubtaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCloseSubtaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloseSubtaskMutation,
    CloseSubtaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CloseSubtaskMutation, CloseSubtaskMutationVariables>(
    CloseSubtaskDocument,
    options,
  );
}
export type CloseSubtaskMutationHookResult = ReturnType<typeof useCloseSubtaskMutation>;
export type CloseSubtaskMutationResult = Apollo.MutationResult<CloseSubtaskMutation>;
export type CloseSubtaskMutationOptions = Apollo.BaseMutationOptions<
  CloseSubtaskMutation,
  CloseSubtaskMutationVariables
>;
export const CloseTaskDocument = gql`
  mutation closeTask($id: Int!) {
    closeTask(id: $id) {
      id
      name
      daysAfterStartCount
      startDate
      endDate
      closeDate
      realizationPerMonthAmount
      realizationPrice
    }
  }
`;
export type CloseTaskMutationFn = Apollo.MutationFunction<
  CloseTaskMutation,
  CloseTaskMutationVariables
>;

/**
 * __useCloseTaskMutation__
 *
 * To run a mutation, you first call `useCloseTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeTaskMutation, { data, loading, error }] = useCloseTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCloseTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<CloseTaskMutation, CloseTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CloseTaskMutation, CloseTaskMutationVariables>(
    CloseTaskDocument,
    options,
  );
}
export type CloseTaskMutationHookResult = ReturnType<typeof useCloseTaskMutation>;
export type CloseTaskMutationResult = Apollo.MutationResult<CloseTaskMutation>;
export type CloseTaskMutationOptions = Apollo.BaseMutationOptions<
  CloseTaskMutation,
  CloseTaskMutationVariables
>;
export const CreateChronographReportDocument = gql`
  mutation createChronographReport($data: CreateChronographReportInput!) {
    createChronographReport(data: $data)
  }
`;
export type CreateChronographReportMutationFn = Apollo.MutationFunction<
  CreateChronographReportMutation,
  CreateChronographReportMutationVariables
>;

/**
 * __useCreateChronographReportMutation__
 *
 * To run a mutation, you first call `useCreateChronographReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChronographReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChronographReportMutation, { data, loading, error }] = useCreateChronographReportMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateChronographReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChronographReportMutation,
    CreateChronographReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateChronographReportMutation,
    CreateChronographReportMutationVariables
  >(CreateChronographReportDocument, options);
}
export type CreateChronographReportMutationHookResult = ReturnType<
  typeof useCreateChronographReportMutation
>;
export type CreateChronographReportMutationResult =
  Apollo.MutationResult<CreateChronographReportMutation>;
export type CreateChronographReportMutationOptions = Apollo.BaseMutationOptions<
  CreateChronographReportMutation,
  CreateChronographReportMutationVariables
>;
export const CreateDecideOnFlyActionDocument = gql`
  mutation createDecideOnFlyAction(
    $createDecideOnFlyActionInput: CreateDecideOnFlyActionInput!
  ) {
    createDecideOnFlyAction(createDecideOnFlyActionInput: $createDecideOnFlyActionInput) {
      id
    }
  }
`;
export type CreateDecideOnFlyActionMutationFn = Apollo.MutationFunction<
  CreateDecideOnFlyActionMutation,
  CreateDecideOnFlyActionMutationVariables
>;

/**
 * __useCreateDecideOnFlyActionMutation__
 *
 * To run a mutation, you first call `useCreateDecideOnFlyActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDecideOnFlyActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDecideOnFlyActionMutation, { data, loading, error }] = useCreateDecideOnFlyActionMutation({
 *   variables: {
 *      createDecideOnFlyActionInput: // value for 'createDecideOnFlyActionInput'
 *   },
 * });
 */
export function useCreateDecideOnFlyActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDecideOnFlyActionMutation,
    CreateDecideOnFlyActionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDecideOnFlyActionMutation,
    CreateDecideOnFlyActionMutationVariables
  >(CreateDecideOnFlyActionDocument, options);
}
export type CreateDecideOnFlyActionMutationHookResult = ReturnType<
  typeof useCreateDecideOnFlyActionMutation
>;
export type CreateDecideOnFlyActionMutationResult =
  Apollo.MutationResult<CreateDecideOnFlyActionMutation>;
export type CreateDecideOnFlyActionMutationOptions = Apollo.BaseMutationOptions<
  CreateDecideOnFlyActionMutation,
  CreateDecideOnFlyActionMutationVariables
>;
export const CreateDecideOnFlyPackageDocument = gql`
  mutation createDecideOnFlyPackage(
    $createDecideOnFlyPackageInput: CreateDecideOnFlyPackageInput!
  ) {
    createDecideOnFlyPackage(
      createDecideOnFlyPackageInput: $createDecideOnFlyPackageInput
    ) {
      id
    }
  }
`;
export type CreateDecideOnFlyPackageMutationFn = Apollo.MutationFunction<
  CreateDecideOnFlyPackageMutation,
  CreateDecideOnFlyPackageMutationVariables
>;

/**
 * __useCreateDecideOnFlyPackageMutation__
 *
 * To run a mutation, you first call `useCreateDecideOnFlyPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDecideOnFlyPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDecideOnFlyPackageMutation, { data, loading, error }] = useCreateDecideOnFlyPackageMutation({
 *   variables: {
 *      createDecideOnFlyPackageInput: // value for 'createDecideOnFlyPackageInput'
 *   },
 * });
 */
export function useCreateDecideOnFlyPackageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDecideOnFlyPackageMutation,
    CreateDecideOnFlyPackageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDecideOnFlyPackageMutation,
    CreateDecideOnFlyPackageMutationVariables
  >(CreateDecideOnFlyPackageDocument, options);
}
export type CreateDecideOnFlyPackageMutationHookResult = ReturnType<
  typeof useCreateDecideOnFlyPackageMutation
>;
export type CreateDecideOnFlyPackageMutationResult =
  Apollo.MutationResult<CreateDecideOnFlyPackageMutation>;
export type CreateDecideOnFlyPackageMutationOptions = Apollo.BaseMutationOptions<
  CreateDecideOnFlyPackageMutation,
  CreateDecideOnFlyPackageMutationVariables
>;
export const CreateForumMessageDocument = gql`
  mutation createForumMessage($data: CreateForumMessageInput!) {
    createForumMessage(data: $data) {
      id
      createdAt
      updatedAt
      text
      forumThreadId
    }
  }
`;
export type CreateForumMessageMutationFn = Apollo.MutationFunction<
  CreateForumMessageMutation,
  CreateForumMessageMutationVariables
>;

/**
 * __useCreateForumMessageMutation__
 *
 * To run a mutation, you first call `useCreateForumMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateForumMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createForumMessageMutation, { data, loading, error }] = useCreateForumMessageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateForumMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateForumMessageMutation,
    CreateForumMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateForumMessageMutation,
    CreateForumMessageMutationVariables
  >(CreateForumMessageDocument, options);
}
export type CreateForumMessageMutationHookResult = ReturnType<
  typeof useCreateForumMessageMutation
>;
export type CreateForumMessageMutationResult =
  Apollo.MutationResult<CreateForumMessageMutation>;
export type CreateForumMessageMutationOptions = Apollo.BaseMutationOptions<
  CreateForumMessageMutation,
  CreateForumMessageMutationVariables
>;
export const CreateForumSectionDocument = gql`
  mutation createForumSection($data: CreateForumSection!) {
    createForumSection(data: $data) {
      id
      createdAt
      updatedAt
      name
      role
    }
  }
`;
export type CreateForumSectionMutationFn = Apollo.MutationFunction<
  CreateForumSectionMutation,
  CreateForumSectionMutationVariables
>;

/**
 * __useCreateForumSectionMutation__
 *
 * To run a mutation, you first call `useCreateForumSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateForumSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createForumSectionMutation, { data, loading, error }] = useCreateForumSectionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateForumSectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateForumSectionMutation,
    CreateForumSectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateForumSectionMutation,
    CreateForumSectionMutationVariables
  >(CreateForumSectionDocument, options);
}
export type CreateForumSectionMutationHookResult = ReturnType<
  typeof useCreateForumSectionMutation
>;
export type CreateForumSectionMutationResult =
  Apollo.MutationResult<CreateForumSectionMutation>;
export type CreateForumSectionMutationOptions = Apollo.BaseMutationOptions<
  CreateForumSectionMutation,
  CreateForumSectionMutationVariables
>;
export const CreateForumThreadDocument = gql`
  mutation createForumThread($data: CreateForumThreadInput!) {
    createForumThread(data: $data) {
      id
      createdAt
      updatedAt
      name
      description
      imagePath
      forumSectionId
      authorId
      viewType
    }
  }
`;
export type CreateForumThreadMutationFn = Apollo.MutationFunction<
  CreateForumThreadMutation,
  CreateForumThreadMutationVariables
>;

/**
 * __useCreateForumThreadMutation__
 *
 * To run a mutation, you first call `useCreateForumThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateForumThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createForumThreadMutation, { data, loading, error }] = useCreateForumThreadMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateForumThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateForumThreadMutation,
    CreateForumThreadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateForumThreadMutation,
    CreateForumThreadMutationVariables
  >(CreateForumThreadDocument, options);
}
export type CreateForumThreadMutationHookResult = ReturnType<
  typeof useCreateForumThreadMutation
>;
export type CreateForumThreadMutationResult =
  Apollo.MutationResult<CreateForumThreadMutation>;
export type CreateForumThreadMutationOptions = Apollo.BaseMutationOptions<
  CreateForumThreadMutation,
  CreateForumThreadMutationVariables
>;
export const CreateNoteDocument = gql`
  mutation createNote($data: CreateNoteInput!) {
    createNote(data: $data) {
      id
      createdAt
      chatId
      taskId
      content
      ownerId
      owner {
        id
        login
        fullName
        image
        createdAt
        role
      }
    }
  }
`;
export type CreateNoteMutationFn = Apollo.MutationFunction<
  CreateNoteMutation,
  CreateNoteMutationVariables
>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNoteMutation,
    CreateNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(
    CreateNoteDocument,
    options,
  );
}
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = Apollo.MutationResult<CreateNoteMutation>;
export type CreateNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateNoteMutation,
  CreateNoteMutationVariables
>;
export const CreatePoligonParentLinkDocument = gql`
  mutation createPoligonParentLink($parentId: Int!, $poligonObjectId: Int) {
    createPoligonParentLink(parentId: $parentId, poligonObjectId: $poligonObjectId)
  }
`;
export type CreatePoligonParentLinkMutationFn = Apollo.MutationFunction<
  CreatePoligonParentLinkMutation,
  CreatePoligonParentLinkMutationVariables
>;

/**
 * __useCreatePoligonParentLinkMutation__
 *
 * To run a mutation, you first call `useCreatePoligonParentLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePoligonParentLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPoligonParentLinkMutation, { data, loading, error }] = useCreatePoligonParentLinkMutation({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      poligonObjectId: // value for 'poligonObjectId'
 *   },
 * });
 */
export function useCreatePoligonParentLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePoligonParentLinkMutation,
    CreatePoligonParentLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePoligonParentLinkMutation,
    CreatePoligonParentLinkMutationVariables
  >(CreatePoligonParentLinkDocument, options);
}
export type CreatePoligonParentLinkMutationHookResult = ReturnType<
  typeof useCreatePoligonParentLinkMutation
>;
export type CreatePoligonParentLinkMutationResult =
  Apollo.MutationResult<CreatePoligonParentLinkMutation>;
export type CreatePoligonParentLinkMutationOptions = Apollo.BaseMutationOptions<
  CreatePoligonParentLinkMutation,
  CreatePoligonParentLinkMutationVariables
>;
export const CreatePoligonTemplateDocument = gql`
  mutation createPoligonTemplate($data: CreatePoligonTemplateInput!) {
    createPoligonTemplate(data: $data) {
      id
      createdAt
      updatedAt
      name
      canvasObject
    }
  }
`;
export type CreatePoligonTemplateMutationFn = Apollo.MutationFunction<
  CreatePoligonTemplateMutation,
  CreatePoligonTemplateMutationVariables
>;

/**
 * __useCreatePoligonTemplateMutation__
 *
 * To run a mutation, you first call `useCreatePoligonTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePoligonTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPoligonTemplateMutation, { data, loading, error }] = useCreatePoligonTemplateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePoligonTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePoligonTemplateMutation,
    CreatePoligonTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePoligonTemplateMutation,
    CreatePoligonTemplateMutationVariables
  >(CreatePoligonTemplateDocument, options);
}
export type CreatePoligonTemplateMutationHookResult = ReturnType<
  typeof useCreatePoligonTemplateMutation
>;
export type CreatePoligonTemplateMutationResult =
  Apollo.MutationResult<CreatePoligonTemplateMutation>;
export type CreatePoligonTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreatePoligonTemplateMutation,
  CreatePoligonTemplateMutationVariables
>;
export const CreatePrivateChatDocument = gql`
  mutation createPrivateChat($userId: Float!) {
    createPrivateChat(userId: $userId) {
      id
    }
  }
`;
export type CreatePrivateChatMutationFn = Apollo.MutationFunction<
  CreatePrivateChatMutation,
  CreatePrivateChatMutationVariables
>;

/**
 * __useCreatePrivateChatMutation__
 *
 * To run a mutation, you first call `useCreatePrivateChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrivateChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrivateChatMutation, { data, loading, error }] = useCreatePrivateChatMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreatePrivateChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePrivateChatMutation,
    CreatePrivateChatMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePrivateChatMutation,
    CreatePrivateChatMutationVariables
  >(CreatePrivateChatDocument, options);
}
export type CreatePrivateChatMutationHookResult = ReturnType<
  typeof useCreatePrivateChatMutation
>;
export type CreatePrivateChatMutationResult =
  Apollo.MutationResult<CreatePrivateChatMutation>;
export type CreatePrivateChatMutationOptions = Apollo.BaseMutationOptions<
  CreatePrivateChatMutation,
  CreatePrivateChatMutationVariables
>;
export const CreateProjectDocument = gql`
  mutation createProject($data: CreateProjectInput!) {
    createProject(data: $data) {
      id
      name
      number
      createdAt
      updatedAt
      author {
        id
        image
        login
      }
      tasks(data: { sort: { type: DESC, field: activity }, isTaskStorage: false }) {
        count
        rows {
          id
          number
          name
          code
          startDate
          closeDate
          endDate
          status {
            id
            color
          }
          project {
            id
            number
          }
          author {
            id
            image
            login
            role
          }
          assignees {
            id
            image
            login
            role
          }
          maintainer {
            id
            login
            image
          }
        }
      }
    }
  }
`;
export type CreateProjectMutationFn = Apollo.MutationFunction<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(
    CreateProjectDocument,
    options,
  );
}
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;
export const CreateScaleMapFileDocument = gql`
  mutation createScaleMapFile($input: CreateScaleMapFileInput!) {
    createScaleMapFile(input: $input) {
      id
      name
      createdAt
      updatedAt
      project {
        id
      }
      scaleMapTasks {
        id
      }
      statistics {
        tasksCount
      }
    }
  }
`;
export type CreateScaleMapFileMutationFn = Apollo.MutationFunction<
  CreateScaleMapFileMutation,
  CreateScaleMapFileMutationVariables
>;

/**
 * __useCreateScaleMapFileMutation__
 *
 * To run a mutation, you first call `useCreateScaleMapFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScaleMapFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScaleMapFileMutation, { data, loading, error }] = useCreateScaleMapFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateScaleMapFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateScaleMapFileMutation,
    CreateScaleMapFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateScaleMapFileMutation,
    CreateScaleMapFileMutationVariables
  >(CreateScaleMapFileDocument, options);
}
export type CreateScaleMapFileMutationHookResult = ReturnType<
  typeof useCreateScaleMapFileMutation
>;
export type CreateScaleMapFileMutationResult =
  Apollo.MutationResult<CreateScaleMapFileMutation>;
export type CreateScaleMapFileMutationOptions = Apollo.BaseMutationOptions<
  CreateScaleMapFileMutation,
  CreateScaleMapFileMutationVariables
>;
export const CreateScaleMapTaskDocument = gql`
  mutation createScaleMapTask($input: CreateScaleMapTaskInput!) {
    createScaleMapTask(input: $input) {
      id
      name
      column
      row
      order
      scaleMapTaskId
      isExclamationPoint
      thread {
        id
      }
      status {
        id
        name
        color
      }
      isNestingAllTasksWithCompleted
      isMain
      statistics {
        maxStatusInNestingTasks {
          id
          name
          color
        }
        maxStatusInNestingTasksPercent
      }
      nestingTaskCount
    }
  }
`;
export type CreateScaleMapTaskMutationFn = Apollo.MutationFunction<
  CreateScaleMapTaskMutation,
  CreateScaleMapTaskMutationVariables
>;

/**
 * __useCreateScaleMapTaskMutation__
 *
 * To run a mutation, you first call `useCreateScaleMapTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScaleMapTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScaleMapTaskMutation, { data, loading, error }] = useCreateScaleMapTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateScaleMapTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateScaleMapTaskMutation,
    CreateScaleMapTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateScaleMapTaskMutation,
    CreateScaleMapTaskMutationVariables
  >(CreateScaleMapTaskDocument, options);
}
export type CreateScaleMapTaskMutationHookResult = ReturnType<
  typeof useCreateScaleMapTaskMutation
>;
export type CreateScaleMapTaskMutationResult =
  Apollo.MutationResult<CreateScaleMapTaskMutation>;
export type CreateScaleMapTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateScaleMapTaskMutation,
  CreateScaleMapTaskMutationVariables
>;
export const CreateSocialCircleObjectDocument = gql`
  mutation createSocialCircleObject(
    $createSocialCircleObjectInput: CreateSocialCircleObjectInput!
  ) {
    createSocialCircleObject(
      createSocialCircleObjectInput: $createSocialCircleObjectInput
    ) {
      id
    }
  }
`;
export type CreateSocialCircleObjectMutationFn = Apollo.MutationFunction<
  CreateSocialCircleObjectMutation,
  CreateSocialCircleObjectMutationVariables
>;

/**
 * __useCreateSocialCircleObjectMutation__
 *
 * To run a mutation, you first call `useCreateSocialCircleObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSocialCircleObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSocialCircleObjectMutation, { data, loading, error }] = useCreateSocialCircleObjectMutation({
 *   variables: {
 *      createSocialCircleObjectInput: // value for 'createSocialCircleObjectInput'
 *   },
 * });
 */
export function useCreateSocialCircleObjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSocialCircleObjectMutation,
    CreateSocialCircleObjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSocialCircleObjectMutation,
    CreateSocialCircleObjectMutationVariables
  >(CreateSocialCircleObjectDocument, options);
}
export type CreateSocialCircleObjectMutationHookResult = ReturnType<
  typeof useCreateSocialCircleObjectMutation
>;
export type CreateSocialCircleObjectMutationResult =
  Apollo.MutationResult<CreateSocialCircleObjectMutation>;
export type CreateSocialCircleObjectMutationOptions = Apollo.BaseMutationOptions<
  CreateSocialCircleObjectMutation,
  CreateSocialCircleObjectMutationVariables
>;
export const CreateSubtaskDocument = gql`
  mutation createSubtask($data: CreateSubtaskInput!) {
    createSubtask(data: $data) {
      id
      createdAt
      updatedAt
      number
      name
      description
      startDate
      closeDate
      task {
        id
        name
        number
        code
        project {
          id
          number
          name
        }
      }
      status {
        id
        color
      }
      author {
        id
        login
        image
      }
      maintainer {
        id
        image
        login
      }
      code
      endDate
      tags {
        count
        rows {
          id
          createdAt
          updatedAt
          name
          color
        }
      }
    }
  }
`;
export type CreateSubtaskMutationFn = Apollo.MutationFunction<
  CreateSubtaskMutation,
  CreateSubtaskMutationVariables
>;

/**
 * __useCreateSubtaskMutation__
 *
 * To run a mutation, you first call `useCreateSubtaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubtaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubtaskMutation, { data, loading, error }] = useCreateSubtaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSubtaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSubtaskMutation,
    CreateSubtaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSubtaskMutation, CreateSubtaskMutationVariables>(
    CreateSubtaskDocument,
    options,
  );
}
export type CreateSubtaskMutationHookResult = ReturnType<typeof useCreateSubtaskMutation>;
export type CreateSubtaskMutationResult = Apollo.MutationResult<CreateSubtaskMutation>;
export type CreateSubtaskMutationOptions = Apollo.BaseMutationOptions<
  CreateSubtaskMutation,
  CreateSubtaskMutationVariables
>;
export const CreateTagDocument = gql`
  mutation createTag($data: CreateTagInput!) {
    createTag(data: $data) {
      id
    }
  }
`;
export type CreateTagMutationFn = Apollo.MutationFunction<
  CreateTagMutation,
  CreateTagMutationVariables
>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(
    CreateTagDocument,
    options,
  );
}
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<
  CreateTagMutation,
  CreateTagMutationVariables
>;
export const CreateTaskDocument = gql`
  mutation createTask($data: CreateTaskInput!) {
    createTask(data: $data) {
      id
      number
      name
      code
      number
      startDate
      closeDate
      endDate
      project {
        id
        name
      }
      status {
        id
        color
      }
      project {
        id
        number
      }
      author {
        id
        image
        login
        role
      }
      assignees {
        id
        image
        login
        role
      }
      maintainer {
        id
        login
        image
      }
      subtasks(data: { sort: { type: DESC, field: activity } }) {
        id
        createdAt
        name
        number
        startDate
        closeDate
        endDate
        status {
          id
          color
        }
        task {
          id
          number
          project {
            id
            name
          }
        }
        tags {
          count
          rows {
            id
            createdAt
            updatedAt
            name
            color
          }
        }
      }
      maintainer {
        id
        login
        image
        fullName
      }
      tags {
        count
        rows {
          id
          createdAt
          updatedAt
          name
          color
        }
      }
      realizationPerMonthAmount
      realizationPrice
    }
  }
`;
export type CreateTaskMutationFn = Apollo.MutationFunction<
  CreateTaskMutation,
  CreateTaskMutationVariables
>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTaskMutation,
    CreateTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(
    CreateTaskDocument,
    options,
  );
}
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateTaskMutation,
  CreateTaskMutationVariables
>;
export const CreateThreadDocument = gql`
  mutation createThread($data: CreateThread!) {
    createThread(data: $data) {
      id
    }
  }
`;
export type CreateThreadMutationFn = Apollo.MutationFunction<
  CreateThreadMutation,
  CreateThreadMutationVariables
>;

/**
 * __useCreateThreadMutation__
 *
 * To run a mutation, you first call `useCreateThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createThreadMutation, { data, loading, error }] = useCreateThreadMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateThreadMutation,
    CreateThreadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateThreadMutation, CreateThreadMutationVariables>(
    CreateThreadDocument,
    options,
  );
}
export type CreateThreadMutationHookResult = ReturnType<typeof useCreateThreadMutation>;
export type CreateThreadMutationResult = Apollo.MutationResult<CreateThreadMutation>;
export type CreateThreadMutationOptions = Apollo.BaseMutationOptions<
  CreateThreadMutation,
  CreateThreadMutationVariables
>;
export const CreateThreadBackgroundDocument = gql`
  mutation createThreadBackground(
    $createThreadBackgroundInputs: [CreateThreadBackgroundInput!]!
  ) {
    createThreadBackground(createThreadBackgroundInputs: $createThreadBackgroundInputs) {
      id
    }
  }
`;
export type CreateThreadBackgroundMutationFn = Apollo.MutationFunction<
  CreateThreadBackgroundMutation,
  CreateThreadBackgroundMutationVariables
>;

/**
 * __useCreateThreadBackgroundMutation__
 *
 * To run a mutation, you first call `useCreateThreadBackgroundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateThreadBackgroundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createThreadBackgroundMutation, { data, loading, error }] = useCreateThreadBackgroundMutation({
 *   variables: {
 *      createThreadBackgroundInputs: // value for 'createThreadBackgroundInputs'
 *   },
 * });
 */
export function useCreateThreadBackgroundMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateThreadBackgroundMutation,
    CreateThreadBackgroundMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateThreadBackgroundMutation,
    CreateThreadBackgroundMutationVariables
  >(CreateThreadBackgroundDocument, options);
}
export type CreateThreadBackgroundMutationHookResult = ReturnType<
  typeof useCreateThreadBackgroundMutation
>;
export type CreateThreadBackgroundMutationResult =
  Apollo.MutationResult<CreateThreadBackgroundMutation>;
export type CreateThreadBackgroundMutationOptions = Apollo.BaseMutationOptions<
  CreateThreadBackgroundMutation,
  CreateThreadBackgroundMutationVariables
>;
export const CreateUserDocument = gql`
  mutation createUser($data: CreateUserInput!) {
    createUser(data: $data)
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options,
  );
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const CreateUserCharacteristicDocument = gql`
  mutation createUserCharacteristic($data: CreateUserCharacteristicInput!) {
    createUserCharacteristic(data: $data) {
      id
      text
      color
    }
  }
`;
export type CreateUserCharacteristicMutationFn = Apollo.MutationFunction<
  CreateUserCharacteristicMutation,
  CreateUserCharacteristicMutationVariables
>;

/**
 * __useCreateUserCharacteristicMutation__
 *
 * To run a mutation, you first call `useCreateUserCharacteristicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserCharacteristicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserCharacteristicMutation, { data, loading, error }] = useCreateUserCharacteristicMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserCharacteristicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserCharacteristicMutation,
    CreateUserCharacteristicMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserCharacteristicMutation,
    CreateUserCharacteristicMutationVariables
  >(CreateUserCharacteristicDocument, options);
}
export type CreateUserCharacteristicMutationHookResult = ReturnType<
  typeof useCreateUserCharacteristicMutation
>;
export type CreateUserCharacteristicMutationResult =
  Apollo.MutationResult<CreateUserCharacteristicMutation>;
export type CreateUserCharacteristicMutationOptions = Apollo.BaseMutationOptions<
  CreateUserCharacteristicMutation,
  CreateUserCharacteristicMutationVariables
>;
export const CreateUserGroupDocument = gql`
  mutation createUserGroup($createUserGroupInput: CreateUserGroupInput!) {
    createUserGroup(createUserGroupInput: $createUserGroupInput) {
      id
    }
  }
`;
export type CreateUserGroupMutationFn = Apollo.MutationFunction<
  CreateUserGroupMutation,
  CreateUserGroupMutationVariables
>;

/**
 * __useCreateUserGroupMutation__
 *
 * To run a mutation, you first call `useCreateUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserGroupMutation, { data, loading, error }] = useCreateUserGroupMutation({
 *   variables: {
 *      createUserGroupInput: // value for 'createUserGroupInput'
 *   },
 * });
 */
export function useCreateUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserGroupMutation,
    CreateUserGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserGroupMutation, CreateUserGroupMutationVariables>(
    CreateUserGroupDocument,
    options,
  );
}
export type CreateUserGroupMutationHookResult = ReturnType<
  typeof useCreateUserGroupMutation
>;
export type CreateUserGroupMutationResult =
  Apollo.MutationResult<CreateUserGroupMutation>;
export type CreateUserGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateUserGroupMutation,
  CreateUserGroupMutationVariables
>;
export const DeassignUserFromTaskDocument = gql`
  mutation deassignUserFromTask($taskId: Int!, $userId: Int!) {
    deassignUserFromTask(taskId: $taskId, userId: $userId) {
      id
      assignees {
        id
        login
        image
      }
    }
  }
`;
export type DeassignUserFromTaskMutationFn = Apollo.MutationFunction<
  DeassignUserFromTaskMutation,
  DeassignUserFromTaskMutationVariables
>;

/**
 * __useDeassignUserFromTaskMutation__
 *
 * To run a mutation, you first call `useDeassignUserFromTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeassignUserFromTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deassignUserFromTaskMutation, { data, loading, error }] = useDeassignUserFromTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeassignUserFromTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeassignUserFromTaskMutation,
    DeassignUserFromTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeassignUserFromTaskMutation,
    DeassignUserFromTaskMutationVariables
  >(DeassignUserFromTaskDocument, options);
}
export type DeassignUserFromTaskMutationHookResult = ReturnType<
  typeof useDeassignUserFromTaskMutation
>;
export type DeassignUserFromTaskMutationResult =
  Apollo.MutationResult<DeassignUserFromTaskMutation>;
export type DeassignUserFromTaskMutationOptions = Apollo.BaseMutationOptions<
  DeassignUserFromTaskMutation,
  DeassignUserFromTaskMutationVariables
>;
export const DeleteChronographSampleItemDocument = gql`
  mutation deleteChronographSampleItem(
    $scaleMapFileId: Int!
    $chronographSampleId: Int!
  ) {
    deleteChronographSampleItem(
      scaleMapFileId: $scaleMapFileId
      chronographSampleId: $chronographSampleId
    )
  }
`;
export type DeleteChronographSampleItemMutationFn = Apollo.MutationFunction<
  DeleteChronographSampleItemMutation,
  DeleteChronographSampleItemMutationVariables
>;

/**
 * __useDeleteChronographSampleItemMutation__
 *
 * To run a mutation, you first call `useDeleteChronographSampleItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChronographSampleItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChronographSampleItemMutation, { data, loading, error }] = useDeleteChronographSampleItemMutation({
 *   variables: {
 *      scaleMapFileId: // value for 'scaleMapFileId'
 *      chronographSampleId: // value for 'chronographSampleId'
 *   },
 * });
 */
export function useDeleteChronographSampleItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteChronographSampleItemMutation,
    DeleteChronographSampleItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteChronographSampleItemMutation,
    DeleteChronographSampleItemMutationVariables
  >(DeleteChronographSampleItemDocument, options);
}
export type DeleteChronographSampleItemMutationHookResult = ReturnType<
  typeof useDeleteChronographSampleItemMutation
>;
export type DeleteChronographSampleItemMutationResult =
  Apollo.MutationResult<DeleteChronographSampleItemMutation>;
export type DeleteChronographSampleItemMutationOptions = Apollo.BaseMutationOptions<
  DeleteChronographSampleItemMutation,
  DeleteChronographSampleItemMutationVariables
>;
export const DeleteFileDocument = gql`
  mutation deleteFile($id: Float!) {
    deleteFile(id: $id) {
      status
    }
  }
`;
export type DeleteFileMutationFn = Apollo.MutationFunction<
  DeleteFileMutation,
  DeleteFileMutationVariables
>;

/**
 * __useDeleteFileMutation__
 *
 * To run a mutation, you first call `useDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileMutation, { data, loading, error }] = useDeleteFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFileMutation,
    DeleteFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(
    DeleteFileDocument,
    options,
  );
}
export type DeleteFileMutationHookResult = ReturnType<typeof useDeleteFileMutation>;
export type DeleteFileMutationResult = Apollo.MutationResult<DeleteFileMutation>;
export type DeleteFileMutationOptions = Apollo.BaseMutationOptions<
  DeleteFileMutation,
  DeleteFileMutationVariables
>;
export const DeleteMessageDocument = gql`
  mutation deleteMessage($messageId: ID!) {
    deleteMessage(messageId: $messageId)
  }
`;
export type DeleteMessageMutationFn = Apollo.MutationFunction<
  DeleteMessageMutation,
  DeleteMessageMutationVariables
>;

/**
 * __useDeleteMessageMutation__
 *
 * To run a mutation, you first call `useDeleteMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageMutation, { data, loading, error }] = useDeleteMessageMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useDeleteMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMessageMutation,
    DeleteMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMessageMutation, DeleteMessageMutationVariables>(
    DeleteMessageDocument,
    options,
  );
}
export type DeleteMessageMutationHookResult = ReturnType<typeof useDeleteMessageMutation>;
export type DeleteMessageMutationResult = Apollo.MutationResult<DeleteMessageMutation>;
export type DeleteMessageMutationOptions = Apollo.BaseMutationOptions<
  DeleteMessageMutation,
  DeleteMessageMutationVariables
>;
export const DeletePoligonDocument = gql`
  mutation deletePoligon($id: Int!, $force: Boolean = false) {
    deletePoligon(id: $id, force: $force)
  }
`;
export type DeletePoligonMutationFn = Apollo.MutationFunction<
  DeletePoligonMutation,
  DeletePoligonMutationVariables
>;

/**
 * __useDeletePoligonMutation__
 *
 * To run a mutation, you first call `useDeletePoligonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePoligonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePoligonMutation, { data, loading, error }] = useDeletePoligonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      force: // value for 'force'
 *   },
 * });
 */
export function useDeletePoligonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePoligonMutation,
    DeletePoligonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePoligonMutation, DeletePoligonMutationVariables>(
    DeletePoligonDocument,
    options,
  );
}
export type DeletePoligonMutationHookResult = ReturnType<typeof useDeletePoligonMutation>;
export type DeletePoligonMutationResult = Apollo.MutationResult<DeletePoligonMutation>;
export type DeletePoligonMutationOptions = Apollo.BaseMutationOptions<
  DeletePoligonMutation,
  DeletePoligonMutationVariables
>;
export const DeletePoligonObjectDocument = gql`
  mutation deletePoligonObject($id: Int!, $force: Boolean = false) {
    deletePoligonObject(id: $id, force: $force)
  }
`;
export type DeletePoligonObjectMutationFn = Apollo.MutationFunction<
  DeletePoligonObjectMutation,
  DeletePoligonObjectMutationVariables
>;

/**
 * __useDeletePoligonObjectMutation__
 *
 * To run a mutation, you first call `useDeletePoligonObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePoligonObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePoligonObjectMutation, { data, loading, error }] = useDeletePoligonObjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      force: // value for 'force'
 *   },
 * });
 */
export function useDeletePoligonObjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePoligonObjectMutation,
    DeletePoligonObjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePoligonObjectMutation,
    DeletePoligonObjectMutationVariables
  >(DeletePoligonObjectDocument, options);
}
export type DeletePoligonObjectMutationHookResult = ReturnType<
  typeof useDeletePoligonObjectMutation
>;
export type DeletePoligonObjectMutationResult =
  Apollo.MutationResult<DeletePoligonObjectMutation>;
export type DeletePoligonObjectMutationOptions = Apollo.BaseMutationOptions<
  DeletePoligonObjectMutation,
  DeletePoligonObjectMutationVariables
>;
export const DeletePoligonParentLinkDocument = gql`
  mutation deletePoligonParentLink($parentId: Int!, $poligonObjectId: Int!) {
    deletePoligonParentLink(parentId: $parentId, poligonObjectId: $poligonObjectId)
  }
`;
export type DeletePoligonParentLinkMutationFn = Apollo.MutationFunction<
  DeletePoligonParentLinkMutation,
  DeletePoligonParentLinkMutationVariables
>;

/**
 * __useDeletePoligonParentLinkMutation__
 *
 * To run a mutation, you first call `useDeletePoligonParentLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePoligonParentLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePoligonParentLinkMutation, { data, loading, error }] = useDeletePoligonParentLinkMutation({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      poligonObjectId: // value for 'poligonObjectId'
 *   },
 * });
 */
export function useDeletePoligonParentLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePoligonParentLinkMutation,
    DeletePoligonParentLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePoligonParentLinkMutation,
    DeletePoligonParentLinkMutationVariables
  >(DeletePoligonParentLinkDocument, options);
}
export type DeletePoligonParentLinkMutationHookResult = ReturnType<
  typeof useDeletePoligonParentLinkMutation
>;
export type DeletePoligonParentLinkMutationResult =
  Apollo.MutationResult<DeletePoligonParentLinkMutation>;
export type DeletePoligonParentLinkMutationOptions = Apollo.BaseMutationOptions<
  DeletePoligonParentLinkMutation,
  DeletePoligonParentLinkMutationVariables
>;
export const DeletePoligonTemplateDocument = gql`
  mutation deletePoligonTemplate($id: Int!, $force: Boolean = false) {
    deletePoligonTemplate(id: $id, force: $force)
  }
`;
export type DeletePoligonTemplateMutationFn = Apollo.MutationFunction<
  DeletePoligonTemplateMutation,
  DeletePoligonTemplateMutationVariables
>;

/**
 * __useDeletePoligonTemplateMutation__
 *
 * To run a mutation, you first call `useDeletePoligonTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePoligonTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePoligonTemplateMutation, { data, loading, error }] = useDeletePoligonTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      force: // value for 'force'
 *   },
 * });
 */
export function useDeletePoligonTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePoligonTemplateMutation,
    DeletePoligonTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePoligonTemplateMutation,
    DeletePoligonTemplateMutationVariables
  >(DeletePoligonTemplateDocument, options);
}
export type DeletePoligonTemplateMutationHookResult = ReturnType<
  typeof useDeletePoligonTemplateMutation
>;
export type DeletePoligonTemplateMutationResult =
  Apollo.MutationResult<DeletePoligonTemplateMutation>;
export type DeletePoligonTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeletePoligonTemplateMutation,
  DeletePoligonTemplateMutationVariables
>;
export const DeleteProjectDocument = gql`
  mutation deleteProject($id: Int!, $force: Boolean) {
    deleteProject(id: $id, force: $force)
  }
`;
export type DeleteProjectMutationFn = Apollo.MutationFunction<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      force: // value for 'force'
 *   },
 * });
 */
export function useDeleteProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(
    DeleteProjectDocument,
    options,
  );
}
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;
export const DeleteScaleMapFileDocument = gql`
  mutation deleteScaleMapFile($id: Int!) {
    deleteScaleMapFile(id: $id)
  }
`;
export type DeleteScaleMapFileMutationFn = Apollo.MutationFunction<
  DeleteScaleMapFileMutation,
  DeleteScaleMapFileMutationVariables
>;

/**
 * __useDeleteScaleMapFileMutation__
 *
 * To run a mutation, you first call `useDeleteScaleMapFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScaleMapFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScaleMapFileMutation, { data, loading, error }] = useDeleteScaleMapFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteScaleMapFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteScaleMapFileMutation,
    DeleteScaleMapFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteScaleMapFileMutation,
    DeleteScaleMapFileMutationVariables
  >(DeleteScaleMapFileDocument, options);
}
export type DeleteScaleMapFileMutationHookResult = ReturnType<
  typeof useDeleteScaleMapFileMutation
>;
export type DeleteScaleMapFileMutationResult =
  Apollo.MutationResult<DeleteScaleMapFileMutation>;
export type DeleteScaleMapFileMutationOptions = Apollo.BaseMutationOptions<
  DeleteScaleMapFileMutation,
  DeleteScaleMapFileMutationVariables
>;
export const DeleteScaleMapTaskDocument = gql`
  mutation deleteScaleMapTask($id: Int!) {
    deleteScaleMapTask(id: $id)
  }
`;
export type DeleteScaleMapTaskMutationFn = Apollo.MutationFunction<
  DeleteScaleMapTaskMutation,
  DeleteScaleMapTaskMutationVariables
>;

/**
 * __useDeleteScaleMapTaskMutation__
 *
 * To run a mutation, you first call `useDeleteScaleMapTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScaleMapTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScaleMapTaskMutation, { data, loading, error }] = useDeleteScaleMapTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteScaleMapTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteScaleMapTaskMutation,
    DeleteScaleMapTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteScaleMapTaskMutation,
    DeleteScaleMapTaskMutationVariables
  >(DeleteScaleMapTaskDocument, options);
}
export type DeleteScaleMapTaskMutationHookResult = ReturnType<
  typeof useDeleteScaleMapTaskMutation
>;
export type DeleteScaleMapTaskMutationResult =
  Apollo.MutationResult<DeleteScaleMapTaskMutation>;
export type DeleteScaleMapTaskMutationOptions = Apollo.BaseMutationOptions<
  DeleteScaleMapTaskMutation,
  DeleteScaleMapTaskMutationVariables
>;
export const DeleteScaleMapTaskWithResponseDocument = gql`
  mutation deleteScaleMapTaskWithResponse($id: Int!) {
    deleteScaleMapTaskWithResponse(id: $id) {
      id
    }
  }
`;
export type DeleteScaleMapTaskWithResponseMutationFn = Apollo.MutationFunction<
  DeleteScaleMapTaskWithResponseMutation,
  DeleteScaleMapTaskWithResponseMutationVariables
>;

/**
 * __useDeleteScaleMapTaskWithResponseMutation__
 *
 * To run a mutation, you first call `useDeleteScaleMapTaskWithResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScaleMapTaskWithResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScaleMapTaskWithResponseMutation, { data, loading, error }] = useDeleteScaleMapTaskWithResponseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteScaleMapTaskWithResponseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteScaleMapTaskWithResponseMutation,
    DeleteScaleMapTaskWithResponseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteScaleMapTaskWithResponseMutation,
    DeleteScaleMapTaskWithResponseMutationVariables
  >(DeleteScaleMapTaskWithResponseDocument, options);
}
export type DeleteScaleMapTaskWithResponseMutationHookResult = ReturnType<
  typeof useDeleteScaleMapTaskWithResponseMutation
>;
export type DeleteScaleMapTaskWithResponseMutationResult =
  Apollo.MutationResult<DeleteScaleMapTaskWithResponseMutation>;
export type DeleteScaleMapTaskWithResponseMutationOptions = Apollo.BaseMutationOptions<
  DeleteScaleMapTaskWithResponseMutation,
  DeleteScaleMapTaskWithResponseMutationVariables
>;
export const DeleteStorageItemDocument = gql`
  mutation deleteStorageItem($storageItemId: Int!) {
    deleteStorageItem(storageItemId: $storageItemId)
  }
`;
export type DeleteStorageItemMutationFn = Apollo.MutationFunction<
  DeleteStorageItemMutation,
  DeleteStorageItemMutationVariables
>;

/**
 * __useDeleteStorageItemMutation__
 *
 * To run a mutation, you first call `useDeleteStorageItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStorageItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStorageItemMutation, { data, loading, error }] = useDeleteStorageItemMutation({
 *   variables: {
 *      storageItemId: // value for 'storageItemId'
 *   },
 * });
 */
export function useDeleteStorageItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteStorageItemMutation,
    DeleteStorageItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteStorageItemMutation,
    DeleteStorageItemMutationVariables
  >(DeleteStorageItemDocument, options);
}
export type DeleteStorageItemMutationHookResult = ReturnType<
  typeof useDeleteStorageItemMutation
>;
export type DeleteStorageItemMutationResult =
  Apollo.MutationResult<DeleteStorageItemMutation>;
export type DeleteStorageItemMutationOptions = Apollo.BaseMutationOptions<
  DeleteStorageItemMutation,
  DeleteStorageItemMutationVariables
>;
export const DeleteSubtaskDocument = gql`
  mutation deleteSubtask($id: Int!) {
    deleteSubtask(id: $id)
  }
`;
export type DeleteSubtaskMutationFn = Apollo.MutationFunction<
  DeleteSubtaskMutation,
  DeleteSubtaskMutationVariables
>;

/**
 * __useDeleteSubtaskMutation__
 *
 * To run a mutation, you first call `useDeleteSubtaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubtaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubtaskMutation, { data, loading, error }] = useDeleteSubtaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSubtaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSubtaskMutation,
    DeleteSubtaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSubtaskMutation, DeleteSubtaskMutationVariables>(
    DeleteSubtaskDocument,
    options,
  );
}
export type DeleteSubtaskMutationHookResult = ReturnType<typeof useDeleteSubtaskMutation>;
export type DeleteSubtaskMutationResult = Apollo.MutationResult<DeleteSubtaskMutation>;
export type DeleteSubtaskMutationOptions = Apollo.BaseMutationOptions<
  DeleteSubtaskMutation,
  DeleteSubtaskMutationVariables
>;
export const DeleteTagDocument = gql`
  mutation deleteTag($id: Int!) {
    deleteTag(id: $id)
  }
`;
export type DeleteTagMutationFn = Apollo.MutationFunction<
  DeleteTagMutation,
  DeleteTagMutationVariables
>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTagMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTagMutation, DeleteTagMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTagMutation, DeleteTagMutationVariables>(
    DeleteTagDocument,
    options,
  );
}
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = Apollo.MutationResult<DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<
  DeleteTagMutation,
  DeleteTagMutationVariables
>;
export const DeleteTaskDocument = gql`
  mutation deleteTask($id: Int!) {
    deleteTask(id: $id)
  }
`;
export type DeleteTaskMutationFn = Apollo.MutationFunction<
  DeleteTaskMutation,
  DeleteTaskMutationVariables
>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTaskMutation,
    DeleteTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(
    DeleteTaskDocument,
    options,
  );
}
export type DeleteTaskMutationHookResult = ReturnType<typeof useDeleteTaskMutation>;
export type DeleteTaskMutationResult = Apollo.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<
  DeleteTaskMutation,
  DeleteTaskMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options,
  );
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const DeleteUserCharacteristicDocument = gql`
  mutation deleteUserCharacteristic($id: Int!) {
    deleteUserCharacteristic(id: $id)
  }
`;
export type DeleteUserCharacteristicMutationFn = Apollo.MutationFunction<
  DeleteUserCharacteristicMutation,
  DeleteUserCharacteristicMutationVariables
>;

/**
 * __useDeleteUserCharacteristicMutation__
 *
 * To run a mutation, you first call `useDeleteUserCharacteristicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserCharacteristicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserCharacteristicMutation, { data, loading, error }] = useDeleteUserCharacteristicMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserCharacteristicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserCharacteristicMutation,
    DeleteUserCharacteristicMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserCharacteristicMutation,
    DeleteUserCharacteristicMutationVariables
  >(DeleteUserCharacteristicDocument, options);
}
export type DeleteUserCharacteristicMutationHookResult = ReturnType<
  typeof useDeleteUserCharacteristicMutation
>;
export type DeleteUserCharacteristicMutationResult =
  Apollo.MutationResult<DeleteUserCharacteristicMutation>;
export type DeleteUserCharacteristicMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserCharacteristicMutation,
  DeleteUserCharacteristicMutationVariables
>;
export const EditUserDocument = gql`
  mutation editUser($data: EditUserInput!) {
    editUser(data: $data) {
      id
      login
      fullName
      image
      theNote
    }
  }
`;
export type EditUserMutationFn = Apollo.MutationFunction<
  EditUserMutation,
  EditUserMutationVariables
>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditUserMutation(
  baseOptions?: Apollo.MutationHookOptions<EditUserMutation, EditUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditUserMutation, EditUserMutationVariables>(
    EditUserDocument,
    options,
  );
}
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<
  EditUserMutation,
  EditUserMutationVariables
>;
export const ExitThreadPerformersDocument = gql`
  mutation exitThreadPerformers($threadId: Int!) {
    exitThreadPerformers(threadId: $threadId)
  }
`;
export type ExitThreadPerformersMutationFn = Apollo.MutationFunction<
  ExitThreadPerformersMutation,
  ExitThreadPerformersMutationVariables
>;

/**
 * __useExitThreadPerformersMutation__
 *
 * To run a mutation, you first call `useExitThreadPerformersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExitThreadPerformersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exitThreadPerformersMutation, { data, loading, error }] = useExitThreadPerformersMutation({
 *   variables: {
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useExitThreadPerformersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExitThreadPerformersMutation,
    ExitThreadPerformersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExitThreadPerformersMutation,
    ExitThreadPerformersMutationVariables
  >(ExitThreadPerformersDocument, options);
}
export type ExitThreadPerformersMutationHookResult = ReturnType<
  typeof useExitThreadPerformersMutation
>;
export type ExitThreadPerformersMutationResult =
  Apollo.MutationResult<ExitThreadPerformersMutation>;
export type ExitThreadPerformersMutationOptions = Apollo.BaseMutationOptions<
  ExitThreadPerformersMutation,
  ExitThreadPerformersMutationVariables
>;
export const GeneratePasswordResetTokenUserDocument = gql`
  mutation generatePasswordResetTokenUser($userId: ID!) {
    generatePasswordResetTokenUser(userId: $userId)
  }
`;
export type GeneratePasswordResetTokenUserMutationFn = Apollo.MutationFunction<
  GeneratePasswordResetTokenUserMutation,
  GeneratePasswordResetTokenUserMutationVariables
>;

/**
 * __useGeneratePasswordResetTokenUserMutation__
 *
 * To run a mutation, you first call `useGeneratePasswordResetTokenUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePasswordResetTokenUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePasswordResetTokenUserMutation, { data, loading, error }] = useGeneratePasswordResetTokenUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGeneratePasswordResetTokenUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratePasswordResetTokenUserMutation,
    GeneratePasswordResetTokenUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratePasswordResetTokenUserMutation,
    GeneratePasswordResetTokenUserMutationVariables
  >(GeneratePasswordResetTokenUserDocument, options);
}
export type GeneratePasswordResetTokenUserMutationHookResult = ReturnType<
  typeof useGeneratePasswordResetTokenUserMutation
>;
export type GeneratePasswordResetTokenUserMutationResult =
  Apollo.MutationResult<GeneratePasswordResetTokenUserMutation>;
export type GeneratePasswordResetTokenUserMutationOptions = Apollo.BaseMutationOptions<
  GeneratePasswordResetTokenUserMutation,
  GeneratePasswordResetTokenUserMutationVariables
>;
export const GetOrCreatePrivateChatDocument = gql`
  mutation getOrCreatePrivateChat($userId: Float!, $offset: Int) {
    getOrCreatePrivateChat(userId: $userId) {
      ...chatFragment
    }
  }
  ${ChatFragmentFragmentDoc}
`;
export type GetOrCreatePrivateChatMutationFn = Apollo.MutationFunction<
  GetOrCreatePrivateChatMutation,
  GetOrCreatePrivateChatMutationVariables
>;

/**
 * __useGetOrCreatePrivateChatMutation__
 *
 * To run a mutation, you first call `useGetOrCreatePrivateChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetOrCreatePrivateChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getOrCreatePrivateChatMutation, { data, loading, error }] = useGetOrCreatePrivateChatMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetOrCreatePrivateChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetOrCreatePrivateChatMutation,
    GetOrCreatePrivateChatMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetOrCreatePrivateChatMutation,
    GetOrCreatePrivateChatMutationVariables
  >(GetOrCreatePrivateChatDocument, options);
}
export type GetOrCreatePrivateChatMutationHookResult = ReturnType<
  typeof useGetOrCreatePrivateChatMutation
>;
export type GetOrCreatePrivateChatMutationResult =
  Apollo.MutationResult<GetOrCreatePrivateChatMutation>;
export type GetOrCreatePrivateChatMutationOptions = Apollo.BaseMutationOptions<
  GetOrCreatePrivateChatMutation,
  GetOrCreatePrivateChatMutationVariables
>;
export const GetRandomDecideOnFlyActionDocument = gql`
  mutation getRandomDecideOnFlyAction($timezoneOffset: Int!) {
    getRandomDecideOnFlyAction(timezoneOffset: $timezoneOffset) {
      isAccessToGet
      currentUserAction {
        id
        userId
        decideOnFlyActionId
        startDate
        endDate
        status
        reasonForNotDone
      }
    }
  }
`;
export type GetRandomDecideOnFlyActionMutationFn = Apollo.MutationFunction<
  GetRandomDecideOnFlyActionMutation,
  GetRandomDecideOnFlyActionMutationVariables
>;

/**
 * __useGetRandomDecideOnFlyActionMutation__
 *
 * To run a mutation, you first call `useGetRandomDecideOnFlyActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetRandomDecideOnFlyActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getRandomDecideOnFlyActionMutation, { data, loading, error }] = useGetRandomDecideOnFlyActionMutation({
 *   variables: {
 *      timezoneOffset: // value for 'timezoneOffset'
 *   },
 * });
 */
export function useGetRandomDecideOnFlyActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetRandomDecideOnFlyActionMutation,
    GetRandomDecideOnFlyActionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetRandomDecideOnFlyActionMutation,
    GetRandomDecideOnFlyActionMutationVariables
  >(GetRandomDecideOnFlyActionDocument, options);
}
export type GetRandomDecideOnFlyActionMutationHookResult = ReturnType<
  typeof useGetRandomDecideOnFlyActionMutation
>;
export type GetRandomDecideOnFlyActionMutationResult =
  Apollo.MutationResult<GetRandomDecideOnFlyActionMutation>;
export type GetRandomDecideOnFlyActionMutationOptions = Apollo.BaseMutationOptions<
  GetRandomDecideOnFlyActionMutation,
  GetRandomDecideOnFlyActionMutationVariables
>;
export const LoadFileDocument = gql`
  mutation loadFile($data: LoadFileInput!) {
    loadFile(data: $data) {
      id
      fileName
      path
      size
    }
  }
`;
export type LoadFileMutationFn = Apollo.MutationFunction<
  LoadFileMutation,
  LoadFileMutationVariables
>;

/**
 * __useLoadFileMutation__
 *
 * To run a mutation, you first call `useLoadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loadFileMutation, { data, loading, error }] = useLoadFileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoadFileMutation(
  baseOptions?: Apollo.MutationHookOptions<LoadFileMutation, LoadFileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoadFileMutation, LoadFileMutationVariables>(
    LoadFileDocument,
    options,
  );
}
export type LoadFileMutationHookResult = ReturnType<typeof useLoadFileMutation>;
export type LoadFileMutationResult = Apollo.MutationResult<LoadFileMutation>;
export type LoadFileMutationOptions = Apollo.BaseMutationOptions<
  LoadFileMutation,
  LoadFileMutationVariables
>;
export const MadeSocialCircleMeetingDocument = gql`
  mutation madeSocialCircleMeeting($socialCircleObjectId: Int!) {
    madeSocialCircleMeeting(socialCircleObjectId: $socialCircleObjectId) {
      id
    }
  }
`;
export type MadeSocialCircleMeetingMutationFn = Apollo.MutationFunction<
  MadeSocialCircleMeetingMutation,
  MadeSocialCircleMeetingMutationVariables
>;

/**
 * __useMadeSocialCircleMeetingMutation__
 *
 * To run a mutation, you first call `useMadeSocialCircleMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMadeSocialCircleMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [madeSocialCircleMeetingMutation, { data, loading, error }] = useMadeSocialCircleMeetingMutation({
 *   variables: {
 *      socialCircleObjectId: // value for 'socialCircleObjectId'
 *   },
 * });
 */
export function useMadeSocialCircleMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MadeSocialCircleMeetingMutation,
    MadeSocialCircleMeetingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MadeSocialCircleMeetingMutation,
    MadeSocialCircleMeetingMutationVariables
  >(MadeSocialCircleMeetingDocument, options);
}
export type MadeSocialCircleMeetingMutationHookResult = ReturnType<
  typeof useMadeSocialCircleMeetingMutation
>;
export type MadeSocialCircleMeetingMutationResult =
  Apollo.MutationResult<MadeSocialCircleMeetingMutation>;
export type MadeSocialCircleMeetingMutationOptions = Apollo.BaseMutationOptions<
  MadeSocialCircleMeetingMutation,
  MadeSocialCircleMeetingMutationVariables
>;
export const MoveScaleMapTaskDocument = gql`
  mutation moveScaleMapTask($scaleMapTaskId: Int!, $parentScaleMapTaskId: Int!) {
    moveScaleMapTask(
      scaleMapTaskId: $scaleMapTaskId
      parentScaleMapTaskId: $parentScaleMapTaskId
    )
  }
`;
export type MoveScaleMapTaskMutationFn = Apollo.MutationFunction<
  MoveScaleMapTaskMutation,
  MoveScaleMapTaskMutationVariables
>;

/**
 * __useMoveScaleMapTaskMutation__
 *
 * To run a mutation, you first call `useMoveScaleMapTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveScaleMapTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveScaleMapTaskMutation, { data, loading, error }] = useMoveScaleMapTaskMutation({
 *   variables: {
 *      scaleMapTaskId: // value for 'scaleMapTaskId'
 *      parentScaleMapTaskId: // value for 'parentScaleMapTaskId'
 *   },
 * });
 */
export function useMoveScaleMapTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveScaleMapTaskMutation,
    MoveScaleMapTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MoveScaleMapTaskMutation, MoveScaleMapTaskMutationVariables>(
    MoveScaleMapTaskDocument,
    options,
  );
}
export type MoveScaleMapTaskMutationHookResult = ReturnType<
  typeof useMoveScaleMapTaskMutation
>;
export type MoveScaleMapTaskMutationResult =
  Apollo.MutationResult<MoveScaleMapTaskMutation>;
export type MoveScaleMapTaskMutationOptions = Apollo.BaseMutationOptions<
  MoveScaleMapTaskMutation,
  MoveScaleMapTaskMutationVariables
>;
export const MoveScaleMapTaskToRowDocument = gql`
  mutation moveScaleMapTaskToRow($id: Int!, $row: Int!, $order: Int!) {
    moveScaleMapTaskToRow(id: $id, row: $row, order: $order)
  }
`;
export type MoveScaleMapTaskToRowMutationFn = Apollo.MutationFunction<
  MoveScaleMapTaskToRowMutation,
  MoveScaleMapTaskToRowMutationVariables
>;

/**
 * __useMoveScaleMapTaskToRowMutation__
 *
 * To run a mutation, you first call `useMoveScaleMapTaskToRowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveScaleMapTaskToRowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveScaleMapTaskToRowMutation, { data, loading, error }] = useMoveScaleMapTaskToRowMutation({
 *   variables: {
 *      id: // value for 'id'
 *      row: // value for 'row'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useMoveScaleMapTaskToRowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveScaleMapTaskToRowMutation,
    MoveScaleMapTaskToRowMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MoveScaleMapTaskToRowMutation,
    MoveScaleMapTaskToRowMutationVariables
  >(MoveScaleMapTaskToRowDocument, options);
}
export type MoveScaleMapTaskToRowMutationHookResult = ReturnType<
  typeof useMoveScaleMapTaskToRowMutation
>;
export type MoveScaleMapTaskToRowMutationResult =
  Apollo.MutationResult<MoveScaleMapTaskToRowMutation>;
export type MoveScaleMapTaskToRowMutationOptions = Apollo.BaseMutationOptions<
  MoveScaleMapTaskToRowMutation,
  MoveScaleMapTaskToRowMutationVariables
>;
export const ReadMessagesBeforeDocument = gql`
  mutation readMessagesBefore($messageId: [Int!]!) {
    readMessagesBefore(messageId: $messageId)
  }
`;
export type ReadMessagesBeforeMutationFn = Apollo.MutationFunction<
  ReadMessagesBeforeMutation,
  ReadMessagesBeforeMutationVariables
>;

/**
 * __useReadMessagesBeforeMutation__
 *
 * To run a mutation, you first call `useReadMessagesBeforeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadMessagesBeforeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readMessagesBeforeMutation, { data, loading, error }] = useReadMessagesBeforeMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useReadMessagesBeforeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReadMessagesBeforeMutation,
    ReadMessagesBeforeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReadMessagesBeforeMutation,
    ReadMessagesBeforeMutationVariables
  >(ReadMessagesBeforeDocument, options);
}
export type ReadMessagesBeforeMutationHookResult = ReturnType<
  typeof useReadMessagesBeforeMutation
>;
export type ReadMessagesBeforeMutationResult =
  Apollo.MutationResult<ReadMessagesBeforeMutation>;
export type ReadMessagesBeforeMutationOptions = Apollo.BaseMutationOptions<
  ReadMessagesBeforeMutation,
  ReadMessagesBeforeMutationVariables
>;
export const ReadMessagesByChatIdDocument = gql`
  mutation readMessagesByChatId($chatId: Int!, $isActivity: Boolean!) {
    readMessagesByChatId(chatId: $chatId, isActivity: $isActivity) {
      status
    }
  }
`;
export type ReadMessagesByChatIdMutationFn = Apollo.MutationFunction<
  ReadMessagesByChatIdMutation,
  ReadMessagesByChatIdMutationVariables
>;

/**
 * __useReadMessagesByChatIdMutation__
 *
 * To run a mutation, you first call `useReadMessagesByChatIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadMessagesByChatIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readMessagesByChatIdMutation, { data, loading, error }] = useReadMessagesByChatIdMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      isActivity: // value for 'isActivity'
 *   },
 * });
 */
export function useReadMessagesByChatIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReadMessagesByChatIdMutation,
    ReadMessagesByChatIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReadMessagesByChatIdMutation,
    ReadMessagesByChatIdMutationVariables
  >(ReadMessagesByChatIdDocument, options);
}
export type ReadMessagesByChatIdMutationHookResult = ReturnType<
  typeof useReadMessagesByChatIdMutation
>;
export type ReadMessagesByChatIdMutationResult =
  Apollo.MutationResult<ReadMessagesByChatIdMutation>;
export type ReadMessagesByChatIdMutationOptions = Apollo.BaseMutationOptions<
  ReadMessagesByChatIdMutation,
  ReadMessagesByChatIdMutationVariables
>;
export const ReadProjectActivitiesDocument = gql`
  mutation readProjectActivities($taskId: Int, $subtaskId: Int, $threadId: Int) {
    readProjectActivities(taskId: $taskId, subtaskId: $subtaskId, threadId: $threadId)
  }
`;
export type ReadProjectActivitiesMutationFn = Apollo.MutationFunction<
  ReadProjectActivitiesMutation,
  ReadProjectActivitiesMutationVariables
>;

/**
 * __useReadProjectActivitiesMutation__
 *
 * To run a mutation, you first call `useReadProjectActivitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadProjectActivitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readProjectActivitiesMutation, { data, loading, error }] = useReadProjectActivitiesMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      subtaskId: // value for 'subtaskId'
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useReadProjectActivitiesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReadProjectActivitiesMutation,
    ReadProjectActivitiesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReadProjectActivitiesMutation,
    ReadProjectActivitiesMutationVariables
  >(ReadProjectActivitiesDocument, options);
}
export type ReadProjectActivitiesMutationHookResult = ReturnType<
  typeof useReadProjectActivitiesMutation
>;
export type ReadProjectActivitiesMutationResult =
  Apollo.MutationResult<ReadProjectActivitiesMutation>;
export type ReadProjectActivitiesMutationOptions = Apollo.BaseMutationOptions<
  ReadProjectActivitiesMutation,
  ReadProjectActivitiesMutationVariables
>;
export const RemoveDecideOnFlyActionDocument = gql`
  mutation removeDecideOnFlyAction($id: Int!) {
    removeDecideOnFlyAction(id: $id)
  }
`;
export type RemoveDecideOnFlyActionMutationFn = Apollo.MutationFunction<
  RemoveDecideOnFlyActionMutation,
  RemoveDecideOnFlyActionMutationVariables
>;

/**
 * __useRemoveDecideOnFlyActionMutation__
 *
 * To run a mutation, you first call `useRemoveDecideOnFlyActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDecideOnFlyActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDecideOnFlyActionMutation, { data, loading, error }] = useRemoveDecideOnFlyActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveDecideOnFlyActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveDecideOnFlyActionMutation,
    RemoveDecideOnFlyActionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveDecideOnFlyActionMutation,
    RemoveDecideOnFlyActionMutationVariables
  >(RemoveDecideOnFlyActionDocument, options);
}
export type RemoveDecideOnFlyActionMutationHookResult = ReturnType<
  typeof useRemoveDecideOnFlyActionMutation
>;
export type RemoveDecideOnFlyActionMutationResult =
  Apollo.MutationResult<RemoveDecideOnFlyActionMutation>;
export type RemoveDecideOnFlyActionMutationOptions = Apollo.BaseMutationOptions<
  RemoveDecideOnFlyActionMutation,
  RemoveDecideOnFlyActionMutationVariables
>;
export const RemoveDecideOnFlyPackageDocument = gql`
  mutation removeDecideOnFlyPackage($id: Int!) {
    removeDecideOnFlyPackage(id: $id)
  }
`;
export type RemoveDecideOnFlyPackageMutationFn = Apollo.MutationFunction<
  RemoveDecideOnFlyPackageMutation,
  RemoveDecideOnFlyPackageMutationVariables
>;

/**
 * __useRemoveDecideOnFlyPackageMutation__
 *
 * To run a mutation, you first call `useRemoveDecideOnFlyPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDecideOnFlyPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDecideOnFlyPackageMutation, { data, loading, error }] = useRemoveDecideOnFlyPackageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveDecideOnFlyPackageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveDecideOnFlyPackageMutation,
    RemoveDecideOnFlyPackageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveDecideOnFlyPackageMutation,
    RemoveDecideOnFlyPackageMutationVariables
  >(RemoveDecideOnFlyPackageDocument, options);
}
export type RemoveDecideOnFlyPackageMutationHookResult = ReturnType<
  typeof useRemoveDecideOnFlyPackageMutation
>;
export type RemoveDecideOnFlyPackageMutationResult =
  Apollo.MutationResult<RemoveDecideOnFlyPackageMutation>;
export type RemoveDecideOnFlyPackageMutationOptions = Apollo.BaseMutationOptions<
  RemoveDecideOnFlyPackageMutation,
  RemoveDecideOnFlyPackageMutationVariables
>;
export const RemoveForumMessageDocument = gql`
  mutation removeForumMessage($id: Int!) {
    removeForumMessage(id: $id)
  }
`;
export type RemoveForumMessageMutationFn = Apollo.MutationFunction<
  RemoveForumMessageMutation,
  RemoveForumMessageMutationVariables
>;

/**
 * __useRemoveForumMessageMutation__
 *
 * To run a mutation, you first call `useRemoveForumMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveForumMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeForumMessageMutation, { data, loading, error }] = useRemoveForumMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveForumMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveForumMessageMutation,
    RemoveForumMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveForumMessageMutation,
    RemoveForumMessageMutationVariables
  >(RemoveForumMessageDocument, options);
}
export type RemoveForumMessageMutationHookResult = ReturnType<
  typeof useRemoveForumMessageMutation
>;
export type RemoveForumMessageMutationResult =
  Apollo.MutationResult<RemoveForumMessageMutation>;
export type RemoveForumMessageMutationOptions = Apollo.BaseMutationOptions<
  RemoveForumMessageMutation,
  RemoveForumMessageMutationVariables
>;
export const RemoveForumSectionDocument = gql`
  mutation removeForumSection($id: Int!) {
    removeForumSection(id: $id)
  }
`;
export type RemoveForumSectionMutationFn = Apollo.MutationFunction<
  RemoveForumSectionMutation,
  RemoveForumSectionMutationVariables
>;

/**
 * __useRemoveForumSectionMutation__
 *
 * To run a mutation, you first call `useRemoveForumSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveForumSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeForumSectionMutation, { data, loading, error }] = useRemoveForumSectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveForumSectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveForumSectionMutation,
    RemoveForumSectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveForumSectionMutation,
    RemoveForumSectionMutationVariables
  >(RemoveForumSectionDocument, options);
}
export type RemoveForumSectionMutationHookResult = ReturnType<
  typeof useRemoveForumSectionMutation
>;
export type RemoveForumSectionMutationResult =
  Apollo.MutationResult<RemoveForumSectionMutation>;
export type RemoveForumSectionMutationOptions = Apollo.BaseMutationOptions<
  RemoveForumSectionMutation,
  RemoveForumSectionMutationVariables
>;
export const RemoveForumThreadDocument = gql`
  mutation removeForumThread($id: Int!) {
    removeForumThread(id: $id)
  }
`;
export type RemoveForumThreadMutationFn = Apollo.MutationFunction<
  RemoveForumThreadMutation,
  RemoveForumThreadMutationVariables
>;

/**
 * __useRemoveForumThreadMutation__
 *
 * To run a mutation, you first call `useRemoveForumThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveForumThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeForumThreadMutation, { data, loading, error }] = useRemoveForumThreadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveForumThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveForumThreadMutation,
    RemoveForumThreadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveForumThreadMutation,
    RemoveForumThreadMutationVariables
  >(RemoveForumThreadDocument, options);
}
export type RemoveForumThreadMutationHookResult = ReturnType<
  typeof useRemoveForumThreadMutation
>;
export type RemoveForumThreadMutationResult =
  Apollo.MutationResult<RemoveForumThreadMutation>;
export type RemoveForumThreadMutationOptions = Apollo.BaseMutationOptions<
  RemoveForumThreadMutation,
  RemoveForumThreadMutationVariables
>;
export const RemoveMyAccountDocument = gql`
  mutation removeMyAccount {
    removeMyAccount
  }
`;
export type RemoveMyAccountMutationFn = Apollo.MutationFunction<
  RemoveMyAccountMutation,
  RemoveMyAccountMutationVariables
>;

/**
 * __useRemoveMyAccountMutation__
 *
 * To run a mutation, you first call `useRemoveMyAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMyAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMyAccountMutation, { data, loading, error }] = useRemoveMyAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveMyAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveMyAccountMutation,
    RemoveMyAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveMyAccountMutation, RemoveMyAccountMutationVariables>(
    RemoveMyAccountDocument,
    options,
  );
}
export type RemoveMyAccountMutationHookResult = ReturnType<
  typeof useRemoveMyAccountMutation
>;
export type RemoveMyAccountMutationResult =
  Apollo.MutationResult<RemoveMyAccountMutation>;
export type RemoveMyAccountMutationOptions = Apollo.BaseMutationOptions<
  RemoveMyAccountMutation,
  RemoveMyAccountMutationVariables
>;
export const RemoveNoteDocument = gql`
  mutation removeNote($id: Int!) {
    removeNote(id: $id)
  }
`;
export type RemoveNoteMutationFn = Apollo.MutationFunction<
  RemoveNoteMutation,
  RemoveNoteMutationVariables
>;

/**
 * __useRemoveNoteMutation__
 *
 * To run a mutation, you first call `useRemoveNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeNoteMutation, { data, loading, error }] = useRemoveNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveNoteMutation,
    RemoveNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveNoteMutation, RemoveNoteMutationVariables>(
    RemoveNoteDocument,
    options,
  );
}
export type RemoveNoteMutationHookResult = ReturnType<typeof useRemoveNoteMutation>;
export type RemoveNoteMutationResult = Apollo.MutationResult<RemoveNoteMutation>;
export type RemoveNoteMutationOptions = Apollo.BaseMutationOptions<
  RemoveNoteMutation,
  RemoveNoteMutationVariables
>;
export const RemovePerformerFromThreadDocument = gql`
  mutation removePerformerFromThread($data: AddPerformersToThread!) {
    removePerformerFromThread(data: $data)
  }
`;
export type RemovePerformerFromThreadMutationFn = Apollo.MutationFunction<
  RemovePerformerFromThreadMutation,
  RemovePerformerFromThreadMutationVariables
>;

/**
 * __useRemovePerformerFromThreadMutation__
 *
 * To run a mutation, you first call `useRemovePerformerFromThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePerformerFromThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePerformerFromThreadMutation, { data, loading, error }] = useRemovePerformerFromThreadMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemovePerformerFromThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemovePerformerFromThreadMutation,
    RemovePerformerFromThreadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemovePerformerFromThreadMutation,
    RemovePerformerFromThreadMutationVariables
  >(RemovePerformerFromThreadDocument, options);
}
export type RemovePerformerFromThreadMutationHookResult = ReturnType<
  typeof useRemovePerformerFromThreadMutation
>;
export type RemovePerformerFromThreadMutationResult =
  Apollo.MutationResult<RemovePerformerFromThreadMutation>;
export type RemovePerformerFromThreadMutationOptions = Apollo.BaseMutationOptions<
  RemovePerformerFromThreadMutation,
  RemovePerformerFromThreadMutationVariables
>;
export const RemoveSocialCircleObjectDocument = gql`
  mutation removeSocialCircleObject($id: Int!) {
    removeSocialCircleObject(id: $id)
  }
`;
export type RemoveSocialCircleObjectMutationFn = Apollo.MutationFunction<
  RemoveSocialCircleObjectMutation,
  RemoveSocialCircleObjectMutationVariables
>;

/**
 * __useRemoveSocialCircleObjectMutation__
 *
 * To run a mutation, you first call `useRemoveSocialCircleObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSocialCircleObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSocialCircleObjectMutation, { data, loading, error }] = useRemoveSocialCircleObjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSocialCircleObjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveSocialCircleObjectMutation,
    RemoveSocialCircleObjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveSocialCircleObjectMutation,
    RemoveSocialCircleObjectMutationVariables
  >(RemoveSocialCircleObjectDocument, options);
}
export type RemoveSocialCircleObjectMutationHookResult = ReturnType<
  typeof useRemoveSocialCircleObjectMutation
>;
export type RemoveSocialCircleObjectMutationResult =
  Apollo.MutationResult<RemoveSocialCircleObjectMutation>;
export type RemoveSocialCircleObjectMutationOptions = Apollo.BaseMutationOptions<
  RemoveSocialCircleObjectMutation,
  RemoveSocialCircleObjectMutationVariables
>;
export const RemoveThreadBackgroundDocument = gql`
  mutation removeThreadBackground($id: Int!) {
    removeThreadBackground(id: $id)
  }
`;
export type RemoveThreadBackgroundMutationFn = Apollo.MutationFunction<
  RemoveThreadBackgroundMutation,
  RemoveThreadBackgroundMutationVariables
>;

/**
 * __useRemoveThreadBackgroundMutation__
 *
 * To run a mutation, you first call `useRemoveThreadBackgroundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveThreadBackgroundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeThreadBackgroundMutation, { data, loading, error }] = useRemoveThreadBackgroundMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveThreadBackgroundMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveThreadBackgroundMutation,
    RemoveThreadBackgroundMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveThreadBackgroundMutation,
    RemoveThreadBackgroundMutationVariables
  >(RemoveThreadBackgroundDocument, options);
}
export type RemoveThreadBackgroundMutationHookResult = ReturnType<
  typeof useRemoveThreadBackgroundMutation
>;
export type RemoveThreadBackgroundMutationResult =
  Apollo.MutationResult<RemoveThreadBackgroundMutation>;
export type RemoveThreadBackgroundMutationOptions = Apollo.BaseMutationOptions<
  RemoveThreadBackgroundMutation,
  RemoveThreadBackgroundMutationVariables
>;
export const RemoveUserGroupDocument = gql`
  mutation removeUserGroup($id: Int!) {
    removeUserGroup(id: $id)
  }
`;
export type RemoveUserGroupMutationFn = Apollo.MutationFunction<
  RemoveUserGroupMutation,
  RemoveUserGroupMutationVariables
>;

/**
 * __useRemoveUserGroupMutation__
 *
 * To run a mutation, you first call `useRemoveUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserGroupMutation, { data, loading, error }] = useRemoveUserGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveUserGroupMutation,
    RemoveUserGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveUserGroupMutation, RemoveUserGroupMutationVariables>(
    RemoveUserGroupDocument,
    options,
  );
}
export type RemoveUserGroupMutationHookResult = ReturnType<
  typeof useRemoveUserGroupMutation
>;
export type RemoveUserGroupMutationResult =
  Apollo.MutationResult<RemoveUserGroupMutation>;
export type RemoveUserGroupMutationOptions = Apollo.BaseMutationOptions<
  RemoveUserGroupMutation,
  RemoveUserGroupMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation resetPassword($data: ResetPasswordUser!) {
    resetPassword(data: $data) {
      status
    }
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(
    ResetPasswordDocument,
    options,
  );
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const ResetPasswordByTokenDocument = gql`
  mutation resetPasswordByToken($token: String!, $newPassword: String!) {
    resetPasswordByToken(token: $token, newPassword: $newPassword) {
      status
    }
  }
`;
export type ResetPasswordByTokenMutationFn = Apollo.MutationFunction<
  ResetPasswordByTokenMutation,
  ResetPasswordByTokenMutationVariables
>;

/**
 * __useResetPasswordByTokenMutation__
 *
 * To run a mutation, you first call `useResetPasswordByTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordByTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordByTokenMutation, { data, loading, error }] = useResetPasswordByTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordByTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordByTokenMutation,
    ResetPasswordByTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordByTokenMutation,
    ResetPasswordByTokenMutationVariables
  >(ResetPasswordByTokenDocument, options);
}
export type ResetPasswordByTokenMutationHookResult = ReturnType<
  typeof useResetPasswordByTokenMutation
>;
export type ResetPasswordByTokenMutationResult =
  Apollo.MutationResult<ResetPasswordByTokenMutation>;
export type ResetPasswordByTokenMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordByTokenMutation,
  ResetPasswordByTokenMutationVariables
>;
export const ResetRealizationPriceDocument = gql`
  mutation resetRealizationPrice($taskId: Int!) {
    resetRealizationPrice(taskId: $taskId)
  }
`;
export type ResetRealizationPriceMutationFn = Apollo.MutationFunction<
  ResetRealizationPriceMutation,
  ResetRealizationPriceMutationVariables
>;

/**
 * __useResetRealizationPriceMutation__
 *
 * To run a mutation, you first call `useResetRealizationPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetRealizationPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetRealizationPriceMutation, { data, loading, error }] = useResetRealizationPriceMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useResetRealizationPriceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetRealizationPriceMutation,
    ResetRealizationPriceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetRealizationPriceMutation,
    ResetRealizationPriceMutationVariables
  >(ResetRealizationPriceDocument, options);
}
export type ResetRealizationPriceMutationHookResult = ReturnType<
  typeof useResetRealizationPriceMutation
>;
export type ResetRealizationPriceMutationResult =
  Apollo.MutationResult<ResetRealizationPriceMutation>;
export type ResetRealizationPriceMutationOptions = Apollo.BaseMutationOptions<
  ResetRealizationPriceMutation,
  ResetRealizationPriceMutationVariables
>;
export const SendMessageDocument = gql`
  mutation sendMessage($data: CreateMessageInput!) {
    sendMessage(data: $data) {
      id
      text
      isRead
      createdAt
      isExistLink
      user {
        login
        image
      }
    }
  }
`;
export type SendMessageMutationFn = Apollo.MutationFunction<
  SendMessageMutation,
  SendMessageMutationVariables
>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendMessageMutation,
    SendMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(
    SendMessageDocument,
    options,
  );
}
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<
  SendMessageMutation,
  SendMessageMutationVariables
>;
export const SetChronographDayRecordsDocument = gql`
  mutation setChronographDayRecords($data: SetChronographDayRecords!) {
    setChronographDayRecords(data: $data)
  }
`;
export type SetChronographDayRecordsMutationFn = Apollo.MutationFunction<
  SetChronographDayRecordsMutation,
  SetChronographDayRecordsMutationVariables
>;

/**
 * __useSetChronographDayRecordsMutation__
 *
 * To run a mutation, you first call `useSetChronographDayRecordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetChronographDayRecordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setChronographDayRecordsMutation, { data, loading, error }] = useSetChronographDayRecordsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSetChronographDayRecordsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetChronographDayRecordsMutation,
    SetChronographDayRecordsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetChronographDayRecordsMutation,
    SetChronographDayRecordsMutationVariables
  >(SetChronographDayRecordsDocument, options);
}
export type SetChronographDayRecordsMutationHookResult = ReturnType<
  typeof useSetChronographDayRecordsMutation
>;
export type SetChronographDayRecordsMutationResult =
  Apollo.MutationResult<SetChronographDayRecordsMutation>;
export type SetChronographDayRecordsMutationOptions = Apollo.BaseMutationOptions<
  SetChronographDayRecordsMutation,
  SetChronographDayRecordsMutationVariables
>;
export const SetDecideOnFlyPackagesToUserDocument = gql`
  mutation setDecideOnFlyPackagesToUser($packagesId: [Int!]!) {
    setDecideOnFlyPackagesToUser(packagesId: $packagesId) {
      id
    }
  }
`;
export type SetDecideOnFlyPackagesToUserMutationFn = Apollo.MutationFunction<
  SetDecideOnFlyPackagesToUserMutation,
  SetDecideOnFlyPackagesToUserMutationVariables
>;

/**
 * __useSetDecideOnFlyPackagesToUserMutation__
 *
 * To run a mutation, you first call `useSetDecideOnFlyPackagesToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDecideOnFlyPackagesToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDecideOnFlyPackagesToUserMutation, { data, loading, error }] = useSetDecideOnFlyPackagesToUserMutation({
 *   variables: {
 *      packagesId: // value for 'packagesId'
 *   },
 * });
 */
export function useSetDecideOnFlyPackagesToUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetDecideOnFlyPackagesToUserMutation,
    SetDecideOnFlyPackagesToUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetDecideOnFlyPackagesToUserMutation,
    SetDecideOnFlyPackagesToUserMutationVariables
  >(SetDecideOnFlyPackagesToUserDocument, options);
}
export type SetDecideOnFlyPackagesToUserMutationHookResult = ReturnType<
  typeof useSetDecideOnFlyPackagesToUserMutation
>;
export type SetDecideOnFlyPackagesToUserMutationResult =
  Apollo.MutationResult<SetDecideOnFlyPackagesToUserMutation>;
export type SetDecideOnFlyPackagesToUserMutationOptions = Apollo.BaseMutationOptions<
  SetDecideOnFlyPackagesToUserMutation,
  SetDecideOnFlyPackagesToUserMutationVariables
>;
export const SetKarmaDocument = gql`
  mutation setKarma($messageId: ID!, $isPlus: Boolean!) {
    setKarma(messageId: $messageId, isPlus: $isPlus)
  }
`;
export type SetKarmaMutationFn = Apollo.MutationFunction<
  SetKarmaMutation,
  SetKarmaMutationVariables
>;

/**
 * __useSetKarmaMutation__
 *
 * To run a mutation, you first call `useSetKarmaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetKarmaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setKarmaMutation, { data, loading, error }] = useSetKarmaMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *      isPlus: // value for 'isPlus'
 *   },
 * });
 */
export function useSetKarmaMutation(
  baseOptions?: Apollo.MutationHookOptions<SetKarmaMutation, SetKarmaMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetKarmaMutation, SetKarmaMutationVariables>(
    SetKarmaDocument,
    options,
  );
}
export type SetKarmaMutationHookResult = ReturnType<typeof useSetKarmaMutation>;
export type SetKarmaMutationResult = Apollo.MutationResult<SetKarmaMutation>;
export type SetKarmaMutationOptions = Apollo.BaseMutationOptions<
  SetKarmaMutation,
  SetKarmaMutationVariables
>;
export const SetPerformerStatusDocument = gql`
  mutation setPerformerStatus($data: PerformerStatus!) {
    setPerformerStatus(data: $data)
  }
`;
export type SetPerformerStatusMutationFn = Apollo.MutationFunction<
  SetPerformerStatusMutation,
  SetPerformerStatusMutationVariables
>;

/**
 * __useSetPerformerStatusMutation__
 *
 * To run a mutation, you first call `useSetPerformerStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPerformerStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPerformerStatusMutation, { data, loading, error }] = useSetPerformerStatusMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSetPerformerStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetPerformerStatusMutation,
    SetPerformerStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetPerformerStatusMutation,
    SetPerformerStatusMutationVariables
  >(SetPerformerStatusDocument, options);
}
export type SetPerformerStatusMutationHookResult = ReturnType<
  typeof useSetPerformerStatusMutation
>;
export type SetPerformerStatusMutationResult =
  Apollo.MutationResult<SetPerformerStatusMutation>;
export type SetPerformerStatusMutationOptions = Apollo.BaseMutationOptions<
  SetPerformerStatusMutation,
  SetPerformerStatusMutationVariables
>;
export const SetStatusUserDecideOnFlyActionDocument = gql`
  mutation setStatusUserDecideOnFlyAction($status: String!, $reasonForNotDone: String!) {
    setStatusUserDecideOnFlyAction(status: $status, reasonForNotDone: $reasonForNotDone)
  }
`;
export type SetStatusUserDecideOnFlyActionMutationFn = Apollo.MutationFunction<
  SetStatusUserDecideOnFlyActionMutation,
  SetStatusUserDecideOnFlyActionMutationVariables
>;

/**
 * __useSetStatusUserDecideOnFlyActionMutation__
 *
 * To run a mutation, you first call `useSetStatusUserDecideOnFlyActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStatusUserDecideOnFlyActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStatusUserDecideOnFlyActionMutation, { data, loading, error }] = useSetStatusUserDecideOnFlyActionMutation({
 *   variables: {
 *      status: // value for 'status'
 *      reasonForNotDone: // value for 'reasonForNotDone'
 *   },
 * });
 */
export function useSetStatusUserDecideOnFlyActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetStatusUserDecideOnFlyActionMutation,
    SetStatusUserDecideOnFlyActionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetStatusUserDecideOnFlyActionMutation,
    SetStatusUserDecideOnFlyActionMutationVariables
  >(SetStatusUserDecideOnFlyActionDocument, options);
}
export type SetStatusUserDecideOnFlyActionMutationHookResult = ReturnType<
  typeof useSetStatusUserDecideOnFlyActionMutation
>;
export type SetStatusUserDecideOnFlyActionMutationResult =
  Apollo.MutationResult<SetStatusUserDecideOnFlyActionMutation>;
export type SetStatusUserDecideOnFlyActionMutationOptions = Apollo.BaseMutationOptions<
  SetStatusUserDecideOnFlyActionMutation,
  SetStatusUserDecideOnFlyActionMutationVariables
>;
export const SetUserAnalyticDocument = gql`
  mutation setUserAnalytic($userId: Int!, $isAnalytics: Boolean!) {
    setUserAnalytic(userId: $userId, isAnalytics: $isAnalytics)
  }
`;
export type SetUserAnalyticMutationFn = Apollo.MutationFunction<
  SetUserAnalyticMutation,
  SetUserAnalyticMutationVariables
>;

/**
 * __useSetUserAnalyticMutation__
 *
 * To run a mutation, you first call `useSetUserAnalyticMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserAnalyticMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserAnalyticMutation, { data, loading, error }] = useSetUserAnalyticMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      isAnalytics: // value for 'isAnalytics'
 *   },
 * });
 */
export function useSetUserAnalyticMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetUserAnalyticMutation,
    SetUserAnalyticMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetUserAnalyticMutation, SetUserAnalyticMutationVariables>(
    SetUserAnalyticDocument,
    options,
  );
}
export type SetUserAnalyticMutationHookResult = ReturnType<
  typeof useSetUserAnalyticMutation
>;
export type SetUserAnalyticMutationResult =
  Apollo.MutationResult<SetUserAnalyticMutation>;
export type SetUserAnalyticMutationOptions = Apollo.BaseMutationOptions<
  SetUserAnalyticMutation,
  SetUserAnalyticMutationVariables
>;
export const StartSubtaskDocument = gql`
  mutation startSubtask($id: Int!) {
    startSubtask(id: $id) {
      id
      name
      daysAfterStartCount
      startDate
      endDate
      closeDate
    }
  }
`;
export type StartSubtaskMutationFn = Apollo.MutationFunction<
  StartSubtaskMutation,
  StartSubtaskMutationVariables
>;

/**
 * __useStartSubtaskMutation__
 *
 * To run a mutation, you first call `useStartSubtaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSubtaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSubtaskMutation, { data, loading, error }] = useStartSubtaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStartSubtaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartSubtaskMutation,
    StartSubtaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StartSubtaskMutation, StartSubtaskMutationVariables>(
    StartSubtaskDocument,
    options,
  );
}
export type StartSubtaskMutationHookResult = ReturnType<typeof useStartSubtaskMutation>;
export type StartSubtaskMutationResult = Apollo.MutationResult<StartSubtaskMutation>;
export type StartSubtaskMutationOptions = Apollo.BaseMutationOptions<
  StartSubtaskMutation,
  StartSubtaskMutationVariables
>;
export const StartTaskDocument = gql`
  mutation startTask($id: Int!) {
    startTask(id: $id) {
      id
      name
      daysAfterStartCount
      startDate
      endDate
      closeDate
      realizationPerMonthAmount
      realizationPrice
    }
  }
`;
export type StartTaskMutationFn = Apollo.MutationFunction<
  StartTaskMutation,
  StartTaskMutationVariables
>;

/**
 * __useStartTaskMutation__
 *
 * To run a mutation, you first call `useStartTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startTaskMutation, { data, loading, error }] = useStartTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStartTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<StartTaskMutation, StartTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StartTaskMutation, StartTaskMutationVariables>(
    StartTaskDocument,
    options,
  );
}
export type StartTaskMutationHookResult = ReturnType<typeof useStartTaskMutation>;
export type StartTaskMutationResult = Apollo.MutationResult<StartTaskMutation>;
export type StartTaskMutationOptions = Apollo.BaseMutationOptions<
  StartTaskMutation,
  StartTaskMutationVariables
>;
export const SubscribeToThreadDocument = gql`
  mutation subscribeToThread($threadId: Int!) {
    subscribeToThread(threadId: $threadId)
  }
`;
export type SubscribeToThreadMutationFn = Apollo.MutationFunction<
  SubscribeToThreadMutation,
  SubscribeToThreadMutationVariables
>;

/**
 * __useSubscribeToThreadMutation__
 *
 * To run a mutation, you first call `useSubscribeToThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeToThreadMutation, { data, loading, error }] = useSubscribeToThreadMutation({
 *   variables: {
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useSubscribeToThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubscribeToThreadMutation,
    SubscribeToThreadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubscribeToThreadMutation,
    SubscribeToThreadMutationVariables
  >(SubscribeToThreadDocument, options);
}
export type SubscribeToThreadMutationHookResult = ReturnType<
  typeof useSubscribeToThreadMutation
>;
export type SubscribeToThreadMutationResult =
  Apollo.MutationResult<SubscribeToThreadMutation>;
export type SubscribeToThreadMutationOptions = Apollo.BaseMutationOptions<
  SubscribeToThreadMutation,
  SubscribeToThreadMutationVariables
>;
export const TrackChronographTimeDocument = gql`
  mutation trackChronographTime($data: TrackChronographTimeInput!) {
    trackChronographTime(data: $data)
  }
`;
export type TrackChronographTimeMutationFn = Apollo.MutationFunction<
  TrackChronographTimeMutation,
  TrackChronographTimeMutationVariables
>;

/**
 * __useTrackChronographTimeMutation__
 *
 * To run a mutation, you first call `useTrackChronographTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackChronographTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackChronographTimeMutation, { data, loading, error }] = useTrackChronographTimeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTrackChronographTimeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrackChronographTimeMutation,
    TrackChronographTimeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TrackChronographTimeMutation,
    TrackChronographTimeMutationVariables
  >(TrackChronographTimeDocument, options);
}
export type TrackChronographTimeMutationHookResult = ReturnType<
  typeof useTrackChronographTimeMutation
>;
export type TrackChronographTimeMutationResult =
  Apollo.MutationResult<TrackChronographTimeMutation>;
export type TrackChronographTimeMutationOptions = Apollo.BaseMutationOptions<
  TrackChronographTimeMutation,
  TrackChronographTimeMutationVariables
>;
export const TrackStorageEventClickedDocument = gql`
  mutation trackStorageEventClicked($storageItemId: Int, $fileId: Int, $threadId: Int) {
    trackStorageEventClicked(
      storageItemId: $storageItemId
      fileId: $fileId
      threadId: $threadId
    )
  }
`;
export type TrackStorageEventClickedMutationFn = Apollo.MutationFunction<
  TrackStorageEventClickedMutation,
  TrackStorageEventClickedMutationVariables
>;

/**
 * __useTrackStorageEventClickedMutation__
 *
 * To run a mutation, you first call `useTrackStorageEventClickedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackStorageEventClickedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackStorageEventClickedMutation, { data, loading, error }] = useTrackStorageEventClickedMutation({
 *   variables: {
 *      storageItemId: // value for 'storageItemId'
 *      fileId: // value for 'fileId'
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useTrackStorageEventClickedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrackStorageEventClickedMutation,
    TrackStorageEventClickedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TrackStorageEventClickedMutation,
    TrackStorageEventClickedMutationVariables
  >(TrackStorageEventClickedDocument, options);
}
export type TrackStorageEventClickedMutationHookResult = ReturnType<
  typeof useTrackStorageEventClickedMutation
>;
export type TrackStorageEventClickedMutationResult =
  Apollo.MutationResult<TrackStorageEventClickedMutation>;
export type TrackStorageEventClickedMutationOptions = Apollo.BaseMutationOptions<
  TrackStorageEventClickedMutation,
  TrackStorageEventClickedMutationVariables
>;
export const UnsubscribeFromThreadDocument = gql`
  mutation unsubscribeFromThread($threadId: Int!) {
    unsubscribeFromThread(threadId: $threadId)
  }
`;
export type UnsubscribeFromThreadMutationFn = Apollo.MutationFunction<
  UnsubscribeFromThreadMutation,
  UnsubscribeFromThreadMutationVariables
>;

/**
 * __useUnsubscribeFromThreadMutation__
 *
 * To run a mutation, you first call `useUnsubscribeFromThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeFromThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeFromThreadMutation, { data, loading, error }] = useUnsubscribeFromThreadMutation({
 *   variables: {
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useUnsubscribeFromThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnsubscribeFromThreadMutation,
    UnsubscribeFromThreadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnsubscribeFromThreadMutation,
    UnsubscribeFromThreadMutationVariables
  >(UnsubscribeFromThreadDocument, options);
}
export type UnsubscribeFromThreadMutationHookResult = ReturnType<
  typeof useUnsubscribeFromThreadMutation
>;
export type UnsubscribeFromThreadMutationResult =
  Apollo.MutationResult<UnsubscribeFromThreadMutation>;
export type UnsubscribeFromThreadMutationOptions = Apollo.BaseMutationOptions<
  UnsubscribeFromThreadMutation,
  UnsubscribeFromThreadMutationVariables
>;
export const UpdateChronographSampleDocument = gql`
  mutation updateChronographSample($input: UpdateChronographSampleInput!) {
    updateChronographSample(input: $input) {
      id
      createdAt
      updatedAt
      name
      isPublic
      userId
      sampleItems {
        scaleMapFileId
        scaleMapFile {
          id
        }
        chronographSampleId
        scheduledTime
        scheduledTimePerDay
        isDaily
        color
      }
    }
  }
`;
export type UpdateChronographSampleMutationFn = Apollo.MutationFunction<
  UpdateChronographSampleMutation,
  UpdateChronographSampleMutationVariables
>;

/**
 * __useUpdateChronographSampleMutation__
 *
 * To run a mutation, you first call `useUpdateChronographSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChronographSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChronographSampleMutation, { data, loading, error }] = useUpdateChronographSampleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChronographSampleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChronographSampleMutation,
    UpdateChronographSampleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateChronographSampleMutation,
    UpdateChronographSampleMutationVariables
  >(UpdateChronographSampleDocument, options);
}
export type UpdateChronographSampleMutationHookResult = ReturnType<
  typeof useUpdateChronographSampleMutation
>;
export type UpdateChronographSampleMutationResult =
  Apollo.MutationResult<UpdateChronographSampleMutation>;
export type UpdateChronographSampleMutationOptions = Apollo.BaseMutationOptions<
  UpdateChronographSampleMutation,
  UpdateChronographSampleMutationVariables
>;
export const UpdateChronographSampleItemDocument = gql`
  mutation updateChronographSampleItem($data: UpdateChronographSampleItemInput!) {
    updateChronographSampleItem(data: $data)
  }
`;
export type UpdateChronographSampleItemMutationFn = Apollo.MutationFunction<
  UpdateChronographSampleItemMutation,
  UpdateChronographSampleItemMutationVariables
>;

/**
 * __useUpdateChronographSampleItemMutation__
 *
 * To run a mutation, you first call `useUpdateChronographSampleItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChronographSampleItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChronographSampleItemMutation, { data, loading, error }] = useUpdateChronographSampleItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateChronographSampleItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChronographSampleItemMutation,
    UpdateChronographSampleItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateChronographSampleItemMutation,
    UpdateChronographSampleItemMutationVariables
  >(UpdateChronographSampleItemDocument, options);
}
export type UpdateChronographSampleItemMutationHookResult = ReturnType<
  typeof useUpdateChronographSampleItemMutation
>;
export type UpdateChronographSampleItemMutationResult =
  Apollo.MutationResult<UpdateChronographSampleItemMutation>;
export type UpdateChronographSampleItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateChronographSampleItemMutation,
  UpdateChronographSampleItemMutationVariables
>;
export const UpdateDecideOnFlyActionDocument = gql`
  mutation updateDecideOnFlyAction(
    $updateDecideOnFlyActionInput: UpdateDecideOnFlyActionInput!
  ) {
    updateDecideOnFlyAction(updateDecideOnFlyActionInput: $updateDecideOnFlyActionInput) {
      id
    }
  }
`;
export type UpdateDecideOnFlyActionMutationFn = Apollo.MutationFunction<
  UpdateDecideOnFlyActionMutation,
  UpdateDecideOnFlyActionMutationVariables
>;

/**
 * __useUpdateDecideOnFlyActionMutation__
 *
 * To run a mutation, you first call `useUpdateDecideOnFlyActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDecideOnFlyActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDecideOnFlyActionMutation, { data, loading, error }] = useUpdateDecideOnFlyActionMutation({
 *   variables: {
 *      updateDecideOnFlyActionInput: // value for 'updateDecideOnFlyActionInput'
 *   },
 * });
 */
export function useUpdateDecideOnFlyActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDecideOnFlyActionMutation,
    UpdateDecideOnFlyActionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDecideOnFlyActionMutation,
    UpdateDecideOnFlyActionMutationVariables
  >(UpdateDecideOnFlyActionDocument, options);
}
export type UpdateDecideOnFlyActionMutationHookResult = ReturnType<
  typeof useUpdateDecideOnFlyActionMutation
>;
export type UpdateDecideOnFlyActionMutationResult =
  Apollo.MutationResult<UpdateDecideOnFlyActionMutation>;
export type UpdateDecideOnFlyActionMutationOptions = Apollo.BaseMutationOptions<
  UpdateDecideOnFlyActionMutation,
  UpdateDecideOnFlyActionMutationVariables
>;
export const UpdateDecideOnFlyPackageDocument = gql`
  mutation updateDecideOnFlyPackage(
    $updateDecideOnFlyPackageInput: UpdateDecideOnFlyPackageInput!
  ) {
    updateDecideOnFlyPackage(
      updateDecideOnFlyPackageInput: $updateDecideOnFlyPackageInput
    ) {
      id
    }
  }
`;
export type UpdateDecideOnFlyPackageMutationFn = Apollo.MutationFunction<
  UpdateDecideOnFlyPackageMutation,
  UpdateDecideOnFlyPackageMutationVariables
>;

/**
 * __useUpdateDecideOnFlyPackageMutation__
 *
 * To run a mutation, you first call `useUpdateDecideOnFlyPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDecideOnFlyPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDecideOnFlyPackageMutation, { data, loading, error }] = useUpdateDecideOnFlyPackageMutation({
 *   variables: {
 *      updateDecideOnFlyPackageInput: // value for 'updateDecideOnFlyPackageInput'
 *   },
 * });
 */
export function useUpdateDecideOnFlyPackageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDecideOnFlyPackageMutation,
    UpdateDecideOnFlyPackageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDecideOnFlyPackageMutation,
    UpdateDecideOnFlyPackageMutationVariables
  >(UpdateDecideOnFlyPackageDocument, options);
}
export type UpdateDecideOnFlyPackageMutationHookResult = ReturnType<
  typeof useUpdateDecideOnFlyPackageMutation
>;
export type UpdateDecideOnFlyPackageMutationResult =
  Apollo.MutationResult<UpdateDecideOnFlyPackageMutation>;
export type UpdateDecideOnFlyPackageMutationOptions = Apollo.BaseMutationOptions<
  UpdateDecideOnFlyPackageMutation,
  UpdateDecideOnFlyPackageMutationVariables
>;
export const UpdateFileDocument = gql`
  mutation updateFile($id: ID!, $data: UpdateFileInput!) {
    updateFile(id: $id, data: $data) {
      id
      fileName
    }
  }
`;
export type UpdateFileMutationFn = Apollo.MutationFunction<
  UpdateFileMutation,
  UpdateFileMutationVariables
>;

/**
 * __useUpdateFileMutation__
 *
 * To run a mutation, you first call `useUpdateFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileMutation, { data, loading, error }] = useUpdateFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFileMutation,
    UpdateFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFileMutation, UpdateFileMutationVariables>(
    UpdateFileDocument,
    options,
  );
}
export type UpdateFileMutationHookResult = ReturnType<typeof useUpdateFileMutation>;
export type UpdateFileMutationResult = Apollo.MutationResult<UpdateFileMutation>;
export type UpdateFileMutationOptions = Apollo.BaseMutationOptions<
  UpdateFileMutation,
  UpdateFileMutationVariables
>;
export const UpdateForumMessageDocument = gql`
  mutation updateForumMessage($data: UpdateForumMessageInput!) {
    updateForumMessage(data: $data) {
      id
    }
  }
`;
export type UpdateForumMessageMutationFn = Apollo.MutationFunction<
  UpdateForumMessageMutation,
  UpdateForumMessageMutationVariables
>;

/**
 * __useUpdateForumMessageMutation__
 *
 * To run a mutation, you first call `useUpdateForumMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateForumMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateForumMessageMutation, { data, loading, error }] = useUpdateForumMessageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateForumMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateForumMessageMutation,
    UpdateForumMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateForumMessageMutation,
    UpdateForumMessageMutationVariables
  >(UpdateForumMessageDocument, options);
}
export type UpdateForumMessageMutationHookResult = ReturnType<
  typeof useUpdateForumMessageMutation
>;
export type UpdateForumMessageMutationResult =
  Apollo.MutationResult<UpdateForumMessageMutation>;
export type UpdateForumMessageMutationOptions = Apollo.BaseMutationOptions<
  UpdateForumMessageMutation,
  UpdateForumMessageMutationVariables
>;
export const UpdateForumSectionDocument = gql`
  mutation updateForumSection($data: UpdateForumSection!) {
    updateForumSection(data: $data) {
      id
      createdAt
      updatedAt
      name
      role
    }
  }
`;
export type UpdateForumSectionMutationFn = Apollo.MutationFunction<
  UpdateForumSectionMutation,
  UpdateForumSectionMutationVariables
>;

/**
 * __useUpdateForumSectionMutation__
 *
 * To run a mutation, you first call `useUpdateForumSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateForumSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateForumSectionMutation, { data, loading, error }] = useUpdateForumSectionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateForumSectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateForumSectionMutation,
    UpdateForumSectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateForumSectionMutation,
    UpdateForumSectionMutationVariables
  >(UpdateForumSectionDocument, options);
}
export type UpdateForumSectionMutationHookResult = ReturnType<
  typeof useUpdateForumSectionMutation
>;
export type UpdateForumSectionMutationResult =
  Apollo.MutationResult<UpdateForumSectionMutation>;
export type UpdateForumSectionMutationOptions = Apollo.BaseMutationOptions<
  UpdateForumSectionMutation,
  UpdateForumSectionMutationVariables
>;
export const UpdateForumThreadDocument = gql`
  mutation updateForumThread($data: UpdateForumThreadInput!) {
    updateForumThread(data: $data) {
      id
      createdAt
      updatedAt
      name
      description
      imagePath
      forumSectionId
      authorId
      viewType
    }
  }
`;
export type UpdateForumThreadMutationFn = Apollo.MutationFunction<
  UpdateForumThreadMutation,
  UpdateForumThreadMutationVariables
>;

/**
 * __useUpdateForumThreadMutation__
 *
 * To run a mutation, you first call `useUpdateForumThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateForumThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateForumThreadMutation, { data, loading, error }] = useUpdateForumThreadMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateForumThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateForumThreadMutation,
    UpdateForumThreadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateForumThreadMutation,
    UpdateForumThreadMutationVariables
  >(UpdateForumThreadDocument, options);
}
export type UpdateForumThreadMutationHookResult = ReturnType<
  typeof useUpdateForumThreadMutation
>;
export type UpdateForumThreadMutationResult =
  Apollo.MutationResult<UpdateForumThreadMutation>;
export type UpdateForumThreadMutationOptions = Apollo.BaseMutationOptions<
  UpdateForumThreadMutation,
  UpdateForumThreadMutationVariables
>;
export const UpdateOrderScaleMapFilesDocument = gql`
  mutation updateOrderScaleMapFiles($data: UpdateOrderScaleMapFiles!) {
    updateOrderScaleMapFiles(data: $data)
  }
`;
export type UpdateOrderScaleMapFilesMutationFn = Apollo.MutationFunction<
  UpdateOrderScaleMapFilesMutation,
  UpdateOrderScaleMapFilesMutationVariables
>;

/**
 * __useUpdateOrderScaleMapFilesMutation__
 *
 * To run a mutation, you first call `useUpdateOrderScaleMapFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderScaleMapFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderScaleMapFilesMutation, { data, loading, error }] = useUpdateOrderScaleMapFilesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOrderScaleMapFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrderScaleMapFilesMutation,
    UpdateOrderScaleMapFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrderScaleMapFilesMutation,
    UpdateOrderScaleMapFilesMutationVariables
  >(UpdateOrderScaleMapFilesDocument, options);
}
export type UpdateOrderScaleMapFilesMutationHookResult = ReturnType<
  typeof useUpdateOrderScaleMapFilesMutation
>;
export type UpdateOrderScaleMapFilesMutationResult =
  Apollo.MutationResult<UpdateOrderScaleMapFilesMutation>;
export type UpdateOrderScaleMapFilesMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrderScaleMapFilesMutation,
  UpdateOrderScaleMapFilesMutationVariables
>;
export const UpdatePoligonTemplateDocument = gql`
  mutation updatePoligonTemplate($data: UpdatePoligonTemplateInput!, $id: Int!) {
    updatePoligonTemplate(id: $id, data: $data) {
      id
      createdAt
      updatedAt
      name
      canvasObject
    }
  }
`;
export type UpdatePoligonTemplateMutationFn = Apollo.MutationFunction<
  UpdatePoligonTemplateMutation,
  UpdatePoligonTemplateMutationVariables
>;

/**
 * __useUpdatePoligonTemplateMutation__
 *
 * To run a mutation, you first call `useUpdatePoligonTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePoligonTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePoligonTemplateMutation, { data, loading, error }] = useUpdatePoligonTemplateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdatePoligonTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePoligonTemplateMutation,
    UpdatePoligonTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePoligonTemplateMutation,
    UpdatePoligonTemplateMutationVariables
  >(UpdatePoligonTemplateDocument, options);
}
export type UpdatePoligonTemplateMutationHookResult = ReturnType<
  typeof useUpdatePoligonTemplateMutation
>;
export type UpdatePoligonTemplateMutationResult =
  Apollo.MutationResult<UpdatePoligonTemplateMutation>;
export type UpdatePoligonTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdatePoligonTemplateMutation,
  UpdatePoligonTemplateMutationVariables
>;
export const UpdateProjectDocument = gql`
  mutation updateProject($id: Int!, $data: UpdateProjectInput!) {
    updateProject(id: $id, data: $data) {
      id
      createdAt
      updatedAt
      name
      number
    }
  }
`;
export type UpdateProjectMutationFn = Apollo.MutationFunction<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectMutation,
    UpdateProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(
    UpdateProjectDocument,
    options,
  );
}
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;
export const UpdateScaleMapFileDocument = gql`
  mutation updateScaleMapFile($input: UpdateScaleMapFileInput!) {
    updateScaleMapFile(input: $input) {
      id
    }
  }
`;
export type UpdateScaleMapFileMutationFn = Apollo.MutationFunction<
  UpdateScaleMapFileMutation,
  UpdateScaleMapFileMutationVariables
>;

/**
 * __useUpdateScaleMapFileMutation__
 *
 * To run a mutation, you first call `useUpdateScaleMapFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScaleMapFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScaleMapFileMutation, { data, loading, error }] = useUpdateScaleMapFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScaleMapFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScaleMapFileMutation,
    UpdateScaleMapFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateScaleMapFileMutation,
    UpdateScaleMapFileMutationVariables
  >(UpdateScaleMapFileDocument, options);
}
export type UpdateScaleMapFileMutationHookResult = ReturnType<
  typeof useUpdateScaleMapFileMutation
>;
export type UpdateScaleMapFileMutationResult =
  Apollo.MutationResult<UpdateScaleMapFileMutation>;
export type UpdateScaleMapFileMutationOptions = Apollo.BaseMutationOptions<
  UpdateScaleMapFileMutation,
  UpdateScaleMapFileMutationVariables
>;
export const UpdateScaleMapTaskDocument = gql`
  mutation updateScaleMapTask($input: UpdateScaleMapTaskInput!) {
    updateScaleMapTask(input: $input) {
      id
      updatedAt
      name
      column
      row
      order
      scaleMapTaskId
      isExclamationPoint
      thread {
        id
      }
      status {
        id
        name
        color
      }
      scaleMapFile {
        id
      }
      isNestingAllTasksWithCompleted
      isMain
      scaleMapTaskMarkStatus {
        id
        title
        color
      }
      statistics {
        maxStatusInNestingTasks {
          id
          name
          color
        }
        maxStatusInNestingTasksPercent
      }
      nestingTaskCount
      statusUpdatedDate
      counterComment
      counterFavorites
      parentTree {
        id
        name
        column
        updatedAt
        statusUpdatedDate
        thread {
          id
        }
        status {
          id
          name
          color
        }
      }
    }
  }
`;
export type UpdateScaleMapTaskMutationFn = Apollo.MutationFunction<
  UpdateScaleMapTaskMutation,
  UpdateScaleMapTaskMutationVariables
>;

/**
 * __useUpdateScaleMapTaskMutation__
 *
 * To run a mutation, you first call `useUpdateScaleMapTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScaleMapTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScaleMapTaskMutation, { data, loading, error }] = useUpdateScaleMapTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScaleMapTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScaleMapTaskMutation,
    UpdateScaleMapTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateScaleMapTaskMutation,
    UpdateScaleMapTaskMutationVariables
  >(UpdateScaleMapTaskDocument, options);
}
export type UpdateScaleMapTaskMutationHookResult = ReturnType<
  typeof useUpdateScaleMapTaskMutation
>;
export type UpdateScaleMapTaskMutationResult =
  Apollo.MutationResult<UpdateScaleMapTaskMutation>;
export type UpdateScaleMapTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateScaleMapTaskMutation,
  UpdateScaleMapTaskMutationVariables
>;
export const UpdateSocialCircleObjectDocument = gql`
  mutation updateSocialCircleObject(
    $updateSocialCircleObjectInput: UpdateSocialCircleObjectInput!
  ) {
    updateSocialCircleObject(
      updateSocialCircleObjectInput: $updateSocialCircleObjectInput
    ) {
      id
    }
  }
`;
export type UpdateSocialCircleObjectMutationFn = Apollo.MutationFunction<
  UpdateSocialCircleObjectMutation,
  UpdateSocialCircleObjectMutationVariables
>;

/**
 * __useUpdateSocialCircleObjectMutation__
 *
 * To run a mutation, you first call `useUpdateSocialCircleObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSocialCircleObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSocialCircleObjectMutation, { data, loading, error }] = useUpdateSocialCircleObjectMutation({
 *   variables: {
 *      updateSocialCircleObjectInput: // value for 'updateSocialCircleObjectInput'
 *   },
 * });
 */
export function useUpdateSocialCircleObjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSocialCircleObjectMutation,
    UpdateSocialCircleObjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSocialCircleObjectMutation,
    UpdateSocialCircleObjectMutationVariables
  >(UpdateSocialCircleObjectDocument, options);
}
export type UpdateSocialCircleObjectMutationHookResult = ReturnType<
  typeof useUpdateSocialCircleObjectMutation
>;
export type UpdateSocialCircleObjectMutationResult =
  Apollo.MutationResult<UpdateSocialCircleObjectMutation>;
export type UpdateSocialCircleObjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateSocialCircleObjectMutation,
  UpdateSocialCircleObjectMutationVariables
>;
export const UpdateStorageItemDocument = gql`
  mutation updateStorageItem($data: UpdateStorageItem!) {
    updateStorageItem(data: $data) {
      id
    }
  }
`;
export type UpdateStorageItemMutationFn = Apollo.MutationFunction<
  UpdateStorageItemMutation,
  UpdateStorageItemMutationVariables
>;

/**
 * __useUpdateStorageItemMutation__
 *
 * To run a mutation, you first call `useUpdateStorageItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStorageItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStorageItemMutation, { data, loading, error }] = useUpdateStorageItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateStorageItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStorageItemMutation,
    UpdateStorageItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateStorageItemMutation,
    UpdateStorageItemMutationVariables
  >(UpdateStorageItemDocument, options);
}
export type UpdateStorageItemMutationHookResult = ReturnType<
  typeof useUpdateStorageItemMutation
>;
export type UpdateStorageItemMutationResult =
  Apollo.MutationResult<UpdateStorageItemMutation>;
export type UpdateStorageItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateStorageItemMutation,
  UpdateStorageItemMutationVariables
>;
export const UpdateSubtaskDocument = gql`
  mutation updateSubtask($data: UpdateSubtaskInput!, $id: Int!) {
    updateSubtask(data: $data, id: $id) {
      id
      createdAt
      name
      number
      description
      code
      startDate
      closeDate
      task {
        id
        number
        code
        startDate
        closeDate
        endDate
        project {
          id
        }
        assignees {
          id
          login
          image
          fullName
        }
        maintainer {
          id
          login
          image
          fullName
        }
      }
      status {
        id
        color
      }
      author {
        id
        login
      }
      maintainer {
        id
        login
        image
        fullName
      }
      notes {
        id
        createdAt
        chatId
        taskId
        content
        ownerId
        owner {
          id
          login
          fullName
          image
          createdAt
          role
        }
      }
      endDate
      tags {
        count
        rows {
          id
          createdAt
          updatedAt
          name
          color
        }
      }
    }
  }
`;
export type UpdateSubtaskMutationFn = Apollo.MutationFunction<
  UpdateSubtaskMutation,
  UpdateSubtaskMutationVariables
>;

/**
 * __useUpdateSubtaskMutation__
 *
 * To run a mutation, you first call `useUpdateSubtaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubtaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubtaskMutation, { data, loading, error }] = useUpdateSubtaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateSubtaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSubtaskMutation,
    UpdateSubtaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSubtaskMutation, UpdateSubtaskMutationVariables>(
    UpdateSubtaskDocument,
    options,
  );
}
export type UpdateSubtaskMutationHookResult = ReturnType<typeof useUpdateSubtaskMutation>;
export type UpdateSubtaskMutationResult = Apollo.MutationResult<UpdateSubtaskMutation>;
export type UpdateSubtaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubtaskMutation,
  UpdateSubtaskMutationVariables
>;
export const UpdateTagDocument = gql`
  mutation updateTag($id: Int!, $data: CreateTagInput!) {
    updateTag(id: $id, data: $data) {
      name
      color
      textColor
    }
  }
`;
export type UpdateTagMutationFn = Apollo.MutationFunction<
  UpdateTagMutation,
  UpdateTagMutationVariables
>;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTagMutation, UpdateTagMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(
    UpdateTagDocument,
    options,
  );
}
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>;
export type UpdateTagMutationResult = Apollo.MutationResult<UpdateTagMutation>;
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<
  UpdateTagMutation,
  UpdateTagMutationVariables
>;
export const UpdateTaskDocument = gql`
  mutation updateTask($data: UpdateTaskInput!, $id: Int!) {
    updateTask(data: $data, id: $id) {
      id
      createdAt
      updatedAt
      name
      number
      description
      code
      startDate
      closeDate
      endDate
      project {
        id
        number
        name
        number
      }
      assignees {
        id
        login
        image
        fullName
      }
      status {
        id
        color
      }
      author {
        id
        login
        image
        fullName
      }
      subtasks(data: { sort: { type: DESC, field: activity } }) {
        id
        createdAt
        updatedAt
        name
        description
        number
        code
        startDate
        closeDate
        endDate
        task {
          id
          number
          code
          project {
            id
            number
          }
        }
        status {
          id
          color
        }
        author {
          id
          login
          image
        }
        maintainer {
          id
          login
          image
          fullName
        }
        notes {
          id
          createdAt
          chatId
          taskId
          content
          ownerId
          owner {
            id
            login
            fullName
            image
          }
        }
        tags {
          count
          rows {
            id
            createdAt
            updatedAt
            name
            color
          }
        }
      }
      notes {
        id
        createdAt
        chatId
        taskId
        content
        ownerId
        owner {
          id
          login
          fullName
          image
          createdAt
          role
        }
      }
      maintainer {
        id
        login
        image
        fullName
      }
      tags {
        count
        rows {
          id
          createdAt
          updatedAt
          name
          color
        }
      }
      realizationPerMonthAmount
      realizationPrice
    }
  }
`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskMutation,
    UpdateTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(
    UpdateTaskDocument,
    options,
  );
}
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;
export const UpdateThreadDocument = gql`
  mutation updateThread($data: UpdateThread!) {
    updateThread(data: $data) {
      id
    }
  }
`;
export type UpdateThreadMutationFn = Apollo.MutationFunction<
  UpdateThreadMutation,
  UpdateThreadMutationVariables
>;

/**
 * __useUpdateThreadMutation__
 *
 * To run a mutation, you first call `useUpdateThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateThreadMutation, { data, loading, error }] = useUpdateThreadMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateThreadMutation,
    UpdateThreadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateThreadMutation, UpdateThreadMutationVariables>(
    UpdateThreadDocument,
    options,
  );
}
export type UpdateThreadMutationHookResult = ReturnType<typeof useUpdateThreadMutation>;
export type UpdateThreadMutationResult = Apollo.MutationResult<UpdateThreadMutation>;
export type UpdateThreadMutationOptions = Apollo.BaseMutationOptions<
  UpdateThreadMutation,
  UpdateThreadMutationVariables
>;
export const UpdateThreadFileCommentDocument = gql`
  mutation updateThreadFileComment($data: UpdateThreadFileComment!) {
    updateThreadFileComment(data: $data) {
      id
    }
  }
`;
export type UpdateThreadFileCommentMutationFn = Apollo.MutationFunction<
  UpdateThreadFileCommentMutation,
  UpdateThreadFileCommentMutationVariables
>;

/**
 * __useUpdateThreadFileCommentMutation__
 *
 * To run a mutation, you first call `useUpdateThreadFileCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateThreadFileCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateThreadFileCommentMutation, { data, loading, error }] = useUpdateThreadFileCommentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateThreadFileCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateThreadFileCommentMutation,
    UpdateThreadFileCommentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateThreadFileCommentMutation,
    UpdateThreadFileCommentMutationVariables
  >(UpdateThreadFileCommentDocument, options);
}
export type UpdateThreadFileCommentMutationHookResult = ReturnType<
  typeof useUpdateThreadFileCommentMutation
>;
export type UpdateThreadFileCommentMutationResult =
  Apollo.MutationResult<UpdateThreadFileCommentMutation>;
export type UpdateThreadFileCommentMutationOptions = Apollo.BaseMutationOptions<
  UpdateThreadFileCommentMutation,
  UpdateThreadFileCommentMutationVariables
>;
export const UpdateThreadHeaderDocument = gql`
  mutation updateThreadHeader($data: UpdateThread!) {
    updateThread(data: $data) {
      id
      tags {
        id
        name
        color
        textColor
      }
      isSubscriber
      meta {
        id
        name
        column
        row
        status {
          color
          name
        }
        isMain
        isCurrent
      }
      scaleMapTask {
        id
        name
        column
        row
        status {
          id
          name
          color
        }
        children {
          id
          name
          status {
            id
            name
            color
          }
        }
      }
    }
  }
`;
export type UpdateThreadHeaderMutationFn = Apollo.MutationFunction<
  UpdateThreadHeaderMutation,
  UpdateThreadHeaderMutationVariables
>;

/**
 * __useUpdateThreadHeaderMutation__
 *
 * To run a mutation, you first call `useUpdateThreadHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateThreadHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateThreadHeaderMutation, { data, loading, error }] = useUpdateThreadHeaderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateThreadHeaderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateThreadHeaderMutation,
    UpdateThreadHeaderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateThreadHeaderMutation,
    UpdateThreadHeaderMutationVariables
  >(UpdateThreadHeaderDocument, options);
}
export type UpdateThreadHeaderMutationHookResult = ReturnType<
  typeof useUpdateThreadHeaderMutation
>;
export type UpdateThreadHeaderMutationResult =
  Apollo.MutationResult<UpdateThreadHeaderMutation>;
export type UpdateThreadHeaderMutationOptions = Apollo.BaseMutationOptions<
  UpdateThreadHeaderMutation,
  UpdateThreadHeaderMutationVariables
>;
export const UpdateThreadPerformersDocument = gql`
  mutation updateThreadPerformers($data: UpdateThread!) {
    updateThread(data: $data) {
      id
      performers {
        threadId
        userId
        status
        user {
          id
          login
          image
          role
          threads {
            rows {
              id
              performerStatus
            }
          }
        }
      }
      mainPerformer {
        id
        login
        image
        IsExistUnViewedForum
        role
        fullName
      }
      mainPerformerId
    }
  }
`;
export type UpdateThreadPerformersMutationFn = Apollo.MutationFunction<
  UpdateThreadPerformersMutation,
  UpdateThreadPerformersMutationVariables
>;

/**
 * __useUpdateThreadPerformersMutation__
 *
 * To run a mutation, you first call `useUpdateThreadPerformersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateThreadPerformersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateThreadPerformersMutation, { data, loading, error }] = useUpdateThreadPerformersMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateThreadPerformersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateThreadPerformersMutation,
    UpdateThreadPerformersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateThreadPerformersMutation,
    UpdateThreadPerformersMutationVariables
  >(UpdateThreadPerformersDocument, options);
}
export type UpdateThreadPerformersMutationHookResult = ReturnType<
  typeof useUpdateThreadPerformersMutation
>;
export type UpdateThreadPerformersMutationResult =
  Apollo.MutationResult<UpdateThreadPerformersMutation>;
export type UpdateThreadPerformersMutationOptions = Apollo.BaseMutationOptions<
  UpdateThreadPerformersMutation,
  UpdateThreadPerformersMutationVariables
>;
export const UpdateUserCharacteristicDocument = gql`
  mutation updateUserCharacteristic($data: UpdateUserCharacteristicInput!) {
    updateUserCharacteristic(data: $data) {
      id
      text
      color
    }
  }
`;
export type UpdateUserCharacteristicMutationFn = Apollo.MutationFunction<
  UpdateUserCharacteristicMutation,
  UpdateUserCharacteristicMutationVariables
>;

/**
 * __useUpdateUserCharacteristicMutation__
 *
 * To run a mutation, you first call `useUpdateUserCharacteristicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserCharacteristicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserCharacteristicMutation, { data, loading, error }] = useUpdateUserCharacteristicMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserCharacteristicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserCharacteristicMutation,
    UpdateUserCharacteristicMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserCharacteristicMutation,
    UpdateUserCharacteristicMutationVariables
  >(UpdateUserCharacteristicDocument, options);
}
export type UpdateUserCharacteristicMutationHookResult = ReturnType<
  typeof useUpdateUserCharacteristicMutation
>;
export type UpdateUserCharacteristicMutationResult =
  Apollo.MutationResult<UpdateUserCharacteristicMutation>;
export type UpdateUserCharacteristicMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserCharacteristicMutation,
  UpdateUserCharacteristicMutationVariables
>;
export const UpdateUserGroupDocument = gql`
  mutation updateUserGroup($updateUserGroupInput: UpdateUserGroupInput!) {
    updateUserGroup(updateUserGroupInput: $updateUserGroupInput) {
      id
    }
  }
`;
export type UpdateUserGroupMutationFn = Apollo.MutationFunction<
  UpdateUserGroupMutation,
  UpdateUserGroupMutationVariables
>;

/**
 * __useUpdateUserGroupMutation__
 *
 * To run a mutation, you first call `useUpdateUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserGroupMutation, { data, loading, error }] = useUpdateUserGroupMutation({
 *   variables: {
 *      updateUserGroupInput: // value for 'updateUserGroupInput'
 *   },
 * });
 */
export function useUpdateUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserGroupMutation,
    UpdateUserGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserGroupMutation, UpdateUserGroupMutationVariables>(
    UpdateUserGroupDocument,
    options,
  );
}
export type UpdateUserGroupMutationHookResult = ReturnType<
  typeof useUpdateUserGroupMutation
>;
export type UpdateUserGroupMutationResult =
  Apollo.MutationResult<UpdateUserGroupMutation>;
export type UpdateUserGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserGroupMutation,
  UpdateUserGroupMutationVariables
>;
export const UpdateWeeklyScheduledItemsDocument = gql`
  mutation updateWeeklyScheduledItems($data: UpdateWeeklyScheduledItemsDataInput!) {
    updateWeeklyScheduledItems(data: $data)
  }
`;
export type UpdateWeeklyScheduledItemsMutationFn = Apollo.MutationFunction<
  UpdateWeeklyScheduledItemsMutation,
  UpdateWeeklyScheduledItemsMutationVariables
>;

/**
 * __useUpdateWeeklyScheduledItemsMutation__
 *
 * To run a mutation, you first call `useUpdateWeeklyScheduledItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWeeklyScheduledItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWeeklyScheduledItemsMutation, { data, loading, error }] = useUpdateWeeklyScheduledItemsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateWeeklyScheduledItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWeeklyScheduledItemsMutation,
    UpdateWeeklyScheduledItemsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWeeklyScheduledItemsMutation,
    UpdateWeeklyScheduledItemsMutationVariables
  >(UpdateWeeklyScheduledItemsDocument, options);
}
export type UpdateWeeklyScheduledItemsMutationHookResult = ReturnType<
  typeof useUpdateWeeklyScheduledItemsMutation
>;
export type UpdateWeeklyScheduledItemsMutationResult =
  Apollo.MutationResult<UpdateWeeklyScheduledItemsMutation>;
export type UpdateWeeklyScheduledItemsMutationOptions = Apollo.BaseMutationOptions<
  UpdateWeeklyScheduledItemsMutation,
  UpdateWeeklyScheduledItemsMutationVariables
>;
export const UpsertPoligonDocument = gql`
  mutation upsertPoligon($data: UpsertPoligonInput!) {
    upsertPoligon(data: $data) {
      id
      name
      owner {
        id
      }
      project {
        id
      }
      colors {
        id
        createdAt
        updatedAt
        name
        color
      }
      rectangles {
        ...PoligonRectFragment
      }
      arrows {
        id
        startX
        startY
        endX
        endY
        fill
      }
    }
  }
  ${PoligonRectFragmentFragmentDoc}
`;
export type UpsertPoligonMutationFn = Apollo.MutationFunction<
  UpsertPoligonMutation,
  UpsertPoligonMutationVariables
>;

/**
 * __useUpsertPoligonMutation__
 *
 * To run a mutation, you first call `useUpsertPoligonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPoligonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPoligonMutation, { data, loading, error }] = useUpsertPoligonMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertPoligonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertPoligonMutation,
    UpsertPoligonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertPoligonMutation, UpsertPoligonMutationVariables>(
    UpsertPoligonDocument,
    options,
  );
}
export type UpsertPoligonMutationHookResult = ReturnType<typeof useUpsertPoligonMutation>;
export type UpsertPoligonMutationResult = Apollo.MutationResult<UpsertPoligonMutation>;
export type UpsertPoligonMutationOptions = Apollo.BaseMutationOptions<
  UpsertPoligonMutation,
  UpsertPoligonMutationVariables
>;
export const UpsertPoligonColorDocument = gql`
  mutation upsertPoligonColor($data: UpsertPoligonColorInput!) {
    upsertPoligonColor(data: $data)
  }
`;
export type UpsertPoligonColorMutationFn = Apollo.MutationFunction<
  UpsertPoligonColorMutation,
  UpsertPoligonColorMutationVariables
>;

/**
 * __useUpsertPoligonColorMutation__
 *
 * To run a mutation, you first call `useUpsertPoligonColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPoligonColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPoligonColorMutation, { data, loading, error }] = useUpsertPoligonColorMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertPoligonColorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertPoligonColorMutation,
    UpsertPoligonColorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertPoligonColorMutation,
    UpsertPoligonColorMutationVariables
  >(UpsertPoligonColorDocument, options);
}
export type UpsertPoligonColorMutationHookResult = ReturnType<
  typeof useUpsertPoligonColorMutation
>;
export type UpsertPoligonColorMutationResult =
  Apollo.MutationResult<UpsertPoligonColorMutation>;
export type UpsertPoligonColorMutationOptions = Apollo.BaseMutationOptions<
  UpsertPoligonColorMutation,
  UpsertPoligonColorMutationVariables
>;
export const UpsertPoligonObjectDocument = gql`
  mutation upsertPoligonObject($data: UpsertPoligonObjectInput!) {
    upsertPoligonObject(data: $data) {
      id
      name
      description
      poligon {
        id
      }
      parent {
        id
      }
      rectangles {
        ...PoligonRectFragment
      }
    }
  }
  ${PoligonRectFragmentFragmentDoc}
`;
export type UpsertPoligonObjectMutationFn = Apollo.MutationFunction<
  UpsertPoligonObjectMutation,
  UpsertPoligonObjectMutationVariables
>;

/**
 * __useUpsertPoligonObjectMutation__
 *
 * To run a mutation, you first call `useUpsertPoligonObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPoligonObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPoligonObjectMutation, { data, loading, error }] = useUpsertPoligonObjectMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertPoligonObjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertPoligonObjectMutation,
    UpsertPoligonObjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertPoligonObjectMutation,
    UpsertPoligonObjectMutationVariables
  >(UpsertPoligonObjectDocument, options);
}
export type UpsertPoligonObjectMutationHookResult = ReturnType<
  typeof useUpsertPoligonObjectMutation
>;
export type UpsertPoligonObjectMutationResult =
  Apollo.MutationResult<UpsertPoligonObjectMutation>;
export type UpsertPoligonObjectMutationOptions = Apollo.BaseMutationOptions<
  UpsertPoligonObjectMutation,
  UpsertPoligonObjectMutationVariables
>;
export const GetChronographDocument = gql`
  query GetChronograph($GetWeeklyScheduledItemsInput: GetWeeklyScheduledItemsInput!) {
    chronograph {
      weeklyScheduledItems(data: $GetWeeklyScheduledItemsInput) {
        scaleMapFile {
          id
          name
        }
        spentTime
        scheduledTime
        timeSpentPercentage
      }
      weeklyScheduled(data: $GetWeeklyScheduledItemsInput) {
        totalPlannedHours
        plannedTimeCompletionPercentage
      }
    }
  }
`;

/**
 * __useGetChronographQuery__
 *
 * To run a query within a React component, call `useGetChronographQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChronographQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChronographQuery({
 *   variables: {
 *      GetWeeklyScheduledItemsInput: // value for 'GetWeeklyScheduledItemsInput'
 *   },
 * });
 */
export function useGetChronographQuery(
  baseOptions: Apollo.QueryHookOptions<GetChronographQuery, GetChronographQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChronographQuery, GetChronographQueryVariables>(
    GetChronographDocument,
    options,
  );
}
export function useGetChronographLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChronographQuery,
    GetChronographQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChronographQuery, GetChronographQueryVariables>(
    GetChronographDocument,
    options,
  );
}
export type GetChronographQueryHookResult = ReturnType<typeof useGetChronographQuery>;
export type GetChronographLazyQueryHookResult = ReturnType<
  typeof useGetChronographLazyQuery
>;
export type GetChronographQueryResult = Apollo.QueryResult<
  GetChronographQuery,
  GetChronographQueryVariables
>;
export const DecideOnFlyActionsDocument = gql`
  query decideOnFlyActions {
    decideOnFlyActions {
      id
      name
      chancePercent
      decideOnFlyPeriodId
      authorId
      decideOnFlyPackage {
        color
      }
    }
  }
`;

/**
 * __useDecideOnFlyActionsQuery__
 *
 * To run a query within a React component, call `useDecideOnFlyActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecideOnFlyActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecideOnFlyActionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDecideOnFlyActionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DecideOnFlyActionsQuery,
    DecideOnFlyActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DecideOnFlyActionsQuery, DecideOnFlyActionsQueryVariables>(
    DecideOnFlyActionsDocument,
    options,
  );
}
export function useDecideOnFlyActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DecideOnFlyActionsQuery,
    DecideOnFlyActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DecideOnFlyActionsQuery, DecideOnFlyActionsQueryVariables>(
    DecideOnFlyActionsDocument,
    options,
  );
}
export type DecideOnFlyActionsQueryHookResult = ReturnType<
  typeof useDecideOnFlyActionsQuery
>;
export type DecideOnFlyActionsLazyQueryHookResult = ReturnType<
  typeof useDecideOnFlyActionsLazyQuery
>;
export type DecideOnFlyActionsQueryResult = Apollo.QueryResult<
  DecideOnFlyActionsQuery,
  DecideOnFlyActionsQueryVariables
>;
export const DecideOnFlyPackageDocument = gql`
  query decideOnFlyPackage($id: Int!) {
    decideOnFlyPackage(id: $id) {
      id
      title
      description
      isActive
      color
      decideOnFlyActions {
        id
        name
        chancePercent
        decideOnFlyPeriodId
        authorId
        hourFrom
        hourTo
      }
    }
  }
`;

/**
 * __useDecideOnFlyPackageQuery__
 *
 * To run a query within a React component, call `useDecideOnFlyPackageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecideOnFlyPackageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecideOnFlyPackageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDecideOnFlyPackageQuery(
  baseOptions: Apollo.QueryHookOptions<
    DecideOnFlyPackageQuery,
    DecideOnFlyPackageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DecideOnFlyPackageQuery, DecideOnFlyPackageQueryVariables>(
    DecideOnFlyPackageDocument,
    options,
  );
}
export function useDecideOnFlyPackageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DecideOnFlyPackageQuery,
    DecideOnFlyPackageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DecideOnFlyPackageQuery, DecideOnFlyPackageQueryVariables>(
    DecideOnFlyPackageDocument,
    options,
  );
}
export type DecideOnFlyPackageQueryHookResult = ReturnType<
  typeof useDecideOnFlyPackageQuery
>;
export type DecideOnFlyPackageLazyQueryHookResult = ReturnType<
  typeof useDecideOnFlyPackageLazyQuery
>;
export type DecideOnFlyPackageQueryResult = Apollo.QueryResult<
  DecideOnFlyPackageQuery,
  DecideOnFlyPackageQueryVariables
>;
export const DecideOnFlyPackagesDocument = gql`
  query decideOnFlyPackages($timezoneOffset: Int!) {
    decideOnFlyPackages {
      id
      title
      description
      isActive
      color
      countDecideOnFlyActions
      decideOnFlyActionsSumPercent(timezoneOffset: $timezoneOffset)
    }
  }
`;

/**
 * __useDecideOnFlyPackagesQuery__
 *
 * To run a query within a React component, call `useDecideOnFlyPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecideOnFlyPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecideOnFlyPackagesQuery({
 *   variables: {
 *      timezoneOffset: // value for 'timezoneOffset'
 *   },
 * });
 */
export function useDecideOnFlyPackagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    DecideOnFlyPackagesQuery,
    DecideOnFlyPackagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DecideOnFlyPackagesQuery, DecideOnFlyPackagesQueryVariables>(
    DecideOnFlyPackagesDocument,
    options,
  );
}
export function useDecideOnFlyPackagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DecideOnFlyPackagesQuery,
    DecideOnFlyPackagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DecideOnFlyPackagesQuery, DecideOnFlyPackagesQueryVariables>(
    DecideOnFlyPackagesDocument,
    options,
  );
}
export type DecideOnFlyPackagesQueryHookResult = ReturnType<
  typeof useDecideOnFlyPackagesQuery
>;
export type DecideOnFlyPackagesLazyQueryHookResult = ReturnType<
  typeof useDecideOnFlyPackagesLazyQuery
>;
export type DecideOnFlyPackagesQueryResult = Apollo.QueryResult<
  DecideOnFlyPackagesQuery,
  DecideOnFlyPackagesQueryVariables
>;
export const FindProjectsDocument = gql`
  query findProjects($data: GetProjectsInput!) {
    getProjects(data: $data) {
      rows {
        id
        name
        createdAt
      }
    }
  }
`;

/**
 * __useFindProjectsQuery__
 *
 * To run a query within a React component, call `useFindProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindProjectsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useFindProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<FindProjectsQuery, FindProjectsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindProjectsQuery, FindProjectsQueryVariables>(
    FindProjectsDocument,
    options,
  );
}
export function useFindProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindProjectsQuery,
    FindProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindProjectsQuery, FindProjectsQueryVariables>(
    FindProjectsDocument,
    options,
  );
}
export type FindProjectsQueryHookResult = ReturnType<typeof useFindProjectsQuery>;
export type FindProjectsLazyQueryHookResult = ReturnType<typeof useFindProjectsLazyQuery>;
export type FindProjectsQueryResult = Apollo.QueryResult<
  FindProjectsQuery,
  FindProjectsQueryVariables
>;
export const FindUsersDocument = gql`
  query findUsers($input: GetUsersInput!) {
    getAllUsers(input: $input) {
      rows {
        id
        login
        image
      }
    }
  }
`;

/**
 * __useFindUsersQuery__
 *
 * To run a query within a React component, call `useFindUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindUsersQuery(
  baseOptions: Apollo.QueryHookOptions<FindUsersQuery, FindUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindUsersQuery, FindUsersQueryVariables>(
    FindUsersDocument,
    options,
  );
}
export function useFindUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindUsersQuery, FindUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindUsersQuery, FindUsersQueryVariables>(
    FindUsersDocument,
    options,
  );
}
export type FindUsersQueryHookResult = ReturnType<typeof useFindUsersQuery>;
export type FindUsersLazyQueryHookResult = ReturnType<typeof useFindUsersLazyQuery>;
export type FindUsersQueryResult = Apollo.QueryResult<
  FindUsersQuery,
  FindUsersQueryVariables
>;
export const GetAllFilesDocument = gql`
  query GetAllFiles($data: GetFileInput!) {
    getAllFiles(data: $data) {
      rows {
        id
        fileName
        path
        size
      }
    }
  }
`;

/**
 * __useGetAllFilesQuery__
 *
 * To run a query within a React component, call `useGetAllFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFilesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetAllFilesQuery(
  baseOptions: Apollo.QueryHookOptions<GetAllFilesQuery, GetAllFilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllFilesQuery, GetAllFilesQueryVariables>(
    GetAllFilesDocument,
    options,
  );
}
export function useGetAllFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllFilesQuery, GetAllFilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllFilesQuery, GetAllFilesQueryVariables>(
    GetAllFilesDocument,
    options,
  );
}
export type GetAllFilesQueryHookResult = ReturnType<typeof useGetAllFilesQuery>;
export type GetAllFilesLazyQueryHookResult = ReturnType<typeof useGetAllFilesLazyQuery>;
export type GetAllFilesQueryResult = Apollo.QueryResult<
  GetAllFilesQuery,
  GetAllFilesQueryVariables
>;
export const GetAllUsersDocument = gql`
  query getAllUsers($input: GetUsersInput!) {
    getAllUsers(input: $input) {
      rows {
        id
        createdAt
        updatedAt
        login
        registrationLink
        editDate
        fullName
        theNote
        image
        role
        lastConnect
        isAnalytics
        karmaStatistics {
          karma
        }
        accessibleProjectsStorage {
          id
          name
        }
        creator {
          login
        }
        tags {
          rows {
            id
            name
            color
            textColor
          }
          count
        }
        chronographReports {
          id
          date
          text
          userId
        }
      }
      count
    }
  }
`;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllUsersQuery(
  baseOptions: Apollo.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(
    GetAllUsersDocument,
    options,
  );
}
export function useGetAllUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(
    GetAllUsersDocument,
    options,
  );
}
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<typeof useGetAllUsersLazyQuery>;
export type GetAllUsersQueryResult = Apollo.QueryResult<
  GetAllUsersQuery,
  GetAllUsersQueryVariables
>;
export const GetAllUsersTredsDocument = gql`
  query getAllUsersTreds($input: GetUsersInput!) {
    getAllUsers(input: $input) {
      rows {
        id
        login
        image
        role
        scaleMapTasksWithThreads {
          id
          name
          column
          row
          scaleMapFile {
            id
          }
          status {
            id
            name
            color
          }
          thread {
            id
          }
          children {
            id
            name
            column
            row
            status {
              id
              name
              color
            }
          }
        }
      }
      count
    }
  }
`;

/**
 * __useGetAllUsersTredsQuery__
 *
 * To run a query within a React component, call `useGetAllUsersTredsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersTredsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersTredsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllUsersTredsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllUsersTredsQuery,
    GetAllUsersTredsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllUsersTredsQuery, GetAllUsersTredsQueryVariables>(
    GetAllUsersTredsDocument,
    options,
  );
}
export function useGetAllUsersTredsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllUsersTredsQuery,
    GetAllUsersTredsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllUsersTredsQuery, GetAllUsersTredsQueryVariables>(
    GetAllUsersTredsDocument,
    options,
  );
}
export type GetAllUsersTredsQueryHookResult = ReturnType<typeof useGetAllUsersTredsQuery>;
export type GetAllUsersTredsLazyQueryHookResult = ReturnType<
  typeof useGetAllUsersTredsLazyQuery
>;
export type GetAllUsersTredsQueryResult = Apollo.QueryResult<
  GetAllUsersTredsQuery,
  GetAllUsersTredsQueryVariables
>;
export const GetAndCountSocialCircleObjectsDocument = gql`
  query getAndCountSocialCircleObjects {
    getAndCountSocialCircleObjects {
      rows {
        id
        name
        whoIs
        colorToDisplay
        usefulnessDescription
        strongPoints
        weakPoints
        meetingCount
        lastMeetingDate
        score
        authorId
        letter1
        letter2
        letter3
        letter4
      }
      count
    }
  }
`;

/**
 * __useGetAndCountSocialCircleObjectsQuery__
 *
 * To run a query within a React component, call `useGetAndCountSocialCircleObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAndCountSocialCircleObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAndCountSocialCircleObjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAndCountSocialCircleObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAndCountSocialCircleObjectsQuery,
    GetAndCountSocialCircleObjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAndCountSocialCircleObjectsQuery,
    GetAndCountSocialCircleObjectsQueryVariables
  >(GetAndCountSocialCircleObjectsDocument, options);
}
export function useGetAndCountSocialCircleObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAndCountSocialCircleObjectsQuery,
    GetAndCountSocialCircleObjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAndCountSocialCircleObjectsQuery,
    GetAndCountSocialCircleObjectsQueryVariables
  >(GetAndCountSocialCircleObjectsDocument, options);
}
export type GetAndCountSocialCircleObjectsQueryHookResult = ReturnType<
  typeof useGetAndCountSocialCircleObjectsQuery
>;
export type GetAndCountSocialCircleObjectsLazyQueryHookResult = ReturnType<
  typeof useGetAndCountSocialCircleObjectsLazyQuery
>;
export type GetAndCountSocialCircleObjectsQueryResult = Apollo.QueryResult<
  GetAndCountSocialCircleObjectsQuery,
  GetAndCountSocialCircleObjectsQueryVariables
>;
export const GetArchivedProjectsDocument = gql`
  query getArchivedProjects($data: GetProjectsInput!) {
    getArchivedProjects(data: $data) {
      rows {
        id
        name
        number
        tasks(limit: 100, sort: { type: ASC, field: number }) {
          rows {
            id
            name
            number
            description
            code
            startDate
            closeDate
            endDate
            project {
              id
              number
              name
            }
            status {
              id
            }
            author {
              id
              login
              image
              fullName
            }
            maintainer {
              id
              login
              image
            }
            tags {
              count
              rows {
                id
                name
                color
                textColor
                createdAt
                updatedAt
              }
            }
          }
          count
        }
      }
      count
    }
  }
`;

/**
 * __useGetArchivedProjectsQuery__
 *
 * To run a query within a React component, call `useGetArchivedProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArchivedProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArchivedProjectsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetArchivedProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetArchivedProjectsQuery,
    GetArchivedProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetArchivedProjectsQuery, GetArchivedProjectsQueryVariables>(
    GetArchivedProjectsDocument,
    options,
  );
}
export function useGetArchivedProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArchivedProjectsQuery,
    GetArchivedProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetArchivedProjectsQuery, GetArchivedProjectsQueryVariables>(
    GetArchivedProjectsDocument,
    options,
  );
}
export type GetArchivedProjectsQueryHookResult = ReturnType<
  typeof useGetArchivedProjectsQuery
>;
export type GetArchivedProjectsLazyQueryHookResult = ReturnType<
  typeof useGetArchivedProjectsLazyQuery
>;
export type GetArchivedProjectsQueryResult = Apollo.QueryResult<
  GetArchivedProjectsQuery,
  GetArchivedProjectsQueryVariables
>;
export const GetCalendarDocument = gql`
  query getCalendar(
    $dateRange: OnlyDateRangeInput!
    $limit: Int
    $offset: Int
    $taskSort: Sort
    $projectSort: Sort
    $subtasksData: GetSubtasksInput!
    $taskSubtaskStatusId: Int
  ) {
    getCalendar(
      dateRange: $dateRange
      limit: $limit
      offset: $offset
      taskSort: $taskSort
      projectSort: $projectSort
      taskSubtaskStatusId: $taskSubtaskStatusId
    ) {
      calendarProjects {
        count
        rows {
          id
          name
          number
          tasks {
            id
            number
            name
            code
            parentId
            startDate
            closeDate
            endDate
            maintainer {
              id
              tags {
                rows {
                  id
                  name
                  color
                  textColor
                }
              }
            }
            realizationPrice
            realizationPerMonthAmount
            assignees {
              id
              tags {
                rows {
                  id
                  name
                  color
                  textColor
                }
              }
            }
            status {
              id
              color
            }
            project {
              id
              number
            }
            subtasks(data: $subtasksData) {
              id
              number
              name
              code
              startDate
              closeDate
              endDate
              status {
                id
                color
              }
              task {
                id
                name
              }
              tags {
                count
                rows {
                  id
                  name
                  color
                  textColor
                  createdAt
                  updatedAt
                }
              }
            }
            tags {
              count
              rows {
                id
                name
                color
                textColor
                createdAt
                updatedAt
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCalendarQuery__
 *
 * To run a query within a React component, call `useGetCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarQuery({
 *   variables: {
 *      dateRange: // value for 'dateRange'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      taskSort: // value for 'taskSort'
 *      projectSort: // value for 'projectSort'
 *      subtasksData: // value for 'subtasksData'
 *      taskSubtaskStatusId: // value for 'taskSubtaskStatusId'
 *   },
 * });
 */
export function useGetCalendarQuery(
  baseOptions: Apollo.QueryHookOptions<GetCalendarQuery, GetCalendarQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCalendarQuery, GetCalendarQueryVariables>(
    GetCalendarDocument,
    options,
  );
}
export function useGetCalendarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCalendarQuery, GetCalendarQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCalendarQuery, GetCalendarQueryVariables>(
    GetCalendarDocument,
    options,
  );
}
export type GetCalendarQueryHookResult = ReturnType<typeof useGetCalendarQuery>;
export type GetCalendarLazyQueryHookResult = ReturnType<typeof useGetCalendarLazyQuery>;
export type GetCalendarQueryResult = Apollo.QueryResult<
  GetCalendarQuery,
  GetCalendarQueryVariables
>;
export const GetCalendarScaleMapTasksWithThreadsDocument = gql`
  query getCalendarScaleMapTasksWithThreads(
    $dateRange: OnlyDateRangeInput!
    $limit: Int
    $offset: Int
    $projectSort: Sort
    $scaleMapTaskStatusId: [Int!]
  ) {
    getCalendarScaleMapTasksWithThreads(
      dateRange: $dateRange
      limit: $limit
      offset: $offset
      projectSort: $projectSort
      scaleMapTaskStatusId: $scaleMapTaskStatusId
    ) {
      projects {
        count
        rows {
          ... on Project {
            id
            name
            calendarScaleMapTasks {
              id
              name
              parentCalendarTaskTextColor
              parentTags {
                id
                name
                color
                textColor
              }
              status {
                id
                name
                color
              }
              scaleMapFile {
                name
                calendarTaskTextColor
              }
              thread {
                id
                isCritical
                tags {
                  id
                  name
                  color
                  textColor
                }
                meta {
                  id
                  name
                }
                scaleMapTaskForCalendar {
                  id
                  name
                  isUrgently
                  status {
                    id
                    name
                    color
                  }
                }
                endDate
                startDate
                closeDate
                mainPerformer {
                  id
                  login
                  tags {
                    rows {
                      id
                      name
                      color
                      textColor
                    }
                  }
                }
              }
            }
          }
          ... on ThreadProject {
            name
            calendarScaleMapTasks {
              id
              name
              parentCalendarTaskTextColor
              parentTags {
                id
                name
                color
                textColor
              }
              status {
                id
                name
                color
              }
              scaleMapFile {
                name
                calendarTaskTextColor
              }
              thread {
                id
                isCritical
                tags {
                  id
                  name
                  color
                  textColor
                }
                meta {
                  id
                  name
                }
                scaleMapTaskForCalendar {
                  id
                  name
                  isUrgently
                  status {
                    id
                    name
                    color
                  }
                }
                endDate
                startDate
                closeDate
                mainPerformer {
                  id
                  login
                  tags {
                    rows {
                      id
                      name
                      color
                      textColor
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCalendarScaleMapTasksWithThreadsQuery__
 *
 * To run a query within a React component, call `useGetCalendarScaleMapTasksWithThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarScaleMapTasksWithThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarScaleMapTasksWithThreadsQuery({
 *   variables: {
 *      dateRange: // value for 'dateRange'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      projectSort: // value for 'projectSort'
 *      scaleMapTaskStatusId: // value for 'scaleMapTaskStatusId'
 *   },
 * });
 */
export function useGetCalendarScaleMapTasksWithThreadsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCalendarScaleMapTasksWithThreadsQuery,
    GetCalendarScaleMapTasksWithThreadsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCalendarScaleMapTasksWithThreadsQuery,
    GetCalendarScaleMapTasksWithThreadsQueryVariables
  >(GetCalendarScaleMapTasksWithThreadsDocument, options);
}
export function useGetCalendarScaleMapTasksWithThreadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCalendarScaleMapTasksWithThreadsQuery,
    GetCalendarScaleMapTasksWithThreadsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCalendarScaleMapTasksWithThreadsQuery,
    GetCalendarScaleMapTasksWithThreadsQueryVariables
  >(GetCalendarScaleMapTasksWithThreadsDocument, options);
}
export type GetCalendarScaleMapTasksWithThreadsQueryHookResult = ReturnType<
  typeof useGetCalendarScaleMapTasksWithThreadsQuery
>;
export type GetCalendarScaleMapTasksWithThreadsLazyQueryHookResult = ReturnType<
  typeof useGetCalendarScaleMapTasksWithThreadsLazyQuery
>;
export type GetCalendarScaleMapTasksWithThreadsQueryResult = Apollo.QueryResult<
  GetCalendarScaleMapTasksWithThreadsQuery,
  GetCalendarScaleMapTasksWithThreadsQueryVariables
>;
export const GetChatsDocument = gql`
  query getChats($data: GetChatsInput!) {
    getChats(data: $data) {
      rows {
        id
        task {
          id
          name
        }
        subtask {
          id
          name
          task {
            id
            name
          }
        }
        name
        unreadMessagesCount
      }
      count
    }
  }
`;

/**
 * __useGetChatsQuery__
 *
 * To run a query within a React component, call `useGetChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetChatsQuery(
  baseOptions: Apollo.QueryHookOptions<GetChatsQuery, GetChatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChatsQuery, GetChatsQueryVariables>(
    GetChatsDocument,
    options,
  );
}
export function useGetChatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChatsQuery, GetChatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChatsQuery, GetChatsQueryVariables>(
    GetChatsDocument,
    options,
  );
}
export type GetChatsQueryHookResult = ReturnType<typeof useGetChatsQuery>;
export type GetChatsLazyQueryHookResult = ReturnType<typeof useGetChatsLazyQuery>;
export type GetChatsQueryResult = Apollo.QueryResult<
  GetChatsQuery,
  GetChatsQueryVariables
>;
export const GetChronographDaysDocument = gql`
  query getChronographDays($data: GetChronographDays!) {
    getChronographDays(data: $data) {
      statistic {
        plan {
          scaleMapFileId
          scaleMapFile {
            id
            color
            name
            isArchived
            order
            isMainScaleMapTasks {
              id
              name
              isMain
            }
          }
          scheduledTime
          isDaily
          color
          trackedTimePercent
          trackedTime
          scheduledTotalTime
        }
        trackedPercentPerWeek
        trackedPercentPerMonth
        statisticPerWeek {
          scaleMapFileId
          timeTracked
          percent
          groupByScaleMapTask {
            percent
            timeTracked
            scaleMapFileId
            scaleMapTaskId
            scaleMapFile {
              id
              color
              name
              isMainScaleMapTasks {
                id
                name
                isMain
              }
            }
          }
          scaleMapFile {
            id
            color
            name
            isMainScaleMapTasks {
              id
              name
              isMain
            }
          }
        }
        statisticPerMonth {
          scaleMapFileId
          timeTracked
          percent
          groupByScaleMapTask {
            percent
            timeTracked
            scaleMapFileId
            scaleMapTaskId
            scaleMapFile {
              id
              color
              name
              isMainScaleMapTasks {
                id
                name
                isMain
              }
            }
          }
          scaleMapFile {
            id
            color
            name
            isMainScaleMapTasks {
              id
              name
              isMain
            }
          }
        }
        energyStatisticPerWeek {
          positiveEnergyPercent
          negativeEnergyPercent
          positiveEnergyTime
          negativeEnergyTime
        }
        energyStatisticPerMonth {
          positiveEnergyPercent
          negativeEnergyPercent
          positiveEnergyTime
          negativeEnergyTime
        }
      }
      days {
        date
        hours {
          hour
          energy
          scaleMapFileId
          scaleMapFile {
            id
            color
            name
          }
        }
        dayRecords {
          date
          cell1
          cell2
          cell3
        }
        statistic {
          scaleMapFilesTracked {
            scaleMapFileId
            timeTracked
            scaleMapFile {
              id
              color
              name
            }
            scaleMapTask {
              id
              name
            }
          }
          positiveEnergyPercent
          negativeEnergyPercent
        }
      }
      uncompletedWeek
      isBlocked
    }
  }
`;

/**
 * __useGetChronographDaysQuery__
 *
 * To run a query within a React component, call `useGetChronographDaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChronographDaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChronographDaysQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetChronographDaysQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetChronographDaysQuery,
    GetChronographDaysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChronographDaysQuery, GetChronographDaysQueryVariables>(
    GetChronographDaysDocument,
    options,
  );
}
export function useGetChronographDaysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChronographDaysQuery,
    GetChronographDaysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChronographDaysQuery, GetChronographDaysQueryVariables>(
    GetChronographDaysDocument,
    options,
  );
}
export type GetChronographDaysQueryHookResult = ReturnType<
  typeof useGetChronographDaysQuery
>;
export type GetChronographDaysLazyQueryHookResult = ReturnType<
  typeof useGetChronographDaysLazyQuery
>;
export type GetChronographDaysQueryResult = Apollo.QueryResult<
  GetChronographDaysQuery,
  GetChronographDaysQueryVariables
>;
export const GetChronographDaysRecordsDocument = gql`
  query getChronographDaysRecords($data: GetChronographDaysRecords!) {
    getChronographDaysRecords(data: $data) {
      date
      cell1
      cell2
      cell3
    }
  }
`;

/**
 * __useGetChronographDaysRecordsQuery__
 *
 * To run a query within a React component, call `useGetChronographDaysRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChronographDaysRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChronographDaysRecordsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetChronographDaysRecordsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetChronographDaysRecordsQuery,
    GetChronographDaysRecordsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetChronographDaysRecordsQuery,
    GetChronographDaysRecordsQueryVariables
  >(GetChronographDaysRecordsDocument, options);
}
export function useGetChronographDaysRecordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChronographDaysRecordsQuery,
    GetChronographDaysRecordsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetChronographDaysRecordsQuery,
    GetChronographDaysRecordsQueryVariables
  >(GetChronographDaysRecordsDocument, options);
}
export type GetChronographDaysRecordsQueryHookResult = ReturnType<
  typeof useGetChronographDaysRecordsQuery
>;
export type GetChronographDaysRecordsLazyQueryHookResult = ReturnType<
  typeof useGetChronographDaysRecordsLazyQuery
>;
export type GetChronographDaysRecordsQueryResult = Apollo.QueryResult<
  GetChronographDaysRecordsQuery,
  GetChronographDaysRecordsQueryVariables
>;
export const GetChronographItemsDocument = gql`
  query getChronographItems($data: GetChronographItemsInput!) {
    getChronographItems(data: $data) {
      date
      scaleMapFileId
      scaleMapFile {
        id
        name
      }
      scaleMapTaskId
      scaleMapTask {
        id
        name
      }
    }
  }
`;

/**
 * __useGetChronographItemsQuery__
 *
 * To run a query within a React component, call `useGetChronographItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChronographItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChronographItemsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetChronographItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetChronographItemsQuery,
    GetChronographItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChronographItemsQuery, GetChronographItemsQueryVariables>(
    GetChronographItemsDocument,
    options,
  );
}
export function useGetChronographItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChronographItemsQuery,
    GetChronographItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChronographItemsQuery, GetChronographItemsQueryVariables>(
    GetChronographItemsDocument,
    options,
  );
}
export type GetChronographItemsQueryHookResult = ReturnType<
  typeof useGetChronographItemsQuery
>;
export type GetChronographItemsLazyQueryHookResult = ReturnType<
  typeof useGetChronographItemsLazyQuery
>;
export type GetChronographItemsQueryResult = Apollo.QueryResult<
  GetChronographItemsQuery,
  GetChronographItemsQueryVariables
>;
export const GetChronographSampleDocument = gql`
  query getChronographSample {
    getChronographSample {
      id
      createdAt
      updatedAt
      name
      isPublic
      userId
      author {
        id
      }
      sampleItems {
        scaleMapFileId
        scaleMapFile {
          id
          color
          name
        }
        chronographSampleId
        scheduledTimePerDay
        color
      }
    }
  }
`;

/**
 * __useGetChronographSampleQuery__
 *
 * To run a query within a React component, call `useGetChronographSampleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChronographSampleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChronographSampleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetChronographSampleQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetChronographSampleQuery,
    GetChronographSampleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChronographSampleQuery, GetChronographSampleQueryVariables>(
    GetChronographSampleDocument,
    options,
  );
}
export function useGetChronographSampleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChronographSampleQuery,
    GetChronographSampleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetChronographSampleQuery,
    GetChronographSampleQueryVariables
  >(GetChronographSampleDocument, options);
}
export type GetChronographSampleQueryHookResult = ReturnType<
  typeof useGetChronographSampleQuery
>;
export type GetChronographSampleLazyQueryHookResult = ReturnType<
  typeof useGetChronographSampleLazyQuery
>;
export type GetChronographSampleQueryResult = Apollo.QueryResult<
  GetChronographSampleQuery,
  GetChronographSampleQueryVariables
>;
export const GetCurrentUserDocument = gql`
  query getCurrentUser {
    getCurrentUser {
      id
      login
      fullName
      theNote
      image
      role
      IsExistUnViewedForum
      isAccessToStorageProjects
      karmaStatistics {
        karma
      }
    }
  }
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  );
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  );
}
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserLazyQuery
>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>;
export const GetCurrentUserCustomDataDocument = gql`
  query getCurrentUserCustomData {
    getCurrentUser {
      id
      login
      role
      IsExistUnViewedForum
      assignedTasks(limit: 100) {
        id
        number
        name
        project {
          id
          name
        }
        status {
          id
        }
        createdAt
      }
      maintainedTasks(limit: 100) {
        id
        createdAt
        number
        name
        project {
          id
          name
        }
        status {
          id
        }
        createdAt
      }
    }
  }
`;

/**
 * __useGetCurrentUserCustomDataQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserCustomDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserCustomDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserCustomDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserCustomDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserCustomDataQuery,
    GetCurrentUserCustomDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrentUserCustomDataQuery,
    GetCurrentUserCustomDataQueryVariables
  >(GetCurrentUserCustomDataDocument, options);
}
export function useGetCurrentUserCustomDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserCustomDataQuery,
    GetCurrentUserCustomDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentUserCustomDataQuery,
    GetCurrentUserCustomDataQueryVariables
  >(GetCurrentUserCustomDataDocument, options);
}
export type GetCurrentUserCustomDataQueryHookResult = ReturnType<
  typeof useGetCurrentUserCustomDataQuery
>;
export type GetCurrentUserCustomDataLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserCustomDataLazyQuery
>;
export type GetCurrentUserCustomDataQueryResult = Apollo.QueryResult<
  GetCurrentUserCustomDataQuery,
  GetCurrentUserCustomDataQueryVariables
>;
export const GetCurrentUserDecideOnFlyDocument = gql`
  query getCurrentUserDecideOnFly {
    getCurrentUser {
      id
      currentUserDecideOnFlyActionData {
        isAccessToGet
        currentUserAction {
          id
          userId
          decideOnFlyActionId
          createdAt
          startDate
          endDate
          status
          reasonForNotDone
          decideOnFlyAction {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetCurrentUserDecideOnFlyQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserDecideOnFlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserDecideOnFlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserDecideOnFlyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserDecideOnFlyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserDecideOnFlyQuery,
    GetCurrentUserDecideOnFlyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrentUserDecideOnFlyQuery,
    GetCurrentUserDecideOnFlyQueryVariables
  >(GetCurrentUserDecideOnFlyDocument, options);
}
export function useGetCurrentUserDecideOnFlyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserDecideOnFlyQuery,
    GetCurrentUserDecideOnFlyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentUserDecideOnFlyQuery,
    GetCurrentUserDecideOnFlyQueryVariables
  >(GetCurrentUserDecideOnFlyDocument, options);
}
export type GetCurrentUserDecideOnFlyQueryHookResult = ReturnType<
  typeof useGetCurrentUserDecideOnFlyQuery
>;
export type GetCurrentUserDecideOnFlyLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserDecideOnFlyLazyQuery
>;
export type GetCurrentUserDecideOnFlyQueryResult = Apollo.QueryResult<
  GetCurrentUserDecideOnFlyQuery,
  GetCurrentUserDecideOnFlyQueryVariables
>;
export const GetCurrentUserHistoryDocument = gql`
  query getCurrentUserHistory($limit: Int, $tagsId: [ID!]) {
    getCurrentUser {
      id
      role
      IsExistUnViewedForum
      viewHistory(limit: $limit, tagsId: $tagsId) {
        id
        task {
          id
          number
          name
          code
          project {
            id
            name
            number
          }
          status {
            id
          }
          createdAt
          maintainer {
            id
            login
            image
          }
        }
        subtask {
          id
          number
          name
          code
          status {
            id
          }
          task {
            id
            project {
              id
              name
              number
            }
            number
          }
          createdAt
          maintainer {
            id
            login
            image
          }
        }
        viewedAt
      }
    }
  }
`;

/**
 * __useGetCurrentUserHistoryQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserHistoryQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      tagsId: // value for 'tagsId'
 *   },
 * });
 */
export function useGetCurrentUserHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserHistoryQuery,
    GetCurrentUserHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentUserHistoryQuery, GetCurrentUserHistoryQueryVariables>(
    GetCurrentUserHistoryDocument,
    options,
  );
}
export function useGetCurrentUserHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserHistoryQuery,
    GetCurrentUserHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentUserHistoryQuery,
    GetCurrentUserHistoryQueryVariables
  >(GetCurrentUserHistoryDocument, options);
}
export type GetCurrentUserHistoryQueryHookResult = ReturnType<
  typeof useGetCurrentUserHistoryQuery
>;
export type GetCurrentUserHistoryLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserHistoryLazyQuery
>;
export type GetCurrentUserHistoryQueryResult = Apollo.QueryResult<
  GetCurrentUserHistoryQuery,
  GetCurrentUserHistoryQueryVariables
>;
export const GetCurrentUserScaleDocument = gql`
  query getCurrentUserScale(
    $statusId: Int
    $isExcludeNullStatusTasks: Boolean
    $isArchived: Boolean
  ) {
    getCurrentUser {
      id
      login
      fullName
      IsExistUnViewedForum
      theNote
      image
      role
      scaleMapFiles(isArchived: $isArchived) {
        count
        rows {
          id
          createdAt
          updatedAt
          name
          color
          isDaily
          scheduledTime
          order
          project {
            id
          }
          scaleMapTasks(
            statusId: $statusId
            isExcludeNullStatusTasks: $isExcludeNullStatusTasks
          ) {
            id
            name
            order
            updatedAt
            statusUpdatedDate
            column
            row
            status {
              id
              name
              color
            }
            thread {
              id
            }
            counterComment
            counterFavorites
            scaleMapTaskMarkStatus {
              id
              title
              color
            }
            parentTree {
              id
              name
              column
              updatedAt
              statusUpdatedDate
              status {
                id
                name
                color
              }
            }
          }
          statistics {
            tasksCount
            completedTasksPercent
            inProgressTasksPercent
            problemTasksCount
            notInProgressTasksPercent
            problemTasksPercent
            isTasksCreatedToday
            isTasksUpdatedToday
          }
        }
      }
    }
  }
`;

/**
 * __useGetCurrentUserScaleQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserScaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserScaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserScaleQuery({
 *   variables: {
 *      statusId: // value for 'statusId'
 *      isExcludeNullStatusTasks: // value for 'isExcludeNullStatusTasks'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useGetCurrentUserScaleQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserScaleQuery,
    GetCurrentUserScaleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentUserScaleQuery, GetCurrentUserScaleQueryVariables>(
    GetCurrentUserScaleDocument,
    options,
  );
}
export function useGetCurrentUserScaleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserScaleQuery,
    GetCurrentUserScaleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentUserScaleQuery, GetCurrentUserScaleQueryVariables>(
    GetCurrentUserScaleDocument,
    options,
  );
}
export type GetCurrentUserScaleQueryHookResult = ReturnType<
  typeof useGetCurrentUserScaleQuery
>;
export type GetCurrentUserScaleLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserScaleLazyQuery
>;
export type GetCurrentUserScaleQueryResult = Apollo.QueryResult<
  GetCurrentUserScaleQuery,
  GetCurrentUserScaleQueryVariables
>;
export const GetCurrentUserTagsDocument = gql`
  query getCurrentUserTags {
    getCurrentUser {
      id
      tags {
        rows {
          id
          name
          color
          textColor
        }
      }
    }
  }
`;

/**
 * __useGetCurrentUserTagsQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserTagsQuery,
    GetCurrentUserTagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentUserTagsQuery, GetCurrentUserTagsQueryVariables>(
    GetCurrentUserTagsDocument,
    options,
  );
}
export function useGetCurrentUserTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserTagsQuery,
    GetCurrentUserTagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentUserTagsQuery, GetCurrentUserTagsQueryVariables>(
    GetCurrentUserTagsDocument,
    options,
  );
}
export type GetCurrentUserTagsQueryHookResult = ReturnType<
  typeof useGetCurrentUserTagsQuery
>;
export type GetCurrentUserTagsLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserTagsLazyQuery
>;
export type GetCurrentUserTagsQueryResult = Apollo.QueryResult<
  GetCurrentUserTagsQuery,
  GetCurrentUserTagsQueryVariables
>;
export const GetDecideOnFlyPackagesForUserDocument = gql`
  query getDecideOnFlyPackagesForUser {
    getDecideOnFlyPackagesForUser {
      id
      title
      color
      isActiveInCurrentUser
      description
    }
  }
`;

/**
 * __useGetDecideOnFlyPackagesForUserQuery__
 *
 * To run a query within a React component, call `useGetDecideOnFlyPackagesForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDecideOnFlyPackagesForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDecideOnFlyPackagesForUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDecideOnFlyPackagesForUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDecideOnFlyPackagesForUserQuery,
    GetDecideOnFlyPackagesForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDecideOnFlyPackagesForUserQuery,
    GetDecideOnFlyPackagesForUserQueryVariables
  >(GetDecideOnFlyPackagesForUserDocument, options);
}
export function useGetDecideOnFlyPackagesForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDecideOnFlyPackagesForUserQuery,
    GetDecideOnFlyPackagesForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDecideOnFlyPackagesForUserQuery,
    GetDecideOnFlyPackagesForUserQueryVariables
  >(GetDecideOnFlyPackagesForUserDocument, options);
}
export type GetDecideOnFlyPackagesForUserQueryHookResult = ReturnType<
  typeof useGetDecideOnFlyPackagesForUserQuery
>;
export type GetDecideOnFlyPackagesForUserLazyQueryHookResult = ReturnType<
  typeof useGetDecideOnFlyPackagesForUserLazyQuery
>;
export type GetDecideOnFlyPackagesForUserQueryResult = Apollo.QueryResult<
  GetDecideOnFlyPackagesForUserQuery,
  GetDecideOnFlyPackagesForUserQueryVariables
>;
export const GetDecideOnFlyPeriodsDocument = gql`
  query getDecideOnFlyPeriods {
    getDecideOnFlyPeriods {
      id
      periodName
      isWeekend
      decideOnFlyActions {
        id
        name
        chancePercent
        decideOnFlyPeriodId
        authorId
        hourFrom
        hourTo
        decideOnFlyPackage {
          color
        }
      }
    }
  }
`;

/**
 * __useGetDecideOnFlyPeriodsQuery__
 *
 * To run a query within a React component, call `useGetDecideOnFlyPeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDecideOnFlyPeriodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDecideOnFlyPeriodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDecideOnFlyPeriodsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDecideOnFlyPeriodsQuery,
    GetDecideOnFlyPeriodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDecideOnFlyPeriodsQuery, GetDecideOnFlyPeriodsQueryVariables>(
    GetDecideOnFlyPeriodsDocument,
    options,
  );
}
export function useGetDecideOnFlyPeriodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDecideOnFlyPeriodsQuery,
    GetDecideOnFlyPeriodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDecideOnFlyPeriodsQuery,
    GetDecideOnFlyPeriodsQueryVariables
  >(GetDecideOnFlyPeriodsDocument, options);
}
export type GetDecideOnFlyPeriodsQueryHookResult = ReturnType<
  typeof useGetDecideOnFlyPeriodsQuery
>;
export type GetDecideOnFlyPeriodsLazyQueryHookResult = ReturnType<
  typeof useGetDecideOnFlyPeriodsLazyQuery
>;
export type GetDecideOnFlyPeriodsQueryResult = Apollo.QueryResult<
  GetDecideOnFlyPeriodsQuery,
  GetDecideOnFlyPeriodsQueryVariables
>;
export const GetExpensesDocument = gql`
  query getExpenses($expensesDate: String, $data: GetTasksInput!) {
    getExpenses(expensesDate: $expensesDate) {
      projects(limit: 100, sort: { field: number, type: ASC }) {
        id
        name
        number
        tasks(data: $data) {
          count
          rows {
            id
            number
            name
            code
            parentId
            startDate
            closeDate
            endDate
            realizationPrice
            realizationPerMonthAmount
            status {
              id
              color
            }
            project {
              id
              number
            }
            tags {
              count
              rows {
                id
                name
                color
                textColor
                createdAt
                updatedAt
              }
            }
          }
        }
      }
      amountPerMonth
      amountPerYear
    }
  }
`;

/**
 * __useGetExpensesQuery__
 *
 * To run a query within a React component, call `useGetExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpensesQuery({
 *   variables: {
 *      expensesDate: // value for 'expensesDate'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetExpensesQuery(
  baseOptions: Apollo.QueryHookOptions<GetExpensesQuery, GetExpensesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExpensesQuery, GetExpensesQueryVariables>(
    GetExpensesDocument,
    options,
  );
}
export function useGetExpensesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExpensesQuery, GetExpensesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExpensesQuery, GetExpensesQueryVariables>(
    GetExpensesDocument,
    options,
  );
}
export type GetExpensesQueryHookResult = ReturnType<typeof useGetExpensesQuery>;
export type GetExpensesLazyQueryHookResult = ReturnType<typeof useGetExpensesLazyQuery>;
export type GetExpensesQueryResult = Apollo.QueryResult<
  GetExpensesQuery,
  GetExpensesQueryVariables
>;
export const GetFileByIdDocument = gql`
  query getFileById($id: Float!) {
    getFileById(id: $id) {
      id
      fileName
    }
  }
`;

/**
 * __useGetFileByIdQuery__
 *
 * To run a query within a React component, call `useGetFileByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFileByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetFileByIdQuery, GetFileByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFileByIdQuery, GetFileByIdQueryVariables>(
    GetFileByIdDocument,
    options,
  );
}
export function useGetFileByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFileByIdQuery, GetFileByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFileByIdQuery, GetFileByIdQueryVariables>(
    GetFileByIdDocument,
    options,
  );
}
export type GetFileByIdQueryHookResult = ReturnType<typeof useGetFileByIdQuery>;
export type GetFileByIdLazyQueryHookResult = ReturnType<typeof useGetFileByIdLazyQuery>;
export type GetFileByIdQueryResult = Apollo.QueryResult<
  GetFileByIdQuery,
  GetFileByIdQueryVariables
>;
export const GetForumMessageDocument = gql`
  query getForumMessage($id: ID!) {
    getForumMessage(id: $id) {
      id
      createdAt
      updatedAt
      text
      forumThreadId
      userId
    }
  }
`;

/**
 * __useGetForumMessageQuery__
 *
 * To run a query within a React component, call `useGetForumMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetForumMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetForumMessageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetForumMessageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetForumMessageQuery,
    GetForumMessageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetForumMessageQuery, GetForumMessageQueryVariables>(
    GetForumMessageDocument,
    options,
  );
}
export function useGetForumMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetForumMessageQuery,
    GetForumMessageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetForumMessageQuery, GetForumMessageQueryVariables>(
    GetForumMessageDocument,
    options,
  );
}
export type GetForumMessageQueryHookResult = ReturnType<typeof useGetForumMessageQuery>;
export type GetForumMessageLazyQueryHookResult = ReturnType<
  typeof useGetForumMessageLazyQuery
>;
export type GetForumMessageQueryResult = Apollo.QueryResult<
  GetForumMessageQuery,
  GetForumMessageQueryVariables
>;
export const GetForumMessagesDocument = gql`
  query getForumMessages($data: GetForumMessagesInput) {
    getForumMessages(data: $data) {
      count
      rows {
        id
        createdAt
        updatedAt
        text
        forumThreadId
        viewType
        imagePath
        user {
          id
          onlineStatus
          forumMessageCount
          forumThreadAuthorCount
          login
          image
          lastConnect
          karmaStatistics {
            karma
          }
        }
        quote {
          id
          text
          createdAt
          user {
            login
            image
          }
        }
        reply {
          id
          text
          createdAt
          imagePath
          viewType
          quoteId
          user {
            login
            image
            forumMessageCount
            karmaStatistics {
              karma
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetForumMessagesQuery__
 *
 * To run a query within a React component, call `useGetForumMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetForumMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetForumMessagesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetForumMessagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetForumMessagesQuery,
    GetForumMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetForumMessagesQuery, GetForumMessagesQueryVariables>(
    GetForumMessagesDocument,
    options,
  );
}
export function useGetForumMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetForumMessagesQuery,
    GetForumMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetForumMessagesQuery, GetForumMessagesQueryVariables>(
    GetForumMessagesDocument,
    options,
  );
}
export type GetForumMessagesQueryHookResult = ReturnType<typeof useGetForumMessagesQuery>;
export type GetForumMessagesLazyQueryHookResult = ReturnType<
  typeof useGetForumMessagesLazyQuery
>;
export type GetForumMessagesQueryResult = Apollo.QueryResult<
  GetForumMessagesQuery,
  GetForumMessagesQueryVariables
>;
export const GetForumSectionsDocument = gql`
  query getForumSections($data: GetForumSections) {
    getForumSections(data: $data) {
      count
      rows {
        id
        createdAt
        updatedAt
        name
        role
        isExistUnViewedForumThreads
        threadCount
      }
    }
  }
`;

/**
 * __useGetForumSectionsQuery__
 *
 * To run a query within a React component, call `useGetForumSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetForumSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetForumSectionsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetForumSectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetForumSectionsQuery,
    GetForumSectionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetForumSectionsQuery, GetForumSectionsQueryVariables>(
    GetForumSectionsDocument,
    options,
  );
}
export function useGetForumSectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetForumSectionsQuery,
    GetForumSectionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetForumSectionsQuery, GetForumSectionsQueryVariables>(
    GetForumSectionsDocument,
    options,
  );
}
export type GetForumSectionsQueryHookResult = ReturnType<typeof useGetForumSectionsQuery>;
export type GetForumSectionsLazyQueryHookResult = ReturnType<
  typeof useGetForumSectionsLazyQuery
>;
export type GetForumSectionsQueryResult = Apollo.QueryResult<
  GetForumSectionsQuery,
  GetForumSectionsQueryVariables
>;
export const GetForumStatisticsDocument = gql`
  query getForumStatistics {
    getForumStatistics {
      threadsCount
      messagesCount
    }
  }
`;

/**
 * __useGetForumStatisticsQuery__
 *
 * To run a query within a React component, call `useGetForumStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetForumStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetForumStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetForumStatisticsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetForumStatisticsQuery,
    GetForumStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetForumStatisticsQuery, GetForumStatisticsQueryVariables>(
    GetForumStatisticsDocument,
    options,
  );
}
export function useGetForumStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetForumStatisticsQuery,
    GetForumStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetForumStatisticsQuery, GetForumStatisticsQueryVariables>(
    GetForumStatisticsDocument,
    options,
  );
}
export type GetForumStatisticsQueryHookResult = ReturnType<
  typeof useGetForumStatisticsQuery
>;
export type GetForumStatisticsLazyQueryHookResult = ReturnType<
  typeof useGetForumStatisticsLazyQuery
>;
export type GetForumStatisticsQueryResult = Apollo.QueryResult<
  GetForumStatisticsQuery,
  GetForumStatisticsQueryVariables
>;
export const GetForumThreadDocument = gql`
  query getForumThread($id: ID!) {
    getForumThread(id: $id) {
      id
      createdAt
      updatedAt
      name
      description
      imagePath
      authorAvatarPath
      forumSectionId
      isClosed
      author {
        id
        onlineStatus
        forumMessageCount
        forumThreadAuthorCount
        login
        image
        lastConnect
        karmaStatistics {
          karma
        }
      }
      viewType
      forumMessages {
        rows {
          id
          createdAt
          updatedAt
          text
          forumThreadId
        }
      }
    }
  }
`;

/**
 * __useGetForumThreadQuery__
 *
 * To run a query within a React component, call `useGetForumThreadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetForumThreadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetForumThreadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetForumThreadQuery(
  baseOptions: Apollo.QueryHookOptions<GetForumThreadQuery, GetForumThreadQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetForumThreadQuery, GetForumThreadQueryVariables>(
    GetForumThreadDocument,
    options,
  );
}
export function useGetForumThreadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetForumThreadQuery,
    GetForumThreadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetForumThreadQuery, GetForumThreadQueryVariables>(
    GetForumThreadDocument,
    options,
  );
}
export type GetForumThreadQueryHookResult = ReturnType<typeof useGetForumThreadQuery>;
export type GetForumThreadLazyQueryHookResult = ReturnType<
  typeof useGetForumThreadLazyQuery
>;
export type GetForumThreadQueryResult = Apollo.QueryResult<
  GetForumThreadQuery,
  GetForumThreadQueryVariables
>;
export const GetForumThreadsDocument = gql`
  query getForumThreads($data: GetForumThreadsInput!) {
    getForumThreads(data: $data) {
      count
      rows {
        id
        createdAt
        updatedAt
        name
        description
        imagePath
        authorAvatarPath
        forumSectionId
        authorId
        viewType
        isAttached
        isClosed
        isViewed
        author {
          id
          onlineStatus
          forumMessageCount
          forumThreadAuthorCount
          login
          image
          lastConnect
          karmaStatistics {
            karma
          }
        }
        forumMessages(data: { limit: 5, offset: 0 }) {
          rows {
            id
            user {
              id
              onlineStatus
              forumMessageCount
              forumThreadAuthorCount
              login
              image
              lastConnect
              karmaStatistics {
                karma
              }
            }
          }
          count
        }
      }
    }
  }
`;

/**
 * __useGetForumThreadsQuery__
 *
 * To run a query within a React component, call `useGetForumThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetForumThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetForumThreadsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetForumThreadsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetForumThreadsQuery,
    GetForumThreadsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetForumThreadsQuery, GetForumThreadsQueryVariables>(
    GetForumThreadsDocument,
    options,
  );
}
export function useGetForumThreadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetForumThreadsQuery,
    GetForumThreadsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetForumThreadsQuery, GetForumThreadsQueryVariables>(
    GetForumThreadsDocument,
    options,
  );
}
export type GetForumThreadsQueryHookResult = ReturnType<typeof useGetForumThreadsQuery>;
export type GetForumThreadsLazyQueryHookResult = ReturnType<
  typeof useGetForumThreadsLazyQuery
>;
export type GetForumThreadsQueryResult = Apollo.QueryResult<
  GetForumThreadsQuery,
  GetForumThreadsQueryVariables
>;
export const GetModeratorProjectsIdsDocument = gql`
  query getModeratorProjectsIds($data: GetProjectsInput!) {
    getProjects(data: $data) {
      rows {
        id
      }
    }
  }
`;

/**
 * __useGetModeratorProjectsIdsQuery__
 *
 * To run a query within a React component, call `useGetModeratorProjectsIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModeratorProjectsIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModeratorProjectsIdsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetModeratorProjectsIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetModeratorProjectsIdsQuery,
    GetModeratorProjectsIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetModeratorProjectsIdsQuery,
    GetModeratorProjectsIdsQueryVariables
  >(GetModeratorProjectsIdsDocument, options);
}
export function useGetModeratorProjectsIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetModeratorProjectsIdsQuery,
    GetModeratorProjectsIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetModeratorProjectsIdsQuery,
    GetModeratorProjectsIdsQueryVariables
  >(GetModeratorProjectsIdsDocument, options);
}
export type GetModeratorProjectsIdsQueryHookResult = ReturnType<
  typeof useGetModeratorProjectsIdsQuery
>;
export type GetModeratorProjectsIdsLazyQueryHookResult = ReturnType<
  typeof useGetModeratorProjectsIdsLazyQuery
>;
export type GetModeratorProjectsIdsQueryResult = Apollo.QueryResult<
  GetModeratorProjectsIdsQuery,
  GetModeratorProjectsIdsQueryVariables
>;
export const GetMyProjectsRoleMaintainerDocument = gql`
  query getMyProjectsRoleMaintainer($data: GetMyProjectsInput!, $tagsId: [ID!]) {
    getMyProjectsRoleMaintainer(data: $data) {
      rows {
        id
        name
        number
        myTaskSubTaskList(limit: 100, tagsId: $tagsId) {
          rows {
            id
            name
            code
            status {
              id
            }
            parentId
            maintainerComment
            tags {
              count
              rows {
                id
                name
                color
                textColor
                createdAt
                updatedAt
              }
            }
            realizationPrice
            realizationPerMonthAmount
          }
          count
        }
      }
      count
    }
  }
`;

/**
 * __useGetMyProjectsRoleMaintainerQuery__
 *
 * To run a query within a React component, call `useGetMyProjectsRoleMaintainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyProjectsRoleMaintainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyProjectsRoleMaintainerQuery({
 *   variables: {
 *      data: // value for 'data'
 *      tagsId: // value for 'tagsId'
 *   },
 * });
 */
export function useGetMyProjectsRoleMaintainerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyProjectsRoleMaintainerQuery,
    GetMyProjectsRoleMaintainerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyProjectsRoleMaintainerQuery,
    GetMyProjectsRoleMaintainerQueryVariables
  >(GetMyProjectsRoleMaintainerDocument, options);
}
export function useGetMyProjectsRoleMaintainerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyProjectsRoleMaintainerQuery,
    GetMyProjectsRoleMaintainerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyProjectsRoleMaintainerQuery,
    GetMyProjectsRoleMaintainerQueryVariables
  >(GetMyProjectsRoleMaintainerDocument, options);
}
export type GetMyProjectsRoleMaintainerQueryHookResult = ReturnType<
  typeof useGetMyProjectsRoleMaintainerQuery
>;
export type GetMyProjectsRoleMaintainerLazyQueryHookResult = ReturnType<
  typeof useGetMyProjectsRoleMaintainerLazyQuery
>;
export type GetMyProjectsRoleMaintainerQueryResult = Apollo.QueryResult<
  GetMyProjectsRoleMaintainerQuery,
  GetMyProjectsRoleMaintainerQueryVariables
>;
export const GetMyTasksSubtasksDocument = gql`
  query getMyTasksSubtasks {
    getCurrentUser {
      id
      myTasksSubtasks(limit: 100) {
        id
        parentId
        name
        code
        status {
          id
        }
        project {
          id
          name
          number
        }
        createdAt
        updatedAt
        maintainer {
          id
          login
          image
        }
        tags {
          count
          rows {
            id
            name
            color
            textColor
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

/**
 * __useGetMyTasksSubtasksQuery__
 *
 * To run a query within a React component, call `useGetMyTasksSubtasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyTasksSubtasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyTasksSubtasksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyTasksSubtasksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyTasksSubtasksQuery,
    GetMyTasksSubtasksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyTasksSubtasksQuery, GetMyTasksSubtasksQueryVariables>(
    GetMyTasksSubtasksDocument,
    options,
  );
}
export function useGetMyTasksSubtasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyTasksSubtasksQuery,
    GetMyTasksSubtasksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyTasksSubtasksQuery, GetMyTasksSubtasksQueryVariables>(
    GetMyTasksSubtasksDocument,
    options,
  );
}
export type GetMyTasksSubtasksQueryHookResult = ReturnType<
  typeof useGetMyTasksSubtasksQuery
>;
export type GetMyTasksSubtasksLazyQueryHookResult = ReturnType<
  typeof useGetMyTasksSubtasksLazyQuery
>;
export type GetMyTasksSubtasksQueryResult = Apollo.QueryResult<
  GetMyTasksSubtasksQuery,
  GetMyTasksSubtasksQueryVariables
>;
export const GetPoligonDocument = gql`
  query getPoligon($id: Int!) {
    getPoligon(id: $id) {
      id
      name
      owner {
        id
        login
        role
      }
      project {
        id
        name
      }
      colors {
        id
        createdAt
        updatedAt
        name
        color
      }
      rectangles {
        ...PoligonRectFragment
      }
      arrows {
        id
        startX
        startY
        endX
        endY
        fill
      }
      objects {
        id
        name
        description
        parent {
          id
        }
        tasksSubtasks {
          count
        }
      }
    }
  }
  ${PoligonRectFragmentFragmentDoc}
`;

/**
 * __useGetPoligonQuery__
 *
 * To run a query within a React component, call `useGetPoligonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoligonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoligonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPoligonQuery(
  baseOptions: Apollo.QueryHookOptions<GetPoligonQuery, GetPoligonQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPoligonQuery, GetPoligonQueryVariables>(
    GetPoligonDocument,
    options,
  );
}
export function useGetPoligonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPoligonQuery, GetPoligonQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPoligonQuery, GetPoligonQueryVariables>(
    GetPoligonDocument,
    options,
  );
}
export type GetPoligonQueryHookResult = ReturnType<typeof useGetPoligonQuery>;
export type GetPoligonLazyQueryHookResult = ReturnType<typeof useGetPoligonLazyQuery>;
export type GetPoligonQueryResult = Apollo.QueryResult<
  GetPoligonQuery,
  GetPoligonQueryVariables
>;
export const GetPoligonObjectDocument = gql`
  query getPoligonObject($id: Int!, $subtasksData: GetSubtasksInput!) {
    getPoligonObject(id: $id) {
      id
      name
      description
      poligon {
        id
      }
      parent {
        id
        name
      }
      task {
        id
        code
        number
        endDate
        project {
          id
          name
          number
        }
        poligonObject {
          id
          name
        }
        maintainer {
          id
          login
          image
          fullName
        }
        status {
          id
          color
        }
        name
        tags {
          count
          rows {
            id
            createdAt
            updatedAt
            name
            color
            textColor
          }
        }
      }
      subtask {
        id
        code
        number
        endDate
        task {
          id
          name
          project {
            id
            name
            number
          }
        }
        poligonObject {
          id
          name
        }
        maintainer {
          id
          login
          image
          fullName
        }
        status {
          id
          color
        }
        name
        tags {
          count
          rows {
            id
            createdAt
            updatedAt
            name
            color
            textColor
          }
        }
      }
      rectangles {
        id
        x
        y
        width
        height
        fill
        text
        textObj
        name
        draggable
        nameArray
        parentLink
        alignName
        taskLink {
          taskId
        }
        task {
          id
        }
        frontendData
        file {
          id
          path
        }
      }
      tasksSubtasks {
        count
        rows {
          id
          name
          number
          parentId
          code
          project {
            id
            number
            name
          }
          maintainer {
            id
            login
            image
          }
          status {
            id
          }
          endDate
          tags {
            count
            rows {
              id
              createdAt
              updatedAt
              name
              color
              textColor
            }
          }
          poligonObject {
            id
          }
          subtasks(data: $subtasksData) {
            id
            name
            number
            code
          }
        }
      }
    }
  }
`;

/**
 * __useGetPoligonObjectQuery__
 *
 * To run a query within a React component, call `useGetPoligonObjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoligonObjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoligonObjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *      subtasksData: // value for 'subtasksData'
 *   },
 * });
 */
export function useGetPoligonObjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPoligonObjectQuery,
    GetPoligonObjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPoligonObjectQuery, GetPoligonObjectQueryVariables>(
    GetPoligonObjectDocument,
    options,
  );
}
export function useGetPoligonObjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPoligonObjectQuery,
    GetPoligonObjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPoligonObjectQuery, GetPoligonObjectQueryVariables>(
    GetPoligonObjectDocument,
    options,
  );
}
export type GetPoligonObjectQueryHookResult = ReturnType<typeof useGetPoligonObjectQuery>;
export type GetPoligonObjectLazyQueryHookResult = ReturnType<
  typeof useGetPoligonObjectLazyQuery
>;
export type GetPoligonObjectQueryResult = Apollo.QueryResult<
  GetPoligonObjectQuery,
  GetPoligonObjectQueryVariables
>;
export const GetPoligonObjectsDocument = gql`
  query getPoligonObjects($data: GetPoligonObjectsInput) {
    getPoligonObjects(data: $data) {
      rows {
        id
        name
        description
        createdAt
        poligon {
          id
          name
          project {
            id
          }
        }
      }
      count
    }
  }
`;

/**
 * __useGetPoligonObjectsQuery__
 *
 * To run a query within a React component, call `useGetPoligonObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoligonObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoligonObjectsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetPoligonObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPoligonObjectsQuery,
    GetPoligonObjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPoligonObjectsQuery, GetPoligonObjectsQueryVariables>(
    GetPoligonObjectsDocument,
    options,
  );
}
export function useGetPoligonObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPoligonObjectsQuery,
    GetPoligonObjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPoligonObjectsQuery, GetPoligonObjectsQueryVariables>(
    GetPoligonObjectsDocument,
    options,
  );
}
export type GetPoligonObjectsQueryHookResult = ReturnType<
  typeof useGetPoligonObjectsQuery
>;
export type GetPoligonObjectsLazyQueryHookResult = ReturnType<
  typeof useGetPoligonObjectsLazyQuery
>;
export type GetPoligonObjectsQueryResult = Apollo.QueryResult<
  GetPoligonObjectsQuery,
  GetPoligonObjectsQueryVariables
>;
export const GetPoligonTemplateDocument = gql`
  query getPoligonTemplate($id: Int!) {
    getPoligonTemplate(id: $id) {
      id
      createdAt
      updatedAt
      name
      canvasObject
    }
  }
`;

/**
 * __useGetPoligonTemplateQuery__
 *
 * To run a query within a React component, call `useGetPoligonTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoligonTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoligonTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPoligonTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPoligonTemplateQuery,
    GetPoligonTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPoligonTemplateQuery, GetPoligonTemplateQueryVariables>(
    GetPoligonTemplateDocument,
    options,
  );
}
export function useGetPoligonTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPoligonTemplateQuery,
    GetPoligonTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPoligonTemplateQuery, GetPoligonTemplateQueryVariables>(
    GetPoligonTemplateDocument,
    options,
  );
}
export type GetPoligonTemplateQueryHookResult = ReturnType<
  typeof useGetPoligonTemplateQuery
>;
export type GetPoligonTemplateLazyQueryHookResult = ReturnType<
  typeof useGetPoligonTemplateLazyQuery
>;
export type GetPoligonTemplateQueryResult = Apollo.QueryResult<
  GetPoligonTemplateQuery,
  GetPoligonTemplateQueryVariables
>;
export const GetPoligonTemplatesDocument = gql`
  query getPoligonTemplates($data: GetPoligonTemplateInput!) {
    getPoligonTemplates(data: $data) {
      rows {
        id
        createdAt
        updatedAt
        name
        canvasObject
      }
      count
    }
  }
`;

/**
 * __useGetPoligonTemplatesQuery__
 *
 * To run a query within a React component, call `useGetPoligonTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoligonTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoligonTemplatesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetPoligonTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPoligonTemplatesQuery,
    GetPoligonTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPoligonTemplatesQuery, GetPoligonTemplatesQueryVariables>(
    GetPoligonTemplatesDocument,
    options,
  );
}
export function useGetPoligonTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPoligonTemplatesQuery,
    GetPoligonTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPoligonTemplatesQuery, GetPoligonTemplatesQueryVariables>(
    GetPoligonTemplatesDocument,
    options,
  );
}
export type GetPoligonTemplatesQueryHookResult = ReturnType<
  typeof useGetPoligonTemplatesQuery
>;
export type GetPoligonTemplatesLazyQueryHookResult = ReturnType<
  typeof useGetPoligonTemplatesLazyQuery
>;
export type GetPoligonTemplatesQueryResult = Apollo.QueryResult<
  GetPoligonTemplatesQuery,
  GetPoligonTemplatesQueryVariables
>;
export const GetPoligonsDocument = gql`
  query getPoligons($data: GetPoligonsInput) {
    getPoligons(data: $data) {
      rows {
        id
        name
        createdAt
        owner {
          id
          login
          role
        }
        project {
          id
          name
        }
      }
      count
    }
  }
`;

/**
 * __useGetPoligonsQuery__
 *
 * To run a query within a React component, call `useGetPoligonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoligonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoligonsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetPoligonsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPoligonsQuery, GetPoligonsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPoligonsQuery, GetPoligonsQueryVariables>(
    GetPoligonsDocument,
    options,
  );
}
export function useGetPoligonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPoligonsQuery, GetPoligonsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPoligonsQuery, GetPoligonsQueryVariables>(
    GetPoligonsDocument,
    options,
  );
}
export type GetPoligonsQueryHookResult = ReturnType<typeof useGetPoligonsQuery>;
export type GetPoligonsLazyQueryHookResult = ReturnType<typeof useGetPoligonsLazyQuery>;
export type GetPoligonsQueryResult = Apollo.QueryResult<
  GetPoligonsQuery,
  GetPoligonsQueryVariables
>;
export const GetPrivateChatDocument = gql`
  query getPrivateChat($userId: Int!, $offset: Int) {
    getPrivateChat(userId: $userId) {
      ...chatFragment
    }
  }
  ${ChatFragmentFragmentDoc}
`;

/**
 * __useGetPrivateChatQuery__
 *
 * To run a query within a React component, call `useGetPrivateChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrivateChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrivateChatQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetPrivateChatQuery(
  baseOptions: Apollo.QueryHookOptions<GetPrivateChatQuery, GetPrivateChatQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPrivateChatQuery, GetPrivateChatQueryVariables>(
    GetPrivateChatDocument,
    options,
  );
}
export function useGetPrivateChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPrivateChatQuery,
    GetPrivateChatQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPrivateChatQuery, GetPrivateChatQueryVariables>(
    GetPrivateChatDocument,
    options,
  );
}
export type GetPrivateChatQueryHookResult = ReturnType<typeof useGetPrivateChatQuery>;
export type GetPrivateChatLazyQueryHookResult = ReturnType<
  typeof useGetPrivateChatLazyQuery
>;
export type GetPrivateChatQueryResult = Apollo.QueryResult<
  GetPrivateChatQuery,
  GetPrivateChatQueryVariables
>;
export const GetPrivateChatNotesDocument = gql`
  query getPrivateChatNotes($userId: Int!) {
    getPrivateChat(userId: $userId) {
      notes(limit: 100) {
        id
        createdAt
        chatId
        taskId
        content
        owner {
          id
          login
          image
          role
        }
      }
    }
  }
`;

/**
 * __useGetPrivateChatNotesQuery__
 *
 * To run a query within a React component, call `useGetPrivateChatNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrivateChatNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrivateChatNotesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetPrivateChatNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPrivateChatNotesQuery,
    GetPrivateChatNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPrivateChatNotesQuery, GetPrivateChatNotesQueryVariables>(
    GetPrivateChatNotesDocument,
    options,
  );
}
export function useGetPrivateChatNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPrivateChatNotesQuery,
    GetPrivateChatNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPrivateChatNotesQuery, GetPrivateChatNotesQueryVariables>(
    GetPrivateChatNotesDocument,
    options,
  );
}
export type GetPrivateChatNotesQueryHookResult = ReturnType<
  typeof useGetPrivateChatNotesQuery
>;
export type GetPrivateChatNotesLazyQueryHookResult = ReturnType<
  typeof useGetPrivateChatNotesLazyQuery
>;
export type GetPrivateChatNotesQueryResult = Apollo.QueryResult<
  GetPrivateChatNotesQuery,
  GetPrivateChatNotesQueryVariables
>;
export const GetPrivateChatsDocument = gql`
  query getPrivateChats($data: GetChatsInput!) {
    getPrivateChats(data: $data) {
      rows {
        id
        login
        image
        role
        onlineStatus
        karmaStatistics {
          karma
          items {
            id
            isPlus
            userId
            messageId
          }
        }
        chats {
          id
          unreadMessagesCount
          name
          messagesList(limit: 50) {
            rows {
              id
              text
              user {
                id
                login
              }
              isRead
            }
            count
          }
        }
      }
      count
    }
  }
`;

/**
 * __useGetPrivateChatsQuery__
 *
 * To run a query within a React component, call `useGetPrivateChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrivateChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrivateChatsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetPrivateChatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPrivateChatsQuery,
    GetPrivateChatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPrivateChatsQuery, GetPrivateChatsQueryVariables>(
    GetPrivateChatsDocument,
    options,
  );
}
export function useGetPrivateChatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPrivateChatsQuery,
    GetPrivateChatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPrivateChatsQuery, GetPrivateChatsQueryVariables>(
    GetPrivateChatsDocument,
    options,
  );
}
export type GetPrivateChatsQueryHookResult = ReturnType<typeof useGetPrivateChatsQuery>;
export type GetPrivateChatsLazyQueryHookResult = ReturnType<
  typeof useGetPrivateChatsLazyQuery
>;
export type GetPrivateChatsQueryResult = Apollo.QueryResult<
  GetPrivateChatsQuery,
  GetPrivateChatsQueryVariables
>;
export const GetProjectDocument = gql`
  query getProject($id: Int!, $tasksData: GetTasksInput!) {
    getProject(id: $id) {
      id
      name
      number
      createdAt
      updatedAt
      poligon {
        id
      }
      author {
        id
        image
        login
      }
      tasks(data: $tasksData) {
        count
        rows {
          id
          number
          name
          code
          startDate
          closeDate
          project {
            id
            name
          }
          status {
            id
            color
          }
          project {
            id
            number
          }
          author {
            id
            image
            login
            role
            karmaStatistics {
              karma
            }
          }
          assignees {
            id
            image
            login
            role
            karmaStatistics {
              karma
            }
          }
          maintainer {
            id
            login
            image
            karmaStatistics {
              karma
            }
          }
          subtasks(data: { sort: { type: DESC, field: activity } }) {
            id
            createdAt
            name
            number
            status {
              id
              color
            }
            task {
              id
              number
              project {
                id
                name
              }
              poligonObject {
                id
              }
            }
            tags {
              count
              rows {
                id
                createdAt
                updatedAt
                name
                color
                textColor
              }
            }
          }
          maintainer {
            id
            login
            image
            fullName
            karmaStatistics {
              karma
            }
          }
          endDate
          tags {
            count
            rows {
              id
              createdAt
              updatedAt
              name
              color
            }
          }
          realizationPerMonthAmount
          realizationPrice
        }
      }
    }
  }
`;

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tasksData: // value for 'tasksData'
 *   },
 * });
 */
export function useGetProjectQuery(
  baseOptions: Apollo.QueryHookOptions<GetProjectQuery, GetProjectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectQuery, GetProjectQueryVariables>(
    GetProjectDocument,
    options,
  );
}
export function useGetProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProjectQuery, GetProjectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(
    GetProjectDocument,
    options,
  );
}
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectLazyQueryHookResult = ReturnType<typeof useGetProjectLazyQuery>;
export type GetProjectQueryResult = Apollo.QueryResult<
  GetProjectQuery,
  GetProjectQueryVariables
>;
export const GetProjectActivitiesDocument = gql`
  query getProjectActivities {
    getProjectActivities {
      id
      action
      createdAt
      actioner {
        id
        login
        image
        karmaStatistics {
          karma
        }
      }
      project {
        id
        name
      }
      task {
        id
        name
        code
        maintainer {
          id
          login
          image
          karmaStatistics {
            karma
          }
        }
      }
      subtask {
        id
        name
        code
        maintainer {
          id
          login
          image
          karmaStatistics {
            karma
          }
        }
      }
      thread {
        id
        meta {
          id
          name
          status {
            color
          }
        }
        scaleMapTask {
          name
        }
      }
    }
  }
`;

/**
 * __useGetProjectActivitiesQuery__
 *
 * To run a query within a React component, call `useGetProjectActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectActivitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectActivitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProjectActivitiesQuery,
    GetProjectActivitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectActivitiesQuery, GetProjectActivitiesQueryVariables>(
    GetProjectActivitiesDocument,
    options,
  );
}
export function useGetProjectActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectActivitiesQuery,
    GetProjectActivitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectActivitiesQuery,
    GetProjectActivitiesQueryVariables
  >(GetProjectActivitiesDocument, options);
}
export type GetProjectActivitiesQueryHookResult = ReturnType<
  typeof useGetProjectActivitiesQuery
>;
export type GetProjectActivitiesLazyQueryHookResult = ReturnType<
  typeof useGetProjectActivitiesLazyQuery
>;
export type GetProjectActivitiesQueryResult = Apollo.QueryResult<
  GetProjectActivitiesQuery,
  GetProjectActivitiesQueryVariables
>;
export const GetProjectLightDocument = gql`
  query getProjectLight($id: Int!, $tasksData: GetTasksInput!) {
    getProject(id: $id) {
      id
      name
      author {
        id
        login
      }
      maintainer {
        id
        login
      }
      tasksSubtasks(data: $tasksData) {
        count
        rows {
          id
          name
          code
          poligonObject {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useGetProjectLightQuery__
 *
 * To run a query within a React component, call `useGetProjectLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectLightQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tasksData: // value for 'tasksData'
 *   },
 * });
 */
export function useGetProjectLightQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectLightQuery,
    GetProjectLightQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectLightQuery, GetProjectLightQueryVariables>(
    GetProjectLightDocument,
    options,
  );
}
export function useGetProjectLightLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectLightQuery,
    GetProjectLightQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectLightQuery, GetProjectLightQueryVariables>(
    GetProjectLightDocument,
    options,
  );
}
export type GetProjectLightQueryHookResult = ReturnType<typeof useGetProjectLightQuery>;
export type GetProjectLightLazyQueryHookResult = ReturnType<
  typeof useGetProjectLightLazyQuery
>;
export type GetProjectLightQueryResult = Apollo.QueryResult<
  GetProjectLightQuery,
  GetProjectLightQueryVariables
>;
export const GetProjectsDocument = gql`
  query getProjects($data: GetProjectsInput!, $tasksData: GetTasksInput!) {
    getProjects(data: $data) {
      rows {
        id
        name
        number
        createdAt
        updatedAt
        poligon {
          id
        }
        author {
          id
          image
          login
          karmaStatistics {
            karma
          }
        }
        tasks(data: $tasksData) {
          count
          rows {
            id
            number
            name
            code
            startDate
            closeDate
            endDate
            status {
              id
              color
            }
            project {
              id
              number
            }
            author {
              id
              image
              login
              role
            }
            assignees {
              id
              image
              login
              role
            }
            maintainer {
              id
              login
              image
              karmaStatistics {
                karma
              }
            }
            tags {
              count
              rows {
                id
                name
                color
                textColor
                createdAt
                updatedAt
              }
            }
            realizationPerMonthAmount
            realizationPrice
            poligonObject {
              id
            }
          }
        }
      }
      count
    }
  }
`;

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *      data: // value for 'data'
 *      tasksData: // value for 'tasksData'
 *   },
 * });
 */
export function useGetProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(
    GetProjectsDocument,
    options,
  );
}
export function useGetProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(
    GetProjectsDocument,
    options,
  );
}
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<typeof useGetProjectsLazyQuery>;
export type GetProjectsQueryResult = Apollo.QueryResult<
  GetProjectsQuery,
  GetProjectsQueryVariables
>;
export const GetProjectsCalendarDocument = gql`
  query getProjectsCalendar(
    $data: GetProjectsInput!
    $tasksSubtasksData: GetTasksInput!
  ) {
    getProjects(data: $data) {
      rows {
        id
        name
        number
        createdAt
        updatedAt
        tasksSubtasks(data: $tasksSubtasksData) {
          rows {
            id
            number
            name
            code
            parentId
            startDate
            closeDate
            endDate
            status {
              id
              color
            }
            project {
              id
              number
            }
            tags {
              count
              rows {
                id
                name
                color
                textColor
                createdAt
                updatedAt
              }
            }
          }
          count
        }
      }
      count
    }
  }
`;

/**
 * __useGetProjectsCalendarQuery__
 *
 * To run a query within a React component, call `useGetProjectsCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsCalendarQuery({
 *   variables: {
 *      data: // value for 'data'
 *      tasksSubtasksData: // value for 'tasksSubtasksData'
 *   },
 * });
 */
export function useGetProjectsCalendarQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectsCalendarQuery,
    GetProjectsCalendarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectsCalendarQuery, GetProjectsCalendarQueryVariables>(
    GetProjectsCalendarDocument,
    options,
  );
}
export function useGetProjectsCalendarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectsCalendarQuery,
    GetProjectsCalendarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectsCalendarQuery, GetProjectsCalendarQueryVariables>(
    GetProjectsCalendarDocument,
    options,
  );
}
export type GetProjectsCalendarQueryHookResult = ReturnType<
  typeof useGetProjectsCalendarQuery
>;
export type GetProjectsCalendarLazyQueryHookResult = ReturnType<
  typeof useGetProjectsCalendarLazyQuery
>;
export type GetProjectsCalendarQueryResult = Apollo.QueryResult<
  GetProjectsCalendarQuery,
  GetProjectsCalendarQueryVariables
>;
export const GetProjectsScaleDocument = gql`
  query getProjectsScale(
    $data: GetProjectsInput!
    $statusId: Int
    $isExcludeNullStatusTasks: Boolean
    $dataFiles: ScaleMapFilesInput
  ) {
    getProjects(data: $data) {
      rows {
        id
        name
        number
        createdAt
        updatedAt
        poligon {
          id
        }
        author {
          id
          image
          login
        }
        scaleMapFiles(data: $dataFiles) {
          id
          createdAt
          updatedAt
          name
          isPersonal
          project {
            id
          }
          scaleMapTasks(
            statusId: $statusId
            isExcludeNullStatusTasks: $isExcludeNullStatusTasks
          ) {
            id
            name
            order
            updatedAt
            statusUpdatedDate
            column
            row
            status {
              id
              name
              color
            }
            thread {
              id
            }
            counterComment
            counterFavorites
            scaleMapTaskMarkStatus {
              id
              title
              color
            }
            parentTree {
              id
              name
              column
              updatedAt
              statusUpdatedDate
              scaleMapTaskMarkStatus {
                id
                title
                color
              }
              thread {
                id
              }
              status {
                id
                name
                color
              }
            }
          }
          statistics {
            tasksCount
            completedTasksPercent
            inProgressTasksPercent
            problemTasksCount
            notInProgressTasksPercent
            problemTasksPercent
            isTasksCreatedToday
            isTasksUpdatedToday
          }
        }
      }
      count
    }
  }
`;

/**
 * __useGetProjectsScaleQuery__
 *
 * To run a query within a React component, call `useGetProjectsScaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsScaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsScaleQuery({
 *   variables: {
 *      data: // value for 'data'
 *      statusId: // value for 'statusId'
 *      isExcludeNullStatusTasks: // value for 'isExcludeNullStatusTasks'
 *      dataFiles: // value for 'dataFiles'
 *   },
 * });
 */
export function useGetProjectsScaleQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectsScaleQuery,
    GetProjectsScaleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectsScaleQuery, GetProjectsScaleQueryVariables>(
    GetProjectsScaleDocument,
    options,
  );
}
export function useGetProjectsScaleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectsScaleQuery,
    GetProjectsScaleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectsScaleQuery, GetProjectsScaleQueryVariables>(
    GetProjectsScaleDocument,
    options,
  );
}
export type GetProjectsScaleQueryHookResult = ReturnType<typeof useGetProjectsScaleQuery>;
export type GetProjectsScaleLazyQueryHookResult = ReturnType<
  typeof useGetProjectsScaleLazyQuery
>;
export type GetProjectsScaleQueryResult = Apollo.QueryResult<
  GetProjectsScaleQuery,
  GetProjectsScaleQueryVariables
>;
export const GetProjectsScaleOptimizeDocument = gql`
  query getProjectsScaleOptimize(
    $data: GetProjectsInput!
    $dataFiles: ScaleMapFilesInput
  ) {
    getProjects(data: $data) {
      rows {
        id
        name
        number
        scaleMapFiles(data: $dataFiles) {
          id
          name
          isPersonal
          statistics {
            tasksCount
            completedTasksPercent
            inProgressTasksPercent
            problemTasksCount
            notInProgressTasksPercent
            problemTasksPercent
            isTasksCreatedToday
            isTasksUpdatedToday
          }
        }
      }
      count
    }
  }
`;

/**
 * __useGetProjectsScaleOptimizeQuery__
 *
 * To run a query within a React component, call `useGetProjectsScaleOptimizeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsScaleOptimizeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsScaleOptimizeQuery({
 *   variables: {
 *      data: // value for 'data'
 *      dataFiles: // value for 'dataFiles'
 *   },
 * });
 */
export function useGetProjectsScaleOptimizeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectsScaleOptimizeQuery,
    GetProjectsScaleOptimizeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectsScaleOptimizeQuery,
    GetProjectsScaleOptimizeQueryVariables
  >(GetProjectsScaleOptimizeDocument, options);
}
export function useGetProjectsScaleOptimizeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectsScaleOptimizeQuery,
    GetProjectsScaleOptimizeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectsScaleOptimizeQuery,
    GetProjectsScaleOptimizeQueryVariables
  >(GetProjectsScaleOptimizeDocument, options);
}
export type GetProjectsScaleOptimizeQueryHookResult = ReturnType<
  typeof useGetProjectsScaleOptimizeQuery
>;
export type GetProjectsScaleOptimizeLazyQueryHookResult = ReturnType<
  typeof useGetProjectsScaleOptimizeLazyQuery
>;
export type GetProjectsScaleOptimizeQueryResult = Apollo.QueryResult<
  GetProjectsScaleOptimizeQuery,
  GetProjectsScaleOptimizeQueryVariables
>;
export const GetProjectsStorageAccesDocument = gql`
  query getProjectsStorageAcces($data: GetProjectsInput!) {
    getProjects(data: $data) {
      rows {
        id
        name
      }
      count
    }
  }
`;

/**
 * __useGetProjectsStorageAccesQuery__
 *
 * To run a query within a React component, call `useGetProjectsStorageAccesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsStorageAccesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsStorageAccesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetProjectsStorageAccesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectsStorageAccesQuery,
    GetProjectsStorageAccesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectsStorageAccesQuery,
    GetProjectsStorageAccesQueryVariables
  >(GetProjectsStorageAccesDocument, options);
}
export function useGetProjectsStorageAccesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectsStorageAccesQuery,
    GetProjectsStorageAccesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectsStorageAccesQuery,
    GetProjectsStorageAccesQueryVariables
  >(GetProjectsStorageAccesDocument, options);
}
export type GetProjectsStorageAccesQueryHookResult = ReturnType<
  typeof useGetProjectsStorageAccesQuery
>;
export type GetProjectsStorageAccesLazyQueryHookResult = ReturnType<
  typeof useGetProjectsStorageAccesLazyQuery
>;
export type GetProjectsStorageAccesQueryResult = Apollo.QueryResult<
  GetProjectsStorageAccesQuery,
  GetProjectsStorageAccesQueryVariables
>;
export const GetProjectsUserDocument = gql`
  query getProjectsUser(
    $data: GetProjectsInput!
    $tasksData: GetTasksInput!
    $userId: Int
  ) {
    getProjects(data: $data, userId: $userId) {
      rows {
        id
        name
        number
        createdAt
        updatedAt
        poligon {
          id
        }
        author {
          id
          image
          login
        }
        tasksSubtasks(data: $tasksData, userId: $userId) {
          count
          rows {
            id
            parentId
            number
            description
            name
            code
            startDate
            closeDate
            endDate
            status {
              id
              color
            }
            project {
              id
              number
            }
            author {
              id
              image
              login
              role
            }
            assignees {
              id
              image
              login
              role
            }
            maintainer {
              id
              login
              image
            }
            tags {
              count
              rows {
                id
                name
                color
                textColor
                createdAt
                updatedAt
              }
            }
            realizationPerMonthAmount
            realizationPrice
            poligonObject {
              id
            }
          }
        }
      }
      count
    }
  }
`;

/**
 * __useGetProjectsUserQuery__
 *
 * To run a query within a React component, call `useGetProjectsUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsUserQuery({
 *   variables: {
 *      data: // value for 'data'
 *      tasksData: // value for 'tasksData'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetProjectsUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectsUserQuery,
    GetProjectsUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectsUserQuery, GetProjectsUserQueryVariables>(
    GetProjectsUserDocument,
    options,
  );
}
export function useGetProjectsUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectsUserQuery,
    GetProjectsUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectsUserQuery, GetProjectsUserQueryVariables>(
    GetProjectsUserDocument,
    options,
  );
}
export type GetProjectsUserQueryHookResult = ReturnType<typeof useGetProjectsUserQuery>;
export type GetProjectsUserLazyQueryHookResult = ReturnType<
  typeof useGetProjectsUserLazyQuery
>;
export type GetProjectsUserQueryResult = Apollo.QueryResult<
  GetProjectsUserQuery,
  GetProjectsUserQueryVariables
>;
export const GetScaleMapFileDocument = gql`
  query getScaleMapFile($getScaleMapFileId: Int!, $statusId: Int, $excludeStatusId: Int) {
    getScaleMapFile(id: $getScaleMapFileId) {
      id
      name
      isPersonal
      columnName1
      columnName2
      columnName3
      calendarTaskTextColor
      isStatus1ExcludedInFilter
      project {
        id
        number
        name
      }
      user {
        id
        login
      }
      scaleMapTasks(statusId: $statusId, excludeStatusId: $excludeStatusId) {
        id
        name
        column
        row
        calendarTaskTextColor
        tags {
          id
          name
          color
          textColor
        }
        order
        scaleMapTaskId
        isExclamationPoint
        thread {
          id
        }
        status {
          id
          name
          color
        }
        isNestingAllTasksWithCompleted
        isMain
        statistics {
          maxStatusInNestingTasks {
            id
            name
            color
          }
          maxStatusInNestingTasksPercent
        }
        nestingTaskCount
      }
      statistics {
        tasksCount
        completedTasksPercent
        inProgressTasksPercent
        problemTasksCount
        notInProgressTasksPercent
        problemTasksPercent
      }
      scaleMapColumnStatistics(statusId: $statusId) {
        column
        row
        statusTasks {
          id
          name
          color
        }
        statusTasksPercent
      }
      mergedColumnStatistics(statusId: $statusId) {
        column
        row
        statusTasks {
          id
          name
          color
        }
        statusTasksPercent
      }
      isBridgeOfSleep
      isPublic
      isArchived
    }
  }
`;

/**
 * __useGetScaleMapFileQuery__
 *
 * To run a query within a React component, call `useGetScaleMapFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScaleMapFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScaleMapFileQuery({
 *   variables: {
 *      getScaleMapFileId: // value for 'getScaleMapFileId'
 *      statusId: // value for 'statusId'
 *      excludeStatusId: // value for 'excludeStatusId'
 *   },
 * });
 */
export function useGetScaleMapFileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetScaleMapFileQuery,
    GetScaleMapFileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetScaleMapFileQuery, GetScaleMapFileQueryVariables>(
    GetScaleMapFileDocument,
    options,
  );
}
export function useGetScaleMapFileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScaleMapFileQuery,
    GetScaleMapFileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetScaleMapFileQuery, GetScaleMapFileQueryVariables>(
    GetScaleMapFileDocument,
    options,
  );
}
export type GetScaleMapFileQueryHookResult = ReturnType<typeof useGetScaleMapFileQuery>;
export type GetScaleMapFileLazyQueryHookResult = ReturnType<
  typeof useGetScaleMapFileLazyQuery
>;
export type GetScaleMapFileQueryResult = Apollo.QueryResult<
  GetScaleMapFileQuery,
  GetScaleMapFileQueryVariables
>;
export const GetScaleMapFileTagsAndFonsDocument = gql`
  query getScaleMapFileTagsAndFons($getScaleMapFileId: Int!) {
    getScaleMapFile(id: $getScaleMapFileId) {
      attachedTags {
        id
      }
      threadBackground {
        id
      }
    }
  }
`;

/**
 * __useGetScaleMapFileTagsAndFonsQuery__
 *
 * To run a query within a React component, call `useGetScaleMapFileTagsAndFonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScaleMapFileTagsAndFonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScaleMapFileTagsAndFonsQuery({
 *   variables: {
 *      getScaleMapFileId: // value for 'getScaleMapFileId'
 *   },
 * });
 */
export function useGetScaleMapFileTagsAndFonsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetScaleMapFileTagsAndFonsQuery,
    GetScaleMapFileTagsAndFonsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetScaleMapFileTagsAndFonsQuery,
    GetScaleMapFileTagsAndFonsQueryVariables
  >(GetScaleMapFileTagsAndFonsDocument, options);
}
export function useGetScaleMapFileTagsAndFonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScaleMapFileTagsAndFonsQuery,
    GetScaleMapFileTagsAndFonsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetScaleMapFileTagsAndFonsQuery,
    GetScaleMapFileTagsAndFonsQueryVariables
  >(GetScaleMapFileTagsAndFonsDocument, options);
}
export type GetScaleMapFileTagsAndFonsQueryHookResult = ReturnType<
  typeof useGetScaleMapFileTagsAndFonsQuery
>;
export type GetScaleMapFileTagsAndFonsLazyQueryHookResult = ReturnType<
  typeof useGetScaleMapFileTagsAndFonsLazyQuery
>;
export type GetScaleMapFileTagsAndFonsQueryResult = Apollo.QueryResult<
  GetScaleMapFileTagsAndFonsQuery,
  GetScaleMapFileTagsAndFonsQueryVariables
>;
export const GetScaleMapStatusesDocument = gql`
  query getScaleMapStatuses {
    getScaleMapStatuses {
      id
      name
      color
    }
  }
`;

/**
 * __useGetScaleMapStatusesQuery__
 *
 * To run a query within a React component, call `useGetScaleMapStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScaleMapStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScaleMapStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetScaleMapStatusesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetScaleMapStatusesQuery,
    GetScaleMapStatusesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetScaleMapStatusesQuery, GetScaleMapStatusesQueryVariables>(
    GetScaleMapStatusesDocument,
    options,
  );
}
export function useGetScaleMapStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScaleMapStatusesQuery,
    GetScaleMapStatusesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetScaleMapStatusesQuery, GetScaleMapStatusesQueryVariables>(
    GetScaleMapStatusesDocument,
    options,
  );
}
export type GetScaleMapStatusesQueryHookResult = ReturnType<
  typeof useGetScaleMapStatusesQuery
>;
export type GetScaleMapStatusesLazyQueryHookResult = ReturnType<
  typeof useGetScaleMapStatusesLazyQuery
>;
export type GetScaleMapStatusesQueryResult = Apollo.QueryResult<
  GetScaleMapStatusesQuery,
  GetScaleMapStatusesQueryVariables
>;
export const GetScaleMapTaskHistoryStatisticsDocument = gql`
  query getScaleMapTaskHistoryStatistics($date: String!, $userId: Int) {
    getScaleMapTaskHistoryStatistics(date: $date, userId: $userId) {
      date
      historyTypes {
        type
        items {
          statusId
          status {
            id
            name
            color
          }
          scaleMapTasks {
            id
            name
            order
            createdAt
            updatedAt
            statusUpdatedDate
            creationScaleMapStatusId
            scaleMapFile {
              id
              name
              project {
                id
                name
              }
              user {
                id
              }
            }
          }
          count
        }
      }
    }
  }
`;

/**
 * __useGetScaleMapTaskHistoryStatisticsQuery__
 *
 * To run a query within a React component, call `useGetScaleMapTaskHistoryStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScaleMapTaskHistoryStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScaleMapTaskHistoryStatisticsQuery({
 *   variables: {
 *      date: // value for 'date'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetScaleMapTaskHistoryStatisticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetScaleMapTaskHistoryStatisticsQuery,
    GetScaleMapTaskHistoryStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetScaleMapTaskHistoryStatisticsQuery,
    GetScaleMapTaskHistoryStatisticsQueryVariables
  >(GetScaleMapTaskHistoryStatisticsDocument, options);
}
export function useGetScaleMapTaskHistoryStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScaleMapTaskHistoryStatisticsQuery,
    GetScaleMapTaskHistoryStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetScaleMapTaskHistoryStatisticsQuery,
    GetScaleMapTaskHistoryStatisticsQueryVariables
  >(GetScaleMapTaskHistoryStatisticsDocument, options);
}
export type GetScaleMapTaskHistoryStatisticsQueryHookResult = ReturnType<
  typeof useGetScaleMapTaskHistoryStatisticsQuery
>;
export type GetScaleMapTaskHistoryStatisticsLazyQueryHookResult = ReturnType<
  typeof useGetScaleMapTaskHistoryStatisticsLazyQuery
>;
export type GetScaleMapTaskHistoryStatisticsQueryResult = Apollo.QueryResult<
  GetScaleMapTaskHistoryStatisticsQuery,
  GetScaleMapTaskHistoryStatisticsQueryVariables
>;
export const SearchThreadsDocument = gql`
  query searchThreads($search: String!, $limit: Int, $offset: Int) {
    searchThreads(search: $search, limit: $limit, offset: $offset) {
      rows {
        id
        createdAt
        updatedAt
        authorId
        cover
        author {
          id
          login
          image
        }
        notes {
          id
        }
        statistic {
          performerAcceptedPercent
        }
        scaleMapTask {
          id
          name
          children {
            id
            name
            status {
              color
            }
          }
        }
        performers {
          threadId
          userId
          status
          user {
            id
            login
            image
            IsExistUnViewedForum
            role
            fullName
          }
        }
        mainPerformer {
          id
          login
          image
          IsExistUnViewedForum
          role
          fullName
        }
        mainPerformerId
        tags {
          id
          name
          color
          textColor
        }
        performerStatus
        meta {
          id
          name
          isMain
          isCurrent
          status {
            color
          }
        }
        userLastEdited {
          id
          login
          tags {
            rows {
              id
              name
              color
              textColor
            }
          }
        }
      }
      count
    }
  }
`;

/**
 * __useSearchThreadsQuery__
 *
 * To run a query within a React component, call `useSearchThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchThreadsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSearchThreadsQuery(
  baseOptions: Apollo.QueryHookOptions<SearchThreadsQuery, SearchThreadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchThreadsQuery, SearchThreadsQueryVariables>(
    SearchThreadsDocument,
    options,
  );
}
export function useSearchThreadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchThreadsQuery,
    SearchThreadsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchThreadsQuery, SearchThreadsQueryVariables>(
    SearchThreadsDocument,
    options,
  );
}
export type SearchThreadsQueryHookResult = ReturnType<typeof useSearchThreadsQuery>;
export type SearchThreadsLazyQueryHookResult = ReturnType<
  typeof useSearchThreadsLazyQuery
>;
export type SearchThreadsQueryResult = Apollo.QueryResult<
  SearchThreadsQuery,
  SearchThreadsQueryVariables
>;
export const GetServerTimeDocument = gql`
  query getServerTime {
    getServerTime {
      timeZone
    }
  }
`;

/**
 * __useGetServerTimeQuery__
 *
 * To run a query within a React component, call `useGetServerTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServerTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServerTimeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetServerTimeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetServerTimeQuery, GetServerTimeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetServerTimeQuery, GetServerTimeQueryVariables>(
    GetServerTimeDocument,
    options,
  );
}
export function useGetServerTimeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServerTimeQuery,
    GetServerTimeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetServerTimeQuery, GetServerTimeQueryVariables>(
    GetServerTimeDocument,
    options,
  );
}
export type GetServerTimeQueryHookResult = ReturnType<typeof useGetServerTimeQuery>;
export type GetServerTimeLazyQueryHookResult = ReturnType<
  typeof useGetServerTimeLazyQuery
>;
export type GetServerTimeQueryResult = Apollo.QueryResult<
  GetServerTimeQuery,
  GetServerTimeQueryVariables
>;
export const GetSocialCircleStatisticsDocument = gql`
  query getSocialCircleStatistics {
    getSocialCircleStatistics {
      totalObjectsInCircle
      totalAppointedMeetings
      daysSinceFirstMeetingCreation
    }
  }
`;

/**
 * __useGetSocialCircleStatisticsQuery__
 *
 * To run a query within a React component, call `useGetSocialCircleStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSocialCircleStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSocialCircleStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSocialCircleStatisticsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSocialCircleStatisticsQuery,
    GetSocialCircleStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSocialCircleStatisticsQuery,
    GetSocialCircleStatisticsQueryVariables
  >(GetSocialCircleStatisticsDocument, options);
}
export function useGetSocialCircleStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSocialCircleStatisticsQuery,
    GetSocialCircleStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSocialCircleStatisticsQuery,
    GetSocialCircleStatisticsQueryVariables
  >(GetSocialCircleStatisticsDocument, options);
}
export type GetSocialCircleStatisticsQueryHookResult = ReturnType<
  typeof useGetSocialCircleStatisticsQuery
>;
export type GetSocialCircleStatisticsLazyQueryHookResult = ReturnType<
  typeof useGetSocialCircleStatisticsLazyQuery
>;
export type GetSocialCircleStatisticsQueryResult = Apollo.QueryResult<
  GetSocialCircleStatisticsQuery,
  GetSocialCircleStatisticsQueryVariables
>;
export const GetStatusesDocument = gql`
  query getStatuses {
    getStatuses {
      id
      color
    }
  }
`;

/**
 * __useGetStatusesQuery__
 *
 * To run a query within a React component, call `useGetStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStatusesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetStatusesQuery, GetStatusesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStatusesQuery, GetStatusesQueryVariables>(
    GetStatusesDocument,
    options,
  );
}
export function useGetStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStatusesQuery, GetStatusesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStatusesQuery, GetStatusesQueryVariables>(
    GetStatusesDocument,
    options,
  );
}
export type GetStatusesQueryHookResult = ReturnType<typeof useGetStatusesQuery>;
export type GetStatusesLazyQueryHookResult = ReturnType<typeof useGetStatusesLazyQuery>;
export type GetStatusesQueryResult = Apollo.QueryResult<
  GetStatusesQuery,
  GetStatusesQueryVariables
>;
export const GetStorageProjectDocument = gql`
  query getStorageProject(
    $id: Int!
    $storageFilter: [storageFilterItems!]!
    $limit: Int
    $offset: Int
    $search: String!
    $isOnlyFromThreads: Boolean
  ) {
    getProject(id: $id) {
      id
      name
      number
      storageEntities(
        storageFilter: $storageFilter
        limit: $limit
        offset: $offset
        search: $search
        isOnlyFromThreads: $isOnlyFromThreads
      ) {
        count
        rows {
          ... on File {
            id
            fileName
            previewUrl
            mimeType
            path
            size
            task {
              id
              number
              name
              code
            }
            subtask {
              id
              number
              name
              code
              task {
                id
              }
            }
            thread {
              id
              scaleMapTask {
                name
              }
            }
            createdAt
            updatedAt
            mimeType
          }
          ... on StorageItem {
            id
            name
            href
            thumbnailPath
            isAccess
            createdAt
            updatedAt
            taskId
            task {
              id
              number
              name
              code
            }
            subtask {
              id
              number
              name
              code
              task {
                id
              }
            }
            thread {
              id
              scaleMapTask {
                name
              }
            }
            subtaskId
            mimeType
          }
        }
      }
    }
  }
`;

/**
 * __useGetStorageProjectQuery__
 *
 * To run a query within a React component, call `useGetStorageProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStorageProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStorageProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *      storageFilter: // value for 'storageFilter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *      isOnlyFromThreads: // value for 'isOnlyFromThreads'
 *   },
 * });
 */
export function useGetStorageProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStorageProjectQuery,
    GetStorageProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStorageProjectQuery, GetStorageProjectQueryVariables>(
    GetStorageProjectDocument,
    options,
  );
}
export function useGetStorageProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStorageProjectQuery,
    GetStorageProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStorageProjectQuery, GetStorageProjectQueryVariables>(
    GetStorageProjectDocument,
    options,
  );
}
export type GetStorageProjectQueryHookResult = ReturnType<
  typeof useGetStorageProjectQuery
>;
export type GetStorageProjectLazyQueryHookResult = ReturnType<
  typeof useGetStorageProjectLazyQuery
>;
export type GetStorageProjectQueryResult = Apollo.QueryResult<
  GetStorageProjectQuery,
  GetStorageProjectQueryVariables
>;
export const GetStorageProjectsDocument = gql`
  query getStorageProjects(
    $projectsData: GetProjectsInput!
    $storageFilter: [storageFilterItems!]!
    $limit: Int
    $offset: Int
    $search: String!
    $isOnlyFromThreads: Boolean
  ) {
    getProjects(data: $projectsData) {
      count
      rows {
        id
        name
        number
        storageEntities(
          storageFilter: $storageFilter
          limit: $limit
          offset: $offset
          search: $search
          isOnlyFromThreads: $isOnlyFromThreads
        ) {
          count
          rows {
            ... on File {
              id
              fileName
              previewUrl
              mimeType
              path
              size
              task {
                id
                number
                name
                code
              }
              subtask {
                id
                number
                name
                code
                task {
                  id
                }
              }
              thread {
                id
                scaleMapTask {
                  name
                }
              }
              createdAt
              updatedAt
              mimeType
            }
            ... on StorageItem {
              id
              name
              href
              thumbnailPath
              isAccess
              createdAt
              updatedAt
              taskId
              task {
                id
                number
                name
                code
              }
              subtask {
                id
                number
                name
                code
                task {
                  id
                }
              }
              thread {
                id
                scaleMapTask {
                  name
                }
              }
              subtaskId
              mimeType
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetStorageProjectsQuery__
 *
 * To run a query within a React component, call `useGetStorageProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStorageProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStorageProjectsQuery({
 *   variables: {
 *      projectsData: // value for 'projectsData'
 *      storageFilter: // value for 'storageFilter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *      isOnlyFromThreads: // value for 'isOnlyFromThreads'
 *   },
 * });
 */
export function useGetStorageProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStorageProjectsQuery,
    GetStorageProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStorageProjectsQuery, GetStorageProjectsQueryVariables>(
    GetStorageProjectsDocument,
    options,
  );
}
export function useGetStorageProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStorageProjectsQuery,
    GetStorageProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStorageProjectsQuery, GetStorageProjectsQueryVariables>(
    GetStorageProjectsDocument,
    options,
  );
}
export type GetStorageProjectsQueryHookResult = ReturnType<
  typeof useGetStorageProjectsQuery
>;
export type GetStorageProjectsLazyQueryHookResult = ReturnType<
  typeof useGetStorageProjectsLazyQuery
>;
export type GetStorageProjectsQueryResult = Apollo.QueryResult<
  GetStorageProjectsQuery,
  GetStorageProjectsQueryVariables
>;
export const GetSubtaskDocument = gql`
  query getSubtask($id: Int!) {
    getSubtask(id: $id) {
      id
      createdAt
      name
      number
      code
      startDate
      closeDate
      endDate
      task {
        id
        code
        startDate
        closeDate
        endDate
        project {
          id
        }
        maintainer {
          id
          login
          image
          isAnalytics
          karmaStatistics {
            karma
          }
        }
      }
      status {
        id
        color
      }
      author {
        id
        login
        karmaStatistics {
          karma
        }
      }
      maintainer {
        id
        login
        image
        isAnalytics
        karmaStatistics {
          karma
        }
      }
      tags {
        count
        rows {
          id
          name
          color
          textColor
          createdAt
          updatedAt
        }
      }
      poligonObject {
        id
      }
    }
  }
`;

/**
 * __useGetSubtaskQuery__
 *
 * To run a query within a React component, call `useGetSubtaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubtaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubtaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSubtaskQuery(
  baseOptions: Apollo.QueryHookOptions<GetSubtaskQuery, GetSubtaskQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSubtaskQuery, GetSubtaskQueryVariables>(
    GetSubtaskDocument,
    options,
  );
}
export function useGetSubtaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSubtaskQuery, GetSubtaskQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSubtaskQuery, GetSubtaskQueryVariables>(
    GetSubtaskDocument,
    options,
  );
}
export type GetSubtaskQueryHookResult = ReturnType<typeof useGetSubtaskQuery>;
export type GetSubtaskLazyQueryHookResult = ReturnType<typeof useGetSubtaskLazyQuery>;
export type GetSubtaskQueryResult = Apollo.QueryResult<
  GetSubtaskQuery,
  GetSubtaskQueryVariables
>;
export const GetSubtaskChatDocument = gql`
  query getSubtaskChat($id: Int!, $offset: Int) {
    getSubtask(id: $id) {
      id
      chat {
        ...chatFragment
      }
    }
  }
  ${ChatFragmentFragmentDoc}
`;

/**
 * __useGetSubtaskChatQuery__
 *
 * To run a query within a React component, call `useGetSubtaskChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubtaskChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubtaskChatQuery({
 *   variables: {
 *      id: // value for 'id'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetSubtaskChatQuery(
  baseOptions: Apollo.QueryHookOptions<GetSubtaskChatQuery, GetSubtaskChatQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSubtaskChatQuery, GetSubtaskChatQueryVariables>(
    GetSubtaskChatDocument,
    options,
  );
}
export function useGetSubtaskChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubtaskChatQuery,
    GetSubtaskChatQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSubtaskChatQuery, GetSubtaskChatQueryVariables>(
    GetSubtaskChatDocument,
    options,
  );
}
export type GetSubtaskChatQueryHookResult = ReturnType<typeof useGetSubtaskChatQuery>;
export type GetSubtaskChatLazyQueryHookResult = ReturnType<
  typeof useGetSubtaskChatLazyQuery
>;
export type GetSubtaskChatQueryResult = Apollo.QueryResult<
  GetSubtaskChatQuery,
  GetSubtaskChatQueryVariables
>;
export const GetSubtaskNotesDocument = gql`
  query getSubtaskNotes($id: Int!) {
    getSubtask(id: $id) {
      id
      notes {
        id
        createdAt
        chatId
        taskId
        content
        owner {
          id
          login
          image
          role
          karmaStatistics {
            karma
          }
        }
      }
    }
  }
`;

/**
 * __useGetSubtaskNotesQuery__
 *
 * To run a query within a React component, call `useGetSubtaskNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubtaskNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubtaskNotesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSubtaskNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSubtaskNotesQuery,
    GetSubtaskNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSubtaskNotesQuery, GetSubtaskNotesQueryVariables>(
    GetSubtaskNotesDocument,
    options,
  );
}
export function useGetSubtaskNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubtaskNotesQuery,
    GetSubtaskNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSubtaskNotesQuery, GetSubtaskNotesQueryVariables>(
    GetSubtaskNotesDocument,
    options,
  );
}
export type GetSubtaskNotesQueryHookResult = ReturnType<typeof useGetSubtaskNotesQuery>;
export type GetSubtaskNotesLazyQueryHookResult = ReturnType<
  typeof useGetSubtaskNotesLazyQuery
>;
export type GetSubtaskNotesQueryResult = Apollo.QueryResult<
  GetSubtaskNotesQuery,
  GetSubtaskNotesQueryVariables
>;
export const GetTagsDocument = gql`
  query getTags($data: GetTagsInput) {
    getTags(data: $data) {
      rows {
        id
        name
        color
        createdAt
        updatedAt
        textColor
      }
    }
  }
`;

/**
 * __useGetTagsQuery__
 *
 * To run a query within a React component, call `useGetTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTagsQuery, GetTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, options);
}
export function useGetTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTagsQuery, GetTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTagsQuery, GetTagsQueryVariables>(
    GetTagsDocument,
    options,
  );
}
export type GetTagsQueryHookResult = ReturnType<typeof useGetTagsQuery>;
export type GetTagsLazyQueryHookResult = ReturnType<typeof useGetTagsLazyQuery>;
export type GetTagsQueryResult = Apollo.QueryResult<GetTagsQuery, GetTagsQueryVariables>;
export const GetTagsByUserDocument = gql`
  query getTagsByUser($userId: Int!) {
    getTagsByUser(userId: $userId) {
      id
      name
      color
      createdAt
      updatedAt
      textColor
    }
  }
`;

/**
 * __useGetTagsByUserQuery__
 *
 * To run a query within a React component, call `useGetTagsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsByUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetTagsByUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetTagsByUserQuery, GetTagsByUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTagsByUserQuery, GetTagsByUserQueryVariables>(
    GetTagsByUserDocument,
    options,
  );
}
export function useGetTagsByUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTagsByUserQuery,
    GetTagsByUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTagsByUserQuery, GetTagsByUserQueryVariables>(
    GetTagsByUserDocument,
    options,
  );
}
export type GetTagsByUserQueryHookResult = ReturnType<typeof useGetTagsByUserQuery>;
export type GetTagsByUserLazyQueryHookResult = ReturnType<
  typeof useGetTagsByUserLazyQuery
>;
export type GetTagsByUserQueryResult = Apollo.QueryResult<
  GetTagsByUserQuery,
  GetTagsByUserQueryVariables
>;
export const GetTaskDocument = gql`
  query getTask($id: Int!, $subtaskStatusId: Int) {
    getTask(id: $id) {
      id
      createdAt
      updatedAt
      name
      number
      description
      code
      startDate
      closeDate
      endDate
      project {
        id
        number
        name
      }
      status {
        id
      }
      author {
        id
        login
        image
        fullName
      }
      subtasks(
        data: {
          limit: 100
          sort: { type: ASC, field: status }
          statusId: $subtaskStatusId
        }
      ) {
        id
        createdAt
        updatedAt
        name
        number
        code
        startDate
        closeDate
        task {
          id
          number
          code
          project {
            id
            number
          }
        }
        status {
          id
        }
        author {
          id
          login
          image
          karmaStatistics {
            karma
          }
        }
        maintainer {
          id
          login
          image
          isAnalytics
          karmaStatistics {
            karma
          }
        }
        endDate
        tags {
          count
          rows {
            id
            name
            color
            textColor
            createdAt
            updatedAt
          }
        }
        poligonObject {
          id
          name
        }
      }
      maintainer {
        id
        login
        image
        isAnalytics
        karmaStatistics {
          karma
        }
      }
      tags {
        count
        rows {
          id
          name
          color
          createdAt
          updatedAt
        }
      }
      realizationPerMonthAmount
      realizationPrice
      poligonObject {
        id
      }
    }
  }
`;

/**
 * __useGetTaskQuery__
 *
 * To run a query within a React component, call `useGetTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *      subtaskStatusId: // value for 'subtaskStatusId'
 *   },
 * });
 */
export function useGetTaskQuery(
  baseOptions: Apollo.QueryHookOptions<GetTaskQuery, GetTaskQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTaskQuery, GetTaskQueryVariables>(GetTaskDocument, options);
}
export function useGetTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTaskQuery, GetTaskQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTaskQuery, GetTaskQueryVariables>(
    GetTaskDocument,
    options,
  );
}
export type GetTaskQueryHookResult = ReturnType<typeof useGetTaskQuery>;
export type GetTaskLazyQueryHookResult = ReturnType<typeof useGetTaskLazyQuery>;
export type GetTaskQueryResult = Apollo.QueryResult<GetTaskQuery, GetTaskQueryVariables>;
export const GetTaskAssigneesDocument = gql`
  query getTaskAssignees($id: Int!, $assigneesLimit: Int!) {
    getTask(id: $id) {
      id
      assignees(limit: $assigneesLimit) {
        id
        login
        image
        isAnalytics
        karmaStatistics {
          karma
        }
      }
    }
  }
`;

/**
 * __useGetTaskAssigneesQuery__
 *
 * To run a query within a React component, call `useGetTaskAssigneesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskAssigneesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskAssigneesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      assigneesLimit: // value for 'assigneesLimit'
 *   },
 * });
 */
export function useGetTaskAssigneesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTaskAssigneesQuery,
    GetTaskAssigneesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTaskAssigneesQuery, GetTaskAssigneesQueryVariables>(
    GetTaskAssigneesDocument,
    options,
  );
}
export function useGetTaskAssigneesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTaskAssigneesQuery,
    GetTaskAssigneesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTaskAssigneesQuery, GetTaskAssigneesQueryVariables>(
    GetTaskAssigneesDocument,
    options,
  );
}
export type GetTaskAssigneesQueryHookResult = ReturnType<typeof useGetTaskAssigneesQuery>;
export type GetTaskAssigneesLazyQueryHookResult = ReturnType<
  typeof useGetTaskAssigneesLazyQuery
>;
export type GetTaskAssigneesQueryResult = Apollo.QueryResult<
  GetTaskAssigneesQuery,
  GetTaskAssigneesQueryVariables
>;
export const GetTaskChatDocument = gql`
  query getTaskChat($id: Int!, $offset: Int) {
    getTask(id: $id) {
      id
      chat {
        ...chatFragment
      }
    }
  }
  ${ChatFragmentFragmentDoc}
`;

/**
 * __useGetTaskChatQuery__
 *
 * To run a query within a React component, call `useGetTaskChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskChatQuery({
 *   variables: {
 *      id: // value for 'id'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetTaskChatQuery(
  baseOptions: Apollo.QueryHookOptions<GetTaskChatQuery, GetTaskChatQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTaskChatQuery, GetTaskChatQueryVariables>(
    GetTaskChatDocument,
    options,
  );
}
export function useGetTaskChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTaskChatQuery, GetTaskChatQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTaskChatQuery, GetTaskChatQueryVariables>(
    GetTaskChatDocument,
    options,
  );
}
export type GetTaskChatQueryHookResult = ReturnType<typeof useGetTaskChatQuery>;
export type GetTaskChatLazyQueryHookResult = ReturnType<typeof useGetTaskChatLazyQuery>;
export type GetTaskChatQueryResult = Apollo.QueryResult<
  GetTaskChatQuery,
  GetTaskChatQueryVariables
>;
export const GetTaskNotesDocument = gql`
  query getTaskNotes($id: Int!) {
    getTask(id: $id) {
      id
      notes {
        id
        createdAt
        chatId
        taskId
        content
        owner {
          id
          login
          image
          role
          karmaStatistics {
            karma
          }
        }
      }
    }
  }
`;

/**
 * __useGetTaskNotesQuery__
 *
 * To run a query within a React component, call `useGetTaskNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskNotesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskNotesQuery(
  baseOptions: Apollo.QueryHookOptions<GetTaskNotesQuery, GetTaskNotesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTaskNotesQuery, GetTaskNotesQueryVariables>(
    GetTaskNotesDocument,
    options,
  );
}
export function useGetTaskNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTaskNotesQuery,
    GetTaskNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTaskNotesQuery, GetTaskNotesQueryVariables>(
    GetTaskNotesDocument,
    options,
  );
}
export type GetTaskNotesQueryHookResult = ReturnType<typeof useGetTaskNotesQuery>;
export type GetTaskNotesLazyQueryHookResult = ReturnType<typeof useGetTaskNotesLazyQuery>;
export type GetTaskNotesQueryResult = Apollo.QueryResult<
  GetTaskNotesQuery,
  GetTaskNotesQueryVariables
>;
export const GetTasksDocument = gql`
  query getTasks($data: GetTasksInput!) {
    getTasks(data: $data) {
      count
      rows {
        id
        createdAt
        updatedAt
        name
        number
        code
        startDate
        closeDate
        endDate
        project {
          id
          name
          number
        }
        status {
          id
          color
        }
        subtasks(data: { limit: 100 }) {
          id
          createdAt
          updatedAt
          name
          number
          code
          startDate
          closeDate
          endDate
          status {
            id
            color
          }
          maintainer {
            id
            login
            image
          }
          task {
            id
            number
            code
            project {
              id
              name
              number
            }
          }
        }
        maintainer {
          id
          login
          image
        }
        realizationPerMonthAmount
        realizationPrice
      }
    }
  }
`;

/**
 * __useGetTasksQuery__
 *
 * To run a query within a React component, call `useGetTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetTasksQuery(
  baseOptions: Apollo.QueryHookOptions<GetTasksQuery, GetTasksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTasksQuery, GetTasksQueryVariables>(
    GetTasksDocument,
    options,
  );
}
export function useGetTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTasksQuery, GetTasksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTasksQuery, GetTasksQueryVariables>(
    GetTasksDocument,
    options,
  );
}
export type GetTasksQueryHookResult = ReturnType<typeof useGetTasksQuery>;
export type GetTasksLazyQueryHookResult = ReturnType<typeof useGetTasksLazyQuery>;
export type GetTasksQueryResult = Apollo.QueryResult<
  GetTasksQuery,
  GetTasksQueryVariables
>;
export const GetTasksSubtasksDocument = gql`
  query getTasksSubtasks($data: GetTasksInput!) {
    getTasksSubtasks(data: $data) {
      count
      rows {
        id
        createdAt
        updatedAt
        name
        number
        code
        startDate
        closeDate
        endDate
        project {
          id
          name
          number
        }
        status {
          id
          color
        }
        subtasks(data: { limit: 100 }) {
          id
          createdAt
          updatedAt
          name
          number
          code
          startDate
          closeDate
          endDate
          status {
            id
            color
          }
          maintainer {
            id
            login
            image
          }
          task {
            id
            number
            code
            project {
              id
              name
              number
            }
          }
        }
        maintainer {
          id
          login
          image
        }
        realizationPerMonthAmount
        realizationPrice
      }
    }
  }
`;

/**
 * __useGetTasksSubtasksQuery__
 *
 * To run a query within a React component, call `useGetTasksSubtasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksSubtasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksSubtasksQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetTasksSubtasksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTasksSubtasksQuery,
    GetTasksSubtasksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTasksSubtasksQuery, GetTasksSubtasksQueryVariables>(
    GetTasksSubtasksDocument,
    options,
  );
}
export function useGetTasksSubtasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTasksSubtasksQuery,
    GetTasksSubtasksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTasksSubtasksQuery, GetTasksSubtasksQueryVariables>(
    GetTasksSubtasksDocument,
    options,
  );
}
export type GetTasksSubtasksQueryHookResult = ReturnType<typeof useGetTasksSubtasksQuery>;
export type GetTasksSubtasksLazyQueryHookResult = ReturnType<
  typeof useGetTasksSubtasksLazyQuery
>;
export type GetTasksSubtasksQueryResult = Apollo.QueryResult<
  GetTasksSubtasksQuery,
  GetTasksSubtasksQueryVariables
>;
export const GetThreadDocument = gql`
  query getThread($id: Int!) {
    getThread(id: $id) {
      id
      author {
        id
        login
        image
      }
      isSubscriber
      notes {
        id
        threadId
        content
        owner {
          id
          image
        }
      }
      threadBackground {
        id
        backgroundColor
      }
      cover
      statistic {
        performerAcceptedPercent
      }
      scaleMapTaskForCalendar {
        id
      }
      scaleMapTask {
        id
        name
        column
        row
        status {
          id
          name
          color
        }
        children {
          id
          name
          isUrgently
          status {
            id
            name
            color
          }
        }
      }
      chat {
        id
      }
      notesAndCount {
        rows {
          id
        }
      }
      storageFiles {
        rows {
          ... on File {
            id
            fileName
            previewUrl
            mimeType
            path
            size
            task {
              id
              number
              name
              code
            }
            subtask {
              id
              number
              name
              code
              task {
                id
              }
            }
            createdAt
            updatedAt
            mimeType
            userClickedAt
          }
          ... on StorageItem {
            id
            name
            href
            thumbnailPath
            isAccess
            createdAt
            updatedAt
            taskId
            task {
              id
              number
              name
              code
            }
            subtask {
              id
              number
              name
              code
              task {
                id
              }
            }
            subtaskId
            mimeType
            userClickedAt
          }
        }
      }
      storageImages {
        rows {
          ... on File {
            id
            fileName
            previewUrl
            mimeType
            path
            size
            task {
              id
              number
              name
              code
            }
            subtask {
              id
              number
              name
              code
              task {
                id
              }
            }
            threadFileWrap {
              id
              orderNumber
              comment
            }
            createdAt
            updatedAt
            mimeType
            userClickedAt
          }
          ... on StorageItem {
            id
            name
            href
            thumbnailPath
            isAccess
            createdAt
            updatedAt
            taskId
            task {
              id
              number
              name
              code
            }
            subtask {
              id
              number
              name
              code
              task {
                id
              }
            }
            subtaskId
            mimeType
            userClickedAt
          }
        }
      }
      performers {
        threadId
        userId
        status
        user {
          id
          login
          image
          role
          karmaStatistics {
            karma
          }
          threads {
            rows {
              id
              performerStatus
            }
          }
        }
      }
      mainPerformer {
        id
        login
        image
        IsExistUnViewedForum
        role
        fullName
      }
      mainPerformerId
      endDate
      startDate
      closeDate
      isCritical
      tags {
        id
        name
        color
        textColor
      }
      meta {
        id
        name
        column
        row
        status {
          color
          name
        }
        isMain
        isCurrent
        scaleMapFile {
          id
          isPersonal
          author {
            id
          }
          user {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useGetThreadQuery__
 *
 * To run a query within a React component, call `useGetThreadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThreadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThreadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetThreadQuery(
  baseOptions: Apollo.QueryHookOptions<GetThreadQuery, GetThreadQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetThreadQuery, GetThreadQueryVariables>(
    GetThreadDocument,
    options,
  );
}
export function useGetThreadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetThreadQuery, GetThreadQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetThreadQuery, GetThreadQueryVariables>(
    GetThreadDocument,
    options,
  );
}
export type GetThreadQueryHookResult = ReturnType<typeof useGetThreadQuery>;
export type GetThreadLazyQueryHookResult = ReturnType<typeof useGetThreadLazyQuery>;
export type GetThreadQueryResult = Apollo.QueryResult<
  GetThreadQuery,
  GetThreadQueryVariables
>;
export const GetThreadAndCountDocument = gql`
  query getThreadAndCount(
    $id: Int!
    $storageFilter: [storageFilterItems!]!
    $limit: Int
    $offset: Int
    $search: String!
  ) {
    getThread(id: $id) {
      id
      scaleMapTask {
        name
      }
      storageEntities(
        storageFilter: $storageFilter
        limit: $limit
        offset: $offset
        search: $search
      ) {
        rows {
          ... on File {
            id
            fileName
            previewUrl
            mimeType
            path
            size
            createdAt
            updatedAt
            mimeType
          }
          ... on StorageItem {
            id
            name
            href
            thumbnailPath
            isAccess
            createdAt
            updatedAt
            mimeType
          }
        }
        count
      }
    }
  }
`;

/**
 * __useGetThreadAndCountQuery__
 *
 * To run a query within a React component, call `useGetThreadAndCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThreadAndCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThreadAndCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *      storageFilter: // value for 'storageFilter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetThreadAndCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetThreadAndCountQuery,
    GetThreadAndCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetThreadAndCountQuery, GetThreadAndCountQueryVariables>(
    GetThreadAndCountDocument,
    options,
  );
}
export function useGetThreadAndCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetThreadAndCountQuery,
    GetThreadAndCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetThreadAndCountQuery, GetThreadAndCountQueryVariables>(
    GetThreadAndCountDocument,
    options,
  );
}
export type GetThreadAndCountQueryHookResult = ReturnType<
  typeof useGetThreadAndCountQuery
>;
export type GetThreadAndCountLazyQueryHookResult = ReturnType<
  typeof useGetThreadAndCountLazyQuery
>;
export type GetThreadAndCountQueryResult = Apollo.QueryResult<
  GetThreadAndCountQuery,
  GetThreadAndCountQueryVariables
>;
export const GetThreadFileDocument = gql`
  query getThreadFile($id: Int!) {
    getThread(id: $id) {
      storageFiles {
        rows {
          ... on File {
            id
            fileName
            previewUrl
            mimeType
            path
            size
            task {
              id
              number
              name
              code
            }
            subtask {
              id
              number
              name
              code
              task {
                id
              }
            }
            createdAt
            updatedAt
            mimeType
            userClickedAt
          }
          ... on StorageItem {
            id
            name
            href
            thumbnailPath
            isAccess
            createdAt
            updatedAt
            taskId
            task {
              id
              number
              name
              code
            }
            subtask {
              id
              number
              name
              code
              task {
                id
              }
            }
            subtaskId
            mimeType
            userClickedAt
          }
        }
      }
      storageImages {
        rows {
          ... on File {
            id
            fileName
            previewUrl
            mimeType
            path
            size
            task {
              id
              number
              name
              code
            }
            subtask {
              id
              number
              name
              code
              task {
                id
              }
            }
            threadFileWrap {
              id
              orderNumber
              comment
            }
            createdAt
            updatedAt
            mimeType
            userClickedAt
          }
          ... on StorageItem {
            id
            name
            href
            thumbnailPath
            isAccess
            createdAt
            updatedAt
            taskId
            task {
              id
              number
              name
              code
            }
            subtask {
              id
              number
              name
              code
              task {
                id
              }
            }
            subtaskId
            mimeType
            userClickedAt
          }
        }
      }
    }
  }
`;

/**
 * __useGetThreadFileQuery__
 *
 * To run a query within a React component, call `useGetThreadFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThreadFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThreadFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetThreadFileQuery(
  baseOptions: Apollo.QueryHookOptions<GetThreadFileQuery, GetThreadFileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetThreadFileQuery, GetThreadFileQueryVariables>(
    GetThreadFileDocument,
    options,
  );
}
export function useGetThreadFileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetThreadFileQuery,
    GetThreadFileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetThreadFileQuery, GetThreadFileQueryVariables>(
    GetThreadFileDocument,
    options,
  );
}
export type GetThreadFileQueryHookResult = ReturnType<typeof useGetThreadFileQuery>;
export type GetThreadFileLazyQueryHookResult = ReturnType<
  typeof useGetThreadFileLazyQuery
>;
export type GetThreadFileQueryResult = Apollo.QueryResult<
  GetThreadFileQuery,
  GetThreadFileQueryVariables
>;
export const GetThreadPerformersDocument = gql`
  query getThreadPerformers($id: Int!) {
    getThread(id: $id) {
      performers {
        threadId
        userId
        status
        user {
          id
          login
          image
          role
          threads {
            rows {
              id
              performerStatus
            }
          }
        }
      }
      mainPerformer {
        id
        login
        image
        IsExistUnViewedForum
        role
        fullName
      }
      mainPerformerId
    }
  }
`;

/**
 * __useGetThreadPerformersQuery__
 *
 * To run a query within a React component, call `useGetThreadPerformersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThreadPerformersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThreadPerformersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetThreadPerformersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetThreadPerformersQuery,
    GetThreadPerformersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetThreadPerformersQuery, GetThreadPerformersQueryVariables>(
    GetThreadPerformersDocument,
    options,
  );
}
export function useGetThreadPerformersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetThreadPerformersQuery,
    GetThreadPerformersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetThreadPerformersQuery, GetThreadPerformersQueryVariables>(
    GetThreadPerformersDocument,
    options,
  );
}
export type GetThreadPerformersQueryHookResult = ReturnType<
  typeof useGetThreadPerformersQuery
>;
export type GetThreadPerformersLazyQueryHookResult = ReturnType<
  typeof useGetThreadPerformersLazyQuery
>;
export type GetThreadPerformersQueryResult = Apollo.QueryResult<
  GetThreadPerformersQuery,
  GetThreadPerformersQueryVariables
>;
export const GetThreadStorageProjectDocument = gql`
  query getThreadStorageProject($data: GetThreadStorageInput!) {
    getThreadStorageProject(data: $data) {
      name
      storageEntities {
        count
        rows {
          ... on File {
            id
            fileName
            previewUrl
            mimeType
            path
            size
            thread {
              id
              scaleMapTask {
                name
              }
            }
            createdAt
            updatedAt
            mimeType
          }
          ... on StorageItem {
            id
            name
            href
            thumbnailPath
            isAccess
            createdAt
            updatedAt
            thread {
              id
              scaleMapTask {
                name
              }
            }
            subtaskId
            mimeType
          }
        }
      }
    }
  }
`;

/**
 * __useGetThreadStorageProjectQuery__
 *
 * To run a query within a React component, call `useGetThreadStorageProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThreadStorageProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThreadStorageProjectQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetThreadStorageProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetThreadStorageProjectQuery,
    GetThreadStorageProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetThreadStorageProjectQuery,
    GetThreadStorageProjectQueryVariables
  >(GetThreadStorageProjectDocument, options);
}
export function useGetThreadStorageProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetThreadStorageProjectQuery,
    GetThreadStorageProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetThreadStorageProjectQuery,
    GetThreadStorageProjectQueryVariables
  >(GetThreadStorageProjectDocument, options);
}
export type GetThreadStorageProjectQueryHookResult = ReturnType<
  typeof useGetThreadStorageProjectQuery
>;
export type GetThreadStorageProjectLazyQueryHookResult = ReturnType<
  typeof useGetThreadStorageProjectLazyQuery
>;
export type GetThreadStorageProjectQueryResult = Apollo.QueryResult<
  GetThreadStorageProjectQuery,
  GetThreadStorageProjectQueryVariables
>;
export const GetThreadViewHistoryDocument = gql`
  query getThreadViewHistory(
    $limit: Int
    $offset: Int
    $sortOrder: SortingOrders
    $tagsId: [ID!]
  ) {
    getThreadViewHistory(
      limit: $limit
      offset: $offset
      sortOrder: $sortOrder
      tagsId: $tagsId
    ) {
      id
      viewedAt
      thread {
        id
        createdAt
        tags {
          id
          name
          color
          textColor
        }
        meta {
          id
          name
          isCurrent
          status {
            id
            name
            color
          }
        }
        mainPerformer {
          image
          login
          id
          karmaStatistics {
            karma
          }
        }
        userLastEdited {
          image
          login
          id
          karmaStatistics {
            karma
          }
        }
        performers {
          userId
        }
      }
    }
  }
`;

/**
 * __useGetThreadViewHistoryQuery__
 *
 * To run a query within a React component, call `useGetThreadViewHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThreadViewHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThreadViewHistoryQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sortOrder: // value for 'sortOrder'
 *      tagsId: // value for 'tagsId'
 *   },
 * });
 */
export function useGetThreadViewHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetThreadViewHistoryQuery,
    GetThreadViewHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetThreadViewHistoryQuery, GetThreadViewHistoryQueryVariables>(
    GetThreadViewHistoryDocument,
    options,
  );
}
export function useGetThreadViewHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetThreadViewHistoryQuery,
    GetThreadViewHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetThreadViewHistoryQuery,
    GetThreadViewHistoryQueryVariables
  >(GetThreadViewHistoryDocument, options);
}
export type GetThreadViewHistoryQueryHookResult = ReturnType<
  typeof useGetThreadViewHistoryQuery
>;
export type GetThreadViewHistoryLazyQueryHookResult = ReturnType<
  typeof useGetThreadViewHistoryLazyQuery
>;
export type GetThreadViewHistoryQueryResult = Apollo.QueryResult<
  GetThreadViewHistoryQuery,
  GetThreadViewHistoryQueryVariables
>;
export const GetThreadsAndCountDocument = gql`
  query getThreadsAndCount(
    $data: GetThreadAndCountInput!
    $storageFilter: [storageFilterItems!]!
    $limit: Int
    $offset: Int
    $search: String!
  ) {
    getThreadsAndCount(data: $data) {
      rows {
        id
        scaleMapTask {
          name
        }
        storageEntities(
          storageFilter: $storageFilter
          limit: $limit
          offset: $offset
          search: $search
        ) {
          rows {
            ... on File {
              id
              fileName
              previewUrl
              mimeType
              path
              size
              createdAt
              updatedAt
              mimeType
            }
            ... on StorageItem {
              id
              name
              href
              thumbnailPath
              isAccess
              createdAt
              updatedAt
              mimeType
            }
          }
          count
        }
      }
      count
    }
  }
`;

/**
 * __useGetThreadsAndCountQuery__
 *
 * To run a query within a React component, call `useGetThreadsAndCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThreadsAndCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThreadsAndCountQuery({
 *   variables: {
 *      data: // value for 'data'
 *      storageFilter: // value for 'storageFilter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetThreadsAndCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetThreadsAndCountQuery,
    GetThreadsAndCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetThreadsAndCountQuery, GetThreadsAndCountQueryVariables>(
    GetThreadsAndCountDocument,
    options,
  );
}
export function useGetThreadsAndCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetThreadsAndCountQuery,
    GetThreadsAndCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetThreadsAndCountQuery, GetThreadsAndCountQueryVariables>(
    GetThreadsAndCountDocument,
    options,
  );
}
export type GetThreadsAndCountQueryHookResult = ReturnType<
  typeof useGetThreadsAndCountQuery
>;
export type GetThreadsAndCountLazyQueryHookResult = ReturnType<
  typeof useGetThreadsAndCountLazyQuery
>;
export type GetThreadsAndCountQueryResult = Apollo.QueryResult<
  GetThreadsAndCountQuery,
  GetThreadsAndCountQueryVariables
>;
export const GetThreadsAndCountByUserDocument = gql`
  query getThreadsAndCountByUser($data: GetThreadAndCountInput) {
    getThreadsAndCountByUser(data: $data) {
      rows {
        id
        createdAt
        updatedAt
        authorId
        cover
        author {
          id
          login
          image
        }
        notes {
          id
        }
        threadBackground {
          id
          backgroundColor
        }
        statistic {
          performerAcceptedPercent
        }
        scaleMapTask {
          id
          name
          status {
            id
            name
            color
          }
          children {
            id
            name
            status {
              color
            }
          }
        }
        performers {
          threadId
          userId
          status
          user {
            id
            login
            image
            IsExistUnViewedForum
            role
            fullName
          }
        }
        mainPerformer {
          id
          login
          image
          IsExistUnViewedForum
          role
          fullName
        }
        mainPerformerId
        tags {
          id
          name
          color
          textColor
        }
        performerStatus
        meta {
          id
          name
          isMain
          isCurrent
          status {
            color
          }
        }
        userLastEdited {
          id
          login
          tags {
            rows {
              id
              name
              color
              textColor
            }
          }
        }
      }
      count
    }
  }
`;

/**
 * __useGetThreadsAndCountByUserQuery__
 *
 * To run a query within a React component, call `useGetThreadsAndCountByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThreadsAndCountByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThreadsAndCountByUserQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetThreadsAndCountByUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetThreadsAndCountByUserQuery,
    GetThreadsAndCountByUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetThreadsAndCountByUserQuery,
    GetThreadsAndCountByUserQueryVariables
  >(GetThreadsAndCountByUserDocument, options);
}
export function useGetThreadsAndCountByUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetThreadsAndCountByUserQuery,
    GetThreadsAndCountByUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetThreadsAndCountByUserQuery,
    GetThreadsAndCountByUserQueryVariables
  >(GetThreadsAndCountByUserDocument, options);
}
export type GetThreadsAndCountByUserQueryHookResult = ReturnType<
  typeof useGetThreadsAndCountByUserQuery
>;
export type GetThreadsAndCountByUserLazyQueryHookResult = ReturnType<
  typeof useGetThreadsAndCountByUserLazyQuery
>;
export type GetThreadsAndCountByUserQueryResult = Apollo.QueryResult<
  GetThreadsAndCountByUserQuery,
  GetThreadsAndCountByUserQueryVariables
>;
export const GetThreadsAndCountByUserEditDocument = gql`
  query getThreadsAndCountByUserEdit($data: GetThreadAndCountInput) {
    getThreadsAndCountByUser(data: $data) {
      rows {
        id
        cover
        scaleMapTask {
          id
          name
          children {
            id
            name
            status {
              color
            }
          }
        }
        threadBackground {
          id
          backgroundColor
        }
        performers {
          threadId
          userId
          status
          user {
            id
            login
            image
            IsExistUnViewedForum
            role
            fullName
          }
        }
        tags {
          id
          name
          color
          textColor
        }
      }
      count
    }
  }
`;

/**
 * __useGetThreadsAndCountByUserEditQuery__
 *
 * To run a query within a React component, call `useGetThreadsAndCountByUserEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThreadsAndCountByUserEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThreadsAndCountByUserEditQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetThreadsAndCountByUserEditQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetThreadsAndCountByUserEditQuery,
    GetThreadsAndCountByUserEditQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetThreadsAndCountByUserEditQuery,
    GetThreadsAndCountByUserEditQueryVariables
  >(GetThreadsAndCountByUserEditDocument, options);
}
export function useGetThreadsAndCountByUserEditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetThreadsAndCountByUserEditQuery,
    GetThreadsAndCountByUserEditQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetThreadsAndCountByUserEditQuery,
    GetThreadsAndCountByUserEditQueryVariables
  >(GetThreadsAndCountByUserEditDocument, options);
}
export type GetThreadsAndCountByUserEditQueryHookResult = ReturnType<
  typeof useGetThreadsAndCountByUserEditQuery
>;
export type GetThreadsAndCountByUserEditLazyQueryHookResult = ReturnType<
  typeof useGetThreadsAndCountByUserEditLazyQuery
>;
export type GetThreadsAndCountByUserEditQueryResult = Apollo.QueryResult<
  GetThreadsAndCountByUserEditQuery,
  GetThreadsAndCountByUserEditQueryVariables
>;
export const GetTredChatDocument = gql`
  query getTredChat($id: Int!, $offset: Int) {
    getThread(id: $id) {
      id
      chat {
        ...chatFragment
      }
    }
  }
  ${ChatFragmentFragmentDoc}
`;

/**
 * __useGetTredChatQuery__
 *
 * To run a query within a React component, call `useGetTredChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTredChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTredChatQuery({
 *   variables: {
 *      id: // value for 'id'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetTredChatQuery(
  baseOptions: Apollo.QueryHookOptions<GetTredChatQuery, GetTredChatQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTredChatQuery, GetTredChatQueryVariables>(
    GetTredChatDocument,
    options,
  );
}
export function useGetTredChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTredChatQuery, GetTredChatQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTredChatQuery, GetTredChatQueryVariables>(
    GetTredChatDocument,
    options,
  );
}
export type GetTredChatQueryHookResult = ReturnType<typeof useGetTredChatQuery>;
export type GetTredChatLazyQueryHookResult = ReturnType<typeof useGetTredChatLazyQuery>;
export type GetTredChatQueryResult = Apollo.QueryResult<
  GetTredChatQuery,
  GetTredChatQueryVariables
>;
export const GetTredChatReadDocument = gql`
  query getTredChatRead($id: Int!) {
    getThread(id: $id) {
      id
      chat {
        isReadActivities
        isReadMessages
      }
    }
  }
`;

/**
 * __useGetTredChatReadQuery__
 *
 * To run a query within a React component, call `useGetTredChatReadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTredChatReadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTredChatReadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTredChatReadQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTredChatReadQuery,
    GetTredChatReadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTredChatReadQuery, GetTredChatReadQueryVariables>(
    GetTredChatReadDocument,
    options,
  );
}
export function useGetTredChatReadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTredChatReadQuery,
    GetTredChatReadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTredChatReadQuery, GetTredChatReadQueryVariables>(
    GetTredChatReadDocument,
    options,
  );
}
export type GetTredChatReadQueryHookResult = ReturnType<typeof useGetTredChatReadQuery>;
export type GetTredChatReadLazyQueryHookResult = ReturnType<
  typeof useGetTredChatReadLazyQuery
>;
export type GetTredChatReadQueryResult = Apollo.QueryResult<
  GetTredChatReadQuery,
  GetTredChatReadQueryVariables
>;
export const GetTredsNotesDocument = gql`
  query getTredsNotes($id: Int!) {
    getThread(id: $id) {
      id
      notes {
        id
        createdAt
        chatId
        taskId
        content
        owner {
          id
          login
          image
          role
          karmaStatistics {
            karma
          }
        }
      }
    }
  }
`;

/**
 * __useGetTredsNotesQuery__
 *
 * To run a query within a React component, call `useGetTredsNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTredsNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTredsNotesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTredsNotesQuery(
  baseOptions: Apollo.QueryHookOptions<GetTredsNotesQuery, GetTredsNotesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTredsNotesQuery, GetTredsNotesQueryVariables>(
    GetTredsNotesDocument,
    options,
  );
}
export function useGetTredsNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTredsNotesQuery,
    GetTredsNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTredsNotesQuery, GetTredsNotesQueryVariables>(
    GetTredsNotesDocument,
    options,
  );
}
export type GetTredsNotesQueryHookResult = ReturnType<typeof useGetTredsNotesQuery>;
export type GetTredsNotesLazyQueryHookResult = ReturnType<
  typeof useGetTredsNotesLazyQuery
>;
export type GetTredsNotesQueryResult = Apollo.QueryResult<
  GetTredsNotesQuery,
  GetTredsNotesQueryVariables
>;
export const GetUserAnalyticDocument = gql`
  query getUserAnalytic($userId: Int!) {
    getUserAnalytic(userId: $userId) {
      id
      fullName
      login
      onlineStatus
      image
      isAnalytics
      karmaStatistics {
        karma
      }
      analyticThreads {
        deadlineDate
        deadlineThrough
        countThreadsInCalendar
        daysInactiveInThreadPanel
        userThreadCount
        totalCreatedThreads
        daysInactiveInThreadPanel
        threadsLastEdited {
          id
          scaleMapTask {
            name
            status {
              color
            }
          }
        }
      }
      analytic {
        completed
        progress
        deadlineDate
        deadlineThrough
      }
      projects {
        id
        name
        tasksSubtasks(data: { limit: 100, offset: 0 }) {
          rows {
            id
          }
        }
      }
      userCharacteristics(data: { limit: 5, offset: 0 }) {
        rows {
          id
          text
          color
        }
        count
      }
      tags {
        count
        rows {
          id
          name
          color
          textColor
        }
      }
    }
  }
`;

/**
 * __useGetUserAnalyticQuery__
 *
 * To run a query within a React component, call `useGetUserAnalyticQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAnalyticQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAnalyticQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserAnalyticQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserAnalyticQuery,
    GetUserAnalyticQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserAnalyticQuery, GetUserAnalyticQueryVariables>(
    GetUserAnalyticDocument,
    options,
  );
}
export function useGetUserAnalyticLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserAnalyticQuery,
    GetUserAnalyticQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserAnalyticQuery, GetUserAnalyticQueryVariables>(
    GetUserAnalyticDocument,
    options,
  );
}
export type GetUserAnalyticQueryHookResult = ReturnType<typeof useGetUserAnalyticQuery>;
export type GetUserAnalyticLazyQueryHookResult = ReturnType<
  typeof useGetUserAnalyticLazyQuery
>;
export type GetUserAnalyticQueryResult = Apollo.QueryResult<
  GetUserAnalyticQuery,
  GetUserAnalyticQueryVariables
>;
export const GetUserAnalyticScaleDocument = gql`
  query getUserAnalyticScale($userId: Int!) {
    getUserAnalytic(userId: $userId) {
      id
      fullName
      login
      onlineStatus
      image
      isAnalytics
      analytic {
        completed
        progress
        deadlineDate
        deadlineThrough
      }
      analyticThreads {
        deadlineDate
        deadlineThrough
        daysInactiveInThreadPanel
        countThreadsInCalendar
        daysInactiveInThreadPanel
        userThreadCount
        totalCreatedThreads
        threadsLastEdited {
          id
          scaleMapTask {
            name
            status {
              color
            }
          }
        }
      }
      scaleMapFiles {
        count
        rows {
          id
          createdAt
          updatedAt
          name
          project {
            id
          }
          statistics {
            tasksCount
            completedTasksPercent
            inProgressTasksPercent
            problemTasksCount
            notInProgressTasksPercent
            problemTasksPercent
            isTasksCreatedToday
            isTasksUpdatedToday
          }
        }
      }
      projects {
        id
        name
        tasksSubtasks(data: { limit: 100, offset: 0 }) {
          rows {
            id
          }
        }
      }
      userCharacteristics(data: { limit: 5, offset: 0 }) {
        rows {
          id
          text
          color
        }
        count
      }
    }
  }
`;

/**
 * __useGetUserAnalyticScaleQuery__
 *
 * To run a query within a React component, call `useGetUserAnalyticScaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAnalyticScaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAnalyticScaleQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserAnalyticScaleQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserAnalyticScaleQuery,
    GetUserAnalyticScaleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserAnalyticScaleQuery, GetUserAnalyticScaleQueryVariables>(
    GetUserAnalyticScaleDocument,
    options,
  );
}
export function useGetUserAnalyticScaleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserAnalyticScaleQuery,
    GetUserAnalyticScaleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserAnalyticScaleQuery,
    GetUserAnalyticScaleQueryVariables
  >(GetUserAnalyticScaleDocument, options);
}
export type GetUserAnalyticScaleQueryHookResult = ReturnType<
  typeof useGetUserAnalyticScaleQuery
>;
export type GetUserAnalyticScaleLazyQueryHookResult = ReturnType<
  typeof useGetUserAnalyticScaleLazyQuery
>;
export type GetUserAnalyticScaleQueryResult = Apollo.QueryResult<
  GetUserAnalyticScaleQuery,
  GetUserAnalyticScaleQueryVariables
>;
export const GetUserCharacteristicsDocument = gql`
  query getUserCharacteristics($userId: Int!, $data: GetUserCharacteristicsInput) {
    getUserCharacteristics(userId: $userId, data: $data) {
      count
      rows {
        id
        text
        color
      }
    }
  }
`;

/**
 * __useGetUserCharacteristicsQuery__
 *
 * To run a query within a React component, call `useGetUserCharacteristicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCharacteristicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCharacteristicsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetUserCharacteristicsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserCharacteristicsQuery,
    GetUserCharacteristicsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserCharacteristicsQuery,
    GetUserCharacteristicsQueryVariables
  >(GetUserCharacteristicsDocument, options);
}
export function useGetUserCharacteristicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserCharacteristicsQuery,
    GetUserCharacteristicsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserCharacteristicsQuery,
    GetUserCharacteristicsQueryVariables
  >(GetUserCharacteristicsDocument, options);
}
export type GetUserCharacteristicsQueryHookResult = ReturnType<
  typeof useGetUserCharacteristicsQuery
>;
export type GetUserCharacteristicsLazyQueryHookResult = ReturnType<
  typeof useGetUserCharacteristicsLazyQuery
>;
export type GetUserCharacteristicsQueryResult = Apollo.QueryResult<
  GetUserCharacteristicsQuery,
  GetUserCharacteristicsQueryVariables
>;
export const GetUserDecideOnFlyActionsHistoryDocument = gql`
  query getUserDecideOnFlyActionsHistory($from: DateTime, $to: DateTime) {
    getUserDecideOnFlyActionsHistory(from: $from, to: $to) {
      rows {
        id
        decideOnFlyActionId
        createdAt
        startDate
        endDate
        status
        reasonForNotDone
        decideOnFlyAction {
          id
          name
          chancePercent
          decideOnFlyPeriodId
        }
      }
      count
      doneCount
      notDoneCount
    }
  }
`;

/**
 * __useGetUserDecideOnFlyActionsHistoryQuery__
 *
 * To run a query within a React component, call `useGetUserDecideOnFlyActionsHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDecideOnFlyActionsHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDecideOnFlyActionsHistoryQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetUserDecideOnFlyActionsHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserDecideOnFlyActionsHistoryQuery,
    GetUserDecideOnFlyActionsHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserDecideOnFlyActionsHistoryQuery,
    GetUserDecideOnFlyActionsHistoryQueryVariables
  >(GetUserDecideOnFlyActionsHistoryDocument, options);
}
export function useGetUserDecideOnFlyActionsHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserDecideOnFlyActionsHistoryQuery,
    GetUserDecideOnFlyActionsHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserDecideOnFlyActionsHistoryQuery,
    GetUserDecideOnFlyActionsHistoryQueryVariables
  >(GetUserDecideOnFlyActionsHistoryDocument, options);
}
export type GetUserDecideOnFlyActionsHistoryQueryHookResult = ReturnType<
  typeof useGetUserDecideOnFlyActionsHistoryQuery
>;
export type GetUserDecideOnFlyActionsHistoryLazyQueryHookResult = ReturnType<
  typeof useGetUserDecideOnFlyActionsHistoryLazyQuery
>;
export type GetUserDecideOnFlyActionsHistoryQueryResult = Apollo.QueryResult<
  GetUserDecideOnFlyActionsHistoryQuery,
  GetUserDecideOnFlyActionsHistoryQueryVariables
>;
export const GetUsersAnalyticDocument = gql`
  query getUsersAnalytic {
    getUsersAnalytic {
      count
      rows {
        id
        fullName
        login
        onlineStatus
        image
        isAnalytics
        karmaStatistics {
          karma
        }
        analytic {
          completed
          progress
          deadlineDate
          deadlineThrough
        }
        analyticThreads {
          deadlineDate
          deadlineThrough
          daysInactiveInThreadPanel
          countThreadsInCalendar
          userThreadCount
          daysInactiveInThreadPanel
          totalCreatedThreads
          threadsLastEdited {
            id
            scaleMapTask {
              name
              status {
                color
              }
            }
          }
        }
        userCharacteristics(data: { limit: 5, offset: 0 }) {
          rows {
            id
            text
            color
          }
          count
        }
        tags {
          count
          rows {
            id
            name
            color
            textColor
          }
        }
      }
    }
  }
`;

/**
 * __useGetUsersAnalyticQuery__
 *
 * To run a query within a React component, call `useGetUsersAnalyticQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersAnalyticQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersAnalyticQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersAnalyticQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUsersAnalyticQuery,
    GetUsersAnalyticQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersAnalyticQuery, GetUsersAnalyticQueryVariables>(
    GetUsersAnalyticDocument,
    options,
  );
}
export function useGetUsersAnalyticLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersAnalyticQuery,
    GetUsersAnalyticQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersAnalyticQuery, GetUsersAnalyticQueryVariables>(
    GetUsersAnalyticDocument,
    options,
  );
}
export type GetUsersAnalyticQueryHookResult = ReturnType<typeof useGetUsersAnalyticQuery>;
export type GetUsersAnalyticLazyQueryHookResult = ReturnType<
  typeof useGetUsersAnalyticLazyQuery
>;
export type GetUsersAnalyticQueryResult = Apollo.QueryResult<
  GetUsersAnalyticQuery,
  GetUsersAnalyticQueryVariables
>;
export const GetUsersThreadsAnalyticDocument = gql`
  query getUsersThreadsAnalytic {
    getUsersThreadsAnalytic {
      count
      rows {
        id
        fullName
        login
        onlineStatus
        image
        isAnalytics
        karmaStatistics {
          karma
        }
        analyticThreads {
          deadlineDate
          deadlineThrough
          daysInactiveInThreadPanel
          countThreadsInCalendar
          userThreadCount
          daysInactiveInThreadPanel
          totalCreatedThreads
          threadsLastEdited {
            id
            scaleMapTask {
              name
              status {
                color
              }
            }
          }
        }
        userCharacteristics(data: { limit: 5, offset: 0 }) {
          rows {
            id
            text
            color
          }
          count
        }
        tags {
          count
          rows {
            id
            name
            color
            textColor
          }
        }
      }
    }
  }
`;

/**
 * __useGetUsersThreadsAnalyticQuery__
 *
 * To run a query within a React component, call `useGetUsersThreadsAnalyticQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersThreadsAnalyticQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersThreadsAnalyticQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersThreadsAnalyticQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUsersThreadsAnalyticQuery,
    GetUsersThreadsAnalyticQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUsersThreadsAnalyticQuery,
    GetUsersThreadsAnalyticQueryVariables
  >(GetUsersThreadsAnalyticDocument, options);
}
export function useGetUsersThreadsAnalyticLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersThreadsAnalyticQuery,
    GetUsersThreadsAnalyticQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUsersThreadsAnalyticQuery,
    GetUsersThreadsAnalyticQueryVariables
  >(GetUsersThreadsAnalyticDocument, options);
}
export type GetUsersThreadsAnalyticQueryHookResult = ReturnType<
  typeof useGetUsersThreadsAnalyticQuery
>;
export type GetUsersThreadsAnalyticLazyQueryHookResult = ReturnType<
  typeof useGetUsersThreadsAnalyticLazyQuery
>;
export type GetUsersThreadsAnalyticQueryResult = Apollo.QueryResult<
  GetUsersThreadsAnalyticQuery,
  GetUsersThreadsAnalyticQueryVariables
>;
export const LoginUserDocument = gql`
  query loginUser($data: LoginUserInput!) {
    loginUser(data: $data) {
      user {
        login
      }
      token
    }
  }
`;

/**
 * __useLoginUserQuery__
 *
 * To run a query within a React component, call `useLoginUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginUserQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginUserQuery(
  baseOptions: Apollo.QueryHookOptions<LoginUserQuery, LoginUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoginUserQuery, LoginUserQueryVariables>(
    LoginUserDocument,
    options,
  );
}
export function useLoginUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LoginUserQuery, LoginUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoginUserQuery, LoginUserQueryVariables>(
    LoginUserDocument,
    options,
  );
}
export type LoginUserQueryHookResult = ReturnType<typeof useLoginUserQuery>;
export type LoginUserLazyQueryHookResult = ReturnType<typeof useLoginUserLazyQuery>;
export type LoginUserQueryResult = Apollo.QueryResult<
  LoginUserQuery,
  LoginUserQueryVariables
>;
export const PulseMonitorDocument = gql`
  query pulseMonitor($year: Int!) {
    pulseMonitor(year: $year) {
      rows {
        scaleMapFile {
          id
          name
        }
        statisticByMonth {
          monthNumber
          percent
        }
      }
    }
  }
`;

/**
 * __usePulseMonitorQuery__
 *
 * To run a query within a React component, call `usePulseMonitorQuery` and pass it any options that fit your needs.
 * When your component renders, `usePulseMonitorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePulseMonitorQuery({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function usePulseMonitorQuery(
  baseOptions: Apollo.QueryHookOptions<PulseMonitorQuery, PulseMonitorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PulseMonitorQuery, PulseMonitorQueryVariables>(
    PulseMonitorDocument,
    options,
  );
}
export function usePulseMonitorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PulseMonitorQuery,
    PulseMonitorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PulseMonitorQuery, PulseMonitorQueryVariables>(
    PulseMonitorDocument,
    options,
  );
}
export type PulseMonitorQueryHookResult = ReturnType<typeof usePulseMonitorQuery>;
export type PulseMonitorLazyQueryHookResult = ReturnType<typeof usePulseMonitorLazyQuery>;
export type PulseMonitorQueryResult = Apollo.QueryResult<
  PulseMonitorQuery,
  PulseMonitorQueryVariables
>;
export const SearchDocument = gql`
  query search($search: String!) {
    search(search: $search) {
      storageEntities {
        ... on File {
          id
          fileName
          threadId
        }
        ... on StorageItem {
          id
          name
          taskId
          subtaskId
        }
      }
      forum {
        ... on ForumThread {
          id
          name
          forumSectionId
        }
        ... on ForumSection {
          id
          name
        }
      }
      threads {
        id
        scaleMapTask {
          name
        }
      }
    }
  }
`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchQuery(
  baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
}
export function useSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
}
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;
export const ThreadBackgroundsDocument = gql`
  query threadBackgrounds {
    threadBackgrounds {
      id
      backgroundColor
    }
  }
`;

/**
 * __useThreadBackgroundsQuery__
 *
 * To run a query within a React component, call `useThreadBackgroundsQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreadBackgroundsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreadBackgroundsQuery({
 *   variables: {
 *   },
 * });
 */
export function useThreadBackgroundsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ThreadBackgroundsQuery,
    ThreadBackgroundsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ThreadBackgroundsQuery, ThreadBackgroundsQueryVariables>(
    ThreadBackgroundsDocument,
    options,
  );
}
export function useThreadBackgroundsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ThreadBackgroundsQuery,
    ThreadBackgroundsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ThreadBackgroundsQuery, ThreadBackgroundsQueryVariables>(
    ThreadBackgroundsDocument,
    options,
  );
}
export type ThreadBackgroundsQueryHookResult = ReturnType<
  typeof useThreadBackgroundsQuery
>;
export type ThreadBackgroundsLazyQueryHookResult = ReturnType<
  typeof useThreadBackgroundsLazyQuery
>;
export type ThreadBackgroundsQueryResult = Apollo.QueryResult<
  ThreadBackgroundsQuery,
  ThreadBackgroundsQueryVariables
>;
export const UserGroupsDocument = gql`
  query userGroups {
    userGroups {
      id
      name
      users {
        id
        login
        image
        IsExistUnViewedForum
        role
        fullName
        karmaStatistics {
          karma
        }
      }
    }
  }
`;

/**
 * __useUserGroupsQuery__
 *
 * To run a query within a React component, call `useUserGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<UserGroupsQuery, UserGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserGroupsQuery, UserGroupsQueryVariables>(
    UserGroupsDocument,
    options,
  );
}
export function useUserGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserGroupsQuery, UserGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserGroupsQuery, UserGroupsQueryVariables>(
    UserGroupsDocument,
    options,
  );
}
export type UserGroupsQueryHookResult = ReturnType<typeof useUserGroupsQuery>;
export type UserGroupsLazyQueryHookResult = ReturnType<typeof useUserGroupsLazyQuery>;
export type UserGroupsQueryResult = Apollo.QueryResult<
  UserGroupsQuery,
  UserGroupsQueryVariables
>;
export const ActivityInChatDocument = gql`
  subscription activityInChat($chatId: Int!) {
    activityInChat(chatId: $chatId) {
      status
      chat {
        isReadActivities
        isReadMessages
      }
    }
  }
`;

/**
 * __useActivityInChatSubscription__
 *
 * To run a query within a React component, call `useActivityInChatSubscription` and pass it any options that fit your needs.
 * When your component renders, `useActivityInChatSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityInChatSubscription({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useActivityInChatSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ActivityInChatSubscription,
    ActivityInChatSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ActivityInChatSubscription,
    ActivityInChatSubscriptionVariables
  >(ActivityInChatDocument, options);
}
export type ActivityInChatSubscriptionHookResult = ReturnType<
  typeof useActivityInChatSubscription
>;
export type ActivityInChatSubscriptionResult =
  Apollo.SubscriptionResult<ActivityInChatSubscription>;
export const AddedNewItemToThreadStorageDocument = gql`
  subscription addedNewItemToThreadStorage($threadId: Int!) {
    addedNewItemToThreadStorage(threadId: $threadId) {
      threadId
      status
    }
  }
`;

/**
 * __useAddedNewItemToThreadStorageSubscription__
 *
 * To run a query within a React component, call `useAddedNewItemToThreadStorageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAddedNewItemToThreadStorageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddedNewItemToThreadStorageSubscription({
 *   variables: {
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useAddedNewItemToThreadStorageSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    AddedNewItemToThreadStorageSubscription,
    AddedNewItemToThreadStorageSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    AddedNewItemToThreadStorageSubscription,
    AddedNewItemToThreadStorageSubscriptionVariables
  >(AddedNewItemToThreadStorageDocument, options);
}
export type AddedNewItemToThreadStorageSubscriptionHookResult = ReturnType<
  typeof useAddedNewItemToThreadStorageSubscription
>;
export type AddedNewItemToThreadStorageSubscriptionResult =
  Apollo.SubscriptionResult<AddedNewItemToThreadStorageSubscription>;
export const DeletedMessageDocument = gql`
  subscription deletedMessage($chatId: Int!) {
    deletedMessage(chatId: $chatId) {
      messageId
      chatId
    }
  }
`;

/**
 * __useDeletedMessageSubscription__
 *
 * To run a query within a React component, call `useDeletedMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeletedMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedMessageSubscription({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useDeletedMessageSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    DeletedMessageSubscription,
    DeletedMessageSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    DeletedMessageSubscription,
    DeletedMessageSubscriptionVariables
  >(DeletedMessageDocument, options);
}
export type DeletedMessageSubscriptionHookResult = ReturnType<
  typeof useDeletedMessageSubscription
>;
export type DeletedMessageSubscriptionResult =
  Apollo.SubscriptionResult<DeletedMessageSubscription>;
export const MessagesReadBeforeDocument = gql`
  subscription messagesReadBefore($chatId: Int!) {
    messagesReadBefore(chatId: $chatId) {
      messageIds
      user {
        id
      }
    }
  }
`;

/**
 * __useMessagesReadBeforeSubscription__
 *
 * To run a query within a React component, call `useMessagesReadBeforeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMessagesReadBeforeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesReadBeforeSubscription({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useMessagesReadBeforeSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    MessagesReadBeforeSubscription,
    MessagesReadBeforeSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    MessagesReadBeforeSubscription,
    MessagesReadBeforeSubscriptionVariables
  >(MessagesReadBeforeDocument, options);
}
export type MessagesReadBeforeSubscriptionHookResult = ReturnType<
  typeof useMessagesReadBeforeSubscription
>;
export type MessagesReadBeforeSubscriptionResult =
  Apollo.SubscriptionResult<MessagesReadBeforeSubscription>;
export const NewActivityDocument = gql`
  subscription newActivity {
    newActivity
  }
`;

/**
 * __useNewActivitySubscription__
 *
 * To run a query within a React component, call `useNewActivitySubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewActivitySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewActivitySubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewActivitySubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    NewActivitySubscription,
    NewActivitySubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NewActivitySubscription,
    NewActivitySubscriptionVariables
  >(NewActivityDocument, options);
}
export type NewActivitySubscriptionHookResult = ReturnType<
  typeof useNewActivitySubscription
>;
export type NewActivitySubscriptionResult =
  Apollo.SubscriptionResult<NewActivitySubscription>;
export const NewMessageDocument = gql`
  subscription newMessage($chatId: Int!) {
    newMessage(chatId: $chatId) {
      ...messageFragment
    }
  }
  ${MessageFragmentFragmentDoc}
`;

/**
 * __useNewMessageSubscription__
 *
 * To run a query within a React component, call `useNewMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewMessageSubscription({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useNewMessageSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    NewMessageSubscription,
    NewMessageSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<NewMessageSubscription, NewMessageSubscriptionVariables>(
    NewMessageDocument,
    options,
  );
}
export type NewMessageSubscriptionHookResult = ReturnType<
  typeof useNewMessageSubscription
>;
export type NewMessageSubscriptionResult =
  Apollo.SubscriptionResult<NewMessageSubscription>;
export const PrivateChatActivityDocument = gql`
  subscription privateChatActivity($chatId: Int!) {
    privateChatActivity(chatId: $chatId) {
      type
      chatId
      noteId
      fileId
      messageId
      message {
        isActivity
      }
    }
  }
`;

/**
 * __usePrivateChatActivitySubscription__
 *
 * To run a query within a React component, call `usePrivateChatActivitySubscription` and pass it any options that fit your needs.
 * When your component renders, `usePrivateChatActivitySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivateChatActivitySubscription({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function usePrivateChatActivitySubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    PrivateChatActivitySubscription,
    PrivateChatActivitySubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    PrivateChatActivitySubscription,
    PrivateChatActivitySubscriptionVariables
  >(PrivateChatActivityDocument, options);
}
export type PrivateChatActivitySubscriptionHookResult = ReturnType<
  typeof usePrivateChatActivitySubscription
>;
export type PrivateChatActivitySubscriptionResult =
  Apollo.SubscriptionResult<PrivateChatActivitySubscription>;
export const TaskSubtaskActivityDocument = gql`
  subscription taskSubtaskActivity {
    taskSubtaskActivity {
      type
      task
      subtask
    }
  }
`;

/**
 * __useTaskSubtaskActivitySubscription__
 *
 * To run a query within a React component, call `useTaskSubtaskActivitySubscription` and pass it any options that fit your needs.
 * When your component renders, `useTaskSubtaskActivitySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskSubtaskActivitySubscription({
 *   variables: {
 *   },
 * });
 */
export function useTaskSubtaskActivitySubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    TaskSubtaskActivitySubscription,
    TaskSubtaskActivitySubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    TaskSubtaskActivitySubscription,
    TaskSubtaskActivitySubscriptionVariables
  >(TaskSubtaskActivityDocument, options);
}
export type TaskSubtaskActivitySubscriptionHookResult = ReturnType<
  typeof useTaskSubtaskActivitySubscription
>;
export type TaskSubtaskActivitySubscriptionResult =
  Apollo.SubscriptionResult<TaskSubtaskActivitySubscription>;
