import './UserStatisticBlockHeader.scss';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useModalContext } from '../../../context/ModalContext';
import {
  useCreateUserCharacteristicMutation,
  User,
} from '../../../graphql/generated/graphql';
import { ModalStatuses } from '../../../types/enums';
import { addKarma, getUserAvatar } from '../../../utils/';
import {
  AllTaskIcons,
  CalendarIcons,
  GearsIcons,
  NewMessageIcons,
  PlusIcons,
} from '../../icons';

type UserStatisticBlockHeaderType = {
  userData: User;
  handleRefetch: () => void;
};

export const UserStatisticBlockHeader = ({
  userData,
  handleRefetch,
}: UserStatisticBlockHeaderType) => {
  const { handleSetModalStatus, handleChangeModalParameters, handleCloseModal } =
    useModalContext();

  const [createUserCharacteristicMutation] = useCreateUserCharacteristicMutation({
    onCompleted: () => {
      handleRefetch();
    },
  });

  const navigate = useNavigate();
  const analytic = userData?.analyticThreads;

  return (
    <div className='userStatisticBlockHeader'>
      <div className='user__wrapper'>
        <div
          className='user__avatar'
          onClick={() => {
            navigate(`/user-tasks/${userData.id}`);
          }}
        >
          <span className='user__status'>
            <img
              src={getUserAvatar(userData.image)}
              alt='avatar'
              className='user__avatar-image'
            />
            <div
              className='user__status-indicator'
              style={{ backgroundColor: userData?.onlineStatus ? 'green' : '#afafaf' }}
            ></div>
          </span>
          <div className='user__avatar-name'>{addKarma(userData)}</div>
        </div>
        <div className='user__action'>
          <NewMessageIcons
            className='user__action-chats'
            onClick={() => {
              navigate(`/private-chat/${userData.id}`);
            }}
          />
          <PlusIcons
            className='user__action-plus'
            onClick={() => {
              handleSetModalStatus(ModalStatuses.ADD_TASKS);
              handleChangeModalParameters([
                {
                  key: 'onCallback',
                  value: (values: any) => {
                    createUserCharacteristicMutation({
                      variables: {
                        data: { ...values, userId: parseInt(userData.id as any) },
                      },
                    });
                    handleCloseModal();
                  },
                },
                {
                  key: 'initialEditTitleValues',
                  value: '',
                },
              ]);
            }}
          />
        </div>
      </div>
      <div className='horizontalLine'></div>
      <div className='tasks'>
        <div className='tasks__line-wrapper mb10'>
          <div className='ml10'>Задачи:</div>
          <div>
            Дедлайн, дней: {analytic?.deadlineThrough ? analytic?.deadlineThrough : 0}
          </div>
        </div>
        <div className='tasks__line-wrapper'>
          <div className='d-flex'>
            <div className='ml10 tasks__item-wrapper'>
              <AllTaskIcons />
              <span className='tasks__item-number'>
                {analytic?.userThreadCount}/{analytic?.totalCreatedThreads}
              </span>
            </div>
            <div className='verticalLine'></div>
            <div className='tasks__item-wrapper'>
              <GearsIcons />
              <span className='tasks__item-number'>
                {analytic?.countThreadsInCalendar ? analytic?.countThreadsInCalendar : 0}
              </span>
            </div>
          </div>
          <div className='tasks__item-wrapper'>
            <CalendarIcons />
            <span className='tasks__item-number'>
              {analytic?.daysInactiveInThreadPanel}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
