import React from 'react';

export const LongArrowLeft = ({ style }: any) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      style={style}
    >
      <g clipPath='url(#clip0_14497_46552)'>
        <path
          d='M0.160399 7.38711C0.160563 7.38727 0.160699 7.38746 0.160891 7.38763L3.01842 10.2314C3.2325 10.4444 3.57875 10.4436 3.79184 10.2295C4.0049 10.0154 4.00408 9.66919 3.79001 9.45613L1.87146 7.54688L13.4531 7.54688C13.7552 7.54688 14 7.30204 14 7C14 6.69796 13.7552 6.45313 13.4531 6.45313L1.87149 6.45313L3.78998 4.54388C4.00406 4.33082 4.00488 3.98456 3.79181 3.77049C3.57872 3.55636 3.23244 3.55562 3.0184 3.76863L0.160863 6.61238C0.160699 6.61254 0.160563 6.61273 0.160371 6.6129C-0.0538127 6.82667 -0.0531289 7.17405 0.160399 7.38711Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_14497_46552'>
          <rect
            width='14'
            height='14'
            fill='white'
            transform='translate(14 14) rotate(-180)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
