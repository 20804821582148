import './AppSelect.scss';

import { useField, useFormikContext } from 'formik';
import React, { ReactElement } from 'react';
import Select from 'react-select';

import { useSearchContext } from '../../context';

type AppSelectPropsType = {
  name: string;
  options?: OptionsType[];
  placeholder?: string;
  isSearchable?: boolean;
  isLoading?: boolean;
  background?: string;
  hideError?: boolean;
  maxHeightList?: number;
  onChange?: any;
};

type OptionsType = {
  value?: string | number | null;
  label: ReactElement | string | null;
};

export const AppSelect = ({
  name,
  options,
  isLoading = false,
  isSearchable = false,
  placeholder = 'Выберите',
  background = '#f5f5f5',
  hideError = false,
  maxHeightList = 200,
  onChange,
}: AppSelectPropsType) => {
  const [field, meta] = useField(name);

  const { changeSearchValue, isDebouncing } = useSearchContext();

  const { setFieldValue } = useFormikContext();

  const hasError = Boolean(meta.error && meta.touched && !hideError);

  const customStyles = {
    container: (provided: any) => ({
      ...provided,
      minWidth: '100%',
    }),
    control: (provided: any) => ({
      ...provided,
      background: background,
      border: 'none',
      borderRadius: 0,
      borderBottomColor: hasError ? 'red' : '#9e9e9e',
      minHeight: '30px',
      height: '30px',
      width: '100%',
      boxShadow: null,
      textAlign: 'left',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: '0 8px',
      ':hover': {
        cursor: 'pointer',
      },
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: '#303030',
    }),
    input: (provided: any) => ({
      ...provided,
      margin: '20',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    placeholder: (provided: any) => ({
      ...provided,
      paddingLeft: '0px',
      fontFamily: 'Roboto Condensed, sans-serif',
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: '300',
      color: '#303030',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      margin: 0,
      paddingLeft: '0px',
      fontFamily: 'Roboto Condensed, sans-serif',
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 'normal',
      color: '#303030',
      backgroundColor: background,
      textAlign: 'left',
    }),
    menu: (provided: any) => ({
      ...provided,
      textAlign: 'left',
      width: '100%',
      padding: 0,
      borderRadius: 0,
    }),
    menuList: (provided: any) => ({
      ...provided,
      backgroundColor: background,
      maxHeight: maxHeightList,
    }),
    option: (provided: any) => ({
      ...provided,
      position: 'relative',
      padding: '7px 5px 7px 8px',
      fontFamily: 'Roboto Condensed, sans-serif',
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: '300',
      backgroundColor: background,
      textAlign: 'left',
      color: '#303030',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      ':hover': {
        cursor: 'pointer',
        textDecorationLine: 'underline',
      },
      ':active': {
        color: '#149939',
        textDecorationLine: 'none',
      },
    }),
  };

  return (
    <div className={'appSelect__wrapper ' + (hasError && 'appSelect__wrapper-error')}>
      <Select
        classNamePrefix='appSelect'
        styles={customStyles}
        isSearchable={isSearchable}
        onInputChange={changeSearchValue}
        name={name}
        value={options?.find((el) => el.value === field?.value)}
        noOptionsMessage={() =>
          isLoading || isDebouncing ? 'Загрузка...' : 'Нет данных'
        }
        defaultValue={options?.find((el) => el.value === field?.value)}
        onChange={(option) => {
          setFieldValue(name, option?.value);
          onChange && onChange(option);
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          spacing: {
            controlHeight: 50,
            baseUnit: 2,
            menuGutter: 5,
          },
        })}
        options={isLoading || isDebouncing ? [] : options}
        placeholder={placeholder}
        // menuIsOpen={true}
      />
      {hasError && <p className='appInput__text-error'>{meta.error}</p>}
    </div>
  );
};
