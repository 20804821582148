import './CalendarTredModal.scss';

import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React from 'react';

import { ROLES } from '../../../../constants/constants';
import { useAuthContext } from '../../../../context';
import { renameTredModal } from '../../../../validation/schemas';
import { AppInput, Button, Checkbox, CustomDatePicker, TagsSelect } from '../../../index';

export const EditTitleModalLocal = ({ modalParameters, onClose }: any) => {
  const handleUpdate = async (values: ValuesType) => {
    modalParameters?.onCallback({
      tagsId: values.tagsId,
      startDate: values.startDate ? format(values.startDate, 'yyyy-MM-dd') : null,
      closeDate: values.closeDate ? format(values.closeDate, 'yyyy-MM-dd') : null,
      endDate: values.endDate ? format(values.endDate, 'yyyy-MM-dd') : null,
      statusId: values?.statusId,
      isCritical: values?.isCritical,
      scaleMapTaskName: values?.scaleMapTaskName,
    });
    onClose();
  };

  const { startDate, closeDate, endDate, tagsIds, isCritical, scaleMapTaskName } =
    modalParameters.initialValues;

  const { currentUserData } = useAuthContext();

  return (
    <Formik
      initialValues={{
        startDate: startDate ? moment(startDate).valueOf() : null,
        closeDate: closeDate ? moment(closeDate).valueOf() : null,
        endDate: endDate ? moment(endDate).valueOf() : null,
        tagsId: tagsIds,
        statusId: undefined,
        isCritical: isCritical,
        scaleMapTaskName: scaleMapTaskName,
      }}
      onSubmit={handleUpdate}
      validationSchema={renameTredModal}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form className='CalendarTredModal'>
            <div
              className='simple-form-modal'
              style={{ width: '400px', maxWidth: 'none' }}
            >
              <div className='simple-form-modal__title'>
                <AppInput name='scaleMapTaskName' label={'Название треда:'} />{' '}
                <div className='editTitle__datePickers'>
                  <CustomDatePicker name='startDate' label='Дата старта:' startDateSet />
                  <CustomDatePicker name='closeDate' label='Дата завершения:' />
                  <CustomDatePicker name='endDate' label='Дата сдачи:' endDateSet />
                </div>
                {(currentUserData?.role === ROLES.ADMIN ||
                  currentUserData?.role === ROLES.MODERATOR) && (
                  <TagsSelect
                    name='tagsId'
                    editTagIds={values?.tagsId?.map((e: any) => String(e))}
                    isEditMode={true}
                  />
                )}
                <Checkbox
                  isChecked={Boolean(values?.isCritical)}
                  className=''
                  label='Критическая'
                  setIsChecked={(e) => {
                    setFieldValue('isCritical', e);
                  }}
                />
              </div>

              <Button
                text='Завершить'
                width='100%'
                height={25}
                onClick={() => {
                  setFieldValue('closeDate', new Date());
                  setFieldValue('endDate', new Date());
                }}
              />
              <Button text='Сохранить' type='submit' width='100%' height={25} />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

type ValuesType = {
  tagsId?: string[];
  startDate?: number | null;
  closeDate?: number | null;
  endDate?: number | null;
  statusId?: number | null | undefined;
  isCritical?: boolean | null | undefined;
  scaleMapTaskName?: string;
};
