import './ChronometerTaskMonth.scss';

import moment from 'moment';
import React, { useRef, useState } from 'react';

import { useClickOutside } from '../../hooks';
import { textSlice } from '../../utils';
import { ArrowRightIcon, ClockIcons, FlagChronoICons } from '../icons';
import { Switch2 } from '../index';

type ChronometerTaskMonthType = {
  statistic: any;
  weeks: any;
  setWeeks: any;
};

export const ChronometerTaskMonth = ({
  statistic,
  weeks,
  setWeeks,
}: ChronometerTaskMonthType) => {
  const [value, setValue] = useState(false);

  const mockEnergy = [
    {
      percent: value
        ? statistic?.energyStatisticPerMonth?.positiveEnergyPercent
        : statistic?.energyStatisticPerWeek?.positiveEnergyPercent,
      scaleMapFile: {
        name: 'Позитив',
        color: '#B3E4C1',
      },

      timeTracked: value
        ? statistic?.energyStatisticPerMonth?.positiveEnergyTime
        : statistic?.energyStatisticPerWeek?.positiveEnergyTime,
    },
    {
      percent: value
        ? statistic?.energyStatisticPerMonth?.negativeEnergyPercent
        : statistic?.energyStatisticPerWeek?.negativeEnergyPercent,
      scaleMapFile: {
        name: 'Негатив',
        color: '#EE3944',
      },

      timeTracked: value
        ? statistic?.energyStatisticPerMonth?.negativeEnergyTime
        : statistic?.energyStatisticPerWeek?.negativeEnergyTime,
    },
  ];

  const percentAll = value
    ? statistic?.trackedPercentPerMonth
    : statistic?.trackedPercentPerWeek;

  return (
    <div className='ChronometerTaskMonth'>
      <div className='ChronometerTaskMonth__title'>
        <div className='ChronometerTaskMonth__title-name'>
          {value ? 'Анализ по месяцу' : 'Задачи на неделю'}
        </div>
        <div className='ChronometerTaskMonth__title-switch'>
          Неделя
          <Switch2 value={value} handleChange={setValue} />
          Месяц
        </div>{' '}
      </div>

      <div style={{ width: '100%', display: 'flex', margin: '20px 0px' }}>
        <div
          style={{
            width: `${percent(percentAll)}%`,
            backgroundColor: '#303030',
            height: '16px',
          }}
        ></div>
        <div
          style={{
            width: `${percentAll ? 100 - percent(percentAll) : 100}%`,
            height: '16px',
            display:
              (percentAll ? 100 - percent(percentAll) : 100) === 0 ? 'none' : 'block',
          }}
          className='plan__gray'
        ></div>
        <div style={{ padding: '0px 10px' }}>{percentAll}%</div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        <ArrowRightIcon
          className='changeWeeks__transform'
          onClick={() => {
            const prevMonthWeek = changeMonth(weeks, 'prev');
            setWeeks(prevMonthWeek);
          }}
        />
        <div className='changeWeeks__text'>{getMonth(weeks?.from)}</div>
        <ArrowRightIcon
          onClick={() => {
            const nextMonthWeek = changeMonth(weeks, 'next');
            setWeeks(nextMonthWeek);
          }}
        />
      </div>

      <Done
        plan={value ? statistic?.statisticPerMonth : statistic?.statisticPerWeek}
        weeks={weeks}
      />

      <div className='ChronometerTaskMonth__title'>
        <div
          className='ChronometerTaskMonth__title-name'
          style={{
            marginTop: '45px',
          }}
        >
          Таблица энергии
        </div>
      </div>

      <Done plan={mockEnergy} weeks={weeks} />
    </div>
  );
};

const Done = ({ plan, weeks }: any) => {
  if (plan?.length === 0) return <></>;

  return (
    <div className='plan'>
      <div style={{ display: 'flex' }}>
        <div className='plan__col1 plan__titleCol'>Название</div>
        <div className='plan__col2 plan__titleCol'>Часы</div>
        <div className='plan__col3 plan__titleCol'>Выполнение</div>
      </div>
      {plan?.map((e: any, index: number) => {
        return <PlanItem e={e} index={index} weeks={weeks} key={index + weeks.to} />;
      })}
    </div>
  );
};

const PlanItem = ({ e, index, weeks }: { e: any; index: number; weeks: any }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<any>(null);

  const positivePercent = percent(e.percent);
  const negativePercent = 100 - percent(e.percent);
  const inner = e?.groupByScaleMapTask;
  const reversedArr = inner?.slice()?.reverse();

  const showInner =
    e?.groupByScaleMapTask && e?.groupByScaleMapTask[0]?.scaleMapTaskId && open;
  useClickOutside(ref, () => setOpen(false));
  return (
    <div
      className='plan__row'
      key={index + weeks.to}
      style={{
        position: 'relative',
      }}
      ref={ref}
    >
      <div
        className='plan__col1 plan__itemCol plan__col1-first'
        style={{ textAlign: 'start' }}
      >
        <FlagChronoICons className='mr5' />
        {textSlice({ text: e.scaleMapFile.name, count: 32 })}
      </div>
      <div
        className='plan__col2 plan__checkCol'
        style={{
          position: 'relative',
        }}
      >
        <ClockIcons
          className='mr5 ChronometerTaskMonth__hideIcon'
          onClick={() => {
            setOpen(!open);
          }}
          color={open && showInner ? 'Black' : '#AFAFAF'}
        />
        <div> {e.timeTracked}ч</div>
      </div>
      {showInner && (
        <div
          style={{
            position: 'absolute',
            top: '90%',
            left: '40%',
            zIndex: 99999,
            backgroundColor: 'white',
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #dfd8d8',
          }}
        >
          {reversedArr?.map((e: any) => {
            const tracked = e?.scaleMapFile?.isMainScaleMapTasks?.find((tasks: any) => {
              return tasks.id === e.scaleMapTaskId;
            });

            return (
              <div
                key={e.scaleMapTaskId}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>{tracked?.name ? tracked?.name : e.scaleMapFile.name}</div>
                <div
                  style={{
                    paddingLeft: '15px',
                  }}
                >
                  {e?.timeTracked}ч
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div
        className='plan__col3 plan__itemCol'
        style={{ justifyContent: 'space-between' }}
      >
        <div style={{ width: '80%', display: 'flex' }}>
          <div
            style={{
              width: `${positivePercent}%`,
              backgroundColor: e.scaleMapFile?.color,
              height: '15px',
              display: positivePercent === 0 ? 'none' : 'block',
            }}
          ></div>
          <div
            style={{
              width: `${negativePercent}%`,
              display: negativePercent === 0 ? 'none' : 'block',
            }}
            className='plan__gray'
          ></div>
        </div>
        <div className='ChronometerTaskMonth__time'>
          <div> {percent(e.percent)}</div>
          <div style={{ marginLeft: '2px' }}>%</div>
        </div>
      </div>
    </div>
  );
};

const percent = (percent: number) => {
  if (percent > 100) {
    return 100;
  }
  return percent;
};

const getMonth = (from: string) => {
  const dateParts = from.split('.'); // Split the date string into an array of parts
  const month = parseInt(dateParts[1]); // Extract the month part and convert it to an integer
  const months = [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь',
  ];
  return months[month - 1]; // Return the month corresponding to the index (month - 1)
};

const changeMonth = (week: any, nextOrPrev: string) => {
  const { fromUTC } = week;
  const date = moment(fromUTC).utc();

  if (nextOrPrev === 'next') {
    date.add(1, 'month');
    date.date(1); // Set the first day of the next month
  } else if (nextOrPrev === 'prev') {
    date.subtract(1, 'month');
    date.date(1); // Set the first day of the previous month
  }
  const firstDayOfWeek = 1; // Monday
  const firstDayOfMonth = date.clone().startOf('month').day(); // Number of the day of the week of the first day of the month

  if (firstDayOfMonth !== firstDayOfWeek) {
    const diff = (firstDayOfWeek + 7 - firstDayOfMonth) % 7;
    date.add(diff, 'days'); // Set the first Monday of the month
  }

  const from = date.clone().format('DD.MM.YYYY'); // Monday of the current week

  const to = date.clone().add(6, 'days').format('DD.MM.YYYY'); // Saturday of the current week
  const fromUTCNext = date.clone().toISOString();
  const toUTCNext = date.clone().add(6, 'days').toISOString();

  return { from, to, fromUTC: fromUTCNext, toUTC: toUTCNext };
};
