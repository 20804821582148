import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as GENERATED from '../../graphql/generated/graphql';
import { GetTaskDocument, GetTaskQuery } from '../../graphql/generated/graphql';

export const useDeleteSubtaskMutation = () => {
  const { taskId, subtaskId } = useParams();

  const location = useLocation();

  const archivedTask = location.pathname.includes('archived');
  return GENERATED.useDeleteSubtaskMutation({
    update(cache, { data }) {
      if (!data?.deleteSubtask) {
        return null;
      }

      const task: GetTaskQuery | null = cache.readQuery({
        query: GetTaskDocument,
        variables: !archivedTask
          ? { id: Number(taskId) }
          : { id: Number(taskId), subtaskStatusId: 8 },
      });

      const newSubtasks = task?.getTask.subtasks.filter(
        (subtask) => subtask?.id !== Number(subtaskId),
      );

      if (task) {
        cache.writeQuery({
          query: GetTaskDocument,
          variables: !archivedTask
            ? { id: Number(taskId) }
            : { id: Number(taskId), subtaskStatusId: 8 },
          data: {
            ...task,
            getTask: {
              ...task?.getTask,
              subtasks: [...(newSubtasks || [])],
            },
          },
        });
      }
    },
    onCompleted: (response) => {
      if (response?.deleteSubtask) {
        toast.success('Подзадача успешно удалена!');
      }
    },
  });
};
