import './TredsModal.scss';

import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';

import { ROLES } from '../../../constants/constants';
import { useAuthContext, useModalContext } from '../../../context';
import {
  CreateScaleMapTaskMutationFn,
  useGetScaleMapFileTagsAndFonsQuery,
} from '../../../graphql/generated/graphql';
import { ModalStatuses } from '../../../types/enums';
import { createTreds } from '../../../validation/schemas';
import { SaveIconWhite } from '../../icons';
import { AppInput, Button, TagsSelect } from '../../index';
import { TreadSelectFonModal } from '../../modal/customModalChildrens/treadSelectFonModal/TreadSelectFonModal';
import { LoadImage } from './LoadImage';
import { Performers } from './Performers';

type CreateStatusType = {
  createScaleMapTaskMutation?: CreateScaleMapTaskMutationFn;
  updateThreadMutation?: any;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  closeModal: () => void;
  initialValues?: {
    name: string;
    tagsIds: string[];
    cover: string;
    threadBackgroundId: number | null | undefined;
    performers: any[];
    selectFile?: string;
  };
  id?: number;
  showAddGroup?: boolean;
  inTable?: boolean;
};

export const CreateStatus = ({
  createScaleMapTaskMutation,
  isOpen,
  setIsOpen,
  closeModal,
  initialValues,
  updateThreadMutation,
  id,
  showAddGroup = true,
  inTable = false,
}: CreateStatusType) => {
  const { currentUserData } = useAuthContext();

  const [isTread, setisTread] = useState(true);

  return (
    <Formik
      validationSchema={createTreds}
      enableReinitialize={true}
      initialValues={
        initialValues
          ? {
              ...initialValues,
              parentScaleMapTaskId: undefined,
              selectFile: initialValues?.selectFile
                ? initialValues?.selectFile
                : undefined,
              selectProject: undefined,
            }
          : {
              name: '',
              tagsIds: [],
              cover: '',
              threadBackgroundId: null,
              performers: [currentUserData],
              parentScaleMapTaskId: undefined,
              selectFile: undefined,
              selectProject: undefined,
            }
      }
      onSubmit={(e) => {
        createScaleMapTaskMutation &&
          createScaleMapTaskMutation({
            variables: {
              input: {
                name: e.name,
                statusId: 2,
                thread: {
                  performers: e.performers?.map((e: any) => parseInt(e.id)),
                  tagsIds: e.tagsIds?.map((e) => parseInt(e)),
                  cover: e?.cover,
                  threadBackgroundId: e?.threadBackgroundId,
                  scaleMapTask: {
                    name: e.name,
                    statusId: 2,
                  },
                  parentScaleMapTaskId: e.parentScaleMapTaskId,
                },
              },
            },
          });

        updateThreadMutation &&
          updateThreadMutation({
            variables: {
              data: {
                id: id as number,
                cover: e?.cover,
                threadBackgroundId: e?.threadBackgroundId,
                tagsIds: e.tagsIds?.map((e) => parseInt(e)),
                performers: e.performers?.map((e: any) => parseInt(e.id)),
                scaleMapTask: {
                  name: e.name,
                },
              },
            },
          });
        closeModal();
      }}
    >
      {({ values, setFieldValue, errors }) => {
        return (
          <Form className={'tredsModal__form'}>
            {isTread ? (
              <>
                {!isOpen && (
                  <>
                    <div>
                      <AppInput
                        name={'name'}
                        label='Название треда:'
                        placeholder='Введите название'
                      />
                      {(currentUserData?.role === ROLES.ADMIN ||
                        currentUserData?.role === ROLES.MODERATOR) && (
                        <TagsSelect
                          name='tagsIds'
                          maxTags={99}
                          isEditMode={true}
                          editTagIds={values.tagsIds?.map((e) => String(e))}
                          initialValues={initialValues}
                        />
                      )}
                    </div>
                    {(currentUserData?.role === ROLES.ADMIN ||
                      currentUserData?.role === ROLES.MODERATOR) && (
                      <LoadImage
                        value={values}
                        setValue={setFieldValue}
                        setisTread={() => {
                          setisTread(false);
                        }}
                      />
                    )}
                  </>
                )}
                <div>
                  {isOpen ? (
                    <Performers
                      setIsOpen={setIsOpen}
                      setValues={(e: any) => {
                        setFieldValue('performers', e);
                      }}
                      assignees1={values.performers as any}
                      showAddGroup={showAddGroup}
                    />
                  ) : (
                    <Button
                      width={'100%'}
                      onClick={() => {
                        setIsOpen(!isOpen);
                      }}
                    >
                      Исполнители
                    </Button>
                  )}
                  {errors.performers && (
                    <div style={{ color: '#dc3545', fontSize: '12px' }}>
                      {errors.performers}
                    </div>
                  )}
                  <ButtonsProject
                    setFieldValue={setFieldValue}
                    values={values}
                    updateThreadMutation={updateThreadMutation}
                    inTable={inTable}
                  />

                  <Button
                    backgroundColor='#149939'
                    color='#FFFFFF'
                    width={'100%'}
                    margin='20px 0px 0px 0px'
                    type='submit'
                  >
                    <SaveIconWhite className={'mr5'} />{' '}
                    {updateThreadMutation && !inTable ? 'Изменить' : 'Создать'}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <TreadSelectFonModal
                  onClose={() => {
                    setisTread(true);
                  }}
                  modalParameters={{
                    onCallback: (value: any) => {
                      setFieldValue('cover', value?.cover);
                      setFieldValue('threadBackgroundId', value.threadBackgroundId);
                    },
                    initialValues: {
                      threadBackground: {
                        id: values?.threadBackgroundId,
                      },
                      cover: values?.cover,
                    },
                  }}
                />
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

const ButtonsProject = ({
  setFieldValue,
  values,
  updateThreadMutation,
  inTable,
}: any) => {
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();
  const { data } = useGetScaleMapFileTagsAndFonsQuery({
    variables: {
      getScaleMapFileId: values?.selectFile,
    },
    skip: !values?.selectFile,
  });

  const attachedTagIds = data?.getScaleMapFile?.attachedTags?.map((e) => e.id);
  const threadBackgroundId = data?.getScaleMapFile?.threadBackground?.id;

  useEffect(() => {
    setFieldValue('tagsIds', attachedTagIds);
    setFieldValue('threadBackgroundId', threadBackgroundId);
  }, [data]);

  if (updateThreadMutation && inTable) return <></>;

  return (
    <Button
      backgroundColor='#149939'
      color='#FFFFFF'
      width={'100%'}
      margin='20px 0px 0px 0px'
      onClick={() => {
        handleSetModalStatus(ModalStatuses.MOVE_TRED_MODAL);
        handleChangeModalParameters([
          {
            key: 'onCallback',
            value: ({
              parentScaleMapTaskId,
              selectFile,
              selectProject,
            }: {
              parentScaleMapTaskId: number;
              selectFile: number;
              selectProject: number;
            }) => {
              setFieldValue('parentScaleMapTaskId', parentScaleMapTaskId);
              setFieldValue('selectFile', selectFile);
              setFieldValue('selectProject', selectProject);
            },
          },
          {
            key: 'initialValues',
            value: {
              parentScaleMapTaskId: values?.parentScaleMapTaskId,
              selectFile: values?.selectFile,
              selectProject: values?.selectProject,
              type: 'create',
            },
          },
        ]);
      }}
    >
      Привязать к проекту
    </Button>
  );
};
