import React from 'react';

import { ModalStatuses } from '../../types/enums';
import { TreeIcon } from '../icons';

type ButtonTreeType = {
  descriptionMenuOpen: any;
  handleSetModalStatus: any;
  handleChangeModalParameters: any;
  poligon: any;
  projectId: any;
  statisticAccess?: boolean;
  polygonId: number;
  polygonStatisticID?: string;
};

export const ButtonTree = ({
  descriptionMenuOpen,
  handleSetModalStatus,
  handleChangeModalParameters,
  poligon,
  projectId,
  statisticAccess,
  polygonId,
  polygonStatisticID,
}: ButtonTreeType) => {
  return (
    <div
      className='statistic-page__instruments-button_tree'
      style={
        descriptionMenuOpen
          ? { marginRight: '425px', marginLeft: statisticAccess ? 'auto' : '' }
          : { marginLeft: statisticAccess ? 'auto' : '' }
      }
      onClick={() => {
        handleSetModalStatus(ModalStatuses.POLYGON_TREE_MODAL);
        handleChangeModalParameters([
          {
            key: 'onCallback',
            value: () => {},
          },
          {
            key: 'polygonTree',
            value: poligon?.objects,
          },
          {
            key: 'poligonName',
            value: poligon?.name,
          },
          {
            key: 'linksParams',
            value: {
              projectId,
              polygonId: polygonId ? polygonId : 0,
              polygonStatisticID: polygonStatisticID,
            },
          },
        ]);
      }}
    >
      <TreeIcon /> Древо
    </div>
  );
};
