import './TableHeader.scss';

import React, { FC } from 'react';

export const TableHeader: FC<PageListHeaderColumnsType> = ({ columnsData }) => {
  return (
    <thead>
      <tr>
        {columnsData.map((title, index) => {
          return (
            <th key={index}>
              <p>{title}</p>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

type PageListHeaderColumnsType = {
  columnsData: string[];
};
