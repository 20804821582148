import './PickerWrapper.scss';

import React, { useRef, useState } from 'react';
import { ChromePicker } from 'react-color';

import { useClickOutside } from '../../hooks/index';

export const PickerWrapper = ({ color, setFieldValue, title }: any) => {
  const [openPanel, setOpenPanel] = useState(false);
  const ref = useRef<any>();
  useClickOutside(ref, () => setOpenPanel(false));

  return (
    <div className='pickerWrapper'>
      <div className='pickerWrapper__label'>{title}</div>
      <div
        className='pickerWrapper__inner'
        onClick={() => {
          setOpenPanel(!openPanel);
        }}
        ref={ref}
      >
        <div
          className='pickerWrapper__color'
          style={{
            backgroundColor: color,
          }}
        ></div>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          style={{
            margin: 'auto',
            transform: openPanel ? 'rotate(180deg)' : '',
          }}
        >
          <path
            d='M13.853 5.36258L8.38036 11.3538C8.27583 11.4588 8.13677 11.5034 8.00024 11.4964C7.8632 11.5034 7.72463 11.4588 7.6201 11.3538L2.14704 5.36258C1.95099 5.16603 1.95099 4.84693 2.14704 4.65036C2.3431 4.4538 2.66122 4.45381 2.85728 4.65036L8.00028 10.281L13.1427 4.65036C13.3388 4.45381 13.6569 4.45381 13.853 4.65036C14.049 4.84689 14.049 5.16601 13.853 5.36258Z'
            fill='#303030'
          />
        </svg>
        {openPanel && (
          <div
            className='pickerWrapper__panel'
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <ChromePicker
              disableAlpha
              color={color as any}
              onChangeComplete={(paletteColor) => {
                setFieldValue('color', paletteColor.hex);
              }}
              onChange={(paletteColor) => {
                setFieldValue('color', paletteColor.hex);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
