import './MyPersonalPanelButton.scss';

import React from 'react';

import { useAuthContext } from '../../context';
import { addKarma, getUserAvatar } from '../../utils';

type Props = {
  alt?: string;
  title?: string;
  icon?: string;
  doubleIcon?: string;
  onClick?: () => void;
  underline?: boolean;
};

export const MyPersonalPanelButton = ({ onClick }: Props) => {
  const { currentUserData } = useAuthContext();

  return (
    <div className='header__button' role='button' onClick={onClick}>
      <img
        src={getUserAvatar(currentUserData?.image)}
        alt={'avatar'}
        className='img__last'
      />
      <p style={{ whiteSpace: 'nowrap' }}>{addKarma(currentUserData as any) || ''}</p>
      <svg
        width='10'
        height='6'
        viewBox='0 0 10 6'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M9.87747 0.80217L5.31696 5.79486C5.22986 5.8824 5.11397 5.91949 5.0002 5.91365C4.886 5.91949 4.77053 5.8824 4.68342 5.79486L0.122537 0.80217C-0.0408456 0.63838 -0.0408456 0.372465 0.122537 0.208658C0.285919 0.0448503 0.551017 0.0448666 0.714399 0.208658L5.00023 4.90088L9.28562 0.208658C9.44901 0.0448666 9.71409 0.0448666 9.87747 0.208658C10.0409 0.372432 10.0409 0.638363 9.87747 0.80217Z'
          fill='white'
        />
      </svg>
    </div>
  );
};
