import React from 'react';

type ForumIconsTreeType = {
  onClick?: (value: any) => void;
  color?: string;
};

export const ForumIconsTree = ({ onClick, color = 'white' }: ForumIconsTreeType) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        d='M10 6.25C10.6904 6.25 11.25 5.69036 11.25 5C11.25 4.30964 10.6904 3.75 10 3.75C9.30964 3.75 8.75 4.30964 8.75 5C8.75 5.69036 9.30964 6.25 10 6.25Z'
        fill={color}
      />
      <path
        d='M10 11.25C10.6904 11.25 11.25 10.6904 11.25 10C11.25 9.30964 10.6904 8.75 10 8.75C9.30964 8.75 8.75 9.30964 8.75 10C8.75 10.6904 9.30964 11.25 10 11.25Z'
        fill={color}
      />
      <path
        d='M10 16.25C10.6904 16.25 11.25 15.6904 11.25 15C11.25 14.3096 10.6904 13.75 10 13.75C9.30964 13.75 8.75 14.3096 8.75 15C8.75 15.6904 9.30964 16.25 10 16.25Z'
        fill={color}
      />
    </svg>
  );
};
