import React from 'react';

export const HeaderStorageIcons = ({ color = 'white', className, onClick }: any) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path d='M5 5H15V6.48438H5V5ZM4.375 7.5H15.625V8.98438H4.375V7.5Z' fill='white' />
      <path
        d='M17.5 2.5H2.5L1.25 10V17.5H18.75V10L17.5 2.5ZM17.0312 10H12.5C12.5 10.663 12.2366 11.2989 11.7678 11.7678C11.2989 12.2366 10.663 12.5 10 12.5C9.33696 12.5 8.70107 12.2366 8.23223 11.7678C7.76339 11.2989 7.5 10.663 7.5 10H2.96875L3.82812 4.14062H16.1719L17.0312 10Z'
        fill={color}
      />
    </svg>
  );
};
