import './History.scss';

import React, { useState } from 'react';

import { TreadHistory } from '../../components';
import { useAuthContext } from '../../context';
import {
  SortingOrders,
  useGetThreadViewHistoryQuery,
} from '../../graphql/generated/graphql';

export const History = () => {
  const [filterIds, setFilterIds] = useState<string[]>([]);
  const { currentUserData } = useAuthContext();

  const { data, loading } = useGetThreadViewHistoryQuery({
    variables: {
      limit: 999999,
      offset: 0,
      sortOrder: SortingOrders.Desc,
      tagsId: filterIds,
    },
    fetchPolicy: 'network-only',
  });

  const handleSetFilterIds = (ids: string[]) => {
    setFilterIds(ids);
  };

  const mappedData = data?.getThreadViewHistory?.map((e) => {
    const thread = e?.thread;
    const current = thread?.meta?.filter((obj: any) => obj?.isCurrent === true)[0];

    const maintainer = thread?.mainPerformer
      ? thread?.mainPerformer
      : thread?.userLastEdited;

    const isPerformer = thread?.performers?.some(
      (e) => e?.userId === currentUserData?.id,
    );

    return {
      id: thread?.id,
      name: `#${current?.name}`,
      status: current?.status?.color,
      createdAt: e?.viewedAt,
      tags: thread?.tags,
      isPerformer: isPerformer,
      maintainer: {
        id: maintainer?.id,
        login: maintainer?.login,
        image: maintainer?.image,
        karmaStatistics: maintainer?.karmaStatistics,
      },
    };
  });

  return (
    <div className='container'>
      <TreadHistory
        isLoading={loading}
        mappedData={mappedData as any}
        pageTitle='История задач'
        filterIds={filterIds}
        setFilterIds={handleSetFilterIds}
      />
    </div>
  );
};
