import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const DownloadIcon: FC<DownloadIconType> = ({
  width = '20px',
  height = '19px',
  className = 'download-icon',
  color = '#000000',
  onClick,
  isBordered = false,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      style={isBordered ? { borderLeft: '1px solid #AFAFAF' } : {}}
    >
      <path
        d='M19 12V16C19 16.5304 18.7893 17.0391 18.4142 17.4142C18.0391 17.7893 17.5304 18 17 18H3C2.46957 18 1.96086 17.7893 1.58579 17.4142C1.21071 17.0391 1 16.5304 1 16V12M5 7L10 12M10 12L15 7M10 12V0'
        stroke={color}
        strokeWidth='1.5'
        strokeLinejoin='round'
      />
    </svg>
  );
};

type DownloadIconType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
> & { isBordered?: boolean };
