import './PolygonTreeModal.scss';

import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FileIcon } from '../../../icons/fileIcon/FileIcon';

export const PolygonTreeModal = ({
  onClose,
  polygonTree,
  linksParams,
  poligonName,
}: any) => {
  const tree = parseTree({ polygonTree });

  const navigate = useNavigate();
  const filterTreeArray = filterTree({ polygonTree, linksParams, tree });

  const [widthRenderTree, setWidthRenderTree] = useState(0);

  const refChild = useRef<any>(null);

  const onRedirect = ({ element, event }: any) => {
    onClose();
    if (event.target === event.currentTarget) {
      linksParams?.polygonStatisticID
        ? navigate(`/polygon-single/${linksParams?.polygonStatisticID}/${element?.id}`)
        : navigate(`/polygon/${linksParams?.projectId}/${element?.id}`);
    }
  };

  const callback = ({ e, callback, position }: any) => {
    // value for width first element
    setWidthRenderTree(refChild?.current?.scrollWidth);
    return (
      <Figure
        element={e}
        callback={callback}
        linksParams={linksParams}
        onRedirect={onRedirect}
        position={position}
        poligonName={poligonName}
      />
    );
  };

  return (
    <div className='polygonTreeModal'>
      <div
        style={{
          width: `${widthRenderTree > 0 ? widthRenderTree + 'px' : '100%'}`,
          display: linksParams?.polygonId === 0 ? 'block' : 'none',
        }}
      >
        <Figure
          element={{
            id: 0,
            name: poligonName,
            parent: [0],
            child: [],
            taskCount: 0,
          }}
          callback={callback}
          linksParams={linksParams}
          onRedirect={() => {
            onClose();
            navigate(`/PolygonStatistic/${linksParams?.projectId}`);
          }}
          start
        />
      </div>

      <div className='figure__elements-child' ref={refChild}>
        {filterTreeArray?.map((element, index) => {
          return (
            <div
              key={element.id}
              className='figure__wrapper-outer'
              onClick={(event) => {
                onRedirect({ element, event });
              }}
              data-id={element?.id}
            >
              <Figure
                element={element}
                callback={callback}
                linksParams={linksParams}
                onRedirect={onRedirect}
                position={calcPosition({ length: filterTreeArray.length, index })}
                start={linksParams?.polygonId === 0 ? false : true}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

enum Position {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  ALL = 'All',
  NONE = 'NONE',
}

type FigureType = {
  poligonName?: string;
  element: TreeElementType;
  callback: any;
  linksParams: {
    projectId: string;
    polygonId: string;
  };
  onRedirect: (value: any) => void;
  start?: boolean;
  position?: Position;
};

const Figure = ({
  element,
  callback,
  linksParams,
  onRedirect,
  start = false,
  position,
}: FigureType) => {
  let showLine = false;

  if (element.child.length > 0 || start) {
    showLine = true;
  }

  return (
    <div className='figure__wrapper-inner'>
      {!start && (
        <>
          <HorizontalLine position={position} />
          <div className='line__height'>
            <span className='arrow down' />
          </div>
        </>
      )}
      <div
        className='figure__elements-wrapper'
        onClick={(event) => {
          onRedirect({ element, event });
        }}
      >
        {element?.taskCount > 0 && (
          <div className='figure__elements-count'>{element?.taskCount}</div>
        )}

        <div
          className={`figure__elements-number ${
            `${linksParams?.polygonId}` === `${element?.id}`
              ? 'figure__elements-select'
              : 'figure__elements-deselect'
          }`}
          data-id={element?.id}
          data-parent={element?.parent}
        >
          {element.id === 0 ? (
            <FileIcon
              color={
                Number(linksParams?.polygonId) === element.id ? '#FFFFFF' : '#000000'
              }
            />
          ) : (
            element.id
          )}
        </div>
        {element.name}
      </div>
      {showLine && (
        <>
          <div className='line__height' />
        </>
      )}
      {element.child.length > 0 && (
        <div key={`${element.id}`} className='figure__elements-child'>
          {element.child?.map((e: TreeElementType, index) => {
            return callback({
              e,
              callback,
              position: calcPosition({ length: element?.child?.length, index }),
            });
          })}
        </div>
      )}
    </div>
  );
};

type TreeElementType = {
  id: number;
  name: string;
  parent: [number];
  child: Array<any>;
  taskCount: number;
};
type ArrayTreeElementType = Array<TreeElementType>;

const parseTree = ({ polygonTree }: any) => {
  const treeElements: [TreeElementType] = polygonTree
    ?.map((e: any) => {
      return {
        id: e.id,
        name: e.name,
        parent: e?.parent?.map((e: any) => {
          return e?.id;
        }),
        child: [],
        taskCount: e?.tasksSubtasks?.count,
      };
    })
    ?.sort(function (a: any, b: any) {
      return a.id - b.id;
    });

  const parseArr: ArrayTreeElementType = [];
  // helps to remove the desired parent id
  // when duplicating a branch
  let deleteID: number;

  const parseLoop = ({ mapArr, callBack }: any) => {
    if (!mapArr) return;
    let newMapArray = JSON.parse(JSON.stringify(mapArr))?.sort(function (a: any, b: any) {
      return a.id - b.id;
    });

    mapArr.map((mapEl: TreeElementType) => {
      // create first level array
      if (mapEl.parent?.length <= 0) {
        parseArr.push(mapEl);
        newMapArray = newMapArray.filter(
          (filterEl: TreeElementType) => filterEl.id !== mapEl.id,
        );
      }
      for (let i = 0; i < newMapArray.length; i++) {
        if (deepSearch(parseArr, newMapArray[i])) {
          if (newMapArray[i]?.parent?.length > 1) {
            newMapArray = newMapArray.map((element: TreeElementType) => {
              if (element.id === newMapArray[i].id) {
                const newParent = JSON.parse(JSON.stringify(element.parent));
                return {
                  ...element,
                  parent: newParent.filter((e: number) => e !== deleteID),
                };
              }
              return element;
            });
          } else {
            newMapArray = newMapArray.filter(
              (filterEl: TreeElementType) => filterEl.id !== newMapArray[i].id,
            );
          }
        }
      }
    });

    if (newMapArray.length > 0) {
      if (newMapArray.length === mapArr.length) {
        return;
      }
      callBack({ mapArr: newMapArray, callBack: parseLoop });
    } else {
      return;
    }
  };
  parseLoop({ mapArr: treeElements, callBack: parseLoop });

  function deepSearch(array: ArrayTreeElementType, item: TreeElementType) {
    return array.some(function s(el: TreeElementType) {
      const find = item.parent.includes(el?.id);
      if (find) {
        el.child.push(item);
        deleteID = el?.id;
      }
      return find || (el.child instanceof Array && el.child.some(s));
    });
  }

  return parseArr;
};

const calcPosition = ({ length, index = 0 }: any) => {
  if (length === 1 || !length) return Position.NONE;
  if (index === 0) {
    return Position.RIGHT;
  }
  if (index + 1 !== length) {
    return Position.ALL;
  }
  return Position.LEFT;
};

const HorizontalLine = ({ position }: any) => {
  return (
    <div
      style={{
        width: '100%',
        display: position === Position.NONE ? 'none' : 'flex',
      }}
    >
      <div
        style={{
          backgroundColor:
            position === Position.LEFT || position === Position.ALL ? '#303030' : '',
          height: '2px',
          width: '50%',
        }}
      />
      <div
        style={{
          backgroundColor:
            position === Position.RIGHT || position === Position.ALL ? '#303030' : '',
          height: '2px',
          width: '50%',
        }}
      />
    </div>
  );
};

const filterTree = ({ polygonTree, linksParams, tree }: any) => {
  if (linksParams.polygonId === 0) return tree as ArrayTreeElementType;
  const fatherArray: any = [];
  const findFatherTree = ({ callBack, id }: any) => {
    const currentPolygon = polygonTree?.filter((e: any) => e.id === id)[0];

    if (currentPolygon?.parent?.length === 0) return fatherArray.push(currentPolygon.id);
    currentPolygon?.parent?.map((e: any) => {
      return callBack({ callBack: findFatherTree, id: e.id });
    });
  };
  findFatherTree({ callBack: findFatherTree, id: linksParams.polygonId });

  return tree.filter((e: any) => {
    const findArr = (element: any) => {
      return element === e.id;
    };
    if (fatherArray.find(findArr)) {
      return true;
    }
    return false;
  }) as ArrayTreeElementType;
};
