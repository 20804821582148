import React from 'react';

export const EditScaleIcon = ({ onClick }: any) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='scalePopup2__comment-icons'
      onClick={onClick}
    >
      <path
        d='M10.9166 14.6655H2.86106C2.01827 14.6655 1.33325 13.9805 1.33325 13.1377V5.08218C1.33325 4.23938 2.01827 3.55437 2.86106 3.55437H7.58325C7.81325 3.55437 7.99992 3.74103 7.99992 3.97103C7.99992 4.20103 7.81325 4.3877 7.58325 4.3877H2.86106C2.47827 4.3877 2.16659 4.69939 2.16659 5.08218V13.1377C2.16659 13.5205 2.47827 13.8322 2.86106 13.8322H10.9166C11.2994 13.8322 11.6111 13.5205 11.6111 13.1377V8.41551C11.6111 8.18551 11.7977 7.99885 12.0277 7.99885C12.2577 7.99885 12.4444 8.185 12.4444 8.41551V13.1377C12.4444 13.9805 11.7594 14.6655 10.9166 14.6655Z'
        fill='#303030'
        stroke='#303030'
        strokeWidth='0.3'
      />
      <path
        d='M6.20673 10.2089C6.09727 10.2089 5.99056 10.1655 5.91224 10.0867C5.81336 9.98831 5.77114 9.84661 5.79841 9.7105L6.19117 7.74608C6.20724 7.66501 6.24722 7.59105 6.305 7.53327L12.059 1.78C12.6545 1.1844 13.6234 1.1844 14.2195 1.78C14.5078 2.06829 14.6667 2.45169 14.6667 2.86002C14.6667 3.26834 14.5078 3.65164 14.2189 3.94003L8.46564 9.69392C8.40786 9.75221 8.3334 9.79168 8.25283 9.80775L6.28892 10.2005C6.26166 10.2061 6.23389 10.2089 6.20673 10.2089ZM8.17115 9.39942H8.17674H8.17115ZM6.9834 8.03335L6.73835 9.26108L7.96556 9.01551L13.63 3.35115C13.7611 3.21941 13.8334 3.04556 13.8334 2.86002C13.8334 2.67447 13.7611 2.50052 13.63 2.36889C13.36 2.0983 12.9195 2.0983 12.6479 2.36889L6.9834 8.03335Z'
        fill='#303030'
        stroke='#303030'
        strokeWidth='0.2'
      />
      <path
        d='M13.139 4.84839C13.0324 4.84839 12.9257 4.8078 12.8446 4.72612L11.2734 3.15446C11.1107 2.9917 11.1107 2.72782 11.2734 2.56506C11.4362 2.4023 11.7001 2.4023 11.8629 2.56506L13.434 4.13672C13.5967 4.29948 13.5967 4.56336 13.434 4.72612C13.3523 4.80729 13.2457 4.84839 13.139 4.84839Z'
        fill='#303030'
        stroke='#303030'
        strokeWidth='0.2'
      />
    </svg>
  );
};
