import './NewLine.scss';

import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import * as yup from 'yup';

import { PlusIcons } from '../icons';
import { AppSelect, Button } from '../index';

export const NewLine = ({
  setFieldValueOuter,
  values,
  options,
  setFieldTouched,
  touchedSampleItems,
}: {
  setFieldValueOuter: any;
  setFieldTouched: any;
  values: any;
  options: [{ label: string; value: number | string }];
  error: boolean;
  touchedSampleItems: boolean;
}) => {
  const [openColor, setOpenColor] = useState(false);

  const initialValues = {
    color: '',
    scaleMapFileId: '',
    name: '',
  };

  // on submit the view of the fix component is not updated
  const [reset, setReset] = useState(false);
  useEffect(() => {
    setReset(false);
  }, [reset]);

  if (reset) return <></>;
  //////

  const schema = yup.object().shape({
    scaleMapFileId: yup.number().required(),
    color: yup.string().required('color'),
  });

  return (
    <Formik
      validationSchema={schema}
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(value, { resetForm }) => {
        const name = options?.find((e) => {
          if (e.value === value.scaleMapFileId) return e;
        })?.label;
        setFieldValueOuter('sampleItems', [...values.sampleItems, { ...value, name }]);
        setFieldTouched('sampleItems', true);
        resetForm({ values: initialValues });
        setReset(true);
      }}
    >
      {({ setFieldValue, values, errors, handleSubmit }) => {
        const isError = errors && touchedSampleItems && !values?.scaleMapFileId;
        return (
          <Form
            style={{
              display: 'flex',
              borderBottom: isError ? '1px solid red' : '',
              paddingBottom: '5px',
              justifyContent: 'space-between',
            }}
          >
            <div
              className='NewLine__selector2'
              style={{
                width: '100%',
                marginRight: '10px',
                borderBottom: isError ? '1px solid red' : '',
              }}
            >
              <div
                style={{
                  width: '20px',
                  height: '20px',
                  backgroundColor: values?.color ? values?.color : 'white',
                }}
                onClick={() => {
                  setOpenColor(true);
                }}
              ></div>
              {openColor && (
                <div className='NewLine__colorPanel'>
                  <ChromePicker
                    disableAlpha
                    color={values.color as any}
                    onChangeComplete={(paletteColor) => {
                      setFieldValue('color', paletteColor.hex);
                    }}
                    onChange={(paletteColor) => {
                      setFieldValue('color', paletteColor.hex);
                    }}
                  />
                  <Button
                    text='Сохранить цвет'
                    margin='10px 0 0 0'
                    onClick={() => {
                      setOpenColor(false);
                    }}
                  />
                </div>
              )}
              <AppSelect
                name='scaleMapFileId'
                options={[...(options ? options : [])]}
                placeholder='Выберите задачу'
                background='white'
                hideError={true}
                maxHeightList={170}
                onChange={(e: any) => {
                  setFieldValue('color', e.color);
                }}
              />
            </div>

            <div className='NewLine__addRight'>
              <PlusIcons
                color='black'
                width='16'
                height='16'
                onClick={() => {
                  handleSubmit();
                }}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
