import './EntityActions.scss';

import React, { FC } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Tag } from '../../graphql/generated/graphql';
import { OptionsIcon, PolygonIcon } from '../icons';
import { Popover } from '../popover/Popover';
import { StartButton } from '../startButton';
import { DeleteBlock } from './deleteBlock/DeleteBlock';
import { EditTitle } from './editTitle/EditTitle';
import { TaskPrice } from './taskPrice/TaskPrice';

export const EntityActions: FC<EntityActionsType> = ({
  entityId,
  projectId,
  projectNumber,
  initialTitle,
  initialTags,
  initialEndDate,
  label,
  color,
  realizationPrice,
  isProject,
  startDate,
  closeDate,
  status = 0,
  handleDelete,
  refetch,
}) => {
  const { taskId, subtaskId } = useParams();

  const navigate = useNavigate();

  const location = useLocation();

  const isTaskOrSubtaskPage = taskId || subtaskId;

  const isArchivePage = location.pathname.includes('archive');

  return (
    <div
      className='editBlock'
      style={
        isTaskOrSubtaskPage || isArchivePage
          ? { display: 'flex', justifyContent: 'space-between' }
          : {}
      }
    >
      <div
        style={
          isTaskOrSubtaskPage && isArchivePage
            ? { display: 'none' }
            : { display: 'flex', marginRight: '10px' }
        }
      >
        {!isTaskOrSubtaskPage ? (
          <PolygonIcon
            color={isTaskOrSubtaskPage ? '#303030' : '#FFFFFF'}
            onClick={() => navigate(`/polygonStatistic/${projectId}`)}
          />
        ) : null}
      </div>
      <div className='editBlock__items'>
        {taskId && (
          <StartButton
            entityId={entityId}
            startDate={startDate}
            closeDate={closeDate}
            status={status}
            refetch={() => {}}
          />
        )}

        <div className='options-wrapper'>
          <OptionsIcon
            width='15px'
            height='15px'
            color={isTaskOrSubtaskPage || isArchivePage ? '#303030' : '#FFFFFF'}
          />
          <Popover>
            <EditTitle
              entityId={entityId}
              projectNumber={projectNumber}
              initialTitle={initialTitle}
              initialTagIds={initialTags?.map(({ id }) => String(id))}
              initialStartDate={startDate}
              initialCloseDate={closeDate}
              initialEndDate={initialEndDate}
              label={label}
              color={color}
              isProject={isProject}
              refetch={refetch}
            />
            <DeleteBlock
              entityId={entityId}
              initialTitle={initialTitle}
              color={color}
              projectId={projectId}
              handleDelete={handleDelete}
            />
            {taskId && !subtaskId ? (
              <TaskPrice entityId={entityId} realizationPrice={realizationPrice} />
            ) : null}
          </Popover>
        </div>
      </div>
    </div>
  );
};

export type EntityActionsType = {
  entityId?: number | null;
  projectNumber?: number | null;
  initialTitle?: string;
  projectId?: number | null;
  initialTags?: Tag[];
  initialEndDate?: string;
  realizationPrice?: number | null;
  label: string;
  author?: {
    id?: number | null;
    login?: string | null;
  } | null;
  color?: string;
  isProject?: boolean;
  startDate?: any | null;
  closeDate?: any | null;
  status?: number;
  handleDelete: (itemId: number) => void;
  refetch?: () => void;
};
