import './Performers.scss';

import React, { useEffect, useState } from 'react';

import { search } from '../../../assets/icons';
import { useAuthContext } from '../../../context';
import {
  useGetAllUsersQuery,
  useUserGroupsQuery,
} from '../../../graphql/generated/graphql';
import { addKarma } from '../../../utils';
import { Button, CrossIcon, UserItem } from '../../index';

export type MaintainerType = {
  id?: number | null | undefined;
  login?: string | null | undefined;
  image?: string | null | undefined;
  isAnalytics?: boolean;
};

type PerformersType = {
  assignees1?: Array<{
    id?: number | null | undefined;
    login?: string | null | undefined;
    image?: string | null | undefined;
  } | null>;
  maintainer1?: MaintainerType | null;
  setIsOpen: (value: boolean) => void;
  setValues?: any;
  closeModalCallback?: any;
  showAddGroup?: boolean;
  isModal?: boolean;
  onClose?: any;
};

export const Performers = ({
  assignees1,
  showAddGroup,
  setIsOpen,
  closeModalCallback,
  setValues,
  isModal,
  onClose,
}: PerformersType) => {
  const [assignees, setAssignees] = useState<any>(assignees1);

  const [searchValue, setSearchValue] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [executorIds, setExecutorIds] = useState<Array<number | null | undefined>>([]);

  const { data } = useGetAllUsersQuery({
    variables: {
      input: {
        search: searchValue,
      },
    },
  });

  const { data: userGroup } = useUserGroupsQuery({
    skip: !showAddGroup,
  });

  useEffect(() => {
    if (assignees) {
      const assIds = assignees?.map((assignedUser: any) => assignedUser?.id);
      setExecutorIds(assIds);
    }
  }, [assignees]);

  useEffect(() => {
    setValues && setValues(assignees);
  }, [assignees]);

  const handleAddAssignees = async (user: any) => {
    setAssignees(assignees ? [...assignees, user] : [user]);
    setIsEditMode(false);
  };

  const handleRemoveAssignees = async (user: any) => {
    setAssignees([...assignees.filter((e: any) => e.id !== user.id)]);
  };

  const handleBackToExecutorsList = () => {
    setIsSearchFocused(false);
    setIsEditMode(false);
  };

  const maintainersList = data?.getAllUsers?.rows;
  const { currentUserData } = useAuthContext();
  return (
    <div className='performers__main' style={{ backgroundColor: '#E4EFFF' }}>
      <div className='performers__description'>
        <p>Управление исполнителями:</p>
      </div>
      {showAddGroup && userGroup?.userGroups && userGroup?.userGroups?.length > 0 && (
        <div className='performers__groups'>
          <div className='performers__groups-title'>Добавить группу пользователей</div>
          <div className='performers__groups-buttons'>
            {userGroup?.userGroups?.map((e) => {
              const sortedArr1 = e?.users
                ?.map((e) => e?.id)
                ?.slice()
                ?.sort();
              const sortedArr2 = assignees
                ?.map((e: any) => e.id)
                ?.slice()
                ?.sort();

              const select = JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2);
              return (
                <div
                  key={e.id}
                  className='performers__groups-button'
                  style={{ border: select ? '1px solid black' : '' }}
                  onClick={() => {
                    const usrs = e?.users;
                    setAssignees(usrs);
                  }}
                >
                  {e.name}
                </div>
              );
            })}
            <div
              className='performers__groups-button performers__groups-button-red'
              onClick={() => {
                setAssignees([currentUserData]);
              }}
            >
              Сброс <CrossIcon className='ml5' color='white' />
            </div>
          </div>
        </div>
      )}

      <div className='performers__search'>
        <img src={search} alt='search' />
        <input
          type='text'
          value={searchValue}
          onChange={(e) => setSearchValue(e.currentTarget.value)}
          onFocus={() => setIsSearchFocused(true)}
          placeholder={
            !isEditMode ? 'Выберите исполнителей' : 'Выберите главного исполнителя'
          }
        />
      </div>

      <div className='performers__list'>
        {!isSearchFocused ? (
          <>
            {assignees?.length ? (
              assignees?.map((assignUser: any) => {
                if (assignUser) {
                  return (
                    <div className='list__item' key={assignUser.id}>
                      <UserItem
                        name={addKarma(assignUser)}
                        avatar={assignUser.image}
                        style={{
                          borderLeft: 'none',
                          paddingLeft: '0',
                        }}
                        id={assignUser?.id as number}
                      />
                      <div className='list__item-buttons' role='button'>
                        <CrossIcon
                          color='#AFAFAF'
                          onClick={() => handleRemoveAssignees(assignUser)}
                        />
                      </div>
                    </div>
                  );
                }
              })
            ) : (
              <p>Исполнители отсутствуют</p>
            )}
          </>
        ) : (
          <>
            {maintainersList
              ?.filter((filteredUser) => filteredUser?.id)
              .map((user) => {
                if (user) {
                  return (
                    <div className='list__item' key={user.id}>
                      <UserItem
                        name={addKarma(user as any)}
                        avatar={user.image}
                        style={{
                          borderLeft: 'none',
                          paddingLeft: '0',
                        }}
                        id={user?.id as number}
                      />
                      <div className='list__item-buttons'>
                        {
                          <>
                            {!executorIds.includes(user.id) && !isEditMode && (
                              <div
                                key={user?.id}
                                className='list__item-add'
                                onClick={() => handleAddAssignees(user)}
                              >
                                <CrossIcon color='#AFAFAF' />
                              </div>
                            )}
                            {executorIds.includes(user.id) && !isEditMode && (
                              <div
                                key={user?.id}
                                onClick={() => handleRemoveAssignees(user)}
                              >
                                <CrossIcon color='#AFAFAF' />
                              </div>
                            )}
                          </>
                        }
                      </div>
                    </div>
                  );
                }
              })}
          </>
        )}
      </div>
      {isSearchFocused && (
        <Button
          text='Назад к исполнителям'
          onClick={handleBackToExecutorsList}
          width={'100%'}
          height={30}
          backgroundColor='#FFFFFF'
        />
      )}
      {isModal && (
        <Button
          text={'Сохранить'}
          onClick={() => {
            closeModalCallback && closeModalCallback(assignees?.map((e: any) => e.id));
            setIsOpen(false);
          }}
          width={'100%'}
          height={30}
        />
      )}
      {!isSearchFocused && (
        <Button
          text={'Выйти'}
          onClick={() => {
            setIsOpen(false);
            onClose && onClose();
          }}
          width={'100%'}
          height={30}
          backgroundColor='#FFFFFF'
        />
      )}
    </div>
  );
};
