import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const UserStatisticIconsReverse: FC<UserStatisticIconsReverseType> = ({
  width = '18px',
  height = '16px',
  color = '#FFFFFF',
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 16'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        d='M16.5 2H17.75V7H16.5V2ZM13.375 0.125H14.625V7H13.375V0.125ZM10.25 3.25H11.5V7H10.25V3.25ZM9 15.75H7.75V12C7.7495 11.5029 7.5518 11.0262 7.20028 10.6747C6.84875 10.3232 6.37213 10.1255 5.875 10.125H3.375C2.87787 10.1255 2.40125 10.3232 2.04972 10.6747C1.6982 11.0262 1.5005 11.5029 1.5 12V15.75H0.25V12C0.250992 11.1715 0.580551 10.3772 1.16639 9.79139C1.75222 9.20555 2.5465 8.87599 3.375 8.875H5.875C6.7035 8.87599 7.49778 9.20555 8.08361 9.79139C8.66945 10.3772 8.99901 11.1715 9 12V15.75ZM4.625 2.625C4.99584 2.625 5.35835 2.73497 5.66669 2.94099C5.97504 3.14702 6.21536 3.43986 6.35727 3.78247C6.49919 4.12508 6.53632 4.50208 6.46397 4.86579C6.39163 5.22951 6.21305 5.5636 5.95083 5.82583C5.6886 6.08805 5.35451 6.26663 4.99079 6.33897C4.62708 6.41132 4.25008 6.37419 3.90747 6.23227C3.56486 6.09036 3.27202 5.85004 3.06599 5.54169C2.85997 5.23335 2.75 4.87084 2.75 4.5C2.75 4.00272 2.94754 3.52581 3.29917 3.17417C3.65081 2.82254 4.12772 2.625 4.625 2.625ZM4.625 1.375C4.00693 1.375 3.40275 1.55828 2.88884 1.90166C2.37494 2.24504 1.9744 2.7331 1.73788 3.30411C1.50135 3.87513 1.43947 4.50347 1.56005 5.10966C1.68062 5.71585 1.97825 6.27267 2.41529 6.70971C2.85233 7.14675 3.40915 7.44438 4.01534 7.56495C4.62153 7.68553 5.24987 7.62365 5.82089 7.38712C6.3919 7.1506 6.87996 6.75006 7.22334 6.23616C7.56672 5.72225 7.75 5.11807 7.75 4.5C7.75 4.08962 7.66917 3.68326 7.51212 3.30411C7.35508 2.92497 7.12489 2.58047 6.83471 2.29029C6.54453 2.00011 6.20003 1.76992 5.82089 1.61288C5.44174 1.45583 5.03538 1.375 4.625 1.375Z'
        fill='#303030'
      />
    </svg>
  );
};

type UserStatisticIconsReverseType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
>;
