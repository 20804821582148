import './MainContentHeaderItem.scss';

import React, { CSSProperties, FC } from 'react';
import { useLocation } from 'react-router-dom';

import { useModalContext } from '../../context';
import { useClearMessagesFromChatMutation } from '../../graphql/generated/graphql';
import { ModalStatuses } from '../../types/enums';
import { CrossIcon } from '../icons';

type Props = {
  style?: CSSProperties;
  onClick?: () => void;
  title: string;
  fullWidth?: boolean;
  isButton?: boolean;
  refetch?: () => void;
  chatId?: number;
};

export const MainContentHeaderItem: FC<Props> = ({
  style,
  onClick,
  title,
  fullWidth,
  isButton,
  refetch,
  chatId,
}) => {
  const full = fullWidth ? { width: '100%' } : {};

  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  const [clearMessagesFromChatMutation] = useClearMessagesFromChatMutation({
    variables: {
      chatId: chatId as any,
    },
    onCompleted: () => {
      refetch && refetch();
    },
  });
  const location = useLocation();
  const inThread = location?.pathname?.includes('tread');

  return (
    <div className='mainContentHeaderItem' style={full}>
      {!inThread && (
        <div
          className={
            isButton
              ? 'mainContentHeaderItem__title--button'
              : 'mainContentHeaderItem__title'
          }
          style={style}
          onClick={onClick}
        >
          {title}
        </div>
      )}

      {refetch && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CrossIcon
            className='ml10'
            onClick={() => {
              handleSetModalStatus(ModalStatuses.CHAT_DELETE_MODAL);
              handleChangeModalParameters([
                {
                  key: 'onCallback',
                  value: () => {
                    clearMessagesFromChatMutation();
                  },
                },
              ]);
            }}
          />
        </div>
      )}
    </div>
  );
};
