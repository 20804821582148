import './ResponsibleUserItem.scss';

import React, { FC } from 'react';

import { Maybe } from '../../graphql/generated/graphql';
import { getUserAvatar } from '../../utils';
import { CrossIcon, EditIcon } from '../icons';

type SelectedUserType = {
  id: Maybe<number> | undefined;
  name: Maybe<string> | undefined;
  image: Maybe<string> | undefined;
};

type ValuesType = {
  selectedUser: SelectedUserType;
};

type ResponsibleUserItemType = {
  handleChooseUserModalOpen: () => void;
  values: ValuesType;
  onSetFieldValue: (field: string, value: SelectedUserType) => void;
};

export const ResponsibleUserItem: FC<ResponsibleUserItemType> = ({
  handleChooseUserModalOpen,
  values,
  onSetFieldValue,
}) => {
  return (
    <div className='new-task__responsible'>
      <p onClick={handleChooseUserModalOpen}>Ответственный за задачу: &nbsp;</p>
      <div className='new-task__user'>
        {values.selectedUser.image !== '' ? (
          <div className='new-task__actions'>
            <img src={getUserAvatar(values.selectedUser?.image)} alt='user' />
            <p>{values.selectedUser.name}</p>
            <div className='new-task__line' />
            <EditIcon onClick={handleChooseUserModalOpen} width='11px' height='11px' />
            <CrossIcon
              color='#AFAFAF'
              width='9px'
              height='9px'
              onClick={() =>
                onSetFieldValue('selectedUser', {
                  id: null,
                  name: '',
                  image: '',
                })
              }
            />
          </div>
        ) : (
          <div className='new-task__add' onClick={handleChooseUserModalOpen}>
            <CrossIcon color='#AFAFAF' width='9px' height='9px' />
          </div>
        )}
      </div>
    </div>
  );
};
