import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const TemplateIcon: FC<TemplateIconType> = ({
  color = '#303030',
  onClick,
  width = '40px',
  height = '24px',
  className = 'template-icon',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 40 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M11.55 3H3V13.1538H11.55V3ZM21 16.8462H3V21H21V16.8462ZM21 3H15.15V6.69231H21V3ZM21 9.46154H15.15V13.1538H21V9.46154Z'
        stroke='#303030'
        strokeWidth='1.5'
        strokeLinejoin='round'
      />
      <path
        d='M37.3231 10.9813L34.4267 13.8312C34.3086 13.9437 34.1555 14 34.0001 14C33.8458 14 33.6912 13.9437 33.5742 13.8311L30.6778 10.9811C30.298 10.6208 30.5668 10 31.105 10H36.8957C37.4325 10 37.7016 10.6208 37.3231 10.9813Z'
        fill={color}
      />
    </svg>
  );
};

type TemplateIconType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
>;
