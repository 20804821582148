import './UserSort.scss';

import React from 'react';
import Select from 'react-select';

export type usersSortValue = {
  by: string;
  type: string;
};

type usersSortOption = {
  value: usersSortValue;
  label: JSX.Element;
};

type UserSortProps = {
  onChangeSelect: (value: usersSortValue) => void;
  options: usersSortOption[];
};

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    width: 180,
    maxHeight: 20,
    minHeight: 20,
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '0 2px',
    ':hover': {
      cursor: 'pointer',
    },
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: 0,
    cursor: 'pointer',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  indicatorsContainer: (prevStyle: any) => ({
    ...prevStyle,
    display: 'none',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    margin: 0,
    fontFamily: 'Roboto Condensed, sans-serif',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 'normal',
    color: '#303030',
    backgroundColor: '#efefef',
    textAlign: 'left',
  }),
  input: (provided: any) => ({
    ...provided,
    ':hover': {
      cursor: 'pointer',
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    margin: 0,
    borderRadius: 0,
    boxShadow: 'none',
  }),
  menuList: (provided: any) => ({
    ...provided,
    width: 183,
    backgroundColor: '#efefef',
    boxShadow: '1px 1px 5px rgba(48, 48, 48, 0.1)',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    width: '100%',
    padding: '5px 4px',
    fontFamily: 'Roboto Condensed, sans-serif',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 'normal',
    backgroundColor: '#efefef',
    textAlign: 'left',
    color: state.isSelected ? '#afafaf' : '#303030',
    ':hover': {
      cursor: 'pointer',
      color: '#149939',
    },
    ':active': {
      textDecoration: 'underline',
    },
  }),
};

export const UserSort = ({ onChangeSelect, options }: UserSortProps) => {
  const onChange = (selectValue: any) => {
    onChangeSelect(selectValue.value);
  };

  return (
    <div className='sort'>
      <p className='sort__title'>Сортировать по:</p>
      <Select
        classNamePrefix={'sortUser'}
        isSearchable={false}
        defaultValue={options[1]}
        options={options}
        styles={customStyles}
        onChange={onChange}
      />
    </div>
  );
};
