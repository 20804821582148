import './TasksList.scss';

import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { PAGE_LIST_COLUMNS_TITLES, tasksQueryLimit } from '../../constants/constants';
import { dateParse } from '../../utils';
import { TableCell } from '../pageListBodyRow/TableCell';
import { Pagination } from '../pagination/Pagination';
import { ProjectStatus } from '../project-status/ProjectStatus';
import { RequestHandler } from '../requestHandler/RequestHandler';
import { Table } from '../table/Table';
import { TableBody } from '../tableBody/TableBody';
import { TableHeader } from '../tableHeader/TableHeader';
import { TableRow } from '../tableRow/TableRow';
import { TagsFilter } from '../tagsFilter/TagsFilter';
import { UserItem } from '../user-item/UserItem';

export const TasksList: FC<PageListType> = ({
  mappedData,
  isLoading,
  pageTitle,
  setFilterIds,
  filterIds,
  count,
  setCurrentPage,
  currentPage,
}) => {
  const location = useLocation();

  const handlePagination = (value: number) => {
    setCurrentPage && setCurrentPage(value);
  };

  const isHistoryPage = location.pathname.includes('/history');

  const navigate = useNavigate();

  return (
    <div className='tasks-list'>
      <div className='tasks-list__body'>
        <div className='tasks-list__header'>
          <h2>{pageTitle}</h2>
          {isHistoryPage && (
            <TagsFilter applyFilter={setFilterIds} filterState={filterIds} />
          )}
        </div>
        <RequestHandler loading={isLoading}>
          <Table>
            {mappedData?.length !== 0 && (
              <TableHeader columnsData={PAGE_LIST_COLUMNS_TITLES} />
            )}
            <TableBody>
              {mappedData.map((item, index) => {
                return (
                  <TableRow
                    key={`${item?.id}${item?.name}${index}`}
                    onClick={() => {
                      navigate(
                        item.parentId
                          ? `/task/${item.parentId}/subtask/${item.id}`
                          : `/task/${item.id}`,
                      );
                    }}
                  >
                    <TableCell width='10%'>
                      <span>{dateParse(item?.createdAt).date}</span>
                    </TableCell>

                    <TableCell width='40%'>
                      <>
                        <ProjectStatus projectStatus={item?.status} id={item.id as any} />
                        <span>{item?.name}</span>
                      </>
                    </TableCell>

                    <TableCell width='18%'>
                      <UserItem
                        avatar={item?.maintainer?.image}
                        name={item?.maintainer?.login}
                        id={item?.maintainer?.id as number}
                      />
                    </TableCell>

                    <TableCell width='32%'>
                      <span>{item?.project}</span>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </RequestHandler>
        {currentPage && (
          <div className='pagination__wrapper'>
            <Pagination
              paginate={handlePagination}
              currentPage={currentPage as number}
              totalCount={count as number}
              pageSize={tasksQueryLimit}
              siblingCount={1}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export type TasksListItemType = {
  id?: number | null | undefined;
  createdAt?: string | null;
  status?: number | null | undefined;
  name?: string | null;
  project?: string | null;
  isSubtask?: boolean | null;
  parentId?: number | null | undefined;
  maintainer?: {
    id?: number | null;
    login: string | null | undefined;
    image: string | null | undefined;
  } | null;
};

type PageListType = {
  isLoading: boolean;
  mappedData: TasksListItemType[];
  pageTitle: string;
  filterIds?: string[];
  setFilterIds?: (ids: string[]) => void;
  count?: number;
  setCurrentPage?: (value: number) => void;
  currentPage?: number;
};
