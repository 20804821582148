import './Switch.scss';

import React from 'react';

export const Switch2 = ({ value, handleChange }: any) => {
  return (
    <label className={`switch ${value && 'switch__true'}`}>
      <input
        type='checkbox'
        value={value}
        checked={value}
        onChange={() => {
          handleChange(!value);
        }}
      />
      <span className={`slider round ${value && 'slider__true'}`}></span>
    </label>
  );
};
