import './ExecutorsManaging.scss';

import React, { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { search } from '../../assets/icons';
import {
  Button,
  CrossIcon,
  EditIcon,
  MaintainerType,
  Modal,
  UserItem,
} from '../../components';
import {
  useAssignUserToTaskMutation,
  useUpdateSubtaskMutation,
  useUpdateTaskMutation,
} from '../../hooks/useMutations';
import { useDeassignUserFromTaskMutation } from '../../hooks/useMutations/useDeassignUserFromTaskMutation';
import { useGetAllUsersQuery } from '../../hooks/useQueries';
import { addKarma } from '../../utils';

type ExecutorsManagingPropsType = {
  title: string;
  assignees?: Array<{
    id?: number | null | undefined;
    login?: string | null | undefined;
    image?: string | null | undefined;
  } | null>;
  maintainer?: MaintainerType | null;
  taskMaintainer?: MaintainerType | null;
};

export const ExecutorsManaging: FC<ExecutorsManagingPropsType> = ({
  title,
  assignees,
  maintainer,
  taskMaintainer,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [executorIds, setExecutorIds] = useState<Array<number | null | undefined>>([]);

  const { taskId, subtaskId } = useParams();
  const location = useLocation();
  const isTaskPage = !location.pathname.includes('/subtask/');

  const [assignUserToTask] = useAssignUserToTaskMutation();
  const [deassignUserToTask] = useDeassignUserFromTaskMutation();
  const [updateTask] = useUpdateTaskMutation();
  const [updateSubtask] = useUpdateSubtaskMutation();
  const { data } = useGetAllUsersQuery({
    variables: {
      input: {
        search: searchValue,
      },
    },
  });

  useEffect(() => {
    if (assignees) {
      const assIds = assignees?.map((assignedUser) => assignedUser?.id);
      setExecutorIds(assIds);
    }
  }, [assignees]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSearchValue('');
    setIsSearchFocused(false);
    setIsEditMode(false);
  };

  const handleAddAssignees = async (
    userId?: number | null | undefined,
    name?: string | null | undefined,
  ) => {
    if (taskId && userId) {
      await assignUserToTask({
        variables: {
          taskId: Number(taskId),
          userId,
        },
      });
      setExecutorIds([...executorIds, userId]);
      toast.success(`Исполнитель ${name} добавлен!`);
    }
  };

  const handleRemoveAssignees = async (
    userId: number | null | undefined,
    name?: string | null | undefined,
  ) => {
    if (taskId && userId) {
      await deassignUserToTask({
        variables: {
          taskId: Number(taskId),
          userId,
        },
      });
      setExecutorIds([...executorIds.filter((id) => id !== userId)]);
      toast.success(`Исполнитель ${name} удалён!`);
    }
  };

  const handleBackToExecutorsList = () => {
    setIsSearchFocused(false);
    setIsEditMode(false);
  };

  const handleSetMainExecutor = async (
    id: number | null | undefined,
    name: string | null | undefined,
  ) => {
    if (id && taskId && isTaskPage) {
      await updateTask({
        variables: {
          id: Number(taskId),
          data: {
            maintainerId: id,
          },
        },
      });
    }

    if (id && subtaskId && !isTaskPage) {
      await updateSubtask({
        variables: {
          id: Number(subtaskId),
          data: {
            maintainerId: id,
          },
        },
      });
    }
    setIsSearchFocused(false);
    setIsEditMode(false);
    toast.success(`Исполнитель ${name} назначен главным!`);
  };

  const handleOpenEditMode = () => {
    setIsEditMode(true);
    setIsSearchFocused(true);
  };

  const saveAndQuit = () => {
    setIsModalOpen(false);
  };

  const maintainersList = isTaskPage ? data?.getAllUsers?.rows : assignees;
  return (
    <>
      <span
        role='button'
        onClick={() => setIsModalOpen(true)}
        style={{ cursor: 'pointer' }}
      >
        {title}
      </span>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <div
          className='executors__main'
          style={!isTaskPage ? { backgroundColor: '#E4EFFF' } : {}}
        >
          <div className='executors__description'>
            <p>Управление исполнителями:</p>
          </div>
          {isTaskPage && (
            <div className='executors__search'>
              <img src={search} alt='search' />
              <input
                type='text'
                value={searchValue}
                onChange={(e) => setSearchValue(e.currentTarget.value)}
                onFocus={() => setIsSearchFocused(true)}
                placeholder={
                  !isEditMode ? 'Выберите исполнителей' : 'Выберите главного исполнителя'
                }
              />
            </div>
          )}
          <div className='executors__list'>
            {!isSearchFocused ? (
              <>
                <div className='executors__list-maintainer'>
                  {maintainer ? (
                    <UserItem
                      name={addKarma(maintainer as any)}
                      avatar={maintainer?.image}
                      style={{
                        color: '#149939',
                        fontWeight: 'bold',
                        borderLeft: 'none',
                        paddingLeft: '0',
                      }}
                      id={maintainer?.id as number}
                    />
                  ) : (
                    <p>Назначить главного исполнителя:</p>
                  )}
                  <div title='Изменить главного исполнителя'>
                    <EditIcon onClick={handleOpenEditMode} />
                  </div>
                </div>
                {taskMaintainer && taskMaintainer.id !== maintainer?.id ? (
                  <div className='list__item'>
                    <UserItem
                      avatar={taskMaintainer?.image}
                      name={addKarma(taskMaintainer as any)}
                      style={{
                        borderLeft: 'none',
                        paddingLeft: '0',
                      }}
                      id={taskMaintainer?.id as number}
                    />
                  </div>
                ) : null}
                {assignees?.length ? (
                  assignees
                    ?.filter(
                      (assigner) =>
                        assigner?.id !== maintainer?.id &&
                        assigner?.id !== taskMaintainer?.id,
                    )
                    .map((assignUser) => {
                      if (assignUser) {
                        return (
                          <div className='list__item' key={assignUser.id}>
                            <UserItem
                              name={addKarma(assignUser as any)}
                              avatar={assignUser.image}
                              style={{
                                borderLeft: 'none',
                                paddingLeft: '0',
                              }}
                              id={assignUser?.id as number}
                            />
                            <div className='list__item-buttons' role='button'>
                              {isTaskPage && (
                                <CrossIcon
                                  color='#AFAFAF'
                                  onClick={() =>
                                    handleRemoveAssignees(assignUser.id, assignUser.login)
                                  }
                                />
                              )}
                            </div>
                          </div>
                        );
                      }
                    })
                ) : (
                  <p>Исполнители отсутствуют</p>
                )}
              </>
            ) : (
              <>
                <div className='list__item'>
                  {maintainer && (
                    <UserItem
                      avatar={maintainer?.image}
                      name={addKarma(maintainer as any)}
                      style={{ paddingLeft: '0', color: '#149939', fontWeight: 'bold' }}
                      id={maintainer?.id as number}
                    />
                  )}
                </div>
                {taskMaintainer && taskMaintainer.id !== maintainer?.id ? (
                  <div className='list__item'>
                    <UserItem
                      avatar={taskMaintainer?.image}
                      name={addKarma(taskMaintainer as any)}
                      style={{
                        borderLeft: 'none',
                        paddingLeft: '0',
                      }}
                      id={taskMaintainer?.id as number}
                    />
                    <EditIcon
                      onClick={() =>
                        handleSetMainExecutor(taskMaintainer?.id, taskMaintainer?.login)
                      }
                    />
                  </div>
                ) : null}
                {maintainersList
                  ?.filter(
                    (filteredUser) =>
                      filteredUser?.id !== maintainer?.id &&
                      filteredUser?.id !== taskMaintainer?.id,
                  )
                  .map((user) => {
                    if (user) {
                      return (
                        <div className='list__item' key={user.id}>
                          <UserItem
                            name={addKarma(user as any)}
                            avatar={user.image}
                            style={{
                              borderLeft: 'none',
                              paddingLeft: '0',
                            }}
                            id={user?.id as number}
                          />
                          <div className='list__item-buttons'>
                            {user.id !== maintainer?.id && (
                              <>
                                {!executorIds.includes(user.id) && !isEditMode && (
                                  <div
                                    key={user?.id}
                                    className='list__item-add'
                                    onClick={() =>
                                      handleAddAssignees(user.id, user.login)
                                    }
                                  >
                                    <CrossIcon color='#AFAFAF' />
                                  </div>
                                )}
                                {executorIds.includes(user.id) && !isEditMode && (
                                  <div
                                    key={user?.id}
                                    onClick={() =>
                                      handleRemoveAssignees(user.id, user.login)
                                    }
                                  >
                                    <CrossIcon color='#AFAFAF' />
                                  </div>
                                )}
                                {isEditMode && (
                                  <p
                                    style={{ margin: '0', padding: '0' }}
                                    title='Назначить главным'
                                  >
                                    <EditIcon
                                      onClick={() =>
                                        handleSetMainExecutor(user?.id, user?.login)
                                      }
                                    />
                                  </p>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      );
                    }
                  })}
              </>
            )}
          </div>
          {isSearchFocused && isModalOpen && (
            <Button
              text='Назад к исполнителям'
              onClick={handleBackToExecutorsList}
              width={'100%'}
              height={30}
              backgroundColor='#FFFFFF'
            />
          )}
          {isModalOpen && !isSearchFocused && (
            <Button
              text='Выйти'
              onClick={saveAndQuit}
              width={'100%'}
              height={30}
              backgroundColor='#FFFFFF'
            />
          )}
        </div>
      </Modal>
    </>
  );
};
