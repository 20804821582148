import './StartButtonOption.scss';

import React, { FC } from 'react';

export const StartButtonOption: FC<StartButtonOptionType> = ({
  option,
  handleStart,
  handleGetIconWithOption,
}) => {
  return (
    <div className='start-button__option' onClick={() => handleStart(option)}>
      <div className='start-button__option-icon'>{handleGetIconWithOption(option)}</div>
      <span>{option}</span>
    </div>
  );
};

type StartButtonOptionType = {
  option: string;
  handleGetIconWithOption: (option: string) => JSX.Element | undefined;
  handleStart: (option: string) => void;
};
