import { useParams } from 'react-router-dom';

import * as GENERATED from '../../graphql/generated/graphql';

export const useGetTaskAssigneesQuery = () => {
  const { taskId } = useParams();
  return GENERATED.useGetTaskAssigneesQuery({
    variables: {
      id: Number(taskId),
      assigneesLimit: 20,
    },
  });
};
