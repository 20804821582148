import './IsExcludeNullStatusTasks.scss';

import React from 'react';

import { useModalContext } from '../../../context/';
import { ModalStatuses } from '../../../types/enums';
import { AlertCircleIcons, GearwheelIcon, PauseIcons, ScaleStarIcons } from '../../icons';

export const IsExcludeNullStatusTasks = ({
  setIsExcludeNullStatusTasks,
  isExcludeNullStatusTasks,
}: {
  isExcludeNullStatusTasks?: number | null | undefined;
  setIsExcludeNullStatusTasks?: (value: number | null) => void;
}) => {
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  return (
    <div className='IsExcludeNullStatusTasks'>
      {isExcludeNullStatusTasks && (
        <div className='IsExcludeNullStatusTasks__star'>
          <ScaleStarIcons
            onClick={() => {
              handleSetModalStatus(ModalStatuses.COLOR_STATUS_ITEM_MODAL);

              handleChangeModalParameters([
                {
                  key: 'initialValues',
                  value: { show: false },
                },
              ]);
            }}
          />
        </div>
      )}

      {setIsExcludeNullStatusTasks && (
        <>
          <div
            className={
              isExcludeNullStatusTasks === 4 ? 'buttonSelectBlack' : 'buttonSelect'
            }
            onClick={() => {
              if (!setIsExcludeNullStatusTasks) return;
              if (isExcludeNullStatusTasks === 4) {
                return setIsExcludeNullStatusTasks(null);
              }
              setIsExcludeNullStatusTasks(4);
            }}
          >
            <AlertCircleIcons
              color={isExcludeNullStatusTasks === 4 ? 'white' : 'black'}
              width='20'
              height='20'
            />
          </div>
          <div
            className={
              isExcludeNullStatusTasks === 2 ? 'buttonSelectBlack' : 'buttonSelect'
            }
            onClick={() => {
              if (!setIsExcludeNullStatusTasks) return;
              if (isExcludeNullStatusTasks === 2) {
                return setIsExcludeNullStatusTasks(null);
              }
              setIsExcludeNullStatusTasks(2);
            }}
          >
            <GearwheelIcon
              color={isExcludeNullStatusTasks === 2 ? 'white' : 'black'}
              width='20'
              height='20'
            />
          </div>
          <div
            className={
              isExcludeNullStatusTasks === 3 ? 'buttonSelectBlack' : 'buttonSelect'
            }
            onClick={() => {
              if (!setIsExcludeNullStatusTasks) return;
              if (isExcludeNullStatusTasks === 3) {
                return setIsExcludeNullStatusTasks(null);
              }
              setIsExcludeNullStatusTasks(3);
            }}
          >
            <PauseIcons
              color={isExcludeNullStatusTasks === 3 ? 'white' : 'black'}
              width='20'
              height='20'
            />
          </div>
        </>
      )}
    </div>
  );
};
