export const fileSettings = ({
  blob,
  loadFileMutation,
  polygonTools,
  setFieldValue,
}: any) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob as any);
  reader.onload = function (e) {
    const image: any = new Image();
    image.src = e?.target?.result;
    image.onload = function () {
      const height = this?.height;
      const width = this?.width;
      loadFileMutation({
        variables: { data: { file: blob } },
      }).then((response: any) => {
        polygonTools &&
          polygonTools.crateNew({
            name: '',
            file: response.data?.loadFile,
            height: height,
            width: width,
          });
        if (setFieldValue) {
          setFieldValue('file', response.data?.loadFile);
          setFieldValue('height', height);
          setFieldValue('width', width);
        }
      });

      return true;
    };
  };
};
