import React from 'react';

type RemoveIconBlackType = {
  className?: string;
  color?: string;
  onClick?: () => void;
};

export const RemoveIconBlack = ({
  className,
  color = '#303030',
  onClick,
}: RemoveIconBlackType) => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M10.8125 1.96162H8.625V1.5129C8.62356 1.15633 8.48482 0.814792 8.23899 0.562659C7.99316 0.310525 7.66015 0.168225 7.3125 0.166748H4.6875C4.33985 0.168225 4.00684 0.310525 3.76101 0.562659C3.51518 0.814792 3.37644 1.15633 3.375 1.5129V1.96162H1.1875C1.07147 1.96162 0.960188 2.0089 0.878141 2.09305C0.796094 2.1772 0.75 2.29133 0.75 2.41034C0.75 2.52935 0.796094 2.64348 0.878141 2.72763C0.960188 2.81178 1.07147 2.85906 1.1875 2.85906H1.625V10.936C1.625 11.174 1.71719 11.4023 1.88128 11.5706C2.04538 11.7389 2.26794 11.8334 2.5 11.8334H9.5C9.73206 11.8334 9.95462 11.7389 10.1187 11.5706C10.2828 11.4023 10.375 11.174 10.375 10.936V2.85906H10.8125C10.9285 2.85906 11.0398 2.81178 11.1219 2.72763C11.2039 2.64348 11.25 2.52935 11.25 2.41034C11.25 2.29133 11.2039 2.1772 11.1219 2.09305C11.0398 2.0089 10.9285 1.96162 10.8125 1.96162ZM5.125 8.69239C5.125 8.8114 5.07891 8.92553 4.99686 9.00968C4.91481 9.09383 4.80353 9.14111 4.6875 9.14111C4.57147 9.14111 4.46019 9.09383 4.37814 9.00968C4.29609 8.92553 4.25 8.8114 4.25 8.69239V5.10265C4.25 4.98364 4.29609 4.8695 4.37814 4.78535C4.46019 4.7012 4.57147 4.65393 4.6875 4.65393C4.80353 4.65393 4.91481 4.7012 4.99686 4.78535C5.07891 4.8695 5.125 4.98364 5.125 5.10265V8.69239ZM7.75 8.69239C7.75 8.8114 7.70391 8.92553 7.62186 9.00968C7.53981 9.09383 7.42853 9.14111 7.3125 9.14111C7.19647 9.14111 7.08519 9.09383 7.00314 9.00968C6.92109 8.92553 6.875 8.8114 6.875 8.69239V5.10265C6.875 4.98364 6.92109 4.8695 7.00314 4.78535C7.08519 4.7012 7.19647 4.65393 7.3125 4.65393C7.42853 4.65393 7.53981 4.7012 7.62186 4.78535C7.70391 4.8695 7.75 4.98364 7.75 5.10265V8.69239ZM7.75 1.96162H4.25V1.5129C4.25 1.39389 4.29609 1.27976 4.37814 1.19561C4.46019 1.11146 4.57147 1.06418 4.6875 1.06418H7.3125C7.42853 1.06418 7.53981 1.11146 7.62186 1.19561C7.70391 1.27976 7.75 1.39389 7.75 1.5129V1.96162Z'
        fill={color}
      />
    </svg>
  );
};
