import React from 'react';

type TreadsDeleteIConsType = {
  color?: string;
  className?: string;
  onClick?: (value: any) => void;
};

export const TreadsDeleteICons = ({
  color = '#303030',
  className,
  onClick,
}: TreadsDeleteIConsType) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M7.5 7.5H8.75V14.1667H7.5V7.5ZM11.25 7.5H12.5V14.1667H11.25V7.5Z'
        fill={color}
      />
      <path
        d='M3.33337 4.64286V5.71429H4.44449V16.4286C4.44449 16.7127 4.56155 16.9853 4.76992 17.1862C4.9783 17.3871 5.26091 17.5 5.5556 17.5H14.4445C14.7392 17.5 15.0218 17.3871 15.2302 17.1862C15.4385 16.9853 15.5556 16.7127 15.5556 16.4286V5.71429H16.6667V4.64286H3.33337ZM5.5556 16.4286V5.71429H14.4445V16.4286H5.5556ZM7.77782 2.5H12.2223V3.57143H7.77782V2.5Z'
        fill={color}
      />
    </svg>
  );
};
