import './AllTredImageModal.scss';

import React, { useEffect, useState } from 'react';

import { getUserAvatar } from '../../../../utils';
import { Modal } from '../../../modal/Modal';

export const AllTredImageModal = ({ modalParameters }: any) => {
  const files = modalParameters?.initialValues?.storageFiles;

  const [modalStatus, setModalStatus] = useState<{ status: boolean; data: any }>({
    status: false,
    data: {},
  });
  const width = useWidth(files?.length);
  return (
    <div
      className='AllTredImageModal'
      style={{
        width: width,
      }}
    >
      <div className='AllTredImageModal__images'>
        {files?.map((e: any) => {
          return (
            <div
              key={e?.id}
              onClick={() => {
                setModalStatus({ status: true, data: e });
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${getUserAvatar(e?.path)})`,
                }}
                className='AllTredImageModal__image'
              ></div>
            </div>
          );
        })}
      </div>
      <Modal
        isOpen={modalStatus?.status}
        onClose={() => {
          setModalStatus({ status: false, data: {} });
        }}
      >
        <img
          src={getUserAvatar(modalStatus?.data?.path)}
          className='AllTredImageModal__imageModal'
          loading='lazy'
        />
        <div className='AllTredImageModal__text'>
          {modalStatus?.data?.threadFileWrap?.comment}
        </div>
      </Modal>
    </div>
  );
};

const useWidth = (lenght: number | undefined) => {
  const [width, setWidth] = useState('920px');
  useEffect(() => {
    setWidth(calcWidth());
  }, [lenght]);

  const calcWidth = () => {
    if (lenght === 1) {
      return '300px';
    }
    if (lenght === 2) {
      return '600px';
    }
    return '920px';
  };

  return width;
};
