import { FormikValues } from 'formik';
import React, { FC } from 'react';

import { OptionsType } from '../../constants/constants';
import { useModalContext } from '../../context';
import {
  File,
  Poligon as PoligonType,
  PoligonObject as PoligonObjectType,
} from '../../graphql/generated/graphql';
import { OptionsStatuses } from '../../types/enums';
import {
  ColorType,
  MenuTextActionsInitialValuesType,
  polygonToolsChangeParameterType,
  polygonToolsDeleteType,
  RectType,
} from '../../types/types';
import { MenuBlockActions, MenuTextActions } from './index';

export const PolygonBlockMenu: FC<PolygonBlockMenuType> = ({
  rectanglesArray,
  blockId,
  removeConfirmTitle,
  onRemove,
  changeParameter,
  initialColorId,
  onSetColor,
  colors,
  sendOptions,
  onMenuTextActionsSubmit,
  setIsEditState,
  selectedIdRect,
  initialInfoValues,
  onSaveParentLinks,
  linkModalHandle,
  poligon,
  polygonObject,
  file,
  changeImage,
  blockItem,
  refScroll,
}) => {
  const { optionsStatus } = useModalContext();
  const selectRect = rectanglesArray?.filter((e: RectType) => e.id === blockId)[0];

  return (
    <>
      {optionsStatus !== OptionsStatuses.EDIT_TEXT_MENU ? (
        <MenuBlockActions
          blockId={blockId}
          blockItem={blockItem}
          selectRect={selectRect}
          colors={colors}
          initialColorId={initialColorId}
          removeConfirmTitle={removeConfirmTitle}
          sendOptions={sendOptions}
          onSetColor={onSetColor}
          onRemove={onRemove}
          changeParameter={changeParameter}
          setIsEditState={setIsEditState}
          selectedIdRect={selectedIdRect}
          initLinkValue={selectRect?.parentLink}
          onSaveParentLinks={onSaveParentLinks}
          linkModalHandle={linkModalHandle}
          initialInfoValues={initialInfoValues}
          poligon={poligon}
          polygonObject={polygonObject}
          file={file}
          changeImage={changeImage}
          refScroll={refScroll}
        />
      ) : (
        <MenuTextActions
          blockId={blockId}
          onMenuTextActionsSubmit={onMenuTextActionsSubmit}
          initialValues={selectRect?.textObj}
          selectRect={selectRect}
          setIsEditState={setIsEditState}
          changeParameter={changeParameter}
          refScroll={refScroll}
        />
      )}
    </>
  );
};

type PolygonBlockMenuType = {
  rectanglesArray: Array<RectType>;
  blockId: number | string | null;
  initialColorId?: number;
  initialValues?: MenuTextActionsInitialValuesType;
  initialInfoValues?: { info?: string };
  removeConfirmTitle?: string | null;
  colors: ColorType[];
  sendOptions: OptionsType;
  onRemove: ({ id }: polygonToolsDeleteType) => void;
  changeParameter: ({ id, key, value }: polygonToolsChangeParameterType) => void;
  onSetColor: (color: string) => void;
  onMenuTextActionsSubmit: (values: FormikValues) => void;
  setIsEditState: React.Dispatch<React.SetStateAction<boolean>>;
  selectedIdRect: string | number | null;
  onSaveParentLinks: (values: FormikValues) => void;
  linkModalHandle: any;
  poligon: PoligonType;
  polygonObject: PoligonObjectType | undefined;
  file?: File;
  changeImage: (value: File) => void;
  blockItem: ({ id, value }: { id: number | string; value: boolean }) => void;
  refScroll: any;
};
