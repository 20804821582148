import './CreateTreadModal.scss';

import React from 'react';

import { ForumCreateTread } from '../../../index';

type CreateTreadModalType = {
  isOpen: boolean;
  onClose: () => void;
  modalParameters?: any;
};

export const CreateTreadModal = ({ onClose, modalParameters }: CreateTreadModalType) => {
  return (
    <div className='CreateTreadModal'>
      <ForumCreateTread
        initialValues={modalParameters?.initialValues}
        onClose={onClose}
        onCallback={modalParameters?.onCallback}
        title={modalParameters?.title}
        buttonText={modalParameters?.buttonText}
      />
    </div>
  );
};
