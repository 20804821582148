import './PulsometerModals.scss';

import moment from 'moment';
import React from 'react';

import {
  PulseMonitorStatisticByMonthEntity,
  usePulseMonitorQuery,
} from '../../../../graphql/generated/graphql';
import { RequestHandler } from '../../../requestHandler/RequestHandler';

export const PulsometerModals = ({ modalParameters }: any) => {
  const selectDate = moment(modalParameters?.initialValues?.selectDayDate);
  const year = selectDate.year();

  const { currentMonthName, previousMonthName, prePreviousMonthName } = monthName();

  const { data, loading } = usePulseMonitorQuery({
    variables: {
      year: year,
    },
    fetchPolicy: 'network-only',
  });
  const array = data?.pulseMonitor?.rows;

  return (
    <div className='pulsometerModals'>
      <RequestHandler loading={loading}>
        {array?.length === 0 ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '20px',
              width: '100%',
            }}
          >
            Нет данных
          </div>
        ) : (
          <div
            style={{
              margin: 'auto',
            }}
          >
            <div
              className={`pulsometerModals__table ${
                array && array?.length <= 20 ? 'pulsometerModals__table-center' : ''
              }`}
            >
              <div className='pulsometerModals__scale'>
                {persent?.map((e) => {
                  const tenth = e % 10 === 0;
                  return (
                    <div
                      key={e}
                      className='pulsometerModals__scale-cell'
                      style={
                        tenth
                          ? {
                              borderTop: '3px solid white',
                            }
                          : {}
                      }
                    >
                      <div className='pulsometerModals__scale-item'>{e}</div>
                    </div>
                  );
                })}
              </div>
              {array?.map((e: any) => {
                return (
                  <Item
                    key={e?.scaleMapFile?.id}
                    statisticByMonth={e?.statisticByMonth}
                    selectDate={selectDate}
                    name={e?.scaleMapFile?.name}
                  />
                );
              })}
            </div>
            <div className='pulsometerModals__notes'>
              <div className='pulsometerModals__notes-item'>
                <div className='pulsometerModals__notes-text'>Текущий месяц</div>
                <div
                  className='pulsometerModals__notes-color'
                  style={{
                    backgroundColor: 'green',
                  }}
                ></div>
                <div>{currentMonthName}</div>
              </div>
              <div className='pulsometerModals__notes-item'>
                <div className='pulsometerModals__notes-text'>Предыдущий месяц</div>

                <div
                  className='pulsometerModals__notes-color'
                  style={{
                    backgroundColor: 'gray',
                  }}
                ></div>
                <div>{previousMonthName}</div>
              </div>
              <div className='pulsometerModals__notes-item'>
                <div className='pulsometerModals__notes-text'>Позапрошлый месяц</div>

                <div
                  className='pulsometerModals__notes-color'
                  style={{
                    backgroundColor: 'red',
                  }}
                ></div>
                <div>{prePreviousMonthName}</div>
              </div>
            </div>
          </div>
        )}
      </RequestHandler>
    </div>
  );
};

const persent = Array.from({ length: 20 }, (_, index) => 20 - index);

type typeItem = {
  statisticByMonth: Array<PulseMonitorStatisticByMonthEntity>;
  selectDate: any;
  name: string;
};

const Item = ({ statisticByMonth, selectDate, name }: typeItem) => {
  const { currentMonthPercent, previousMonthPercent, prePreviousMonthPercent } =
    findMonthData({ data: statisticByMonth, selectDate });

  return (
    <div className='pulsometerModals__table-item'>
      <div className='pulsometerModals__table-head'>{name}</div>
      {persent?.map((e) => {
        const { current, previous, prePrevious } = calcBackgroundColor({
          percent: e,
          currentMonthPercent,
          previousMonthPercent,
          prePreviousMonthPercent,
        });
        const tenth = e % 10 === 0;

        const backgroundColor = () => {
          if (current) {
            return '';
          }
          if (prePrevious) {
            return '';
          }
          if (previous) {
            return 'gray';
          }
        };

        return (
          <div
            key={e}
            className='pulsometerModals__table-cell'
            style={
              tenth
                ? {
                    borderTop: '3px solid black',
                    backgroundColor: backgroundColor(),
                  }
                : { backgroundColor: backgroundColor() }
            }
          >
            {current && (
              <div
                style={{
                  backgroundColor: 'green',
                }}
                className='pulsometerModals__table-inner'
              ></div>
            )}
            {prePrevious && (
              <div
                style={{
                  backgroundColor: 'red',
                }}
                className='pulsometerModals__table-inner'
              ></div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const calcBackgroundColor = ({
  percent,
  currentMonthPercent,
  previousMonthPercent,
  prePreviousMonthPercent,
}: any) => {
  let current = false;
  let previous = false;
  let prePrevious = false;
  if (currentMonthPercent) {
    if (percent >= currentMonthPercent && percent - 1 < currentMonthPercent) {
      current = true;
    }
  }
  if (previousMonthPercent) {
    if (percent - 1 < previousMonthPercent) {
      previous = true;
    }
  }
  if (prePreviousMonthPercent) {
    if (percent >= prePreviousMonthPercent && percent - 1 < prePreviousMonthPercent) {
      prePrevious = true;
    }
  }
  return { current, previous, prePrevious };
};

function fillMissingMonths(data: any) {
  const result = [];

  for (let i = 1; i <= 12; i++) {
    const existingMonth = data?.find((item: any) => item?.monthNumber === i);

    if (existingMonth) {
      result.push(existingMonth);
    } else {
      result.push({
        __typename: 'PulseMonitorStatisticByMonthEntity',
        monthNumber: i,
        percent: 0,
      });
    }
  }

  return result;
}

const findMonthData = ({ data }: any) => {
  const filledData = fillMissingMonths(data);
  const currentMonth = moment().month() + 1;
  const currentMonthData = filledData?.find((item) => item?.monthNumber === currentMonth);
  const currentMonthPercent = currentMonthData
    ? Math.ceil(currentMonthData?.percent)
    : null;

  const previousMonth = moment().subtract(1, 'months').month() + 1;
  const previousMonthData = filledData?.find(
    (item) => item?.monthNumber === previousMonth,
  );
  const previousMonthPercent = previousMonthData
    ? Math.ceil(previousMonthData?.percent)
    : null;

  const prePreviousMonth = moment().subtract(2, 'months').month() + 1;
  const prePreviousMonthData = filledData?.find(
    (item) => item?.monthNumber === prePreviousMonth,
  );
  const prePreviousMonthPercent = prePreviousMonthData
    ? Math.ceil(prePreviousMonthData?.percent)
    : null;

  return {
    currentMonthPercent,
    previousMonthPercent,
    prePreviousMonthPercent,
  };
};

const monthName = () => {
  const currentMonthNumber = moment().month() + 1;
  const previousMonthNumber = moment().subtract(1, 'months').month() + 1;
  const prePreviousMonthNumber = moment().subtract(2, 'months').month() + 1;

  const translateToRussian = (monthNumber: number): string => {
    const translations = [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ];

    return translations[monthNumber - 1];
  };

  return {
    currentMonthName: translateToRussian(currentMonthNumber),
    previousMonthName: translateToRussian(previousMonthNumber),
    prePreviousMonthName: translateToRussian(prePreviousMonthNumber),
  };
};
