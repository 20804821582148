import './TreadItem.scss';
import 'quill/dist/quill.snow.css';

import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ROLES } from '../../../constants/constants';
import { useModalContext } from '../../../context';
import { ForumMessage, ForumThread } from '../../../graphql/generated/graphql';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { ModalStatuses, TemplateState } from '../../../types/enums';
import { addKarma, avatarUrlCreate, getUserAvatar, wrapContainer } from '../../../utils';
import { ForumAnswerIcons, ForumIconsTree, ForumQuoteIcons } from '../../icons';
import { ForumUserHover, HoverMenuTreadItem, Quote, TreadLink } from '../../index';

type TreadItemType = {
  updateForumMessageMutation: (value: any) => void;
  removeForumMessageMutation: (value: any) => void;
  createForumMessageMutation: (value: any) => void;
  message: ForumMessage;
  isAccessEdit: boolean;
  sectionRole?: string;
  currentUserData: any;
  treadClose?: boolean;
  nextTreadAuthorId?: any;
  tread: ForumThread;
};

type userAccessEditType = {
  isAccessEdit: boolean;
  user: any;
  messageId: number;
  sectionRole?: string;
};

const userAccessEdit = ({
  isAccessEdit,
  user,
  messageId,
  sectionRole,
}: userAccessEditType) => {
  if (user?.role === ROLES.ADMIN) return true;
  if (!sectionRole) {
    if (user?.id === messageId) return true;
    return false;
  }
  if (user?.id === messageId) return true;
  return isAccessEdit;
};

export const TreadItem = ({
  updateForumMessageMutation,
  removeForumMessageMutation,
  createForumMessageMutation,
  message,
  isAccessEdit,
  sectionRole,
  currentUserData,
  treadClose,
  nextTreadAuthorId,
  tread,
}: TreadItemType) => {
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();
  const { treadID } = useParams();
  const userEdit = userAccessEdit({
    isAccessEdit,
    user: currentUserData,
    messageId: message?.user?.id as number,
    sectionRole: sectionRole,
  });

  const hideHeader = message?.user?.id === nextTreadAuthorId;

  const [hoverMenuAction, setHoverMenuAction] = useState(false);
  const ref = useRef(null);

  useClickOutside(ref, () => setHoverMenuAction(false));
  const avatarUrl = avatarUrlCreate({
    tread,
    userId: tread?.author?.id as number,
    message,
  });

  const handleOpenImageModal = () => {
    handleSetModalStatus(ModalStatuses.TRED_OPEN_IMAGE_MODAL);
    handleChangeModalParameters([
      {
        key: 'initialValues',
        value: {
          url: getUserAvatar(message?.imagePath),
        },
      },
    ]);
  };

  return (
    <div className='TreadItem__wrapper'>
      <div className='TreadItem__wrapper-inner'>
        {message?.viewType === TemplateState.left && message?.imagePath && (
          <img
            src={getUserAvatar(message?.imagePath)}
            alt='add'
            className='TreadItem__comment-image pr30'
            onClick={handleOpenImageModal}
          />
        )}
        <div className='TreadItem'>
          <div>
            {hideHeader ? (
              <div className='TreadItem__headerHide'>
                <div className='TreadItem__data'>
                  {userEdit ? (
                    <span className='HoverMenu__wrapper' ref={ref}>
                      <ForumIconsTree
                        onClick={() => {
                          setHoverMenuAction(!hoverMenuAction);
                        }}
                        color={'#303030'}
                      />
                      {hoverMenuAction && (
                        <HoverMenuTreadItem
                          updateForumMessageMutation={updateForumMessageMutation}
                          removeForumMessageMutation={removeForumMessageMutation}
                          message={message}
                        />
                      )}
                    </span>
                  ) : (
                    <span></span>
                  )}
                </div>
              </div>
            ) : (
              <div className='TreadItem__header'>
                <div className='TreadItem__avatar'>
                  <ForumUserHover user={message?.user}>
                    <img src={avatarUrl} alt='add' className='TreadItem__avatar-image' />
                  </ForumUserHover>
                  <div className='TreadItem__avatar-wrapper'>
                    <p className='TreadItem__avatar-name'> {addKarma(message?.user)}</p>
                    <p className='TreadItem__avatar-count'>
                      Сообщений: {message?.user?.forumMessageCount}
                    </p>
                  </div>
                </div>
                <div className='TreadItem__data'>
                  {userEdit ? (
                    <span className='HoverMenu__wrapper' ref={ref}>
                      <ForumIconsTree
                        onClick={() => {
                          setHoverMenuAction(!hoverMenuAction);
                        }}
                      />
                      {hoverMenuAction && (
                        <HoverMenuTreadItem
                          updateForumMessageMutation={updateForumMessageMutation}
                          removeForumMessageMutation={removeForumMessageMutation}
                          message={message}
                        />
                      )}
                    </span>
                  ) : (
                    <span></span>
                  )}
                </div>
              </div>
            )}
            {message?.quote && <Quote message={message?.quote as ForumMessage} />}
            {message?.reply && <TreadLink link={message?.reply} tread={tread} />}

            <div className='TreadItem__comment'>
              <div
                className='ql-editor'
                dangerouslySetInnerHTML={{ __html: wrapContainer(message?.text) }}
                style={{
                  width: message?.viewType === TemplateState.center ? '100%' : undefined,
                }}
              ></div>
            </div>
            {!treadClose && (
              <div className='TreadItem__ButtonWrapper-outer'>
                <div
                  className='TreadItem__ButtonWrapper-inner'
                  onClick={() => {
                    handleSetModalStatus(ModalStatuses.FORUM_TREAD_MESSAGE);
                    handleChangeModalParameters([
                      {
                        key: 'title',
                        value: 'Ответить на сообщение',
                      },
                      {
                        key: 'initialValues',
                        value: {
                          description: '',
                          link: message,
                        },
                      },
                      {
                        key: 'onCallback',
                        value: (value: any) => {
                          createForumMessageMutation({
                            variables: {
                              data: {
                                forumThreadId: parseInt(treadID as string),
                                text: value?.description,
                                viewType: value?.viewType,
                                image:
                                  typeof value?.image !== 'string'
                                    ? value?.image
                                    : undefined,
                                imagePath:
                                  typeof value?.image === 'string'
                                    ? value?.image
                                    : undefined,
                                replyId: parseInt(message.id as any),
                              },
                            },
                          });
                        },
                      },
                    ]);
                  }}
                >
                  <ForumAnswerIcons /> Ответить
                </div>
                <div
                  className='TreadItem__ButtonWrapper-inner'
                  onClick={() => {
                    handleSetModalStatus(ModalStatuses.FORUM_TREAD_MESSAGE);
                    handleChangeModalParameters([
                      {
                        key: 'title',
                        value: 'Цитировать сообщение',
                      },
                      {
                        key: 'initialValues',
                        value: {
                          description: '',
                          message: message,
                        },
                      },
                      {
                        key: 'onCallback',
                        value: (value: any) => {
                          createForumMessageMutation({
                            variables: {
                              data: {
                                forumThreadId: parseInt(treadID as string),
                                text: value?.description,
                                viewType: value?.viewType,
                                image:
                                  typeof value?.image !== 'string'
                                    ? value?.image
                                    : undefined,
                                imagePath:
                                  typeof value?.image === 'string'
                                    ? value?.image
                                    : undefined,
                                quoteId: parseInt(message.id as any),
                              },
                            },
                          });
                        },
                      },
                    ]);
                  }}
                >
                  <ForumQuoteIcons /> Цитировать
                </div>
              </div>
            )}

            {message?.viewType === TemplateState.center && (
              <div className='TreadItem__line'></div>
            )}
          </div>
        </div>
        {message?.viewType === TemplateState.right && message?.imagePath && (
          <img
            src={getUserAvatar(message?.imagePath)}
            alt='add'
            className='TreadItem__comment-image pl30'
            onClick={handleOpenImageModal}
          />
        )}
      </div>
      {message?.viewType !== TemplateState.center && (
        <div className='TreadItem__line2'></div>
      )}
    </div>
  );
};
