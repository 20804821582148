import './Modal.scss';

import React, { FC } from 'react';
import ReactModal from 'react-modal';

export type ModalProps = {
  isOpen: boolean;
  onClose?: () => void;
  overflow?: string;
};

export const Modal: FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  overflow = 'visible',
}) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      padding: 'auto',
      overflow: overflow,
      border: 'none',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      zIndex: '999999',
    },
  };

  const onCloseHandler = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <ReactModal
      closeTimeoutMS={300}
      style={customStyles}
      onRequestClose={onCloseHandler}
      isOpen={isOpen}
      ariaHideApp={false}
      overlayClassName='defaultModal-overlay'
    >
      {children}
    </ReactModal>
  );
};
