import './ExpensesRow.scss';

import React, { FC } from 'react';

import { TagList } from '../../../graphql/generated/graphql';
import { ExpensesPriceCell } from '../expensesPriceCell/ExpensesPriceCell';
import { ExpensesTagCell } from '../expensesTagCell/ExpensesTagCell';
import { ExpensesTaskCell } from '../expensesTaskCell/ExpensesTaskCell';

export const ExpensesRow: FC<ExpensesRowType> = ({ task }) => {
  return (
    <div className='projectAccordion__tasksList'>
      <div key={task?.id} className='projectAccordion__taskItem'>
        <ExpensesTagCell tags={task?.tags} />
        <ExpensesPriceCell realizationPerMonthAmount={task?.realizationPerMonthAmount} />
        <ExpensesTaskCell
          id={task?.id}
          name={task?.name}
          status={task?.status}
          parentId={task?.parentId}
          code={task?.code}
        />
      </div>
    </div>
  );
};

export type ExpensesRowType = {
  task?: ExpensesTaskType | null;
};

export type ExpensesTaskType = {
  id?: number;
  number?: number;
  name?: string;
  code?: string | null;
  parentId?: number | null;
  realizationPrice?: number | null;
  realizationPerMonthAmount?: number | null;
  status?: { id?: number; color?: string } | null;
  project?: { id?: number; number?: number | null };
  tags: TagList;
};
