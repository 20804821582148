import { ForumMessage, ForumThread } from '../graphql/generated/graphql';
import { getUserAvatar } from './index';

export const avatarUrlCreate = ({
  tread,
  userId,
  message,
}: {
  tread: ForumThread;
  userId: number;
  message?: ForumMessage;
}) => {
  if (tread?.author?.id === userId) {
    if (tread?.authorAvatarPath) {
      return getUserAvatar(tread?.authorAvatarPath);
    }
  }
  if (message) return getUserAvatar(message?.user?.image);
  return getUserAvatar(tread?.author?.image);
};
