import './UnderlineTextIcon.scss';

import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const UnderlineTextIcon: FC<UnderlineTextIconType> = ({
  width = '20px',
  height = '20px',
  className = 'underline-text-icon',
  color = '#FFFFFF',
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M15.5 5.625V17H14.0625V12.2969C13.2708 12.5677 12.4297 12.7031 11.5391 12.7031C10.4505 12.7031 9.64062 12.4245 9.10938 11.8672C8.57812 11.3099 8.30208 10.4609 8.28125 9.32031V5.625H9.71875V9.24219C9.72396 10.0391 9.86458 10.6094 10.1406 10.9531C10.4167 11.2969 10.8828 11.4688 11.5391 11.4688C12.3568 11.4688 13.1979 11.3307 14.0625 11.0547V5.625H15.5Z'
        fill={color}
      />
      <path d='M7.19531 18.5625H16.8047V19.3438H7.19531V18.5625Z' fill={color} />
    </svg>
  );
};

type UnderlineTextIconType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
>;
