import './ScaleListI.scss';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ROLES } from '../../constants/constants';
import { useAuthContext, useModalContext } from '../../context';
import {
  Project as ProjectType,
  useCreateScaleMapFileMutation,
  useUpdateChronographSampleMutation,
  useUpdateScaleMapFileMutation,
} from '../../graphql/generated/graphql';
import { ModalStatuses } from '../../types/enums';
import { Button, IsExcludeNullStatusTasks } from '../index';
import { RequestHandler } from '../requestHandler/RequestHandler';
import { ScaleAccordion } from './scaleAccordion/ScaleAccordion';

type ScaleListType = {
  projectsList?: Array<ProjectType>;
  refetchProjects: () => void;
  isExcludeNullStatusTasks: number | undefined | null;
  setIsExcludeNullStatusTasks?: (value: number | null) => void;
  isArchived: boolean;
  setIsArchived: (value: boolean) => void;
  loading: boolean;
  upFile: ({
    currentOrder,
    currentID,
  }: {
    currentOrder: number;
    currentID: number;
  }) => void;
  downFile: ({
    currentOrder,
    currentID,
  }: {
    currentOrder: number;
    currentID: number;
  }) => void;
};

export const ScaleListI = ({
  projectsList,
  refetchProjects,
  isExcludeNullStatusTasks,
  setIsExcludeNullStatusTasks,
  setIsArchived,
  isArchived,
  upFile,
  downFile,
  loading,
}: ScaleListType) => {
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();
  const { currentUserData } = useAuthContext();

  const [createScaleMapFileMutation] = useCreateScaleMapFileMutation({
    onCompleted: () => {
      refetchProjects();
    },
  });

  const [updateScaleMapFileMutation] = useUpdateScaleMapFileMutation({
    onCompleted: () => {
      refetchProjects();
    },
  });

  const [updateChronographSampleMutation] = useUpdateChronographSampleMutation({
    onCompleted: () => {
      refetchProjects();
    },
  });

  const navigate = useNavigate();

  return (
    <div className='scale-list-i'>
      <div className='scale-list-i__body'>
        <div className='scale-list-i__title'>
          <div className='scale-list-i__h2-wrapper'>
            <h2>Scale map</h2>
            {currentUserData?.role !== ROLES.GUEST && (
              <Button
                onClick={() => {
                  navigate('/file/graphicsI');
                }}
                width='200px'
                height='25px'
                text='График'
              />
            )}
          </div>

          <div className='selectOuter'>
            <div className='button-add__wrapper'>
              <IsExcludeNullStatusTasks
                setIsExcludeNullStatusTasks={setIsExcludeNullStatusTasks}
                isExcludeNullStatusTasks={isExcludeNullStatusTasks}
              />
              {(currentUserData?.role === ROLES.ADMIN ||
                currentUserData?.role === ROLES.MODERATOR) && (
                <Button
                  onClick={() => {
                    handleSetModalStatus(ModalStatuses.ADD_TASK_IN_PLAN_MODAL);
                    handleChangeModalParameters([
                      {
                        key: 'onCallback',
                        value: (value: any, id: any) => {
                          updateChronographSampleMutation({
                            variables: {
                              input: {
                                id: id,
                                sampleItems: value as any,
                              },
                            },
                          });
                        },
                      },
                    ]);
                  }}
                  backgroundColor='#303030'
                  width='200px'
                  height='25px'
                  text='Публичный шаблон'
                  color='white'
                  margin='0px 10px 0px 0px'
                />
              )}

              <Button
                onClick={() => {
                  handleSetModalStatus(ModalStatuses.ADD_FILE_USER_MODAL);
                  handleChangeModalParameters([
                    {
                      key: 'onCallback',
                      value: (value: { name: string; projectId: string }) => {
                        createScaleMapFileMutation({
                          variables: {
                            input: value,
                          },
                        });
                      },
                    },
                    {
                      key: 'initialValues',
                      value: undefined,
                    },
                  ]);
                }}
                backgroundColor='#149939'
                width='200px'
                height='25px'
                text='Создать файл'
                color='white'
              />
            </div>
          </div>
        </div>
        <div className='horizontal__line'></div>
        <div className='scale-list-i__table'>
          <div className='scale-list-i__row'>
            <div className='scale-list-i__column scale-list-i__projects'>Проект</div>
            <div className='scale-list-i__column scale-list-i__task'>Всего задач</div>
            <div className='scale-list-i__column scale-list-i__name'>Название файла</div>
            <button
              onClick={() => {
                setIsArchived(!isArchived);
              }}
              className='buttonArchive'
            >
              {!isArchived ? 'Показать архив' : 'Убрать архив'}
            </button>
          </div>
          <RequestHandler loading={loading}>
            {projectsList?.map((item) => (
              <ScaleAccordion
                key={item?.id}
                project={item}
                createScaleMapFileMutation={createScaleMapFileMutation}
                updateScaleMapFileMutation={updateScaleMapFileMutation}
                isExcludeNullStatusTasks={isExcludeNullStatusTasks}
                upFile={upFile}
                downFile={downFile}
              />
            ))}
          </RequestHandler>
        </div>
      </div>
    </div>
  );
};
