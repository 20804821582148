import './CalendarPicker.scss';

import { ru } from 'date-fns/locale';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

import { months } from '../../../constants/constants';

type CalendarPickerPropTypes = {
  changeMonthHandler?: (date: Date) => void;
};

export const CalendarPicker = ({ changeMonthHandler }: CalendarPickerPropTypes) => {
  const dateNow = new Date();

  const [date, setDate] = useState<Date>(dateNow);

  const [value, setValue] = useState(
    `${months[dateNow.getMonth()]} ${dateNow?.getFullYear()}`,
  );

  const changeMonthBySlider = (num: number) => {
    const newDate = date?.setMonth(date.getMonth() + num);
    const newDateObj = new Date(newDate);

    setDate(newDateObj);
    setValue(`${months[newDateObj.getMonth()]} ${newDateObj?.getFullYear()}`);

    if (changeMonthHandler) {
      changeMonthHandler(newDateObj);
    }
  };

  const changeMonthByPicker = (date: Date | null) => {
    if (date) {
      setDate(date);
      setValue(`${months[date.getMonth()]} ${date?.getFullYear()}`);
      if (changeMonthHandler) {
        changeMonthHandler(date);
      }
    }
  };

  return (
    <div className='calendarPicker'>
      <div className='calendarPicker__inputWrap'>
        <div
          className='calendarPicker__navIcon calendarPicker__navIcon--prev'
          onClick={() => changeMonthBySlider(-1)}
        >
          <div />
        </div>
        <DatePicker
          selected={date}
          value={value}
          onChange={(date) => changeMonthByPicker(date)}
          locale={ru}
          dateFormat='MMM yyyy'
          autoComplete='off'
          showMonthYearPicker
          showFourColumnMonthYearPicker
          withPortal
        />
        <div
          className='calendarPicker__navIcon calendarPicker__navIcon--next'
          onClick={() => changeMonthBySlider(1)}
        >
          <div />
        </div>
      </div>
    </div>
  );
};
