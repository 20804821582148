import PropTypes from 'prop-types';
import React, { createContext, FC, useContext, useState } from 'react';

import { User } from '../graphql/generated/graphql';

const TredsContext = createContext<any>('');
const useTredsContext = () => {
  const context = useContext(TredsContext);
  if (!context) {
    throw new Error('useTredsContext must be used within a TredsProvider');
  }
  return context;
};

const TredsProvider: FC = ({ children }) => {
  const [searchTred, setSerchTred] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectUser, setSelectUser] = useState<User | undefined>();
  const [isAll, setIsAll] = useState(false);

  return (
    <TredsContext.Provider
      value={{
        searchTred,
        setSerchTred,
        currentPage,
        setCurrentPage,
        selectUser,
        setSelectUser,
        isAll,
        setIsAll,
      }}
    >
      {children}
    </TredsContext.Provider>
  );
};

TredsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { TredsProvider, useTredsContext };
