import './AddFileTaskStatusModal.scss';

import { Form, Formik } from 'formik';
import React from 'react';

import { useModalContext } from '../../../../context/';
import { ModalStatuses } from '../../../../types/enums';
import {
  addFileTaskStatusSchema,
  addFileTaskStatusSchemaCol1,
} from '../../../../validation/schemas';
import { RemoveIconBlack, SaveIconWhite } from '../../../icons/index';
import { AppInput, Button, Checkbox } from '../../../index';

type AddFileTaskStatusModalType = {
  isOpen: boolean;
  onClose: () => void;
  modalParameters?: any;
};

export const AddFileTaskStatusModal = ({
  onClose,
  modalParameters,
}: AddFileTaskStatusModalType) => {
  const { handleSetModalStatus } = useModalContext();

  return (
    <div className='addFileTaskStatusModal'>
      <Formik
        initialValues={modalParameters?.initialValues || { status: '', name: '' }}
        onSubmit={(e) => {
          modalParameters.onCallback(e);
          onClose();
        }}
        validationSchema={
          modalParameters?.col === 'col1'
            ? addFileTaskStatusSchemaCol1
            : addFileTaskStatusSchema
        }
      >
        {({ setFieldValue, values, errors }) => {
          return (
            <Form style={{ width: 'auto' }}>
              {modalParameters?.col === 'col3' && (
                <div className='addFileTaskStatusModal__message'>
                  В этой записке используются{' '}
                  <span className='addFileTaskStatusModal__message-black'>ГЛАГОЛЫ</span>
                  . Наша задача задать вопросы появившемся ранее мыслям. Рекомендуемые
                  вопросы для создания записки:
                  <br />
                  <br />
                  [Как решаем] — [Почему сейчас] — [Что мешает] — [Кто делает] — [Зачем] —
                  [Когда] — [Насколько это важно сейчас] — [Что делаем]
                </div>
              )}
              <div
                className='status__wrapper'
                style={{
                  border: errors?.status ? '1px solid red' : '',
                }}
              >
                <div>Статус:</div>
                <div
                  onClick={() => {
                    setFieldValue('status', 1);
                  }}
                  className={`${
                    values.status === 1 ? 'status__select-border' : ''
                  } status__element`}
                  style={{
                    backgroundColor: '#B3E4C1',
                  }}
                ></div>
                <div
                  onClick={() => {
                    setFieldValue('status', 2);
                  }}
                  className={`${
                    values.status === 2 ? 'status__select-border' : ''
                  } status__element`}
                  style={{
                    backgroundColor: '#FAF2A7',
                  }}
                ></div>
                <div
                  onClick={() => {
                    setFieldValue('status', 3);
                  }}
                  className={`${
                    values.status === 3 ? 'status__select-border' : ''
                  } status__element`}
                  style={{
                    backgroundColor: '#EFEFEF',
                  }}
                ></div>
                <div
                  onClick={() => {
                    setFieldValue('status', 4);
                  }}
                  className={`${
                    values.status === 4 ? 'status__select-border' : ''
                  } status__element`}
                  style={{
                    backgroundColor: '#E80025',
                  }}
                ></div>
              </div>
              <AddChecbox
                isAttach={modalParameters?.initialValues?.isAttach}
                setFieldValue={setFieldValue}
                values={values}
              />

              {modalParameters?.initialValues ? (
                <>
                  <Button
                    text={'Сохранить'}
                    type='submit'
                    width='100%'
                    height='25px'
                    margin='5px 0 0 0'
                  >
                    <SaveIconWhite className='icons' />
                  </Button>
                  <Button
                    text={'Удалить'}
                    width='100%'
                    height='25px'
                    margin='5px 0 0 0'
                    onClick={() => {
                      modalParameters.onCallbackDelete();
                      onClose();
                    }}
                  >
                    <RemoveIconBlack className='icons' />
                  </Button>
                  {modalParameters?.initialValues?.fileId && (
                    <Button
                      text={'Перенести'}
                      width='100%'
                      height='25px'
                      margin='20px 0 0 0'
                      onClick={() => {
                        handleSetModalStatus(ModalStatuses.MOVE_SCALE_TASK_MODAL);
                      }}
                    ></Button>
                  )}
                </>
              ) : (
                <Button
                  text={'Добавить'}
                  type='submit'
                  width='100%'
                  height='25px'
                  margin='5px 0 0 0'
                />
              )}
              {modalParameters?.col === 'col2' && (
                <div className='addFileTaskStatusModal__comment'>
                  В этой записке рекомендуется записывать
                  <span className='addFileTaskStatusModal__comment-black'> Сущность</span>
                  , которая является основной мыслью для входа в производственный план.
                  Это может быть просто{' '}
                  <span className='addFileTaskStatusModal__comment-underline'>ИДЕЯ</span>,
                  просто{' '}
                  <span className='addFileTaskStatusModal__comment-underline'>
                    ЗАДАЧА
                  </span>
                  , просто{' '}
                  <span className='addFileTaskStatusModal__comment-underline'>МЫСЛЬ</span>
                  , которая образовалась у вас в голове.
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const AddChecbox = ({ isAttach, values, setFieldValue }: any) => {
  if (!isAttach) {
    return (
      <div style={{ marginTop: '25px' }}>
        <AppInput name={'name'} label='Название:' placeholder='Введите название' />
      </div>
    );
  }
  return (
    <div className='addFileTaskStatusModal__custom-wrapper'>
      <div className='addFileTaskStatusModal__custom'>
        <div className='addFileTaskStatusModal__custom-inner'>
          <div className='addFileTaskStatusModal__custom-title'>Название:</div>
          <input
            value={values.name}
            onChange={(e) => {
              setFieldValue('name', e.target.value);
            }}
            className='appInput__field'
          />
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <Checkbox
          isChecked={values?.attach}
          setIsChecked={(e) => {
            setFieldValue('attach', e);
            if (!e) setFieldValue('isUrgently', false);
          }}
          className=''
          disabled={false}
          label='Показывать в календаре'
        />
        <Checkbox
          isChecked={values?.isUrgently}
          setIsChecked={(e) => {
            setFieldValue('isUrgently', e);
          }}
          className=''
          disabled={!values?.attach}
          label='Красить в календаре'
        />
      </div>
    </div>
  );
};
