import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useClickOutside } from '../../../../hooks';
import { ThreeDotsIcon } from '../../../icons';

type dataLogType =
  | {
      convertedDateFrom: string;
      convertedDateTo: string;
      formattedTimeFrom: string;
      formattedTimeTo: string;
      fileName: string;
      taskName: string;
    }[]
  | undefined;

type PopupType = {
  dataLogs: dataLogType;
  fileName: string;
};

export const Popup = ({ dataLogs, fileName }: PopupType) => {
  const [click, setClick] = useState(false);

  const ref = useRef<any>();

  useClickOutside(ref, () => setClick(false));

  const copyText = formatDataLog(dataLogs);

  return (
    <div ref={ref} className='ReportChronographModal__popup'>
      <ThreeDotsIcon
        onClick={() => {
          setClick(!click);
        }}
      />
      <div
        style={{
          display: click ? 'block' : 'none',
        }}
        className='ReportChronographModal__popup-inside'
      >
        <div
          className='ReportChronographModal__popup-item'
          onClick={() => {
            createTxtFile(dataLogs, fileName);
          }}
        >
          <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='mr10'
          >
            <path
              d='M10.3333 10.9767V12.1839L11.1869 11.3302L15.8925 6.62382L16.3637 7.09504L9.99998 13.4596L3.63615 7.09573L4.10765 6.62389L8.81314 11.3286L9.66667 12.182V10.975V2.16669H10.3333V10.9767ZM17 16.3334V17H3V16.3334H17Z'
              fill='#303030'
              stroke='#303030'
            />
          </svg>
          Скачать
        </div>
        <div
          className='ReportChronographModal__popup-item'
          onClick={() => {
            copyToClipboard(copyText);
            toast.success('Cкопированно в буфер обмена');
          }}
        >
          <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='mr10'
          >
            <path
              d='M11.21 12.9961C11.1806 12.967 11.1409 12.9507 11.0996 12.9507C11.0583 12.9507 11.0186 12.967 10.9893 12.9961L8.71973 15.2656C7.66895 16.3164 5.89551 16.4277 4.73535 15.2656C3.57324 14.1035 3.68457 12.332 4.73535 11.2813L7.00488 9.01172C7.06543 8.95117 7.06543 8.85156 7.00488 8.79102L6.22754 8.01367C6.19817 7.98459 6.15852 7.96828 6.11719 7.96828C6.07586 7.96828 6.0362 7.98459 6.00684 8.01367L3.7373 10.2832C2.08496 11.9355 2.08496 14.6094 3.7373 16.2598C5.38965 17.9102 8.06348 17.9121 9.71387 16.2598L11.9834 13.9902C12.0439 13.9297 12.0439 13.8301 11.9834 13.7695L11.21 12.9961ZM16.2607 3.73828C14.6084 2.08594 11.9346 2.08594 10.2842 3.73828L8.0127 6.00781C7.98362 6.03718 7.9673 6.07684 7.9673 6.11816C7.9673 6.15949 7.98362 6.19915 8.0127 6.22852L8.78809 7.00391C8.84863 7.06445 8.94824 7.06445 9.00879 7.00391L11.2783 4.73438C12.3291 3.68359 14.1025 3.57227 15.2627 4.73438C16.4248 5.89648 16.3135 7.66797 15.2627 8.71875L12.9932 10.9883C12.9641 11.0176 12.9478 11.0573 12.9478 11.0986C12.9478 11.14 12.9641 11.1796 12.9932 11.209L13.7705 11.9863C13.8311 12.0469 13.9307 12.0469 13.9912 11.9863L16.2607 9.7168C17.9111 8.06445 17.9111 5.39063 16.2607 3.73828V3.73828ZM11.915 7.27148C11.8857 7.24241 11.846 7.22609 11.8047 7.22609C11.7634 7.22609 11.7237 7.24241 11.6943 7.27148L7.27051 11.6934C7.24143 11.7227 7.22512 11.7624 7.22512 11.8037C7.22512 11.845 7.24143 11.8847 7.27051 11.9141L8.04395 12.6875C8.10449 12.748 8.2041 12.748 8.26465 12.6875L12.6865 8.26563C12.7471 8.20508 12.7471 8.10547 12.6865 8.04492L11.915 7.27148Z'
              fill='#303030'
            />
          </svg>
          Скопировать
        </div>
      </div>
    </div>
  );
};

const copyToClipboard = (text: string) => {
  const textField = document.createElement('textarea');
  textField.innerHTML = text;
  textField.style.position = 'fixed';
  textField.style.opacity = '0';
  document.body.appendChild(textField);
  textField.focus();
  textField.select();
  document.execCommand('copy');
  textField.remove();
};
function formatDataLog(dataLogs: dataLogType) {
  let formattedText = '';

  if (dataLogs && Array.isArray(dataLogs)) {
    for (const dataLog of dataLogs) {
      const {
        fileName,
        taskName,
        convertedDateFrom,
        convertedDateTo,
        formattedTimeFrom,
        formattedTimeTo,
      } = dataLog;
      formattedText += `${convertedDateFrom}/${formattedTimeFrom}-${fileName}${
        taskName ? ' / ' + taskName : ''
      } до ${convertedDateTo}/${formattedTimeTo}\n`;
    }
  }

  return formattedText;
}

function createTxtFile(dataLogs: dataLogType, fileName: string) {
  let formattedText = '';

  if (dataLogs && Array.isArray(dataLogs)) {
    for (const dataLog of dataLogs) {
      const {
        fileName,
        taskName,
        convertedDateFrom,
        convertedDateTo,
        formattedTimeFrom,
        formattedTimeTo,
      } = dataLog;
      formattedText += `${convertedDateFrom}/${formattedTimeFrom}-${fileName}${
        taskName ? ' / ' + taskName : ''
      } до ${convertedDateTo}/${formattedTimeTo}\n`;
    }
  }

  const element = document.createElement('a');
  const file = new Blob([formattedText], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
