export const textSlice = ({
  text = '',
  count = 50,
  hide = false,
}: {
  text?: string;
  count?: number;
  hide?: boolean;
}) => {
  if (text?.length >= count) {
    return text?.slice(0, count) + (hide ? '.' : '...');
  }
  return text;
};

export const textSliceSpace = ({ text = '', count = 50, space = false }) => {
  const textLength = text?.length || 0;
  const hasSpace = text?.trim()?.includes(' ');

  if (space && hasSpace) {
    const parts = text?.split(' ');
    if (parts?.every((part) => part?.length <= count)) {
      return text;
    }
  }

  if (textLength >= count) {
    const slicedText = text?.slice(0, count);
    const ellipsis = '...';
    if (slicedText) {
      return slicedText + ellipsis;
    }
  }

  return text;
};
