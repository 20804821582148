import React from 'react';

type ForumSearchIconsType = {
  className: string;
};

export const ForumSearchIcons = ({ className }: ForumSearchIconsType) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M1.66699 14.1666V2.49996C1.66699 2.26385 1.74699 2.06579 1.90699 1.90579C2.06644 1.74635 2.26421 1.66663 2.50033 1.66663H13.3337C13.5698 1.66663 13.7675 1.74635 13.927 1.90579C14.087 2.06579 14.167 2.26385 14.167 2.49996V9.99996C14.167 10.2361 14.087 10.4338 13.927 10.5933C13.7675 10.7533 13.5698 10.8333 13.3337 10.8333H5.00033L1.66699 14.1666ZM5.83366 15C5.59755 15 5.39949 14.92 5.23949 14.76C5.08005 14.6005 5.00033 14.4027 5.00033 14.1666V12.5H15.8337V4.99996H17.5003C17.7364 4.99996 17.9342 5.07968 18.0937 5.23913C18.2537 5.39913 18.3337 5.59718 18.3337 5.83329V18.3333L15.0003 15H5.83366Z'
        fill='#AFAFAF'
      />
    </svg>
  );
};
