import React from 'react';

export const ForumAnswerIcons = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.33333 7.50033V4.16699L2.5 10.0003L8.33333 15.8337V12.417C12.5 12.417 15.4167 13.7503 17.5 16.667C16.6667 12.5003 14.1667 8.33366 8.33333 7.50033Z'
        fill='#303030'
      />
    </svg>
  );
};
