import './ArchiveProjectBlock.scss';

import React, { FC } from 'react';

import { ProjectBlockHeader, ProjectBlockItem } from '../../components';
import { ArchivedProject } from '../../graphql/generated/graphql';
import { addKarma } from '../../utils';

export const ArchiveProjectBlock: FC<ArchiveProjectBlockType> = ({ projectData }) => {
  return (
    <div className='projectBlockArchive'>
      <ProjectBlockHeader
        title={`#${projectData.number} ${projectData.name}`}
        entityId={Number(projectData?.id)}
        projectNumber={projectData?.number}
        initialTitle={projectData?.name}
        label='Название проекта:'
        color='#AFAFAF'
        handleDelete={() => {}}
      />
      <div className='line' />
      <div className='projectBlock__itemsArchive'>
        {projectData?.tasks?.rows.length !== 0 ? (
          projectData?.tasks?.rows.map((task) => {
            if (task) {
              return (
                <ProjectBlockItem
                  key={task.id}
                  taskId={task.id}
                  title={`#${task?.project?.number}.${task?.number} ${task?.name}`}
                  userAvatar={task?.maintainer?.image}
                  name={addKarma(task?.maintainer)}
                  projectStatus={task?.status?.id}
                  tags={task?.tags.rows}
                  isArchived
                />
              );
            }
          })
        ) : (
          <p className='projectBlock__missingProjects'>Еще нет архивных задач</p>
        )}
      </div>
    </div>
  );
};

type ArchiveProjectBlockType = {
  projectData: ArchivedProject;
};
