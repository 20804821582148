import './TagItem.scss';

import React from 'react';

import { CrossIcon } from '../icons';

type TagItemPropsType = {
  id?: number;
  title: string;
  color?: string | null;
  closeFunc?: () => void;
  onClick?: () => void;
  isClose?: boolean;
  textColor?: string | undefined | null;
  margin?: string;
};

export const TagItem = ({
  title,
  color,
  closeFunc,
  onClick,
  isClose = false,
  textColor,
  margin,
}: TagItemPropsType) => {
  return (
    <div className='tag__item' style={{ backgroundColor: `${color}`, margin: margin }}>
      <p
        className={`tag__text ${!isClose ? 'hover' : null}`}
        style={{ color: textColor ? textColor : 'white' }}
        onClick={onClick}
      >
        {title}
      </p>
      {isClose ? (
        <div className='tag__closeIcon'>
          <CrossIcon width={6} height={6} color='#ffffff' onClick={closeFunc} />
        </div>
      ) : null}
    </div>
  );
};
