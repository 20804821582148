import './TemplateProjectI.scss';

import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { ChromePicker } from 'react-color';

import { weeklyFile } from '../../validation/schemas';
import { AppInput, Button } from '../index';

type TemplateProjectIType = {
  onCallback: (value: any) => void;
  initialValues: {
    color: string | undefined | null;
    name: string;
  };
  onClose: () => void;
};

export const TemplateProjectI = ({
  onCallback,
  initialValues,
  onClose,
}: TemplateProjectIType) => {
  const [openColor, setOpenColor] = useState(false);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={weeklyFile}
      onSubmit={(value) => {
        onCallback(value);
      }}
    >
      {({ setFieldValue, values, errors }) => {
        return (
          <Form className='TemplateProjectI'>
            <div>
              <div className='TemplateProjectI__title'>Задача</div>
              <div className='TemplateProjectI__selector2'>
                <div
                  style={{
                    width: '20px',
                    height: '20px',
                    backgroundColor: values?.color ? values?.color : '#EAEAEA',
                    border: errors?.color ? '1px solid red' : '1px solid black',
                    marginBottom: '2px',
                  }}
                  onClick={() => {
                    setOpenColor(true);
                  }}
                ></div>
                {openColor && (
                  <div className='TemplateProjectI__colorPanel'>
                    <ChromePicker
                      disableAlpha
                      color={values.color as any}
                      onChangeComplete={(paletteColor) => {
                        setFieldValue('color', paletteColor.hex);
                      }}
                      onChange={(paletteColor) => {
                        setFieldValue('color', paletteColor.hex);
                      }}
                    />
                    <Button
                      text='Сохранить цвет'
                      margin='10px 0 0 0'
                      onClick={() => {
                        setOpenColor(false);
                      }}
                    />
                  </div>
                )}
                <AppInput placeholder='Название файла' name='name' hideError={true} />
              </div>
            </div>
            {errors && (
              <div style={{ color: 'red', textAlign: 'start' }}>
                <div>{errors?.name}</div>
                <div>{errors?.color}</div>
              </div>
            )}

            <div className='TemplateProjectI__inputWrapper'>
              <AppInput
                name='color'
                hideError={true}
                placeholder='#ffffff'
                style={{ marginLeft: '0px' }}
              />
            </div>

            <Button
              onClick={() => {
                onClose();
              }}
              width={'100%'}
              margin={'20px 0px 10px 0px'}
            >
              Отмена
            </Button>
            <Button type='submit' width={'100%'} backgroundColor='#149939'>
              Сохранить
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
