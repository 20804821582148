import './PolygonMainArrowMenu.scss';

import React, { FC } from 'react';

import { useModalContext } from '../../context';
import { ModalStatuses, OptionsStatuses } from '../../types/enums';
import { arrowArrayType, ColorType } from '../../types/types';
import { calcLeftMenu, calcTopMenu } from '../../utils/';
import { RemoveIcon } from '../icons';
import { FillIcon } from '../icons/fillIcon/FillIcon';
import { PolygonActionsWrapper } from '../polygonActionsWrapper/PolygonActionsWrapper';
import { Popover } from '../popover/Popover';
import { PolygonArrowColorItem } from './polygonArrowColorItem/PolygonArrowColorItem';

export const PolygonMainArrowMenu: FC<PolygonMainArrowMenuType> = ({
  arrowId,
  removeConfirmTitle,
  additionalRemoveConfirmTitle,
  colors,
  onSetColor,
  initialColorId,
  onRemove,
  arrowArray,
  selectedIdArrow,
  refScroll,
}) => {
  const {
    optionsStatus,
    handleSetModalStatus,
    handleCloseModal,
    handleChangeModalParameters,
    handleSetOptionsStatus,
  } = useModalContext();

  const onSetColorHandler = (fill: string) => {
    onSetColor && onSetColor(arrowId, fill || '#303030');
    handleSetOptionsStatus(OptionsStatuses.WITHOUT_STATUS);
  };

  const onRemoveHandler = () => {
    onRemove && onRemove(arrowId);
    handleCloseModal();
  };

  const selectedArrow = arrowArray?.filter(
    (arrow) => String(arrow.id) === String(selectedIdArrow),
  )[0];

  const top = selectedArrow
    ? (selectedArrow?.startY -
        refScroll?.current?.scrollTop +
        selectedArrow?.endY -
        refScroll?.current?.scrollTop) /
        2 -
      68
    : 0;
  const left = selectedArrow
    ? (selectedArrow?.startX -
        refScroll?.current?.scrollLeft +
        selectedArrow?.endX -
        refScroll?.current?.scrollLeft) /
      2
    : 0;

  return (
    <PolygonActionsWrapper
      isVisible={!!selectedIdArrow}
      style={{
        top: calcTopMenu({ top: top, height: 10 }),
        left: calcLeftMenu({ shift: 280, left }),
      }}
    >
      <div className='polygon-main-arrow-menu__colors'>
        <FillIcon onClick={() => handleSetOptionsStatus(OptionsStatuses.PALETTE_MENU)} />
        {optionsStatus === OptionsStatuses.PALETTE_MENU ? (
          <Popover>
            <PolygonArrowColorItem
              initialColorId={initialColorId}
              colors={colors}
              onClick={onSetColorHandler}
              onClose={() => handleSetOptionsStatus(OptionsStatuses.WITHOUT_STATUS)}
            />
          </Popover>
        ) : null}
      </div>

      <div className='polygon-actions-wrapper-line' />

      <RemoveIcon
        onClick={() => {
          handleChangeModalParameters([
            {
              key: 'onCallback',
              value: () => onRemoveHandler(),
            },
            {
              key: 'removeConfirmTitle',
              value: removeConfirmTitle,
            },
            {
              key: 'additionalConfirmTitle',
              value: additionalRemoveConfirmTitle,
            },
          ]);
          handleSetModalStatus(ModalStatuses.REMOVE_MODAL);
        }}
      />
    </PolygonActionsWrapper>
  );
};

type PolygonMainArrowMenuType = {
  arrowId: string | number | null;
  removeConfirmTitle?: string | null;
  additionalRemoveConfirmTitle?: string | null;
  initialColorId?: number;
  colors: ColorType[];
  onSetColor: (arrowId: string | number | null, fill: string) => void;
  onRemove: (arrowId: string | number | null) => void;
  arrowArray: arrowArrayType;
  selectedIdArrow: string | number | null;
  refScroll: any;
};
