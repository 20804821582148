import React from 'react';

export const AlertIcons = ({ className, color = '#EE3944', onClick }: any) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 0C4.47727 0 0 4.47727 0 10C0 15.5227 4.47727 20 10 20C15.5227 20 20 15.5227 20 10C20 4.47727 15.5227 0 10 0ZM10.9091 5.45455C10.9091 5.21344 10.8133 4.98221 10.6428 4.81172C10.4723 4.64123 10.2411 4.54545 10 4.54545C9.75889 4.54545 9.52766 4.64123 9.35718 4.81172C9.18669 4.98221 9.09091 5.21344 9.09091 5.45455V10.9091C9.09091 11.1502 9.18669 11.3814 9.35718 11.5519C9.52766 11.7224 9.75889 11.8182 10 11.8182C10.2411 11.8182 10.4723 11.7224 10.6428 11.5519C10.8133 11.3814 10.9091 11.1502 10.9091 10.9091V5.45455ZM10.9091 14.0909C10.9091 13.8498 10.8133 13.6186 10.6428 13.4481C10.4723 13.2776 10.2411 13.1818 10 13.1818C9.75889 13.1818 9.52766 13.2776 9.35718 13.4481C9.18669 13.6186 9.09091 13.8498 9.09091 14.0909V14.5455C9.09091 14.7866 9.18669 15.0178 9.35718 15.1883C9.52766 15.3588 9.75889 15.4545 10 15.4545C10.2411 15.4545 10.4723 15.3588 10.6428 15.1883C10.8133 15.0178 10.9091 14.7866 10.9091 14.5455V14.0909Z'
        fill={color}
      />
    </svg>
  );
};
