import moment from 'moment';

import { TagList } from '../graphql/generated/graphql';
import { DaysType } from './index';

type getWorkDay2Type = {
  checkedDate: Date;
  day: DaysType;
  item: ItemType | null;
  currentDay: any;
  isEndDay: boolean | null;
  isStartDay: boolean | null;
};

export const getWorkDay2 = ({
  checkedDate,
  item,
  currentDay,
  isEndDay,
  isStartDay,
}: getWorkDay2Type) => {
  if (isEndDay || isStartDay) return true;
  const rowMonth = new Date(checkedDate).getMonth();
  const rowYear = new Date(checkedDate).getFullYear();

  /// for safari fix
  const createRowMonth = `${rowMonth + 1 <= 9 ? '0' : ''}${rowMonth + 1}`;
  const createRowDay = `${currentDay <= 9 ? '0' : ''}${currentDay}`;

  const rowDate = new Date(`${rowYear}-${createRowMonth}-${createRowDay}`);

  const currentDate = moment(new Date()).format();

  const endDate = moment(new Date(item?.closeDate)).format();

  const closeData = moment(new Date(item?.endDate)).format();

  const startDate = moment(new Date(item?.startDate)).format();
  const rowDateMoment = moment(rowDate).format();

  if (
    (startDate < rowDateMoment &&
      rowDateMoment < endDate &&
      item?.startDate &&
      item?.closeDate) ||
    (item?.startDate &&
      !item?.closeDate &&
      startDate < rowDateMoment &&
      rowDateMoment <= currentDate) ||
    (item?.endDate &&
      closeData > endDate &&
      rowDateMoment <= closeData &&
      rowDateMoment >= startDate)
  ) {
    return true;
  }
  return false;
};

type ItemType = {
  id: number;
  status?: {
    id: number;
    color: string;
  } | null;
  endDate?: any | null;
  startDate?: any | null;
  closeDate?: any | null;
  parentId?: number | null;
  name: string;
  code?: string | null;
  tags: TagList;
  task?: {
    id: number;
    name: string;
  } | null;
  tread?: boolean;
};
