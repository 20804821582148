import React from 'react';

export const CrossIcon2 = ({ onClick }: any) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      onClick={onClick}
    >
      <path
        d='M8.91684 7.64866L8.56328 8.00223L8.91685 8.35578L14.5795 14.0181L14.5796 14.0182C14.736 14.1745 14.736 14.428 14.5796 14.5842L14.5795 14.5844C14.501 14.6628 14.3993 14.7018 14.2963 14.7018C14.1934 14.7018 14.0916 14.6628 14.0132 14.5844L8.35058 8.92173L7.99703 8.56819L7.64348 8.92173L1.98056 14.5843L1.98055 14.5844C1.90211 14.6628 1.80037 14.7018 1.69742 14.7018C1.59448 14.7018 1.49265 14.6628 1.41414 14.5845C1.25779 14.4283 1.25779 14.1748 1.41414 14.0185L1.41428 14.0184L7.0772 8.35578L7.43078 8.00222L7.0772 7.64866L1.41428 1.98603L1.41414 1.98589C1.25779 1.82968 1.25779 1.57615 1.41414 1.41993L1.41429 1.41978C1.57084 1.26323 1.824 1.26323 1.98055 1.41978L1.98056 1.41979L7.64348 7.08241L7.99703 7.43595L8.35058 7.0824L14.0132 1.41978C14.1697 1.26323 14.4229 1.26323 14.5795 1.41978L14.5796 1.41993C14.736 1.57615 14.736 1.82968 14.5796 1.98589L14.5795 1.98604L8.91684 7.64866Z'
        fill='#303030'
        stroke='#303030'
      />
    </svg>
  );
};
