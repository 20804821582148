import * as GENERATED from '../../graphql/generated/graphql';
import {
  GetCurrentUserDocument,
  GetCurrentUserQuery,
} from '../../graphql/generated/graphql';
import { getErrorText } from '../../utils';

export const useEditUserMutation = () => {
  return GENERATED.useEditUserMutation({
    update(cache, { data }) {
      if (!data?.editUser.id) return null;

      const user: GetCurrentUserQuery | null = cache.readQuery({
        query: GetCurrentUserDocument,
      });

      if (user) {
        cache.writeQuery({
          query: GetCurrentUserDocument,

          data: {
            ...user,
            getCurrentUser: {
              ...user.getCurrentUser,
              fullName: data.editUser?.fullName,
              image: data.editUser?.image,
              theNote: data.editUser?.theNote,
            },
          },
        });
      }
    },
    onError: (error) => {
      getErrorText(error.message);
    },
  });
};
