import { toast } from 'react-toastify';

import { useAuthContext } from '../../../context';
import { useUpdateThreadPerformersMutation } from '../../../graphql/generated/graphql';
import { GET_THREAD } from '../../../graphql/queries/getThread.gql';

export const usePerformerOptimize = ({ data }: any) => {
  const { currentUserData } = useAuthContext();

  const [updateThreadPerformersMutation] = useUpdateThreadPerformersMutation({
    update(cache, { data }) {
      const performers = data?.updateThread?.performers;
      const mainPerformer = data?.updateThread?.mainPerformer;
      const mainPerformerId = data?.updateThread?.mainPerformerId;
      cache.writeQuery({
        query: GET_THREAD,
        data: {
          performers: performers,
          mainPerformer: mainPerformer,
          mainPerformerId: mainPerformerId,
        },
      });
    },
    onError: () => {
      toast.error('Ой');
    },
  });

  const performers = data?.getThread?.performers?.map((e: any) => {
    return {
      id: e.user?.id,
      login: e.user?.login,
      image: e.user?.image,
      threads: e.user?.threads,
      status: e.status,
      karmaStatistics: e?.user?.karmaStatistics,
    };
  });
  const mainPerformerId = data?.getThread?.mainPerformerId;
  const mainPerformer = data?.getThread?.mainPerformer;

  const isPerformer = performers
    ?.map((e: any) => e?.id)
    ?.some((id: number) => id === currentUserData?.id);

  return {
    performers,
    isPerformer,
    mainPerformerId,
    mainPerformer,
    updatePerformers: updateThreadPerformersMutation,
  };
};
