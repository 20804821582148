import './MoveScaleTaskModal.scss';

import React, { useState } from 'react';

import { useGetScaleMapFileQuery } from '../../../../graphql/generated/graphql';
import { Button } from '../../../button/Button';
import { NoFormikSelect } from '../../../noFormikSelect/NoFormikSelect';

export const MoveScaleTaskModal = ({ onClose, modalParameters }: any) => {
  const { data } = useGetScaleMapFileQuery({
    variables: {
      getScaleMapFileId: parseInt(modalParameters?.initialValues?.fileId),
    },
    fetchPolicy: 'cache-and-network',
  });

  const [selectTask, setSelectTask] = useState<any>(
    modalParameters?.initialValues?.scaleMapTaskId,
  );

  const optionsSelectTask = data?.getScaleMapFile?.scaleMapTasks
    ?.filter((e) => e?.column === 1)
    ?.map((e) => {
      return { value: e.id, label: e.name };
    });

  if (!optionsSelectTask) return <></>;
  return (
    <div className='MoveScaleTaskModal'>
      {optionsSelectTask && (
        <div className='MoveScaleTaskModal__item'>
          <div className='MoveScaleTaskModal__name'>Выбор таски </div>
          {optionsSelectTask && (
            <NoFormikSelect
              options={optionsSelectTask as any}
              value={selectTask}
              setValue={setSelectTask}
            />
          )}
        </div>
      )}
      {selectTask && (
        <Button
          width={'100%'}
          margin={'20px 0px 0px'}
          onClick={() => {
            modalParameters?.onCallbackMove(selectTask);
            onClose();
          }}
        >
          Перенести в цель
        </Button>
      )}

      <Button
        width={'100%'}
        margin={'20px 0px 0px'}
        onClick={() => {
          onClose();
        }}
      >
        Отменить
      </Button>
    </div>
  );
};
