import './EditTitle.scss';

import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  useUpdateProjectMutation,
  useUpdateSubtaskMutation,
  useUpdateTaskMutation,
} from '../../../hooks/useMutations';
import { editProjectSchema, editTaskSubtaskSchema } from '../../../validation/schemas';
import { AppInput } from '../../appInput/AppInput';
import { Button } from '../../button/Button';
import { CustomDatePicker } from '../../customDatePicker/CustomDatePicker';
import { EditIcon } from '../../icons';
import { Modal } from '../../modal/Modal';
import { TagsSelect } from '../../tagsSelect/TagsSelect';

export const EditTitle: FC<EditTitleType> = ({
  label,
  initialTitle,
  initialTagIds,
  initialStartDate,
  initialCloseDate,
  initialEndDate,
  entityId,
  projectNumber,
  color,
  isProject,
  showLabel = true,
  refetch,
}) => {
  const { taskId, subtaskId } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const initialValues = {
    title: initialTitle || '',
    projectNumber: projectNumber,
    tagsId: [],
    startDate: initialStartDate ? Date.parse(initialStartDate) : null,
    closeDate: initialCloseDate ? Date.parse(initialCloseDate) : null,
    endDate: initialEndDate ? Date.parse(initialEndDate) : null,
  };

  const handleCloseModal = () => setIsModalOpen(false);

  const handleOpenModal = () => setIsModalOpen(true);

  return (
    <>
      <div className='editTitle' onClick={handleOpenModal}>
        <EditIcon width={13} height={13} color={color} className='edit-color' />
        {showLabel && <span>Редактировать</span>}
      </div>
      <EditTitleModalLocal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        initialValues={initialValues}
        taskId={taskId}
        label={label}
        isProject={isProject}
        projectNumber={projectNumber}
        initialTagIds={initialTagIds}
        entityId={entityId}
        subtaskId={subtaskId}
        refetch={refetch}
      />
    </>
  );
};

type EditTitleModalLocalType = {
  isModalOpen: any;
  handleCloseModal: any;
  initialValues: any;
  taskId: any;
  label: any;
  isProject: any;
  projectNumber: any;
  initialTagIds: any;
  entityId: any;
  subtaskId: any;
  refetch?: () => void;
};

export const EditTitleModalLocal = ({
  isModalOpen,
  handleCloseModal,
  initialValues,
  taskId,
  label,
  isProject,
  projectNumber,
  initialTagIds,
  entityId,
  subtaskId,
  refetch,
}: EditTitleModalLocalType) => {
  const [updateSubtask] = useUpdateSubtaskMutation();
  const [updateTask] = useUpdateTaskMutation();
  const [updateProject] = useUpdateProjectMutation();

  const handleUpdate = async (values: ValuesType) => {
    const parameters = {
      data: {
        name: values.title,
        tagsId: values.tagsId,
        startDate: values.startDate ? format(values.startDate, 'yyyy-MM-dd') : null,
        closeDate: values.closeDate ? format(values.closeDate, 'yyyy-MM-dd') : null,
        endDate: values.endDate ? format(values.endDate, 'yyyy-MM-dd') : null,
      },
      id: Number(entityId),
    };

    if (subtaskId && taskId) {
      await updateSubtask({
        variables: parameters,
        onCompleted: (response) => {
          if (response?.updateSubtask.id) {
            handleCloseModal();
            toast.success(`Подзадача ${response.updateSubtask.name} успешно изменена!`);
          }
        },
      });
    } else if (!subtaskId && taskId) {
      await updateTask({
        variables: parameters,
        onCompleted: (response) => {
          if (response?.updateTask.id) {
            handleCloseModal();
            toast.success(`Задача ${response.updateTask.name} успешно изменена!`);
          }
        },
      });
    } else if (!subtaskId && !taskId)
      await updateProject({
        variables: {
          data: {
            name: values.title,
            number: Number(values.projectNumber),
          },
          id: Number(entityId),
        },
        onCompleted: (response) => {
          if (response?.updateProject.id) {
            handleCloseModal();
            toast.success(`Проект ${response.updateProject.name} успешно изменен!`);
          }
        },
      }).then(() => {
        refetch && refetch();
      });
  };

  return (
    <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
      <Formik
        initialValues={initialValues}
        validationSchema={!taskId ? editProjectSchema : editTaskSubtaskSchema}
        onSubmit={handleUpdate}
      >
        <Form>
          <div className='simple-form-modal' style={{ width: '400px', maxWidth: 'none' }}>
            <div className='simple-form-modal__title'>
              <AppInput label={label} name='title' />
              {!isProject && !projectNumber ? (
                <>
                  <div className='editTitle__datePickers'>
                    <CustomDatePicker
                      name='startDate'
                      label='Дата старта:'
                      startDateSet
                    />
                    <CustomDatePicker name='closeDate' label='Дата завершения:' />
                    <CustomDatePicker name='endDate' label='Дата сдачи:' endDateSet />
                  </div>
                  <TagsSelect
                    name='tagsId'
                    editTagIds={initialTagIds}
                    isEditMode={true}
                  />
                </>
              ) : null}

              {isProject ? (
                <AppInput label='Номер проекта:' name='projectNumber' />
              ) : null}
            </div>
            <Button text='Сохранить' type='submit' width='100%' height={25} />
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

type EditTitleType = {
  entityId?: number | null;
  projectNumber?: number | null;
  label: string;
  initialTitle?: string;
  initialTagIds?: string[];
  initialStartDate?: string;
  initialCloseDate?: string;
  initialEndDate?: string;
  color?: string;
  isProject?: boolean;
  showLabel?: boolean;
  refetch?: () => void;
};

type ValuesType = {
  title: string;
  projectNumber?: number | null;
  tagsId?: string[];
  startDate?: number | null;
  closeDate?: number | null;
  endDate?: number | null;
};
