import { useParams } from 'react-router-dom';

import * as GENERATED from '../../graphql/generated/graphql';
import { useReadMessagesBeforeMutation } from '../../graphql/generated/graphql';

export const useGetSubtaskChatLazyQuery = () => {
  const [readMessagesBeforeMutation] = useReadMessagesBeforeMutation();

  const { subtaskId } = useParams();

  return GENERATED.useGetSubtaskChatLazyQuery({
    variables: {
      id: Number(subtaskId),
    },
    onCompleted: (data) => {
      const lastMessage = data?.getSubtask?.chat?.messagesList?.rows[0];
      if (lastMessage && lastMessage?.isRead === null) {
        readMessagesBeforeMutation({
          variables: {
            messageId: [Number(data?.getSubtask?.chat?.messagesList?.rows?.[0]?.id)],
          },
        });
      }
    },
  });
};
