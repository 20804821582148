import './TemplateAll.scss';

import React from 'react';

import { RectType } from '../../../../types/types';
import { RemoveIcon } from '../../../icons';

export const TemplateAll = ({ onClose, deleteTemplate, templates }: any) => {
  const handleDelete = (template: any) => {
    deleteTemplate({ id: template.id });
    onClose();
  };

  return (
    <div className='templateAll'>
      {templates?.map((template: RectType) => {
        return (
          <div key={template.name} className='templateAll-line'>
            <div>{template.name}</div>
            <div>
              <RemoveIcon
                color='#303030'
                onClick={() => {
                  handleDelete(template);
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
