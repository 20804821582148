import './TaskCommentsModal.scss';

import { Form, Formik } from 'formik';
import React from 'react';

import { taskCommentSchema } from '../../../../validation/schemas';
import { AppInput } from '../../../appInput/AppInput';
import { Button } from '../../../index';

export const TaskCommentsModal = ({ modalParameters }: any) => {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        description: modalParameters?.initValue ? modalParameters?.initValue : '',
      }}
      onSubmit={modalParameters?.onCallback}
      validationSchema={taskCommentSchema}
    >
      {() => {
        return (
          <Form className='TaskCommentsModal'>
            <AppInput name='description' label='Комментарий:' />
            <Button
              text={'Сохранить'}
              type='submit'
              width='100%'
              height='25px'
              margin='20px 0 0 0'
            />
          </Form>
        );
      }}
    </Formik>
  );
};
