import { useParams } from 'react-router-dom';

import * as GENERATED from '../../graphql/generated/graphql';
import {
  GetTaskAssigneesDocument,
  GetTaskAssigneesQuery,
} from '../../graphql/generated/graphql';

export const useDeassignUserFromTaskMutation = () => {
  const { taskId } = useParams();
  return GENERATED.useDeassignUserFromTaskMutation({
    update(cache, { data }) {
      if (!data?.deassignUserFromTask) {
        return null;
      }

      const assignees: GetTaskAssigneesQuery | null = cache.readQuery({
        query: GetTaskAssigneesDocument,
        variables: {
          id: Number(taskId),
          assigneesLimit: 20,
        },
      });

      cache.writeQuery({
        query: GetTaskAssigneesDocument,
        variables: {
          id: Number(taskId),
          assigneesLimit: 20,
        },
        data: {
          ...assignees,
          getTask: {
            ...assignees?.getTask,
            assignees: data.deassignUserFromTask.assignees,
          },
        },
      });
    },
  });
};
