import './AddFileModalUser.scss';

import { Form, Formik } from 'formik';
import React from 'react';

import { addFileUserSchema } from '../../../../validation/schemas';
import { AppInput, Button } from '../../../index';

type AddFileModalUserType = {
  isOpen: boolean;
  onClose: () => void;
  modalParameters?: any;
};

export const AddFileModalUser = ({ onClose, modalParameters }: AddFileModalUserType) => {
  return (
    <div className='addFileModalUserType'>
      <div className='addFileModalUserType__title'>Добавить файл к проекту</div>
      <Formik
        initialValues={
          modalParameters?.initialValues || { projectId: undefined, fileName: '' }
        }
        onSubmit={(e) => {
          modalParameters.onCallback({
            name: e.fileName,
            projectId: undefined,
            color: '#9a9898',
          });
          onClose();
        }}
        validationSchema={addFileUserSchema}
      >
        {({ errors }) => {
          return (
            <Form style={{ width: 'auto' }}>
              <div style={{ marginTop: '10px' }}>
                <AppInput
                  name={'fileName'}
                  placeholder='Введите имя файла'
                  error={errors?.fileName as string}
                />
              </div>
              <Button
                text={'Добавить файл'}
                type='submit'
                width='100%'
                height='25px'
                margin='5px 0 0 0'
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
