import React from 'react';

import { TasksList, TasksListItemType } from '../../components';
import { useGetMyTasksSubtasksQuery } from '../../hooks/useQueries';

export const MyTasks = () => {
  const { data, loading: allTasksLoading } = useGetMyTasksSubtasksQuery();

  const myTasksList = () => {
    const myTasksListArray: TasksListItemType[] = [];

    if (data) {
      data?.getCurrentUser?.myTasksSubtasks.map((task) => {
        myTasksListArray.push({
          id: task?.id,
          project: `#${task?.project?.number} ${task?.project?.name}`,
          name: `#${task?.code} ${task?.name}`,
          status: task?.status?.id,
          createdAt: task?.createdAt,
          parentId: task?.parentId || null,
          maintainer: {
            id: task?.maintainer?.id,
            login: task?.maintainer?.login,
            image: task?.maintainer?.image,
          },
        });
      });
    }

    return myTasksListArray;
  };

  return (
    <div className='container'>
      <TasksList
        isLoading={allTasksLoading}
        mappedData={myTasksList()}
        pageTitle='Мои задачи'
      />
    </div>
  );
};
