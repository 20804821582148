import './ChangeColNameModal.scss';

import { Form, Formik } from 'formik';
import React from 'react';

import { changeColName } from '../../../../validation/schemas';
import { AppInput, Button } from '../../../index';

export const ChangeColNameModal = ({ modalParameters, onClose }: any) => {
  return (
    <div>
      <Formik
        onSubmit={(e) => {
          modalParameters?.onCallback(e);
          onClose();
        }}
        initialValues={{
          name: modalParameters?.initialValues?.name,
        }}
        validationSchema={changeColName}
        enableReinitialize={true}
      >
        {() => {
          return (
            <Form className='changeColNameModal'>
              <div className='changeColNameModal__inner'>
                <div className='changeColNameModal__title'>Название колонки</div>
                <AppInput name='name' label={'Имя:'} />{' '}
                <Button type='submit' width={'100%'}>
                  Сохранить
                </Button>
                <Button onClick={onClose} width={'100%'} margin={'10px 0px 0px 0px'}>
                  Отменить
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
