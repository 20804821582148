import React, { useEffect, useRef, useState } from 'react';

import { useClickOutside } from '../../../hooks';

export const TredItemSubscribeButton = ({
  name,
  subscribeToThreadMutation,
  id,
  isSubscriber,
  isPerformer,
}: SubscribeButtonType) => {
  const ref = useRef<any>();

  const [outside, setOutside] = useState(false);

  useEffect(() => {
    setOutside(false);
  }, [isSubscriber]);

  const hideButton = isSubscriber || isPerformer || outside || !id;

  useEffect(() => {
    if (!hideButton) {
      subscribeToThreadMutation({
        variables: {
          threadId: id,
        },
      });
    }
  }, [isPerformer]);

  useClickOutside(ref, () => setOutside(true));

  if (hideButton) return <div></div>;
  return (
    <div
      ref={ref}
      className='TredItem__button'
      onClick={() => {
        subscribeToThreadMutation({
          variables: {
            threadId: id,
          },
        });
      }}
    >
      Подписаться на тредд #{name}
    </div>
  );
};

type SubscribeButtonType = {
  name: string | undefined;
  subscribeToThreadMutation: any;
  id: number | undefined;
  isSubscriber: boolean | undefined;
  isPerformer: boolean | undefined;
};
