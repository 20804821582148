import './SimpleFormModal.scss';

import { Formik, FormikValues } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { selectLinks, SelectLinksType } from '../../constants/constants';
import { ColorType } from '../../types/types';
import { AppInput } from '../appInput/AppInput';
import { AppSelect } from '../appSelect/AppSelect';
import { Button } from '../button/Button';
import { AddPageIcon, CrossIcon } from '../icons';
import { Modal } from '../modal/Modal';
import { PolygonArrowColorItem } from '../polygonMainArrowMenu';
import { TagsSelect } from '../tagsSelect/TagsSelect';

export const SimpleFormModal: FC<SimpleFormModalType> = ({
  isOpen,
  onSubmit,
  onReset,
  initialValues,
  onClose,
  validationSchema,
  tagsName,
  colorsName,
  inputName,
  inputType,
  labelText,
  buttonText,
  buttonType,
  resetButtonText,
  colors,
  initialColorId,
  links,
  selectName,
}) => {
  const [linksData, setLinksData] = useState<SelectLinksType[]>(links || []);

  const [isNewPageModalOpen, setIsNewPageModalOpen] = useState(false);

  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setDisable(false);
    }
  }, [isOpen]);

  const handleSubmit = (values: FormikValues) => {
    onSubmit(values);
  };

  const handleReset = (values?: FormikValues) => {
    if (onReset) {
      onReset(values);
    }
  };

  const onSetColorHandler = (
    color: string,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) => {
    colorsName && setFieldValue(colorsName, color);
  };

  //TODO refactor when backend was added
  const handleCreateNewPage = (values: FormikValues) => {
    selectLinks.options.push({ name: values.newPage });
    setIsNewPageModalOpen(false);
    toast.success(`Страница ${values.newPage} успешно создана`);
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        onReset={handleReset}
      >
        {({ setFieldValue, values }) => (
          <div className='simple-form-modal' style={{ width: 'auto', maxWidth: 'none' }}>
            <div className='simple-form-modal__title'>
              {inputName && (
                <AppInput name={inputName} label={labelText} type={inputType} />
              )}
              {tagsName && <TagsSelect name={tagsName} />}
              {colorsName && (
                <div className='simple-form-modal__colors'>
                  <span>Цвет:</span>
                  <PolygonArrowColorItem
                    initialColorId={initialColorId}
                    colors={colors}
                    onClick={(color) => onSetColorHandler(color, setFieldValue)}
                  />
                </div>
              )}
              {selectName && (
                <div className='simple-form-modal__links'>
                  <span className='simple-form-modal__links-title'>Линки</span>
                  {/*TODO refactor when backend was added*/}
                  {linksData?.map((select, idx) => {
                    return (
                      <div
                        className='simple-form-modal__links-select'
                        key={select.id + idx}
                      >
                        <AppSelect
                          name={`${selectName}${String(idx)}`}
                          options={select.options?.map((link) => ({
                            value: link?.name,
                            label: link?.name || 'Нет имени',
                          }))}
                          placeholder='Выберите страницу или раздел'
                        />
                        <div className='simple-form-modal__links_remove'>
                          {linksData.length > 1 && (
                            <CrossIcon
                              color='#AFAFAF'
                              onClick={() =>
                                setLinksData(
                                  linksData.filter((link) => link.id !== select.id),
                                )
                              }
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                  <div
                    className='simple-form-modal__links_add'
                    onClick={() =>
                      //TODO refactor when backend was added
                      setLinksData([
                        ...linksData,
                        {
                          id: selectLinks.id + 1,
                          options: selectLinks.options,
                        },
                      ])
                    }
                  >
                    + Добавить еще
                  </div>

                  <span>или</span>
                  <Button
                    text='Создать новую страницу'
                    width='100%'
                    onClick={() => setIsNewPageModalOpen(true)}
                    height='25px'
                    backgroundColor='#008000'
                    color='#FFFFFF'
                  >
                    <AddPageIcon width='15px' height='15px' />
                  </Button>
                  <SimpleFormModal
                    isOpen={isNewPageModalOpen}
                    onClose={() => setIsNewPageModalOpen(false)}
                    initialValues={{ newPage: '' }}
                    onSubmit={handleCreateNewPage}
                    inputName='newPage'
                    labelText='Введите название страницы'
                    buttonText='Создать'
                    buttonType='submit'
                  />
                </div>
              )}
            </div>
            <div className='simple-form-modal__buttons'>
              <Button
                text={buttonText}
                type={buttonType}
                width='100%'
                onClick={() => {
                  setDisable(true);
                  handleSubmit(values);
                }}
                height='25px'
                disabled={disable}
              />
              {onReset && (
                <Button
                  text={resetButtonText}
                  isCancelButton
                  type='reset'
                  width='100%'
                  height='25px'
                />
              )}
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  );
};

type SimpleFormModalType = {
  isOpen: boolean;
  onClose: () => void;
  initialValues: {
    [key: string]: any;
  };
  onSubmit: (values: any) => void;
  onReset?: (values?: any) => void;
  validationSchema?: any;
  tagsName?: string;
  colorsName?: string;
  inputName?: string;
  selectName?: string;
  inputType?: string;
  buttonText: string;
  labelText?: string;
  buttonType: 'button' | 'submit' | 'reset';
  resetButtonText?: string;
  colors?: Array<ColorType>;
  initialColorId?: number;
  links?: Array<SelectLinksType>;
};
