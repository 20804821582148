import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useChatContext } from '../../context';
import * as GENERATED from '../../graphql/generated/graphql';
import { GetAllFilesDocument, GetAllFilesQuery } from '../../graphql/generated/graphql';
import { getErrorText } from '../../utils';

export const useLoadFileMutation = () => {
  const { taskId, subtaskId, privateChatId } = useParams();

  const { chatId } = useChatContext();

  return GENERATED.useLoadFileMutation({
    update(cache, { data }) {
      if (!data?.loadFile) return null;

      const getFilesVariables = {
        data: subtaskId
          ? { subtaskId: Number(subtaskId) }
          : privateChatId
          ? { chatId: Number(chatId) }
          : { taskId: Number(taskId) },
      };

      const files: GetAllFilesQuery | null = cache.readQuery({
        query: GetAllFilesDocument,
        variables: getFilesVariables,
      });

      if (files) {
        cache.writeQuery({
          query: GetAllFilesDocument,
          variables: getFilesVariables,
          data: {
            ...files,
            getAllFiles: {
              ...files?.getAllFiles,
              rows: [...(files?.getAllFiles?.rows || []), data.loadFile],
            },
          },
        });
      }
    },
    onCompleted: (response) => {
      if (response.loadFile?.id) {
        toast.success('Файл успешно добавлен!');
      }
    },
    onError: (response) => {
      toast.error(getErrorText(response.message));
    },
  });
};
