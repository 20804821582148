import './PlanChronoModal.scss';

import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import {
  useGetChronographQuery,
  useUpdateWeeklyScheduledItemsMutation,
} from '../../../../graphql/generated/graphql';
import { AppInput } from '../../../appInput/AppInput';
import { Button } from '../../../button/Button';
import { ClockIcons2 } from '../../../icons';
import { RequestHandler } from '../../../index';

export const PlanChronoModal = ({ onClose, modalParameters }: any) => {
  const startOfPeriodDate = modalParameters?.initialValues?.from;

  const [filter, setFilter] = useState(true);

  const { data, loading } = useGetChronographQuery({
    variables: {
      GetWeeklyScheduledItemsInput: {
        startOfPeriodDate: startOfPeriodDate,
        isAll: !filter,
      },
    },
    fetchPolicy: 'network-only',
  });

  const [updateWeeklyScheduledItemsMutation] = useUpdateWeeklyScheduledItemsMutation({
    onCompleted: () => {
      toast.success(`Время успешно запланировано`);
      onClose();
    },
  });

  const items = data?.chronograph?.weeklyScheduledItems;

  interface DynamicSchema {
    [key: string]: yup.NumberSchema;
  }

  const generateValidationSchema = (array: any) => {
    if (!array) return yup.object();
    const schema: DynamicSchema = {};

    array.forEach((e: any) => {
      schema[`time${e?.scaleMapFile?.id}`] = yup.number().min(0, 'меньше нуля');
    });

    return yup.object().shape({
      ...schema,
      totalSum: yup
        .number()
        .test('sum', 'Сумма полей не может превышать 112 часов', function () {
          const sum: any = Object.values(this.parent).reduce(
            (acc: any, curr: any) => acc + (curr || 0),
            0,
          );
          return sum <= 112;
        }),
    });
  };

  const initValue: any = {};
  items &&
    items?.forEach((entry) => {
      return (initValue[`time${entry?.scaleMapFile?.id}`] = entry.scheduledTime
        ? entry.scheduledTime
        : 0);
    });

  const addFileTaskSchema = generateValidationSchema(items);
  const percentAll =
    data?.chronograph?.weeklyScheduled?.plannedTimeCompletionPercentage || 0;

  return (
    <RequestHandler loading={loading}>
      <Formik
        initialValues={initValue}
        enableReinitialize={true}
        onSubmit={(e) => {
          const outputArray = Object.keys(e).map((key) => ({
            scaleMapFileId: parseInt(key.replace('time', '')),
            scheduledTime: e[key] ? e[key] : 0,
          }));
          updateWeeklyScheduledItemsMutation({
            variables: {
              data: {
                items: outputArray,
                startOfPeriodDate: startOfPeriodDate,
              },
            },
          });
        }}
        validationSchema={addFileTaskSchema}
      >
        {({ errors }) => {
          return (
            <Form className='planChronoModal'>
              <div className='planChronoModal__title'>Запланировать период</div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  margin: '20px 0px',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: `${percent(percentAll as any)}%`,
                    backgroundColor: '#303030',
                    height: '16px',
                  }}
                ></div>
                <div
                  style={{
                    width: `${percentAll ? 100 - percent(percentAll) : 100}%`,
                    height: '16px',
                    display:
                      (percentAll ? 100 - percent(percentAll) : 100) === 0
                        ? 'none'
                        : 'block',
                    backgroundColor: '#f5f5f5',
                  }}
                ></div>
                <div
                  style={{
                    padding: '0px 10px',
                    height: '16px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {percentAll}%
                </div>
              </div>
              <div className='planChronoModal__table'>
                {items?.length === 0 ? (
                  <div
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    Нет запланированного времени
                  </div>
                ) : (
                  <div className='planChronoModal__table-head'>
                    <div className='planChronoModal__table-col planChronoModal__table-col1'>
                      Название
                    </div>
                    <div className='planChronoModal__table-col planChronoModal__table-col2'>
                      Время всего
                    </div>
                    <div className='planChronoModal__table-col planChronoModal__table-col3'>
                      Затрачено
                    </div>
                    <div className='planChronoModal__table-col planChronoModal__table-col4'>
                      Статус
                    </div>
                  </div>
                )}

                <div className='planChronoModal__table-body'>
                  {items?.map((e, index) => {
                    return (
                      <div key={index} className='planChronoModal__table-cell'>
                        <div className='planChronoModal__table-col planChronoModal__table-col1'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='16'
                            height='16'
                            viewBox='0 0 16 16'
                            fill='none'
                            style={{
                              paddingRight: '4px',
                            }}
                          >
                            <path
                              d='M3.66667 13.6667C3.48986 13.6667 3.32029 13.5964 3.19526 13.4714C3.07024 13.3464 3 13.1768 3 13V3C3 2.82318 3.07024 2.65362 3.19526 2.52859C3.32029 2.40357 3.48986 2.33333 3.66667 2.33333H7.92C8.04424 2.33246 8.16626 2.36634 8.27227 2.43113C8.37829 2.49591 8.46409 2.58904 8.52 2.7L9 3.66666H12.3333C12.5101 3.66666 12.6797 3.7369 12.8047 3.86192C12.9298 3.98695 13 4.15652 13 4.33333V9.66666C13 9.84347 12.9298 10.013 12.8047 10.1381C12.6797 10.2631 12.5101 10.3333 12.3333 10.3333H8.74C8.61689 10.333 8.49628 10.2985 8.39156 10.2338C8.28683 10.1691 8.20207 10.0766 8.14667 9.96666L7.66667 9H4.33333V13C4.33333 13.1768 4.2631 13.3464 4.13807 13.4714C4.01305 13.5964 3.84348 13.6667 3.66667 13.6667ZM4.33333 3.66666V7.66666H8.33333L9 9H11.6667V5H8.33333L7.66667 3.66666H4.33333Z'
                              fill='#AFAFAF'
                            />
                          </svg>
                          {e?.scaleMapFile?.name}
                        </div>
                        <div className='planChronoModal__table-col planChronoModal__table-col2'>
                          <div className='planChronoModal__input'>
                            <span
                              style={{
                                paddingRight: '3px',
                                paddingBottom: '1px',
                              }}
                            >
                              Время:
                            </span>
                            <AppInput name={`time${e?.scaleMapFile?.id}`} type='number' />
                          </div>
                        </div>
                        <div className='planChronoModal__table-col planChronoModal__table-col3'>
                          <ClockIcons2
                            style={{
                              marginRight: '10px',
                            }}
                          />
                          {e?.spentTime}
                        </div>
                        <div className='planChronoModal__table-col planChronoModal__table-col4'>
                          <Percent percent={e?.timeSpentPercentage} />
                          {e?.timeSpentPercentage}%
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                style={{
                  textAlign: 'center',
                  paddingBottom: '5px',
                  color: 'red',
                }}
              >
                {errors?.totalSum}
              </div>
              <div className='planChronoModal__buttons'>
                <Button type={'submit'}>Сохранить</Button>{' '}
                <Button
                  onClick={() => {
                    setFilter(!filter);
                  }}
                >
                  {filter ? 'Настройки' : 'Назад'}
                </Button>
                <Button onClick={onClose}>Закрыть</Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </RequestHandler>
  );
};

const Percent = ({ percent = 30 }: any) => {
  return (
    <div
      style={{
        width: '90px',
        height: '15px',
        marginRight: '5px',
        display: 'flex',
      }}
    >
      {percent !== 0 && (
        <div
          style={{
            width: `${percent}%`,
            height: '100%',
            backgroundColor: '#B3E4C1',
          }}
        ></div>
      )}
      {percent !== 100 && (
        <div
          style={{
            width: `${100 - percent}%`,
            height: '100%',
            backgroundColor: '#F5F5F5',
          }}
        ></div>
      )}
    </div>
  );
};

const percent = (percent: number) => {
  if (percent > 100) {
    return 100;
  }
  return percent;
};
