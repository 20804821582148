import * as yup from 'yup';

import * as MESSAGES from '../messages';

export const createComment = yup.object().shape({
  maintainerComment: yup
    .string()
    .trim()
    .min(3, MESSAGES.INCORRECT_MIN_LENGTH_3)
    .max(255, MESSAGES.TITLE_TOO_LONG)
    .required(MESSAGES.REQUIRE_MESSAGE),
});

export const editComment = yup.object().shape({
  maintainerComment: yup.string().trim(),
});
