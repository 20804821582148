import './DecideOnTheFlyLogsTable.scss';

import moment from 'moment';
import React from 'react';

import { GetUserDecideOnFlyActionsHistoryQuery } from '../../../../../graphql/generated/graphql';
import { textSliceSpace } from '../../../../../utils';

type DecideOnTheFlyLogsTableType = {
  history: GetUserDecideOnFlyActionsHistoryQuery | undefined;
};

export const DecideOnTheFlyLogsTable = ({ history }: DecideOnTheFlyLogsTableType) => {
  const rows = history?.getUserDecideOnFlyActionsHistory?.rows;

  return (
    <div className='DecideOnTheFlyLogsTable'>
      <div className='DecideOnTheFlyLogsTable__head'>
        <div
          className='DecideOnTheFlyLogsTable__head-item'
          style={{ paddingLeft: '10px' }}
        >
          Часы
        </div>
        <div className='DecideOnTheFlyLogsTable__head-item'>Задание</div>
        <div className='DecideOnTheFlyLogsTable__head-item'>Статус</div>
        <div className='DecideOnTheFlyLogsTable__head-item'>Причина</div>
      </div>
      <div className='DecideOnTheFlyLogsTable__body-outer'>
        {rows?.map((e) => {
          const formattedDate = moment(
            e.startDate ||
              moment.utc(parseInt(e.createdAt)).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          ).format('DD-MM-YYYY [в] HH:mm');

          const getStatus = () => {
            const createdAtTime = moment(parseInt(e.createdAt));
            const oneHourAgo = moment().subtract(1, 'hour');
            const isOneHourPassed = createdAtTime.isBefore(oneHourAgo);

            if (moment(e?.endDate).isAfter(moment())) {
              return Type.wait;
            } else if (!e.startDate && isOneHourPassed) {
              return Type.time_is_over;
            } else if (!e.startDate) {
              return Type.wait;
            } else {
              return e?.status;
            }
          };

          return (
            <div className='DecideOnTheFlyLogsTable__body' key={e?.id}>
              <div
                className='DecideOnTheFlyLogsTable__body-item'
                style={{ paddingLeft: '10px' }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  style={{
                    marginRight: '10px',
                  }}
                >
                  <path
                    d='M9.9974 1.66675C14.5999 1.66675 18.3307 5.39758 18.3307 10.0001C18.3307 14.6026 14.5999 18.3334 9.9974 18.3334C5.3949 18.3334 1.66406 14.6026 1.66406 10.0001C1.66406 5.39758 5.3949 1.66675 9.9974 1.66675ZM9.9974 2.82925C8.09557 2.82925 6.27164 3.58474 4.92685 4.92954C3.58206 6.27433 2.82656 8.09826 2.82656 10.0001C2.82656 11.9019 3.58206 13.7258 4.92685 15.0706C6.27164 16.4154 8.09557 17.1709 9.9974 17.1709C11.8992 17.1709 13.7231 16.4154 15.0679 15.0706C16.4127 13.7258 17.1682 11.9019 17.1682 10.0001C17.1682 8.09826 16.4127 6.27433 15.0679 4.92954C13.7231 3.58474 11.8992 2.82925 9.9974 2.82925ZM9.2224 6.31758C9.54323 6.31758 9.80323 6.57841 9.80323 6.89925V10.8334L13.6987 9.98456C13.8491 9.99025 13.9914 10.054 14.0957 10.1624C14.2001 10.2707 14.2584 10.4153 14.2584 10.5658C14.2584 10.7163 14.2001 10.8609 14.0957 10.9692C13.9914 11.0776 13.8491 11.1414 13.6987 11.1471L9.2224 12.1317C9.06813 12.1317 8.92018 12.0705 8.8111 11.9614C8.70201 11.8523 8.64073 11.7043 8.64073 11.5501V6.90008C8.64073 6.57841 8.90073 6.31758 9.2224 6.31758Z'
                    fill='#AFAFAF'
                  />
                </svg>
                {formattedDate}
              </div>
              <div className='DecideOnTheFlyLogsTable__body-item'>
                {textSliceSpace({
                  text: e?.decideOnFlyAction?.name,
                  count: 20,
                  space: true,
                })}
              </div>
              <div className='DecideOnTheFlyLogsTable__body-item'>
                <Status type={getStatus()} />
              </div>
              <div className='DecideOnTheFlyLogsTable__body-item'>
                {e?.reasonForNotDone}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

enum Type {
  wait = 'wait',
  will_be_performed = 'will_be_performed',
  not_done = 'not_done',
  time_is_over = 'time_is_over',
}

type StatusType = {
  type: string;
};

const Status = ({ type }: StatusType) => {
  if (type === Type.time_is_over) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {' '}
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9.99921 5C10.165 5 10.3239 5.06585 10.4412 5.18306C10.5584 5.30027 10.6242 5.45924 10.6242 5.625L10.6242 12.8663L13.3067 10.1825C13.4241 10.0651 13.5832 9.99921 13.7492 9.99921C13.9152 9.99921 14.0744 10.0651 14.1917 10.1825C14.3091 10.2999 14.375 10.459 14.375 10.625C14.375 10.791 14.3091 10.9501 14.1917 11.0675L10.4417 14.8175C10.3837 14.8757 10.3147 14.9219 10.2388 14.9534C10.1628 14.9849 10.0814 15.0011 9.99921 15.0011C9.917 15.0011 9.8356 14.9849 9.75967 14.9534C9.68374 14.9219 9.61477 14.8757 9.55671 14.8175L5.80671 11.0675C5.68935 10.9501 5.62342 10.791 5.62342 10.625C5.62342 10.459 5.68935 10.2999 5.80671 10.1825C5.92407 10.0651 6.08324 9.99921 6.24921 9.99921C6.41518 9.99921 6.57435 10.0651 6.69171 10.1825L9.37421 12.8663L9.37421 5.625C9.37421 5.45924 9.44006 5.30027 9.55727 5.18306C9.67448 5.06585 9.83345 5 9.99921 5Z'
            fill='#E80025'
          />
        </svg>
        Время вышло
      </div>
    );
  }
  if (type === Type.wait) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='6'
            height='6'
            viewBox='0 0 6 6'
            fill='none'
          >
            <path
              d='M6 3C6 1.34315 4.65685 -2.03558e-07 3 -1.31134e-07C1.34315 -5.87108e-08 -2.03558e-07 1.34315 -1.31134e-07 3C-5.87108e-08 4.65685 1.34315 6 3 6C4.65685 6 6 4.65685 6 3Z'
              fill='#303030'
            />
          </svg>
        </div>
        Ожидание
      </div>
    );
  }
  if (type === Type.will_be_performed) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10.0008 15C9.83503 15 9.67606 14.9342 9.55885 14.8169C9.44164 14.6997 9.37579 14.5408 9.37579 14.375L9.37579 7.13375L6.69329 9.8175C6.57593 9.93486 6.41676 10.0008 6.25079 10.0008C6.08482 10.0008 5.92565 9.93486 5.80829 9.8175C5.69093 9.70014 5.625 9.54097 5.625 9.375C5.625 9.20903 5.69093 9.04986 5.80829 8.9325L9.55829 5.1825C9.61635 5.1243 9.68532 5.07812 9.76125 5.04661C9.83718 5.0151 9.91858 4.99888 10.0008 4.99888C10.083 4.99888 10.1644 5.0151 10.2403 5.04661C10.3163 5.07812 10.3852 5.1243 10.4433 5.1825L14.1933 8.9325C14.3106 9.04986 14.3766 9.20903 14.3766 9.375C14.3766 9.54097 14.3106 9.70014 14.1933 9.8175C14.0759 9.93486 13.9168 10.0008 13.7508 10.0008C13.5848 10.0008 13.4256 9.93486 13.3083 9.8175L10.6258 7.13375L10.6258 14.375C10.6258 14.5408 10.5599 14.6997 10.4427 14.8169C10.3255 14.9342 10.1666 15 10.0008 15Z'
            fill='#149939'
          />
        </svg>
        Выполнил
      </div>
    );
  }
  if (type === Type.not_done) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9.99921 5C10.165 5 10.3239 5.06585 10.4412 5.18306C10.5584 5.30027 10.6242 5.45924 10.6242 5.625L10.6242 12.8663L13.3067 10.1825C13.4241 10.0651 13.5832 9.99921 13.7492 9.99921C13.9152 9.99921 14.0744 10.0651 14.1917 10.1825C14.3091 10.2999 14.375 10.459 14.375 10.625C14.375 10.791 14.3091 10.9501 14.1917 11.0675L10.4417 14.8175C10.3837 14.8757 10.3147 14.9219 10.2388 14.9534C10.1628 14.9849 10.0814 15.0011 9.99921 15.0011C9.917 15.0011 9.8356 14.9849 9.75967 14.9534C9.68374 14.9219 9.61477 14.8757 9.55671 14.8175L5.80671 11.0675C5.68935 10.9501 5.62342 10.791 5.62342 10.625C5.62342 10.459 5.68935 10.2999 5.80671 10.1825C5.92407 10.0651 6.08324 9.99921 6.24921 9.99921C6.41518 9.99921 6.57435 10.0651 6.69171 10.1825L9.37421 12.8663L9.37421 5.625C9.37421 5.45924 9.44006 5.30027 9.55727 5.18306C9.67448 5.06585 9.83345 5 9.99921 5Z'
            fill='#E80025'
          />
        </svg>
        Не выполнил
      </div>
    );
  }

  return <div></div>;
};
