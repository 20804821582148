import React from 'react';

export const DeleteIcon = ({ onClick }: any) => {
  return (
    <div
      style={{
        width: '16px',
        height: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        onClick={onClick}
      >
        <path d='M6 6H7V11.3333H6V6ZM9 6H10V11.3333H9V6Z' fill='#E80025' />
        <path
          d='M2.66406 3.71429V4.57143H3.55295V13.1429C3.55295 13.3702 3.6466 13.5882 3.8133 13.7489C3.98 13.9097 4.20609 14 4.44184 14H11.553C11.7887 14 12.0148 13.9097 12.1815 13.7489C12.3482 13.5882 12.4418 13.3702 12.4418 13.1429V4.57143H13.3307V3.71429H2.66406ZM4.44184 13.1429V4.57143H11.553V13.1429H4.44184ZM6.21962 2H9.77517V2.85714H6.21962V2Z'
          fill='#E80025'
        />
      </svg>
    </div>
  );
};
