import { toast } from 'react-toastify';

import * as GENERATED from '../../graphql/generated/graphql';
import { GetFileByIdDocument, GetFileByIdQuery } from '../../graphql/generated/graphql';
import { getErrorText } from '../../utils';

export const useUpdateFileMutation = () => {
  return GENERATED.useUpdateFileMutation({
    update(cache, { data }) {
      if (!data?.updateFile.id) return null;

      const file: GetFileByIdQuery | null = cache.readQuery({
        query: GetFileByIdDocument,
        variables: { id: data?.updateFile.id },
      });

      if (file) {
        cache.writeQuery({
          query: GetFileByIdDocument,
          variables: { id: data?.updateFile.id },
          data: {
            ...file,
            getFileById: {
              ...file?.getFileById,
              fileName: data?.updateFile.fileName,
            },
          },
        });
      }
    },
    onError: (response) => {
      toast.error(getErrorText(response.message));
    },
  });
};
