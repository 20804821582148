import { useLocation, useParams } from 'react-router-dom';

import * as GENERATED from '../../graphql/generated/graphql';
import { GetTaskDocument, GetTaskQuery } from '../../graphql/generated/graphql';

export const useCreateSubtaskMutation = () => {
  const { taskId } = useParams();

  const location = useLocation();

  const archivedTask = location.pathname.includes('archived');
  return GENERATED.useCreateSubtaskMutation({
    update(cache, { data }) {
      if (!data?.createSubtask) {
        return null;
      }

      const task: GetTaskQuery | null = cache.readQuery({
        query: GetTaskDocument,
        variables: !archivedTask
          ? { id: Number(taskId) }
          : { id: Number(taskId), subtaskStatusId: 8 },
      });

      const newSubtask = data.createSubtask;

      if (task) {
        cache.writeQuery({
          query: GetTaskDocument,
          variables: !archivedTask
            ? { id: Number(taskId) }
            : { id: Number(taskId), subtaskStatusId: 8 },
          data: {
            ...task,
            getTask: {
              ...task.getTask,
              subtasks: [newSubtask, ...task.getTask.subtasks],
            },
          },
        });
      }
    },
  });
};
