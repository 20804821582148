import React from 'react';

export const SearchIcons = ({ onClick, color = '#AFAFAF' }: any) => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='serch-input__icon'
      onClick={onClick}
    >
      <path
        d='M12.4607 11.7935L9.68401 8.90558C10.3979 8.05689 10.7891 6.98906 10.7891 5.8774C10.7891 3.28012 8.67598 1.16699 6.0787 1.16699C3.48142 1.16699 1.36829 3.28012 1.36829 5.8774C1.36829 8.47468 3.48142 10.5878 6.0787 10.5878C7.05375 10.5878 7.98293 10.2937 8.77736 9.73544L11.5751 12.6452C11.6921 12.7667 11.8494 12.8337 12.0179 12.8337C12.1775 12.8337 12.3288 12.7728 12.4437 12.6622C12.6878 12.4273 12.6956 12.0378 12.4607 11.7935ZM6.0787 2.3958C7.9985 2.3958 9.56031 3.9576 9.56031 5.8774C9.56031 7.7972 7.9985 9.35901 6.0787 9.35901C4.1589 9.35901 2.59709 7.7972 2.59709 5.8774C2.59709 3.9576 4.1589 2.3958 6.0787 2.3958Z'
        fill={color}
      />
    </svg>
  );
};
