import { gql } from '@apollo/client';

export const GET_PROJECTS_CALENDAR = gql`
  query getProjectsCalendar(
    $data: GetProjectsInput!
    $tasksSubtasksData: GetTasksInput!
  ) {
    getProjects(data: $data) {
      rows {
        id
        name
        number
        createdAt
        updatedAt
        tasksSubtasks(data: $tasksSubtasksData) {
          rows {
            id
            number
            name
            code
            parentId
            startDate
            closeDate
            endDate
            status {
              id
              color
            }
            project {
              id
              number
            }
            tags {
              count
              rows {
                id
                name
                color
                textColor
                createdAt
                updatedAt
              }
            }
          }
          count
        }
      }
      count
    }
  }
`;
