import { useState } from 'react';

import { AddArrowType } from '../types/enums';
import {
  arrowArrayType,
  arrowToolsChangeColorType,
  arrowToolsChangeParameterType,
  arrowToolsCheckDeselectType,
  arrowToolsDeleteType,
  arrowToolsDragMoveType,
  arrowToolsDragStartType,
  arrowToolsMouseDownType,
  arrowToolsMouseMoveType,
  arrowToolsSelectType,
  ArrowType,
} from '../types/types';
import { idGenerate } from '../utils';

export const useArrow = () => {
  const [arrowArrayDraggable, setArrayDraggable] = useState([
    {
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0,
      fill: 'black',
      id: idGenerate(),
      isStatus: 'Upsert',
    },
  ]);

  const [arrowArray, setArrowArray] = useState([
    {
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0,
      fill: 'black',
      id: idGenerate(),
      isStatus: 'Upsert',
    },
  ] as arrowArrayType);

  const [selectedIdArrow, setSelectedIdArrow] = useState<string | number | null>(null);

  const [addArrow, setAddArrow] = useState<AddArrowType>(AddArrowType.NO);

  /// watch move START ///
  const [oldX, setOldX] = useState(0);
  const [oldY, setOldY] = useState(0);

  const [startPoints, setStartPoints] = useState<Array<number | string>>(['']);
  const [endPoints, setEndPoints] = useState<Array<number | string>>(['']);
  /// watch move END ///

  type findType = {
    id: number | string | null;
  };

  const find = ({ id }: findType) => {
    const items = arrowArray.slice();
    const item = items.find((i: ArrowType) => i.id === id);
    const index = items.indexOf(item as ArrowType);
    return { index, items, item };
  };

  const arrowTools = {
    mouseDown: ({ event }: arrowToolsMouseDownType) => {
      if (addArrow === AddArrowType.Add) {
        const { x, y } = event?.target?.getStage()?.getPointerPosition() as any;
        const newDrawable = {
          startX: x,
          startY: y,
          endX: x,
          endY: y,
          fill: 'black',
          id: idGenerate(),
          isStatus: 'Upsert',
        };
        setArrayDraggable([newDrawable]);
        setAddArrow(AddArrowType.Down);
      }
    },
    mouseUp: () => {
      if (addArrow === AddArrowType.Down) {
        setArrowArray(arrowArray?.concat(arrowArrayDraggable as arrowArrayType));
        setArrayDraggable([
          {
            startX: 0,
            startY: 0,
            endX: 0,
            endY: 0,
            fill: 'white',
            id: idGenerate(),
            isStatus: 'Upsert',
          },
        ]);
        setAddArrow(AddArrowType.NO);
      }
    },
    mouseMove: ({ event }: arrowToolsMouseMoveType) => {
      if (addArrow === AddArrowType.Down) {
        const { x, y } = event?.target?.getStage()?.getPointerPosition() as any;
        const updatedNewDrawable = arrowArrayDraggable[0];
        updatedNewDrawable.endX = x;
        updatedNewDrawable.endY = y;
        setArrayDraggable([updatedNewDrawable]);
      }
    },
    dragStart: ({ event, rect }: arrowToolsDragStartType) => {
      const xCurrent = event.target.x();
      const yCurrent = event.target.y();
      setOldX(xCurrent);
      setOldY(yCurrent);
      const newStartArray: Array<number | string> = [''];
      const newEndArray: Array<number | string> = [''];

      arrowArray.map((arrow: ArrowType, index: number) => {
        if (xCurrent < arrow.startX && xCurrent + rect.width > arrow.startX) {
          if (yCurrent < arrow.startY && yCurrent + rect.height > arrow.startY) {
            newStartArray.push(index);
          }
        }
        if (xCurrent < arrow.endX && xCurrent + rect.width > arrow.endX) {
          if (yCurrent < arrow.endY && yCurrent + rect.height > arrow.endY) {
            newEndArray.push(index);
          }
        }
      });
      setStartPoints(newStartArray);
      setEndPoints(newEndArray);
    },
    dragMove: ({ event }: arrowToolsDragMoveType) => {
      setOldX(event.target.x());
      setOldY(event.target.y());

      const xCurrent = event.target.x();
      const yCurrent = event.target.y();

      const diffX = xCurrent - oldX;
      const diffY = yCurrent - oldY;

      const moveArrow = JSON.parse(JSON.stringify(arrowArray));

      arrowArray.map((arrow: ArrowType, index: number) => {
        if (startPoints.indexOf(index) !== -1) {
          moveArrow[index].startX = moveArrow[index].startX + diffX;
          moveArrow[index].startY = moveArrow[index].startY + diffY;
        }
        if (endPoints.indexOf(index) !== -1) {
          moveArrow[index].endX = moveArrow[index].endX + diffX;
          moveArrow[index].endY = moveArrow[index].endY + diffY;
        }
      });
      setArrowArray(moveArrow);
    },
    dragEnd: () => {
      setStartPoints(['']);
      setEndPoints(['']);
    },
    delete: ({ id }: arrowToolsDeleteType) => {
      const { index, items, item } = find({ id });
      items.splice(index, 1);
      items.push({ ...item, isStatus: 'Delete' } as ArrowType);
      setArrowArray(items);
      setSelectedIdArrow(null);
    },
    changeColor: ({ id, fill }: arrowToolsChangeColorType) => {
      const { index, items, item } = find({ id });
      items.splice(index, 1);
      items.push({ ...item, isStatus: 'Upsert', fill: fill } as ArrowType);
      setArrowArray(items);
    },
    checkDeselect: ({ event }: arrowToolsCheckDeselectType) => {
      const clickedOnEmpty = event.target === event.target.getStage();
      if (clickedOnEmpty) {
        setSelectedIdArrow(null);
      }
    },
    select: ({ id }: arrowToolsSelectType) => {
      setSelectedIdArrow(id);
    },
    changeParameter: ({ id, key, value }: arrowToolsChangeParameterType) => {
      const { index, items, item } = find({ id });
      items.splice(index, 1);
      items.push({ ...item, isStatus: 'Upsert', [key]: value } as ArrowType);
      setArrowArray(items);
    },
  };

  return {
    arrowArrayDraggable,
    arrowArray,
    setArrowArray,
    addArrow,
    setAddArrow,
    arrowTools,
    selectedIdArrow,
  };
};
