import './TreadsFile.scss';

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { RequestHandler, TredFileList } from '../../components';
import {
  useCreateScaleMapTaskMutation,
  useDeleteScaleMapFileMutation,
  useDeleteScaleMapTaskMutation,
  useGetAllUsersTredsQuery,
  useMoveScaleMapTaskMutation,
  useMoveScaleMapTaskToRowMutation,
  useUpdateScaleMapFileMutation,
  useUpdateScaleMapTaskMutation,
} from '../../graphql/generated/graphql';
import { textSlice } from '../../utils';

export const TreadsFile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selfLocation = location?.pathname?.includes('/file/self/');

  const [hideSuccess] = useState(true);
  const [hideSuccessInner, setHideSuccessInner] = useState(true);

  useEffect(() => {
    if (!hideSuccess) {
      setHideSuccessInner(false);
    }
  }, [hideSuccess]);

  const {
    createScaleMapTaskMutation,
    deleteScaleMapTaskMutation,
    updateScaleMapTaskMutation,
    moveScaleMapTaskToRowMutation,
    file,
    filterStatus,
    setFilterStatus,
    moveScaleMapTaskMutation,
  } = useFileModeration({ navigate, selfLocation, hideSuccessInner });

  const {
    selectFirstCol,
    setSelectFirstCol,
    selectTwoCol,
    setSelectTwoCol,
    selectThreeCol,
    setSelectThreeCol,
  } = useControlSelectCol({ setFilterStatus });
  const [selectTask, setSelectTask] = useState(null);

  return (
    <div className='container'>
      <RequestHandler loading={!file}>
        <div className='TreadsFile'>
          <div className='TreadsFile__body'>
            <div className='TreadsFile__title'>
              <div>
                <h2>Тред</h2>
                <div
                  className='TreadsFile__title-name'
                  onClick={() => {
                    if (selfLocation) {
                      navigate(`/projectI`);
                    } else {
                      navigate(`/scale`);
                    }
                  }}
                >
                  #{file?.project?.number} {selfLocation && 'Проект Я'}
                  {textSlice({ text: file?.project?.name, count: 85 })}
                </div>
              </div>
              <div className='TreadsFile__title-wrapper'></div>
            </div>
            <div className='horizontal__line'></div>
            <TredFileList
              scaleMapTasks={file}
              scaleMapColumnStatistics={file?.scaleMapColumnStatistics as any}
              createScaleMapTaskMutation={createScaleMapTaskMutation}
              updateScaleMapTaskMutation={updateScaleMapTaskMutation}
              deleteScaleMapTaskMutation={deleteScaleMapTaskMutation}
              moveScaleMapTaskToRowMutation={moveScaleMapTaskToRowMutation}
              selectTwoCol={selectTwoCol}
              setSelectTwoCol={setSelectTwoCol}
              selectThreeCol={selectThreeCol}
              setSelectThreeCol={setSelectThreeCol}
              selectFirstCol={selectFirstCol}
              setSelectFirstCol={setSelectFirstCol}
              filterStatus={filterStatus}
              setFilterStatus={setFilterStatus}
              hideSuccess={hideSuccess}
              selectTask={selectTask}
              setSelectTask={setSelectTask}
              hideSuccessInner={hideSuccessInner}
              moveScaleMapTaskMutation={moveScaleMapTaskMutation}
            />
          </div>
        </div>
      </RequestHandler>
    </div>
  );
};

const useControlSelectCol = ({
  setFilterStatus,
}: {
  setFilterStatus: (value: number | null) => void;
}) => {
  const [selectFirstCol, setSelectFirstCol] = useState(0);
  const [selectTwoCol, setSelectTwoCol] = useState(0);
  const [selectThreeCol, setSelectThreeCol] = useState(0);

  const reload = () => {
    setSelectTwoCol(0);
    setSelectThreeCol(0);
    setSelectFirstCol(0);
    setFilterStatus(null);
  };

  return {
    selectFirstCol,
    setSelectFirstCol,
    reload,
    selectTwoCol,
    setSelectTwoCol,
    selectThreeCol,
    setSelectThreeCol,
  };
};

const useFileModeration = ({ navigate, selfLocation }: any) => {
  const [file, setFile] = useState<any>();
  const [filterStatus, setFilterStatus] = useState<number | null>(null);

  const { refetch } = useGetAllUsersTredsQuery({
    variables: {
      input: {},
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const files: any = [];
      if (!data?.getAllUsers?.rows) return;
      data?.getAllUsers?.rows?.map((e, index) => {
        files.push({
          column: 1,
          row: index + 1,
          name: e.login,
          scaleMapTasks: e?.scaleMapTasksWithThreads,
          id: e.id,
        });
      });
      const files2 = files?.filter((e: any) => e.scaleMapTasks.length !== 0);
      const files3: any = [];

      files2?.map((e: any) => {
        const row = e.row;
        const parentID = e.id;

        files3.push({
          column: e.column,
          row: e.row,
          name: e.name,
          id: parentID + e.name,
        });
        files3.push({
          column: e.column,
          row: e.row,
          name: 'Тригер',
          id: parentID,
        });
        e?.scaleMapTasks?.map((e: any) => {
          files3.push({
            id: e.id,
            column: 2,
            row: row,
            name: e.name,
            scaleMapTaskId: parentID,
            status: e.status,
            children: e.children,
            threadId: e?.thread?.id,
          });
        });
      });
      const filesCol3: any = [];

      files3?.map((father: any) => {
        father?.children?.map((child: any) => {
          filesCol3?.push({
            id: child.id,
            column: 3,
            row: father.row,
            name: child.name,
            scaleMapTaskId: father.id,
            status: child.status,
          });
        });
      });
      setFile([...files3, ...filesCol3]);
    },
  });

  const [createScaleMapTaskMutation] = useCreateScaleMapTaskMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const [updateScaleMapTaskMutation] = useUpdateScaleMapTaskMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const [deleteScaleMapTaskMutation] = useDeleteScaleMapTaskMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const [updateScaleMapFileMutation] = useUpdateScaleMapFileMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const [deleteScaleMapFileMutation] = useDeleteScaleMapFileMutation({
    onCompleted: () => {
      if (selfLocation) {
        navigate(`/projectI`);
      } else {
        navigate(`/scale`);
      }
    },
  });

  const [moveScaleMapTaskToRowMutation] = useMoveScaleMapTaskToRowMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const [moveScaleMapTaskMutation] = useMoveScaleMapTaskMutation({
    onCompleted: () => {
      refetch();
      toast.success('Тред перемещен');
    },
    onError: () => {
      toast.error(`Ой`);
    },
  });

  return {
    createScaleMapTaskMutation,
    deleteScaleMapFileMutation,
    updateScaleMapFileMutation,
    deleteScaleMapTaskMutation,
    updateScaleMapTaskMutation,
    moveScaleMapTaskToRowMutation,
    file,
    filterStatus,
    setFilterStatus,
    moveScaleMapTaskMutation,
  };
};
