type calcTopMenuType = {
  top: number;
  height: number;
};

const heightMenu = 70;

export const calcTopMenu = ({ top, height }: calcTopMenuType) => {
  if (top < 0) {
    if (height <= heightMenu + 30) {
      return heightMenu + height / 2;
    }

    return 0;
  }
  return top;
};
