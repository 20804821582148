import { gql } from '@apollo/client';

export const GET_ALL_FILES = gql`
  query GetAllFiles($data: GetFileInput!) {
    getAllFiles(data: $data) {
      rows {
        id
        fileName
        path
        size
      }
    }
  }
`;
