import './TredsModal.scss';

import React, { useState } from 'react';

import { useMenuContext } from '../../../context';
import { CreateScaleMapTaskMutationFn } from '../../../graphql/generated/graphql';
import { CreateStatus } from './CreateStatus';

type TredsModalType = {
  x: number;
  y: number;
  createScaleMapTaskMutation: CreateScaleMapTaskMutationFn;
  closeModal: () => void;
};

export const TredsModal = ({
  x,
  y,
  createScaleMapTaskMutation,
  closeModal,
}: TredsModalType) => {
  const { isOpenMenu } = useMenuContext();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className='tredsModal'
      style={{ top: y - 45, left: x - (isOpenMenu ? 260 : 75) }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className='tredsModal__one'>
        <div className='tredsModal__title'>Создать тред</div>
        <div className='tredsModal__icons'>
          <div className={`tredsModal__icon tredsModal__icon-select`}>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M14.8332 7.32951H8.67782V1.17416C8.67782 0.804104 8.37738 0.5 8.00367 0.5C7.62995 0.5 7.32951 0.804104 7.32951 1.17416V7.32584H1.17416C0.994626 7.32584 0.822423 7.39546 0.697851 7.52369C0.569614 7.65193 0.5 7.82047 0.5 8C0.5 8.37372 0.804104 8.67416 1.17416 8.67416H7.32584V14.8258C7.32584 15.1996 7.62995 15.5 8 15.5C8.37372 15.5 8.67416 15.1959 8.67416 14.8258V8.67782H14.8258C15.1996 8.67782 15.5 8.37372 15.5 8.00366C15.5073 7.62995 15.2032 7.32951 14.8332 7.32951Z'
                fill={'white'}
              />
            </svg>
          </div>
        </div>
      </div>

      <CreateStatus
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        closeModal={closeModal}
        createScaleMapTaskMutation={createScaleMapTaskMutation}
      />
    </div>
  );
};
