import './ChatIconWithNumber.scss';

import React, { FC } from 'react';

export const ChatIconWithNumber: FC<ChatIconWithNumberType> = ({
  count,
  width,
  height,
  onClick,
}) => {
  return (
    <div className='chatIconWithNumber' onClick={onClick}>
      <svg
        width={width}
        height={height}
        viewBox='0 0 20 19'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M20 9C20 13.8325 15.5225 17.75 10 17.75C9.00956 17.7513 8.02323 17.6227 7.06625 17.3675C6.33625 17.7375 4.66 18.4475 1.84 18.91C1.59 18.95 1.4 18.69 1.49875 18.4575C1.94125 17.4125 2.34125 16.02 2.46125 14.75C0.93 13.2125 0 11.2 0 9C0 4.1675 4.4775 0.25 10 0.25C15.5225 0.25 20 4.1675 20 9ZM6.25 9C6.25 8.66848 6.1183 8.35054 5.88388 8.11612C5.64946 7.8817 5.33152 7.75 5 7.75C4.66848 7.75 4.35054 7.8817 4.11612 8.11612C3.8817 8.35054 3.75 8.66848 3.75 9C3.75 9.33152 3.8817 9.64946 4.11612 9.88388C4.35054 10.1183 4.66848 10.25 5 10.25C5.33152 10.25 5.64946 10.1183 5.88388 9.88388C6.1183 9.64946 6.25 9.33152 6.25 9ZM11.25 9C11.25 8.66848 11.1183 8.35054 10.8839 8.11612C10.6495 7.8817 10.3315 7.75 10 7.75C9.66848 7.75 9.35054 7.8817 9.11612 8.11612C8.8817 8.35054 8.75 8.66848 8.75 9C8.75 9.33152 8.8817 9.64946 9.11612 9.88388C9.35054 10.1183 9.66848 10.25 10 10.25C10.3315 10.25 10.6495 10.1183 10.8839 9.88388C11.1183 9.64946 11.25 9.33152 11.25 9ZM15 10.25C15.3315 10.25 15.6495 10.1183 15.8839 9.88388C16.1183 9.64946 16.25 9.33152 16.25 9C16.25 8.66848 16.1183 8.35054 15.8839 8.11612C15.6495 7.8817 15.3315 7.75 15 7.75C14.6685 7.75 14.3505 7.8817 14.1161 8.11612C13.8817 8.35054 13.75 8.66848 13.75 9C13.75 9.33152 13.8817 9.64946 14.1161 9.88388C14.3505 10.1183 14.6685 10.25 15 10.25Z'
          fill='white'
        />
      </svg>
      {count && count !== 0 && (
        <div className='chatIconWithNumber__number'>
          <span>
            <p>{count}</p>
          </span>
        </div>
      )}
    </div>
  );
};

type ChatIconWithNumberType = {
  count?: number;
  width?: string;
  height?: string;
  onClick?: () => void;
};
