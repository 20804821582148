import React from 'react';

export const AddPlusScaleIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.8332 9.32951H10.6778V3.17416C10.6778 2.8041 10.3774 2.5 10.0037 2.5C9.62995 2.5 9.32951 2.8041 9.32951 3.17416V9.32584H3.17416C2.99463 9.32584 2.82242 9.39546 2.69785 9.52369C2.56961 9.65193 2.5 9.82047 2.5 10C2.5 10.3737 2.8041 10.6742 3.17416 10.6742H9.32584V16.8258C9.32584 17.1996 9.62995 17.5 10 17.5C10.3737 17.5 10.6742 17.1959 10.6742 16.8258V10.6778H16.8258C17.1996 10.6778 17.5 10.3737 17.5 10.0037C17.5073 9.62995 17.2032 9.32951 16.8332 9.32951Z'
        fill='#303030'
      />
    </svg>
  );
};
