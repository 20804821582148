import React from 'react';

export const RedPinIcon = ({ className }: any) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M10.6667 8L12 9.33333V10.6667H8.66667V14.6667L8 15.3333L7.33333 14.6667V10.6667H4V9.33333L5.33333 8V3.33333H4.66667V2H11.3333V3.33333H10.6667V8Z'
        fill='#E5580A'
      />
    </svg>
  );
};
