import React from 'react';

type EyeIconsType = {
  className?: string;
  onClick?: () => void;
};

export const EyeIcons = ({ className = '', onClick }: EyeIconsType) => {
  return (
    <svg
      width='17'
      height='10'
      viewBox='0 0 17 10'
      fill='none'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        d='M1.75998 6.602C2.44198 3.327 5.29298 1 8.49998 1C11.706 1 14.557 3.327 15.24 6.602C15.267 6.73196 15.3446 6.84584 15.4556 6.91861C15.5666 6.99137 15.702 7.01705 15.832 6.99C15.9619 6.96295 16.0758 6.88538 16.1486 6.77436C16.2213 6.66334 16.247 6.52796 16.22 6.398C15.443 2.673 12.193 0 8.49998 0C4.80698 0 1.55698 2.673 0.779976 6.398C0.752924 6.52796 0.778605 6.66334 0.851369 6.77436C0.924133 6.88538 1.03802 6.96295 1.16798 6.99C1.29793 7.01705 1.43331 6.99137 1.54433 6.91861C1.65535 6.84584 1.73292 6.73196 1.75998 6.602ZM8.49998 3C7.57172 3 6.68148 3.36875 6.0251 4.02513C5.36873 4.6815 4.99998 5.57174 4.99998 6.5C4.99998 7.42826 5.36873 8.3185 6.0251 8.97487C6.68148 9.63125 7.57172 10 8.49998 10C9.42823 10 10.3185 9.63125 10.9748 8.97487C11.6312 8.3185 12 7.42826 12 6.5C12 5.57174 11.6312 4.6815 10.9748 4.02513C10.3185 3.36875 9.42823 3 8.49998 3ZM5.99998 6.5C5.99998 5.83696 6.26337 5.20107 6.73221 4.73223C7.20105 4.26339 7.83694 4 8.49998 4C9.16302 4 9.7989 4.26339 10.2677 4.73223C10.7366 5.20107 11 5.83696 11 6.5C11 7.16304 10.7366 7.79893 10.2677 8.26777C9.7989 8.73661 9.16302 9 8.49998 9C7.83694 9 7.20105 8.73661 6.73221 8.26777C6.26337 7.79893 5.99998 7.16304 5.99998 6.5Z'
        fill='black'
      />
    </svg>
  );
};
