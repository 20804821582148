import './DeleteBlock.scss';

import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  useDeleteProjectMutation,
  useDeleteSubtaskMutation,
  useDeleteTaskMutation,
} from '../../../hooks/useMutations';
import { ConfirmModal } from '../../confirmModal/ConfirmModal';
import { CrossIcon } from '../../icons';

export const DeleteBlock: FC<DeleteBlockType> = ({
  entityId,
  initialTitle,
  color = '#AFAFAF',
  projectId,
  handleDelete,
}) => {
  const navigate = useNavigate();

  const location = useLocation();

  const { taskId, subtaskId } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [deleteProject] = useDeleteProjectMutation({
    projectId: Number(entityId),
    refech: () => {
      handleDelete(Number(entityId));
    },
  });

  const [deleteTask] = useDeleteTaskMutation(projectId);

  const [deleteSubtask] = useDeleteSubtaskMutation();

  const handleCloseModal = () => setIsModalOpen(false);

  const handleOpenModal = () => setIsModalOpen(true);

  const isArchivePage = location.pathname.includes('archive');

  const handleDeletelocl = async () => {
    const deleteVariables = { id: Number(entityId) };

    if (subtaskId && taskId) {
      await deleteSubtask({ variables: deleteVariables });
      handleCloseModal();
      navigate(isArchivePage ? `/archivedTask/${taskId}` : `/task/${taskId}`);
    } else if (!subtaskId && taskId) {
      await deleteTask({ variables: deleteVariables });
      handleCloseModal();
      navigate(isArchivePage ? '/archive' : '/');
    } else if (!subtaskId && !taskId) {
      await deleteProject({ variables: { id: Number(entityId), force: isArchivePage } });
      handleCloseModal();
    }
  };

  useEffect(() => {
    return () => {
      setIsModalOpen(false);
    };
  }, []);

  return (
    <>
      <div className='deleteBlock' onClick={handleOpenModal}>
        <CrossIcon width={13} height={13} color={color} />
        <span>Удалить</span>
      </div>
      <ConfirmModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        callback={handleDeletelocl}
        confirmTitle={initialTitle}
      />
    </>
  );
};

type DeleteBlockType = {
  entityId?: number | null;
  projectId?: number | null;
  initialTitle?: string;
  color?: string;
  handleDelete: (itemId: number) => void;
};
