import { ApolloError } from '@apollo/client';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as GENERATED from '../../graphql/generated/graphql';
import {
  GetTaskDocument,
  GetTaskQuery,
  SortingField,
  SortingOrders,
} from '../../graphql/generated/graphql';
import { GET_ARCHIVED_PROJECTS, GET_PROJECTS } from '../../graphql/queries';
import { getErrorText } from '../../utils';

export const useUpdateTaskMutation = () => {
  const { taskId } = useParams();

  const location = useLocation();

  const archivedTask = location.pathname.includes('archived');

  const getProjectVariables = {
    data: {
      limit: 100,
      sort: { field: SortingField.Number, type: SortingOrders.Asc },
    },
    tasksData: {
      sort: { type: SortingOrders.Desc, field: SortingField.Activity },
      limit: 100,
    },
  };
  return GENERATED.useUpdateTaskMutation({
    update(cache, { data }) {
      if (!data?.updateTask) {
        return null;
      }

      const task: GetTaskQuery | null = cache.readQuery({
        query: GetTaskDocument,
        variables: !archivedTask
          ? { id: Number(taskId) }
          : { id: Number(taskId), subtaskStatusId: 8 },
      });

      cache.writeQuery({
        query: GetTaskDocument,
        variables: !archivedTask
          ? { id: Number(taskId) }
          : { id: Number(taskId), subtaskStatusId: 8 },
        data: {
          ...task,
          getTask: data?.updateTask,
        },
      });
    },
    refetchQueries: [
      { query: GET_PROJECTS, variables: getProjectVariables },
      { query: GET_ARCHIVED_PROJECTS, variables: { data: { limit: 100 } } },
    ],
    onError(error: ApolloError) {
      if (error?.message) {
        toast.error(getErrorText(error.message));
      }
    },
  });
};
