import * as yup from 'yup';

import * as MESSAGES from '../messages';

export const addFileTaskSchema = yup.object().shape({
  name: yup
    .string()
    .min(1, MESSAGES.INCORRECT_MIN_LENGTH_1)
    .required(MESSAGES.REQUIRE_MESSAGE),
});
