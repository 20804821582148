import { format } from 'date-fns';

type Options = {
  locale?: Locale | undefined;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;
  firstWeekContainsDate?: number | undefined;
  useAdditionalWeekYearTokens?: boolean | undefined;
  useAdditionalDayOfYearTokens?: boolean | undefined;
};

export const formatDate = (date: Date, dateFormat: string, options?: Options) => {
  return format(date, dateFormat, options);
};
