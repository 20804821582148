import './LongArrowTopIcon.scss';

import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const LongArrowTopIcon: FC<LongArrowTopType> = ({
  width = '20px',
  height = '20px',
  color = '#303030',
  className = 'long-arrow-top-icon',
  onClick,
  style,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 10 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      style={style}
    >
      <path
        d='M3.81303 2.97974L1.99992 4.79285L1.54036 4.33329L4.99992 0.873733L8.45948 4.33329L7.99992 4.79285L6.18681 2.97974L5.33325 2.12619V3.33329V11.3333H4.66659V3.33329V2.12619L3.81303 2.97974Z'
        fill={color}
      />
    </svg>
  );
};

type LongArrowTopType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
> & { style?: any };
