import { useRef, useState } from 'react';

export const useLine = () => {
  const stage = useRef<any>();

  const [h_lines, setHlines] = useState([]);
  const [v_lines, setVlines] = useState([]);

  // where can we snap our objects?
  const getLineGuideStops = (skipShape: any) => {
    // we can snap to stage borders and the center of the stage
    const vertical = [0, stage.current.width() / 2, stage.current.width()];
    const horizontal = [0, stage.current.height() / 2, stage.current.height()];

    // and we snap over edges and center of each object on the canvas

    stage.current.find('Group').forEach((guideItem: any) => {
      if (guideItem === skipShape) {
        return;
      }
      const box = guideItem.getClientRect({ relativeTo: stage.current });
      // and we can snap to all edges of shapes
      vertical.push([box.x, box.x + box.width, box.x + box.width / 2]);
      horizontal.push([box.y, box.y + box.height, box.y + box.height / 2]);
    });
    return {
      vertical: vertical.flat(),
      horizontal: horizontal.flat(),
    };
  };

  // what points of the object will trigger to snapping?
  // it can be just center of the object
  // but we will enable all edges and center
  const getObjectSnappingEdges = (node: any) => {
    const box = node.getClientRect({ relativeTo: stage.current });
    const absPos = node.absolutePosition();
    return {
      vertical: [
        {
          guide: Math.round(box.x),
          offset: Math.round(absPos.x - box.x),
          snap: 'start',
        },
        {
          guide: Math.round(box.x + box.width / 2),
          offset: Math.round(absPos.x - box.x - box.width / 2),
          snap: 'center',
        },
        {
          guide: Math.round(box.x + box.width),
          offset: Math.round(absPos.x - box.x - box.width),
          snap: 'end',
        },
      ],
      horizontal: [
        {
          guide: Math.round(box.y),
          offset: Math.round(absPos.y - box.y),
          snap: 'start',
        },
        {
          guide: Math.round(box.y + box.height / 2),
          offset: Math.round(absPos.y - box.y - box.height / 2),
          snap: 'center',
        },
        {
          guide: Math.round(box.y + box.height),
          offset: Math.round(absPos.y - box.y - box.height),
          snap: 'end',
        },
      ],
    };
  };

  // find all snapping possibilities
  const getGuides = (lineGuideStops: any, itemBounds: any) => {
    const resultV = [] as any;
    const resultH = [] as any;
    const GUIDELINE_OFFSET = 5;

    lineGuideStops.vertical.forEach((lineGuide: any) => {
      itemBounds.vertical.forEach((itemBound: any) => {
        const diff = Math.abs(lineGuide - itemBound.guide);
        // if the distance between guild line and object snap point is close we can consider this for snapping
        if (diff < GUIDELINE_OFFSET) {
          resultV.push({
            lineGuide: lineGuide,
            diff: diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          });
        }
      });
    });

    lineGuideStops.horizontal.forEach((lineGuide: any) => {
      itemBounds.horizontal.forEach((itemBound: any) => {
        const diff = Math.abs(lineGuide - itemBound.guide);
        if (diff < GUIDELINE_OFFSET) {
          resultH.push({
            lineGuide: lineGuide,
            diff: diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          });
        }
      });
    });

    const guides = [];

    // find closest snap
    const minV = resultV.sort((a: any, b: any) => a.diff - b.diff)[0];
    const minH = resultH.sort((a: any, b: any) => a.diff - b.diff)[0];
    if (minV) {
      guides.push({
        lineGuide: minV.lineGuide,
        offset: minV.offset,
        orientation: 'V',
        snap: minV.snap,
      });
    }
    if (minH) {
      guides.push({
        lineGuide: minH.lineGuide,
        offset: minH.offset,
        orientation: 'H',
        snap: minH.snap,
      });
    }
    return guides;
  };

  const drawGuides = (guides: any) => {
    if (guides) {
      guides.forEach((lg: any) => {
        if (lg.orientation === 'H') {
          const guide = {
            points: [-6000, 0, 6000, 0],
            stroke: 'rgb(0, 161, 255)',
            strokeWidth: 1,
            name: 'guid-line',
            dash: [4, 6],
            x: 0,
            y: lg.lineGuide,
          };
          setHlines([...guides, guide] as any);
        } else if (lg.orientation === 'V') {
          const guide = {
            points: [0, -6000, 0, 6000],
            stroke: 'rgb(0, 161, 255)',
            strokeWidth: 1,
            name: 'guid-line',
            dash: [4, 6],
            x: lg.lineGuide,
            y: 0,
          };
          setVlines([...guides, guide] as any);
        }
      });
    }
  };

  const lineTools = {
    onDragMoveLine: (e: any) => {
      // clear all previous lines on the screen
      // layer.find('.guid-line').destroy();

      // find possible snapping lines
      const lineGuideStops = getLineGuideStops(e.target);
      // find snapping points of current object
      const itemBounds = getObjectSnappingEdges(e.target);

      // now find where can we snap current object
      const guides = getGuides(lineGuideStops, itemBounds);

      // do nothing of no snapping
      if (!guides.length) {
        return;
      }

      drawGuides(guides);

      const absPos = e.target.absolutePosition();
      // now force object position
      guides.forEach((lg: any) => {
        switch (lg.snap) {
          case 'start': {
            switch (lg.orientation) {
              case 'V': {
                absPos.x = lg.lineGuide + lg.offset;
                break;
              }
              case 'H': {
                absPos.y = lg.lineGuide + lg.offset;
                break;
              }
              default:
                break;
            }
            break;
          }
          case 'center': {
            switch (lg.orientation) {
              case 'V': {
                absPos.x = lg.lineGuide + lg.offset;
                break;
              }
              case 'H': {
                absPos.y = lg.lineGuide + lg.offset;
                break;
              }
              default:
                break;
            }
            break;
          }
          case 'end': {
            switch (lg.orientation) {
              case 'V': {
                absPos.x = lg.lineGuide + lg.offset;
                break;
              }
              case 'H': {
                absPos.y = lg.lineGuide + lg.offset;
                break;
              }
              default:
                break;
            }
            break;
          }
          default:
            break;
        }
      });
      // eslint-disable-next-line
      e.target.absolutePosition(absPos);
    },
    onDragEndLine: () => {
      setHlines([]);
      setVlines([]);
    },
  };

  return { h_lines, v_lines, stage, lineTools };
};
