import './DecideOnTheFlyPackageItem.scss';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { PATHS } from '../../constants/constants';
import {
  DecideOnFlyPackage,
  UpdateDecideOnFlyPackageMutationFn,
} from '../../graphql/generated/graphql';

type DecideOnTheFlyPackageItemType = {
  decideOnFlyPackage: DecideOnFlyPackage;
  updateDecideOnFlyPackageMutation: UpdateDecideOnFlyPackageMutationFn;
};

export const DecideOnTheFlyPackageItem = ({
  decideOnFlyPackage,
  updateDecideOnFlyPackageMutation,
}: DecideOnTheFlyPackageItemType) => {
  const navigation = useNavigate();

  return (
    <div
      className='decideOnTheFlyPackageItem'
      onClick={() => {
        navigation({
          pathname: PATHS.decideOnTheFlyCreate,
          search: `&id=${decideOnFlyPackage.id}`,
        });
      }}
    >
      <div className='decideOnTheFlyPackageItem__inner'>
        <div>
          <div className='decideOnTheFlyPackageItem__name'>
            <div
              className='decideOnTheFlyPackageItem__name-color'
              style={{
                backgroundColor: decideOnFlyPackage?.color
                  ? decideOnFlyPackage?.color
                  : '',
              }}
            ></div>
            <div className='decideOnTheFlyPackageItem__name-text'>
              {decideOnFlyPackage?.title}
            </div>
          </div>
          <div className='decideOnTheFlyPackageItem__subscriptions'>
            {decideOnFlyPackage?.description ? (
              <span className='decideOnTheFlyPackageItem__subscriptions-text'>
                {decideOnFlyPackage?.description}
              </span>
            ) : (
              <div
                style={{
                  opacity: '0',
                }}
              >
                1
              </div>
            )}
          </div>
        </div>
        <div className='decideOnTheFlyPackageItem__footer'>
          <div
            className='decideOnTheFlyPackageItem__footer-status'
            style={{
              backgroundColor: decideOnFlyPackage?.isActive ? '#149939' : '#AFAFAF',
            }}
            onClick={(e) => {
              e.stopPropagation();
              updateDecideOnFlyPackageMutation({
                variables: {
                  updateDecideOnFlyPackageInput: {
                    id: decideOnFlyPackage.id,
                    isActive: !decideOnFlyPackage?.isActive,
                  },
                },
              });
            }}
          >
            {decideOnFlyPackage?.isActive ? 'Активирован' : 'Не активно'}
          </div>
          <div className='decideOnTheFlyPackageItem__footer-percent'>
            {decideOnFlyPackage?.isActive
              ? decideOnFlyPackage?.decideOnFlyActionsSumPercent
              : 0}
            %
          </div>
          <div
            className='decideOnTheFlyPackageItem__footer-counterName'
            style={{
              marginLeft: '10px',
            }}
          >
            Спонтанок:
            <span className='decideOnTheFlyPackageItem__footer-counter'>
              {decideOnFlyPackage?.countDecideOnFlyActions
                ? decideOnFlyPackage?.countDecideOnFlyActions
                : 0}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
