import './CrossedTextIcon.scss';

import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const CrossedTextIcon: FC<CrossedTextIconType> = ({
  width = '20px',
  height = '20px',
  className = 'crossed-text-icon',
  color = '#FFFFFF',
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 8 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M7.64844 12H6.21094V1.85938H1.75781V12H0.328125V0.625H7.64844V12Z'
        fill={color}
      />
      <path d='M-0.96875 8H8.96094V8.79688H-0.96875V8Z' fill={color} />
    </svg>
  );
};

type CrossedTextIconType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
>;
