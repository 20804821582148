import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

import { TasksList, TasksListItemType } from '../../components';
import { PATHS, tasksQueryLimit } from '../../constants/constants';
import { useAuthContext } from '../../context';
import {
  SortingField,
  SortingOrders,
  useGetTasksSubtasksQuery,
} from '../../graphql/generated/graphql';

export const Tasks = () => {
  const { currentUserData } = useAuthContext();
  const [currentPage, setCurrentPage] = useState(1);

  const { data, loading } = useGetTasksSubtasksQuery({
    variables: {
      data: {
        offset: (currentPage - 1) * tasksQueryLimit,
        limit: tasksQueryLimit,
        sort: {
          type: SortingOrders.Desc,
          field: SortingField.StatusId,
        },
        isTaskStorage: false,
        statusId: null,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  if (currentUserData?.role !== 'Admin') return <Navigate replace to={PATHS.calendar} />;

  const allTasksList = () => {
    const allTasksListArray: TasksListItemType[] = [];

    data?.getTasksSubtasks?.rows.map((task) => {
      const index = allTasksListArray.findIndex((o) => o?.id === task?.id);
      if (index !== -1) return;
      allTasksListArray.push({
        id: task?.id,
        project: `#${task?.project?.number} ${task?.project?.name}`,
        name: `#${task?.code} ${task?.name}`,
        status: task?.status?.id,
        createdAt: task?.createdAt,
        isSubtask: false,
        maintainer: {
          id: task?.maintainer?.id,
          login: task?.maintainer?.login,
          image: task?.maintainer?.image,
        },
      });

      if (task?.subtasks?.length) {
        task?.subtasks?.map((subtask) => {
          const index = allTasksListArray.findIndex((o) => o?.id === task?.id);
          if (index !== -1) return;
          allTasksListArray.push({
            id: subtask?.id,
            project: `#${subtask?.task?.project?.number} ${subtask?.task?.project?.name}`,
            name: `#${subtask?.code} ${subtask?.name}`,
            status: subtask?.status?.id,
            createdAt: subtask?.createdAt,
            isSubtask: true,
            parentId: subtask?.task?.id,
            maintainer: {
              id: subtask?.maintainer?.id,
              login: subtask?.maintainer?.login,
              image: subtask?.maintainer?.image,
            },
          });
        });
      }
    });

    return allTasksListArray;
  };

  return (
    <div className='container'>
      <TasksList
        isLoading={loading}
        mappedData={allTasksList()}
        count={data?.getTasksSubtasks?.count}
        pageTitle='Все задачи'
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};
