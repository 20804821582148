import React from 'react';

export const ImageRightTemplateIcons = ({ color = '#303030' }: any) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='1' y='3' width='6' height='2' fill={color} />
      <rect x='1' y='6' width='6' height='6' fill={color} />
      <rect x='8' y='3' width='7' height='7' fill={color} />
    </svg>
  );
};
