import './OptionsMenu.scss';

import React, { FC } from 'react';

import { OptionsType } from '../../constants/constants';
import { Popover } from '../popover/Popover';

export const OptionsMenu: FC<{
  isOpen: boolean;
  isChildrenPopoverOpen?: boolean;
  options?: OptionsType;
  childrenOptions?: OptionsType;
  onClickCallback?: (value: string) => void;
}> = ({
  options,
  childrenOptions,
  isOpen = false,
  onClickCallback,
  isChildrenPopoverOpen = false,
}) => {
  return (
    <div className={`options-menu ${isOpen ? 'options-menu_open' : ''}`}>
      <Popover>
        {options?.map(
          ({ title, Icon, isHaveChildrenPopover, onClick, className }, idx) => {
            const handleClick = () => {
              onClick ? onClick(title) : onClickCallback && onClickCallback(title);
            };
            return (
              <div key={`${title}${idx}`} className='options-menu__container'>
                {Icon && (
                  <div id={title} className={className}>
                    {<Icon /> || null}
                  </div>
                )}
                <label htmlFor={title} onClick={handleClick}>
                  {title}
                </label>
                {isHaveChildrenPopover && (
                  <div id='children-popover' className='options-menu__children-arrow' />
                )}
                {isChildrenPopoverOpen && (
                  <Popover>
                    {childrenOptions?.map(({ title, onClick }, index) => {
                      const handleClick = () => {
                        onClick
                          ? onClick(title)
                          : onClickCallback && onClickCallback(title);
                      };
                      return (
                        <div
                          key={`${title}${index}`}
                          className='options-menu__children-container'
                        >
                          <label htmlFor='children-popover' onClick={handleClick}>
                            {title}
                          </label>
                        </div>
                      );
                    })}
                  </Popover>
                )}
              </div>
            );
          },
        )}
      </Popover>
    </div>
  );
};
