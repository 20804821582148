import './NotesItem.scss';

import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { ROLES } from '../../constants/constants';
import { useAuthContext } from '../../context';
import { useRemoveNoteMutation } from '../../hooks/useMutations';
import { addKarma, formatToDateAndTime } from '../../utils';
import { CrossIcon } from '../icons';
import { LinkifyText } from '../linkifyText/LinkifyText';
import { NotesType } from '../notes/Notes';
import { UserItem } from '../user-item/UserItem';

type PrevNoteType = {
  note: NotesType | null;
  assignees?: Array<{
    id?: number | null | undefined;
    login?: string | null | undefined;
    image?: string | null | undefined;
  } | null>;
  refetch: () => void;
};

export const NotesItem: FC<PrevNoteType> = ({ note, refetch, assignees }) => {
  const { privateChatId } = useParams();

  const [removeNote] = useRemoveNoteMutation();

  const { currentUserData } = useAuthContext();

  const handleRemoveNote = async (id: number | null | undefined) => {
    if (id) {
      await removeNote({
        variables: {
          id,
        },
        onCompleted: (response) => {
          if (response?.removeNote) {
            refetch();
          }
        },
      });
    }
  };

  const checkRightsForDeleteNote = () => {
    if (
      currentUserData?.role === 'User' &&
      currentUserData?.login === note?.owner?.login &&
      assignees?.some((assigned) => assigned?.login === currentUserData?.login)
    )
      return true;

    if (
      (currentUserData?.role === 'Moderator' &&
        currentUserData?.login === note?.owner?.login) ||
      (assignees?.some((assigned) => assigned?.login === currentUserData?.login) &&
        note?.owner?.role !== 'Admin' &&
        currentUserData?.login === note?.owner?.login) ||
      (currentUserData?.role !== note?.owner?.role &&
        note?.owner?.role !== 'Moderator' &&
        note?.owner?.role !== 'Admin' &&
        assignees?.some((assigned) => assigned?.login === currentUserData?.login))
    )
      return true;

    return (
      (currentUserData?.role === 'Admin' && note?.owner?.role === 'User') ||
      (currentUserData?.role === 'Admin' && note?.owner?.role === 'Moderator') ||
      currentUserData?.login === note?.owner?.login
    );
  };

  return (
    <div className='notes__item-container'>
      <div className='notes__item'>
        <div className='notes__item--text'>
          <LinkifyText>{note?.content || ''}</LinkifyText>
        </div>
        <div className='notes__item--actions'>
          {privateChatId ? (
            <>
              {(note?.owner?.id === currentUserData?.id ||
                currentUserData?.role === ROLES.ADMIN) && (
                <CrossIcon onClick={() => handleRemoveNote(note?.id)} />
              )}
            </>
          ) : (
            checkRightsForDeleteNote() && (
              <CrossIcon onClick={() => handleRemoveNote(note?.id)} />
            )
          )}
        </div>
      </div>
      <div className='notes__item--user'>
        <UserItem
          avatar={note?.owner?.image}
          name={addKarma(note?.owner as any)}
          id={note?.owner?.id as number}
        />
        <span>{formatToDateAndTime(note?.createdAt)}</span>
      </div>
    </div>
  );
};
