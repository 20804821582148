import './Header.scss';

import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Link, useLocation } from 'react-router-dom';

import logo from '../../assets/logo.svg';
import {
  MyPersonalPanel,
  MyPersonalPanelButton,
  SearchInputHeader,
  TredSelectUser,
} from '../../components';
import { PATHS, ROLES } from '../../constants/constants';
import { useAuthContext, useTredsContext } from '../../context';
import {
  AlertCircleIcons,
  HeaderCalendarIcons,
  HeaderForumIcons,
  HeaderGraphicsIcons,
  HeaderMapIcons,
  HeaderStorageIcons,
} from '../icons';

export type User = {
  id: number;
  name: string;
  avatar: string;
};

export const Header = () => {
  const location = useLocation();
  const { currentUserData, refetch }: any = useAuthContext();

  const forumMessages = currentUserData.IsExistUnViewedForum;

  const [isMyPersonalPanelOpen, setIsMyPersonalPanelOpen] = useState(false);

  const isArchivePage = location.pathname.includes('archive');

  const isGuest = currentUserData.role === ROLES.GUEST;
  const { setSerchTred } = useTredsContext();

  // for notification forum
  useEffect(() => {
    refetch();
  }, [location.pathname]);

  return (
    <div
      className='header'
      style={
        isArchivePage ? { backgroundColor: '#2a52be' } : { backgroundColor: '#303030' }
      }
    >
      <div
        className='header__logo'
        onClick={() => {
          setSerchTred('');
        }}
      >
        <Link to={PATHS.treads}>
          <img src={logo} alt='logo' />
        </Link>
      </div>
      <div className='header__right'>
        <TredSelectUser />
        <div className='searchInputHeader__line'></div>
        <div className='header__linkWrapper'>{!isGuest && <SearchInputHeader />}</div>
        <div className='header__actions'>
          {!isGuest && (
            <>
              <>
                <Link to={PATHS.calendar} className='header__link'>
                  <HeaderCalendarIcons />
                </Link>
                <div className='header__line' />
              </>
              <>
                <Link to={PATHS.scale} className='header__link'>
                  <HeaderMapIcons />
                </Link>
                <div className='header__line' />
              </>
              <>
                <Link to={PATHS.fileGraphics} className='header__link'>
                  <HeaderGraphicsIcons />
                </Link>
                <div className='header__line' />
              </>
              <>
                <Link to={PATHS.storage} className='header__link'>
                  <HeaderStorageIcons />
                </Link>
                <div className='header__line' />
              </>
              <>
                <Link to={PATHS.forum} className='header__link'>
                  <HeaderForumIcons />
                  {forumMessages && <AlertCircleIcons className='ml5' color='red' />}
                </Link>
                <div className='header__line' />
              </>
            </>
          )}

          <MyPersonalPanelButton onClick={() => setIsMyPersonalPanelOpen(true)} />
          <ReactModal
            className='MyPersonalPanel__modal'
            overlayClassName='MyPersonalPanel__modal-overlay'
            onRequestClose={() => setIsMyPersonalPanelOpen(false)}
            isOpen={isMyPersonalPanelOpen}
            ariaHideApp={false}
          >
            <MyPersonalPanel onRequestClose={() => setIsMyPersonalPanelOpen(false)} />
          </ReactModal>
        </div>
      </div>
    </div>
  );
};
