import './ItemCircle.scss';

import React from 'react';

import { textSlice } from '../../../utils';
import { EllipseCircleIcons } from '../../icons';

type ItemCircleType = {
  selectNumber: number;
  selectColor: string;
  refIcon: any;
  color: string;
  myNumber: number;
  text?: string;
  circleRadius: number;
  angle: number;
  scale: number;
};

export const ItemCircle = ({
  selectNumber,
  selectColor,
  refIcon,
  color,
  myNumber,
  text,
  circleRadius,
  angle,
  scale,
}: ItemCircleType) => {
  const select = selectNumber === myNumber;
  const scaleCalc = () => {
    switch (scale) {
      case 1: {
        return 20;
      }
      case 0.8: {
        return 12;
      }
      case 0.65: {
        return 8;
      }
      default:
        return 8;
    }
  };

  return (
    <div
      className='DecideOnTheFlyCircle__item'
      style={{
        transform: `rotate(${angle}deg) translate(${circleRadius}px)`,
      }}
    >
      {select && (
        <div className='DecideOnTheFlyCircle__item-text'>
          {textSlice({
            text: text,
            count: scaleCalc(),
          })}
        </div>
      )}

      <EllipseCircleIcons
        style={{ transform: `rotate(90deg) scale(${scale})` }}
        fill={select ? selectColor : color}
        refIcon={refIcon}
      />
    </div>
  );
};
