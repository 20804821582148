import './ScaleAccordion.scss';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { arrowUp } from '../../../assets/icons';
import { PATHS } from '../../../constants/constants';
import { useModalContext } from '../../../context/ModalContext';
import { Project as ProjectType, ScaleMapFile } from '../../../graphql/generated/graphql';
import { ModalStatuses } from '../../../types/enums';
import { FinishIcons, FlagIcons } from '../../icons';
import { Button } from '../../index';
import { ScalePopup2 } from '../scalePopup2/ScalePopup2';

type ScaleAccordionType = {
  project: ProjectType;
  createScaleMapFileMutation: (value: any) => void;
  isExcludeNullStatusTasks?: boolean;
  isTreads?: boolean;
};

export const ScaleAccordion = ({
  project,
  createScaleMapFileMutation,
  isExcludeNullStatusTasks,
  isTreads,
}: ScaleAccordionType) => {
  const [isOpen, setIsOpen] = useState(true);
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  const accordionHeader = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className='scaleAccordion' style={{ marginBottom: !isOpen ? '1px' : '' }}>
        <div className='scaleAccordion__header' onClick={accordionHeader}>
          <div className={`scaleAccordion__icon ${isOpen ? 'open' : ''}`}>
            <img src={arrowUp} width='12px' height='8px' />
          </div>
          <h3 className='scaleAccordion__title'>
            <div className='scaleAccordion__title-col1'>
              #{project?.number} {project?.name}
            </div>
            <div className='scaleAccordion__title-col2'></div>
            <div className='scaleAccordion__title-col3'>
              {!isTreads && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSetModalStatus(ModalStatuses.ADD_FILE_MODAL);
                    handleChangeModalParameters([
                      {
                        key: 'onCallback',
                        value: (value: { name: string; projectId: string }) => {
                          createScaleMapFileMutation({
                            variables: {
                              input: value,
                            },
                          });
                        },
                      },
                      {
                        key: 'initialValues',
                        value: {
                          projectId: project?.id,
                        },
                      },
                      {
                        key: 'projectName',
                        value: project?.name,
                      },
                    ]);
                  }}
                  backgroundColor='white'
                  width='150px'
                  height='25px'
                  text='+  Добавить файл'
                  color='#149939'
                />
              )}
            </div>
          </h3>
        </div>
        {isOpen && (
          <div className='scaleAccordion__tasksList'>
            {project?.scaleMapFiles?.map((file) => {
              return (
                <ScaleFile
                  file={file}
                  key={file?.id}
                  isExcludeNullStatusTasks={isExcludeNullStatusTasks}
                  isTreads={isTreads}
                />
              );
            })}
            {project?.scaleMapFiles?.length === 0 && (
              <div>Еще не создано ни одного файла</div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

type ScaleFileType = {
  file: ScaleMapFile;
  isExcludeNullStatusTasks?: boolean;
  isTreads?: boolean;
};

const ScaleFile = ({ file, isExcludeNullStatusTasks, isTreads }: ScaleFileType) => {
  const navigate = useNavigate();
  const selfLocation = location?.pathname?.includes('projectI');
  return (
    <>
      <div
        className='scaleAccordion__taskItem'
        onClick={() => {
          if (isTreads) {
            return navigate(PATHS.treadsFile);
          }
          if (selfLocation) {
            navigate(`/file/self/${file?.id}`);
          } else {
            navigate(`/file/${file?.id}`);
          }
        }}
        style={{
          borderBottom: file?.scaleMapTasks?.length > 0 ? 'none' : undefined,
          backgroundColor: file?.isPersonal ? '#d1d1d1' : undefined,
        }}
      >
        <div className='scaleAccordion__taskItemCell projects'>
          <FinishIcons className='finish_flag' />
          <span className='scaleAccordion__span'>
            {file?.statistics?.completedTasksPercent}%
          </span>

          <LinePercent statistics={file?.statistics} />
        </div>
        <div className={`scaleAccordion__taskItemCell task`}>
          <FlagIcons className='finish_flag' /> {file?.statistics?.tasksCount}
        </div>
        <div className={`scaleAccordion__taskItemCell name`}>
          <div
            className='scaleAccordion__arrow-wrapper'
            style={{
              width: '70%',
            }}
          >
            {file?.name}
            <div className='scaleAccordion__arrow-wrapper__inner'>
              {file?.statistics?.isTasksUpdatedToday && (
                <div
                  className={`scaleAccordion__arrow-up scaleAccordion__arrow-down`}
                ></div>
              )}
              {file?.statistics?.isTasksCreatedToday && (
                <div className={`scaleAccordion__arrow-up `}></div>
              )}
            </div>
          </div>
        </div>
      </div>

      {file?.scaleMapTasks?.length > 0 && isExcludeNullStatusTasks && (
        <ScalePopup2 scaleMapTasks={file?.scaleMapTasks} fileId={file?.id} />
      )}
    </>
  );
};

type LinePercentType = {
  statistics: any;
};

const LinePercent = ({ statistics }: LinePercentType) => {
  return (
    <div className='line__wrapper'>
      <div
        className='line__item line__success'
        style={{ width: `${statistics?.completedTasksPercent}%` }}
      ></div>
      <div
        className='line__item line__process'
        style={{ width: `${statistics?.inProgressTasksPercent}%` }}
      ></div>
      <div
        className='line__item line__problem'
        style={{ width: `${statistics?.problemTasksPercent}%` }}
      >
        {statistics?.problemTasksPercent >= 10 && (
          <>{statistics?.problemTasksPercent} %</>
        )}
      </div>
      <div
        className='line__item line__empty'
        style={{ width: `${statistics?.notInProgressTasksPercent}%` }}
      ></div>
    </div>
  );
};
