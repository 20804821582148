import './UpdateChapterModal.scss';

import { Form, Formik } from 'formik';
import React from 'react';

import {
  ForumSectionRoles,
  useRemoveForumSectionMutation,
  useUpdateForumSectionMutation,
} from '../../../../graphql/generated/graphql';
import { addFileTaskSchema } from '../../../../validation/schemas';
import { TreadDeleteIcons, TreadSaveIcons } from '../../../icons/index';
import { AppInput, AppSelect, Button } from '../../../index';

type UpdateFileScaleModalType = {
  isOpen: boolean;
  onClose: () => void;
  modalParameters?: any;
};

export const UpdateChapterModal = ({
  onClose,
  modalParameters,
}: UpdateFileScaleModalType) => {
  const [updateForumSectionMutation] = useUpdateForumSectionMutation({
    onCompleted: () => {
      modalParameters.refetch();
    },
  });

  const [removeForumSectionMutation] = useRemoveForumSectionMutation({
    variables: {
      id: modalParameters?.initialValues?.id,
    },
    onCompleted: () => {
      modalParameters.refetch();
    },
  });

  return (
    <div className='CreateChapterModal'>
      <Formik
        initialValues={modalParameters.initialValues}
        onSubmit={(e) => {
          updateForumSectionMutation({
            variables: {
              data: {
                name: e.name,
                id: modalParameters?.initialValues?.id,
                role: e?.role === 'all' ? null : e?.role,
              },
            },
          });
          onClose();
        }}
        validationSchema={addFileTaskSchema}
      >
        {() => {
          return (
            <Form style={{ width: 'auto' }}>
              <div className='CreateChapterModal__title'>Редактировать раздел</div>
              <div className='CreateChapterModal__inputWrapper'>
                <AppInput name={'name'} label={'Название:'} />
              </div>
              <div className='CreateChapterModal__selectorWrapper'>
                <div>Доступ:</div>
                <AppSelect
                  name='role'
                  options={[
                    {
                      value: 'all',
                      label: 'Все пользователи',
                    },
                    {
                      value: ForumSectionRoles.Admin,
                      label: 'Админ',
                    },
                    {
                      value: ForumSectionRoles.AdminModerator,
                      label: 'Админ и модератор',
                    },
                  ]}
                  placeholder=''
                  isSearchable={true}
                />
              </div>
              <div className='mb20'>
                <Button
                  text={`Сохранить`}
                  type='submit'
                  width='100%'
                  height={25}
                  onClick={() => {}}
                  backgroundColor={'green'}
                  color={'white'}
                >
                  <TreadSaveIcons className='CreateChapterModal__icons' />
                </Button>
              </div>
              <Button
                text={`Удалить`}
                type='submit'
                width='100%'
                height={25}
                onClick={() => {
                  removeForumSectionMutation();
                  onClose();
                }}
              >
                <TreadDeleteIcons className='CreateChapterModal__icons' />
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
