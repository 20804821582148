import React from 'react';

type SaveIconWhiteType = {
  className?: string;
};

export const SaveIconWhite = ({ className }: SaveIconWhiteType) => {
  return (
    <svg
      width='11'
      height='12'
      viewBox='0 0 11 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M2.875 0.75V2.9375C2.875 3.2856 3.01328 3.61944 3.25942 3.86558C3.50556 4.11172 3.8394 4.25 4.1875 4.25H5.9375C6.2856 4.25 6.61944 4.11172 6.86558 3.86558C7.11172 3.61944 7.25 3.2856 7.25 2.9375V0.75H7.58162C8.04572 0.750099 8.49076 0.934539 8.81888 1.26275L10.2372 2.68112C10.5655 3.00924 10.7499 3.45428 10.75 3.91837V9.5C10.75 9.96413 10.5656 10.4092 10.2374 10.7374C9.90925 11.0656 9.46413 11.25 9 11.25V7.3125C9 6.9644 8.86172 6.63056 8.61558 6.38442C8.36944 6.13828 8.0356 6 7.6875 6H3.3125C2.58887 6 2 6.58538 2 7.31075V11.25C1.53587 11.25 1.09075 11.0656 0.762563 10.7374C0.434374 10.4092 0.25 9.96413 0.25 9.5V2.5C0.25 2.03587 0.434374 1.59075 0.762563 1.26256C1.09075 0.934374 1.53587 0.75 2 0.75H2.875ZM3.75 0.75V2.9375C3.75 3.05353 3.79609 3.16481 3.87814 3.24686C3.96019 3.32891 4.07147 3.375 4.1875 3.375H5.9375C6.05353 3.375 6.16481 3.32891 6.24686 3.24686C6.32891 3.16481 6.375 3.05353 6.375 2.9375V0.75H3.75ZM2.875 11.25H8.125V7.3125C8.125 7.19647 8.07891 7.08519 7.99686 7.00314C7.91481 6.92109 7.80353 6.875 7.6875 6.875H3.3125C3.07012 6.875 2.875 7.07013 2.875 7.31075V11.25Z'
        fill='white'
      />
    </svg>
  );
};
