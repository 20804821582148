import './RenameTredModal.scss';

import { Form, Formik } from 'formik';
import React from 'react';

import { renameTred } from '../../../../validation/schemas';
import { AppInput, Button } from '../../../index';

export const RenameTredFileModal = ({ onClose, modalParameters }: any) => {
  return (
    <div>
      <Formik
        initialValues={{
          name: modalParameters?.initialValues?.name,
          comment: modalParameters?.initialValues?.comment,
        }}
        onSubmit={(e) => {
          modalParameters.onCallback(e);
          onClose();
        }}
        validationSchema={renameTred}
      >
        {({ errors }) => {
          return (
            <Form className='RenameTredFileModal'>
              <div className='RenameTredFileModal__title'>Название файла</div>
              <div className='RenameTredFileModal__input'>
                <div className='RenameTredFileModal__input-inner'>
                  <svg
                    width='17'
                    height='16'
                    viewBox='0 0 17 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className='mr10 RenameTredModal__input-svg'
                  >
                    <path
                      d='M11.4163 14.6656H3.36082C2.51803 14.6656 1.83301 13.9806 1.83301 13.1378V5.08226C1.83301 4.23946 2.51803 3.55444 3.36082 3.55444H8.08301C8.31301 3.55444 8.49967 3.74111 8.49967 3.97111C8.49967 4.20111 8.31301 4.38778 8.08301 4.38778H3.36082C2.97803 4.38778 2.66634 4.69946 2.66634 5.08226V13.1378C2.66634 13.5206 2.97803 13.8323 3.36082 13.8323H11.4163C11.7991 13.8323 12.1108 13.5206 12.1108 13.1378V8.41559C12.1108 8.18559 12.2975 7.99892 12.5275 7.99892C12.7575 7.99892 12.9442 8.18508 12.9442 8.41559V13.1378C12.9442 13.9806 12.2591 14.6656 11.4163 14.6656Z'
                      fill='#AFAFAF'
                      stroke='#AFAFAF'
                      strokeWidth='0.3'
                    />
                    <path
                      d='M6.70649 10.2088C6.59703 10.2088 6.49032 10.1655 6.41199 10.0866C6.31312 9.98826 6.2709 9.84656 6.29816 9.71045L6.69092 7.74604C6.707 7.66496 6.74697 7.59101 6.80475 7.53323L12.5587 1.77995C13.1542 1.18435 14.1232 1.18435 14.7193 1.77995C15.0076 2.06824 15.1665 2.45164 15.1665 2.85997C15.1665 3.26829 15.0076 3.6516 14.7187 3.93999L8.9654 9.69387C8.90762 9.75216 8.83315 9.79163 8.75259 9.8077L6.78868 10.2005C6.76142 10.2061 6.73365 10.2088 6.70649 10.2088ZM8.6709 9.39938H8.6765H8.6709ZM7.48316 8.03331L7.2381 9.26103L8.46531 9.01547L14.1298 3.3511C14.2609 3.21937 14.3331 3.04552 14.3331 2.85997C14.3331 2.67442 14.2609 2.50047 14.1298 2.36884C13.8598 2.09825 13.4192 2.09825 13.1476 2.36884L7.48316 8.03331Z'
                      fill='#AFAFAF'
                      stroke='#AFAFAF'
                      strokeWidth='0.2'
                    />
                    <path
                      d='M13.639 4.84827C13.5324 4.84827 13.4257 4.80768 13.3446 4.72599L11.7734 3.15434C11.6107 2.99158 11.6107 2.7277 11.7734 2.56494C11.9362 2.40218 12.2001 2.40218 12.3629 2.56494L13.934 4.1366C14.0967 4.29936 14.0967 4.56323 13.934 4.72599C13.8523 4.80717 13.7457 4.84827 13.639 4.84827Z'
                      fill='#AFAFAF'
                      stroke='#AFAFAF'
                      strokeWidth='0.2'
                    />
                  </svg>
                  Название файла:
                </div>

                <AppInput
                  name={'name'}
                  placeholder='Введите название файла'
                  error={errors?.name as string}
                />
              </div>
              {modalParameters?.initialValues?.type === 'image' && (
                <div
                  className='RenameTredFileModal__input'
                  style={{
                    marginTop: '10px',
                  }}
                >
                  <div className='RenameTredFileModal__input-inner'>
                    <svg
                      width='17'
                      height='16'
                      viewBox='0 0 17 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      className='mr10 RenameTredFileModal__input-svg'
                    >
                      <path
                        d='M11.4163 14.6656H3.36082C2.51803 14.6656 1.83301 13.9806 1.83301 13.1378V5.08226C1.83301 4.23946 2.51803 3.55444 3.36082 3.55444H8.08301C8.31301 3.55444 8.49967 3.74111 8.49967 3.97111C8.49967 4.20111 8.31301 4.38778 8.08301 4.38778H3.36082C2.97803 4.38778 2.66634 4.69946 2.66634 5.08226V13.1378C2.66634 13.5206 2.97803 13.8323 3.36082 13.8323H11.4163C11.7991 13.8323 12.1108 13.5206 12.1108 13.1378V8.41559C12.1108 8.18559 12.2975 7.99892 12.5275 7.99892C12.7575 7.99892 12.9442 8.18508 12.9442 8.41559V13.1378C12.9442 13.9806 12.2591 14.6656 11.4163 14.6656Z'
                        fill='#AFAFAF'
                        stroke='#AFAFAF'
                        strokeWidth='0.3'
                      />
                      <path
                        d='M6.70649 10.2088C6.59703 10.2088 6.49032 10.1655 6.41199 10.0866C6.31312 9.98826 6.2709 9.84656 6.29816 9.71045L6.69092 7.74604C6.707 7.66496 6.74697 7.59101 6.80475 7.53323L12.5587 1.77995C13.1542 1.18435 14.1232 1.18435 14.7193 1.77995C15.0076 2.06824 15.1665 2.45164 15.1665 2.85997C15.1665 3.26829 15.0076 3.6516 14.7187 3.93999L8.9654 9.69387C8.90762 9.75216 8.83315 9.79163 8.75259 9.8077L6.78868 10.2005C6.76142 10.2061 6.73365 10.2088 6.70649 10.2088ZM8.6709 9.39938H8.6765H8.6709ZM7.48316 8.03331L7.2381 9.26103L8.46531 9.01547L14.1298 3.3511C14.2609 3.21937 14.3331 3.04552 14.3331 2.85997C14.3331 2.67442 14.2609 2.50047 14.1298 2.36884C13.8598 2.09825 13.4192 2.09825 13.1476 2.36884L7.48316 8.03331Z'
                        fill='#AFAFAF'
                        stroke='#AFAFAF'
                        strokeWidth='0.2'
                      />
                      <path
                        d='M13.639 4.84827C13.5324 4.84827 13.4257 4.80768 13.3446 4.72599L11.7734 3.15434C11.6107 2.99158 11.6107 2.7277 11.7734 2.56494C11.9362 2.40218 12.2001 2.40218 12.3629 2.56494L13.934 4.1366C14.0967 4.29936 14.0967 4.56323 13.934 4.72599C13.8523 4.80717 13.7457 4.84827 13.639 4.84827Z'
                        fill='#AFAFAF'
                        stroke='#AFAFAF'
                        strokeWidth='0.2'
                      />
                    </svg>
                    Комментарий к файлу:
                  </div>

                  <AppInput
                    name={'comment'}
                    placeholder='Введите комментарий к файлу'
                    error={errors?.comment as string}
                  />
                </div>
              )}

              <Button
                text={'Сохранить'}
                type='submit'
                width='100%'
                height='30px'
                margin='40px 0 0 0'
                backgroundColor='green'
                color='white'
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
