import * as yup from 'yup';

import * as MESSAGES from '../messages';

export const addPolygonSchema = yup.object().shape({
  polygonBlockName: yup
    .string()
    .min(3, MESSAGES.INCORRECT_MIN_LENGTH_3)
    .max(30, MESSAGES.INCORRECT_MAX_LENGTH_30)
    .required(MESSAGES.REQUIRE_MESSAGE),
});

export const addPolygonObjectSchema = yup.object().shape({
  polygonBlockName: yup
    .string()
    .min(3, MESSAGES.INCORRECT_MIN_LENGTH_3)
    .max(30, MESSAGES.INCORRECT_MAX_LENGTH_30),
});

export const addPolygonStatisticSchema = yup.object().shape({
  polygonName: yup
    .string()
    .min(3, MESSAGES.INCORRECT_MIN_LENGTH_3)
    .max(30, MESSAGES.INCORRECT_MAX_LENGTH_30)
    .required(MESSAGES.REQUIRE_MESSAGE),
});
