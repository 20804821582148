import './ChronometerNote.scss';

import { Form, Formik } from 'formik';
import React from 'react';

import { Button } from '../../../index';

export const ChronometerNote = ({ onClose, modalParameters }: any) => {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        text1: modalParameters?.initialValues?.cell1,
        text2: modalParameters?.initialValues?.cell2,
        text3: modalParameters?.initialValues?.cell3,
      }}
      onSubmit={(e) => {
        modalParameters?.onCallback(e);
        onClose();
      }}
    >
      {({ setFieldValue, values }) => {
        return (
          <Form className='ChronometerNote'>
            <div className='ChronometerNote__wrapTextarea'>
              <textarea
                className='ChronometerNote__textarea'
                placeholder='Сообщение'
                onChange={(e) => {
                  setFieldValue('text1', e.target.value);
                }}
                value={values.text1}
              ></textarea>
              <textarea
                className='ChronometerNote__textarea'
                placeholder='Сообщение'
                onChange={(e) => {
                  setFieldValue('text2', e.target.value);
                }}
                value={values.text2}
              ></textarea>{' '}
              <textarea
                className='ChronometerNote__textarea'
                placeholder='Сообщение'
                onChange={(e) => {
                  setFieldValue('text3', e.target.value);
                }}
                value={values.text3}
              ></textarea>
            </div>
            <Button type='submit'>Сохранить</Button>
          </Form>
        );
      }}
    </Formik>
  );
};
