import './ExpensesTagCell.scss';

import React, { FC } from 'react';

import { Tag, TagList } from '../../../graphql/generated/graphql';
import { TagItem } from '../../tag/TagItem';

export const ExpensesTagCell: FC<{ tags?: TagList }> = ({ tags }) => {
  return (
    <div className='expensesTagCell'>
      {tags?.rows?.map((tag: Tag) => {
        return (
          <div key={tag.id}>
            <TagItem
              id={tag.id}
              title={tag.name}
              color={tag.color}
              textColor={tag.textColor as string}
            />
          </div>
        );
      })}
    </div>
  );
};
