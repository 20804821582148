import moment from 'moment';

export const createArrayStartingFromMonday = (from = '20.02.2023') => {
  const daysOfWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
  const startDate = moment(from, 'DD.MM.YYYY');
  const result = [];

  for (let i = 0; i < 7; i++) {
    const date = startDate.clone().add(i, 'days');
    const name = daysOfWeek[i];
    const newDate = date.format('DD.MM');
    const newDate2 = date.format('YYYY-MM-DD');

    result.push({ name, date: newDate, date2: newDate2 });
  }

  return result;
};
