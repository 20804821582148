import './CalendarRow.scss';

import { BlobOptions } from 'buffer';
import React from 'react';
import { Link } from 'react-router-dom';

import { ROLES } from '../../../constants/constants';
import { useAuthContext } from '../../../context';
import { TagList } from '../../../graphql/generated/graphql';
import { afterBefore, getCalendarDaysArray, getWorkDay2 } from '../../../utils';
import {
  CalendarTimeIcons,
  FlagCalendarIcon,
  PersonalIcons,
  WarningCalendarIcon,
} from '../../icons';

export const CalendarRowThred = ({
  count,
  item,
  checkedDate,
  updateTask,
  showRedLine,
}: CalendarRowPropTypes) => {
  const days = getCalendarDaysArray({
    endDate: item?.endDate,
    count: count,
    checkedDate,
  });
  const { currentUserData } = useAuthContext();
  const isBlue = item?.status?.id === 5;

  const participant = item?.maintainer?.id === currentUserData?.id;

  return (
    <div key={item?.parentId} className='calendarTable__row'>
      <div
        className={`calendarTable__rowTitle ${
          item?.parentId ? 'calendarTable__rowTitle_isSub' : ''
        }`}
        style={{ borderBottom: showRedLine ? 'none' : undefined }}
      >
        <div className={`calendarTable__rowTitle--tags tagsList`}></div>
        <div className='linkWrap'>
          <Link to={`/tread/${item?.id}`}>
            {/* {participant && <PersonalIcons />} */}
            <div>
              <span style={{ color: item?.textFileColor }}>{item?.name}</span>

              {item?.name3 ? (
                <span
                  style={{
                    textDecoration: 'underline',
                    color: item?.isUrgently ? 'red' : undefined,
                  }}
                >
                  {item?.name3}
                </span>
              ) : (
                <span
                  style={{
                    fontWeight: '600',
                  }}
                >
                  {item?.name2}
                </span>
              )}
            </div>
          </Link>
        </div>
      </div>
      <div className='calendarTable__days'>
        {days.map((day, index) => {
          const afterBeforeColor = item?.tags?.rows[0]?.color
            ? item?.tags?.rows[0]?.color
            : item?.status?.color;

          const { background, color, isEndDay, isStartDay, today, rowDateYMD } =
            afterBefore({
              day,
              currentDay: index + 1,
              checkedDate,
              color: afterBeforeColor,
              item,
            });
          const workDat2 = getWorkDay2({
            checkedDate,
            day,
            item,
            currentDay: index + 1,
            isEndDay,
            isStartDay,
          });
          const borderRight = borderRightCalc({ today });

          return (
            <div
              key={day.id}
              className='calendarTable__row__day'
              style={
                workDat2
                  ? {
                      background: background,
                      borderRadius: `${isStartDay ? '6px' : '0px'} 
                      ${isEndDay ? '6px' : '0px'} 
                      ${isEndDay ? '6px' : '0px'} 
                      ${isStartDay ? '6px' : '0px'}`,
                      borderRight: `${borderRight}`,
                      borderBottom: showRedLine ? 'none' : undefined,
                    }
                  : {
                      borderRight: `${borderRight}`,
                      borderBottom: showRedLine ? 'none' : undefined,
                    }
              }
              onClick={() => {
                if (currentUserData?.role === ROLES.ADMIN) {
                  updateTask({
                    variables: {
                      data: { id: item?.id, endDate: rowDateYMD },
                    },
                  });
                }
              }}
            >
              {day?.deadlineDay && (
                <div
                  style={{
                    backgroundColor: color,
                  }}
                  className='calendarTable__closeItem'
                >
                  <>
                    {isBlue && item?.closeDate && <CalendarTimeIcons />}
                    {item?.status?.id === 1 ? (
                      <FlagCalendarIcon />
                    ) : (
                      !isBlue && <WarningCalendarIcon />
                    )}
                  </>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

type ItemType = {
  id: number;
  status?: {
    id: number;
    color: string;
  } | null;
  endDate?: string | null;
  startDate?: any | null;
  closeDate?: any | null;
  parentId?: number | null;
  name: string;
  name2: string;
  name3?: string;
  code?: string | null;
  tags: TagList;
  task?: {
    id: number;
    name: string;
  } | null;
  assignees: any;
  maintainer: any;
  isUrgently?: boolean;
  textFileColor?: string;
};

type CalendarRowPropTypes = {
  count: number;
  item: ItemType | null;
  checkedDate: Date;
  updateTask: (value: any) => void;
  showRedLine?: boolean;
};

const borderRightCalc = ({ today }: any) => {
  if (today) return '2px solid #303030';
  return '1px solid #afafaf';
};
