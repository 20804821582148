import React from 'react';

export const FlagChronoICons = ({ className }: any) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M3.66667 13.6673C3.48986 13.6673 3.32029 13.5971 3.19526 13.4721C3.07024 13.347 3 13.1775 3 13.0007V3.00067C3 2.82386 3.07024 2.65429 3.19526 2.52926C3.32029 2.40424 3.48986 2.334 3.66667 2.334H7.92C8.04424 2.33314 8.16626 2.36701 8.27227 2.4318C8.37829 2.49658 8.46409 2.58971 8.52 2.70067L9 3.66733H12.3333C12.5101 3.66733 12.6797 3.73757 12.8047 3.8626C12.9298 3.98762 13 4.15719 13 4.334V9.66733C13 9.84414 12.9298 10.0137 12.8047 10.1387C12.6797 10.2638 12.5101 10.334 12.3333 10.334H8.74C8.61689 10.3336 8.49628 10.2992 8.39156 10.2345C8.28683 10.1697 8.20207 10.0773 8.14667 9.96733L7.66667 9.00067H4.33333V13.0007C4.33333 13.1775 4.2631 13.347 4.13807 13.4721C4.01305 13.5971 3.84348 13.6673 3.66667 13.6673ZM4.33333 3.66733V7.66733H8.33333L9 9.00067H11.6667V5.00067H8.33333L7.66667 3.66733H4.33333Z'
        fill='#AFAFAF'
      />
    </svg>
  );
};
