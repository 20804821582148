import React from 'react';

type EditClickedType = {
  className?: string;
};

export const EditClicked = ({ className }: EditClickedType) => {
  return (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.9163 15.1655H2.86082C2.01803 15.1655 1.33301 14.4805 1.33301 13.6377V5.58218C1.33301 4.73938 2.01803 4.05437 2.86082 4.05437H7.58301C7.81301 4.05437 7.99967 4.24103 7.99967 4.47103C7.99967 4.70103 7.81301 4.8877 7.58301 4.8877H2.86082C2.47803 4.8877 2.16634 5.19939 2.16634 5.58218V13.6377C2.16634 14.0205 2.47803 14.3322 2.86082 14.3322H10.9163C11.2991 14.3322 11.6108 14.0205 11.6108 13.6377V8.91551C11.6108 8.68551 11.7975 8.49885 12.0275 8.49885C12.2575 8.49885 12.4442 8.685 12.4442 8.91551V13.6377C12.4442 14.4805 11.7591 15.1655 10.9163 15.1655Z'
        fill='#303030'
        stroke='#303030'
        strokeWidth='0.3'
      />
      <path
        d='M6.20649 10.7089C6.09703 10.7089 5.99032 10.6655 5.91199 10.5867C5.81312 10.4883 5.7709 10.3466 5.79816 10.2105L6.19092 8.24608C6.207 8.16501 6.24697 8.09105 6.30475 8.03327L12.0587 2.28C12.6542 1.6844 13.6232 1.6844 14.2193 2.28C14.5076 2.56829 14.6665 2.95169 14.6665 3.36002C14.6665 3.76834 14.5076 4.15164 14.2187 4.44003L8.4654 10.1939C8.40762 10.2522 8.33315 10.2917 8.25259 10.3077L6.28868 10.7005C6.26142 10.7061 6.23365 10.7089 6.20649 10.7089ZM8.1709 9.89942H8.1765H8.1709ZM6.98316 8.53335L6.7381 9.76108L7.96531 9.51551L13.6298 3.85115C13.7609 3.71941 13.8331 3.54556 13.8331 3.36002C13.8331 3.17447 13.7609 3.00052 13.6298 2.86889C13.3598 2.5983 12.9192 2.5983 12.6476 2.86889L6.98316 8.53335Z'
        fill='#303030'
        stroke='#303030'
        strokeWidth='0.2'
      />
      <path
        d='M13.139 5.34839C13.0324 5.34839 12.9257 5.3078 12.8446 5.22612L11.2734 3.65446C11.1107 3.4917 11.1107 3.22782 11.2734 3.06506C11.4362 2.9023 11.7001 2.9023 11.8629 3.06506L13.434 4.63672C13.5967 4.79948 13.5967 5.06336 13.434 5.22612C13.3523 5.30729 13.2457 5.34839 13.139 5.34839Z'
        fill='#303030'
        stroke='#303030'
        strokeWidth='0.2'
      />
    </svg>
  );
};
