type calcLeftMenuType = {
  shift: number;
  left: number;
};
export const calcLeftMenu = ({ shift, left }: calcLeftMenuType) => {
  const leftMenu = !document.querySelector('.menu-left_close');
  const leftMenuWidth = 240;
  const calcShift = leftMenu ? shift + leftMenuWidth : shift;

  if (window.innerWidth < left + calcShift) {
    return window.innerWidth - calcShift;
  }
  if (left < 0) {
    return 0;
  }
  return left;
};
