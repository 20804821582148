import './AddFileModal.scss';

import { Form, Formik } from 'formik';
import React from 'react';
import Select from 'react-select';

import { selectorStyle } from '../../../../constants/constants';
import { useOptionScrollLoad } from '../../../../hooks';
import { addFileSchema } from '../../../../validation/schemas';
import { AppInput, Button, Checkbox } from '../../../index';

type AddFileModalType = {
  isOpen: boolean;
  onClose: () => void;
  modalParameters?: any;
};

export const AddFileModal = ({ onClose, modalParameters }: AddFileModalType) => {
  const { setPageNumberState, options } = useOptionScrollLoad();

  return (
    <div className='addFileModal'>
      <div className='addFileModal__title'>Добавить файл к проекту</div>
      <Formik
        initialValues={modalParameters?.initialValues || { projectId: '', fileName: '' }}
        validationSchema={addFileSchema}
        onSubmit={(e) => {
          modalParameters.onCallback({
            name: e.fileName,
            projectId: `${e.projectId}`,
            isPersonal: e?.isPersonal,
          });
          onClose();
        }}
      >
        {({ setFieldValue, errors, values }) => {
          return (
            <Form style={{ width: 'auto' }}>
              <div
                style={{
                  border: errors?.projectId ? '1px solid red' : '',
                }}
              >
                <Select
                  styles={selectorStyle}
                  options={options}
                  defaultValue={
                    modalParameters?.initialValues && {
                      value: modalParameters?.initialValues?.projectId,
                      label: modalParameters?.projectName,
                    }
                  }
                  placeholder='Выберете проект'
                  onChange={(option) => setFieldValue('projectId', option?.value)}
                  isClearable
                  onMenuScrollToBottom={() => {
                    setPageNumberState((prev) => prev + 1);
                  }}
                />
              </div>
              <div style={{ marginTop: '10px' }}>
                <AppInput
                  name={'fileName'}
                  placeholder='Введите имя файла'
                  error={errors?.fileName as string}
                />
              </div>
              <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                <Checkbox
                  isChecked={values?.isPersonal}
                  setIsChecked={(e) => {
                    setFieldValue('isPersonal', e);
                  }}
                  className=''
                  label='Сделать файл персональным'
                />
              </div>
              <Button
                text={'Добавить файл'}
                type='submit'
                width='100%'
                height='25px'
                margin='5px 0 0 0'
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
