import React from 'react';
import { Arc, Group, Layer, Ring, Stage } from 'react-konva';

import { toolsChronoType } from '../../../types/types';

type ChronometerCanvasType = {
  toolsChrono: toolsChronoType;
  selectDay: any;
};

export const ChronometerCanvas = ({ selectDay }: ChronometerCanvasType) => {
  const outerRadius = 80;
  const innerRadius = 75;
  const angle = 15;
  const rotation = -90;

  return (
    <Stage width={400} height={400}>
      <Layer>
        <Group hash='test' scaleX={2} scaleY={2} name='clock'>
          <Ring
            x={100}
            y={100}
            innerRadius={innerRadius - 15}
            outerRadius={outerRadius - 3}
            fill='#F5F5F5'
          />
          {selectDay?.hours?.map((e: any, index: number) => {
            const newRot = rotation + (360 / 24) * index;
            const colorCalc = () => {
              if (e?.energy === '1') {
                return '#DEBB3F';
              }
              if (e?.energy === '-1') {
                return '#303030';
              }
              return '';
            };

            return (
              <ArcWrapper
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                angle={angle}
                rotation={newRot}
                key={index}
                index={index}
                color={colorCalc()}
              />
            );
          })}
        </Group>
      </Layer>
    </Stage>
  );
};

const ArcWrapper = ({ innerRadius, outerRadius, angle, rotation, color }: any) => {
  const arcCenterX = 100;
  const arcCenterY = 100;

  return (
    <Arc
      x={arcCenterX}
      y={arcCenterY}
      innerRadius={innerRadius}
      outerRadius={outerRadius - 3}
      angle={angle}
      rotation={rotation}
      fill={color}
    />
  );
};
