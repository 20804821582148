import './LeftAlignTextIcon.scss';

import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const LeftAlignTextIcon: FC<LeftAlignTextIconType> = ({
  width = '20px',
  height = '20px',
  className = 'left-align-text-icon',
  color = '#FFFFFF',
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path d='M1.5 11H14H1.5Z' fill={color} />
      <path
        d='M1.5 1H16.5M1.5 6H9.83333M1.5 11H14'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='square'
      />
    </svg>
  );
};

type LeftAlignTextIconType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
>;
