import React, { FC } from 'react';

export const RefreshIcon: FC<RefreshIconType> = ({
  height = '14px',
  width = '14px',
  color = '' + '#AFAFAF',
  onClick,
  className = 'refresh-icon',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      className={className}
    >
      <path
        d='M7 0.250001C4.12562 0.250001 1.72206 2.0275 0.742188 4.53906L1.77944 4.96094C2.599 2.86 4.58687 1.375 7 1.375C8.8225 1.375 10.4504 2.26938 11.4651 3.625H9.25V4.75H13.1875V0.812501H12.0625V2.55288C11.4324 1.82939 10.6544 1.24945 9.7811 0.852187C8.90778 0.454925 7.95942 0.249579 7 0.250001ZM12.22 9.03906C11.401 11.14 9.41313 12.625 7 12.625C5.15725 12.625 3.53669 11.7183 2.51687 10.375H4.75V9.25H0.8125V13.1875H1.9375V11.4471C3.16937 12.8421 4.97219 13.75 7 13.75C9.87438 13.75 12.2779 11.9725 13.2578 9.46094L12.2206 9.03906H12.22Z'
        fill={color}
      />
    </svg>
  );
};

type RefreshIconType = {
  color?: string;
  width?: string;
  height?: string;
  className?: string;
  onClick?: () => void;
};
