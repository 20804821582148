import './ScaleAccordion.scss';

import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { arrowUp } from '../../../assets/icons';
import { useModalContext } from '../../../context/ModalContext';
import { Project as ProjectType, ScaleMapFile } from '../../../graphql/generated/graphql';
import { useClickOutside } from '../../../hooks';
import { ModalStatuses } from '../../../types/enums';
import { ArrowLeftIcon, FinishIcons, FlagIcons } from '../../icons';
import { Button, Checkbox, ScalePopup2, TemplateProjectI } from '../../index';

type ScaleAccordionType = {
  project: ProjectType;
  createScaleMapFileMutation: (value: any) => void;
  isExcludeNullStatusTasks: number | null | undefined;
  updateScaleMapFileMutation: (value: any) => void;
  upFile: ({
    currentOrder,
    currentID,
  }: {
    currentOrder: number;
    currentID: number;
  }) => void;
  downFile: ({
    currentOrder,
    currentID,
  }: {
    currentOrder: number;
    currentID: number;
  }) => void;
};

export const ScaleAccordion = ({
  project,
  createScaleMapFileMutation,
  updateScaleMapFileMutation,
  isExcludeNullStatusTasks,
  upFile,
  downFile,
}: ScaleAccordionType) => {
  const [isOpen, setIsOpen] = useState(true);
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  const accordionHeader = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className='scaleAccordion' style={{ marginBottom: !isOpen ? '1px' : '' }}>
        <div className='scaleAccordion__header' onClick={accordionHeader}>
          <div className={`scaleAccordion__icon ${isOpen ? 'open' : ''}`}>
            <img src={arrowUp} width='12px' height='8px' />
          </div>
          <h3 className='scaleAccordion__title'>
            <div className='scaleAccordion__title-col1'>
              #{project?.number} {project?.name}
            </div>
            <div className='scaleAccordion__title-col2'></div>
            <div className='scaleAccordion__title-col3'>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  handleSetModalStatus(ModalStatuses.ADD_FILE_USER_MODAL);
                  handleChangeModalParameters([
                    {
                      key: 'onCallback',
                      value: (value: { name: string; projectId: string }) => {
                        createScaleMapFileMutation({
                          variables: {
                            input: value,
                          },
                        });
                      },
                    },
                    {
                      key: 'initialValues',
                      value: {
                        projectId: project?.id,
                      },
                    },
                    {
                      key: 'projectName',
                      value: project?.name,
                    },
                  ]);
                }}
                backgroundColor='white'
                width='150px'
                height='25px'
                text='+  Добавить файл'
                color='#149939'
              />
            </div>
          </h3>
        </div>
        {isOpen && (
          <div className='scaleAccordion__tasksList'>
            {project?.scaleMapFiles?.map((file, index) => {
              const length = project?.scaleMapFiles?.length;
              const arrowControl = {
                showUp: index !== 0,
                showDown: index !== length - 1,
                showAllArrow: length > 1,
              };
              return (
                <ScaleFile
                  file={file}
                  key={file?.id}
                  upFile={upFile}
                  downFile={downFile}
                  updateScaleMapFileMutation={updateScaleMapFileMutation}
                  isExcludeNullStatusTasks={isExcludeNullStatusTasks}
                  arrowControl={arrowControl}
                  borderBottom={project?.scaleMapFiles?.length !== index + 1}
                />
              );
            })}
            {project?.scaleMapFiles?.length === 0 && (
              <div>Еще не создано ни одного файла</div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

type ScaleFileType = {
  file: ScaleMapFile;
  updateScaleMapFileMutation: (value: any) => void;
  upFile: ({
    currentOrder,
    currentID,
  }: {
    currentOrder: number;
    currentID: number;
  }) => void;
  downFile: ({
    currentOrder,
    currentID,
  }: {
    currentOrder: number;
    currentID: number;
  }) => void;
  isExcludeNullStatusTasks?: number | null | undefined;
  arrowControl: {
    showUp: boolean;
    showDown: boolean;
    showAllArrow: boolean;
  };
  borderBottom: boolean;
};

const ScaleFile = ({
  file,
  updateScaleMapFileMutation,
  upFile,
  downFile,
  isExcludeNullStatusTasks,
  arrowControl,
  borderBottom,
}: ScaleFileType) => {
  const navigate = useNavigate();
  const selfLocation = location?.pathname?.includes('projectI');
  const [checked, setChecked] = useState(false);
  const ref = useRef(null);

  useClickOutside(ref, () => setChecked(false));

  return (
    <>
      <div
        className='scaleAccordion__taskItem'
        onClick={() => {
          if (selfLocation) {
            navigate(`/file/self/${file?.id}`);
          } else {
            navigate(`/file/${file?.id}`);
          }
        }}
        style={{ borderBottom: borderBottom ? 'none' : undefined }}
      >
        <div className='scaleAccordion__taskItemCell projects'>
          <FinishIcons className='finish_flag' />
          <span className='scaleAccordion__span'>
            {file?.statistics?.completedTasksPercent}%
          </span>

          <LinePercent statistics={file?.statistics} />
        </div>
        <div
          className={`scaleAccordion__taskItemCell task`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          ref={ref}
        >
          <div className={`scaleAccordion__taskItemCell-item`}>
            <FlagIcons className='finish_flag' /> {file?.statistics?.tasksCount}
          </div>
          <div>
            <Checkbox isChecked={checked} setIsChecked={setChecked} className='' />
            {checked && (
              <TemplateProjectI
                initialValues={{
                  color: file?.color ? file?.color : '#ffffff',
                  name: file?.name,
                }}
                onClose={() => {
                  setChecked(false);
                }}
                onCallback={(value: any) => {
                  updateScaleMapFileMutation({
                    variables: {
                      input: {
                        id: file?.id,
                        color: value.color,
                        name: value.name,
                      },
                    },
                  });
                  setChecked(false);
                }}
              />
            )}
          </div>
        </div>
        <div className={`scaleAccordion__taskItemCell name`}>
          <div
            className='scaleAccordion__arrow-wrapper'
            style={{
              width: '70%',
            }}
          >
            {file?.name}
            <div className='scaleAccordion__arrow-wrapper__inner'>
              {file?.statistics?.isTasksUpdatedToday && (
                <div
                  className={`scaleAccordion__arrow-up scaleAccordion__arrow-down`}
                ></div>
              )}
              {file?.statistics?.isTasksCreatedToday && (
                <div className={`scaleAccordion__arrow-up `}></div>
              )}
            </div>
          </div>
          {arrowControl?.showAllArrow && (
            <div
              className='scaleAccordion__wrapper_arrow'
              style={{
                width: '30%',
              }}
            >
              {arrowControl?.showUp && (
                <ArrowLeftIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    upFile({
                      currentOrder: file?.order as number,
                      currentID: file?.id,
                    });
                  }}
                  className='scaleAccordion__arrowUp'
                />
              )}

              {arrowControl?.showDown && (
                <ArrowLeftIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    downFile({
                      currentOrder: file?.order as number,
                      currentID: file?.id,
                    });
                  }}
                  className='scaleAccordion__arrowDown'
                />
              )}
            </div>
          )}
        </div>
      </div>{' '}
      {file?.scaleMapTasks?.length > 0 && isExcludeNullStatusTasks && (
        <ScalePopup2 scaleMapTasks={file?.scaleMapTasks} fileId={file?.id} />
      )}
    </>
  );
};

type LinePercentType = {
  statistics: any;
};

const LinePercent = ({ statistics }: LinePercentType) => {
  return (
    <div className='line__wrapper'>
      <div
        className='line__item line__success'
        style={{ width: `${statistics?.completedTasksPercent}%` }}
      ></div>
      <div
        className='line__item line__process'
        style={{ width: `${statistics?.inProgressTasksPercent}%` }}
      ></div>
      <div
        className='line__item line__problem'
        style={{ width: `${statistics?.problemTasksPercent}%` }}
      >
        {statistics?.problemTasksPercent >= 10 && (
          <>{statistics?.problemTasksPercent} %</>
        )}
      </div>
      <div
        className='line__item line__empty'
        style={{ width: `${statistics?.notInProgressTasksPercent}%` }}
      ></div>
    </div>
  );
};
