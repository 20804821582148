import React, { useState } from 'react';
import Select from 'react-select';

import { selectorStyle } from '../../constants/constants';

type linkType = {
  value: string;
  label: string;
};

type SelectStateControllerType = {
  filterLinks: any;
  setSelectValue: any;
  selectValue: any;
  initValue: any;
  rectOptions: any;
  selectRect: any;
  setSelectRect: any;
  initRect?: linkType | null;
};

export const SelectStateController = ({
  filterLinks,
  setSelectValue,
  selectValue = [],
  initValue,
  rectOptions,
  selectRect,
  setSelectRect,
  initRect,
}: SelectStateControllerType) => {
  const [value, setValue] = useState<any>(initValue ? initValue : null);
  const [value2, setValue2] = useState<any>(initRect);

  const deleteSelectRect = () => {
    setValue2(null);
    const array = JSON.parse(JSON.stringify(selectRect));
    const index = customFindIndex({
      array,
      key: 'rectID',
      value: value2?.value,
    });
    array.splice(index, 1);
    setSelectRect(array);
  };

  return (
    <div style={{ width: '100%' }}>
      <Select
        value={value?.value ? value : undefined}
        styles={selectorStyle}
        options={filterLinks}
        placeholder='Страница или раздел'
        onChange={(option: any) => {
          if (option) {
            setValue(option);
            setSelectValue([...selectValue, option]);
          } else {
            setValue(null);
            const array = JSON.parse(JSON.stringify(selectValue));
            const index = customFindIndex({ array, value: value?.value });
            array.splice(index, 1);
            setSelectValue(array);
            deleteSelectRect();
          }
        }}
        isClearable
      />
      <div className='mt10'>
        <Select
          styles={selectorStyle}
          value={value2}
          options={rectOptions}
          placeholder='Элемент полигона'
          onChange={(option: any) => {
            if (option) {
              setValue2(option);
              setSelectRect([
                ...selectRect,
                { rectID: option.value, taskId: value?.value },
              ]);
            } else {
              deleteSelectRect();
            }
          }}
          isClearable
        />
      </div>
    </div>
  );
};

const customFindIndex = ({ array, key = 'value', value }: any) => {
  return array?.map((x: any) => x[key])?.indexOf(value);
};
