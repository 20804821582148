import './TredsModal.scss';

import React from 'react';

import { useModalContext } from '../../../context';
import { ModalStatuses } from '../../../types/enums';
import { CrossIcon } from '../../icons';

type LoadImageType = {
  value: any;
  setValue: (key: string, value: string) => void;
  setisTread?: () => void;
};

export const LoadImage = ({ value, setValue, setisTread }: LoadImageType) => {
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();
  return (
    <div className='mb10 mt10'>
      <div>
        <div className='tredsModal__images'>
          <div>Фон:</div>
          <div
            className='tredsModal__crossing'
            onClick={() => {
              if (setisTread) {
                return setisTread();
              }
              handleSetModalStatus(ModalStatuses.TREAD_SELECT_FON_MODAL);
              handleChangeModalParameters([
                {
                  key: 'onCallback',
                  value: (value: any) => {
                    setValue('cover', value?.cover);
                    setValue('threadBackgroundId', value.threadBackgroundId);
                  },
                },
                {
                  key: 'initialValues',
                  value: {
                    threadBackground: {
                      id: value?.threadBackgroundId,
                    },
                    cover: value?.cover,
                  },
                },
              ]);
            }}
          >
            <CrossIcon color='#AFAFAF' width='9px' height='9px' />
          </div>
        </div>
      </div>
    </div>
  );
};
