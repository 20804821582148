import './Subtasks.scss';

import React, { FC } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  MainContentHeaderItem,
  NewSubtask,
  ProjectStatus,
  UserItem,
} from '../../components';
import { Tag, TagList } from '../../graphql/generated/graphql';
import { addKarma } from '../../utils';
import { Deadline } from '..';
import { PolygonIcon } from '../icons';
import { TagItem } from '../tag/TagItem';

export const Subtasks: FC<SubTasksProps> = ({
  subTasksList,
  taskMaintainerId,
  initialTagIds,
}) => {
  const { taskId } = useParams();

  const location = useLocation();

  const navigate = useNavigate();

  const isArchivedPage = location.pathname.includes('archive');
  return (
    <div className='subtasks'>
      <div className='subtasks__header'>
        <MainContentHeaderItem title='Подзадачи:' style={{ fontWeight: 'normal' }} />
        <NewSubtask taskMaintainerId={taskMaintainerId} initialTagIds={initialTagIds} />
      </div>
      {subTasksList?.length ? (
        <div className='subtasks__list'>
          {subTasksList?.map((subtask) => {
            return (
              <div key={subtask?.id} className='subtasks__item'>
                <div className='subtasks__item-linkWrap'>
                  <ProjectStatus
                    projectStatus={subtask?.status?.id}
                    id={subtask && (subtask.id as any)}
                  />
                  <Link
                    to={
                      isArchivedPage
                        ? `/archivedTask/${taskId}/archivedSubtaskId/${subtask?.id}`
                        : `/task/${taskId}/subtask/${subtask?.id}`
                    }
                    className='subtasks__item-link'
                  >
                    <span>{`#${subtask?.task?.project?.number}.${subtask?.task?.number}.${subtask?.number}`}</span>
                    <p>{`${subtask?.name}`}</p>
                  </Link>
                  {subtask?.poligonObject?.id ? (
                    <div
                      style={{
                        width: '18px',
                        borderRight: '1px solid #afafaf',
                        paddingRight: '10px',
                      }}
                    >
                      <PolygonIcon
                        color='#303030'
                        onClick={() => {
                          navigate(
                            `/polygon/${subtask?.task?.project?.id}/${subtask?.poligonObject?.id}`,
                          );
                        }}
                      />
                    </div>
                  ) : null}
                </div>
                <UserItem
                  name={addKarma(subtask?.maintainer as any)}
                  avatar={subtask?.maintainer?.image}
                  id={taskMaintainerId as number}
                />
                <div className='tagsList'>
                  {subtask?.tags?.rows.map((tag: Tag) => (
                    <div key={tag.id}>
                      <TagItem
                        id={tag.id}
                        title={tag.name}
                        color={tag.color}
                        textColor={tag.textColor as string}
                      />
                    </div>
                  ))}
                </div>
                <div className='subtasks__item-deadline'>
                  {subtask?.endDate ? <Deadline date={subtask?.endDate} /> : null}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <p className='subtasks__info-text'>Подзадач пока нет</p>
      )}
    </div>
  );
};

export type SubTasksProps = {
  subTasksList?: Array<{
    id: number;
    name: string;
    number?: number | null;
    status?: { id?: number; color?: string } | null;
    task?: {
      number?: number | null;
      project: {
        id: number;
        number?: number | null;
      } | null;
    } | null;
    tags: TagList;
    endDate?: string | null;
    maintainer?: {
      login?: string | null;
      image?: string | null;
    } | null;
    poligonObject?: {
      id: number;
      name: string;
    } | null;
  } | null> | null;
  taskMaintainerId?: number | null;
  initialTagIds: Array<string> | undefined;
};
