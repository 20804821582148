import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const ClipIcon: FC<ClipIconType> = ({
  width = '13px',
  height = '12px',
  className = 'clip-icon',
  color = '#AFAFAF',
  onClick,
  style,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 13 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      style={style}
    >
      <g clipPath='url(#clip0_294_6892)'>
        <path
          d='M7.10223 11.6202C6.99754 11.6202 6.89287 11.5833 6.813 11.5096L1.08386 6.22117C-0.361286 4.88717 -0.361286 2.71661 1.08386 1.38263C2.5323 0.0456016 4.87694 0.0453419 6.32561 1.38263L12.2127 6.81682C13.2624 7.78579 13.2624 9.3624 12.2127 10.3314C11.163 11.3003 9.455 11.3004 8.40526 10.3314L3.30414 5.62262C2.66343 5.03117 2.66343 4.06886 3.30414 3.47746C3.94631 2.88466 4.98577 2.88457 5.62807 3.47746L10.5135 7.98714C10.6733 8.13459 10.6733 8.37368 10.5135 8.52113C10.3538 8.6686 10.0948 8.6686 9.93505 8.52113L5.04959 4.01144C4.7271 3.7138 4.20517 3.71373 3.8826 4.01144C3.56088 4.30842 3.56088 4.79166 3.8826 5.08864L8.98371 9.79736C9.71449 10.4719 10.9035 10.4719 11.6342 9.79736C12.3649 9.12282 12.3649 8.02533 11.6342 7.35081L5.74713 1.91659C4.61834 0.87465 2.79129 0.874508 1.66237 1.91659C0.536184 2.95614 0.536184 4.64761 1.66234 5.68717L7.39149 10.9756C7.64996 11.2142 7.46338 11.6202 7.10223 11.6202Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_294_6892'>
          <rect width='13' height='12' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

type ClipIconType =
  | Pick<ArrowLeftIconType, 'width' | 'height' | 'color' | 'className' | 'onClick'> & {
      style?: any;
    };
