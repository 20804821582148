import './ProjectBlockItem.scss';

import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { ProjectStatus, TagItem } from '../../../components';
import { Tag } from '../../../graphql/generated/graphql';
import { HoverRectIdType } from '../../../types/enums';
import { getUserAvatar } from '../../../utils';
import { Deadline } from '../..';
import { PolygonIcon } from '../../icons';

type ProjectBlockItemPropsType = {
  title?: string;
  userAvatar?: string | null;
  projectStatus?: number;
  taskId?: number;
  name?: string | null;
  tags: Tag[];
  isArchived?: boolean;
  endDate?: string | null;
  poligonObjectId?: number;
  projectId?: number;
  hoverRectId?: { id: string | number; type: HoverRectIdType };
  setHoverRectId?: (value: { id: string | number; type: HoverRectIdType }) => void;
  connectRectId?: number | string;
  isSubtask?: boolean;
  subtaskId?: number;
};

export const ProjectBlockItem: React.FC<ProjectBlockItemPropsType> = ({
  title,
  taskId,
  userAvatar,
  projectStatus,
  name,
  endDate,
  tags,
  isArchived,
  poligonObjectId,
  projectId,
  hoverRectId,
  setHoverRectId,
  connectRectId,
  isSubtask,
  subtaskId,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!hoverRectId) return;
    if (hoverRectId.id === connectRectId && hoverRectId.type === HoverRectIdType.RECT) {
      document.getElementById(`task-${connectRectId}`)?.scrollIntoView();
    }
  }, [hoverRectId?.id]);

  return (
    <div
      className='project-block-item'
      style={{
        border:
          hoverRectId &&
          hoverRectId.id === connectRectId &&
          hoverRectId.type === HoverRectIdType.RECT
            ? '1px solid #9747FF'
            : '1px solid white',
        backgroundColor:
          hoverRectId &&
          hoverRectId.id === connectRectId &&
          hoverRectId.type === HoverRectIdType.RECT
            ? '#F5F5F5'
            : 'white',
      }}
      onMouseEnter={() => {
        connectRectId &&
          setHoverRectId &&
          setHoverRectId({ id: connectRectId as any, type: HoverRectIdType.DESCRIPTION });
      }}
      onMouseLeave={() => {
        connectRectId &&
          setHoverRectId &&
          setHoverRectId({ id: 0, type: HoverRectIdType.NONE });
      }}
      id={connectRectId ? `task-${connectRectId}` : `${taskId}`}
    >
      <div className='project-block-item__title'>
        <div className='project-block-item__status'>
          <ProjectStatus projectStatus={projectStatus} id={taskId} />
        </div>
        <span>
          {!isSubtask ? (
            <Link to={isArchived ? `/archivedTask/${taskId}` : `/task/${taskId}`}>
              {title}
            </Link>
          ) : (
            <Link to={`/task/${taskId}/subtask/${subtaskId}`}>{title}</Link>
          )}
        </span>
      </div>
      <div className='project-block-item__user'>
        <div className='project-block-item__user-inner'>
          {name ? (
            <div className='project-block-item__user-item'>
              <img src={getUserAvatar(userAvatar)} alt='avatar' />
              <span>{name}</span>
            </div>
          ) : (
            <p>Ответственных нет</p>
          )}
          {endDate ? <Deadline date={endDate} status={projectStatus} /> : null}
        </div>
      </div>
      <div className='project-block-item__tags'>
        <div className='project-block-item__tags-items'>
          {tags?.map((tag: Tag) => (
            <TagItem
              key={tag.id}
              id={tag.id}
              title={tag.name}
              color={tag.color}
              textColor={tag.textColor as string}
            />
          ))}
        </div>
        {poligonObjectId && (
          <PolygonIcon
            color='#AFAFAF'
            onClick={() => {
              navigate(`/polygon/${projectId}/${poligonObjectId}`);
            }}
          />
        )}
      </div>
    </div>
  );
};
