import * as yup from 'yup';

import * as MESSAGES from '../messages';

export const renameTred = yup.object().shape({
  name: yup
    .string()
    .min(3, MESSAGES.INCORRECT_MIN_LENGTH_3)
    .max(30, MESSAGES.INCORRECT_MAX_LENGTH_30)
    .required(MESSAGES.REQUIRE_MESSAGE),
  comment: yup.string().max(50, MESSAGES.INCORRECT_MAX_LENGTH_50),
});
