import React from 'react';

export const NotificationUnSubscribeIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.7946 18.0806C20.2122 17.55 19.7022 16.9418 19.2785 16.2722C18.816 15.348 18.5387 14.3388 18.4631 13.3036V10.2548C18.4671 8.62887 17.8899 7.05745 16.8399 5.83575C15.7899 4.61404 14.3395 3.82614 12.7611 3.62009V2.82393C12.7611 2.60541 12.6761 2.39584 12.5249 2.24132C12.3737 2.08681 12.1686 2 11.9547 2C11.7408 2 11.5357 2.08681 11.3845 2.24132C11.2333 2.39584 11.1483 2.60541 11.1483 2.82393V3.63244C9.58405 3.85334 8.15112 4.646 7.11495 5.8636C6.07878 7.0812 5.50957 8.64123 5.51275 10.2548V13.3036C5.4371 14.3388 5.15987 15.348 4.69732 16.2722C4.28107 16.9402 3.77931 17.5484 3.20537 18.0806C3.14094 18.1384 3.0893 18.2096 3.05389 18.2894C3.01848 18.3692 3.00011 18.4558 3 18.5435V19.3828C3 19.5465 3.06364 19.7035 3.17692 19.8192C3.29019 19.935 3.44383 20 3.60403 20H20.396C20.5562 20 20.7098 19.935 20.8231 19.8192C20.9364 19.7035 21 19.5465 21 19.3828V18.5435C20.9999 18.4558 20.9815 18.3692 20.9461 18.2894C20.9107 18.2096 20.8591 18.1384 20.7946 18.0806ZM4.25638 18.7656C4.81837 18.2109 5.31318 17.5893 5.7302 16.9141C6.31285 15.7979 6.65281 14.5664 6.72685 13.3036V10.2548C6.70289 9.53145 6.82163 8.81061 7.076 8.13516C7.33037 7.45972 7.71516 6.84348 8.20746 6.32315C8.69977 5.80282 9.28952 5.38903 9.94159 5.10643C10.5937 4.82382 11.2947 4.67819 12.003 4.67819C12.7113 4.67819 13.4124 4.82382 14.0645 5.10643C14.7165 5.38903 15.3063 5.80282 15.7986 6.32315C16.2909 6.84348 16.6757 7.45972 16.93 8.13516C17.1844 8.81061 17.3032 9.53145 17.2792 10.2548V13.3036C17.3532 14.5664 17.6932 15.7979 18.2758 16.9141C18.6929 17.5893 19.1877 18.2109 19.7497 18.7656H4.25638Z'
        fill='#303030'
      />
      <path
        d='M12.038 21.9999C12.5171 21.9935 12.9768 21.8897 13.3358 21.7067C13.6949 21.5237 13.9301 21.2734 14 21H10C10.0718 21.2808 10.3181 21.537 10.693 21.7209C11.0679 21.9049 11.5459 22.004 12.038 21.9999Z'
        fill='#303030'
      />
    </svg>
  );
};
