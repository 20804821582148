import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useAnchorFile = ({ setSelectFirstCol, file }: any) => {
  const [searchParams] = useSearchParams();
  const firstCol = searchParams.get('firstCol');
  const [goAnchor, setGoAnchor] = useState(true);

  useEffect(() => {
    if (firstCol && file && goAnchor) {
      setTimeout(() => {
        setSelectFirstCol(parseInt(firstCol));
        const element = document.getElementById(firstCol);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
        setGoAnchor(false);
      }, 100);
    }
  }, [file]);
};
