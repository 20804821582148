import './Palette.scss';

import { Field, Formik } from 'formik';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, ProjectStatus, TooltipStatus } from '../../components';
import { useChatContext } from '../../context';
import { useGetStatusesQuery } from '../../graphql/generated/graphql';
import {
  useUpdateSubtaskMutation,
  useUpdateTaskMutation,
} from '../../hooks/useMutations';
import { getStatusNameById } from '../../utils';

type Props = {
  fullWidth?: boolean;
  statusId?: number;
};

type initialValues = {
  status: number;
};

export const Palette: FC<Props> = ({ fullWidth, statusId }) => {
  const { taskId, subtaskId } = useParams();

  const { data } = useGetStatusesQuery();

  const [updateTask, { loading: loadingTask }] = useUpdateTaskMutation();

  const [updateSubtask, { loading: loadingSubtask }] = useUpdateSubtaskMutation();

  const { sendMessageHandler } = useChatContext();

  const handleFormSubmit = async (values: initialValues) => {
    if (subtaskId) {
      await updateSubtask({
        variables: {
          id: Number(subtaskId),
          data: {
            statusId: Number(values.status),
          },
        },
        onCompleted: (response) => {
          if (response?.updateSubtask?.id) {
            toast.success(
              `Изменен статус на ${getStatusNameById(
                response?.updateSubtask?.status?.id,
              )} цвет!`,
            );
            sendMessageHandler(
              `Изменен статус на ${getStatusNameById(
                response?.updateSubtask?.status?.id,
              )} цвет!`,
            );
          }
        },
      });
    } else {
      await updateTask({
        variables: {
          id: Number(taskId),
          data: {
            statusId: Number(values.status),
          },
        },
        onCompleted: (response) => {
          if (response?.updateTask?.id) {
            toast.success(
              `Изменен статус на ${getStatusNameById(
                response?.updateTask?.status?.id,
              )} цвет!`,
            );
            sendMessageHandler(
              `Изменен статус на ${getStatusNameById(
                response?.updateTask?.status?.id,
              )} цвет!`,
            );
          }
        },
      });
    }
  };

  const full = fullWidth ? { width: '100%' } : {};

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={{
        status: statusId || 0,
      }}
      enableReinitialize={true}
    >
      {({ handleSubmit, values }) => (
        <div className='palette' style={full}>
          {data?.getStatuses?.map((status) => {
            return (
              <label key={status.id} htmlFor={`${status.id}`} id={`${status.id}pelette`}>
                <Field
                  style={{ display: 'none', width: '0', height: '0' }}
                  type='radio'
                  id={`${status.id}`}
                  value={status.id}
                  name='status'
                />
                <div className='palette__box'>
                  <ProjectStatus
                    projectStatus={status.id}
                    id={status.id}
                    style={
                      +status.id == +values.status
                        ? { borderRadius: '50%', border: '0.5px solid #303030' }
                        : {}
                    }
                  />
                </div>
                <TooltipStatus id={status.id} anchorId={`${status.id}pelette`} />
              </label>
            );
          })}
          <div className={'palette__button-submit'}>
            <Button
              withBorder
              type='submit'
              width={100}
              height={20}
              backgroundColor='#FFFFFF'
              fontSize='12px'
              lineHeight='14px'
              onClick={handleSubmit}
              text='Сохранить'
              disabled={loadingTask || loadingSubtask}
            />
          </div>
        </div>
      )}
    </Formik>
  );
};
