import './WrapperUserStatisticBlock.scss';

import React from 'react';

import { ROLES } from '../../constants/constants';
import { useAuthContext } from '../../context';
import { useGetUserAnalyticQuery } from '../../graphql/generated/graphql';
import { UserStatisticBlock } from '../index';

type WrapperUserStatisticBlockType = {
  id: number | undefined | null;
  isAnalytics: boolean;
};

export const WrapperUserStatisticBlock = ({
  id,
  isAnalytics,
}: WrapperUserStatisticBlockType) => {
  const { currentUserData } = useAuthContext();
  currentUserData?.role === ROLES.ADMIN;

  const { data } = useGetUserAnalyticQuery({
    variables: {
      userId: id as number,
    },
    skip: currentUserData?.role !== ROLES.ADMIN || !isAnalytics,
  });
  return (
    <div>
      <div className='WrapperUserStatisticBlock'>
        <div className='WrapperUserStatisticBlock__inner'>
          {data && <UserStatisticBlock userData={data && data?.getUserAnalytic} />}
        </div>
      </div>
    </div>
  );
};
