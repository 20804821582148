import './Checkbox.scss';

import React from 'react';

export const Checkbox = ({
  className,
  isChecked,
  setIsChecked,
  label,
  disabled = false,
}: {
  className: string;
  isChecked: boolean;
  setIsChecked: (value: boolean) => void;
  label?: string;
  disabled?: boolean;
}) => {
  function handleCheckboxChange() {
    setIsChecked(!isChecked);
  }

  return (
    <div className={`checkbox ${className} ${disabled ? 'checkbox__disable' : ''}`}>
      {label && (
        <div
          className='labelTitle'
          onClick={() => {
            !disabled && handleCheckboxChange();
          }}
        >
          {label}
        </div>
      )}

      <label>
        <input
          type='checkbox'
          checked={isChecked}
          value={isChecked as any}
          onChange={handleCheckboxChange}
          disabled={disabled}
        />

        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className='checkmark'
        >
          <path
            d='M2.66675 8L6.66675 12L13.3334 4'
            stroke={isChecked ? 'green' : 'none'}
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </label>
    </div>
  );
};

export default Checkbox;
