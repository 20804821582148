import React from 'react';

export const ClockIcons = ({ className, onClick, color = '#AFAFAF' }: any) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M7.99998 1.33398C11.682 1.33398 14.6666 4.31865 14.6666 8.00065C14.6666 11.6827 11.682 14.6673 7.99998 14.6673C4.31798 14.6673 1.33331 11.6827 1.33331 8.00065C1.33331 4.31865 4.31798 1.33398 7.99998 1.33398ZM7.99998 2.26398C6.47852 2.26398 5.01938 2.86838 3.94354 3.94422C2.86771 5.02005 2.26331 6.47919 2.26331 8.00065C2.26331 9.52211 2.86771 10.9813 3.94354 12.0571C5.01938 13.1329 6.47852 13.7373 7.99998 13.7373C9.52144 13.7373 10.9806 13.1329 12.0564 12.0571C13.1322 10.9813 13.7366 9.52211 13.7366 8.00065C13.7366 6.47919 13.1322 5.02005 12.0564 3.94422C10.9806 2.86838 9.52144 2.26398 7.99998 2.26398ZM7.37998 5.05465C7.63665 5.05465 7.84465 5.26332 7.84465 5.51998V8.66732L10.961 7.98823C11.0813 7.99279 11.1952 8.04377 11.2786 8.13048C11.3621 8.21718 11.4088 8.33287 11.4088 8.45323C11.4088 8.5736 11.3621 8.68928 11.2786 8.77598C11.1952 8.86269 11.0813 8.91368 10.961 8.91823L7.37998 9.70598C7.25657 9.70598 7.13821 9.65696 7.05094 9.56969C6.96367 9.48242 6.91465 9.36407 6.91465 9.24065V5.52065C6.91465 5.26332 7.12265 5.05465 7.37998 5.05465Z'
        fill={color}
      />
    </svg>
  );
};
