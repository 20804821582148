import React, { useState } from 'react';

import { useModalContext } from '../../../../../context';
import { AddModalEnum, ModalStatuses } from '../../../../../types/enums';
import { AddPageIcon, EditIcon, LinkIcon, PlusIcons } from '../../../../icons/index';
import { Button, Pagination } from '../../../../index';

type LinkAddModalType = {
  buttonText: string;
  projectId: string;
  handleSubmit: () => void;
  selectValue: any;
  selectRect: any;
  rectOptionInit: any;
  setModalStatus: (value: AddModalEnum) => void;
  setEditValue: (value: any) => void;
};

export const AddLinkStart = ({
  buttonText,
  projectId,
  selectValue,
  handleSubmit,
  selectRect,
  rectOptionInit,
  setModalStatus,
  setEditValue,
}: LinkAddModalType) => {
  const shift = 5;
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  const [currentPage, setCurrentPage] = useState(1);

  return (
    <div className='link-add-modal' style={{ width: 'auto', maxWidth: 'none' }}>
      <div className='link-add-modal__links'>
        <span className='link-add-modal__links-title'>Задачи</span>
        {selectValue
          ?.slice((currentPage - 1) * shift, (currentPage - 1) * shift + shift)
          ?.map((select: selectValueType, index: number) => {
            const initRect = selectRect
              .map((e: any) => {
                if (e?.taskId === select?.value) {
                  return rectOptionInit?.find((option: any) => {
                    if (option?.value === e?.rectID) return option;
                  });
                }
              })
              .filter((e: any) => !!e)[0];

            return (
              <SelectElement
                value={select}
                key={index}
                initRect={initRect}
                setModalStatus={setModalStatus}
                setEditValue={setEditValue}
              />
            );
          })}
      </div>
      <div className='mt10'>
        <Pagination
          paginate={setCurrentPage}
          currentPage={currentPage}
          totalCount={selectValue?.length as number}
          pageSize={shift}
          siblingCount={1}
          hideArrow
        />
      </div>
      <div
        className='add-more__wrapper'
        onClick={() => {
          setModalStatus(AddModalEnum.ADD_STATUS);
        }}
      >
        <div className='add-more__text'>Добавить ещё</div>
        <PlusIcons color='#303030' width='12' height='12' />
      </div>
      <div className='add-more__or'>или</div>
      <Button
        text='Новая задача \ подзадача'
        width='100%'
        backgroundColor='#149939'
        color='#FFFFFF'
        onClick={() => {
          handleSetModalStatus(ModalStatuses.CREATE_TASK_OR_SUBTASK_MODAL);
          handleChangeModalParameters([
            {
              key: 'projectId',
              value: projectId,
            },
          ]);
        }}
      >
        <AddPageIcon width='15px' height='15px' />
      </Button>
      <Button
        text={buttonText}
        width='100%'
        height='25px'
        onClick={() => {
          handleSubmit();
        }}
      />
    </div>
  );
};

type SelectElementType = {
  value: selectValueType;
  initRect: selectValueType;
  setModalStatus: (volid: AddModalEnum) => void;
  setEditValue: (value: any) => void;
};

const SelectElement = ({
  value,
  initRect,
  setModalStatus,
  setEditValue,
}: SelectElementType) => {
  return (
    <div className='link-add-modal__item'>
      <LinkIcon width='10' height='10' className='link-add-modal__item-icons' />
      <div className='link-add-modal__item-text'>
        <div className={`link-add-modal__item-label ${initRect?.label && 'mb10'}`}>
          {value?.label}
        </div>
        <div>{initRect?.label}</div>
      </div>
      <EditIcon
        color='#303030'
        width='12'
        height='12'
        onClick={() => {
          setEditValue(value);
          setModalStatus(AddModalEnum.EDIT_STATUS);
        }}
      />
    </div>
  );
};

type selectValueType = {
  value: number;
  label: string;
};
