import { gql } from '@apollo/client';

export const GET_EXPENSES = gql`
  query getExpenses($expensesDate: String, $data: GetTasksInput!) {
    getExpenses(expensesDate: $expensesDate) {
      projects(limit: 100, sort: { field: number, type: ASC }) {
        id
        name
        number
        tasks(data: $data) {
          count
          rows {
            id
            number
            name
            code
            parentId
            startDate
            closeDate
            endDate
            realizationPrice
            realizationPerMonthAmount
            status {
              id
              color
            }
            project {
              id
              number
            }
            tags {
              count
              rows {
                id
                name
                color
                textColor
                createdAt
                updatedAt
              }
            }
          }
        }
      }
      amountPerMonth
      amountPerYear
    }
  }
`;
