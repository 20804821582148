import { getDaysInMonth } from 'date-fns';
import { useEffect, useState } from 'react';

import {
  SortingField,
  SortingOrders,
  useGetCalendarScaleMapTasksWithThreadsQuery,
  useTaskSubtaskActivitySubscription,
  useUpdateSubtaskMutation,
  useUpdateTaskMutation,
  useUpdateThreadMutation,
} from '../graphql/generated/graphql';
import { getMonthRange } from '../utils';
import { useGetCalendarQuery } from './useQueries';

export const useNewActivityCalendar = ({ filter }: { filter: boolean }) => {
  const initialDate = new Date();

  const [daysCount, setDaysCount] = useState(getDaysInMonth(initialDate));

  const [checkedDate, setCheckedDate] = useState(initialDate);

  const [range, setRange] = useState(getMonthRange(initialDate));

  const [isLoading, setIsLoading] = useState(false);

  const changeMonth = (date: Date) => {
    setDaysCount(getDaysInMonth(date));
    setCheckedDate(date);
    setRange(getMonthRange(date));
  };

  const {
    data: calendarActivitySubscriptionData,
    loading: calendarActivitySubscriptionLoading,
  } = useTaskSubtaskActivitySubscription();

  const { data, loading, refetch } = useGetCalendarQuery({
    variables: {
      dateRange: range,
      projectSort: { type: SortingOrders.Asc, field: SortingField.Number },
      taskSort: { type: SortingOrders.Asc, field: SortingField.Code },
      subtasksData: {
        sort: { type: SortingOrders.Asc, field: SortingField.Number },
      },
      // filter by yellow status
      taskSubtaskStatusId: filter ? 3 : undefined,
    },
  });

  const {
    data: dataTred,
    loading: loadingTred,
    refetch: refetchTred,
  } = useGetCalendarScaleMapTasksWithThreadsQuery({
    variables: {
      dateRange: range,
      limit: 999,
      offset: 0,
      projectSort: { type: SortingOrders.Asc, field: SortingField.Number },
      scaleMapTaskStatusId: filter ? [2, 4] : undefined,
    },
    fetchPolicy: 'no-cache',
  });

  const [updateThreadMutation] = useUpdateThreadMutation({
    onCompleted: () => {
      refetchTred();
    },
  });

  const [updateTask] = useUpdateTaskMutation({
    onCompleted: () => {
      refetch();
    },
  });
  const [updateSubtask] = useUpdateSubtaskMutation({
    onCompleted: () => {
      refetch();
    },
  });

  useEffect(() => {
    if (!calendarActivitySubscriptionLoading) {
      if (calendarActivitySubscriptionData && !isLoading) {
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
          refetch();
        }, 3000);
      }
    }
  }, [calendarActivitySubscriptionData]);

  return {
    data,
    loading,
    changeMonth,
    checkedDate,
    daysCount,
    updateTask,
    updateSubtask,
    dataTred,
    loadingTred,
    updateThreadMutation,
  };
};
