import './DecideOnTheFlyDeleteTask.scss';

import React from 'react';

import { Button } from '../../../index';

export const DecideOnTheFlyDeleteTask = ({ onClose, modalParameters }: any) => {
  return (
    <div className='decideOnTheFlyDeleteTask'>
      <Button
        text='Удалить'
        width={'100%'}
        onClick={() => {
          modalParameters.onCallback();
          onClose();
        }}
      />
      <Button
        margin='20px 0px 0px 0px'
        text='Отменить'
        width={'100%'}
        onClick={() => {
          onClose();
        }}
      />
    </div>
  );
};
