import './ProjectBlockTitle.scss';

import React from 'react';
import { useLocation } from 'react-router-dom';

type ProjectBlockTitlePropsType = {
  title: string | undefined;
};

export const ProjectBlockTitle = ({ title }: ProjectBlockTitlePropsType) => {
  const location = useLocation();

  const isArchivePage = location.pathname.includes('archive');
  return (
    <div className={isArchivePage ? 'projectBlockTitleArchive' : 'projectBlockTitle'}>
      <span>{title}</span>
    </div>
  );
};
