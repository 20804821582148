import React from 'react';

type ForumImgIconsType = {
  onClick?: (value: any) => void;
  className?: string;
};

export const ForumImgIcons = ({ onClick, className }: ForumImgIconsType) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      className={className}
    >
      <path
        d='M8.49902 9.5C8.49902 10.0523 8.05131 10.5 7.49902 10.5C6.94674 10.5 6.49902 10.0523 6.49902 9.5C6.49902 8.94772 6.94674 8.5 7.49902 8.5C8.05131 8.5 8.49902 8.94771 8.49902 9.5Z'
        fill='#303030'
        stroke='#303030'
      />
      <path
        d='M10.499 14L8.99902 12L5.99902 16H17.999L13.499 10L10.499 14Z'
        fill='#303030'
      />
      <path
        d='M19.999 4H3.99902C2.89602 4 1.99902 4.897 1.99902 6V18C1.99902 19.103 2.89602 20 3.99902 20H19.999C21.102 20 21.999 19.103 21.999 18V6C21.999 4.897 21.102 4 19.999 4ZM3.99902 18V6H19.999L20.001 18H3.99902V18Z'
        fill='#303030'
      />
    </svg>
  );
};
