import * as yup from 'yup';

import * as MESSAGES from '../messages';

export const addPolygonLink = ({ poligonIDArray, fatherElements }: any) =>
  yup.object().shape({
    parentLink: yup
      .number()
      .positive()
      .integer()
      .test('', MESSAGES.POLYGON_NOT_FOUND, (val: any): any => {
        return val ? poligonIDArray.includes(val) : true;
      })
      .test('', MESSAGES.POLYGON_TREE_ERROR, (val: any): any => {
        return val ? !fatherElements.includes(val) : true;
      })
      .nullable(),
  });
