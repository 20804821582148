import { toast } from 'react-toastify';

import * as GENERATED from '../../graphql/generated/graphql';
import { getErrorText } from '../../utils';

export const useRemoveNoteMutation = () => {
  return GENERATED.useRemoveNoteMutation({
    onCompleted: (response) => {
      if (response?.removeNote) {
        toast.success('Заметка успешно удалена!');
      }
    },
    onError: (error) => {
      getErrorText(error.message);
    },
  });
};
