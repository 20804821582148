import React from 'react';

type TreadDeleteIconsType = {
  className?: string;
};

export const TreadDeleteIcons = ({ className }: TreadDeleteIconsType) => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M11.8125 2.96186H9.625V2.51315C9.62356 2.15658 9.48482 1.81504 9.23899 1.5629C8.99316 1.31077 8.66015 1.16847 8.3125 1.16699H5.6875C5.33985 1.16847 5.00684 1.31077 4.76101 1.5629C4.51518 1.81504 4.37644 2.15658 4.375 2.51315V2.96186H2.1875C2.07147 2.96186 1.96019 3.00914 1.87814 3.09329C1.79609 3.17744 1.75 3.29157 1.75 3.41058C1.75 3.52959 1.79609 3.64372 1.87814 3.72787C1.96019 3.81202 2.07147 3.8593 2.1875 3.8593H2.625V11.9362C2.625 12.1742 2.71719 12.4025 2.88128 12.5708C3.04538 12.7391 3.26794 12.8337 3.5 12.8337H10.5C10.7321 12.8337 10.9546 12.7391 11.1187 12.5708C11.2828 12.4025 11.375 12.1742 11.375 11.9362V3.8593H11.8125C11.9285 3.8593 12.0398 3.81202 12.1219 3.72787C12.2039 3.64372 12.25 3.52959 12.25 3.41058C12.25 3.29157 12.2039 3.17744 12.1219 3.09329C12.0398 3.00914 11.9285 2.96186 11.8125 2.96186ZM6.125 9.69263C6.125 9.81164 6.07891 9.92577 5.99686 10.0099C5.91481 10.0941 5.80353 10.1414 5.6875 10.1414C5.57147 10.1414 5.46019 10.0941 5.37814 10.0099C5.29609 9.92577 5.25 9.81164 5.25 9.69263V6.10289C5.25 5.98388 5.29609 5.86975 5.37814 5.7856C5.46019 5.70145 5.57147 5.65417 5.6875 5.65417C5.80353 5.65417 5.91481 5.70145 5.99686 5.7856C6.07891 5.86975 6.125 5.98388 6.125 6.10289V9.69263ZM8.75 9.69263C8.75 9.81164 8.70391 9.92577 8.62186 10.0099C8.53981 10.0941 8.42853 10.1414 8.3125 10.1414C8.19647 10.1414 8.08519 10.0941 8.00314 10.0099C7.92109 9.92577 7.875 9.81164 7.875 9.69263V6.10289C7.875 5.98388 7.92109 5.86975 8.00314 5.7856C8.08519 5.70145 8.19647 5.65417 8.3125 5.65417C8.42853 5.65417 8.53981 5.70145 8.62186 5.7856C8.70391 5.86975 8.75 5.98388 8.75 6.10289V9.69263ZM8.75 2.96186H5.25V2.51315C5.25 2.39414 5.29609 2.28001 5.37814 2.19585C5.46019 2.1117 5.57147 2.06443 5.6875 2.06443H8.3125C8.42853 2.06443 8.53981 2.1117 8.62186 2.19585C8.70391 2.28001 8.75 2.39414 8.75 2.51315V2.96186Z'
        fill='#303030'
      />
    </svg>
  );
};
