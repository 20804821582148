import './MenuLeft.scss';

import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Chats, Updates } from '../../components';
import { PATHS, ROLES } from '../../constants/constants';
import { useAuthContext, useMenuContext } from '../../context';
import { useNewActivityRefetch } from '../../hooks';
import {
  ArrowLeftIcon,
  BellIconWithNumber,
  ChatIconWithNumber,
  UserStatisticIcons,
} from '../icons';

export const MenuLeft: FC = () => {
  const { isOpenMenu, handleToggleMenu } = useMenuContext();
  const { currentUserData } = useAuthContext();

  const { tasksSubtasksActivities, counts } = useNewActivityRefetch();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      className={`menu-left ${!isOpenMenu ? 'menu-left_close' : ''}`}
      style={{
        height: location.pathname.includes('/tread/') ? 'calc(100vh - 55px)' : '',
      }}
    >
      {isOpenMenu ? (
        <div className='menu-left__top'>
          <Updates />
          <Chats />
        </div>
      ) : (
        <div className='menu-left__top menu-left__top_close'>
          {(currentUserData?.role === ROLES.ADMIN ||
            currentUserData?.role === ROLES.MODERATOR) && (
            <div
              className='mb20'
              style={{
                border: location.pathname.includes('/users-statistic')
                  ? '1px solid white'
                  : '',
                borderRadius: '3px',
              }}
            >
              <UserStatisticIcons
                height='20px'
                width='24px'
                onClick={() => {
                  navigate(PATHS.userStatistic);
                }}
              />
            </div>
          )}

          <BellIconWithNumber
            count={tasksSubtasksActivities?.length || undefined}
            height='18px'
            width='18px'
            onClick={handleToggleMenu}
          />
          <ChatIconWithNumber
            count={counts || undefined}
            height='19px'
            width='20px'
            onClick={handleToggleMenu}
          />
        </div>
      )}
      <div
        className={`menu-left__btn ${!isOpenMenu ? 'menu-left__btn_close' : ''}`}
        onClick={handleToggleMenu}
      >
        <ArrowLeftIcon />
      </div>
    </div>
  );
};
