import React from 'react';

export const CloseTreadWhiteIcon = ({ className }: any) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M13.3334 7.99967C13.3334 7.26434 12.7354 6.66634 12 6.66634H11.3334V4.66634C11.3334 2.82834 9.83802 1.33301 8.00002 1.33301C6.16202 1.33301 4.66669 2.82834 4.66669 4.66634V6.66634H4.00002C3.26469 6.66634 2.66669 7.26434 2.66669 7.99967V13.333C2.66669 14.0683 3.26469 14.6663 4.00002 14.6663H12C12.7354 14.6663 13.3334 14.0683 13.3334 13.333V7.99967ZM6.00002 4.66634C6.00002 3.56367 6.89735 2.66634 8.00002 2.66634C9.10269 2.66634 10 3.56367 10 4.66634V6.66634H6.00002V4.66634Z'
        fill='#F5F5F5'
      />
    </svg>
  );
};
