import './UserTasks.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { RequestHandler, Switch, TagItem, TagsFilter } from '../../components';
import { EditClicked, Status } from '../../components/icons';
import { ROLES } from '../../constants/constants';
import { useAuthContext, useModalContext } from '../../context';
import {
  Project as ProjectType,
  SortingField,
  SortingOrders,
  useGetProjectsUserQuery,
  useGetUserAnalyticQuery,
  useUpdateSubtaskMutation,
  useUpdateTaskMutation,
} from '../../graphql/generated/graphql';
import { ModalStatuses } from '../../types/enums';

export const UserTasks = () => {
  const { userId } = useParams();
  const [filterIds, setFilterIds] = useState<string[]>([]);
  const [findItem, setFindItem] = useState(false);

  const { currentUserData } = useAuthContext();
  const navigate = useNavigate();
  currentUserData?.role !== ROLES.ADMIN && navigate('/');

  const [inProgress, setInProgress] = useState(false);

  const { data: user } = useGetUserAnalyticQuery({
    variables: {
      userId: parseInt(userId as string),
    },
  });

  const { data: userProject, loading } = useGetProjectsUserQuery({
    fetchPolicy: 'network-only',
    variables: {
      data: {
        limit: 100,
        sort: { field: SortingField.Number, type: SortingOrders.Asc },
        taskSubtaskPerformerId: parseInt(userId as any),
      },
      tasksData: {
        limit: 100,
        isTaskStorage: false,
        statusId: inProgress ? 3 : undefined,
        tagsId: filterIds,
        performerId: parseInt(userId as any),
      },
    },
  });
  useEffect(() => {
    setFindItem(false);
  }, [filterIds, userId, inProgress]);

  const projects = userProject?.getProjects?.rows;

  return (
    <div className='container'>
      <div className='UserTasks'>
        <div className='UserTasks__heder'>
          <div className='UserTasks__heder-name'>
            Задачи {user?.getUserAnalytic?.login}
          </div>
          <div className='UserTasks__tags-wrapper'>
            <div className='UserTasks__tags-switch'>
              <div className='UserTasks__tags-label'>Только В работе </div>
              <Switch
                value={inProgress}
                handleChange={(value: boolean) => {
                  setInProgress(value);
                }}
              />
            </div>
            <div className='UserTasks__tags-line'></div>
            <div className='UserTasks__tags-name'>Теги:</div>
            <div className='UserTasks__tags-wrapper-inner'>
              <div className='UserTasks__tags-item'>
                <TagsFilter applyFilter={setFilterIds} filterState={filterIds} />
              </div>
            </div>
          </div>
        </div>
        {findItem && (
          <div className='UserTasks-table__header'>
            <h3 className='UserTasks-table__title'>
              <span className='verticalLine'></span>
              Теги
            </h3>
            <h3 className='UserTasks-table__title'>
              <span className='verticalLine'></span>
              Задача
            </h3>
            <h3 className='UserTasks-table__title'>
              <span className='verticalLine'></span>
              Комментарий
            </h3>
          </div>
        )}

        <RequestHandler loading={loading}>
          {projects?.map((project: any) => {
            return (
              <ProjectItem
                key={project?.id}
                project={project}
                setFindItem={setFindItem}
              />
            );
          })}
          {!findItem && <div className='mt10'>Задач не найдено</div>}
        </RequestHandler>
      </div>
    </div>
  );
};

type ProjectItemType = {
  project: ProjectType;
  setFindItem: (value: boolean) => void;
};

const ProjectItem = ({ project, setFindItem }: ProjectItemType) => {
  const [open, setOpen] = useState(true);

  const tasksSubtasks = project?.tasksSubtasks?.rows;

  if (tasksSubtasks?.length === 0) return null;

  return (
    <div className='ProjectItem'>
      <div
        className='ProjectItem__header'
        onClick={() => {
          setOpen(!open);
        }}
      >
        <svg
          width='10'
          height='6'
          viewBox='0 0 10 6'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          style={{
            marginRight: '10px',
            transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        >
          <path
            d='M9.87747 0.80217L5.31696 5.79486C5.22986 5.8824 5.11397 5.91949 5.0002 5.91365C4.886 5.91949 4.77053 5.8824 4.68342 5.79486L0.122537 0.80217C-0.0408456 0.63838 -0.0408456 0.372465 0.122537 0.208658C0.285919 0.0448503 0.551017 0.0448666 0.714399 0.208658L5.00023 4.90088L9.28562 0.208658C9.44901 0.0448666 9.71409 0.0448666 9.87747 0.208658C10.0409 0.372432 10.0409 0.638363 9.87747 0.80217Z'
            fill='white'
          />
        </svg>
        {`#${project?.number} ${project?.name}`}
      </div>
      <span
        style={{
          display: open ? 'block' : 'none',
        }}
      >
        {tasksSubtasks?.map((task: any) => {
          return <UserItem key={task?.id} task={task} setFindItem={setFindItem} />;
        })}
      </span>
    </div>
  );
};

type UserItemType = {
  task: any;
  setFindItem: (value: boolean) => void;
};

const UserItem = ({ task, setFindItem }: UserItemType) => {
  const [description, setDescription] = useState(task?.description);

  useEffect(() => {
    setFindItem(true);
  }, [task?.tags?.rows]);

  const { handleSetModalStatus, handleChangeModalParameters, handleCloseModal } =
    useModalContext();

  const [updateTaskMutation] = useUpdateTaskMutation();
  const [updateSubtaskMutation] = useUpdateSubtaskMutation();

  const handleChangeDescription = ({ description }: { description: string }) => {
    if (task?.parentId) {
      updateSubtaskMutation({
        variables: {
          data: {
            description: description,
          },
          id: task?.id,
        },
      }).then(() => {
        setDescription(description);
      });
    } else {
      updateTaskMutation({
        variables: {
          data: { description: description },
          id: task?.id,
        },
      }).then(() => {
        setDescription(description);
      });
    }
  };

  const navigate = useNavigate();

  const handleLink = () => {
    if (task?.parentId) {
      navigate(`/task/${task?.parentId}/subtask/${task?.id}`);
    } else {
      navigate(`/task/${task?.id}`);
    }
  };

  return (
    <div className='UserTasksItem'>
      <div
        className='UserTasksItem__col'
        onClick={() => {
          handleLink();
        }}
      >
        <div className='UserTasksItem__tag'>
          {task?.tags?.rows?.map((tag: any) => {
            return (
              <div className='ml15 mb5' key={tag?.id + 'key'}>
                <TagItem
                  key={tag?.id}
                  id={tag?.id}
                  title={tag?.name}
                  color={tag?.color}
                  textColor={tag?.textColor}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div
        className='UserTasksItem__col'
        onClick={() => {
          handleLink();
        }}
      >
        <div className='UserTasksItem__status-wrapper'>
          <Status
            color={task?.status?.color}
            style={{ marginRight: '10px', minWidth: '11px' }}
          />
          <p className='UserTasksItem__content'>{task?.name}</p>
        </div>
      </div>
      <div
        className='UserTasksItem__col'
        onClick={() => {
          handleChangeModalParameters([
            {
              key: 'onCallback',
              value: (values: any) => {
                handleCloseModal();
                handleChangeDescription(values);
              },
            },
            {
              key: 'initValue',
              value: description,
            },
          ]);
          handleSetModalStatus(ModalStatuses.TASK_COMMENTS_MODAL);
        }}
      >
        <div
          className={`${!description && 'UserTasksItem__prev'}  UserTasksItem__content`}
        >
          <EditClicked className='mr10 UserTasksItem__EditClicked' />
          <div>{description ? description : 'Добавьте комментарий к задаче'}</div>
        </div>
      </div>
    </div>
  );
};
