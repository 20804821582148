import './TagsFilter.scss';

import React, { useEffect, useState } from 'react';

import { TagType, useGetTagsQuery } from '../../graphql/generated/graphql';
import { TagItem } from '..';

export type FilterTag = {
  id: number;
  title: string;
  color?: string | null;
  textColor: string | undefined | null;
  active: boolean;
};

type TagsFilterTypeProps = {
  applyFilter?: (ids: string[]) => void;
  filterState?: string[];
  typeTag?: TagType;
};

export const TagsFilter = ({
  applyFilter,
  filterState,
  typeTag,
}: TagsFilterTypeProps) => {
  const { data: dataTags } = useGetTagsQuery({
    variables: typeTag && {
      data: {
        type: typeTag,
      },
    },
  });
  const [filterTags, setFiltersTags] = useState<FilterTag[]>([]);

  const applyTag = (id: number) => {
    if (applyFilter && filterState) {
      const arr = filterTags.map((item) => {
        if (item.id === id) {
          item.active = true;
        }
        return item;
      });
      setFiltersTags([...arr]);
      applyFilter([...filterState, String(id)]);
    }
  };

  const desclineTag = (id: number) => {
    if (applyFilter && filterState) {
      const arr = filterTags.map((item) => {
        if (item.id === id) {
          item.active = false;
        }
        return item;
      });
      setFiltersTags([...arr]);
      const arrIds = filterState.filter((item) => item !== String(id));
      applyFilter([...arrIds]);
    }
  };

  useEffect(() => {
    if (dataTags?.getTags?.rows) {
      const filterTagsArr = dataTags?.getTags?.rows.map((item) => ({
        id: item.id,
        title: item.name,
        color: item.color,
        textColor: item?.textColor,
        active: false,
      }));
      setFiltersTags(filterTagsArr);
    }
  }, [dataTags]);

  return (
    <div className='tagsFilter'>
      <div className='tagsFilter__inner'>
        <p className='tagsFilter__title'>Теги:</p>
        <div className='tagsFilter__list'>
          {filterTags.map((tag) => (
            <div
              className={`tagsFilter__itemWrap ${tag.active && 'active'}`}
              key={tag.id}
            >
              <TagItem
                id={tag.id}
                title={tag.title}
                color={tag.color}
                textColor={tag.textColor as string}
                isClose={tag.active}
                closeFunc={() => desclineTag(tag.id)}
                onClick={() => {
                  !tag.active ? applyTag(tag.id) : null;
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
