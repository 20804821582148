import './TreadItemFile.scss';

import React from 'react';

import { BASE_URL } from '../../../constants/constants';
import { useModalContext } from '../../../context';
import {
  UpdateFileMutationFn,
  UpdateStorageItemMutationFn,
} from '../../../graphql/generated/graphql';
import { FileTypeStorage, ModalStatuses } from '../../../types/enums';
import { textSlice } from '../../../utils';
import {
  SmallEditIcons,
  StorageExelWord,
  StorageFileWord,
  StorageImgWord,
  StoragePdfWord,
  TreadsDeleteICons,
} from '../../icons';

type TreadItemFileType = {
  storageFiles: any;
  deleteFileMutation: any;
  deleteStorageItemMutation: any;
  updateFileMutation: UpdateFileMutationFn;
  updateStorageItemMutation: UpdateStorageItemMutationFn;
  trackStorageEventClickedMutation: any;
};
export const TreadItemFile = ({
  storageFiles,
  deleteFileMutation,
  deleteStorageItemMutation,
  updateFileMutation,
  updateStorageItemMutation,
  trackStorageEventClickedMutation,
}: TreadItemFileType) => {
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  return (
    <div className='TreadItemFile'>
      {storageFiles?.map((file: any) => {
        const createUrlFile = () => {
          if (file?.href) return file?.href;
          return BASE_URL + file?.path;
        };
        const allImg =
          file?.mimeType === FileTypeStorage.jpeg ||
          file?.mimeType === FileTypeStorage.gif ||
          file?.mimeType === FileTypeStorage.png ||
          file?.mimeType === FileTypeStorage.svg ||
          file?.mimeType === FileTypeStorage.webp;
        if (!file) return <></>;
        return (
          <a
            key={file?.id}
            href={createUrlFile() as string}
            target='_blank'
            rel='noreferrer'
            style={{
              textDecoration: 'none',
            }}
            onClick={() => {
              trackStorageEventClickedMutation({
                variables: {
                  fileId: file.__typename !== 'StorageItem' ? file?.id : undefined,
                  storageItemId: file.__typename === 'StorageItem' ? file?.id : undefined,
                },
              });
            }}
          >
            <div
              className='TreadItemFile__item'
              style={{
                border: file?.userClickedAt && '1px solid #DEBB3F',
              }}
            >
              <div className='TreadItemFile__item-inner'>
                {(file?.mimeType === FileTypeStorage.doc ||
                  file?.mimeType === FileTypeStorage.docx) && (
                  <StorageFileWord color='#4E89E1' />
                )}
                {(file?.mimeType === FileTypeStorage.xls ||
                  file?.mimeType === FileTypeStorage.xlsx) && (
                  <StorageExelWord color='#149939' />
                )}
                {file?.mimeType === FileTypeStorage.pdf && (
                  <StoragePdfWord color='#E80025' />
                )}
                {allImg && <StorageImgWord color='#E5580A' />}
                <span className='ml10'>
                  {textSlice({
                    text: file?.name ? file?.name : file?.fileName,
                    count: 20,
                  })}
                </span>
              </div>

              <div style={{ padding: '5px', display: 'flex', alignItems: 'center' }}>
                <SmallEditIcons
                  className='mr10 TreadItemFile__item-edit'
                  onClick={(e: any) => {
                    e.preventDefault();
                    handleSetModalStatus(ModalStatuses.RENAME_TRED_FILE_MODAL);
                    handleChangeModalParameters([
                      {
                        key: 'onCallback',
                        value: (value: any) => {
                          if (file.__typename !== 'StorageItem') {
                            updateFileMutation({
                              variables: {
                                id: file?.id,
                                data: {
                                  fileName: value?.name,
                                },
                              },
                            });
                          } else {
                            updateStorageItemMutation({
                              variables: {
                                data: {
                                  id: file?.id,
                                  name: value?.name,
                                },
                              },
                            });
                          }
                        },
                      },
                      {
                        key: 'initialValues',
                        value: {
                          name: file?.name ? file?.name : file?.fileName,
                        },
                      },
                    ]);
                  }}
                />
                <TreadsDeleteICons
                  color='#E80025'
                  onClick={(e) => {
                    e?.preventDefault();
                    handleSetModalStatus(ModalStatuses.DELETE_TREDS_FILE);
                    handleChangeModalParameters([
                      {
                        key: 'onCallback',
                        value: () => {
                          if (file.__typename === 'StorageItem') {
                            deleteStorageItemMutation({
                              variables: {
                                storageItemId: file?.id,
                              },
                            });
                          } else {
                            deleteFileMutation({
                              variables: {
                                id: file?.id,
                              },
                            });
                          }
                        },
                      },
                    ]);
                  }}
                />
              </div>
            </div>
          </a>
        );
      })}
    </div>
  );
};
