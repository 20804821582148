import './ExpensesHeader.scss';

import React, { FC } from 'react';

import { CalendarPicker } from '../../calendar/calendarPicker/CalendarPicker';
import { ExpensesCount } from '../expensesCount/ExpensesCount';

export const ExpensesHeader: FC<ExpensesHeaderType> = ({
  title,
  changeMonth,
  amountPerYear,
  amountPerMonth,
}) => {
  return (
    <div className='expensesHeader'>
      <h2>{title}</h2>
      <CalendarPicker changeMonthHandler={changeMonth} />
      <div className='expensesHeader__counts'>
        <ExpensesCount title='Итого за месяц' value={amountPerMonth} />
        <ExpensesCount title='Всего' value={amountPerYear} />
      </div>
    </div>
  );
};

type ExpensesHeaderType = {
  title?: string;
  amountPerYear?: number;
  amountPerMonth?: number;
  changeMonth?: (date: Date) => void;
};
