import './TreadItemHeader.scss';

import React, { useRef } from 'react';
import { toast } from 'react-toastify';

import { useLoadFileMutation } from '../../../graphql/generated/graphql';
import { Button, RequestHandler } from '../../index';

export const LoadImage = ({ handleSelectImage }: any) => {
  const [loadFileMutation, { loading }] = useLoadFileMutation({
    onCompleted: (data) => {
      handleSelectImage(data?.loadFile?.path);
    },
  });

  const handleFileChange = async (e: any) => {
    const file: any = e.target.files[0];
    const fileType = file.type;
    if (!fileType.startsWith('image/')) {
      toast.error('Please select an image file.');
      return;
    }
    try {
      await loadFileMutation({ variables: { data: { file: file } } });
    } catch (e) {
      console.error('Error uploading file', e);
    }
  };
  const ref = useRef<any>();
  return (
    <RequestHandler loading={loading}>
      <label className='mb10 mt10 TreadItemHeader__changeBackground'>
        <input
          name='name'
          type='file'
          onChange={handleFileChange}
          style={{
            display: 'none',
          }}
          accept='image/*'
          ref={ref}
        />
        <Button
          text='Добавить новый фон'
          width='100%'
          height='25px'
          margin={'0px 0px 10px 0px'}
          onClick={() => {
            ref?.current?.click();
          }}
        />
      </label>
    </RequestHandler>
  );
};
