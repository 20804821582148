import './TredSelectUser.scss';

import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { PATHS } from '../../constants/constants';
import { useTredsContext } from '../../context';
import { useGetAllUsersQuery, User } from '../../graphql/generated/graphql';
import { useClickOutside } from '../../hooks';
import { addKarma, getUserAvatar } from '../../utils';
import { HistoryIcon, UserOutlineIcons } from '../icons';

export const TredSelectUser = () => {
  const { selectUser, setSelectUser, isAll, setIsAll } = useTredsContext();

  const [showInput, setShowInput] = useState(true);
  const [value, setValue] = useState('');
  const { data } = useGetAllUsersQuery({
    variables: {
      input: {
        search: value,
      },
    },
  });
  const ref = useRef<any>();

  const userList = data?.getAllUsers?.rows;
  useClickOutside(ref, () => setShowInput(true));

  const location = useLocation();

  const handleChangeIsAll = () => {
    setIsAll((prev: boolean) => !prev);
  };

  useEffect(() => {
    setShowInput(true);
  }, [location?.pathname]);
  useEffect(() => {
    if (showInput) {
      setValue('');
    }
  }, [showInput]);

  if (location?.pathname !== PATHS.treads) return <div className='TredSelectUser'></div>;
  return (
    <div className='TredSelectUser' ref={ref}>
      <div
        className={`TredSelectUser__history ${isAll ? 'active' : ''}`}
        onClick={handleChangeIsAll}
      >
        <HistoryIcon color={isAll ? '#000' : '#fff'} />
      </div>
      {showInput ? (
        <>
          <div className='TredSelectUser__line'></div>
          {selectUser ? (
            <div className='TredSelectUser__select'>
              <img
                src={getUserAvatar(selectUser?.image)}
                alt='avatar'
                className='TredSelectUser__avatar'
                onClick={() => {
                  setShowInput(!showInput);
                  setSelectUser(undefined);
                }}
              />
              {addKarma(selectUser)}
            </div>
          ) : (
            <UserOutlineIcons
              onClick={() => {
                setShowInput(!showInput);
              }}
              color='white'
            />
          )}
        </>
      ) : (
        <div className='TredSelectUser'>
          <label>
            <UserOutlineIcons
              onClick={() => {
                setShowInput(!showInput);
              }}
              className='TredSelectUser__icon'
              color='#AFAFAF'
            />
            <input
              className='TredSelectUser__input'
              type='text'
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              placeholder={'имя пользователя'}
              autoFocus
            />
          </label>
        </div>
      )}
      {value && !showInput && (
        <div className='TredSelectUser__list'>
          {userList?.map((e) => {
            return (
              <div
                key={e?.id}
                className='TredSelectUser__item'
                onClick={() => {
                  setSelectUser(e as User);
                  setShowInput(!showInput);
                }}
              >
                <img
                  src={getUserAvatar(e?.image)}
                  alt='avatar'
                  className='TredSelectUser__avatar'
                />
                <div> {addKarma(e as any)}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
