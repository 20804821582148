import './PolygonList.scss';

import React from 'react';

import { ROLES, tasksQueryLimit } from '../../constants/constants';
import { useAuthContext } from '../../context';
import { Poligon as PoligonType } from '../../graphql/generated/graphql';
import { AddPolygonButton, Pagination, PolygonSearch, RequestHandler } from '../index';
import { PolygonListItem } from './polygonListItem/PolygonListItem';

type polygonListProps = {
  poligons: Array<PoligonType>;
  loading: boolean;
  searchPolygon: (value: string) => void;
  currentPage: number;
  handlePagination: (value: number) => void;
  count: number;
};

export const PolygonList = ({
  poligons,
  loading,
  searchPolygon,
  currentPage,
  handlePagination,
  count,
}: polygonListProps) => {
  const { currentUserData } = useAuthContext();
  return (
    <div className='polygonList'>
      <div
        className='AllPolygon__header'
        style={
          currentUserData?.role === ROLES.USER ? { justifyContent: 'space-between' } : {}
        }
      >
        <div className='polygonList__general polygonList__title'>Полигоны</div>
        <PolygonSearch handleSearch={searchPolygon} />
        {currentUserData?.role !== 'User' ? <AddPolygonButton /> : null}
      </div>
      <RequestHandler loading={loading}>
        {poligons?.length ? (
          <>
            <div className='polygonList__header'>
              <h3 className='polygonList__title'>
                <span className='verticalLine'></span>
                Название
              </h3>
              <h3 className='polygonList__title'>
                <span className='verticalLine'></span>
                Создан
              </h3>
              <h3 className='polygonList__title'>
                <span className='verticalLine'></span>
                Проект
              </h3>
            </div>
            <div>
              {poligons.map((poligon: PoligonType) => {
                return <PolygonListItem poligon={poligon} key={poligon.id} />;
              })}
            </div>
          </>
        ) : (
          !loading && <p className='polygonList__text'>Записи не найдены...</p>
        )}
      </RequestHandler>
      <div className='pagination-wrapper'>
        <Pagination
          paginate={handlePagination}
          currentPage={currentPage as number}
          totalCount={count as number}
          pageSize={tasksQueryLimit}
          siblingCount={1}
        />
      </div>
    </div>
  );
};
