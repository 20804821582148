import './UserProfileModal.scss';

import { Field, Formik } from 'formik';
import React, { FC, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { uploadAvatar } from '../../assets/icons';
import { RECOVERY_URL } from '../../constants/constants';
import { useAuthContext, useModalContext } from '../../context';
import {
  EditUserInput,
  InputMaybe,
  Scalars,
  useAdminEditUserMutation,
  useGeneratePasswordResetTokenUserMutation,
} from '../../graphql/generated/graphql';
import { useDeleteUserMutation } from '../../hooks/useMutations';
import { ModalStatuses } from '../../types/enums';
import { browserDetect, getUserAvatar } from '../../utils';
import { Button } from '../button/Button';
import { ConfirmModal } from '../confirmModal/ConfirmModal';
import { Modal, ModalProps } from '../modal/Modal';
import { RequestHandler } from '../requestHandler/RequestHandler';
import { UsersListItemType } from '../usersList/UsersList';
import { UserStatusSelect } from '../userStatusSelect/UserStatusSelect';

type UserProfileModal = {
  userData: UsersListItemType;
  registrationLink?: string | null;
};

export const UserProfileModal: FC<UserProfileModal & ModalProps> = ({
  userData,
  isOpen,
  onClose,
  registrationLink,
}) => {
  const browser = browserDetect();

  const { currentUserData } = useAuthContext();
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  const [adminEditUser] = useAdminEditUserMutation({
    onCompleted: (result) => {
      if (result.adminEditUser.status === 'Success') {
        toast.success('Данные успешно изменены!');
      }
    },
  });

  const [deleteUser] = useDeleteUserMutation(userData.id);

  const [generatePasswordResetToken] = useGeneratePasswordResetTokenUserMutation();

  const [load, setLoad] = useState(false);

  const inputElement = useRef<HTMLInputElement>();

  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

  const [theNoteEditMode, setTheNoteEditMode] = useState(false);

  const handleAvatarSubmits = async (image: InputMaybe<Scalars['Upload']>) => {
    setLoad(true);
    await adminEditUser({
      variables: {
        data: {
          idUser: userData?.id?.toString() || '',
          image: image,
        },
      },
    });
    setLoad(false);
  };

  const handleTheNoteSubmit = async (theNoteValue: InputMaybe<string> | undefined) => {
    if (userData?.theNote !== theNoteValue) {
      setLoad(true);
      await adminEditUser({
        variables: {
          data: {
            idUser: userData?.id?.toString() || '',
            theNote: theNoteValue,
          },
        },
      });
      setLoad(false);
    }
    setTheNoteEditMode(false);
  };

  const handleFullNameSubmit = async (fullNameValue: InputMaybe<string> | undefined) => {
    setLoad(true);
    await adminEditUser({
      variables: {
        data: {
          idUser: userData?.id?.toString() || '',
          fullName: fullNameValue,
        },
      },
    });
    setLoad(false);
  };

  const roleSubmit = async (role: any) => {
    setLoad(true);
    await adminEditUser({
      variables: {
        data: {
          idUser: userData?.id?.toString() || '',
          role: role,
        },
      },
    });
    setLoad(false);
  };

  const handleRemoveUser = async (id: number | null | undefined) => {
    if (id) {
      await deleteUser({
        variables: { id: id.toString() },
        onCompleted: (result) => {
          if (result.deleteUser) {
            onClose && onClose();
            toast.success(`Пользователь ${userData.login} успешно удален!`);
          }
        },
      });
    }
  };

  const handleResetPassword = async ({ safari }: { safari?: boolean }) => {
    if (registrationLink)
      return toast.error('Пароль не может быть сброшен. Регистрация не завершена!');
    await generatePasswordResetToken({
      variables: {
        userId: String(userData.id),
      },
      onCompleted: (res) => {
        if (res.generatePasswordResetTokenUser) {
          const resetPasswordLink = `${RECOVERY_URL}?recovery=${res.generatePasswordResetTokenUser}&login=${userData.login}`;
          if (safari) {
            handleSetModalStatus(ModalStatuses.COPY_PASSWORD);
            handleChangeModalParameters([
              {
                key: 'resetPasswordLink',
                value: resetPasswordLink,
              },
            ]);
          } else {
            navigator.clipboard.writeText(resetPasswordLink);
          }
        }
      },
      onError: () => {
        console.info('errors');
      },
    });
    if (!safari) {
      toast.success(
        'Пароль успешно сброшен! Ссылка для восстановления скопирована в буфер обмена!',
      );
    }
  };

  const initialValues: EditUserInput = {
    fullName: userData?.fullName || '',
    image: '',
    theNote: userData?.theNote || '',
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className='profile__modal-wrapper'>
        <div className='profile__modal'>
          <Formik initialValues={initialValues} onSubmit={() => {}}>
            {({ values }) => (
              <>
                <div className='user__head'>
                  <div className='avatar__image-upload'>
                    <label htmlFor='admin-edit-image'>
                      <div>
                        <RequestHandler loading={load}>
                          <div className='user__head-avatar'>
                            <img src={getUserAvatar(userData?.image)} alt='avatar' />
                            <img
                              src={uploadAvatar}
                              alt='avatar'
                              className='avatar__upload'
                            />
                          </div>
                        </RequestHandler>
                      </div>
                    </label>
                    <input
                      id='admin-edit-image'
                      name='image'
                      type='file'
                      onChange={(e) => {
                        handleAvatarSubmits(e?.currentTarget?.files?.[0]);
                      }}
                    />
                  </div>
                  <div className={'user__info'}>
                    <Field
                      autoComplete='off'
                      name='fullName'
                      placeholder={'Нет имени'}
                      className={'user__info-name'}
                      onBlur={() =>
                        userData?.fullName !== values?.fullName &&
                        handleFullNameSubmit(values?.fullName)
                      }
                    />
                    <span>{userData?.login}</span>
                  </div>
                </div>
                <div className='user__note'>
                  <Field
                    autoComplete='off'
                    name={'theNote'}
                    innerRef={inputElement}
                    placeholder={'Измените заметку'}
                    onBlur={() => handleTheNoteSubmit(values?.theNote)}
                    disabled={!theNoteEditMode}
                  />
                </div>
              </>
            )}
          </Formik>
          <div className='user__actions'>
            <span
              onClick={async () => {
                await setTheNoteEditMode(true);
                inputElement?.current?.focus();
              }}
            >
              Изменить заметку
            </span>
            {currentUserData?.role == 'Admin' && browser === 'safari' && (
              <span onPointerUp={() => handleResetPassword({ safari: true })}>
                Сбросить пароль
              </span>
            )}
            {currentUserData?.role == 'Admin' && browser !== 'safari' && (
              <span onClick={() => handleResetPassword({})}>Сбросить пароль</span>
            )}
            <span
              className='user__actions--remove'
              onClick={() => setIsOpenConfirmModal(true)}
            >
              Удалить пользователя
            </span>
          </div>
          <div className='user__status'>
            <Formik initialValues={{ role: userData?.role }} onSubmit={roleSubmit}>
              {({ values }) => (
                <>
                  <UserStatusSelect />
                  {values?.role !== userData?.role ? (
                    <Button
                      onClick={() => roleSubmit(values?.role)}
                      type='submit'
                      height='25px'
                      backgroundColor='#f5f5f5'
                      width='181px'
                      text='Сохранить роль'
                    />
                  ) : null}
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <ConfirmModal
        isOpen={isOpenConfirmModal}
        userName={userData.login}
        callback={() => handleRemoveUser(userData.id)}
        onClose={() => setIsOpenConfirmModal(false)}
      />
    </Modal>
  );
};
