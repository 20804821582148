import './CustomTooltip.scss';

import moment from 'moment';
import React, { useMemo } from 'react';

import { textSlice } from '../../../utils';
import { DateIcons, FileTskIcon } from '../../icons';
import {
  AllTaskType,
  ConvertProjectType,
  CustomTooltipType,
  projectType,
} from '../fileTypes';

export const CustomTooltip = ({
  label,
  taskList,
  hoverValue,
  isGraphicsI,
}: CustomTooltipType) => {
  const filterTask = taskList?.filter((e) => {
    if (e?.date === label && e?.statusFull === hoverValue) return e;
  });
  const convert = useMemo(() => {
    return Convert(filterTask);
  }, [filterTask]);

  const calcData = () => {
    if (convert[0]?.projectId || isGraphicsI) {
      const color = convert[0]?.file[0]?.task[0]?.status?.color;
      const colorText = color === '#E80025' ? 'white' : '#303030';
      const type = convert[0]?.file[0]?.task[0]?.type;
      const statusName = convert[0]?.file[0]?.task[0]?.status?.name;
      return { convert, color, colorText, type, statusName };
    } else {
      const color = '#8B00FF';
      const colorText = 'white';
      const type = convert[0]?.file[0]?.task[0]?.type;
      const statusName = 'Project I';
      return { convert, color, colorText, type, statusName };
    }
  };
  const { color, colorText, type, statusName } = calcData();

  const statusNameCalc = () => {
    if (statusName === 'Completed') return 'Выполнено';
    if (statusName === 'Not In Progress') return 'Ожидает исполнения';
    if (statusName === 'In Progress') return 'Начато исполнение';
    if (statusName === 'Problem') return ' Требует срочного выполнения';
    if (statusName === 'Project I') return 'Проект Я';

    return 'В работе';
  };

  if (convert?.length === 0) return <></>;

  return (
    <div className='customTooltip' style={{ borderLeft: `4px solid ${color}` }}>
      <div className='customTooltip__date'>
        <DateIcons className='mr10 mb2' /> {label}
      </div>
      <div
        className='customTooltip__inWork'
        style={{ backgroundColor: color, color: colorText }}
      >
        <div>{statusNameCalc()}</div>
        <div className='customTooltip__inWork-count'>
          {type === 'TaskUpdated' ? 'обновлено' : 'создано'}: {filterTask?.length}
        </div>
      </div>
      {convert?.map((project, index) => {
        return (
          <div
            className='customTooltip__item'
            key={project?.projectId ? project?.projectId : index}
          >
            {project?.name && (
              <div className='customTooltip__item-title'>
                {textSlice({ text: project?.name, count: 60 })}
              </div>
            )}

            {project?.file?.map((file) => {
              return (
                <div key={file?.scaleMapFileId}>
                  <div className='customTooltip__item-task'>
                    <div className='customTooltip__item-name'>
                      <FileTskIcon className='mr5' />
                      {textSlice({ text: file?.name, count: 60 })}
                    </div>
                    <div>{file?.task?.length}</div>
                  </div>
                  {file?.task?.map((e) => {
                    return (
                      <div key={e?.id} className='customTooltip__scaleTask'>
                        <div
                          style={{
                            backgroundColor: e?.status?.color,
                          }}
                          className='customTooltip__scaleTask-item'
                        ></div>
                        {textSlice({ text: e?.name, count: 30 })}

                        <span className='customTooltip__scaleTask-date'>
                          {DaysBetween({
                            create: e?.createdAt,
                            update: e?.statusUpdatedDate
                              ? e?.statusUpdatedDate
                              : e?.updatedAt,
                          })}{' '}
                          д
                        </span>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

function Convert(data: AllTaskType) {
  const convertProject: ConvertProjectType = [];
  const projectIds = Array.from(new Set(data.map((x) => x?.projectId)));

  projectIds.forEach((projectId) => {
    const projectTasks = data.filter((x) => x?.projectId === projectId);
    const scaleMapFileIds = Array.from(
      new Set(projectTasks.map((x) => x?.scaleMapFileId)),
    );

    const project: projectType = {
      projectId: projectId,
      name: projectTasks[0]?.projectName,
      file: [],
    };

    scaleMapFileIds.forEach((scaleMapFileId) => {
      const fileTasks = projectTasks.filter((x) => x?.scaleMapFileId === scaleMapFileId);
      const file = {
        scaleMapFileId: scaleMapFileId,
        name: fileTasks[0]?.scaleMapFileName,
        task: fileTasks,
      };

      project.file.push(file);
    });
    convertProject.push(project);
  });

  return convertProject;
}

const DaysBetween = ({
  create,
  update,
}: {
  create: string | undefined;
  update: string | undefined;
}) => {
  if (!create || !update) return '';
  const dateCreate = moment(parseInt(create));
  const dateUpdate = moment(parseInt(update));

  const diffInDays = dateCreate.diff(dateUpdate, 'days');
  return Math.abs(diffInDays);
};
