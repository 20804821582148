import React from 'react';

import { Pagination } from '../../index';
import { UserStatisticBlockItem } from '../userStatisticBlockItem/UserStatisticBlockItem';

export const UserStatisticList = ({
  listChar,
  userData,
  handleRefetch,
  setCurrentPage,
  setDisablePagination,
  currentPage,
  countChar,
  taskPaginationLimit,
  tasksSiblingPaginationCount,
}: any) => {
  return (
    <div>
      <div className='userStatisticBlock__items'>
        {listChar?.length > 0 ? (
          listChar?.map((characteristic: any) => {
            return (
              <UserStatisticBlockItem
                characteristic={characteristic}
                key={characteristic.id}
                userId={userData.id}
                handleRefetch={handleRefetch}
              />
            );
          })
        ) : (
          <div className='userStatisticBlock__missingProjects'>Заметки не найдены</div>
        )}
        <Pagination
          paginate={(value) => {
            setCurrentPage(value);
            setDisablePagination(false);
          }}
          currentPage={currentPage}
          totalCount={countChar}
          pageSize={taskPaginationLimit}
          siblingCount={tasksSiblingPaginationCount}
        />
        <div className='userStatisticBlock__line' />
      </div>
    </div>
  );
};
