import React from 'react';

export const PlusIcon2 = ({ onClick, className }: any) => {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      className={className}
    >
      <path
        d='M10.5099 9.32951V9.82951H11.0099H17.1652C17.2628 9.82951 17.3338 9.90758 17.3321 9.99386L17.332 9.99386V10.0037C17.332 10.0993 17.2537 10.1778 17.1579 10.1778H11.0062H10.5062V10.6778V16.8258C10.5062 16.9215 10.4278 17 10.332 17C10.2364 17 10.1579 16.9216 10.1579 16.8258V10.6742V10.1742H9.65788H3.50619C3.41051 10.1742 3.33203 10.0958 3.33203 10C3.33203 9.95342 3.34872 9.91196 3.38343 9.87725L3.38347 9.87728L3.38852 9.87209C3.41457 9.84527 3.45537 9.82584 3.50619 9.82584H9.66154H10.1615V9.32584V3.17416C10.1615 3.07848 10.2399 3 10.3357 3C10.4315 3 10.5099 3.07847 10.5099 3.17416V9.32951Z'
        fill='#AFAFAF'
        stroke='#AFAFAF'
      />
    </svg>
  );
};
