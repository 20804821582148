import React from 'react';

type StorageFileWordType = {
  color?: string;
  className?: string;
};

export const StorageFileWord = ({ color = 'black', className }: StorageFileWordType) => {
  return (
    <svg
      width='16'
      height='20'
      viewBox='0 0 16 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M10 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H14C14.5304 20 15.0391 19.7893 15.4142 19.4142C15.7893 19.0391 16 18.5304 16 18V6L10 0ZM11.2 18H9.8L8 11.2L6.2 18H4.8L2.6 9H4.1L5.5 15.8L7.3 9H8.6L10.4 15.8L11.8 9H13.3L11.2 18ZM9 7V1.5L14.5 7H9Z'
        fill={color}
      />
    </svg>
  );
};
