import '../../components/masonryWrapper/MasonreyWrapper.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AddProject,
  MasonryWrapper,
  ProjectBlock,
  RequestHandler,
} from '../../components';
import { ROLES, taskPaginationLimit } from '../../constants/constants';
import { useAuthContext } from '../../context';
import {
  Project,
  SortingField,
  SortingOrders,
  useGetProjectsQuery,
} from '../../graphql/generated/graphql';

export const Projects = () => {
  const [data, setData] = useState<any>([]);
  const [offset, setOffset] = useState(0);
  const [stopPagination, setStopPagination] = useState(false);

  const { currentUserData } = useAuthContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (currentUserData?.role !== ROLES.ADMIN) {
      navigate('/');
    }
  }, []);

  const { loading, refetch } = useGetProjectsQuery({
    variables: {
      data: {
        limit: 100,
        offset: offset,
        sort: { field: SortingField.Number, type: SortingOrders.Asc },
      },
      tasksData: {
        limit: taskPaginationLimit,
        isTaskStorage: false,
      },
    },
    onCompleted: (fetchMoreResult) => {
      setData((prev: any) => {
        if (!prev) return fetchMoreResult?.getProjects?.rows;
        const concatenatedArray = [
          ...(prev as any),
          ...(fetchMoreResult?.getProjects?.rows as any),
        ];
        // filter dublicate id and use last update at item
        const uniqueArray = Array.from(
          new Set(concatenatedArray?.map((item) => item.id)),
        ).map((id) => {
          const itemsWithId = concatenatedArray?.filter((item) => item?.id === id);
          const newestItem = itemsWithId?.reduce((prev, current) =>
            prev?.updatedAt > current?.updatedAt ? prev : current,
          );
          return newestItem;
        });
        setStopPagination(fetchMoreResult?.getProjects?.rows?.length === 100);
        return uniqueArray;
      });
    },
  });
  const handleScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && stopPagination) {
      setOffset(data.length);
    }
  };

  const handleDelete = (itemId: number) => {
    setData((prev: any) => {
      const updatedData = prev.filter((item: any) => item.id !== itemId);
      return updatedData;
    });
  };

  return (
    <div className='container' onScrollCapture={handleScroll}>
      <RequestHandler loading={loading && data?.length === 0}>
        <MasonryWrapper>
          {data?.length ? (
            data?.map((project: any, index: number) => {
              return (
                <ProjectBlock
                  key={index}
                  projectData={project as Project}
                  handleDelete={handleDelete}
                  refetch={refetch}
                />
              );
            })
          ) : (
            <p className='empty-projects'>Вы не участвуете ни в одном проекте</p>
          )}
          <div className='projectBlock'>
            <AddProject refetch={refetch} />
          </div>
        </MasonryWrapper>
      </RequestHandler>
    </div>
  );
};
