import './StorageItem.scss';

import React from 'react';
import { Link } from 'react-router-dom';

import locImg from '../../../assets/locImg.png';
import noImg from '../../../assets/noImg.jpg';
import { BASE_URL } from '../../../constants/constants';
import { useTrackStorageEventClickedMutation } from '../../../graphql/generated/graphql';
import { FileTypeStorage } from '../../../types/enums';
import { textSlice } from '../../../utils';
import {
  FigmaIcons,
  StorageExelWord,
  StorageFileWord,
  StorageImgWord,
  StoragePdfWord,
} from '../../icons';
import { ShowImage } from '../storageItemTred/StorageItemTred';

export const StorageItem = ({ file }: { file: any }) => {
  const [trackStorageEventClickedMutation] = useTrackStorageEventClickedMutation({});
  const allImg =
    file.mimeType === FileTypeStorage.jpeg ||
    file.mimeType === FileTypeStorage.gif ||
    file.mimeType === FileTypeStorage.png ||
    file.mimeType === FileTypeStorage.svg ||
    file.mimeType === FileTypeStorage.webp ||
    file.mimeType === FileTypeStorage.oimage ||
    file.mimeType === FileTypeStorage.sharingFileImage;

  const createImgUrl = () => {
    if (file?.isAccess === false) {
      return locImg;
    }
    if (file.previewUrl) {
      return BASE_URL + file.previewUrl;
    }
    if (file?.thumbnailPath) {
      return BASE_URL + file.thumbnailPath;
    }
    if (allImg && file.path) {
      return BASE_URL + file.path;
    }

    return noImg;
  };
  const createUrlFile = () => {
    if (file.href) return file.href;
    return BASE_URL + file.path;
  };

  const crateLinkTask = () => {
    if (file.subtask?.id) {
      return `/task/${file?.subtask?.task?.id}/subtask/${file?.subtask?.id}`;
    }
    return `/task/${file?.task?.id}`;
  };
  return (
    <div
      className='storage-item'
      onClick={() => {
        trackStorageEventClickedMutation({
          variables: {
            fileId: file?.__typename === 'File' ? file?.id : undefined,
            storageItemId: file?.__typename === 'StorageItem' ? file?.id : undefined,
          },
        });
      }}
    >
      <a
        href={createUrlFile() as string}
        target='_blank'
        rel='noreferrer'
        className='storage-item-imageLink'
      >
        {file.mimeType === 'figma' ? (
          <FigmaIcons width='248' height='212' />
        ) : (
          <img src={createImgUrl()} alt='avatar' className='storage-item__image' />
        )}
        <div className='storage-item__text-wrapper'>
          <div className='storage-item__file'>
            <ShowImage />
            {(file.mimeType === FileTypeStorage.doc ||
              file.mimeType === FileTypeStorage.docx) && (
              <StorageFileWord color='#4E89E1' />
            )}
            {(file.mimeType === FileTypeStorage.xls ||
              file.mimeType === FileTypeStorage.xlsx) && (
              <StorageExelWord color='#149939' />
            )}
            {file.mimeType === FileTypeStorage.pdf && <StoragePdfWord color='#E80025' />}
            {allImg && <StorageImgWord color='#E5580A' />}
            <span>
              {textSlice({ text: file?.name ? file?.name : file?.fileName, count: 25 })}
            </span>
          </div>
        </div>
      </a>
      <Link to={crateLinkTask()}>
        <div className='storage-item__text-wrapper'>
          <div className='storage-item__task-name'>
            {file?.task?.name
              ? textSlice({
                  text:
                    `#${file?.task?.number ? file?.task?.code : file?.subtask?.code} ` +
                    file?.task?.name,
                  count: 55,
                })
              : textSlice({
                  text:
                    `#${file?.task?.number ? file?.task?.code : file?.subtask?.code} ` +
                    file?.subtask?.name,
                  count: 55,
                })}
          </div>
        </div>
      </Link>
    </div>
  );
};
