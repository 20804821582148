import './TreadItemNotes.scss';

import { Field, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import googleDrive from '../../../assets/free-icon-google-drive-logo-60958.png';
import { useGetTredsNotesLazyQuery } from '../../../graphql/generated/graphql';
import { useCreateNoteMutation } from '../../../hooks/useMutations';
import { Button, RequestHandler } from '../..';
import { NotesItem } from '../../notesItem/NotesItem';

export const TreadItemNotes: React.FC<NotesPropsType> = () => {
  const [createNote] = useCreateNoteMutation();

  const { treadId } = useParams();

  const [
    getTredNotes,
    { data: treadNotes, refetch: treadNotesRefetch, loading: treadNotesLoading },
  ] = useGetTredsNotesLazyQuery();

  useEffect(() => {
    getTredNotes({
      variables: {
        id: Number(treadId),
      },
    });
  }, [treadId]);

  const prevNotes = treadNotes?.getThread.notes;

  const handleSubmit = async (values: initialStateType, { setFieldValue }: any) => {
    if (values.notes !== null && values.notes !== undefined && values.notes !== '') {
      await createNote({
        variables: {
          data: {
            content: values.notes.toString().trim(),
            threadId: Number(treadId),
          },
        },

        onCompleted: (data?) => {
          if (data?.createNote?.id) {
            toast.success('Заметка успешно сохранена!');
            setFieldValue('notes', '');
            treadNotesRefetch();
          }
        },
      });
    } else {
      toast.error('Введите текст заметки!');
    }
  };

  const initialState = {
    notes: '',
  };

  return (
    <Formik initialValues={initialState} onSubmit={handleSubmit}>
      {({ handleSubmit }) => (
        <div className='TreadItemNotes'>
          <div className='TreadItemNotes__header'>
            <p>Заметки</p>
            <a
              href='https://drive.google.com/drive/u/0/my-drive'
              target='_blank'
              rel='noreferrer'
            >
              <img
                src={googleDrive}
                alt=''
                style={{ width: '20px', cursor: 'pointer' }}
              />
            </a>
            <Button type='submit' onClick={handleSubmit} text='Сохранить' />
          </div>
          <div className='TreadItemNotes__text'>
            <Field as='textarea' placeholder='Введите текст заметки' name='notes' />

            <RequestHandler loading={treadNotesLoading}>
              {prevNotes?.map((prevNote) => {
                if (prevNote) {
                  return (
                    <NotesItem
                      key={prevNote.id}
                      note={prevNote}
                      refetch={treadNotesRefetch}
                    />
                  );
                }
              })}
            </RequestHandler>
          </div>
        </div>
      )}
    </Formik>
  );
};

type NotesPropsType = {
  backgroundColor: string;
  executorsHeight?: number;
  maintainer?: {
    id?: number | null | undefined;
    login?: string | null | undefined;
    image?: string | null | undefined;
  } | null;
};

type initialStateType = {
  notes?: string | null | undefined;
};
