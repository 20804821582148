import './UserSearch.scss';

import React, { ChangeEvent, useState } from 'react';

type userSearchProps = {
  handleSearch: (value: string) => void;
};

export const UserSearch = ({ handleSearch }: userSearchProps) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const handleChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    handleSearch(e.target.value);
    setSearchValue(e.target.value);
  };

  return (
    <div className='user-search'>
      <label>
        <svg
          className='user-search__icon'
          width='12'
          height='12'
          viewBox='0 0 11 11'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M10.6487 10.0193L8.03072 7.29639C8.70386 6.49619 9.07268 5.48938 9.07268 4.44125C9.07268 1.99238 7.0803 0 4.63143 0C2.18257 0 0.190186 1.99238 0.190186 4.44125C0.190186 6.89011 2.18257 8.88249 4.63143 8.88249C5.55077 8.88249 6.42685 8.6052 7.17588 8.07882L9.81378 10.8224C9.92404 10.9369 10.0723 11 10.2313 11C10.3817 11 10.5244 10.9426 10.6327 10.8384C10.8629 10.6169 10.8702 10.2496 10.6487 10.0193ZM4.63143 1.15859C6.44153 1.15859 7.91409 2.63115 7.91409 4.44125C7.91409 6.25134 6.44153 7.72391 4.63143 7.72391C2.82133 7.72391 1.34877 6.25134 1.34877 4.44125C1.34877 2.63115 2.82133 1.15859 4.63143 1.15859Z' />
        </svg>

        <input
          className='user-search__input'
          type='text'
          value={searchValue}
          onChange={handleChangeSearchValue}
          placeholder='Поиск пользователя'
        />
      </label>
    </div>
  );
};
