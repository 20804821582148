import './TredFonsModal.scss';

import React, { useState } from 'react';
import { ChromePicker } from 'react-color';

import {
  useCreateThreadBackgroundMutation,
  useRemoveThreadBackgroundMutation,
  useThreadBackgroundsQuery,
} from '../../../../graphql/generated/graphql';
import { Button } from '../../../button/Button';
import { Plus2Icons, RemoveIconBlack } from '../../../icons';

export const TredFonsModal = ({ onClose }: any) => {
  const { data, refetch } = useThreadBackgroundsQuery({
    fetchPolicy: 'network-only',
  });

  const colors = data?.threadBackgrounds;

  const [createThreadBackgroundMutation] = useCreateThreadBackgroundMutation({});

  const [removeThreadBackgroundMutation] = useRemoveThreadBackgroundMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const [color, setColor] = useState('#EAEAEA');
  const [openColor, setOpenColor] = useState(false);
  const [newColorList, setNewColorList] = useState<Array<string | null>>([]);
  return (
    <div className='TredFonsModal'>
      <div className='TredFonsModal__tite1'>Настройка фона</div>
      <div className='TredFonsModal__tite2'>
        <div>Цвет фона</div>
        <Plus2Icons
          onClick={() => {
            setOpenColor(!openColor);
          }}
        />
      </div>
      <div className='TredFonsModal__colorPanel-wraper'>
        {openColor && (
          <div className='TredFonsModal__colorPanel'>
            <ChromePicker
              disableAlpha
              color={color}
              onChangeComplete={(paletteColor) => {
                setColor(paletteColor.hex);
              }}
              onChange={(paletteColor) => {
                setColor(paletteColor.hex);
              }}
            />
            <Button
              text='Добавить цвет'
              margin='10px 0 0 0'
              onClick={() => {
                setNewColorList((e: any) => {
                  return [...e, color];
                });
                setOpenColor(false);
              }}
            />
          </div>
        )}
      </div>
      <div className='TredFonsModal__list'>
        {colors?.map((e) => {
          return (
            <ItemColor
              color={e.backgroundColor}
              key={e.id}
              select={false}
              remove={() => {
                removeThreadBackgroundMutation({
                  variables: {
                    id: e.id,
                  },
                });
              }}
            />
          );
        })}
        {newColorList?.map((e) => {
          if (!e) return;
          return (
            <ItemColor
              color={e}
              key={e}
              select={false}
              remove={() => {
                setNewColorList(newColorList?.filter((item) => item !== e));
              }}
            />
          );
        })}
      </div>
      <Button
        type='submit'
        text='Сохранить'
        width='100%'
        height='25px'
        backgroundColor='#149939'
        color='white'
        onClick={() => {
          if (newColorList[0]) {
            createThreadBackgroundMutation({
              variables: {
                createThreadBackgroundInputs: newColorList?.map((e) => {
                  return {
                    backgroundColor: e,
                  };
                }) as any,
              },
            });
          }
          onClose();
        }}
      />
    </div>
  );
};

export const ItemColor = ({
  color,
  select,
  setSelectColor,
  id,
  remove,
}: {
  color: string;
  select: boolean;
  setSelectColor?: any;
  id?: number;
  remove?: () => void;
}) => {
  return (
    <div className='TredFonsModal__list-wrapper-outer'>
      <div
        className='TredFonsModal__list-wrapper'
        style={{
          border: select ? `1px solid ${color}` : '',
        }}
        onClick={() => {
          setSelectColor && setSelectColor(id);
        }}
      >
        <div
          className='TredFonsModal__list-inner'
          style={{
            backgroundColor: color,
          }}
        ></div>
      </div>
      <div className='TredFonsModal__list-text'>{color}</div>{' '}
      {remove && (
        <RemoveIconBlack
          className={`TredFonsModal__list-icon`}
          color='red'
          onClick={() => {
            remove();
          }}
        />
      )}
    </div>
  );
};
