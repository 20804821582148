import { taskPaginationLimit } from '../../constants/constants';
import * as GENERATED from '../../graphql/generated/graphql';

export const useGetProjectQuery = (projectId: number) => {
  return GENERATED.useGetProjectLazyQuery({
    variables: {
      id: projectId,
      tasksData: {
        limit: taskPaginationLimit,
        isTaskStorage: false,
      },
    },
  });
};
