import './DecideOnTheFlyLogsHed.scss';

import moment from 'moment';
import React, { useRef, useState } from 'react';

import { useClickOutside } from '../../../../../hooks';
import { DatePickerC } from '../../reportChronographModal/DatePickerC';

export const DecideOnTheFlyLogsHed = ({
  onClose,
  history,
  endDate,
  setEndDate,
  startDate,
  setStartDate,
  historyRows,
}: any) => {
  const [selectId, setSelectId] = useState('DatePicker');

  const [popup, setPopup] = useState(false);
  const ref = useRef<any>(null);

  const historyData = history?.getUserDecideOnFlyActionsHistory;

  useClickOutside(ref, () => setPopup(false));

  return (
    <div className='DecideOnTheFlyLogs__head'>
      <div className='DecideOnTheFlyLogs__title'>Мои логи</div>
      <div className='DecideOnTheFlyLogs__statistic'>
        <div>
          Пинов выпало:
          <span className='DecideOnTheFlyLogs__statistic-count'>
            {historyData?.count}
          </span>
        </div>
        <div>
          Пинов выполнено:
          <span className='DecideOnTheFlyLogs__statistic-count'>
            {historyData?.doneCount}
          </span>
        </div>
        <div>
          Пинов невыполнено:
          <span className='DecideOnTheFlyLogs__statistic-count'>
            {historyData?.notDoneCount}
          </span>
        </div>
      </div>
      <div className='DecideOnTheFlyLogs__right'>
        <div className='DecideOnTheFlyLogs__right-calendar' ref={ref}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            onClick={() => {
              setPopup(!popup);
            }}
          >
            <path
              d='M17.5 16.6667V5.00008C17.5 4.08091 16.7525 3.33341 15.8333 3.33341H14.1667V1.66675H12.5V3.33341H7.5V1.66675H5.83333V3.33341H4.16667C3.2475 3.33341 2.5 4.08091 2.5 5.00008V16.6667C2.5 17.5859 3.2475 18.3334 4.16667 18.3334H15.8333C16.7525 18.3334 17.5 17.5859 17.5 16.6667ZM7.5 15.0001H5.83333V13.3334H7.5V15.0001ZM7.5 11.6667H5.83333V10.0001H7.5V11.6667ZM10.8333 15.0001H9.16667V13.3334H10.8333V15.0001ZM10.8333 11.6667H9.16667V10.0001H10.8333V11.6667ZM14.1667 15.0001H12.5V13.3334H14.1667V15.0001ZM14.1667 11.6667H12.5V10.0001H14.1667V11.6667ZM15.8333 7.50008H4.16667V5.83342H15.8333V7.50008Z'
              fill='#0D0D0D'
            />
          </svg>
          {popup && (
            <div
              style={{
                position: 'absolute',
                top: '35px',
                right: '0px',
              }}
            >
              <div
                className='ChronometerReport__pickers'
                style={{
                  width: '360px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <DatePickerC
                  id={'DatePicker'}
                  selectId={selectId}
                  setSelectId={setSelectId}
                  date={startDate}
                  setDate={setStartDate}
                />
                <DatePickerC
                  id={'DatePicker2'}
                  selectId={selectId}
                  setSelectId={setSelectId}
                  date={endDate}
                  setDate={setEndDate}
                />
              </div>
            </div>
          )}
        </div>
        <div
          className='DecideOnTheFlyLogs__download'
          onClick={() => {
            createTxtFile(
              historyRows,
              `from ${moment(startDate).format('YYYY-MM-DD')} to ${moment(endDate).format(
                'YYYY-MM-DD',
              )}`,
            );
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            style={{
              paddingRight: '5px',
            }}
          >
            <path
              d='M7.5 2V10.2815L4.8595 7.64L4.14 8.36L7.64 11.86L7.9995 12.2035L8.359 11.86L11.859 8.36L11.14 7.641L8.5 10.281V2H7.5ZM3.5 13V14H12.5V13H3.5Z'
              fill='white'
            />
          </svg>
          скачать txt
        </div>

        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          style={{
            marginLeft: '15px',
          }}
          onClick={() => {
            onClose();
          }}
        >
          <path
            d='M8.91684 7.64879L8.56328 8.00235L8.91685 8.3559L14.5795 14.0182L14.5796 14.0184C14.736 14.1746 14.736 14.4281 14.5796 14.5843L14.5795 14.5845C14.501 14.6629 14.3993 14.7019 14.2963 14.7019C14.1934 14.7019 14.0916 14.6629 14.0132 14.5845L8.35058 8.92186L7.99703 8.56831L7.64348 8.92185L1.98056 14.5845L1.98055 14.5845C1.90211 14.6629 1.80037 14.7019 1.69742 14.7019C1.59448 14.7019 1.49265 14.6629 1.41414 14.5846C1.25779 14.4284 1.25779 14.1749 1.41414 14.0187L1.41428 14.0185L7.0772 8.3559L7.43078 8.00234L7.0772 7.64878L1.41428 1.98616L1.41414 1.98601C1.25779 1.8298 1.25779 1.57627 1.41414 1.42005L1.41429 1.4199C1.57084 1.26335 1.824 1.26335 1.98055 1.4199L1.98056 1.41991L7.64348 7.08253L7.99703 7.43607L8.35058 7.08252L14.0132 1.4199C14.1697 1.26335 14.4229 1.26335 14.5795 1.4199L14.5796 1.42005C14.736 1.57627 14.736 1.8298 14.5796 1.98601L14.5795 1.98617L8.91684 7.64879Z'
            fill='#303030'
            stroke='#303030'
          />
        </svg>
      </div>
    </div>
  );
};
function createTxtFile(dataLogs: any, fileName: string) {
  let formattedText = '';

  dataLogs?.map((e: any) => {
    const formattedDate = moment(e?.startDate).format('DD-MM-YYYY [в] HH:mm');

    const getStatus = () => {
      if (moment(e?.endDate).isAfter(moment())) {
        return 'Ожидание';
      } else {
        if (e?.status === 'not_done') {
          return 'Не выполнил';
        } else {
          return 'Выполнил';
        }
      }
    };
    formattedText =
      formattedText +
      `${formattedDate} ${e?.decideOnFlyAction?.name} ${getStatus()} ${
        e?.reasonForNotDone
      }\n`;
  });
  const element = document.createElement('a');
  const file = new Blob([formattedText], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
