import * as GENERATED from '../../graphql/generated/graphql';

export const useGetArchivedProjectsQuery = () => {
  return GENERATED.useGetArchivedProjectsQuery({
    variables: {
      data: {
        limit: 100,
      },
    },
  });
};
