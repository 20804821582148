import './ExpensesAccordionHeader.scss';

import React, { FC } from 'react';

import { ExpensesAccordionHeaderType } from '../../../constants/constants';

export const ExpensesAccordionHeader: FC<{
  items: ExpensesAccordionHeaderType;
}> = ({ items }) => (
  <div className='expensesAccordionHeader'>
    {items.map((item) => (
      <div
        key={`${item.name}${item.class}`}
        className={`expensesAccordionHeader__column ${item.class}`}
      >
        {item.name}
      </div>
    ))}
  </div>
);
