import React from 'react';

export const TredLinkIcon = ({ className, onClick, color = '#303030' }: any) => {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M7.12836 11.29L6.80923 11.1076L6.53996 11.3578C6.0981 11.7683 5.51778 11.9973 4.91464 11.9991C4.49559 11.9998 4.08355 11.8915 3.71899 11.6848C3.35427 11.478 3.04961 11.1799 2.83494 10.8198C2.62028 10.4597 2.503 10.0499 2.49462 9.63073C2.48625 9.21156 2.58706 8.79742 2.78717 8.429C2.98728 8.06059 3.27979 7.75056 3.63596 7.5294C3.99213 7.30823 4.39972 7.18353 4.81867 7.16754C5.23762 7.15156 5.65352 7.24484 6.02551 7.43823C6.3975 7.63161 6.71279 7.91845 6.94038 8.27055L7.19732 8.66804L7.60831 8.43327L12.345 5.72744L12.6791 5.53655L12.5801 5.16467C12.5295 4.97426 12.5024 4.77694 12.4995 4.57828C12.4984 4.02678 12.6867 3.4916 13.0329 3.06225C13.3797 2.63222 13.8638 2.33454 14.404 2.21914C14.9443 2.10373 15.5078 2.17764 16 2.42844C16.4922 2.67925 16.8832 3.09171 17.1073 3.59661C17.3315 4.10152 17.3752 4.66816 17.2311 5.20147C17.087 5.73478 16.7639 6.20231 16.316 6.52562C15.868 6.84894 15.3225 7.00838 14.771 6.97718C14.2194 6.94598 13.6954 6.72605 13.2868 6.35428L13.0178 6.1096L12.7022 6.29002L7.57719 9.21918L7.33197 9.35933L7.32543 9.6417C7.32132 9.81924 7.29662 9.99608 7.25186 10.1657L7.15385 10.5369L7.48723 10.7274L12.7039 13.7082L13.0215 13.8897L13.2906 13.642C13.7209 13.2459 14.2794 13.018 14.864 13C15.4486 12.982 16.02 13.1752 16.4738 13.5442C16.9276 13.9131 17.2333 14.4332 17.335 15.0091C17.4367 15.585 17.3275 16.1783 17.0275 16.6804C16.7275 17.1824 16.2568 17.5596 15.7014 17.7429C15.146 17.9262 14.5432 17.9034 14.0033 17.6785C13.4634 17.4537 13.0225 17.042 12.7614 16.5186C12.5003 15.9953 12.4364 15.3955 12.5814 14.8289L12.6758 14.4598L12.345 14.2708L7.12836 11.29Z'
        fill={color}
        stroke={color}
      />
    </svg>
  );
};
