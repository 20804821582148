import { useNavigate } from 'react-router-dom';

import { useAuthContext } from '../../context';
import * as GENERATED from '../../graphql/generated/graphql';

export const useSignInLazyQuery = ({ ...options }) => {
  const navigate = useNavigate();
  const { onSuccessAuth } = useAuthContext();

  return GENERATED.useLoginUserLazyQuery({
    fetchPolicy: 'network-only',
    ...options,
    onCompleted: (response) => {
      if (response?.loginUser?.token) {
        onSuccessAuth(response?.loginUser?.token);
        navigate('/');
      }
    },
  });
};
