import './AddPolygonBlockModal.scss';

import { Form, Formik, FormikValues } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import { useLocation } from 'react-router-dom';

import { BASE_URL } from '../../../../constants/constants';
import { useLoadFileMutation } from '../../../../graphql/generated/graphql';
import { ModalType } from '../../../../types/types';
import { fileSettings } from '../../../../utils';
import { addPolygonObjectSchema, addPolygonSchema } from '../../../../validation/schemas';
import { AppInput } from '../../../appInput/AppInput';
import { Button } from '../../../button/Button';
import { CrossIcon } from '../../../icons';
import { Modal } from '../../Modal';

export const AddPolygonBlockModal: FC<
  Pick<
    ModalType,
    | 'isOpen'
    | 'onSubmit'
    | 'onClose'
    | 'labelText'
    | 'buttonText'
    | 'colors'
    | 'initialColorId'
    | 'onSubmitPaletteColors'
    | 'polygonId'
  >
> = ({ onSubmit, onClose, labelText, buttonText, colors }) => {
  const handleSubmit = (values: FormikValues) => {
    onSubmit(values);
    onClose();
  };

  const location = useLocation();

  const isStatisticPage = location.pathname
    ?.toLocaleLowerCase()
    ?.includes('/polygonstatistic');

  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const [pickerColor, setPickerColor] = useState('');

  const { handleEnter } = useEnterColor({ setPickerColor, setIsPickerOpen });

  const [loadFileMutation] = useLoadFileMutation();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        polygonBlockName: '',
        blockColorsName: '',
        file: null,
        height: '',
        window: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={isStatisticPage ? addPolygonSchema : addPolygonObjectSchema}
    >
      {({ setFieldValue, values }) => {
        return (
          <Form
            className='add-polygon-block-modal'
            style={{ width: 'auto', maxWidth: 'none' }}
          >
            <div className='add-polygon-block-modal__colors-container'>
              <div className='add-polygon-block-modal__colors-items'>
                {colors?.map((color) => {
                  if (color.color)
                    return (
                      <div
                        key={color.id}
                        className='add-polygon-block-modal__colors-item'
                        style={{
                          backgroundColor: color.color || '#AFAFAF',
                          border: `1px solid #AFAFAF`,
                        }}
                        onClick={() => {
                          setPickerColor(color.color);
                          setFieldValue('blockColorsName', color.color);
                        }}
                      />
                    );
                })}
              </div>
              <Modal isOpen={isPickerOpen} onClose={() => setIsPickerOpen(false)}>
                <div className='add-polygon-block-modal__colors-picker'>
                  <ChromePicker
                    disableAlpha
                    color={pickerColor}
                    onChangeComplete={(color) => {
                      setPickerColor(color.hex);
                      setFieldValue('blockColorsName', color.hex);
                    }}
                    onChange={(paletteColor) => {
                      handleEnter({ hex: paletteColor.hex, name: paletteColor.hex });
                    }}
                  />
                  <Button
                    text='Сохранить цвет'
                    margin='10px 0 0 0'
                    onClick={() => setIsPickerOpen(false)}
                  />
                </div>
              </Modal>
              <div className='add-polygon-block-modal__title'>
                <div className='add-polygon-block-modal__colors'>
                  <span>Цвет:</span>
                  <div
                    className='add-polygon-block-modal__colors-picker-item'
                    style={{
                      backgroundColor: pickerColor || '#AFAFAF',
                    }}
                    onClick={() => setIsPickerOpen(true)}
                  />
                </div>
              </div>
            </div>
            <div className='add-polygon-block-modal__input'>
              <span className='add-polygon-block-modal__label'>{labelText}</span>
              <AppInput name='polygonBlockName' />
            </div>
            {!values?.file && !isStatisticPage && (
              <label>
                <span className='add-polygon-block-modal__file-button'>
                  Использовать изображение
                </span>
                <input
                  name='file'
                  type='file'
                  onChange={(e) => {
                    fileSettings({
                      blob: e?.currentTarget?.files?.[0],
                      loadFileMutation,
                      setFieldValue,
                    });
                  }}
                  className='add-polygon-block-modal__file'
                />
              </label>
            )}
            {values?.file && !isStatisticPage && (
              <div style={{ position: 'relative' }}>
                <img
                  alt='cross-image'
                  className='showImage'
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  src={`${BASE_URL}${values?.file?.path as string}`}
                />
                <CrossIcon
                  width={20}
                  height={20}
                  color={'red'}
                  onClick={() => {
                    setFieldValue('file', null);
                  }}
                  className={'deleteImageIcons'}
                />
              </div>
            )}

            <div className='add-polygon-block-modal__buttons'>
              <Button
                text={buttonText}
                type='submit'
                width='100%'
                height='25px'
                margin='5px 0 0 0'
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const useEnterColor = ({ setPickerColor, setIsPickerOpen }: any) => {
  const [colorEnter, setColorEnter] = useState('');

  useEffect(() => {
    const handleUserKeyPress = (event: any) => {
      const { keyCode } = event;
      if (keyCode === 13) {
        setPickerColor(colorEnter);
        setIsPickerOpen(false);
      }
    };

    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  });

  const handleEnter = ({ hex }: any) => {
    setColorEnter(hex);
  };

  return { handleEnter };
};
