import * as yup from 'yup';

import * as MESSAGES from '../messages';

export const circleUser = yup.object().shape({
  name: yup
    .string()
    .max(20, MESSAGES.INCORRECT_MAX_LENGTH_20)
    .required(MESSAGES.REQUIRE_MESSAGE),
  whoIs: yup.string().max(50, MESSAGES.INCORRECT_MAX_LENGTH_50),
  colorToDisplay: yup.string().max(50, MESSAGES.INCORRECT_MAX_LENGTH_50),
  usefulnessDescription: yup.string().max(50, MESSAGES.INCORRECT_MAX_LENGTH_50),
  strongPoints: yup.string(),
  weakPoints: yup.string(),
});
