import * as GENERATED from '../../graphql/generated/graphql';
import { SortingField, SortingOrders } from '../../graphql/generated/graphql';

export const useGetMyProjectsRoleMaintainerQuery = ({ ...options }) => {
  return GENERATED.useGetMyProjectsRoleMaintainerQuery({
    variables: {
      data: {
        limit: 100,
        sort: { field: SortingField.Number, type: SortingOrders.Asc },
      },
      ...options,
    },
  });
};
