import * as yup from 'yup';

import * as MESSAGES from '../messages';

export const changeColName = yup.object().shape({
  name: yup
    .string()
    .min(3, MESSAGES.INCORRECT_MIN_LENGTH_3)
    .required(MESSAGES.REQUIRE_MESSAGE),
});
