import { gql } from '@apollo/client';

export const GET_ARCHIVED_PROJECTS = gql`
  query getArchivedProjects($data: GetProjectsInput!) {
    getArchivedProjects(data: $data) {
      rows {
        id
        name
        number
        tasks(limit: 100, sort: { type: ASC, field: number }) {
          rows {
            id
            name
            number
            description
            code
            startDate
            closeDate
            endDate
            project {
              id
              number
              name
            }
            status {
              id
            }
            author {
              id
              login
              image
              fullName
            }
            maintainer {
              id
              login
              image
            }
            tags {
              count
              rows {
                id
                name
                color
                textColor
                createdAt
                updatedAt
              }
            }
          }
          count
        }
      }
      count
    }
  }
`;
