import './CreateTemplate.scss';

import React, { useState } from 'react';

import { Button } from '../../../button/Button';
import { NoFormikInput } from '../../../index';

type CreateTemplateType = {
  onClose: any;
  modalParameters: any;
};

export const CreateTemplate = ({ onClose, modalParameters }: CreateTemplateType) => {
  const [value, setValue] = useState('');

  return (
    <div className='CreateTemplate'>
      <NoFormikInput value={value} setValue={setValue} placeholder='Название шаблона' />
      <div style={{ display: 'flex' }}>
        <Button text='Отменить' onClick={onClose} />
        <Button
          text='Создать'
          onClick={() => {
            modalParameters.onCallback(value);
            onClose();
          }}
        />
      </div>
    </div>
  );
};
