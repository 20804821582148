import './ChatItem.scss';

import React, { CSSProperties, FC } from 'react';
import { Link } from 'react-router-dom';

import { message } from '../../../assets/icons';
import { UserItem } from '../../user-item/UserItem';

type ChatItemPropsType = {
  id: number | null | undefined;
  login: string | null | undefined;
  image: string | null | undefined;
  onlineStatus: boolean;
  style: CSSProperties;
  chats:
    | {
        id: number;
        unreadMessagesCount: number;
        name?: string | null | undefined;
      }[]
    | null
    | undefined;
};

export const ChatItem: FC<ChatItemPropsType> = ({
  id,
  login,
  image,
  onlineStatus,
  style,
  chats,
}) => {
  return (
    <Link to={`/private-chat/${id}`} className='chat__item' style={style}>
      <UserItem
        avatar={image}
        name={login}
        displayOnline
        onlineStatus={onlineStatus}
        id={id as number}
      />
      <div className='item__count'>
        <div className='item__count'>
          {chats?.length && chats[0].unreadMessagesCount ? (
            <>
              <span>{chats[0]?.unreadMessagesCount || null}</span>
              <img src={message} alt='chat' />
            </>
          ) : null}
        </div>
      </div>
    </Link>
  );
};
