import * as yup from 'yup';

import * as MESSAGES from '../messages';

export const createPackageShema = yup.object().shape({
  title: yup
    .string()
    .min(1, MESSAGES.INCORRECT_MIN_LENGTH_1)
    .required(MESSAGES.REQUIRE_MESSAGE),
  description: yup.string(),
});
