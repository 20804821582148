import './EditTitle.scss';

import React from 'react';

import { EditTitleModalLocal } from '../../../entityActions/editTitle/EditTitle';

export const EditTitleModal = ({ isOpen, onClose, modalParameters }: any) => {
  const {
    initialTitle,
    projectNumber,
    initialStartDate,
    initialCloseDate,
    initialEndDate,
    taskId,
    label,
    isProject,
    initialTagIds,
    entityId,
    subtaskId,
  } = modalParameters.initialValues;

  const initialValues = {
    title: initialTitle || '',
    projectNumber: projectNumber,
    tagsId: [],
    startDate: initialStartDate ? Date.parse(initialStartDate) : null,
    closeDate: initialCloseDate ? Date.parse(initialCloseDate) : null,
    endDate: initialEndDate ? Date.parse(initialEndDate) : null,
  };
  return (
    <EditTitleModalLocal
      isModalOpen={isOpen}
      handleCloseModal={onClose}
      initialValues={initialValues}
      taskId={taskId}
      label={label}
      isProject={isProject}
      projectNumber={projectNumber}
      initialTagIds={initialTagIds}
      entityId={entityId}
      subtaskId={subtaskId}
    />
  );
};
