import { FormikValues } from 'formik';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  UpsertPoligonColorInput,
  useGetPoligonQuery,
  useGetProjectQuery,
  useUpsertPoligonColorMutation,
  useUpsertPoligonMutation,
} from '../graphql/generated/graphql';
import { ArrowType, RectType } from '../types/types';
import { useCheckAccessEditPolygon } from './useCheckAccessEditPolygon';

type useApiPolygonStatisticType = {
  rectangles: Array<RectType>;
  arrowArray: Array<ArrowType>;
  setRectangles: (value: Array<RectType>) => void;
  setArrowArray: (value: Array<ArrowType>) => void;
  setColorsData: (Value: any) => void;
};

export const useApiPolygonStatistic = ({
  rectangles,
  arrowArray,
  setRectangles,
  setArrowArray,
  setColorsData,
}: useApiPolygonStatisticType) => {
  const { projectId, polygonStatisticID } = useParams();
  const navigate = useNavigate();

  const [poligon, setPoligon] = useState<any>();
  const [newPoligonId, setNewPoligonId] = useState(null);

  const [flagCreatePoligon, setFlagCreatePoligon] = useState(false);

  const { data: project, refetch: refetchProject } = useGetProjectQuery({
    variables: {
      id: parseInt(projectId as string),
      tasksData: {
        isTaskStorage: false,
        limit: 5,
      },
    },
    skip: !!polygonStatisticID,
    onCompleted: (response) => {
      if (!response?.getProject?.poligon?.id) {
        setFlagCreatePoligon(true);
        onSaveButton && onSaveButton();
      }
    },
    onError: () => {
      navigate('/');
    },
  });

  const { checkAccess } = useCheckAccessEditPolygon({ poligon });

  const [upsertPoligonMutation] = useUpsertPoligonMutation({
    onCompleted: () => {
      projectId && refetchProject();
      if (!flagCreatePoligon) {
        refetchPoligon();
        toast.success('Полигон сохранен');
      } else {
        setFlagCreatePoligon(false);
      }
    },
  });

  const [upsertPoligonColorMutation] = useUpsertPoligonColorMutation();

  const { refetch: refetchPoligon, loading: loadingRectangle } = useGetPoligonQuery({
    variables: {
      id: polygonStatisticID
        ? parseInt(polygonStatisticID)
        : (project?.getProject?.poligon?.id as number),
    },
    skip: !project?.getProject?.poligon?.id && !polygonStatisticID,
    onCompleted: (response) => {
      const data = response?.getPoligon;
      setPoligon(data);
      if (data?.rectangles && data?.rectangles?.length >= 1) {
        setRectangles(data?.rectangles as any);
      }
      if (data.arrows && data.arrows.length >= 1) {
        setArrowArray(data.arrows as any);
      }
      if (response?.getPoligon?.colors?.length) {
        setColorsData(
          response?.getPoligon?.colors?.map(({ id, name, color }) => ({
            id,
            name,
            color,
          })),
        );
      }
    },
  });

  const onSaveButton = async () => {
    if (!checkAccess({})) return;

    await upsertPoligonMutation({
      variables: {
        data: {
          id: newPoligonId ? newPoligonId : poligon?.id,
          name: poligon?.name ? poligon?.name : project?.getProject?.name,
          projectId: parseInt(projectId as string),
          canvasData: {
            rectangles: rectangles.map((e: RectType) => {
              return {
                ...e,
                id: typeof e.id === 'string' ? undefined : e.id,
                isStatus: e.isStatus === 'Delete' ? 'Delete' : 'Upsert',
                taskLink: e?.taskLink?.map((e: any) => {
                  delete e.name;
                  return e;
                }),
                x: Math.round(e.x),
                y: Math.round(e.y),
                width: Math.round(e.width),
                height: Math.round(e.height),
                parentLink: e?.parentLink,
                parentLinkUpdate: undefined,
                frontendData: `${e.id}`,
                deletedLink: undefined,
                __typename: undefined,
                file: undefined,
                statistics: undefined,
              };
            }) as any,
            arrows: arrowArray.map((e: ArrowType) => {
              return {
                ...e,
                id: typeof e.id === 'string' ? undefined : e.id,
                isStatus: e.isStatus === 'Delete' ? 'Delete' : 'Upsert',
                __typename: undefined,
              };
            }) as any,
          },
        },
      },
    });
  };

  const onSaveColorsPallete = (values: FormikValues) => {
    const data = transformColorsPalleteBeforeSend(values, poligon?.id);
    if (!checkAccess({})) return;

    upsertPoligonColorMutation({
      variables: {
        data: data,
      },
      onCompleted: () => {
        setColorsData(values);
      },
    });
  };

  return {
    onSaveButton,
    onSaveColorsPallete,
    poligon,
    project,
    loadingRectangle,
    setNewPoligonId,
  };
};

export const transformColorsPalleteBeforeSend = (
  values: FormikValues,
  poligonId?: number,
) => {
  const colors: string[] = [];
  const names: string[] = [];

  values.forEach((item: any) => {
    colors.push(item.color);
    names.push(item.name);
  });

  const obj: UpsertPoligonColorInput = {} as UpsertPoligonColorInput;

  if (poligonId) {
    obj.color = colors;
    obj.name = names;
    obj.poligonId = poligonId;
  }

  return obj;
};
