import './RemoveIcon.scss';

import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const RemoveIcon: FC<RemoveIconType> = ({
  width = '20px',
  height = '20px',
  className = 'remove-icon',
  color = '#FFFFFF',
  onClick,
  isBordered = false,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      style={isBordered ? { borderLeft: '1px solid #AFAFAF' } : {}}
    >
      <path
        d='M7.5 7.5H8.75V14.1667H7.5V7.5ZM11.25 7.5H12.5V14.1667H11.25V7.5Z'
        fill={color}
      />
      <path
        d='M3.33334 4.64286V5.71429H4.44445V16.4286C4.44445 16.7127 4.56151 16.9853 4.76988 17.1862C4.97826 17.3871 5.26087 17.5 5.55556 17.5H14.4444C14.7391 17.5 15.0217 17.3871 15.2301 17.1862C15.4385 16.9853 15.5556 16.7127 15.5556 16.4286V5.71429H16.6667V4.64286H3.33334ZM5.55556 16.4286V5.71429H14.4444V16.4286H5.55556ZM7.77778 2.5H12.2222V3.57143H7.77778V2.5Z'
        fill={color}
      />
    </svg>
  );
};

type RemoveIconType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
> & { isBordered?: boolean };
