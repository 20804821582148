import './NewSubtask.scss';

import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import React, { FC, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { plus } from '../../assets/icons';
import { useCreateSubtaskMutation } from '../../hooks/useMutations';
import { getErrorText } from '../../utils';
import { addSubtaskSchema } from '../../validation/schemas';
import { AppInput, Button, CustomDatePicker, Modal, SubTasksProps, TagsSelect } from '..';

type Values = {
  title: string;
  parentId?: number;
  statusId?: number;
  tagsId: string[];
  endDate: Date | null;
};

export const NewSubtask: FC<
  Pick<SubTasksProps, 'taskMaintainerId'> & { initialTagIds: Array<string> | undefined }
> = ({ taskMaintainerId, initialTagIds }) => {
  const { taskId } = useParams();

  const initialValues = {
    title: '',
    tagsId: initialTagIds ? initialTagIds : [],
    endDate: null,
  };

  const location = useLocation();

  const [createSubtask, { loading }] = useCreateSubtaskMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const isArchivedPage = location.pathname.includes('archive');

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCreateSubtask = async (values: Values) => {
    handleCloseModal();
    if (values.title && taskMaintainerId && !loading) {
      await createSubtask({
        variables: {
          data: {
            name: values.title,
            parentId: Number(taskId),
            statusId: 7,
            maintainerId: taskMaintainerId,
            tagsId: values.tagsId,
            endDate: values.endDate ? format(values.endDate, 'yyyy-MM-dd') : null,
          },
        },
        onCompleted: (response) => {
          if (response?.createSubtask.id) {
            toast.success('Подзадача успешно создана!');
          }
        },
        onError: (error) => {
          if (error?.message) {
            toast.error(getErrorText(error.message));
          }
        },
      });
    }
  };
  return (
    <div>
      {!isArchivedPage && (
        <div className='addSubtask' role='button' onClick={() => setIsModalOpen(true)}>
          <div className='addSubtask__title'>
            <span>Добавить подзадачу</span>
          </div>

          <img src={plus} alt='add' />
        </div>
      )}
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <Formik
          initialValues={initialValues}
          validationSchema={addSubtaskSchema}
          onSubmit={handleCreateSubtask}
        >
          <Form>
            <div
              className='simple-form-modal'
              style={{ width: '400px', maxWidth: 'none' }}
            >
              <div className='newProject__data' />
              <div className='simple-form-modal__title'>
                <AppInput label={'Название подзадачи:'} name={'title'} />
                <CustomDatePicker name='endDate' label='Дата сдачи:' endDateSet />
                <TagsSelect name='tagsId' editTagIds={initialTagIds} isEditMode={true} />
              </div>
              <Button
                text={'Добавить подзадачу'}
                type='submit'
                width='100%'
                height={25}
              />
            </div>
          </Form>
        </Formik>
      </Modal>
    </div>
  );
};
