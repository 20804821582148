import './MoveTredModal.scss';

import React, { useEffect, useState } from 'react';

import {
  SortingField,
  SortingOrders,
  useGetProjectsScaleQuery,
} from '../../../../graphql/generated/graphql';
import { Button } from '../../../button/Button';
import { NoFormikSelect } from '../../../noFormikSelect/NoFormikSelect';

export const MoveTredModal = ({ onClose, modalParameters }: any) => {
  const { data } = useGetProjectsScaleQuery({
    variables: {
      data: {
        limit: 1000,
        sort: { field: SortingField.Number, type: SortingOrders.Asc },
        isExistScaleMapFiles: true,
      },
      statusId: undefined,
      isExcludeNullStatusTasks: false,
    },
  });

  const [selectProject, setSelectProject] = useState<any>(
    modalParameters?.initialValues?.selectProject,
  );
  const [selectFile, setSelectFile] = useState<any>(
    modalParameters?.initialValues?.selectFile,
  );

  const [selectTask, setSelectTask] = useState<any>(
    modalParameters?.initialValues?.parentScaleMapTaskId,
  );
  useEffect(() => {
    setSelectTask(null);
  }, [selectFile]);

  const optionsProject = data?.getProjects?.rows?.map((e) => {
    if (!e) return;
    return { value: e.id, label: e.name };
  });
  const selectProjectItem = data?.getProjects?.rows?.filter((e) => {
    return e?.id === selectProject;
  })[0];

  const optionsSelectFile: any = selectProjectItem?.scaleMapFiles?.map((e) => {
    return { value: e.id, label: e.name };
  });

  const selectItemFile = selectProjectItem?.scaleMapFiles?.filter((e) => {
    return e?.id === selectFile;
  })[0];

  const optionsSelectTask = selectItemFile?.scaleMapTasks
    ?.filter((e) => e.column === 1)
    ?.map((e) => {
      return { value: e.id, label: e.name };
    });

  if (!optionsProject) return <></>;
  return (
    <div className='MoveTredModal'>
      {optionsProject && (
        <div className='MoveTredModal__item'>
          <div className='MoveTredModal__name'>Выбор проекта </div>
          <NoFormikSelect
            options={optionsProject as any}
            value={selectProject}
            setValue={setSelectProject}
          />
        </div>
      )}
      {optionsSelectFile && (
        <div className='MoveTredModal__item'>
          <div className='MoveTredModal__name'>Выбор файла </div>
          {optionsSelectFile && (
            <NoFormikSelect
              options={optionsSelectFile as any}
              value={selectFile}
              setValue={setSelectFile}
            />
          )}
        </div>
      )}
      {optionsSelectTask && (
        <div className='MoveTredModal__item'>
          <div className='MoveTredModal__name'>Выбор таски </div>
          {optionsSelectTask && (
            <NoFormikSelect
              options={optionsSelectTask as any}
              value={selectTask}
              setValue={setSelectTask}
            />
          )}
        </div>
      )}
      {selectTask && (
        <Button
          width={'100%'}
          onClick={() => {
            modalParameters.onCallback({
              parentScaleMapTaskId: selectTask,
              selectFile: selectFile,
              selectProject: selectProject,
            });
            onClose();
          }}
        >
          {modalParameters?.initialValues?.type === 'create'
            ? 'Привязать к проекту'
            : 'Перенести в файл'}
        </Button>
      )}

      <Button
        width={'100%'}
        margin={'10px 0px 0px'}
        onClick={() => {
          onClose();
        }}
      >
        Отменить
      </Button>
    </div>
  );
};
