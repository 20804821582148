import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ROLES } from '../constants/constants';
import { useGetCurrentUserQuery } from '../graphql/generated/graphql';
import { useGetProjectLightQuery } from './useQueries';

export const useCheckAccessEditPolygon = ({ poligon }: any) => {
  const { projectId } = useParams();
  const { data: currentUser } = useGetCurrentUserQuery({ fetchPolicy: 'cache-only' });
  const { data: getProjectData, loading: getProjectDataLoading } =
    useGetProjectLightQuery({ skip: !projectId });

  const checkAccess = ({ skipMessage = false }: checkAccessType) => {
    if (!getProjectDataLoading) {
      if (
        currentUser?.getCurrentUser?.id === poligon?.owner?.id ||
        currentUser?.getCurrentUser?.id === getProjectData?.getProject?.author?.id ||
        currentUser?.getCurrentUser?.role === ROLES.ADMIN
      ) {
        return true;
      } else {
        if (!skipMessage) toast.error('Редактировать может только создатель полигона');
        return false;
      }
    }

    return false;
  };

  return { checkAccess, getProjectDataLoading };
};

type checkAccessType = {
  skipMessage?: boolean;
};
