import { OptionsType } from '../constants/constants';
import { useModalContext } from '../context';
import {
  useCreatePoligonTemplateMutation,
  useDeletePoligonTemplateMutation,
  useGetPoligonTemplatesQuery,
} from '../graphql/generated/graphql';
import { ModalStatuses, OptionsStatuses } from '../types/enums';
import { RectType } from '../types/types';
import { idGenerate } from './../utils/idGenerate';
import { useCheckAccessEditPolygon } from './useCheckAccessEditPolygon';

type useApiPolygonTemplatesType = {
  setRectangles: (value: Array<RectType>) => void;
  poligon: any;
};

export const useApiPolygonTemplates = ({
  setRectangles,
  poligon,
}: useApiPolygonTemplatesType) => {
  const {
    handleSetModalStatus,
    handleSetChildrenOptionsStatus,
    handleChangeModalParameters,
  } = useModalContext();

  const { checkAccess } = useCheckAccessEditPolygon({ poligon });

  const { data: templates, refetch: refetchTemplate } = useGetPoligonTemplatesQuery({
    variables: {
      data: {
        limit: 1000,
        offset: 0,
      },
    },
  });

  const [createPoligonTemplateMutation] = useCreatePoligonTemplateMutation({
    onCompleted: () => {
      refetchTemplate();
    },
  });

  const [deletePoligonTemplateMutation] = useDeletePoligonTemplateMutation({
    onCompleted: () => {
      refetchTemplate();
    },
  });

  const templateOptions: OptionsType = [
    {
      id: 1,
      title: 'Сохранить шаблон',
      onClick: () => handleSetModalStatus(ModalStatuses.TEMPLATE_MODAL),
      isHaveChildrenPopover: false,
    },
    {
      id: 2,
      title: 'Загрузка из шаблона',
      onClick: () =>
        handleSetChildrenOptionsStatus(OptionsStatuses.TEMPLATE_CHILDREN_MENU),
      isHaveChildrenPopover: true,
    },
    {
      id: 3,
      title: 'Список шаблонов',
      onClick: () => {
        handleSetModalStatus(ModalStatuses.TEMPLATE_MODAL_ALL);
        handleChangeModalParameters([
          {
            key: 'templates',
            value: templates?.getPoligonTemplates?.rows,
          },
          {
            key: 'onDelete',
            value: (values: any) => {
              if (!checkAccess({})) return;
              deletePoligonTemplateMutation({
                variables: {
                  id: values.id,
                },
              });
            },
          },
        ]);
      },
      isHaveChildrenPopover: false,
    },
  ];

  const templateChildrenOptions = () => {
    return templates?.getPoligonTemplates.rows.map((template) => {
      return {
        id: template.id,
        title: template.name,
        onClick: () => {
          setRectangles([]);
          setRectangles(
            template?.canvasObject?.map((e: any, index: number) => {
              return {
                ...e,
                parentLink: undefined,
                draggable: true,
                id: idGenerate() + index,
              };
            }),
          );
        },
        isHaveChildrenPopover: false,
      };
    });
  };

  return {
    templateChildrenOptions,
    templateOptions,
    createPoligonTemplateMutation,
  };
};
