import './ChronometerDone.scss';

import React from 'react';
import { toast } from 'react-toastify';

import puls from '../../assets/puls.png';
import { useModalContext } from '../../context/';
import { ModalStatuses } from '../../types/enums';
import { filterArrayDiapason, textSlice } from '../../utils';
import { ClockIcons } from '../icons';

type ChronometerDoneType = {
  handleTimeFixed: (value: any) => void;
  handleEnergyFixed: (value: any) => void;
  selectHours: (number | null | undefined)[];
  selectDay: any;
  selectDayDate: string;
  plan: any;
  from: string;
};

export const ChronometerDone = ({
  handleTimeFixed,
  handleEnergyFixed,
  selectHours,
  selectDay,
  selectDayDate,
  plan,
  from,
}: ChronometerDoneType) => {
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  return (
    <div className='chronometerDone'>
      <div
        className='chronometerDone__title'
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            cursor: 'pointer',
            paddingRight: '5px',
            display: 'flex',
            alignItems: 'center',
            marginRight: 'auto',
          }}
          onClick={() => {
            handleSetModalStatus(ModalStatuses.PULSOMETER_MODAL);
            handleChangeModalParameters([
              {
                key: 'initialValues',
                value: {
                  selectDayDate: selectDayDate,
                },
              },
            ]);
          }}
        >
          <img
            src={puls}
            width={40}
            height={40}
            style={{
              marginRight: '5px',
            }}
          />
          ПУЛЬСОМЕТР
        </div>
      </div>

      <div className='chronometerDone__button-wrapper'>
        <button
          className='chronometerDone__button-positive'
          onClick={() => {
            if (selectHours.length > 0) {
              handleSetModalStatus(ModalStatuses.CHRONO__TIME);
              handleChangeModalParameters([
                {
                  key: 'onCallback',
                  value: handleTimeFixed,
                },
                {
                  key: 'diapason',
                  value: filterArrayDiapason(selectHours),
                },
                {
                  key: 'selectDay',
                  value: selectDayDate,
                },
                {
                  key: 'plan',
                  value: plan,
                },
              ]);
            } else {
              toast.error(`Выберете часы`);
            }
          }}
        >
          Зафиксировать время
        </button>
        <button
          className='chronometerDone__button-negative'
          onClick={() => {
            if (selectHours.length > 0) {
              handleSetModalStatus(ModalStatuses.CHRONO__ENERGY);
              handleChangeModalParameters([
                {
                  key: 'onCallback',
                  value: handleEnergyFixed,
                },
                {
                  key: 'selectHourData',
                  value: selectDay?.hours?.filter((e: any) => {
                    return selectHours?.indexOf(e.hour) !== -1 && e;
                  }),
                },
                {
                  key: 'selectDay',
                  value: selectDayDate,
                },
                {
                  key: 'diapason',
                  value: filterArrayDiapason(selectHours),
                },
                {
                  key: 'plan',
                  value: plan,
                },
              ]);
            } else {
              toast.error(`Выберете часы`);
            }
          }}
        >
          Зафиксировать энергию
        </button>
        <button
          className='chronometerDone__button-tracked'
          onClick={() => {
            handleSetModalStatus(ModalStatuses.REPORT_CHRONOGRAPH_MODAL);
          }}
        >
          Сгенерировать отчет
        </button>
        <button
          className='chronometerDone__button-plan'
          onClick={() => {
            handleSetModalStatus(ModalStatuses.PLAN_CHRONO_MODAL);
            handleChangeModalParameters([
              {
                key: 'initialValues',
                value: {
                  from,
                },
              },
            ]);
          }}
        >
          Запланировать
        </button>
      </div>

      {selectDay?.statistic && (
        <>
          {selectDay?.statistic?.scaleMapFilesTracked?.length > 0 && (
            <>
              <div className='chronometerDone__title3'>Задачи</div>
            </>
          )}
          <div className='chronometerDone__task-wrapper'>
            {selectDay?.statistic?.scaleMapFilesTracked?.map((e: any, index: number) => {
              const endElement =
                selectDay?.statistic?.scaleMapFilesTracked?.length === index + 1;
              return (
                <div
                  className='chronometerDone__task-item'
                  key={e?.scaleMapFileId + '' + e?.scaleMapTask?.id}
                >
                  <div
                    className='chronometerDone__task-name'
                    style={{ paddingBottom: endElement ? '0px' : '' }}
                  >
                    <div
                      className='chronometerDone__task-color'
                      style={{ backgroundColor: e?.color }}
                    ></div>
                    <div className='chronometerDone__task-text'>
                      {textSlice({ text: e?.scaleMapFile?.name, count: 45 })}
                      {`${e.scaleMapTask?.id ? ' / ' + e.scaleMapTask?.name : ''}`}
                    </div>
                  </div>
                  <div
                    className='chronometerDone__task-clock'
                    style={{ paddingBottom: endElement ? '0px' : '' }}
                  >
                    <ClockIcons />
                    <div className='chronometerDone__task-time'>{e?.timeTracked}ч</div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
