import { taskPaginationLimit } from '../../constants/constants';
import { usePaginationContext } from '../../context';
import * as GENERATED from '../../graphql/generated/graphql';
import {
  GetProjectDocument,
  GetProjectQuery,
  GetProjectsDocument,
  GetProjectsQuery,
  GetTasksDocument,
  GetTasksQuery,
  SortingField,
  SortingOrders,
} from '../../graphql/generated/graphql';

export const useCreateTaskMutation = () => {
  const { paginationNumber } = usePaginationContext();

  return GENERATED.useCreateTaskMutation({
    update(cache, { data }) {
      if (!data?.createTask) {
        return null;
      }

      const getProjectsVariables = {
        data: {
          limit: 100,
          sort: { field: SortingField.Number, type: SortingOrders.Asc },
        },
        tasksData: {
          limit: taskPaginationLimit,
          isTaskStorage: false,
        },
      };

      const tasks: GetTasksQuery | null = cache.readQuery({
        query: GetTasksDocument,
        variables: {
          data: {
            limit: 100,
          },
        },
      });

      const project: GetProjectQuery | null = cache.readQuery({
        query: GetProjectDocument,
        variables: {
          id: data.createTask.project.id,
          tasksData: {
            limit: taskPaginationLimit,
            isTaskStorage: false,
          },
        },
      });

      const projects: GetProjectsQuery | null = cache.readQuery({
        query: GetProjectsDocument,
        variables: getProjectsVariables,
      });

      const newProjectsRows = projects?.getProjects.rows.map((project) =>
        project?.id === data.createTask.project.id
          ? {
              ...project,
              tasks: {
                ...project.tasks,
                count: project.tasks.count + 1,
                rows: [...(project.tasks.rows || []), data.createTask],
              },
            }
          : project,
      );

      if (tasks) {
        cache.writeQuery({
          query: GetTasksDocument,
          variables: {
            data: {
              limit: 100,
            },
          },
          data: {
            ...tasks,
            getTasks: {
              ...tasks.getTasks,
              count: tasks.getTasks.count + 1,
              rows: [...(tasks.getTasks.rows || []), data.createTask],
            },
          },
        });
      }

      if (project) {
        cache.writeQuery({
          query: GetProjectDocument,
          variables: {
            id: data.createTask.project.id,
            tasksData: {
              limit: taskPaginationLimit,
              offset: (paginationNumber - 1) * taskPaginationLimit,
            },
          },
          data: {
            ...project,
            getProject: {
              ...project.getProject,
              tasks: {
                ...project.getProject.tasks,
                rows: [...(project.getProject.tasks.rows || []), data.createTask],
              },
            },
          },
        });
      }

      if (projects) {
        cache.writeQuery({
          query: GetProjectsDocument,
          variables: getProjectsVariables,
          data: {
            ...projects,
            getProjects: {
              ...projects?.getProjects,
              rows: newProjectsRows,
            },
          },
        });
      }
    },
  });
};
