import './CirclesStats.scss';

import React from 'react';

import { GetSocialCircleStatisticsQuery } from '../../graphql/generated/graphql';

type CirclesStatsType = {
  statistic: GetSocialCircleStatisticsQuery | undefined;
  setSearchValue: (value: string) => void;
  searchValue: string;
};

export const CirclesStats = ({
  statistic,
  searchValue,
  setSearchValue,
}: CirclesStatsType) => {
  const data = statistic?.getSocialCircleStatistics;
  return (
    <div className='circlesStats'>
      <div className='circlesStats__list'>
        <div className='circlesStats__list-item'>
          Прошло дней с создания первой встречи:
          <span className='circlesStats__list-number'>
            {data?.daysSinceFirstMeetingCreation
              ? data?.daysSinceFirstMeetingCreation
              : 0}
          </span>
        </div>
        <div className='circlesStats__list-item'>
          Всего создано встреч:
          <span className='circlesStats__list-number'>
            {data?.totalAppointedMeetings ? data?.totalAppointedMeetings : 0}
          </span>
        </div>
        <div className='circlesStats__list-item'>
          Всего людей в круге общения:
          <span className='circlesStats__list-number'>
            {data?.totalObjectsInCircle ? data?.totalObjectsInCircle : 0}
          </span>
        </div>
      </div>
      <input
        className='circlesStats__serch'
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e?.target?.value);
        }}
      />
    </div>
  );
};
