import { FormikValues } from 'formik';
import React, { FC } from 'react';

import { polygonStatisticHeight } from '../../constants/constants';
import { useModalContext } from '../../context';
import { ModalStatuses } from '../../types/enums';
import { polygonToolsChangeParameterType, RectType } from '../../types/types';
import { calcLeftMenu, calcTopMenu } from '../../utils/';
import { EditIcon, InfoIcon, LinkAddIcon, RemoveIcon } from '../icons';
import { PolygonActionsWrapper } from '../polygonActionsWrapper/PolygonActionsWrapper';

export const PolygonMainBlockMenu: FC<PolygonMainBlockMenuType> = ({
  removeConfirmTitle,
  blockId,
  onRemove,
  initialEditInfoValues,
  initialColorId,
  changeParameter,
  onSaveEditData,
  onSaveLinksData,
  rectangles,
  selectedIdRect,
  editColors,
  initialInfoValues,
  initLinkValue,
  poligon,
  refScroll,
}) => {
  const { handleSetModalStatus, handleCloseModal, handleChangeModalParameters } =
    useModalContext();

  const onRemoveHandler = () => {
    onRemove && onRemove(blockId);
    handleCloseModal();
  };

  const onAddInfoHandler = (values: FormikValues) => {
    blockId && changeParameter({ id: blockId, key: 'text', value: values.info });
    handleCloseModal();
  };

  const onSaveEditDataHandler = (values: FormikValues) => {
    onSaveEditData && onSaveEditData(values.polygonName, values.color);
    handleCloseModal();
  };

  const onSaveLinksHandler = (values: FormikValues) => {
    onSaveLinksData && onSaveLinksData(values);
    handleCloseModal();
  };

  const selectedRect = rectangles?.filter((rect) => rect.id === selectedIdRect)[0];
  const top = selectedRect
    ? selectedRect?.y - polygonStatisticHeight + 50 - refScroll?.current?.scrollTop
    : 0;
  const left = selectedRect ? selectedRect?.x + 60 - refScroll?.current?.scrollLeft : 0;

  return (
    <PolygonActionsWrapper
      isVisible={!!selectedIdRect}
      style={{
        top: calcTopMenu({ top, height: polygonStatisticHeight }),
        left: calcLeftMenu({ shift: 350, left }),
      }}
    >
      <InfoIcon
        onClick={() => {
          handleChangeModalParameters([
            {
              key: 'onCallback',
              value: (values: any) => onAddInfoHandler(values),
            },
            {
              key: 'initialInfoValues',
              value: initialInfoValues,
            },
          ]);
          handleSetModalStatus(ModalStatuses.INFO_MODAL);
        }}
      />

      <EditIcon
        width={18}
        height={18}
        color='#FFFFFF'
        className='edit-color_with-background'
        onClick={() => {
          handleChangeModalParameters([
            {
              key: 'onCallback',
              value: (values: any) => onSaveEditDataHandler(values),
            },
            { key: 'initialEditInfoValues', value: initialEditInfoValues },
            { key: 'initialColorId', value: initialColorId },
            { key: 'colors', value: editColors },
          ]);
          handleSetModalStatus(ModalStatuses.EDIT_MODAL);
        }}
      />

      <LinkAddIcon
        onClick={() => {
          handleChangeModalParameters([
            {
              key: 'onCallback',
              value: (values: any) => onSaveLinksHandler(values),
            },
            {
              key: 'parentLink',
              value: { parentLink: initLinkValue },
            },
            { key: 'poligon', value: poligon },
            { key: 'polygonObject', value: null },
          ]);
          handleSetModalStatus(ModalStatuses.NEW_LINK_MODAL);
        }}
      />

      <div className='polygon-actions-wrapper-line' />
      <RemoveIcon
        onClick={() => {
          handleChangeModalParameters([
            {
              key: 'onCallback',
              value: () => onRemoveHandler(),
            },
            {
              key: 'removeConfirmTitle',
              value: removeConfirmTitle,
            },
          ]);
          handleSetModalStatus(ModalStatuses.REMOVE_MODAL);
        }}
      />
    </PolygonActionsWrapper>
  );
};

type PolygonMainBlockMenuType = {
  blockId: string | number | null;
  removeConfirmTitle: string;
  additionalRemoveConfirmTitle?: string;
  initialInfoValues?: { info?: string };
  initialEditInfoValues?: { polygonName?: string; color?: string };
  initialColorId?: number;
  onRemove: (blockId?: string | number | null) => void;
  changeParameter: ({ id, key, value }: polygonToolsChangeParameterType) => void;
  onSaveEditData: (polygonName?: string, color?: number) => void;
  onSaveLinksData: (values: FormikValues) => void;
  rectangles: Array<RectType>;
  selectedIdRect: string | number | null;
  editColors?: any[];
  initLinkValue?: string;
  poligon: any;
  refScroll: any;
};
