import './CrossIcon.scss';

import React, { FC } from 'react';

type Props = {
  color?: string;
  onClick?: () => void;
  width?: string | number;
  height?: string | number;
  className?: string;
};

export const CrossIcon: FC<Props> = ({
  color = '#303030',
  onClick,
  width = 8,
  height = 8,
  className = 'crossColor',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 6 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      className={className}
    >
      <g clipPath='url(#clip0_216_2158)'>
        <path
          d='M3.53063 3.00091L5.89006 0.641489C6.03665 0.495025 6.03665 0.257381 5.89006 0.110918C5.74347 -0.0356706 5.50608 -0.0356706 5.35949 0.110918L3.00006 2.47034L0.640512 0.110918C0.493923 -0.0356706 0.25653 -0.0356706 0.109941 0.110918C-0.0366471 0.257381 -0.0366471 0.495025 0.109941 0.641489L2.46949 3.00091L0.109941 5.36034C-0.0366471 5.5068 -0.0366471 5.74445 0.109941 5.89091C0.183236 5.96408 0.279294 6.00073 0.375227 6.00073C0.47116 6.00073 0.567218 5.96408 0.640512 5.89078L3.00006 3.53136L5.35949 5.89078C5.43278 5.96408 5.52884 6.00073 5.62477 6.00073C5.72071 6.00073 5.81676 5.96408 5.89006 5.89078C6.03665 5.74432 6.03665 5.50668 5.89006 5.36021L3.53063 3.00091Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_216_2158'>
          <rect width='6' height='6' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
