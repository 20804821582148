import './DecideOnTheFlyControlItem.scss';

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROLES } from '../../constants/constants';
import { useAuthContext, useMenuContext, useModalContext } from '../../context';
import {
  CreateDecideOnFlyActionMutationFn,
  RemoveDecideOnFlyActionMutationFn,
  UpdateDecideOnFlyActionMutationFn,
} from '../../graphql/generated/graphql';
import { ModalStatuses } from '../../types/enums';
import { textSlice } from '../../utils';
import { Button } from '../button/Button';
import { DeleteIcon, EditIcon3 } from '../icons';

type DecideOnTheFlyControlItemType = {
  createDecideOnFlyActionMutation: CreateDecideOnFlyActionMutationFn;
  updateDecideOnFlyActionMutation: UpdateDecideOnFlyActionMutationFn;
  removeDecideOnFlyActionMutation: RemoveDecideOnFlyActionMutationFn;
  decideOnFlyActions: any;
  decideOnFlyPeriod: number;
  time: string;
  disableButton?: boolean;
  idPage?: number;
  backgroundColor: string | undefined | null;
};

export const DecideOnTheFlyControlItem = ({
  createDecideOnFlyActionMutation,
  updateDecideOnFlyActionMutation,
  removeDecideOnFlyActionMutation,
  decideOnFlyActions,
  decideOnFlyPeriod,
  time,
  disableButton,
  idPage,
  backgroundColor,
}: DecideOnTheFlyControlItemType) => {
  const nodata = decideOnFlyActions?.length === 0;

  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();

  const { currentUserData } = useAuthContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (currentUserData?.role !== ROLES.ADMIN) {
      navigate('/');
    }
  }, []);

  return (
    <div className='DecideOnTheFlyControlItems'>
      <div className='DecideOnTheFlyControlItems__table'>
        <div className='DecideOnTheFlyControlItems__title'>
          {time}
          {!disableButton && (
            <Button
              backgroundColor='#149939'
              width='155px'
              height={'30px'}
              color='#FFF'
              disabled={disableButton}
              onClick={() => {
                handleSetModalStatus(ModalStatuses.DECIDE_ON_THE_FLY_TASK);
                handleChangeModalParameters([
                  {
                    key: 'initialValues',
                    value: {
                      name: '',
                      percent: '',
                      decideOnFlyPackageId: idPage,
                      color: '',
                      decideOnFlyPeriodId: decideOnFlyPeriod,
                    },
                  },
                  { key: 'typeModal', value: 'create' },
                  {
                    key: 'onCallback',
                    value: (value: any) => {
                      createDecideOnFlyActionMutation({
                        variables: {
                          createDecideOnFlyActionInput: {
                            name: value.name,
                            chancePercent: value.percent ? value.percent : null,
                            decideOnFlyPeriodId: decideOnFlyPeriod,
                            decideOnFlyPackageId: value?.decideOnFlyPackageId
                              ? value?.decideOnFlyPackageId
                              : undefined,
                            color: value?.color,
                            hourTo: value?.hourTo,
                            hourFrom: value?.hourFrom,
                          },
                        },
                      });
                    },
                  },
                ]);
              }}
            >
              Добавить еще
            </Button>
          )}
        </div>
        <div className='DecideOnTheFlyControlItems__table-body'>
          {nodata ? (
            <div className='DecideOnTheFlyControlItems__empty'>Добавьте задание</div>
          ) : (
            <div>
              {decideOnFlyActions?.map((e: any) => {
                return (
                  <Item
                    key={e?.id}
                    name={e?.name}
                    percent={e?.chancePercent}
                    id={e?.id}
                    updateDecideOnFlyActionMutation={updateDecideOnFlyActionMutation}
                    removeDecideOnFlyActionMutation={removeDecideOnFlyActionMutation}
                    decideOnFlyPeriodId={decideOnFlyPeriod}
                    backgroundColor={
                      backgroundColor ? backgroundColor : e?.decideOnFlyPackage?.color
                    }
                    idPage={idPage}
                    hourTo={e?.hourTo}
                    hourFrom={e?.hourFrom}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

type ItemType = {
  name: string;
  percent: number;
  id: number;
  updateDecideOnFlyActionMutation: UpdateDecideOnFlyActionMutationFn;
  removeDecideOnFlyActionMutation: RemoveDecideOnFlyActionMutationFn;
  decideOnFlyPeriodId: number;
  backgroundColor: string | undefined | null;
  idPage?: number;
  hourTo: string;
  hourFrom: string;
};

const Item = ({
  name,
  percent,
  id,
  updateDecideOnFlyActionMutation,
  removeDecideOnFlyActionMutation,
  decideOnFlyPeriodId,
  backgroundColor,
  idPage,
  hourTo,
  hourFrom,
}: ItemType) => {
  const { handleSetModalStatus, handleChangeModalParameters } = useModalContext();
  const ref = useRef<any>(null);

  const [count, setCount] = useState(20);

  useResize({ ref, setCount });

  return (
    <div
      className='DecideOnTheFlyControlItem'
      style={{
        backgroundColor: backgroundColor ? backgroundColor : '',
      }}
    >
      <div className='DecideOnTheFlyControlItem__name' ref={ref}>
        {textSlice({
          text: name,
          count: count,
        })}
      </div>
      <div className='DecideOnTheFlyControlItem__count'>{percent?.toFixed(0)}%</div>
      <div className='DecideOnTheFlyControlItem__icons'>
        <EditIcon3
          onClick={() => {
            handleSetModalStatus(ModalStatuses.DECIDE_ON_THE_FLY_TASK);
            handleChangeModalParameters([
              {
                key: 'initialValues',
                value: {
                  name: name,
                  percent: percent,
                  decideOnFlyPackageId: idPage,
                  color: backgroundColor,
                  hourTo: hourTo,
                  hourFrom: hourFrom,
                  decideOnFlyPeriodId: decideOnFlyPeriodId,
                },
              },
              { key: 'typeModal', value: 'update' },
              {
                key: 'onCallback',
                value: (value: any) => {
                  updateDecideOnFlyActionMutation({
                    variables: {
                      updateDecideOnFlyActionInput: {
                        id: id,
                        name: value.name,
                        chancePercent: value.percent ? value.percent : null,
                        decideOnFlyPeriodId: decideOnFlyPeriodId,
                        decideOnFlyPackageId: value?.decideOnFlyPackageId,
                        color: value?.color,
                        hourTo: value?.hourTo,
                        hourFrom: value?.hourFrom,
                      },
                    },
                  });
                },
              },
            ]);
          }}
        />
        <DeleteIcon
          onClick={() => {
            handleSetModalStatus(ModalStatuses.DECIDE_ON_THE_FLY_DELETE_TASK);
            handleChangeModalParameters([
              {
                key: 'onCallback',
                value: () => {
                  removeDecideOnFlyActionMutation({
                    variables: {
                      id: id,
                    },
                  });
                },
              },
            ]);
          }}
        />
      </div>
    </div>
  );
};

const useResize = ({ setCount, ref }: any) => {
  const { isOpenMenu } = useMenuContext();

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (ref?.current?.offsetWidth >= 220) {
      return setCount(30);
    }
    if (ref?.current?.offsetWidth >= 190) {
      return setCount(25);
    }
    if (ref?.current?.offsetWidth >= 160) {
      return setCount(20);
    }
    if (ref?.current?.offsetWidth >= 130) {
      return setCount(16);
    }
    if (ref?.current?.offsetWidth >= 100) {
      return setCount(10);
    }

    return setCount(5);
  }, [isOpenMenu, width]);
};
