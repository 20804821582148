import './EditModal.scss';

import { Formik, FormikValues } from 'formik';
import React, { FC } from 'react';

import { ModalType } from '../../../../types/types';
import { addPolygonStatisticSchema } from '../../../../validation/schemas';
import { AppInput } from '../../../appInput/AppInput';
import { Button } from '../../../button/Button';

export const EditModal: FC<
  Pick<
    ModalType,
    | 'isOpen'
    | 'onSubmit'
    | 'initialValues'
    | 'onClose'
    | 'labelText'
    | 'buttonText'
    | 'colorsName'
    | 'inputName'
    | 'colors'
    | 'initialColorId'
  >
> = ({
  onSubmit,
  initialValues,
  colorsName,
  inputName,
  labelText,
  buttonText,
  colors,
}) => {
  const handleSubmit = (values: FormikValues) => {
    onSubmit(values);
  };

  const onSetColorHandler = (
    color: string,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) => {
    colorsName && setFieldValue(colorsName, color);
  };

  return (
    <Formik
      initialValues={initialValues || { polygonName: '', color: '' }}
      onSubmit={(e) => {
        handleSubmit(e);
      }}
      validationSchema={addPolygonStatisticSchema}
    >
      {({ setFieldValue, values, errors }) => {
        return (
          <div className='edit-modal' style={{ width: 'auto', maxWidth: 'none' }}>
            <div className='edit-modal__title'>
              <AppInput
                name={inputName || 'polygonName'}
                label={labelText}
                error={errors.polygonName as string}
              />
              {colors && (
                <div className='edit-modal__colors'>
                  <span>Цвет: </span>
                  <div className='edit-modal__colors-container'>
                    {colors?.map((color) => {
                      if (color.color)
                        return (
                          <div
                            key={color.id}
                            className='add-polygon-block-modal__colors-item'
                            style={{
                              backgroundColor: color.color || '#AFAFAF',
                              border: `1px solid #AFAFAF`,
                            }}
                            onClick={() => onSetColorHandler(color.color, setFieldValue)}
                          />
                        );
                    })}
                  </div>
                </div>
              )}
            </div>
            <div className='edit-modal__buttons'>
              <Button
                text={buttonText}
                width='100%'
                height='25px'
                onClick={() => handleSubmit(values)}
              />
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
