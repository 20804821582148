import './ShowKarmaModal.scss';

import moment from 'moment';
import React from 'react';

import { getUserAvatar } from '../../../../utils';

export const ShowKarmaModal = ({ modalParameters }: any) => {
  const isPositive = modalParameters?.initialValues?.karmaStatistics?.karma >= 0;

  return (
    <div className='showKarmaModal'>
      <div className='showKarmaModal__header'>
        <div className='showKarmaModal__header-left'>
          <img
            src={getUserAvatar(modalParameters?.initialValues?.avatar)}
            alt='avatar'
            className='showKarmaModal__header-avatar'
          />
          <div className='showKarmaModal__header-texts'>
            <div className='showKarmaModal__header-title'>Карма</div>
            <div className='showKarmaModal__header-userName'>
              {modalParameters?.initialValues?.login}
            </div>
          </div>
        </div>
        <div className='showKarmaModal__header-right'>
          <div className='showKarmaModal__header-allCount'>
            оценок: {modalParameters?.initialValues?.karmaStatistics?.items?.length}
          </div>

          <div
            className={`showKarmaModal__header-count ${
              isPositive
                ? 'showKarmaModal__header-positive'
                : 'showKarmaModal__header-negative'
            }`}
            style={{
              opacity:
                modalParameters?.initialValues?.karmaStatistics?.items?.length === 0
                  ? 0
                  : undefined,
            }}
          >
            {isPositive ? '+' : ''}
            {modalParameters?.initialValues?.karmaStatistics?.karma}
          </div>
        </div>
      </div>
      {modalParameters?.initialValues?.karmaStatistics?.items?.length === 0 && (
        <div className='showKarmaModal__body'>У данного пользователя еще нет оценок</div>
      )}
      <div className='showKarmaModal__body'>
        {modalParameters?.initialValues?.karmaStatistics?.items?.map((e: any) => {
          return (
            <div key={e?.id} className='showKarmaModal__body-item'>
              <div className='showKarmaModal__body-left'>
                <img
                  src={getUserAvatar(e?.user?.image)}
                  alt='avatar'
                  className='showKarmaModal__body-avatar'
                />
                <div className='showKarmaModal__body-userName'>{e?.user?.login}</div>
              </div>
              <div className='showKarmaModal__body-right'>
                <div className='showKarmaModal__body-time'>
                  {moment(parseInt(e?.createdAt)).format('DD.MM.YY')}
                </div>
                <div
                  className={`showKarmaModal__body-count ${
                    e?.isPlus
                      ? 'showKarmaModal__body-positive'
                      : 'showKarmaModal__body-negative'
                  }`}
                >
                  {e?.isPlus ? '+' : '-'}1
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
