import React from 'react';

type TreadsEditIConsType = {
  color?: string;
  className?: string;
  onClick?: (value: any) => void;
};

export const TreadsEditICons = ({ onClick }: TreadsEditIConsType) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        d='M13.6458 18.3323H3.57639C2.5229 18.3323 1.66663 17.476 1.66663 16.4225V6.35312C1.66663 5.29963 2.5229 4.44336 3.57639 4.44336H9.47913C9.76663 4.44336 9.99996 4.67669 9.99996 4.96419C9.99996 5.25169 9.76663 5.48503 9.47913 5.48503H3.57639C3.0979 5.48503 2.70829 5.87463 2.70829 6.35312V16.4225C2.70829 16.901 3.0979 17.2906 3.57639 17.2906H13.6458C14.1243 17.2906 14.5139 16.901 14.5139 16.4225V10.5198C14.5139 10.2323 14.7472 9.99896 15.0347 9.99896C15.3222 9.99896 15.5556 10.2317 15.5556 10.5198V16.4225C15.5556 17.476 14.6993 18.3323 13.6458 18.3323Z'
        fill='#303030'
        stroke='#303030'
        strokeWidth='0.3'
      />
      <path
        d='M7.75847 12.7614C7.62165 12.7614 7.48827 12.7073 7.39036 12.6087C7.26676 12.4858 7.21399 12.3086 7.24807 12.1385L7.73902 9.68297C7.75911 9.58163 7.80908 9.48919 7.88131 9.41696L15.0738 2.22537C15.8182 1.48087 17.0293 1.48087 17.7745 2.22537C18.1348 2.58573 18.3334 3.06498 18.3334 3.57539C18.3334 4.0858 18.1348 4.56492 17.7737 4.92541L10.5821 12.1178C10.5099 12.1906 10.4168 12.24 10.3161 12.2601L7.86122 12.751C7.82714 12.758 7.79243 12.7614 7.75847 12.7614ZM10.214 11.7497H10.221H10.214ZM8.72931 10.0421L8.42299 11.5767L9.95701 11.2698L17.0376 4.1893C17.2015 4.02463 17.2918 3.80732 17.2918 3.57539C17.2918 3.34346 17.2015 3.12602 17.0376 2.96148C16.7001 2.62324 16.1494 2.62324 15.8099 2.96148L8.72931 10.0421Z'
        fill='#303030'
        stroke='#303030'
        strokeWidth='0.2'
      />
      <path
        d='M16.4237 6.06046C16.2905 6.06046 16.1571 6.00972 16.0557 5.90761L14.0918 3.94304C13.8883 3.73959 13.8883 3.40975 14.0918 3.2063C14.2952 3.00285 14.6251 3.00285 14.8287 3.2063L16.7925 5.17087C16.9959 5.37432 16.9959 5.70416 16.7925 5.90761C16.6904 6.00908 16.5571 6.06046 16.4237 6.06046Z'
        fill='#303030'
        stroke='#303030'
        strokeWidth='0.2'
      />
    </svg>
  );
};
