import { useEffect, useState } from 'react';

export const useShowStatus2Filter = ({
  filterStatus,
}: {
  filterStatus: null | number;
}) => {
  const [showLocalStatus2Filter, setSowLocalStatus2Filter] = useState(false);
  useEffect(() => {
    setSowLocalStatus2Filter(!!filterStatus);
  }, [filterStatus]);

  const handleSetSowLocalStatus2Filter = () => {
    if (filterStatus) {
      setSowLocalStatus2Filter(!showLocalStatus2Filter);
    }
  };

  return { showLocalStatus2Filter, handleSetSowLocalStatus2Filter };
};
