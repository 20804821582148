import React from 'react';

type Plus2IconsType = {
  className?: string;
  color?: string;
  onClick?: (value: any) => void;
};

export const Plus2Icons = ({ className, color = '#303030', onClick }: Plus2IconsType) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M13.4665 7.46361H8.54226V2.53933C8.54226 2.24328 8.30191 2 8.00293 2C7.70396 2 7.46361 2.24328 7.46361 2.53933V7.46067H2.53933C2.3957 7.46067 2.25794 7.51637 2.15828 7.61895C2.05569 7.72154 2 7.85638 2 8C2 8.29897 2.24328 8.53933 2.53933 8.53933H7.46067V13.4607C7.46067 13.7596 7.70396 14 8 14C8.29897 14 8.53933 13.7567 8.53933 13.4607V8.54226H13.4607C13.7596 8.54226 14 8.29897 14 8.00293C14.0059 7.70396 13.7626 7.46361 13.4665 7.46361Z'
        fill={color}
      />
    </svg>
  );
};
