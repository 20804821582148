import './AppInput.scss';

import { useField } from 'formik';
import React from 'react';

type AppInputPropsType = {
  name: string;
  placeholder?: string;
  label?: string;
  type?: string;
  disabled?: boolean;
  width?: string | number;
  error?: string;
  hideError?: boolean;
  style?: any;
};

export const AppInput: React.FC<AppInputPropsType> = ({
  name,
  type = 'text',
  label,
  placeholder,
  disabled = false,
  error,
  hideError = false,
  style,
}) => {
  const [field, meta] = useField(name);
  const hasError = (Boolean(meta.error && meta.touched) || error) && !hideError;
  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <div
      className={'appInput__wrapper ' + (hasError && 'appInput__wrapper-error')}
      style={style}
    >
      {label && (
        <p onClick={() => inputRef?.current?.focus()} className='appInput__label'>
          {label}
        </p>
      )}
      <input
        ref={inputRef}
        value={field.value}
        type={type}
        onChange={field.onChange}
        className='appInput__field'
        placeholder={placeholder}
        name={name}
        disabled={disabled}
      />
      {hasError && <p className='appInput__text-error'>{meta.error}</p>}
    </div>
  );
};
