import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import {
  useGetProjectActivitiesQuery,
  useNewActivitySubscription,
} from '../graphql/generated/graphql';
import { useGetPrivateChatsQuery } from './useQueries';

export const useNewActivityRefetch = (search?: string | undefined) => {
  const { taskId, subtaskId } = useParams();

  const { data: ProjectActivitiesData, refetch: GetProjectActivitiesRefetch } =
    useGetProjectActivitiesQuery({});

  const { data: chatsData, refetch: refetchChatsData } = useGetPrivateChatsQuery({
    variables: {
      fetchPolicy: 'no-cache',
      data: {
        limit: 20,
        name: search || undefined,
      },
    },
  });

  const { data: activity, loading } = useNewActivitySubscription();

  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const [tasksSubtasksActivities, setTasksSubtasksActivities] = useState<
    typeof tasksSubtasksActivitiesWithoutTrash
  >([]);

  const tasksSubtasksActivitiesWithoutTrash =
    ProjectActivitiesData?.getProjectActivities?.map((activity) => {
      let taskSubtask: any = {};

      if (activity.subtask) {
        taskSubtask = {
          ...activity.subtask,
          pathname: `/task/${activity.task?.id}/subtask/${activity.subtask?.id}`,
        };
      } else if (activity.task) {
        taskSubtask = {
          ...activity.task,
          pathname: `/task/${activity.task?.id}`,
        };
      } else {
        const path =
          activity?.action === 'Performer invite' ? '/' : `/tread/${activity.thread?.id}`;

        taskSubtask = {
          ...activity?.thread,
          code: activity?.thread?.id,
          name: activity.thread?.scaleMapTask?.name,
          pathname: path,
        };
      }
      return {
        ...taskSubtask,
        action: activity.action,
        actioner: activity.actioner,
        project: activity.project,
      };
    });

  const [counts, setCounts] = useState(undefined);

  useEffect(() => {
    setCounts(totalUnreadMessagesCount() as any);
  }, [chatsData]);

  const totalUnreadMessagesCount = () => {
    const counts: Array<number> = [0];

    chatsData?.getPrivateChats.rows
      .filter((row) => row.chats?.length)
      .forEach((row) => {
        if (row.chats) {
          counts.push(row?.chats[0].unreadMessagesCount);
        }
      });

    return counts?.filter((e) => e > 0)?.length;
  };
  useEffect(() => {
    setTasksSubtasksActivities(() => [...(tasksSubtasksActivitiesWithoutTrash || [])]);
  }, [ProjectActivitiesData]);

  useEffect(() => {
    setTasksSubtasksActivities(
      (prevState) =>
        prevState?.filter((el) => el?.id !== Number(subtaskId ? subtaskId : taskId)) ||
        [],
    );
  }, [ProjectActivitiesData, taskId, subtaskId]);

  useEffect(() => {
    if (!loading) {
      if (activity?.newActivity === 'taskSubtask' && !isLoading) {
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
          GetProjectActivitiesRefetch();
        }, 1000);
      } else if (activity?.newActivity === 'message' && !isLoading) {
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
          refetchChatsData();
        }, 1000);
      }
    }
  }, [activity, location.pathname]);

  return {
    tasksSubtasksActivities,
    GetProjectActivitiesRefetch,
    chatsData,
    refetchChatsData,
    counts,
  };
};
