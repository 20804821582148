import { useEffect, useState } from 'react';

import {
  useAddedNewItemToThreadStorageSubscription,
  useDeleteFileMutation,
  useDeleteStorageItemMutation,
  useGetThreadFileLazyQuery,
  useTrackStorageEventClickedMutation,
  useUpdateFileMutation,
  useUpdateStorageItemMutation,
  useUpdateThreadFileCommentMutation,
  useUpdateThreadMutation,
} from '../../../graphql/generated/graphql';

export const useFileOptimize = ({ data, treadId, refetch }: any) => {
  const [storageFiles, setStorageFiles] = useState([]);
  const [storageImages, setStorageImages] = useState([]);

  const { data: dataUpdate } = useAddedNewItemToThreadStorageSubscription({
    variables: {
      threadId: parseInt(treadId),
    },
  });

  useEffect(() => {
    if (dataUpdate?.addedNewItemToThreadStorage?.threadId === parseInt(treadId)) {
      getThreadFile();
    }
  }, [dataUpdate]);

  const [getThreadFile] = useGetThreadFileLazyQuery({
    variables: {
      id: parseInt(treadId as any),
    },
    onCompleted(data) {
      setStorageFiles(data?.getThread?.storageFiles?.rows as any);
      setStorageImages(data?.getThread?.storageImages?.rows as any);
    },
    fetchPolicy: 'no-cache',
  });
  const [updateThreadFileCommentMutation] = useUpdateThreadFileCommentMutation({
    onCompleted: () => {
      getThreadFile();
    },
  });

  useEffect(() => {
    setStorageFiles(data?.getThread?.storageFiles?.rows);
    setStorageImages(data?.getThread?.storageImages?.rows as any);
  }, [data]);

  const [deleteFileMutation] = useDeleteFileMutation({
    onCompleted: () => {
      getThreadFile();
    },
  });
  const [deleteStorageItemMutation] = useDeleteStorageItemMutation({
    onCompleted: () => {
      getThreadFile();
    },
  });
  const [updateFileMutation] = useUpdateFileMutation({
    onCompleted: () => {
      getThreadFile();
    },
  });

  const [updateStorageItemMutation] = useUpdateStorageItemMutation({
    onCompleted: () => {
      getThreadFile();
    },
  });
  const [trackStorageEventClickedMutation] = useTrackStorageEventClickedMutation({
    onCompleted: () => {
      getThreadFile();
    },
  });
  const [updateThread] = useUpdateThreadMutation({
    onCompleted: () => {
      refetch();
    },
  });

  return {
    storageFiles,
    storageImages,
    getThreadFile,
    deleteFileMutation,
    deleteStorageItemMutation,
    updateFileMutation,
    updateStorageItemMutation,
    trackStorageEventClickedMutation,
    updateThreadFileCommentMutation,
    updateThread,
  };
};
