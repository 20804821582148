import './Storage.scss';

import React, { useEffect, useState } from 'react';

import { AccordionItemStorage } from '../../components';
import { StorageItemTred } from '../../components/storage/storageItemTred/StorageItemTred';
import { useGetThreadStorageProjectLazyQuery } from '../../graphql/generated/graphql';
import { Pagination } from './Pagination';

const limit = 12;

export const ProjectAllTreeadItem = ({
  filter,
  debouncedValue,
  setNoTredCount,
}: {
  filter: any;
  debouncedValue: string;
  setNoTredCount: (value: number | undefined) => void;
}) => {
  const [fileList, setFileList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(limit);

  const [getStorage, { data }] = useGetThreadStorageProjectLazyQuery({
    variables: {
      data: {
        search: debouncedValue,
        filter: filter,
        storageEntitiesLimit: dataPerPage,
        storageEntitiesOffset: (currentPage - 1) * dataPerPage,
      },
    },
    onCompleted: (data) => {
      setFileList(data?.getThreadStorageProject?.storageEntities?.rows as any);
    },
  });
  useEffect(() => {
    getStorage();
  }, []);

  useEffect(() => {
    if (data?.getThreadStorageProject?.storageEntities?.count) {
      setNoTredCount(data?.getThreadStorageProject?.storageEntities?.count);
    }
  }, [data?.getThreadStorageProject?.storageEntities?.count]);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (firstRender) {
      return setFirstRender(false);
    }
    getStorage();
  }, [currentPage]);

  if (fileList?.length === 0) return <div></div>;

  return (
    <AccordionItemStorage
      title={`#Треды без проекта`}
      pagination={
        <Pagination
          dataPerPage={dataPerPage}
          totalData={data?.getThreadStorageProject?.storageEntities?.count as number}
          paginate={paginate}
          currentPage={currentPage}
        />
      }
    >
      <div className='storage__itemWrapper-outer'>
        <div className='storage__itemWrapper-inner'>
          {fileList?.map((storageEntities, index) => {
            if (storageEntities) {
              return <StorageItemTred key={index} file={storageEntities as any} />;
            }
          })}
        </div>
      </div>
    </AccordionItemStorage>
  );
};
