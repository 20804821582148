import React from 'react';

type FlagSearchIconType = {
  className: string;
};

export const FlagSearchIcon = ({ className }: FlagSearchIconType) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M11.3833 3.58329C11.3449 3.39507 11.2426 3.22589 11.0938 3.10441C10.945 2.98292 10.7588 2.91659 10.5667 2.91663H4.58333C4.36232 2.91663 4.15036 3.00442 3.99408 3.1607C3.8378 3.31698 3.75 3.52895 3.75 3.74996V16.25C3.75 16.471 3.8378 16.6829 3.99408 16.8392C4.15036 16.9955 4.36232 17.0833 4.58333 17.0833C4.80435 17.0833 5.01631 16.9955 5.17259 16.8392C5.32887 16.6829 5.41667 16.471 5.41667 16.25V11.25H10.0833L10.2833 12.25C10.3583 12.64 10.7025 12.9208 11.1 12.9166H15.4167C15.6377 12.9166 15.8496 12.8288 16.0059 12.6725C16.1622 12.5163 16.25 12.3043 16.25 12.0833V5.41663C16.25 5.19561 16.1622 4.98365 16.0059 4.82737C15.8496 4.67109 15.6377 4.58329 15.4167 4.58329H11.5833L11.3833 3.58329Z'
        fill='#AFAFAF'
      />
    </svg>
  );
};
