import './AddTaskModal.scss';

import { Form, Formik } from 'formik';
import React from 'react';

import { appCharTask } from '../../../../validation/schemas';
import { AppInput } from '../../../appInput/AppInput';
import { Button } from '../../../index';

export const AddTaskModal = ({ modalParameters }: any) => {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        text: modalParameters?.initialEditTitleValues?.text
          ? modalParameters?.initialEditTitleValues?.text
          : '',
        color: modalParameters?.initialEditTitleValues?.color
          ? modalParameters?.initialEditTitleValues?.color
          : '',
      }}
      onSubmit={modalParameters.onCallback}
      validationSchema={appCharTask}
    >
      {({ setFieldValue, values, errors }) => {
        return (
          <Form className='AddTaskModal'>
            <AppInput name='text' label='Название:' />
            <div className='color-wrapper-inner d-flex'>
              <div className='color-wrapper d-flex'>
                Цвет:
                <div
                  className='color-item'
                  style={{
                    backgroundColor: '#149939',
                    border: values?.color === '#149939' ? '1px solid black' : '',
                  }}
                  onClick={() => {
                    setFieldValue('color', '#149939');
                  }}
                ></div>
                <div
                  className='color-item'
                  style={{
                    backgroundColor: '#FBE000',
                    border: values?.color === '#FBE000' ? '1px solid black' : '',
                  }}
                  onClick={() => {
                    setFieldValue('color', '#FBE000');
                  }}
                ></div>
                <div
                  className='color-item'
                  style={{
                    backgroundColor: '#E80025',
                    border: values?.color === '#E80025' ? '1px solid black' : '',
                  }}
                  onClick={() => {
                    setFieldValue('color', '#E80025');
                  }}
                ></div>
              </div>
              {errors?.color && <div className='color-errors'>{errors?.color}</div>}
            </div>
            <Button
              text={'Сохранить'}
              type='submit'
              width='100%'
              height='25px'
              margin='20px 0 0 0'
            />
          </Form>
        );
      }}
    </Formik>
  );
};
