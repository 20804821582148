import { useParams } from 'react-router-dom';

import * as GENERATED from '../../graphql/generated/graphql';
import {
  GetSubtaskNotesDocument,
  GetSubtaskNotesQuery,
  GetTaskNotesDocument,
  GetTaskNotesQuery,
} from '../../graphql/generated/graphql';

export const useCreateNoteMutation = () => {
  const { taskId, subtaskId } = useParams();

  return GENERATED.useCreateNoteMutation({
    update(cache, { data }) {
      if (!data?.createNote) {
        return null;
      }

      const task: GetTaskNotesQuery | null = cache.readQuery({
        query: GetTaskNotesDocument,
        variables: {
          id: Number(taskId),
        },
      });

      const subtask: GetSubtaskNotesQuery | null = cache.readQuery({
        query: GetSubtaskNotesDocument,
        variables: {
          id: Number(subtaskId),
        },
      });

      if (!subtaskId) {
        cache.writeQuery({
          query: GetTaskNotesDocument,
          variables: {
            id: Number(taskId),
          },
          data: {
            ...task,
            getTask: {
              ...task?.getTask,
              notes: [data.createNote, ...(task?.getTask.notes || [])],
            },
          },
        });
      } else if (subtaskId) {
        cache.writeQuery({
          query: GetSubtaskNotesDocument,
          variables: {
            id: Number(subtaskId),
          },
          data: {
            ...subtask,
            getSubtask: {
              ...subtask?.getSubtask,
              notes: [data.createNote, ...(subtask?.getSubtask.notes || [])],
            },
          },
        });
      }
    },
  });
};
