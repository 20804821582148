import React from 'react';

export const ForumNewMessageIcons = () => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.5455 0H2.45453C1.10108 0 0 1.14767 0 2.55834V8.40273C0 9.81339 1.10108 10.961 2.45453 10.961L8.24498 10.9611L11.0169 13.8501C11.1108 13.9481 11.2363 14 11.3641 14C11.4273 14 11.4911 13.9872 11.5518 13.961C11.7353 13.8818 11.8549 13.6953 11.8549 13.4883V10.9408C13.0629 10.7818 14 9.70418 14 8.40273V2.55834C14 1.14767 12.8989 0 11.5455 0ZM13.0182 8.40277C13.0182 9.24918 12.3575 9.93775 11.5455 9.93775H11.364C11.0929 9.93775 10.8731 10.1669 10.8731 10.4494V12.2531L8.79546 10.0876C8.7034 9.99162 8.5786 9.93775 8.44833 9.93775H2.45453C1.64245 9.93775 0.981811 9.24918 0.981811 8.40277V2.55838C0.981811 1.71197 1.64245 1.0234 2.45453 1.0234H11.5455C12.3575 1.0234 13.0182 1.71197 13.0182 2.55838V8.40277Z'
        fill='white'
      />
      <path
        d='M10.613 3.62305H3.3869C3.1158 3.62305 2.896 3.85211 2.896 4.13471C2.896 4.41727 3.1158 4.64637 3.3869 4.64637H10.613C10.8841 4.64637 11.1039 4.41727 11.1039 4.13471C11.1039 3.85211 10.8842 3.62305 10.613 3.62305Z'
        fill='white'
      />
      <path
        d='M10.613 6.48828H3.3869C3.1158 6.48828 2.896 6.71734 2.896 6.99994C2.896 7.28254 3.1158 7.5116 3.3869 7.5116H10.613C10.8841 7.5116 11.1039 7.2825 11.1039 6.99994C11.1039 6.71738 10.8842 6.48828 10.613 6.48828Z'
        fill='white'
      />
    </svg>
  );
};
