import './CommentCounterModal.scss';

import { Form, Formik } from 'formik';
import React from 'react';

import { commentSchema } from '../../../../validation/schemas';
import { AppInput, Button } from '../../../index';

export const CommentCounterModal = ({ onClose, modalParameters }: any) => {
  return (
    <Formik
      onSubmit={(e) => {
        modalParameters?.onCallback(e);
        onClose();
      }}
      initialValues={modalParameters?.initialValues}
      validationSchema={commentSchema}
    >
      {() => {
        return (
          <Form className='commentCounterModal'>
            <AppInput name={'comment'} label={'Название:'} />
            <div className='commentCounterModal__buttons'>
              <Button type='submit'>Сохранить</Button>
              <Button onClick={onClose}>Отменить</Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
