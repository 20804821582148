import './ArrowSmallIcon.scss';

import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const ArrowSmallIcon: FC<ArrowSmallIconType> = ({
  width = '8px',
  height = '4px',
  color = '#FFFFFF',
  className = 'arrow-small-icon',
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 8 4'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M6.97833 0.619171L6.97828 0.619123L6.97246 0.624852L4.08013 3.47083C4.06039 3.4888 4.03353 3.5 4.00014 3.5C3.96632 3.5 3.93935 3.48794 3.92205 3.47187L1.02847 0.624665L1.02853 0.624605L1.02189 0.618308C1.00315 0.600534 1.00116 0.589565 1.00057 0.586344L1.00057 0.586331C0.99946 0.580233 0.99947 0.569109 1.00574 0.554687C1.01201 0.540256 1.02273 0.527003 1.03674 0.517588C1.04886 0.509439 1.06939 0.5 1.10503 0.5H6.89568C6.93068 0.5 6.95087 0.509274 6.96292 0.517385C6.97687 0.526776 6.98772 0.540117 6.99412 0.554828C7.00051 0.569532 7.00056 0.580957 6.99942 0.587272C6.99881 0.590676 6.99681 0.601572 6.97833 0.619171Z'
        fill={color}
        stroke={color}
      />
    </svg>
  );
};

type ArrowSmallIconType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
>;
