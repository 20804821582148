import React, { useEffect } from 'react';

import { Preloader } from '../components';
import { useGetCurrentUserQuery } from '../graphql/generated/graphql';

type Props = {
  children: React.ReactNode;
};

export type currentUserDataTypeProps = {
  __typename?: 'User';
  id?: number | null;
  login?: string | null;
  fullName?: string | null;
  theNote?: string | null;
  image?: string | null;
  role?: string | null;
  IsExistUnViewedForum: boolean;
  isAccessToStorageProjects: boolean;
  karmaStatistics?: { __typename?: 'UserKarmaStatistics'; karma: number } | null;
};

type ContextProps = {
  currentUserData?: currentUserDataTypeProps | null;
  refetch: () => void;
  isAuth: boolean;
  onSuccessAuth: (token: string) => void;
  onLogout: () => void;
  loading: boolean;
};

export const AuthContext = React.createContext<ContextProps>({
  currentUserData: undefined,
  refetch: () => {},
  isAuth: false,
  onSuccessAuth: () => {},
  onLogout: () => {},
  loading: false,
});

export function useAuthContext() {
  const authContext = React.useContext(AuthContext);

  if (!authContext) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return authContext;
}

export const actionTypes = {
  SUCCESS_AUTH: 'SUCCESS_AUTH',
  LOGOUT: 'LOGOUT',
  INIT: 'INIT',
} as const;

export const AuthProvider = ({ children }: Props) => {
  const token = localStorage?.getItem('token');

  const { data, loading, refetch, client } = useGetCurrentUserQuery({
    skip: !token,
  });

  const [state, dispatch] = React.useReducer(authReducer, {
    isAuth: false,
    isInit: false,
  });

  useEffect(() => {
    if (!loading) {
      if (data?.getCurrentUser) {
        dispatch({ type: actionTypes.SUCCESS_AUTH });
      } else {
        dispatch({ type: actionTypes.INIT });
      }
    }
  }, [data, loading]);

  const handleSuccessAuth = (token: string) => {
    localStorage.setItem('token', token);
    client.resetStore().then(() => dispatch({ type: actionTypes.SUCCESS_AUTH }));
  };

  const handleLogout = () => {
    client.clearStore();
    localStorage.removeItem('token');
    dispatch({ type: actionTypes.LOGOUT });
  };

  const value = {
    currentUserData: data?.getCurrentUser,
    refetch,
    loading,
    isAuth: state.isAuth,
    onSuccessAuth: handleSuccessAuth,
    onLogout: handleLogout,
  };

  if (!state.isInit) {
    return <Preloader fullScreen />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

type AuthStateType = {
  isAuth: boolean;
  isInit: boolean;
};

type ActionType = { type: 'SUCCESS_AUTH' } | { type: 'LOGOUT' } | { type: 'INIT' };

function authReducer(state: AuthStateType, action: ActionType) {
  switch (action.type) {
    case actionTypes.INIT: {
      return { ...state, isInit: true };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        isAuth: false,
      };
    }
    case actionTypes.SUCCESS_AUTH: {
      return {
        ...state,
        isAuth: true,
        isInit: true,
      };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}
