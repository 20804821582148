import React from 'react';

type TreadTemplateIconType = { onClick?: () => void; color?: string };

export const TreadTemplateOpenIcon = ({
  onClick,
  color = 'white',
}: TreadTemplateIconType) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        d='M21 3H3V10H21V3ZM21 14H16V21H21V14ZM12 14H3V21H12V14Z'
        fill={color}
        stroke='#303030'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
