import * as yup from 'yup';

import * as MESSAGES from '../messages';

export const weeklyTemplate = yup.object().shape({
  isDefault: yup.boolean(),
  name: yup
    .string()
    .max(40, MESSAGES.INCORRECT_MAX_LENGTH_40)
    .required(MESSAGES.REQUIRE_MESSAGE),
  role: yup.string(),
  sampleItems: yup
    .array()
    .min(1, 'В шаблоне должна быть хотя бы одна задача')
    .of(
      yup.object().shape({
        color: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
        isDaily: yup.boolean().required(MESSAGES.REQUIRE_MESSAGE),
        scaleMapFileId: yup.number().required(MESSAGES.REQUIRE_MESSAGE),
        scheduledTime: yup
          .number()
          .min(0, 'Значение должно быть больше нуля')
          .required(MESSAGES.REQUIRE_MESSAGE),
        scheduledTimePerDay: yup.number(),
      }),
    )
    .test(
      'sum',
      'Сумма времени ежедневных задач должна быть не больше 24 часа',
      function (values) {
        const allTimeValue = values
          ?.filter((e) => e.isDaily)
          ?.map((e) => e.scheduledTime)
          ?.reduce((acc: any, val: any) => acc + val, 0);

        return allTimeValue <= 24;
      },
    )
    .test(
      'sum',
      'Сумма времени задач за неделю должна быть не больше 168 часа',
      function (values) {
        const allTimeValue = values
          ?.map((e) => {
            if (e?.isDaily) {
              return e.scheduledTime && e.scheduledTime * 7;
            }
            return e.scheduledTime;
          })
          ?.reduce((acc: any, val: any) => acc + val, 0);

        return allTimeValue <= 168;
      },
    )
    .required(MESSAGES.REQUIRE_MESSAGE),
});
