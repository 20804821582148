import React from 'react';

export const HeaderGraphicsIcons = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.3333 17.5H1.66663V2.5H3.33329V15.8333H4.99996V14.1667H8.33329V15.8333H9.99996V13.3333H13.3333V15.8333H15V14.1667H18.3333V17.5ZM15 11.6667H18.3333V13.3333H15V11.6667ZM9.99996 5H13.3333V7.5H9.99996V5ZM13.3333 12.5H9.99996V8.33333H13.3333V12.5ZM4.99996 8.33333H8.33329V10H4.99996V8.33333ZM8.33329 13.3333H4.99996V10.8333H8.33329V13.3333Z'
        fill='white'
      />
    </svg>
  );
};
