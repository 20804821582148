import React, { FC } from 'react';

import { ArrowLeftIconType } from '../arrowLeftIcon/ArrowLeftIcon';

export const AddPageIcon: FC<AddPageIconType> = ({
  width = '20px',
  height = '20px',
  className = 'add-page-icon',
  color = '#FFFFFF',
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 13 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.8125 0.9625L9.7875 4.025L9.875 4.375V6.125H9V5.25H5.5V1.75H1.125V11.375H4.625V12.25H0.6875L0.25 11.8125V1.3125L0.6875 0.875H6.55L6.8125 0.9625ZM6.375 1.75V4.375H8.9125L6.375 1.75ZM9.875 14H9V11.375H6.375V10.5H9V7.875H9.875V10.5H12.5V11.375H9.875V14Z'
        fill={color}
      />
    </svg>
  );
};

type AddPageIconType = Pick<
  ArrowLeftIconType,
  'width' | 'height' | 'color' | 'className' | 'onClick'
>;
