import './ReportChronographModal.scss';

import moment from 'moment';
import React, { useState } from 'react';

import { useGetChronographItemsQuery } from '../../../../graphql/generated/graphql';
import { Button } from '../../../index';
import { DatePickerC } from './DatePickerC';
import { Popup } from './Popup';
import { TableReport } from './TableReport';

export const ReportChronographModal = ({ onClose }: any) => {
  const [selectId, setSelectId] = useState('DatePicker');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const { data } = useGetChronographItemsQuery({
    variables: {
      data: {
        from: moment(startDate).format('YYYY-MM-DD'),
        to: moment(endDate).format('YYYY-MM-DD'),
      },
    },
    fetchPolicy: 'network-only',
  });

  const dataLogs = data?.getChronographItems?.map((e) => {
    const convertedDate = moment.utc(e?.date).format('DD-MM-YYYY');
    const convertedDate2 = moment(e?.date);
    const hours = convertedDate2.utc().format('HH');
    const minutes = convertedDate2.utc().format('mm');
    const formattedTime = `${hours}:${minutes}`;
    return {
      convertedDate,
      formattedTime,
      fileName: e?.scaleMapFile?.name,
      taskName: e?.scaleMapTask?.name,
    };
  });

  const fillEmpty = () => {
    const filledDataLog: any = [];
    if (!dataLogs) return [];
    for (let date = moment(startDate); date.isSameOrBefore(endDate); date.add(1, 'day')) {
      for (let hour = 0; hour < 24; hour++) {
        const formattedDate = date.format('DD-MM-YYYY');
        const formattedTime = hour.toString().padStart(2, '0') + ':00';

        const existingDataIndex = dataLogs.findIndex((data) => {
          return (
            data.convertedDate === formattedDate && data.formattedTime === formattedTime
          );
        });
        if (existingDataIndex === -1) {
          filledDataLog.push({
            convertedDate: formattedDate,
            formattedTime: formattedTime,
            fileName: 'ОТДЫХ',
            taskName: '',
          });
        } else {
          filledDataLog.push(dataLogs[existingDataIndex]);
        }
      }
    }
    return filledDataLog;
  };

  const dataLogs2 = fillEmpty();

  const mergeTasks2 = processFileInfos(dataLogs2);

  const fileName = `from:${moment(startDate).format('DD/MM/YYYY')}_to:${moment(
    endDate,
  ).format('DD/MM/YYYY')}`;

  return (
    <div className='ReportChronographModal'>
      <div className='ReportChronographModal__title-wrapper'>
        <div className='ReportChronographModal__title'>Отчет за указанный период</div>
        <div>
          <Popup dataLogs={mergeTasks2} fileName={fileName} />
        </div>
      </div>
      <div className='ReportChronographModal__pickers'>
        <DatePickerC
          id={'DatePicker'}
          selectId={selectId}
          setSelectId={setSelectId}
          date={startDate}
          setDate={setStartDate}
        />
        <DatePickerC
          id={'DatePicker2'}
          selectId={selectId}
          setSelectId={setSelectId}
          date={endDate}
          setDate={setEndDate}
        />
      </div>
      <div>
        <TableReport dataLogs={mergeTasks2} />
        <div className='ReportChronographModal__button'>
          <Button onClick={onClose} width={'100%'}>
            Далее
          </Button>
        </div>
      </div>
    </div>
  );
};
interface FileInfo {
  convertedDate: string;
  formattedTime: string;
  fileName: string;
  taskName?: string;
}

interface ProcessedFileInfo {
  convertedDateFrom: string;
  convertedDateTo: string;
  formattedTimeFrom: string;
  formattedTimeTo: string;
  fileName: string;
  taskName: string;
}

function isNextHour(fileInfo1: FileInfo, fileInfo2: FileInfo): boolean {
  const date1 = moment(
    fileInfo1.convertedDate + ' ' + fileInfo1.formattedTime,
    'DD-MM-YYYY HH:mm',
  );
  const date2 = moment(
    fileInfo2.convertedDate + ' ' + fileInfo2.formattedTime,
    'DD-MM-YYYY HH:mm',
  );

  const diffInHours = date2.diff(date1, 'hours');
  const diffInDays = date2.diff(date1, 'days');

  // Check that the time interval between fileInfo1 and fileInfo2 is exactly 1 hour

  return (
    (diffInDays === 0 && diffInHours === -1) || (diffInDays === -1 && diffInHours === -1)
  );
}

function processFileInfos(fileInfos: FileInfo[] | undefined): ProcessedFileInfo[] {
  if (!fileInfos || fileInfos.length === 0) {
    return [];
  }

  const processedFileInfos: ProcessedFileInfo[] = [];
  let currentGroup: FileInfo[] = [];

  for (const fileInfo of fileInfos) {
    if (
      currentGroup.length > 0 &&
      fileInfo.fileName === currentGroup[0].fileName &&
      fileInfo.taskName === currentGroup[0].taskName &&
      isNextHour(fileInfo, currentGroup[currentGroup.length - 1])
    ) {
      currentGroup.push(fileInfo);
    } else {
      if (currentGroup.length > 0) {
        processedFileInfos.push(convertToProcessedFileInfo(currentGroup));
      }
      currentGroup = [fileInfo];
    }
  }

  if (currentGroup.length > 0) {
    processedFileInfos.push(convertToProcessedFileInfo(currentGroup));
  }

  return processedFileInfos;
}

function convertToProcessedFileInfo(fileGroup: FileInfo[]): ProcessedFileInfo {
  const firstFileInfo = fileGroup[0];
  const lastFileInfo = fileGroup[fileGroup.length - 1];

  const formattedTimeFrom = lastFileInfo.formattedTime;
  const addedTime = moment(formattedTimeFrom, 'HH:mm').add(1, 'hours');
  const formattedTimeTo = addedTime.format('HH:mm');

  return {
    convertedDateFrom: firstFileInfo.convertedDate,
    convertedDateTo: lastFileInfo.convertedDate,
    formattedTimeFrom: firstFileInfo.formattedTime,
    formattedTimeTo: formattedTimeTo,
    fileName: firstFileInfo.fileName,
    taskName: firstFileInfo.taskName || '',
  };
}
