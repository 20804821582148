import './DecideOnTheFlyPackage.scss';

import React, { useState } from 'react';

import { DecideOnTheFlyControl } from './DecideOnTheFlyControl/DecideOnTheFlyControl';
import { DecideOnTheFlyPackages } from './DecideOnTheFlyPackages/DecideOnTheFlyPackages';

export const DecideOnTheFlyPackage = () => {
  const [statePage, setStatePage] = useState(1);

  return (
    <div className='DecideOnTheFlyPackage__wrapper'>
      {statePage === 1 && <DecideOnTheFlyPackages setStatePage={setStatePage} />}
      {statePage === 2 && <DecideOnTheFlyControl setStatePage={setStatePage} />}
    </div>
  );
};
