import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { SendLevels } from '../types/enums';
import {
  changeParentLinkAndParentNameType,
  createSimpleNameArrayType,
  findType,
  polygonBlockItemType,
  polygonToolsChangeNameAndColorType,
  polygonToolsChangeParameterType,
  polygonToolsChangeType,
  polygonToolsCheckDeselectType,
  polygonToolsCrateNewType,
  polygonToolsDeleteType,
  polygonToolsDragEndType,
  polygonToolsSelectType,
  polygonToolsTransformEndType,
  RectType,
} from '../types/types';
import { idGenerate } from '../utils';

export const usePolygon = () => {
  const { polygonId } = useParams();

  const [colorsData, setColorsData] = useState<any[]>([{ id: 1, color: '', name: '' }]);

  const [mockProjectTitle, setMockProjectTitle] = useState('Главная страница десктоп');

  const [descriptionMenuOpen, setDescriptionMenuOpen] = useState(false);

  const widthConstant = 350;
  const heightConstant = 65;

  const [rectangles, setRectangles] = React.useState<Array<RectType>>([]);

  const [selectedIdRect, setSelectIdRect] = React.useState<string | number | null>(null);

  useEffect(() => {
    setSelectIdRect(null);
  }, [polygonId]);

  const find = ({ id }: findType) => {
    const items = rectangles.slice();
    const item = items.find((i: RectType) => `${i.id}` === `${id}`);
    const index = items.indexOf(item as RectType);
    return { index, items, item };
  };

  const getSelectedRect = () => {
    const { item } = find({ id: selectedIdRect });
    return item;
  };

  const getRectDescriptionById = (rectId: string | number | null) => {
    return rectangles.find((rect) => rect.id === rectId);
  };

  const descriptionMenuOpenHandler = (value: boolean) => {
    setDescriptionMenuOpen(value);
  };

  const polygonTools = {
    crateNew: ({ name, color, file, text, width, height }: polygonToolsCrateNewType) => {
      const items = rectangles.slice();

      items.push({
        x: 10,
        y: 10,
        width: width ? width : widthConstant,
        height: height ? height : heightConstant,
        fill: color || 'white',
        id: idGenerate(),
        text: text || '',
        textObj: {
          fontSize: 16,
          fontWeight: 'normal',
          fontStyle: 'normal',
          textDecoration: 'none',
          textAlign: 'left',
        },
        name: name || '',
        draggable: true,
        isStatus: 'Upsert',
        nameArray: createSimpleNameArray({ rectangles }) as any,
        file: file,
      });
      setRectangles(items);
    },
    changeParameter: ({ id, key, value }: polygonToolsChangeParameterType) => {
      const { index, items, item } = find({ id });
      items.splice(index, 1);
      items.push({ ...item, isStatus: 'Upsert', [key]: value } as RectType);
      setRectangles(items);
    },
    changeParentLinkAndParentName: ({
      id,
      parentLink,
      parentName,
    }: changeParentLinkAndParentNameType) => {
      const { index, items, item } = find({ id });
      items.splice(index, 1);

      let deletedLink = item?.deletedLink;

      if (!parentLink && item?.parentLink && !item?.parentLinkUpdate) {
        deletedLink = item?.parentLink;
      }

      items.push({
        ...item,
        isStatus: 'Upsert',
        parentLinkUpdate: true,
        parentLink: parentLink,
        deletedLink: deletedLink,
        parentName: parentName,
      } as RectType);
      setRectangles(items);
    },
    changeNameAndColor: ({ id, color, name }: polygonToolsChangeNameAndColorType) => {
      const { index, items, item } = find({ id });
      items.splice(index, 1);
      items.push({ ...item, isStatus: 'Upsert', fill: color, name: name } as RectType);
      setRectangles(items);
    },
    delete: ({ id }: polygonToolsDeleteType) => {
      const { index, items, item } = find({ id });
      items.splice(index, 1);
      // if the element exists in the base its id number
      // and you need to change its status
      if (typeof id === 'number') {
        items.push({ ...item, isStatus: 'Delete' } as RectType);
      }
      setRectangles(items);
      setSelectIdRect(null);
    },
    change: ({ rect, index }: polygonToolsChangeType) => {
      const rects = rectangles.slice();
      rects[index] = { ...rect, isStatus: 'Upsert' };
      setRectangles(rects);
    },
    dragStart: () => {
      setSelectIdRect(null);
    },
    dragEnd: ({ event, onChange, shapeProps }: polygonToolsDragEndType) => {
      onChange({
        ...shapeProps,
        x: event.target.x(),
        y: event.target.y(),
      });
    },
    checkDeselect: ({ event }: polygonToolsCheckDeselectType) => {
      const clickedOnEmpty = event.target === event.target.getStage();
      if (clickedOnEmpty) {
        setSelectIdRect(null);
      }
    },
    select: ({ id }: polygonToolsSelectType) => {
      setSelectIdRect(id);
    },
    transformEnd: ({ shapeRef, onChange, shapeProps }: polygonToolsTransformEndType) => {
      const node = shapeRef.current;
      const scaleX = node.scaleX();
      const scaleY = node.scaleY();
      node.scaleX(1);
      node.scaleY(1);
      onChange({
        ...shapeProps,
        x: node.x(),
        y: node.y(),
        width: Math.max(5, node.width() * scaleX),
        height: Math.max(node.height() * scaleY),
      });
    },
    blockItem: ({ id, value }: polygonBlockItemType) => {
      const rects = rectangles.slice();
      const { index } = find({ id });

      const rect = rects[index];
      rects[index] = { ...rect, draggable: value, isStatus: 'Upsert' };
      setRectangles(rects);
    },
    setRectTask: (value: any) => {
      const rects = rectangles.slice();
      const newRect = rects.map((e) => {
        const taskId = value?.find((i: any) => `${i.rectID}` === `${e.id}`)?.taskId;
        return { ...e, task: taskId ? { id: taskId } : undefined };
      });
      setRectangles(newRect);
    },
    sendToLayer: (type: SendLevels) => {
      if (type === 'up' || type === 'down') {
        const selectRect = rectangles?.filter((e: RectType) => e.id === selectedIdRect);
        const newArr = rectangles.filter((e: RectType) => e.id !== selectedIdRect);

        if (type === 'up') {
          setRectangles([...newArr, selectRect[0]]);
        } else {
          setRectangles([selectRect[0], ...newArr]);
        }
      } else if (type === 'bottom') {
        const idx = rectangles?.findIndex((e: RectType) => e.id === selectedIdRect);

        if (idx !== 0) {
          const newArr = [...rectangles];
          newArr[idx - 1] = rectangles[idx];
          newArr[idx] = rectangles[idx - 1];
          setRectangles([...newArr]);
        }
      } else if (type === 'top') {
        const idx = rectangles?.findIndex((e: RectType) => e.id === selectedIdRect);

        if (idx !== rectangles?.length - 1) {
          const newArr = [...rectangles];
          newArr[idx + 1] = rectangles[idx];
          newArr[idx] = rectangles[idx + 1];
          setRectangles([...newArr]);
        }
      }
    },
  };

  const createSimpleNameArray = ({ rectangles }: createSimpleNameArrayType) => {
    const arr = ['0'];
    rectangles?.map((e: RectType) => {
      arr.push(e.nameArray[0]);
    });
    return `${[Math.max(...(arr as any)) + 1]}`;
  };

  return {
    rectangles,
    setRectangles,
    polygonTools,
    selectedIdRect,
    setSelectIdRect,
    getSelectedRect,
    getRectDescriptionById,
    mockProjectTitle,
    descriptionMenuOpenHandler,
    descriptionMenuOpen,
    setMockProjectTitle,
    colorsData,
    setColorsData,
  };
};
