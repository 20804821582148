import React from 'react';

export const FlagCalendarIcon = () => {
  return (
    <svg
      width='14'
      height='16'
      viewBox='0 0 14 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.125 0.5V15.5H1.375V10.5H13.875V0.5H0.125ZM1.375 1.75H3.25V3.625H5.125V1.75H7V3.625H8.875V1.75H10.75V3.625H12.625V5.5H10.75V7.375H12.625V9.25H10.75V7.375H8.875V9.25H7V7.375H5.125V9.25H3.25V7.375H1.375V5.5H3.25V3.625H1.375V1.75ZM3.25 5.5V7.375H5.125V5.5H3.25ZM5.125 5.5H7V3.625H5.125V5.5ZM7 5.5V7.375H8.875V5.5H7ZM8.875 5.5H10.75V3.625H8.875V5.5Z'
        fill='white'
      />
    </svg>
  );
};
