import * as GENERATED from '../../graphql/generated/graphql';
import { SortingField, SortingOrders } from '../../graphql/generated/graphql';

export const useGetExpensesQuery = (date?: Date) => {
  return GENERATED.useGetExpensesQuery({
    variables: {
      expensesDate: date?.toISOString().substr(0, 7),
      data: {
        expensesDate: date?.toISOString().substr(0, 7),
        limit: 100,
        statusId: null,
        sort: { field: SortingField.Number, type: SortingOrders.Asc },
        isTaskStorage: false,
      },
    },
  });
};
