import React from 'react';

type FlagIconsType = {
  className?: string;
};
export const FlagIcons = ({ className }: FlagIconsType) => {
  return (
    <svg
      width='10'
      height='12'
      viewBox='0 0 10 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M0.666667 11.6667C0.489856 11.6667 0.320287 11.5964 0.195262 11.4714C0.070238 11.3464 0 11.1768 0 11V1C0 0.823192 0.070238 0.653623 0.195262 0.528599C0.320287 0.403575 0.489856 0.333337 0.666667 0.333337H4.92C5.04424 0.332472 5.16626 0.366345 5.27227 0.431133C5.37829 0.495921 5.46409 0.589047 5.52 0.700004L6 1.66667H9.33333C9.51014 1.66667 9.67971 1.73691 9.80474 1.86193C9.92976 1.98696 10 2.15653 10 2.33334V7.66667C10 7.84348 9.92976 8.01305 9.80474 8.13807C9.67971 8.2631 9.51014 8.33334 9.33333 8.33334H5.74C5.61689 8.33296 5.49628 8.29851 5.39156 8.23379C5.28683 8.16907 5.20207 8.07661 5.14667 7.96667L4.66667 7H1.33333V11C1.33333 11.1768 1.2631 11.3464 1.13807 11.4714C1.01305 11.5964 0.843478 11.6667 0.666667 11.6667ZM1.33333 1.66667V5.66667H5.33333L6 7H8.66667V3H5.33333L4.66667 1.66667H1.33333Z'
        fill='#AFAFAF'
      />
    </svg>
  );
};
