import './SelectTypeChat.scss';

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  useActivityInChatSubscription,
  useGetTredChatReadQuery,
  useReadMessagesByChatIdMutation,
} from '../../graphql/generated/graphql';
import { chatType } from '../../types/enums';

export const SelectTypeChat = ({ setChatTypeData, chatTypeData, chatId }: any) => {
  const { treadId } = useParams();
  const { data: chat, refetch } = useGetTredChatReadQuery({
    variables: {
      id: parseInt(treadId as string),
    },
  });

  const { data } = useActivityInChatSubscription({
    variables: {
      chatId: parseInt(chatId),
    },
  });

  const [readMessagesByChatIdMutation] = useReadMessagesByChatIdMutation({
    onCompleted: () => {
      refetch();
    },
  });

  useEffect(() => {
    refetch();
  }, [
    data?.activityInChat?.chat?.isReadActivities,
    data?.activityInChat?.chat?.isReadMessages,
    chatTypeData,
    data?.activityInChat?.status,
  ]);

  useEffect(() => {
    if (!chatId) return;
    if (!chat?.getThread?.chat?.isReadActivities && chatTypeData === chatType.logs) {
      readMessagesByChatIdMutation({
        variables: { chatId: parseInt(chatId), isActivity: true },
      });
    }
    if (
      !chat?.getThread?.chat?.isReadMessages &&
      chatTypeData === chatType.messagesList
    ) {
      readMessagesByChatIdMutation({
        variables: { chatId: parseInt(chatId), isActivity: false },
      });
    }
  }, [
    chat?.getThread?.chat?.isReadActivities,
    chat?.getThread?.chat?.isReadMessages,
    chatTypeData,
    chatId,
  ]);

  return (
    <div className='SelectTypeChat__selectChat'>
      <div
        className='SelectTypeChat__selectChat-button'
        onClick={() => {
          setChatTypeData(chatType.logs);
        }}
        style={{
          backgroundColor: chatTypeData === chatType.logs ? 'gray' : undefined,
        }}
      >
        Логи
        <div
          className='SelectTypeChat__selectChat-circle'
          style={{
            backgroundColor: !chat?.getThread?.chat?.isReadActivities ? 'red' : '',
          }}
        ></div>
      </div>
      <div
        className='SelectTypeChat__selectChat-button ml10'
        onClick={() => {
          setChatTypeData(chatType.messagesList);
        }}
        style={{
          backgroundColor: chatTypeData === chatType.messagesList ? 'gray' : undefined,
        }}
      >
        Чат
        <div
          className='SelectTypeChat__selectChat-circle2'
          style={{
            backgroundColor: !chat?.getThread?.chat?.isReadMessages ? 'red' : '',
          }}
        ></div>
      </div>
    </div>
  );
};
