import './MenuLeftItem.scss';

import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { getUserAvatar, textSlice } from '../../../utils';

type MenuLeftItemPropsType = {
  projectName: string | null | undefined;
  avatar: string | null | undefined;
  userName: string | undefined | null;
  code: string | null | undefined;
  itemName: string | null | undefined;
  pathname: string;
  metaName?: string | null;
};

export const MenuLeftItem: FC<MenuLeftItemPropsType> = ({
  pathname,
  code,
  projectName,
  avatar,
  userName,
  itemName,
  metaName,
}) => {
  return (
    <div className={'menuLeft__item'}>
      <div className='menuLeftItem__title'>
        <div className='menuLeftItem_name'>
          {metaName ? (
            <div
              style={{
                backgroundColor: 'rgb(226, 226, 226)',
                padding: '2px',
                borderRadius: '2px',
              }}
            >
              {textSlice({ text: metaName, count: 14 })}
            </div>
          ) : (
            projectName
          )}
        </div>
        <div className='menuLeftItem_user'>
          <img src={getUserAvatar(avatar)} alt='avatar' />
          <span>{userName}</span>
        </div>
      </div>
      <div className='menuLeftItem__description'>
        <Link to={pathname} className='menuLeftItem__description--link'>
          {itemName}
        </Link>
        <span className='menuLeftItem__description--hash'>
          <b>&nbsp;{`#${code}`}</b>
        </span>
      </div>
    </div>
  );
};
