import './ConfirmModal.scss';

import React, { FC } from 'react';

import { Button } from '../button/Button';
import { Modal } from '../modal/Modal';

export const ConfirmModal: FC<ConfirmModalType> = ({
  isOpen,
  onClose,
  callback,
  confirmTitle,
  additionalConfirmTitle,
  userName,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className='confirmModal' style={{ width: 'auto', maxWidth: 'none' }}>
        {confirmTitle && (
          <p>
            Вы действительно хотите удалить{' '}
            {additionalConfirmTitle && additionalConfirmTitle} <b>{confirmTitle}&nbsp;</b>
            ?
          </p>
        )}
        {userName && (
          <p>
            Вы действительно хотите удалить пользователя <b>{userName}&nbsp;</b>?
          </p>
        )}
        <div className='confirmModal--buttons'>
          <Button
            text='Удалить'
            onClick={callback}
            width='40%'
            isCancelButton
            height={25}
          />
        </div>
      </div>
    </Modal>
  );
};

type ConfirmModalType = {
  isOpen: boolean;
  confirmTitle?: string | null | undefined;
  additionalConfirmTitle?: string | null | undefined;
  userName?: string | null | undefined;
  callback: () => void;
  onClose: () => void;
};
