import React, { useRef } from 'react';
import { Group, Image, Rect, Text, Transformer } from 'react-konva';
import useImage from 'use-image';

import OptionsIcon from '../../../assets/icons/link.svg';
import { BASE_URL } from '../../../constants/constants';
import { HoverRectIdType } from '../../../types/enums';
import { multiselectToolsType, polygonToolsType, RectType } from '../../../types/types';
import { EditText } from './EditText/EditText';

type RectangleType = {
  shapeProps: any;
  isSelected: boolean;
  onSelect: (e: any) => void;
  onChange: (e: any) => void;
  rect: RectType;
  rectangles: Array<RectType>;
  setRectangles: (e: Array<RectType>) => void;
  polygonTools: polygonToolsType;
  multiselectTools: multiselectToolsType;
  isEditText: boolean;
  navigate: (e: string) => void;
  projectId: string | number;
  isAccess: boolean;
  polygonStatisticID?: string;
  hoverRectId: { id: string | number; type: HoverRectIdType };
  setHoverRectId: (value: { id: string | number; type: HoverRectIdType }) => void;
};

export const Rectangle = ({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  rect,
  polygonTools,
  multiselectTools,
  isEditText,
  navigate,
  projectId,
  isAccess,
  polygonStatisticID,
  hoverRectId,
  setHoverRectId,
}: RectangleType) => {
  const shapeRef = useRef<any>();
  const trRef = useRef<any>();

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  const [optionsImage] = useImage(OptionsIcon);

  const draggable = () => {
    if (!isAccess) return false;
    return rect?.draggable;
  };

  const showLink = !!rect?.parentLink;

  const [rectImage] = useImage(rect?.file ? `${BASE_URL}${rect?.file?.path}` : '');

  return (
    <Group
      style={{ position: 'relative' }}
      id={rect.id}
      onClick={() => {
        if (!isAccess) return;
        onSelect(shapeRef);
      }}
      onTap={() => {
        if (!isAccess) return;
        onSelect(shapeRef);
      }}
      name='rectangle'
      ref={shapeRef}
      {...shapeProps}
      draggable={draggable()}
      onDragStart={(event) => {
        polygonTools.dragStart();
        multiselectTools.dragStart({ event });
      }}
      onDragEnd={(event) => {
        polygonTools.dragEnd({ event, onChange, shapeProps });
        multiselectTools.dragEnd();
      }}
      onTransformEnd={() => {
        polygonTools.transformEnd({ shapeRef, onChange, shapeProps });
      }}
      onDragMove={(event) => {
        multiselectTools.dragMove({ event });
      }}
      onDblClick={() => {
        if (rect?.parentLink !== 'new' && rect?.parentLink)
          polygonStatisticID
            ? navigate(`/polygon-single/${polygonStatisticID}/${rect?.parentLink}`)
            : navigate(`/polygon/${projectId}/${rect?.parentLink}`);
      }}
      onMouseEnter={() => {
        setHoverRectId({ id: rect.id, type: HoverRectIdType.RECT });
      }}
      onMouseLeave={() => {
        setHoverRectId({ id: 0, type: HoverRectIdType.NONE });
      }}
    >
      <React.Fragment>
        {rectImage ? (
          <Image
            image={rectImage}
            y={2}
            x={2}
            width={rect?.width - 4}
            height={rect?.height - 4}
            fill={rect.fill}
            stroke={
              hoverRectId.id === rect.id &&
              hoverRectId.type === HoverRectIdType.DESCRIPTION
                ? '#9747FF'
                : '#d8d8d8'
            }
            strokeWidth={
              hoverRectId.id === rect.id &&
              hoverRectId.type === HoverRectIdType.DESCRIPTION
                ? 5
                : 1
            }
            name='rectangle'
            id={rect.id + '/rect'}
          />
        ) : (
          <Rect
            y={2}
            x={2}
            width={rect?.width - 4}
            height={rect?.height - 4}
            fill={rect.fill}
            strokeWidth={
              hoverRectId.id === rect.id &&
              hoverRectId.type === HoverRectIdType.DESCRIPTION
                ? 3
                : 1
            }
            name='rectangle'
            id={rect.id + '/rect'}
            stroke={
              hoverRectId.id === rect.id &&
              hoverRectId.type === HoverRectIdType.DESCRIPTION
                ? '#9747FF'
                : '#d8d8d8'
            }
          />
        )}

        {showLink && <Image image={optionsImage} x={rect?.width - 18} y={5} />}
        {isSelected && (
          <Text
            text={`${rect?.nameArray}`}
            x={rect?.width - 20 * (rect?.nameArray?.length + 1)}
            y={rect?.height - 30}
            fontSize={30}
          />
        )}

        <Group y={5}>
          <EditText
            isEditMode={isEditText && isSelected}
            rect={rect}
            onChangeHandler={polygonTools.changeParameter}
          />
        </Group>

        {isSelected && (
          <Transformer
            ref={trRef}
            rotateEnabled={false}
            boundBoxFunc={(oldBox, newBox) => {
              // limit resize
              if (newBox.width < 5 || newBox.height < 5) {
                return oldBox;
              }
              return newBox;
            }}
          />
        )}
      </React.Fragment>
    </Group>
  );
};
