import './AddUserTagsModal.scss';

import React, { useState } from 'react';

import { Button, TagsSelectNew } from '../../../index';

type AddUserTagsModalType = {
  onClose: () => void;
  modalParameters: any;
};

export const AddUserTagsModal = ({ onClose, modalParameters }: AddUserTagsModalType) => {
  const [tagSave, setTagSave] = useState(modalParameters?.initialValues?.tagsId);

  return (
    <div className='addUserTagsModal'>
      <TagsSelectNew
        setValue={(e: any) => {
          setTagSave(e);
        }}
        editTagIds={tagSave}
        isEditMode={true}
      />
      <div className='addUserTagsModal__button-wrapper'>
        <Button
          onClick={() => {
            modalParameters?.onCallback(
              tagSave?.map((e: string) => {
                return parseInt(e);
              }),
            );
            onClose();
          }}
        >
          Сохранить
        </Button>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Отменить
        </Button>
      </div>
    </div>
  );
};
