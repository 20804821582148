import React from 'react';

type NewMessageIconsType = {
  className?: string;
  onClick?: () => void;
};

export const NewMessageIcons = ({ className, onClick }: NewMessageIconsType) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M13.1948 0H2.80518C1.25838 0 0 1.31163 0 2.92382V9.60312C0 11.2153 1.25838 12.5269 2.80518 12.5269L9.42283 12.5269L12.5907 15.8287C12.6981 15.9406 12.8415 16 12.9876 16C13.0598 16 13.1327 15.9854 13.2021 15.9554C13.4117 15.8649 13.5485 15.6518 13.5485 15.4152V12.5038C14.929 12.322 16 11.0905 16 9.60312V2.92382C16 1.31163 14.7416 0 13.1948 0ZM14.8779 9.60316C14.8779 10.5705 14.1229 11.3574 13.1948 11.3574H12.9874C12.6776 11.3574 12.4264 11.6193 12.4264 11.9422V14.0035L10.052 11.5287C9.94675 11.419 9.80411 11.3574 9.65523 11.3574H2.80518C1.87709 11.3574 1.12207 10.5705 1.12207 9.60316V2.92387C1.12207 1.95654 1.87709 1.1696 2.80518 1.1696H13.1948C14.1229 1.1696 14.8779 1.95654 14.8779 2.92387V9.60316Z'
        fill='white'
      />
      <path
        d='M12.129 4.14066H3.87061C3.56078 4.14066 3.30957 4.40244 3.30957 4.72541C3.30957 5.04834 3.56078 5.31017 3.87061 5.31017H12.129C12.4389 5.31017 12.6901 5.04834 12.6901 4.72541C12.6901 4.40244 12.4389 4.14066 12.129 4.14066Z'
        fill='white'
      />
      <path
        d='M12.129 7.41528H3.87061C3.56078 7.41528 3.30957 7.67707 3.30957 8.00004C3.30957 8.32301 3.56078 8.58479 3.87061 8.58479H12.129C12.4389 8.58479 12.6901 8.32296 12.6901 8.00004C12.6901 7.67711 12.4389 7.41528 12.129 7.41528Z'
        fill='white'
      />
    </svg>
  );
};
