import './MasonreyWrapper.scss';

import React, { FC } from 'react';
import Masonry from 'react-masonry-css';

import { breakpointColumnsObj } from '../../constants/constants';

export const MasonryWrapper: FC = ({ children }) => {
  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className='my-masonry-grid'
      columnClassName='my-masonry-grid_column'
    >
      {children}
    </Masonry>
  );
};
