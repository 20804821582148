import './ProjectBlock.scss';

import React, { FC, useState } from 'react';

import { Pagination, ProjectBlockHeader, ProjectBlockItem } from '../../components';
import {
  taskPaginationLimit,
  tasksSiblingPaginationCount,
} from '../../constants/constants';
import { Project } from '../../graphql/generated/graphql';
import { useGetProjectQuery } from '../../hooks/useQueries/useGetProjectQuery';
import { addKarma } from '../../utils';

export const ProjectBlock: FC<ProjectBlockType> = ({
  projectData,
  handleDelete,
  refetch,
}) => {
  const [
    getProjectQuery,
    { data: singleProjectData, fetchMore: singleProjectFetchMore, called },
  ] = useGetProjectQuery(projectData?.id);

  const [currentPage, setCurrentPage] = useState(1);

  const [isFirstPage, setIsFirstPage] = useState(true);

  const tasksCount = singleProjectData
    ? singleProjectData.getProject.tasks.count
    : projectData.tasks.count;

  const currentPosts = isFirstPage
    ? projectData?.tasks.rows
    : singleProjectData?.getProject.tasks.rows;

  const onPageChange = async (pageNumber: number) => {
    if (pageNumber === 1) {
      setIsFirstPage(true);
      setCurrentPage(pageNumber);
      return;
    }

    if (pageNumber >= 2 && !called) {
      await getProjectQuery({
        variables: {
          id: projectData?.id,
          tasksData: {
            limit: taskPaginationLimit,
            offset: (pageNumber - 1) * taskPaginationLimit,
            isTaskStorage: false,
          },
        },
      });
      setIsFirstPage(false);
      setCurrentPage(pageNumber);
    }

    if (pageNumber >= 2 && called) {
      await singleProjectFetchMore({
        variables: {
          id: projectData?.id,
          tasksData: {
            limit: taskPaginationLimit,
            offset: (pageNumber - 1) * taskPaginationLimit,
            isTaskStorage: false,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          const fetchMoreTasks = fetchMoreResult.getProject.tasks.rows;
          return {
            getProject: {
              ...prev.getProject,
              tasks: {
                ...prev.getProject.tasks,
                rows: fetchMoreTasks,
                count: fetchMoreResult.getProject.tasks.count,
              },
            },
          };
        },
      });
      setIsFirstPage(false);
      setCurrentPage(pageNumber);
    }
  };

  return (
    <div className='projectBlock'>
      <ProjectBlockHeader
        title={`#${projectData?.number} ${projectData?.name}`}
        entityId={projectData?.id}
        projectId={projectData?.id}
        projectNumber={projectData?.number}
        initialTitle={projectData?.name}
        label='Название проекта:'
        color='#FFFFFF'
        isProject
        handleDelete={handleDelete}
        refetch={refetch}
      />
      <div className='projectBlock__items'>
        {currentPosts?.length ? (
          currentPosts.map((task) => {
            if (task) {
              return (
                <ProjectBlockItem
                  key={task.id}
                  taskId={task.id}
                  title={`#${task?.project?.number}.${task?.number} ${task?.name}`}
                  userAvatar={task?.maintainer?.image}
                  name={addKarma(task?.maintainer as any)}
                  projectStatus={task?.status?.id}
                  endDate={task?.endDate}
                  tags={task?.tags.rows}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  poligonObjectId={task?.poligonObject?.id}
                  projectId={task?.project?.id}
                />
              );
            }
          })
        ) : (
          <div className='projectBlock__missingProjects'>Еще нет задач</div>
        )}
      </div>
      <Pagination
        paginate={onPageChange}
        currentPage={currentPage}
        totalCount={tasksCount}
        pageSize={taskPaginationLimit}
        siblingCount={tasksSiblingPaginationCount}
      />
      <div className='projectBlock__line' />
    </div>
  );
};

type ProjectBlockType = {
  projectData: Project;
  handleDelete: (itemId: number) => void;
  refetch: () => void;
};
