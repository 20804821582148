import './PolygonActionsWrapper.scss';

import React, { CSSProperties, FC } from 'react';

export const PolygonActionsWrapper: FC<{
  isVisible?: boolean;
  style?: CSSProperties;
}> = ({ children, isVisible, style }) => {
  return (
    <div style={isVisible ? { display: 'flex' } : { display: 'none' }}>
      <div className='polygon-actions-wrapper' style={style}>
        {children}
      </div>
    </div>
  );
};
