import React, { CSSProperties, FunctionComponent } from 'react';

import { useProjectStatus } from '../../hooks/useProjectStatus';
import { Status } from '../icons';
import { TooltipStatus } from '../index';

type ProjectStatusPropsType = {
  projectStatus: number | null | undefined;
  style?: CSSProperties;
  id: any;
};

export const ProjectStatus: FunctionComponent<ProjectStatusPropsType> = ({
  projectStatus,
  style,
  id,
}) => {
  const color = useProjectStatus(projectStatus);

  return (
    <div className='project__status' id={`project__status${id}`}>
      <Status color={color} style={style} />
      <TooltipStatus id={projectStatus as any} anchorId={`project__status${id}`} />
    </div>
  );
};
