import './UserChat.scss';

import React from 'react';

import { Chat, Files, PrivateChatNotes } from '../../components';
import { ChatProvider } from '../../context';

export const UserChat = () => {
  return (
    <ChatProvider>
      <div className='container'>
        <div className='user-chat'>
          <Chat />
          <div className='user-chat__items'>
            <Files backgroundColor='#D5EDD8' />
            <PrivateChatNotes />
          </div>
        </div>
      </div>
    </ChatProvider>
  );
};
