import React, { FC } from 'react';

export const ExpensesCount: FC<{ title: string; value?: number }> = ({
  title,
  value,
}) => {
  return (
    <span>
      {title}:&nbsp;<b>$&nbsp;{value}</b>
    </span>
  );
};
